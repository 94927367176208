<div class="notification-item" (click)="onClickAcknowledge($event)">
    <app-dg-event-log-item
        [translationResult]="notification"
    ></app-dg-event-log-item>
    <div *ngIf="!hideAcknowledgeAction" class="action-container">
        <dxy-color-point-cell
            *ngIf="!acknowledged"
            (click)="onClickAcknowledge($event)"
            class="dot-wrapper small primary"
            [matTooltip]="
                'UI.NotificationContainer.AcknowledgeTooltip' | translate
            "
        ></dxy-color-point-cell>

        <div
            *ngIf="acknowledged"
            (click)="onClickUnAcknowledge($event)"
            [matTooltip]="
                'UI.NotificationContainer.UnacknowledgeTooltip' | translate
            "
            class="dot-placeholder"
        ></div>
    </div>
</div>
