import { Pipe, PipeTransform } from '@angular/core';
import { getLanguageName } from './language-names';

@Pipe({
    name: 'languageName',
    standalone: true,
})
export class LanguageNamePipe implements PipeTransform {
    transform(languageCode: string): string {
        return getLanguageName(languageCode);
    }
}
