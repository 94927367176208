import { IBaseFilterData } from '../base-filter/base-filter.types';

export interface INumberFilterData
    extends IBaseFilterData<NumberFilterOperator> {
    min?: number;
    max?: number;
}

export enum NumberFilterOperator {
    Unknown = 0,
    Equals,
    Greater,
    GreaterEqual,
    Lower,
    LowerEqual,
    RangeContains,
    FieldIsEmpty,
    FieldHasValue,
}
