import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[dxyFilterCarouselItem]',
    standalone: true,
})
export class FilterCarouselItemDirective implements AfterViewInit {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.renderer.setStyle(this.el.nativeElement, 'flex', '0 0 auto');
    }

    public getWidth() {
        return this.el.nativeElement.offsetWidth;
    }
}
