import {
    IRectSidePointParams,
    IXY,
    Rect,
    RectSide,
    RectSidePoint,
} from '@datagalaxy/core-2d-util';
import { Connector } from '../connector';
import { IConnectorEnd } from './endpoint.types';
import { NodePort } from '../../ports';
import { EndpointHandle } from '../../tools';

/**
 * ## Role
 * Handle a connector endpoint connection on a node port
 */
export class ConnectorEndpoint<N = unknown>
    extends RectSidePoint<N>
    implements IConnectorEnd<N>
{
    private static _stubRect = new Rect();
    private port: NodePort<N>;

    /** the connector this connector end belong to */
    public connector?: Connector<N>;
    public handle?: EndpointHandle<N>;

    public get excludedSides() {
        return this.port?.excludedSides;
    }
    /** the other endpoint of the connector */
    public get other() {
        return this.connector.src == this
            ? this.connector.tgt
            : this.connector.src;
    }

    constructor(port: NodePort<N>, spec?: IRectSidePointParams) {
        super(port, Rect.rectSide(spec?.side), spec?.distance);
        this.port = port;
    }

    /**
     * Computes a point on the side of this node's rectangle augmented with the
     * given margin
     */
    public getStubPoint(margin: number, result?: IXY) {
        let mx: number, my: number;
        switch (this.side) {
            case RectSide.left:
            case RectSide.right:
                mx = 2 * margin;
                my = 0;
                break;
            case RectSide.top:
            case RectSide.bottom:
                mx = 0;
                my = 2 * margin;
                break;
        }
        return new Rect()
            .copy(this.node.rect)
            .augmentBy(mx, my)
            .sidePoint(this.side, result, this.distance);
    }
}
