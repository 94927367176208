<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.Insights
    </div>
    <div class="actions-container"></div>
</div>
<div [class.panel-content-body]="showHeader">
    <!-- Activity -->
    <dxy-insight-activity
        [currentEntity]="currentEntity"
    ></dxy-insight-activity>
    <!-- Context -->
    <dxy-collapsible
        class="reflex-shadow big-header body-padding"
        titleKey="UI.EntityDockingPane.Insights.Context.title"
    >
        <div class="context-container">
            <div
                *ngIf="canHaveEntityTree"
                (click)="onDescendantsClick()"
                class="btn-context"
                [matTooltip]="
                    'UI.EntityDockingPane.Insights.Context.descendantsTooltip'
                        | translate
                "
            >
                <i
                    class="btn-context-icon descendants glyph glyph-hierarchy"
                ></i>
                <span class="btn-context-value">{{
                    displayedDescendantsCount
                }}</span>
                <span class="btn-context-text" translate
                    >UI.EntityDockingPane.Insights.Context.descendants</span
                >
            </div>
            <div
                (click)="onLinkedObjectsClick()"
                class="btn-context"
                [matTooltip]="
                    'UI.EntityDockingPane.Insights.Context.linkedObjectsTooltip'
                        | translate
                "
            >
                <i class="btn-context-icon linked glyph glyph-diagram"></i>
                <span class="btn-context-value">{{
                    diksplayedLinkedObjectCount
                }}</span>
                <span class="btn-context-text" translate
                    >UI.EntityDockingPane.Insights.Context.linkedObjects</span
                >
            </div>
            <div
                *ngIf="showFeedback"
                (click)="onFeedbackClick()"
                class="btn-context feedback"
            >
                <i class="btn-context-icon feedback glyph glyph-help"></i>
                <span class="btn-context-text" translate
                    >UI.EntityDockingPane.Insights.Context.feedback</span
                >
            </div>
        </div>
    </dxy-collapsible>
    <!-- Filled in attributes  -->
    <dxy-collapsible
        class="reflex-shadow big-header body-padding"
        titleKey="UI.EntityDockingPane.Insights.Indicators.title"
    >
        <div class="indicators-container">
            <div class="completeness-indicator">
                <dxy-percentage-donut
                    [percentage]="fillingValue"
                    [matTooltip]="
                        'UI.EntityDockingPane.Insights.Indicators.completenessTooltip'
                            | translate
                    "
                ></dxy-percentage-donut>
                <span class="text" translate
                    >UI.EntityDockingPane.Insights.Indicators.completeness</span
                >
            </div>
            <div class="filling-indicator">
                <dxy-percentage-donut
                    [percentage]="completionValue"
                    [matTooltip]="
                        'UI.EntityDockingPane.Insights.Indicators.fillingTooltip'
                            | translate
                    "
                ></dxy-percentage-donut>
                <span class="text" translate
                    >UI.EntityDockingPane.Insights.Indicators.filling</span
                >
            </div>
        </div>
    </dxy-collapsible>
    <!-- Top Contributors -->
    <dxy-insight-top-contributors
        *ngIf="isTopContributorsVisible"
        [topUsers]="topUsers"
    ></dxy-insight-top-contributors>
</div>
