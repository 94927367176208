<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.ModelDashboard.titleDiagrams
    </div>
    <div class="actions-container"></div>
</div>
<header>
    <div *ngIf="usePublishingStatusTabs" class="header-tab-wrapper">
        <dxy-tabs-header
            [tabs]="tabsHeaderData"
            (tabChange)="onTabChange($event)"
        ></dxy-tabs-header>
    </div>
    <div
        *ngIf="showDiagramCount"
        class="header-count"
        translate
        [translateParams]="{ count: diagramCount }"
    >
        UI.Diagrams.diagramsCount
    </div>
    <button
        *ngIf="canCreate"
        class="add-btn"
        mat-mini-fab
        color="primary"
        (click)="onCreateDiagram()"
        [matTooltip]="'UI.ModelDashboard.btnNewDiagram' | translate"
    >
        <span class="glyph glyph-add"></span>
    </button>
    <dxy-grid-column-selector
        *ngIf="entityGrid?.grid"
        [forGrid]="entityGrid.grid"
        [enableSearch]="true"
    ></dxy-grid-column-selector>
</header>
<dxy-search-input
    *ngIf="showSearch"
    class="no-border-top"
    [(searchString)]="searchTerm"
    (onSearchString)="onSearchTermChange($event)"
></dxy-search-input>

<dxy-entity-grid
    *ngIf="gridVisible"
    [entities]="entities"
    [config]="config"
    (rowClick)="goToDiagram($event)"
    (attributeVisibilityChange)="onAttributeVisibilityChange($event)"
></dxy-entity-grid>

<div *ngIf="placeholderVisible" class="dxy-placeholder">
    <img
        class="dxy-placeholder-img"
        src="{{ placeholderSrc }}"
        alt="no diagrams"
    />
    <ng-container *ngIf="useLargePlaceholder">
        <h3 translate>UI.Diagrams.diagramsListPlaceholderTitle</h3>
        <div class="dxy-placeholder-text" translate>
            UI.Diagrams.diagramsListPlaceholderText
        </div>
    </ng-container>
    <div *ngIf="!useLargePlaceholder" class="dxy-placeholder-text" translate>
        UI.Diagrams.diagramsListShortPlaceholder
    </div>
</div>

<dxy-spinner *ngIf="loading"></dxy-spinner>
