import {
    AfterViewInit,
    Directive,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    AttributeFilterAction,
    IAttributeFilterSubmitEvent,
} from './attribute-filter.types';
import { AttributeFilterModel } from './attribute-filter/attributeFilterModel';
import { DxyBaseFilterComponent } from '@datagalaxy/core-ui/filters';
import { AttributeFilterService } from './attribute-filter.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';

@Directive()
export abstract class BaseAttributeFilter<
        TItemModel extends AttributeFilterModel
    >
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    /** Not to be used directly in HTML (use local public properties instead)
     * - Assumed never null
     */
    @Input() filterItemData: TItemModel;
    @Input() hasRemove: boolean;
    @Input() isQuickFilter: boolean;
    @Input() removeDisabled: boolean;
    @Input() removeDisabledTooltipKey: string;
    @Input() isReadOnly: boolean;
    @Input() isMini: boolean;

    @Output() onSubmitFilter = new EventEmitter<IAttributeFilterSubmitEvent>();
    @Output() onAction = new EventEmitter<AttributeFilterAction>();

    public menuOpen: boolean;

    public get readOnly() {
        return this.isReadOnly || this.filterItemData.isReadOnly;
    }

    public get label() {
        return this.attributeFilterService.getFilterItemDisplayName(
            this.filterItemData
        );
    }
    public get showRemoveButton() {
        return (
            (this.filterItemData.isDeletableItem && !this.readOnly) ||
            this.hasRemove
        );
    }

    /** Available values, to be listed in the selection drop down list */
    protected get listValues() {
        return this.filterItemData.ListValues;
    }
    protected set listValues(values: AttributeMetaValue[]) {
        this.filterItemData.ListValues = values;
    }
    protected get attributeMeta() {
        return this.filterItemData.attributeMeta;
    }
    protected get attributeKey() {
        return this.filterItemData.attributeKey;
    }
    protected get attributeType() {
        return this.filterItemData.attributeType;
    }

    protected get isFormOpen() {
        return this.filterItemData.isFormOpen;
    }
    protected set isFormOpen(value: boolean) {
        this.filterItemData.isFormOpen = value;
    }

    protected abstract filterComponent: DxyBaseFilterComponent<any, any>;

    constructor(protected attributeFilterService: AttributeFilterService) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', this.filterItemData);
    }

    ngAfterViewInit() {
        if (this.isFormOpen) {
            this.filterComponent?.openMenu();
        }
    }

    public onRemove() {
        this.onAction.emit(AttributeFilterAction.itemRemoved);
    }

    public onOpenCloseMenu(isOpen: boolean) {
        this.log('onOpenCloseMenu', isOpen, this.filterItemData);
        this.menuOpen = isOpen;
        if (isOpen) {
            return;
        }
        this.onAction.emit(AttributeFilterAction.formClosed);
    }
}
