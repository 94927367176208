import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { EntityActivityEventBase, registerTypedEvent } from '../../events';
import { EntityCommentaryDTO } from './EntityCommentaryDTO';

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('CommentariesDeletedEvent')
export class CommentariesDeletedEvent extends EntityActivityEventBase {
    @deserialize DeletedCommentariesCount: number;
}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('CommentaryCreatedEvent')
export class CommentaryCreatedEvent extends EntityActivityEventBase {
    @deserializeAs(EntityCommentaryDTO) Commentary: EntityCommentaryDTO;
}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('CommentaryUpdatedEvent')
export class CommentaryUpdatedEvent extends EntityActivityEventBase {
    @deserializeAs(EntityCommentaryDTO) Commentary: EntityCommentaryDTO;
    @deserialize PreviousText: string;
}
