<div class="dxy-field" [class.mini]="mini">
    <div class="read-only-field">
        <label
            [dxyEllipsisTooltip]="description"
            dxyEllipsisMode="alwaysVisible"
        >
            {{ labelText }}
        </label>
        <p class="value-container">
            <i [ngClass]="glyphClass"></i> {{ value }}
        </p>
        <div class="read-only-separator"></div>
    </div>
</div>
