import { Injectable } from '@angular/core';
import {
    MsTeamsIntegrationActivatedEvent,
    MsTeamsIntegrationDeactivatedEvent,
} from '@datagalaxy/dg-object-model';
import { IDgEventInterpolationProvider } from './dg-event-interpolation-provider';
import { msTeamsNotificationChannel } from '../../notification-channels/ms-teams-notification-channel';

@Injectable({ providedIn: 'root' })
export class MsTeamsInterpolationProvider
    implements IDgEventInterpolationProvider
{
    public static eventTypes = [
        MsTeamsIntegrationActivatedEvent,
        MsTeamsIntegrationDeactivatedEvent,
    ];

    constructor() {}

    public resolveParameters() {
        return {
            customAvatarUrl: msTeamsNotificationChannel.imageUrl,
        };
    }
}
