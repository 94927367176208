import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { ImportContext } from '../../../shared/ImportContext';
import { EOperationType } from '../../../shared/EOperationType';
import { CsvFieldInfo } from '../../../shared/CsvFieldInfo';
import { ImportModule } from '../../../shared/ImportModule';
import { AttributeCategory } from '../../../shared/AttributeCategory';
import { AttributeMatch } from '../../../shared/AttributeMatch';
import { CsvImportApiService } from '../../../services/csv-import-api.service';
import { GlyphService } from '../../../../services/glyph.service';
import {
    EmptyFieldBehavior,
    ImportAttributesMetaInfo,
} from '@datagalaxy/webclient/data-port/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyAttributeMatchComponent } from '../dxy-attribute-match/dxy-attribute-match.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxyDraggableFieldDirective } from '../../../directives/dxyDraggableField.directive';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

/**
 * ## Role
 * Csv import Mapping step
 * Display a list of attributes & fields
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-csv-pre-import-mappings',
    templateUrl: './dxy-csv-pre-import-mappings.component.html',
    styleUrls: ['./dxy-csv-pre-import-mappings.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        MatLegacyCheckboxModule,
        FormsModule,
        NgIf,
        SearchInputComponent,
        NgFor,
        DxyDraggableFieldDirective,
        MatLegacyButtonModule,
        DxyAttributeMatchComponent,
    ],
})
export class DxyCsvPreImportMappingsComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() importContext: ImportContext;
    @Input() isImportLoading: boolean;
    @Output() readonly onInitMappingDone = new EventEmitter<void>();

    @ViewChild('sourceFieldsList')
    sourceFieldsListElement: ElementRef<HTMLElement>;

    public readonly EOperationType = EOperationType;
    public hideMatchedFields = true;

    public get canOverrideEmptyValues() {
        return ![
            EOperationType.Teams,
            EOperationType.Users,
            EOperationType.AttributeTags,
        ].includes(this.importContext.currentOperation);
    }

    public get isEmptyValuesOverride() {
        return (
            this.importContext.emptyFieldBehavior ==
            EmptyFieldBehavior.DeleteValues
        );
    }
    public set isEmptyValuesOverride(value) {
        this.importContext.hasChangedMapping = true;
        this.importContext.emptyFieldBehavior = value
            ? EmptyFieldBehavior.DeleteValues
            : EmptyFieldBehavior.Default;
    }
    public searchString = '';
    public get fileName() {
        return this.importContext.csvFileName;
    }
    public get fields(): Array<CsvFieldInfo> {
        return this.alphaSortFields(this.importContext.csvFields);
    }
    public get isCurrentOperationHardCoded() {
        return ImportModule.isOperationHardCoded(
            this.importContext.currentOperation
        );
    }

    public get categories() {
        return this.importContext.categories;
    }
    public set categories(value: AttributeCategory[]) {
        this.importContext.categories = value;
    }

    private get attributes() {
        return this.importContext.attributeMatches;
    }
    private set attributes(value: AttributeMatch[]) {
        this.importContext.attributeMatches = value;
    }

    private mappedFieldsCount = 0;
    private get unmappedFieldsCount() {
        return this.importContext.csvFields?.length - this.mappedFieldsCount;
    }

    constructor(
        private translate: TranslateService,
        private importApiService: CsvImportApiService,
        private glyphService: GlyphService
    ) {
        super();
    }

    ngOnInit() {
        this.init().then();
    }

    public onClickCategory(category: AttributeCategory) {
        category.isCollapsed = !category.isCollapsed;
    }

    public getAttributeItem(attributeName: string) {
        return this.attributes?.find(
            (att) => att.attributeName == attributeName
        );
    }

    public isEntityLinksOperation() {
        return [
            EOperationType.GlossaryRelations,
            EOperationType.SoftwareRelations,
            EOperationType.DataProcessingRelations,
            EOperationType.CatalogRelations,
        ].includes(this.importContext.currentOperation);
    }

    public isHardCodedVisible(operation: EOperationType) {
        return (
            this.importContext.currentOperation == operation &&
            ImportModule.isOperationHardCoded(operation)
        );
    }

    public getUnmappedFieldsText() {
        return this.translate.instant(
            `Import.GenericImportWizard.CsvPreImport.Mapping.UnmappedFieldsTxt`,
            { count: this.unmappedFieldsCount }
        );
    }

    public onMatched(matchedAttribute: AttributeMatch) {
        const fieldFrom = this.fields.filter(
            (f) => f.name == matchedAttribute.csvFieldName
        )[0];
        if (fieldFrom) {
            fieldFrom.isMatched = true;
        }
        this.mappedFieldsCount = this.computeMappedFieldsCount();
        this.importContext.hasChangedMapping = true;
    }

    public onUnmatched(e: {
        matchedAttribute: AttributeMatch;
        unmatchedFieldName: string;
    }) {
        e.matchedAttribute.csvFieldName = undefined;
        if (
            e.unmatchedFieldName != undefined &&
            !this.attributes.some(
                (a) => a.csvFieldName && a.csvFieldName == e.unmatchedFieldName
            )
        ) {
            const fieldFrom = this.fields.filter(
                (f) => f.name == e.unmatchedFieldName
            )[0];
            if (fieldFrom) {
                fieldFrom.isMatched = false;
            }
        }
        this.mappedFieldsCount = this.computeMappedFieldsCount();
        this.importContext.hasChangedMapping = true;
    }

    public alphaSortFields(fieldList: Array<CsvFieldInfo>) {
        return CollectionsHelper.alphaSort(fieldList, 'name');
    }
    public alphaSortAttributes(attributeList: Array<AttributeMatch>) {
        return CollectionsHelper.alphaSort(attributeList, 'attributeTitle');
    }

    public onSearchField(searchString: string) {
        this.searchString = searchString;
        const normalizedSearched = StringUtil.normalizeForSearch(
            this.searchString
        );
        this.fields.forEach(
            (f) =>
                (f.isFilteredOut = !StringUtil.normalizeForSearch(
                    f.name
                ).includes(normalizedSearched))
        );
    }

    public isSearchBarVisible() {
        if (!this.sourceFieldsListElement) {
            return false;
        }
        const menuElement = this.sourceFieldsListElement.nativeElement;
        return (
            !!this.searchString ||
            menuElement?.scrollHeight > menuElement?.clientHeight
        );
    }

    private async init() {
        if (!this.attributes) {
            await this.getAttributes();
        }
        this.onInitMappingDone.emit();
    }

    private async getAttributes() {
        const { currentOperation, spaceIdr, currentModule } =
            this.importContext;
        const ido = ImportModule.GetImportDataOperation(currentOperation);
        try {
            const result = await this.importApiService.getImportAttributes(
                ido,
                spaceIdr
            );
            this.attributes = result.ImportAttributes.map((a) =>
                this.newAttribute(a)
            );

            if (ImportModule.isOperationHardCoded(currentOperation)) {
                const i18nKey = currentModule.getOpeTextKey(currentOperation);
                this.categories = [
                    new AttributeCategory(
                        this.translate.instant(i18nKey),
                        this.attributes
                    ),
                ];
            } else {
                this.makeCategories(this.attributes);
            }

            this.initMapping();
        } catch (err) {
            CoreUtil.warn('getImportAttributes', err);
        }
    }

    private makeCategories(attrs: AttributeMatch[]) {
        const addIfNotEmpty = (
            suffix: string,
            filter: (a: AttributeMatch) => boolean,
            sortAlpha = false
        ) => {
            const data = attrs.filter(filter);
            if (!data?.length) {
                return;
            }
            const sortedData = sortAlpha
                ? this.alphaSortAttributes(data)
                : data;
            this.categories.push(
                new AttributeCategory(
                    this.translate.instant(
                        `Import.Wizard.MatchFields.Categories.${suffix}`
                    ),
                    sortedData
                )
            );
        };
        this.categories = [];
        addIfNotEmpty('GeneralInfo', (a) => !a.isCDP && !a.isSystemUser);
        addIfNotEmpty('SystemUser', (a) => !a.isCDP && a.isSystemUser);
        addIfNotEmpty('CDP', (a) => a.isCDP && !a.isSystemUser, true);
    }

    private initMapping() {
        this.importContext.isAttributesAutoMapped = false;
        this.attributes.forEach((attr) => {
            const field = this.findFieldForAttribute(attr);
            if (field) {
                attr.csvFieldName = field.name;
                this.onMatched(attr);
            }
        });
        this.importContext.isAttributesAutoMapped = true;
    }

    private findFieldForAttribute(attributeMatch: AttributeMatch) {
        const s = attributeMatch.expectedTitle.toUpperCase();
        return this.fields.find(
            (field) => field.name.trim().toUpperCase() === s
        );
    }

    private newAttribute(a: ImportAttributesMetaInfo) {
        const result = new AttributeMatch(
            a.AttributeKey,
            a.AttributeHeaderName,
            a.IsMandatory,
            a.Description,
            a.IsSystemUser,
            a.IsCdp
        );
        result.glyphClass = this.glyphService.getDataGlyphClass(a.DataTypeName);
        return result;
    }

    private computeMappedFieldsCount() {
        return CollectionsHelper.count(this.attributes, (o) => o.isMatched);
    }
}
