import { Point, Rect, Vect2 } from '@datagalaxy/core-2d-util';
import { select } from 'd3-selection';
import { IConnectorRouter } from '../routing.types';
import { OrthogonalConnectorByproduct, Rectangle } from './path-finder';

export class OrthogonalRouterDebug {
    public static addDebugGroup(
        router: IConnectorRouter,
        el: SVGGElement,
        points: Point[],
        position: Point
    ) {
        const byProduct = router.byproduct;
        if (byProduct) {
            const vb = Rect.fromPoints(
                this.translateToOrigin(points, position)
            );
            const byproduct = this.translateProduct(position, byProduct);
            const d3bps = select(el)
                .selectAll('g.byproduct')
                .data([byproduct])
                .join('g')
                .attr('class', 'byproduct');
            // hRulers
            d3bps
                .selectAll('path.obp-hruler')
                .data((d) => d.hRulers)
                .join('path')
                .attr('class', 'obp-hruler')
                .attr('d', (y) => `M${vb.left} ${y},L${vb.right} ${y}`)
                .attr('stroke', 'gray')
                .attr('stroke-dasharray', '2 2')
                .attr('stroke-width', 0.5)
                .style('pointer-events', 'none');
            // vRulers
            d3bps
                .selectAll('path.obp-vruler')
                .data((d) => d.vRulers)
                .join('path')
                .attr('class', 'obp-vruler')
                .attr('d', (x) => `M${x} ${vb.top},L${x} ${vb.bottom}`)
                .attr('stroke', 'gray')
                .attr('stroke-dasharray', '2 2')
                .attr('stroke-width', 0.5)
                .style('pointer-events', 'none');
            // grid cells
            const m = 1;
            d3bps
                .selectAll('rect.obp-grid')
                .data((d) =>
                    d.grid.filter((g) => g.width >= 0 && g.height >= 0)
                )
                .join('rect')
                .attr('class', 'obp-grid')
                .attr('x', (d) => d.left + m)
                .attr('y', (d) => d.top + m)
                .attr('width', (d) => Math.max(0, d.width - 2 * m))
                .attr('height', (d) => Math.max(0, d.height - 2 * m))
                .attr('stroke', 'blue')
                .attr('fill', 'none')
                .attr('stroke-width', 0.5)
                .attr('opacity', 0.5)
                // .on('mouseenter mouseleave', function(this, e: MouseEvent) {
                //     select(this).attr('fill', e.type == 'mouseenter' ? 'gray' : 'none')
                // })
                .style('pointer-events', 'none');
            // spots
            d3bps
                .selectAll('circle.obp-spot')
                .data((d) => d.spots)
                .join('circle')
                .attr('class', 'obp-spot')
                .attr('cx', (d) => d.x)
                .attr('cy', (d) => d.y)
                .attr('r', 2)
                .attr('fill', 'green')
                .style('pointer-events', 'none');
            // unwantedSpots
            d3bps
                .selectAll('circle.obp-nospot')
                .data((d) => d.unwantedSpots)
                .join('circle')
                .attr('class', 'obp-nospot')
                .attr('cx', (d) => d.x)
                .attr('cy', (d) => d.y)
                .attr('r', 2)
                .attr('fill', 'orange')
                .style('pointer-events', 'none');
            // connections
            d3bps
                .selectAll('path.obp-con')
                .data((d) => d.connections)
                .join('path')
                .attr('class', 'obp-con')
                .attr('d', (d) => `M${d.a.x} ${d.a.y},L${d.b.x} ${d.b.y}`)
                .attr('stroke', (d) => (d.fixed ? 'limegreen' : 'green'))
                .attr('stroke-width', (d) => (d.fixed ? 1 : 0.5))
                .attr('stroke-dasharray', '3 3')
                .style('pointer-events', 'none');
        } else {
            select(el.parentElement).selectAll('g.byproduct').remove();
        }
    }

    private static translateProduct(
        position: Point,
        byProduct: OrthogonalConnectorByproduct
    ): OrthogonalConnectorByproduct {
        return {
            hRulers: byProduct.hRulers.map((y) => y - position.y),
            vRulers: byProduct.vRulers.map((x) => x - position.x),
            spots: this.translateToOrigin(byProduct.spots, position),
            grid: byProduct.grid
                .map((r) =>
                    Rect.from(r.toIXYRect).shift({
                        dx: -position.x,
                        dy: -position.y,
                    })
                )
                .map((r) => new Rectangle(r.x, r.y, r.width, r.height)),
            connections: byProduct.connections.map((c) => {
                return {
                    a: Vect2.from(c.a).sub(position),
                    b: Vect2.from(c.b).sub(position),
                    fixed: c.fixed,
                };
            }),
            originA:
                byProduct.originA &&
                Vect2.from(byProduct.originA).sub(position),
            originB:
                byProduct.originB &&
                Vect2.from(byProduct.originB).sub(position),
            start: Vect2.from(byProduct.start).sub(position),
            end: Vect2.from(byProduct.end).sub(position),
            unwantedSpots: this.translateToOrigin(
                byProduct.unwantedSpots,
                position
            ),
        };
    }

    private static translateToOrigin(points: Point[], position) {
        return points?.map((p) => Vect2.from(p).sub(position));
    }
}
