import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from './BaseService';

/** Base class for a UI service. Provides:
 * - logging methods
 * - registering of service for logging activation/deactivation from browser console (see DebugUtil)
 * - UI event subscription management */
@Injectable()
export abstract class BaseUiService extends BaseService {
    private uiSubscriptions: Subscription;

    protected subscribeUI<T>(
        eventSubject: Observable<T>,
        action: (eventData?: T) => void
    ) {
        if (!eventSubject || !action) {
            return;
        }
        const subscription = eventSubject.subscribe(action);
        return this.registerUiSubscription(subscription);
    }
    protected unsubscribeUI() {
        this.log('unsubscribeUI', !!this.uiSubscriptions);
        this.uiSubscriptions?.unsubscribe();
        this.uiSubscriptions = undefined;
    }
    protected registerUiSubscription(subscription: Subscription) {
        if (!subscription) {
            return;
        }
        (this.uiSubscriptions ??= new Subscription()).add(subscription);
        return subscription;
    }
}
