import { CollectionsHelper } from '@datagalaxy/core-util';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivityLogEntryTranslationFactory } from './activity-log-entry-translation/activity-log-entry-translation-factory';
import {
    IActivityLogEntryTranslationAdapter,
    ITranslatedActivityLogEntry,
} from './activity-log-entry-translation/activity-log-entry-translation.type';
import { EntityLinkService } from '../entity-links/entity-link.service';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { UserService } from '../services/user.service';
import { ActivityLogApiService } from '@datagalaxy/webclient/activity-log/data-access';
import { ActivityLogEntryElement } from './activityLog.types';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { GetActivityLogParameter } from '@datagalaxy/webclient/activity-log/data-access';
import { ObservableInput } from 'rxjs';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';

@Injectable({ providedIn: 'root' })
export class ActivityLogService extends BaseService {
    private translationFactory: ActivityLogEntryTranslationFactory;

    constructor(
        domSanitizer: DomSanitizer,
        translate: TranslateService,
        linkedObjectService: EntityLinkService,
        attributeDataService: AttributeDataService,
        userService: UserService,
        private activityLogApiService: ActivityLogApiService
    ) {
        super();
        this.translationFactory = new ActivityLogEntryTranslationFactory(
            domSanitizer,
            translate,
            linkedObjectService,
            attributeDataService,
            userService
        );
    }

    public async getSpaceActivityLogs(
        space: Space,
        opt: {
            userId?: string;
            maxResults?: number;
            fromTime?: string;
            toTime?: string;
        } = { maxResults: 250 }
    ) {
        const params = this.createGetActivityLogParameter(space.ReferenceId);
        const { userId, maxResults, fromTime, toTime } = opt;
        params.VersionId = space.VersionId;
        params.MaxResults = maxResults;
        params.UserId = userId;
        params.FromTime = fromTime;
        params.ToTime = toTime;
        const res = await this.requestGetActivityLog(params);
        return res?.Entries;
    }

    public createGetActivityLogParameter(
        dataReferenceId: string = null,
        dataTypeName: string = null,
        userId: string = null
    ) {
        const galp = new GetActivityLogParameter();
        galp.DataReferenceId = dataReferenceId;
        galp.DataTypeName = dataTypeName;
        galp.UserId = userId;
        galp.IncludeChildren = true;
        galp.IncludeTechnicalEntries = false;
        //galp.MaxResults = 50
        return galp;
    }

    public async requestGetActivityLog(
        galp: GetActivityLogParameter,
        cancelNotifier?: ObservableInput<any>
    ) {
        return await this.activityLogApiService.getActivityLog(
            galp,
            cancelNotifier
        );
    }

    public getActivityLogEntryElements(
        activityLogDataEntries: ActivityLogEntry[]
    ): ActivityLogEntryElement[] {
        const groupedEntries = CollectionsHelper.groupBy(
            activityLogDataEntries,
            (ale) => ale.CreationTimeFromNow,
            (k, items) => items
        );
        return CollectionsHelper.flattenGroups(groupedEntries, (entries) =>
            entries.map((e, i) => new ActivityLogEntryElement(e, !i))
        );
    }

    public buildActivityLogEntryTranslation(
        entry: ActivityLogEntry,
        adapter?: IActivityLogEntryTranslationAdapter
    ): ITranslatedActivityLogEntry {
        return this.translationFactory.translateEntry(entry, adapter);
    }
}
