import { Point } from '@datagalaxy/core-2d-util';
import { ShapeId } from '../../shapes';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export interface ArrowComponentProps {
    position?: Point;
    angle?: number;
    color?: GraphicalColor;
    shapeId?: ShapeId;
    thickness?: number;
}

/*
    Values are stored as numbers in json in DiagramEdgeDto.VisualData.arrowType,
    and some value names have to match ShapeId enum value names (see libs/core-d3-util/src/graph/shapes/shape.types.ts)
*/
export enum ArrowType {
    unknown = 0,
    none,
    //#region used in free diagrams
    /** plain, 45 degree, foldback, 10x6 */
    plain45fb,
    /** plain, 90 degree, 10x10 */
    plain90,
    /** line, 90 degree, 12x6 */
    line90,
    //#endregion
    //#region used in physical diagrams
    /** line, 60 degree, 6x6 */
    line6x6,
    //#endregion
}
