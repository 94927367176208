import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'dxy-funnel-stepper',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './funnel-stepper.component.html',
    styleUrls: ['./funnel-stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelStepperComponent {
    @Input() currentIndex = 0;
    @Input() stepCount = 0;

    protected get steps() {
        if (this.stepCount <= 0) {
            return [];
        }
        return new Array(this.stepCount);
    }
}
