import { GridTreeBehavior } from './grid-tree.types';
import { TDatasourceConfig } from '../grid-config';
import { ServerTree } from './server-tree';
import { Tree } from './tree';
import { Row } from '@tanstack/table-core';

export class GridTree<TRow> {
    private tree?: GridTreeBehavior<TRow>;

    public get enabled() {
        return !!this.tree;
    }

    public get expandAll() {
        return this.tree?.expandAll;
    }

    public isRowLoading(row: TRow): boolean {
        return this.tree?.isRowLoading(row) || false;
    }

    public get isExpandedFn(): ((row: Row<TRow>) => boolean) | undefined {
        return this.tree?.isExpanded;
    }

    public setDataSource(
        dataSource: TDatasourceConfig<TRow> | undefined,
        onChange: () => void
    ) {
        switch (dataSource?.type) {
            case 'serverTree': {
                this.tree = new ServerTree(dataSource, onChange);
                break;
            }
            case 'tree': {
                this.tree = new Tree(dataSource);
                break;
            }
            default: {
                break;
            }
        }
    }

    public canExpand(row: TRow): boolean {
        return this.tree?.canExpand(row) || false;
    }

    public getChildren(row: TRow): TRow[] {
        return this.tree?.getChildren(row) || [];
    }

    public async toggleExpand(row: Row<TRow>) {
        if (!row.getIsExpanded()) {
            await this.loadChildren(row.original);
        }

        this.tree?.setExpanded(row.original, !row.getIsExpanded());
        row.toggleExpanded();
    }

    public async loadChildren(row: TRow): Promise<TRow[]> {
        if (!this.tree?.canExpand(row) || this.isRowLoading(row)) {
            return [];
        }

        return await this.tree.loadChildren(row);
    }
}
