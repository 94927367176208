import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { BaseDTO, HierarchicalData } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseDTO)
export class EntityInsightItem extends BaseDTO {
    @deserialize public $id?: string;
    @deserialize public InsightType?: string;
    @deserialize public Count?: number;
    @deserializeAs(HierarchicalData) public HddData?: HierarchicalData;
}
