import * as moment from 'moment';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityPanelTool } from '../entity-panels/entity-panels.types';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';

export class ActivityLogDataOptions {
    public static areSame(
        a: ActivityLogDataOptions,
        b: ActivityLogDataOptions
    ) {
        return (
            (!a && !b) ||
            (a &&
                b &&
                a.dataReferenceId == b.dataReferenceId &&
                a.dataTypeName == b.dataTypeName &&
                SpaceIdentifier.areSame(a.spaceIdr, b.spaceIdr) &&
                a.userId == b.userId)
        );
    }

    constructor(
        public dataReferenceId?: string,
        public dataTypeName?: string,
        public spaceIdr?: ISpaceIdentifier,
        public userId?: string
    ) {}
}

export class ActivityLogEntryElement {
    constructor(
        public entry?: ActivityLogEntry,
        public isGroupHeader?: boolean,
        public entries?: ActivityLogEntry[]
    ) {}

    public get CreationTimeFromNow() {
        return moment(this.entry.CreationTime).fromNow();
    }
}

export interface IActivityLogItemTranslateParams {
    userFullName: string;
    parentDataTypeName: string;
    parentDataName: string;
    childDataTypeName: string;
    childDataName: string;
    propertyName: string;
    useTranslateValues: boolean;
    entityLinkName: string;
    oldValue?: string;
    newValue?: string;
    teamName?: string;
}

export interface IActivityLogNavLinkData {
    hdata?: HierarchicalData;
    childHData?: HierarchicalData;
    panelTool?: EntityPanelTool;
}
