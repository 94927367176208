import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Directive({
    selector: '[dxyFileDrop]',
    standalone: true,
})
export class FileDropDirective {
    @Input() droppedFiles: File[] = [];
    @Output() droppedFilesChange = new EventEmitter<File[]>();

    @HostListener('dragenter', ['$event']) onDragEnter(e: DragEvent) {
        this.onEvent(e);
    }
    @HostListener('dragover', ['$event']) onDragOver(e: DragEvent) {
        this.onEvent(e);
    }
    @HostListener('drop', ['$event']) onDrop(e: DragEvent) {
        this.onEvent(e);
        const fileList = e.dataTransfer?.files;
        const files = fileList && Array.from(fileList);
        this.droppedFiles = files ?? [];
        this.droppedFilesChange?.emit(files);
    }

    private onEvent(e: Event) {
        e.stopPropagation();
        e.preventDefault();
    }
}
