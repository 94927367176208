import { DgModuleName } from '../dg-module-name';
import catalogDgModule from './catalog-dg-module';
import glossaryDgModule from './glossary-dg-module';
import mainSearchDgModule from './main-search-dg-module';
import processingDgModule from './processing-dg-module';
import usageDgModule from './usage-dg-module';
import diagramDgModule from './diagram-dg-module';

export const allModules = [
    glossaryDgModule,
    catalogDgModule,
    processingDgModule,
    usageDgModule,
    mainSearchDgModule,
    diagramDgModule,
];

export const allModuleNames = allModules.map((m) => m.name);

export function getModuleDefinition(moduleName: DgModuleName | string) {
    return allModules.find((m) => m.name == moduleName) ?? mainSearchDgModule;
}
