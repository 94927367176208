import { NgModule } from '@angular/core';
import { COMMENTARY_MODAL_SERVICE_TOKEN } from '@datagalaxy/webclient/comment/feature';
import { CommentaryService } from './commentary.service';

@NgModule({
    providers: [
        {
            provide: COMMENTARY_MODAL_SERVICE_TOKEN,
            useClass: CommentaryService,
        },
    ],
})
export class DxyCommentaryModule {}
