import { INumberFilterData, NumberFilterOperator } from './number-filter.types';

export class NumberFilterUtils {
    public static isValueLessOperator(operator: NumberFilterOperator) {
        return [
            NumberFilterOperator.FieldHasValue,
            NumberFilterOperator.FieldIsEmpty,
        ].includes(operator);
    }

    public static isResolved(filter: INumberFilterData) {
        if (!filter) {
            return false;
        }
        return (
            NumberFilterUtils.isValueLessOperator(filter.operator) ||
            filter.min != null ||
            filter.max != null
        );
    }

    public static getOperatorTranslateKey(operator: NumberFilterOperator) {
        if (operator == undefined) {
            return;
        }
        const operatorName = NumberFilterOperator[operator];
        return `CoreUI.Filter.Operators.Number.${operatorName}`;
    }
}
