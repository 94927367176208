<!-- File input, triggered on click and hidden, used for file upload behavior -->
<input
    #inputRef
    [accept]="acceptedFormat"
    type="file"
    class="hidden-input"
    [disabled]="disabled"
    [multiple]="allowsMultiple"
    dxyInputFileChange
    (filesChange)="onFilesChange($event)"
/>

<div
    class="files-container"
    dxyFileDrop
    [(droppedFiles)]="value"
    (droppedFilesChange)="onFilesChange($event)"
>
    <span class="glyph-upload" [class.fail]="errorState"></span>

    <p *ngIf="!hasFiles" class="text-placeholder" translate>
        UI.Attribute.FileUpload.SelectFile
    </p>
    <div *ngIf="hasFiles" class="files-list">
        <div *ngFor="let file of value" class="file-container">
            <span class="file-name">{{ file.name }}</span>
            <span
                (click)="onRemoveFileClick($event, file)"
                class="glyph-close-circle-filled"
            ></span>
        </div>
    </div>
</div>
