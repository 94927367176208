import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    GenerateScriptCaseOption,
    GenerateScriptItem,
    GenerateScriptLineEndingOption,
    GenerateScriptQuoteOption,
    GenerateScriptResultItem,
} from './script';
import {
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';

@inheritSerialization(BaseServiceParameter)
export class GenerateScriptParameter extends BaseServiceParameter {
    @serializeAs(GenerateScriptItem) public Items: GenerateScriptItem[] = [];
    @serialize public DropBeforeCreate?: boolean;
    @serialize public PrefixWithSchemaName?: boolean;
    @serialize public DataTypeMappingReferenceId?: string;
    @serializeAs(GenerateScriptQuoteOption)
    public QuoteOption?: GenerateScriptQuoteOption;
    @serializeAs(GenerateScriptCaseOption)
    public CaseOption?: GenerateScriptCaseOption;
    @serializeAs(GenerateScriptLineEndingOption)
    public LineEndingOption?: GenerateScriptLineEndingOption;
}

@inheritSerialization(BaseServiceResult)
export class GenerateScriptResult extends BaseServiceResult {
    @deserializeAs(GenerateScriptResultItem)
    public Items: GenerateScriptResultItem[] = [];
}
