import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { PrimaryKey } from './primary-key';
import { Ref } from '../ref';

@inheritSerialization(Ref)
export class PrimaryKeyRef extends Ref<PrimaryKey> {
    static fromId(id: string) {
        return new PrimaryKeyRef(id);
    }

    constructor(id?: string) {
        super(ServerType.PrimaryKey, id);
    }
}
