import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { BaseMessage } from '@datagalaxy/data-access';
import { UserNotification } from '@datagalaxy/webclient/notification/data-access';

export enum NotificationMessageCode {
    Dynamic,
    WelcomeUser,
    UserLoggedIn,
    UserLoggedOut,
    ImportStarted,
    ImportEnded,
    CopyStarted,
    CopyEnded,
    GenerateGlossaryDataEnded,
    UserNotification,
    UserListChange,
    SecurityRightsChange,
}

@inheritSerialization(BaseMessage)
export class NotificationMessage extends BaseMessage {
    @deserialize public SourceConnectionId: string;
    @deserialize public SourceSystemId: string;
    @deserialize public SourceUserId: string;
    @deserialize public SourceUserFullName: string;
    @deserialize public IsSuccess: boolean;
    @deserializeAs(UserNotification)
    public NotificationObject: UserNotification;

    public get ServerType() {
        return ServerType.NotificationMessage;
    }

    constructor() {
        super();
    }
}
