import { DateTimeUtil } from '@datagalaxy/core-util';
import { Moment } from 'moment';

/** Synchronized ISO-formatted string and Moment getters and setters */
export class IsoStringMomentAdapter {
    /** Instanciates an date container object having two synchronized properties: *isoString* and *moment* */
    constructor() {}

    /** The date value as an ISO-formatted string */
    public get isoString() {
        return this._isoString;
    }
    public set isoString(value: string) {
        this._isoString = value;
        this._moment = DateTimeUtil.getFromIso(value);
    }

    /** The date value as a moment object */
    public get moment() {
        return this._moment;
    }
    public set moment(value: Moment) {
        this._moment = value;
        this._isoString = value?.isValid() ? value.toISOString() : undefined;
    }

    private _isoString: string;
    private _moment: Moment;
}
