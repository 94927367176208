import { FocusMonitor } from '@angular/cdk/a11y';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import {
    MatLegacyRadioChange as MatRadioChange,
    MatLegacyRadioGroup as MatRadioGroup,
    MatLegacyRadioModule,
} from '@angular/material/legacy-radio';
import { IRadioOption } from '../..';
import { DxyBaseMatFormFieldControl } from '../../../base/DxyBaseMatFormFieldControl';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { NgFor, NgClass, NgTemplateOutlet, NgIf } from '@angular/common';

@Component({
    selector: 'dxy-radio-field-control',
    templateUrl: './radio-field-control.component.html',
    styleUrls: ['./radio-field-control.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyRadioFieldControlComponent,
        },
    ],
    standalone: true,
    imports: [
        MatLegacyRadioModule,
        NgFor,
        DxyDataTestIdDirective,
        MatLegacyTooltipModule,
        NgClass,
        NgTemplateOutlet,
        NgIf,
        TranslateModule,
    ],
})
export class DxyRadioFieldControlComponent<T>
    extends DxyBaseMatFormFieldControl<T>
    implements OnChanges, AfterViewInit
{
    @Input() options?: IRadioOption<T>[];
    @Output() valueChange = new EventEmitter<T>();

    @ViewChild('matRadioGroup') private matRadioGroup: MatRadioGroup;

    constructor(
        @Optional() @Self() ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef,
        ngZone: NgZone
    ) {
        super(
            'dxy-radio-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone
        );
    }
    ngAfterViewInit() {
        this.initRadioValue();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'options', () => this.initRadioValue(), true);
    }
    public onRadioChange(change: MatRadioChange) {
        this.value = change.value;
    }
    /** mat-radio-group not focusable */
    public focus() {}

    private initRadioValue() {
        if (!this.matRadioGroup) {
            return;
        }
        this.matRadioGroup.value = this.value;
    }
}
