import {
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { DashboardDto } from './dashboard';

export enum UpdateDashboardAction {
    Unknown = 0,
    UpdateProperties = 1,
    SetDefault = 2,
    UnsetDefault = 3,
    SetConfig = 4,
}
SerializableEnumeration(UpdateDashboardAction);

@inheritSerialization(BaseServiceParameter)
export class GetDashboardsParameter extends BaseServiceParameter {
    @serialize public IncludeConfig?: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class GetDashboardParameter extends BaseServiceParameter {
    @serialize public DashboardId!: number;
    @serialize public IncludeConfig!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class CreateDashboardParameter extends BaseServiceParameter {
    @serialize public DisplayName?: string;
    @serialize public Description?: string;
    @serialize public Config?: string;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteDashboardParameter extends BaseServiceParameter {
    @serialize public DashboardId?: number;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateDashboardParameter extends BaseServiceParameter {
    @serialize public DisplayName?: string;
    @serialize public Description?: string;
    @serialize public IsPrivate?: boolean;
    @serialize public IsDefaultValue?: boolean;
    @serialize public Config?: string;
    @serialize public DashboardId?: number;
    @serializeAs(UpdateDashboardAction) public Action?: UpdateDashboardAction;
}

@inheritSerialization(BaseServiceResult)
export class DashboardListResult extends BaseServiceResult {
    @deserializeAs(DashboardDto) public DashboardDtoList!: DashboardDto[];
}

@inheritSerialization(BaseServiceResult)
export class DashboardResult extends BaseServiceResult {
    @deserializeAs(DashboardDto) public Dashboard!: DashboardDto;
}
