<div
    *ngIf="!hasSpaceIcon"
    class="workspace-icon dg5-palette bg"
    [ngClass]="trigramColorClass"
>
    {{ trigram }}
</div>
<img
    *ngIf="hasSpaceIcon"
    [src]="spaceIconUrl"
    class="workspace-icon"
    alt="workspace-icon"
/>
