export class AttributeMatch {
    /** name of the matched column name in the csv file */
    public csvFieldName: string;

    /** the icon's css class */
    public glyphClass: string;

    /** name of the expected column name */
    public expectedTitle: string;

    constructor(
        public attributeName: string,
        public attributeTitle: string,
        public isMandatory: boolean,
        public description: string = null,
        public isSystemUser = false,
        public isCDP = false
    ) {
        this.expectedTitle = attributeTitle;
    }

    public get isMatched() {
        return !!(this.csvFieldName && this.csvFieldName.length);
    }
}
