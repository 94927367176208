<div class="content">
    <ng-content></ng-content>
</div>
<div
    *ngIf="!hideActions"
    class="action-list"
    [class.active]="isBurgerMenuOpened"
    [class.fix-width]="fixButtonPositions"
>
    <ng-container *ngFor="let action of actions">
        <dxy-action-list-item
            *ngIf="!isHidden(action)"
            [action]="action"
            [data]="data"
            [actionsCaptureMouseDown]="actionsCaptureMouseDown"
            (openClose)="onBurgerMenuOpenClose($event)"
            (onLogFunctional)="onLogFunctional.emit($event)"
        ></dxy-action-list-item>
    </ng-container>
</div>
