import * as moment from 'moment';
import { DxyNewCommentaryModalComponent } from './dxy-new-commentary-modal/dxy-new-commentary-modal.component';
import { Injectable } from '@angular/core';
import {
    EntityCommentaryDTO,
    IEntityIdentifier,
} from '@datagalaxy/dg-object-model';
import {
    IRealTimeDeleteCommentaryHandler,
    IRealTimeUpdateCommentaryHandler,
    RealTimeCommService,
} from '../services/realTimeComm.service';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { AppDataService } from '../services/app-data.service';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import {
    CommentApiService,
    CreateEntityCommentParameter,
    DeleteEntityCommentsParameter,
    GetEntityCommentsParameter,
    UpdateEntityCommentParameter,
} from '@datagalaxy/webclient/comment/data-access';
import { FunctionalLogService } from '@datagalaxy/shared/monitoring/data-access';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import {
    CommentaryModalService,
    INewCommentaryModalResolve,
    INewCommentaryModalResult,
} from '@datagalaxy/webclient/comment/feature';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';

@Injectable({ providedIn: 'root' })
export class CommentaryService implements CommentaryModalService {
    constructor(
        private appDataService: AppDataService,
        private realTimeCommService: RealTimeCommService,
        private commentApiService: CommentApiService,
        private dxyModalService: DxyModalService,
        private entityEventService: EntityEventService,
        private functionalLogService: FunctionalLogService
    ) {}

    public subscribeUpdateCommentary(
        handler: IRealTimeUpdateCommentaryHandler
    ) {
        return this.realTimeCommService.subscribeUpdateCommentary(handler);
    }

    public subscribeDeleteCommentary(
        handler: IRealTimeDeleteCommentaryHandler
    ) {
        return this.realTimeCommService.subscribeDeleteCommentary(handler);
    }

    public isCurrentUserCommentaryOwner(commentary: EntityCommentaryDTO) {
        return commentary.CreationUserId === this.appDataService.currentUserId;
    }

    public async createCommentary(entityData: IEntityIdentifier, text: string) {
        const featureCode = EntityFunctionalLogService.getFeatureCode(
            entityData,
            'SOCIAL_COMMENT',
            'C'
        );
        this.functionalLogService.parseAndLog(featureCode);
        const createParam = new CreateEntityCommentParameter(
            entityData.ReferenceId,
            entityData.ServerType,
            entityData.VersionId,
            text
        );
        const result = await this.commentApiService.createEntityComment(
            createParam
        );
        return result.CreatedCommentary;
    }

    public async loadCommentaries(entityData: IEntityIdentifier) {
        const getParameter = new GetEntityCommentsParameter(
            entityData.ReferenceId,
            entityData.ServerType,
            entityData.VersionId,
            false
        );
        const result = await this.commentApiService.getEntityComments(
            getParameter
        );
        return result.Commentaries;
    }

    public async deleteCommentary(
        entityData: IEntityIdentifier,
        commentaryId: string
    ) {
        const featureCode = EntityFunctionalLogService.getFeatureCode(
            entityData,
            'SOCIAL_COMMENT',
            'D'
        );
        const confirmed = await this.dxyModalService.confirmDeleteObject(
            ServerConstants.TypeName.ObjectCommentary,
            { featureCode }
        );
        if (!confirmed) {
            return false;
        }
        const deleteParameter = new DeleteEntityCommentsParameter(
            entityData.ReferenceId,
            entityData.ServerType,
            entityData.VersionId,
            commentaryId
        );
        await this.commentApiService.deleteEntityComments(deleteParameter);
    }

    public async updateCommentary(
        entityData: IEntityIdentifier,
        commentaryId: string,
        updatedCommentaryContent: string
    ) {
        const featureCode = EntityFunctionalLogService.getFeatureCode(
            entityData,
            'SOCIAL_COMMENT',
            'U'
        );
        this.functionalLogService.parseAndLog(featureCode);
        const createParam = new UpdateEntityCommentParameter(
            entityData.ReferenceId,
            entityData.ServerType,
            entityData.VersionId,
            commentaryId,
            updatedCommentaryContent
        );
        const result = await this.commentApiService.updateEntityComment(
            createParam
        );
        return result.UpdatedCommentary;
    }

    public async confirmCancelEdit() {
        return this.dxyModalService.confirmCancelEdit(
            ServerConstants.TypeName.ObjectCommentary
        );
    }

    public getCreationDate(commentary: EntityCommentaryDTO) {
        return moment(commentary.CreationTime).fromNow();
    }

    public async openCommentaryNewModal(params: INewCommentaryModalResolve) {
        const result = await this.dxyModalService.open<
            DxyNewCommentaryModalComponent,
            INewCommentaryModalResolve,
            INewCommentaryModalResult
        >({
            componentType: DxyNewCommentaryModalComponent,
            data: params,
        });
        if (result?.createdCommentaries?.length) {
            this.entityEventService.notifyCommentaryCreation(
                result.createdCommentaries[0]
            );
        }
    }
}
