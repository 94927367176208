import { CommentariesDeletedEvent } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getEntityActivityEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

/**
 * ## Role
 * Adapter to get CommentariesDeletedEvent translation inputs
 */
@registerTypedEventTranslationAdapter([CommentariesDeletedEvent])
export class CommentariesDeletedEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: CommentariesDeletedEvent) {
        super(event);
    }

    getTranslationInputs(): IEventTranslationInputs {
        return getEntityActivityEventTranslationInputs(this.event);
    }
}
