import { BurgerMenuActionProviderBase, IActionDef } from '@datagalaxy/core-ui';
import { DataQualityService } from '../data-quality.service';
import { RuleRow } from '../data-quality.types';

/** OmniGrid subcomponent providing actions for the context-menu in saved-connections-grid */
export class RuleBurgerMenuActionProvider extends BurgerMenuActionProviderBase<
    RuleGridBurgerMenuAction,
    RuleRow,
    void
> {
    private readonly btnEdit: IRuleGridActionDef = {
        actionId: RuleGridBurgerMenuAction.btnEdit,
        isAvailable: () => true, // TODO: access rights?
        glyphClass: 'glyph-edit',
        execute: (rule) => this.editRule(rule),
        dataTestId: 'rule-preview',
    };

    private async editRule(item: RuleRow) {
        await this.dataQualityService.openRuleEditionModal(item);
    }

    private readonly btnDelete: IRuleGridActionDef = {
        actionId: RuleGridBurgerMenuAction.btnDelete,
        isAvailable: () => true, // TODO: access rights?
        glyphClass: 'glyph-delete',
        execute: (rule) => this.deleteRule(rule),
        dataTestId: 'rule-delete',
    };

    private async deleteRule(rule: RuleRow) {
        await this.dataQualityService.openRuleDeletionModal(rule);
    }

    constructor(private dataQualityService: DataQualityService) {
        super();
        this.actionDefs = [this.btnEdit, this.btnDelete];
    }

    protected getTranslateKey(actionId: RuleGridBurgerMenuAction) {
        return `UI.Global.${RuleGridBurgerMenuAction[actionId]}`;
    }
}

enum RuleGridBurgerMenuAction {
    btnEdit,
    btnDelete,
}

interface IRuleGridActionDef
    extends IActionDef<RuleGridBurgerMenuAction, RuleRow, void> {}
