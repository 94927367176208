import { FieldType } from '../../types/enums/field-type.enum';
import { BigQueryImportType } from '../../types/enums/big-query-import-type.enum';

export const googleBigQueryConfig = [
    {
        payloadField: 'project-import-type',
        formField: 'projectImportType',
        tradKey: 'importType',
        type: FieldType.select,
        translate: true,
        payloadValue: BigQueryImportType.MONOPROJECT,
        selectValues: ['monoProject', 'multiProject'],
        getDisplayValueTradKey: (value: BigQueryImportType) => value,
        dependencies: [
            {
                field: {
                    payloadField: 'project-id',
                    formField: 'projectId',
                    formMandatory: true,
                    type: FieldType.text,
                },
                show: (value: BigQueryImportType) =>
                    value === BigQueryImportType.MONOPROJECT,
            },
            {
                field: {
                    payloadField: 'project-ids',
                    formField: 'projectIds',
                    formMandatory: false,
                    payloadValue: [],
                    type: FieldType.list,
                    isFilter: true,
                    listFieldTradKeys: {
                        gridTitle:
                            'UI.Connector.Wizard.Step3.ProjectIds.Grid.title',
                        gridAddBtn:
                            'UI.Connector.Wizard.Step3.ProjectIds.Grid.addBtn',
                        modalTitle:
                            'UI.Connector.Wizard.Step3.ProjectIds.Modal.title',
                        modalLabel:
                            'UI.Connector.Wizard.Step3.ProjectIds.Modal.value',
                    },
                },
                show: (value: BigQueryImportType) =>
                    value === BigQueryImportType.MULTIPROJECT,
            },
            {
                field: {
                    formMandatory: false,
                    payloadField: 'dataset',
                    formField: 'dataset',
                    payloadValue: [],
                    type: FieldType.list,
                    isFilter: true,
                    listFieldTradKeys: {
                        gridTitle:
                            'UI.Connector.Wizard.Step3.DatasetIds.Grid.title',
                        gridAddBtn:
                            'UI.Connector.Wizard.Step3.DatasetIds.Grid.addBtn',
                        modalTitle:
                            'UI.Connector.Wizard.Step3.DatasetIds.Modal.title',
                        modalLabel:
                            'UI.Connector.Wizard.Step3.DatasetIds.Modal.value',
                    },
                },
                show: (value: BigQueryImportType) =>
                    value === BigQueryImportType.MONOPROJECT,
            },
            {
                field: {
                    payloadField: 'table-location',
                    formField: 'tableLocation',
                    formMandatory: false,
                    type: FieldType.text,
                },
                show: (value: BigQueryImportType) =>
                    value === BigQueryImportType.MONOPROJECT ||
                    value === BigQueryImportType.MULTIPROJECT,
            },
            {
                field: {
                    payloadField: 'password',
                    formField: 'password',
                    tradKey: 'privateKey',
                    formMandatory: true,
                    isPassword: true,
                    isDownloadField: true,
                },
                show: (value: BigQueryImportType) =>
                    value === BigQueryImportType.MONOPROJECT ||
                    value === BigQueryImportType.MULTIPROJECT,
            },
        ],
    },
    { payloadField: 'database-technology', payloadValue: 'googlebigquery' },
    { payloadField: 'type', payloadValue: 'MODEL' },
    {
        payloadField: 'get-lineage',
        formField: 'getAutomaticLineage',
        formMandatory: false,
        type: FieldType.boolean,
    },
];
