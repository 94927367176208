import {
    AttributeUpdatesTranslation,
    IDgEventNavLink,
} from '@datagalaxy/translation-common';
import { IDgEventNavLinkCell } from './dg-event-translation.type';
import { Injectable } from '@angular/core';
import { MsTeamsActivateIntegrationButtonComponent } from './nav-links/ms-teams-activate-integration-button/ms-teams-activate-integration-button.component';
import { MsTeamsConnectMeButtonComponent } from './nav-links/ms-teams-connect-me-button/ms-teams-connect-me-button.component';
import { CampaignNavLinkComponent } from './nav-links/campaign-nav-link/campaign-nav-link.component';
import { CampaignBriefDto } from '@datagalaxy/webclient/campaign/data-access';
import { StateName } from '@datagalaxy/webclient/routing';
import {
    INavLinkCellParams,
    IRouteNavLinkCellParams,
} from './nav-links/nav-link.types';
import {
    CampaignEventBase,
    EntityTaskDTO,
    HierarchicalData,
    ICampaignIdentifier,
} from '@datagalaxy/dg-object-model';
import { NotificationButtonComponent } from './nav-links/notification-button/notification-button.component';
import { INotificationButtonParams } from './nav-links/notification-button/INotificationButtonParams';
import { NotificationPanelUiService } from '../notifications/notification-panel-ui.service';
import { SuggestionPanelUiService } from '../suggestions/suggestion-panel-ui.service';
import { AttributesUpdatesComponent } from './nav-links/attributes-updates/attributes-updates.component';
import { TeamNavLinkComponent } from './nav-links/team-nav-link/team-nav-link.component';
import { TCellRenderer } from '@datagalaxy/core-ui/cell-components';
import { EntityNavLinkComponent } from './nav-links/entity-nav-link/entity-nav-link.component';
import { TaskNavLinkComponent } from './nav-links/task-nav-link/task-nav-link.component';
import { SlackConnectMeButtonComponent } from './nav-links/slack-connect-me-button/slack-connect-me-button.component';
import { SlackActivateIntegrationButtonComponent } from './nav-links/slack-activate-integration-button/slack-activate-integration-button.component';
import { NavLinkModuleComponent } from './nav-links/module/nav-link-module.component';
import { ChangeLanguageButtonComponent } from '@datagalaxy/webclient/multilingual/feature';

@Injectable({ providedIn: 'root' })
export class DgEventNavLinkCellAdapter {
    private navLinkConfigurations: INavLinkConfiguration[] = [
        {
            linkCssClasses: ['nav-link-teams-integration'],
            fullWidth: true,
            renderer: MsTeamsActivateIntegrationButtonComponent,
        },
        {
            linkCssClasses: ['nav-link-teams-connection'],
            fullWidth: true,
            renderer: MsTeamsConnectMeButtonComponent,
        },
        {
            linkCssClasses: ['nav-link-slack-integration'],
            fullWidth: true,
            renderer: SlackActivateIntegrationButtonComponent,
        },
        {
            linkCssClasses: ['nav-link-slack-connection'],
            fullWidth: true,
            renderer: SlackConnectMeButtonComponent,
        },
        {
            linkCssClasses: ['nav-link-campaign'],
            renderer: CampaignNavLinkComponent,
            getRendererParameters(cIdr: ICampaignIdentifier) {
                return {
                    value: cIdr,
                    routeStateName: StateName.CampaignDetailsInfos,
                } as IRouteNavLinkCellParams<CampaignBriefDto>;
            },
        },
        {
            linkCssClasses: ['nav-link-show-more'],
            fullWidth: true,
            renderer: CampaignNavLinkComponent,
            parametersType: CampaignEventBase,
            getRendererParameters(event: CampaignEventBase) {
                return {
                    value: {
                        Guid: event.CampaignGuid,
                        Name: event.CampaignName,
                        SpaceGuid: event.SpaceGuid,
                        VersionId: event.VersionId,
                    } as CampaignBriefDto,
                    hideOnNavigationDisabled: true,
                    showMoreStyle: true,
                    routeStateName: StateName.CampaignDetailsInfos,
                } as IRouteNavLinkCellParams<CampaignBriefDto>;
            },
        },
        {
            linkCssClasses: ['nav-link-suggestion-panel'],
            fullWidth: true,
            renderer: NotificationButtonComponent,
            getRendererParameters: () => {
                return {
                    value: {
                        buttonTextKey: 'UI.Suggestions.Panel.triggerButtonText',
                        iconGlyphClass: 'glyph-robot2',
                        callback: () => {
                            this.notificationPanelUiService.closePanel();
                            this.suggestionPanelUiService.openSuggestionPanel();
                        },
                    },
                } as INavLinkCellParams<INotificationButtonParams>;
            },
        },
        {
            linkCssClasses: ['nav-link-updates'],
            fullWidth: true,
            renderer: AttributesUpdatesComponent,
            parametersType: AttributeUpdatesTranslation,
            getRendererParameters: (updates: AttributeUpdatesTranslation) => {
                return {
                    value: updates,
                };
            },
        },
        {
            linkCssClasses: ['nav-link-task'],
            renderer: TaskNavLinkComponent,
            parametersType: EntityTaskDTO,
            getRendererParameters: (task) => {
                return {
                    value: task,
                };
            },
        },
        {
            linkCssClasses: ['nav-link-team'],
            renderer: TeamNavLinkComponent,
            parametersType: String, // Todo same type as translation common
            getRendererParameters: (teamGuid) => {
                return {
                    value: teamGuid,
                };
            },
        },
        {
            linkCssClasses: [
                'nav-link-entity',
                'nav-link-parent-entity',
                'nav-link-source-entity',
                'nav-link-target-entity',
            ],
            renderer: EntityNavLinkComponent,
            parametersType: HierarchicalData, // Todo same type as translation common
            getRendererParameters: (hd) => {
                return {
                    value: hd,
                };
            },
        },
        {
            linkCssClasses: ['nav-link-module'],
            renderer: NavLinkModuleComponent,
            getRendererParameters: (dgModule) => {
                return {
                    value: dgModule,
                };
            },
        },
        {
            linkCssClasses: ['nav-link-language'],
            fullWidth: true,
            renderer: ChangeLanguageButtonComponent,
            getRendererParameters: ({ languageCode }) => {
                return {
                    value: languageCode,
                };
            },
        },
    ];

    constructor(
        private notificationPanelUiService: NotificationPanelUiService,
        private suggestionPanelUiService: SuggestionPanelUiService
    ) {}

    public async adapt(navLink: IDgEventNavLink): Promise<IDgEventNavLinkCell> {
        const navLinkCellConfig = this.navLinkConfigurations.find(
            (n) =>
                n.linkCssClasses.includes(navLink.linkCssClass) &&
                this.havSameParametersType(n, navLink)
        );
        if (!navLinkCellConfig) {
            console.warn('Impossible to construct navigation link', {
                navLink,
            });
            return undefined;
        }
        return {
            linkCssClass: navLink.linkCssClass,
            renderer: navLinkCellConfig.renderer,
            fullWidth: navLinkCellConfig.fullWidth,
            param: navLinkCellConfig.getRendererParameters?.(
                navLink.parameters
            ),
        };
    }

    private havSameParametersType(
        n: INavLinkConfiguration,
        navLink: IDgEventNavLink<object>
    ): boolean {
        if (!n.parametersType) {
            return true;
        }
        return navLink.parameters instanceof n.parametersType;
    }
}

interface INavLinkConfiguration<
    TParameters = unknown,
    TRendererParameters = unknown
> {
    linkCssClasses: string[];
    fullWidth?: boolean;
    renderer: TCellRenderer;
    parametersType?: new () => TParameters;
    getRendererParameters?: (
        params: TParameters,
        panelTool?: string
    ) => TRendererParameters;
}
