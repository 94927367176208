export function getLanguageName(languageCode: string) {
    switch (languageCode) {
        case 'en':
            return 'English';
        case 'fr':
            return 'Français';
        case 'de':
            return 'Deutsch';
        case 'es':
            return 'Español';
        case 'nl':
            return 'Nederlands';
        case 'it':
            return 'Italiano';
        case 'pt':
            return 'Português';
        default:
            return '';
    }
}
