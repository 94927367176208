import { IXY, RectSide } from '@datagalaxy/core-2d-util';

export type TConnectorGeometry = ConnectorGeometry | IPlumbEdgeGeometry;

export enum ConnectorGeometryType {
    /** Jsplumb's OrthogonalConnectorGeometry */
    jspOrtho = 0,
    /** DataGalaxy's orthogonal connector geometry: ConnectorGeometry */
    ortho = 1,
}

export interface ConnectorGeometrySidePoint {
    side?: RectSide;
    /** [0, 1] along the side - Defaults to 0.5: middle */
    distance?: number;
}

export interface ConnectorGeometry {
    type: ConnectorGeometryType.ortho;
    /** points of the fixed part of the route (starts and ends with stub points, not endpoints) */
    points?: IXY[];
    /** side and distance along that side, of the source node's rectangle */
    src?: ConnectorGeometrySidePoint;
    /** side and distance along that side, of the target node's rectangle */
    tgt?: ConnectorGeometrySidePoint;
}

/* ox, oy:
    H A->B  s: 1, 0 t:-1, 0
    H B<-A  s:-1, 0 t: 1, 0
    V A->B  s: 0, 1 t: 0,-1
    V B<-A  s: 0,-1 t: 0, 1
*/
/** matches jsplumb's AnchorPlacement */
export interface IPlumbAnchorPlacement {
    /** in diagram coordinates (unzoomed, from canvas origin) */
    curX: number;
    /** in diagram coordinates */
    curY: number;
    /** normalized, relative to the node's rectangle width */
    x: number;
    /** normalized, relative to the node's rectangle height */
    y: number;
    /** AnchorOrientationHint */
    ox: -1 | 0 | 1;
    /** AnchorOrientationHint */
    oy: -1 | 0 | 1;
}

/** compatible with IPlumbOrthogonalConnectorGeometry */
export interface IPlumbEdgeGeometry {
    type?: ConnectorGeometryType.jspOrtho;
    segments?: IXY[];
    source?: IPlumbAnchorPlacement;
    target?: IPlumbAnchorPlacement;
}
