import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { IListOption, ListOptionUtil } from '../../IListOption';
import { TranslateService } from '@ngx-translate/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ButtonColor } from '@datagalaxy/ui/buttons';
import { DxyBurgerMenuComponent } from '../burger-menu/burger-menu.component';
import { NgIf } from '@angular/common';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Display a burger menu button with its menu items
 */
@Component({
    selector: 'dxy-burger-menu-button',
    templateUrl: 'burger-menu-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./burger-menu-button.component.scss'],
    standalone: true,
    imports: [
        DxyIconButtonDirective,
        MatLegacyMenuModule,
        MatLegacyTooltipModule,
        DxyDataTestIdDirective,
        NgIf,
        DxyBurgerMenuComponent,
    ],
})
export class DxyBurgerMenuButtonComponent<T> extends DxyBaseComponent {
    @Input() options: IListOption[];
    @Input() data?: T;
    @Input() tooltipKey: string;
    @Input() tooltipText: string;
    /** when true, the icon has the class *dg5-small-icon-button* */
    @Input() small: boolean;
    @Input() captureMouseDown: boolean;
    @Input() dataTestId: string;
    @Input() glyphClass: string;
    @Input() menuClass: string;
    @Input() buttonColor: ButtonColor = 'secondary';

    @Output() readonly onLogFunctional = new EventEmitter<IFunctionalEvent>();
    /** Emits when the menu panel is opened or closed, a value of *true* meaning opened */
    @Output() openClose = new EventEmitter<boolean>();

    public get hidden() {
        return (
            !this.options?.length ||
            this.options.every((option) =>
                ListOptionUtil.isHidden(option, this.data)
            )
        );
    }
    public get tooltip() {
        return (
            this.tooltipText ||
            (this.tooltipKey && this.translate.instant(this.tooltipKey))
        );
    }
    public get btnClass() {
        return this.glyphClass || 'glyph-splitter';
    }

    constructor(private translate: TranslateService) {
        super();
    }

    public onClickTrigger(event: MouseEvent) {
        event.stopPropagation();
    }

    public onMouseDown(event: Event) {
        if (!this.captureMouseDown) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
    }
}
