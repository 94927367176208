<svg viewBox="0 0 42 42">
    <circle
        class="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="#fff"
    ></circle>
    <circle
        class="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke-linecap="round"
        stroke="#0000ff"
        stroke-opacity="0.05"
        stroke-width="2"
    ></circle>
    <circle
        class="donut-segment"
        [ngClass]="getColorClass()"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke-linecap="round"
        stroke-width="2"
        [style.strokeDasharray]="strokeDasharray"
        [style.stroke-dashoffset]="0"
    ></circle>
    <text class="donut-text" font-size="8" x="50%" y="50%">
        {{ getTextPercentage() }}
    </text>
</svg>
