import { D3DragEvent, drag as d3drag } from 'd3-drag';
import { selectAll, Selection } from 'd3-selection';
import { Subject } from 'rxjs';
import {
    Dom2dUtil,
    IXY,
    IXYRectRO,
    MovePhase,
    Rect,
    TDomElement,
} from '@datagalaxy/core-2d-util';
import {
    D3Helper,
    ID3DragEvent,
    ID3MouseEvent,
    SD3ED,
    TD3Subject,
} from '../../../D3Helper';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { SelectionTool } from './selection.tool';
import { GraphSurfaceOptions } from '../../graph-surface.types';
import {
    ID3SelectionManagerParams,
    IGetEnclosedOptions,
} from './selection.types';
import { GraphManager } from '../../graph-manager';
import { ManagedItem } from '../../node/managed-item';
import { AreaSelector, ISelectionArea } from '../area-selection';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { KeyboardUtil } from '@datagalaxy/utils';

/** ## Role
 * Sub-component managing the selection of displayed items by mouse
 * ## Features
 * - drag rectangle area to select enclosed items
 * - area selection activation by keyboard (shift, ctrl or alt) or by mode (select/pan)
 * */
export class DragSelectionTool<
    E extends TDomElement = TDomElement,
    NodeData = unknown,
    S = unknown,
    EdgeData = unknown
> extends BaseGraphicalManager {
    private static readonly verbose = false;
    public get isSelectMode() {
        return this.selectionTool.isSelectMode;
    }
    public get areaRect$() {
        return this.areaRect.asObservable();
    }

    private params: ID3SelectionManagerParams<E, NodeData>;
    private areaSelector: AreaSelector;
    private areaSelectionCandidates: SD3ED<E, NodeData>;
    private areaSelectingNodes: SD3ED<E, NodeData>;
    private dragBehaviour = d3drag<HTMLElement, NodeData, TD3Subject>();
    private readonly areaRect = new Subject<DOMRect>();
    private get selectingClass() {
        return this.params?.area?.selectingClass ?? 'sm-selecting';
    }

    constructor(
        selection: Selection<HTMLDivElement, unknown, HTMLElement, TD3Subject>,
        private selectionTool: SelectionTool,
        private zoomAdapter: ZoomedViewAdapter,
        private graph: GraphManager<NodeData, EdgeData>,
        private options?: GraphSurfaceOptions<E, NodeData, S>
    ) {
        super();
        super.initInternal(options, DragSelectionTool.verbose);
        this.init(selection);
    }

    private init(
        selection: Selection<HTMLElement, unknown, HTMLElement, TD3Subject>
    ) {
        super.initInternal(this.options, DragSelectionTool.verbose);
        this.params = {
            areaContainer: selection,
            getEnclosedElements: (items, areaRect, opt) =>
                this.getEnclosedElements(items, areaRect, opt),
            ...this.options.selection,
            debug: this.options.debug,
            verbose: this.options.verbose,
        };
        this.areaSelector?.clear();
        this.areaSelector = new AreaSelector({
            onAreaChange: (change) => this.onAreaChanged(change),
            areaContainer: selection,
            ...this.options.selection?.area,
            debug: this.debug,
            verbose: this.verbose,
        });

        selection?.call(this.dragBehaviour);

        /**
         * Ignores mousedown events on secondary buttons, since those buttons
         * are typically intended for other purposes, such as the context menu.
         * And ignore events when disabled
         */
        const filterEvents = (e) => !e.button && this.isSelectMode;

        this.dragBehaviour
            .filter(filterEvents)
            .on('start', (event: D3DragEvent<E, NodeData, TD3Subject>) =>
                this.onDragStart(event)
            )
            .on('drag', (event: D3DragEvent<E, NodeData, TD3Subject>) =>
                this.onDrag(event)
            )
            .on('end', (event: D3DragEvent<E, NodeData, TD3Subject>) =>
                this.onDragEnd(event)
            );
    }

    public clear(noEmit?: boolean) {
        this.log('clear', noEmit);
        this.clearSelectArea();
        this.params?.onSelectionCleared?.();
    }

    public clearSelectArea() {
        this.areaSelector?.clear();
    }

    public isAreaMove(event: ID3MouseEvent) {
        if (!this.params?.getEnclosedElements) {
            return false;
        }
        const areaKbdKeys = this.params.area?.kbdKeys;
        return (
            (!areaKbdKeys && this.isSelectMode) ||
            KeyboardUtil.isSameAltShiftCtrl(
                D3Helper.getKbdAltShiftCtrl(event),
                areaKbdKeys
            )
        );
    }

    public updateAreaOnMove(
        phase: MovePhase,
        zoomEvent: ID3MouseEvent,
        point: IXY
    ) {
        if (this.isAreaMove(zoomEvent)) {
            this.areaSelector.update(phase, point, zoomEvent.sourceEvent);
        }
    }

    private allItemsAsD3() {
        const nodes = this.graph.nodes;
        return selectAll(nodes.map((node) => node.el)).data(nodes);
    }

    private getEnclosedElements(
        items: SD3ED<E, NodeData>,
        areaRect: IXYRectRO,
        opt?: IGetEnclosedOptions
    ) {
        const isContained = Rect.makeIsContained(areaRect, opt?.strict);
        const convert = this.zoomAdapter.getRectConverter(opt).rectSelf;
        return items.filter(function () {
            return isContained(convert(Dom2dUtil.getRect(this, opt)));
        });
    }

    private onDragStart(e: ID3DragEvent<E, NodeData, TD3Subject>) {
        this.updateAreaOnMove(MovePhase.start, e, { x: e.x, y: e.y });
    }

    private onDrag(e: ID3DragEvent<E, NodeData, TD3Subject>) {
        this.updateAreaOnMove(MovePhase.move, e, { x: e.x, y: e.y });
    }

    private onDragEnd(e: ID3DragEvent<E, NodeData, TD3Subject>) {
        this.updateAreaOnMove(MovePhase.end, e, { x: e.x, y: e.y });
    }

    private onAreaChanged(change: ISelectionArea) {
        const p = this.params;
        if (!p) {
            return;
        }
        const { areaRect, phase } = change;
        if (phase == MovePhase.start) {
            const candidates = this.allItemsAsD3() as SD3ED<E, NodeData>;
            this.areaSelectionCandidates = candidates?.empty()
                ? null
                : candidates;
            this.verbose && this.log('onAreaChanged-start', change, candidates);
        } else {
            const candidates = this.areaSelectionCandidates;
            this.verbose &&
                this.log(
                    'onAreaChanged',
                    MovePhase[phase],
                    areaRect,
                    change,
                    candidates
                );
            if (areaRect && candidates) {
                const className = this.selectingClass;
                this.areaSelectingNodes?.classed(className, false);
                const enclosedElements = p.getEnclosedElements?.(
                    candidates,
                    areaRect,
                    p.area?.getEnclosedOptions
                );
                this.areaSelectingNodes = p.getSelectedNodes
                    ? p.getSelectedNodes(enclosedElements)
                    : enclosedElements;
                this.areaSelectingNodes?.classed(className, true);
            }
            if (phase == MovePhase.end && this.areaSelectingNodes) {
                this.clear();
                this.areaSelectionCandidates = null;
                this.selectionTool.select(
                    ...(this.areaSelectingNodes.data() as ManagedItem<NodeData>[])
                );
                this.selectionTool.setSelectMode(false);
            }
            if (areaRect && phase == MovePhase.end) {
                this.areaRect.next(
                    this.zoomAdapter
                        .getRectConverter({ unzoom: true })
                        .rectTo(areaRect)
                );
                const items =
                    this.areaSelectingNodes?.data() as ManagedItem<NodeData>[];
                this.selectionTool.select(...items);
            }
        }
    }
}
