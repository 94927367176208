import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from '@angular/core';
import { AttributeTranslationBaseInput } from '../attribute-translation-base-input';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { AttributeTranslationButtonComponent } from '../../translations';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-attribute-text-translation-input',
    templateUrl: './attribute-text-translation-input.component.html',
    styleUrls: ['./attribute-text-translation-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        NgIf,
        AttributeTranslationButtonComponent,
        DxyIconButtonDirective,
        AsyncPipe,
    ],
})
export class AttributeTextTranslationInputComponent extends AttributeTranslationBaseInput {
    protected textMaxLength =
        ServerConstants.AttributeConstants.AttributeTextLimit;

    constructor() {
        super();
    }
}
