<dxy-grid
    *ngIf="hasRequests"
    [items]="teamRequests"
    [config]="gridConfig"
    [columns]="columns"
></dxy-grid>

<div *ngIf="!hasRequests" class="empty-search-result">
    <img
        src="/images/placeholders/team-requests.svg"
        alt="no membership request"
    />
    <span class="empty-search-result-text" translate
        >UI.Teams.Requests.emptyLbl</span
    >
</div>
