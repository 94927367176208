import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    GetRecentEntitiesParameter,
    GetRecentEntitiesResult,
    GetRecentSearchesParameter,
    GetRecentSearchesResult,
    SaveRecentSearchFiltersParameter,
} from './recent';
import {
    GetFilteredEntitiesParameter,
    GetFilteredEntitiesResult,
} from './search';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchApiService {
    // Temporarily using a Subject to emit the searched entities to be able to translate entities after loading
    // Will be useless and removed once the new search route is done
    private searchedEntitiesSubject = new Subject<IEntityIdentifier[]>();
    public searchedEntities$ = this.searchedEntitiesSubject.asObservable();

    constructor(private backendApiService: BackendApiService) {}

    public async getLastSearches(param: GetRecentSearchesParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/GetRecentSearches',
            param,
            GetRecentSearchesResult
        );
    }

    public async saveLastSearch(param: SaveRecentSearchFiltersParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/SaveRecentSearch',
            param,
            BaseServiceResult
        );
    }

    public async clearLastSearches(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/DeleteRecentSearches',
            param,
            BaseServiceResult
        );
    }

    public async getRecentEntities(param: GetRecentEntitiesParameter) {
        const result = await this.backendApiService.postPromise(
            'SearchHistory/GetRecentEntities',
            param,
            GetRecentEntitiesResult
        );
        this.emitSearchedEntities(result.Entities);
        return result;
    }

    public async getFilteredEntities(param: GetFilteredEntitiesParameter) {
        const result = await this.backendApiService.postPromise(
            'Entity/GetFilteredEntities',
            param,
            GetFilteredEntitiesResult
        );
        this.emitSearchedEntities(result.Entities);
        return result;
    }

    private emitSearchedEntities(entities: IEntityIdentifier[]) {
        this.searchedEntitiesSubject.next(
            entities.map((e) => ({
                ReferenceId: e.ReferenceId,
                ServerType: e.ServerType,
                VersionId: e.VersionId,
                entityType: e.entityType,
            }))
        );
    }
}
