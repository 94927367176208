import { ServerType, TypeLinkDataInfo } from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export interface IEntityBulkEditModalInputs {
    entityDataList: EntityItem[];
    entityServerType: ServerType;
    entityAttributes: AttributeMetaInfo[];
    availableLinkTypes: TypeLinkDataInfo[];
    hasWriteAccess: boolean;
    noWriteAccessEntityDataList: EntityItem[];
}

export enum BulkActionTypes {
    Append,
    Replace,
    Clear,
    Update,
}
