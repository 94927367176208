import { Component, HostBinding, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { IconComponent, IconType } from '../icon/icon.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';

export type IconCardSize = 'medium' | 'xl';

@Component({
    standalone: true,
    selector: 'dxy-icon-card',
    templateUrl: './icon-card.component.html',
    imports: [
        EllipsisTooltipDirective,
        NgIf,
        IconComponent,
        MatLegacyTooltipModule,
    ],
    styleUrls: ['./icon-card.component.scss'],
})
export class IconCardComponent {
    @Input() size: IconCardSize = 'medium';
    @Input() icon = '';
    @Input() iconType?: IconType = 'glyph';
    @Input() iconTooltip = '';
    @Input() iconTooltipDisabled = false;
    @Input() text = '';

    @HostBinding('class')
    private get sizeClass() {
        return this.size;
    }

    protected get iconSize() {
        return this.size === 'xl' ? 'xxl' : 'xl';
    }
}
