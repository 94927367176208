import { CoreUtil } from '@datagalaxy/core-util';
import { DataType } from '@datagalaxy/webclient/modeler/data-access';

export class DataTypeGridItem {
    public get dataType() {
        return this.dt;
    }
    public get isDeleteDisabled() {
        return this.isDataTypeSystem;
    }

    public get isDataTypeNameReadOnly() {
        return this.isDataTypeSystem;
    }
    public get dataTypeName() {
        return this.dt.DisplayName;
    }
    public set dataTypeName(value: string) {
        this.dt.DisplayName = value;
    }

    public get isDataTypeSystem() {
        return this.dt.IsSystem;
    }
    public set isDataTypeSystem(value: boolean) {}

    public get isDataTypeSizeRequiredReadOnly() {
        return this.isDataTypeSystem;
    }
    public get isDataTypeSizeRequired() {
        return this.dt?.IsSizeRequired;
    }
    public set isDataTypeSizeRequired(value: boolean) {
        this.dt.IsSizeRequired = value;
    }

    public get isDataTypePrecisionRequiredReadOnly() {
        return (
            this.isDataTypeSystem ||
            (!this.isDataTypeSystem && !this.isDataTypeSizeRequired)
        );
    }
    public get isDataTypePrecisionRequired() {
        return this.dt?.IsPrecisionRequired;
    }
    public set isDataTypePrecisionRequired(value: boolean) {
        this.dt.IsPrecisionRequired = value;
    }

    public get isDataTypeExcluded() {
        return this.isExcluded();
    }
    public set isDataTypeExcluded(value: boolean) {}

    constructor(private dt: DataType, private isExcluded: () => boolean) {
        if (!dt) {
            CoreUtil.warn('no dt', this);
        }
    }
}
