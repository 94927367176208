import {
    DxyEllipsisTooltipCellComponent,
    DxyIconCellComponent,
    IActionOption,
    IIconCellData,
    ITranslateParameters,
} from '@datagalaxy/core-ui';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { IPreImportMessageGroup } from './preimport.types';
import { IMessageGroup, ImportContext } from '../../../shared/ImportContext';
import { ToasterService } from '../../../../services/toaster.service';
import { TextAndToolsCellComponent } from '../../../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { ImportDataMessage } from '@datagalaxy/webclient/data-port/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { GridComponent } from '@datagalaxy/ui/grid';

@Component({
    selector: 'dxy-csv-preimport-check',
    templateUrl: './dxy-csv-preimport-check.component.html',
    styleUrls: ['dxy-csv-preimport-check.component.scss'],
    standalone: true,
    imports: [
        GridComponent,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        TranslateModule,
    ],
})
export class DxyCsvPreImportCheckComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() importContext: ImportContext;
    @Input() showActionsColumn: boolean;

    protected gridConfig: GridConfig<IPreImportMessageGroup> = {
        getItemId: (item) => this.rows.indexOf(item).toString(),
    };
    protected cols: TColDef<IPreImportMessageGroup>[] = [];
    protected rows: IPreImportMessageGroup[] = [];

    private readonly baseTranslateKey =
        'Import.GenericImportWizard.CsvPreImport.Check';
    private get messages() {
        return this.importContext.lastImportResult?.Messages ?? [];
    }
    private set messages(newMessages: ImportDataMessage[]) {
        this.importContext.lastImportResult.Messages = newMessages;
    }

    private get groupedMessages(): IPreImportMessageGroup[] {
        const groups = CollectionsHelper.groupBy(
            this.messages,
            (message) => `${message.MessageCode}-${message.Parameters}`,
            (_k, items) => items
        );
        return groups.map((group) => {
            const firstMessage = group[0];
            return {
                code: firstMessage.MessageCode,
                messages: group,
                isError: firstMessage.IsError,
                isWarning: firstMessage.IsWarning,
            };
        });
    }

    private get orderedMessages() {
        return this.sortMessages(this.groupedMessages);
    }

    constructor(
        private translate: TranslateService,
        private changeDetector: ChangeDetectorRef,
        private toasterService: ToasterService
    ) {
        super();
    }

    ngOnInit() {
        super.subscribe(this.importContext.onRefreshMessages$, (fieldName) =>
            this.onRefreshMessages(fieldName)
        );
        this.refreshGrid();
    }

    public onClickCopyErrors() {
        const errorsAsString = this.orderedMessages
            .map((message) => {
                const errorMessage = this.getErrorMessage(message);
                const errorLines = this.getErrorLines(message);
                return `${errorMessage} \r\n ${errorLines}`;
            })
            .join('\r\n');

        DomUtil.copyToClipboard(errorsAsString);
        this.toasterService.successToast({
            messageKey: `${this.baseTranslateKey}.copyDone`,
        });
    }

    private getColumns() {
        const columns: TColDef<IPreImportMessageGroup>[] = [
            {
                id: 'MessageCode',
                headerLabel: this.translate.instant(
                    `${this.baseTranslateKey}.errorsFieldLbl`
                ),
                type: GridCellType.custom,
                customCellComponent: DxyIconCellComponent,
                getInputs: (row) => {
                    return {
                        ...this.getMessageCode(row),
                    } as Partial<DxyIconCellComponent<IPreImportMessageGroup>>;
                },
                sortable: false,
                fixed: true,
                minWidth: 100,
                width: 300,
            },
            {
                id: 'LineNumber',
                headerLabel: this.translate.instant(
                    `${this.baseTranslateKey}.commentsFieldLbl`
                ),
                type: GridCellType.custom,
                customCellComponent: DxyEllipsisTooltipCellComponent,
                getInputs: (row) =>
                    ({
                        text: this.getCommentLines(row),
                    } as Partial<
                        DxyEllipsisTooltipCellComponent<IPreImportMessageGroup>
                    >),
                sortable: false,
                width: 200,
            },
        ];

        if (this.showActionsColumn) {
            columns.push({
                id: 'actions',
                headerLabel: this.translate.instant(
                    `${this.baseTranslateKey}.actionsFieldLbl`
                ),
                // cellClass: 'text-center',
                sortable: false,
                type: GridCellType.custom,
                customCellComponent: TextAndToolsCellComponent,
                getInputs: (row) => ({
                    actions: this.getActions(),
                    actionsData: row,
                }),
                width: 200,
            });
        }
        return columns;
    }

    private getMessageParametersObject(parameters: string[]) {
        const result: ITranslateParameters = {};
        parameters?.forEach(
            (param, index) => (result[`params_${index}`] = param)
        );
        return result;
    }

    private getMessageCode(data: IMessageGroup): IIconCellData {
        const translatedParameters = this.getMessageParametersObject(
            data.messages[0].Parameters
        );
        const message = this.translate.instant(
            `Import.MessageCode.${data.code}`,
            translatedParameters
        );
        let glyphClass: string;
        let glyphTooltip: string;

        if (data.isError) {
            glyphClass = 'glyph-mandatory';
            glyphTooltip = this.translate.instant(
                'Import.GenericImportWizard.CsvPreImport.Check.errorGlyphTooltip'
            );
        } else if (data.isWarning) {
            glyphClass = 'glyph-warning';
            glyphTooltip = this.translate.instant(
                'Import.GenericImportWizard.CsvPreImport.Check.warningGlyphTooltip'
            );
        }
        return {
            glyphClass,
            glyphTooltip,
            text: message,
        };
    }

    private getCommentLines(data: IMessageGroup) {
        const lines = data.messages.filter((line) => line.LineNumber != -1);
        if (!lines?.length) {
            return '';
        }

        // +1 on line number is necessary because of file header
        const filteredLines = CollectionsHelper.distinct(
            lines.map((message) => message.LineNumber + 1)
        );
        const linesString = filteredLines.join(', ');
        const message = this.translate.instant(
            'Import.GenericImportWizard.CsvPreImport.Check.commentContentHeader',
            { count: filteredLines.length }
        );
        const linesText = `${message} ${linesString}`;

        if (!data.isWarning) {
            return linesText;
        }

        const warningMessage: string = this.translate.instant(
            `Import.MessageWarning.${data.code}`
        );

        return [warningMessage, linesText];
    }

    private getActions(): IActionOption[] {
        const deMappableCodes = [
            'UnknownTag',
            'UnknownCdpTag',
            'InvalidSpaceGovernanceUser',
            'UnknownUser',
            'UnknownDataOwnerOrSteward',
            'UnauthorizedDataOwnerOrSteward',
            'UnknownDataType',
            'CannotSetTechnologyOnNonWritableEntityType',
            'CannotSetTechnologyOnNonRootEntity',
            'TechnologyNotFound',
            'TechnologyNotAvailableOnModule',
        ];

        return [
            {
                hidden: (data: IMessageGroup) =>
                    !deMappableCodes.includes(data.code),
                labelKey:
                    'Import.GenericImportWizard.CsvPreImport.Check.actions.UnMapBtn',
                callback: (data: IMessageGroup) =>
                    this.importContext.onUnmapData(data),
                alwaysVisible: true,
            },
        ];
    }

    private sortMessages(
        messages: IPreImportMessageGroup[]
    ): IPreImportMessageGroup[] {
        // Sort messages first by error type and then alphabetically
        const sortByType = (message: IPreImportMessageGroup) =>
            message.isError ? -1 : 1;
        return CollectionsHelper.orderBy(messages, [sortByType, 'code']);
    }

    private onRefreshMessages(fieldName: string) {
        this.messages = this.messages.filter(
            (message) =>
                message.Parameters[message.Parameters.length - 1] != fieldName
        );
        this.refreshGrid();
    }

    private refreshGrid() {
        this.rows = [...this.orderedMessages];
        this.cols = this.getColumns();
        this.changeDetector.detectChanges();
    }

    private getErrorMessage(message: IPreImportMessageGroup): string {
        const translateValueObject: ITranslateParameters = {};
        const parameters = message.messages[0].Parameters;
        if (!parameters) {
            return '';
        }

        parameters.forEach((param, index) => {
            const key = `params_${index}`;
            translateValueObject[key] = param;
        });

        return this.translate.instant(
            `Import.MessageCode.${message.code}`,
            translateValueObject
        );
    }

    private getErrorLines(messageGroup: IPreImportMessageGroup): string {
        const lines = messageGroup.messages.filter(
            (line) => line.LineNumber != -1
        );
        if (!lines.length) {
            return '';
        }

        // +1 on line number is necessary because of file header
        const filteredLines = CollectionsHelper.distinct(
            lines.map((message) => message.LineNumber + 1)
        );
        const linesString = filteredLines.join(', ');
        const message = this.translate.instant(
            'Import.GenericImportWizard.CsvPreImport.Check.commentContentHeader',
            { count: filteredLines.length }
        );

        return `${message} ${linesString}`;
    }
}
