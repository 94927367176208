import { Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { RealTimeCommService } from '../../services/realTimeComm.service';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';

@Injectable({ providedIn: 'root' })
export class VersioningEventService extends BaseService {
    public get enableVersioning$() {
        return this.enableVersioning.asObservable();
    }
    private enableVersioning = new Subject<VersioningEvent<ProjectVersion>>();

    public get createVersion$() {
        return this.createVersion.asObservable();
    }
    private createVersion = new Subject<VersioningEvent<ProjectVersion>>();

    public get updateVersion$() {
        return this.updateVersion.asObservable();
    }
    private updateVersion = new Subject<VersioningEvent<ProjectVersion>>();

    public get updateDefaultVersion$() {
        return this.updateDefaultVersion.asObservable();
    }
    private updateDefaultVersion = new Subject<
        VersioningEvent<ProjectVersion>
    >();

    public get updateVersionStatus$() {
        return this.updateVersionStatus.asObservable();
    }
    private updateVersionStatus = new Subject<
        VersioningEvent<ProjectVersion[]>
    >();

    constructor(
        private realTimeCommService: RealTimeCommService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
        this.realTimeCommService.subscribeVersioning({
            EnableVersioning: (userData, data) =>
                this.notifyEnableVersioningEvent(data, true),
            CreateVersion: (userData, data) =>
                this.notifyCreateVersionEvent(data, true),
            UpdateVersion: (userData, data) =>
                this.notifyUpdateVersionEvent(data, true),
            UpdateVersionStatus: (userData, data) =>
                this.notifyUpdateVersionStatusEvent(data, true),
        });
    }

    public isEqualCurrentVersion(version: ProjectVersion) {
        return this.currentSpaceService.isCurrentSpaceAndVersion(version);
    }

    public notifyEnableVersioningEvent(
        version: ProjectVersion,
        external: boolean = false
    ) {
        this.enableVersioning.next(new VersioningEvent(external, version));
    }

    public notifyCreateVersionEvent(
        version: ProjectVersion,
        external: boolean = false
    ) {
        this.createVersion.next(new VersioningEvent(external, version));
    }

    public notifyUpdateVersionEvent(
        version: ProjectVersion,
        external: boolean = false
    ) {
        this.updateVersion.next(new VersioningEvent(external, version));
    }

    public notifyUpdateDefaultVersionEvent(
        version: ProjectVersion,
        external: boolean = false
    ) {
        this.updateDefaultVersion.next(new VersioningEvent(external, version));
    }

    public notifyUpdateVersionStatusEvent(
        versions: ProjectVersion[],
        external: boolean = false
    ) {
        this.updateVersionStatus.next(new VersioningEvent(external, versions));
    }
}

export class VersioningEvent<T> {
    constructor(public external: boolean, public data?: T) {}
}
