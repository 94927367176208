<dxy-field-select
    #field
    [adapter]="adapter"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [search]="true"
    [readonly]="readonly"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    [hintAsTooltipWhenMini]="mini"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    (openClose)="onPanelOpenClose.emit($event)"
    [useRichLayout]="true"
    [class.mini]="mini"
></dxy-field-select>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>
