import { deserialize, inheritSerialization } from 'cerialize';
import { UserEventBase } from '../events/UserEventBase';

@inheritSerialization(UserEventBase)
export class CampaignEventBase extends UserEventBase {
    @deserialize CampaignGuid: string;
    @deserialize CampaignName: string;
    @deserialize SpaceGuid: string;
    @deserialize VersionId: string;
}
