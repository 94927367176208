import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
    selector: 'dxy-checkbox',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
    @Input() label!: string;
    @Input() name?: string;
    @Input() value?: string;
    @Input() checked? = false;
    @Input() disabled = false;
    @Input() indeterminate = false;
    @Output() checkedChange = new EventEmitter<boolean>();

    private onChange: any = () => {};

    writeValue(checked: boolean) {
        this.checked = checked;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    registerOnTouched(_fn: any) {}

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    protected onValueChange(event: Event) {
        this.checked = (event.target as HTMLInputElement)?.checked;
        this.onChange(this.checked);
        this.checkedChange.emit(this.checked);
    }
}
