import { Directive, OnInit, Renderer2, Input, ElementRef } from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

/*
  sources:  https://medium.com/@imdebasispanda/resize-table-column-angular-5cb58b67367,
            https://stackblitz.com/edit/mat-table-resize-column?file=src%2Fapp%2Fapp.component.ts
*/

/** Adds a resize handle to a mat-header-cell
 * Note: Currently supports either:
 * - flex mat-table
 * - or fixed layout table (`<table mat-table fixedLayout="true"`)
 */
@Directive({
    selector:
        'mat-header-cell[dxyResizeColumn],[mat-header-cell][dxyResizeColumn]',
    standalone: true,
})
export class DxyResizeColumnDirective
    extends DxyBaseDirective
    implements OnInit
{
    /** id of the column (the one passed to [matColumnDef]) */
    @Input('dxyResizeColumn') columnId: string;
    /** Defaults to 10 (px), to match the draggable resize handle, so it is always accessible */
    @Input('dxyResizeColumnMinWidth') minWidth = 10;
    @Input('dxyResizeColumnOffset') offset = 0;

    private readonly resizerClick = (e: MouseEvent) => e.stopPropagation();
    private readonly resizerMouseDown = (e: MouseEvent) =>
        this.onResizerMouseDown(e);
    private readonly tableMouseMove = (e: MouseEvent) =>
        this.onTableMouseMove(e);
    private readonly documentMouseUp = () => this.onDocumentMouseUp();
    private startX: number;
    private startWidth: number;
    private columnHeader: HTMLElement;
    private table: HTMLElement;
    private pressed: boolean;
    private cells: NodeListOf<HTMLElement>;
    private isFixedLayout: boolean;

    constructor(private renderer: Renderer2, private el: ElementRef) {
        super();
        this.columnHeader = this.el.nativeElement;
    }

    ngOnInit() {
        if (!this.columnId) {
            return;
        }

        const renderer = this.renderer;
        const row = renderer.parentNode(this.columnHeader);
        const thead = renderer.parentNode(row);
        const table = renderer.parentNode(thead);
        this.isFixedLayout =
            table.tagName == 'TABLE' &&
            table.classList.contains('mat-table-fixed-layout');

        const resizer = renderer.createElement('span');
        renderer.addClass(resizer, 'resize-handle');
        renderer.appendChild(this.columnHeader, resizer);
        renderer.listen(resizer, 'click', this.resizerClick);
        renderer.listen(resizer, 'mousedown', this.resizerMouseDown);
        renderer.listen(table, 'mousemove', this.tableMouseMove);
        renderer.listen('document', 'mouseup', this.documentMouseUp);

        this.table = table;
    }

    private onResizerMouseDown(event: MouseEvent) {
        event.stopPropagation();
        this.pressed = true;
        this.startX = event.pageX;
        this.startWidth = this.columnHeader.offsetWidth;
        this.cells = this.table.querySelectorAll<HTMLElement>(
            `.mat-column-${this.columnId}`
        );
        this.renderer.addClass(this.table, 'resizing');
    }

    private onTableMouseMove(event: MouseEvent) {
        if (!this.pressed || !event.buttons) {
            return;
        }
        event.stopPropagation();
        const widthPx = `${Math.max(
            this.minWidth,
            this.startWidth + (event.pageX - this.startX - this.offset)
        )}px`;
        if (this.isFixedLayout) {
            this.cells.forEach((el) =>
                this.renderer.setStyle(el, 'width', widthPx)
            );
        } else {
            this.cells.forEach((el) => {
                this.renderer.setStyle(el, 'flex-basis', widthPx);
                this.renderer.setStyle(el, 'max-width', widthPx);
            });
        }
    }

    private onDocumentMouseUp = () => {
        if (!this.pressed) {
            return;
        }
        this.pressed = false;
        this.renderer.removeClass(this.table, 'resizing');
    };
}
