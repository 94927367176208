// temporary solution, this information is plan to be in connectors manifest
import { IConfigFieldInfos } from './types/interfaces/config-field-infos.interface';
import { amazonAthenaFieldInfo } from './plugins/amazon-athena/amazon-athena-field-info';
import { amazonGlueFieldInfo } from './plugins/amazon-glue/amazon-glue-field-info';
import { amazonS3FieldInfo } from './plugins/amazon-s3/amazon-s3-field-info';
import { azureDataLakeGen2FieldInfo } from './plugins/azure-data-lake-gen2/azure-data-lake-gen2-field-info';
import { azureSqlFieldInfo } from './plugins/azure-sql/azure-sql-field-info';
import { denodoFieldInfo } from './plugins/denodo/denodo-field-info';
import { googleBigQueryFieldInfo } from './plugins/google-big-query/google-big-query-field-info';
import { lookerFieldInfo } from './plugins/looker/looker-field-info';
import { oracleFieldInfo } from './plugins/oracle/oracle-field-info';
import { powerBiFieldInfo } from './plugins/power-bi/power-bi-field-info';
import { snowflakeFieldInfo } from './plugins/snowflake/snowflake-field-info';
import { sqlServerFieldInfo } from './plugins/sql-server/sql-server-field-info';
import { tableauFieldInfo } from './plugins/tableau/tableau-field-info';
import { talendCloudFieldInfo } from './plugins/talend-cloud/talend-cloud-field-info';
import { azureSynapseFieldInfo } from './plugins/azure-synapse/azure-synapse-field-info';
import { mongoDbFieldInfo } from './plugins/mongo-db/mongo-db-field-info';
import { amazonDynamoDbFieldInfo } from './plugins/amazon-dynamo-db/amazon-dynamo-db-field-info';
import { salesforcePlatformFieldInfo } from './plugins/salesforce-platform/salesforce-platform-field-info';
import { databricksFieldInfo } from './plugins/databricks/databricks-field-info';
import { hubspotFieldInfo } from './plugins/hubspot/hubspot-field-info';

export const connectorFieldsInfos: IConfigFieldInfos = {
    amazonathena: amazonAthenaFieldInfo,
    amazonglue: amazonGlueFieldInfo,
    amazons3: amazonS3FieldInfo,
    azuredatalakegen2: azureDataLakeGen2FieldInfo,
    azuresql: azureSqlFieldInfo,
    denodo: denodoFieldInfo,
    googlebigquery: googleBigQueryFieldInfo,
    looker: lookerFieldInfo,
    oracle: oracleFieldInfo,
    powerbi: powerBiFieldInfo,
    snowflake: snowflakeFieldInfo,
    sqlserver: sqlServerFieldInfo,
    tableau: tableauFieldInfo,
    talendcloud: talendCloudFieldInfo,
    azuresynapse: azureSynapseFieldInfo,
    mongodb: mongoDbFieldInfo,
    amazondynamodb: amazonDynamoDbFieldInfo,
    salesforceplatform: salesforcePlatformFieldInfo,
    databricks: databricksFieldInfo,
    hubspot: hubspotFieldInfo,
};
