import { FieldType } from '../../types/enums/field-type.enum';

export const amazonGlueConfig = [
    { payloadField: 'type', payloadValue: 'MULTI' },
    { payloadField: 'multi-technology', payloadValue: 'amazonglue' },
    {
        payloadField: 'used-authentification-mode',
        payloadValue: 'ACCESS_KEY',
    },
    {
        payloadField: 'region',
        formField: 'region',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'database',
        formField: 'database',
        type: FieldType.text,
    },
    {
        payloadField: 'endpoint',
        formField: 'endpoint',
        type: FieldType.text,
    },
    { payloadField: 'roleArn', formField: 'roleArn', type: FieldType.text },
    {
        payloadField: 'access-key',
        formField: 'accessKey',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        tradKey: 'secretKey',
        isPassword: true,
        formMandatory: true,
    },
    {
        payloadField: 'sts-token',
        formField: 'stsToken',
        isPassword: true,
        formMandatory: false,
        passwordMaxLength: 1000,
    },
];
