<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.User.changePasswordModal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <dxy-field-password
            labelKey="UI.User.changePasswordModal.oldPassword"
            [(ngModel)]="oldPassword"
            (ngModelChange)="onOldPasswordChange()"
            [errorMessageText]="oldPasswordErrorMessage"
            name="old-password"
            [hideMaxLengthHint]="true"
            [mandatory]="true"
        ></dxy-field-password>
        <dxy-field-password
            labelKey="UI.User.changePasswordModal.newPassword"
            [(ngModel)]="newPassword"
            name="new-password"
            [mandatory]="true"
        ></dxy-field-password>
        <dxy-field-password
            #secondPasswordField
            labelKey="UI.User.changePasswordModal.copyPassword"
            [(ngModel)]="copyPassword"
            [errorMessageText]="
                secondPasswordField.touched && comparePasswordsErrorMessage
            "
            name="copy-password"
            [mandatory]="true"
        ></dxy-field-password>
        <div
            *ngIf="isOldPasswordServerError"
            class="alert alert-danger"
            role="alert"
        >
            <span class="glyph glyph-close-circle-filled"></span>
            <span>{{ oldPasswordMessage }}</span>
        </div>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Global.btnUpdate' | translate"
        (onCloseCancel)="onCloseCancel()"
        featureCode="CHANGE_PASSWORD,U"
    >
    </dxy-modal-footer>
</form>
