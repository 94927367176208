import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { BaseCellComponent } from '../../cell-components/BaseCellComponent';
import {
    ICellParams,
    TCellRenderer,
} from '../../cell-components/cell-components.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/** For to use a renderer in an Angular context */
@Component({
    standalone: true,
    selector: 'dxy-renderer',
    template: '<ng-container #container></ng-container>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyRendererComponent<TEntity = any, TValue = any>
    extends DxyBaseComponent
    implements OnChanges, AfterViewInit, OnDestroy
{
    @Input() renderer: TCellRenderer<TEntity, TValue>;
    @Input() param: ICellParams<TEntity, TValue>;
    @Input() isMini: boolean;
    @Input() data: TValue;
    /** When true, we destroy then recreate the renderer instead of calling its refresh method */
    @Input() forceUpdateDataOnChange: boolean;

    // Note: We use a ng-container instead of getting the ViewContainerRef from the constructor,
    // so the inner component is created inside this component instead of after it in the html.
    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    private componentRef: ComponentRef<BaseCellComponent>;

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['param', 'isMini'], () => this.render());
        super.onChange(changes, 'renderer', () => this.initRenderer());
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.componentRef?.destroy();
    }

    ngAfterViewInit() {
        this.initRenderer();
    }

    private initRenderer() {
        this.componentRef?.destroy();
        if (!this.renderer) {
            return;
        }
        const renderer = this.renderer as Type<
            BaseCellComponent<TEntity, TValue>
        >;
        this.componentRef = this.viewContainerRef.createComponent(renderer);
        this.render();
    }

    private render() {
        if (!this.renderer || !this.componentRef) {
            return;
        }
        const params = {
            ...this.param,
            value: this.data || this.param?.value,
            isMini: this.isMini,
        };

        this.componentRef.setInput('params', params);
        this.componentRef.changeDetectorRef.detectChanges();
    }
}
