import { CoreUtil } from '@datagalaxy/core-util';
import {
    IRectABSides,
    IRectSidePoint,
    IWidthHeight,
    IXY,
    IXYRO,
    Point,
    Rect,
    RectSide,
    RectSidePoint,
    Vect2,
} from '@datagalaxy/core-2d-util';
import {
    IRouteEndpointsParams,
    IRouteParams,
    OrthogonalConnector,
} from './path-finder';
import { IConstrainedRectSidePoint } from '../../endpoint';

export class OrthoRouteUtil {
    //#region
    // For better performance, we re-use those objects instead of creating new ones each time
    private static readonly _opts = { pointA: {}, pointB: {} } as IRouteParams;
    private static readonly _segmentStart: number[] = [];
    public static readonly _epIn: IRectSidePoint<void> = {
        node: { rect: new Rect() },
    };
    private static readonly _epOut: IRectSidePoint<void> = {
        node: { rect: new Rect() },
    };
    private static readonly _ra = new Rect();
    private static readonly _rb = new Rect();
    private static readonly _s1: Readonly<IWidthHeight> = {
        width: 1,
        height: 1,
    };
    private static readonly _rs = {} as IRectABSides;
    //#endregion

    /** computes endpoints side and distance */
    public static computeEndpoints(
        a: IConstrainedRectSidePoint,
        b: IConstrainedRectSidePoint,
        fixedPart?: IXYRO[]
    ) {
        if (fixedPart?.length > 3) {
            // setup zero-size endpoints for enter and exit of fixed part of the route
            const epIn = OrthoRouteUtil._epIn,
                epOut = OrthoRouteUtil._epOut,
                l = fixedPart.length - 1;
            RectSidePoint.makeZeroSized(epIn, fixedPart[2]);
            RectSidePoint.makeZeroSized(epOut, fixedPart[l - 2]);
            const excludedSidesForFixedIn = [
                OrthoRouteUtil.exitSide(fixedPart[0], fixedPart[1]),
            ];
            const excludedSidesForFixedOut = [
                OrthoRouteUtil.enterSide(fixedPart[l - 1], fixedPart[l]),
            ];
            OrthoRouteUtil.setSideAndDistance(
                a,
                epIn,
                a.excludedSides,
                excludedSidesForFixedIn
            );
            OrthoRouteUtil.setSideAndDistance(
                epOut,
                b,
                excludedSidesForFixedOut,
                b.excludedSides
            );
        } else {
            OrthoRouteUtil.setSideAndDistance(
                a,
                b,
                a.excludedSides,
                b.excludedSides
            );
        }
    }

    /**
     * Returns the route between the 2 given rectangular endpoints,
     * as an array of points of connected orthogonal segments.
     * The returned path is simplified and decimals are truncated
     */
    public static routeEndpoints(
        a: IRectSidePoint,
        b: IRectSidePoint,
        p: IRouteEndpointsParams
    ): Point[] {
        const opts = OrthoRouteUtil._opts,
            { pointA: pa, pointB: pb } = opts;
        pa.shape = a.node.rect;
        pa.side = Rect.side(a.side);
        pa.distance = a.distance;
        if (!pa.side) {
            CoreUtil.warn('side is needed', a);
        }

        pb.shape = b.node.rect;
        pb.side = Rect.side(b.side);
        pb.distance = b.distance;
        if (!pb.side) {
            CoreUtil.warn('side is needed', b);
        }

        opts.globalBounds = p.globalBounds;
        opts.shapeMargin = p.shapeMargin ?? 20;
        opts.globalBoundsMargin = p.globalBoundsMargin ?? 20;

        try {
            let points = OrthogonalConnector.route(opts, p.withByproduct);
            return points.map((q) => Vect2.truncateDecimals(q, q));
        } catch (e) {
            CoreUtil.warn(e, { opts, a, b, p });
            return [];
        }
    }

    /** Returns the segment index and coordinates of the position
     * of a normalized distance (.5 is the middle) along the route */
    public static getPosition(
        route: IXY[],
        d = 0.5,
        res = { p: {}, i: -1 } as IRoutePos,
        noDecimals?: boolean
    ) {
        // Optimized by minimizing round-trips, function calls and memory allocations
        const rl = route?.length;
        if (!(rl > 1)) {
            res.i = -1;
            res.p.x = res.p.y = undefined;
            return res;
        }
        let si: number; // searched segment index
        let sd: number; // normalized distance in searched segment
        if (d <= 0) {
            si = sd = 0;
        } else if (d >= 1) {
            si = rl - 1;
            sd = 1;
        } else {
            // compute segments start, in length
            const ss = OrthoRouteUtil._segmentStart; // re-use existing array
            ss.length = rl; // last segment-start is (total length)
            ss[0] = 0; // first segment-start
            let L = 0; // total length, needed for normalization
            let p = route[0]; // previous point
            for (let i = 1; i < rl; i++) {
                const c = route[i]; // current point
                // segments are either vertical or horizontal
                ss[i] = L += Math.abs(c.x == p.x ? c.y - p.y : c.x - p.x);
                p = c;
            }
            // normalize segments start
            let pnss = 0; // previous segment start, normalized
            for (let i = 1; i < rl; i++) {
                const nss = ss[i] / L; // current segment start, normalized
                if (nss > d) {
                    // segment found
                    si = i;
                    sd = d / nss - pnss;
                    break;
                }
                pnss = nss;
            }
        }
        // coordinates
        const p = route[si - 1],
            q = route[si];
        res.p.x = p.x + sd * (q.x - p.x);
        res.p.y = p.y + sd * (q.y - p.y);
        if (noDecimals) {
            res.p.x |= 0;
            res.p.y |= 0;
        }
        res.i = si;
        res.d = sd;
        return res;
    }

    /** Returns the enter side, given the enter and exit points of an orthogonal segment */
    private static enterSide(enter: IXYRO, exit: IXYRO) {
        return enter.x == exit.x
            ? enter.y < exit.y
                ? RectSide.top
                : RectSide.bottom
            : enter.y == exit.y
            ? enter.x < exit.x
                ? RectSide.left
                : RectSide.right
            : RectSide.none;
    }
    /** Returns the exit side, given the enter and exit points of an orthogonal segment */
    private static exitSide(enter: IXYRO, exit: IXYRO) {
        return OrthoRouteUtil.enterSide(exit, enter);
    }

    /** If undefined, sets the given endpoint's distance to .5 and,
     * if undefined, computes their side to be the closest as possible to each other. */
    private static setSideAndDistance(
        a: IRectSidePoint,
        b: IRectSidePoint,
        excludedSidesForA?: RectSide[],
        excludedSidesForB?: RectSide[]
    ) {
        const da = (a.distance ??= 0.5);
        const db = (b.distance ??= 0.5);

        if (a.side && b.side) {
            return;
        }

        // zero-size side points are supported, but we need a non-zero size to compute a nearest side
        const ra = OrthoRouteUtil._ra
            .copy(a.node.rect)
            .clampSize(OrthoRouteUtil._s1);
        const rb = OrthoRouteUtil._rb
            .copy(b.node.rect)
            .clampSize(OrthoRouteUtil._s1);
        if (a.side) {
            b.side = Rect.nearestSideToSide(
                rb,
                ra,
                Rect.rectSide(a.side),
                db,
                da,
                excludedSidesForB
            );
        } else if (b.side) {
            a.side = Rect.nearestSideToSide(
                ra,
                rb,
                Rect.rectSide(b.side),
                da,
                db,
                excludedSidesForA
            );
        } else {
            const sides = Rect.nearestSides(
                ra,
                rb,
                OrthoRouteUtil._rs,
                da,
                db,
                excludedSidesForA,
                excludedSidesForB
            );
            a.side = sides.a;
            b.side = sides.b;
        }
    }
}

/** Segment index and coordinates of the position
 * of a normalized distance (.5 is the middle) along the route */
export interface IRoutePos {
    /** point along the route - absolute coordinates */
    p: Partial<IXY>;
    /** segment's index */
    i?: number;
    /** normalized distance (.5 is the middle) along the segment */
    d?: number;
}
