import { IEntityIdentifier } from '@datagalaxy/dg-object-model';

export function getSpaceIdFromEntity(entity: IEntityIdentifier) {
    return getSpaceIdFromEntityId(entity?.ReferenceId);
}

export function getSpaceIdFromEntityId(dataId?: string | null) {
    if (!dataId) {
        return;
    }
    const parsedId = dataId.split(':');
    const contextId = parsedId[0];
    return `${contextId}:${contextId}`;
}
