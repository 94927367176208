import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-field-radio-item',
    template: '<ng-template #item><ng-content></ng-content><ng-template>',
    standalone: true,
})
export class DxyFieldRadioItemComponent<T> extends DxyBaseComponent {
    @Input() value: T;
    @Input() disabled = false;

    /** template of item HTMLElement in order to inject it properly inside radio field control */
    @ViewChild('item') public itemTemplate: TemplateRef<HTMLElement>;

    constructor() {
        super();
    }
}
