import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

export enum LicenseLevel {
    Reader = 0,
    Explorer = 1,
    Steward = 2,
}
SerializableEnumeration(LicenseLevel);

@inheritSerialization(BaseDTO)
export class LicenseDTO extends BaseDTO {
    @deserialize public LicenseId?: number;
    @deserialize public ClientId?: number;
    @deserializeAs(LicenseLevel) public LicenseLevel?: LicenseLevel;
    @deserialize public StartsOn?: string;
    @deserialize public ExpiresOn?: string;
    @deserialize public Status?: string;
    @deserialize public AssociateUser?: string;
    @deserialize public IsExpired?: boolean;
    @deserialize public AvailableLicenses?: number;
    @deserialize public Count?: number;
    @deserialize public PersonId?: string;
    @deserialize public IsLicensePool?: boolean;
    @deserialize public IsInfinite?: boolean;
}
