<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.Details
    </div>
</div>
<div [class.panel-content-body]="showHeader">
    <dxy-screen-layout
        [spaceId]="spaceId"
        [entityData]="entityData"
        [entityAttributes]="entityAttributes"
        [reloadOnSourceChange]="reloadOnSourceChange"
        [readOnly]="readOnly"
        [noNavLink]="noNavLink"
    ></dxy-screen-layout>
</div>
