import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import Pendo = pendo.Pendo;

/**
 * ## Role
 * Wrapper service for pendo-js library
 *
 * ## Note
 * Pendo is not initialised in this service since it is already set up
 * by SegmentService under the hood. So if segmentService is not initialised,
 * this service will not be initialised, meaning the global variable pendo will
 * be undefined
 * cf: ANALYTICS_SEGMENT_ENABLED
 */
@Injectable({
    providedIn: 'root',
})
export class PendoService extends BaseService {
    private get pendo(): IPendo {
        return (window as any).pendo;
    }

    constructor() {
        super();
    }

    /**
     * Open Pendo resource center's feedback guide
     */
    public showGuideById(guideId: string) {
        return this.pendo?.showGuideById(guideId);
    }

    public clearSession() {
        if (!this.pendo) {
            return;
        }
        this.log('clearPendoSession', !!this.pendo.clearSession);
        try {
            this.pendo.clearSession?.();
        } catch (e) {
            this.warn(e);
        }
    }
}

interface IPendo extends Pendo {
    clearSession(): void;
}
