import { FieldType } from '../../types/enums/field-type.enum';

export const talendCloudConfig = [
    {
        payloadField: 'server',
        formField: 'url',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'personal-access-token',
        formField: 'personalAccessToken',
        formMandatory: true,
        isPassword: true,
    },
    { payloadField: 'multi-technology', payloadValue: 'talendcloud' },
    { payloadField: 'type', payloadValue: 'MULTI' },
];
