import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseDTO)
export class StatisticCount extends BaseDTO {
    @deserialize public UsedElementsCount!: number;
    @deserialize public ConfigurationElementsCount!: number;
}

@inheritSerialization(BaseDTO)
export class ClientStatisticsDto extends BaseDTO {
    @deserialize public Offer!: string;
    @deserialize public Level!: string;
    @deserializeAs(StatisticCount) public WorkspaceCount!: StatisticCount;
    @deserializeAs(StatisticCount) public ObjectsCount!: StatisticCount;
    @deserializeAs(StatisticCount) public StewardLicenseCount!: StatisticCount;
    @deserializeAs(StatisticCount) public ExplorerLicenseCount!: StatisticCount;
    @deserializeAs(StatisticCount) public ReaderLicenseCount!: StatisticCount;
    @deserializeAs(StatisticCount)
    public MonthlyInteractionCount!: StatisticCount;
}
