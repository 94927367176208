<dxy-filter-button
    [xMenuPosition]="xMenuPosition"
    [labelKey]="labelKey"
    [labelText]="labelText"
    [isResolved]="isResolved"
    [isMini]="isMini"
    [hasRemove]="hasRemove"
    [operators]="operators"
    [operator]="operator"
    [hasSmallPanel]="true"
    [readonly]="readonly"
    [noOperator]="noOperator"
    [getOperatorTranslateKey]="getOperatorTranslateKey"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    iconClass="glyph-number"
    (operatorChange)="onOperatorChange($event)"
    (removeClick)="onRemove($event)"
    (onOpenClose)="onOpenClose.emit($event)"
>
    <div customTextTemplate>
        <div class="filter-value-wrapper">
            <ng-container *ngIf="isNumberRange">
                <span class="operator-name light-operator" translate
                    >CoreUI.Filter.Number.Criteria.From</span
                >
                <div *ngIf="hasMin" class="filter-value">{{ filter.min }}</div>
                <span
                    class="operator-name light-operator second-operator"
                    translate
                    >CoreUI.Filter.Number.Criteria.To</span
                >
                <div *ngIf="hasMax" class="filter-value">{{ filter.max }}</div>
            </ng-container>
            <ng-container *ngIf="!isNumberRange">
                <span
                    [class.hidden]="!showOperator"
                    class="operator-name light-operator"
                    [translate]="operatorTranslateKey"
                ></span>
                <div
                    *ngIf="hasMin && !isValueLessOperator"
                    class="filter-value"
                >
                    {{ filter.min }}
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!isValueLessOperator" mat-menu-item (click)="min.focus()">
        <span *ngIf="isNumberRange" class="range-prefix" translate
            >CoreUI.Filter.Number.Criteria.From</span
        >
        <input
            matInput
            type="number"
            dxyAutoFocusInput
            [ngModel]="filter.min"
            (ngModelChange)="onMinChange($event)"
            autocomplete="off"
            #min
        />
    </div>
    <div *ngIf="isNumberRange" mat-menu-item (click)="max.focus()">
        <span class="range-prefix" translate
            >CoreUI.Filter.Number.Criteria.To</span
        >
        <input
            matInput
            type="number"
            [ngModel]="filter.max"
            (ngModelChange)="onMaxChange($event)"
            autocomplete="off"
            #max
        />
    </div>
</dxy-filter-button>
