<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        Multilingual.Administration.addLanguage
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <img
            src="/images/multilingual-target-language.svg"
            alt="Target language"
        />
        <dxy-target-language-selector
            formControlName="language"
            [exludedLanguageCodes]="exludedLanguageCodes"
        ></dxy-target-language-selector>
    </div>
    <div class="modal-footer">
        <dxy-spinner
            *ngIf="loading$ | async; else buttons"
            class="no-margin"
        ></dxy-spinner>
        <ng-template #buttons>
            <div class="dxy-button-group">
                <button
                    type="button"
                    dxyButton
                    color="secondary"
                    (click)="onCloseCancel()"
                >
                    <span translate>UI.Global.btnCancel</span>
                </button>
                <button type="submit" dxyButton [disabled]="!form.valid">
                    <span translate> common.add </span>
                </button>
            </div>
        </ng-template>
    </div>
</form>
