<dxy-spinner
    *ngIf="isLoading"
    [message]="
        'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.loadingMessage'
            | translate
    "
></dxy-spinner>

<ng-container *ngIf="!isLoading">
    <span class="title">{{ titleText }}</span>
    <mat-radio-group
        [(ngModel)]="isSelectAll"
        (ngModelChange)="onChangeSelectAll()"
        name="is-private"
    >
        <mat-radio-button [value]="true">
            <span translate
                >Import.GenericImportWizard.ConnectionForm.ObjectsSelection.radioSelectAll</span
            >
        </mat-radio-button>
        <mat-radio-button [value]="false">
            <span translate
                >Import.GenericImportWizard.ConnectionForm.ObjectsSelection.radioCustomSelection</span
            >
        </mat-radio-button>
    </mat-radio-group>

    <dxy-search-input
        class="no-border-top"
        [(searchString)]="searchString"
        (onSearchString)="onSearchTermChange()"
    ></dxy-search-input>
</ng-container>
<dxy-grid
    [class.disabled]="isSelectAll"
    [config]="gridConfig"
    [columns]="cols"
    [items]="rows"
    (selectionChange)="onSelectionChange($event)"
></dxy-grid>
