import {
    IValueListFilterData,
    ValueListFilterOperator,
} from './value-list-filter.types';

export class ValueListFilterUtils {
    public static isValueLessOperator(operator: ValueListFilterOperator) {
        return [
            ValueListFilterOperator.FieldIsEmpty,
            ValueListFilterOperator.FieldHasValue,
        ].includes(operator);
    }

    public static isResolved<TItem>(filter: IValueListFilterData<TItem>) {
        if (!filter) {
            return false;
        }
        return (
            ValueListFilterUtils.isValueLessOperator(filter.operator) ||
            !!filter.values?.length
        );
    }

    public static getOperatorTranslateKey(operator: ValueListFilterOperator) {
        if (operator == undefined) {
            return;
        }
        const operatorName = ValueListFilterOperator[operator];
        return `CoreUI.Filter.Operators.ValueList.${operatorName}`;
    }
}
