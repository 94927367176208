import { getBootLogger } from '../boot';
import { StateName } from '@datagalaxy/webclient/routing';
import { StateParams } from '@uirouter/core';
import { Ng2StateDeclaration } from '@uirouter/angular/interface';
import { DxyErrorMainComponent } from './errorPages/dxy-error-main/dxy-error-main.component';
import { AppComponent } from './app.component';
import { DxyIsUpComponent } from './app-main/dxy-is-up/dxy-is-up.component';
import { Constants } from './shared/util/Constants';
import { LegacyAuthenticationService } from './services/authentication.service';
import { MonitoringApiService } from '@datagalaxy/shared/monitoring/data-access';
import { SplashScreenComponent } from '@datagalaxy/ui/illustration';

const logBoot = getBootLogger('app.route.ts');
logBoot('IN');
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import ParamKey = Nav.ParamKey;

export const mainRoute: Ng2StateDeclaration = {
    name: StateName.Main,
    abstract: true,
    component: AppComponent,
    resolve: [
        {
            token: '_initLogin',
            deps: [LegacyAuthenticationService],
            resolveFn: (authenticationService: LegacyAuthenticationService) =>
                authenticationService.enterApp(),
        },
    ],
};

// State for Global Health Check (even on SSO deployment)
// Example url: http://localhost:3000/#/is-up
export const healCheckRoute: Ng2StateDeclaration = {
    name: StateName.MainIsUp,
    url: `/is-up`,
    component: DxyIsUpComponent,
    resolve: [
        {
            token: 'upData',
            deps: [MonitoringApiService],
            resolveFn: (monitoringApiService: MonitoringApiService) =>
                monitoringApiService.healthCheck(),
        },
    ],
};

// State for global error before authentication (SAML-scenario, mainly)
export const errorRoute: Ng2StateDeclaration = {
    name: StateName.MainError,
    url: `/${UrlWord.error}`,
    params: {
        errorMessage: '',
        accessLevel: '',
    },
    component: DxyErrorMainComponent,
    resolve: [
        {
            token: 'errorMessage',
            deps: ['$stateParams'],
            resolveFn: ($stateParams: StateParams) =>
                $stateParams[ParamKey.ErrorMessage],
        },
        {
            token: 'accessLevel',
            deps: ['$stateParams'],
            resolveFn: ($stateParams: StateParams) =>
                $stateParams[ParamKey.AccessLevel],
        },
    ],
};

export const mainPrivateRouteLegacy: Ng2StateDeclaration = {
    name: StateName.MainPrivate,
    abstract: true,
    resolve: [
        {
            token: '_systemLogin',
            deps: [LegacyAuthenticationService],
            resolveFn: async (
                authenticationService: LegacyAuthenticationService
            ) => {
                return await authenticationService.doLoginProcess();
            },
        },
    ],
};

export const mainPrivateIndexRouteLegacy: Ng2StateDeclaration = {
    name: StateName.MainPrivateIndex,
    url: '^/',
    views: { '@main': { component: SplashScreenComponent } },
};

export const mainPrivateErrorRoute: Ng2StateDeclaration = {
    name: StateName.MainPrivateError,
    url: `^/${UrlWord.error}`,
    params: { errorMessage: '' },
    views: { '@main': { component: DxyErrorMainComponent } },
    resolve: [
        {
            token: 'errorMessage',
            deps: ['$stateParams'],
            resolveFn: ($stateParams: StateParams) =>
                $stateParams['errorMessage'],
        },
        {
            token: 'accessLevel',
            resolveFn: () => 'private',
        },
    ],
};

export const clientLazyLoadedLegacy: Ng2StateDeclaration = {
    name: StateName.ClientLazyLoaded,
    url: `/${UrlWord.client}`,
    loadChildren: () =>
        import('./client/client.module.legacy').then(
            (m) => m.ClientModuleLegacy
        ),
};

export const appRoutes: Ng2StateDeclaration[] = [
    //#region unauthorized
    mainRoute,
    healCheckRoute,
    errorRoute,
    //#endregion unauthorized
    mainPrivateRouteLegacy,
    mainPrivateErrorRoute,
];

logBoot('OUT');
