import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { SecurityService } from '../services/security.service';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { AppDataService } from '../services/app-data.service';
import { CampaignDto } from '@datagalaxy/webclient/campaign/data-access';
import { AppSpaceService } from '../services/AppSpace.service';
import { getReferenceId } from '@datagalaxy/webclient/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { UserDto } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class CampaignSecurityService extends BaseService {
    public async canCreateOrModifyCampaign(spaceIdr: ISpaceIdentifier) {
        const space = await this.spaceService.getSpace(spaceIdr);
        return (
            this.isCurrentUserWorkspaceAdmin(space) &&
            this.securityService.areCampaignsEnabled()
        );
    }

    public canCreateOrModifyCampaignSync(space: Space) {
        return (
            this.isCurrentUserWorkspaceAdmin(space) &&
            this.securityService.areCampaignsEnabled()
        );
    }
    public async userCanModifyPhaseAndObjects(
        campaign: CampaignDto
    ): Promise<boolean> {
        const spaceIdr = new SpaceIdentifier(
            getReferenceId(campaign.SpaceGuid, campaign.SpaceGuid),
            campaign.VersionId
        );
        return (
            this.securityService.areCampaignsEnabled() &&
            !campaign.Ended &&
            ((await this.canCreateOrModifyCampaign(spaceIdr)) ||
                (this.isAssignee(campaign.CurrentPhase.Assignees) &&
                    this.securityService.isSteward()))
        );
    }

    constructor(
        private securityService: SecurityService,
        private spaceService: AppSpaceService,
        private appDataService: AppDataService
    ) {
        super();
    }

    private isCurrentUserWorkspaceAdmin(currentSpace: Space) {
        return this.securityService.hasManagementAccess(currentSpace);
    }
    private isAssignee(assignees: UserDto[]): boolean {
        return assignees.some(
            (u) => u.UserId === this.appDataService.currentUserId
        );
    }
}
