import {
    autoserialize,
    autoserializeAs,
    deserialize,
    deserializeAs,
    SerializableEnumeration,
} from 'cerialize';

export class SuggestionElement {
    @autoserialize public SuggestionId!: string;
    @autoserialize public EntityReferenceId!: string;
    @autoserializeAs(Number) public SuggestionType!: SuggestionType;
    @autoserialize public Hashcode!: string;
    @autoserialize public Score!: number;
    @autoserialize public AttributeType!: string;
    @autoserialize public AttributePath!: string;
    @autoserialize public AttributeKey!: string;
    @autoserialize public Value: any;
    @autoserialize public VersionId!: string;
}

export enum SuggestionType {
    All,
    Tag,
    Dcp,
    Link,
}
SerializableEnumeration(SuggestionType);

export class SuggestionData {
    @deserialize public ReferenceId!: string;
    @deserialize public AttributePath!: string;
    @deserialize public AttributeKey!: string;
    @deserialize public VersionId!: string;
    @deserialize public SpaceId!: string;
    @deserializeAs(SuggestionElement)
    public SuggestionElements!: SuggestionElement[];
}
