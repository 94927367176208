import { Injectable } from '@angular/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ServerType } from '@datagalaxy/dg-object-model';
import {
    EntityGridView,
    ModuleEntitiesStore,
} from './module-entities-store.service';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import {
    getModuleEntitiesFlatDataSource,
    getModuleEntitiesTreeDataSource,
} from './data-source/module-entities-data-source';
import { EntityService } from '../../shared/entity/services/entity.service';
import {
    ServerFlatDataSourceConfig,
    ServerTreeDataSourceConfig,
    TDatasourceConfig,
} from '@datagalaxy/ui/grid';

@Injectable()
export class ModuleEntitiesService {
    constructor(
        private entityService: EntityService,
        private moduleEntitiesStore: ModuleEntitiesStore
    ) {}

    public getGridDataSource(
        view: EntityGridView,
        spaceIdr: SpaceIdentifier,
        serverTypes: ServerType[],
        filters?: Filter[],
        entityRootId?: string,
        sortKey?: string
    ): TDatasourceConfig<EntityItem> | undefined {
        if (!spaceIdr || !serverTypes) {
            return;
        }

        return view === 'flat'
            ? this.getFlatDataSource(
                  spaceIdr,
                  serverTypes,
                  filters,
                  entityRootId,
                  sortKey
              )
            : this.getTreeDataSource(
                  spaceIdr,
                  serverTypes,
                  filters,
                  entityRootId,
                  sortKey
              );
    }

    private getFlatDataSource(
        spaceIdr: SpaceIdentifier,
        serverTypes: ServerType[],
        filters?: Filter[],
        entityRootId?: string,
        sortKey?: string
    ): ServerFlatDataSourceConfig<EntityItem> {
        const sizeLimit = 10000;
        const pageSize = 50;

        const dataSource = getModuleEntitiesFlatDataSource(
            this.moduleEntitiesStore,
            this.entityService,
            spaceIdr,
            serverTypes,
            filters,
            entityRootId,
            sizeLimit,
            pageSize,
            this.moduleEntitiesStore.getAttributeKeys(),
            sortKey
        );

        this.moduleEntitiesStore.setLoading(true);
        void dataSource.getRows({ startRow: 0 });

        return dataSource;
    }

    private getTreeDataSource(
        spaceIdr: SpaceIdentifier,
        serverTypes: ServerType[],
        filters?: Filter[],
        entityRootId?: string,
        sortKey?: string
    ): ServerTreeDataSourceConfig<EntityItem> {
        const sizeLimit = 5000;
        const dataSource = getModuleEntitiesTreeDataSource(
            this.moduleEntitiesStore,
            this.entityService,
            spaceIdr,
            serverTypes,
            filters,
            sizeLimit,
            this.moduleEntitiesStore.getAttributeKeys(),
            sortKey
        );

        void this.loadRootEntities(
            spaceIdr,
            serverTypes,
            filters,
            entityRootId,
            sizeLimit,
            sortKey
        );

        return dataSource;
    }

    private async loadRootEntities(
        spaceIdr: SpaceIdentifier,
        serverTypes: ServerType[],
        filters?: Filter[],
        entityRootId?: string,
        sizeLimit?: number,
        sortKey?: string
    ) {
        this.moduleEntitiesStore.setLoading(true);

        const res = await this.entityService.getEntitiesForHierarchical(
            spaceIdr,
            serverTypes,
            filters,
            this.moduleEntitiesStore.getAttributeKeys(),
            entityRootId,
            sizeLimit,
            sortKey
        );

        this.moduleEntitiesStore.setRootEntities(res.Entities, {
            totalCount: res.TotalCount,
            hasReachLimit: res.Size >= sizeLimit,
        });
    }
}
