import { orderBy as _orderBy } from 'lodash';

type OneOrMany<T> = T | T[];

export class ArrayUtils {
    public static sortByIndexOf<TData, TValue>(
        orderedArray: TValue[],
        getValue: (o: TData) => TValue
    ): (a: TData, b: TData) => number;
    public static sortByIndexOf<T>(orderedArray: T[]): (a: T, b: T) => number;
    public static sortByIndexOf<TData, TValue>(
        orderedArray: TData[] | TValue[],
        getValue?: (o: TData) => TValue
    ) {
        return getValue
            ? (a: TData, b: TData) =>
                  this.compareByIndex(
                      getValue(a),
                      getValue(b),
                      orderedArray as TValue[]
                  )
            : (a: TData, b: TData) =>
                  this.compareByIndex(a, b, orderedArray as TData[]);
    }

    static orderBy<T>(
        data: T[],
        getValue?: OneOrMany<string | ((o: T) => number | string | boolean)>,
        order?: OneOrMany<'asc' | 'desc'>
    ) {
        return _orderBy(data, getValue, order);
    }

    static toRecord<T, U>(data: T[], getKeyValue: (o: T) => [string, U]) {
        return data.reduce((acc, item) => {
            const [key, value] = getKeyValue(item);
            acc[key] = value;
            return acc;
        }, {} as Record<string, U>);
    }

    private static compareByIndex<T>(a: T, b: T, orderedArray: T[]) {
        const indexA = orderedArray.indexOf(a);
        const indexB = orderedArray.indexOf(b);

        if (indexA === -1 && indexB === -1) {
            return 0;
        } else if (indexB === -1) {
            return -1;
        } else if (indexA === -1) {
            return 1;
        }
        return indexA - indexB;
    }
}
