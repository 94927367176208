<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.NotificationChannels.MsTeams.DeactivationModal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content translate>
    UI.NotificationChannels.MsTeams.DeactivationModal.description
</div>

<dxy-modal-footer
    [actionEnabled]="true"
    [noMandatory]="true"
    [actionBtnLbl]="
        'UI.NotificationChannels.ClientSettings.disconnect' | translate
    "
    [actionLoading]="loading$ | async"
    (onCloseCancel)="onCloseCancel()"
    (onActionPointerDown)="deactivateTeamsIntegration()"
    featureCode="MS_TEAMS_INTEGRATION_DEACTIVATION"
></dxy-modal-footer>
