import { Injectable } from '@angular/core';
import {
    IEntityToolParams,
    IEntityToolState,
} from '../interfaces/entity-panel.interface';
import { EntityService } from './entity.service';
import { CoreEventsService, IDragDropConfig } from '@datagalaxy/core-ui';
import { map, ReplaySubject } from 'rxjs';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import { AppSpaceService } from '../../../services/AppSpace.service';
import { StateName } from '@datagalaxy/webclient/routing';
import { AppEventsService } from '../../../services/AppEvents.service';
import { PreviewPanelService } from '../../shared-ui/preview-panel.service';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { DxyEntityPreviewPanelContentComponent } from '../../../entity-panels/dxy-entity-preview-panel-content/dxy-entity-preview-panel-content.component';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { ScreenService } from '../../../screens/screen.service';
import { BaseEntityToolService } from '../../../entity-panels/BaseEntityToolService';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';

/** ## Role:
 * Manage the entity preview panel content */
@Injectable({ providedIn: 'root' })
export class EntityPreviewPanelService extends BaseEntityToolService<
    IEntityToolParams,
    IEntityToolState
> {
    public static isPanelAvailable(entityType: EntityType) {
        switch (EntityTypeUtil.getServerType(entityType)) {
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.Property:
            case ServerType.SoftwareElement:
            case ServerType.DataProcessing:
            case ServerType.Diagram:
                return true;
            default:
                return false;
        }
    }

    public get hidden$() {
        return this.previewPanelService.visible$.pipe(
            map((visible) => !visible)
        );
    }
    public get isPanelHidden() {
        return this.previewPanelService.isPanelHidden;
    }
    public get isPanelVisible() {
        return !this.isPanelHidden;
    }

    public get dragDropConfig$() {
        return this.dragDropConfig.asObservable();
    }

    private dragDropConfig = new ReplaySubject<IDragDropConfig | null>();

    constructor(
        screenService: ScreenService,
        entityService: EntityService,
        dxyModalService: DxyModalService,
        appSpaceService: AppSpaceService,
        private coreEventsService: CoreEventsService,
        private previewPanelService: PreviewPanelService,
        private appEventsService: AppEventsService,
        private modalService: DxyModalService
    ) {
        super(screenService, entityService, appSpaceService);
        this.coreEventsService.windowKeyDownEscape$.subscribe(() => {
            if (!dxyModalService.isActiveModal) {
                this.hidePanel();
            }
        });
        this.appEventsService.moduleChanged$.subscribe(() => this.hidePanel());
        this.appEventsService.stateChanged$.subscribe((stateName: string) => {
            switch (stateName) {
                case StateName.ClientDashboard:
                case StateName.ClientTasks:
                case StateName.SpaceHome:
                    this.hidePanel();
            }
        });
    }

    public setupDragDrop(dragDropConfig: IDragDropConfig) {
        this.dragDropConfig.next(dragDropConfig);
    }
    public clearDragDrop() {
        this.dragDropConfig.next(null);
    }

    public hidePanel() {
        this.previewPanelService.hidePanel();
    }

    /** called by any component or service to show the panel */
    public async setupPanel(params: IEntityToolParams) {
        this.log('setupPanel', params);
        const previousEntityIdr = this.parameters?.entityIdr;
        super.setupPanel(params);
        this.parameters.enableToolDetails ??= true;

        const entityIdr = params.entityIdr;
        const sameEntityRequested = EntityIdentifier.areSame(
            entityIdr,
            previousEntityIdr
        );

        const readOnly = params.readOnly || this.modalService.isActiveModal;
        const noNavLink = params.noNavLink || this.modalService.isActiveModal;

        if (readOnly) {
            this.dragDropConfig.next(undefined);
        }

        this.previewPanelService.setupPanel({
            component: DxyEntityPreviewPanelContentComponent,
            inputs: {
                entityIdr,
                preferredTool: this.activeTool,
                readOnly,
                noNavLink,
            },
            isSameInput: sameEntityRequested,
        });
    }
}
