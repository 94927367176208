import { IXY } from '@datagalaxy/core-2d-util';

export enum ShapeType {
    unknown = 0,
    arrow,
    circle,
}

export enum ShapeId {
    none = 0,
    /** arrow, plain, 45 degree, foldback, 10x6 */
    arrowPlain45fb,
    /** arrow, plain, 90 degree, 10x10 */
    arrowPlain90,
    /** arrow, line, 90 degree, 12x6 */
    arrowLine90,
    /** arrow, line, 6x6 */
    arrowLine6x6,
    /** disc, plain, 10px diameter */
    circlePlain10,
}

export enum ShapeOrient {
    fixed = 0,
    /** auto oriented, at end of path */
    end,
    /** auto oriented, at start of path */
    start,
}

/** Definition of a shape for usage in a diagram */
export interface IDiagramShapeSpec {
    type: ShapeType;
    /** (for a points path shape) coordinates of points defining the shape's contour */
    points?: IXY[];
    /** (for a path shape) true to add a *z* to the path to close the shape's contour */
    close?: boolean;
    /** (for a path shape) value of the *d* attribute of the path element when the shape is a path */
    pathD?: string;
    /** distance between the visual tip of the arrow and the joint point. Defaults to 0.
     * may depend on thickness (the contour stroke width). Used to shorten the connector's path */
    recess?: number;
    /** shape contour stroke width */
    thickness?: number;

    /** needed when used as a marker - viewbox and marker width */
    vw?: number;
    /** needed when used as a marker - viewbox and marker height */
    vh?: number;
    /** needed when used as a marker - viewbox X coordinate - Defaults to -vw/2 */
    vx?: number;

    /** class name to apply to the shape's dom element */
    shapeClass?: string;

    /** (for a circle shape) */
    radius?: number;

    /** true to remove decimals when computing the final shape point coordinates */
    noDecimals?: boolean;

    /** true to add debug elements to the shape element */
    addDummies?: boolean;
}
