import { ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { dxyCustomTooltipDefaults } from './DxyMaterialOptionsConstants';
import { DxyTranslateModule } from './translate';

@NgModule({
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: dxyCustomTooltipDefaults,
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        // the default locale
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } },
    ],
    imports: [DxyTranslateModule.forChild({ extend: true })],
})
export class CoreUiModule {
    public static forRoot(): ModuleWithProviders<CoreUiModule> {
        return {
            ngModule: CoreUiModule,
            providers: [
                // Put here all custom providers (for example: mock services)
            ],
        };
    }
}
