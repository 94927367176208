export const salesforcePlatformFieldInfo = {
    server: {
        hint: {
            fr: "URL d'authentification de Salesforce, par exemple : https://login.salesforce.com",
            en: 'Salesforce authentication URL, for example: https://login.salesforce.com',
        },
    },
    clientId: {
        hint: {
            fr: "Identifiant de la clef d'accès d'une Connected App",
            en: 'Access key identifier of a Connected App',
        },
    },
    clientSecret: {
        hint: {
            fr: "Secret de la clef d'accès d'une Connected App",
            en: 'Secret of the access key of a Connected App',
        },
    },
    username: {
        hint: {
            fr: "Adresse mail de connexion à Salesforce de l'utilisateur",
            en: "User's Salesforce login email address",
        },
    },
    accessToken: {
        hint: {
            fr: "Identifiant du jeton d'accès initial associé à l'utilisateur",
            en: 'Initial access token identifier assigned to the user',
        },
    },
};
