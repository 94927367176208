import { OpenIdConfiguration } from 'angular-auth-oidc-client';

export const clientId = 'datagalaxy';

export const defaultOpenIdConfig: Partial<OpenIdConfiguration> = {
    clientId,
    responseType: 'code',
    scope: 'openid profile email dg_platform',
    autoUserInfo: false,
    silentRenew: true,
    useRefreshToken: true,
    ignoreNonceAfterRefresh: true,
    renewTimeBeforeTokenExpiresInSeconds: 120,
    historyCleanupOff: false,
};
