import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { BehaviorSubject } from 'rxjs';
import {
    EntityType,
    HierarchicalData,
    IHasHddData,
} from '@datagalaxy/dg-object-model';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { DiagramService } from '../../../diagrams/diagram/diagram.service';
import { ViewTypeService } from '../../../services/viewType.service';
import { DataUtil } from '../../util/DataUtil';
import { NavigationService } from '../../../services/navigation.service';
import { EntityPreviewPanelService } from '../services/entity-preview-panel.service';
import { EntityUiService } from '../services/entity-ui.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ISearchTermEvent } from '@datagalaxy/ui/search';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { EntityCardCellComponent } from '../../entityCard/entity-card/entity-card-cell.component';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * List all entity's assets grouped by module
 */
@Component({
    selector: 'app-entity-assets',
    templateUrl: './entity-assets.component.html',
    styleUrls: ['./entity-assets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        SearchInputComponent,
        NgFor,
        DxyCollapsibleComponent,
        forwardRef(() => EntityCardCellComponent),
        MatLegacyButtonModule,
        SpinnerComponent,
        AsyncPipe,
    ],
})
export class EntityAssetsComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() entityData: EntityItem;
    @Input() noNavLink: boolean;
    @Input() showHeader: boolean;

    public sections: IAssetSection[];
    public filteredSections: IAssetSection[];
    public loading = new BehaviorSubject<boolean>(false);

    public get hasSections() {
        return this.sections?.length;
    }
    public get emptyAssets() {
        return !this.hasSections && !this.loading.value;
    }
    public get isSearchAvailable() {
        return this.totalCount >= 10;
    }
    public get totalCount() {
        return CollectionsHelper.sum(
            this.sections,
            (section) => section.entities?.length
        );
    }

    protected actions: IActionOption<IHasHddData, unknown>[];

    constructor(
        private diagramService: DiagramService,
        private viewTypeService: ViewTypeService,
        private navigationService: NavigationService,
        private entityUiService: EntityUiService,
        private entityPreviewPanelService: EntityPreviewPanelService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();

        /**
         * For now since we don't have events for diagram operations we just refresh the assets' list
         * each time we open the preview
         * TODO: use events when they are available to maintain the assets' list (delete, add)
         */
        super.subscribe(this.entityPreviewPanelService.hidden$, (hidden) =>
            this.onPreviewPanelHiddenChange(hidden)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityData', () => {
            this.filteredSections = this.sections = null;
            this.initAsync();
        });
    }

    public onSearchTerm(event: ISearchTermEvent) {
        this.filteredSections = this.sections
            .map((section) => {
                const filteredEntities = StringUtil.filterSearched(
                    event.searchString,
                    section.entities,
                    (entity) =>
                        this.viewTypeService.getTechnicalOrDisplayName(entity)
                );
                return {
                    title: section.title,
                    count: filteredEntities?.length,
                    entities: filteredEntities,
                };
            })
            .filter((section) => !this.isSectionEmpty(section));
    }

    public async goToDiagram() {
        await this.navigationService.goToWithHierarchicalData(
            this.entityData.HddData
        );
    }

    private async initAsync() {
        const assets = await this.loadEntityAssets();
        this.actions = this.buildActions();
        this.filteredSections = this.sections =
            this.buildAssetsSections(assets);
    }

    protected onEntityClick(entity: HierarchicalData): void {
        void this.entityUiService.openPreviewOrDetailsOrDefault({
            HddData: entity,
        });
    }

    private buildActions(): IActionOption<IHasHddData, unknown>[] {
        const actions =
            this.entityUiService.getEntityCellNavigationMenu('DIAGRAM_ASSETS');
        return [actions];
    }

    private async loadEntityAssets() {
        this.loading.next(true);
        try {
            return await this.diagramService.getDiagramAssets(this.entityData);
        } finally {
            this.loading.next(false);
        }
    }

    private isSectionEmpty(section: IAssetSection) {
        return !section?.entities?.length;
    }

    private buildAssetsSections(assets: HierarchicalData[]) {
        const modules = CollectionsHelper.getEnumValues<DgModule>(DgModule);

        return modules
            .map((module) => {
                const moduleAssets = assets.filter(
                    (hd) =>
                        DataUtil.getModuleFromEntityType(hd.EntityType) ===
                        module
                );
                return {
                    title: `DgServerTypes.DataGalaxyModule.${DgModule[module]}`,
                    count: moduleAssets?.length,
                    entities: CollectionsHelper.orderBy(moduleAssets, [
                        (entity) => EntityType[entity.EntityType],
                        (entity) =>
                            this.viewTypeService.getTechnicalOrDisplayName(
                                entity
                            ),
                    ]),
                };
            })
            .filter((section) => !this.isSectionEmpty(section));
    }

    private async onPreviewPanelHiddenChange(hidden: boolean) {
        if (!hidden) {
            await this.initAsync();
        }
    }
}

export interface IAssetSection {
    title: string;
    count: number;
    entities: HierarchicalData[];
}
