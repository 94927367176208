import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultilingualStateService } from '../../multilingual-state.service';
import { LanguageIconComponent } from '@datagalaxy/webclient/multilingual/ui';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LanguageCardComponent } from '../language-card/language-card.component';
import {
    DxyTranslateModule,
    executeOnce,
    fadeInFadeOutAnimation,
    withLoading,
} from '@datagalaxy/core-ui';
import { BaseComponent } from '@datagalaxy/utils';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { MultilingualStateName } from '../../multilingual.routes';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { LanguageUserSettingsService } from '../language-user-settings.service';
import { selectPendingBatchesState } from './language-selector-button-state-selector';
import { TranslationProgressComponent } from '../../translations/translation-progress/translation-progress.component';

@Component({
    selector: 'dxy-language-selector-button',
    standalone: true,
    imports: [
        CommonModule,
        LanguageIconComponent,
        DxyButtonsModule,
        MatMenuModule,
        LanguageCardComponent,
        SpinnerComponent,
        MatTooltipModule,
        DxyTranslateModule,
        UIRouterModule,
        TranslationProgressComponent,
    ],
    templateUrl: './language-selector-button.component.html',
    styleUrls: ['./language-selector-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInFadeOutAnimation()],
})
export class LanguageSelectorButtonComponent extends BaseComponent {
    @Input() administrationLinkVisible = false;

    protected state$ = this.multilingualStateService.select(
        selectPendingBatchesState
    );

    protected selectedLanguageCode: string;
    protected adminRouteStateName = MultilingualStateName.Languages;

    constructor(
        private multilingualStateService: MultilingualStateService,
        currentUserService: CurrentUserService,
        private languageUserSettingsService: LanguageUserSettingsService
    ) {
        super();
        this.selectedLanguageCode =
            currentUserService.userInfo?.LanguageCode ?? 'en';
    }

    @withLoading()
    @executeOnce()
    protected async selectLanguage(languageCode: string) {
        this.selectedLanguageCode = languageCode;
        await this.languageUserSettingsService.setUserLanguage(languageCode);
    }
}
