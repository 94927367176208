import { AppConfigService } from '@datagalaxy/webclient/config';

export function apiBaseUrlFactory(appConfigService: AppConfigService) {
    const configUrl = appConfigService.apiHost;
    const clientUrl = window.location.origin;
    return getApiBaseUrl(configUrl, clientUrl);
}

export function getApiBaseUrl(configUrl, clientUrl) {
    if (isSubdomain(clientUrl, configUrl)) {
        return clientUrl;
    }
    return configUrl;
}

function isSubdomain(subdomainUrl: string, baseUrl: string): boolean {
    const hostname = new URL(baseUrl).hostname;
    const subHostname = new URL(subdomainUrl).hostname;

    const subDomainParts = subHostname.split('.');
    subDomainParts.shift();

    return subDomainParts.join('.') === hostname;
}
