export class DomainName {
    public static fromEmail(email: string): DomainName {
        const atIndex = email.indexOf('@');
        if (atIndex === -1) {
            throw new Error('Invalid email address');
        }

        const domain = email.slice(atIndex + 1);
        return new DomainName(domain);
    }

    private readonly value: string;

    constructor(value: string) {
        this.validate(value);
        this.value = value;
    }

    private validate(value: string): void {
        // Perform domain name validation logic here
        // You can use regular expressions or any other validation rules
        if (!value.match(/^[a-z0-9.-]+$/)) {
            throw new Error('Invalid domain name');
        }
    }

    toString(): string {
        return this.value;
    }
}
