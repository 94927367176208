<div
    class="suggestions-grid-container"
    *ngIf="(gridHiddenAndPlaceholderVisible$ | async) === false"
>
    <dxy-grid
        [columns]="columns"
        [config]="gridConfig"
        [items]="rows"
        (rowClick)="onRowClick($event)"
    ></dxy-grid>
</div>
<div
    *ngIf="gridHiddenAndPlaceholderVisible$ | async"
    class="placeholder-container"
>
    <img
        src="/images/placeholders/suggestions/no-suggestion.svg"
        alt="No suggestion image"
    />
    <div translate>UI.Suggestions.Panel.gridPlaceholder</div>
</div>
<div class="action-container" *ngIf="hasSuggestions">
    <dxy-show-more-button
        [loading]="isLoadingMore"
        [disabled]="allSuggestionsBeingApplied$ | async"
        *ngIf="showMoreButtonVisible$ | async"
        (click)="loadMore()"
    ></dxy-show-more-button>
    <app-suggestion-apply-all-button
        [disabled]="isLoadingMore"
        [suggestions]="rows"
        [suggestionType]="suggestionType"
    ></app-suggestion-apply-all-button>
</div>
<dxy-spinner *ngIf="isMainSpinnerVisible$ | async"></dxy-spinner>
