import { Ng2StateDeclaration } from '@uirouter/angular/interface';
import { ClientMainComponent } from './client-main/client-main.component';
import { Constants } from '../shared/util/Constants';
import { StateName } from '@datagalaxy/webclient/routing';
import { DxyClientErrorComponent } from '../errorPages/dxy-client-error/dxy-client-error.component';
import { SlackRedirectionQueryParams } from '../notification-channels/slack-redirection.types';
import { ClientFirstAccessComponent } from './first-access/client-first-access.component';
import { UserService } from '../services/user.service';
import { Transition } from '@uirouter/core';
import { handleQueryParamsOnClientTransitionSuccess } from './client-query-param-handler';
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import ParamKey = Nav.ParamKey;

export const clientRoute: Ng2StateDeclaration = {
    name: StateName.Client,
    url: `?from&${SlackRedirectionQueryParams.code}&${SlackRedirectionQueryParams.callbackIdentifier}`,
    views: { '@main': { component: ClientMainComponent } },
    onEnter: async (transition: Transition) => {
        if (transition.options()?.custom?.queryParamRemoval) {
            return;
        }
        transition.onSuccess({}, (transition) => {
            handleQueryParamsOnClientTransitionSuccess(transition);
        });
    },
};

export const legacyClientRoute: Ng2StateDeclaration = {
    name: StateName.Client,
    url: `/${UrlWord.client}/:${ParamKey.ClientId}?from&${SlackRedirectionQueryParams.code}&${SlackRedirectionQueryParams.callbackIdentifier}`,
    views: { '@main': { component: ClientMainComponent } },
};

const spaceListLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientSpacesList + '.**',
    url: `/${UrlWord.spaces}`,
    loadChildren: () =>
        import('../space/space-list-routing.module').then(
            (m) => m.SpaceListRoutingModule
        ),
};

const tasksLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientTasks + '.**',
    url: `/${UrlWord.tasks}`,
    loadChildren: () =>
        import('../tasks/task-routing.module').then((m) => m.TaskRoutingModule),
};

const clientErrorRoute: Ng2StateDeclaration = {
    name: StateName.ClientError,
    url: `/${UrlWord.error}`,
    views: {
        content: { component: DxyClientErrorComponent },
    },
};

const clientProfileLazyRoute: Ng2StateDeclaration = {
    name: StateName.UserLazyLoaded,
    url: `/${UrlWord.user}`,
    loadChildren: () => import('../user/user.module').then((m) => m.UserModule),
};

const clientAdminLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientAdminLazyLoaded,
    url: `/${UrlWord.administration}`,
    loadChildren: () =>
        import('../client-admin/client-admin.module').then(
            (m) => m.ClientAdminModule
        ),
};

const clientDashboardLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientDashboardLazyLoaded,
    url: `/${UrlWord.dashboard}`,
    loadChildren: () =>
        import('../widgets-dashboard/widgets-dashboard.module').then(
            (m) => m.WidgetsDashboardModule
        ),
};

const workspaceLazyRoute: Ng2StateDeclaration = {
    name: StateName.Space + '.**',
    url: `/${UrlWord.space}`,
    loadChildren: () =>
        import('../space/space.module').then((m) => m.SpaceModule),
};

const clientSearchResultsLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientSearchResults + '.**',
    url: `/${UrlWord.searchResults}`,
    loadChildren: () =>
        import('../search/search-routing.module').then(
            (m) => m.SearchRoutingModule
        ),
};

/**
 * @deprecated to replace with clientFirstAccessRouteAuthV2
 */
export const clientFirstAccessRoute: Ng2StateDeclaration = {
    name: StateName.ClientFirstAccess,
    url: `/${UrlWord.login}/${UrlWord.firstAccess}`,
    views: { '@main': { component: ClientFirstAccessComponent } },
    resolve: [
        {
            token: 'user',
            deps: [UserService],
            resolveFn: (userService: UserService) =>
                userService.getCurrentUser(),
        },
    ],
};

export const clientFirstAccessRouteAuthV2: Ng2StateDeclaration = {
    name: StateName.ClientFirstAccessAuthV2,
    url: `/${UrlWord.firstAccess}`,
    views: { '@main': { component: ClientFirstAccessComponent } },
    resolve: [
        {
            token: 'user',
            deps: [UserService],
            resolveFn: (userService: UserService) =>
                userService.getCurrentUser(),
        },
    ],
};

export const clientRoutes: Ng2StateDeclaration[] = [
    spaceListLazyRoute,
    clientSearchResultsLazyRoute,
    tasksLazyRoute,
    clientErrorRoute,
    clientProfileLazyRoute,
    clientAdminLazyRoute,
    clientDashboardLazyRoute,
    workspaceLazyRoute,
];
