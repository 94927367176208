export const oracleFieldInfo = {
    server: {
        hint: {
            fr: "Nom, adresse ou SID de l'instance Oracle en fonction du mode de connexion choisi",
            en: 'Name, address or SID of the Oracle instance depending on the chosen connection mode',
        },
    },
    port: {
        hint: {
            fr: "Port d'écoute du serveur Oracle",
            en: 'Oracle listener port',
        },
    },
    useSid: {
        hint: {
            fr: 'Mode de connexion au serveur Oracle',
            en: 'Oracle server connection mode',
        },
    },
    instance: {
        hint: {
            fr: 'Limite le périmètre à la base de données spécifiée',
            en: 'Limits the scope to the specified database',
        },
    },
    schema: {
        hint: {
            fr: 'Limite le périmètre au schéma spécifié',
            en: 'Limits the scope to the specified schema',
        },
    },
    user: {
        hint: {
            fr: 'Compte utilisateur Oracle',
            en: 'Oracle user account',
        },
    },
};
