import { NgZone } from '@angular/core';
import { DomUtil, THTMLElement } from '@datagalaxy/core-util';
import { environment } from '../../environments/environment';
import { EntityPanelTool } from '../entity-panels/entity-panels.types';
import { NavigateTo, NavigationService } from '../services/navigation.service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';

export interface IDgNavNewTabParams {
    hierarchicalData?: IHierarchicalData;
    navToLineage?: boolean;
    navToTab?: string;
    dockingPanelTool?: EntityPanelTool;
    navTo?: NavigateTo;
    wrapContent?: boolean;
    isFromBreadcrumb?: boolean;
}

/**
 * @deprecated replace with a link, no need to generate a temp cookie
 * Adds the right-click/open in new tab option to the specified element
 * */
export function setupRightClickOpenInNewTab(
    element: THTMLElement,
    info: IDgNavNewTabParams,
    navigationService: NavigationService,
    ngZone?: NgZone
) {
    const el = DomUtil.getElement(element);

    if (!el || !info || !info.hierarchicalData || !navigationService) {
        return;
    }

    const navTo =
        info.navTo ||
        navigationService.getNavTo(info.navToLineage, info.navToTab);

    //wraps the element or its content into a A tag
    const aElement = wrapElement(el, info.wrapContent);

    //on the right-click event, sets the href link and the cookie allowing to pass through the login
    const rightClickListenerRemover = DomUtil.addListener(
        aElement,
        'contextmenu',
        () =>
            setAHref(
                aElement,
                info.hierarchicalData,
                navigationService,
                navTo,
                true
            ).then(),
        ngZone,
        true
    );

    //prevents the left-click from using the href link, which is there only for right-click/open in new tab
    const clickListenerRemover = DomUtil.addListener(
        aElement,
        'click',
        (e) => e.preventDefault(),
        ngZone,
        true
    );

    return {
        element: aElement,
        rightClickListenerRemover,
        clickListenerRemover,
    };
}

function wrapElement(el: HTMLElement, wrapContent = false) {
    if (!el) return;

    const className = 'navto-href';

    if (el.tagName == 'A') {
        //no need to wrap
        el.classList.add(className);
        return el;
    }

    let a = wrapContent ? (el.firstChild as HTMLElement) : el.parentElement;
    if (a && a.tagName == 'A' && a.classList.contains(className)) {
        //already wrapped
        return a;
    }

    a = document.createElement('a');
    //so we'll know it has been wrapped
    a.classList.add(className);
    //since that was not a link, we dont want it to look as such
    a.style.textDecoration = 'none';
    a.style.cursor = 'default';

    if (wrapContent) {
        //wrap element's content into a A tag
        while (el.firstChild) a.appendChild(el.firstChild);
        el.appendChild(a);
    } else {
        //wrap element into a A tag
        if (!el.parentElement) {
            if (!environment.production) {
                console.warn(
                    'element has no parent. ensure it has one (or has been added to the dom)'
                );
            }
            return;
        }
        el.parentElement.insertBefore(a, el);
        a.appendChild(el);
    }

    return a;
}

async function setAHref(
    aElement: HTMLElement,
    hData: IHierarchicalData,
    navigationService: NavigationService,
    navTo?: NavigateTo,
    setCookie = false
) {
    const url = await navigationService.getUrlToGoToWithHierarchicalData(
        hData,
        navTo,
        true
    );
    if (!url) return false;

    aElement.setAttribute('href', url);

    if (setCookie) {
        navigationService.setCookieNavNewTab(url);
    }

    return true;
}
