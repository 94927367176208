import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { RestApiService } from '@datagalaxy/data-access';
import { GetClientNotificationChannelIntegrationsQueryResult } from './queries/GetClientNotificationChannelIntegrationsQuery';
import { GetUserNotificationSettingsQueryResult } from './queries/GetUserNotificationSettingsQueryResult';

@Injectable({ providedIn: 'root' })
export class NotificationChannelsApiService extends BaseService {
    constructor(private restApiService: RestApiService) {
        super();
    }

    public async getClientIntegrations() {
        return await this.restApiService.getPromise<GetClientNotificationChannelIntegrationsQueryResult>(
            'notifications/clientChannelIntegrations'
        );
    }

    public async getCurrentUserSettings() {
        return await this.restApiService.getPromise<GetUserNotificationSettingsQueryResult>(
            'notifications/userSettings'
        );
    }
}
