import { Injectable } from '@angular/core';
import { UserGridSettingsService } from '../../../user/grid-user-settings/user-grid-settings.service';
import { PersistedGridState } from '@datagalaxy/ui/grid';
import { CollectionsHelper } from '@datagalaxy/core-util';

@Injectable({ providedIn: 'root' })
export class EntityGridStateService {
    private cacheGridStates = new Map<string, PersistedGridState>();

    constructor(private userGridSettingsService: UserGridSettingsService) {}

    public async getGridStateAttributeKeys(gridId: string): Promise<string[]> {
        const gridState = await this.getGridState(gridId);

        return this.getAttributeKeysFromGridState(gridState);
    }

    public async getGridState(
        gridId?: string
    ): Promise<PersistedGridState | undefined> {
        if (!gridId) {
            return;
        }

        if (this.cacheGridStates.has(gridId)) {
            return this.cacheGridStates.get(gridId);
        }

        return await this.userGridSettingsService.getGridState(gridId);
    }

    public async saveGridState(
        gridId: string,
        gridState: PersistedGridState
    ): Promise<void> {
        await this.userGridSettingsService.saveGridState(gridId, gridState);
        this.cacheGridStates.set(gridId, gridState);
    }

    /**
     * Get the difference between the previous attribute keys and the new ones
     * It will return only the added keys.
     */
    public async getAttributeKeysDiff(
        gridState: PersistedGridState,
        gridId: string
    ): Promise<string[]> {
        const previousKeys = await this.getGridStateAttributeKeys(gridId);

        await this.saveGridState(gridId, gridState);

        const attributeKeys = this.getAttributeKeysFromGridState(gridState);

        return CollectionsHelper.difference(attributeKeys, previousKeys);
    }

    public getAttributeKeysFromGridState(
        gridState: PersistedGridState
    ): string[] {
        return (
            gridState?.columns
                .filter((column) => !column.hidden)
                ?.map((column) => column.id)
                ?.filter((id) => !!id) || []
        );
    }
}
