import { NgModule } from '@angular/core';
import { AttributeFinderService } from './attribute-finder.service';
import { ATTRIBUTES_FINDER_TOKEN } from '@datagalaxy/webclient/attribute/feature';

@NgModule({
    providers: [
        {
            provide: ATTRIBUTES_FINDER_TOKEN,
            useClass: AttributeFinderService,
        },
    ],
})
export class DxyAttributesModule {}
