import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGridCellComponent } from '../grid-cell.types';
import { GridColumnUtils } from '../../grid-column/grid-column.utils';
import { ITextColDef } from '../../grid-column/grid-column.types';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    standalone: true,
    selector: 'dxy-text-grid-cell',
    templateUrl: './text-grid-cell.component.html',
    styleUrls: ['./text-grid-cell.component.scss'],
    imports: [EllipsisTooltipDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextGridCellComponent<TRow> implements IGridCellComponent<TRow> {
    @Input() col?: ITextColDef<TRow>;
    @Input() item?: TRow;

    protected get text() {
        return GridColumnUtils.getColumnValue(this.col, this.item);
    }
}
