import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import { IRect, IXYRect } from '@datagalaxy/core-2d-util';

export class ABaseDiagramDto {
    @deserialize ReferenceId!: string;
}

//#region Object Types on Parameters (to use in UpdateDiagramContent)
export class ABaseDiagramItem {
    public static readonly baseKeys: (keyof ABaseDiagramItem)[] = [
        'ReferenceId',
    ];

    // NOTE: Mandatory, and set by client (using generateGuid()) on new objects
    @serialize ReferenceId!: string;
}

export enum PublishingStatus {
    Private,
    Shared,
    Public,
}
SerializableEnumeration(PublishingStatus);

export enum DiagramKind {
    undefined = 0,
    Free,
    MonoSourceDatabase,
    MultiSources,
}

@inheritSerialization(ABaseDiagramDto)
export class ABaseDiagramPositionDto
    extends ABaseDiagramDto
    implements IXYRect, IRect
{
    @deserialize Top!: number;
    @deserialize Left!: number;
    @deserialize Width!: number;
    @deserialize Height!: number;

    //@region IXYRect, IRect
    //#region IXY
    public get x() {
        return this.Left;
    }

    public set x(value: number) {
        this.Left = value;
    }

    public get y() {
        return this.Top;
    }

    public set y(value: number) {
        this.Top = value;
    }

    //#endregion
    //#region ITopLeft
    public get top() {
        return this.Top;
    }

    public set top(value: number) {
        this.Top = value;
    }

    public get left() {
        return this.Left;
    }

    public set left(value: number) {
        this.Left = value;
    }

    //#endregion
    //#region IWidthHeight
    public get width() {
        return this.Width;
    }

    public set width(value: number) {
        this.Width = value;
    }

    public get height() {
        return this.Height;
    }

    public set height(value: number) {
        this.Height = value;
    }

    //#endregion
    //#endregion
}

@inheritSerialization(ABaseDiagramItem)
export class ABaseDiagramPositionItem extends ABaseDiagramItem {
    public static readonly basePositionKeys: (keyof ABaseDiagramPositionItem)[] =
        [...ABaseDiagramItem.baseKeys, 'Top', 'Left', 'Width', 'Height'];

    @serialize Top!: number;
    @serialize Left!: number;
    @serialize Width!: number;
    @serialize Height!: number;
}
