<form [formGroup]="diagramForm">
    <div class="diagram-type-selector">
        <div class="illustration">
            <img [src]="selectedDiagramType.imageUrl" />
        </div>
        <div class="type-buttons">
            <ng-container *ngFor="let diagramType of diagramTypes">
                <button
                    *ngIf="
                        !isTypeReadOnly ||
                        (isTypeReadOnly &&
                            diagramType.diagramKind == diagramKind)
                    "
                    mat-flat-button
                    type="button"
                    [class.active]="diagramType == selectedDiagramType"
                    (click)="selectDiagramType(diagramType)"
                >
                    {{ diagramType.labelKey | translate }}
                    <span
                        *ngIf="diagramType.isBeta"
                        class="dg5-beta-badge"
                    ></span>
                </button>
            </ng-container>
        </div>
        <div
            class="description"
            [translate]="selectedDiagramType.descriptionKey"
        ></div>
    </div>
    <dxy-field-text
        formControlName="displayName"
        labelKey="UI.Dialog.NewItem.Diagram.lblInstructions"
        [errorMessageText]="
            diagramForm.controls.displayName.errors?.existingName
        "
        [mandatory]="true"
        (ngModelChange)="onChanged()"
        [readonly]="isNameReadonly"
    ></dxy-field-text>
    <dxy-entity-selector-field
        *ngIf="needsSource(selectedDiagramType)"
        formControlName="sourceIdr"
        labelKey="DgServerTypes.BaseData.fields.ModelId"
        [options]="entitySelectorOptions"
        [mandatory]="true"
        [readonly]="isSourceReadonly"
        (openClose)="panelOpenClose.emit($event)"
        (ngModelChange)="onChanged()"
    ></dxy-entity-selector-field>
</form>
