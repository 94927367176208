import { Inject, Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, mergeMap, Observable, throwError } from 'rxjs';
import {
    BaseServiceParameter,
    IApiConfig,
    BACKEND_API_CONFIG,
} from '@datagalaxy/data-access';
import { BaseService } from '@datagalaxy/core-ui';
import { LoggingHttpService } from './logging-http.service';
import { CoreUtil } from '@datagalaxy/core-util';

@Injectable()
export class UnauthorizedInterceptor
    extends BaseService
    implements HttpInterceptor
{
    protected get baseUrl() {
        return this.config.baseUrl;
    }

    constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
        private loggingHttpService: LoggingHttpService
    ) {
        super();
        this.debug = true;
    }

    intercept(
        req: HttpRequest<BaseServiceParameter>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const userSessionId = this.config.getUserSessionId?.();

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status === 401 &&
                    userSessionId &&
                    !req.url.includes('Login/KeepAlive')
                ) {
                    return this.tryToLoginAndRetryRequest(req, next);
                }
                return throwError(() => error);
            })
        );
    }

    private tryToLoginAndRetryRequest(
        req: HttpRequest<BaseServiceParameter>,
        next: HttpHandler
    ) {
        this.loggingHttpService.notifySessionRecovering();

        return this.loggingHttpService.sessionRecovered$.pipe(
            mergeMap((recovered) => {
                if (recovered) {
                    return next.handle(
                        req.clone({
                            body: CoreUtil.assign(req.body, {
                                UserSessionId:
                                    this.config?.getUserSessionId?.(),
                            }),
                        })
                    );
                }
                return next.handle(req);
            })
        );
    }
}
