import { IUserCommonDto } from './user-common';
import { deserialize, deserializeAs } from 'cerialize';
import { metaBotUserFullName, metaBotUserId } from './user.constants';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';

function getFirstNonSpaceChar(text: string | undefined) {
    return text?.trim()[0] || '';
}

export class UserDto implements IUserCommonDto {
    @deserialize ReferenceId!: string;
    @deserialize LastName!: string;
    @deserialize FirstName!: string;
    @deserialize Email!: string;
    @deserialize UserId!: string;
    @deserialize ProfileImageHash!: string;
    @deserialize ProfileThumbnailHash!: string;
    @deserializeAs(LicenseLevel) LicenseLevel!: LicenseLevel;

    constructor(opt?: { firstName?: string; lastName?: string }) {
        this.FirstName = opt?.firstName || '';
        this.LastName = opt?.lastName || '';
    }

    public get FullName(): string {
        if (this.isMetaBot) {
            return metaBotUserFullName;
        }
        return `${this.FirstName ?? ''} ${this.LastName ?? ''}`.trim();
    }

    public get Initials(): string {
        return (
            getFirstNonSpaceChar(this.FirstName) +
            getFirstNonSpaceChar(this.LastName)
        ).toUpperCase();
    }

    public get isMetaBot(): boolean {
        return this.UserId === metaBotUserId;
    }
}
