<div *ngIf="!isInnerSection">
    <button
        class="base-toggle-btn"
        [ngClass]="btnClass"
        [matMenuTriggerFor]="menu"
        (menuOpened)="onOpenClose(true); onToggle()"
        (menuClosed)="onOpenClose(false)"
    >
        <i [ngClass]="btnGlyphClass"></i>
        <span>{{ btnTitle }}</span>
        <span *ngIf="showDropdownCaret" class="glyph-arrow-drop-down"></span>
    </button>

    <mat-menu
        #menu="matMenu"
        class="dg5-mat-menu relative-menu dropdown-section--mat-menu"
    >
        <dxy-search-input
            *ngIf="enableSearch"
            #searchInputRef
            class="search-input no-border-top not-an-item"
            [searchString]="searchString"
            (onSearchString)="onSearchString($event)"
            [showCancelSearch]="true"
        ></dxy-search-input>
        <div class="dropdown-selector-body not-an-item">
            <ul>
                <li *ngFor="let section of sections" class="section-wrapper">
                    <ng-container *ngIf="isSectionVisible(section)">
                        <!-- caret & title -->
                        <div
                            *ngIf="sectionTitleVisible"
                            class="section-title"
                            (click)="onClickSection($event, section)"
                        >
                            <button
                                mat-icon-button
                                class="dg5-small-icon-button"
                            >
                                <i
                                    class="glyph-arrow-drop-down"
                                    [ngClass]="{
                                        'glyph-arrow-drop-down--collapsed':
                                            !section.isExpanded
                                    }"
                                ></i>
                            </button>
                            <div class="title-container" translate>
                                {{ section.textTranslateKey }}
                            </div>
                        </div>
                        <!-- items -->
                        <ul
                            *ngIf="areOptionsVisible(section)"
                            class="items-list"
                            [class.flat]="flattenSections"
                        >
                            <li
                                *ngFor="let option of section.filteredOptions"
                                class="option-wrapper menu-item"
                                role="menuitem"
                                (click)="onClickOption(option, $event)"
                            >
                                <a class="option-text">
                                    <i
                                        [ngClass]="getGlyphClass(option)"
                                        class="option-text-icon"
                                    ></i>
                                    <span>{{ getLabel(option) }}</span>
                                </a>
                            </li>
                        </ul>
                        <!-- subsections -->
                        <dxy-dropdown-section
                            *ngIf="areSubSectionsVisible(section)"
                            [sections]="section.subSections"
                            [isInnerSection]="true"
                            (onLogFunctional)="onLogFunctionalInternal($event)"
                            (openClose)="onOpenClose($event)"
                        ></dxy-dropdown-section>
                    </ng-container>
                </li>
            </ul>
        </div>
    </mat-menu>
</div>
<ul *ngIf="isInnerSection" class="sections-dropdown-menu">
    <li *ngFor="let section of sections" class="section-wrapper">
        <ng-container *ngIf="isSectionVisible(section)">
            <!-- caret & title -->
            <div
                class="section-title"
                (click)="onClickSection($event, section)"
            >
                <button mat-icon-button class="dg5-small-icon-button">
                    <i
                        class="glyph-arrow-drop-down"
                        [ngClass]="{
                            'glyph-arrow-drop-down--collapsed':
                                !section.isExpanded
                        }"
                    ></i>
                </button>
                <div class="title-container" translate>
                    {{ section.textTranslateKey }}
                </div>
            </div>
            <!-- items -->
            <ul *ngIf="areOptionsVisible(section)" class="items-list">
                <li
                    *ngFor="let option of section.filteredOptions"
                    class="option-wrapper menu-item"
                    role="menuitem"
                    (click)="onClickOption(option, $event)"
                >
                    <a class="option-text">
                        <i
                            [ngClass]="getGlyphClass(option)"
                            class="option-text-icon"
                        ></i>
                        <span>{{ getLabel(option) }}</span>
                    </a>
                </li>
            </ul>
            <!-- subsections -->
            <dxy-dropdown-section
                *ngIf="areSubSectionsVisible(section)"
                [sections]="section.subSections"
                [isInnerSection]="true"
                (onLogFunctional)="onLogFunctionalInternal($event)"
                (openClose)="onOpenClose($event)"
            ></dxy-dropdown-section>
        </ng-container>
    </li>
</ul>
