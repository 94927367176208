export const hubspotFieldInfo = {
    apiUrl: {
        hint: {
            fr: 'URL de l’API de Hubspot',
            en: 'Hubspot API URL',
        },
    },
    accessTokenPwd: {
        hint: {
            fr: "Jeton d'accès d'une application privée, exemple : pat-eu1-65302**-****-****-****-************",
            en: 'Access token of a private application, example: pat-eu1-65302**-****-****-****-************',
        },
    },
};
