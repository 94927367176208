import { Component } from '@angular/core';
import { BaseCellComponent } from '../BaseCellComponent';
import { INumberSelectorCellData } from './number-selector-cell.types';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

@Component({
    selector: 'dxy-number-selector-cell',
    templateUrl: 'number-selector-cell.component.html',
    styleUrls: ['./number-selector-cell.component.scss'],
    standalone: true,
    imports: [MatLegacyInputModule, FormsModule, NgClass],
})
export class DxyNumberSelectorCellComponent extends BaseCellComponent<
    any,
    INumberSelectorCellData
> {
    public ngModelOptions: any;

    constructor() {
        super();
    }

    protected initInternal() {
        this.ngModelOptions = this.value.updateOnBlur
            ? { updateOn: 'blur' }
            : undefined;
    }

    public onValueChange(value: number) {
        if (!this.value.onValueChanged) {
            return;
        }
        if (this.value.min != undefined) {
            value = Math.max(value, this.value.min);
        }
        if (this.value.max != undefined) {
            value = Math.min(value, this.value.max);
        }
        this.value.onValueChanged(value);
    }
}
