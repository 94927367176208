export enum EntityCreationOrigin {
    unknown = 0,
    globalAddButton,
    dockingPane,
    burgerMenu,
    burgerMenuCreateChild,
    gridAddButton,
    dashboardCloneButton,
    diagramClone,
    diagramElementClone,
    diagramAddEntityButton,
}
