import { FunnelService, FunnelStep } from '@datagalaxy/ui/funnel';
import { TranslateService } from '@ngx-translate/core';
import { StartMultilingualActivationComponent } from './start-multilingual-activation/start-multilingual-activation.component';
import { Injectable } from '@angular/core';
import { SourceLanguageStepComponent } from './source-language-step/source-language-step.component';
import { TargetLanguageStepComponent } from './target-language-step/target-language-step.component';
import { AttributesSelectionStepComponent } from './attributes-selection-step/attributes-selection-step.component';
import { MultilingualRecapStepComponent } from './multilingual-recap-step/multilingual-recap-step.component';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { MultilingualStateService } from '../../multilingual-state.service';
import { LexiconStepComponent } from './lexicon-step/lexicon-step.component';

@Injectable()
export class MultilingualFunnelService {
    private steps: FunnelStep[] = [
        {
            title: this.translateService.instant(
                'Multilingual.Administration.activate'
            ),
            componentType: StartMultilingualActivationComponent,
            stepperVisible: false,
        },
        {
            title: this.translateService.instant(
                'Multilingual.Administration.sourceLanguage'
            ),
            componentType: SourceLanguageStepComponent,
            stepperVisible: true,
        },
        {
            title: this.translateService.instant(
                'Multilingual.Administration.targetLanguage'
            ),
            componentType: TargetLanguageStepComponent,
            stepperVisible: true,
        },
        {
            title: this.translateService.instant(
                'Multilingual.Administration.LexiconStep.title'
            ),
            componentType: LexiconStepComponent,
            stepperVisible: true,
        },
        {
            title: this.translateService.instant(
                'Multilingual.Administration.AttributesSelectionStep.title'
            ),
            componentType: AttributesSelectionStepComponent,
            stepperVisible: true,
        },
        {
            title: this.translateService.instant(
                'Multilingual.Administration.RecapStep.title'
            ),
            componentType: MultilingualRecapStepComponent,
            stepperVisible: true,
        },
    ];

    constructor(
        private funnelService: FunnelService,
        private translateService: TranslateService,
        private multilingualApiService: MultilingualApiService,
        private multilingualStateService: MultilingualStateService
    ) {}

    public async startFunnel() {
        await this.funnelService.startFunnel({ steps: this.steps });
        const settings = await this.multilingualApiService.getSettings();
        this.multilingualStateService.updateSettings(settings);
    }
}
