<form
    class="spotlight-form"
    [class.form-search-loading]="searching$ | async"
    (ngSubmit)="onSubmit()"
>
    <div
        class="filter-carousel-wrapper"
        [class.full-width]="isFilterCarouselFullWidth"
    >
        <app-attribute-filter-carousel
            [displayMode]="carouselDisplayMode"
            [filterItems]="displayedFilters$ | async"
            [startAtEnd]="true"
            (filterChange)="onFilterItemAction($event)"
            (scrollPrevious)="onFilterScroll()"
            (scrollNext)="onFilterScroll()"
        ></app-attribute-filter-carousel>
    </div>
    <input
        matInput
        type="text"
        name="searchTerm"
        [placeholder]="placeholder || ''"
        [class.no-filters]="displayedFiltersEmpty$ | async"
        [(ngModel)]="searchTerm"
        (focus)="onFocusSearchInput()"
        (document:keydown.escape)="onEscapeKey()"
        [disabled]="disabled"
        autocomplete="off"
    />
    <button
        mat-icon-button
        type="submit"
        class="spotlight-submit-button"
        [disabled]="disabled"
    >
        <i
            *ngIf="(searching$ | async) === false"
            class="glyph glyph-search"
        ></i>
        <i *ngIf="searching$ | async" class="glyph glyph-reload"></i>
    </button>
</form>

<dxy-spotlight-result-container
    class="spotlight-result-container"
    [ngClass]="displayMode"
    [class.opened]="isResultPaneVisible"
    [filteredUsers]="filteredUsers"
    [filteredTags]="filteredTags"
    [filteredDomains]="filteredDomains"
    [lastViewedEntities]="lastViewedEntities"
    [lastSearchViews]="lastSearchesViews"
    (onSelectFilterItem)="onSelectFilterItem($event)"
    (onSelectQuickFilter)="onSelectQuickFilter($event)"
    (onClickFilteredSearch)="onSelectRecentSearch($event)"
    (onClickResultItem)="onClickResultItem()"
    (onModuleShowMore)="onShowAllResult()"
    (onClearLastSearches)="onClearLastSearches()"
    (onSearch)="onSearch('dxy-spotlight-result-container')"
    [previewResult]="previewResult"
    (spaceVersionSelected)="onSpaceVersionSelected($event)"
    (onClosePane)="onCloseResult()"
    [spaceIdr]="selectedSpaceIdr"
    (onSubmit)="onSubmit()"
    [loading]="(searching$ | async) || (loading$ | async)"
    [currentSearch]="currentSearch"
    [quickFilters]="quickFilters$ | async"
></dxy-spotlight-result-container>
