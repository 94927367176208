<div class="modal-header">
    <div class="bulk-modal-title">
        <h4 mat-dialog-title class="modal-title" translate>
            UI.BulkEdit.btnAction
        </h4>
        <span>{{ getSelectedCountInfo() }}</span>
    </div>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <dxy-spinner *ngIf="isSaving"></dxy-spinner>
    <div *ngIf="!isSaving">
        <div *ngIf="isSummaryDisplayed" class="msg-container" role="alert">
            <span>{{ summaryMessage }}</span>
        </div>
        <div *ngIf="hasBulkAction" class="field-group">
            <p class="instruction" translate>
                UI.BulkEdit.attributeInstruction
            </p>
            <dxy-field-select
                class="field"
                labelKey="UI.BulkEdit.attribute"
                [adapter]="attributeAdapter"
                [search]="true"
                (openClose)="onPanelOpenClose($event)"
            ></dxy-field-select>
        </div>
        <div *ngIf="selectedFieldInfo && hasBulkAction" class="field-group">
            <p class="instruction" translate>
                UI.BulkEdit.attributeEditInstruction
            </p>
            <dxy-entity-attribute-input
                class="field"
                [fieldInfo]="selectedFieldInfo"
                (onPanelOpenClose)="onPanelOpenClose($event)"
            ></dxy-entity-attribute-input>
        </div>
        <div *ngIf="!hasWriteAccess" class="alert alert-warning" role="alert">
            <span class="glyph glyph-warning"></span>
            <span translate>UI.BulkEdit.noWriteAccessMsg</span>
            <br />
            <span translate>UI.BulkEdit.noWriteAccessDataList</span>
            <div class="statistics-container">
                <ul>
                    <li *ngFor="let link of noWriteAccessEntityDataList">
                        {{ link.DisplayName }}
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
            <span class="glyph glyph-close-circle-filled"></span>
            {{ errorMessage }}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="onCloseCancel()" mat-flat-button [disabled]="isSaving">
        <span [translate]="cancelButtonTranslateKey"></span>
    </button>
    <dxy-split-button
        (click)="onSubmit()"
        color="primary"
        [options]="splitBtnOptions"
        [disabled]="isSaving || !isBulkEnabled"
        [textKey]="submitButtonTranslateKey"
        [showSplitButton]="showFooterDropDownButton"
        [dxyLogFunctional]="featureCode"
        (openClose)="onPanelOpenClose($event)"
    ></dxy-split-button>
</div>
