import { Inject, Injectable } from '@angular/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CoreUtil } from '@datagalaxy/core-util';
import { DXY_TRANSLATE_CONFIG } from './translate.module';
import { ITranslationConfig } from './translate.types';

@Injectable()
export class DxyTranslateMessageFormatCompiler extends TranslateMessageFormatCompiler {
    constructor(
        @Inject(DXY_TRANSLATE_CONFIG)
        private config?: ITranslationConfig
    ) {
        super();
    }

    public compileTranslations(translations: any, lang: string): any {
        const { doCheckAllOnError, doReplaceShortcutAll } =
            this.config.messageFormatPatch;

        if (!doCheckAllOnError && !doReplaceShortcutAll) {
            this.log('using genuine TranslateMessageFormatCompiler');
            return;
        }

        this.log('compileTranslations', lang);
        this.time('compileTranslations' + lang);

        if (doReplaceShortcutAll) {
            this.replaceShortcutAll(translations);
        }

        let result: object;
        try {
            result = super.compileTranslations(translations, lang);
            this.log('messageFormat.compile OK', lang, result);
        } catch (e) {
            if (!CoreUtil.isProduction) {
                console.warn('messageFormat.compile', lang, e);
            }
            if (doCheckAllOnError) {
                this.checkAll(lang, translations);
            }
        }

        this.timeEnd('compileTranslations' + lang);

        return result;
    }

    /** replace recursively @:path shortcuts by linked value */
    private replaceShortcutAll(translations: object) {
        this.visitAll(translations, (v, k, o) => {
            if (!v.startsWith('@:')) {
                return;
            }
            let value = v;
            do {
                value = CoreUtil.getObjectPathValue(
                    translations,
                    value.slice(2)
                );
            } while (value?.startsWith('@:'));

            if (value) {
                o[k] = value;
            } else {
                this.log(`${v} shortcut links to no existing value`);
            }
        });
    }
    /** compiles each text individually to show where the errors are */
    private checkAll(lang: string, translations: object) {
        this.log('checkAll', lang);
        this.visitAll(translations, (v, k, _, stack) => {
            try {
                this.compile(v[k], lang);
            } catch (e) {
                this.log(v, [...stack, k].join('.'), e);
            }
        });
        this.log('checkAll-done', lang);
    }
    /** executes the given function with each string property of the given object, recursively */
    private visitAll(
        obj: object,
        fn: (v: string, k: string, o: object, stack: string[]) => void
    ) {
        const stack: string[] = [];
        const visit = (o: object) =>
            Object.keys(o).forEach((k) => {
                const v = o[k];
                if (typeof v == 'string') {
                    fn(v, k, o, stack);
                } else if (typeof v == 'object') {
                    stack.push(k);
                    visit(v);
                    stack.pop();
                }
            });
        visit(obj);
    }

    private log(...args: unknown[]) {
        this.config.messageFormatPatch.doLog && console.log(...args);
    }
    private time(label?: string) {
        this.config.messageFormatPatch.doLog &&
            (console as { time?(label: string): void }).time(label);
    }
    private timeEnd(label?: string) {
        this.config.messageFormatPatch.doLog &&
            (console as { timeEnd?(label: string): void }).timeEnd(label);
    }
}
