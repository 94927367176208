<div *ngIf="isReadonly" class="read-only-field">
    <label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
        >{{ label }}</label
    >
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <div class="value-container">
        <p class="value-text-readonly">{{ valueText }}</p>
    </div>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>

<mat-form-field
    *ngIf="!isReadonly && !useRange"
    floatLabel="always"
    appearance="standard"
    [class.dg5-compact]="compact"
>
    <mat-label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
        >{{ label }}</mat-label
    >
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>

    <input
        matInput
        #fieldControl="matInput"
        #dateInputRef
        [(ngModel)]="date"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="datePicker"
        [placeholder]="placeholderDate"
        [disabled]="disabled"
        [required]="required"
        [errorStateMatcher]="errorStateMatcher"
    />
    <mat-datepicker-toggle
        *ngIf="isClearBtnVisible"
        matSuffix
        (click)="clear($event)"
        [matTooltip]="'UI.FieldDate.clear' | translate"
        class="clear-btn-icon"
    >
        <mat-icon matDatepickerToggleIcon>
            <span class="glyph-cancelsearch"></span>
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle
        #pickerToggle
        matSuffix
        [for]="datePicker"
        (keydown)="onPickerToggleKeyDown($event)"
        class="picker-btn-icon"
    >
        <mat-icon matDatepickerToggleIcon>
            <span class="glyph-date"></span>
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker
        #datePicker
        xPosition="end"
        (opened)="onPickerOpenedClosed(true)"
        (closed)="onPickerOpenedClosed(false)"
    ></mat-datepicker>

    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>

<mat-form-field
    *ngIf="!readonly && useRange"
    floatLabel="always"
    appearance="standard"
    [class.dg5-compact]="compact"
>
    <mat-label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
        >{{ label }}</mat-label
    >
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>

    <mat-date-range-input #fieldControl [rangePicker]="rangePicker">
        <input
            matStartDate
            #startInputRef
            [(ngModel)]="start"
            [ngModelOptions]="modelOptionsForUpdateOn"
            [placeholder]="placeholderStart"
            [min]="minDate"
            [max]="maxDate"
            [disabled]="disabled"
            [required]="required"
            [errorStateMatcher]="errorStateMatcher"
        />
        <input
            matEndDate
            #endInputRef
            [(ngModel)]="end"
            [ngModelOptions]="modelOptionsForUpdateOn"
            [placeholder]="placeholderEnd"
            [min]="minDate"
            [max]="maxDate"
            [disabled]="disabled"
            [required]="required"
            [errorStateMatcher]="errorStateMatcher"
        />
    </mat-date-range-input>
    <mat-datepicker-toggle
        *ngIf="isClearBtnVisible"
        matSuffix
        (click)="clear($event)"
        [matTooltip]="'UI.FieldDate.clear' | translate"
        class="clear-btn-icon"
    >
        <mat-icon matDatepickerToggleIcon>
            <span class="glyph-cancelsearch"></span>
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle
        #pickerToggle
        matSuffix
        [for]="rangePicker"
        (keydown)="onPickerToggleKeyDown($event)"
        class="picker-btn-icon"
    >
        <mat-icon matDatepickerToggleIcon>
            <span class="glyph-date"></span>
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker
        #rangePicker
        xPosition="end"
        (opened)="onPickerOpenedClosed(true)"
        (closed)="onPickerOpenedClosed(false)"
    ></mat-date-range-picker>

    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
