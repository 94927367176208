import { CollectionsHelper } from './collections-helper';
import { StringUtil } from './string-util';
import { CoreUtil } from './core-util';

/** provides logging activation/deactivation for components and services */
export class DebugUtil {
    public static get globalObject() {
        return window['dgdebug'] ?? (window['dgdebug'] = {});
    }
    private static readonly localStorageKey = 'dg.debug';
    private static types: Map<string, IDebugTypeInfo>;
    // Needs getter when access window object because at build time window is undefined and Build util imports core-util
    private static get storage() {
        return window.localStorage;
    }

    //#region for components and services

    /** registers the given app component type, and returns its current debugging info */
    public static registerController(ctorName: string) {
        if (!DebugUtil.types) {
            DebugUtil.init();
        }

        let info = DebugUtil.types.get(ctorName);
        if (!info) {
            DebugUtil.types.set(
                ctorName,
                (info = {
                    name: ctorName,
                    type: ComponentType.controller,
                })
            );
        }
        return info;
    }

    public static registerService(serviceInstance: IDebugLog) {
        if (!DebugUtil.types) {
            DebugUtil.init();
        }

        const ctorName = serviceInstance.constructor.name;
        let info = DebugUtil.types.get(ctorName);
        if (info) {
            info.instance = serviceInstance;
        } else {
            DebugUtil.types.set(
                ctorName,
                (info = {
                    name: ctorName,
                    type: ComponentType.service,
                    instance: serviceInstance,
                })
            );
        }
        return info;
    }
    public static unregisterService(serviceInstance: IDebugLog) {
        const info = CollectionsHelper.findFirstInMap(
            DebugUtil.types,
            (o) => o.instance === serviceInstance
        );
        if (info) {
            DebugUtil.types.delete(info.name);
        }
    }

    //#endregion

    //#region private

    private static init() {
        DebugUtil.types = new Map<string, IDebugTypeInfo>();

        const dgdebug = DebugUtil.globalObject;
        dgdebug.log = DebugUtil.log;
        dgdebug.clearCache = DebugUtil.clearCache;
        dgdebug.traceBoot = DebugUtil.traceBoot;

        DebugUtil.loadFromLocalStorage();
    }

    /** Retrieves or sets the logging flag for one or multiple component types or services.
     * - To be called from the browser console via:
     * ``dgdebug.log('componentNamePrefix|ALL' [,true|false])``
     * @param ctorNamePrefix
     * First characters of the name of a component or service type.
     * Special value 'ALL' processes all registered types.
     * @param value
     * If ommited, logs the matching currently registered types logging state,
     * otherwise sets the logging flag for the matching registered types, and logs the result.
     * @param persistent (default: true) If value is false, the processed state is not cached into local storage.
     * Use clearCache() to clear the local storage.
     * @param type
     * Allows filtering: 1 = only services, 2 = only controllers
     *
     * Example:
     *   ``dgdebug.log('monitoring', true)``
     *  persistently activates logging to console for the monitoring service
     */
    private static log(
        ctorNamePrefix: string,
        value?: boolean,
        persistent = true,
        type?: ComponentType
    ) {
        ctorNamePrefix = ctorNamePrefix?.trim().replace(/-/g, '');
        const infos = CollectionsHelper.orderBy(
            CollectionsHelper.filterMap(
                DebugUtil.types,
                (info, ctorName) =>
                    (!type || info.type === type) &&
                    (ctorNamePrefix === 'ALL' ||
                        StringUtil.startsWith(ctorName, ctorNamePrefix, true))
            ),
            ['type', 'name']
        );

        if (!infos.length) {
            console.log('no registered types found (maybe not registered yet)');
            return;
        }

        if (value == undefined) {
            // get
            console.log(
                'registered types found:',
                infos.map(DebugUtil.getStoredInfo)
            );
        } else {
            // set
            value = !!value;
            infos.forEach((o) => {
                o.logging = value;
                if (o.instance) {
                    o.instance.debug = value;
                }
            });
            console.log(
                `logging set to ${value} for types:\n\t${infos
                    .map((o) => o.name)
                    .join('\n\t')}`
            );
            if (persistent) {
                DebugUtil.saveToLocalStorage();
                console.log('state persisted to local storage');
            }
        }
    }

    private static traceBoot(trace: boolean, logStates?: number[]) {
        const storageKey = 'dgTraceBoot';
        if (trace) {
            DebugUtil.storage.setItem(
                storageKey,
                JSON.stringify({ logStates })
            );
        } else {
            DebugUtil.storage.removeItem(storageKey);
        }
        console.log(`app boot traces ${trace ? 'activated' : 'deactivated'}`);
    }

    private static getStorageKeys() {
        const keys = [],
            storage = DebugUtil.storage;
        for (let i = 0, l = storage.length; i < l; i++) {
            keys[i] = storage.key(i);
        }
        return keys;
    }

    private static clearCache() {
        DebugUtil.storage.removeItem(DebugUtil.localStorageKey);
    }

    private static loadFromLocalStorage() {
        const json = DebugUtil.storage.getItem(DebugUtil.localStorageKey);

        if (!CoreUtil.isProduction) {
            console.log('dgdebug-data', !!json);
        }

        const infos = json && (JSON.parse(json) as IDebugTypeInfo[]);
        infos?.forEach((o) => DebugUtil.types.set(o.name, o));
    }
    private static saveToLocalStorage() {
        const infos = Array.from(DebugUtil.types.values()).map(
            DebugUtil.getStoredInfo
        );
        const json = JSON.stringify(infos);
        DebugUtil.storage.setItem(DebugUtil.localStorageKey, json);
    }
    private static getStoredInfo(o: IDebugTypeInfo) {
        const dti: IDebugTypeInfo = { type: o.type, name: o.name };
        if (o.logging) {
            dti.logging = true;
        }
        return dti;
    }

    //#endregion - private
}

/** registered component type info */
export interface IDebugTypeInfo {
    name: string;
    type: ComponentType;
    logging?: boolean;
    instance?: IDebugLog;
}

/** registered component types */
export enum ComponentType {
    unknown = 0,
    service = 1,
    controller = 2,
}

export interface IDebugLog {
    debug?: boolean;
}
