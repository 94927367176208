import { EntitiesImportedEvent } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getEntityActivityEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [EntitiesImportedEvent];
type TEntitiesEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to get entities bulk events translation inputs
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class EntitiesEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TEntitiesEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const data = getEntityActivityEventTranslationInputs(this.event);
        data.translateParameters.push({
            key: 'entitiesCount',
            value: this.event.EntitiesCount,
        });
        return data;
    }
}
