import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { ObjectLinkType } from './ObjectLinkType.enum';
import { EntityLinkTypeKind } from './EntityLinkTypeKind.enum';
import { EntityType, EntityTypeMapping } from './EntityType';
import { ServerType } from './ServerType.enum';

export class EntityTypeUtil {
    public static initMappings(entityTypeMappings: EntityTypeMapping[]) {
        EntityTypeUtil.entityTypeMappings = entityTypeMappings;
        EntityTypeUtil.entityTypeMappingsByEntityType =
            CollectionsHelper.arrayToMap(
                entityTypeMappings,
                (etm) => etm.EntityType
            );
        EntityTypeUtil.universalObjectLinkTypeByKind =
            CollectionsHelper.objArrayToMap(
                CollectionsHelper.flattenGroups(
                    entityTypeMappings,
                    (etm) => etm.NonHierarchicalLinkRules
                ),
                (tldi) => tldi.EntityLinkTypeKind,
                (tldi) => ({
                    direct: tldi.UniversalObjectLinkType,
                    reverse: tldi.UniversalObjectLinkTypeReverse,
                })
            );
        // console.log('initMappings', {
        //     entityTypeMappings: EntityTypeUtil.entityTypeMappings,
        //     entityTypeMappingsByEntityType: EntityTypeUtil.entityTypeMappingsByEntityType,
        //     universalObjectLinkTypeByKind: EntityTypeUtil.universalObjectLinkTypeByKind
        // })
    }

    //#region NOTE: This Data is initialized on call to LoadSystemData, do not try to use before
    private static entityTypeMappings: EntityTypeMapping[] = [];
    private static entityTypeMappingsByEntityType = new Map<
        EntityType,
        EntityTypeMapping
    >();
    private static universalObjectLinkTypeByKind = new Map<
        EntityLinkTypeKind,
        { direct: ObjectLinkType; reverse: ObjectLinkType }
    >();
    //#endregion

    public static getEntityType(typeName: string, subTypeName: string = null) {
        return EntityTypeUtil.entityTypeMappings?.find(
            (c) => c.DataTypeName == typeName && c.SubTypeName == subTypeName
        )?.EntityType;
    }

    public static getEntityTypes(serverTypes: ServerType[]) {
        if (!serverTypes?.length) {
            return [];
        }
        return EntityTypeUtil.entityTypeMappings
            .filter((e) => serverTypes.includes(e.serverType))
            .map((etm) => etm.EntityType);
    }

    public static getMapping(entityType: EntityType) {
        if (!entityType) {
            return;
        }
        const result = EntityTypeUtil.entityTypeMappingsByEntityType.get(
            +entityType
        );
        if (!result && !CoreUtil.isProduction && entityType != EntityType.All) {
            console.warn(
                `no mapping for ${
                    EntityType[entityType]
                }(${entityType}, ${typeof entityType})`,
                Array.from(
                    EntityTypeUtil.entityTypeMappingsByEntityType.values()
                )
            );
        }
        return result;
    }

    public static getMappings(serverType: ServerType) {
        const serverTypeName = ServerType[serverType];
        return EntityTypeUtil.entityTypeMappings.filter(
            (c) => c.DataTypeName == serverTypeName
        );
    }

    public static getServerType(entityType: EntityType) {
        return EntityTypeUtil.getMapping(entityType)?.serverType;
    }
    public static getServerTypeName(entityType: EntityType) {
        return ServerType[EntityTypeUtil.getServerType(entityType)];
    }

    public static getDistinctServerTypes(entityTypes: EntityType[]) {
        return CollectionsHelper.distinct(
            entityTypes.map(EntityTypeUtil.getServerType)
        );
    }

    public static isCompatibleLinkType(
        serverType: ServerType,
        entityType: EntityType,
        linkTypeKind: EntityLinkTypeKind
    ) {
        return EntityTypeUtil.getMappings(serverType).some(
            (etm) =>
                etm &&
                etm.EntityType == entityType &&
                etm.NonHierarchicalLinkRules.some(
                    (lr) => lr.EntityLinkTypeKind == linkTypeKind
                )
        );
    }

    public static canHaveChildren(entityType: EntityType) {
        return EntityTypeUtil.getMapping(entityType)?.ChildRules?.length > 0;
    }

    public static canHaveAssets(entityType: EntityType) {
        return EntityTypeUtil.isDiagramEntityType(entityType);
    }

    public static isParentUpdatable(entityType: EntityType) {
        const parentRule = EntityTypeUtil.getMapping(entityType).ParentRules[0];
        return parentRule.IsUpdatable && !!parentRule.AllowedParentTypes.length;
    }
    public static hasSameAllowedParentTypes(
        firstEntityType: EntityType,
        secondEntityType: EntityType
    ) {
        const firstEntityTypeMapping =
            EntityTypeUtil.getMapping(firstEntityType).ParentRules[0];
        const secondEntityTypeMapping =
            EntityTypeUtil.getMapping(secondEntityType).ParentRules[0];

        return firstEntityTypeMapping.IsUpdatable &&
            secondEntityTypeMapping.IsUpdatable
            ? CollectionsHelper.contentEquals(
                  firstEntityTypeMapping.AllowedParentTypes,
                  secondEntityTypeMapping.AllowedParentTypes
              )
            : false;
    }

    public static isParentMandatory(entityType: EntityType) {
        return EntityTypeUtil.getMapping(entityType)?.ParentRules[0]
            .IsMandatory;
    }

    static hasEntityTypeAsChild(
        parentEntityType: EntityType,
        childEntityType: EntityType
    ) {
        return EntityTypeUtil.getMapping(
            parentEntityType
        )?.ChildRules[0]?.AllowedChildrenTypes?.includes(childEntityType);
    }

    public static canHaveLinks(entityType: EntityType) {
        return (
            !!entityType &&
            entityType != EntityType.BusinessDomainGroup &&
            !EntityTypeUtil.isDiagramEntityType(entityType)
        );
    }

    public static getTargetEntityTypesFromObjectLinkTypeAndServerType(
        objectLinkType: ObjectLinkType,
        serverTypes: ServerType[],
        debug = false
    ) {
        const getEntityTypes = (st: ServerType) => {
            const entityTypesByMappingByServerType = EntityTypeUtil.getMappings(
                st
            ).map(
                (etm) =>
                    etm.NonHierarchicalLinkRules.find(
                        (e) => e.UniversalObjectLinkType == objectLinkType
                    )?.TargetEntityTypes
            );
            const entityTypes = CollectionsHelper.flatten(
                entityTypesByMappingByServerType,
                false,
                true
            );
            debug &&
                console.log(
                    ServerType[st],
                    entityTypes.map((et) => EntityType[et])
                );
            return entityTypes;
        };
        return CollectionsHelper.flatten(serverTypes.map(getEntityTypes), true);
    }

    public static getTargetEntityTypesFromAllObjectLinkTypeAndServerType(
        serverTypes: ServerType[],
        debug = false
    ) {
        const getEntityTypes = (st: ServerType) => {
            const entityTypesByMappingByServerType = EntityTypeUtil.getMappings(
                st
            ).map((etm) =>
                CollectionsHelper.flatten(
                    etm.NonHierarchicalLinkRules.map(
                        (r) => r?.TargetEntityTypes
                    ),
                    true,
                    true
                )
            );
            const entityTypes = CollectionsHelper.flatten(
                entityTypesByMappingByServerType,
                false,
                true
            );
            debug &&
                console.log(
                    ServerType[st],
                    entityTypes.map((et) => EntityType[et])
                );
            return entityTypes;
        };
        return CollectionsHelper.flatten(serverTypes.map(getEntityTypes), true);
    }

    public static getAllowedParentEntityTypesFromServerType(
        serverTypes: ServerType[],
        debug = false
    ) {
        const getEntityTypes = (st: ServerType) => {
            const parentEntityTypesByServerType = EntityTypeUtil.getMappings(
                st
            ).map((etm) => {
                const entityTypes = CollectionsHelper.flatten(
                    etm.ParentRules.map((pr) => pr.AllowedParentTypes)
                );
                debug &&
                    console.log(
                        ServerType[st],
                        EntityType[etm.EntityType],
                        entityTypes.map((et) => EntityType[et])
                    );
                return entityTypes;
            });
            return CollectionsHelper.flatten(parentEntityTypesByServerType);
        };
        return CollectionsHelper.flatten(serverTypes.map(getEntityTypes), true);
    }

    public static isDiagramEntityType(entityType: EntityType) {
        return (
            entityType == EntityType.FreeDiagram ||
            entityType == EntityType.PhysicalDiagram
        );
    }

    public static getUniversalObjectLinkType(
        kind: EntityLinkTypeKind,
        getReversed: boolean
    ) {
        const olti = EntityTypeUtil.universalObjectLinkTypeByKind.get(kind);
        return olti ? (getReversed ? olti.reverse : olti.direct) : undefined;
    }
    public static getLinkTypeInfosForLinkTypeKind(kind: EntityLinkTypeKind) {
        return CollectionsHelper.flattenGroups(
            EntityTypeUtil.entityTypeMappings,
            (etm) =>
                etm.NonHierarchicalLinkRules.filter(
                    (tldi) => tldi.EntityLinkTypeKind == kind
                )
        );
    }
}
