import { EntityType } from '@datagalaxy/dg-object-model';
import { SerializableEnumeration } from 'cerialize';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';

/** Widget content configuration parameter. Used for:
 * - binding the widget's content component properties
 * - persisting the widget configuration */
export interface IWidgetConfigValue {
    /** name of the parameter
     * - **stored in db/json**
     * - not displayed to the user
     * - by default is the name of the property to set */
    name: string;

    /** value of the parameter (when not computed at run time)
     * - **stored in db/json**
     * - enum values are stored as numbers */
    value?: unknown;

    /** name of the component property to bind to, in case it is different from the parameter name (ie, has been renamed since stored) */
    propName?: string;
}

/** types of widget
 * - **stored in db/json as a number**
 * - Hint: To be visible in the widgets library, a WidgetType must be added to DashboardService.availableWidgetTypes */
export enum WidgetType {
    unknown = 0,

    //#region legacy widgets
    spaceModule = 1,
    projectModuleItemsList = 2,
    projectModuleQualityChart = 3,
    //#endregion

    //#region general widgets
    spaceModulesCount = 4,
    clientSpacesCount = 5,
    shortcutButtons = 6,
    usersGlobalActivity = 7,
    entityList = 8,
    //#endregion

    //#region debugging values
    // c11, c12, c13,  c21, c22, c23,  c31, c32, c33,
    //#endregion

    //#region Widgets "fondamentaux"

    /** Dénombrement des objets par module
     * [EXG-001.1](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1412169739/SPEC+-+Catalogue+des+widgets+fondamentaux#EXG-001.1) */
    entityCountByEntityType = 101,

    /** Evolution du nombre des objets par module
     * [EXG-001.2](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1412169739/SPEC+-+Catalogue+des+widgets+fondamentaux#EXG-001.2) */
    evolutionOfEntityCountByEntityType = 102,

    /** Dénombrement et répartition des objets
     * [EXG-002](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1412169739/SPEC+-+Catalogue+des+widgets+fondamentaux#EXG-002) */
    entityCountByAttributeValues = 103,

    /** Taux de remplissage par attribut
     * [EXG-003.1](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1412169739/SPEC+-+Catalogue+des+widgets+fondamentaux#EXG-003.1) */
    textAttributesFillPercentage = 104,

    /** Taux de remplissage des attributs par type d’objet
     * [EXG-003.2](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1412169739/SPEC+-+Catalogue+des+widgets+fondamentaux#EXG-003.2) */
    textAttributesFillPercentageByEntityType = 105,

    /** Taux de remplissage d’un attribut
     * [EXG-003.3](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1412169739/SPEC+-+Catalogue+des+widgets+fondamentaux#EXG-003.3) */
    cumulativeTextAttributesFillPercentage = 106,

    //#endregion

    //#region Widgets "Activité"

    /** Connexions uniques par type de licence
     * [EXG-013.1](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1411776553/SPEC+-+Catalogue+des+widgets+activit#EXG-013.1) */
    nbUniqueConnectionByLicenseType = 201,

    /** Evolution des connexions uniques par type de licence
     * [EXG-013.2](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1411776553/SPEC+-+Catalogue+des+widgets+activit#EXG-013.2) */
    evolutionOfNbUniqueConnectionByLicenseType = 202,

    /** Nombre de sessions par type de licence
     * [EXG-013.3](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1411776553/SPEC+-+Catalogue+des+widgets+activit#EXG-013.3) */
    nbSessionByLicenseType = 203,

    /** Evolution des sessions par type de licence
     * [EXG-013.4](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1411776553/SPEC+-+Catalogue+des+widgets+activit#EXG-013.4) */
    evolutionOfNbSessionByLicenseType = 204,

    //#endregion
}

/** a widget content stored configuration */
export interface IWidgetStoredContentConfig {
    /** type of the widget */
    widgetType: WidgetType;

    /** widget's content configuration parameters */
    params?: IWidgetConfigValue[];
}

/** a widget's stored configuration */
export interface IWidgetStoredConfig {
    /** content component configuration */
    content: IWidgetStoredContentConfig;

    /** placement & options in the grid */
    inGrid?: IWidgetIngridConfig;
}

/** position and size in the grid, expressed in grid cells
 * - **stored in db/json**
 * - cell width: a 12th of the grid's width
 * - cell height: 70px (60px + 2 * 5px margins) */
export interface IWidgetIngridConfig {
    x: number;
    y: number;
    width: number;
    height: number;
}

export enum WidgetDatasetType {
    Unknown = 0,
    EntityCountByEntityType,
    EvolutionOfEntityCountByEntityType,
    EntityCountByAttributeValues,
    TextAttributesFillPercentage,
    TextAttributesFillPercentageByEntityType,
    CumulativeTextAttributesFillPercentage,
    NbUniqueConnectionByLicenseType,
    EvolutionOfNbUniqueConnectionByLicenseType,
    NbSessionByLicenseType,
    EvolutionOfNbSessionByLicenseType,
    UserSpacesWithObjectCount,
    SpaceObjectCountByModule,
    UsersGlobalActivity,
}
SerializableEnumeration(WidgetDatasetType);

export interface IWidgetFiltersParameter {
    SpaceId?: string;
    DgModule?: DataGalaxyModule;
    EntityTypes?: EntityType[];
    OwnerIds?: string[];
    StewardIds?: string[];
    UserIds?: string[];
    TagIds?: string[];
    EntityStatuses?: EntityLifecycleStatus[];
    LicenseTypes?: LicenseLevel[];
    AttributePaths?: string[];
    NbDays?: number;
    ExcludeWeekends?: boolean;
    Teams?: string[];
}
