import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntityType } from '@datagalaxy/dg-object-model';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgClass } from '@angular/common';
import { IconComponent, IconSize } from '@datagalaxy/ui/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'dxy-entity-type-icon',
    templateUrl: './entity-type-icon.component.html',
    imports: [MatLegacyTooltipModule, NgClass, TranslateModule, IconComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityTypeIconComponent {
    @Input() entityType?: EntityType;
    @Input() size: IconSize = 'medium';
    @Input() tooltipDisabled = false;

    protected get glyphClass() {
        return EntityTypeUtils.getColoredGlyphClass(this.entityType);
    }

    protected get tooltipKey() {
        return EntityTypeUtils.getTranslateKey(this.entityType);
    }
}
