import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityLinkTypeKind } from '@datagalaxy/dg-object-model';
import { IEntityLinksChangeInfo } from '../../../entity/services/entity.service';
import { LinkedDataItem } from '@datagalaxy/webclient/entity/domain';

export class EntityLinksChanges {
    private readonly linksToAdd: LinkedDataItem[] = [];
    private readonly linksToDelete: LinkedDataItem[] = [];
    private kind: EntityLinkTypeKind;

    public get empty() {
        return !this.linksToAdd.length && !this.linksToDelete.length;
    }

    public hasAny() {
        return !this.empty;
    }
    public clearAll() {
        this.linksToAdd.length = this.linksToDelete.length = 0;
    }

    public clearLinksToAdd() {
        this.linksToAdd.length = 0;
    }
    public clearLinksToDelete() {
        this.linksToDelete.length = 0;
    }

    public addLinkToAdd(link: LinkedDataItem) {
        this.linksToAdd.push(link);
    }
    public addLinkToDelete(link: LinkedDataItem) {
        this.linksToDelete.push(link);
    }

    public removeLinkToAdd(linkRefId: string) {
        return (
            CollectionsHelper.remove(
                this.linksToAdd,
                (ldi) => ldi.LinkedData.DataReferenceId == linkRefId
            )?.length > 0
        );
    }
    public removeLinkToDelete(linkRefId: string) {
        return (
            CollectionsHelper.remove(
                this.linksToDelete,
                (ldi) => ldi.LinkedData.DataReferenceId == linkRefId
            )?.length > 0
        );
    }

    public setKind(kind: EntityLinkTypeKind) {
        this.kind = kind;
    }

    public getLinksToAddLinkedDataIds() {
        return this.linksToAdd.map((l) => l.LinkedData.DataReferenceId);
    }

    public asChangeInfo(): IEntityLinksChangeInfo {
        return {
            linksToAdd: this.linksToAdd.slice(),
            linksToDelete: this.linksToDelete.slice(),
            kind: this.kind,
        };
    }
}
