import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { Observable, of } from 'rxjs';
import { EntityDisplayNameService } from '../services/entity-display-name.service';
import { TranslationOrigin } from '@datagalaxy/webclient/multilingual/domain';
import { AttributeValueTranslationResult } from '@datagalaxy/webclient/multilingual/feature';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { TranslateModule } from '@ngx-translate/core';
import { AttributeTranslationButtonComponent } from '@datagalaxy/webclient/multilingual/feature';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-entity-display-name',
    templateUrl: 'entity-display-name.component.html',
    styleUrls: ['entity-display-name.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        AttributeTranslationButtonComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class EntityDisplayNameComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() entity!: EntityItem;
    @Input() readOnly?: boolean;
    @Input() displayNameTooltip?: string;

    @Output() titleClicked = new EventEmitter();

    protected translationResult$: Observable<AttributeValueTranslationResult> =
        of({
            value: '',
        });

    protected aiOrigin = TranslationOrigin.AI;

    protected get hasWriteAccess() {
        return this.entity?.SecurityData?.HasWriteAccess;
    }

    constructor(private entityDisplayNameService: EntityDisplayNameService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onChange(
            changes,
            'entity',
            () => {
                this.init();
            },
            true
        );
    }

    private init() {
        this.translationResult$ =
            this.entityDisplayNameService.getDisplayNameTranslationResult$(
                this.entity.HddData
            );
    }
}
