import { SerializableEnumeration } from 'cerialize';

export enum EntityLifecycleStatus {
    Proposed,
    InRevision,
    Validated,
    InValidation,
    Obsolete,
}
SerializableEnumeration(EntityLifecycleStatus);
