import * as dagre from '@dagrejs/dagre';
import { ManagedItem } from '../../node/managed-item';
import { Connector } from '../../connector';
import { Rect } from '@datagalaxy/core-2d-util';
import { CollectionsHelper } from '@datagalaxy/core-util';

export class DirectedLayout {
    updateNodesLayout(nodes: ManagedItem[], connectors?: Connector[]) {
        if (!nodes?.length) {
            return;
        }
        const g = new dagre.graphlib.Graph({ directed: true });

        g.setGraph({
            rankdir: 'LR',
            ranker: 'network-simplex',
            marginx: 10,
            marginy: 25,
            nodesep: 20,
            ranksep: 110,
        });

        g.setDefaultEdgeLabel(function () {
            return {};
        });

        if (g.nodeCount()) {
            dagre.layout(g);
        }

        nodes?.forEach((node) => {
            const rect = node.rect;

            g.setNode(node.id, {
                width: rect.width,
                height: rect.height,
            });
        });

        CollectionsHelper.orderBy(connectors, [
            (connector) =>
                nodes.findIndex((node) => node.id === connector.srcNode.id),
            (connector) =>
                nodes.findIndex((node) => node.id === connector.tgtNode.id),
        ])?.forEach((connector) =>
            g.setEdge(
                connector.inverted
                    ? connector.tgtNode.id
                    : connector.srcNode.id,
                connector.inverted ? connector.srcNode.id : connector.tgtNode.id
            )
        );

        dagre.layout(g);

        const newBoundingBox = Rect.boundingBox(
            nodes.map((node) => g.node(node.id))
        );

        const delta = {
            x: newBoundingBox.center().x,
            y: newBoundingBox.center().y,
        };

        nodes.forEach((node) => {
            const n = g.node(node.id);

            node.rect.x = n.x - delta.x - node.rect.width / 2;
            node.rect.y = n.y - delta.y - node.rect.height / 2;
        });
    }
}
