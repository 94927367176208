import {
    Directive,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { IBaseFilterData } from './base-filter.types';
import { DxyFilterButtonComponent } from '../filter-button/filter-button.component';
import { LegacyMenuPositionX as MenuPositionX } from '@angular/material/legacy-menu';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Directive()
export class DxyBaseFilterComponent<
    TFilter extends IBaseFilterData<TOperator>,
    TOperator
> extends DxyBaseComponent {
    @Input() xMenuPosition: MenuPositionX = 'after';
    @Input() labelKey: string;
    @Input() labelText: string;
    @Input() isMini: boolean;
    @Input() hasRemove: boolean;
    @Input() noOperator: boolean;
    @Input() operators: TOperator[];
    @Input() excludedOperators: TOperator[];
    @Input() filter: TFilter;
    @Input() readonly: boolean;
    @Input() hideIcon: boolean;
    @Input() removeDisabled: boolean;
    @Input() removeDisabledTooltipKey: string;

    @Output() remove = new EventEmitter();
    @Output() filterChange = new EventEmitter<TFilter>();
    @Output() onOpenClose = new EventEmitter<boolean>();

    @ViewChild(DxyFilterButtonComponent)
    filterButton: DxyFilterButtonComponent<TOperator>;

    public get operator() {
        return this.filter.operator;
    }
    public set operator(value: TOperator) {
        this.filter.operator = value;
    }

    //#region API
    public openMenu() {
        this.filterButton?.openMenu();
    }
    //#endregion API

    public onRemove(event: MouseEvent) {
        event.stopPropagation();
        this.remove.emit();
    }
}
