import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { LegacyAppModule } from './app/app.module.legacy';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreUtil } from '@datagalaxy/core-util';
import { AppModule } from './app/app.module';
import { APP_CONFIG, AppConfig, IConfig } from '@datagalaxy/webclient/config';

CoreUtil.isProduction = environment.production;

if (environment.production) {
    enableProdMode();
}

function bootstrapApp(config: IConfig) {
    const authV2Enabled = config.appConfig.enableAuthV2 === 'true';
    const providers = [{ provide: APP_CONFIG, useValue: config }];
    const appModule = authV2Enabled ? AppModule : LegacyAppModule;

    return platformBrowserDynamic(providers).bootstrapModule(appModule);
}

AppConfig.load()
    .then((config) => bootstrapApp(config))
    .catch((err) => console.error(err));
