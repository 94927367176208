import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IMentionResolver } from '@datagalaxy/core-ui/rich-text';
import { SupportTicketService } from '../support-ticket.service';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { RichTextMentionService } from '../../../richText/mentions/rich-text-mention.service';
import { AppDataService } from '../../../../services/app-data.service';
import { ToasterService } from '../../../../services/toaster.service';
import { ClientErrorService } from '../../../../services/clientError.service';
import { FreshDeskService } from '../../freshdesk/fresh-desk.service';
import { NgIf } from '@angular/common';
import { DxyModalFooterComponent } from '../../../dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyFieldFileUploadComponent } from '@datagalaxy/core-ui/fields';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-support-ticket-modal',
    templateUrl: './dxy-support-ticket-modal.component.html',
    styleUrls: ['./dxy-support-ticket-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyFieldSelectComponent,
        DxyFieldTextComponent,
        DxyRichTextFieldComponent,
        DxyFieldFileUploadComponent,
        DxyModalFooterComponent,
        NgIf,
    ],
})
export class DxySupportTicketModalComponent
    extends DxyBaseModalComponent<void, void>
    implements OnInit, AfterViewChecked
{
    public readonly mentionResolvers: IMentionResolver[];
    public canCreate = false;
    public isCheckedCreateAnother: boolean;
    public creationLoading = false;

    public typeAdapter: IFieldSelectAdapter<string> = {
        getTextKey: (o) => `UI.TicketModal.form.types.${o}.text`,
        getSubTextKey: (o) => `UI.TicketModal.form.types.${o}.subText`,
    };
    public files: File[];
    public form: TicketForm;
    public isApiUnreachable: boolean;

    public get isIncident() {
        return this.form.type == 'Incident';
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        private toasterService: ToasterService,
        private appDataService: AppDataService,
        private supportTicketService: SupportTicketService,
        private freshDeskService: FreshDeskService,
        private clientErrorService: ClientErrorService,
        richTextMentionService: RichTextMentionService,
        dialogRef: MatDialogRef<DxySupportTicketModalComponent, void>
    ) {
        super(dialogRef);
        this.mentionResolvers =
            richTextMentionService.getDefaultMentionResolvers();
    }

    ngOnInit() {
        this.init().then();
    }
    ngAfterViewChecked() {
        this.preventNg0100Error(this.changeDetector);
    }

    public async getTicketTypes() {
        try {
            const types = await this.freshDeskService.getTicketTypes();

            // TODO jga: remove the filter after removing featureRequest ticket type in freshdesk
            return types.filter((type) => type !== 'Feature Request');
        } catch (error) {
            this.isApiUnreachable = true;
            this.clientErrorService.notify(
                error.data || error,
                error.data?.code,
                'FreshDesk'
            );
        }
    }

    public onChangeCreateAnother() {
        this.isCheckedCreateAnother = !this.isCheckedCreateAnother;
    }
    public onCloseSubmit() {
        this.createTicket().then();
    }

    public updateCanCreate() {
        this.canCreate = !!(
            this.form.description &&
            this.form.subject &&
            this.form.type
        );
    }

    public getDescriptionPlaceholder(type: string) {
        if (!type) {
            return;
        }
        return `UI.TicketModal.form.types.${type}.descriptionPlaceholder`;
    }

    private resetForm() {
        this.files = [];
        this.form = new TicketForm('', this.appDataService.currentUserEmail);
    }

    private async init() {
        this.resetForm();
        this.typeAdapter.options = await this.getTicketTypes();
    }

    private async createTicket() {
        this.creationLoading = true;
        try {
            await this.sendForm();
            this.toasterService.successToast({
                titleKey: 'UI.Notification.FeedbackSuccess.title',
                messageKey: 'UI.Notification.FeedbackSuccess.msg',
            });

            if (this.isCheckedCreateAnother) {
                this.resetForm();
            } else {
                super.onCloseCancel();
            }
        } catch (error) {
            this.toasterService.errorToast({
                titleKey: 'UI.Notification.FeedbackSubmitFail.title',
                messageKey: 'UI.Notification.FeedbackSubmitFail.msg',
            });
            this.clientErrorService.notify(
                error.data || error,
                error.data?.code,
                'FreshDesk'
            );
        } finally {
            this.creationLoading = false;
        }
    }

    private async sendForm() {
        const formData = new FormData();

        if (this.form.type != 'Other') {
            formData.append('type', this.form.type);
        }
        formData.append('email', this.form.email);
        formData.append('subject', this.form.subject);
        formData.append(
            'description',
            this.parseDescription(this.form.description)
        );
        formData.append('source', '9');
        formData.append('status', '2');
        formData.append('priority', '1');

        this.files?.forEach((file) => formData.append('attachments[]', file));

        await this.freshDeskService.createTicket(formData);
    }

    private parseDescription(jsonString: string) {
        const formDesc: IDescription = JSON.parse(jsonString);
        const content = formDesc.Items[2].Content;
        return this.supportTicketService.addDetailsToDesc(content);
    }
}

class TicketForm {
    constructor(
        public description = '',
        public email = '',
        public subject = '',
        public type = ''
    ) {}
}
interface IDescriptionItem {
    Content: string;
}
interface IDescription {
    Items: IDescriptionItem[];
}
