import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { clientRoutes } from './client.routes';
import { clientRouterConfig } from './client-router.config';
import { ClientSharedModule } from './client.shared.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({
            states: clientRoutes,
            config: clientRouterConfig,
        }),
        ClientSharedModule,
    ],
})
export class ClientModule {
    constructor() {}
}
