<img src="/images/multilingual-target-language.svg" alt="Target language" />
<p>
    <strong translate>
        Multilingual.Administration.TargetLanguageStep.question
    </strong>
    <br />
    <span
        [innerHTML]="
            'Multilingual.Administration.TargetLanguageStep.hint' | translate
        "
    >
    </span>
</p>
<form [formGroup]="form">
    <dxy-target-language-selector
        formControlName="language"
        [exludedLanguageCodes]="exludedLanguageCodes"
    >
    </dxy-target-language-selector>
</form>
