<div *ngIf="isImportLoading" class="loading-container">
    <span class="dg5-spinner xl-spinner"></span>
    <span class="result-description" translate
        >Import.GenericImportWizard.CsvPostImport.LoadingMessage</span
    >
</div>
<div *ngIf="!isImportLoading" class="post-import-wrapper">
    <ng-container *ngIf="isImportSuccess">
        <img src="/images/import/import-success.svg" alt="import-success" />
        <span class="result-description" translate
            >Import.GenericImportWizard.CsvPostImport.ImportSuccessMessage</span
        >
        <div class="card-grid-container">
            <dxy-grid
                [config]="gridConfig"
                [columns]="cols"
                [items]="resultItems"
            ></dxy-grid>
        </div>
    </ng-container>
    <ng-container *ngIf="!isImportSuccess">
        <img src="/images/import/import-fail.svg" alt="import-success" />
        <span class="result-description" translate
            >Import.GenericImportWizard.CsvPostImport.ImportFailMessage</span
        >
        <dxy-csv-preimport-check
            [importContext]="importContext"
        ></dxy-csv-preimport-check>
    </ng-container>
</div>
