import { Injectable } from '@angular/core';
import {
    SetUserSettingsParameter,
    UserApiService,
} from '@datagalaxy/webclient/user/data-access';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';

@Injectable({ providedIn: 'root' })
export class LanguageUserSettingsService {
    constructor(
        private currentUserService: CurrentUserService,
        private userApiService: UserApiService
    ) {}

    public async setUserLanguage(languageCode: string) {
        const userInfo = this.currentUserService.userInfo;
        if (!userInfo || languageCode === userInfo.LanguageCode) {
            return;
        }
        const clientLocalId = userInfo.CurrentClientId ?? '';
        const userLocalId = userInfo.UserId;
        const param = new SetUserSettingsParameter(clientLocalId, userLocalId);
        param.UserLanguageCode = languageCode;
        await this.userApiService.setUserSettings(param);
        location.reload();
    }
}
