import * as moment from 'moment';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { IEntityExportParameters } from '../export-api.types';
import {
    EntityExportType,
    IEntityExportModalInput,
} from '../export-modal.types';
import { IFileOptionConfig } from '../../shared/file-options/file-option-form.types';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { SearchService } from '../../search/search.service';
import { SpaceApiService } from '../../space/services/space-api.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { NavigationService } from '../../services/navigation.service';
import { DataUtil } from '../../shared/util/DataUtil';
import {
    EntityExportChildrenOption,
    EntityExportParentsOption,
    EntityExportReferencesOption,
} from '@datagalaxy/webclient/data-port/data-access';
import {
    FilteredViewDto,
    FilterModuleName,
} from '@datagalaxy/webclient/filter/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';
import { DxyFileOptionsFormComponent } from '../../shared/file-options/dxy-file-options-form/dxy-file-options-form.component';
import { NgIf, NgFor } from '@angular/common';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'app-entity-export-modal',
    templateUrl: './entity-export-modal.component.html',
    styleUrls: ['./entity-export-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyFieldTextComponent,
        NgIf,
        DxyFileOptionsFormComponent,
        DxyCollapsibleComponent,
        MatLegacyRadioModule,
        NgFor,
        MatLegacyTooltipModule,
        MatLegacyCheckboxModule,
    ],
})
export class EntityExportModalComponent
    extends DxyBaseModalComponent<IEntityExportModalInput, void>
    implements OnInit
{
    public childOptions: EntityExportChildrenOption[];
    public childOptionSelected = EntityExportChildrenOption.None;
    public parentOptions: EntityExportParentsOption[];
    public parentOptionSelected = EntityExportParentsOption.None;
    public referenceOptions: {
        value: boolean;
        id: EntityExportReferencesOption;
    }[];
    public importAllLinks: boolean;
    public filename: string;
    public showLinkedObjectOption: boolean;
    public showReferencesOptions: boolean;
    public isLoadingPreExport: boolean;
    public fileCount: number;
    public objectCount: number;
    public showLineOptions = false;
    public readonly allowedEncodings = ['UTF-8', 'windows-1252'];
    public defaultDelimiter = ';';

    // TODO: remove when preExport is available
    public isPreExportDisabled = true;

    private entity: EntityItem;
    private originalFilename: string;
    private fileConfig: IFileOptionConfig;

    constructor(
        private translate: TranslateService,
        private currentSpaceService: CurrentSpaceService,
        private searchService: SearchService,
        private spaceApiService: SpaceApiService,
        private entityService: EntityService,
        private navigationService: NavigationService,
        @Inject(MAT_DIALOG_DATA) data: IEntityExportModalInput,
        dialogRef: MatDialogRef<EntityExportModalComponent>
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.init();
    }

    private async init() {
        await this.loadData();
        this.initOptions();
        this.originalFilename = this.filename = await this.computeFilename();
        this.showLinkedObjectOption = this.setShowLinkedObject();
        this.showReferencesOptions = this.setShowReferencesOptions();
    }

    private async loadData(): Promise<void> {
        switch (this.data.exportType) {
            case EntityExportType.Unitary:
                this.entity = await this.entityService.getEntity(
                    this.data.data as EntityItem,
                    {
                        includedAttributesFilter: [
                            PropertyName.EntityLinkCount,
                            PropertyName.DisplayName,
                            PropertyName.LogicalChildrenCount,
                        ],
                    }
                );
                break;
            default:
                return Promise.resolve();
        }
    }

    private initOptions() {
        this.childOptions =
            CollectionsHelper.getEnumValues<EntityExportChildrenOption>(
                EntityExportChildrenOption
            );
        this.parentOptions =
            CollectionsHelper.getEnumValues<EntityExportParentsOption>(
                EntityExportParentsOption
            );
        this.referenceOptions =
            CollectionsHelper.getEnumValues<EntityExportReferencesOption>(
                EntityExportReferencesOption
            ).map((option) => ({
                value: false,
                id: option,
            }));
    }

    private setShowLinkedObject(): boolean {
        switch (this.data.exportType) {
            case EntityExportType.Unitary:
                // Temporarily allow this option, to be adjusted when preExport is available
                // We cant only check entity's AllEntityLinkCount attribute, we need to compute children too
                return true;
            case EntityExportType.List:
                // Temporarily allow this option, to be adjusted when preExport is available
                return true;
            case EntityExportType.FilteredView:
                // Temporarily allow this option, to be adjusted when preExport is available
                return true;
            default:
                CoreUtil.warn(
                    `setShowLinkedObject not implemented for ${
                        EntityExportType[this.data.exportType]
                    } export type`
                );
        }
    }

    private setShowReferencesOptions(): boolean {
        switch (this.data.exportType) {
            case EntityExportType.Unitary:
                return (
                    DataUtil.getModuleFromServerType(this.entity.ServerType) ===
                    DgModule.Catalog
                );
            case EntityExportType.List:
                return (
                    DataUtil.getModuleFromServerType(
                        this.data.data[0].ServerType
                    ) === DgModule.Catalog
                );
            case EntityExportType.FilteredView: {
                const view = this.data.data as FilteredViewDto;
                return [
                    FilterModuleName.Catalog,
                    FilterModuleName.MainSearch,
                ].includes(view.ModuleName);
            }
            default:
                CoreUtil.warn(
                    `setShowReferencesOptions not implemented for ${
                        EntityExportType[this.data.exportType]
                    } export type`
                );
        }
    }

    private async computeFilename() {
        const space = await this.getSpace();

        switch (this.data.exportType) {
            case EntityExportType.Unitary: {
                const dgModule = DataUtil.getModuleFromServerType(
                    this.entity.ServerType
                );
                const dgModuleDisplayName = this.translate.instant(
                    DataUtil.getModuleTranslateKey(dgModule)
                );
                return `${space.DisplayName}-${dgModuleDisplayName}-${this.entity.DisplayName}`;
            }
            case EntityExportType.List: {
                const dgModule = DataUtil.getModuleFromServerType(
                    this.data.data[0].ServerType
                );
                const dgModuleDisplayName = this.translate.instant(
                    DataUtil.getModuleTranslateKey(dgModule)
                );
                const text = this.translate.instant(
                    'UI.EntityExportModal.objectSelectionFilename'
                );
                return `${space.DisplayName}-${dgModuleDisplayName}-${text}`;
            }
            case EntityExportType.FilteredView: {
                const view = this.data.data as FilteredViewDto;
                const defaultViewName = this.translate.instant(
                    'UI.EntityExportModal.filteredViewFilename'
                );
                const text =
                    view.FilteredViewId === -1
                        ? defaultViewName
                        : view.DisplayName;
                return `${space.DisplayName}-${view.ModuleName}-${text}`;
            }
            default:
                CoreUtil.warn(
                    `computeFilename not implemented for ${
                        EntityExportType[this.data.exportType]
                    } export type`
                );
        }
    }

    private async getSpace() {
        switch (this.data.exportType) {
            case EntityExportType.FilteredView: {
                const view = this.data.data as FilteredViewDto;

                if (view.ModuleName === FilterModuleName.MainSearch) {
                    const spaceIdr =
                        this.searchService.getCurrentSearch()?.spaceIdr;
                    return this.spaceApiService.getSpace(spaceIdr);
                }
                break;
            }
        }
        return this.currentSpaceService.getCurrentSpace();
    }

    private addOptionsToFilename() {
        const optionsText: string[] = [];

        if (this.importAllLinks) {
            optionsText.push(
                this.translate.instant(
                    'UI.EntityExportModal.options.linkedObjects.title'
                )
            );
        }

        if (this.referenceOptions?.some((opt) => opt.value)) {
            optionsText.push(
                this.translate.instant(
                    'UI.EntityExportModal.options.references.title'
                )
            );
        }

        if (!optionsText.length) {
            return this.filename;
        }

        if (this.filename !== this.originalFilename) {
            return `${optionsText.join('-')}-${this.filename}`;
        } else {
            return `${this.filename}${optionsText.join('-')}`;
        }
    }
    private getFinalFilename() {
        const filename = this.addOptionsToFilename();
        const date = moment().format('YYYYMMDD_HHmmss');
        return `${filename}-${date}`;
    }

    private prepareExportParameters(): IEntityExportParameters {
        const module = this.navigationService.getCurrentModule();
        return {
            filename: this.getFinalFilename(),
            parentsOption: this.parentOptionSelected,
            childrenOption: this.childOptionSelected,
            referenceOptions: this.referenceOptions
                .filter((opt) => opt.value)
                .map((opt) => opt.id),
            includeLinkedObjects: this.importAllLinks,
            module: module !== DgModule.unknown ? module : null,
            textEntrySeparator: this.fileConfig?.carriage,
            encodingWebName: this.fileConfig?.encoding,
            textFieldSeparator: this.fileConfig?.delimiter,
        };
    }

    public onFileConfigChange(config: IFileOptionConfig) {
        this.fileConfig = config;
    }

    public toggleFileOptions() {
        this.showLineOptions = !this.showLineOptions;
    }

    public onFilenameBlur() {
        if (StringUtil.isNullOrEmpty(this.filename)) {
            this.filename = this.originalFilename;
        }
    }

    public onUpdateOptions() {
        if (this.isPreExportDisabled) {
            return;
        }
        // TODO: Add pre export request when available & update spinner to dg5-spinner
        this.isLoadingPreExport = true;
        const _parameters = this.prepareExportParameters();
        setTimeout(() => {
            this.isLoadingPreExport = false;
            // TODO: refresh fileCount and objectCount
        }, 1000);
    }

    public async onExport() {
        this.log('onExport');
        const parameters = this.prepareExportParameters();
        const currentSpace = await this.getSpace();
        let dataReferenceIdList = [];

        switch (this.data.exportType) {
            case EntityExportType.Unitary:
                dataReferenceIdList = [this.entity.ReferenceId];
                break;
            case EntityExportType.List:
                dataReferenceIdList = (this.data.data as EntityItem[])?.map(
                    (et) => et.ReferenceId
                );
                break;
            case EntityExportType.FilteredView:
                parameters.filters = (
                    this.data.data as FilteredViewDto
                ).filters;
                dataReferenceIdList = null;
                break;
            default:
                CoreUtil.warn(
                    `onExport not implemented for ${
                        EntityExportType[this.data.exportType]
                    } export type`
                );
        }
        // unawaited promise
        this.entityService.exportEntity(
            dataReferenceIdList,
            currentSpace,
            parameters
        );
        this.onCloseSubmit();
    }

    public isChildOptionDisabled(option: EntityExportChildrenOption): boolean {
        switch (this.data.exportType) {
            case EntityExportType.Unitary: {
                const childrenCount = this.entity.getAttributeValue(
                    PropertyName.LogicalChildrenCount
                ) as number;
                return !(
                    option === EntityExportChildrenOption.None ||
                    (childrenCount &&
                        (option === EntityExportChildrenOption.Children ||
                            option === EntityExportChildrenOption.Descendants))
                );
            }
            case EntityExportType.List: {
                // Temporarily allow this option, to be adjusted when preExport is available
                // We dont have enough infos on LoadMultiEntity with flat infos
                return false;
            }
            case EntityExportType.FilteredView:
                // Temporarily allow this option, to be adjusted when preExport is available
                return false;
            default:
                CoreUtil.warn(
                    `isChildOptionDisabled not implemented for ${
                        EntityExportType[this.data.exportType]
                    } export type`
                );
        }
    }

    public isParentOptionDisabled(option: EntityExportParentsOption): boolean {
        switch (this.data.exportType) {
            case EntityExportType.Unitary: {
                const parents = this.entity.HddData.Parents;
                return !(
                    option === EntityExportParentsOption.None ||
                    (parents?.length &&
                        (option === EntityExportParentsOption.Parent ||
                            option === EntityExportParentsOption.Ancestors))
                );
            }
            case EntityExportType.List: {
                return !(this.data.data as EntityItem[])?.some((entity) => {
                    const parents = entity._HddData.Parents;
                    return (
                        option === EntityExportParentsOption.None ||
                        (parents?.length &&
                            (option === EntityExportParentsOption.Parent ||
                                option === EntityExportParentsOption.Ancestors))
                    );
                });
            }
            case EntityExportType.FilteredView:
                // Temporarily allow this option, to be adjusted when preExport is available
                return false;

            default:
                CoreUtil.warn(
                    `isParentOptionDisabled not implemented for ${
                        EntityExportType[this.data.exportType]
                    } export type`
                );
        }
    }

    public getChildrenOptionText(option: EntityExportChildrenOption) {
        return `UI.EntityExportModal.options.hierarchy.childrenOptions.${EntityExportChildrenOption[option]}.text`;
    }
    public getChildrenOptionTooltip(option: EntityExportChildrenOption) {
        const disabled = this.isChildOptionDisabled(option);
        return this.translate.instant(
            `UI.EntityExportModal.options.hierarchy.childrenOptions.${
                EntityExportChildrenOption[option]
            }.${disabled ? 'disabledTooltip' : 'tooltip'}`
        );
    }
    public getParentOptionTooltip(option: EntityExportParentsOption) {
        const disabled = this.isParentOptionDisabled(option);
        return this.translate.instant(
            `UI.EntityExportModal.options.hierarchy.parentOptions.${
                EntityExportParentsOption[option]
            }.${disabled ? 'disabledTooltip' : 'tooltip'}`
        );
    }
    public getParentOptionText(option: EntityExportParentsOption) {
        return `UI.EntityExportModal.options.hierarchy.parentOptions.${EntityExportParentsOption[option]}.text`;
    }
    public getReferenceOptionText(option: EntityExportReferencesOption) {
        return `UI.EntityExportModal.options.references.options.${EntityExportReferencesOption[option]}.text`;
    }
    public getReferenceOptionTooltip(option: EntityExportReferencesOption) {
        return this.translate.instant(
            `UI.EntityExportModal.options.references.options.${EntityExportReferencesOption[option]}.tooltip`
        );
    }
    public getRecapText() {
        // TODO: use this.fileCount & this.objectCount
        return this.translate.instant(`UI.EntityExportModal.recap`, {
            fileCount: 3,
            objectCount: 188,
        });
    }
}
