import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export interface IBottomBanner {
    title: string;
    text: string;
    imageSrc?: string;
    bannerClass?: string;
    rowClass?: string;
}

@Injectable({ providedIn: 'root' })
export class BottomBannerService {
    private bannerShowHide = new BehaviorSubject<IBottomBanner | null>(null);

    public get bannerShowHide$() {
        return this.bannerShowHide.asObservable();
    }

    constructor(private translate: TranslateService) {}

    /** hides any displayed bottom banner */
    public hideBanner() {
        this.bannerShowHide.next(null);
    }

    public show(
        titleKey: string,
        textKey: string,
        options?: { imageSrc?: string; bannerClass?: string; rowClass?: string }
    ) {
        const banner: IBottomBanner = {
            title: this.translate.instant(titleKey),
            text: this.translate.instant(textKey),
            ...options,
        };

        this.bannerShowHide.next(banner);
    }
}
