/**
 * Glossary Entity types
 */
export enum PropertyType {
    BusinessTerm,
    Indicator,
    Concept,
    ReferenceData,
    Dimension,
    BusinessDomain,
    Universe,
    IndicatorGroup,
    DimensionGroup,
    BusinessDomainGroup,
    ReferenceDataValue,
}
