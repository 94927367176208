<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.NotificationChannels.MsTeams.IntegrationModal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <div class="channel-illustration">
        <img src="/images/logo/logo-cloud.svg" alt="datagalaxy-logo" />
        <div class="arrows">
            <i class="glyph-arrow-right"></i>
            <i class="glyph-arrow-left"></i>
        </div>
        <img src="/images/collaboration/ms-teams.svg" />
    </div>

    <p translate>
        UI.NotificationChannels.MsTeams.IntegrationModal.description
    </p>
    <form
        [formGroup]="domainFormGroup"
        (ngSubmit)="getTenantIdFromDomainName()"
    >
        <dxy-field-text
            labelKey="UI.NotificationChannels.MsTeams.IntegrationModal.domainName"
            [infoTooltip]="
                'UI.NotificationChannels.MsTeams.IntegrationModal.domainNameTooltip'
                    | translate
            "
            formControlName="domainName"
        >
        </dxy-field-text>
        <button
            mat-flat-button
            type="submit"
            [disabled]="domainFormGroup.invalid"
        >
            <span translate
                >UI.NotificationChannels.MsTeams.IntegrationModal.updateTenantId</span
            >
        </button>
    </form>

    <form [formGroup]="tenantFormGroup">
        <dxy-field-text
            labelKey="UI.NotificationChannels.MsTeams.IntegrationModal.tenantId"
            [infoTooltip]="
                'UI.NotificationChannels.MsTeams.IntegrationModal.tenantIdTooltip'
                    | translate
            "
            formControlName="tenantId"
            [required]="true"
        >
        </dxy-field-text>
    </form>
</div>

<dxy-modal-footer
    [actionEnabled]="tenantFormGroup.valid"
    [actionBtnLbl]="
        'UI.NotificationChannels.ClientSettings.connect' | translate
    "
    [actionLoading]="loading$ | async"
    (onCloseCancel)="onCloseCancel()"
    (onActionPointerDown)="activateTeamsIntegration()"
    featureCode="MS_TEAMS_INTEGRATION_ACTIVATION"
></dxy-modal-footer>
