import * as JSZip from 'jszip';

export const zipToBlobOptions: JSZip.JSZipGeneratorOptions<'blob'> = {
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level: 3 },
};

export interface IZipData {
    path: string;
    content: string;
}
