<dxy-client-admin-header
    titleKey="Multilingual.Administration.title"
></dxy-client-admin-header>

<dxy-spinner *ngIf="loading$ | async; else content"></dxy-spinner>

<ng-template #content>
    <dxy-multilingual-configurator
        *ngIf="multilingualEnabled$ | async; else notActivated"
    ></dxy-multilingual-configurator>

    <ng-template #notActivated>
        <img src="/images/multilingual.svg" alt="Multilingual Administration" />

        <div class="feature-presentation">
            <strong translate>
                Multilingual.Administration.featureDescription
            </strong>
            <p translate>Multilingual.Administration.featureBenefits</p>
            <dxy-multilingual-activator
                *dxyIfFeatureFlag="
                    'multilingual';
                    else dxyMultilingualUnavailability
                "
            ></dxy-multilingual-activator>
        </div>

        <ng-template #dxyMultilingualUnavailability>
            <dxy-multilingual-unavailability></dxy-multilingual-unavailability>
        </ng-template>
    </ng-template>
</ng-template>
