import { Inject, Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { IApiConfig, BACKEND_API_CONFIG } from '../types';
import { BaseServiceParameter } from '../backend';
import { ObjectUtils } from '@datagalaxy/utils';

/**
 * ## Role
 * Add userSessionId value to the request if there is one
 */
@Injectable({ providedIn: 'root' })
export class UserSessionInterceptor implements HttpInterceptor {
    constructor(@Inject(BACKEND_API_CONFIG) private config: IApiConfig) {}

    intercept(req: HttpRequest<BaseServiceParameter>, next: HttpHandler) {
        const userSessionId = this.config?.getUserSessionId?.();

        if (!userSessionId) {
            return next.handle(req);
        }

        const reqClone: HttpRequest<BaseServiceParameter> = req.clone({
            body: ObjectUtils.assign(req.body, {
                UserSessionId: userSessionId,
            }),
        });

        return next.handle(reqClone);
    }
}
