import {
    HierarchyDataDescriptor,
    IDataIdentifier,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { getSpaceIdFromEntityId } from '@datagalaxy/webclient/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

/** concrete ISpaceIdentifier, and static utility lib for ISpaceIdentifier management */
export class SpaceIdentifier implements ISpaceIdentifier {
    public static isProject(spaceIdr?: ISpaceIdentifier) {
        return spaceIdr ? !!spaceIdr.versionId : undefined;
    }

    public static isOrga(spaceIdr: ISpaceIdentifier) {
        return spaceIdr ? !spaceIdr.versionId : undefined;
    }

    /** returns true if the given spaceIdentifier is non null and has no spaceId */
    public static isClientSpace(spaceIdr: ISpaceIdentifier): boolean {
        return spaceIdr && !spaceIdr.spaceId;
    }

    public static getServerTypeName(spaceIdr: ISpaceIdentifier) {
        const serverType = SpaceIdentifier.getServerType(spaceIdr);
        return serverType ? ServerType[serverType] : undefined;
    }

    public static getServerType(
        spaceIdr: ISpaceIdentifier
    ): ServerType.Project | ServerType.Organization | undefined {
        return spaceIdr
            ? spaceIdr.versionId
                ? ServerType.Project
                : ServerType.Organization
            : undefined;
    }

    public static areSame(a: ISpaceIdentifier, b: ISpaceIdentifier) {
        return (
            (!a && !b) ||
            (a &&
                b &&
                (!a.spaceId && !b.spaceId
                    ? !a.versionId && !b.versionId
                    : a.spaceId === b.spaceId) &&
                ((!a.versionId && !b.versionId) || a.versionId === b.versionId))
        );
    }

    public static toString(spaceIdr: ISpaceIdentifier) {
        return spaceIdr
            ? `${spaceIdr.spaceId}v${spaceIdr.versionId ?? ''}`
            : undefined;
    }

    public static fromString(value: string) {
        const splitted = value?.split('v');
        if (splitted?.length != 2) {
            return;
        }
        return new SpaceIdentifier(splitted[0], splitted[1] || null);
    }

    public static haveSameProject(a: ISpaceIdentifier, b: ISpaceIdentifier) {
        return a && b && (a.spaceId == b.spaceId || (!a.spaceId && !b.spaceId));
    }

    /** returns true if undefined, or spaceId and versionId are undefined */
    public static isAllSpaces(spaceIdr: ISpaceIdentifier) {
        return !spaceIdr?.spaceId;
    }

    /** returns a SpaceIdentifier:
     * - from the given ISpaceIdentifier if it has a spaceId,
     * - otherwise a ClientSpace identifier */
    public static fromOrClientSpace(spaceIdr: ISpaceIdentifier) {
        return SpaceIdentifier.from(spaceIdr) ?? new SpaceIdentifier(null);
    }

    /** returns a SpaceIdentifier from the given ISpaceIdentifier if it has a spaceId, otherwise null.
     * @param spaceIdr
     * @param defaultToClientSpace When true and the given spaceIdr is null, undefined or has no spaceId,
     * returns a SpaceIdentifier with null spaceId and versionId (thus targeting the ClientSpace), otherwise returns null. */
    public static from(
        spaceIdr: ISpaceIdentifier,
        defaultToClientSpace = false
    ) {
        return !spaceIdr?.spaceId
            ? defaultToClientSpace
                ? new SpaceIdentifier(null)
                : null
            : new SpaceIdentifier(spaceIdr.spaceId, spaceIdr.versionId);
    }

    /** returns a SpaceIdentifier from the given IDataIdentifier */
    public static fromDataIdentifier(
        dataIdentifier?: IDataIdentifier,
        versionId?: string
    ): ISpaceIdentifier | null {
        const spaceId = getSpaceIdFromEntityId(dataIdentifier?.DataReferenceId);
        return dataIdentifier ? new SpaceIdentifier(spaceId, versionId) : null;
    }

    /** returns a SpaceIdentifier from the given HierarchyDataDescriptor */
    public static fromHdd(
        hdd?: HierarchyDataDescriptor
    ): ISpaceIdentifier | null {
        const spaceId = getSpaceIdFromEntityId(hdd?.DataReferenceId);
        return hdd ? new SpaceIdentifier(spaceId, hdd.VersionId) : null;
    }

    /** returns a SpaceIdentifier from the given entity by:
     * - extracting the spaceId from the ReferenceId,
     * - copying the versionId
     * @param entityIdr
     * @param defaultToClientSpace
     * When true and the given entityIdr is null or undefined, returns a SpaceIdentifier
     * with null spaceId and versionId (thus targeting the ClientSpace), otherwise returns undefined. */
    public static fromEntity(
        entityIdr: IEntityIdentifier,
        defaultToClientSpace = false
    ) {
        return entityIdr
            ? new SpaceIdentifier(
                  getSpaceIdFromEntityId(entityIdr.ReferenceId),
                  entityIdr.VersionId
              )
            : defaultToClientSpace
            ? new SpaceIdentifier(null)
            : undefined;
    }

    /** will be considered :
     * - the client space if no or null spaceId is given,
     * - an organization if no or null versionId is given,
     * - a project otherwise */
    constructor(
        public readonly spaceId?: string | null,
        public readonly versionId?: string | null
    ) {
        if (!this.spaceId) {
            this.spaceId = this.versionId = null;
        }
    }
}
