import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';

/**
 * ## Role
 * Activity log adapter for logs concerning workspaces
 */
export class WorkspaceLogEntryAdapter extends DefaultLogEntryAdapter {
    protected override getTranslateKey(entry: ActivityLogEntry) {
        const entryType = entry.EntryType;
        const key = [
            ActivityLogEntryType.AddObject,
            ActivityLogEntryType.Create,
        ].includes(entryType)
            ? 'addWorkspace'
            : ActivityLogEntryType[entryType];
        return key && `${this.baseTranslateKey}${key}`;
    }

    protected override getChildHData(entry: ActivityLogEntry) {
        if (entry.EntryType == ActivityLogEntryType.Create) {
            const childHdd = entry?.Data;
            const hdd = childHdd && this.getUsedDataDescriptor(entry, childHdd);
            return hdd && new HierarchicalData(hdd, entry.Parents);
        }
        return super.getChildHData(entry);
    }
}
