<dxy-splash-screen></dxy-splash-screen>
<div class="login-zone" [class.slide-out]="out" [class.slide-in]="!out">
    <div class="login-content">
        <!-- login form -->
        <form
            class="account-form"
            [formGroup]="loginForm"
            (ngSubmit)="doLogin()"
        >
            <h1 translate>UI.Login.lblHeader</h1>

            <!-- user login field -->
            <dxy-field-email
                fieldAppearance="outline"
                formControlName="login"
                dxyDataTestId="login-email-field"
                [errorMessageKey]="
                    isLoginError ? 'UI.Login.msgEmailRequired' : undefined
                "
                [placeholder]="'UI.Login.lblLogin' | translate"
                [hideMaxLengthHint]="true"
            >
            </dxy-field-email>

            <!-- password field -->
            <dxy-field-password
                fieldAppearance="outline"
                formControlName="password"
                dxyDataTestId="login-password-field"
                [errorMessageKey]="
                    isPasswordError ? 'UI.Login.msgPasswordRequired' : undefined
                "
                [placeholder]="'UI.Login.lblPassword' | translate"
                [hideMaxLengthHint]="true"
                browserAutocomplete="current-password"
            >
            </dxy-field-password>

            <!-- login button -->
            <button
                mat-flat-button
                color="primary"
                dxyDataTestId="login-submit-button"
                type="submit"
                class="account-button"
            >
                <span translate> UI.Login.btnConnect </span>
            </button>

            <!-- autologin checkbox, forgotten password link -->
            <div class="login-sub">
                <!-- autologin checkbox -->
                <mat-checkbox
                    *ngIf="showAutologin"
                    formControlName="useAutoLogin"
                    name="checkboxAutoLogin"
                >
                    <span translate> UI.Login.lblRemember </span>
                </mat-checkbox>

                <!-- forgotten password link -->
                <a
                    *ngIf="showForgotPassword"
                    class="account-link forgot-password"
                    [uiSref]="StateName.AccountMainReset"
                    dxyDataTestId="forgot-password-link"
                    translate
                >
                    UI.Login.lblForgotPassword
                </a>
            </div>

            <!-- login error message -->
            <div
                *ngIf="isLoginFailed"
                class="account-error"
                role="alert"
                dxyDataTestId="login-error-message"
            >
                <span [translate]="loginErrorMessage"></span>
            </div>
        </form>
    </div>
</div>
