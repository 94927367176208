<ng-container *ngIf="timeSeriesObject?.LastEntry != undefined">
    <dxy-trend-pill
        [trend]="trend"
        [colorRule]="trendColorRule"
        [matTooltip]="'UI.TimeSeriesTrendRenderer.tooltip' | translate"
    ></dxy-trend-pill>

    <div class="value-container">
        {{ timeSeriesObject.LastEntry.Value | numberFormat }}
    </div>
</ng-container>
