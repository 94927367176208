import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { ISuggestion, ISuggestionGroup } from '../suggestion.types';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { HierarchicalData, ServerType } from '@datagalaxy/dg-object-model';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { SuggestionElement } from '@datagalaxy/webclient/suggestion/types';
import {
    AttributeMetaInfo,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';

/**
 * ## Role
 * Adapter to create Suggestion and SuggestionGroup from attribute suggestion
 * elements
 */
@Injectable({ providedIn: 'root' })
export class AttributeSuggestionAdapterFactory extends BaseService {
    constructor(private attributeDataService: AttributeDataService) {
        super();
    }

    public getAttributeSuggestionGroup(
        serverType: ServerType,
        attributeKey: string,
        suggestionElements: SuggestionElement[]
    ): ISuggestionGroup {
        const suggestions = suggestionElements?.filter(
            (se) => se.AttributeKey === attributeKey
        );
        const attribute = this.attributeDataService.getAttribute(
            serverType,
            attributeKey
        );
        const suggestionType = suggestions?.[0]?.SuggestionType;

        return {
            type: suggestionType,
            groupKey: attribute.AttributeKey,
            labelKey:
                this.attributeDataService.getAttributeDisplayName(attribute),
            canAcceptMultiValue: attribute.IsMultiValue,
            suggestions: suggestions?.map((se) =>
                this.getAttributeSuggestion(serverType, se)
            ),
        };
    }

    public getAttributeSuggestionLists(
        serverType: ServerType,
        suggestionElements: SuggestionElement[]
    ): ISuggestionGroup[] {
        const attributeKeys = CollectionsHelper.distinct(
            suggestionElements?.map((se) => se.AttributeKey)
        );

        return attributeKeys?.map((attributeKey) =>
            this.getAttributeSuggestionGroup(
                serverType,
                attributeKey,
                suggestionElements
            )
        );
    }

    public getAttributeSuggestion(
        serverType: ServerType,
        se: SuggestionElement,
        hdd?: HierarchicalData
    ): ISuggestion {
        const attribute = this.attributeDataService.getAttribute(
            serverType,
            se.AttributeKey
        );
        return {
            id: se.Hashcode,
            score: se.Score,
            title: this.attributeDataService.getAttributeDisplayName(attribute),
            titleTooltip: attribute.Description,
            type: se.SuggestionType,
            data: se,
            entity: hdd,
            labelText: this.getSuggestionElementDisplayName(se, attribute),
            tagColor: se.Value?.TagColor,
        };
    }

    private getSuggestionElementDisplayName(
        suggestionElement: SuggestionElement,
        attribute: AttributeMetaInfo
    ): string {
        const s = suggestionElement;
        if (!s?.Value) {
            return;
        }
        const value = s.Value.DisplayName || s.Value;
        if (!attribute) {
            return;
        }
        const option = new AttributeMetaValue(attribute, value, value);
        return this.attributeDataService.getAttributeOptionDisplayName(
            attribute,
            option
        );
    }
}
