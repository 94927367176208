import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { UserDto } from '@datagalaxy/webclient/user/domain';

export enum SpaceRole {
    NoRole,
    Administrator,
    DataOwners,
    DataStewards,
    DpoUsers,
    CdoUsers,
    ExpertUsers,
    CisoUsers,
}
SerializableEnumeration(SpaceRole);

export class SpaceRoleData {
    @deserialize SpaceRole!: SpaceRole;
    @deserialize IsDefault!: boolean;
}

@inheritSerialization(UserDto)
export class SpaceMemberDto extends UserDto {
    @deserializeAs(SpaceRoleData) SpaceRoleDataList!: SpaceRoleData[];
}
