<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Modeler.PrimaryKeySettingsModal.titleModal
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form #form="ngForm">
    <div mat-dialog-content>
        <app-primary-key-settings-content
            [data]="data"
            (outputChange)="onOutputChange($event)"
        ></app-primary-key-settings-content>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            mat-flat-button
            name="cancel"
            (click)="onCloseCancel()"
        >
            <span translate>UI.Global.btnCancel</span>
        </button>
        <button
            type="button"
            mat-flat-button
            color="primary"
            name="submit"
            (click)="onCloseSubmit()"
            dxyLogFunctional="DIAGRAM_PK_TABLE,C"
            [disabled]="!isValidPkSettings"
            [matTooltip]="'UI.Dialog.NewItem.ttCreateButton' | translate"
        >
            <span translate>UI.Global.btnUpdate</span>
        </button>
    </div>
</form>
