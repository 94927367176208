import { Pipe, PipeTransform } from '@angular/core';
import { RichTextContent } from './RichTextContent';

@Pipe({
    name: 'richText',
    standalone: true,
})
export class RichTextPipe implements PipeTransform {
    transform(text: string): any {
        return RichTextContent.getRawText(text, true);
    }
}
