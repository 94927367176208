import { CollectionsHelper } from '@datagalaxy/core-util';

/** Generic serializable document.
 * Important: Do not rename class nor properties */
export class Document {
    /** Parts of the document */
    public Items: DocumentItem[] = [];
    /** External references in the document */
    public Refs: DocumentReference[] = [];

    //#region Items management
    public hasItemByKey(docItemKey: string) {
        return !!this.Items?.some((it) => it.Key === docItemKey);
    }
    public getItemByKey(docItemKey: string): DocumentItem {
        return this.Items?.find((it) => it.Key === docItemKey);
    }
    public getItemContent(docItemKey: string) {
        return this.getItemByKey(docItemKey)?.Content;
    }
    public setItemContent(docItemKey: string, docItemContent: any) {
        const documentItem = this.getItemByKey(docItemKey);
        if (documentItem) {
            documentItem.Content = docItemContent;
        }
    }
    public addItem(
        docItemKey: string,
        docItemType: string,
        docItemContent: any
    ) {
        (this.Items ??= []).push(
            new DocumentItem(docItemKey, docItemType, docItemContent)
        );
    }
    //#endregion

    //#region Refs management
    public getReferences() {
        return this.Refs?.slice() ?? [];
    }
    public getReferenceById(docRefId: string): DocumentReference {
        return this.Refs?.find((ref) => ref.Id === docRefId);
    }
    public addReference(docRef: DocumentReference) {
        (this.Refs ??= []).push(docRef);
    }
    public deleteReference(docRefId: string) {
        CollectionsHelper.remove(this.Refs, (ref) => ref.Id === docRefId);
    }
    //#endregion

    //#region de/serialization
    public fromJsonString(jsonString: string) {
        const jsonObj: Document = JSON.parse(jsonString);
        jsonObj.Items.forEach((item) => {
            const existing = item ? this.getItemByKey(item.Key) : undefined;
            if (existing && existing.Type == item.Type) {
                existing.Content = item.Content;
            } else {
                this.Items.push(DocumentItem.from(item));
            }
        });

        this.Refs = jsonObj.Refs.map(DocumentReference.from);
    }
    public toJsonString() {
        return JSON.stringify(this);
    }
    //#endregion
}

/** Part of a Document.
 * (for RichTextContent: the raw text, or the html text, or the quill data)
 * Note: Serializable - Do not rename anything */
export class DocumentItem {
    static from(item: DocumentItem) {
        return item && new DocumentItem(item.Key, item.Type, item.Content);
    }
    constructor(
        /** (for RichTextContent: RawTextContent, HtmlTextContent, DataContent) */
        public Key?: string,
        /** (for RichTextContent: RawText, HtmlText, QuillDelta) */
        public Type?: string,
        /** (for RichTextContent: a string (for RawText, HtmlText), an any[] (for QuillDelta) */
        public Content?: any
    ) {}
}

/** External reference data in a Document
 * (for example, for a mention in a rich-text content)
 * Note: Serializable - Do not rename anything */
export class DocumentReference {
    static from(dr: DocumentReference) {
        return (
            dr &&
            new DocumentReference(
                dr.Id,
                dr.Kind,
                dr.TargetId,
                dr.TargetType,
                dr.Content
            )
        );
    }
    constructor(
        /** (for a rich-text mention: mentionBlot.mentionId) */
        public Id: string,
        /** (for a rich-text mention: RichTextMentionType) */
        public Kind?: string,
        /** (for a rich-text mention: a user (or user group) id) */
        public TargetId?: string,
        /** (for a rich-text mention: a ServerType) */
        public TargetType?: string,
        public Content?: string
    ) {}
}

//#Archi-cleanup (fbo) Seems unused in WebFront
export class DocumentKindConstants {
    public static User = 'User';
    public static Object = 'Object';
    public static File = 'File';
}
