import * as quill from 'quill';
import { DeltaOperation } from 'quill';
import { IRichTextContentOf } from '../core-rich-text.types';
const Embed = (quill as any).import('blots/embed');
const Parchment = (quill as any).import('parchment');

/** IRichTextContentOf<DeltaOperation[]> (see the *RichTextContent* class) */
export interface IQuillContent extends IRichTextContentOf<DeltaOperation[]> {}

export class QuillEmbededMention extends Embed {
    //#region Not used by super class
    static readonly mentionIdAttribute = 'mention-id';
    //#endregion

    static readonly blotName = 'mention';
    static readonly scope = Parchment.Scope.INLINE_BLOT;

    //#region Stored in html content - DO NOT MODIFY */
    /** Tag name of the mention in the html. Note this is *not* used as an angular component selector */

    /** Additional Note: Must update server-side SecurityUtil.IsStringXssExploitSafe if we want to change the tagName
     * for the Mention, as we explicitly white-list this tag for XSS Exploit  */
    static readonly tagName = 'rich-text-mention';
    static readonly className = 'ql-mention';
    //#endregion

    // called by Quill editor insertEmbed()
    static create(mentionId: string) {
        const node: Element = super.create(mentionId); // uses tagName and className
        node.setAttribute(QuillEmbededMention.mentionIdAttribute, mentionId);
        node.setAttribute('contenteditable', 'false');
        return node;
    }
    static value(node: Element): any {
        return node.getAttribute(QuillEmbededMention.mentionIdAttribute);
    }
    static formats(node: Element): boolean {
        return true;
    }

    public index(node: Element, offset: number): number {
        return 1;
    }
}
