<div *ngIf="isReadonly" class="read-only-field">
    <label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
        >{{ label }}</label
    >
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <p class="value-container">{{ value }}</p>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>

<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    [appearance]="fieldAppearance"
    [class.dg5-compact]="compact"
    j
    [hintLabel]="hint"
>
    <mat-label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
        >{{ label }}</mat-label
    >
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <input
        type="text"
        matInput
        #fieldControl="matInput"
        [placeholder]="placeholder"
        [errorStateMatcher]="errorStateMatcher"
        [(ngModel)]="value"
        [disabled]="disabled"
        [maxLength]="maxLength"
        (ngModelChange)="onInputChange($event)"
        [matAutocomplete]="matAutocomplete"
        [matAutocompleteDisabled]="!matAutocomplete"
        [required]="required"
    />
    <mat-hint *ngIf="showMaxLengthHint" align="end"
        >{{ value?.length || 0 }}/{{ maxLength }}</mat-hint
    >
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
