/*
    Notes:
    - For clipboard (ctrl-c, ctrl-v) use ClipBoardEvent instead
    - For ctrl-a/cmd-a from non-input element, use DomUtil.attachSelectAll
*/

/** Keyboard events tools*/
export class KeyboardUtil {
    /** prevents the Enter key's default action. Returns true if the key is Enter. */
    public static preventEnterKey(
        event: KeyboardEvent,
        stopPropagation = false
    ) {
        if (!KeyboardUtil.isEnterKey(event)) {
            return false;
        }
        event.preventDefault();
        if (stopPropagation) {
            event.stopPropagation();
        }
        return true;
    }

    /** returns true if the key is Enter */
    public static isEnterKey(event: KeyboardEvent) {
        return event?.key === 'Enter';
    }

    /** returns true if the key is the space bar */
    public static isSpaceBarKey(event: KeyboardEvent) {
        return event?.key == ' ' || event?.key == 'SpaceBar';
    }

    /** returns true if the key is Escape */
    public static isEscapeKey(event: KeyboardEvent) {
        return event?.key == 'Escape';
    }

    /** returns true if the key is Delete or Backspace */
    public static isDeleteOrBackspaceKey(event: KeyboardEvent) {
        return event?.key === 'Backspace' || event?.key === 'Delete';
    }

    /** returns true if the key is Delete or Backspace, and the event is not from an input or textarea */
    public static isDeleteOrBackspaceKeyButFromInput(event: KeyboardEvent) {
        if (!KeyboardUtil.isDeleteOrBackspaceKey(event)) {
            return false;
        }
        return !KeyboardUtil.isFromInputOrTextarea(event);
    }

    /** returns true if the event is from an input or textarea */
    public static isFromInputOrTextarea(event: Event) {
        const tagName = (event?.target as Element)?.tagName;
        return tagName == 'INPUT' || tagName == 'TEXTAREA';
    }

    /** Note that on Mac, Control-click is used to emulate right-click so it's not available to the app */
    public static hasShiftOrControl(event: KeyboardEvent | MouseEvent) {
        return event?.shiftKey || event?.ctrlKey;
    }

    /** Returns the state of the alt, shift, and ctrl keys during the given event,
     * if any is pressed, otherwise undefined.
     *  Note: On Mac, ctrl key is used for right-click, so we use the cmd key instead */
    public static getAltShiftCtrl(e: MouseEvent): IKbdAltShiftCtrl | undefined {
        if (!e) {
            return;
        }
        const alt = e.altKey,
            shift = e.shiftKey,
            ctrl = e.ctrlKey || e.metaKey;
        return alt || shift || ctrl ? { alt, shift, ctrl } : undefined;
    }

    /** returns true if any of alt, shift, ctrl is true in both given objects */
    public static isSameAltShiftCtrl(
        ka: IKbdAltShiftCtrl,
        kb: IKbdAltShiftCtrl
    ) {
        return (
            ka &&
            kb &&
            ((ka.alt && kb.alt) ||
                (ka.shift && kb.shift) ||
                (ka.ctrl && kb.ctrl))
        );
    }
}

export interface IKbdAltShiftCtrl {
    alt?: boolean;
    shift?: boolean;
    /** or cmd key on Mac, because the ctrl key is used for right-click */
    ctrl?: boolean;
}
