<!--Header-->
<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Connector.Scheduler.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>

<!--Body-->
<form class="form-horizontal attribute-form-container" (ngSubmit)="submit()">
    <div mat-dialog-content>
        <header class="header">
            <div class="description" translate="">
                UI.Connector.Scheduler.description
            </div>
            <div class="radio-buttons">
                <!-- #Archi-fields (revi) replace all mat-radio-groups by dxy-fieldèradio -->
                <mat-radio-group
                    name="frequency"
                    [(ngModel)]="frequencyType"
                    (ngModelChange)="onChangeFrequency($event)"
                    [disabled]="!enabled"
                >
                    <mat-radio-button
                        name="daily"
                        [value]="schedulerFrequencyType.Daily"
                        dxyDataTestId="radio-daily"
                        dxyDataTestIdTarget="label"
                    >
                        <span translate>UI.Connector.Scheduler.daily</span>
                    </mat-radio-button>
                    <mat-radio-button
                        name="weekly"
                        [value]="schedulerFrequencyType.Weekly"
                        dxyDataTestId="radio-weekly"
                        dxyDataTestIdTarget="label"
                    >
                        <span translate>UI.Connector.Scheduler.weekly</span>
                    </mat-radio-button>
                    <mat-radio-button
                        name="monthly"
                        [value]="schedulerFrequencyType.Monthly"
                        dxyDataTestId="radio-monthly"
                        dxyDataTestIdTarget="label"
                    >
                        <span translate>UI.Connector.Scheduler.monthly</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </header>
        <div class="fields-container">
            <dxy-field-multi-select
                *ngIf="dailyValue"
                class="daily"
                [disabled]="!enabled"
                name="dailyValue"
                dxyDataTestId="frequency-daily-select"
                labelKey="UI.Connector.Scheduler.frequencyDailyLabel"
                [data]="frequencyDailyMultiSelectData"
                (openClose)="onPanelOpenClose($event)"
            >
            </dxy-field-multi-select>
            <dxy-field-select
                *ngIf="weeklyValue"
                class="weekly"
                [disabled]="!enabled"
                name="weeklyValue"
                dxyDataTestId="frequency-weekly-select"
                labelKey="UI.Connector.Scheduler.day"
                [options]="weekdayOptions"
                [(ngModel)]="weekdayValue"
                (ngModelChange)="onSelectWeekday($event)"
                (openClose)="onPanelOpenClose($event)"
            >
            </dxy-field-select>
            <dxy-field-select
                *ngIf="monthlyValue"
                class="monthly"
                [disabled]="!enabled"
                name="monthlyValue"
                dxyDataTestId="frequency-monthly-select"
                labelKey="UI.Connector.Scheduler.frequencyMonthLabel"
                [options]="frequencyMonthlyOptions"
                [(ngModel)]="frequencyMonthlyValue"
                [adapter]="frequencyMonthlyAdapter"
                (ngModelChange)="onSelectFrequencyMonthly($event)"
                (openClose)="onPanelOpenClose($event)"
            >
            </dxy-field-select>
            <dxy-field-select
                *ngIf="monthlyValue"
                class="day"
                [disabled]="!enabled"
                name="day"
                dxyDataTestId="scheduler-day-select"
                labelKey="UI.Connector.Scheduler.day"
                [options]="monthDayOptions"
                [(ngModel)]="monthDayValue"
                (ngModelChange)="onSelectMonthDay($event)"
                (openClose)="onPanelOpenClose($event)"
            >
            </dxy-field-select>
            <div class="time">
                <dxy-field-select
                    class="timezone"
                    [disabled]="!enabled"
                    name="timezone"
                    dxyDataTestId="scheduler-timezone-select"
                    labelKey="UI.Connector.Scheduler.timezone"
                    [infoTooltip]="getTimezomeTooltip()"
                    [options]="timezoneOptions"
                    [(ngModel)]="timezoneValue"
                    [adapter]="timezoneAdapter"
                    (openClose)="onPanelOpenClose($event)"
                    [search]="true"
                    [displayOptionTooltips]="true"
                >
                </dxy-field-select>
                <dxy-field-text
                    class="time-field"
                    [disabled]="!enabled"
                    labelKey="UI.Connector.Scheduler.time"
                    name="time"
                    dxyDataTestId="scheduler-time-field"
                    [placeholder]="getTimePlaceholder()"
                    [(ngModel)]="timeInputValue"
                    (ngModelChange)="onChangeTime()"
                >
                </dxy-field-text>
            </div>
        </div>
    </div>

    <!--Footer-->
    <div class="modal-footer">
        <div class="scheduler-enabled">
            <mat-slide-toggle
                color="primary"
                (toggleChange)="onChangeEnabled()"
                [checked]="enabled"
                dxyDataTestId="scheduler-toggle"
                dxyDataTestIdTarget="input"
            ></mat-slide-toggle>
            <span class="toggle-label" translate
                >UI.Connector.Scheduler.enabled</span
            >
        </div>
        <div>
            <button mat-flat-button type="button" (click)="onCloseCancel()">
                <span translate>UI.Dialog.btnCancel</span>
            </button>
            <button
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="!formIsValid || isLoading"
            >
                <span translate>UI.Global.btnSave</span>
                <span *ngIf="isLoading" class="dg4-spinner"></span>
            </button>
        </div>
    </div>
</form>
