import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { PropertyType, ServerType } from '@datagalaxy/dg-object-model';
import { IEntityFusionModalInput } from '../../shared/entity/interfaces/entity-fusion-modal.interface';
import { IEntitySelectorInputs } from '../../shared/entitySelector/entity-selector.types';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { EntityService } from '../../shared/entity/services/entity.service';
import { FusionEntitiesResult } from '@datagalaxy/webclient/entity/data-access';
import { isUnsuccessfulApiError } from '@datagalaxy/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule } from '@angular/forms';
import { DxyEntitySelectorFieldComponent } from '../../shared/entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { NgIf } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'dxy-glossary-fusion-modal',
    templateUrl: './dxy-glossary-fusion-modal.component.html',
    styleUrls: ['./dxy-glossary-fusion-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        NgIf,
        forwardRef(() => DxyEntitySelectorFieldComponent),
        FormsModule,
        MatLegacyFormFieldModule,
        MatLegacyCheckboxModule,
        SpinnerComponent,
        DxyLogFunctionalDirective,
    ],
})
export class DxyGlossaryFusionModalComponent
    extends DxyBaseModalComponent<IEntityFusionModalInput, FusionEntitiesResult>
    implements OnInit
{
    public hasIncompatibilityError: boolean;
    public targetProperty: EntityItem;
    public includeDetails: boolean;
    public includeRelations: boolean;
    public includeImplementations: boolean;
    public isLoading: boolean;
    public errorMessage: any;
    public entitySelectorData: IEntitySelectorInputs;

    public get sourceProperties() {
        return this.data.sourceEntities;
    }
    public get spaceIdr() {
        return this.data.spaceIdr;
    }
    public get targetPropertyId() {
        return this.targetProperty?.DataReferenceId;
    }
    public get sourcePropertyIds() {
        return this.sourceProperties?.map(
            (sourceProperty) => sourceProperty.ReferenceId
        );
    }
    public get hasSelectedTargetProperty() {
        return !!this.targetPropertyId;
    }
    public get isFusionFormEnabled() {
        return !this.isLoading && !this.hasIncompatibilityError;
    }
    public get targetErrorMessageKey() {
        return this.targetProperty &&
            !this.targetProperty?.SecurityData.HasWriteAccess
            ? 'UI.Glossary.fusion.lblTargetPropertyErrorWriteAccess'
            : null;
    }

    constructor(
        dialogRef: MatDialogRef<DxyGlossaryFusionModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IEntityFusionModalInput,
        private translate: TranslateService,
        private entityService: EntityService,
        private dxyModalService: DxyModalService
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.hasIncompatibilityError = this.computeHasIncompatibilityErrors();
        this.buildEntitySelectorData();
    }

    public onSelectTargetProperty(entity: EntityItem) {
        this.targetProperty = entity;
    }

    public isSubmitEnabled() {
        return (
            !this.isLoading &&
            this.hasSelectedTargetProperty &&
            (this.includeDetails ||
                this.includeRelations ||
                this.includeImplementations) &&
            !this.targetErrorMessageKey
        );
    }

    public async onSubmit() {
        const confirmed = await this.dxyModalService.confirm({
            titleKey: 'UI.Glossary.fusion.title',
            messageKey: 'UI.Glossary.fusion.lblConfirmationModalMessage',
        });
        if (!confirmed) {
            return;
        }
        this.isLoading = true;
        try {
            try {
                this.result = await this.entityService.fusionEntities(
                    ServerType.Property,
                    this.targetPropertyId,
                    this.sourcePropertyIds,
                    this.includeDetails,
                    this.includeRelations,
                    this.includeImplementations
                );
                super.onCloseSubmit();
            } catch (e) {
                if (isUnsuccessfulApiError<FusionEntitiesResult>(e)) {
                    if (e.error.IsErrorHasExistingLogicalParentLinks) {
                        this.errorMessage = this.translate.instant(
                            'UI.Glossary.fusion.lblLogicalLinksError'
                        );
                    }
                }
            }
        } finally {
            this.isLoading = false;
        }
    }

    private computeHasIncompatibilityErrors() {
        const propTypes =
            CollectionsHelper.distinct(
                this.sourceProperties?.map((sp) => PropertyType[sp.SubTypeName])
            ) ?? [];
        if (propTypes?.length == 1) {
            return propTypes[0] == PropertyType.ReferenceData;
        }
        return !CollectionsHelper.contentEquals(propTypes, [
            PropertyType.BusinessTerm,
            PropertyType.Indicator,
        ]);
    }

    private buildEntitySelectorData() {
        this.entitySelectorData = {
            spaceIdr: this.spaceIdr,
            includedEntityTypes: CollectionsHelper.distinct(
                this.sourceProperties.map((e) => e.EntityType)
            ),
            excludedIds: this.sourcePropertyIds,
            includeOnlyHasWriteAccess: true,
        };
    }
}
