export const azureSqlFieldInfo = {
    server: {
        hint: {
            fr: 'Nom de domaine complet (FQDN) de l’instance Azure SQL, exemple : myazuresqlserver.database.windows.net',
            en: 'Azure SQL instance fully qualified name (FQDN), example: myazuresqlserver.database.windows.net',
        },
    },
    port: {
        hint: {
            fr: 'Port TCP de la base Azure SQL',
            en: 'Azure SQL TCP port',
        },
    },
    instance: {
        hint: {
            fr: 'Limite le périmètre à la base de données spécifiée',
            en: 'Limits the scope to the specified database',
        },
    },
    schema: {
        hint: {
            fr: 'Limite le périmètre au schéma spécifié',
            en: 'Limits the scope to the specified schema',
        },
    },
    azureAuthMode: {
        description: {
            fr: "Sélectionnez un mode d'authentification : SQL (mode d’authentification Azure par défaut), Mot de passe AAD, ou Service Principal AAD",
            en: 'Select an authentication mode: SQL (default Azure authentication mode), AAD Password, or AAD Service Principal',
        },
        hint: {
            fr: 'Sélectionner un mode d’authentification ',
            en: 'Select an authentication mode',
        },
    },
    user: {
        hint: {
            fr: 'Compte utilisateur Azure SQL',
            en: 'Azure SQL user account',
        },
    },
    clientId: {
        hint: {
            fr: 'Identifiant du compte de service client Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure client service account identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    tenantId: {
        hint: {
            fr: 'Identifiant du tenant Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure tenant identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    clientSecret: {
        hint: {
            fr: 'Secret du client Azure',
            en: 'Azure customer secret',
        },
    },
};
