import * as moment from 'moment';
import { IBaseFilterData } from '../base-filter/base-filter.types';

export interface IDateFilterData extends IBaseFilterData<DateFilterOperator> {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
}

export enum DateFilterOperator {
    Unknown = 0,
    RangeContains,
    DateTimePastHour,
    DateTimeToday,
    DateTimeBeforeToday,
    DateTimeYesterday,
    DateTimeCurrentWeek,
    DateTimePastWeek,
    DateTimeBeforeCurrentWeek,
    DateTimeBeforePastWeek,
    DateTimeCurrentMonth,
    DateTimePastMonth,
    DateTimeBeforeCurrentMonth,
    DateTimeBeforePastMonth,
    DateTimeCurrentYear,
    FieldIsEmpty,
    FieldHasValue,
}
