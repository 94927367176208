import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { FormControl } from '@angular/forms';
import { Language } from './language';

export const languageAdapter: IFieldSelectAdapter<Language> = {
    getText: (l: Language) => l.languageName,
    getId: (l: Language) => l.languageCode,
};

export interface LanguageForm {
    language: FormControl<Language | null>;
}
