import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseLinkDataInfo, EntityType } from '@datagalaxy/dg-object-model';

export class DataLineageItem {
    @deserializeAs(EntityType) public EntityType!: EntityType;
    @deserialize public DataLocalId!: string;
    @deserialize public DisplayName!: string;
    @deserialize public TechnicalName!: string;
    @deserialize public SubTypeName!: string;
    @deserialize public ParentLocalId!: string;
    @deserialize public LazyChildrenCount!: number;
    @deserialize public HasReadAccess!: boolean;
}

export enum LineageLinkOrientationType {
    Unoriented,
    /** the link has an end marker */
    Oriented,
    /** Oriented, and src and tgt were swapped from the original link */
    Swapped,
}
SerializableEnumeration(LineageLinkOrientationType);

@inheritSerialization(BaseLinkDataInfo)
export class DataLineageDataLink extends BaseLinkDataInfo {
    @deserialize public SourceId!: string;
    @deserialize public TargetId!: string;
    @deserialize public EntityLinkReferenceId!: string;
    @deserialize public OrientationType!: LineageLinkOrientationType;
    /** True when at least one of the 2 two linked objects is not loaded */
    @deserialize public IsLazy!: boolean;
}

export class DataLineageGenerationData {
    @deserialize public Level?: number;
    @deserialize public DataLocalIds?: Array<string>;

    constructor(level?: number, dataLocalIds?: string[]) {
        this.Level = level;
        this.DataLocalIds = dataLocalIds;
    }
}
