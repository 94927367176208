import { IXYRect } from './2d.types';
import { DragHandle, IDragHandleOptions } from './DragHandle';
import { CardinalUtil } from './CardinalUtil';
import { IRect2Sides, Rect } from './Rect';
import { CardinalPosition } from './cardinal.types';
import { DomUtil, TCSSStylePropertyKeys } from '@datagalaxy/core-util';

/** SVG or HTML element associated to a rectangle, for a dom element to be resized by drag */
export class ResizeHandle extends DragHandle<IResizeHandleOptions> {
    private static readonly defaultSize = 20;

    /** Generates the *ResizeHandle*s for the given rectangle */
    public static make(
        rect: IXYRect,
        frameRect: IXYRect,
        /** true for handles coordinates to be relative to *rect* */
        relative: boolean,
        options: IResizeHandlesOptions
    ) {
        return (
            options?.cardinals ??
            (options?.cornersOnly
                ? CardinalUtil.cornerPositions
                : CardinalUtil.allPositions)
        ).map((cp) => new ResizeHandle(rect, frameRect, cp, relative, options));
    }

    public readonly rectSides: IRect2Sides = {};
    public get resizeCursor() {
        return CardinalUtil.resizeCursor(this.cp);
    }

    constructor(
        /** the rectangle being resized */
        public readonly rect: IXYRect,
        public readonly frameRect: IXYRect,
        /** position of the handle */
        public readonly cp: CardinalPosition,
        /** set to true to make this handle's location coordinates relative to the provided *rect*'s location */
        private relative: boolean,
        options: IResizeHandlesOptions
    ) {
        super(options);
        CardinalUtil.getRectSides(cp, this.rectSides);
        this.setHandleSize();

        const element = DomUtil.createElement(
            'div',
            DomUtil.addClass(`rm-handle ${cp}`, options.class)
        );
        element.style.position = 'absolute';
        element.style.cursor = options.noCursor ? null : this.resizeCursor;

        if (options.margin) {
            const div = DomUtil.createElement('div');
            element.style.padding = `${options.margin}px`;
            div.style.height = '100%';

            element.append(div);
        }

        const style = options.style;
        style &&
            Object.entries(style).forEach(
                ([k, v]) =>
                    (element.style[k] =
                        typeof v == 'object'
                            ? `${v.value}${v.important ? '!important' : ''}`
                            : v)
            );
        this.init(element, true);
        this.draw();
    }

    public getSidePoint() {
        return Rect.getXY(this.rect, this.rectSides);
    }

    public override update() {
        const { frameRect, width, height, rectSides } = this;
        Rect.getXY(frameRect, rectSides, this);
        this.shiftXY(-width / 2, -height / 2);
        if (this.relative) {
            this.makeRelativeTo(frameRect);
        }
        return this;
    }

    public addClass(className: string) {
        this.element.classList.add(className);
    }

    public removeClass(className: string) {
        this.element.classList.remove(className);
    }

    private setHandleSize() {
        const {
            size: handleSize = ResizeHandle.defaultSize,
            sideSize: sideHandleSize = handleSize,
            noDecimals,
        } = this.options;
        this.width = handleSize;
        this.height = handleSize;
        if (sideHandleSize == handleSize || !CardinalUtil.isASide(this.cp)) {
            return;
        }

        let length: number, breadth: number;

        if (typeof sideHandleSize == 'number') {
            length = sideHandleSize;
            breadth = handleSize;
        } else {
            length = sideHandleSize.length ?? handleSize;
            breadth = Math.min(
                sideHandleSize.breadth ?? handleSize,
                handleSize
            );
        }
        if (this.rectSides.lr) {
            this.width = breadth;
            this.height = length;
        } else {
            this.width = length;
            this.height = breadth;
        }
        Rect.truncateDecimals(this, noDecimals);
    }
}

export interface IResizeHandlesOptions extends IResizeHandleOptions {
    /** true not to display side handles */
    cornersOnly?: boolean;
    cardinals?: CardinalPosition[];
    /** css class name to be applied to the handle elements container element */
    frameClass?: string;
    /** css class name to be applied to each handle */
    class?: string;
    /** css properties to be applied to each handle */
    style?: Partial<{
        [K in TCSSStylePropertyKeys]:
            | string
            | { value: string; important: boolean };
    }>;
    /** true not to show the resize cursor when hovering the handle */
    noCursor?: boolean;
}
export interface IResizeHandleOptions extends IDragHandleOptions {
    /** size of the handle - defaults to 20 */
    size?: number;
    /** length of the handle for sides - defaults to *size*. Note: *breadth* cannot not exceed *size* */
    sideSize?: number | { length?: number; breadth?: number };
    /** When defined, an inner element is added to the handle's element, within a padding set to this value.
     * This can be useful to make a hovering zone larger than the handle. */
    margin?: number;
    class?: string;
    noCursor?: boolean;
}
