import { deserialize, deserializeAs } from 'cerialize';
import {
    AttributeMetaType,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';

export class AttributeFilterValue {
    @deserialize public ValueKey!: string;
    @deserialize public ValueName!: string;
    @deserialize public IsNameTranslated!: boolean;
    @deserialize public Count!: number;
    @deserializeAs(AttributeObjectValue)
    public ObjectValue!: AttributeObjectValue;
}

export class AttributeFilter {
    @deserialize public AttributeKey!: string;
    @deserialize public AttributeName!: string;
    @deserialize public DataTypeName!: string;
    @deserializeAs(AttributeMetaType) public AttributeType!: AttributeMetaType;
    @deserializeAs(FilterOperator) public Operator!: FilterOperator;
    @deserialize public IsNameTranslated!: boolean;
    @deserializeAs(AttributeFilterValue) public Values!: AttributeFilterValue[];
}
