<div class="team-audit-container">
    <div *ngIf="teamHistoryData.isGroupHeader" class="logElement-title">
        {{ teamHistoryData.creationTimeFromNow }}
    </div>

    <div
        class="logElement-item dg_animate animation-fade"
        [innerHTML]="teamHistoryData.innerHtml"
    ></div>

    <div class="hidden-data">
        <div class="team-data">
            <img
                *ngIf="teamImgUrl"
                [src]="teamImgUrl"
                class="team-img team-icon"
                alt="teamIcon"
            />
            <span
                *ngIf="!teamImgUrl"
                [ngClass]="teamGlyphClass"
                class="team-glyph team-icon"
            ></span>
            <span>{{ teamHistoryData.teamData.TeamName }}</span>
        </div>
        <div *ngIf="teamHistoryData.maker" class="user-data maker-data">
            <dxy-profile-avatar
                *ngIf="teamHistoryData.maker"
                [userId]="teamHistoryData.maker.ReferenceId"
                [isMiniIcon]="true"
            ></dxy-profile-avatar>
            {{ teamHistoryData.maker.FullName }}
        </div>
        <div *ngIf="teamHistoryData.member" class="user-data member-data">
            <dxy-profile-avatar
                *ngIf="teamHistoryData.member"
                [userId]="teamHistoryData.member.ReferenceId"
                [isMiniIcon]="true"
            ></dxy-profile-avatar>
            {{ teamHistoryData.member.FullName }}
        </div>
    </div>
</div>
