export type FnExecutor = <T>(fn: () => T) => T;

export interface INgZone {
    run: FnExecutor;
    runOutsideAngular: FnExecutor;
}

export class ZoneUtils {
    /** Executes *window.setTimeout* passing it the given function and delay.
     * If *ngZone* is provided, execution is done using *ngZone.run* or,
     * if *outsideAngular* is true, using *ngZone.runOutsideAngular* */
    static zoneTimeout(
        fn: () => unknown,
        delayMs?: number,
        ngZone?: INgZone,
        outsideAngular?: boolean
    ): number {
        return this.zoneExecute(
            () => window.setTimeout(fn, delayMs),
            ngZone,
            outsideAngular
        );
    }

    /** Executes the given function.
     * If *ngZone* is provided, execution is done using *ngZone.run* or,
     * if *outsideAngular* is true, using *ngZone.runOutsideAngular* */
    static zoneExecute<T>(
        fn: () => T,
        ngZone?: INgZone,
        outsideAngular?: boolean
    ) {
        return ngZone
            ? outsideAngular
                ? ngZone.runOutsideAngular(fn)
                : ngZone.run(fn)
            : fn();
    }
}
