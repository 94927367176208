import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from '@angular/core';
import { AttributeTranslationBaseInput } from '../attribute-translation-base-input';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { AttributeTranslationButtonComponent } from '../../translations';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-attribute-rich-text-translation-input',
    templateUrl: './attribute-rich-text-translation-input.component.html',
    styleUrls: ['./attribute-rich-text-translation-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyRichTextFieldComponent,
        FormsModule,
        NgIf,
        AttributeTranslationButtonComponent,
        DxyIconButtonDirective,
        AsyncPipe,
    ],
})
export class AttributeRichTextTranslationInputComponent extends AttributeTranslationBaseInput {
    constructor() {
        super();
    }
}
