import { ICaptionPosition } from '@datagalaxy/core-ui/graphical';
import { CoreUtil } from '@datagalaxy/core-util';
import {
    CardinalPosition,
    CardinalUtil,
    IShift,
    ISizeRO,
    IWidthHeight,
} from '@datagalaxy/core-2d-util';
import {
    DiagramLinkKind,
    DiagramLinkType,
    IDiagramNodeSpec,
    TNodeType,
    TSizeMode,
} from './diagram.types';
import { ArrowType, LineStyle } from '@datagalaxy/core-d3-util';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

/*
    Notes:
    To avoid some side effects, every size should be a multiple of the grid's cell size (20x20).
*/

export class DiagramConstants {
    public static readonly behaviour = {
        onDrop: {
            fromLinkedObjects: {
                fromCurrentPreview: {
                    closePreviewPanel: false,
                },
                fromNonCurrentPreview: {
                    closePreviewPanel: false,
                },
            },
        },
        /** Minimum delay, in millisecond, between 2 requests to getFilteredEntities
         * to check if an entity is linked in the DG model */
        hasLinksCacheTimeMs: 3000,
    };

    /** Note: legacy min size for table elements was w:150, h:100.
     * The following thresholds allow them to keep their size. */
    public static readonly sizeModeThreshold = {
        medium_min_width: 150,
        medium_max_height: 96,
    };

    public static readonly offsetNewTable = 300;
    public static readonly offsetChangeSourceTarget = 200;
    public static readonly offsetNewFkTarget = 500;
    public static readonly offsetLinkedObject = 150;
    public static readonly entityColorBorderWidth = 6;

    public static readonly dropSideOffset = 100;
    public static readonly multiDropShift: IShift = { dx: 50, dy: 50 };
    public static cloneShift(type: TNodeType): IShift {
        switch (type) {
            case 'entity':
                return { dx: 50, dy: 50 };
            case 'note':
                return { dx: 30, dy: 30 };
            case 'frame':
                return { dx: 30, dy: 30 };
        }
    }

    public static readonly zoom = {
        // from 0% to 200% when displayed rounded
        range: [0.001, 2] as [number, number],
        // used by zoom buttons
        nudge: 0.1, // 10% increment or decrement
    };

    public static readonly bgGrid = {
        cellSize: 20,
        dotRadius: 1,
        magneticDistance: 5,
        /** for the grid to be visible down to minimum zoomlevel */
        bounds: { w: 50000, h: 50000 },
    };

    public static readonly assetsPanel = {
        width: 420,
        slideDelayMs: 200,
        side: 'left' as 'left' | 'right',
    };

    public static readonly margins = {
        frame: {
            content: 20,
        },
        selection: {
            resize: 6,
            boundingBox: 6,
        },
    };

    public static getDefaultSize(
        type: TNodeType,
        sizeMode?: TSizeMode,
        extraDataVisible?: boolean
    ): IWidthHeight {
        switch (type) {
            case 'entity':
                switch (sizeMode) {
                    case 'nano':
                        return DiagramConstants.entityNanoSize;
                    case 'mini':
                        return DiagramConstants.entityMinSize;
                    case 'medium': {
                        const size: IWidthHeight = {
                            ...DiagramConstants.entityMediumSize,
                        };
                        if (extraDataVisible) {
                            size.height += this.extraDataHeight;
                        }
                        return size;
                    }
                    case 'maxi':
                    default:
                        return DiagramConstants.entityDefaultSize;
                }
            case 'note':
            default:
                return DiagramConstants.noteDefaultSize;
        }
    }
    public static getMinSize(
        spec: IDiagramNodeSpec,
        sizeMode: TSizeMode,
        extraDataVisible?: boolean
    ): ISizeRO {
        const type = spec.data.type;
        const hasColor = !!spec.data.visualData?.color;
        switch (type) {
            case 'entity': {
                switch (sizeMode) {
                    case 'nano':
                        return DiagramConstants.entityNanoSize;
                    case 'mini': {
                        if (hasColor) {
                            return {
                                height: DiagramConstants.entityMinSize.height,
                                width:
                                    DiagramConstants.entityMinSize.width +
                                    DiagramConstants.entityColorBorderWidth,
                            };
                        }
                        return DiagramConstants.entityMinSize;
                    }
                    case 'medium': {
                        if (extraDataVisible) {
                            return {
                                width: DiagramConstants.entityDefaultMinSize
                                    .width,
                                height:
                                    DiagramConstants.entityDefaultMinSize
                                        .height + this.extraDataHeight,
                            };
                        }
                        return { ...DiagramConstants.entityDefaultMinSize };
                    }
                    default:
                        return DiagramConstants.entityDefaultSize;
                }
            }
            case 'note':
                return DiagramConstants.noteMinSize;
            case 'frame':
                return DiagramConstants.frameMinSize;
            default:
                return { width: 0, height: 0 };
        }
    }

    public static getMaxSize(
        spec: IDiagramNodeSpec,
        sizeMode: TSizeMode,
        extraDataVisible?: boolean
    ): IWidthHeight {
        const type = spec.data.type;
        const hasColor = !!spec.data.visualData?.color;
        switch (type) {
            case 'entity': {
                switch (sizeMode) {
                    case 'nano':
                        return DiagramConstants.entityNanoSize;
                    case 'mini': {
                        if (hasColor) {
                            return {
                                height: DiagramConstants.entityMinSize.height,
                                width:
                                    DiagramConstants.entityMinSize.width +
                                    DiagramConstants.entityColorBorderWidth,
                            };
                        }
                        return DiagramConstants.entityMinSize;
                    }
                    case 'medium': {
                        if (extraDataVisible) {
                            return {
                                width: DiagramConstants.entityMediumMaxSize
                                    .width,
                                height:
                                    DiagramConstants.entityMediumMaxSize
                                        .height + this.extraDataHeight,
                            };
                        }
                        return { ...DiagramConstants.entityMediumMaxSize };
                    }
                    default:
                        return null;
                }
            }
            default:
                return null;
        }
    }

    public static getResizeCardinals(
        type: TNodeType,
        sizeMode: TSizeMode
    ): CardinalPosition[] {
        switch (type) {
            case 'entity': {
                switch (sizeMode) {
                    case 'maxi':
                        return CardinalUtil.allPositions;
                    default:
                        return CardinalUtil.horizontalSides;
                }
            }
            default:
                return null;
        }
    }

    public static readonly cursorLinkSvgUrl = `url(${URL.createObjectURL(
        new Blob(
            [
                `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 35.8" width="20"><g>
<path d="M0,0v24.2l1.6-1.3l3.8-3.2l1.9,3.8l0.4,0.9l0.9-0.4l2.9-1.5l0.8-0.4l-0.4-0.9l-1.7-3.4l4.8-0.6l1.9-0.2l-1.4-1.4L1.6,1.6
    L0,0z M1.9,4.6l10.9,10.9L8.6,16l-1.3,0.2l0.6,1.2l1.9,3.8l-1.2,0.6l-2-4.1L6,16.7l-0.9,0.8l-3.2,2.7V4.6z"/>
<path d="M24,19.7c0-1.6-1.3-2.8-2.8-2.8s-2.8,1.3-2.8,2.8c0,1.2,0.8,2.3,1.9,2.7v0.2c0,1.6-1.3,2.8-2.8,2.8h-1.9
    c-2.6,0-4.7,2.1-4.7,4.7v0.2c-1.1,0.4-1.9,1.4-1.9,2.7c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.2-0.8-2.3-1.9-2.7v-0.2
    c0-1.6,1.3-2.8,2.8-2.8h1.9c2.6,0,4.7-2.1,4.7-4.7v-0.2C23.2,22,24,20.9,24,19.7z M11.7,33.9c-0.5,0-0.9-0.4-0.9-0.9
    c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C12.7,33.5,12.2,33.9,11.7,33.9z M21.2,18.8c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9
    c-0.5,0-0.9-0.4-0.9-0.9C20.2,19.2,20.6,18.8,21.2,18.8z"/>
<polygon fill="#FFFFFF" points="1.9,4.6 12.8,15.5 8.6,16 7.2,16.2 7.9,17.4 9.8,21.1 8.6,21.8 6.5,17.7 6,16.7 5.1,17.4 1.9,20.1"/>
</g></svg>`,
            ],
            { type: 'image/svg+xml' }
        )
    )})`;

    public static readonly colors = {
        /** for Note elements */
        note: {
            /** set on creation */
            default: GraphicalColor.Yellow,
            /** set when removing the color */
            clear: GraphicalColor.White,
        },
        /** for Node elements */
        entity: {
            /** set on creation */
            default: undefined,
            /** set when removing the color */
            clear: undefined,
            /** default background color for nano size-mode */
            defaultNano: GraphicalColor.White,
        },
        /** for Frame elements */
        frame: {
            /** set on creation */
            default: GraphicalColor.Indigo,
            /** set when removing the color */
            clear: GraphicalColor.LightGray,
        },
        edge: {
            default: GraphicalColor.Gray,
        },
        multiColor: GraphicalColor._multi,
    };

    /*
        Values are stored in json in DiagramEdgeDto.VisualData.thickness
        Values must be matched by diagram-designer.global.scss#jtk-connector.link-edge.thickness-*
    */
    public static readonly edgeThickness = {
        values: [1, 2, 4, 6],
        default: 2,
    };

    public static getEdgeDefaultLineStyle(
        linkType: DiagramLinkType,
        isFFK?: boolean,
        isMandatoryFK?: boolean
    ) {
        switch (linkType) {
            case DiagramLinkType.IsLinkedTo:
                return LineStyle.dashed_2_2;
            case DiagramLinkType.FK:
            case DiagramLinkType.FKInversed:
                return isFFK || isMandatoryFK
                    ? LineStyle.none
                    : LineStyle.dashed_3_3;
            default:
                return LineStyle.none;
        }
    }
    public static getEdgeDefaultArrowType(linkType: DiagramLinkType) {
        switch (linkType) {
            case DiagramLinkType.FK:
            case DiagramLinkType.FKInversed:
                return ArrowType.line6x6;
            default:
                switch (DiagramConstants.getLinkKind(linkType)) {
                    case DiagramLinkKind.noArrowDirect:
                    case DiagramLinkKind.noArrowInverted:
                        return ArrowType.none;
                    default:
                        return DiagramConstants.arrowType.default;
                }
        }
    }

    public static readonly arrowType = {
        default: ArrowType.plain45fb,
    };

    public static readonly edgeTooltip = {
        offsetY: 30,
        withNodeNames: false,
    };

    /** min zoom level to get entity cards pointer events enabled */
    public static readonly entityCardNoPointerEventsZoomLevel = 0.35;

    public static readonly nodeFontSize = {
        noteAndFrame: {
            default: 14,
            min: 10,
            max: 30,
        },
    };

    public static readonly entityNanoSize: ISizeRO = {
        width: 10,
        height: 10,
    };
    private static readonly entityDefaultSize: ISizeRO = {
        width: 340,
        height: 300,
    };
    private static readonly entityDefaultMinSize: ISizeRO = {
        width: 150,
        height: 60,
    };
    private static readonly noteDefaultSize: ISizeRO = {
        width: 120,
        height: 120,
    };
    private static readonly entityMinSize: ISizeRO = {
        width: 60,
        height: 60,
    };
    private static readonly entityMediumSize: ISizeRO = {
        width: 300,
        height: 60,
    };
    private static readonly entityMediumMaxSize: ISizeRO = {
        height: 60,
        width: null,
    };
    private static readonly noteMinSize: ISizeRO = {
        width: 100,
        height: 100,
    };
    private static readonly frameMinSize: ISizeRO = {
        width: 100,
        height: 100,
    };

    public static readonly linkExplorerMaxSize: ISizeRO = {
        width: 350,
        height: 400,
    };

    private static readonly extraDataHeight = 36;

    public static readonly frameCaption = {
        default: {
            type: 'outside',
            alignH: 'left',
            alignV: 'top',
        } as Readonly<ICaptionPosition>,
    };

    public static getLinkKind(linkType: DiagramLinkType): DiagramLinkKind {
        switch (linkType) {
            case DiagramLinkType.IsSynonymOf:
                return DiagramLinkKind.noArrowDirect;

            case DiagramLinkType.Generalizes:
                return DiagramLinkKind.direct;
            case DiagramLinkType.Specializes:
                return DiagramLinkKind.inverted;

            case DiagramLinkType.IsComputedBy:
                return DiagramLinkKind.inverted;
            case DiagramLinkType.IsUsedForComputationOf:
                return DiagramLinkKind.direct;

            case DiagramLinkType.DependsOn:
                return DiagramLinkKind.inverted;
            case DiagramLinkType.IsNeededBy:
                return DiagramLinkKind.direct;

            case DiagramLinkType.IsLinkedTo:
                return DiagramLinkKind.noArrowDirect;

            case DiagramLinkType.ListsValuesFor:
                return DiagramLinkKind.noArrowInverted;
            case DiagramLinkType.HasForReferenceData:
                return DiagramLinkKind.noArrowDirect;

            case DiagramLinkType.IsDeclinedAccordingToDimension:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.Declines:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.Precedes:
                return DiagramLinkKind.direct;
            case DiagramLinkType.Follows:
                return DiagramLinkKind.inverted;

            case DiagramLinkType.HasForUniverse:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.IsUniverseOf:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.HasForDomain:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.IsDomainOf:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.HasForSource:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.IsSourceOf:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.IsPartOfDimension:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.Regroups:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.HasForRecordingSystem:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.IsRecordingSystemFor:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.IsUsageSourceFor:
                return DiagramLinkKind.direct;
            case DiagramLinkType.HasForUsageSource:
                return DiagramLinkKind.inverted;

            case DiagramLinkType.IsUsageDestinationFor:
                return DiagramLinkKind.inverted;
            case DiagramLinkType.HasForUsageDestination:
                return DiagramLinkKind.direct;

            case DiagramLinkType.Calls:
                return DiagramLinkKind.inverted;
            case DiagramLinkType.IsCalledBy:
                return DiagramLinkKind.direct;

            case DiagramLinkType.Transcodes:
                return DiagramLinkKind.noArrowDirect;

            case DiagramLinkType.IsImplementedBy:
                return DiagramLinkKind.noArrowDirect;
            case DiagramLinkType.Implements:
                return DiagramLinkKind.noArrowInverted;

            case DiagramLinkType.Uses:
                return DiagramLinkKind.inverted;
            case DiagramLinkType.IsUsedBy:
                return DiagramLinkKind.direct;

            case DiagramLinkType.HasInput:
                return DiagramLinkKind.inverted;
            case DiagramLinkType.IsInputOf:
                return DiagramLinkKind.direct;

            case DiagramLinkType.HasOutput:
                return DiagramLinkKind.direct;
            case DiagramLinkType.IsOutputOf:
                return DiagramLinkKind.inverted;

            //#region special cases, for legacy compatibility
            case DiagramLinkType.FK:
            case DiagramLinkType.FKInversed:
                return;
            //#endregion - special cases

            default:
                CoreUtil.warn(`not implemented: ${DiagramLinkType[linkType]}`);
                return;
        }
    }
}
