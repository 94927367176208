<dxy-field-hyperlink
    [(ngModel)]="model"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [readonly]="readonly"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    [editOnly]="isBulk"
    [nameLabel]="bulkNameLabel"
    [inputsLabelClass]="bulkInputsLabelClass"
    [class.mini]="mini"
></dxy-field-hyperlink>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>
