import { DomUtil } from '@datagalaxy/core-util';
import {
    CardinalUtil,
    IRect2Sides,
    IXYRect,
    IXYRectRO,
    MovePhase,
    Rect,
    ResizeHandle,
} from '@datagalaxy/core-2d-util';
import { select } from 'd3-selection';
import { SD3D, TD3Subject } from '../../../D3Helper';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { D3DragEvent, drag as d3drag } from 'd3-drag';
import { ManagedItem } from '../../node/managed-item';
import { SelectionTool } from '../selection';
import { Subject } from 'rxjs';
import {
    IOnDemandResizeOptions,
    IResizeEvent,
    ResizeToolOptions,
} from './resize.types';
import { ResizeFrame } from './resize-frame';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { GraphManager } from '../../graph-manager';
import { GraphNodeEvent } from '../../graph-manager.types';
import { LayerManager } from '../../layer';
import { GridManager } from '../../grid';

/** ## Role
 * Sub-component managing draggable handles for to resize an item
 * ## Features
 * - mouse drag
 * - min/max
 *    - on click
 *    - by drag
 *    - on demand
 * - activation/deactivation
 *    - on click
 *    - on demand
 * - resize handles
 *    - 4 or 8 handles: corners only, or corners and sides
 *    - customizable size
 *    - custom css class & style
 *    - transparent margin to ease grabbing of small handles
 *    - css transitions support
 *    - zoom-independent mode
 * */
export class ResizeTool<NodeData = unknown> extends BaseGraphicalManager {
    public get resized$() {
        return this.resized.asObservable();
    }

    private container: HTMLDivElement;
    private d3Frames: SD3D<ResizeFrame<NodeData>>;
    private resizingClass: string[];
    private dragBehaviour = d3drag<HTMLElement, ResizeHandle, TD3Subject>();
    private readonly resized = new Subject<IResizeEvent<NodeData>>();

    private get snapToGrid() {
        return this.grid.isSnapToGridActive;
    }

    constructor(
        private layerManager: LayerManager,
        private zoomAdapter: ZoomedViewAdapter,
        private selectionManager: SelectionTool<NodeData>,
        private grid: GridManager,
        graph: GraphManager<NodeData>,
        private options?: ResizeToolOptions
    ) {
        super();
        this.initInternal(options);
        this.resizingClass = DomUtil.addClass(
            'rm-resizing',
            this.options?.resizingItemClass
        ).split(' ');
        const container = (this.container = DomUtil.createElement(
            'div',
            'rm-container'
        ));
        container.style.position = 'relative';

        this.layerManager.addToTools(container);

        const self = this;
        this.dragBehaviour
            .subject((_, d) => {
                const point = d.getSidePoint();
                return this.zoomAdapter.fixedToZoomed.xy(point.x, point.y);
            })
            .on('start', function (_: ResizeDragEvent, d: ResizeHandle) {
                self.onResizeStart(this, d);
            })
            .on('drag', function (event: ResizeDragEvent, d: ResizeHandle) {
                self.onResize(this, d, event);
            })
            .on('end', function (_: ResizeDragEvent, d: ResizeHandle) {
                self.onResizeEnd(this, d);
            });

        super.subscribe(selectionManager.nodeSelection$, (selection) =>
            this.onSelectionChanged(selection)
        );
        super.subscribe(graph.nodesEvents$, (event) =>
            this.onGraphNodesEvent(event)
        );
    }

    public dispose() {
        this.container?.remove();
        this.container = this.resizingClass = undefined;
    }

    public drawFrames() {
        this.d3Frames?.data().forEach((frame) => {
            frame.update();
            frame.draw();
        });
    }

    public resizeRect(item: ManagedItem) {
        const r = item.rect;
        const opt: IOnDemandResizeOptions = this.getResizeOptions(item);
        const rectSides = item.resizeCardinals?.map((cardinal) =>
            CardinalUtil.getRectSides(cardinal)
        );

        rectSides?.forEach((rs) => this.resizeRectSides(r, rs, opt));
    }

    private resizeRectSides(
        r: IXYRect,
        rectSides: IRect2Sides,
        opt?: IOnDemandResizeOptions
    ) {
        const p = this.options;
        const cellSize = this.grid.cellSize;
        if (this.snapToGrid && cellSize && !this.isSmallerThanGridCell(r)) {
            Rect.roundSides(r, rectSides, cellSize, r);
        }
        const min = opt?.min ?? p?.min;
        const max = opt?.max ?? p?.max;
        if (min || max) {
            Rect.clampSides(r, rectSides, min, max, r);
        }
    }

    private isSmallerThanGridCell(rect: IXYRectRO) {
        const cellSize = this.grid.cellSize;
        return (
            cellSize &&
            (rect.width < cellSize.width || rect.height < cellSize.height)
        );
    }

    private onResizeStart(el: HTMLElement, handle: ResizeHandle) {
        const frame = select(el.parentElement).datum() as ResizeFrame<NodeData>;
        handle.addClass('moving');
        frame.target.el.classList.add(...this.resizingClass);
        this.resized.next({ item: frame.target, phase: MovePhase.start });
    }

    private onResize(
        el: HTMLElement,
        handle: ResizeHandle,
        event: ResizeDragEvent
    ) {
        const frameSelection = select(el.parentElement);
        const frame = frameSelection.datum() as ResizeFrame<NodeData>;
        const adapter = this.zoomAdapter.zoomedToFixed;
        const point = adapter.xy(event.x, event.y);

        frame.target.rect.setSides(handle.rectSides, point);
        this.resizeRectSides(
            frame.target.rect,
            handle.rectSides,
            this.getResizeOptions(frame.target)
        );

        frame.update();

        this.resized.next({ item: frame.target, phase: MovePhase.move });
    }

    private onResizeEnd(el: HTMLElement, handle: ResizeHandle) {
        const frame = select(el.parentElement).datum() as ResizeFrame<NodeData>;
        handle.removeClass('moving');
        frame.target.el.classList.remove(...this.resizingClass);
        this.resized.next({ item: frame.target, phase: MovePhase.end });
    }

    protected getResizeOptions(
        d: ManagedItem,
        res: IOnDemandResizeOptions = {}
    ) {
        res.min = d.minSize;
        res.max = d.maxSize;
        return res;
    }

    private onGraphNodesEvent(event: GraphNodeEvent) {
        if (event.updated?.length) {
            const items = event.updated;
            const selectedNodes = this.selectionManager.nodeSelection;
            const updatedItems = selectedNodes.filter((it) =>
                items.includes(it)
            );

            if (!updatedItems?.length) {
                return;
            }

            this.onSelectionChanged(selectedNodes);
        }
    }

    private onSelectionChanged(items: ManagedItem<NodeData>[]) {
        if (this.options?.disabled) {
            return;
        }
        const resizableItems = items.filter((it) => !it.unResizable);
        const frames = resizableItems.map((it) => {
            const cardinals = it.resizeCardinals;
            return new ResizeFrame(it, { ...this.options?.handles, cardinals });
        });

        this.d3Frames?.remove();
        const containers = (this.d3Frames = select(this.container)
            .selectAll('div')
            .data(frames)
            .enter()
            .append((frame) => frame.el));

        const newHandles = containers
            .selectAll('div')
            .data((frame) => frame.handles)
            .enter()
            .append((handle) => handle.element);

        newHandles.call(this.dragBehaviour);
    }
}

type ResizeDragEvent = D3DragEvent<HTMLDivElement, ResizeHandle, TD3Subject>;
