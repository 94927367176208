import { GridTreeBehavior } from './grid-tree.types';
import { TreeDataSourceConfig } from '../grid-config/grid-data-source-config.types';
import { Row } from '@tanstack/table-core';

export class Tree<TRow> implements GridTreeBehavior<TRow> {
    public readonly isExpanded: ((row: Row<TRow>) => boolean) | undefined;

    constructor(private dataSource: TreeDataSourceConfig<TRow>) {
        const isExpanded = dataSource.isExpanded;

        this.isExpanded = isExpanded
            ? (row: Row<TRow>) => isExpanded(row.original)
            : undefined;
    }

    public get expandAll() {
        return this.dataSource.expandAll ?? false;
    }

    canExpand(row: TRow): boolean {
        return this.dataSource.getChildren(row).length > 0;
    }

    getChildren(row: TRow): TRow[] {
        return this.dataSource.getChildren(row);
    }

    async loadChildren(row: TRow): Promise<TRow[]> {
        return this.getChildren(row);
    }

    isRowLoading(): boolean {
        return false;
    }

    setExpanded(row: TRow, expanded: boolean): void {
        this.dataSource.setExpanded?.(row, expanded);
    }
}
