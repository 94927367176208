import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DxyAttributeBaseInput } from '../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import { DxyFieldBooleanComponent } from '@datagalaxy/core-ui/fields';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyUnitaryFieldActionsComponent } from '../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-boolean-input',
    templateUrl: './dxy-attribute-boolean-input.component.html',
    standalone: true,
    imports: [
        DxyFieldBooleanComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
    ],
})
export class DxyAttributeBooleanInputComponent extends DxyAttributeBaseInput<boolean> {
    @ViewChild('field') field: DxyFieldBooleanComponent;

    constructor(
        elementref: ElementRef<HTMLElement>,
        translate: TranslateService
    ) {
        super(elementref, translate);
    }

    //#region IAttributeBaseInputOverride
    public isEmpty() {
        return false;
    }
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    //#region

    @HostListener('keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent) {
        if (this.readonly || !this.isEditEnabled) {
            return;
        }
        if (KeyboardUtil.preventEnterKey(event, true)) {
            this.onEnterKeyDown().then();
        }
    }
}
