<!-- left actions -->
<div *ngIf="hasLeftActions && isActive" class="attribute-actions left">
    <div
        *ngIf="hasToolbarAction"
        (pointerdown)="onClickToolbarAction($event)"
        class="attribute-action"
        [ngClass]="toolbarActionGlyphClass"
    ></div>
</div>
<!-- /left actions -->

<!-- right actions -->
<div class="attribute-actions right">
    <div
        *ngIf="isRestoreVisible"
        (pointerdown)="onClickRestore($event)"
        class="attribute-action glyph-copy-compared-field"
        dxyDataTestId="attribute-action-restore"
    ></div>
    <div
        *ngIf="isUndoVisible"
        (pointerdown)="onClickUndo($event)"
        class="attribute-action glyph-undo"
        dxyDataTestId="attribute-action-undo"
    ></div>
    <div
        *ngIf="isValidateVisible"
        (pointerdown)="onClickValidate($event)"
        class="attribute-action glyph-check"
        dxyDataTestId="attribute-action-validate"
    ></div>
</div>
<!-- /right actions -->

<!-- status -->
<div *ngIf="hasUnitaryValidation" class="attribute-status-placeholder">
    <div
        *ngIf="isValidated"
        class="attribute-status glyph-check"
        @fadeOut
    ></div>
    <div *ngIf="isValidating" class="attribute-action glyph-spinner"></div>
    <div
        *ngIf="!isActive && isErrorValidating"
        class="attribute-status glyph-cancelsearch"
    ></div>
</div>
<div class="attribute-status-placeholder">
    <div
        *ngIf="recentlyModifiedExternally"
        class="attribute-action externally-modified glyph-show"
        [matTooltip]="'UI.Global.ttModifiedExternally' | translate"
    ></div>
</div>
<!-- /status -->
