<!-- Selector section of comparator-->
<div class="version-selection-wrapper">
    <div>
        <mat-label translate>UI.Versioning.Comparator.sourceVersion</mat-label>
        <div>{{ currentVersionName }}</div>
    </div>
    <div class="comparison-label" translate>
        UI.Versioning.Comparator.comparedTo
    </div>
    <div>
        <mat-label translate>UI.Versioning.Comparator.targetVersion</mat-label>
        <dxy-versioning-selector
            [projectIdr]="versionCompareSpace"
            [noNavigator]="true"
            [isVersionSelectable]="true"
            [includeArchived]="true"
            (onProjectVersionSelected)="onProjectVersionSelected($event)"
            (onProjectVersionsLoading)="onProjectVersionsLoading($event)"
            logId="versioning-comparator"
        ></dxy-versioning-selector>
    </div>
</div>

<ng-container *ngIf="isVersionSelected">
    <!-- Results section of comparator-->
    <div
        *ngIf="hasResultsModule"
        class="dg_versioning-comparator-content-wrapper"
    >
        <dxy-tab-view [tabsData]="tabsData">
            <div class="versioning-comparator-section-container">
                <dxy-versioning-comparator-section
                    *ngFor="
                        let data of displayedModuleData;
                        trackBy: getModuleDataId
                    "
                    [moduleName]="data.ModuleName"
                    [comparisonItems]="data.Items"
                    [currentOperation]="currentActiveOperation"
                    [selectedVersion]="selectedVersion"
                    class="vcontainerDiv flex-grow"
                ></dxy-versioning-comparator-section>
            </div>
        </dxy-tab-view>
    </div>
    <!-- No difference between both versions -->
    <div
        *ngIf="hasNoResultModule"
        class="dg_versioning-comparator-empty-results"
    >
        <span translate>UI.Versioning.Comparator.emptyDifference</span>
    </div>
</ng-container>

<div *ngIf="isLoading" class="dg5-spinner-container">
    <span class="dg5-spinner"></span>
</div>
