/** for tags */
export enum CustomColors {
    White = 'white',

    BlueGreen = '#00a99d',
    Green = '#8cc63f',
    Orange = '#f7931e',
    Pink = '#d4145a',
    LightBlue = '#29abe2',
    Blue = '#0971b7',
    DarkBlue = '#1e2437',
    DarkGrey = '#808595',
    LightGrey = '#b7bdcb',

    BrownD = '#3E2723',
    BrownM = '#753627',
    BrownL = '#D38C6C',

    //OrangeD="#E02F00",
    OrangeD = '#CB4800',
    OrangeM = '#E57400',
    //OrangeL="#FFA931",
    OrangeL = '#F1A600',

    RedD = '#7D0000',
    RedM = '#D30027',
    RedL = '#FC6565',

    PinkD = '#841052',
    PinkM = '#E01882',
    PinkL = '#F074C0',

    PurpleD = '#4A148C',
    PurpleM = '#761FDE',
    PurpleL = '#B98BEF',

    BlueD = '#1A237E',
    BlueM = '#404ED7',
    BlueL = '#3FA9F5',

    GreenD = '#006837',
    GreenM = '#009245',
    GreenL = '#8CC63F',

    BlueGrayD = '#263238',
    BlueGrayM = '#577F92',
    BlueGrayL = '#6EB3D4',

    GrayD = '#212121',
    GrayM = '#616161',
    GrayL = '#90A4AE',
}
