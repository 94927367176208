import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ChangeDetectorRef, Component } from '@angular/core';
import {
    IUiImageInputParams,
    UiImageInputMode,
} from '@datagalaxy/core-ui/image-input';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IMultiSelectData } from '@datagalaxy/core-ui';
import { CropMode } from '@datagalaxy/core-doka-util';
import { MultiSelectAdapter } from '../../../shared/shared-ui/UiMultiSelect.util';
import { TechnologyUiService } from '../../services/technology-ui.service';
import { TechnologyService } from '../../services/technology.service';
import { INgModelOptions } from '@datagalaxy/ui/fields';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { DxyModalFooterComponent } from '../../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyImageInputComponent } from '@datagalaxy/core-ui/image-input';
import { DxyFieldMultiSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-client-admin-technology-create-modal',
    templateUrl: './client-admin-technology-create-modal.component.html',
    styleUrls: ['./client-admin-technology-create-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyFieldTextComponent,
        DxyFieldMultiSelectComponent,
        DxyImageInputComponent,
        DxyModalFooterComponent,
    ],
})
export class ClientAdminTechnologyCreateModalComponent extends DxyBaseModalComponent<
    void,
    TechnologyDto
> {
    public canCreate = false;
    public isLoading = false;
    public readonly featureCode = 'ADMIN_CS_TECHNOLOGY,C';
    public ngModelOptions: INgModelOptions = { updateOn: 'blur' };

    public technologyName = '';
    public technologyCode = '';
    public technologyDescription = '';
    public imageFile: File;
    public imageParams: IUiImageInputParams = {
        mode: UiImageInputMode.modalEdit,
        getImageUrl: () => '',
        editSize: 200,
        crop: CropMode.square,
        placeHolderClass: 'technology-icon-placeholder',
        onDone: (file) => {
            this.imageFile = file;
            this.changeDetector.detectChanges();
        },
    };
    public technologyModules: DgModule[] = [];

    public modulesMultiSelect: IMultiSelectData<DgModule> = {
        items: [DgModule.Catalog, DgModule.Usage, DgModule.Processing],
        adapter: MultiSelectAdapter.dgModule,
    };

    public displayNameErrorKey: string;
    public codeErrorKey: string;
    public moduleErrorKey: string;
    private isCodeManuallyUpdated = false;
    private previousModules = [];

    constructor(
        dialogRef: MatDialogRef<ClientAdminTechnologyCreateModalComponent>,
        private changeDetector: ChangeDetectorRef,
        private technologyUiService: TechnologyUiService,
        private technologyService: TechnologyService
    ) {
        super(dialogRef);
    }

    public async onNameChange() {
        await this.preCreateTechno();
    }

    public async onModuleChange(selected: any) {
        if (CollectionsHelper.contentEquals(selected, this.previousModules)) {
            return;
        }
        this.previousModules = selected;
        await this.preCreateTechno();
    }

    public async onCodeChange() {
        this.isCodeManuallyUpdated = true;
        await this.preCreateTechno();
    }

    public async onCloseSubmit() {
        const createdTechnology =
            await this.technologyUiService.createTechnology(
                this.technologyName,
                this.technologyCode,
                this.technologyModules,
                this.technologyDescription
            );

        this.result = createdTechnology;

        if (this.imageFile) {
            await this.technologyService.setTechnologyIcon(
                createdTechnology.TechnologyCode,
                this.imageFile
            );
        }
        super.onCloseSubmit();
    }

    private async preCreateTechno() {
        const technologyCode = this.isCodeManuallyUpdated
            ? this.technologyCode
            : null;
        this.resetErrors();
        const result = await this.technologyUiService.preCreateTechnology(
            this.technologyName,
            this.technologyModules,
            technologyCode
        );

        if (result.IsMissingDisplayName) {
            this.displayNameErrorKey =
                'UI.TechnologyAdmin.creationErrors.missingName';
        }
        if (result.ExistingTechnologyWithDisplayName) {
            this.displayNameErrorKey =
                'UI.TechnologyAdmin.creationErrors.duplicateName';
        }
        if (result.IsMissingModule) {
            this.moduleErrorKey =
                'UI.TechnologyAdmin.creationErrors.missingModule';
        }
        if (result.ExistingTechnologyWithCode) {
            this.codeErrorKey =
                'UI.TechnologyAdmin.creationErrors.duplicateCode';
        }
        const updatedCode = result?.UpdatedTechnologyCode;
        if (updatedCode) {
            this.technologyCode = updatedCode;
        }

        this.updateCanCreate();
    }

    private resetErrors() {
        this.displayNameErrorKey = '';
        this.codeErrorKey = '';
        this.moduleErrorKey = '';
    }

    private updateCanCreate() {
        this.canCreate =
            !!this.technologyName &&
            !!this.technologyCode &&
            !!this.technologyModules?.length &&
            !this.displayNameErrorKey &&
            !this.codeErrorKey;
    }
}
