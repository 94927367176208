<div
    *ngIf="!hideLookingFor"
    [attr.data-tracker-id]="dataTrackerId"
    class="looking-for"
>
    {{ placeholderText }}
</div>
<app-attribute-filter-carousel
    *ngIf="showCarousel"
    [displayMode]="horizontal ? 'default' : 'quick-filters'"
    [filterItems]="filters"
    [readonly]="true"
    (select)="onSelectFilterItem($event)"
></app-attribute-filter-carousel>
<dxy-spinner *ngIf="isLoading"></dxy-spinner>
