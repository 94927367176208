import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FunnelModalComponent } from './funnel-modal/funnel-modal.component';

@Injectable({ providedIn: 'root' })
export class FunnelDialogService {
    private dialogRef?: MatDialogRef<unknown>;
    constructor(private dialog: MatDialog) {}

    public open() {
        this.dialogRef = this.dialog.open<FunnelModalComponent>(
            FunnelModalComponent,
            {
                panelClass: 'dg5-base-modal',
                width: '680px',
                disableClose: true,
            }
        );
    }
    public close() {
        this.dialogRef?.close();
    }
}
