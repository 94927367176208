import { deserialize, inheritSerialization } from 'cerialize';
import { BaseServiceResult } from '@datagalaxy/data-access';

export enum ImportStatus {
    unknown = 0,
    running,
    success,
    error,
}

@inheritSerialization(BaseServiceResult)
export class Scheduling {
    @deserialize public suspend!: boolean;
    @deserialize public cron!: string;
    @deserialize public creationTime!: Date;
    @deserialize public timezone?: string;
}

export class Connection {
    @deserialize public credentials: string;
    @deserialize public id: string;
    @deserialize public name: string;
    @deserialize public creationTime: string;
    @deserialize public creationUserId: string;
    @deserialize public entityId: string;
    @deserialize public tokenUid: string;
    @deserialize public versionId: string;
    @deserialize public targetModule: string;
    @deserialize public pluginName: string;
    @deserialize public selectedObjectNames: string[];
    /** ImportStatus enum key stored as string in database
     *
     * To access enum value, use
     * ````
     * ImportStatus[connection.lastImportStatus]
     * ```` */
    @deserialize public lastImportStatus?: string;
    @deserialize public lastImportDate?: string;
    @deserialize public scheduling?: Scheduling;
    @deserialize public suspend?: boolean;

    constructor(
        credentials: string,
        id: string,
        name: string,
        creationTime: string,
        creationUserId: string,
        entityId: string,
        tokenUid: string,
        versionId: string,
        targetModule: string,
        pluginName: string,
        selectedObjectNames?: string[]
    ) {
        this.credentials = credentials;
        this.id = id;
        this.name = name;
        this.creationTime = creationTime;
        this.creationUserId = creationUserId;
        this.entityId = entityId;
        this.tokenUid = tokenUid;
        this.versionId = versionId;
        this.targetModule = targetModule;
        this.pluginName = pluginName;
        this.selectedObjectNames = selectedObjectNames ?? [];
    }
}

export interface IOnlineImportEntity {
    name: string;
}
