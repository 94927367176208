<dxy-grid [config]="gridConfig" [columns]="cols" [items]="rows"></dxy-grid>
<button
    dxyIconButton
    color="secondary"
    (click)="onClickCopyErrors()"
    [matTooltip]="
        'Import.GenericImportWizard.CsvPreImport.Check.copyTooltip' | translate
    "
>
    <i class="glyph-file-copy"></i>
</button>
