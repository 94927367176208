<dxy-field-text
    #field
    [ngModel]="value$ | async"
    (ngModelChange)="onValueChange($event)"
    [triggerSubmitOnEnter]="true"
    [readonly]="readonly"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [maxLength]="textMaxLength"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    [class.mini]="mini"
>
    <ng-container
        *ngIf="translationResult$ | async as translationResult"
        label-actions
    >
        <dxy-attribute-translation-button
            *ngIf="translationResult?.aiOrigin && translationResult.translation"
            [translation]="translationResult.translation"
            [entityIdentifier]="entity"
            [attributeName]="labelText"
            [metalModelValue]="sourceValue"
        ></dxy-attribute-translation-button>
    </ng-container>
</dxy-field-text>

<button
    *ngIf="edited"
    type="button"
    dxyIconButton
    [disabled]="loading$ | async"
>
    <i class="glyph-check"></i>
</button>
