<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Connector.ImportHistory.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <dxy-grid [config]="gridConfig" [columns]="cols" [items]="rows"></dxy-grid>
</div>
