export class CsvFieldInfo {
    /** whenever this field is filtered out in GUI */
    public isFilteredOut: boolean;

    /** whenever this field has been matched with an attribute*/
    public isMatched: boolean;

    public dragName() {
        return 'csvField:' + this.name;
    }

    constructor(public name: string) {}
}
