<a
    *ngIf="iconButtonVisible"
    [uiSref]="routeStateName"
    [uiParams]="navParams"
    class="nav-link-button"
>
    <div class="icon-wrapper">
        <i class="glyph-campaign"></i>
    </div>
    <div class="text-wrapper" dxyEllipsisTooltip>{{ displayName }}</div>
</a>

<dxy-show-more-button
    *ngIf="showMoreButtonVisible"
    [uiSref]="routeStateName"
    [uiParams]="navParams"
    [labelKey]="labelKey"
    [isFlat]="true"
>
</dxy-show-more-button>
