import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppDataService } from '../../services/app-data.service';
import { SecurityService } from '../../services/security.service';
import { ApiServiceError, ApiServiceErrorType } from '@datagalaxy/data-access';
import {
    LoadSpacesResult,
    NavProject,
    WorkSpaceApiService,
} from '@datagalaxy/webclient/workspace/data-access';

@Injectable({ providedIn: 'root' })
export class NavigationApiService extends BaseService {
    private lastResult: LoadSpacesResult;
    private getNavSpacesPromise: Promise<LoadSpacesResult>;

    constructor(
        private workspaceApiService: WorkSpaceApiService,
        private appDataService: AppDataService,
        private securityService: SecurityService
    ) {
        super();
        this.lastResult = null;
    }

    public init() {
        this.lastResult = null;
        this.getNavSpacesPromise = null;
    }

    public async getNavSpaceById(spaceId: string, noCache = false) {
        const result = await this.getNavSpaceResult(noCache);
        return result?.findById(spaceId);
    }

    /** from cache */
    public async getSingleWorkspace(): Promise<NavProject> {
        // Avoid call to loadSpaces on single Workspace.
        if (this.lastResult?.Projects?.length == 1) {
            return this.lastResult.Projects[0];
        }
        const result = await this.getNavSpaces();
        return result?.Projects[0];
    }

    /** Returns the first non null NavSpace in:
     * - getSingleProject
     * - navSpace matching loginData.DefaultSpaceUid
     *
     * (The following are included only includeNonUserDefault is true)
     * - first project having a defined IsDefaultSpace property
     * - first orga having a defined IsDefaultSpace property
     * - first project
     * - first orga
     */
    public async getUserDefaultNavSpace(
        includeNonUserDefault = false,
        noCache = false
    ) {
        const options = {
            preferredSpaceId:
                this.appDataService.getLoginDataDefaultSpaceReferenceId(),
            includeDefault: includeNonUserDefault,
            includeFirstAvailable: includeNonUserDefault,
            includeSingleProject:
                this.securityService.isSingleWorkspaceClient(),
        };
        const navSpace = await this.getOneNavSpaceResult(noCache, options);
        this.log(
            'getUserDefaultProjectOrSpace',
            includeNonUserDefault,
            options,
            navSpace
        );
        return navSpace;
    }

    /** Returns the first non null NavSpace in:
     * - getSingleProject if includeSingleProject is true
     * - navSpace matching the given preferredSpaceId
     *
     * (the following are included only if includeDefault is true)
     * - first project having a defined IsDefaultSpace property
     * - first orga having a defined IsDefaultSpace property
     *
     * (the following are included only if includeFirstAvailable is true)
     * - first project
     * - first orga
     *
     * - getSingleProject in last case
     */
    private async getOneNavSpaceResult(
        noCache: boolean,
        opt?: {
            preferredSpaceId?: string;
            includeDefault?: boolean;
            includeFirstAvailable?: boolean;
            includeSingleProject?: boolean;
        }
    ) {
        const result = await this.getNavSpaceResult(noCache);

        return (
            (opt?.includeSingleProject
                ? result.getSingleProject()
                : undefined) ??
            (opt?.preferredSpaceId
                ? result.findById(opt.preferredSpaceId)
                : undefined) ??
            (opt?.includeDefault
                ? result.getDefaultProject() ??
                  result.getDefaultSpace() ??
                  (opt?.includeFirstAvailable
                      ? result.Projects?.[0] ?? result.Organizations?.[0]
                      : undefined)
                : undefined) ??
            result.getSingleProject()
        );
    }

    /** debounced */
    public async getNavSpaces(): Promise<LoadSpacesResult> {
        // Avoid simultaneate double call (from nav-tree and nav-list)
        let result: LoadSpacesResult;
        const promise = this.getNavSpacesPromise;
        if (promise) {
            result = await promise;
            this.log('getNavSpaces-debounced', result);
        } else {
            try {
                result = await (this.getNavSpacesPromise =
                    this.doGetNavSpaces());
            } finally {
                this.getNavSpacesPromise = null;
            }
            this.log('getNavSpaces', result);
        }
        return result;
    }

    /** Emitted on getNavSpaces, argument is the first navspace received, if any.
     * Used by monitoring service: segmentSpaceUpdate. */
    public get onGetNavSpaces$() {
        return this.onGetNavSpaces.asObservable();
    }
    private readonly onGetNavSpaces = new Subject<NavProject>();

    private async doGetNavSpaces() {
        try {
            const result = (this.lastResult =
                await this.workspaceApiService.loadSpaces());
            const firstNavProject = result.Projects?.[0];
            if (firstNavProject) {
                this.onGetNavSpaces.next(firstNavProject);
            }
            return result;
        } catch (error) {
            if (
                error instanceof ApiServiceError &&
                error.type === ApiServiceErrorType.UnmodifiedContent
            ) {
                this.log('doGetNavSpaces-304');
                return this.lastResult;
            }
            throw error;
        }
    }

    private async getNavSpaceResult(
        noCache = false
    ): Promise<LoadSpacesResult> {
        if (this.lastResult && !noCache) {
            return this.lastResult;
        }
        return await this.getNavSpaces();
    }
}
