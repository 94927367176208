import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    GridComponent,
    GridConfig,
    PersistedGridState,
    ServerSideSortEvent,
    TColDef,
} from '@datagalaxy/ui/grid';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { EntityGridConfig } from '../entity-grid-config';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityGridColumnService } from '../entity-grid-column/entity-grid-column.service';
import { GridUserSettingsDirective } from '../../../../user/grid-user-settings/grid-user-settings.directive';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { EntityGridStateService } from '../entity-grid-state.service';
import { BehaviorSubject } from 'rxjs';
import { ViewTypeService } from '../../../../services/viewType.service';

export interface EntityGridState {
    columns: TColDef<EntityItem>[];
    config: GridConfig<EntityItem>;
    persistedState: PersistedGridState;
}

@Component({
    standalone: true,
    selector: 'dxy-entity-grid',
    templateUrl: './entity-grid-component.component.html',
    styleUrls: ['./entity-grid-component.component.scss'],
    imports: [
        GridComponent,
        AsyncPipe,
        TranslateModule,
        MatLegacyTooltipModule,
        NgIf,
        SpinnerComponent,
        GridUserSettingsDirective,
        DxyIconButtonDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EntityGridColumnService],
})
export class EntityGridComponent extends DxyBaseComponent implements OnChanges {
    @Input() entities: EntityItem[] = [];
    @Input() config?: EntityGridConfig;
    @Input() activeIds?: string[];

    @Output() rowClick = new EventEmitter<EntityItem>();
    @Output() selectionChange = new EventEmitter<EntityItem[]>();
    @Output() attributeVisibilityChange = new EventEmitter<string[]>();
    @Output() serverSideSort = new EventEmitter<ServerSideSortEvent>();

    @ViewChild(GridComponent) public grid?: GridComponent<EntityItem>;

    protected defaultGridConfig: GridConfig<EntityItem> = {
        getItemId: (entity: EntityItem) => entity.ReferenceId,
    };

    protected gridState$ = new BehaviorSubject<EntityGridState | null>(null);

    constructor(
        private entityGridColumnService: EntityGridColumnService,
        private entityGridStateService: EntityGridStateService,
        private viewTypeService: ViewTypeService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'config', () => this.onConfigChange(), true);
    }

    public focusEntity(entity: EntityItem) {
        const parents = entity?.HddData?.Parents?.slice(0, -1);
        const parentIds = parents?.map((parent) => parent.ReferenceId) || [];

        const path = [...parentIds.reverse(), entity?.HddData?.ReferenceId];

        this.grid?.focusRow(path);
    }

    protected async onRowClick(entity: EntityItem) {
        this.rowClick.emit(entity);
    }

    protected async onGridStateChange(gridState: PersistedGridState) {
        const gridId = this.config?.persistedUserSettingsId;

        if (!gridId) {
            return;
        }

        const diff = await this.entityGridStateService.getAttributeKeysDiff(
            gridState,
            gridId
        );

        if (diff?.length) {
            const attributeKeys =
                this.entityGridStateService.getAttributeKeysFromGridState(
                    gridState
                );
            this.attributeVisibilityChange.emit(attributeKeys);
        }
    }

    protected onServerSideSortChange(event: ServerSideSortEvent) {
        if (event.colId === EntityGridColumnService.defaultColumnId) {
            event.colId = this.viewTypeService.getViewAttributeKey();
        }

        this.serverSideSort.emit(event);
    }

    private async onConfigChange() {
        const gridState = await this.getEntityGridState(this.config);
        this.gridState$.next(gridState);
    }

    private async getEntityGridState(
        config: EntityGridConfig
    ): Promise<EntityGridState> {
        this.startLoading();
        const persistedState = await this.entityGridStateService.getGridState(
            this.config?.persistedUserSettingsId
        );

        this.endLoading();

        return {
            columns: this.entityGridColumnService.buildColumns(config),
            config: this.getConfig(config),
            persistedState,
        };
    }

    private getConfig(config: EntityGridConfig): GridConfig<EntityItem> {
        const {
            hideColumnsHeader,
            dataSourceConfig,
            rowCursorOnHover,
            multiSelect,
            sorting,
            rowHeight,
        } = config || {};

        if (
            sorting?.activeSort?.columnId ===
            this.viewTypeService.getViewAttributeKey()
        ) {
            sorting.activeSort.columnId =
                EntityGridColumnService.defaultColumnId;
        }

        return {
            ...this.defaultGridConfig,
            hideHeader: hideColumnsHeader,
            rowHeight,
            dataSourceConfig,
            rowCursorOnHover,
            multiSelect,
            sorting,
        };
    }
}
