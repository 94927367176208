export enum LogLevel {
    NONE = 0,
    TRACE = 1,
    DEBUG = 2,
    INFO = 3,
    LOG = 4,
    WARN = 5,
    ERROR = 6,
    FATAL = 7,
}

export interface ILoggerService {
    print(level: LogLevel, message: string, ...additional: any[]): void;
    trace(message: string, ...additional: any[]): void;
    debug(message: string, ...additional: any[]): void;
    info(message: string, ...additional: any[]): void;
    log(message: string, ...additional: any[]): void;
    warn(message: string, ...additional: any[]): void;
    error(message: string, ...additional: any[]): void;
    fatal(message: string, ...additional: any[]): void;
}
