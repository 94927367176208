<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="Authentication.InactivityLogoutModal.title"
    ></h3>
</div>
<div mat-dialog-content>
    <p translate="Authentication.InactivityLogoutModal.message"></p>
</div>

<div class="modal-footer">
    <button dxyButton (click)="onCloseSubmit()">
        <span translate>Authentication.InactivityLogoutModal.login</span>
    </button>
</div>
