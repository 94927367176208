import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { EntityType } from '@datagalaxy/dg-object-model';
import { IWidgetFiltersParameter, WidgetDatasetType } from './widget';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';

export enum GlobalActivityWidgetEntry {
    EntityCreate,
    EntityUpdate,
    EntityDelete,
    EntityLinkCreate,
    EntityLoad,
    TaskCreate,
    CommentaryCreate,
    Search,
}
SerializableEnumeration(GlobalActivityWidgetEntry);

export enum StatisticsDataQueryType {
    Aggregation = 0,
    DataQuality = 1,
}

export enum StatisticsDataTargetType {
    ProjectDashboardProperties = 0,
    ProjectDashboardModels = 1,
    ProjectDashboardDataProcessing = 2,
    ProjectDashboardSoftwareElements = 3,
}

@inheritSerialization(BaseServiceParameter)
export class GetWidgetDatasetParameter
    extends BaseServiceParameter
    implements IWidgetFiltersParameter
{
    @serialize public DatasetType?: WidgetDatasetType;
    //#region filter values
    @serialize public SpaceId?: string;
    //inherited @serialize public VersionId: string
    @serialize public DgModule?: DataGalaxyModule;
    @serialize public EntityTypes?: EntityType[];
    @serialize public OwnerIds?: string[];
    @serialize public StewardIds?: string[];
    @serialize public TagIds?: string[];
    @serialize public EntityStatuses?: EntityLifecycleStatus[];
    @serialize public AttributePaths?: string[];
    @serialize public LicenseTypes?: LicenseLevel[];
    @serialize public NbDays?: number;
    @serialize public ExcludeWeekends?: boolean;
    @serialize public UserRoles?: string[];
    @serialize public UserServices?: string[];
    @serialize public UserIds?: string[];
    @serialize public Teams?: string[];

    //#endregion

    constructor(datasetType?: WidgetDatasetType) {
        super();
        if (datasetType) {
            this.DatasetType = datasetType;
        }
    }
}

@inheritSerialization(BaseServiceResult)
export class GetWidgetDatasetResult extends BaseServiceResult {
    @deserialize DataSet?: object;
    @deserialize DateMin?: string;
    @deserialize DateMax?: string;
    @deserialize AllKeys?: string[];
    @deserialize Value?: number;
}

@inheritSerialization(BaseServiceParameter)
export class GetStatisticsDataParameter extends BaseServiceParameter {
    @serialize public RootDataReferenceId?: string;
    @serialize public TargetType?: StatisticsDataTargetType;
    @serialize public QueryType?: StatisticsDataQueryType;
}

export class BaseStatisticsDataResultItem {
    @deserialize public DisplayName!: string;
}

@inheritSerialization(BaseServiceResult)
export class GetStatisticsDataResult extends BaseServiceResult {
    @deserializeAs(BaseStatisticsDataResultItem)
    public Items: Array<BaseStatisticsDataResultItem> = [];
}

@inheritSerialization(BaseStatisticsDataResultItem)
export class AggregationStatisticsDataResultItem extends BaseStatisticsDataResultItem {
    @deserialize public EntityType?: EntityType;
    @deserialize public Count?: number;
    @deserialize public TotalCount?: number;
}

@inheritSerialization(BaseStatisticsDataResultItem)
export class DataQualityStatisticsDataResultItem extends BaseStatisticsDataResultItem {
    @deserialize public DataFillPercentage!: number;
    @deserialize public RecommendedDataFillPercentage!: number;
    @deserialize public Count!: number;
    @deserialize public TotalCount!: number;
}

@inheritSerialization(BaseServiceResult)
export class GetAggregationStatisticsDataResult extends BaseServiceResult {
    @deserializeAs(AggregationStatisticsDataResultItem)
    public Items: Array<AggregationStatisticsDataResultItem> = [];
}

@inheritSerialization(BaseServiceResult)
export class GetDataQualityStatisticsDataResult extends BaseServiceResult {
    @deserializeAs(DataQualityStatisticsDataResultItem)
    public Items: Array<DataQualityStatisticsDataResultItem> = [];
}
