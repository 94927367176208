import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UiSpinnerService } from '@datagalaxy/core-ui';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { TeamService } from '../../../team/team.service';
import { INavLinkCellParams } from '../nav-link.types';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf, NgClass } from '@angular/common';

/**
 * ## Role
 * Link to open a team preview panel
 */
@Component({
    selector: 'app-team-nav-link',
    templateUrl: './team-nav-link.component.html',
    styleUrls: ['../nav-link.scss', './team-nav-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, EllipsisTooltipDirective],
})
export class TeamNavLinkComponent extends BaseCellComponent<
    any,
    string,
    INavLinkCellParams<string>
> {
    constructor(
        private teamService: TeamService,
        private uiSpinnerService: UiSpinnerService
    ) {
        super();
    }

    protected get glyphClass() {
        return this.teamService.getTeamGlyphClass(this.team, true);
    }
    protected get imgUrl() {
        return this.teamService.getTeamImageUrl(this.team);
    }
    protected get name() {
        return this.team.TeamName;
    }
    protected get team() {
        return this.teamService.getTeamFromCache(this.value.toString());
    }

    public async openTeamPreview() {
        if (this.params?.disableNavigation) {
            return;
        }
        if (!this.team?.HasTeamReadAccess) {
            return;
        }
        return await this.uiSpinnerService.executeWithSpinner(async () => {
            const teamDto = await this.teamService.getTeam(this.team.TeamUid);
            if (!teamDto) {
                return;
            }
            await this.teamService.openTeamPreviewPanel({
                teamData: teamDto,
            });
        });
    }
}
