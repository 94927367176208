import { FieldType } from '../../types/enums/field-type.enum';

export const lookerConfig = [
    { payloadField: 'type', payloadValue: 'MULTI' },
    { payloadField: 'multi-technology', payloadValue: 'looker' },
    {
        payloadField: 'api-url',
        formField: 'apiUrl',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'client-id',
        formField: 'clientId',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        tradKey: 'clientSecret',
        isPassword: true,
        formMandatory: true,
    },
];
