import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { UserNotificationChannelsStateService } from '../../../notification-channels/state/user-notification-channels-state.service';
import { Observable } from 'rxjs';
import { slackNotificationChannel } from '../../../notification-channels/slack-notification-channel';
import { ClientNotificationChannelIntegrationDto } from '../../../notification-channels/api/queries/GetClientNotificationChannelIntegrationsQuery';
import { withLoading } from '@datagalaxy/core-ui';
import { SlackIntegrationService } from '../../../client-admin/integration/slack/slack-integration.service';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * Button inside a notification to activate client Slack Intégration
 */
@Component({
    selector: 'app-slack-activate-integration-button',
    templateUrl: './slack-activate-integration-button.component.html',
    styleUrls: [
        './slack-activate-integration-button.component.scss',
        '../nav-link.scss',
    ],
    standalone: true,
    imports: [NgIf, TranslateModule, MatLegacyButtonModule, AsyncPipe],
})
export class SlackActivateIntegrationButtonComponent extends BaseCellComponent<
    unknown,
    unknown,
    unknown
> {
    protected integration$: Observable<ClientNotificationChannelIntegrationDto>;

    constructor(
        userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private slackIntegrationService: SlackIntegrationService
    ) {
        super();
        userNotificationChannelsStateService.initClientIntegration();
        this.integration$ =
            userNotificationChannelsStateService.selectClientIntegration(
                slackNotificationChannel.name
            );
    }

    @withLoading()
    protected async connect() {
        await this.slackIntegrationService.activate();
    }
}
