import * as Papa from 'papaparse';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
    IDropdownSection,
    IListOption,
    withLoading,
} from '@datagalaxy/core-ui';
import { CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { ImportContext, ImportTarget } from '../../../shared/ImportContext';
import { ModelType, ServerType } from '@datagalaxy/dg-object-model';
import {
    ImportModule,
    ImportModuleCatalog,
} from '../../../shared/ImportModule';
import { ParseErrorInfo } from '../../../shared/ParseErrorInfo';
import { CsvParseOptions } from '../../../shared/CsvParseOptions';
import { EntityService } from '../../../../shared/entity/services/entity.service';
import { EntitySecurityService } from '../../../../shared/entity/services/entity-security.service';
import { AppSpaceService } from '../../../../services/AppSpace.service';
import { IFileOptionConfig } from '../../../../shared/file-options/file-option-form.types';
import { EOperationType } from '../../../shared/EOperationType';
import { detectEncoding } from '../../../shared/encoding.utils';
import { CsvFieldInfo } from '../../../shared/CsvFieldInfo';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ModuleStore } from '../../../../module/module.store';
import { DgModuleDefinition } from '@datagalaxy/shared/dg-module/domain';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { GridComponent } from '@datagalaxy/ui/grid';
import { DxyFileOptionsFormComponent } from '../../../../shared/file-options/dxy-file-options-form/dxy-file-options-form.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { DxyTargetSelectionComponent } from '../../../../connector/dxy-target-selection/dxy-target-selection.component';
import { DxyDropdownSectionComponent } from '@datagalaxy/core-ui';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxySpaceVersionSelectorComponent } from '../../../../space-selector/dxy-space-version-selector/dxy-space-version-selector.component';
import { DxyCsvFileDepositCardComponent } from '../dxy-csv-file-deposit-card/dxy-csv-file-deposit-card.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-csv-import-settings',
    templateUrl: 'dxy-csv-import-settings.component.html',
    styleUrls: ['dxy-csv-import-settings.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        DxyCsvFileDepositCardComponent,
        TranslateModule,
        DxySpaceVersionSelectorComponent,
        MatLegacyButtonModule,
        MatLegacyTooltipModule,
        DxyDropdownSectionComponent,
        DxyTargetSelectionComponent,
        MatLegacyCheckboxModule,
        FormsModule,
        DxyFileOptionsFormComponent,
        GridComponent,
        AsyncPipe,
    ],
})
export class DxyCsvImportSettingsComponent
    extends DxyBaseComponent
    implements OnInit
{
    /** For performance reasons, we have to limit the number of fields */
    static readonly CsvFieldsLimit = 1000;

    @Input() importContext: ImportContext;
    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();

    protected gridConfig: GridConfig<any> = {
        getItemId: (item) => this.rows?.indexOf(item)?.toString(),
        autoHeight: true,
        horizontalScroll: true,
    };

    protected cols: TColDef<any>[] = [];
    protected rows: any[] = [];

    public showSettingsAndPreview: boolean;
    public targetSections: IDropdownSection[] = [];

    //#region ImportModule
    public set isSourceUpdate(value) {
        this.module.isUpdate = value;
    }

    public get hasSourceCreationAccess() {
        return this.module.hasSourceCreationAccess;
    }

    public get newSourceName() {
        return this.module.newSourceName;
    }

    public set newSourceName(value) {
        this.module.newSourceName = value;
    }

    public get sourceId() {
        return this.module.sourceId;
    }

    public set sourceId(value) {
        this.module.sourceId = value;
    }

    public get sourceType() {
        return this.module.sourceType;
    }

    public set sourceType(value) {
        this.module.sourceType = value;
    }

    public get sourceTypeName() {
        return ModelType[this.sourceType];
    }

    protected get module() {
        return this.importContext?.currentModule as ImportModuleCatalog;
    }

    //#endregion

    //#region ImportContext
    public get selectedSpaceIdr() {
        return this.importContext.spaceIdr;
    }

    public get isSpaceVersionSelectable() {
        return !this.importContext.hasInitialWorkspace;
    }

    public get isPreviewVisible() {
        return this.parseSuccess && this.cols.length && !this.loadingValue;
    }

    public get isWorkspaceVisible() {
        return this.importContext.importTarget == ImportTarget.Entities;
    }

    public get isOperationEditable() {
        return this.importContext.importTarget == ImportTarget.Entities;
    }

    public get csvFile(): File {
        return this.importContext.csvFile;
    }

    public set csvFile(value: File) {
        this.importContext.csvFile = value;
    }

    //#endregion

    //#region parsing data and info
    /** Show parseWarning if papaparse did not succeed, try old legacy way of parsing */
    public parseWarning: boolean;
    public parseSuccess: boolean;
    public parseFailed: boolean;
    public parseErrors: Papa.ParseError[];
    public parsedFields: Array<string>;
    public parsedData: Array<any>;
    //#endregion

    /** parse errors grouped by rows */
    public parseErrorInfos: ParseErrorInfo[];

    private parseOptions: CsvParseOptions;

    /** text delimiter conversion */
    public get delimiter() {
        return this.parseOptions.delimiter.replace('\t', '\\t');
    }

    public set delimiter(value: string) {
        this.parseOptions.delimiter = value.replace('\\t', '\t');
    }

    public isManuallyModifiedDelimiter = false;

    public get autoDetectEncoding() {
        return !this.parseOptions.skipAutoDetectEncoding;
    }

    public set autoDetectEncoding(value: boolean) {
        this.parseOptions.skipAutoDetectEncoding = !value;
    }

    /** 5 lines of parsed data, for preview */
    public get previewData() {
        return this.parsedData
            ? this.parsedData.slice(0, Math.min(this.parsedData.length || 0, 5))
            : [];
    }

    public availableSources: EntityItem[];
    public sourceServerType = ServerType.Model;
    public readonly allowedEncodings = ['UTF-8', 'windows-1252'];
    private fileContent: File;
    private selectedSpace: Space;
    private accessibleModules: DgModuleDefinition[];

    constructor(
        private entityService: EntityService,
        private entitySecurityService: EntitySecurityService,
        private appSpaceService: AppSpaceService,
        private functionalLogService: FunctionalLogService,
        private translate: TranslateService,
        private moduleStore: ModuleStore,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.resetFromPrevious();
        this.parseOptions = this.importContext.csvParseOptions;
        this.parseOptions.skipAutoDetectEncoding = false;
        this.initAsync().then();
    }

    public onUploadFiles(e: { files: File[] }) {
        this.csvFile = e.files[0];
        this.importContext.contentRequestId = null;
        this.parseCSVFile().then();
    }

    public onDeleteFile() {
        this.csvFile = null;
        this.resetFromPrevious();
    }

    public onIsUpdateChange(isUpdate: boolean) {
        this.isSourceUpdate = isUpdate;
    }

    public onSourceTypeChange(sourceTypeName: string) {
        this.sourceType = ModelType[sourceTypeName];
    }

    public onNewSourceNameChange(sourceName: string) {
        this.newSourceName = sourceName;
    }

    public onSourceChange(value: EntityItem) {
        this.sourceId = value.DataReferenceId;
        this.sourceType = ModelType[value.SubTypeName];
    }

    public onReset() {
        this.sourceId = null;
        this.newSourceName = null;
        this.sourceType = null;
    }

    public getFileName() {
        return this.csvFile.name ?? '';
    }

    public isFileUploaded() {
        return !!this.csvFile;
    }

    public async onClickReload() {
        this.parseCSVFile().then();
    }

    public onClickShowSettings() {
        this.showSettingsAndPreview = !this.showSettingsAndPreview;
    }

    public getCurrentOperationTitle() {
        const opeKey = this.importContext.currentModule?.getOpeTextKey(
            this.importContext.currentOperation
        );
        const translateKey =
            opeKey ??
            'Import.GenericImportWizard.CsvSettings.SelectOperationLbl';
        return this.translate.instant(translateKey);
    }

    public onFileOptionsChange(config: IFileOptionConfig) {
        this.delimiter = config.delimiter;
        this.isManuallyModifiedDelimiter = config.isDelimiterModified;
        this.importContext.csvParseOptions.encoding = config.encoding;
        this.parseOptions.encoding = config.encoding;
        this.importContext.csvParseOptions.newline = config.carriage;
        this.parseOptions.newline = config.carriage;
    }

    public isSourceConfigVisible() {
        const requiringSourceOperations = [
            EOperationType.Containers,
            EOperationType.Structures,
            EOperationType.Fields,
            EOperationType.PKs,
            EOperationType.FKs,
            EOperationType.FFKs,
        ];
        return (
            this.selectedSpaceIdr &&
            requiringSourceOperations.some(
                (ope) => ope == this.importContext.currentOperation
            )
        );
    }

    public isSourceWarningVisible() {
        if (!this.isSourceConfigVisible()) {
            return false;
        }
        const importModule = this.importContext
            .currentModule as ImportModuleCatalog;
        return importModule?.isUpdate
            ? !importModule?.sourceId
            : !importModule.newSourceName;
    }

    public isTargetErrorVisible() {
        return this.importContext.currentModule == null;
    }

    public async onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        const hasSpaceOrVersionChanged = !SpaceIdentifier.areSame(
            spaceIdr,
            this.importContext.spaceIdr
        );
        this.log('onSpaceVersionSelected', hasSpaceOrVersionChanged);
        if (!hasSpaceOrVersionChanged) {
            return;
        }

        this.importContext.spaceIdr = spaceIdr;
        this.importContext.currentModule = null;
        this.importContext.currentOperation = null;
        await this.initSources();
        await this.initWithSpaceIdr();
    }

    //#region parsing

    private async parseCSVFile() {
        await this.parseFile(this.csvFile);
    }

    /** Try with one Option and then Another **/
    @withLoading()
    private async parseFile(file: File) {
        const options = this.parseOptions;
        const newLine = options.newline?.length ? options.newline : undefined;
        const testedNewLineStrings = [];
        if (newLine) {
            testedNewLineStrings.push(newLine);
        }
        await this.tryParseFile(file, newLine, testedNewLineStrings);
    }

    private async tryParseFile(
        file: File,
        newLineString: string,
        testedNewLineStrings: string[]
    ): Promise<void> {
        const options = this.parseOptions;
        this.log('tryParseFile', file, options);

        this.fileContent = file;

        const detectEncodingPromise = options.skipAutoDetectEncoding
            ? Promise.resolve(options.encoding)
            : detectEncoding(
                  file,
                  this.allowedEncodings,
                  options.encoding,
                  this.logger
              );

        const encoding = await detectEncodingPromise;

        if (encoding != options.encoding) {
            this.log(
                'tryParseFile',
                'updating selected encoding',
                options.encoding,
                encoding
            );
            options.encoding = encoding;
        }

        this.debug && console.time('parse');
        Papa.parse(file, {
            delimiter: this.isManuallyModifiedDelimiter ? this.delimiter : '',
            newline: newLineString,
            quoteChar: options.quoteChar,
            encoding: encoding,
            header: true,
            preview: 10,
            dynamicTyping: false,
            worker: true,
            comments: false,
            skipEmptyLines: true,
            complete: (results) => {
                this.debug && console.timeEnd('parse');
                const usedLineBreak = newLineString ?? results.meta.linebreak;
                this.delimiter = results.meta.delimiter;
                this.debug &&
                    !newLineString?.length &&
                    this.log('parse-lineBreak-detected', {
                        selected: escape(newLineString),
                        used: escape(usedLineBreak),
                    });
                this.parseFileSuccess(
                    results,
                    usedLineBreak,
                    testedNewLineStrings,
                    file
                );
            },
            error: (err) => {
                this.debug && console.timeEnd('parse');
                this.log('parse-error', err);
                this.parseFileFail([err]);
            },
        });
    }

    private async parseFileSuccess(
        results: Papa.ParseResult,
        newLineString: string,
        testedNewLineStrings: string[],
        file: File
    ) {
        this.log('parseFileSuccess', results);

        this.parsedData = results.data;
        this.parsedFields = results.meta.fields
            ?.filter((field) => !StringUtil.isNullOrEmpty(field))
            .slice(0, DxyCsvImportSettingsComponent.CsvFieldsLimit);

        if (results.errors?.length || !this.parsedData.length) {
            if (newLineString?.length) {
                testedNewLineStrings.push(newLineString);
                const otherLineOption =
                    this.getOtherLineEndOption(newLineString);
                if (!testedNewLineStrings.find((s) => s == otherLineOption)) {
                    testedNewLineStrings.push(otherLineOption);
                    await this.tryParseFile(
                        file,
                        otherLineOption,
                        testedNewLineStrings
                    );
                    this.refreshGrid();
                    return;
                }
            }

            await this.parseFileFail(results.errors);
        } else {
            this.parseOptions.newline = newLineString;
            this.log('file parse complete:', results);

            this.importContext.csvFileName = this.getFileName();
            this.importContext.csvFields = this.parsedFields.map(
                (pf) => new CsvFieldInfo(pf)
            );
            this.csvFile = file;
            this.importContext.csvParsedData = this.parsedData;
            this.importContext.attributeMatches = undefined;

            this.parseSuccess = true;
            this.parseFailed = false;
            this.parseWarning = false;
            this.refreshGrid();
        }
    }

    private async parseFileFail(errors: Papa.ParseError[]) {
        CoreUtil.warn('file parse error: ', errors);

        this.parseErrors = errors;
        this.parseErrorInfos = ParseErrorInfo.fromParseErrors(errors);

        this.importContext.csvFileName = undefined;
        this.importContext.csvFields = undefined;
        this.csvFile = undefined;
        this.importContext.csvParsedData = undefined;
        this.importContext.attributeMatches = undefined;

        this.parseSuccess = false;
        this.parseFailed = true;
        this.showSettingsAndPreview = true;

        await this.tryParseUsingOldMethod();
    }

    private getOtherLineEndOption(lineEndOption: string) {
        return lineEndOption == '\n' ? '\r\n' : '\n';
    }

    // TODO vbo #Archi-import : pretty sure this 'old parsing method' is never relevant. Can we measure it's uses / efficiency ?
    private async tryParseUsingOldMethod() {
        const rawFileContent = await CoreUtil.readAsText(
            this.fileContent,
            this.parseOptions.encoding
        );

        const matches = rawFileContent.match(/(.*)[\n\r]+/);
        const fields = matches[1]?.split(this.parseOptions.delimiter);
        const unquotedFields = fields.map((c) => c.replace(/"/g, ''));

        this.parsedFields = unquotedFields;
        const csvFields = unquotedFields.map((c) => new CsvFieldInfo(c));

        this.csvFile = this.fileContent;
        this.importContext.csvFileName = this.getFileName();
        this.importContext.csvFields = csvFields;

        this.parseWarning = true;
        this.parseSuccess = true;
        this.parseFailed = false;

        this.refreshGrid();
    }

    //#endregion

    private refreshGrid() {
        this.cols = [...this.getColumns()];
        this.rows = [...this.previewData];

        this.cd.detectChanges();
    }

    private resetFromPrevious() {
        this.parseSuccess = true;
        this.parseFailed = false;
        this.parsedFields = this.importContext.csvFields.map((f) => f.name);
        this.parsedData = this.importContext.csvParsedData;
        this.importContext.attributeMatches = undefined;
        this.importContext.csvFields.forEach(
            (f) => (f.isMatched = f.isFilteredOut = false)
        );
    }

    private getColumns(): TColDef<any>[] {
        return this.parsedFields.map((id) => ({
            id,
            headerLabel: id,
            type: GridCellType.text,
            minWidth: 100,
            resizable: true,
            fixed: true,
            sortable: false,
        }));
    }

    //#region init target selection data

    private initSections() {
        this.log(
            'initSections-start',
            DgModule[this.importContext.initialModule]
        );
        this.targetSections = [];
        switch (this.importContext.initialModule) {
            case DgModule.Glossary:
                if (this.hasUserAccessToModule(DgModule.Glossary)) {
                    this.targetSections.push(this.getGlossarySection(true));
                }
                break;
            case DgModule.Catalog:
                if (this.hasUserAccessToModule(DgModule.Catalog)) {
                    this.targetSections.push(this.getCatalogSection(true));
                }
                break;
            case DgModule.Processing:
                if (this.hasUserAccessToModule(DgModule.Processing)) {
                    this.targetSections.push(this.getDpSection(true));
                }
                break;
            case DgModule.Usage:
                if (this.hasUserAccessToModule(DgModule.Usage)) {
                    this.targetSections.push(this.getUsageSection(true));
                }
                break;
            case DgModule.unknown:
            default:
                if (this.hasModuleImportsAccess(DgModule.Glossary)) {
                    this.targetSections.push(this.getGlossarySection());
                }
                //Todo vbo : add condition if admin of at least 1 source (& add source security checks)
                if (this.hasModuleImportsAccess(DgModule.Catalog)) {
                    this.targetSections.push(this.getCatalogSection());
                }
                if (this.hasModuleImportsAccess(DgModule.Processing)) {
                    this.targetSections.push(this.getDpSection());
                }
                if (this.hasModuleImportsAccess(DgModule.Usage)) {
                    this.targetSections.push(this.getUsageSection());
                }
                break;
        }

        if (this.targetSections.length == 1) {
            this.targetSections[0].isExpanded = true;
        }

        this.log('initSections-end', this.targetSections);
    }

    private getGlossarySection(isExpanded = false) {
        const glossaryBaseTranslateKey = 'Import.Wizard.Glossary';

        const operations = [
            EOperationType.Properties,
            EOperationType.GlossaryRelations,
        ];

        const options = this.getOptions(glossaryBaseTranslateKey, operations);

        return {
            textTranslateKey: `${glossaryBaseTranslateKey}.Title`,
            isExpanded,
            filteredOptions: options,
        } as IDropdownSection;
    }

    private getCatalogSection(isExpanded = false) {
        const catalogBaseTranslateKey = 'Import.Wizard.Catalog';

        const operations = [
            EOperationType.Sources,
            EOperationType.Containers,
            EOperationType.Structures,
            EOperationType.Fields,
            EOperationType.PKs,
            EOperationType.FKs,
            EOperationType.FFKs,
            EOperationType.CatalogRelations,
        ];

        const options = this.getOptions(catalogBaseTranslateKey, operations);

        return {
            textTranslateKey: `${catalogBaseTranslateKey}.Title`,
            isExpanded,
            filteredOptions: options,
        } as IDropdownSection;
    }

    private getDpSection(isExpanded = false) {
        const dpBaseTranslateKey = 'Import.Wizard.DataProcessings';

        const operations = [
            EOperationType.DataProcessings,
            EOperationType.DataProcessingInput,
            EOperationType.DataProcessingOutput,
            EOperationType.DataProcessingItems,
            EOperationType.DataProcessingRelations,
        ];

        const options = this.getOptions(dpBaseTranslateKey, operations);

        return {
            textTranslateKey: `${dpBaseTranslateKey}.Title`,
            isExpanded,
            filteredOptions: options,
        } as IDropdownSection;
    }

    private getUsageSection(isExpanded = false) {
        const usageBaseTranslateKey = 'Import.Wizard.SoftwareElements';

        const operations = [
            EOperationType.SoftwareElements,
            EOperationType.SoftwareRelations,
        ];

        const options = this.getOptions(usageBaseTranslateKey, operations);

        return {
            textTranslateKey: `${usageBaseTranslateKey}.Title`,
            isExpanded,
            filteredOptions: options,
        } as IDropdownSection;
    }

    private getOptions(
        baseTranslateKey: string,
        operations: EOperationType[]
    ): IListOption<EOperationType>[] {
        return operations.map((o) => ({
            labelKey: `${baseTranslateKey}.${EOperationType[o]}`,
            callback: () => this.onSelectTypeTarget(o),
            data: o,
        }));
    }

    //#endregion

    private async onSelectTypeTarget(operation: EOperationType) {
        this.importContext.currentModule = ImportModule.getModule(
            operation,
            this.importContext
        );
        const space = await this.appSpaceService.getSpace(
            this.importContext.spaceIdr
        );
        this.module.hasSourceCreationAccess =
            space?.SecurityData.HasCreateAccess;
        this.importContext.currentOperation = operation;
        const operationCode =
            ImportModule.getImportOperationFunctionalCode(operation);
        this.functionalLogService.logFunctionalAction(
            `IMPORT_CSV_${operationCode}`,
            CrudOperation.R
        );
    }

    private hasModuleImportsAccess(module: DgModule) {
        const hasModuleImportAccess = this.selectedSpace
            ? this.entitySecurityService.getModuleRootSecurityData(
                  module,
                  this.selectedSpace
              )?.HasImportAccess
            : false;
        return hasModuleImportAccess && this.hasUserAccessToModule(module);
    }

    private hasUserAccessToModule(module: DgModule) {
        return this.accessibleModules.some((m) => DgModule[m.name] === module);
    }

    private async initAsync() {
        this.log('init-start');
        this.accessibleModules = this.moduleStore.modules;
        await this.initWithSpaceIdr();
        await this.initSources();
        if (this.isFileUploaded()) {
            await this.parseCSVFile();
        }
        this.refreshGrid();
        this.log('init-end');
    }

    private async initWithSpaceIdr() {
        const spaceIdr = this.importContext.spaceIdr;
        this.log('initWithSpaceIdr', spaceIdr);
        this.selectedSpace = await this.appSpaceService.getSpace(spaceIdr);
        this.initSections();
    }

    private async initSources() {
        if (this.importContext.spaceIdr) {
            const result = await this.entityService.getModelEntities(
                this.importContext.spaceIdr
            );
            this.availableSources = result.Entities.filter(
                (model) => model.SecurityData.HasImportAccess
            );
        }
    }
}
