import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DialogType } from '@datagalaxy/ui/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IMoveEntityModalResolve } from './entity-move-modal-types';
import { EntityService } from '../services/entity.service';
import { EntityUiService } from '../services/entity-ui.service';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { EntitySelectorData } from '../../entitySelector/entity-selector.types';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import {
    getSpaceIdFromEntity,
    isSpaceReferenceId,
} from '@datagalaxy/webclient/utils';
import { IUpdateLinksResult } from '@datagalaxy/webclient/entity/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DxyModalFooterComponent } from '../../dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyEntitySelectorFieldComponent } from '../../entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'dxy-entity-move-modal',
    templateUrl: './dxy-entity-move-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyFieldTextComponent,
        forwardRef(() => DxyEntitySelectorFieldComponent),
        DxyModalFooterComponent,
    ],
})
export class DxyEntityMoveModalComponent
    extends DxyBaseModalComponent<IMoveEntityModalResolve, void>
    implements OnInit
{
    public parentItem: IHasHddData;
    public canDeleteParent: boolean;
    public parentErrorMessage: string;
    public canCreate = false;
    public isSaving = false;
    public readonly entitySelectorData = new EntitySelectorData(true);
    public get entityDisplayName() {
        return this.entity?.DisplayName;
    }
    public get entityTypeString() {
        return this.attributeDataService.getEntityTypeTranslation(
            this.entity?.entityType
        );
    }

    private entity: EntityItem;
    private get parentHdd() {
        return this.parentItem?.HddData.Data;
    }

    constructor(
        private translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private entityUiService: EntityUiService,
        private dxyModalService: DxyModalService,
        private entityService: EntityService,
        dialogRef: MatDialogRef<DxyEntityMoveModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IMoveEntityModalResolve
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        const entity = (this.entity = this.data.entityData);
        this.entitySelectorData.setupForParentChange(entity);
        const parentHdd = this.getParentHdd(entity);
        this.setParent(this.makePseudoItem(parentHdd));
    }

    public setParent(parent: IHasHddData) {
        this.parentItem = parent;
        this.parentErrorMessage = null;
        this.updateCanDeleteParent();
        this.updateCanCreate();
    }

    public onCloseSubmit() {
        this.onCloseSubmitAsync().then();
    }

    private updateCanDeleteParent() {
        this.canDeleteParent =
            !!this.parentItem &&
            this.entityUiService.canDeleteParent(this.entity.entityType);
    }

    private updateCanCreate() {
        const newParentId = this.parentHdd?.DataReferenceId;
        this.canCreate = this.entity.entityParentId != newParentId;
    }

    private async onCloseSubmitAsync() {
        const hasParent =
            this.parentHdd &&
            !isSpaceReferenceId(this.parentHdd.DataReferenceId);
        if (hasParent) {
            const success = await this.updateParent();
            this.log(
                'onCloseSubmitAsync-success',
                success,
                this.parentErrorMessage
            );
            if (success) {
                super.onCloseSubmit();
            }
        } else {
            super.onCloseSubmit();
            const confirmed = await this.confirmRemoveParent();
            if (confirmed) {
                await this.doUpdateParent();
            } else {
                await this.entityUiService.openChangeParentModal(this.entity);
            }
        }
    }
    private async updateParent(): Promise<boolean> {
        this.parentErrorMessage = null;
        this.isSaving = true;
        try {
            await this.doUpdateParent();
            return true;
        } catch (error) {
            this.parentErrorMessage = this.getErrorMessage(error);
            return false;
        } finally {
            this.isSaving = false;
        }
    }
    private async doUpdateParent() {
        const parentHdd =
            this.parentHdd ??
            new HierarchyDataDescriptor(getSpaceIdFromEntity(this.entity));
        await this.entityService.updateEntity(
            this.entity,
            ServerConstants.PropertyName.LogicalParentData,
            parentHdd
        );
    }
    private getErrorMessage(error: { data?: IUpdateLinksResult }) {
        if (error?.data?.IsErrorWouldBreakUniquenessRule) {
            return this.translate.instant(
                'UI.EntityMoveModal.duplicateNameError'
            );
        }
        return error?.data?.ErrorDetails ?? '(error)';
    }

    private async confirmRemoveParent() {
        return await this.dxyModalService.confirm({
            titleKey: 'UI.EntityMoveModal.modalTitle',
            messageKey: 'UI.EntityMoveModal.confirmModalLbl',
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnUpdate',
        });
    }

    private getParentHdd(entity: EntityItem) {
        return entity.entityParentId ? entity.HddData.Parents[0] : null;
    }

    private makePseudoItem(hdd: HierarchyDataDescriptor): IHasHddData {
        return hdd && { HddData: new HierarchicalData(hdd, []) };
    }
}
