import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { DataTypeMapping, DataTypeMappingItem } from './data-type-mapping';
import { CRefIdr, CRefSubIdr, Ref } from '../ref';

/** Ref&lt;DataTypeMapping&gt; legacy generic format adapter */
@inheritSerialization(CRefIdr)
export class CDataTypeMappingRef extends CRefSubIdr<DataTypeMapping> {
    readonly serverType = ServerType.DataTypeMapping;

    constructor() {
        super();
    }
}

@inheritSerialization(Ref)
export class DataTypeMappingItemRef extends Ref<DataTypeMappingItem> {
    static fromIds(ids: string[]) {
        return ids?.map(DataTypeMappingItemRef.fromId) ?? [];
    }

    static fromId(id: string) {
        return new DataTypeMappingItemRef(id);
    }

    constructor(id?: string) {
        super(ServerType.DataTypeMappingItem, id);
    }
}

@inheritSerialization(Ref)
export class DataTypeMappingRef extends Ref<DataTypeMapping> {
    static fromIds(ids: string[]) {
        return ids?.map(DataTypeMappingRef.fromId) ?? [];
    }

    static fromId(id: string) {
        return new DataTypeMappingRef(id);
    }

    constructor(id?: string) {
        super(ServerType.DataTypeMapping, id);
    }
}
