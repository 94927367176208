<ng-container *ngIf="pendingTranslationMessageKeys$ | async as messageKeys">
    <ng-container *ngIf="messageKeys.length > 0">
        <div
            *ngIf="displayMode === 'badge'"
            class="progress"
            [dxyRichTooltip]="batchesTooltip"
            [richTooltipPosition]="'left'"
            [richTooltipPositionOptions]="{ offsetX: -10 }"
        >
            <div class="dg5-badge warning" translate>
                Multilingual.pendingTranslation
            </div>
        </div>
        <dxy-icon
            *ngIf="displayMode === 'icon'"
            icon="glyph-warning"
            [matTooltip]="'Multilingual.pendingTranslation' | translate"
        ></dxy-icon>
    </ng-container>
    <div
        *ngIf="messageKeys.length === 0 && displayMode === 'badge'"
        class="progress"
    >
        <div class="dg5-badge success" translate>
            Multilingual.translationCompleted
        </div>
    </div>
</ng-container>

<ng-template #batchesTooltip>
    <div class="batches-tooltip dg5-custom-tooltip">
        <h3 translate>
            Multilingual.Administration.LanguagesTab.TranslationProgressTooltip.title
        </h3>
        <ul>
            <li
                *ngFor="
                    let messageKey of pendingTranslationMessageKeys$ | async
                "
            >
                {{ messageKey | translate }}
            </li>
        </ul>
    </div>
</ng-template>
