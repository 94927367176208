export enum EntityPanelTool {
    unknown = 0,
    Insights,
    DataQuality,
    LinkedObject,
    Assets,
    EntityTree,
    Tasks,
    Commentaries,
    Suggestion,
    VersionComparison,
    ActivityLog,
    Details,
    PrimaryKey,
    Campaign,
    Diagrams,
}
