import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');

/** Low level configuration values, loaded at runtime.
 * - Read from <client_host>/config/app-config.json,
 * - located at <build>/config/app-config.json,
 * - written by azure devops build pipeline, and azure devops release pipeline or docker/K8S
 * - Default version (used for dev) is in /src/app-config.json */
export interface IAppConfig {
    versionCheckMinutes: number | string;
    platformVersionNumber: string;
    //#region set by azure devops  build pipeline
    versionNumber: string;
    //#endregion
    //#region set by azure devops release pipeline or docker/K8S
    type: 'prod' | 'nonprod';
    apiHost: string;
    versionName: string;
    releaseNoteUrl?: string;
    cguUrl?: string;
    blogUrl?: string;
    applicationPort?: number | string;

    disableBlog: string;
    // If set, no connector will be available (plugins, tabs, pages)
    disableConnectors: string;
    disableFeedback: string;
    disableFirstAccess: string;
    disableOnlineHelp: string;
    disablePublicApi: string;
    disableEmailing: string;
    disableTracking: string;
    disableReleaseNote: string;
    disableAutoLogin: string;
    disableCommunicationPreferences: string;
    disableDataTestIds: string;
    enableMsTeamsIntegration: string;
    enableAuthV2: string;
    enableConnectorUrnSupport: string;

    // If set, and disabledConnector is false, online plugins will be requested in import wizard
    isEmbeddedConnectorAvailable: 'yes' | 'no';
    //#endregion
}

export interface IDgAppVersionInfo {
    versionName: string;
    platformVersionNumber: string;
    versionNumber: string;
    buildString: string;
}
