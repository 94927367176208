import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
    EditCellType,
    IColDef,
    IEditGridData,
    IValueChange,
} from '@datagalaxy/core-ui';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import {
    IColumnFieldChange,
    IPrimaryKeySettingsInput,
    IPrimaryKeySettingsOutput,
} from '../pkfk.types';
import { ModelerService } from '../../services/modeler.service';
import { Column } from '@datagalaxy/webclient/modeler/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyEditGridComponent } from '@datagalaxy/core-ui';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'app-primary-key-settings-content',
    templateUrl: 'primary-key-settings-content.component.html',
    standalone: true,
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        NgIf,
        TranslateModule,
        NgClass,
        MatLegacyAutocompleteModule,
        NgFor,
        MatLegacyOptionModule,
        DxyEditGridComponent,
    ],
})
export class PrimaryKeySettingsContentComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() data: IPrimaryKeySettingsInput;
    @Input() noTableName?: boolean;
    @Input() readOnly?: boolean;
    @Input() canEditColumnProperties = false;

    @Output() readonly outputChange =
        new EventEmitter<IPrimaryKeySettingsOutput>();

    public currentSearch: string;
    public currentTableTechnicalName: string;
    public currentPkName: string;
    public gridData: IEditGridData<Column>;
    public get tableGlyphClass() {
        return EntityTypeUtils.getColoredGlyphClass(
            this.data.currentTable.EntityType
        );
    }

    /** key is "{colDef.field??colDef.id}|{column.ReferenceId}" */
    private readonly changesByColumnField = new Map<
        string,
        IColumnFieldChange
    >();
    private readonly dataSource = new MatTableDataSource<Column>();
    private readonly colDefs: IColDef<Column>[] = [
        {
            field: 'DisplayOrder',
            headerKey: 'UI.Modeler.PrimaryKeyColumnsTable.lblTableOrder',
            width: 30,
            minWidth: 30,
            isReadOnly: () => !this.canEditColumnProperties,
        },
        {
            field: 'TechnicalName',
            headerKey: 'UI.Modeler.PrimaryKeyColumnsTable.lblTechnicalName',
            width: '40%',
            minWidth: 80,
            isReadOnly: () => !this.canEditColumnProperties,
        },
        {
            id: 'dataType',
            headerKey: 'UI.Modeler.PrimaryKeyColumnsTable.lblDataTypeName',
            getValue: (column) =>
                this.modelerService.getColumnDataTypeDisplayName(column),
            width: '15%',
            minWidth: 50,
            isReadOnly: () => !this.canEditColumnProperties,
        },
        {
            field: 'Size',
            headerKey: 'UI.Modeler.PrimaryKeyColumnsTable.lblSize',
            width: 30,
            minWidth: 30,
            isReadOnly: () => !this.canEditColumnProperties,
        },
        {
            type: EditCellType.action,
            id: 'remove',
            headerText: '',
            actionGlyphClass: 'glyph-delete',
            actionCallback: (column) =>
                this.onRemoveColumnFromPrimaryKey(column),
            isDisplayedOnHover: true,
            actionDisabled: () => this.readOnly,
            noSort: true,
            width: 10,
        },
    ];
    private get currentPkColumns() {
        return this.dataSource.data;
    }
    private set currentPkColumns(value: Column[]) {
        this.dataSource.data = value;
        this.updateOutput();
    }

    constructor(private modelerService: ModelerService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'data', () => this.init());
    }
    ngOnInit() {
        this.init();
    }

    public updateOutput() {
        const output: IPrimaryKeySettingsOutput = {
            primaryKeyName: this.currentPkName,
            columnIds: this.currentPkColumns.map(
                (column) => column.ReferenceId
            ),
            isValidSettings: !!(
                this.currentPkName && this.currentPkColumns?.length
            ),
            tableId: this.data?.currentTable.ReferenceId,
            columnChanges: CollectionsHelper.filterMap(
                this.changesByColumnField,
                (v) => v.currentValue != v.previousValue
            ),
        };
        this.log('updateOutput', output);
        this.outputChange.emit(output);
    }

    public getAvailableColumns(searchTerm: string) {
        return this.data.availableColumns
            .filter(
                (availableColumn) =>
                    !this.isColumnUsedInPrimaryKeyColumns(availableColumn)
            )
            .filter(
                (column) =>
                    searchTerm &&
                    StringUtil.contains(column.TechnicalName, searchTerm, true)
            );
    }

    public onSelectColumn(event: MatAutocompleteSelectedEvent) {
        this.currentPkColumns = [...this.currentPkColumns, event.option.value];
        this.currentSearch = null;
    }

    public onColumnPropertyChanged(e: IValueChange<Column>) {
        const map = this.changesByColumnField;
        const key = `${e.colDef.field ?? e.colDef.id}|${e.item.ReferenceId}`;
        if (map.has(key)) {
            map.get(key).currentValue = e.currentValue;
        } else {
            map.set(key, {
                column: e.item,
                fieldKey: e.colDef.field,
                fieldId: e.colDef.id,
                previousValue: e.previousValue,
                currentValue: e.currentValue,
            });
        }
        this.log('onColumnPropertyChanged', map);
        this.updateOutput();
    }

    private isColumnUsedInPrimaryKeyColumns(column: Column) {
        return this.currentPkColumns.some(
            (usedColumn) => usedColumn.ReferenceId === column.ReferenceId
        );
    }

    private onRemoveColumnFromPrimaryKey(column: Column) {
        this.currentPkColumns = [
            ...CollectionsHelper.removeElement(this.currentPkColumns, column),
        ];
    }

    private init() {
        this.log('init', this.data);
        this.changesByColumnField.clear();
        this.currentTableTechnicalName = this.data.currentTable.TechnicalName;
        const pk = this.data.currentPrimaryKey;
        this.currentPkName = pk
            ? pk.TechnicalName
            : `PK_${this.currentTableTechnicalName}`;
        this.gridData = {
            dataSource: this.dataSource,
            colDefs: this.colDefs,
            options: {
                readonly: () => this.readOnly,
                sortFixed: true,
                orderByDrag: {
                    headerKey:
                        'UI.Modeler.PrimaryKeyColumnsTable.lblPrimaryKeyOrder',
                    onDrop: () => this.updateOutput(),
                    showValue: true,
                    getValue: (column) =>
                        this.currentPkColumns.indexOf(column) + 1,
                },
                orderByDragColumnWidth: 30,
            },
        };
        this.currentPkColumns = this.modelerService.getPkColumns(pk);
    }
}
