import {
    IConnectionToken,
    OrphanedObjectsHandling,
} from './dxy-connection-form-target/dxy-connection-form-target.types';
import { IConnectorPlugin } from '@datagalaxy/webclient/connectivity/data-access';

export type JsonObject = { [key: string]: string };

export type ConnectionOutput = {};

export type ImportFormConfig = {
    plugin: IConnectorPlugin;
    connectionName: string;
    connectionId?: string;
    dataStructure: ImportDataStructureConfig;
    token: IConnectionToken;
    output: ConnectionOutput;
    orphanedObjectsHandling: OrphanedObjectsHandling;
};

export type ConnectionFormConfig = {
    importConfig: ImportFormConfig;
};

export type ImportDataStructureConfig =
    | ImportDataTreeConfig
    | ImportDataUrnConfig;

export class ImportDataTreeConfig {
    public importEntityTargets: ImportEntityTargets;

    constructor(importEntityTargets: ImportEntityTargets) {
        this.importEntityTargets = importEntityTargets;
    }
}

export type ImportEntityTargets = {
    // usage
    rootApplicationName?: string;
    // data processing
    rootDataflowName?: string;
    // dictionary
    rootSourceId?: string;
    rootSourceName?: string;
};

export class ImportDataUrnConfig {}
