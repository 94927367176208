import { BaseServiceParameter } from '@datagalaxy/data-access';
import { deserialize, inheritSerialization, serialize } from 'cerialize';
import { ModelerApiResult } from '../model/model.queries';

@inheritSerialization(BaseServiceParameter)
export class UpdatePrimaryKeyParameter extends BaseServiceParameter {
    @serialize public TableId: string;
    @serialize public Name: string;
    @serialize public ColumnIds: string[];

    constructor(tableId: string, name: string, columnIds: string[]) {
        super();
        this.TableId = tableId;
        this.Name = name;
        this.ColumnIds = columnIds;
    }
}

@inheritSerialization(ModelerApiResult)
export class ModelerUpdatePKApiResult extends ModelerApiResult {
    @deserialize IsModelError!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class AddColumnToPrimaryKeyParameter extends BaseServiceParameter {
    @serialize public TableId: string;
    @serialize public ColumnId: string;

    constructor(tableId: string, columnId: string) {
        super();
        this.TableId = tableId;
        this.ColumnId = columnId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class RemoveColumnFromPrimaryKeyParameter extends BaseServiceParameter {
    @serialize public TableId: string;
    @serialize public ColumnId: string;

    constructor(tableId: string, columnId: string) {
        super();
        this.TableId = tableId;
        this.ColumnId = columnId;
    }
}
