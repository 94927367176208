import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    BaseCellComponent,
    EllipsisTooltipDirective,
} from '@datagalaxy/core-ui';
import { getAttributeTypeGlyphClass } from '../attribute-glyph-util';
import {
    AttributeCommonDto,
    AttributeDTO,
} from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'dxy-attribute-cell',
    standalone: true,
    imports: [CommonModule, EllipsisTooltipDirective],
    templateUrl: './attribute-cell.component.html',
    styleUrls: ['./attribute-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeCellComponent extends BaseCellComponent<
    AttributeDTO,
    AttributeCommonDto
> {
    @Input() attribute?: AttributeCommonDto;

    public get isEditorLogoVisible() {
        return !this.attribute?.IsCdp;
    }
    public get text() {
        return (
            this.attribute?.translatedDisplayName ??
            this.attribute?.DisplayName ??
            ''
        );
    }
    public get glyphClass() {
        if (this.attribute?.AttributeType === undefined) {
            return '';
        }
        return getAttributeTypeGlyphClass(this.attribute.AttributeType);
    }

    protected override updateBindings() {
        this.attribute = this.value;
        this.cd.detectChanges();
    }
}
