import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ImportContext, ImportMode } from '../../../shared/ImportContext';
import { AppDataService } from '../../../../services/app-data.service';
import { ConnectorService } from '../../../../connector/connector.service';
import { Constants } from '../../../../shared/util/Constants';
import { SecurityService } from '../../../../services/security.service';
import { CurrentSpaceService } from '../../../../services/currentSpace.service';
import { IConnectorPlugin } from '@datagalaxy/webclient/connectivity/data-access';
import { FreshDeskService } from '../../../../shared/support/freshdesk/fresh-desk.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-solution-details',
    templateUrl: './dxy-solution-details.component.html',
    styleUrls: ['./dxy-solution-details.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        MatLegacyButtonModule,
        MatLegacyTooltipModule,
    ],
})
export class DxySolutionDetailsComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() importContext: ImportContext;
    @Input() selectedSolution?: IConnectorPlugin;

    public solutionImgUrl?: string;
    public descriptionHtml?: string;
    public titleText?: string;
    public isCsvImport: boolean;
    public isOnlineImport: boolean;
    public isDesktopImport: boolean;
    public isWarningVisible: boolean;
    public isDownloadingConnector64: boolean;
    public isDownloadingConnector32: boolean;

    public get hasManagementAccess() {
        return this.securityService.hasManagementAccess(
            this.currentSpaceService.getCurrentOrLastSpace()
        );
    }

    constructor(
        private translate: TranslateService,
        private appDataService: AppDataService,
        private freshDeskService: FreshDeskService,
        private connectorService: ConnectorService,
        private securityService: SecurityService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    ngOnInit() {
        this.solutionImgUrl =
            this.importContext.importMode == ImportMode.Csv
                ? 'images/import/csv_import.svg'
                : this.selectedSolution?.iconUrl ?? 'images/dg3-connector.png';

        this.isCsvImport = this.importContext.importMode == ImportMode.Csv;
        this.isOnlineImport =
            this.importContext.importMode == ImportMode.OnlineConnector;
        this.isDesktopImport =
            this.importContext.importMode == ImportMode.DesktopConnector;
        this.isWarningVisible =
            this.isOnlineImport && !this.hasManagementAccess;
        this.initTrads();
    }

    public onClickHelp() {
        const url = this.freshDeskService.getImportOnlineHelpUrl(
            this.importContext.importMode
        );
        window.open(url, '_blank');
    }

    public onClickSampleFiles() {
        const url = this.freshDeskService.getFreshdeskArticleUrl(
            Constants.FreshdeskUrl.CsvImportSamples
        );
        window.open(url, '_blank');
    }

    public onClickUseTokens() {
        const url = this.freshDeskService.getFreshdeskArticleUrl(
            Constants.FreshdeskUrl.OnlineImportTokens
        );
        window.open(url, '_blank');
    }

    public onClickOperationMode() {
        const url = this.freshDeskService.getFreshdeskArticleUrl(
            Constants.FreshdeskUrl.DesktopImportOperations
        );
        window.open(url, '_blank');
    }

    public onClickConnector64Download() {
        this.isDownloadingConnector64 = true;
        this.connectorService
            .downloadConnectorDesktop('64')
            .finally(() => (this.isDownloadingConnector64 = false));
    }

    public onClickConnector32Download() {
        this.isDownloadingConnector32 = true;
        this.connectorService
            .downloadConnectorDesktop('32')
            .finally(() => (this.isDownloadingConnector32 = false));
    }

    private getLanguageCode(): string {
        return this.appDataService.currentUserLanguageCode;
    }

    private initTrads() {
        const baseTranslationKey = 'Import.GenericImportWizard.SolutionDetails';
        const pluginName =
            this.selectedSolution?.title[this.getLanguageCode()] || '';

        switch (this.importContext.importMode) {
            case ImportMode.Csv:
                this.titleText = this.translate.instant(
                    baseTranslationKey + '.CsvImport.SolutionTitle'
                );
                this.descriptionHtml = this.translate.instant(
                    baseTranslationKey + '.CsvImport.SolutionDescription'
                );
                break;
            case ImportMode.OnlineConnector: {
                const btnName = this.translate.instant(
                    'Import.GenericImportWizard.SolutionDetails.NextBtn'
                );
                this.titleText = this.translate.instant(
                    baseTranslationKey + '.EmbeddedImport.SolutionTitle'
                );
                this.descriptionHtml = this.translate.instant(
                    baseTranslationKey + '.EmbeddedImport.SolutionDescription',
                    { pluginName: pluginName, btnName: btnName }
                );
                break;
            }
            case ImportMode.DesktopConnector: {
                const btnNext = this.translate.instant(
                    'Import.GenericImportWizard.SolutionDetails.NextBtnDesktop'
                );
                this.titleText = this.translate.instant(
                    baseTranslationKey + '.DesktopImport.SolutionTitle'
                );
                this.descriptionHtml = this.translate.instant(
                    baseTranslationKey + '.DesktopImport.SolutionDescription',
                    { pluginName: pluginName, btnNext: btnNext }
                );
                break;
            }
        }
    }
}
