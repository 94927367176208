/** Base class for a chained object, and utility for object chains */
export abstract class ChainList<T extends ChainList<T>> {
    //#region static

    /** Returns an array of consecutive chained objects given the *start* one, the direction.
     * If a *filter* function is provided, the collect stops as soon as this function returns a falsy value. */
    public static toArray<T extends ChainList<T>>(
        start: T,
        upstream?: boolean,
        filter?: (o: T) => boolean
    ) {
        if (!start) {
            return [];
        }
        const res: T[] = [];
        let c: T | undefined = start;
        if (upstream) {
            if (filter) {
                while (c && filter(c)) {
                    res.unshift(c);
                    c = c._prev;
                }
            } else {
                while (c) {
                    res.unshift(c);
                    c = c._prev;
                }
            }
        } else {
            if (filter) {
                while (c && filter(c)) {
                    res.push(c);
                    c = c._next;
                }
            } else {
                while (c) {
                    res.push(c);
                    c = c._next;
                }
            }
        }
        return res;
    }

    /** returns the first chained object matching the given predicate,
     * from the given *start* object, in the given direction */
    public static find<T extends ChainList<T>>(
        start: T,
        predicate: (o: T) => boolean,
        upstream?: boolean
    ) {
        if (!start) {
            return;
        }
        let c: T | undefined = start;
        if (upstream) {
            while (c) {
                if (predicate(c)) {
                    break;
                }
                c = c._prev;
            }
        } else {
            while (c) {
                if (predicate(c)) {
                    break;
                }
                c = c._next;
            }
        }
        return c;
    }

    /** executes the given *act* function on each chained element,
     * from the given *start* element, in the given direction */
    public static each<T extends ChainList<T>>(
        start: T,
        act: (s: T) => unknown,
        upstream?: boolean
    ) {
        if (!start) {
            return;
        }
        let c: T | undefined = start;
        if (upstream) {
            while (c) {
                act(c);
                c = c._prev;
            }
        } else {
            while (c) {
                act(c);
                c = c._next;
            }
        }
    }

    /** executes the given *stop* function on each chained element,
     * from the given *start* element, in the given direction, while *stop* returns a falsy value */
    public static eachUntil<T extends ChainList<T>>(
        start: T,
        stop: (s: T) => boolean,
        upstream?: boolean
    ) {
        if (!start) {
            return;
        }
        let c: T | undefined = start;
        if (upstream) {
            while (c) {
                if (stop(c)) {
                    break;
                }
                c = c._prev;
            }
        } else {
            while (c) {
                if (stop(c)) {
                    break;
                }
                c = c._next;
            }
        }
    }

    //#endregion

    /** This object's previous object in the chain */
    public get prev() {
        return this._prev;
    }

    /** Sets this object's *prev*, and sets this object as the next of *other*, if provided. */
    public set prev(other: T | undefined) {
        if (this._prev) {
            this._prev._next = undefined;
        }
        this._prev = other;
        if (other) {
            (other._next as ChainList<T>) = this;
        }
    }

    /** This object's next object in the chain */
    public get next() {
        return this._next;
    }

    /** Sets this object's *next*, and sets this object as the previous of *other*, if provided. */
    public set next(other: T | undefined) {
        if (this._next) {
            this._next._prev = undefined;
        }
        this._next = other;
        if (other) {
            (other._prev as ChainList<T>) = this;
        }
    }

    /** true if no next or no previous object in the chain */
    public get isFirstOrLast() {
        return !this._prev || !this._next;
    }

    /** true if no previous object in the chain */
    public get isFirst() {
        return !this._prev;
    }

    /** true if no next object in the chain */
    public get isLast() {
        return !this._next;
    }

    protected _prev?: T;
    protected _next?: T;

    /** Will set this object as the next of *prev* and the prev of *next*, if provided. */
    protected constructor(prev?: T, next?: T) {
        this.prev = prev;
        this.next = next;
    }

    /** Chains this object's *prev* and *next* to each other, removing this one from the chain */
    public unchain() {
        const { prev, next } = this;
        if (prev) {
            prev.next = next;
        }
        if (next) {
            next.prev = prev;
        }
        return this;
    }
}
