import {
    UserApiService,
    UserInfoApiService,
} from '@datagalaxy/webclient/user/data-access';
import { ClientInfoApiService } from '@datagalaxy/webclient/client/data-access';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { Injectable } from '@angular/core';
import { CurrentUserService } from './current-user-service';
import { ApiServiceError, ApiServiceErrorType } from '@datagalaxy/data-access';

@Injectable({ providedIn: 'root' })
export class CurrentUserInitializer {
    constructor(
        private userInfoApiService: UserInfoApiService,
        private clientInfoApiService: ClientInfoApiService,
        private authenticationService: AuthenticationService,
        private userApiService: UserApiService,
        private currentUserService: CurrentUserService
    ) {}

    public async init() {
        const relativeCallbackUrl =
            await this.authenticationService.initToken();
        const accessToken =
            await this.authenticationService.getAccessTokenFromStorage();
        if (!accessToken) {
            this.throwTokenError();
        }
        try {
            const userInfoResult = await this.userInfoApiService.getUserInfo();
            const userInfo = userInfoResult.UserInfo;
            const clientInfoResult =
                await this.clientInfoApiService.getClientInfo();
            const clientInfo = clientInfoResult.ClientInfo;
            const { CommunicationSettings } =
                await this.userApiService.getUserCommunicationSettings();
            this.currentUserService.init({
                userInfo,
                clientInfo,
                communicationSettings: CommunicationSettings,
            });
        } catch (error) {
            this.manageErrors(error);
        }
        return relativeCallbackUrl;
    }

    private manageErrors(error: unknown) {
        if (
            error instanceof ApiServiceError &&
            error.type === ApiServiceErrorType.Unauthorized
        ) {
            this.reLoginOnUnauthorizedError();
            this.throwTokenError();
        }
        throw error;
    }

    private reLoginOnUnauthorizedError() {
        this.authenticationService.logoutLocal();
        this.authenticationService.authorize();
    }

    private throwTokenError() {
        throw {
            tokenError: true,
        };
    }
}
