import { FieldType } from '../../types/enums/field-type.enum';

export const sqlServerConfig = [
    {
        payloadField: 'server',
        formField: 'server',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'server-intance',
        formField: 'serverInstance',
        formMandatory: false,
        type: FieldType.text,
    },
    {
        payloadField: 'port',
        formField: 'port',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'database-name',
        formField: 'database',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'schema',
        formField: 'schema',
        formMandatory: false,
        type: FieldType.text,
    },
    {
        payloadField: 'user',
        formField: 'user',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        formMandatory: true,
        isPassword: true,
    },
    { payloadField: 'database-technology', payloadValue: 'sqlserver' },
    { payloadField: 'type', payloadValue: 'MODEL' },
];
