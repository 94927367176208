import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { ToasterService } from '../../services/toaster.service';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import {
    publicApiDocumentationUrl,
    publicApiUrl,
} from '@datagalaxy/data-access';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'dxy-api-doc-modal',
    templateUrl: 'dxy-api-doc-modal.component.html',
    styleUrls: ['dxy-api-doc-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, TranslateModule, MatLegacyButtonModule],
})
export class DxyApiDocModalComponent
    extends DxyBaseModalComponent<void, void>
    implements OnInit
{
    public v2ApiDocumentationUrl: string;
    public v2ApiUrl: string;
    public quickStartUrl: string;

    constructor(
        private toasterService: ToasterService,
        private translate: TranslateService,
        private httpClient: HttpClient,
        dialogRef: MatDialogRef<DxyApiDocModalComponent>
    ) {
        super(dialogRef);
    }

    ngOnInit() {
        this.quickStartUrl = this.translate.instant(
            'UI.apiDocumentation.links.quickStartExternalLink'
        );
        this.v2ApiUrl = publicApiUrl;
        this.v2ApiDocumentationUrl = publicApiDocumentationUrl;
    }

    public onClickQuickStart() {
        this.openInNewWindow(this.quickStartUrl);
    }

    public onClickPublicApi() {
        this.openInNewWindow(this.v2ApiDocumentationUrl);
    }

    public onClickChangelog() {
        this.openInNewWindow(`${this.v2ApiUrl}changelog`);
    }

    public async onDownloadDocumentationClick() {
        await this.getDocumentation(`${this.v2ApiUrl}specification`);
    }

    public copyUrl() {
        DomUtil.copyToClipboard(this.v2ApiUrl);
        this.toasterService.successToast({
            messageKey: 'UI.apiDocumentation.copyDone',
        });
    }

    private openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    private async getDocumentation(url: string) {
        const responseBody = await CoreUtil.toPromise(this.httpClient.get(url));
        const content = JSON.stringify(responseBody);
        saveAs(new Blob([content], { type: 'text/plain' }), 'swagger.json');
    }
}
