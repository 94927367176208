import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateTimeUtil } from '@datagalaxy/core-util';
import * as moment from 'moment';
import { BaseCellComponent } from '../BaseCellComponent';
import { IDgDateTimeCellParams } from './dgdatetime-cell.types';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf } from '@angular/common';
/**
 * ## Role
 * Cell component for a datetime value
 */
@Component({
    standalone: true,
    selector: 'dxy-dgdatetime-cell',
    templateUrl: 'dgdatetime-cell.component.html',
    styleUrls: ['dgdatetime-cell.component.scss'],
    imports: [EllipsisTooltipDirective, NgIf],
})
export class DxyDgDateTimeCellComponent<TEntity>
    extends BaseCellComponent<
        TEntity,
        moment.MomentInput,
        IDgDateTimeCellParams
    >
    implements OnChanges
{
    @Input() dgDate: moment.MomentInput;
    @Input() noTime?: boolean;
    @Input() useDgFormat?: boolean;
    @Input() placeholder?: string;

    public formatedDate?: string;
    public formatedTime?: string;

    private userLang: string;

    constructor() {
        super();
        this.userLang = moment.locale();
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        super.onAnyChangesBut(
            changes,
            this,
            ['params'],
            () => this.updateDate(),
            true
        );
    }

    protected override updateBindings() {
        this.dgDate = this.value;
        this.noTime = this.params.noTime;
        this.useDgFormat = this.params.useDgFormat;
        this.placeholder = this.params.placeholder;
        this.updateDate();
    }

    private updateDate() {
        const m = DateTimeUtil.getValidMoment(this.dgDate, this.userLang);
        if (!m) {
            this.formatedDate = this.placeholder ?? '';
            return;
        }
        this.formatedDate = DateTimeUtil.formatForDisplay(
            m,
            false,
            undefined,
            this.params?.useDgFormat
        );
        if (!this.noTime) {
            this.formatedTime = DateTimeUtil.formatTime(m);
        }
    }
}
