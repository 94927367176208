<button *ngIf="!flat" class="nav-link-button" (click)="onClick($event)">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<button
    *ngIf="flat"
    mat-flat-button
    [color]="flatButtonColor"
    (click)="onClick($event)"
>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<ng-template #buttonContent>
    <div *ngIf="hasIcon" class="icon-wrapper">
        <i *ngIf="iconGlyphClass" [ngClass]="iconGlyphClass"></i>
    </div>
    <div
        class="text-wrapper"
        dxyEllipsisTooltip
        [translate]="buttonTextKey"
    ></div>
</ng-template>
