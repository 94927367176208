<dxy-entity-selector-field
    #field
    [options]="options"
    [ngModel]="items"
    [isMultiValue]="isAttributeMultiValue"
    (ngModelChange)="onSelectionChange($event)"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [readonly]="readonly"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    (openClose)="onPanelOpenClose.emit($event)"
    [mini]="mini"
    [openPreviewOnSelectedItemClick]="true"
></dxy-entity-selector-field>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>
