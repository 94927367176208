import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {
    FunnelAction,
    ValidatedFunnelStepComponent,
} from '@datagalaxy/ui/funnel';
import {
    ATTRIBUTES_FINDER_TOKEN,
    AttributeSelectorComponent,
    AttributesFinder,
} from '@datagalaxy/webclient/attribute/feature';
import { map, Observable, startWith } from 'rxjs';
import { MultilingualFunnelStateService } from '../multilingual-funnel-state.service';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';
import {
    AttributesForm,
    isTranslatableAttribute,
} from '../../../attributes/attributes-selection-helpers';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-attributes-selection-step',
    templateUrl: './attributes-selection-step.component.html',
    styleUrls: ['../funnel-step.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AttributeSelectorComponent,
    ],
})
export class AttributesSelectionStepComponent
    extends MultilingualFunnelStepComponent
    implements ValidatedFunnelStepComponent, AfterViewInit
{
    override actions: FunnelAction[] = [
        this.previousAction,
        {
            ...this.nextAction,
        },
    ];

    public valid$!: Observable<boolean>;
    protected attributes: AttributeMetaInfo[] = [];
    protected form!: FormGroup<AttributesForm>;

    constructor(
        private formBuilder: FormBuilder,
        @Inject(ATTRIBUTES_FINDER_TOKEN)
        private attributesFinder: AttributesFinder,
        private stateService: MultilingualFunnelStateService
    ) {
        super();
        this.initForm();
    }

    ngAfterViewInit() {
        this.initAttributes();
    }

    private async initAttributes() {
        this.attributes = await this.attributesFinder.searchAttributes((a) =>
            isTranslatableAttribute(a)
        );
    }

    private initForm() {
        this.form = this.formBuilder.group<AttributesForm>({
            attributes: new FormControl(
                this.stateService.state.selectedAttributes ?? null,
                [Validators.required]
            ),
        });
        this.valid$ = this.form.statusChanges.pipe(
            startWith(this.form.status),
            map((status) => status === 'VALID')
        );
        this.form.controls.attributes.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }
            this.stateService.setSelectedAttributes(value);
        });
    }
}
