import { autoserialize, deserialize, inheritSerialization } from 'cerialize';
import { CoreUtil } from '@datagalaxy/core-util';
import {
    BaseMasterData,
    IHas$type,
    IHasReferenceId,
    IIsData,
} from '../master-data';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CRefData } from '../ref';

/** Data type meta definition, named */
export interface IDataType extends IHasReferenceId {
    DisplayName: string;
    IsSystem: boolean;
    IsSizeRequired: boolean;
    IsPrecisionRequired: boolean;
}

/** DataType legacy generic format adapter */
@inheritSerialization(CRefData)
export class CDataType
    extends CRefData<DataType>
    implements IDataType, IIsData
{
    readonly ctorType = DataType;
    readonly serverType = ServerType.DataType;
    //#region IDataType
    @deserialize DisplayName!: string;
    @deserialize IsSystem!: boolean;
    @deserialize IsSizeRequired!: boolean;
    @deserialize IsPrecisionRequired!: boolean;

    //#endregion
    asData() {
        return DataType.from(this);
    }

    protected override getProducers() {
        return [];
    }
}

@inheritSerialization(BaseMasterData)
export class DataType extends BaseMasterData implements IDataType {
    static readonly keys: (keyof IDataType)[] = [
        'DisplayName',
        'IsSystem',
        'IsSizeRequired',
        'IsPrecisionRequired',
    ];

    /**
     * $id and $type are override, so they can be serialized first
     * This is to respect a weird constraint on backend side, if those properties
     * are serialized in wrong order, the request (SaveData) will fail
     */
    @autoserialize override $id!: string;
    @autoserialize override $type!: string;

    //#region IDataType
    @autoserialize public IsSystem!: boolean;
    @autoserialize public DisplayName!: string;
    @autoserialize public IsSizeRequired!: boolean;
    @autoserialize public IsPrecisionRequired!: boolean;
    //#endregion

    public readonly ServerType = ServerType.DataType;

    static from(o: IDataType & Partial<IHas$type>) {
        return CoreUtil.buildPartial(
            DataType,
            o,
            DataType.keys,
            o?.ReferenceId,
            o?.$type
        );
    }

    static OnDeserialized(instance: any, json: any) {
        CoreUtil.log('OnDeserialized', instance, json);
    }

    static forModelSettings(id: string, displayName = 'Custom Type') {
        const dt = new DataType(id);
        dt.DisplayName = displayName;
        dt.IsSystem = false;
        dt.IsSizeRequired = false;
        dt.IsPrecisionRequired = false;
        return dt;
    }

    constructor(id?: string, $type?: string) {
        super(id, $type);
    }
}

export class DataTypeDTO implements IDataType {
    //#region IDataType
    @deserialize public ReferenceId!: string;
    @deserialize public DisplayName!: string;
    @deserialize public IsSystem!: boolean;
    @deserialize public IsSizeRequired!: boolean;
    @deserialize public IsPrecisionRequired!: boolean;
    //#endregion
    @deserialize public ModelDisplayName!: string;

    public get fullDisplayName() {
        return this.ModelDisplayName
            ? `(${this.ModelDisplayName}) ${this.DisplayName}`
            : this.DisplayName;
    }
}

// used as attribute value
export class DataTypePrecisionSize {
    constructor(
        public DataTypeId?: string,
        public Size?: number,
        public Precision?: number
    ) {}
}
