<div
    [matMenuTriggerFor]="menu"
    class="selected-workspace"
    [class.disabled]="!isMenuVisible"
    (menuOpened)="openClose.emit(true)"
    (menuClosed)="openClose.emit(false)"
    [matTooltip]="menuTriggerTooltipContent"
    [matTooltipPosition]="menuTriggerTooltipPosition"
>
    <dxy-space-icon
        *ngIf="showSpaceIcon"
        [ngClass]="iconLayoutClass"
        [workspace]="currentSelected"
    ></dxy-space-icon>

    <span
        *ngIf="showSpaceLabel"
        (click)="onDisplayNameClickInternal($event)"
        class="displayname"
        [attr.data-tracker-id]="selectedTextTrackerId"
        >{{ selectedDisplayName }}</span
    >
    <button
        *ngIf="isSpaceCaretDisplayed"
        mat-icon-button
        type="button"
        [attr.data-tracker-id]="selectedCaretTrackerId"
        [class.dg5-small-icon-button]="isSmallCaretBtn"
        dxyDataTestId="workspace-select"
    >
        <i class="glyph-arrow-drop-down"></i>
    </button>
</div>
<mat-menu #menu="matMenu" class="dg5-mat-menu {{ menuClass }}">
    <div *ngIf="isMenuVisible" class="workspaces-menu not-an-item">
        <div class="body">
            <ul *ngIf="isSpaceSelectable" class="workspaces dg5-list-unstyled">
                <li
                    *ngIf="allowAll"
                    (click)="onSpaceClick(null)"
                    class="menu-item"
                    [class.isActive]="isSelected(currentSelected)"
                    dxyLogFunctional="SELECT_SPACE,R"
                >
                    <span class="all-spaces" translate
                        >UI.SpaceVersionSelector.allSpaces</span
                    >
                </li>
                <li
                    *ngFor="
                        let navSpace of orderedNavSpaces;
                        trackBy: getNavSpaceId
                    "
                    (click)="onSpaceClick(navSpace)"
                    class="workspace menu-item"
                    [class.selected]="isSelected(navSpace)"
                    dxyLogFunctional="SELECT_SPACE,R"
                    [dxyDataTestId]="'workspace-' + navSpace.spaceId + '-item'"
                >
                    <dxy-space-icon
                        [workspace]="navSpace"
                        class="small"
                    ></dxy-space-icon>
                    <div class="workspace-text">
                        <span
                            [matTooltip]="navSpace.DisplayName"
                            matTooltipPosition="above"
                        >
                            {{ navSpace.DisplayName }}
                        </span>
                    </div>
                    <i
                        *ngIf="isFavoriteButtonVisible(navSpace)"
                        (click)="onStarClick($event, navSpace)"
                        role="button"
                        tabindex="0"
                        [matTooltip]="'UI.Tooltip.DefaultSpace' | translate"
                        matTooltipPosition="above"
                        class="workspace-favorite"
                        [class.isActive]="isSelected(navSpace)"
                        [class.mat-button-base]="enableFavorite"
                        [ngClass]="{
                            'mat-button-base mat-icon-button': enableFavorite
                        }"
                        [class.glyph-star-fav]="navSpace.IsDefaultSpace"
                        [class.glyph-star]="!navSpace.IsDefaultSpace"
                    ></i>
                </li>
            </ul>
        </div>
        <div *ngIf="showAllSpaceRedirection" class="footer">
            <ul class="dg5-list-unstyled">
                <li
                    class="menu-item"
                    (click)="goToClientSpacesList()"
                    dxyDataTestId="all-workspaces-item"
                >
                    <span translate>UI.SpacesList.showAll</span>
                </li>
            </ul>
        </div>
    </div>
</mat-menu>
