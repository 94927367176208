import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DxyCommentaryModule } from '../commentary/DxyCommentaryModule';
import { DxyAttributesModule } from '../shared/attribute/DxyAttributesModule';

const clientSharedImports = [DxyCommentaryModule, DxyAttributesModule];

@NgModule({
    imports: [...clientSharedImports],
    exports: clientSharedImports,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClientSharedModule {
    constructor() {}
}
