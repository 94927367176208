export enum FacetGroupType {
    /** not an attributeKey */
    unknown = 0,
    /** not an attributeKey */
    Module,
    /** not an attributeKey */
    TechnologyCode,
    /** not an attributeKey */
    EntityType,
    /** not an attributeKey */
    Tag,
    EntityStatus,
    BusinessDomain,
    DataOwners,
    DataStewards,
    CdoUsers,
    CisoUsers,
    DpoUsers,
    ExpertUsers,
    CreationTime,
    LastModificationTime,
    GdprRiskLevel,
    GdprPersonalDataType,
    GdprPhysicalPersonType,
    GdprSensitiveDataType,
    GdprContainsPersonalData,
    GdprContainsSensitiveData,
    GdprIsPersonalData,
    GdprIsSensitiveData,
}
