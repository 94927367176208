import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * ## Role
 * Manage Notification panel visibility
 */
@Injectable({ providedIn: 'root' })
export class NotificationPanelUiService extends BaseService {
    public get panelOpened$() {
        return this.panelOpened.asObservable();
    }
    private readonly panelOpened = new BehaviorSubject<boolean>(false);

    public togglePanel() {
        this.panelOpened.next(!this.panelOpened.value);
    }

    public closePanel() {
        this.panelOpened.next(false);
    }
}
