import { Directive, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { DXY_POPOVER_DATA } from './DxyPopoverService';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * Base class for every popover components
 */
@Directive()
export abstract class DxyBasePopoverComponent<TData> extends DxyBaseComponent {
    constructor(
        protected overlayRef: OverlayRef,
        @Inject(DXY_POPOVER_DATA) protected data?: TData
    ) {
        super();
    }

    public onClose() {
        this.log('(super)onClose');
        this.overlayRef.detach();
    }
}
