import {
    CampaignEventBase,
    registerTypedEvent,
} from '@datagalaxy/dg-object-model';
import { deserialize, inheritSerialization } from 'cerialize';

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignCreatedEvent')
export class CampaignCreatedEvent extends CampaignEventBase {}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignEntitiesAddedEvent')
export class CampaignEntitiesAddedEvent extends CampaignEventBase {
    @deserialize AddedEntitiesReferenceIds: string[];
}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignEntitiesRemovedEvent')
export class CampaignEntitiesRemovedEvent extends CampaignEventBase {
    @deserialize RemovedEntitiesReferenceIds: string[];
}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignPhaseChangedEvent')
export class CampaignPhaseChangedEvent extends CampaignEventBase {
    @deserialize PhaseGuid: string;
    @deserialize PhaseName: string;
}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignUpdatedEvent')
export class CampaignUpdatedEvent extends CampaignEventBase {}
