import { Inject, Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import {
    CreateRuleParameter,
    DataQualityRule,
    UpdateRuleParameter,
} from './data-quality';
import { RulesResult } from './data-quality-api.types';

@Injectable({ providedIn: 'root' })
export class DataQualityApiService {
    constructor(
        @Inject('RestDataQualityApiService')
        private restApiService: RestApiService
    ) {}

    public async getEntityRules(entityId: string): Promise<RulesResult> {
        return await this.restApiService.getPromise(
            `/rules?entityId=${entityId}`
        );
    }

    public async findRuleByCode(
        code: string,
        spaceId: string
    ): Promise<DataQualityRule | undefined> {
        const response = await this.restApiService.getPromise<{
            rules: DataQualityRule[];
        }>(`/rules`, { code: code, workspaceId: spaceId });
        if (response && response.rules && response.rules.length) {
            return response.rules[0];
        }
        return undefined;
    }

    public async getRuleDetails(ruleId: string): Promise<DataQualityRule> {
        return await this.restApiService.getPromise(`/rules/${ruleId}`);
    }

    public async deleteRuleCheck(
        ruleId: string,
        checkId: string
    ): Promise<void> {
        await this.restApiService.deletePromise(
            `/rules/${ruleId}/checks/${checkId}`
        );
    }

    public async createRule(
        createRuleParameter: CreateRuleParameter
    ): Promise<{ ruleId: string }> {
        return await this.restApiService.postPromise(
            `/rules`,
            createRuleParameter
        );
    }

    public async updateRule(
        ruleId: string,
        updateRuleParameter: UpdateRuleParameter
    ): Promise<void> {
        await this.restApiService.putPromise(
            `/rules/${ruleId}`,
            updateRuleParameter
        );
    }

    public async deleteRule(ruleId: string): Promise<void> {
        await this.restApiService.deletePromise(`/rules/${ruleId}`);
    }
}
