<!-- When used as a dropdown button displaying an options list -->
<div
    *ngIf="isDropdownMenu"
    class="dropdown"
    [class.disabled]="isDisabled(dropdownButton)"
    [ngClass]="{
        'mat-button-base mat-icon-button dg5-dropdown-icon-button':
            isDropdownMenu && dropdownMenuSelectedGlyphOnly
    }"
>
    <!-- dropdown button -->
    <button
        type="button"
        class="selected"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerRestoreFocus]="!noRestoreFocus"
        #menuTrigger="matMenuTrigger"
        [disabled]="isDisabled(dropdownButton)"
        [class.option-list-button]="dropdownMenuSelectedGlyphOnly"
        (menuOpened)="onMenuOpenedClosed(true)"
        (menuClosed)="onMenuOpenedClosed(false)"
    >
        <button
            *ngIf="dropdownButton"
            mat-icon-button
            class="dg5-dropdown-icon-button"
            [disabled]="isDisabled(dropdownButton)"
            [matTooltip]="getBtnTooltip()"
            [matTooltipPosition]="matTooltipPosition"
            matTooltipClass="multiline-tooltip"
        >
            <span
                [ngClass]="getGlyphClass(dropdownButton)"
                class="glyph"
            ></span>
        </button>
        <span
            *ngIf="
                !dropdownButton &&
                !dropdownMenuSelectedTextOnly &&
                hasGlyphClass(dropdownMenuSelected)
            "
            class="selected-glyph glyph"
            [ngClass]="getGlyphClass(dropdownMenuSelected)"
            [matTooltip]="getBtnTooltip()"
            [matTooltipPosition]="matTooltipPosition"
            matTooltipClass="multiline-tooltip"
        ></span>
        <span
            *ngIf="showSelectedText"
            class="selected-text text"
            [ngClass]="dropdownMenuSelected.optionTextClass"
            [attr.data-tracker-id]="selectedTextTrackerId"
            [dxyEllipsisTooltip]="selectedTextTooltip"
            (click)="onSelectedTextClicked($event)"
            #selectedText
        >
            {{ getText(dropdownMenuSelected) }}
        </span>
        <button
            *ngIf="!dropdownButton && !dropdownMenuSelectedGlyphOnly"
            mat-icon-button
            [attr.data-tracker-id]="selectedDropdownTrackerId"
            class="dropdown-glyph glyph-arrow-drop-down dg5-small-icon-button"
        ></button>
    </button>
    <!-- dropdown options list -->
    <mat-menu
        #menu="matMenu"
        [class]="matMenuClass"
        class="dg5-mat-menu"
        [xPosition]="menuXPosition"
    >
        <ng-template
            *ngIf="!isDisabled(dropdownButton) && isMenuOpen"
            [ngTemplateOutlet]="listContent"
        ></ng-template>
    </mat-menu>
</div>

<!-- When used as an options list -->
<div *ngIf="!isDropdownMenu" class="list-container">
    <ng-template [ngTemplateOutlet]="listContent"></ng-template>
</div>

<!-- Content of the options list -->
<ng-template #listContent>
    <ul
        [ngClass]="dropdownMenuCustomClass"
        (pointerdown)="onListContentPointerDown($event)"
    >
        <li *ngIf="hasSearch" class="dropdown-search" tabindex="-1">
            <dxy-search-input
                [showCancelSearch]="true"
                [searchString]="searchString"
                (onSearchString)="onSearched($event)"
                placeholderKey="{{
                    'UI.OptionList.search.placeholder' | translate
                }}"
            ></dxy-search-input>
        </li>
        <li
            *ngIf="searchHasNoResult"
            class="dropdown-search-no-result"
            translate
        >
            UI.OptionList.search.noResults
        </li>

        <ng-container
            *ngFor="let group of categoryGroups; let lastGroup = last"
        >
            <li *ngIf="categoryGroupHasText(group)" role="header">
                {{ getCategoryGroupText(group) }}
            </li>
            <ng-container *ngFor="let option of group; let index = index">
                <li
                    *ngIf="!isHidden(option)"
                    (click)="onOptionClicked(option, $event)"
                    [ngClass]="getOptionClass(option)"
                    [class.option-list-button]="!isDropdownMenu"
                    [class.disabled]="isDisabled(option)"
                    [class.rich-layout-option]="hasRichLayout(option)"
                    [class.separator]="isSeparator(option)"
                    [class.submenu]="option.noDropDown"
                    [dxyDataTestId]="getDataTestId(option)"
                    [attr.data-tracker-id]="getDataTrackerId(option)"
                >
                    <span
                        *ngIf="
                            isAction(option) ||
                            (option.noDropDown && isDropDown(option))
                        "
                        role="button"
                        [class.option-list-button]="!isDropdownMenu"
                        [class.disabled]="isDisabled(option)"
                        [ngClass]="option.optionTextClass"
                        [matTooltip]="getTooltipText(option)"
                        [matTooltipPosition]="matTooltipPosition"
                        matTooltipClass="multiline-tooltip"
                    >
                        <dxy-option-item
                            [option]="option"
                            [dataType]="dataType"
                        ></dxy-option-item>
                    </span>
                    <dxy-option-list
                        *ngIf="isDropDown(option)"
                        [isDropdownMenu]="!option.noDropDown"
                        [options]="option.options"
                        [tooltipsPlacement]="tooltipsPlacement"
                        (functional)="onFunctionalInternal($event)"
                        [_parent]="this"
                        [logId]="getNestedLogId(index)"
                    ></dxy-option-list>
                </li>
            </ng-container>
            <li *ngIf="!lastGroup" role="separator"></li>
        </ng-container>
    </ul>
</ng-template>
