import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'dxy-animated-logo',
    templateUrl: './animated-logo.component.html',
    styleUrls: ['./animated-logo.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedLogoComponent {
    protected maskId = this.getRandomMaskId();
    protected get maskUrl() {
        return `url(#${this.maskId})`;
    }

    private getRandomMaskId() {
        const randomInt = Math.floor(Math.random() * 1000000);
        return `mask-${randomInt}`;
    }
}
