import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { StringUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ICellParams, TCellRenderer } from '../../cell-components';
import { IActionOption } from '../action-list/action-list.types';
import { ITabsHeaderData, ITabItem } from '../tabs-header/tabs-header.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyDgDateTimeCellComponent } from '../../cell-components/dgdatetime-cell/dgdatetime-cell.component';
import { DxyRendererComponent } from '../renderer/renderer.component';
import {
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
} from '@angular/cdk/scrolling';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { DxyMatBurgerMenuComponent } from '../mat-burger-menu/mat-burger-menu.component';
import { DxyTabsHeaderComponent } from '../tabs-header/tabs-header.component';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { DxyPanelHostComponent } from '../panel-host/panel-host.component';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxyActionListComponent } from '../action-list/action-list.component';

/**
 * ## Role
 * Generic navigation panel with its trigger button,
 * displaying filterable list of items in a left panel
 */
@Component({
    selector: 'dxy-navigation-panel',
    templateUrl: './navigation-panel.component.html',
    styleUrls: ['./navigation-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyActionListComponent,
        MatLegacyButtonModule,
        MatLegacyTooltipModule,
        EllipsisTooltipDirective,
        DxyPanelHostComponent,
        NgIf,
        DxyTabsHeaderComponent,
        DxyMatBurgerMenuComponent,
        SearchInputComponent,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        NgClass,
        DxyRendererComponent,
        DxyDgDateTimeCellComponent,
        NgTemplateOutlet,
    ],
})
export class DxyNavigationPanelComponent<TItem = any, TId = any>
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() panelOpened: boolean;
    @Input() params!: INavigationPanelParams<TItem, TId>;
    @Output() addButtonClick = new EventEmitter();
    @Output() itemClick = new EventEmitter<TItem>();
    @Output() panelOpenedChange = new EventEmitter<boolean>();

    @ContentChild('extraInfos', { static: false }) extraInfos: TemplateRef<any>;

    public searchTerm?: string;
    public currentTab?: ITabItem<TItem[]>;

    public readonly itemSize = 40;

    public items?: INavigationPanelItem[];
    public totalCount?: number;

    public get searchVisible() {
        return this.totalCount >= 10;
    }

    public get addButtonVisible() {
        return !this.params.hideAddButton;
    }

    public get tabsHeaderData(): ITabsHeaderData<TItem[]> {
        return {
            tabItems: this.params?.tabs ?? [],
            isTabItemActive: (tabItem) =>
                this.currentTab.tabId == tabItem.tabId,
        };
    }

    public get itemActions() {
        return this.params?.itemActions ?? [];
    }
    public get currentItemActions() {
        return this.params?.currentItemActions ?? [];
    }

    public get titleButtonText() {
        return (
            this.params?.titleButtonText ??
            (this.params?.titleButtonTextKey &&
                this.translate.instant(this.params.titleButtonTextKey)) ??
            (this.params?.currentItem &&
                this.params.getName(this.params.currentItem)) ??
            this.params.noItemText ??
            this.translate.instant('UI.Global.btn.Open')
        );
    }

    public get titleButtonTooltipText() {
        const key = this.params?.titleButtonTooltipKey;
        return key && this.translate.instant(key);
    }

    public get addButtonTooltip() {
        const key = this.params?.addButtonTooltipKey;
        return key && this.translate.instant(key);
    }

    public get currentItemId() {
        if (!this.currentItem) {
            return;
        }
        return this.params.getId(this.currentItem);
    }
    public get currentItem() {
        return this.params?.currentItem;
    }

    public get userRenderer() {
        return this.params?.userRenderer;
    }

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.init();
    }
    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'params', () => this.init());
    }

    public setCurrentTab(tab: ITabItem<TItem[]>) {
        this.currentTab = tab;
        this.createCurrentTabItems();
    }

    public trackByItemId(_index: number, item: INavigationPanelItem) {
        return item.id;
    }
    public createCurrentTabItems() {
        if (!this.currentTab) {
            this.items = [];
            return;
        }
        let data: INavigationPanelItem[] = this.currentTab.data.map((item) => ({
            id: this.params.getId(item),
            name: this.params.getName(item),
            glyphClass: this.params.getGlyphClass?.(item),
            tooltipText: this.params.getTooltipText?.(item),
            userRendererParams: this.params.getUserRendererParams?.(item),
            lastModificationDate: this.params.getLastModificationDate?.(item),
            data: item,
        }));
        this.totalCount = data.length;
        const searchTerm = this.searchVisible ? this.searchTerm : undefined;
        data = StringUtil.filterSearched(searchTerm, data, (data) => data.name);
        this.items = data;
    }

    public onItemClick(item: INavigationPanelItem<TItem>) {
        this.itemClick.emit(item.data);
    }

    public onAddClick() {
        this.addButtonClick.emit();
    }

    public togglePanel() {
        this.panelOpened = !this.panelOpened;
        this.panelOpenedChange.emit(this.panelOpened);
    }

    private init() {
        const tabs = this.params?.tabs ?? [];
        const defaultTab =
            tabs.find((t) => t.tabId == this.currentTab?.tabId) ??
            tabs.find((t) => t.data.includes(this.params.currentItem)) ??
            tabs[0];
        this.setCurrentTab(defaultTab);
    }
}

export interface INavigationPanelParams<TItem = any, TId = any> {
    tabs: ITabItem<TItem[]>[];
    getId: (item: TItem) => TId;
    getName: (item: TItem) => string;
    getGlyphClass?: (item: TItem) => string;
    getTooltipText?: (item: TItem) => string;
    getUserRendererParams?: (item: TItem) => ICellParams;
    getLastModificationDate?: (item: TItem) => moment.MomentInput;
    noItemText?: string;
    currentItemActions?: IActionOption<TItem>[];
    itemActions?: IActionOption<TItem>[];
    currentItem?: TItem;
    titleButtonText?: string;
    titleButtonTextKey?: string;
    titleButtonTooltipKey?: string;
    addButtonTooltipKey?: string;
    userRenderer?: TCellRenderer;
    hideAddButton?: boolean;
}

interface INavigationPanelItem<TItem = any, TId = any> {
    id?: TId;
    name: string;
    glyphClass?: string;
    tooltipText?: string;
    userRendererParams?: ICellParams;
    data?: TItem;
    lastModificationDate?: moment.MomentInput;
}
