<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" [translate]="modalTitleKey"></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content [class.modal-disable]="creationLoading">
        <dxy-field-text
            name="displayName"
            labelKey="UI.Dialog.NewItem.Diagram.lblInstructions"
            [(ngModel)]="displayName"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
            [multiLine]="false"
        ></dxy-field-text>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="actionBtnLblKey | translate"
        [actionLoading]="creationLoading"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
    ></dxy-modal-footer>
</form>
