export const talendCloudFieldInfo = {
    url: {
        hint: {
            fr: "URL de l'application Talend Cloud Data Inventory, exemple : https://tdc.at.cloud.talend.com/",
            en: 'Talend Cloud Data Inventory application URL, example: https://tdc.at.cloud.talend.com/',
        },
    },
    personalAccessToken: {
        hint: {
            fr: 'Identifiant du token généré dans Talend Cloud',
            en: 'Talend Cloud token Identifier',
        },
    },
};
