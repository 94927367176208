<dxy-field-select
    #field
    [(ngModel)]="currentOption"
    [adapter]="adapter"
    [options]="options"
    [readonly]="readonly"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    (openClose)="onPanelOpenClose.emit($event)"
    [search]="options?.length > 9"
    [logId]="logId"
    [class.mini]="mini"
></dxy-field-select>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>

<app-attribute-suggestion-list
    *ngIf="showSuggestions && suggestionGroup$ | async as suggestionGroup"
    [serverType]="serverType"
    [suggestionGroup]="suggestionGroup"
    [attributeInfo]="attributeInfo"
    [isMultiValue]="true"
></app-attribute-suggestion-list>
