<img
    src="/images/glossary-generation/glossary-generation-placeholder-choice.svg"
/>
<p class="text" translate>UI.Suggestions.Panel.Glossary.text</p>
<button
    mat-flat-button
    (click)="openGlossaryGenerationModal()"
    dxyLogFunctional="GLOSSARY_GENERATION_FROM_SUGGESTIONS_CENTER,R"
>
    <i class="glyph-metabot-glossary"></i>
    <span translate>UI.Glossary.import.generateFromSource</span>
</button>
