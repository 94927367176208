export class ConnectorCredentials {
    constructor(
        public connection?: object,
        public output?: object,
        public transform?: object,
        public context?: any
    ) {}
}

export interface ISaveConnectionParams {
    credentials: ConnectorCredentials;
    entityId?: string;
    versionId: string;
    spaceId: string;
}

/** string enum */
export enum VersionStatus {
    RECOMMENDED = 'RECOMMENDED',
    BETA = 'BETA',
    RELEASE = 'RELEASE',
}

export type UpsertSchedulingParams = {
    hasScheduling: boolean;
    connectionId: string;
    cron: string;
    suspend?: boolean;
    timezone?: string;
};
