import { CoreUtil } from '@datagalaxy/core-util';
import {
    ITypedEvent,
    deserializeTypedEvent,
} from '@datagalaxy/dg-object-model';
import * as moment from 'moment';

export class UserNotification implements IUserNotification {
    public Guid = '';
    public Event?: ITypedEvent;
    public AcknowledgementTime?: string;
    public ReadTime?: string;
    public CreationTime?: string;
    public IsUserMention?: boolean;
    public MentionedTeamGuid?: string;

    public get CreationTimeFromNow() {
        return moment(this.CreationTime).fromNow();
    }
    public get IsAcknowledged() {
        return !!this.AcknowledgementTime;
    }
    public get IsMention() {
        return this.IsUserMention || !!this.MentionedTeamGuid;
    }

    constructor(un: IUserNotification | undefined = undefined) {
        if (!un) {
            return;
        }
        CoreUtil.assign(this, un);
        if (!this.Event) {
            return;
        }
        this.Event = deserializeTypedEvent(this.Event) as ITypedEvent;
    }
}

export interface IUserNotification {
    Guid: string;
    Event?: ITypedEvent;
    AcknowledgementTime?: string;
    ReadTime?: string;
    CreationTime?: string;
    IsUserMention?: boolean;
    MentionedTeamGuid?: string;
}
