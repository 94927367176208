import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    ViewChild,
} from '@angular/core';
import { DxyNavBreadcrumbComponent } from '../../../../navigation/dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import { HierarchicalData } from '@datagalaxy/dg-object-model';

/**
 * ## Role
 * Display entity path as a breadcrumb with clickable links
 */
@Component({
    standalone: true,
    selector: 'app-dg-entity-path-cell',
    templateUrl: 'dg-entity-path-cell.component.html',
    styleUrls: ['./dg-entity-path-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [forwardRef(() => DxyNavBreadcrumbComponent)],
})
export class DgEntityPathCellComponent {
    @Input() hddData?: HierarchicalData;
    @Input() dtContext?: string;

    @ViewChild(DxyNavBreadcrumbComponent)
    navBreadcrumb: DxyNavBreadcrumbComponent;

    public refreshLayout() {
        this.navBreadcrumb?.refresh();
    }
}
