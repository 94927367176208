<div class="card">
    <span class="title" [translate]="titleKey"></span>
    <div class="csv-wrapper">
        <img
            dxyFileDrop
            [(droppedFiles)]="files"
            (droppedFilesChange)="handleFiles()"
            src="/images/import/csv_import.svg"
            alt=""
        />
    </div>
    <button mat-flat-button (click)="onClickFlatBtn()">
        <span translate
            >Import.GenericImportWizard.SolutionSelection.CsvCardBtn</span
        >
    </button>
    <input
        #fileInputRef
        type="file"
        id="fileInput"
        [accept]="mimeAcceptString"
        dxyInputFileChange
        (filesChange)="onFilesSelected($event)"
        multiple
    />
    <div
        *ngIf="showInfoBtn"
        (click)="onClickInfoBtn()"
        class="glyph-info"
    ></div>
</div>
