import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Display an html link from an entity attribute in an omnigrid
 */
@Component({
    standalone: true,
    selector: 'app-dg-attribute-html-link-model-cell',
    templateUrl: 'dg-attribute-html-link-model-cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf],
})
export class DgAttributeHtmlLinkModelCellComponent {
    @Input() href?: string;
    @Input() text?: string;
}
