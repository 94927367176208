<div *ngIf="!mini" class="time-series-container">
    <div class="header">
        <div class="time-series-label">
            <span class="text" [matTooltip]="description">{{ labelName }}</span>
            <div class="time-series-edit">
                <button
                    mat-icon-button
                    (click)="openEditModal()"
                    [matTooltip]="
                        'UI.AttributeTimeSeriesInput.seeValues' | translate
                    "
                    class="dg5-small-icon-button"
                >
                    <i class="glyph-edit"></i>
                </button>
            </div>
        </div>
        <dxy-trend-pill
            *ngIf="hasValues"
            [trend]="trend"
            [colorRule]="colorRule"
        ></dxy-trend-pill>
        <div class="time-series-last-value">{{ lastEntryValue }}</div>
    </div>

    <!-- noedit -->
    <div class="body">
        <div *ngIf="!hasValues" translate>
            UI.AttributeTimeSeriesInput.empty
        </div>
        <div *ngIf="hasValues" class="time-series-graph"></div>
    </div>
</div>

<div *ngIf="mini" class="dxy-field mini" [class.readonly]="readonly">
    <label
        class="time-series-label"
        [dxyEllipsisTooltip]="description"
        dxyEllipsisMode="alwaysVisible"
    >
        {{ labelText }}
    </label>
    <div class="time-series-mini-container">
        <div class="trend-pill-container">
            <dxy-trend-pill
                *ngIf="hasValues"
                [trend]="trend"
                [colorRule]="colorRule"
            ></dxy-trend-pill>
            <div *ngIf="hasValues" class="time-series-last-value">
                {{ lastEntryValue }}
            </div>
            <ng-container *ngIf="!hasValues">-</ng-container>
        </div>

        <button
            mat-icon-button
            (click)="openEditModal()"
            [matTooltip]="'UI.AttributeTimeSeriesInput.seeValues' | translate"
            class="dg5-small-icon-button"
        >
            <i class="glyph-edit"></i>
        </button>
    </div>
</div>
