import { Injectable } from '@angular/core';
import { CoreUtil, DebugUtil } from '@datagalaxy/core-util';

/**
 * @deprecated The class should not be used, you can use LogService instead
 * Base class for a service. Provides:
 * - logging methods
 * - registering of service for logging activation/deactivation from browser console (see DebugUtil) */
@Injectable()
export class BaseService {
    private _debug: boolean;

    public get debug() {
        return this._debug;
    }
    /** set to true to activate logging to console */
    public set debug(value: boolean) {
        this._debug = value;
    }

    public constructor() {
        // comment this line to completely deactivate the logging activation/deactivation available from browser console
        // for all services extending this base class
        if (DebugUtil.registerService(this)?.logging) {
            this._debug = true;
        }

        if (!CoreUtil.isProduction && CoreUtil.isDebuggingBoot()) {
            console.log(
                this.constructor.name,
                'base-ctor',
                Zone.current?.name,
                Zone.currentTask?.source
            );
        }
    }

    /** executes console.warn when not in production environment */
    protected warn(...args: any[]) {
        if (!CoreUtil.isProduction) {
            console.warn(...args);
        }
    }

    //#region logging
    protected get logger() {
        return (...args: any[]) => this.log(...args);
    }
    protected getLogger(logId: string) {
        return (...args: any[]) => this.log(logId, ...args);
    }

    /** Logs the given arguments to console if debug is true, with this object's constructor name.
     * A good practice is to pass the calling method's name as first argument.
     * Please avoid heavy computation on arguments, to keep the call fast when logging is off */
    protected log(...args: any[]) {
        if (this._debug) {
            console.log(this.constructor.name, ...args);
        }
    }
    protected logTrace(...args: any[]) {
        if (this._debug) {
            console.trace(this.constructor.name, ...args);
        }
    }
    protected logData(title: string, data: object) {
        if (!this.debug) {
            return;
        }
        if (typeof data == 'object') {
            this.log(
                title,
                ...Object.keys(data)
                    .map((k) => [`\n\t${k}:`, data[k]])
                    .reduce((p, c) => p.concat(c), [])
            );
        } else {
            this.log(title, data);
        }
    }
    protected logZone(...args: any[]) {
        this.log(
            `(zone: ${Zone.current?.name}, source: ${Zone.currentTask?.source})`,
            ...args
        );
    }
    //#endregion
}
