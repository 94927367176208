import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { InactivityLogoutService } from './inactivity-logout-service';

/**
 * ## Role
 * Reset the inactivity timer for each requests
 */
@Injectable()
export class InactivityTimerInterceptor implements HttpInterceptor {
    constructor(private inactivityLogoutService: InactivityLogoutService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.inactivityLogoutService.handleNewRequest();
        return next.handle(req);
    }
}
