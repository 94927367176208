<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="UI.Campaign.Form.Title.Create"
    ></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            labelKey="UI.Campaign.Form.Name"
            formControlName="name"
            [mandatory]="true"
            [hintBeforeControl]="true"
            [hint]="'UI.Campaign.Form.NameHint' | translate"
            [errorMessageText]="campaignAlreadyExistsErrorMessage"
            [maxLength]="255"
        ></dxy-field-text>

        <dxy-rich-text-field
            labelKey="UI.Campaign.Form.Description"
            formControlName="description"
            toolbarDisplayMode="focus"
            [mandatory]="true"
            [noTabCapture]="true"
            [hintBeforeControl]="true"
            [hint]="'UI.Campaign.Form.DescriptionHint' | translate"
        ></dxy-rich-text-field>

        <dxy-field-select
            *ngIf="workflows$ | async as workflows; else spinner"
            labelKey="UI.Campaign.Form.Workflow"
            formControlName="workflow"
            [hintBeforeControl]="true"
            [htmlHint]="'UI.Campaign.Form.WorkflowHint' | translate"
            [options]="workflows"
            [adapter]="workflowAdapter"
            [mandatory]="true"
        ></dxy-field-select>
        <ng-template #spinner>
            <dxy-spinner></dxy-spinner>
        </ng-template>

        <dxy-field-select
            *ngIf="!disableOwner"
            labelKey="UI.Campaign.Form.Owner"
            formControlName="owner"
            [options]="owners"
            [adapter]="ownerAdapter"
            [mandatory]="true"
        ></dxy-field-select>
    </div>

    <dxy-modal-footer
        [actionEnabled]="formGroup.valid"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblCreate' | translate"
        [actionLoading]="loading$ | async"
        (onCloseCancel)="onCloseCancel()"
        featureCode="CAMPAIGN,C"
    ></dxy-modal-footer>
</form>
