<div class="modal-header">
    <h3 mat-dialog-title class="modal-title">{{ titleText }}</h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>

<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            labelKey="UI.PublicEditModal.displayName"
            [(ngModel)]="displayName"
            (ngModelChange)="updateCanCreate()"
            name="displayName"
            required
        ></dxy-field-text>
        <dxy-field-text
            labelKey="UI.PublicEditModal.description"
            [(ngModel)]="description"
            [multiLine]="true"
            (ngModelChange)="updateCanCreate()"
            name="description"
        ></dxy-field-text>
        <div *ngIf="areRadioBtnsVisible" class="radio-form">
            <mat-radio-group
                [(ngModel)]="isPrivate"
                (ngModelChange)="updateCanCreate()"
                name="is-private"
            >
                <mat-radio-button [value]="true">
                    <span translate>UI.PublicEditModal.private</span>
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    <span translate>UI.PublicEditModal.public</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="actionBtnLblKey | translate"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
        [featureCodeSecond]="featureCodeSecond"
    >
    </dxy-modal-footer>
</form>
