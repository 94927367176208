import {
    GlossaryGenerationAbortedEvent,
    GlossaryGenerationEndedEvent,
} from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [
    GlossaryGenerationAbortedEvent,
    GlossaryGenerationEndedEvent,
];
export type TGlossaryGenerationEvent = InstanceType<
    (typeof eventTypes)[number]
>;

/**
 * ## Role
 * Adapter to get glossary generation events translation inputs
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class GlossaryGenerationEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TGlossaryGenerationEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);

        if (this.event instanceof GlossaryGenerationEndedEvent) {
            const targetUniverseHdd = this.event.TargetUniverseHdd;
            inputs.translateParameters.push({
                key: 'objectCount',
                value: this.event.EntityCreatedCount,
            });
            inputs.translateParameters.push({
                key: 'linkCount',
                value: this.event.LinkCreatedCount,
            });
            inputs.translateParameters.push({
                key: 'universeName',
                value: this.event.TargetUniverseHdd?.DisplayName,
            });
            if (targetUniverseHdd) {
                inputs.navLinks.push(this.getEntityNavLink(targetUniverseHdd));
            }
        }
        return inputs;
    }
}
