import { AttributeDataService } from '../attribute/attribute-data.service';
import { UiOptionSelectDataType } from '@datagalaxy/core-ui';
import { IOptionAdapter } from '@datagalaxy/core-ui';
import { ICellRenderData } from '@datagalaxy/core-ui/cell-components';
import { EntityCardCellComponent } from '../entityCard/entity-card/entity-card-cell.component';
import { IEntityCardCellParams } from '../entityCard/entity-card/entity-card-cell.types';
import { DataUtil } from '../util/DataUtil';
import { GlyphUtil } from '../util/GlyphUtil';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import {
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export function getUiMultiSelectDataType(attributeType: AttributeMetaType) {
    if (AttributeDataService.isUserOrPersonAttribute(attributeType)) {
        return UiOptionSelectDataType.user;
    }
    if (AttributeDataService.isTagAttribute(attributeType)) {
        return UiOptionSelectDataType.tag;
    }
    if (AttributeDataService.isEntityRefAttribute(attributeType)) {
        return UiOptionSelectDataType.entityReference;
    }
    if (AttributeDataService.isIconAttribute(attributeType)) {
        return UiOptionSelectDataType.icon;
    }
    return UiOptionSelectDataType.other;
}

export class MultiSelectAdapter {
    public static get dgModule(): IOptionAdapter<DgModule> {
        return {
            getId: (o) => DgModule[o],
            getTextKey: (o) => DataUtil.getModuleTranslateKey(o),
            getGlyphClass: (o) => GlyphUtil.getModuleColoredGlyphClass(o),
        };
    }

    public static get userPublicData(): IOptionAdapter<UserPublicData> {
        return {
            getId: (o) => o.ReferenceId,
            getRenderData: (o) =>
                MultiSelectAdapter.getUserOrPersonRenderData(o.UserId),
            getText: (o) => o.FullName,
        };
    }

    public static attributeMetaValue(
        attributeType: AttributeMetaType,
        getEntityCardParams: (data: IHasHddData) => IEntityCardCellParams
    ): IOptionAdapter<AttributeMetaValue> {
        return {
            getId: (amv) => amv.Key,
            getText: (amv) => amv.translatedDisplayName,
            getGlyphClass: (amv) => amv.glyphClass,
            getTagColor: (amv) =>
                AttributeDataService.isUserOrPersonAttribute(attributeType)
                    ? undefined
                    : amv.Color,
            getRenderData: (amv) =>
                MultiSelectAdapter.getAttributeMetaValueRenderData(
                    attributeType,
                    amv,
                    getEntityCardParams
                ),
            getTooltipText: (amv) => amv.translatedDescription,
            getStyle: (amv) => {
                const isHierarchical =
                    attributeType === AttributeMetaType.Hierarchy;
                const marginLeft = (amv?.getParents?.()?.length || 0) * 20;
                return isHierarchical
                    ? { marginLeft: `${marginLeft}px` }
                    : null;
            },
        };
    }

    public static getUserOrPersonRenderData(userId: string): ICellRenderData {
        return {
            renderer: UserCellComponent,
            param: { value: { userId } },
        };
    }

    public static getEntityRenderData(
        data: IHasHddData,
        getEntityCardParams: (data: IHasHddData) => IEntityCardCellParams
    ) {
        return {
            renderer: EntityCardCellComponent,
            param: getEntityCardParams(data),
        };
    }

    private static getAttributeMetaValueRenderData(
        attributeType: AttributeMetaType,
        amv: AttributeMetaValue,
        getEntityCardParams: (data: IHasHddData) => IEntityCardCellParams
    ): ICellRenderData {
        if (AttributeDataService.isEntityRefAttribute(attributeType)) {
            return {
                renderer: EntityCardCellComponent,
                param: getEntityCardParams(amv),
            };
        } else if (
            AttributeDataService.isUserOrPersonAttribute(attributeType)
        ) {
            return MultiSelectAdapter.getUserOrPersonRenderData(amv.Key);
        }
        return undefined;
    }
}
