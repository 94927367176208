<div class="suggestion-infos" [class.readonly]="acceptedIndicatorVisible">
    <span
        *ngIf="showGroupDisplayName"
        class="attribute-name"
        [matTooltip]="suggestion.titleTooltip"
    >
        {{ suggestion.title }}
    </span>
    <div class="suggestion-value">
        <dxy-option-item
            [option]="suggestion"
            [isMini]="true"
        ></dxy-option-item>
        <span
            class="suggestion-score dg5-badge"
            [matTooltip]="'UI.Suggestions.scoreTT' | translate"
        >
            {{ score | percent }}
        </span>
    </div>
</div>
<div [class.hidden]="!actionsVisible" class="suggestion-actions">
    <app-suggestion-choice-actions
        [suggestion]="suggestion"
        [featureCodePrefix]="featureCodePrefix"
    ></app-suggestion-choice-actions>
</div>
<div *ngIf="acceptedIndicatorVisible" class="accepted-indicator">
    <i [ngClass]="accepted ? 'glyph-check' : 'glyph-cancelsearch'"></i>
</div>
