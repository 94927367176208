<dxy-collapsible [titleKey]="titleKey" class="reflex-shadow big-header">
    <div class="table-header">
        <div class="title-container">
            {{ synonymsCountMsg }}
        </div>
        <dxy-dropdown-button
            *ngIf="hasWriteAccess"
            type="add"
            tooltipTranslateKey="UI.Synonyms.Create.CreateSynonymToolTip"
            [actions]="addButtonActions"
        ></dxy-dropdown-button>
    </div>
    <dxy-grid
        *ngIf="hasSynonyms"
        [config]="gridConfig"
        [columns]="cols"
        [items]="synonyms"
    ></dxy-grid>
</dxy-collapsible>
