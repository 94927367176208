import {
    BaseLinkDataInfo,
    DataIdentifier,
    HierarchicalData,
    IHasHddData,
    IHierarchicalData,
    ObjectLinkType,
} from '@datagalaxy/dg-object-model';
import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';

@inheritSerialization(DataIdentifier)
export class EntityLinkItem extends DataIdentifier {
    @deserializeAs(DataIdentifier) Source!: DataIdentifier;
    @deserializeAs(DataIdentifier) Target!: DataIdentifier;
    @deserializeAs(DataIdentifier) EntityLinkType!: DataIdentifier;
    @deserializeAs(ObjectLinkType) Kind!: ObjectLinkType;
    @deserialize IsGoldenLink!: boolean;
}

export class LinkedDataItem implements IHierarchicalData, IHasHddData {
    @deserializeAs(HierarchicalData) public LinkedData: HierarchicalData;
    // If Link is materialized by an EntityLink, this is the EntityLink object with its DataReferenceId
    @deserializeAs(EntityLinkItem) public LinkEntityData: EntityLinkItem;

    constructor(linkedData: HierarchicalData, linkEntityData: EntityLinkItem) {
        this.LinkedData = linkedData;
        this.LinkEntityData = linkEntityData;
    }

    public get DataReferenceId() {
        return this.LinkedData.DataReferenceId;
    }

    public get DataTypeName() {
        return this.LinkedData.DataTypeName;
    }

    public get DataServerType() {
        return this.LinkedData.DataServerType;
    }

    public get EntityType() {
        return this.LinkedData.EntityType;
    }

    public get VersionId() {
        return this.LinkedData.VersionId;
    }

    public get TechnologyCode() {
        return this.LinkedData.TechnologyCode;
    }

    public get HddData() {
        return this.LinkedData;
    }

    public get Parents() {
        return this.HddData.Parents;
    }

    public get Data() {
        return this.HddData.Data;
    }

    public get entityType() {
        return this.LinkedData.EntityType;
    }

    public get ReferenceId() {
        return this.LinkedData.DataReferenceId;
    }

    public get ServerType() {
        return this.LinkedData.DataServerType;
    }

    public setVersionId(versionId: string) {
        this.LinkedData.setVersionId(versionId);
    }
}

@inheritSerialization(BaseLinkDataInfo)
export class LinkedDataGroup extends BaseLinkDataInfo {
    @deserializeAs(LinkedDataItem) public Items!: LinkedDataItem[];
}

export interface GoldenLinkDto {
    sourceGuid: string;
    targetGuid: string;
}

export interface GetPropertyGoldenLinksResult {
    goldenLinks: GoldenLinkDto[];
}
