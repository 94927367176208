<div class="gridbox-list-field">
    <dxy-grid
        [config]="gridConfig"
        [columns]="cols"
        [items]="rows$ | async"
    ></dxy-grid>
    <button
        dxyButton
        color="secondary"
        size="small"
        class="add-btn"
        (click)="onClickOpen.emit()"
        dxyDataTestId="add-data-to-list-button"
    >
        <span class="glyph-add"></span>
        <span translate>{{ addBtnTranslateKey }}</span>
    </button>
</div>
