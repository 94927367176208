import { IQuillContent } from './editor/rich-text-editor-quill.types';
import { IRichTextContentAdapter } from './editor/rich-text-editor.types';
import { RichTextContent } from './RichTextContent';

/** Adapter for RichTextEditor and DxyRichTextFieldControl (which use Quill editor) to use RichTextContent)
 *
 * Note that it does not implement mention management. */
export class SimpleQuillContentAdapter implements IRichTextContentAdapter {
    deserialize(rawTextOrJsonString: string): IQuillContent {
        return RichTextContent.fromJsonOrRawText(rawTextOrJsonString);
    }
    serialize(doc: IQuillContent): string {
        return doc instanceof RichTextContent ? doc.toJsonString() : undefined;
    }
    isEmpty(doc: IQuillContent): boolean {
        return doc instanceof RichTextContent
            ? doc.isEmpty()
            : doc == null
            ? true
            : undefined;
    }
}
