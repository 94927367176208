import { deserialize, inheritSerialization, serialize } from 'cerialize';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { IXYRect } from '@datagalaxy/core-2d-util';
import { ABaseDiagramPositionDto, ABaseDiagramPositionItem } from '../diagram';

export enum DiagramNodeKind {
    Unknown = 0,
    Entity,
    Note,
    Frame,
}

@inheritSerialization(ABaseDiagramPositionDto)
export class DiagramNodeDto extends ABaseDiagramPositionDto {
    @deserialize NodeKind!: DiagramNodeKind; // deserialized as number

    @deserialize Text?: string;
    @deserialize Description?: string;
    @deserialize VisualData?: string;
    /** Only for when NodeKind is DiagramNodeKind.Entity */
    @deserialize EntityReferenceId?: string;

    public entityHierarchicalData?: HierarchicalData;
    public hasNoAccess? = false;

    /** Only for when NodeKind is DiagramNodeKind.Entity */
    public get HddData() {
        return this.entityHierarchicalData;
    }

    /** Only for when NodeKind is DiagramNodeKind.Entity */
    public get EntityType() {
        return this.entityHierarchicalData?.EntityType;
    }

    public get DisplayName() {
        return this.Text;
    }

    constructor(
        nodeKind: DiagramNodeKind,
        referenceId: string,
        entityHierarchicalData: HierarchicalData,
        rect?: IXYRect,
        text?: string,
        description?: string,
        visualData?: string
    ) {
        super();
        if (nodeKind) {
            this.NodeKind = nodeKind;
        }
        if (referenceId) {
            this.ReferenceId = referenceId;
        }
        if (entityHierarchicalData) {
            this.EntityReferenceId = entityHierarchicalData.ReferenceId;
            this.entityHierarchicalData = entityHierarchicalData;
        }
        if (rect) {
            this.Top = rect.y;
            this.Left = rect.x;
            this.Width = rect.width;
            this.Height = rect.height;
        }
        if (text) {
            this.Text = text;
        }
        if (description) {
            this.Description = description;
        }
        if (visualData) {
            this.VisualData = visualData;
        }
    }
}

@inheritSerialization(ABaseDiagramPositionItem)
export class DiagramNodeItem extends ABaseDiagramPositionItem {
    public static readonly keys: (keyof DiagramNodeItem)[] = [
        ...ABaseDiagramPositionItem.basePositionKeys,
        'NodeKind',
        'Text',
        'Description',
        'EntityReferenceId',
        'VisualData',
    ];

    // this value should not be updated
    @serialize NodeKind?: DiagramNodeKind;

    // Only for when NodeType is DiagramNodeType.Entity
    // For now, we cannot UPDATE this value, only use it on creation
    // To replace the referenced entity, one must delete existing/create new node
    @serialize EntityReferenceId?: string;

    @serialize Text?: string;
    @serialize Description?: string;

    @serialize VisualData?: string;
}
