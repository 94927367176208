<a
    class="nav-item-container"
    (click)="onClick()"
    [dxyLogFunctional]="logModule"
    [matTooltip]="matTooltipContent | translate"
    matTooltipClass="no-vmargins"
    matTooltipPosition="right"
    [attr.data-dtname]="dtNameFull"
    [class.active]="isActive$ | async"
    [dxyDataTestId]="itemIdentifier + '-navbar-item'"
    #matMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menuOptions ? menu : null"
>
    <i
        *ngIf="!isGlyphUndefined"
        class="icon sidebar-icon"
        [ngClass]="glyphClass"
        [attr.data-dtname]="dtNameFull"
    ></i>
    <span class="caption">{{ translateKey | translate }}</span>
</a>

<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu no-max-width {{ menuClass }}"
    xPosition="after"
>
    <ng-container *ngIf="matMenuTrigger.menuOpen">
        <dxy-burger-menu [options]="menuOptions"></dxy-burger-menu>
    </ng-container>
</mat-menu>
