<div
    class="dg5-match-tag"
    dxyDroppableAttribute
    [drop]="onDroppedFunction"
    [draggable]="draggable"
    [dragData]="csvFieldName"
    (dragEnd)="onDragEnd($event)"
>
    <div
        class="match-tag-container"
        [class.to-match]="!matched"
        [class.mandatory]="mandatory"
        [matTooltip]="getDescriptionTooltip()"
    >
        <span class="match-tag-icon" [ngClass]="glyphClass"></span>
        <div class="match-tag-group">
            <span class="match-tag-label">{{ attributeTitle }}</span>
            <span
                class="match-tag-text"
                [matTooltip]="matched ? csvFieldName : ''"
            >
                {{ matched ? csvFieldName : attributeTitle }}
            </span>
        </div>
    </div>
    <div
        *ngIf="mandatoryVisible"
        class="attribute-match-mandatory-text"
        translate
    >
        Import.Wizard.MatchFields.MandatoryField
    </div>
</div>
