export class CsvParseOptions {
    /* fields delimiter */
    public delimiter = ''; // empty string is for auto-detection

    /* line separator */
    public newline = ''; //"\r\n"  empty string is for auto-detection

    /* text delimiter */
    public quoteChar = '"';

    /* file encoding */
    public encoding = 'windows-1252';

    public skipAutoDetectEncoding = false;

    public thousandsSep = '';
    public decimalSep = '.';
    public dateFormat = 'DD/MM/YYYY';

    public toString() {
        return (
            '' +
            'delimiter ' +
            this.delimiter +
            ', newlineText ' +
            this.newline.replace('\r', '\\r').replace('\n', '\\n') +
            ', quoteChar ' +
            this.quoteChar +
            ', encoding ' +
            this.encoding +
            ', thousandsSep ' +
            this.thousandsSep +
            ', decimalSep ' +
            this.decimalSep +
            ', dateFormat ' +
            this.dateFormat
        );
    }
}
