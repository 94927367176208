import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Side panel displayed over graphical surface
 */
@Component({
    selector: 'dxy-graphical-side-panel',
    templateUrl: 'graphical-side-panel.component.html',
    styleUrls: ['graphical-side-panel.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule, MatLegacyButtonModule],
})
export class DxyGraphicalSidePanelComponent {
    @Input() noHeader?: boolean;
    @Input() buttonTextKey?: string;
    @Output() readonly close = new EventEmitter<void>();
}
