export interface IBaseField {
    /** label text to be translated */
    labelKey?: string;
    /** label displayed above the field (overwrites labelKey) */
    labelText?: string;
    /** label tooltip text to be translated */
    labelTooltipKey?: string;
    /** label tooltip displayed (overwrites labelTooltipKey) */
    labelTooltipText?: string;
    /** Text that is showed under the input */
    hint?: string;
    /** error message to be displayed under the field, when no translation is needed */
    errorMessageText?: string;
    /** error message text to be translated and displayed under the field */
    errorMessageKey?: string;
    /** when true, the dg5-compact style is applied */
    compact?: boolean;
    /** when true, the field is grayed and not editable */
    disabled?: boolean | string;
    /** when true, the value is displayed but the field is not editable */
    readonly?: boolean | string;
    /** when true, and *readonly* is false, and a label is provided, an asterisk is added to the label.
     * Note: The field is not marked as error when empty. If needed, use *required* instead */
    mandatory?: boolean | string;
    /** when true, an asterisk is added to the label, and the field is marked as error when empty */
    required?: boolean | string;
}

/** NOTE: 524288 is the default html Limit (for chrome) */
export const InputMaxLength = 524288;

export interface INgModelOptions {
    name?: string;
    standalone?: boolean;
    /** Default is 'change' */
    updateOn?: 'change' | 'blur' | 'submit';
}
