import { Observable } from 'rxjs';
import { IEntityLinksChangeInfo } from '../services/entity.service';
import {
    ComparatorItemClient,
    VersionCompareContext,
} from '../compare-entity.types';
import { EntityType } from '@datagalaxy/dg-object-model';
import { BaseServiceResult } from '@datagalaxy/data-access';
import { ISuggestionGroup } from '../../../suggestions/suggestion.types';
import { UpdateEntityAttributeResult } from '@datagalaxy/webclient/entity/data-access';
import { IUpdatedAttributeData } from '@datagalaxy/webclient/entity/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaInfo,
    AttributeMetaValue,
    TextQualityUserVoteDto,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export interface IEntityForm<T> {
    kind?: EntityFormKind;
    formEntityUpdated$?: Observable<IUpdatedAttributeData>;
    isEditEnabled?: boolean;
    isLabelDisplayed?: boolean;
    isFieldByFieldValidationNeeded?: boolean;
    asterisk?: boolean;
    // if true, attribute validate() will be triggered only on validate button or enter key
    disableValidationOnBlur?: boolean;
    // if true, undo btn will remain hidden
    disableUndoBtn?: boolean;
    isSuggestionsDisabled?: boolean;

    getAttributeValue?(attributeKey: string): T;
    setAttributeValue?(attributeKey: string, newValue: T): Promise<void>;

    getSuggestionGroup$?(attributeKey: string): Observable<ISuggestionGroup>;

    getTextQualityUserVote?(attributePath: string): TextQualityUserVoteDto;

    getSpaceIdr?(): ISpaceIdentifier;
    getModule?(): DgModule;
    getEntityData?(): EntityItem;
    getEntityDataList?(): EntityItem[];
    setFormValid?(valid: boolean, checkControl?: boolean): void;
    setCurrentAttributeKey?(attributeKey: string): void;
    isAttributeEditEnabled?(attributeMeta: AttributeMetaInfo): boolean;

    setTextQualityUserVote?(
        attributePath: string,
        textQualityUserVote: TextQualityVoteStatus
    ): void;

    /** typical usage is to update the value on the server */
    onChangeAttributeValue?(
        attributeKey: string,
        linkChangeInfo?: IEntityLinksChangeInfo
    ): Promise<UpdateEntityAttributeResult | BaseServiceResult>;

    getExternalError?(attributeKey: string): string;

    loadReferenceOptions?(
        attributeMeta: AttributeMetaInfo
    ): Promise<AttributeMetaValue[]>;

    //#region EntityFormKind.spaceGov
    onChangeSpaceGovUserValue?(
        attributeKey: string,
        addUserList: string[],
        deleteUserList: string[],
        defaultUserId: string
    ): Promise<BaseServiceResult>;
    //#endregion

    //#region EntityFormKind.compare
    comparatorItemsValues?: ComparatorItemClient[];
    versionCompareContext?: VersionCompareContext;
    getAttributeValueForVersionCompare?(
        attributeKey: string,
        role?: string,
        wantsObjectData?: boolean
    ): string;
    undo?(attributeMeta: AttributeMetaInfo): void;
    restorePreviousValue?(attributeMeta: AttributeMetaInfo): void;
    //#endregion

    //#region logical-parent-input
    getLocalEntityType?(): EntityType;
    entityTypeChange$?: Observable<void>;
    //#endregion

    /** Emitted when an attribute-input value has been changed externally, and its UI must be updated. Argument is the attributeKey */
    attributeValueLocalChange$?: Observable<string>;
}

export enum EntityFormKind {
    generic = 0,
    bulk,
    compare,
    spaceGov,
}
