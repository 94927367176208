import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'dxy-language-icon',
    standalone: true,
    imports: [CommonModule, MatTooltipModule],
    templateUrl: './language-icon.component.html',
    styleUrls: ['./language-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageIconComponent {
    @Input() tooltip?: string;
    @Input() languageCode?: string;
}
