<div *ngIf="isGroupHeader" class="logElement-title">
    {{ getCreationTimeFromNow() }}
</div>
<div class="log-element-content">
    <dxy-profile-avatar
        *ngIf="activityLogEntry.UserId"
        [userId]="activityLogEntry.UserId"
        [isMiniIcon]="true"
    ></dxy-profile-avatar>
    <dxy-activity-log-item
        [activityLogEntry]="activityLogEntry"
        [disableNavigation]="disableNavigation"
    ></dxy-activity-log-item>
</div>
