<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" [translate]="modalTitle"></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form #form="ngForm">
    <div mat-dialog-content>
        <dxy-field-text
            *ngIf="!isFunctionalOnly"
            labelKey="UI.Modeler.ForeignKeyTechnicalSettingsModal.lblTechnicalName"
            [readonly]="!hasWriteAccess"
            [placeholder]="
                'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblTechnicalName'
                    | translate
            "
            [(ngModel)]="fkTechnicalName"
            name="technicalName"
            [errorMessageKey]="technicalNameErrorKey"
            required
        ></dxy-field-text>
        <dxy-field-text
            [labelKey]="displayNameLabel"
            [readonly]="!hasWriteAccess"
            [placeholder]="displayNamePlaceholder | translate"
            [(ngModel)]="fkDisplayName"
            name="displayName"
            [errorMessageKey]="displayNameErrorKey"
            required
        ></dxy-field-text>
        <dxy-field-text
            [labelKey]="descriptionLabel"
            [readonly]="!hasWriteAccess"
            [placeholder]="descriptionPlaceholder | translate"
            [(ngModel)]="fkDescription"
            [multiLine]="true"
            name="description"
        ></dxy-field-text>
        <dxy-field-boolean
            *ngIf="!isFunctionalOnly"
            labelKey="UI.Modeler.ForeignKeyTechnicalSettingsModal.lblMandatory"
            (ngModelChange)="onUpdateFkMandatory()"
            [readonly]="!hasWriteAccess"
            [(ngModel)]="isFkMandatory"
            name="fk-mandatory"
        ></dxy-field-boolean>
        <div class="structure">
            <label>{{ parentTableLabel | translate }}</label>
            <p class="structure-info">
                <i class="icon primary-icon glyph-table"></i>
                <span>{{ getObjetDisplayName(parentTable) }}</span>
            </p>
        </div>
        <div class="structure">
            <label>{{ childTableLabel | translate }}</label>
            <p class="structure-info">
                <i class="icon primary-icon glyph-table"></i>
                {{ getObjetDisplayName(childTable) }}
            </p>
        </div>
        <dxy-foreign-key-columns-table
            *ngIf="!isFunctionalOnly"
            [hasWriteAccess]="hasWriteAccess"
            [childTable]="childTable"
            [isFkMandatory]="isFkMandatory"
            [fkColumnMappingContainer]="fkColumnMappingContainer"
        ></dxy-foreign-key-columns-table>
    </div>
    <div class="modal-footer">
        <button
            *ngIf="isConvertFkAvailable"
            mat-flat-button
            type="button"
            class="pull-left"
            (click)="onConvertFk()"
        >
            <span translate
                >UI.Modeler.ForeignKeyFunctionalSettingsModal.btnConvertForeignKey</span
            >
        </button>
        <button mat-flat-button name="cancel" (click)="onCloseCancel()">
            <span translate>UI.Global.btnCancel</span>
        </button>
        <button
            mat-flat-button
            color="primary"
            name="submit"
            [disabled]="!canSave || form.invalid"
            (click)="onCloseSubmit()"
            dxyLogFunctional="DIAGRAM_FK_TABLE,U"
        >
            <span translate>UI.Global.btnUpdate</span>
        </button>
    </div>
</form>
