import { Component, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { DiagramDataService } from '../diagram/diagram-data.service';
import { IDiagramPublishModalInputs } from './diagram-publish-modal.types';
import { withLoading } from '@datagalaxy/core-ui';
import { map } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

/**
 * ## Role
 * Diagram publish modal dialog
 */
@Component({
    selector: 'app-diagram-publish-modal',
    templateUrl: './diagram-publish-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class DiagramPublishModalComponent extends DxyBaseModalComponent<
    IDiagramPublishModalInputs,
    boolean
> {
    protected actionEnabled$ = this.loading$.pipe(map((l) => !l));

    constructor(
        private diagramDataService: DiagramDataService,
        dialogRef: MatDialogRef<any, boolean>,
        @Inject(MAT_DIALOG_DATA) data: IDiagramPublishModalInputs
    ) {
        super(dialogRef, data);
    }

    @withLoading()
    public async publishDiagram() {
        await this.diagramDataService.publishDiagram(this.data.entity);
        this.result = true;
        this.onCloseSubmit();
    }
}
