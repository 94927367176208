import { Injectable } from '@angular/core';
import { ModuleService } from './module.service';
import {
    DgModule,
    DgModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { ModelerDataUtil } from '../shared/util/ModelerDataUtil';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { CurrentSpaceService } from '../services/currentSpace.service';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { BaseStateService } from '@datagalaxy/utils';
import { ModuleAccessInfo } from '@datagalaxy/webclient/security/data-access';

@Injectable({ providedIn: 'root' })
export class ModuleStore extends BaseStateService<ModuleStoreState> {
    public get modules(): DgModuleDefinition[] {
        return this.state.modules.map((m) => m.definition);
    }

    constructor(
        private moduleService: ModuleService,
        private entityEventService: EntityEventService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super({
            modules: [],
            loading: false,
        });

        // Subscribe to entity creation events to load accessible modules
        // a space admin, can always create a new dictionary, so if he does not have
        // access to dictionaries, we need to reload the modules (should be managed
        // with a realtime event from backend but not for the moment)
        this.entityEventService.subscribeEntityCreation(null, (event) => {
            if (ModelerDataUtil.isModelerServerType(event.entity.ServerType)) {
                const spaceId = getLocalId(
                    SpaceIdentifier.fromEntity(event.entity).spaceId
                );
                this.loadAccessibleModules(spaceId, event.entity.VersionId);
            }
        });

        this.currentSpaceService.currentSpace$.subscribe((space) => {
            if (space) {
                this.loadAccessibleModules(space.spaceId, space.versionId);
            }
        });
    }

    public selectModules() {
        return this.select((state) => state.modules.map((m) => m.definition));
    }

    public loadAccessibleModules(spaceId: string, versionId: string) {
        this.setState({ loading: true });
        void this.fetchModules(spaceId, versionId);
    }

    public hasAccess(module: DgModule) {
        return this.modules.some((m) => DgModule[m.name] === module);
    }

    private async fetchModules(spaceId: string, versionId: string) {
        const result =
            await this.moduleService.loadCurrentUserAccessibleModules(
                spaceId,
                versionId
            );
        this.setState({ modules: result, loading: false });
    }
}

export interface ModuleInfo {
    access: ModuleAccessInfo;
    definition: DgModuleDefinition;
}

export interface ModuleStoreState {
    modules: ModuleInfo[];
    loading: boolean;
}
