<dxy-field-multi-select
    #field
    [data]="multiSelectData"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [readonly]="readonly"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    (openClose)="onPanelOpenClose.emit($event)"
    [logId]="logId"
    [class.mini]="mini"
>
    <div *ngIf="mini" class="collection-wrapper" selectedValueContent>
        <app-attribute-collection-cell
            *ngIf="collectionWidth$ | async as cw"
            [attributeMeta]="fieldInfo.attributeMeta"
            [values]="collectionCellItems"
            [mini]="true"
            [containerWidth]="cw"
            [enablePopover]="enablePopover"
            [entity]="getEntityData()"
        ></app-attribute-collection-cell>
    </div>
    <div
        *ngIf="canAddNewValue"
        class="menu-item"
        emptyResult
        (click)="onCreateOption()"
    >
        <span class="value">{{ emptySearchTerm }}</span>
        (<span class="new-item" translate>UI.Components.TagsField.newValue</span
        >)
    </div>
</dxy-field-multi-select>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>

<app-attribute-suggestion-list
    *ngIf="showSuggestions && suggestionGroup$ | async as suggestionGroup"
    [serverType]="serverType"
    [suggestionGroup]="suggestionGroup"
    [attributeInfo]="attributeInfo"
    [isMultiValue]="true"
></app-attribute-suggestion-list>
