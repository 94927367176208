import { EventEmitter, Injectable } from '@angular/core';
import {
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityCreationOrigin } from './entity-creation-origin';
import {
    AttributeValueInfo,
    CloneEntityParameter,
    CreateEntityOperation,
    CreateEntityParameter,
    EntityApiService,
    VersionedDataIdentifier,
} from '@datagalaxy/webclient/entity/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityCreatedEvent } from './entity-created-event';
import { EntityMetaService } from '../entity-meta.service';
import { UiSpinnerService } from '@datagalaxy/core-ui';

@Injectable({ providedIn: 'root' })
export class EntityCreator {
    public entityCreatedEvent = new EventEmitter<EntityCreatedEvent>();
    public lastCreatedEntity?: EntityItem;

    constructor(
        private entityApiService: EntityApiService,
        private entityMetaService: EntityMetaService,
        private uiSpinnerService: UiSpinnerService
    ) {}

    public async createEntityFromExisting(
        parentId: string,
        versionId: string,
        existingEntity: IEntityIdentifier,
        opt?: {
            description?: string;
            referenceDataValueCode?: string;
            actionOrigin?: EntityCreationOrigin;
            isMultiCreation?: boolean;
        }
    ) {
        if (existingEntity.entityType === undefined) {
            return;
        }
        const parameter = new CreateEntityParameter(
            parentId,
            versionId,
            existingEntity.entityType,
            null,
            opt?.description,
            opt?.referenceDataValueCode
        );
        parameter.Operation = CreateEntityOperation.CopyFromExisting;
        parameter.ExistingEntityIdentifier = new VersionedDataIdentifier(
            existingEntity.ReferenceId,
            EntityTypeUtil.getServerTypeName(existingEntity.entityType),
            existingEntity.VersionId
        );

        const result = await this.entityApiService.createEntity(parameter);
        await this.entityMetaService.setEntityMeta(
            result.CreatedEntity,
            result.Meta
        );

        this.entityCreatedEvent.emit(
            new EntityCreatedEvent(
                result.CreatedEntity,
                opt?.isMultiCreation,
                opt?.actionOrigin
            )
        );
        this.lastCreatedEntity = result.CreatedEntity;
        return result.CreatedEntity;
    }

    public async createEntity(
        parentId: string,
        versionId: string,
        entityType: EntityType,
        defaultDisplayName: string,
        opt?: {
            description?: string;
            checkParent?: boolean;
            referenceDataValueCode?: string;
            technicalName?: string;
            attributeValues?: AttributeValueInfo[];
            isMultiCreation?: boolean;
            actionOrigin?: EntityCreationOrigin;
        }
    ) {
        const parameter = new CreateEntityParameter(
            parentId,
            versionId,
            entityType,
            defaultDisplayName,
            opt?.description,
            opt?.referenceDataValueCode,
            opt?.technicalName,
            opt?.attributeValues
        );
        parameter.CheckInParentSpace = opt?.checkParent || false;
        const result = await this.entityApiService.createEntity(parameter);
        await this.entityMetaService.setEntityMeta(
            result.CreatedEntity,
            result.Meta
        );

        this.entityCreatedEvent.emit(
            new EntityCreatedEvent(
                result.CreatedEntity,
                opt?.isMultiCreation,
                opt?.actionOrigin
            )
        );
        this.lastCreatedEntity = result.CreatedEntity;
        return result.CreatedEntity;
    }

    public async cloneEntity(
        entityIdr: IEntityIdentifier,
        opt?: {
            actionOrigin?: EntityCreationOrigin;
            displayName?: string;
            technicalName?: string;
        }
    ) {
        const serverType = entityIdr.ServerType;
        const includeLinks = this.shouldIncludeLinksOnClone(serverType);
        const includeChildren = this.shouldIncludeChildrenOnClone(serverType);
        const parameter = new CloneEntityParameter(
            entityIdr.ReferenceId,
            ServerType[serverType],
            includeLinks,
            includeChildren,
            opt?.displayName,
            opt?.technicalName
        );
        const cloneAsync = async () => {
            const result = await this.entityApiService.cloneEntity(parameter);
            await this.entityMetaService.setEntityMeta(
                result.ClonedEntity,
                result.Meta
            );

            this.entityCreatedEvent.emit(
                new EntityCreatedEvent(
                    result.ClonedEntity,
                    false,
                    opt?.actionOrigin
                )
            );
            this.lastCreatedEntity = result.ClonedEntity;
            return result.ClonedEntity;
        };
        return await this.uiSpinnerService.executeWithSpinner(cloneAsync);
    }

    // Note: This logic is 100% arbitrary for now
    private shouldIncludeLinksOnClone(serverType: ServerType) {
        return serverType == ServerType.DataProcessing;
    }

    // Note: This logic is 100% arbitrary for now
    private shouldIncludeChildrenOnClone(serverType: ServerType) {
        return serverType == ServerType.Table;
    }
}
