import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    IImportDataParameterBaseWithSourceCreation,
    IImportDataParameterBaseWithSourceUpdate,
} from '../components/dxy-import-wizard/dxy-import-wizard-modal/dxy-import-wizard-modal.types';
import { ImportContext } from '../shared/ImportContext';
import { ImportModule, ImportModuleCatalog } from '../shared/ImportModule';
import { EOperationType } from '../shared/EOperationType';
import { ModelType } from '@datagalaxy/dg-object-model';
import { ConnectorDownloadApiService } from '@datagalaxy/webclient/connectivity/data-access';
import {
    DataPortApiService,
    GetImportAttributesParameter,
    IImportDataParameterBase,
    ImportDataMapping,
    ImportDataOperation,
    ImportDataOperationParameter,
    ImportDataOperationParameterType,
    ImportDataParameter,
    ImportDataResult,
} from '@datagalaxy/webclient/data-port/data-access';
import * as moment from 'moment';
import { ZipService } from '../../services/zip.service';
import { AppDataService } from '../../services/app-data.service';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class CsvImportApiService extends BaseService {
    constructor(
        private dataPortApiService: DataPortApiService,
        private connectorDownloadApiService: ConnectorDownloadApiService,
        private zipService: ZipService,
        private appDataService: AppDataService
    ) {
        super();
    }

    public async getImportAttributes(
        importOperation: ImportDataOperation,
        spaceIdr: ISpaceIdentifier
    ) {
        const parameter = new GetImportAttributesParameter(
            importOperation,
            spaceIdr?.spaceId
        );
        parameter.setVersionId(spaceIdr?.versionId);
        return this.dataPortApiService.getImportAttributes(parameter);
    }

    public async importData(
        importDataParameterBase: IImportDataParameterBase,
        operationParameters: ImportDataOperationParameter[] = []
    ) {
        const parameter = new ImportDataParameter(
            importDataParameterBase,
            operationParameters
        );
        parameter.setVersionId(importDataParameterBase.spaceIdr?.versionId);
        parameter.UserSessionId = this.appDataService.userSessionId;
        const zippedContent = await this.zipRawContent(
            parameter.ImportRawContent
        );
        parameter.ImportRawContent = null;
        return importDataParameterBase.isPreImport
            ? this.dataPortApiService.preImportData(parameter, zippedContent)
            : this.dataPortApiService.importData(parameter, zippedContent);
    }

    public async importFromContextAndRawContent(
        importContext: ImportContext,
        rawContent: string,
        isPreimport: boolean
    ): Promise<ImportDataResult> {
        const importBaseParam: IImportDataParameterBase = {
            importRawContent: rawContent,
            contentRequestId: importContext.contentRequestId,
            isPreImport: isPreimport,
            attributeId: null,
            importOperation: ImportModule.GetImportDataOperation(
                importContext.currentOperation
            ),
            mappings: importContext.attributeMatches
                .filter((a) => a.isMatched)
                .map(
                    (a) =>
                        new ImportDataMapping(a.attributeName, a.csvFieldName)
                ),
            textFieldSeparator: importContext.csvParseOptions.delimiter,
            encoding: importContext.csvParseOptions.encoding,
            spaceIdr: importContext.spaceIdr,
            entityReferenceId: null,
            attributeKey: null,
            emptyFieldBehavior: importContext.emptyFieldBehavior,
        };

        if (importContext.currentModule instanceof ImportModuleCatalog) {
            if (importContext.currentOperation == EOperationType.Sources) {
                return this.importData(importBaseParam);
            }

            if (importContext.currentModule.isUpdate) {
                const importBaseParamWithSourceUpdate =
                    importBaseParam as IImportDataParameterBaseWithSourceUpdate;
                importBaseParamWithSourceUpdate.modelId =
                    importContext.currentModule.sourceId;
                importBaseParamWithSourceUpdate.createDataTypeIfMissing = true;
                const operationParameters: ImportDataOperationParameter[] = [
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.IsNewSource,
                        '0'
                    ),
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.ExistingSourceId,
                        importBaseParamWithSourceUpdate.modelId
                    ),
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.CreateFieldDataTypeIfMissing,
                        importBaseParamWithSourceUpdate.createDataTypeIfMissing
                            ? '1'
                            : '0'
                    ),
                ];
                return this.importData(
                    importBaseParamWithSourceUpdate,
                    operationParameters
                );
            } else {
                const importBaseParamWithSourceCreation =
                    importBaseParam as IImportDataParameterBaseWithSourceCreation;
                importBaseParamWithSourceCreation.modelName =
                    importContext.currentModule.newSourceName;
                importBaseParamWithSourceCreation.modelType =
                    importContext.currentModule.sourceType;
                importBaseParamWithSourceCreation.createDataTypeIfMissing =
                    true;
                const operationParameters: ImportDataOperationParameter[] = [
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.IsNewSource,
                        '1'
                    ),
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.NewSourceName,
                        importBaseParamWithSourceCreation.modelName
                    ),
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.NewSourceType,
                        ModelType[importBaseParamWithSourceCreation.modelType]
                    ),
                    new ImportDataOperationParameter(
                        ImportDataOperationParameterType.CreateFieldDataTypeIfMissing,
                        importBaseParamWithSourceCreation.createDataTypeIfMissing
                            ? '1'
                            : '0'
                    ),
                ];
                return this.importData(
                    importBaseParamWithSourceCreation,
                    operationParameters
                );
            }
        } else {
            importBaseParam.attributeId = importContext.attribute
                ? importContext.attribute.AttributeUid
                : '';
            importBaseParam.entityReferenceId = importContext.entityReferenceId;
            importBaseParam.attributeKey = importContext.attributeKey;
            return this.importData(importBaseParam);
        }
    }

    private async zipRawContent(rawContent: string): Promise<Blob> {
        const loggerName = 'zipFile';
        const timeString = () => moment(Date.now()).utc().toISOString();
        const timedLog = (s: string) =>
            console.log(`${timeString()} - ${loggerName} - ${s}`);
        timedLog(`Zipping content - Source Size: ${rawContent?.length}...`);

        console.time(loggerName);
        const content = await this.zipService.getZipBlobAsync(
            'rawContent',
            rawContent,
            timedLog
        );
        console.timeEnd(loggerName);

        timedLog(`Zip content created - Size: ${content.size}`);

        return content;
    }
}
