import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { ModelSettings } from './model-settings';
import { Ref } from '../ref';

@inheritSerialization(Ref)
export class ModelSettingsRef extends Ref<ModelSettings> {
    static fromId(id: string) {
        return new ModelSettingsRef(id);
    }

    constructor(id?: string) {
        super(ServerType.ModelSettings, id);
    }
}
