export interface IGroupingFactory<T> {
    getGroupKey: (obj: T) => string;
    getGroupDisplayName?: (groupKey: string) => string;
    getGroupSortIndex?: (groupKey: string) => number;
    getInGroupSortIndex?: (obj: T) => number;
}

export interface IDisplayedDataGroupOf<T extends object>
    extends IDataGroupOf<T> {
    displayName?: string;
    glyphClassName?: string;
}
export interface IDataGroupOf<T extends object> extends IDataGroup {
    objects: T[];
}
export interface IDataGroup {
    key: string;
    objects: object[];
    count: number;
}

export class DataGroup<T extends object>
    implements IDataGroupOf<T>, IDisplayedDataGroupOf<T>
{
    get count(): number {
        return this._objects?.length ?? 0;
    }
    get objects(): T[] {
        return this._objects ?? [];
    }

    constructor(
        public key: string,
        private _objects: T[] = [],
        public displayName?: string,
        public glyphClassName?: string
    ) {
        this.displayName = displayName;
    }
}
