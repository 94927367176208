<dxy-filter-button
    [xMenuPosition]="xMenuPosition"
    [labelKey]="labelKey"
    [labelText]="labelText"
    [isResolved]="isResolved"
    [isMini]="isMini"
    [hasRemove]="hasRemove"
    [operators]="operators"
    [operator]="operator"
    [readonly]="readonly"
    [noOperator]="noOperator"
    [getOperatorTranslateKey]="getOperatorTranslateKey"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    iconClass="glyph-text"
    (operatorChange)="onOperatorChange($event)"
    (removeClick)="onRemove($event)"
    (onOpenClose)="onOpenClose.emit($event)"
>
    <div customTextTemplate>
        <span
            *ngIf="showTextSeparator"
            class="text-separator"
            [class.struck-out-text]="!isEmptyOperator"
            translate
        >
            CoreUI.Filter.Operators.Global.EmptyField
        </span>
        <div *ngIf="!showTextSeparator" class="filter-value-wrapper">
            <span
                [class.hidden]="!showOperator"
                class="operator-name light-operator"
                [translate]="operatorTranslateKey"
            ></span>
            <span class="filter-value">{{ filter?.value }}</span>
        </div>
    </div>
    <textarea
        *ngIf="showTextarea"
        type="text"
        [(ngModel)]="filter.value"
        (ngModelChange)="onTextChange()"
        dxyAutoFocusInput
    ></textarea>
</dxy-filter-button>
