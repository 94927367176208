import { ITextFilterData, TextFilterOperator } from './text-filter.types';

export class TextFilterUtils {
    public static isValueLessOperator(operator: TextFilterOperator) {
        return [
            TextFilterOperator.FieldHasValue,
            TextFilterOperator.FieldIsEmpty,
        ].includes(operator);
    }

    public static isResolved(filter: ITextFilterData) {
        if (!filter) {
            return false;
        }
        return (
            TextFilterUtils.isValueLessOperator(filter.operator) ||
            !!filter.value?.length
        );
    }

    public static getOperatorTranslateKey(operator: TextFilterOperator) {
        if (operator == undefined) {
            return;
        }
        const operatorName = TextFilterOperator[operator];
        return `CoreUI.Filter.Operators.Text.${operatorName}`;
    }
}
