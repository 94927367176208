import { ServerType } from '@datagalaxy/dg-object-model';
import {
    autoserialize,
    autoserializeAs,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import { DataTypeRef } from '../data-type';
import { BaseMasterData, ITypeMetadata } from '../master-data';
import { TableRef } from '../table';
import { ForeignKeyRef } from '../foreign-key';

// NOTE: To simplify i18n translation automation, we name this enum ColumnType, instead of DataFieldType, used on server, temporarily
export enum ColumnType {
    Column = 0,
    Field = 1,
}
SerializableEnumeration(ColumnType);

@inheritSerialization(BaseMasterData)
export class Column extends BaseMasterData {
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'Columns',
        childPropertyName: 'TableRef',
    };

    /**
     * $id and $type are override, so they can be serialized first
     * This is to respect a weird constraint on backend side, if those properties
     * are serialized in wrong order, the request (SaveData) will fail
     */
    @autoserialize override $id!: string;
    @autoserialize override $type!: string;
    @autoserialize public DisplayOrder!: number;
    @autoserialize public DisplayName!: string;
    @autoserialize public TechnicalName!: string;
    @autoserialize public Description!: string;
    @autoserialize public TypeString!: string;
    @autoserialize public Size = 50;
    @autoserialize public Precision = 0;
    @autoserialize public EntityName!: string;
    @autoserialize public IsTechnicalData = false;
    @autoserialize public IsMandatory = false;
    @deserializeAs('IsPk')
    @serialize
    public IsPrimaryKey = false;
    @deserializeAs('IsFk')
    @serialize
    public IsForeignKey = false;
    @autoserialize public IsForeignKeyMandatory!: boolean;
    @autoserialize public IsNameSyncEnabled = true;
    @autoserialize public TechnicalComments!: string;
    @autoserialize public PkOrder?: number;
    @deserializeAs(ForeignKeyRef.fromIds, 'ComputedForeignKeyIds')
    @serialize
    public ComputedForeignKeys: ForeignKeyRef[] = []; //#abasedata-metadata
    @deserializeAs(DataTypeRef.fromId, 'DataTypeId')
    @serialize
    public DataTypeRef = new DataTypeRef();
    @deserializeAs(TableRef.fromId, 'TableId')
    @serialize
    public TableRef = new TableRef();
    @autoserializeAs(ServerType) public readonly ServerType = ServerType.Column;

    constructor(referenceId?: string) {
        super();
        if (referenceId) {
            super.setId(referenceId);
        }
    }

    public hasForeignKeyById(foreignKeyId: string) {
        return (
            !!foreignKeyId &&
            this.ComputedForeignKeys.some((fkRef) => fkRef.$id == foreignKeyId)
        );
    }

    public hasOtherForeignKeyById(foreignKeyId: string) {
        return (
            !!foreignKeyId &&
            this.ComputedForeignKeys.some((fkRef) => fkRef.$id != foreignKeyId)
        );
    }
}
