import {
    HierarchicalData,
    ITypedEvent,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { IDgEventNavLink } from '../../../interfaces/IEventTranslationInputs';

export function getEntityNavLink(
    parameters: IHddLinkParameters
): IDgEventNavLink {
    return getHddNavLink({
        ...parameters,
        linkCssClass: 'nav-link-entity',
    });
}

export function getParentEntityNavLink(parameters: IHddLinkParameters) {
    return getHddNavLink({
        ...parameters,
        linkCssClass: 'nav-link-parent-entity',
    });
}

export function getHddNavLink(parameters: IHddLinkParameters) {
    const { hdd, linkCssClass, event } = parameters;
    const hd = new HierarchicalData(hdd);
    const { leftId: workSpaceGuid, rightId: objectGuid } = parseReferenceId(
        hd.DataReferenceId
    );
    const versionGuid = hd.VersionId;

    const urlEntityPart = isDiagram(hdd)
        ? getDiagramPartUrl(hdd)
        : getEntityPartUrl(hdd, objectGuid);

    const baseUrl = resolveBaseRelativeUrl(event, workSpaceGuid, versionGuid);
    const url = `${baseUrl}${urlEntityPart}`;

    return {
        linkCssClass,
        relativeUrl: url,
        parameters: hd,
    } as IDgEventNavLink;
}

export function getTeamNavLink(
    event: ITypedEvent,
    teamGuid: string,
    teamName: string
): IDgEventNavLink {
    return {
        linkCssClass: 'nav-link-team',
        text: teamName,
        relativeUrl: `${resolveClientBaseRelativeUrl(event)}user/teams`,
        parameters: new String(teamGuid),
    };
}

export function resolveIntegrationAdminUrl(event: ITypedEvent) {
    return `${resolveClientBaseRelativeUrl(
        event
    )}administration/integrations/collaboration`;
}
export function resolveUserNotificationsSettingsUrl(event: ITypedEvent) {
    return `${resolveClientBaseRelativeUrl(
        event
    )}user/user-settings/Notification`;
}

export function resolveBaseRelativeUrl(
    event: ITypedEvent,
    workspaceGuid: string,
    versionGuid: string
) {
    return `${resolveClientBaseRelativeUrl(
        event
    )}space/${workspaceGuid}/${versionGuid}/`;
}

export function resolveClientBaseRelativeUrl(event: ITypedEvent) {
    return `#/client/${event.ClientGuid}/`;
}

function isDiagram(entityHdd: HierarchyDataDescriptor) {
    return entityHdd.DataTypeName == ServerType[ServerType.Diagram];
}
function getDiagramPartUrl(entityHdd: HierarchyDataDescriptor) {
    return `diagrams/diagram/${entityHdd.DataReferenceId}`;
}
function getEntityPartUrl(
    entityHdd: HierarchyDataDescriptor,
    objectGuid: string
) {
    const dataTypeUrl = DataTypeUrlMapping[entityHdd.DataTypeName];
    const dpiUrlEndpoint =
        entityHdd.DataTypeName == DataTypesClientMapping.DataProcessingItem
            ? '/implementation'
            : '';
    return `${dataTypeUrl}/all/list/tree/browse/${objectGuid}${dpiUrlEndpoint}`;
}

const parseReferenceId = (referenceId: string) => {
    const parsed = referenceId?.split(':');

    if (parsed?.length !== 2) {
        console.warn('Wrong ReferenceId format');
        return { leftId: '', rightId: '' };
    }

    return { leftId: parsed[0], rightId: parsed[1] };
};

enum DataTypeUrlMapping {
    Model = 'catalog',
    Table = 'catalog',
    Column = 'catalog',
    Container = 'catalog',
    Property = 'glossary',
    DataProcessing = 'processing',
    DataTypesClientMapping = 'processing',
    SoftwareElement = 'usage',
}

enum DataTypesClientMapping {
    Property = 'Property',
    Model = 'Source',
    Container = 'Container',
    Table = 'Structure',
    Column = 'Field',
    DataProcessing = 'DataProcessing',
    DataProcessingItem = 'DataProcessingItem',
    SoftwareElement = 'Usage',
    AllTypes = 'Common',
}

export interface IHddLinkParameters {
    event: ITypedEvent;
    hdd: HierarchyDataDescriptor;
    linkCssClass?: string;
}
