import domtoimage from 'dom-to-image';
import { CoreUtil, IOptionsWaitFor } from './core-util';
import { FileUtil } from './file-util';
import { CollectionsHelper } from './collections-helper';
import { INgZone, ZoneUtils } from '@datagalaxy/utils';

/*
    Note: There is also a Dom2dUtil in core-2d-util
*/

/** ## Role
 * Low-level DOM elements manipulation utility
 * @deprecated Use DomUtils from @datagalaxy/utils instead and move
 * methods into it whenever you can
 */
export class DomUtil {
    public static addClass(initial: string, more: string) {
        return initial
            ? more
                ? `${initial} ${more}`
                : initial ?? ''
            : more ?? '';
    }
    public static addClasses(initial: string, ...more: string[]) {
        return initial
            ? more
                ? `${initial} ${more.filter((o) => o).join(' ')}`
                : initial ?? ''
            : more.filter((o) => o).join(' ');
    }

    public static removeAllClasses(el: Element) {
        el.classList.remove(...Array.from(el.classList));
    }

    public static replaceAllClasses(el: Element, classes: string[]) {
        const currentClasses = Array.from(el.classList);
        const newClasses = classes.filter((c) => !!c);
        if (CollectionsHelper.containSameStrings(currentClasses, newClasses)) {
            return;
        }
        DomUtil.removeAllClasses(el);
        el.classList.add(...newClasses);
    }

    public static replaceClasses(
        el: Element,
        classes: string[],
        oldClasses: string[]
    ) {
        const newClasses = classes.filter((c) => !!c);
        if (CollectionsHelper.containSameStrings(newClasses, oldClasses)) {
            return;
        }
        el.classList.remove(...oldClasses.filter((c) => !!c));
        el.classList.add(...newClasses.filter((c) => !!c));
    }

    public static getGlobalOffset(el: HTMLElement): {
        top: number;
        left: number;
    } {
        const rect = el?.getBoundingClientRect();
        return (
            rect && {
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX,
            }
        );
    }

    /** Remove all element children from DOM */
    public static empty(el: HTMLElement) {
        while (el?.firstChild) el.removeChild(el.firstChild);
    }

    public static smoothScrollToBottom(element: Element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }

    // from 'top'|'bottom'|'left'|'right' to 'above'|'below'|'left'|'right'|'before'|'after'
    public static getMatTooltipPosition(
        tooltipsPlacement: string
    ): TMatTooltipPosition {
        switch (tooltipsPlacement) {
            case 'top':
                return 'above';
            case 'bottom':
                return 'below';
            default:
                return (tooltipsPlacement || 'above') as TMatTooltipPosition;
        }
    }

    /** creates an HTML element with provided tag name, classes and content */
    public static createElement<K extends keyof HTMLElementTagNameMap>(
        tagName: K,
        className?: string | string[],
        ...content: (string | Element)[]
    ) {
        const el = document.createElement(tagName);
        return DomUtil.setupElement(el, className, ...content);
    }
    /** creates an SVG element with provided tag name, classes and content */
    public static createSvgElement<K extends keyof SVGElementTagNameMap>(
        tagName: K,
        className?: string | string[],
        ...content: (string | Element)[]
    ) {
        const el = document.createElementNS(
            'http://www.w3.org/2000/svg',
            tagName
        );
        return DomUtil.setupElement(el, className, ...content);
    }
    private static setupElement<T extends HTMLElement | SVGElement>(
        el: T,
        className?: string | string[],
        ...content: (string | Element)[]
    ) {
        if (className) {
            const classNames = Array.isArray(className)
                ? className
                : className.split(/\s+/g);
            el.classList.add(...classNames.filter((c) => c && c.length));
        }
        content.forEach((c) => {
            if (typeof c == 'string') {
                el.appendChild(document.createTextNode(c));
            } else if (c instanceof Element) {
                el.appendChild(c);
            }
        });
        return el;
    }

    /** returns a new Text element with the provided text */
    public static createTextNode(text: string) {
        return document.createTextNode(text);
    }

    /** returns true if the given element is taller than its container's height */
    public static isScrollbarVisible(
        element: THTMLElement,
        childSelector?: string
    ) {
        const el = DomUtil.getElement(element, childSelector);
        return el?.scrollHeight > el?.clientHeight;
    }

    /** set the given element's min-width to its current width */
    public static fixMinWidth(element: THTMLElement, childSelector?: string) {
        const el = DomUtil.getElement(element, childSelector);
        if (!el) {
            return;
        }
        el.style.minWidth = `${el.offsetWidth}px`;
    }

    public static focusElement(
        element: THTMLElement,
        childSelector?: string,
        debug = false
    ) {
        const el = DomUtil.getElement(element, childSelector);
        debug && console.log('focusElement', el);
        el?.focus();
        return el;
    }
    public static blurElement(
        element: THTMLElement,
        childSelector?: string,
        debug = false
    ) {
        const el = DomUtil.getElement(element, childSelector);
        debug && console.log('blurElement', el);
        el?.blur();
        return el;
    }

    public static getElement<T extends HTMLElement>(
        element: Element | THTMLElement | Document,
        childSelector?: string
    ): T {
        if (!element) {
            return;
        }
        const el =
            (element as IElementRef)?.nativeElement ??
            (element as Element | HTMLElement | Document);
        return el && childSelector
            ? el.querySelector?.(childSelector)
            : (el as T);
    }
    public static getElements<T extends HTMLElement>(
        element: Element | THTMLElement | Document,
        childrenSelector: string
    ): T[] {
        const nodeList =
            DomUtil.getElement(element)?.querySelectorAll<T>(childrenSelector);
        return nodeList ? Array.from(nodeList) : [];
    }

    /** Adds an event listener and returns a function to remove it.
     * If *ngZone* is provided and *outsideAngular* is *true* or *undefined*, *runOutsideAngular()* will be used. */
    public static addListener<K extends keyof HTMLElementEventMap>(
        el: Element | Document | Window,
        eventName: K | K[],
        listener: (ev: HTMLElementEventMap[K]) => unknown,
        ngZone?: INgZone,
        outsideAngular = true
    ) {
        return this.addCustomListener(
            el,
            eventName,
            listener,
            ngZone,
            outsideAngular
        );
    }

    /** Adds an event listener and returns a function to remove it.
     * If *ngZone* is provided and *outsideAngular* is *true* or *undefined*, *runOutsideAngular()* will be used. */
    public static addCustomListener(
        el: Element | Document | Window,
        eventName: string | string[],
        listener: (e: Event) => unknown,
        ngZone?: INgZone,
        outsideAngular = true
    ) {
        if (!el || !listener || !eventName) {
            return () => {};
        }
        const eventNames = Array.isArray(eventName) ? eventName : [eventName];
        ZoneUtils.zoneExecute(
            () =>
                eventNames.forEach((evtName) =>
                    el.addEventListener(evtName, listener)
                ),
            ngZone,
            outsideAngular
        );
        return () =>
            eventNames.forEach((evtName) =>
                el.removeEventListener(evtName, listener)
            );
    }

    /** returns an array containing the parent elements of the given element.
     * Array is sorted outermost-first.
     * body element is excluded. */
    public static getParents(
        element: THTMLElement,
        filter?: (parent: HTMLElement) => boolean
    ) {
        const parents: HTMLElement[] = [];
        let parent = DomUtil.getElement(element)?.parentElement;
        while (parent && parent != document.body) {
            if (!filter || filter(parent)) {
                parents.push(parent);
            }
            parent = parent.parentElement;
        }
        parents.reverse();
        return parents;
    }

    /** returns an array containing the parent components of the given element.
     * Array is sorted outermost-first.
     * Note: An element is considered a component when it has as dash in its name */
    public static getParentComponents(
        element: THTMLElement,
        ...excludedNames: string[]
    ) {
        return DomUtil.getParents(
            element,
            (p) =>
                p.localName.includes('-') &&
                !excludedNames.includes(p.localName)
        );
    }

    /** copy text to clipboard */
    public static copyToClipboard(text: string) {
        const listener = (e: ClipboardEvent) => {
            const clipboard = e?.clipboardData ?? window['clipboardData'];
            clipboard?.setData?.('text', text);
            e?.preventDefault();
        };

        document.addEventListener('copy', listener, false);
        try {
            document.execCommand('copy');
        } finally {
            document.removeEventListener('copy', listener, false);
        }
    }

    /** returns a promise that will be resolved when the element matching the given selector is present in the DOM */
    public static async waitForElement(
        cssSelector: string,
        opt?: IOptionsWaitFor,
        parent: Element | Document = document
    ) {
        const cancelExt = opt?.cancel;
        const o = opt ?? { timeoutMs: 1000, pollDelayMs: 222 };
        o.cancel = () => {
            if (!parent) {
                return 'No parent specified.';
            }
            if (!cssSelector) {
                return 'No css selector specified.';
            }
            return cancelExt?.();
        };
        return CoreUtil.waitFor(
            () => parent.querySelector(cssSelector) as HTMLElement,
            o
        );
    }

    /** returns a promise that will be resolved when the given parent's child element matching the given selector is present in the DOM */
    public static async waitForChildElement(
        parent: THTMLElement,
        childCssSelector: string,
        opt?: IOptionsWaitFor
    ) {
        return DomUtil.waitForElement(
            childCssSelector,
            opt,
            DomUtil.getElement(parent)
        );
    }

    /** take screenshot from dom html */
    public static async screenshot(
        element: HTMLElement,
        downloadFileName = 'DataGalaxy_screenshot.png',
        noShutterAnimation = false,
        quality = 100,
        ngZone?: INgZone,
        outsideAngular = false
    ) {
        if (!element) {
            return;
        }
        let shutter: HTMLElement;
        if (!noShutterAnimation) {
            shutter = this.createElement('div', 'screenshot-effect');
            document.body.append(shutter);
        }
        /** delay not to have the browser rendering the screenshot animation
         * while generating the PNG, resulting in a delayed animation */
        ZoneUtils.zoneTimeout(
            async () => {
                try {
                    const dataUrl = await (async () =>
                        domtoimage.toPng(element, {
                            quality,
                            filter: (node) => {
                                if (!(node instanceof HTMLImageElement)) {
                                    return true;
                                }
                                // dom-to-image doesn't support images with no extension
                                // archi-images(rvi) Try to find a newer lib
                                // and/or add extensions in uploaded files Urls
                                return FileUtil.hasExtension(node.src);
                            },
                        }))();
                    DomUtil.downloadDataUrl(downloadFileName, dataUrl);
                } finally {
                    shutter?.remove();
                }
            },
            undefined,
            ngZone,
            outsideAngular
        );
    }

    /** download the given dataUrl as a file with the given name*/
    public static downloadDataUrl(fileName: string, dataUrl: string) {
        const a = document.createElement('a');
        a.download = fileName.replace(/[,:;//|]/g, '_');
        //console.log('downloadDataUrl', a.download, dataUrl)
        a.href = dataUrl;
        a.click();
    }

    //#region moved to CoreUtil

    /** Returns the content of the given File as a data URL
     * @deprecated Use to CoreUtil.readAsDataUrl instead */
    public static async readAsDataUrl(file: Blob) {
        return CoreUtil.readAsDataUrl(file);
    }
    //#endregion

    /** emulates a 'browse...' action for selecting an image */
    public static async getUserImage() {
        return DomUtil.readUserFile((file) => Promise.resolve(file), 'image/*');
    }

    public static async readUserFile<T>(
        read: (file: Blob, encoding?: string) => Promise<T>,
        accept = 'application/json',
        encoding?: string
    ) {
        return new Promise<T>((resolve, reject) => {
            let input: HTMLInputElement;
            const dispose = () => {
                try {
                    input?.remove();
                    input = undefined;
                } catch {}
            };
            try {
                input = DomUtil.createElement('input') as HTMLInputElement;
                input.setAttribute('style', 'display: none');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', accept);
                input.addEventListener('change', () =>
                    read(input?.files?.[0], encoding)
                        .then((result) => {
                            dispose();
                            resolve(result);
                        })
                        .catch((e) => {
                            dispose();
                            reject(e);
                        })
                );
                document.body.appendChild(input);
                input.click();
            } catch (e) {
                dispose();
                reject(e);
            }
        });
    }

    /** returns true if the given files have the same name, type and size, or are both null or undefined */
    static areSameFile(a: File, b: File) {
        return (
            (!a && !b) ||
            (a && b && a.name == b.name && a.type == b.type && a.size == b.size)
        );
    }

    /** Add/remove a class on all given elements.
     * If *toggle* is undefined, class is removed if present, or added if not. */
    public static toggleClass(
        els: HTMLElement[],
        className: string,
        toggle?: boolean
    ) {
        els?.forEach((el) => el?.classList?.toggle(className, toggle));
    }

    /** returns true if the given event's *relatedTarget* element has any of the given class names */
    public static relatedTargetHasClassName(
        event: FocusEvent,
        ...classNames: string[]
    ) {
        return DomUtil.hasClassName(
            event?.relatedTarget as HTMLElement,
            ...classNames
        );
    }
    /** returns true if the given event's *target* element has any of the given class names */
    public static targetHasClassName(event: Event, ...classNames: string[]) {
        return DomUtil.hasClassName(event.target as HTMLElement, ...classNames);
    }

    /** returns true if the given element has any of the given class names */
    public static hasClassName(element: THTMLElement, ...classNames: string[]) {
        const classList = DomUtil.getElement(element)?.classList;
        return classList && classNames.some((cn) => classList.contains(cn));
    }

    /** returns true if the given event's target element is scrolled all the way down */
    public static isTargetScrolledToBottom(event: Event) {
        return DomUtil.isScrolledToBottom(event.target as HTMLElement);
    }
    /** returns true if the given element is scrolled all the way down */
    public static isScrolledToBottom(el: HTMLElement) {
        return el && el.offsetHeight + el.scrollTop >= el.scrollHeight;
    }

    public static setStyleProperty(
        element: THTMLElement,
        propertyName: string,
        v: number | string,
        unitSuffix?: string
    ) {
        const sv =
            v == undefined || (typeof v == 'number' && isNaN(v ?? NaN))
                ? undefined
                : unitSuffix
                ? `${v}${unitSuffix}`
                : v.toString();
        DomUtil.getElement(element)?.style?.setProperty(propertyName, sv);
    }

    /** returns the first div having the given class in the given container, or appends it if it does not exist */
    public static getDivOrAppend(
        container: Element | THTMLElement,
        className: string
    ) {
        const eContainer = DomUtil.getElement(container);
        if (!eContainer) {
            return;
        }
        let el = eContainer.querySelector<HTMLDivElement>(
            `div.${className.trim().replace(/\s+/g, '.')}`
        );
        if (!el) {
            eContainer.appendChild(
                (el = DomUtil.createElement('div', className) as HTMLDivElement)
            );
        }
        return el;
    }

    public static blurActiveElement() {
        (window.document.activeElement as HTMLElement)?.blur?.();
    }

    /** Get css property of an HTML element in DOM */
    public static getCssProperty(el: HTMLElement, propertyName: string) {
        return getComputedStyle(el, null).getPropertyValue(propertyName);
    }

    /** Get all font css properties of an element */
    public static getCssFontProperties(el: HTMLElement): ICssFontProperties {
        return {
            fontFamily: DomUtil.getCssProperty(el, 'font-family'),
            fontWeight: DomUtil.getCssProperty(el, 'font-weight'),
            fontSize: DomUtil.getCssProperty(el, 'font-size'),
        };
    }

    /** Canvas to calculate future text width in DOM */
    private static textWidthCanvas?: HTMLCanvasElement;

    /** Get text width in DOM before inserting in Dom */
    public static getTextWidth(
        text: string,
        fontProperties: ICssFontProperties
    ) {
        // re-use canvas object for better performance
        const canvas = (DomUtil.textWidthCanvas ??=
            document.createElement('canvas'));
        const { fontWeight, fontSize, fontFamily } = fontProperties;
        const context = canvas.getContext('2d');
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    /** adds the given style sheet to the document's head */
    public static addStyleSheetRules(
        ...rules: { selector: string; style: TCSSStyleObject }[]
    ) {
        const styleEl = document.createElement('style');
        const sheet = document.head.appendChild(styleEl).sheet;
        rules.forEach((r) => {
            const css = Object.entries(r.style)
                .map(([k, v]) => `${k}:${k == 'content' ? `"${v}"` : v}`)
                .join(';');
            sheet.insertRule(`${r.selector} { ${css} }`, sheet.cssRules.length);
        });
        return styleEl;
    }

    public static getElementOrSelector(
        containerOrSelector: string | HTMLElement
    ) {
        return typeof containerOrSelector != 'string'
            ? containerOrSelector
            : (document.querySelector(containerOrSelector) as HTMLElement);
    }

    // source: https://dev.to/chromiumdev/detecting-select-all-on-the-web-2alo
    /** Allows detection of ctrl-a / cmd-a select-all event from a non-input element.
     * Appends a style-sheet and 2 hidden divs to the *body* element,
     * and a listener on *document.selectionchange*.
     * Returns a function to remove the listener.
     * Prevents from re-adding style sheet and elements on subsequent calls.
     * Ensures hidden divs stay in place on subsequent calls. */
    public static attachSelectAll(
        listener: (e: Event) => unknown,
        ngZone?: INgZone
    ) {
        const selector = 'body div.extent';
        let start = document.querySelector(selector + '.start');
        let end = document.querySelector(selector + '.end');
        if (!start && !end) {
            DomUtil.addStyleSheetRules(
                { selector, style: { position: 'fixed', opacity: 0 } },
                { selector: selector + '::after', style: { content: '\\200b' } }
            );
        }
        document.body.insertBefore(
            (start ??= DomUtil.createElement('div', ['extent', 'start'])),
            document.body.firstChild
        );
        document.body.append(
            (end ??= DomUtil.createElement('div', ['extent', 'end']))
        );

        const isExtent = (n: Node) =>
            n instanceof HTMLDivElement && n.classList.contains('extent');

        return DomUtil.addListener(
            document,
            'selectionchange',
            (e) => {
                const s = window.getSelection();
                if (
                    s.anchorNode === s.focusNode ||
                    !isExtent(s.anchorNode) ||
                    !isExtent(s.focusNode)
                ) {
                    return;
                }
                // in timeout, so several attached listeners can function properly before clearing the selection
                ZoneUtils.zoneTimeout(
                    () => {
                        s.removeAllRanges();
                        listener(e);
                    },
                    0,
                    ngZone,
                    true
                );
            },
            ngZone,
            true
        );
    }
}

export type TCSSStyleObject = Partial<{
    [K in TCSSStylePropertyKeys]: string | number;
}>;
export type TCSSStylePropertyKeys = string &
    keyof Omit<
        CSSStyleDeclaration,
        | 'getPropertyPriority'
        | 'getPropertyValue'
        | 'item'
        | 'removeProperty'
        | 'setProperty'
        | 'parentRule'
        | 'length'
    >;

export type TTooltipPlacement = 'top' | 'bottom' | TMatTooltipPosition;
export type TMatTooltipPosition =
    | 'above'
    | 'below'
    | 'left'
    | 'right'
    | 'before'
    | 'after';

export type THTMLElement = HTMLElement | IElementRef | SVGElement;
interface IElementRef {
    nativeElement: HTMLElement;
}
export interface ICssFontProperties {
    fontFamily: string;
    fontWeight: string;
    fontSize: string;
}
