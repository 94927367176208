import { FieldType } from '../../types/enums/field-type.enum';
import { maskTradKeys } from '../../types/constants/mask-trad-keys.constant';
import { pathTypes } from '../../types/constants/path-types.constant';

export const amazonS3Config = [
    {
        payloadField: 'region',
        formField: 'region',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'bucket-name',
        formField: 'bucketName',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'files-path',
        formField: 'filesPath',
        formMandatory: false,
        type: FieldType.text,
    },
    {
        payloadField: 'access-key',
        formField: 'accessKey',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        tradKey: 'secretKey',
        formMandatory: true,
        isPassword: true,
    },
    {
        payloadField: 'sts-token',
        formField: 'stsToken',
        isPassword: true,
        formMandatory: false,
        passwordMaxLength: 1000,
    },
    {
        payloadField: 'mask-filter',
        formField: 'maskFilter',
        type: FieldType.boolean,
        formMandatory: false,
        isOptionInfo: true,
    },
    {
        payloadField: 'mask-group',
        formField: 'maskGroup',
        type: FieldType.boolean,
        formMandatory: false,
        isOptionInfo: true,
    },
    {
        formField: 'maskList',
        payloadValue: [],
        type: FieldType.list,
        isOptionInfo: true,
        listFieldTradKeys: maskTradKeys,
    },
    {
        formField: 'pathTransformation',
        formMandatory: false,
        type: FieldType.select,
        isOptionInfo: true,
        translate: true,
        selectValues: pathTypes,
    },
    { payloadField: 'database-technology', payloadValue: 'amazons3' },
    { payloadField: 'type', payloadValue: 'MODEL' },
];
