export enum SearchUsage {
    SearchResult = 'SearchResult',
    Spotlight = 'Spotlight',
    EntitySelector = 'EntitySelector',
    FilterPanel = 'FilterPanel ',
    LastModifiedEntities = 'LastModifiedEntities',
    LastCreatedEntities = 'LastCreatedEntities',
    Diagrams = 'Diagrams',
    FilterEntity = 'FilterEntity',
    SpaceHome = 'SpaceHome',
}
