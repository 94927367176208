export const powerBiFieldInfo = {
    tenantId: {
        hint: {
            fr: 'Identifiant du tenant Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure tenant identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    clientId: {
        hint: {
            fr: 'Identifiant du compte de service client Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure service account identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    clientSecret: {
        hint: {
            fr: 'Secret du client Azure',
            en: 'Azure service account secret',
        },
    },
};
