import {
    EntityActivityEventBase,
    ITypedEvent,
    IUserEvent,
} from '@datagalaxy/dg-object-model';
import {
    IEventTranslationInputs,
    TEventTranslateParameter,
} from '../../../interfaces/IEventTranslationInputs';
import {
    getEntityNavLink,
    getParentEntityNavLink,
} from './dg-event-translation-nav-links.helpers';

export function getUserEventTranslationInputs(
    event: IUserEvent
): IEventTranslationInputs {
    return {
        sourceEvent: event,
        translateKey: getTypedEventTranslateKey(event),
        translateParameters: getUserEventTranslateParams(event),
        navLinks: [],
    };
}

function getTypedEventTranslateKey(event: ITypedEvent): string {
    return `DgEvent.${event.EventTypeName}`;
}
function getUserEventTranslateParams(
    event: IUserEvent
): TEventTranslateParameter[] {
    return [
        {
            key: 'authorFullName',
            value: event?.UserFullName,
        },
    ];
}

function getEntityEventTranslationInputs(
    event: EntityActivityEventBase
): IEventTranslationInputs {
    const hdd = event.EntityHdd;
    hdd?.setVersionId(event.VersionId);
    const inputs = getUserEventTranslationInputs(event);
    inputs.translateParameters.push({
        key: 'entityName',
        value: hdd?.DisplayName,
    });
    if (!hdd) {
        return inputs;
    }
    inputs.navLinks = [
        getEntityNavLink({
            event,
            hdd,
        }),
    ];

    return inputs;
}

export function getEntityActivityEventTranslationInputs(
    event: EntityActivityEventBase
): IEventTranslationInputs {
    const inputs = getEntityEventTranslationInputs(event);

    const parentHdd = event.ParentEntityHdd;
    if (parentHdd && !parentHdd.isSpace()) {
        inputs.translateParameters.push({ key: 'withParent', value: true });
        inputs.translateParameters.push({
            key: 'parentEntityName',
            value: parentHdd.DisplayName,
        });
        inputs.navLinks.push(
            getParentEntityNavLink({
                event,
                hdd: parentHdd,
            })
        );
    } else {
        inputs.translateParameters.push({ key: 'withParent', value: false });
    }
    return inputs;
}
