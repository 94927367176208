import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { IOmniGridState, PersistedGridState } from '@datagalaxy/ui/grid';

export type TGridState = IOmniGridState | PersistedGridState;

@Injectable({
    providedIn: 'root',
})
export class UserGridSettingsService {
    constructor(private userService: UserService) {}

    public async getGridState(
        gridId: string
    ): Promise<PersistedGridState | undefined> {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridId,
            true,
            true
        );

        const gridState: TGridState = res?.Value && JSON.parse(res.Value);

        return this.convertGridState(gridState);
    }

    public async saveGridState(
        gridId: string,
        gridState: PersistedGridState
    ): Promise<void> {
        await this.userService.setUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridId,
            JSON.stringify(gridState)
        );
    }

    private convertGridState(
        gridState?: TGridState
    ): PersistedGridState | undefined {
        switch (gridState?.version) {
            case 'omni-grid-1': {
                return {
                    version: 'dxy-grid-v1',
                    columns: gridState.columns.map((col) => ({
                        id: col.colId,
                        hidden: col.hide,
                        width: col.width,
                    })),
                };
            }
            case 'dxy-grid-v1': {
                return gridState as PersistedGridState;
            }
            default: {
                return;
            }
        }
    }
}
