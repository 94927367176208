import { deserialize, inheritSerialization } from 'cerialize';
import { BaseServiceResult } from '@datagalaxy/data-access';

@inheritSerialization(BaseServiceResult)
export class PersonalAccessTokenResult extends BaseServiceResult {
    @deserialize public PersonalAccessToken?: string;
    @deserialize public CreationTime?: string;
    @deserialize public CreationUserId?: string;
    @deserialize public RevocationTime?: string;
    @deserialize public RevocationUserId?: string;
    @deserialize public LastAccessTime?: string;

    constructor() {
        super();
    }
}
