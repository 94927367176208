export class MathUtils {
    static avg(data: number[]): number {
        return (
            (data?.length &&
                data.reduce((p, c) => p + (c || 0), 0) / data.length) ||
            0
        );
    }

    static clamp(n: number, min: number, max?: number) {
        return Math.min(Math.max(min ?? -Infinity, n ?? 0), max ?? Infinity);
    }
}
