import { FieldType } from '../../types/enums/field-type.enum';

export const hubspotConfig = [
    {
        payloadField: 'server',
        formField: 'apiUrl',
        formMandatory: true,
        type: FieldType.text,
        payloadValue: 'https://api.hubapi.com',
    },
    {
        payloadField: 'password',
        formField: 'accessTokenPwd',
        isPassword: true,
        formMandatory: true,
    },
];
