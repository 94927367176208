import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    IRuleEditModalInput,
    IRuleEditModalOutput,
} from './rule-edit-modal.types';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { DataQualityRuleType } from '@datagalaxy/webclient/data-quality/data-access';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { IFieldSelectAdapter, withLoading } from '@datagalaxy/core-ui';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { IRuleForm } from '../rule-create-modal/rule-create-modal.types';
import {
    EntityApiService,
    LoadMultiEntityParameter,
} from '@datagalaxy/webclient/entity/data-access';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { IDxyEntitySelectorInputs } from '../../shared/entitySelector/entity-selector.types';
import { RuleCodeValidatorService } from '../validators/rule-code-validator.service';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyEntitySelectorFieldComponent } from '../../shared/entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

/**
 * ## Role
 * Modal to create a new rule
 */
@Component({
    selector: 'app-rule-edit-modal',
    templateUrl: './rule-edit-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        ReactiveFormsModule,
        DxyFieldTextComponent,
        DxyFieldSelectComponent,
        forwardRef(() => DxyEntitySelectorFieldComponent),
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class RuleEditModalComponent
    extends DxyBaseModalComponent<IRuleEditModalInput, IRuleEditModalOutput>
    implements OnInit
{
    protected formGroup: FormGroup<IRuleForm>;
    public entitySelectorOptions: IDxyEntitySelectorInputs;

    protected get codeErrorMessage() {
        const code = this.formGroup.controls.code;
        if (code?.valid) {
            return undefined;
        }
        return code.errors?.ruleCodeAlreadyExists;
    }

    constructor(
        private entityApiService: EntityApiService,
        private currentSpaceService: CurrentSpaceService,
        private translate: TranslateService,
        dialogRef: MatDialogRef<RuleEditModalComponent, IRuleEditModalOutput>,
        @Inject(MAT_DIALOG_DATA) data: IRuleEditModalInput,
        private fb: FormBuilder,
        private ruleCodeValidatorService: RuleCodeValidatorService
    ) {
        super(dialogRef, data);
        this.formGroup = this.fb.group<IRuleForm>({
            code: new FormControl(data.code, {
                asyncValidators:
                    this.ruleCodeValidatorService.uniqueCodeRuleValidator(
                        data.code
                    ),
            }),
            type: new FormControl(data.type, Validators.required),
            statement: new FormControl(data.statement, Validators.required),
            fieldIds: new FormControl([]),
        });
    }

    public ruleTypesAdapter: IFieldSelectAdapter<DataQualityRuleType> = {
        options: Object.values(DataQualityRuleType),
        getText: (type: DataQualityRuleType) =>
            this.translate.instant(
                `UI.DataQualityRules.DataQualityRuleType.${type}`
            ),
        onSelectionChange: (t: DataQualityRuleType) => {
            this.formGroup.controls.type.setValue(t);
        },
    };

    ngOnInit() {
        this.log('ngOnInit', this);
        void this.initAsync();
    }

    @withLoading()
    private async initAsync() {
        const currentSpace = this.currentSpaceService.currentSpace;
        const spaceId = currentSpace.ReferenceId.split(':')[0];
        const versionId = currentSpace.versionId;
        const entities = await this.entityApiService.loadMultiEntity(
            new LoadMultiEntityParameter(
                this.data.entityId,
                ServerType.Column,
                false,
                0,
                1000,
                '',
                '',
                '',
                [],
                []
            )
        );
        const includedIds = entities.Entities.map((e) => e.ReferenceId);
        this.entitySelectorOptions = {
            spaceIdr: {
                spaceId,
                versionId,
            },
            includedIds,
            excludedIds: [],
        };
        const selectedEntities = entities.Entities.filter((e) =>
            this.data.fieldIds.includes(e.ReferenceId)
        );
        this.formGroup.controls.fieldIds.setValue(selectedEntities);
    }

    onCloseSubmit() {
        const { statement, type, code, fieldIds } = this.formGroup.value;
        this.result = {
            ruleId: this.data.ruleId,
            statement,
            type,
            code,
            fieldIds: fieldIds.map((e) => e.ReferenceId),
        };
        // update rule
        super.onCloseSubmit();
    }
}
