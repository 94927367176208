import { IRichTextContentAdapter } from './editor/rich-text-editor.types';
import { SimpleQuillContentAdapter } from './SimpleQuillContentAdapter';

export interface IRichtextDefaults {
    /** Default adapter for the quill-based rich-text editor.
     * To be set globally at app boot to avoid specifying the adapter for each instance of the editor. */
    quillContentAdapter?: IRichTextContentAdapter;
}

/**
 * Global default settings for the rich-text editor
 */
export const richTextDefaults: IRichtextDefaults = {
    quillContentAdapter: new SimpleQuillContentAdapter(),
};
