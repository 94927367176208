import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import {
    SearchSettings,
    SearchSettingsNode,
    searchSettingsTree,
} from './search-settings.types';
import { UserService } from '../services/user.service';
import { ViewTypeService } from '../services/viewType.service';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { IGroupingFactory } from './search.types';
import { EnumUtil } from '../shared/util/EnumUtil';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';

export type TSearchSettingsType = string | ServerType | EntityType;

@Injectable({ providedIn: 'root' })
export class SearchSettingsService extends BaseService {
    private searchSettings: SearchSettings;
    private searchResultGroups: Map<string, EntityType[]>;
    private searchResultGroupKeys: string[];

    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private viewTypeService: ViewTypeService,
        private attributeDataService: AttributeDataService
    ) {
        super();
    }

    public initAsync() {
        return this.userService
            .getUserSettingValue(
                userSettingsValues.search.category,
                userSettingsValues.search.routes.settings
            )
            .then((res) => {
                if (res?.Value) {
                    let tree = JSON.parse(res.Value);

                    if (tree.version === searchSettingsTree.version) {
                        return tree;
                    }

                    if (tree.version == '1.4') {
                        this.upgradeFromVersion14(tree);
                        return tree;
                    }

                    if (tree.version == '1.8') {
                        this.upgradeFromVersion18(tree);
                        return tree;
                    }

                    if (tree.version == '1.9' || tree.version == '1.10') {
                        tree = this.upgradeFromVersion19(tree);
                    }

                    tree = this.upgradeFromPreviousVersion(tree);
                    this.saveSettings(tree);
                    return tree;
                }
                return this.initSettings(searchSettingsTree);
            })
            .then((settings) => {
                this.searchSettings = settings;
                this.computeSearchResultGroups();
            });
    }

    public getSearchSettings(): SearchSettings {
        return this.searchSettings;
    }

    public saveSettings(settings: SearchSettings) {
        this.userService
            .setUserSettingValue(
                userSettingsValues.search.category,
                userSettingsValues.search.routes.settings,
                JSON.stringify(settings)
            )
            .then(() => {
                this.searchSettings = settings;
                this.computeSearchResultGroups();
            });
    }

    public findNode(
        node: SearchSettingsNode,
        id: TSearchSettingsType
    ): SearchSettingsNode {
        if (node.id === id) {
            return node;
        } else if (node.nodes && node.nodes.length) {
            return node.nodes
                .map((child) => this.findNode(child, id))
                .reduce((a, b) => a || b);
        }
        return null;
    }

    public getNodeTranslateKey(node: SearchSettingsNode) {
        if (!node) {
            return undefined;
        }
        const viewTypeParticle = this.viewTypeService.isTechnicalView
            ? 'technical'
            : 'functional';
        let typeName = node.name;

        if (node.id === ServerType.Property && node.isServerType) {
            typeName = 'Glossary';
        }

        if (node.isServerType) {
            const translateKey = `UI.Search.settings.groups.${typeName}`;
            const translatedText = this.translate.instant(translateKey);
            if (translatedText !== translateKey) {
                return translateKey;
            }
        } else {
            const translateKey = `UI.Search.settings.entityTypes.${viewTypeParticle}.${typeName}`;
            const translatedText = this.translate.instant(translateKey);

            if (translatedText === translateKey) {
                return this.attributeDataService.getEntityTypeTranslation(
                    EntityType[typeName]
                );
            }
            return translateKey;
        }
        return `DgServerTypes.ServerTypeName.${typeName}`;
    }

    //#region search-result groups
    public readonly groupingFactory: IGroupingFactory<FilteredEntityItem> = {
        getGroupKey: (e) =>
            e.ExactMatchAttributes?.size ? 'exactMatches' : 'moreResults',
        getGroupDisplayName: (groupKey) =>
            this.translate.instant(`UI.Search.settings.groups.${groupKey}`),
        getGroupSortIndex: (groupKey) => (groupKey === 'exactMatches' ? 0 : 1),
    };
    public getSearchResultGroupKeys() {
        return this.searchResultGroupKeys.slice();
    }
    public getSearchResultGroupEntityTypes(groupKey: string) {
        return this.searchResultGroups.get(groupKey)?.slice() ?? [];
    }
    //#endregion

    private initSettings(settings: SearchSettings) {
        settings?.nodes?.forEach((node) =>
            this.initSettingsNode(node, null, '', 0)
        );
        return settings;
    }

    /**
     * For each node we:
     * - Set his parentId
     * - Set his name based on enum value
     * - Set default selected to true
     */
    private initSettingsNode(
        node: SearchSettingsNode,
        parentId: TSearchSettingsType,
        parentPath: string,
        level: number
    ) {
        node.parentId = parentId;
        node.level = level;
        node.selected = node.selected === undefined ? true : node.selected;
        if (node.isServerType) {
            node.name =
                EnumUtil.getValuesAndNames(ServerType).find(
                    (type) => type.id === node.id
                )?.value || node.id.toString();
        } else {
            node.name =
                EnumUtil.getValuesAndNames(EntityType).find(
                    (type) => type.id === node.id
                )?.value || node.id.toString();
        }
        const name = node.name ?? '';
        node.path = parentPath ? `${parentPath}.${name}` : name;
        node?.nodes?.forEach((child) =>
            this.initSettingsNode(child, node.id, node.path, level + 1)
        );
    }

    private setSearchSettingsPath(settings: SearchSettings) {
        settings?.nodes?.forEach((node) => this.setNodePath(node, ''));
    }

    private setNodePath(node: SearchSettingsNode, parentPath: string): void {
        if (!node) {
            return;
        }
        const name = node.name ?? '';
        node.path = parentPath ? `${parentPath}.${name}` : name;
        node.nodes?.forEach((child) => this.setNodePath(child, node.path));
    }

    public setSearchSettingsEntityTypeList(
        node: SearchSettingsNode,
        res: TSearchSettingsType[]
    ) {
        if (!node.isServerType && !node.isPlaceholder && node.selected) {
            const setting = res.find((el) => el === node.name);
            if (!setting) {
                res.push(node.name);
            }
        }
        (node.nodes || []).forEach((childNode) => {
            this.setSearchSettingsEntityTypeList(childNode, res);
        });
    }

    private getEntityTypesAsEnumValues(
        tmpEntityTypes: (string | EntityType)[]
    ): EntityType[] {
        return tmpEntityTypes?.map((et) =>
            typeof et == 'string' ? EntityType[et] : et
        );
    }

    private computeSearchResultGroups() {
        const groups = new Map<string, EntityType[]>();
        const keys = new Map<EntityType, string>();

        const collect = (n: SearchSettingsNode, groupTranslateKey: string) => {
            if (n.name == 'FilteredView') {
                groups.set(groupTranslateKey, [EntityType.FilteredView]);
                keys.set(EntityType.FilteredView, groupTranslateKey);
            } else if (n.nodes?.length) {
                n.nodes.forEach((sn) => collect(sn, groupTranslateKey));
            } else if (!n.isPlaceholder && !n.isServerType) {
                const ets = groups.get(groupTranslateKey);
                const et = n.id as EntityType;
                if (ets) {
                    ets.push(et);
                } else {
                    groups.set(groupTranslateKey, [et]);
                }
                keys.set(et, groupTranslateKey);
            }
        };

        this.searchSettings.nodes.forEach((n) =>
            collect(n, this.getNodeTranslateKey(n))
        );

        this.searchResultGroups = groups;
        this.searchResultGroupKeys = Array.from(groups.keys());
        this.debug &&
            this.log(
                'computeSearchResultGroups',
                this.searchResultGroupKeys,
                Array.from(groups.values())
            );
    }

    /**  Add missing Sub Structure Node
     *   And set as selected only if File or Document is selected */
    private upgradeFromVersion14(v14tree: SearchSettings) {
        v14tree.version = searchSettingsTree.version;

        const dictionaryNode = v14tree.nodes.find((n) => n.id == 'Dictionary');
        const structuresNode =
            dictionaryNode &&
            dictionaryNode.nodes.find((n) => n.id == ServerType.Table);

        if (!structuresNode) {
            return;
        }

        const isFileOrDocumentSelected = structuresNode.nodes.some(
            (n) =>
                n.selected &&
                (n.id == EntityType.Document || n.id == EntityType.File)
        );
        let subStructureNode = structuresNode.nodes.find(
            (n) => n.id == EntityType.SubStructure
        );

        if (!subStructureNode) {
            subStructureNode = new SearchSettingsNode();
            subStructureNode.id = EntityType.SubStructure;
            subStructureNode.selected = isFileOrDocumentSelected;
            structuresNode.nodes.push(subStructureNode);
            this.initSettingsNode(
                subStructureNode,
                structuresNode.id,
                structuresNode.path,
                structuresNode.level + 1
            );
        }
    }

    /**
     * Update Diagram root node to be serverType instead of entityType
     */
    private upgradeFromVersion18(v18tree: SearchSettings) {
        v18tree.version = searchSettingsTree.version;
        const diagram = v18tree.nodes.find((n) => n.id == EntityType.Diagram);
        diagram.isServerType = true;
        diagram.id = ServerType.Diagram;
    }

    /**
     *  Ensure to Keep value for
     *      - EntityType.PhysicalDiagram, previously EntityType.Diagram
     *      - EntityType.FreeDiagram, previously EntityType.GenericDiagram & moved path
     *      - EntityType.DiagramNote moved path
     */
    private upgradeFromVersion19(v19tree: SearchSettings) {
        const newTree = this.upgradeFromPreviousVersion(v19tree);
        this.upgradeNodeSelectedFromPreviousPaths(newTree, v19tree, [
            {
                newPath: 'Diagram.FreeDiagram',
                oldPath: 'Diagram.GenericDiagram',
            },
            {
                newPath: 'Diagram.PhysicalDiagram',
                oldPath: 'Diagram.Diagram.Diagram',
            },
            {
                newPath: 'Diagram.DiagramNote',
                oldPath: 'Diagram.Diagram.DiagramNote',
            },
        ]);
        return newTree;
    }

    private upgradeNodeSelectedFromPreviousPaths(
        newTree: SearchSettings,
        oldTree: SearchSettings,
        pathArray: { newPath: string; oldPath: string }[]
    ) {
        const flatNewTreeNode = this.getFlatTreeSearchSettings(newTree);
        const flatOldTreeNode = this.getFlatTreeSearchSettings(oldTree);

        pathArray?.forEach((paths) => {
            const newNode = flatNewTreeNode.find(
                (node) => node.path === paths.newPath
            );
            const oldNode = flatOldTreeNode.find(
                (node) => node.path === paths.oldPath
            );

            if (newNode && oldNode) {
                newNode.selected = oldNode.selected;
            }
        });
    }

    private upgradeFromPreviousVersion(tree: SearchSettings) {
        this.setSearchSettingsPath(tree);
        const flatTreeNode = this.getFlatTreeSearchSettings(tree);
        const newTree = this.initSettings(searchSettingsTree);

        const setSelected = (
            node: SearchSettingsNode,
            flatTree: SearchSettingsNode[]
        ) => {
            if (!node) {
                return;
            }
            const previousNode = flatTree.find((n) => n.path === node.path);
            const parent = flatTree.find(
                (n) => n.id === node.parentId && n.level === node.level - 1
            );

            if (parent && !parent.selected) {
                node.selected = false;
            } else if (previousNode) {
                node.selected = previousNode.selected;
                node.collapsed = previousNode.collapsed;
            }
            node.nodes?.forEach((child) => setSelected(child, flatTree));
        };
        newTree?.nodes?.forEach((node) => setSelected(node, flatTreeNode));
        return newTree;
    }

    private getFlatTreeSearchSettings(tree: SearchSettings) {
        const flatTreeNode: SearchSettingsNode[] = [];
        const getFlatTreeNode = (
            node: SearchSettingsNode,
            result: SearchSettingsNode[]
        ) => {
            result.push(node);
            node?.nodes?.forEach((child) => getFlatTreeNode(child, result));
        };
        tree?.nodes?.forEach((node) => getFlatTreeNode(node, flatTreeNode));
        return flatTreeNode;
    }
}
