import * as JSZip from 'jszip';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    IZipData,
    zipToBlobOptions,
} from '../shared/util/zip-worker/zip-worker.types';

/**
    #Archi-service-leaf (Does not reference any other app service)
*/
@Injectable({ providedIn: 'root' })
export class ZipService extends BaseService {
    public async getZipBlobAsync(
        path: string,
        content: string,
        logFunc?: (arg: string) => void
    ): Promise<Blob> {
        if (typeof Worker !== 'undefined') {
            logFunc?.('Using zip worker...');
            return new Promise<Blob>((resolve) => {
                const worker = new Worker(
                    new URL(
                        '../shared/util/zip-worker/zip-worker.worker',
                        import.meta.url
                    ),
                    { type: 'module' }
                );
                worker.onmessage = ({ data }) => {
                    worker.terminate();
                    resolve(data);
                };
                const message: IZipData = { path, content };
                worker.postMessage(message);
            });
        } else {
            logFunc?.('Warning: Worker is not supported');
            const zip = new JSZip();
            zip.file(path, content);
            return zip.generateAsync(zipToBlobOptions);
        }
    }
}
