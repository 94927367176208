import { BaseService, UiUtil } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPromptOptions } from '@datagalaxy/ui/dialog';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { PreviewPanelService } from '../shared/shared-ui/preview-panel.service';
import { ImportWizardService } from '../import/services/import-wizard.service';
import { TeamCreateModalComponent } from './team-create-modal/team-create-modal.component';
import {
    IAddTeamMemberModalInput,
    TeamAddMemberModalComponent,
} from './team-add-member-modal/team-add-member-modal.component';
import { TeamPreviewComponent } from './team-preview/team-preview.component';
import { ImportContext, ImportTarget } from '../import/shared/ImportContext';
import { EOperationType } from '../import/shared/EOperationType';
import { ImportModule } from '../import/shared/ImportModule';
import { TeamUtil } from './team.util';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import {
    AddTeamMembersParameter,
    AddTeamMembersResult,
    BaseTeamData,
    BaseTeamParameter,
    CloneTeamParameter,
    CreateTeamParameter,
    CreateTeamResult,
    DeleteTeamMembersParameter,
    DeleteTeamsParameter,
    GetTeamMembersResult,
    GetTeamsParameter,
    PreCreateTeamParameter,
    PredeleteTeamsParameter,
    TeamAccessType,
    TeamApiService,
    TeamAuditData,
    TeamDto,
    TeamMemberDto,
    TeamMembershipRequest,
    TeamPublicData,
    TeamUsage,
    UpdateMembershipRequestParameter,
    UpdateMembershipRequestType,
    UpdateTeamParameter,
} from '@datagalaxy/webclient/team/data-access';
import {
    ImageCategory,
    SetImageParameter,
} from '@datagalaxy/webclient/client/data-access';
import { ClientService } from '../client/client.service';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import {
    DataPortApiService,
    ExportDataOperation,
    ExportDataParameter,
} from '@datagalaxy/webclient/data-port/data-access';
import { buildHtmlUlListFromList } from '@datagalaxy/webclient/utils';
import { UsageTypeName } from '@datagalaxy/dg-object-model';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';

@Injectable({ providedIn: 'root' })
export class TeamService extends BaseService {
    private teamPublicData: TeamPublicData[];

    public get teamChange$() {
        return this.teamChange.asObservable();
    }
    private readonly teamChange = new Subject<TeamDto>();

    public get teamCreate$() {
        return this.teamCreate.asObservable();
    }
    private readonly teamCreate = new Subject<TeamDto>();

    public get teamDelete$() {
        return this.teamDelete.asObservable();
    }
    private readonly teamDelete = new Subject<string>();

    public get membersCountChanged$() {
        return this.membersCountChanged.asObservable();
    }
    private readonly membersCountChanged =
        new Subject<ITeamMembersCountUpdate>();

    public get requestsCountChanged$() {
        return this.requestsCountChanged.asObservable();
    }
    private readonly requestsCountChanged =
        new Subject<ITeamRequestsCountUpdate>();

    public get userJoinTeam$() {
        return this.userJoinTeam.asObservable();
    }
    private readonly userJoinTeam = new Subject<string>();

    public get onImportTeams$() {
        return this.onImportTeams.asObservable();
    }
    private readonly onImportTeams = new Subject<void>();

    constructor(
        private clientService: ClientService,
        private dataPortApiService: DataPortApiService,
        private teamApiService: TeamApiService,
        private translate: TranslateService,
        private dxyModalService: DxyModalService,
        private previewPanelService: PreviewPanelService,
        private functionalLogService: FunctionalLogService,
        private importWizardService: ImportWizardService
    ) {
        super();
        this.subscribeEvents();
    }

    public async getTeamMembers(teamId: string): Promise<GetTeamMembersResult> {
        const param = new BaseTeamParameter(teamId);
        return await this.teamApiService.getTeamMembers(param);
    }

    public async getTeamsPublicData(
        refreshCache?: boolean
    ): Promise<TeamPublicData[]> {
        if (this.teamPublicData && !refreshCache) {
            return this.teamPublicData;
        }
        const param = new BaseServiceParameter();
        const result = await this.teamApiService.getTeamsPublicData(param);
        return (this.teamPublicData = result?.TeamPublicDatas);
    }

    public async getTeams(isAdmin?: boolean): Promise<TeamDto[]> {
        const result = await this.teamApiService.getTeams(
            new GetTeamsParameter(isAdmin)
        );
        return result.Teams;
    }

    public async openCreateTeamModal() {
        return await this.dxyModalService.open<
            TeamCreateModalComponent,
            void,
            CreateTeamResult
        >({ componentType: TeamCreateModalComponent });
    }

    public async deleteTeams(teams: TeamDto[]) {
        if (!teams) {
            return;
        }

        const preDeleteResult = await this.preDeleteTeam(teams);

        if (!preDeleteResult.CanBeDeleted) {
            const workflowUsages = preDeleteResult.Usages.filter(
                (u) => u.UsageTypeName === UsageTypeName.WorkflowTeamUsage
            );
            await this.displayMessageTeamLinkedToWorkflow(
                teams,
                workflowUsages
            );
            return;
        }

        await this.openDeleteTeamModal(teams);
    }

    public getTeamImageUrl(team: BaseTeamData) {
        return this.clientService.getImageUrl(team.TeamImageHash);
    }

    public getTeamFromCache(teamId: string): TeamPublicData {
        return this.teamPublicData?.find((t) => t.TeamUid == teamId);
    }

    public async createTeam(
        teamName: string,
        accessType: TeamAccessType,
        description?: string
    ) {
        const param = new CreateTeamParameter(
            teamName,
            accessType,
            description
        );
        const result = await this.teamApiService.createTeam(param);
        this.notifyTeamCreated(result.CreatedTeam);
        return result;
    }

    public async cloneTeam(teamId: string) {
        const param = new CloneTeamParameter(teamId);
        const result = await this.teamApiService.cloneTeam(param);
        this.notifyTeamCreated(result.CreatedTeam);
        return result;
    }

    public async getTeam(teamId: string) {
        const param = new BaseTeamParameter(teamId);
        const result = await this.teamApiService.getTeam(param);
        return result.Team;
    }

    public async preCreateTeam(teamName: string) {
        const param = new PreCreateTeamParameter(teamName);
        return await this.teamApiService.preCreateTeam(param);
    }

    public async createTeamMembershipRequest(teamId: string) {
        const param = new BaseTeamParameter(teamId);
        return await this.teamApiService.createTeamMembershipRequest(param);
    }

    public async updateTeamMembershipRequest(
        teamId: string,
        updateType: UpdateMembershipRequestType,
        userId?: string
    ) {
        const param = new UpdateMembershipRequestParameter(
            teamId,
            updateType,
            userId
        );
        const result = await this.teamApiService.updateTeamMembershipRequest(
            param
        );
        this.notifyRequestsCountChanged({
            teamId,
            updatedMembers: result.TeamMembers,
            updatedRequests: result.MembershipRequests,
        });
        return result;
    }

    public async openAskMembershipModal(teamId: string) {
        const confirmed = await this.dxyModalService.confirm({
            titleKey: 'UI.Teams.Requests.title',
            messageKey: 'UI.Teams.Requests.modalText',
            confirmButtonKey: 'UI.Dialog.btnConfirm',
        });
        if (!confirmed) {
            return;
        }
        return await this.createTeamMembershipRequest(teamId);
    }

    public async updateTeam(team: TeamDto) {
        const param = new UpdateTeamParameter(
            team.TeamUid,
            team.TeamName,
            team.AccessType,
            team.Description,
            team.Email,
            team.TeamOwners?.map((owner) => owner.UserId)
        );
        const result = await this.teamApiService.updateTeam(param);
        if (
            team.MembersCount != undefined &&
            result.UpdatedTeam.MembersCount != team.MembersCount
        ) {
            const updatedTeam = result.UpdatedTeam;
            this.notifyMembersCountChanged({
                teamId: updatedTeam.TeamUid,
                updatedCount: updatedTeam.MembersCount,
            });
        }
        this.notifyTeamChanged(result.UpdatedTeam);

        return result;
    }

    public async setTeamImage(team: TeamDto, file: File, isDelete?: boolean) {
        const imageCategory = ImageCategory.TeamImage;
        let param: SetImageParameter;
        if (isDelete) {
            param = SetImageParameter.forDelete(
                imageCategory,
                null,
                null,
                team.TeamUid
            );
        } else {
            const fileContent = await DomUtil.readAsDataUrl(file);
            param = new SetImageParameter(
                fileContent,
                file.name,
                file.type,
                imageCategory,
                null,
                null,
                team.TeamUid
            );
        }
        const result = await this.clientService.setImage(param);
        team.TeamImageHash = result.ImageHash;
        this.notifyTeamChanged(team);
        return true;
    }

    public async addTeamMembers(
        team: TeamDto,
        newMemberIds: string[],
        newOwnerIds?: string[],
        isMembershipRequest?: boolean
    ) {
        const param = new AddTeamMembersParameter(
            team.TeamUid,
            newMemberIds,
            newOwnerIds,
            isMembershipRequest
        );
        const result = await this.teamApiService.addTeamMembers(param);
        this.notifyMembersCountChanged({
            teamId: team.TeamUid,
            updatedCount: result.TeamMembers.length,
        });
        return result;
    }

    public async openAddTeamMemberModal(
        team: TeamDto,
        members: TeamMemberDto[]
    ) {
        return await this.dxyModalService.open<
            TeamAddMemberModalComponent,
            IAddTeamMemberModalInput,
            AddTeamMembersResult
        >({
            componentType: TeamAddMemberModalComponent,
            data: { currentMembers: members, team },
        });
    }

    public async openDeleteTeamMembersModal(
        team: TeamDto,
        members: TeamMemberDto[]
    ) {
        const baseTranslationKey = 'UI.Teams.Members.DeleteMembersModal';
        const multipleMembers = members.length > 1;

        const titleKey = multipleMembers
            ? `${baseTranslationKey}.manyMembersTitle`
            : `${baseTranslationKey}.title`;

        const message = multipleMembers
            ? this.translate.instant(`${baseTranslationKey}.manyMembersLbl`, {
                  teamName: team.TeamName,
                  membersCount: members.length,
              })
            : this.translate.instant(`${baseTranslationKey}.messageLbl`, {
                  teamName: team.TeamName,
                  memberName: members[0].FullName,
              });

        const result = await this.dxyModalService.prompt({
            titleKey,
            message,
            featureCode: 'TEAM_MEMBER,D',
            userInputValidationMethod: (input?: string) => {
                return multipleMembers
                    ? members.length == +input
                    : members[0].FullName.toLocaleLowerCase() ==
                          input?.toLocaleLowerCase();
            },
        });

        if (result?.trim()) {
            const memberIds = members.map((member) => member.UserId);
            const param = new DeleteTeamMembersParameter(
                team.TeamUid,
                memberIds
            );
            const result = await this.teamApiService.deleteTeamMembers(param);
            this.notifyMembersCountChanged({
                teamId: team.TeamUid,
                updatedCount: team.MembersCount - members.length,
                deletedMembersId: memberIds,
            });
            return result;
        } else {
            this.functionalLogService.logFunctionalAction(
                'CANCEL_DELETE_TEAM_MEMBER',
                CrudOperation.R
            );
        }
    }

    public async exportTeams(exportedIds: string[]) {
        const parameter = new ExportDataParameter(
            null,
            ExportDataOperation.ExportTeams,
            null,
            exportedIds
        );
        await this.dataPortApiService.exportData(parameter);
        this.functionalLogService.logFunctionalAction(
            'ADMIN_CS_TEAMS',
            CrudOperation.A,
            CrudActionType.Export
        );
    }

    public async getTeamHistory(teamId: string): Promise<TeamAuditData[]> {
        const param = new BaseTeamParameter(teamId);
        const result = await this.teamApiService.getTeamHistory(param);
        return result?.TeamAuditDataList;
    }

    /**
     * Retrieve glyphClass for a TeamDto or a TeamPublicData
     * @param team team to get icon class
     * @param skipPrivate if true, it will return a glyphClass even if there is no read access
     */
    public getTeamGlyphClass(team: BaseTeamData, skipPrivate?: boolean) {
        const hasReadAccess =
            team instanceof TeamDto
                ? TeamUtil.hasReadAccess(team)
                : (team as TeamPublicData)?.HasTeamReadAccess;
        if (!hasReadAccess && !skipPrivate) {
            return 'glyph-lock';
        }
        return team.TeamImageHash
            ? ''
            : `glyph-team dg5-palette bg ${UiUtil.getColorClassFromString(
                  team.TeamName
              )}`;
    }

    public notifyMembersCountChanged(countUpdate: ITeamMembersCountUpdate) {
        this.membersCountChanged.next(countUpdate);
    }

    public notifyRequestsCountChanged(countUpdate: ITeamRequestsCountUpdate) {
        this.requestsCountChanged.next(countUpdate);
    }

    public notifyJoinTeam(teamId: string) {
        this.userJoinTeam.next(teamId);
    }

    public notifyTeamChanged(team: TeamDto) {
        this.teamChange.next(team);
    }
    public notifyTeamCreated(team: TeamDto) {
        this.teamCreate.next(team);
    }
    public notifyTeamDeleted(teamId: string) {
        this.teamDelete.next(teamId);
    }

    public getTeamAccessIcon(accessType: TeamAccessType): string {
        switch (accessType) {
            case TeamAccessType.Private:
                return 'glyph-lock';
            case TeamAccessType.Limited:
                return 'glyph-user-unlock';
            case TeamAccessType.Open:
                return 'glyph-person';
        }
    }

    public async openTeamPreviewPanel(panelInputs: ITeamPreviewPanelInputs) {
        await this.previewPanelService.setupPanel({
            component: TeamPreviewComponent,
            inputs: {
                teamData: panelInputs.teamData,
                showJoinBtn: panelInputs.showJoinBtn,
                isAdmin: panelInputs.isAdmin,
                defaultTabIndex: panelInputs.defaultTabIndex,
            },
        });
    }

    public clearCache() {
        this.teamPublicData = null;
    }

    public async importTeams(functionalLog: string) {
        const importContext = new ImportContext(
            new SpaceIdentifier(null),
            ImportTarget.Teams
        );
        importContext.currentOperation = EOperationType.Teams;
        importContext.onModalClosed = async (isImportDone) => {
            if (!isImportDone) {
                return;
            }
            this.functionalLogService.logFunctionalAction(
                functionalLog,
                CrudOperation.A,
                CrudActionType.Import
            );
            this.onImportTeams.next();
        };
        importContext.currentModule = ImportModule.getModule(
            importContext.currentOperation,
            importContext
        );
        await this.importWizardService.openImportWizardModal(importContext);
    }

    private async deleteTeamsAndNotify(teamIds: string[]) {
        const param = new DeleteTeamsParameter(teamIds);
        const result = await this.teamApiService.deleteTeams(param);
        teamIds.forEach((teamId) => this.notifyTeamDeleted(teamId));
        return result;
    }

    private async openDeleteTeamModal(teams: TeamDto[]) {
        let promptOptions: IPromptOptions;
        const baseTranslationKey = 'UI.Teams.DeleteModal';
        if (teams.length > 1) {
            promptOptions = {
                titleKey: `${baseTranslationKey}.manyTeamsTitle`,
                message: this.translate.instant(
                    `${baseTranslationKey}.manyTeamsLbl`,
                    { teamsCount: teams.length }
                ),
                userInputValidationMethod: (input?: string) =>
                    teams.length == +input,
            };
        } else {
            const teamName = teams[0].TeamName;
            const messageKey = teams[0].MembersCount
                ? 'existingMembersLbl'
                : 'noMembersLbl';
            const message = this.translate.instant(
                `${baseTranslationKey}.${messageKey}`,
                { teamName }
            );

            promptOptions = {
                titleKey: `${baseTranslationKey}.title`,
                message,
                userInputValidationMethod: (input?: string) =>
                    teamName.toLocaleLowerCase() == input?.toLocaleLowerCase(),
            };
        }

        promptOptions.featureCode = 'TEAM,D';

        const result = await this.dxyModalService.prompt(promptOptions);
        if (result?.trim()) {
            await this.deleteTeamsAndNotify(teams.map((team) => team.TeamUid));
        } else {
            this.functionalLogService.logFunctionalAction(
                'CANCEL_DELETE_TEAM',
                CrudOperation.R
            );
        }
    }

    private async preDeleteTeam(teams: TeamDto[]) {
        const params = new PredeleteTeamsParameter();
        params.TeamGuids = teams.map((team) => team.TeamUid);
        return await this.teamApiService.preDeleteTeam(params);
    }

    private async displayMessageTeamLinkedToWorkflow(
        teams: TeamDto[],
        workflowUsages: TeamUsage[]
    ) {
        let teamsNotDeletableIds: string[] = [];
        workflowUsages.forEach((usage) => {
            teamsNotDeletableIds = teamsNotDeletableIds.concat(usage.TeamGuids);
        });

        const teamsNotDeletable = teams.filter(
            (t) => teamsNotDeletableIds.indexOf(t.TeamUid) != -1
        );
        let workflows = workflowUsages.map((u) => u.DisplayName);

        const filteredWorkflows = CollectionsHelper.distinct(workflows);

        const messageParams = {
            teamCount: teamsNotDeletable.length,
            workflowCount: workflows.length,
            teamNames: `"${teamsNotDeletable
                .map((t) => t.TeamName)
                .join('", "')}"`,
            workflowNames: buildHtmlUlListFromList(filteredWorkflows),
        };
        await this.dxyModalService.inform({
            title: this.translate.instant(`UI.Teams.WorkflowControl.Title`, {
                count: teamsNotDeletable.length,
            }),
            message: this.translate.instant(
                `UI.Teams.WorkflowControl.Message`,
                messageParams
            ),
        });
    }

    private subscribeEvents() {
        this.teamChange$.subscribe((team) => this.onTeamChange(team));
        this.teamCreate$.subscribe((team) => this.onTeamCreate(team));
        this.teamDelete$.subscribe((teamId) => this.onTeamDelete(teamId));
    }

    private onTeamChange(team: TeamDto) {
        const publicTeam = this.teamPublicData?.find(
            (d) => team.TeamUid === d.TeamUid
        );
        if (!publicTeam) {
            return;
        }

        publicTeam.TeamImageHash = team.TeamImageHash;
        publicTeam.HasTeamReadAccess = TeamUtil.hasReadAccess(team);
        publicTeam.TeamName = team.TeamName;
    }

    private onTeamCreate(team: TeamDto) {
        this.teamPublicData?.push(TeamUtil.publicTeamFromTeamDto(team));
    }

    private onTeamDelete(teamId: string) {
        this.teamPublicData = this.teamPublicData?.filter(
            (d) => d.TeamUid === teamId
        );
    }
}

export interface ITeamMembersCountUpdate {
    teamId: string;
    updatedCount: number;
    updatedTeam?: TeamDto;
    deletedMembersId?: string[];
}

export interface ITeamRequestsCountUpdate {
    teamId: string;
    updatedMembers: TeamMemberDto[];
    updatedRequests: TeamMembershipRequest[];
}

export interface ITeamPreviewPanelInputs {
    teamData: TeamDto;
    showJoinBtn?: boolean;
    isAdmin?: boolean;
    defaultTabIndex?: number;
}
