<button class="nav-link-button" (click)="openEntityPreview()">
    <div *ngIf="hasIcon" class="icon-wrapper">
        <i
            *ngIf="iconGlyphClass"
            [ngClass]="iconGlyphClass"
            [matTooltip]="iconTooltip"
        ></i>
        <img
            *ngIf="technoImgUrl"
            [src]="technoImgUrl"
            [matTooltip]="technoTooltip"
        />
    </div>
    <div class="text-wrapper" dxyEllipsisTooltip>{{ displayName }}</div>
</button>
