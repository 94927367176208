import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import * as moment from 'moment/moment';
import { VersionStatus } from './connector.types';
import { ConnectorVersionDto } from '@datagalaxy/webclient/connectivity/data-access';
import { DataStructure } from '@datagalaxy/webclient/connectivity/data-access';
import { IConfigFieldDef } from './connection-form/form-configs/types/interfaces/config-field-def.interface';
import { IConnectionFields } from './connection-form/form-configs/types/interfaces/connection-fields.interface';

export class ConnectorUtil {
    public static parseDate(date?: moment.Moment | string): string | null {
        if (date) {
            const momentDate = typeof date === 'string' ? moment(date) : date;
            const year = momentDate.year();
            return year > 1 ? momentDate.format('L, LTS') : null;
        } else {
            return null;
        }
    }

    public static getVersionDtosForSelection(
        dtos: ConnectorVersionDto[],
        clone = false,
        maxReleases = 8
    ) {
        const sortedDtos = CollectionsHelper.distinctByProperty(
            dtos,
            (v) => v.Version
        )
            .sort((a, b) => ConnectorUtil.compareVersion(a.Version, b.Version))
            .reverse();
        const localDtos = clone ? sortedDtos.map(CoreUtil.clone) : sortedDtos;
        const recommended = localDtos.find(
            (dto) => dto.Status == VersionStatus.RECOMMENDED
        );
        const beta = localDtos.find((dto) => dto.Status == VersionStatus.BETA);
        const releases = localDtos
            .filter((dto) => dto.Status == VersionStatus.RELEASE)
            .slice(0, maxReleases);
        return {
            available: [recommended, beta, ...releases].filter((o) => o),
            selected: recommended ?? beta ?? releases[0],
        };
    }

    public static buildPayload(
        fields: IConnectionFields,
        conf: IConfigFieldDef[],
        dataStructure: DataStructure
    ) {
        return conf
            .filter((c) =>
                ConnectorUtil.isConfigFieldIncludedInPayload(
                    fields,
                    c,
                    dataStructure
                )
            )
            .reduce((p, c) => {
                p[c.payloadField] = ConnectorUtil.getFieldValue(fields, c);
                if (c.dependencies) {
                    c.dependencies.forEach((dep) => {
                        if (
                            ConnectorUtil.isConfigFieldIncludedInPayload(
                                fields,
                                dep.field,
                                dataStructure
                            )
                        ) {
                            p[dep.field.payloadField] =
                                ConnectorUtil.getFieldValue(fields, dep.field);
                        }
                    });
                }
                return p;
            }, {});
    }

    public static getFieldValue(
        fields: IConnectionFields,
        cf: IConfigFieldDef
    ) {
        if (cf.formField) {
            let fieldValue = fields[cf.formField];
            if (cf.isFilter && typeof fieldValue === 'string') {
                fieldValue = fieldValue.split(',').map((v) => v.trim());
            }
            if (cf.format) {
                return cf.format(fieldValue);
            } else {
                return fieldValue;
            }
        } else {
            return cf.payloadValue;
        }
    }

    public static buildMaskList(savedCredentials: {
        [credentialKey: string]: string;
    }) {
        const credentialKeys = Object.keys(savedCredentials).filter((k) =>
            k.includes('structure-masks')
        );
        return credentialKeys?.map((k) => savedCredentials[k]) ?? [];
    }

    public static getDefaultPort(pluginName: string) {
        switch (pluginName) {
            case 'sqlserver':
            case 'azuresql':
                return 1433;
            case 'snowflake':
                return 443;
            default:
                return null;
        }
    }

    // defaultName (x) if defaultName already exists
    public static buildDefaultName<T>(
        dataArray: T[],
        defaultName: string,
        getName: (data: T) => string
    ) {
        const filteredNames = dataArray
            .map(getName)
            .filter((name) => ConnectorUtil.isDefaultName(defaultName, name));
        if (!filteredNames.length) {
            return defaultName;
        }
        const max = filteredNames
            .map((name) => parseInt(name.match(/\((\d+)\)$/)?.[1] || '0', 10))
            .reduce((a, b) => Math.max(a, b), 0);

        return `${defaultName} (${1 + max})`;
    }

    public static isDefaultName(defaultName: string, name: string): boolean {
        const defaultNameRegex = new RegExp(
            `^(${defaultName.toLowerCase()}) ?(\\(\\d+\\))?$`
        );
        return defaultNameRegex.test(name.toLowerCase());
    }

    private static compareVersion(versionA: string, versionB: string) {
        const split = (v: string) => v.split('.').map((d) => parseInt(d, 10));
        const [majorA, minorA, patchA] = split(versionA);
        const [majorB, minorB, patchB] = split(versionB);
        if (majorA !== majorB) {
            return majorA - majorB;
        }
        if (minorA !== minorB) {
            return minorA - minorB;
        }
        if (patchA !== patchB) {
            return patchA - patchB;
        }
        return 0;
    }

    private static isConfigFieldIncludedInPayload(
        fields: IConnectionFields,
        config: IConfigFieldDef,
        dataStructure: DataStructure
    ): boolean {
        return !!(
            !config.isHidden?.(fields) &&
            !config.isDisabled?.(fields) &&
            (!config.isUrnField || dataStructure == DataStructure.Urn) &&
            config.payloadField
        );
    }
}
