<div class="accept-reject-container" *ngIf="!isLoading">
    <div
        class="button-container"
        (click)="onRejectAllClick()"
        [class.disabled]="areButtonsDisabled"
    >
        <button
            dxyIconButton
            [disabled]="areButtonsDisabled"
            color="transparent"
        >
            <i class="glyph-cancelsearch"></i>
        </button>
        <span translate="UI.Suggestions.Panel.rejectAll"></span>
    </div>
    <div
        class="button-container"
        (click)="onAcceptAllClick()"
        [class.disabled]="areButtonsDisabled"
    >
        <button
            dxyIconButton
            [disabled]="areButtonsDisabled"
            color="transparent"
        >
            <i class="glyph-check"></i>
        </button>
        <span translate="UI.Suggestions.Panel.acceptAll"></span>
    </div>
</div>
<dxy-spinner *ngIf="isLoading"></dxy-spinner>
