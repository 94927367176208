<label *ngIf="showTypeLabel" translate> DgServerTypes.Column.name.Type </label>
<div *ngIf="value" class="dg_select">
    <mat-select
        *ngIf="!readonly"
        #matSelect
        [(ngModel)]="value.dataType"
        (ngModelChange)="onDataTypeChange()"
        [disabled]="disabled"
    >
        <mat-option *ngFor="let o of availableDataTypes" [value]="o">
            {{ getOptionText(o) }}
        </mat-option>
    </mat-select>
    <div *ngIf="readonly" class="form-control-static">
        {{ getOptionText(value.dataType) }}
    </div>
    <div *ngIf="showSize" class="input-separator"></div>
</div>
<ng-container *ngIf="showSize">
    <label *ngIf="!noLabels" translate> DgServerTypes.Column.name.Size </label>
    <input
        *ngIf="!readonly && value"
        type="number"
        matInput
        [(ngModel)]="value.size"
        [disabled]="disabled"
    />
    <div *ngIf="readonly" class="form-control-static">
        {{ value?.size }}
    </div>
    <div *ngIf="showPrecision" class="input-separator"></div>
</ng-container>
<ng-container *ngIf="showPrecision">
    <label *ngIf="!noLabels" translate>
        DgServerTypes.Column.name.Precision
    </label>
    <input
        *ngIf="!readonly"
        type="number"
        matInput
        [(ngModel)]="value.precision"
        [disabled]="disabled"
    />
    <div *ngIf="readonly" class="form-control-static">
        {{ value.precision }}
    </div>
</ng-container>
<div *ngIf="readonly" class="input-separator"></div>
