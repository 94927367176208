export class BrowserInformation {
    public appName: string;
    public userAgent: string;
    public appVersion: string;
    public appCodeName: string;
    public platform: string;
    public devicePixelRatio = 1;
    public cookieEnabled: boolean;
    public language: string;
    public outResolution: string;
    public inResolution: string;
}
