import { DragHandle, IDragHandleOptions } from '@datagalaxy/core-2d-util';
import { OrthoSegment } from './OrthoSegment';

/** Handle to drag a segment of an orthogonal route */
export class OrthoHandle extends DragHandle<IOrthoHandleOptions> {
    private static readonly defaultLength = 20;
    private static readonly defaultBreadth = 10;

    public declare useStyle: boolean;
    public dbg?: string | number;

    public get vh() {
        const s = this.segment;
        return s.isVertical
            ? 'v'
            : s.isHorizontal
            ? 'h'
            : (s.prev || s.next)?.isHorizontal
            ? 'v'
            : 'h';
    }
    public get isFirstOrLast() {
        return this.segment.isFirstOrLast;
    }
    public get cursorNsew() {
        return this.segment.isVertical ? 'ew-resize' : 'ns-resize';
    }
    public get cursorRowCol() {
        return this.segment.isVertical ? 'col-resize' : 'row-resize';
    }

    constructor(
        public readonly segment: OrthoSegment<OrthoHandle>,
        options: IOrthoHandleOptions
    ) {
        super(options);
        segment.data = this;
        const {
            length = OrthoHandle.defaultLength,
            breadth = OrthoHandle.defaultBreadth,
        } = this.options;
        if (segment.isVertical) {
            this.width = breadth;
            this.height = length;
        } else {
            this.width = length;
            this.height = breadth;
        }
    }

    /** Updates the handle's DOM element position and propagates the call to other handles */
    public update(_from?: OrthoHandle) {
        const segmentMidPoint = this.segment.getMidPoint();
        this.x = segmentMidPoint.x - this.width / 2;
        this.y = segmentMidPoint.y - this.height / 2;
        super.update();
        const prev = this.segment.prev?.data;
        if (prev && prev != _from) {
            prev.update(this);
        }
        const next = this.segment.next?.data;
        if (next && next != _from) {
            next.update(this);
        }
        return this;
    }
}

export interface IOrthoHandleOptions extends IDragHandleOptions {
    /** default to 20 */
    length?: number;
    /** default to 10 */
    breadth?: number;
    /** css class to be applied to each handle */
    className?: string;
    /** Type of cursor:
     * - 0: no cursor
     * - 1: (default) ns-resize/ew-resize,
     * - 2: row-resize/col-resize */
    cursor?: 0 | 1 | 2;
}
