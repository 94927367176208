import { IRect2Sides, RectSide } from '.';
import {
    CardinalCorner,
    CardinalPlacement,
    CardinalPosition,
    CardinalSide,
    ICardinalPlacementInfo,
} from './cardinal.types';

export class CardinalUtil {
    public static readonly allCorners: Readonly<CardinalCorner>[] = [
        'nw',
        'ne',
        'se',
        'sw',
    ];
    public static readonly allPositions: Readonly<CardinalPosition>[] = [
        'nw',
        'n',
        'ne',
        'e',
        'se',
        's',
        'sw',
        'w',
    ];
    public static readonly horizontalSides: Readonly<CardinalSide>[] = [
        'e',
        'w',
    ];

    public static readonly cornerPositions: Readonly<CardinalPosition>[] =
        CardinalUtil.allCorners;

    public static isASide(cp: CardinalPosition) {
        return cp && cp.length == 1;
    }

    public static resizeCursor(cp: CardinalPosition) {
        switch (cp) {
            case 'n':
            case 's':
                return 'ns-resize';
            case 'e':
            case 'w':
                return 'ew-resize';
            case 'ne':
            case 'sw':
                return 'nesw-resize';
            case 'nw':
            case 'se':
                return 'nwse-resize';
            default:
                return null;
        }
    }

    public static getRectSides(
        cp: CardinalPosition,
        res: IRect2Sides = {}
    ): IRect2Sides {
        switch (cp) {
            case 'nw':
                res.lr = RectSide.left;
                res.tb = RectSide.top;
                break;
            case 'n':
                res.tb = RectSide.top;
                break;
            case 'ne':
                res.lr = RectSide.right;
                res.tb = RectSide.top;
                break;
            case 'e':
                res.lr = RectSide.right;
                break;
            case 'se':
                res.lr = RectSide.right;
                res.tb = RectSide.bottom;
                break;
            case 's':
                res.tb = RectSide.bottom;
                break;
            case 'sw':
                res.lr = RectSide.left;
                res.tb = RectSide.bottom;
                break;
            case 'w':
                res.lr = RectSide.left;
                break;
        }
        return res;
    }

    public static getPlacementInfo(
        cp: CardinalPlacement
    ): ICardinalPlacementInfo {
        switch (cp) {
            case 'n':
                return { pos: 'n', vh: 'h', dir: 'bottom' };
            case 'neh':
                return { pos: 'ne', vh: 'h', dir: 'left' };
            case 'nev':
                return { pos: 'ne', vh: 'v', dir: 'bottom' };
            case 'e':
                return { pos: 'e', vh: 'v', dir: 'left' };
            case 'sev':
                return { pos: 'se', vh: 'v', dir: 'top' };
            case 'seh':
                return { pos: 'se', vh: 'h', dir: 'left' };
            case 's':
                return { pos: 's', vh: 'h', dir: 'top' };
            case 'swh':
                return { pos: 'sw', vh: 'h', dir: 'right' };
            case 'swv':
                return { pos: 'sw', vh: 'v', dir: 'top' };
            case 'w':
                return { pos: 'w', vh: 'v', dir: 'right' };
            case 'nwv':
                return { pos: 'nw', vh: 'v', dir: 'bottom' };
            case 'nwh':
                return { pos: 'nw', vh: 'h', dir: 'right' };
        }
    }
}
