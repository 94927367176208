import {
    OrthoRect,
    OrthogonalConnectorOpts,
    OrthoSide,
} from './orthogonal-path-finder';
import {
    IOrthogonalConnectorRouteScenario,
    IOrthogonalConnectorRouteTestSuite,
} from './orthogonal-path-finder-test-cases';
import { Point } from '@datagalaxy/core-2d-util';

export type OrthoRotationDegree = 0 | 90 | 180 | 270;

/**
 * Calculates and returns a square OrthoRect with its origin point adjusted based on the provided parameters.
 *
 * @param inflatedOrigin - The inflated origin Point.
 * @param margin - The margin value to be added to the origin's x and y coordinates.
 * @param size - The size of the square.
 * @param globalMargin - The global margin to be added to the origin's x and y coordinates.
 * @returns A OrthoRect representing the square with the adjusted origin point.
 */
export function calculateSquareRect(
    inflatedOrigin: Point,
    margin: number,
    size: number,
    globalMargin: number
): OrthoRect {
    return {
        left: inflatedOrigin.x + globalMargin + margin,
        top: inflatedOrigin.y + globalMargin + margin,
        width: size,
        height: size,
    };
}

function rotate90Degrees(point: Point): Point {
    // Perform a 90-degree counterclockwise rotation on the point
    const rotatedX = -point.y;
    const rotatedY = point.x;

    return { x: rotatedX, y: rotatedY };
}

/**
 * Rotates two points by 90 degrees counterclockwise around their midpoint
 * and ensures that at least one of the rotated points has positive values.
 * @param {Point} point1 - The first point to rotate.
 * @param {Point} point2 - The second point to rotate.
 * @returns {[Point, Point]} - An array containing the rotated and realigned points.
 */
function rotatePoints90DegreesAndRealign(
    point1: Point,
    point2: Point
): { pointA: Point; pointB: Point } {
    // Rotate both points by 90 degrees
    const rotatedPoint1 = rotate90Degrees(point1);
    const rotatedPoint2 = rotate90Degrees(point2);

    // Calculate the minimum x and y values among the rotated points
    const minX = Math.min(rotatedPoint1.x, rotatedPoint2.x);
    const minY = Math.min(rotatedPoint1.y, rotatedPoint2.y);

    // Adjust both rotated points to ensure they are positive
    const realignedPoint1: Point = {
        x: rotatedPoint1.x - minX,
        y: rotatedPoint1.y - minY,
    };

    const realignedPoint2: Point = {
        x: rotatedPoint2.x - minX,
        y: rotatedPoint2.y - minY,
    };

    return { pointA: realignedPoint1, pointB: realignedPoint2 };
}

export function rotatePointsAndRealign(
    pointA: Point,
    pointB: Point,
    angle: OrthoRotationDegree
) {
    let point1 = pointA;
    let point2 = pointB;
    for (let i = 0; i < angle / 90; i++) {
        let res = rotatePoints90DegreesAndRealign(point1, point2);
        point1 = res.pointA;
        point2 = res.pointB;
    }
    return { pointA: point1, pointB: point2 };
}

export function getRouteConfig(
    options: IOrthogonalConnectorRouteTestSuite,
    scenario: IOrthogonalConnectorRouteScenario,
    rotation: OrthoRotationDegree = 0
): OrthogonalConnectorOpts {
    const { boxSize, boxMargin, spaceBetween, globalMargin } = options.settings;
    const points = options.getPoints(boxSize, boxMargin, spaceBetween);
    const { pointA, pointB } = rotatePointsAndRealign(
        points.pointA,
        points.pointB,
        rotation
    );

    return {
        pointA: {
            shape: calculateSquareRect(
                pointA,
                boxMargin,
                boxSize,
                globalMargin
            ),
            side: rotateSide(scenario.sideA, rotation),
            distance: 0.5,
        },
        pointB: {
            shape: calculateSquareRect(
                pointB,
                boxMargin,
                boxSize,
                globalMargin
            ),
            side: rotateSide(scenario.sideB, rotation),
            distance: 0.5,
        },
        shapeMargin: boxMargin,
        globalBoundsMargin: globalMargin,
        globalBounds: { left: 0, top: 0, width: 500, height: 500 },
    };
}

export type PathDirection = 'up' | 'down' | 'right' | 'left';

export function pathToDirections(path: Point[]): PathDirection[] {
    const directions: PathDirection[] = [];

    for (let i = 0; i < path.length - 1; i++) {
        const currentPoint = path[i];
        const nextPoint = path[i + 1];

        if (nextPoint.x > currentPoint.x) {
            directions.push('right');
        } else if (nextPoint.x < currentPoint.x) {
            directions.push('left');
        } else if (nextPoint.y > currentPoint.y) {
            directions.push('down');
        } else if (nextPoint.y < currentPoint.y) {
            directions.push('up');
        }
    }

    return directions;
}

export function rotateDirection(
    directions: PathDirection[],
    angle: OrthoRotationDegree
) {
    let directions2 = directions;
    for (let i = 0; i < angle / 90; i++) {
        directions2 = rotate90DegreesDirection(directions2);
    }
    return directions2;
}

export function rotate90DegreesDirection(
    directions: PathDirection[]
): PathDirection[] {
    return directions.map((direction) => {
        switch (direction) {
            case 'up':
                return 'right';
            case 'right':
                return 'down';
            case 'down':
                return 'left';
            case 'left':
                return 'up';
        }
    });
}

export function rotateSide(side: OrthoSide, angle: OrthoRotationDegree) {
    let side2 = side;
    for (let i = 0; i < angle / 90; i++) {
        side2 = rotate90DegreesSide(side2);
    }
    return side2;
}

export function rotate90DegreesSide(side: OrthoSide): OrthoSide {
    switch (side) {
        case 'top':
            return 'right';
        case 'right':
            return 'bottom';
        case 'bottom':
            return 'left';
        case 'left':
            return 'top';
    }
}

export function debugUrl(options: OrthogonalConnectorOpts) {
    const urlParams = new URLSearchParams({
        boxSize: options.pointA.shape.width.toString(),
        boxMargin: options.shapeMargin.toString(),
        globalMargin: options.globalBoundsMargin.toString(),
        sideAx: options.pointA.shape.left.toString(),
        sideAy: options.pointA.shape.top.toString(),
        sideBx: options.pointB.shape.left.toString(),
        sideBy: options.pointB.shape.top.toString(),
        mirror: 'true',
        sideA: options.pointA.side,
        sideB: options.pointB.side,
    });

    return `http://localhost:4200/ortho/details?${urlParams.toString()}`;
}
