<!--Header-->
<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Connector.Wizard.Step3.Test.TestErrorDetail
    </h3>
    <button mat-icon-button (click)="close()" class="modal-close-btn"></button>
</div>

<!--Body-->
<div mat-dialog-content>
    <div class="message">{{ message }}</div>
</div>

<!--Footer-->
<div class="modal-footer">
    <button mat-flat-button (click)="close()">
        <span translate>UI.Global.btnClose</span>
    </button>
    <button mat-flat-button color="primary" (click)="copy()">
        <span translate>Import.GenericImportWizard.Global.BtnCopy</span>
    </button>
</div>
