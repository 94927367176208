import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { ObjectTaskType } from './ObjectTaskType';
import { ObjectTaskStatus } from './ObjectTaskStatus';
import { BaseDTO, HierarchicalData, IHasHddData, ServerType } from '../../core';

@inheritSerialization(BaseDTO)
export class EntityTaskDTO extends BaseDTO implements IHasHddData {
    @deserialize public ReferenceId: string;
    @deserialize public Title: string;
    @deserialize public Text: string;
    @deserialize public AssignedToUserId: string;
    @deserializeAs(ObjectTaskStatus) Status: ObjectTaskStatus;
    @deserialize public Response: string;
    @deserializeAs(HierarchicalData) public HddData: HierarchicalData;
    @deserializeAs(ObjectTaskType) Type: ObjectTaskType;
    @deserialize public DueTime: string;

    // NOTE: This property must be filled manually before usage after loading DTO from server
    // See DataUtil.adaptEntityTaskDto
    public RawText: string = null;

    get DataServerType() {
        return ServerType.ObjectTask;
    }
    get entityDisplayName() {
        return (
            this.HddData && this.HddData.Data && this.HddData.Data.DisplayName
        );
    }
    get titleAndRawText() {
        return (this.Title || '') + '\n' + (this.RawText || '');
    }
}
