import { Inject, Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { ConnectorPackage, PackagesResponse } from './connector';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ConnectorDownloadApiService {
    private quotedKeyValue = /\s*(?<key>\w+)\s*=\s*"(?<value>.*)"\s*/;

    constructor(
        @Inject('RestConnectivityApiService')
        private restApiService: RestApiService
    ) {}

    public async getConnectorPackages(): Promise<ConnectorPackage[]> {
        const response = await this.restApiService.getPromise<PackagesResponse>(
            '/packages'
        );
        return response.connectors;
    }

    public async downloadConnectorPackage(name: string): Promise<{
        filename: string;
        contents: ArrayBuffer | null;
    }> {
        const httpResponse = await this.restApiService.downloadPromise(
            `/packages/${name}/latest/contents.jar`
        );
        const filename =
            this.getFilenameFromHeader(httpResponse.headers) ||
            `datagalaxy-plugin-${name}.jar`;
        return {
            filename,
            contents: httpResponse.body,
        };
    }

    public async downloadConnectorDesktop(arch: '64' | '32'): Promise<{
        filename: string;
        contents: ArrayBuffer | null;
    }> {
        const httpResponse = await this.restApiService.downloadPromise(
            `/packages/desktop/latest/${arch}bits.zip`
        );
        const filename =
            this.getFilenameFromHeader(httpResponse.headers) ||
            `datagalaxy-desktop-connector-${arch}.zip`;
        return {
            filename,
            contents: httpResponse.body,
        };
    }

    private getFilenameFromHeader(httpHeaders: HttpHeaders): string | null {
        const contentDisposition = httpHeaders.get('content-disposition');
        const parts = contentDisposition?.split(';') || [];
        for (const entry of parts) {
            const found = entry.match(this.quotedKeyValue);
            const key = found?.groups?.['key']?.toLowerCase() || '';
            if (key === 'filename') {
                return found?.groups?.['value'] || null;
            }
        }
        return null;
    }

    public getPluginImageUrl(name: string) {
        return this.restApiService.url(
            `/packages/${name}/latest/icon500.png?inline`
        );
    }
}
