import { SlackIntegrationActivatedEvent } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IDgEventNavLink,
    IEventTranslationInputs,
} from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';
import { resolveUserNotificationsSettingsUrl } from '../common/dg-event-translation-nav-links.helpers';

/**
 * ## Role
 * Adapter to get SlackIntegrationActivatedEvent translation inputs
 */
@registerTypedEventTranslationAdapter([SlackIntegrationActivatedEvent])
export class SlackIntegrationActivatedEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: SlackIntegrationActivatedEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);
        this.addNavLinks(inputs);
        return inputs;
    }

    private addNavLinks(data: IEventTranslationInputs) {
        data.navLinks = [this.getConnectSlackNavLink()];
    }

    private getConnectSlackNavLink(): IDgEventNavLink {
        return {
            linkCssClass: 'nav-link-slack-connection',
            relativeUrl: resolveUserNotificationsSettingsUrl(this.event),
        };
    }
}
