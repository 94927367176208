import { FieldType } from '../../types/enums/field-type.enum';

export const starburstConfig = [
    {
        payloadField: 'protocol',
        formField: 'protocol',
        formMandatory: true,
        type: FieldType.select,
        selectValues: ['http', 'https'],
    },
    {
        payloadField: 'server',
        formField: 'hostname',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'port',
        formField: 'port',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'user',
        formField: 'username',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        formMandatory: true,
        isPassword: true,
    },
];
