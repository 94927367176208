import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import {
    Connection,
    DataStructure,
    ImportHistory,
} from '@datagalaxy/webclient/connectivity/data-access';
import { Injectable } from '@angular/core';
import { ToasterService } from '../services/toaster.service';
import { NextObserver } from 'rxjs';
import { ConnectivityService } from './connectivity.service';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    ConnectionFormService,
    IS_OTHER_USER_PAT,
    OUTPUT_ORPHANED_OBJECTS_HANDLING,
} from './connection-form/connection-form.service';
import { ISaveConnectionParams } from './connector.types';
import { ImportEntityTarget } from './dxy-target-selection/target-entity-selector.types';
import {
    EntityType,
    EntityTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ConnectionSettingsService } from './connection-form/connection-settings.service';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { CurrentSpaceService } from '../services/currentSpace.service';
import { ISavedConnectionRow } from './saved-connections.types';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { DxyConnectorImportHistoryModalComponent } from './dxy-connector-import-history-modal/dxy-connector-import-history-modal.component';
import { IImportHistoryModalResolve } from './dxy-connector-import-history-modal/connector-import-history-modal.types';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { DxyConnectorSchedulerModalComponent } from './dxy-connector-scheduler-modal/dxy-connector-scheduler-modal.component';
import { IConnectorSchedulerModalData } from './dxy-connector-scheduler-modal/dxy-connector-scheduler-modal.types';
import { ConnectorStateService } from './connector-state.service';
import { ConnectionEntitySelectionService } from './connection-form/connection-entity-selection.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityCreator } from '@datagalaxy/webclient/entity/feature';
import { EntityService } from '../shared/entity/services/entity.service';

@Injectable({ providedIn: 'root' })
export class ConnectorConnectionService {
    constructor(
        private connectionFormService: ConnectionFormService,
        private connectionSettingsService: ConnectionSettingsService,
        private connectorStateService: ConnectorStateService,
        private connectionEntitySelectionService: ConnectionEntitySelectionService,
        private connectivityService: ConnectivityService,
        private entityCreator: EntityCreator,
        private entityService: EntityService,
        private dxyModalService: DxyModalService,
        private currentSpaceService: CurrentSpaceService,
        private functionalLogService: FunctionalLogService,
        private toasterService: ToasterService
    ) {}

    public get importUpdateCalled$() {
        return this.connectorStateService.importUpdateCalled$;
    }

    public get savedConnectionsRefreshCalled$() {
        return this.connectorStateService.savedConnectionsRefreshCalled$;
    }

    public refreshSavedConnections() {
        this.connectorStateService.refreshSavedConnections();
    }

    public async getConnections(): Promise<Connection[]> {
        const result = await this.connectivityService.getConnections();
        return result.connections;
    }

    public getConnectorImageUrl(connectorName: string) {
        return this.connectivityService.getConnectorImageUrl(connectorName);
    }

    /**
     * Load target entities.
     */
    public async loadEntities() {
        const searchValues = [
            EntityType.RelationalModel,
            EntityType.NonRelationalModel,
            EntityType.NoSqlModel,
            EntityType.TagBase,
            EntityType.Application,
        ].map((value) => EntityType[value]);
        const typeFilter = new Filter(
            '_EntityType',
            FilterOperator.ListContains,
            searchValues
        );
        const response = await this.entityService.getEntities(
            this.currentSpaceService.getCurrentSpace(),
            [ServerType.Model, ServerType.SoftwareElement],
            true,
            0,
            5000,
            [typeFilter],
            ['DisplayName']
        );
        return response.Entities;
    }

    /**
     * Save connection.
     * @param spaceIdr
     * @param isConnectionUpdate
     */
    public async preSaveConnection(
        spaceIdr: SpaceIdentifier,
        isConnectionUpdate: boolean
    ) {
        const pluginName = this.connectionFormService.getPlugin().name;
        const currentConnection =
            this.connectionSettingsService.getCurrentConnection();
        const featureCode = `TEST_SAVE_CONNECTION_${pluginName.toUpperCase()}`;
        const crudOperation = isConnectionUpdate
            ? CrudOperation.U
            : CrudOperation.C;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            crudOperation
        );

        let sourceId;
        if (
            this.connectionFormService.getDataStructure() === DataStructure.Tree
        ) {
            const importEntityTargets =
                this.connectionFormService.getImportEntityTargets();
            await Promise.all(
                importEntityTargets.map(async (target) => {
                    if (!target.isUpdate) {
                        const createdEntity = await this.createEntity(
                            target,
                            spaceIdr
                        );
                        target.selectedEntityId = createdEntity.ReferenceId;
                        target.isUpdate = true;
                        target.availableEntities.push(createdEntity);
                        this.connectionFormService.updateTarget(target);
                    }
                })
            );

            sourceId = importEntityTargets.find(
                (target) => target.isCatalogModule
            )?.selectedEntityId;
            const usageTarget = importEntityTargets.find(
                (target) => target.isUsageModule
            );
            if (usageTarget) {
                currentConnection['root-application-name'] =
                    usageTarget.isUpdate
                        ? usageTarget.availableEntities.find(
                              (entity) =>
                                  entity.DataReferenceId ==
                                  usageTarget.selectedEntityId
                          )?.DisplayName
                        : usageTarget.newEntityName;
            }

            const processingTarget = importEntityTargets.find(
                (target) => target.isProcessingModule
            );
            if (processingTarget) {
                currentConnection['root-dataflow-name'] =
                    processingTarget.isUpdate
                        ? processingTarget.availableEntities.find(
                              (entity) =>
                                  entity.DataReferenceId ==
                                  processingTarget.selectedEntityId
                          )?.DisplayName
                        : processingTarget.newEntityName;
            }
        } else {
            sourceId = undefined;
            delete currentConnection['root-dataflow-name'];
            delete currentConnection['root-application-name'];
        }

        const connection = await this.saveConnection(pluginName, {
            credentials: {
                connection: currentConnection,
            },
            entityId: sourceId,
            spaceId: spaceIdr.spaceId,
            versionId: spaceIdr.versionId,
        });
        this.connectionSettingsService.setCurrentConnection(currentConnection);
        this.connectorStateService.refreshSavedConnections();
        return connection;
    }

    /**
     * Test connection.
     * @param spaceId
     */
    public async testConnection(spaceId: string) {
        const { reachable } = await this.connectivityService.testConnection(
            spaceId,
            this.connectionFormService.connectionId
        );
        return {
            reachable,
        };
    }

    /**
     * Execute connection
     * @param spaceId
     */
    public async execute(spaceId: string) {
        const pluginName = this.connectionFormService.getPlugin().name;
        const connectionId = this.connectionFormService.connectionId;
        try {
            const featureCode = `IMPORT_CONNECTION_${pluginName.toUpperCase()}`;
            this.functionalLogService.logFunctionalAction(
                featureCode,
                CrudOperation.A,
                CrudActionType.Import
            );
            const operationId = await this.executeConnection(
                spaceId,
                connectionId
            );
            this.listenExecutionStatus(operationId, {
                next: (value: ImportHistory) => {
                    this.connectorStateService.notifyImportUpdate(value);
                },
            });
            const executeMsg = `Operation id: ${operationId}`;
            this.toasterService.successToast({
                titleKey: 'UI.Connector.Wizard.Step4.Execute.Success.Title',
                messageKey: executeMsg,
            });
        } catch (error) {
            this.toasterService.errorToast({
                titleKey: 'UI.Connector.Wizard.Step4.Execute.Failure.Title',
                messageKey: error.data.error.message,
            });
        } finally {
            this.connectorStateService.refreshSavedConnections();
        }
    }

    /**
     * Delete connection.
     * @param connectionId
     */
    public async deleteConnection(connectionId: string): Promise<void> {
        return this.connectivityService.deleteConnection(connectionId);
    }

    public async confirmDelete(pluginName: string, connectionId: string) {
        const featureCode = `DELETE_CONNECTION_${pluginName.toUpperCase()},D`;
        const confirmed = await this.dxyModalService.confirmDeleteObject(null, {
            featureCode,
        });
        if (!confirmed) {
            return;
        }
        await this.deleteConnection(connectionId);
        this.connectorStateService.refreshSavedConnections();
    }

    /**
     * Duplicate connection.
     * @param connection
     */
    public async duplicateConnection(
        connection: ISavedConnectionRow
    ): Promise<Connection> {
        const featureCode = `DUPLICATE_CONNECTION_${connection.pluginName.toUpperCase()}`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.C
        );
        return this.connectivityService.duplicateConnection(
            connection.credentials.connectionId
        );
    }

    /**
     * Rename connection.
     * @param connectionId
     * @param name
     */
    public async renameConnection(connectionId: string, name: string) {
        const pluginName = this.connectionFormService.getPlugin().name;
        const featureCode = `RENAME_CONNECTION_${pluginName.toUpperCase()}`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.U
        );
        return this.connectivityService.updateConnection(
            { name },
            this.currentSpaceService.getCurrentSpace().ReferenceId,
            connectionId
        );
    }

    public async openRenameModal(connectionName: string, connectionId: string) {
        const result = await this.dxyModalService.prompt({
            titleKey: 'UI.Connector.SavedConnections.renameModalTitle',
            userInputLabelKey: 'UI.Connector.Wizard.Step3.displayName',
            userInputValue: connectionName,
            confirmButtonKey: 'UI.Global.btnRename',
        });

        if (result?.trim()) {
            await this.renameConnection(connectionId, result);
            this.connectorStateService.refreshSavedConnections();
        }
    }

    // import history
    public async getImportHistory(
        connectionId: string
    ): Promise<ImportHistory[]> {
        const result = await this.connectivityService.getImportsHistory(
            connectionId
        );
        return result.importsHistory;
    }

    public async openImportHistoryModal(metadata: ISavedConnectionRow) {
        await this.dxyModalService.open<
            DxyConnectorImportHistoryModalComponent,
            IImportHistoryModalResolve,
            void
        >({
            componentType: DxyConnectorImportHistoryModalComponent,
            size: ModalSize.Large,
            data: {
                connectionId: metadata.credentials.connectionId,
            },
        });
    }

    // scheduler
    public async openSchedulerModal(metadata: ISavedConnectionRow) {
        await this.dxyModalService.open<
            DxyConnectorSchedulerModalComponent,
            IConnectorSchedulerModalData,
            void
        >({
            componentType: DxyConnectorSchedulerModalComponent,
            disableCloseOnBackdropClick: true,
            width: 'auto',
            data: {
                connection: {
                    id: metadata.credentials.connectionId,
                    hasScheduling: Boolean(metadata.nextExecution),
                },
                pluginName: metadata.pluginName,
            },
        });
    }

    public listenExecutionStatus(
        operationId: string,
        observer: NextObserver<ImportHistory>
    ) {
        const executionStatusWebSocket =
            this.connectivityService.getExecutionStatusWebSocket(operationId);
        executionStatusWebSocket.subscribe({
            ...observer,
            error: () => {
                /* won't be able to update execution status, not critical, ignoring... */
                /* QUESTION: we should probably log to be able to troubleshoot, but I don't know how :( */
                executionStatusWebSocket.unsubscribe();
            },
            complete: () => {
                executionStatusWebSocket.unsubscribe();
            },
        });
    }

    private async saveConnection(
        pluginName: string,
        { spaceId, versionId, entityId, credentials }: ISaveConnectionParams
    ) {
        const tokenValue = this.connectionFormService.getTokenValue();
        const token = tokenValue === IS_OTHER_USER_PAT ? undefined : tokenValue;
        credentials.output = {
            token,
            'project-id': spaceId,
            'version-id': versionId,
            'model-id': entityId,
            [OUTPUT_ORPHANED_OBJECTS_HANDLING]:
                this.connectionFormService.getOrphanedObjectsHandling(),
        };
        credentials.transform = {
            'structure-path':
                this.connectionSettingsService.fields.pathTransformation,
        };

        const commonPayload = {
            credentials: JSON.stringify(credentials),
            name: this.connectionFormService.getConnectionName(),
            entityId,
            integrationToken: token,
            pluginName: pluginName,
            selectedObjectNames:
                this.connectionEntitySelectionService.getObjectNames(),
        };

        let connection: Connection;
        const connectionId = this.connectionFormService.connectionId;
        if (connectionId) {
            connection = await this.connectivityService.updateConnection(
                commonPayload,
                spaceId,
                connectionId
            );
        } else {
            connection = await this.connectivityService.createConnection(
                {
                    ...commonPayload,
                    versionId,
                    integrationToken: token,
                    targetModule:
                        this.connectionFormService.getPlugin().modules[0],
                },
                spaceId
            );
        }
        this.connectionFormService.updateConnectionId(connection.id);
        return connection;
    }

    private async createEntity(
        importEntityTarget: ImportEntityTarget,
        spaceIdr: SpaceIdentifier
    ): Promise<EntityItem> {
        return await this.entityCreator.createEntity(
            spaceIdr.spaceId,
            spaceIdr.versionId,
            EntityTypeUtil.getEntityType(
                ServerType[importEntityTarget.serverType],
                importEntityTarget.subTypeName
            ),
            importEntityTarget.newEntityName
        );
    }

    private async executeConnection(
        spaceId: string,
        connectionId: string
    ): Promise<string> {
        const result = await this.connectivityService.executeConnection(
            spaceId,
            connectionId
        );
        return result.operationId;
    }
}
