import { Inject, Injectable } from '@angular/core';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';
import { InactivityLogoutModalComponent } from '@datagalaxy/webclient/auth/ui';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from '../authentication.service';

const lastActivityDateKey = 'lastActivityDate';
/**
 * ## Role
 * This service is responsible for logging out the user after a period of inactivity.
 * The inactivity period is defined by the `maxInactivityPeriodInMinutes` parameter.
 * The service will start the inactivity timer when the user logs in and reset it every time a new request is made.
 * The service checks if the user is active in other tabs via a local storage variable before logging out the user.
 */
@Injectable({
    providedIn: 'root',
})
export class InactivityLogoutService {
    private isAuthenticatedSub?: Subscription;
    private maxInactivityPeriodInMs = 0;
    private checkTimeoutId: number | undefined = undefined;

    private get lastActivityDate() {
        const item = localStorage.getItem(lastActivityDateKey);
        return item ? new Date(item) : undefined;
    }
    private set lastActivityDate(date: Date | undefined) {
        if (!date) {
            localStorage.removeItem(lastActivityDateKey);
            return;
        }
        localStorage.setItem(lastActivityDateKey, date.toISOString());
    }

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private authenticationService: AuthenticationService,
        @Inject(DIALOG_SERVICE_TOKEN) private dialogService: IDialogService
    ) {}

    public init(maxInactivityPeriodInMinutes: number) {
        this.maxInactivityPeriodInMs = maxInactivityPeriodInMinutes * 60 * 1000;
        this.isAuthenticatedSub = this.oidcSecurityService.isAuthenticated$
            .pipe(distinctUntilChanged())
            .subscribe(async (r) => {
                if (r.isAuthenticated) {
                    this.startInactivityCheck(this.maxInactivityPeriodInMs);
                } else {
                    this.stopInactivityCheck();
                }
            });
    }

    public handleNewRequest() {
        this.lastActivityDate = new Date();
    }

    private startInactivityCheck(timeout: number) {
        if (this.checkTimeoutId === undefined) {
            this.checkTimeoutId = window.setTimeout(
                () => this.checkForInactivity(),
                timeout
            );
        }
    }

    private stopInactivityCheck() {
        if (this.checkTimeoutId !== undefined) {
            window.clearTimeout(this.checkTimeoutId);
            this.checkTimeoutId = undefined;
        }
    }

    private checkForInactivity() {
        this.stopInactivityCheck();
        const now = new Date();
        const timeSinceLastActivity =
            now.getTime() - (this.lastActivityDate?.getTime() ?? 0);
        if (timeSinceLastActivity >= this.maxInactivityPeriodInMs) {
            this.inactivityLogout();
            return;
        }
        const nextCheckTime =
            this.maxInactivityPeriodInMs - timeSinceLastActivity + 1;
        this.startInactivityCheck(nextCheckTime);
    }

    private async inactivityLogout() {
        await this.authenticationService.logoutServer();
        this.isAuthenticatedSub?.unsubscribe();
        this.authenticationService.logoutLocal();
        await this.dialogService.open<
            InactivityLogoutModalComponent,
            void,
            void
        >({
            componentType: InactivityLogoutModalComponent,
        });
        await this.authenticationService.authorize();
    }
}
