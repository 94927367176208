import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'dxy-client-admin-header',
    templateUrl: 'admin-header.component.html',
    styleUrls: ['admin-header.component.scss'],
})
export class AdminHeaderComponent {
    @Input() titleKey?: string;
    @Input() titleText?: string;

    public get title() {
        if (this.titleKey) {
            return this.translate.instant(this.titleKey);
        }
        return this.titleText;
    }

    constructor(private translate: TranslateService) {}
}
