import { BaseService, UiSpinnerService } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FirstClassTypeUtil, ServerType } from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import {
    IRealTimeAttributeHandler,
    RealTimeCommService,
} from '../services/realTimeComm.service';
import { ViewTypeUtil } from '../shared/util/ViewTypeUtil';
import {
    AttributeApiService,
    CreateAttributeListValueParameter,
    CreateAttributeParameter,
    CreateAttributeTagParameter,
    CustomColors,
    DeleteAttributeParameter,
    GetModuleAttributesParameter,
    PreCreateAttributeTagParameter,
    PreUpdateAttributeListValueParameter,
    UpdateAttributeListValueParameter,
    UpdateAttributeParameter,
    UpdateAttributeTagParameter,
} from '@datagalaxy/webclient/attribute/data-access';
import {
    DataPortApiService,
    ExportDataOperation,
    ExportDataParameter,
} from '@datagalaxy/webclient/data-port/data-access';
import { emptyRef } from '@datagalaxy/webclient/modeler/data-access';
import { Moment } from 'moment';
import {
    AttributeDTO,
    AttributeMetaType,
    AttributeMetaValue,
    RtAttributeDTO,
} from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export class AttributeTypeConstants {
    public static get allAttributeTypes() {
        return [
            AttributeMetaType.Boolean,
            AttributeMetaType.Date,
            AttributeMetaType.FormattedText,
            AttributeMetaType.HtmlLink,
            AttributeMetaType.Number,
            AttributeMetaType.ValueList,
            AttributeMetaType.Text,
            AttributeMetaType.ManagedTag,
            AttributeMetaType.MultiValueList,
            AttributeMetaType.UserReference,
            AttributeMetaType.PersonReference,
            AttributeMetaType.Hierarchy,
            AttributeMetaType.TimeSeriesObject,
        ];
    }
}

/*
    NOTE: Do not add reference to DxyModalService here.
    If needed, use ClientAdminAttributeUiService.
*/
@Injectable({ providedIn: 'root' })
export class ClientAdminAttributeService extends BaseService {
    private moduleAttributes = new Map<string, Array<AttributeDTO>>();
    private availableGlobalSearchAttributeCount?: number;

    public get updating$() {
        return this.updating.asObservable();
    }

    private updating = new BehaviorSubject<boolean>(false);

    public get attributeChanged$() {
        return this.attributeChanged.asObservable();
    }

    private readonly attributeChanged = new Subject<AttributeDTO>();

    public get attributeDeleted$() {
        return this.attributeDeleted.asObservable();
    }

    private readonly attributeDeleted = new Subject<AttributeDTO>();

    constructor(
        private translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private dataPortApiService: DataPortApiService,
        private realTimeCommService: RealTimeCommService,
        private uiSpinnerService: UiSpinnerService,
        private attributeApiService: AttributeApiService
    ) {
        super();
    }

    public init() {
        this.moduleAttributes.clear();
    }

    public subscribeRealTimeCreateAttribute(
        handler: IRealTimeAttributeHandler
    ) {
        return this.realTimeCommService.subscribeCreateAttribute(handler);
    }

    public subscribeRealTimeUpdateAttribute(
        handler: IRealTimeAttributeHandler
    ) {
        return this.realTimeCommService.subscribeUpdateAttribute(handler);
    }

    public subscribeRealTimeDeleteAttribute(
        handler: IRealTimeAttributeHandler
    ) {
        return this.realTimeCommService.subscribeAttributeDelete(handler);
    }

    public getAttributeByAttributeKey(
        serverType: ServerType,
        attributeKey: string
    ) {
        const attributes = this.getLocalAttributes(serverType);
        return attributes?.find((a) => a.AttributeKey == attributeKey);
    }

    public getAttributeTypes() {
        return AttributeTypeConstants.allAttributeTypes;
    }

    public getTranslateKey(name: string): string {
        return ViewTypeUtil.getTranslateKey(
            'UI.Components.AttributeAdmin',
            name
        );
    }

    /** Warning: This does not ask for confirmation anymore.
     * If confirmation is needed, use attributeAdminUIService instead. */
    public async deleteAttribute(moduleName: string, attributeKey: string) {
        const deleteAttributeParameter = new DeleteAttributeParameter(
            moduleName,
            attributeKey
        );
        const result = await this.attributeApiService.deleteAttribute(
            deleteAttributeParameter
        );
        this.availableGlobalSearchAttributeCount =
            result.AvailableGlobalSearchAttributeCount;
        await this.updateAttributeDataCache(moduleName);
        this.removeAttributeFromLocalCache(moduleName, attributeKey);
        this.attributeDeleted.next(result.Attribute);
    }

    public async preDeleteAttribute(moduleName: string, attributeKey: string) {
        const params = new DeleteAttributeParameter(moduleName, attributeKey);
        return await this.attributeApiService.preDeleteAttribute(params);
    }

    public async updateAttribute(
        moduleName: string,
        attribute: AttributeDTO,
        propName: string,
        propValue: any
    ) {
        this.updating.next(true);
        const updateAttributeParameter = new UpdateAttributeParameter(
            moduleName,
            attribute.AttributeKey
        );
        updateAttributeParameter[propName] = this.convertValueIfDateAttribute(
            attribute,
            propName,
            propValue
        );

        if (propName === 'DefaultValue' && propValue == null) {
            updateAttributeParameter.ResetDefaultValue = true;
        }

        const result = await this.attributeApiService.updateAttribute(
            updateAttributeParameter
        );
        result.Attribute.serverType = ServerType[result.Attribute.ModuleName];
        this.availableGlobalSearchAttributeCount =
            result.AvailableGlobalSearchAttributeCount;
        this.updateLocalCache(result.Attribute);
        await this.updateAttributeDataCache(moduleName);
        this.updating.next(false);
        this.attributeChanged.next(result.Attribute);
        return result.Attribute;
    }

    public async createAttributeListValue(
        moduleName: string,
        attributeKey: string
    ) {
        const defaultKey = this.translate.instant(
            'UI.Components.AttributeAdmin.newValueListTitle'
        );
        const createAttributeValueList = new CreateAttributeListValueParameter(
            moduleName,
            attributeKey,
            defaultKey
        );
        const result = await this.attributeApiService.createAttributeListValue(
            createAttributeValueList
        );
        this.updateLocalCache(result.Attribute);
        this.attributeChanged.next(result.Attribute);
        return result.Attribute;
    }

    public async updateAttributeListValue(
        moduleName: string,
        attributeKey: string,
        item: AttributeMetaValue
    ) {
        this.updating.next(true);
        const updateAttributeValueList = new UpdateAttributeListValueParameter(
            moduleName,
            attributeKey,
            item.Value
        );
        updateAttributeValueList.DisplayName = item.Key;
        const result = await this.attributeApiService.updateAttributeListValue(
            updateAttributeValueList
        );
        this.updateLocalCache(result.Attribute);
        this.updating.next(false);
        return result.Attribute;
    }

    public async enableValueListItem(
        moduleName: string,
        attributeKey: string,
        item: AttributeMetaValue
    ) {
        const updateAttributeValueList = new UpdateAttributeListValueParameter(
            moduleName,
            attributeKey,
            item.Value
        );
        updateAttributeValueList.IsActive = !!item.DeactivationTime;
        const result = await this.attributeApiService.updateAttributeListValue(
            updateAttributeValueList
        );
        this.updateLocalCache(result.Attribute);
        return result.Attribute;
    }

    public getLocalAttributes(serverType: ServerType) {
        return this.moduleAttributes.get(ServerType[serverType]);
    }

    // For Screen Admin, we need to combine Module Attributes with Common Attributes
    public async getAttributesForScreenAdmin(serverType: ServerType) {
        const list = await this.getAttributesFromServerType(serverType);
        if (serverType == ServerType.AllTypes) {
            return list;
        }
        const attributes = list?.slice() ?? [];
        const commonAttributes = await this.getAttributesFromServerType(
            ServerType.AllTypes
        );
        attributes.push(
            ...commonAttributes.filter(
                (a) => !this.isExcludedCommonType(a, serverType)
            )
        );
        return attributes;
    }

    public async getAttributesForAttributeAdmin(serverType: ServerType) {
        return await this.getAttributesFromServerType(serverType);
    }

    public async getAttributesFromServerType(
        serverType: ServerType,
        filterFunc?: (attributeDTO: AttributeDTO) => boolean
    ) {
        let attributes: AttributeDTO[];
        // Work on cache logic
        const moduleName = ServerType[serverType];
        if (this.moduleAttributes.has(moduleName)) {
            attributes = this.moduleAttributes.get(moduleName);
        } else {
            const result = await this.attributeApiService.getModuleAttributes(
                new GetModuleAttributesParameter(moduleName, true)
            );
            result.Attributes.forEach((c) => {
                c.serverType = ServerType[c.ModuleName];
                c.translatedDisplayName =
                    this.getAttributeDisplayNameFromAttribute(c);
            });
            this.availableGlobalSearchAttributeCount =
                result.AvailableGlobalSearchAttributeCount;
            attributes = this.alphaSortAttributes(result.Attributes);
            this.moduleAttributes.set(moduleName, attributes);
        }
        if (attributes && filterFunc) {
            attributes = attributes.filter(filterFunc);
        }
        this.debug &&
            this.log(
                'getAttributesFromServerType',
                attributes?.map((a) => a.AttributePath)
            );
        return attributes;
    }

    public async deleteDefaultValue(
        moduleName: string,
        attribute: AttributeDTO
    ) {
        return await this.updateAttribute(
            moduleName,
            attribute,
            'DefaultValue',
            null
        );
    }

    public alphaSortAttributes(attributeList: Array<AttributeDTO>) {
        return CollectionsHelper.alphaSort(
            attributeList,
            'translatedDisplayName'
        );
    }

    public async createAttribute(
        moduleName: string,
        attributeType: AttributeMetaType
    ) {
        const defaultName = this.translate.instant(
            'UI.Components.AttributeAdmin.newFieldTitle'
        );
        const description = this.translate.instant(
            'UI.Components.AttributeAdmin.newFieldDescription'
        );
        const createAttributeParameter = new CreateAttributeParameter(
            moduleName,
            attributeType,
            defaultName,
            description
        );
        const result = await this.attributeApiService.createAttribute(
            createAttributeParameter
        );
        const attribute = result.Attribute;
        this.availableGlobalSearchAttributeCount =
            result.AvailableGlobalSearchAttributeCount;
        attribute.translatedDisplayName =
            this.getAttributeDisplayNameFromAttribute(attribute);
        this.addToLocalCache(result.Attribute);
        await this.updateAttributeDataCache(moduleName);
        return attribute;
    }

    public preCreateTagAttribute(
        moduleName: string,
        attributeKey: string,
        displayName: string
    ) {
        const param = new PreCreateAttributeTagParameter(
            moduleName,
            attributeKey,
            displayName
        );
        return this.attributeApiService.preCreateAttributeTag(param);
    }

    public async createAttributeTag(
        moduleName: string,
        attributeKey: string,
        defaultName: string,
        description: string
    ) {
        const param = new CreateAttributeTagParameter(
            moduleName,
            attributeKey,
            defaultName,
            description,
            true,
            CustomColors.LightGrey
        );
        const result = await this.attributeApiService.createAttributeTag(param);
        return result.AttributeTag;
    }

    public async updateAttributeTag(
        attributeId: string,
        attributeTagId: string,
        displayName: string,
        description: string,
        isActive: boolean,
        tagColor: string,
        userKeywordsInput: string,
        isUserSuggestionEnabled: boolean,
        parentTagId: string,
        activateChildTags: boolean
    ) {
        this.updating.next(true);
        const param = new UpdateAttributeTagParameter(
            attributeId,
            attributeTagId,
            displayName,
            description,
            isActive,
            tagColor,
            userKeywordsInput,
            isUserSuggestionEnabled,
            parentTagId,
            activateChildTags
        );
        const result = await this.attributeApiService.updateAttributeTag(param);
        this.updating.next(false);
        return result.AttributeTag;
    }

    public onRtAttributeUpdate(
        rtAttribute: RtAttributeDTO,
        isDeleted: boolean
    ) {
        if (isDeleted) {
            this.removeAttributeFromLocalCache(
                rtAttribute.Attribute.ModuleName,
                rtAttribute.Attribute.AttributeKey
            );
        } else {
            this.updateLocalCache(rtAttribute.Attribute);
        }
        this.availableGlobalSearchAttributeCount =
            rtAttribute.AvailableGlobalSearchAttributeCount;
    }

    public addToLocalCache(attribute: AttributeDTO) {
        attribute.translatedDisplayName =
            this.getAttributeDisplayNameFromAttribute(attribute);
        if (!this.moduleAttributes.has(attribute.ModuleName)) {
            return;
        }
        this.moduleAttributes.set(
            attribute.ModuleName,
            this.alphaSortAttributes([
                ...this.moduleAttributes.get(attribute.ModuleName),
                attribute,
            ])
        );
    }

    public removeAttributeFromLocalCache(
        moduleName: string,
        attributeKey: string
    ) {
        const attributes = this.moduleAttributes.get(moduleName);
        if (!attributes) {
            return;
        }
        CollectionsHelper.remove(
            attributes,
            (a) => a.AttributeKey === attributeKey
        );
    }

    public updateLocalCache(attribute: AttributeDTO) {
        attribute.serverType = ServerType[attribute.ModuleName];
        attribute.translatedDisplayName =
            this.getAttributeDisplayNameFromAttribute(attribute);
        const attributes = this.moduleAttributes.get(attribute.ModuleName);
        CollectionsHelper.replaceOne(
            attributes,
            (a) => a.AttributeKey == attribute.AttributeKey,
            attribute
        );
        const sorted = this.alphaSortAttributes(attributes);
        this.moduleAttributes.set(attribute.ModuleName, sorted);
    }

    public getGlyphClass(attributeType: AttributeMetaType) {
        return this.attributeDataService.getGlyphClass(attributeType);
    }

    public getAttributeDisplayNameFromAttribute(attribute: AttributeDTO) {
        return this.attributeDataService.getAttributeDisplayNameInternal(
            attribute.AttributeKey,
            attribute.IsCdp,
            attribute.DisplayName,
            attribute.serverType
        );
    }

    public hasDefaultValue(attributeType: AttributeMetaType) {
        switch (attributeType) {
            case AttributeMetaType.Text:
            case AttributeMetaType.Number:
            case AttributeMetaType.Date:
            case AttributeMetaType.FormattedText:
            case AttributeMetaType.Boolean:
            case AttributeMetaType.ValueList:
            case AttributeMetaType.HtmlLink:
                return true;
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.MultiValueList:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.UserReference:
            case AttributeMetaType.StewardUserReference:
            case AttributeMetaType.ClientTag:
            case AttributeMetaType.Hierarchy:
            case AttributeMetaType.TimeSeriesObject:
                return false;
            default:
                throw new Error();
        }
    }

    public getAttributeDisplayName(
        serverType: ServerType,
        attributeKey: string
    ) {
        const attribute =
            this.getAttributeByAttributeKey(serverType, attributeKey) ??
            this.getAttributeByAttributeKey(ServerType.AllTypes, attributeKey);
        if (!attribute) {
            return null;
        }
        return this.getAttributeDisplayNameFromAttribute(attribute);
    }

    // On any local admin operation, force reloading of public attributes cache
    private async updateAttributeDataCache(moduleName: string) {
        await this.attributeDataService.refreshLocalCacheAttributes(moduleName);
    }

    // Specific logic to exclude specific fields from Columns.
    // Todo: use Server Definition logic instead to remove these attributes from Common Types
    private isExcludedCommonType(c: AttributeDTO, serverType: ServerType) {
        if (c.IsObservabilityAttribute) {
            const fct =
                FirstClassTypeUtil.firstClassTypeFromServerType(serverType);
            return !c.ObservabilityAttributeIncludedFirstClassTypes.includes(
                fct
            );
        }

        if (serverType != ServerType.Column) {
            return;
        }

        return [
            ServerConstants.PropertyName.LogicalParentData,
            ServerConstants.PropertyName.LogicalChildrenCount,
        ].includes(c.AttributeKey);
    }

    public getAttributeTypeTranslateKey(attributeType: AttributeMetaType) {
        return AttributeDataService.getAttributeTypeTranslateKey(attributeType);
    }

    public async exportAttribute(attributeUid: string) {
        const operation = ExportDataOperation.ExportAttributeTags;
        const exportDataParameter = new ExportDataParameter(
            emptyRef,
            operation,
            null,
            null,
            attributeUid
        );
        exportDataParameter.setVersionId(null);
        await this.uiSpinnerService.executeWithSpinner(() =>
            this.dataPortApiService.exportData(exportDataParameter)
        );
    }

    public hasAvailableGlobalSearchCount() {
        return this.availableGlobalSearchAttributeCount > 0;
    }

    public isAttributeSearchIndexationEditable(attribute: AttributeDTO) {
        return (
            !attribute.IsDefaultSearchAttribute &&
            attribute.IsGlobalSearchIndexable
        );
    }

    public preUpdateAttributeListValue(
        moduleName: string,
        attribute: AttributeDTO,
        isActive: boolean,
        attributeValue: string
    ) {
        const param = new PreUpdateAttributeListValueParameter();
        param.ModuleName = moduleName;
        param.AttributeKey = attribute.AttributeKey;
        param.Value = attributeValue;
        param.IsActive = isActive;
        return this.attributeApiService.preUpdateAttributeListValue(param);
    }

    private convertValueIfDateAttribute(
        attribute: AttributeDTO,
        propName: string,
        propValue: any
    ) {
        if (
            attribute.AttributeType !== AttributeMetaType.Date ||
            propName !== 'DefaultValue'
        )
            return propValue;
        const valueToMoment = propValue as Moment;
        return valueToMoment.format('yyyy-MM-DD');
    }
}
