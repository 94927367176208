<div [hidden]="!isWrapperVisible" class="wrapper">
    <img
        *ngIf="isImageVisible"
        [src]="trustedImgSrc"
        alt=""
        class="image"
        [class.round]="isRound"
        [class.default-image]="isDefaultImage"
    />
    <div
        *ngIf="isPlaceHolderVisible"
        class="placeholder {{ placeHolderClass }}"
        [class.round]="isRound"
    >
        {{ placeHolderText }}
    </div>
    <div class="overlay off" [class.round]="isRound">
        <div
            class="dropzone"
            [class.round]="isRound"
            dxyFileDrop
            [(droppedFiles)]="droppedFiles"
            (droppedFilesChange)="onFilesDropped()"
        >
            <span class="text" translate>UI.ImageInput.dropzoneText</span>
        </div>
        <div
            [hidden]="!isMenuVisible"
            [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger"
            (menuOpened)="onMenuOpenClose(true)"
            (menuClosed)="onMenuOpenClose(false)"
            class="action-menu pull-right"
        >
            <button
                type="button"
                mat-icon-button
                class="dg5-dropdown-icon-button"
            >
                <span class="glyph-splitter"></span>
            </button>
        </div>
        <mat-menu #menu="matMenu" class="dg5-mat-menu">
            <ul>
                <li (click)="onActionUploadClick()">
                    <span class="glyph glyph-plus-circle"></span>
                    <span translate>UI.ImageInput.menuOptions.upload</span>
                </li>
                <li *ngIf="isActionEditVisible" (click)="onActionEditClick()">
                    <span class="glyph glyph-edit"></span>
                    <span translate>UI.ImageInput.menuOptions.edit</span>
                </li>
                <li *ngIf="isActionResetVisible" (click)="onActionResetClick()">
                    <span class="glyph glyph-undo2"></span>
                    <span translate>UI.ImageInput.menuOptions.reset</span>
                </li>
                <li
                    *ngIf="isActionDeleteVisible"
                    (click)="onActionDeleteClick()"
                >
                    <span class="glyph glyph-delete"></span>
                    <span translate>UI.ImageInput.menuOptions.delete</span>
                </li>
            </ul>
        </mat-menu>
    </div>
</div>
<div [hidden]="!isEditorVisible" class="editor"></div>
