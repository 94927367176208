import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export enum EntityExportChildrenOption {
    None,
    Children,
    Descendants,
}
SerializableEnumeration(EntityExportChildrenOption);

export enum EntityExportParentsOption {
    None,
    Parent,
    Ancestors,
}
SerializableEnumeration(EntityExportParentsOption);

export enum EntityExportReferencesOption {
    PrimaryKeys,
    ForeignKeys,
    FunctionalKeys,
}
SerializableEnumeration(EntityExportReferencesOption);

export enum ExportDataOperation {
    /*
     Used to export a full module, without specifying the individual DataReferenceIdList values
     In that case, the Module parameter is Mandatory
     */
    ExportModuleEntities,

    /*
    Used to export specified entities (using DataReferenceIdList), with eventual Parent/Children/Link options
    In that case, the Module parameter is Mandatory (for now), as multi-module is not yet supported
    */
    ExportEntities,

    /*
    Used to export the Tag Values of a Tag-type Attribute (ClientTag, ClientAttributeTag, Hierarchy, ManagedTag,
    */
    ExportAttributeTags,

    /*
     Used to export the Time Series array of values for a specific Entity (DataReferenceId) and Attribute
    */
    ExportEntityTimeSeries,

    /*
     Used to export the current active Users for a client
    */
    ExportUsers,
    /*
     Used to export the current active Users for a client
    */
    ExportPersons,
    /*
     Used to export the selected Team(s)
    */
    ExportTeams,
}
SerializableEnumeration(ExportDataOperation);

@inheritSerialization(BaseServiceParameter)
export class ExportDataParameter extends BaseServiceParameter {
    @serialize ExportDataOperation: string;
    @serialize Module?: string;
    @serialize ExportFileName?: string;
    @serialize ParentReferenceId: string;
    @serialize DataReferenceIdList?: string[];
    @serialize Filters?: Filter[];

    // Enum: EntityExportChildrenOption
    @serialize ChildrenOption: string;

    // Enum: EntityExportChildrenOption
    @serialize ParentsOption: string;

    // Enum[]: EntityExportReferencesOption
    @serialize ReferencesOptions: string[];
    @serialize IncludeLinks: boolean;

    /** Used for TimeSeries Attribute */
    @serialize AttributeKey?: string;

    @serialize AttributeUid?: string;

    /*  Default: utf-8, also supported: windows-1252 */
    @serialize EncodingWebName: string;
    @serialize TextEntrySeparator?: string;
    @serialize TextFieldSeparator?: string;

    /** Client time zone, to compute files timestamp */
    @serialize IANATimeZone: string;

    constructor(
        parentReferenceId: string,
        exportDataOperation: ExportDataOperation,
        module?: DgModule,
        dataReferenceIds?: string[],
        attributeUid?: string,
        attributeKey?: string
    ) {
        super();
        this.ExportDataOperation = ExportDataOperation[exportDataOperation];
        this.Module = module == undefined ? '' : DgModule[module];
        this.ParentReferenceId = parentReferenceId;
        this.DataReferenceIdList = dataReferenceIds;
        this.AttributeUid = attributeUid;
        this.AttributeKey = attributeKey;

        // TODO: Use these parameter attributes with modal
        this.ChildrenOption =
            EntityExportChildrenOption[EntityExportChildrenOption.None];
        // TODO: Use these parameter attributes with modal
        this.ParentsOption =
            EntityExportParentsOption[EntityExportParentsOption.None];
        // TODO: Use these parameter attributes with modal
        this.ReferencesOptions = [];
        // TODO: Use these parameter attributes with modal
        this.IncludeLinks = false;

        this.EncodingWebName = 'utf-8';

        this.IANATimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
}

@inheritSerialization(BaseServiceResult)
export class ExportDataResult extends BaseServiceResult {
    @deserialize public Filename!: string;
    @deserialize public ZipFileContent!: string;
}
