<div class="header-container" #headerContainer>
    <ng-content select="[menu-header]"></ng-content>
</div>
<ul
    class="burger-menu"
    [class.hidden]="isStandalone"
    [class.standalone]="isStandalone"
>
    <ng-container *ngFor="let group of optionGroups; let lastGroup = last">
        <li *ngIf="categoryGroupHasText(group)" class="menu-item" role="header">
            {{ categoryGroupKey(group) | translate }}
        </li>
        <ng-container *ngFor="let opt of group">
            <ng-container *ngIf="!isHidden(opt)">
                <li *ngIf="opt.isSeparator" class="menu-item separator"></li>
                <li
                    *ngIf="!opt.isSeparator"
                    mat-menu-item
                    (click)="onClick(opt, $event)"
                    [class.disabled]="getOptionDisabled(opt)"
                    [disableRipple]="getOptionDisabled(opt)"
                    [ngClass]="getOptionClass(opt)"
                    [dxyDataTestId]="getDataTestId(opt)"
                >
                    <dxy-option-item
                        [option]="opt"
                        [data]="data"
                    ></dxy-option-item>
                </li>
            </ng-container>
        </ng-container>
        <li *ngIf="!lastGroup" class="menu-item" role="separator"></li>
    </ng-container>
</ul>
