import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { EntityService } from '../services/entity.service';
import { AppEventsService } from '../../../services/AppEvents.service';
import { EntityUiService } from '../services/entity-ui.service';
import { DataUtil } from '../../util/DataUtil';
import { ScreenService } from '../../../screens/screen.service';
import { EntityEventService } from '../services/entity-event.service';
import { IScreenData } from '../../screens-layout/screens-layout.types';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import { EntityCardSettings } from '../../entityCard/entity-card/EntityCardSettings';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DxyScreenLayoutCategoryComponent } from '../../screens-layout/dxy-screen-layout-category/dxy-screen-layout-category';
import { DxyEntityHeaderTitleComponent } from '../dxy-entity-header-title/dxy-entity-header-title.component';
import { DxyNavBreadcrumbComponent } from '../../../navigation/dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import { EntityTypeCardComponent } from '@datagalaxy/webclient/entity/ui';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-header',
    templateUrl: 'dxy-entity-header.component.html',
    styleUrls: ['dxy-entity-header.component.scss'],
    standalone: true,
    imports: [
        EntityTypeCardComponent,
        forwardRef(() => DxyNavBreadcrumbComponent),
        DxyEntityHeaderTitleComponent,
        DxyScreenLayoutCategoryComponent,
    ],
})
export class DxyEntityHeaderComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: EntityItem;
    @Input() readOnly?: boolean;

    protected headerScreenCategory?: ScreenCategory;
    protected headerScreenData?: IScreenData;

    protected technoImgUrl: string;
    protected technoTooltipLbl: string;
    protected displaySettings: EntityCardSettings;

    constructor(
        private entityService: EntityService,
        private appEventsService: AppEventsService,
        private entityEventService: EntityEventService,
        private entityUiService: EntityUiService,
        private screenService: ScreenService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'entityData', () => this.updateData(), true);
    }

    ngOnInit() {
        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.updateData()
        );
        const moduleType = DataUtil.getDefaultServerTypeFromModule(
            DataUtil.getModuleFromServerType(this.entityData.ServerType)
        );
        super.registerSubscription(
            this.entityEventService.subscribeEntityTechnologyUpdate(
                moduleType,
                (entityItem) => this.onEntityUpdate(entityItem)
            )
        );
    }

    private async updateData() {
        if (!this.entityData) {
            return;
        }
        await this.updateCurrentLayout(this.entityData.HddData.TechnologyCode);
    }

    private async onEntityUpdate(entityItem: EntityItem) {
        if (
            entityItem.ReferenceId != this.entityData.ReferenceId &&
            !this.entityData.HddData.Parents.some(
                (ancestor) => ancestor.DataReferenceId == entityItem.ReferenceId
            )
        ) {
            return;
        }
        await this.updateCurrentLayout(
            entityItem.getAttributeValue<string>(
                ServerConstants.PropertyName.Technology
            )
        );
    }

    private async updateCurrentLayout(newTechnoCode?: string) {
        const displaySettings = (this.displaySettings =
            this.entityUiService.getUserEntityCardDisplaySettings(
                newTechnoCode
            ));
        this.technoImgUrl = displaySettings.technoImgUrl;
        this.technoTooltipLbl = displaySettings.technoTooltip;
        await this.updateEditableAttributes();
    }

    private async updateEditableAttributes() {
        let entityAttributes =
            await this.entityService.getEntityAttributesForDetails(
                this.entityData.ServerType
            );
        if (this.readOnly) {
            entityAttributes =
                EntityUtils.getAttributesReadOnlyClones(entityAttributes);
        }
        const screenDto = this.screenService.getSpaceScreenForEntity(
            this.entityData,
            false
        );
        const screenLayout = this.screenService.resolveScreenLayout(screenDto);
        this.headerScreenCategory = screenLayout?.Categories.find(
            (c) => c.IsHeader
        );

        this.headerScreenData = {
            entityAttributes,
            entityData: this.entityData,
        };
    }
}
