import { deserialize, deserializeAs, SerializableEnumeration } from 'cerialize';
import { AttributeDTO } from '@datagalaxy/webclient/attribute/domain';

export class CategoryTemplateDto {
    @deserialize public TemplateUid!: string;
    @deserialize public SpaceId!: string;
    @deserialize public CategoryTemplate!: string;
    @deserialize public TemplateName!: string;
    @deserialize public IsTemplateOverridden!: boolean;
    @deserialize public IsSpaceLevel!: boolean;
    @deserializeAs(AttributeDTO) public Attributes!: AttributeDTO[];
    @deserialize public TemplateType!: CategoryTemplateType;
}

export enum CategoryTemplateType {
    Header = 0,
}
SerializableEnumeration(CategoryTemplateType);
