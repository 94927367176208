import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { CurrentSpaceService } from '../services/currentSpace.service';
import { AppDataService } from '../services/app-data.service';
import {
    ConnectivityApiService,
    ConnectorDownloadApiService,
    CreateConnectionBody,
    CreateSchedulingBody,
    ExecuteConnectionBody,
    GetConnectionsResponse,
    GetConnectorResponse,
    GetConnectorsResponse,
    TestConnectionResponse,
    UpdateConnectionBody,
    UpdateSchedulingBody,
} from '@datagalaxy/webclient/connectivity/data-access';

@Injectable({ providedIn: 'root' })
export class ConnectivityService extends BaseService {
    private get spaceId() {
        return this.currentSpaceService.getCurrentSpace().ReferenceId;
    }
    private get userId() {
        return this.appDataService.currentUserId;
    }

    constructor(
        private currentSpaceService: CurrentSpaceService,
        private connectivityApiService: ConnectivityApiService,
        private connectorDownloadApiService: ConnectorDownloadApiService,
        private appDataService: AppDataService
    ) {
        super();
    }

    //#region Connections
    public async getConnections(): Promise<GetConnectionsResponse> {
        return this.connectivityApiService.getConnections(this.spaceId);
    }

    public async createConnection(
        body: Omit<CreateConnectionBody, 'creationUserId'>,
        spaceId: string
    ) {
        return this.connectivityApiService.createConnection(
            spaceId,
            new CreateConnectionBody({
                creationUserId: this.userId,
                ...body,
            })
        );
    }

    public async updateConnection(
        body: UpdateConnectionBody,
        spaceId: string,
        connectionId: string
    ) {
        return this.connectivityApiService.updateConnection(
            spaceId,
            connectionId,
            new UpdateConnectionBody(body)
        );
    }

    public async duplicateConnection(connectionId: string) {
        return this.connectivityApiService.duplicateConnection(
            this.spaceId,
            connectionId
        );
    }

    public async deleteConnection(connectionId: string) {
        await this.connectivityApiService.deleteConnection(
            this.spaceId,
            connectionId
        );
    }

    public async testConnection(
        spaceId: string,
        connectionId: string
    ): Promise<TestConnectionResponse> {
        return this.connectivityApiService.testConnection(
            spaceId,
            connectionId
        );
    }

    public async executeConnection(spaceId: string, connectionId: string) {
        return this.connectivityApiService.executeConnection(
            spaceId,
            connectionId,
            this.buildExecuteBody()
        );
    }

    public async getConnectionObjects(spaceId: string, connectionId: string) {
        return this.connectivityApiService.getConnectionObjects(
            spaceId,
            connectionId
        );
    }

    //#endregion

    //#region Scheduling

    public async getScheduling(connectionId: string) {
        return this.connectivityApiService.getScheduling(
            this.spaceId,
            connectionId
        );
    }

    /** Get connection scheduling
     * @param timezone
     * @param suspend
     * @param connectionId connection UUID
     * @param cron cron is a string at format mm hh jj MMM JJJ, example: 5 * * 4 *  */
    public async createScheduling(
        connectionId: string,
        cron: string,
        suspend?: boolean,
        timezone?: string
    ) {
        const createSchedulingBody = new CreateSchedulingBody();
        createSchedulingBody.cron = cron;
        createSchedulingBody.suspend = suspend;
        createSchedulingBody.executeBody = this.buildExecuteBody();
        createSchedulingBody.timezone = timezone;
        return this.connectivityApiService.createScheduling(
            this.spaceId,
            connectionId,
            createSchedulingBody
        );
    }

    public async updateScheduling(
        connectionId: string,
        cron?: string,
        suspend?: boolean,
        timezone?: string
    ) {
        return this.connectivityApiService.updateScheduling(
            this.spaceId,
            connectionId,
            new UpdateSchedulingBody({
                cron,
                suspend,
                timezone,
            })
        );
    }

    //#endregion

    //#region Connectors

    public async getConnectors(): Promise<GetConnectorsResponse> {
        return this.connectivityApiService.getConnectors();
    }

    public async getConnector(name: string): Promise<GetConnectorResponse> {
        return this.connectivityApiService.getConnector(name);
    }

    public getConnectorImageUrl(connectorName: string) {
        return this.connectivityApiService.getConnectorImageUrl(connectorName);
    }

    public getExecutionStatusWebSocket(operationId: string) {
        return this.connectivityApiService.getExecutionStatusWebSocket(
            operationId
        );
    }

    //#endregion

    //#region Imports History

    public async getImportsHistory(connectionId: string) {
        return this.connectivityApiService.getImportsHistory(
            this.spaceId,
            connectionId
        );
    }

    //#endregion
    private buildExecuteBody() {
        const body = new ExecuteConnectionBody();
        body.userId = this.userId;
        return body;
    }
}
