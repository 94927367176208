<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        [translate]="'UI.Modeler.DataQuality.checkHistory'"
    ></h3>
    <button
        dxyIconButton
        color="transparent"
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <h2>{{ ruleStatement }}</h2>
        <dxy-grid
            class="data-quality-grid"
            [columns]="cols"
            [config]="gridConfig"
            [items]="qualityChecks"
        ></dxy-grid>
        <dxy-spinner
            *ngIf="loading$ | async"
            class="xl absolute-centered"
        ></dxy-spinner>
    </div>
    <div class="modal-footer">
        <button
            dxyButton
            color="secondary"
            (click)="onCloseSubmit()"
            translate="UI.Dialog.btnClose"
        ></button>
    </div>
</form>
