//#region enums and interfaces
import {
    IWidgetConfigValue,
    IWidgetIngridConfig,
    IWidgetStoredContentConfig,
    WidgetType,
} from '@datagalaxy/webclient/dashboard/data-access';

/** categories of widgets */
export enum WidgetCategory {
    general = 0,
    counting,
    activity,
    quality,
    //#region debugging values
    // c1, c2, c3,

    //#endregion
}

/** Types of widget's parameters. Used to:
 * - build the settings form
 * - adapt the parameter's value
 *
 * **Will be soon renamed to WIdgetParamType** */
export enum WidgetFormFieldType {
    /** the parameter does not appear in the settings form
     *  - When using this, be sure to provide the parameter a valueType (WidgetConfigValueType). */
    hidden = 0,

    /** - input type in settings form : (depends on its value type (string, number, or date input, or checkbox).
     *  - When using this, be sure to provide the parameter a valueType (WidgetConfigValueType). */
    default,

    /** - input type in settings form: selection list for one of the DataGalaxy modules
     *  - value: enum DgModule with only the 4 legacy modules (aka: without Diagram), as number */
    dgModuleLegacy,
    /** - input type in settings form: selection list for one of the DataGalaxy modules
     *  - value: enum DgModule, as number */
    dgModule,
    /** - input type in settings forms: selection list for one of the DataGalaxy modules or all
     *  - value: enum DgModule, as number */
    dgModuleAll,
    /** - input type in settings form: selection list for one of the DataGalaxy modules, but Catalog
     *  - value: enum DgModule, as number */
    dgModuleButCatalogAndDiagram,
    /** - input type in settings form: selection list with Catalog as the only value
     *  - value: enum DgModule, as number */
    dgModuleOnlyCatalog,

    /** - input type in settings form: selector for a space with an optional version
     *  - value: SpaceIdentifier, stored as string */
    spaceVersion,

    /** - input type in settings form: multi-selector for entity types (EntityType), with available values depending on selected dgModule
     *  - value: array of enum values EntityType, as numbers */
    moduleEntityTypes,
    /** - input type in settings form: multi-selector for entity types (EntityType), with available values depending on selected dgModule except if dgModule is null
     *  - value: array of enum values EntityType, as numbers */
    moduleEntityTypesAll,

    /** - input type in settings form: multi-selector for DataOwners
     *  - value: array of referenceId as strings */
    owners,

    /** - input type in settings form: multi-selector for DataStewards
     *  - value: array of referenceId as strings */
    stewards,

    /** - input type in settings form: multi-selector for Users depending on licenseType
     *  - value: array of referenceId as strings */
    licenseUsers,
    /** - input type in settings form: multi-selector for Users
     *  - value: array of referenceId as strings */
    users,

    /** - input type in settings form: multi-selector for entity statuses
     *  - value: array of referenceId as strings */
    entityStatuses,

    /** - input type in settings form: multi-selector for tags
     *  - value: array of enum values EntityLifecycleStatus, as numbers */
    tags,

    /** - input type in settings form: selector for a number of days (1, 7, 30)
     *  - value: array of numbers */
    days_1_7_30,

    /** - input type in settings form: selector for a number of days (30, 60, 90)
     *  - value: array of numbers */
    days_30_60_90,

    /** - input type in settings form: selector for a number of days
     *  - value: array of numbers */
    period,

    /** - input type in settings form: multi-selector for license types
     *  - value: array of enum values LicenseLevel, as numbers */
    licenseTypes,

    /** - input type in settings form: multi-selector for license types
     *  - value: array of enum values LicenseLevel, as numbers */
    licenseTypesAll,

    /** - input type in settings form: selector for common attributes.
     * The selector may be single or multi, depending on the parameter's isSingleSelect property
     *  - value: array of strings: attributeKey */
    commonAttributes,

    /** - input type in settings form: selector for text attributes (Description, LongDescription, + text cdp attributeKeys).
     * The selector may be single or multi, depending on the parameter's isSingleSelect property
     *  - value: array of strings: attributeKey for non-CDPs, attributePath for CDPs */
    textAttributes,

    /** - input type in settings form: multi-selector for user services
     *  - value: array of string values representing services */
    userServicesAll,

    /** - input type in settings form: multi-selector for user roles
     *  - value: array of string values representing roles */
    userRolesAll,

    /** - input type in settings form: multi-selector for teams
     *  - value: array of referenceId as strings */
    teams,
}

/** Widget's parameter value types.
 * To be used in the widget type definition when a parameter type is: default. */
export enum WidgetConfigValueType {
    unknown = 0,
    string,
    number,
    boolean,
    date,
    stringLong,
    richText,
}

/** a widget type definition */
export interface IWidgetTypeDefinition extends IWidgetContentConfig {
    /** category for the widgets panel */
    category: WidgetCategory;

    commonFieldTranslateKeys: IWidgetCommonSettingTranslateKeys;

    spaceVersionSelectorAllowAllSpaces?: boolean;
    spaceVersionSelectorProjectsOnly?: boolean;
}

/** a widget content configuration */
export interface IWidgetContentWorkingConfig extends IWidgetContentConfig {
    /** true means: every needed parameter value is defined */
    valid?: boolean;
}
/** a widget content configuration */
export interface IWidgetContentConfig extends IWidgetStoredContentConfig {
    /** pascal cased name of the widget's content angular component */
    component: string;

    /** widget's content configuration parameters */
    params?: IWidgetConfigParam[];
}

/** Widget content configuration parameter. Used for:
 * - building the settings form of the widget
 * - binding the widget's content component properties
 * - persisting the widget configuration */
export interface IWidgetConfigParam extends IWidgetConfigValue {
    /** type of parameter and input field - default value is *hidden* */
    formFieldType?: WidgetFormFieldType;

    /** data type of the value, or id of the data the value is computed from at run time
     * - Mandatory when formFieldType is *default* or *hidden*. */
    valueType?: WidgetConfigValueType;

    /** translation id to display the parameter's name in the settings form.
     * - Mandatory when formFieldType is default */
    translateKey?: string;

    /** name of other parameters on which this one depends */
    dependsOn?: string | string[];

    /** when true, the widget's configuration will not be considered incomplete if the parameter has no value */
    isOptional?: boolean;

    /** for a selector, some values to filter the available options with */
    availableValues?: string[];

    /** forces a single value selector */
    isSingleValue?: boolean;

    /** when the field is a multi-selection and no value is selected, use all available values */
    useAllValuesWhenMultiNoneSelected?: boolean;

    /** Used by dxy-widget-settings */
    selectedValue?: unknown;
}

/** interface for a component to be used as a widget content.
 *
 * Other constraints:
 * - maximum z-index must be less than 90 */
export interface IWidgetComponent {
    titleText: string;

    description?: string;

    widgetEvents?: IWidgetEvents;
}

export interface IWidgetEvents extends IResizableEvents {}

export interface IResizableEvents {
    onResized?: () => void;
}

/** a widget's in-grid (or grid-ready) configuration */
export interface IWidgetConfig {
    /** content component configuration */
    content: IWidgetContentWorkingConfig;

    /** placement & options in the grid */
    inGrid?: IWidgetIngridConfig;

    /** is dummy widget */
    creation?: boolean;

    /** ACM flag */
    noAccess?: boolean;
}

export interface IWidgetCommonSettingTranslateKeys {
    needsDgModuleSuffix?: boolean;
    titleText: string;
    description?: string;
}

//#endregion

export class WidgetUtil {
    static getFeatureCodeFromWidgetType(widgetType: WidgetType) {
        switch (widgetType) {
            //#region Widgets "fondamentaux"
            case WidgetType.entityCountByEntityType:
                return 'WIDGET_OBJECT_COUNT_BY_MODULE';
            case WidgetType.evolutionOfEntityCountByEntityType:
                return 'WIDGET_OBJECT_PROGRESS_BY_MODULE';
            case WidgetType.entityCountByAttributeValues:
                return 'WIDGET_OBJECT_COUNT_AND_DISTRIBUTION';
            case WidgetType.textAttributesFillPercentage:
                return 'WIDGET_FILLING_RATE_BY_ATTRIBUTE';
            case WidgetType.textAttributesFillPercentageByEntityType:
                return 'WIDGET_FILLING_RATE_BY_OBJECT';
            case WidgetType.cumulativeTextAttributesFillPercentage:
                return 'WIDGET_ATTRIBUTE_FILLING_RATE';
            //#endregion

            //#region Legacy Widgets
            case WidgetType.spaceModule:
                return 'WIDGET_MODULE';
            case WidgetType.projectModuleItemsList:
                return 'WIDGET_ITEMS';
            case WidgetType.projectModuleQualityChart:
                return 'WIDGET_GRAPH';
            //#endregion

            //#region Widgets "Activité"
            case WidgetType.nbUniqueConnectionByLicenseType:
                return 'WIDGET_COUNT_LOGIN';
            case WidgetType.evolutionOfNbUniqueConnectionByLicenseType:
                return 'WIDGET_COUNT_LOGIN_PROGRESS';
            case WidgetType.nbSessionByLicenseType:
                return 'WIDGET_COUNT_SESSION';
            case WidgetType.evolutionOfNbSessionByLicenseType:
                return 'WIDGET_COUNT_SESSION_PROGRESS';
            //#endregion
        }
    }
}

export interface IWidgetConfigDb {
    id: string;
    hideFilters: boolean;
    params: IWidgetConfigParam[];
}
