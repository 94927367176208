import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TrendPillColorRule } from '@datagalaxy/core-ui';
import { DxyAttributeBaseInput } from '../DxyAttributeBaseInput';
import { TimeSeriesGraphHelper } from '../../../../time-series/TimeSeriesGraphHelper';
import { EntityEventService } from '../../../entity/services/entity-event.service';
import { TimeSeriesUiService } from '../../../../services/time-series-ui.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    TimeSeriesColorRule,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { DxyTrendPillComponent } from '@datagalaxy/core-ui';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-attribute-time-series-input',
    templateUrl: './dxy-attribute-time-series-input.component.html',
    styleUrls: ['./dxy-attribute-time-series-input.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatLegacyTooltipModule,
        MatLegacyButtonModule,
        DxyTrendPillComponent,
        TranslateModule,
        EllipsisTooltipDirective,
    ],
})
export class DxyAttributeTimeSeriesInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit
{
    public get lastEntryValue() {
        return this.timeSeriesObject?.LastEntry?.Value;
    }
    public get trend() {
        return this.timeSeriesObject?.Trend;
    }
    public get hasValues() {
        return this.timeSeriesObject?.Entries?.length > 0;
    }
    public get colorRule() {
        return (
            this.getColorRuleFromTimeSeriesColorRule(
                this.attributeMeta.TimeSeriesColorRule
            ) ?? TrendPillColorRule.shouldIncrease
        );
    }
    public get labelName() {
        return this.translatedDisplayName;
    }

    @HostBinding('class.mini') private get isMini() {
        return this.mini;
    }

    private timeSeriesObject: TimeSeriesObject;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
        private timeSeriesUiService: TimeSeriesUiService,
        private entityEventService: EntityEventService
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        this.setTimeSeriesObjectAndDraw(this.getEntityData());

        this.subscribeEntityUpdate();
    }

    public async openEditModal() {
        try {
            this.onPanelOpenClose.emit(true);
            const result = await this.timeSeriesUiService.openTimeSeriesModal(
                this.timeSeriesObject,
                this.attributeMeta,
                this.getEntityData(),
                this.readonly
            );
            if (result) {
                this.timeSeriesObject = result;
                this.clearGraph();
                if (this.hasValues) {
                    this.draw();
                }
            }
        } finally {
            this.onPanelOpenClose.emit(false);
        }
    }

    private subscribeEntityUpdate() {
        const entity = this.getEntityData();
        if (!entity) {
            return;
        }

        super.registerSubscription(
            this.entityEventService.subscribeEntityUpdate(
                entity.ServerType,
                (data) => {
                    if (data.DataReferenceId == entity.DataReferenceId) {
                        this.setTimeSeriesObjectAndDraw(entity);
                    }
                }
            )
        );
    }

    private setTimeSeriesObjectAndDraw(entity: EntityItem) {
        this.timeSeriesObject = entity.getTypedAttributeValue(
            this.attributeMeta
        ) as TimeSeriesObject;
        this.clearGraph();
        if (this.hasValues) {
            this.draw();
        }
    }

    private clearGraph() {
        DomUtil.getElement(this.elementRef.nativeElement, 'svg')?.remove();
    }

    private draw() {
        setTimeout(() => {
            const graphEl = DomUtil.getElement(
                this.elementRef,
                '.time-series-graph'
            );
            if (!graphEl) {
                return;
            }
            TimeSeriesGraphHelper.drawTimeSeriesGraph(
                this.timeSeriesObject,
                this.attributeMeta.TimeSeriesFrequency,
                graphEl,
                10
            );
        }, 200);
    }

    private getColorRuleFromTimeSeriesColorRule(
        timeSeriesColorRule: TimeSeriesColorRule
    ): TrendPillColorRule {
        switch (timeSeriesColorRule) {
            case TimeSeriesColorRule.None:
                return TrendPillColorRule.none;
            case TimeSeriesColorRule.ShouldIncrease:
                return TrendPillColorRule.shouldIncrease;
            case TimeSeriesColorRule.ShouldDecrease:
                return TrendPillColorRule.shouldDecrease;
        }
    }
}
