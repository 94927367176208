import { Point, RectSide, TRect } from '@datagalaxy/core-2d-util';

export class LinkingUtils {
    /**
     * Determines the quadrant of a point relative to a rectangle based on specified angles.
     * The angles are considered from the perspective of each corner:
     *   - Top right considers angle relative to the positive x and y axes.
     *   - Top left considers angle relative to -x and y axes.
     *   - Bottom left considers angle relative to -x and -y axes.
     *   - Bottom right considers angle relative to x and -y axes.
     *
     * Notes: this is an attempt to improve the general direction of a source link
     * being drawn, to avoid flickering in particular
     *
     * @param rectangle - The rectangle object (x, y, width, height).
     * @param point - The point for which the quadrant is determined.
     * @param angleDegrees - The angle in degrees used to define the quadrants.
     * @returns The quadrant (top, right, bottom, left) in which the point lies.
     */
    public static getQuadrantRelativeToRectangle(
        rectangle: TRect,
        point: Point,
        angleDegrees: number
    ): RectSide {
        // Convert angle to radians
        const angle = angleDegrees * (Math.PI / 180);

        // Calculate the coordinates of each corner of the rectangle
        const topLeft = { x: rectangle.x, y: rectangle.y };
        const topRight = { x: rectangle.x + rectangle.width, y: rectangle.y };
        const bottomLeft = {
            x: rectangle.x,
            y: rectangle.y + rectangle.height,
        };
        const bottomRight = {
            x: rectangle.x + rectangle.width,
            y: rectangle.y + rectangle.height,
        };

        // Calculate the angle between each corner and the horizontal axis from the corner's perspective
        const angleTopLeft = Math.atan2(
            topLeft.y - point.y,
            topLeft.x - point.x
        );
        const angleTopRight = -Math.atan2(
            point.y - topRight.y,
            point.x - topRight.x
        );
        const angleBottomLeft = -Math.atan2(
            bottomLeft.y - point.y,
            bottomLeft.x - point.x
        );
        const angleBottomRight = Math.atan2(
            point.y - bottomRight.y,
            point.x - bottomRight.x
        );

        const valid = (agl: number) => agl <= angle && agl >= -Math.PI / 2;
        const validTopBottom = (agl: number) => agl > angle && agl < Math.PI;

        // Check which side of the specified angle the point lies
        if (valid(angleTopRight) && valid(angleBottomRight)) {
            return RectSide.right;
        } else if (valid(angleTopLeft) && valid(angleBottomLeft)) {
            return RectSide.left;
        } else if (
            validTopBottom(angleTopLeft) &&
            validTopBottom(angleTopRight)
        ) {
            return RectSide.top;
        } else if (
            validTopBottom(angleBottomLeft) &&
            validTopBottom(angleBottomRight)
        ) {
            return RectSide.bottom;
        } else {
            return RectSide.none;
        }
    }
}
