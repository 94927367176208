import { FieldType } from '../../types/enums/field-type.enum';

export const flakyConfig = [
    {
        payloadField: 'check-access-case',
        formField: 'checkAccessCase',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'check-access-initial-delay-seconds',
        formField: 'checkAccessInitialDelaySeconds',
        payloadValue: 0,
        type: FieldType.text,
        formMandatory: true,
        format: (value: string) => Number(value),
    },
    {
        payloadField: 'fetch-data-case',
        formField: 'fetchDataCase',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'fetch-data-initial-delay-seconds',
        formField: 'fetchDataInitialDelaySeconds',
        payloadValue: 0,
        type: FieldType.text,
        formMandatory: true,
        format: (value: string) => Number(value),
    },
    {
        payloadField: 'exit-code',
        formField: 'exitCode',
        payloadValue: 0,
        type: FieldType.text,
        formMandatory: true,
        format: (value: string) => Number(value),
    },
];
