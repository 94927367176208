import { Point } from './2d.types';
import { Vect2 } from './Vect2';

export type Path = Point[];

/**
 * Given an array of points, returns a new array with simplified path by removing certain points:
 *
 * - Consecutive equal points are removed.
 * - Non-orthogonal adjacent points are removed.
 * - Collinear orthogonal triplets of points are removed.
 *
 * @param {Path} path - An array of points representing a path.
 * @returns {Path} - The simplified path array (a new array).
 */
export function simplifyPath(path: Path): Path {
    if (!path) {
        return;
    }
    for (let i = 0; i < path.length - 1; ) {
        const a = path[i],
            b = path[i + 1];
        let c: Point;
        if (
            (a.x == b.x && a.y == b.y) || // same
            (a.x != b.x && a.y != b.y) // non-orthogonal
        ) {
            path.splice(i + 1, 1);
        } else if (
            (c = path[i + 2]) && // colinear triplet
            ((a.x == b.x && b.x == c.x) || (a.y == b.y && b.y == c.y))
        ) {
            path.splice(i + 1, 1);
            if (i > 0) {
                --i;
            }
        } else {
            ++i;
        }
    }
    return path;
}

export function isOrthogonalPath(path: Path): boolean {
    if (!path?.length || path.length == 1) {
        return false;
    }
    const l = path.length - 1;
    for (let i = 0; i < l; i++) {
        if (!Vect2.isOrthogonalSegment(path[i], path[i + 1])) {
            return false;
        }
    }
    return true;
}

export function getPathOriginPosition(path: Path): Point {
    return {
        x: Math.min(...path.map((point) => point.x)),
        y: Math.min(...path.map((point) => point.y)),
    };
}
