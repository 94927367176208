import {
    getExpandedRowModel,
    getGroupedRowModel,
    Header,
    Row,
    Table,
    TableState,
} from '@tanstack/table-core';
import { TColDef } from '../grid-column/grid-column.types';
import { TanStackGridAdapter } from './grid.adapter';
import { BehaviorSubject } from 'rxjs';
import { GridConfig } from '../grid-config';
import { GridColumnSizeUtils } from '../grid-column-size/grid-column-size.utils';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { GridScroll } from '../grid-scroll/grid-scroll';
import { GridTree } from '../grid-tree/grid-tree';
import { createDefaultTable } from './grid-table.utils';
import { ArrayUtils } from '@datagalaxy/utils';

export class GridTable<TRow> {
    private tableScroll = new GridScroll<TRow>();
    private tableTree = new GridTree<TRow>();
    private tableBehavior: BehaviorSubject<Table<TRow>>;

    public get table(): Table<TRow> {
        return this.tableBehavior.value;
    }

    public get table$() {
        return this.tableBehavior.asObservable();
    }

    public get selection(): Row<TRow>[] {
        return this.table.getSelectedRowModel().rows;
    }

    public get scroll() {
        return this.tableScroll;
    }

    public get tree() {
        return this.tableTree;
    }

    constructor() {
        const table: Table<TRow> = createDefaultTable<TRow>(() => {
            this.tableBehavior.next(table);
        });

        this.tableBehavior = new BehaviorSubject(table);
    }

    public setItems(items: TRow[]) {
        this.table.setOptions((prev) => ({
            ...prev,
            data: items || [],
        }));
    }

    public setup(columns: TColDef<TRow>[], config?: GridConfig<TRow>) {
        const dataSourceConfig = config?.dataSourceConfig;

        this.tableScroll.setDataSource(dataSourceConfig);
        this.tableTree.setDataSource(dataSourceConfig, () => {
            this.refreshItems();
        });

        const hasGrouping = columns.some((col) => col.rowGroup);
        const initialState = TanStackGridAdapter.getColumnsInitialState(config);

        const state: Partial<TableState> = {
            columnVisibility: ArrayUtils.toRecord(columns, (col) => [
                col.id,
                !col.hidden,
            ]),
            columnOrder: columns
                .filter((col) => !col.hidden)
                .map((col) => col.id),
            columnPinning: {
                left: columns.filter((c) => c.fixed).map((col) => col.id),
            },
            grouping: columns
                .filter((col) => col.rowGroup)
                .map((col) => col.id),
            expanded: this.tableTree.expandAll || hasGrouping || {},
            ...initialState,
        };

        this.table.initialState = {
            ...this.table.initialState,
            ...state,
        };

        const isExpandable = this.tableTree.enabled || hasGrouping;

        this.table.setOptions((prev) => ({
            ...prev,
            initialState,
            state: {
                ...prev.state,
                ...state,
            },
            columns: TanStackGridAdapter.convertColumns(columns),
            getRowId: (row) => config?.getItemId(row) || '',
            getSubRows: (row: TRow) => this.tableTree.getChildren(row),
            getIsRowExpanded: this.tableTree.isExpandedFn,
            getRowCanExpand: (row: Row<TRow>) =>
                this.tableTree.canExpand(row.original),
            getGroupedRowModel: hasGrouping ? getGroupedRowModel() : undefined,
            getExpandedRowModel: isExpandable
                ? getExpandedRowModel()
                : undefined,
        }));
    }

    public async onScrollIndexChange(index: number) {
        const rowCount = this.table.getRowCount();
        const res = await this.scroll.onScrollIndexChange(index, rowCount);

        if (!res?.length) {
            return;
        }

        this.addItems(res);
    }

    public isColumnVisible(colId: string) {
        return this.table.getColumn(colId)?.getIsVisible();
    }

    public refreshItems() {
        this.table.setOptions((prev) => ({
            ...prev,
            data: [...prev.data],
        }));
    }

    public onResizeHeader(event: MouseEvent, header: Header<TRow, unknown>) {
        event.stopPropagation();
        const resizeHandler = header.getResizeHandler();

        resizeHandler(event);
    }

    public reorderHeader(previousIndex: number, currentIndex: number) {
        const columns = this.table.getVisibleLeafColumns();
        if (!columns) {
            return;
        }
        const columnIds = columns.map((col) => col.id);
        moveItemInArray(columnIds, previousIndex, currentIndex);
        this.table.setColumnOrder(columnIds);
    }

    public toggleColumnVisibility(colId: string, availableWidth: number) {
        const column = this.table?.getColumn(colId);

        if (!column || !this.table) {
            return;
        }

        const isVisible = column.getIsVisible();

        if (isVisible) {
            column.toggleVisibility();
            this.fitColumnsToWidth(availableWidth);
        } else {
            const remainingWidth = availableWidth - column.getSize();
            this.fitColumnsToWidth(remainingWidth);
            column.toggleVisibility();
        }
    }

    public fitColumnsToWidth(availableWidth: number) {
        const columns = this.table.getVisibleLeafColumns();
        const columnSizing = columns.map((col) => ({
            id: col.id,
            width: col.getSize() ?? col.columnDef.size,
            minWidth: col.columnDef.minSize,
        }));

        this.table.setColumnSizing((prev) => ({
            ...prev,
            ...GridColumnSizeUtils.fitColumnSizingToWidth(
                columnSizing,
                availableWidth
            ),
        }));
    }

    public resetColumns() {
        this.table.resetColumnOrder();
        this.table.resetColumnSizing();
        this.table.resetColumnVisibility();
    }

    public setSelection(rows: string[]) {
        this.table.setRowSelection({
            ...rows.reduce((acc, row) => ({ ...acc, [row]: true }), {}),
        });
    }

    private addItems(items: TRow[]) {
        this.table.setOptions((prev) => ({
            ...prev,
            data: [...prev.data, ...items],
        }));
    }
}
