/** Matches color names in graphical-colors.global.scss
 * **Important**: Stored as number in DB (in diagram elements VisualData json property)
 * Add a color: put it at the end then add key name to "GraphicalColorGridOrder" where you see fits
 */
export enum GraphicalColor {
    _multi = -1,
    _none = 0,
    Black,
    White,
    Indigo,
    Ruby,
    Magenta,
    Reflex2,
    Blue,
    Turquoise,
    Wood,
    Green,
    Lime,
    Orange,
    Yellow,
    Gray,
    LightGray,
    Reflex,
}
