<ng-container *ngIf="integration$ | async as integration">
    <span
        *ngIf="integration.Activated"
        class="dg5-badge nav-link-badge"
        translate
        >UI.NotificationChannels.MsTeams.alreadyConnected</span
    >
    <button
        *ngIf="!integration.Activated"
        class="small"
        type="button"
        mat-flat-button
        color="primary"
        (click)="connect()"
        data-tracker-id="Integration_Teams_Notifications_ConnectTeams"
    >
        <span translate>UI.NotificationChannels.MsTeams.connect</span>
    </button>
</ng-container>
