import { CollectionsHelper } from '@datagalaxy/core-util';
import { CoreUtil } from '@datagalaxy/core-util';
import { DomUtil } from '@datagalaxy/core-util';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { EntityTaskDTO, ObjectTaskStatus } from '@datagalaxy/dg-object-model';
import { DxyTaskItemComponent } from '../dxy-task-item/dxy-task-item.component';
import { TaskService } from '../task.service';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { DeleteEntityTasksParameter } from '@datagalaxy/webclient/task/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'dxy-task-container',
    templateUrl: 'dxy-task-container.component.html',
    styleUrls: ['dxy-task-container.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        MatLegacyButtonModule,
        MatLegacyTooltipModule,
        MatLegacyMenuModule,
        NgFor,
        DxyTaskItemComponent,
    ],
})
export class DxyTaskContainerComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: IMiniEntityContent;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;

    @ViewChild('itemsContainer') itemsContainerRef: ElementRef<HTMLElement>;
    @ViewChildren(DxyTaskItemComponent, { read: ElementRef })
    taskItemsQueryList: QueryList<ElementRef<HTMLElement>>;

    public availableStatusFilters: ObjectTaskStatus[];
    public activeStatusFilter: ObjectTaskStatus;
    public currentEditedTaskId: string;

    public newTaskText: string;
    public newTaskTitle: string;
    public newTaskAssignedToUserId: string;

    public isLoading = false;
    public isFormCollapsed = true;
    public oldTasksNumber = 0;
    public tasks: EntityTaskDTO[] = [];

    public get canAddTask() {
        return !this.readOnly;
    }
    public get securityData() {
        return this.entityData?.SecurityData;
    }
    public get activeTasks() {
        return this.activeStatusFilter != null
            ? this.tasks.filter((t) => t.Status == this.activeStatusFilter)
            : this.tasks;
    }
    public get activeTabStatusTranslateKey() {
        return this.activeStatusFilter != null
            ? this.getStatusTabTranslateKey(this.activeStatusFilter)
            : 'UI.TaskContainer.lblFilterAll';
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private taskService: TaskService,
        private entityEventService: EntityEventService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'entityData', () => this.updateData());
    }

    ngOnInit() {
        this.availableStatusFilters =
            this.taskService.getAvailableStatusValues();
        this.subscribeExternalTaskEvents();
        this.updateData();
    }

    public getTaskId(index: number, task: EntityTaskDTO) {
        return task.ReferenceId;
    }

    public onScroll() {
        if (this.isScrollbarVisible()) {
            this.updateOldInvisibleTasksNumber();
        }
    }

    public onToggleForm() {
        this.isFormCollapsed = !this.isFormCollapsed;
    }

    public async loadTasks() {
        if (!this.entityData) {
            return;
        }

        this.isLoading = true;
        const tasks = await this.taskService.loadTasks(this.entityData);
        this.tasks = tasks;
        this.isLoading = false;
        setTimeout(() => this.scrollToMostRecentElement(), 50);
    }

    public setCurrentEditedTask(task: EntityTaskDTO) {
        this.log('setCurrentEditedTask', { readOnly: this.readOnly }, task);
        if (this.readOnly) {
            return;
        }
        this.currentEditedTaskId = task.ReferenceId;
        setTimeout(() => this.scrollToActiveElement(task), 50);
    }

    public isCurrentEditedTask(task: EntityTaskDTO) {
        return task.ReferenceId === this.currentEditedTaskId;
    }

    public initCurrentEditedTask() {
        this.currentEditedTaskId = null;
    }

    public async deleteTask(task: EntityTaskDTO) {
        if (!this.entityData || this.readOnly) {
            return;
        }
        const deleted = await this.taskService.confirmThenDeleteTask(
            this.entityData,
            task
        );
        if (!deleted) {
            return;
        }
        const removed = CollectionsHelper.remove(
            this.tasks,
            (t) => t.ReferenceId === task.ReferenceId
        );
        if (this.entityData.SocialData) {
            this.entityData.SocialData.TotalTaskCount -= removed.length;
        }
    }

    public scrollToMostRecentElement() {
        const listContainerElement = this.itemsContainerRef.nativeElement;
        const mostRecentElement = this.taskItemsQueryList.last?.nativeElement;
        if (!mostRecentElement) {
            return;
        }

        const scrollPosition = listContainerElement.scrollTop;
        const containerPositionTop =
            DomUtil.getGlobalOffset(listContainerElement).top;
        const itemPositionTop = DomUtil.getGlobalOffset(mostRecentElement).top;

        listContainerElement.scrollBy({
            top: itemPositionTop - containerPositionTop + scrollPosition,
            behavior: 'smooth',
        });
    }

    public scrollToActiveElement(task: EntityTaskDTO) {
        const listContainerElement = this.itemsContainerRef.nativeElement;
        const activeElement = DomUtil.getElement(
            this.elementRef,
            `[data-task-id="${task.ReferenceId}"]`
        );

        const scrollPosition = listContainerElement.scrollTop;
        const containerPositionTop =
            DomUtil.getGlobalOffset(listContainerElement).top;
        const itemPositionTop = DomUtil.getGlobalOffset(activeElement).top;
        const scrollValue =
            itemPositionTop - containerPositionTop + scrollPosition;

        listContainerElement.scrollBy({ top: scrollValue, behavior: 'smooth' });
    }

    public updateOldInvisibleTasksNumber() {
        const listItemElements = DomUtil.getElements(
            this.elementRef,
            'dxy-task-item'
        );
        for (let i = 0, il = listItemElements.length; i < il; ++i) {
            if (this.isOldInvisibleTask(listItemElements[i])) {
                this.oldTasksNumber = i;
                break;
            }
        }
    }

    public changeFilter(newStatusFilter?: ObjectTaskStatus) {
        this.activeStatusFilter = newStatusFilter;
        this.initCurrentEditedTask();
        setTimeout(() => this.scrollToMostRecentElement(), 50);
    }

    public getStatusCount(status: ObjectTaskStatus) {
        return this.tasks?.filter((task) => task.Status === status)?.length;
    }

    public getStatusTabTranslateKey(status: ObjectTaskStatus): string {
        return `UI.TaskContainer.StatusTabs.${ObjectTaskStatus[status]}`;
    }

    public showStatusCount(status: ObjectTaskStatus) {
        return (
            !this.isLoading &&
            !!this.tasks?.filter((task) => task.Status === status)?.length
        );
    }

    public async openTaskNewModal() {
        await this.taskService.openTaskFormModal(this.entityData);
    }

    private subscribeExternalTaskEvents() {
        super.registerSubscription(
            this.entityEventService.subscribeTaskUpdate(
                (taskDto: EntityTaskDTO, external?: boolean) => {
                    if (!external) {
                        return;
                    }
                    if (
                        !taskDto ||
                        !this.entityData ||
                        !this.entityData.SocialData
                    ) {
                        return;
                    }
                    if (
                        taskDto.HddData.VersionId != this.entityData.VersionId
                    ) {
                        return;
                    }

                    const matchingHdd = taskDto.HddData.Parents?.find(
                        (c) => c.DataReferenceId === this.entityData.ReferenceId
                    );
                    if (!matchingHdd) {
                        return;
                    }

                    const existing = this.tasks?.find(
                        (t) => t.ReferenceId == taskDto.ReferenceId
                    );
                    if (existing) {
                        CoreUtil.merge(existing, taskDto);
                    } else {
                        this.tasks.push(taskDto);
                        this.entityData.SocialData.TotalTaskCount++;
                    }
                }
            )
        );

        super.registerSubscription(
            this.entityEventService.subscribeTaskDelete(
                (
                    deleteParam: DeleteEntityTasksParameter,
                    external?: boolean
                ) => {
                    if (!external) {
                        return;
                    }
                    if (!this.entityData || !this.entityData.SocialData) {
                        return;
                    }
                    if (deleteParam.VersionId != this.entityData.VersionId) {
                        return;
                    }
                    if (
                        deleteParam.EntityDataReferenceId !==
                        this.entityData.ReferenceId
                    ) {
                        return;
                    }

                    const removed = CollectionsHelper.remove(this.tasks, (t) =>
                        deleteParam.TaskIds.some((id) => id === t.ReferenceId)
                    );
                    this.entityData.SocialData.TotalTaskCount -= removed.length;
                }
            )
        );

        super.registerSubscription(
            this.entityEventService.subscribeTaskCreation(
                (createdTask: EntityTaskDTO, external?: boolean) => {
                    if (!this.entityData || !this.entityData.SocialData) {
                        return;
                    }
                    if (
                        createdTask.HddData.VersionId !=
                        this.entityData.VersionId
                    ) {
                        return;
                    }
                    if (
                        !createdTask.HddData.Parents.some(
                            (c) =>
                                c.DataReferenceId ===
                                this.entityData.ReferenceId
                        )
                    ) {
                        return;
                    }
                    this.tasks.push(createdTask);
                    if (!external) {
                        setTimeout(() => this.scrollToMostRecentElement(), 100);
                    }
                }
            )
        );
    }

    private updateData() {
        if (!this.entityData) {
            return;
        }
        this.loadTasks();
    }

    private isOldInvisibleTask(taskElement: HTMLElement) {
        const listContainerElement = this.itemsContainerRef.nativeElement;
        const listContainerTop =
            DomUtil.getGlobalOffset(listContainerElement).top;
        const listItemTop = DomUtil.getGlobalOffset(taskElement).top;

        return listItemTop >= listContainerTop;
    }

    private isScrollbarVisible() {
        return !!DomUtil.isScrollbarVisible(this.itemsContainerRef);
    }
}
