<mat-expansion-panel
    #expansionPanel
    displayMode="flat"
    hideToggle
    [expanded]="!isCollapsed"
    (opened)="onOpenClose(true)"
    (closed)="onOpenClose(false)"
    (afterExpand)="onAfterExpandCollapse(true)"
    (afterCollapse)="onAfterExpandCollapse(false)"
>
    <mat-expansion-panel-header *ngIf="!noHeader" (click)="onHeaderClick()">
        <button
            mat-icon-button
            class="dg5-collapsible-caret"
            [class.collapsed]="isIconCollapsed"
        ></button>
        <div class="title-container">
            <div class="title-text">
                <span>{{ title }}</span>
                <span *ngIf="titleCount" class="dg5-badge">{{
                    titleCount
                }}</span>
            </div>
            <div class="title-description" [class.hidden]="!description">
                {{ description }}
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
</mat-expansion-panel>
