<div>
    <div
        *ngIf="hasLabel"
        class="space-label"
        [class.hidden]="!isSpaceVisible"
        translate
    >
        UI.SpaceVersionSelector.workspace
    </div>
    <dxy-navigation-space-list
        *ngIf="showSpaceDropdown"
        class="space-selector"
        [class.has-label]="hasLabel"
        [class.hidden]="!isSpaceVisible"
        [projectsOnly]="projectsOnly"
        [allSpaces]="true"
        [isSpaceSelectable]="isSpaceSelectable"
        [enableFavorite]="enableFavorite"
        [allowAll]="allowAll"
        [defaultAll]="defaultAll"
        data-dtname="Space List Menu"
        [spaceId]="spaceId"
        [isSmallCaretBtn]="isSmallCaretBtn"
        [showAllSpaceRedirection]="showAllSpaceRedirection"
        [showSelectedSpaceIcon]="showSelectedSpaceIcon"
        [onlyImportable]="onlyImportable"
        [onlyImportableCatalog]="onlyImportableCatalog"
        [openOnDisplayNameClick]="openOnSpaceOrVersionClick"
        [selectedTextTrackerId]="trackerIds?.spaceSelectedText"
        [selectedCaretTrackerId]="trackerIds?.spaceDropdownCaret"
        [hideSelectedSpaceLabel]="hideSelectedSpaceLabel"
        [logId]="logId"
        (onNavSpaceSelected)="onSpaceSelected($event)"
        (onDisplayNameClick)="onSpaceNameClickInternal()"
        (openClose)="onMenuOpenCloseInternal($event)"
    ></dxy-navigation-space-list>
</div>

<div *ngIf="showSeparator" class="separator"></div>

<div *ngIf="showOfficialVersion" class="all-spaces-version-text">
    <div *ngIf="showOfficialVersionLabel" class="space-label" translate>
        UI.SpaceVersionSelector.version
    </div>
    <div translate>UI.SpaceVersionSelector.officialVersion</div>
</div>
<div>
    <div *ngIf="showVersionLabel" class="space-label" translate>
        UI.SpaceVersionSelector.version
    </div>
    <dxy-versioning-selector
        *ngIf="isProjectVersioned"
        class="version-selector"
        [class.hidden]="!showVersion"
        [projectIdr]="projectIdr"
        [enableFavorite]="enableFavorite"
        [isSmallCaretBtn]="isSmallCaretBtn"
        [useCurrentSpace]="useCurrentSpace"
        [isVersionSelectable]="isVersionSelectable"
        [openOnDisplayNameClick]="openOnSpaceOrVersionClick"
        [hideOfficialVersion]="hideOfficialVersion"
        [selectedTextTrackerId]="trackerIds?.versionSelectedText"
        [selectedCaretTrackerId]="trackerIds?.versionDropdownCaret"
        [logId]="logId"
        (onProjectVersionSelected)="onVersionSelected($event)"
        (onDisplayNameClick)="onVersionNameClickInternal()"
        (openClose)="onMenuOpenCloseInternal($event)"
    ></dxy-versioning-selector>
</div>
