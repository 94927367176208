import { IListOption } from '../../IListOption';
import { ValueSelectionHelper } from '../../ValueSelectionHelper';
import { DxyCaptionPositionSelectorComponent } from './caption-position-selector/caption-position-selector.component';
import { ICaptionPositionSelectorParams } from './caption-position-selector/caption-position-selector.types';
import {
    CaptionPositionInsideLeftTop,
    CaptionPositionKeys,
    CaptionPositionValuesByKey,
    ICaptionPosition,
    ICaptionPositionAvailableValues,
    TCaptionAlignH,
    TCaptionAlignV,
    TCaptionPositionKey,
    TCaptionPositionProperty,
    TCaptionPositionType,
} from './caption-position.types';

interface IListOptionOpt {
    customClass?: string;
    tooltipTranslateKey?: string;
}
interface IHelperOpt<TItem> {
    withSelected?: (o: Partial<ICaptionPosition>, ...items: TItem[]) => unknown;
    debug?: boolean;
    logIdPrefix?: string;
}
interface IPropOpt<T extends TCaptionPositionProperty, TItem> {
    availables?: T[];
    default?: T;
    getValue?: (o: TItem) => T;
}
interface IBaseParams<TItem> extends IListOptionOpt, IHelperOpt<TItem> {}
export interface IBuildCaptionPositionSelectorListOptionParamsFull<TItem>
    extends IBaseParams<TItem> {
    type: IPropOpt<TCaptionPositionType, TItem>;
    alignH: IPropOpt<TCaptionAlignH, TItem>;
    alignV: IPropOpt<TCaptionAlignV, TItem>;
}
export interface IBuildCaptionPositionSelectorListOptionParamsSimple<TItem>
    extends IBaseParams<TItem> {
    getValue: (e: TItem) => ICaptionPosition;
}
export function buildCaptionPositionSelectorListOption<TItem>(
    params:
        | IBuildCaptionPositionSelectorListOptionParamsFull<TItem>
        | IBuildCaptionPositionSelectorListOptionParamsSimple<TItem>,
    ...items: TItem[]
): IListOption<ICaptionPosition> {
    const keys = CaptionPositionKeys;
    const props = {} as {
        [K in TCaptionPositionKey]: IPropOpt<TCaptionPositionProperty, TItem>;
    };
    const { getValue } =
        params as IBuildCaptionPositionSelectorListOptionParamsSimple<TItem>;
    if (getValue) {
        keys.forEach((k) => (props[k] = { getValue: (e) => getValue(e)?.[k] }));
    } else {
        keys.forEach(
            (k) =>
                (props[k] = (
                    params as IBuildCaptionPositionSelectorListOptionParamsFull<TItem>
                )[k])
        );
    }
    const defaults = CaptionPositionInsideLeftTop;
    const helpers = {} as {
        [K in TCaptionPositionKey]: ValueSelectionHelper<
            TItem,
            TCaptionPositionProperty
        >;
    };
    const availableValues = {} as ICaptionPositionAvailableValues;
    const value = {} as ICaptionPosition;
    keys.forEach((k) => {
        helpers[k] = buildHelper(
            k,
            props[k],
            CaptionPositionValuesByKey[k],
            defaults[k],
            params,
            items
        );
        availableValues[k + 's'] = helpers[k].availableValues;
        value[k as string] =
            helpers[k].currentValue ?? props[k].default ?? defaults[k];
    });
    const { withSelected, customClass, tooltipTranslateKey } = params ?? {};
    return {
        renderData: {
            renderer: DxyCaptionPositionSelectorComponent,
            param: {
                ...availableValues,
                value,
                onValueChange: (val) => {
                    keys.filter((k) => k in val).forEach((k) =>
                        helpers[k].onValueSelected(
                            val[k as string] as TCaptionPositionProperty
                        )
                    );
                    withSelected?.(val, ...items);
                },
                customClass,
            } as ICaptionPositionSelectorParams,
        },
        tooltipTranslateKey,
    };
}

function buildHelper<T extends TCaptionPositionProperty, TItem>(
    key: keyof ICaptionPosition,
    prop: IPropOpt<T, TItem>,
    defaultAvailableValues: T[],
    defaultValue: T,
    opt: IHelperOpt<TItem>,
    items: TItem[]
) {
    return new ValueSelectionHelper<TItem, T>({
        selection: items,
        availableValues: prop.availables ?? defaultAvailableValues,
        getValue: (e) => prop.getValue(e),
        defaultValue: prop.default ?? defaultValue,
        withSelectedValue: () => true,
        debug: opt.debug,
        logId: `${opt.logIdPrefix ? opt.logIdPrefix + '-' : ''}caption-${key}`,
    });
}

/** Returns the given *object* (or a new one if *null* or *undefined* is given or if *clone* is true),
 * updated with non-*undefined* properties of the given *data*.
 * Unless *defaults* is *false*, if a *data* property has the default value, the *result* property is set to *undefined*.
 * If every property of *result* is *undefined*, then *undefined* is returned.
 * */
export function updateCaptionPosition(
    object: ICaptionPosition,
    data: Partial<ICaptionPosition>,
    defaults: ICaptionPosition | false = CaptionPositionInsideLeftTop,
    clone?: boolean
) {
    const result = clone && object ? { ...object } : object ?? {};
    CaptionPositionKeys.filter((k) => k in data).forEach(
        (k) =>
            (result[k as string] =
                typeof defaults == 'object' && data[k] == defaults[k]
                    ? undefined
                    : data[k])
    );
    return CaptionPositionKeys.every((k) => result[k] == undefined)
        ? undefined
        : result;
}

export function getCaptionClasses(
    value: ICaptionPosition,
    defaults = CaptionPositionInsideLeftTop
) {
    return CaptionPositionKeys.map(
        (k) => value?.[k] ?? (defaults[k] as string)
    );
}
