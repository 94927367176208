import { FacetGroupType } from './FacetGroupType';
import { IComparableSearchFilterItem } from '../../search.service';
import { getLocalId } from '@datagalaxy/webclient/utils';
import {
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';

export class FacetItem implements IComparableSearchFilterItem {
    public readonly id: string;
    public readonly valueId: string;
    public readonly displayName: string;
    public readonly attributeKey: string;
    public readonly attributeType: AttributeMetaType;
    public readonly glyphClassName: string;
    public readonly tooltipText: string;
    public readonly glyphColor: string;
    public readonly hasIcon: boolean;
    public readonly iconUrl: string;

    public isChecked: boolean;

    constructor(
        public readonly groupType: FacetGroupType,
        public readonly dataCount: number,
        public readonly attributeValue: AttributeMetaValue,
        public readonly compareAsLocalId: boolean,
        opt?: IFacetItemOptData
    ) {
        this.displayName = attributeValue.translatedDisplayName;
        this.valueId = compareAsLocalId
            ? getLocalId(attributeValue.Key)
            : attributeValue.Key;
        const ami = attributeValue.attributeInfo;
        this.attributeKey = ami.AttributeKey;
        this.attributeType = ami.AttributeType;
        this.id = `facet${this.attributeKey}${this.valueId}`;
        this.glyphClassName = opt?.glyphClassName;
        this.tooltipText = opt?.tooltipText;
        this.glyphColor = opt?.glyphColor;
        this.iconUrl = opt?.iconUrl;
        this.hasIcon = opt?.hasIcon;
    }

    public containsValue(value: string, compareAsLocalId: boolean) {
        return this.compareAsLocalId
            ? compareAsLocalId
                ? this.valueId == value
                : this.valueId == getLocalId(value)
            : compareAsLocalId
            ? getLocalId(this.valueId) == value
            : this.valueId == value;
    }

    public equals(other: IComparableSearchFilterItem) {
        return (
            other instanceof FacetItem &&
            other.groupType == this.groupType &&
            other.attributeKey == this.attributeKey &&
            other.valueId == this.valueId
        );
    }
}

export interface IFacetItemOptData {
    glyphClassName?: string;
    tooltipText?: string;
    glyphColor?: string;
    hasIcon?: boolean;
    iconUrl?: string;
}
