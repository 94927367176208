import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IDiagramEditModalInputs } from './diagram-edit-modal.types';
import { DiagramDataService } from '../diagram/diagram-data.service';
import { ViewTypeService } from '../../services/viewType.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-diagram-edit-modal',
    templateUrl: './diagram-edit-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyFieldTextComponent,
        DxyModalFooterComponent,
    ],
})
export class DiagramEditModalComponent
    extends DxyBaseModalComponent<IDiagramEditModalInputs, EntityItem>
    implements OnInit
{
    public displayName: string;
    public canCreate: boolean;
    public creationLoading: boolean;

    public get modalTitleKey() {
        if (this.data.isUpdate) {
            return 'UI.Dialog.NewItem.Diagram.titleUpdate';
        }
        if (this.data.isCopy) {
            return 'UI.Dialog.CopyItem.ModelDiagram.title';
        }
    }
    public get actionBtnLblKey() {
        const suffix = this.data.isUpdate
            ? 'lblSave'
            : this.data.isCopy
            ? 'lblCopy'
            : undefined;
        return suffix ? `UI.Dialog.NewItem.${suffix}` : '';
    }
    public get featureCode() {
        if (this.data.isUpdate) {
            return 'DIAGRAM,U';
        }
        if (this.data.isCopy) {
            return 'DIAGRAM_COPY,C';
        }
    }

    constructor(
        private diagramDataService: DiagramDataService,
        private viewTypeService: ViewTypeService,
        dialogRef: MatDialogRef<DiagramEditModalComponent, EntityItem>,
        @Inject(MAT_DIALOG_DATA) data: IDiagramEditModalInputs
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        if (this.data.isUpdate || this.data.isCopy) {
            const diagram = this.data.diagram;
            this.displayName =
                this.viewTypeService.getTechnicalOrDisplayName(diagram);
        }
    }

    public updateCanCreate() {
        if (this.data.isCopy) {
            this.canCreate = !!this.displayName;
        } else if (this.data.isUpdate) {
            this.canCreate =
                !!this.displayName &&
                this.displayName != this.data.diagram.DisplayName;
        }
    }

    public onCloseSubmit() {
        this.creationLoading = true;
        this.onCreate().then((outputDiagram) => {
            this.displayName = null;
            this.creationLoading = false;
            this.result = outputDiagram;
            super.onCloseSubmit();
        });
    }

    private async onCreate(): Promise<EntityItem> {
        let outputDiagram: EntityItem;
        const { isUpdate, diagram: inputDiagram, isCopy } = this.data;
        const diagramName = this.displayName?.trim();
        if (isUpdate) {
            outputDiagram = await this.diagramDataService.renameDiagram(
                inputDiagram,
                diagramName
            );
        } else if (isCopy) {
            outputDiagram = await this.diagramDataService.cloneDiagram(
                inputDiagram,
                diagramName
            );
        }
        return outputDiagram;
    }
}
