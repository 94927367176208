import { IEntityForm } from '../entity/interfaces/entity-form.interface';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export declare interface IObjectDataTags {
    ObjectId: string;
    DisplayName: string;
    TagColor: string;
    TagDescription: string;
}

export class AttributeFieldInfo {
    constructor(
        public attributeMeta: AttributeMetaInfo,
        public entityForm: IEntityForm<any>
    ) {}
}
