import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { StringUtil } from '@datagalaxy/core-util';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class AttributeTextFilterModel extends AttributeFilterModel {
    public static readonly defaultAvailableOperators = [
        FilterOperator.TextContains,
        FilterOperator.TextEquals,
        FilterOperator.TextStartsWith,
        FilterOperator.TextEndsWith,
        FilterOperator.FieldIsEmpty,
        FilterOperator.FieldHasValue,
    ];

    public static readonly quickFilterAvailableOperators = [
        FilterOperator.TextContains,
    ];

    public static availableOperators(isQuickFilter?: boolean) {
        return isQuickFilter
            ? AttributeTextFilterModel.quickFilterAvailableOperators
            : AttributeTextFilterModel.defaultAvailableOperators;
    }

    public value: string;

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[]
    ) {
        super(attributeMeta, operators, filterFormData);
        this.value = null;
    }

    getValuesAsArray() {
        return this.value == undefined ? [] : [this.value];
    }

    isValid() {
        return (
            !StringUtil.isNullOrEmpty(this.value) ||
            this.operator == FilterOperator.FieldIsEmpty ||
            this.operator == FilterOperator.FieldHasValue
        );
    }

    setValuesFromDb(values: string[]) {
        this.value = values?.[0];
    }

    copy(filterItem: AttributeTextFilterModel) {
        this.operator = filterItem.operator;
        this.value = filterItem.value;
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        return (
            other instanceof AttributeTextFilterModel &&
            ((this.value == undefined && other.value == undefined) ||
                this.value == other.value)
        );
    }
}
