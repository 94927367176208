import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import {
    IDiagramContentUpdateOne,
    TContentOpType,
    TDiagramDto,
} from './diagram.types';
import { DiagramData } from './DiagramData';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import {
    ABaseDiagramDto,
    DiagramApiService,
    DiagramEdgeDto,
    DiagramEdgeItem,
    DiagramNodeDto,
    DiagramNodeItem,
    DiagramNodeKind,
    DiagramSourceParameter,
    GetDiagramContentParameter,
    IUpdateDiagramContent,
    UpdateDiagramContentParameter,
} from '@datagalaxy/webclient/diagram/data-access';
import { EntityService } from '../../shared/entity/services/entity.service';
import { EntityServerTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { getContextId, getLocalId } from '@datagalaxy/webclient/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { HddUtil } from '../../shared/util/HddUtil';

/**
 * ## Role
 *  Performs data operations on a Generic Diagram Content
 * ## Type
 *  Stateless
 */
@Injectable({ providedIn: 'root' })
export class DiagramService extends BaseService {
    constructor(
        private diagramApiService: DiagramApiService,
        private entityService: EntityService
    ) {
        super();
    }

    public async getDiagramAssets(diagramEntity: IEntityIdentifier) {
        const content = await this.getDiagramContent(
            diagramEntity.ReferenceId,
            diagramEntity.VersionId
        );
        return DiagramData.getEntitiesHierarchicalData(content);
    }

    public async getDiagramContent(diagramId: string, versionId: string) {
        const spaceId = `${getContextId(diagramId)}:${getContextId(diagramId)}`;
        const spaceIdr = new SpaceIdentifier(spaceId, versionId);
        const res = await this.diagramApiService.getDiagramContent(
            new GetDiagramContentParameter(diagramId, versionId)
        );

        await this.loadDiagramNodesHierarchicalData(spaceIdr, res.Nodes);
        await this.loadDiagramNodesWithRestrictedAccess(
            spaceIdr,
            diagramId,
            res.Nodes
        );

        return res;
    }

    public async addDiagramSources(diagramId: string, ...sourceIds: string[]) {
        return this.diagramApiService.addDiagramSources(
            new DiagramSourceParameter(diagramId, sourceIds)
        );
    }

    //#region content management

    public async addItems(
        diagramData: DiagramData,
        ...dtos: (DiagramNodeDto | DiagramEdgeDto)[]
    ) {
        return this.update(
            diagramData,
            ...(dtos.map((dto) => ({
                type: 'A',
                dto,
            })) as TDiagramUpdateContentOperations)
        );
    }

    public async removeItems(
        diagramData: DiagramData,
        ...dtos: (DiagramNodeDto | DiagramEdgeDto)[]
    ) {
        return this.update(
            diagramData,
            ...(dtos.map((dto) => ({
                type: 'D',
                dto,
            })) as TDiagramUpdateContentOperations)
        );
    }

    public async updateNode(
        diagramData: DiagramData,
        dto: DiagramNodeDto,
        ...keys: (keyof DiagramNodeItem)[]
    ) {
        return this.update(diagramData, { dto, type: 'U', keys });
    }
    public async updateEdge(
        diagramData: DiagramData,
        dto: DiagramEdgeDto,
        ...keys: (keyof DiagramEdgeItem)[]
    ) {
        return this.update(diagramData, { dto, type: 'U', keys });
    }

    public async updateNodes(
        diagramData: DiagramData,
        key: keyof DiagramNodeItem,
        ...dtos: DiagramNodeDto[]
    ) {
        return this.update(
            diagramData,
            ...(dtos.map((dto) => ({
                dto,
                type: 'U',
                keys: [key],
            })) as TDiagramUpdateContentOperations)
        );
    }
    public async updateEdges(
        diagramData: DiagramData,
        key: keyof DiagramEdgeItem,
        ...dtos: DiagramEdgeDto[]
    ) {
        return this.update(
            diagramData,
            ...(dtos.map((dto) => ({
                dto,
                type: 'U',
                keys: [key],
            })) as TDiagramUpdateContentOperations)
        );
    }
    public async updateNodesPosition(
        diagramData: DiagramData,
        ...dtos: DiagramNodeDto[]
    ) {
        return this.update(
            diagramData,
            ...(dtos.map((dto) => ({
                dto,
                type: 'U',
                keys: ['Top', 'Left', 'Width', 'Height'],
            })) as TDiagramUpdateContentOperations)
        );
    }
    //#endregion

    public async update(
        diagramData: DiagramData,
        ...ops: TDiagramUpdateContentOperations
    ) {
        /* Notes:
            ops contain DTOs created by the client or received from GetDiagramContent, and keys of the properties to be persisted.
            We make items from those DTOs, and send them to the server.
            We then receive DTOs from the server, but we use them only to update properties of our DTO instances.
            Example of property that is computed by the server: ForeignKeyHierarchicalData
        */

        this.log('update', ops, diagramData);
        const p = this.makeUpdateDiagramContentParameter(diagramData, ops);
        const res = await this.diagramApiService.updateDiagramContent(p);
        this.log('update-result', res);

        diagramData.updateContent(ops);
        return res;
    }

    private makeUpdateDiagramContentParameter(
        diagramData: DiagramData,
        ops: TDiagramUpdateContentOperations
    ) {
        const p = new UpdateDiagramContentParameter();
        p.DiagramId = diagramData.diagramId;
        p.VersionId = diagramData.versionId;
        ops.forEach((op) => {
            const { type, dto } = op;
            const arrayName = this.getUpdateContentArrayName(dto, type);
            const array: (TDiagramUpdateItem | string)[] = (p[arrayName] ??=
                []);
            if (op.type == 'U' && !op.keys.includes('ReferenceId')) {
                op.keys.push('ReferenceId');
            }
            array.push(
                type == 'D'
                    ? dto.ReferenceId
                    : this.makeUpdateContentItem(dto, op.keys)
            );
        });
        return p;
    }

    private getUpdateContentArrayName(
        obj: TDiagramDto | TDiagramUpdateItem,
        opType: TContentOpType
    ): keyof IUpdateDiagramContent {
        if (obj instanceof DiagramNodeDto || obj instanceof DiagramNodeItem) {
            switch (opType) {
                case 'A':
                    return 'AddedNodes';
                case 'U':
                    return 'UpdatedNodes';
                case 'D':
                    return 'DeletedNodes';
            }
        } else if (
            obj instanceof DiagramEdgeDto ||
            obj instanceof DiagramEdgeItem
        ) {
            switch (opType) {
                case 'A':
                    return 'AddedEdges';
                case 'U':
                    return 'UpdatedEdges';
                case 'D':
                    return 'DeletedEdges';
            }
        }
    }

    private makeUpdateContentItem(dto: ABaseDiagramDto, onlyKeys?: string[]) {
        let item: TDiagramUpdateItem, itemKeys: string[];
        if (dto instanceof DiagramNodeDto) {
            item = new DiagramNodeItem();
            itemKeys = DiagramNodeItem.keys;
        } else if (dto instanceof DiagramEdgeDto) {
            item = new DiagramEdgeItem();
            itemKeys = DiagramEdgeItem.keys;
        } else {
            return;
        }
        const keys = onlyKeys?.length
            ? itemKeys.filter((k) => onlyKeys.includes(k))
            : itemKeys;
        keys.forEach((k) => (item[k] = dto[k]));
        return item;
    }

    /**
     * Mutate diagram nodes to set their entityHierarchicalData property
     */
    private async loadDiagramNodesHierarchicalData(
        spaceIdr: ISpaceIdentifier,
        nodes: DiagramNodeDto[]
    ): Promise<void> {
        const nodeIds = nodes
            .filter((node) => node.NodeKind === DiagramNodeKind.Entity)
            .map((node) => node.EntityReferenceId);

        if (!nodeIds.length) {
            return;
        }

        const entitiesResult = await this.entityService.loadMultiEntity({
            parentReferenceId: spaceIdr.spaceId,
            versionId: spaceIdr.versionId,
            dataReferenceIdList: nodeIds,
            includeHdd: true,
            dataTypes: [
                ...EntityServerTypeUtils.firstClassEntityServerTypes,
                ServerType.DataProcessingItem,
            ],
        });

        entitiesResult.Entities.forEach((entity) => {
            const matchingNodes = nodes.filter(
                (n) => n.EntityReferenceId === entity.ReferenceId
            );

            matchingNodes?.forEach((node) => {
                node.entityHierarchicalData = entity.HddData;
            });
        });
    }

    private async loadDiagramNodesWithRestrictedAccess(
        spaceIdr: ISpaceIdentifier,
        diagramId: string,
        nodes: DiagramNodeDto[]
    ) {
        if (!nodes.length) {
            return;
        }

        const resNoAccess = await this.diagramApiService.getDiagramNoAccessData(
            getContextId(diagramId),
            spaceIdr.versionId,
            getLocalId(diagramId)
        );

        resNoAccess.Entities.forEach((entity) => {
            const noAccessNodes = nodes.filter(
                (node) => node.EntityReferenceId === entity.ReferenceId
            );

            noAccessNodes?.forEach((node) => {
                const hdd = HddUtil.createNoAccessHData(entity);

                node.hasNoAccess = true;
                node.entityHierarchicalData = hdd;
            });
        });
    }
}

export type TDiagramUpdateContentOperations = (
    | IDiagramUpdateContentOperation<DiagramNodeItem>
    | IDiagramUpdateContentOperation<DiagramEdgeItem>
)[];

interface IDiagramUpdateContentOperation<TItem>
    extends IDiagramContentUpdateOne {
    /** mandatory for Update */
    keys?: (keyof TItem)[];
}
type TDiagramUpdateItem = DiagramNodeItem | DiagramEdgeItem;
