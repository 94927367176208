import { Component, Input } from '@angular/core';
import { TAccessLevel } from '../errorPages.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyErrorPageComponent } from '../dxy-error-page/dxy-error-page.component';

@Component({
    selector: 'dxy-error-main',
    templateUrl: 'dxy-error-main.component.html',
    styleUrls: ['dxy-error-main.component.scss'],
    standalone: true,
    imports: [DxyErrorPageComponent],
})
export class DxyErrorMainComponent extends DxyBaseComponent {
    @Input() errorMessage: string;
    @Input() accessLevel: TAccessLevel;

    constructor() {
        super();
    }
}
