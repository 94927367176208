import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { FileDropDirective } from '../file-drop.directive';

@Component({
    selector: 'dxy-file-input',
    standalone: true,
    imports: [CommonModule, FileDropDirective, SpinnerComponent],
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent implements ControlValueAccessor {
    @Input() label!: string;
    @Input() buttonText!: string;
    @Input() name?: string;
    @Input() glyphClass = 'glyph-csv-file';
    @Input() loading?: boolean | null = false;
    @Input() succeeded?: boolean;
    @Input() acceptedMimeTypes: string[] = ['text/csv'];
    @Output() fileChange = new EventEmitter<File>();
    public file?: File;

    @HostBinding('class.success') get successIconVisible() {
        return !this.loading && this.succeeded === true;
    }

    @HostBinding('class.error') get errorIconVisible() {
        return !this.loading && this.succeeded === false;
    }

    protected get glyphVisible() {
        return !this.loading && this.succeeded === undefined;
    }

    @ViewChild('fileInputRef')
    private fileInputRef!: ElementRef<HTMLInputElement>;

    private onChange: any = () => {};

    writeValue(file: File) {
        this.file = file;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    registerOnTouched(_fn: any) {}

    protected handleFiles(files: File[]) {
        const file = files?.[0];
        if (
            file?.type &&
            !this.acceptedMimeTypes.includes(file.type.toLowerCase())
        ) {
            return;
        }
        this.file = file;
        this.fileChange.emit(file);
    }

    protected onFilesSelected(event: Event) {
        const target = event.target as HTMLInputElement;
        const fileList = target.files as FileList;
        const files = Array.from(fileList);
        this.handleFiles(files);
    }

    protected openFileExplorer() {
        this.fileInputRef.nativeElement.click();
    }
}
