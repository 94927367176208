import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { TeamService } from '../team.service';
import { ServerTimeService } from '../../services/serverTime.service';
import {
    TeamDto,
    TeamAuditData,
    TeamAuditActionType,
} from '@datagalaxy/webclient/team/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { UserDto } from '@datagalaxy/webclient/user/domain';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { TeamHistoryItemComponent } from '../team-history-item/team-history-item.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-team-history',
    templateUrl: 'team-history.component.html',
    styleUrls: ['team-history.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        NgFor,
        TeamHistoryItemComponent,
        SpinnerComponent,
    ],
})
export class TeamHistoryComponent extends DxyBaseComponent implements OnInit {
    @Input() teamData: TeamDto;

    public teamAuditDataList: TeamAuditData[];
    public teamHistoryDataList: ITeamHistoryData[];
    public loading = true;
    public isEmpty = true;

    constructor(
        private translateService: TranslateService,
        private teamService: TeamService,
        private serverTimeService: ServerTimeService
    ) {
        super();
    }

    ngOnInit() {
        this.initHistory().then();
    }

    public getCreationTimeFromNow(teamAuditData: ITeamHistoryData) {
        const dateString = teamAuditData.creationTimeFromNow;
        return this.serverTimeService.getServerTimeFromNowString(
            dateString,
            true
        );
    }

    private async initHistory() {
        this.teamAuditDataList = await this.teamService.getTeamHistory(
            this.teamData.TeamUid
        );
        this.isEmpty = !this.teamAuditDataList?.length;

        this.teamAuditDataList = CollectionsHelper.orderBy(
            this.teamAuditDataList,
            (e) => e.CreationTime,
            'desc'
        );
        const teamsPublicData = await this.teamService.getTeamsPublicData();

        this.teamHistoryDataList = this.teamAuditDataList.map(
            (teamAuditData) => {
                const translateKey = `UI.Teams.History.Messages.${
                    TeamAuditActionType[teamAuditData.ActionType]
                }`;
                const isUpdateAccessType =
                    teamAuditData.ActionType ==
                    TeamAuditActionType.UpdateTeamAccessType;
                let teamData = this.teamData;

                if (teamAuditData.ActionType == TeamAuditActionType.CloneTeam) {
                    teamData = teamsPublicData.find(
                        (value) =>
                            value.TeamUid ==
                            teamAuditData.ExtraData.SourceTeamUid
                    );
                }
                const newValue = isUpdateAccessType
                    ? this.translateService.instant(
                          `UI.Teams.AccessType.${teamAuditData?.NewValue}`
                      )
                    : StringUtil.isNullOrEmpty(teamAuditData?.NewValue)
                    ? null
                    : teamAuditData.NewValue;
                const oldValue = isUpdateAccessType
                    ? this.translateService.instant(
                          `UI.Teams.AccessType.${teamAuditData?.OldValue}`
                      )
                    : StringUtil.isNullOrEmpty(teamAuditData?.OldValue)
                    ? null
                    : teamAuditData.OldValue;
                const translationParams = { newValue, oldValue };
                return {
                    innerHtml: this.translateService.instant(
                        translateKey,
                        translationParams
                    ),
                    teamData,
                    member: teamAuditData.Member,
                    maker: teamAuditData.ActionMaker,
                    creationTimeFromNow:
                        this.serverTimeService.getServerTimeFromNowString(
                            teamAuditData.CreationTime,
                            true
                        ),
                };
            }
        );
        this.groupHistoryByDate();
        this.loading = false;
    }

    private groupHistoryByDate() {
        const groupedEntries = CollectionsHelper.groupBy(
            this.teamHistoryDataList,
            (teamAuditData) => teamAuditData.creationTimeFromNow,
            (k, items) => items
        );

        this.teamHistoryDataList = CollectionsHelper.flattenGroups(
            groupedEntries,
            (entries) => {
                entries.forEach(
                    (entry, index) => (entry.isGroupHeader = !index)
                );
                return entries;
            }
        );
    }
}

export interface ITeamHistoryData {
    innerHtml: string;
    teamData: TeamDto;
    member: UserDto;
    maker: UserDto;
    creationTimeFromNow: string;
    isGroupHeader?: boolean;
}
