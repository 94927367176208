import { SerializableEnumeration } from 'cerialize';

export enum EntityLinkTypeKind {
    Unknown = 0,
    Synonym = 1,
    Generalization = 2,
    Formula = 3,
    //Description = 4, REPLACED BY IsParentOf in v2.22
    // Ownership = 5, REPLACED BY IsParentOf in v2.22
    IsParentOf = 5,
    Dependency = 6,
    GenericLink = 7,

    RefDataDescription = 9,
    DimensionDeclination = 10,

    //Contains = 11,  // REPLACED BY IsParentOf in v2.22
    IsFollowedBy = 12,
    HasUniverse = 13,
    HasDomain = 14,
    //UsageHasDimension = 15, // REPLACED BY Uses in v2.37
    UsageHasSource = 16,
    DimensionRegroups = 17,
    PropertyHasRecordingSystem = 18,
    InputUsage = 19,
    OutputUsage = 20,
    UsageCalls = 21,
    Transcode = 22,
    IsImplementedBy = 23,
    Uses = 24,
    HasInput = 25,
    HasOutput = 26,
    HasInputSource = 27,
    HasOutputSource = 28,
}
SerializableEnumeration(EntityLinkTypeKind);
