import { EntityType } from '@datagalaxy/dg-object-model';

export class EntityTypeUtils {
    public static getTranslateKey(entityType?: EntityType): string {
        if (!entityType) {
            return '';
        }
        return `Entity.EntityType.Name.${EntityType[entityType]}`;
    }

    public static getShortTranslateKey(entityType?: EntityType): string {
        if (!entityType) {
            return '';
        }
        return `Entity.EntityType.ShortName.${EntityType[entityType]}`;
    }

    public static getColoredGlyphClass(entityType?: EntityType): string {
        if (!entityType) {
            return '';
        }
        const glyphClass = this.getGlyphClass(entityType);
        const glyphColorClass = this.getGlyphColorClass(entityType);

        return `${glyphClass} ${glyphColorClass}`.trim();
    }

    public static getGlyphClass(entityType?: EntityType): string {
        if (entityType == undefined) {
            return '';
        }
        switch (entityType) {
            case EntityType.BusinessTerm:
                return 'glyph-term';
            case EntityType.Concept:
                return 'glyph-concept';
            case EntityType.IndicatorGroup:
                return 'glyph-indicator-group';
            case EntityType.Indicator:
                return 'glyph-indicator';
            case EntityType.ReferenceData:
                return 'glyph-reference-data';
            case EntityType.DataFlow:
                return 'glyph-data-flow';
            case EntityType.DataProcessing:
                return 'glyph-dataprocessing';
            case EntityType.Use:
                return 'glyph-software';
            case EntityType.DataSet:
                return 'glyph-dataset';
            case EntityType.OpenDataSet:
                return 'glyph-opendataset';
            case EntityType.Process:
                return 'glyph-process';
            case EntityType.Report:
                return 'glyph-report';
            case EntityType.Algorithm:
                return 'glyph-algorithm';
            case EntityType.Application:
                return 'glyph-software-application';
            case EntityType.Screen:
                return 'glyph-software-screen';
            case EntityType.Dashboard:
                return 'glyph-software-dashboard';
            case EntityType.Feature:
                return 'glyph-features';
            case EntityType.Table:
                return 'glyph-table';
            case EntityType.Column:
                return 'glyph-columnview';
            case EntityType.NonRelationalModel:
                return 'glyph-filestore';
            case EntityType.RelationalModel:
                return 'glyph-database';
            case EntityType.Dimension:
                return 'glyph-dimension';
            case EntityType.DimensionGroup:
                return 'glyph-dimension-group';
            case EntityType.File:
                return 'glyph-file';
            case EntityType.Field:
                return 'glyph-datafield';
            case EntityType.NoSqlModel:
                return 'glyph-nosql';
            case EntityType.Document:
                return 'glyph-document';
            case EntityType.BusinessDomain:
                return 'glyph-domain';
            case EntityType.BusinessDomainGroup:
                return 'glyph-domain-group';
            case EntityType.Universe:
                return 'glyph-universe';
            case EntityType.Model:
                return 'glyph-model';
            case EntityType.Directory:
                return 'glyph-containerfolder';
            case EntityType.Equipment:
                return 'glyph-equipment';
            case EntityType.SubStructure:
                return 'glyph-sub-structure';
            case EntityType.TagBase:
                return 'glyph-tagbase';
            case EntityType.Tag:
                return 'glyph-tag';
            case EntityType.View:
                return 'glyph-view';
            case EntityType.DataProcessingItem:
                return 'glyph-processItem';
            case EntityType.ReferenceDataValue:
                return 'glyph-value';
            case EntityType.UsageField:
                return 'glyph-usage-field';
            case EntityType.PrimaryKey:
                return 'glyph-pk';
            case EntityType.ForeignKey:
                return 'glyph-fk';
            case EntityType.Diagram:
                return 'glyph-diagram1';
            case EntityType.DiagramNode:
                return 'glyph-note';
            case EntityType.FilteredView:
                return 'glyph-filter-empty';
            case EntityType.Project:
                return 'glyph-project';
            case EntityType.Organization:
                return 'glyph-organization';
            case EntityType.UsageComponent:
                return 'glyph-usage-component';
            case EntityType.FreeDiagram:
                return 'glyph-diagram1';
            case EntityType.PhysicalDiagram:
                return 'glyph-diagram1';
            case EntityType.None:
            case EntityType.All:
                return '';
            default: {
                const exhaustiveCheck: never = entityType;
                throw new Error(exhaustiveCheck);
            }
        }
    }

    public static getGlyphColorClass(entityType?: EntityType): string {
        if (entityType == undefined) {
            return '';
        }
        switch (entityType) {
            case EntityType.BusinessTerm:
            case EntityType.Concept:
            case EntityType.Dimension:
            case EntityType.DimensionGroup:
            case EntityType.BusinessDomain:
            case EntityType.BusinessDomainGroup:
            case EntityType.Indicator:
            case EntityType.IndicatorGroup:
            case EntityType.ReferenceData:
            case EntityType.ReferenceDataValue:
            case EntityType.Universe:
                return 'functional-static-color';
            case EntityType.Column:
            case EntityType.RelationalModel:
            case EntityType.Directory:
            case EntityType.Document:
            case EntityType.Equipment:
            case EntityType.Field:
            case EntityType.File:
            case EntityType.NonRelationalModel:
            case EntityType.Model:
            case EntityType.NoSqlModel:
            case EntityType.SubStructure:
            case EntityType.Table:
            case EntityType.Tag:
            case EntityType.TagBase:
            case EntityType.View:
            case EntityType.PrimaryKey:
            case EntityType.ForeignKey:
                return 'technical-static-color';
            case EntityType.DataFlow:
            case EntityType.DataProcessing:
            case EntityType.DataProcessingItem:
                return 'technical-dynamic-color';
            case EntityType.Algorithm:
            case EntityType.Application:
            case EntityType.Dashboard:
            case EntityType.DataSet:
            case EntityType.Feature:
            case EntityType.UsageField:
            case EntityType.OpenDataSet:
            case EntityType.Process:
            case EntityType.Report:
            case EntityType.Screen:
            case EntityType.Use:
            case EntityType.UsageComponent:
                return 'functional-dynamic-color';
            case EntityType.All:
            case EntityType.None:
            case EntityType.Diagram:
            case EntityType.DiagramNode:
            case EntityType.FilteredView:
            case EntityType.Project:
            case EntityType.Organization:
            case EntityType.FreeDiagram:
            case EntityType.PhysicalDiagram:
                return '';
            default: {
                const exhaustiveCheck: never = entityType;
                throw new Error(exhaustiveCheck);
            }
        }
    }
}
