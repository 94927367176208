import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetClientInfoQueryResult } from './get-client-info-query-result';
import { firstValueFrom, map } from 'rxjs';
import { GenericDeserialize } from 'cerialize';
import { DataTypeSettings } from '@datagalaxy/webclient/modeler/data-access';

@Injectable({ providedIn: 'root' })
export class ClientInfoApiService {
    constructor(private restApiService: RestApiService) {}

    public async getClientInfo() {
        return await firstValueFrom(
            this.restApiService
                .get<GetClientInfoQueryResult>('client-info')
                .pipe(
                    map((r) => {
                        if (r?.ClientInfo?.ClientTypeSettings) {
                            r.ClientInfo.ClientTypeSettings =
                                GenericDeserialize(
                                    r.ClientInfo.ClientTypeSettings,
                                    DataTypeSettings
                                );
                        }
                        return r;
                    })
                )
        );
    }
}
