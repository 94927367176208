import {
    Directive,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { BaseCellComponent } from '../BaseCellComponent';
import { ICellParams } from '../cell-components.types';
import { ILayoutRefreshableGridCellComponent } from '@datagalaxy/ui/grid';

/**
 * ## Role
 * Base class for collection cells
 */
@Directive()
export class BaseCollectionCellComponent<
        TEntity = unknown,
        TValue = unknown,
        TCellParams extends ICellParams = ICellParams
    >
    extends BaseCellComponent<TEntity, TValue, TCellParams>
    implements OnChanges, ILayoutRefreshableGridCellComponent
{
    @Input() containerWidth?: number;
    @Input() lineHeight?: number;
    @Input() mini = false;
    @Input() fitContentWidth = false;

    /** Get true omnigird column size - more accurate than parent html element width */
    protected get columnActualWidth() {
        const w = this.params?.column?.getActualWidth();
        if (!w) {
            return;
        }
        return Math.max(w - 20, 20);
    }

    @HostBinding('style.max-width') get maxWidth() {
        return this.containerWidth ? `${this.containerWidth}px` : '100%';
    }
    @HostBinding('style.width') get width() {
        return this.fitContentWidth ? 'fit-content' : this.maxWidth;
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this.onAnyChangesBut(
            changes,
            this,
            ['params'],
            () => this.updateLayout(),
            true
        );
    }

    protected override updateBindings() {
        this.mini = this.isMini;
    }

    public override updateLayout() {
        if (!this.columnActualWidth) {
            return;
        }
        this.containerWidth = this.columnActualWidth;
    }

    public refreshLayout() {
        this.updateLayout();
        this.cd.detectChanges();
    }
}
