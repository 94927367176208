import { FieldType } from '../../types/enums/field-type.enum';
import { maskTradKeys } from '../../types/constants/mask-trad-keys.constant';
import { pathTypes } from '../../types/constants/path-types.constant';

export const azureDataLakeGen2Config = [
    {
        payloadField: 'tenant-id',
        formField: 'tenantId',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'cluster-name',
        formField: 'storageAccount',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'file-system-name',
        formField: 'containerName',
        type: FieldType.text,
        formMandatory: true,
    },
    {
        payloadField: 'directory-path',
        formField: 'path',
        type: FieldType.text,
        formMandatory: false,
    },
    {
        payloadField: 'client-id',
        formField: 'clientId',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'mask-filter',
        formField: 'maskFilter',
        type: FieldType.boolean,
        formMandatory: false,
        isOptionInfo: true,
    },
    {
        payloadField: 'mask-group',
        formField: 'maskGroup',
        type: FieldType.boolean,
        formMandatory: false,
        isOptionInfo: true,
    },
    {
        formField: 'maskList',
        payloadValue: [],
        type: FieldType.list,
        isOptionInfo: true,
        listFieldTradKeys: maskTradKeys,
    },
    {
        formField: 'pathTransformation',
        formMandatory: false,
        type: FieldType.select,
        isOptionInfo: true,
        translate: true,
        selectValues: pathTypes,
    },
    {
        payloadField: 'database-technology',
        payloadValue: 'azuredatalakegen2',
    },
    {
        payloadField: 'password',
        formField: 'clientSecret',
        formMandatory: true,
        isPassword: true,
    },
    { payloadField: 'type', payloadValue: 'MODEL' },
];
