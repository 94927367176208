import { TColDef } from '../grid-column/grid-column.types';

export interface IGridCellComponent<TRow> {
    refreshLayout?: () => void;
    col?: TColDef<TRow>;
    item?: TRow;
}

export enum GridCellType {
    /** text */
    text,
    custom,
}
