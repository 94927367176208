import { IDragDropConfig } from '@datagalaxy/core-ui';
import {
    IBoundingBox,
    ICaptionPosition,
    IGraphicalToolbarOption,
} from '@datagalaxy/core-ui/graphical';
import { ICellParams } from '@datagalaxy/core-ui/cell-components';
import { CdkDropList } from '@angular/cdk/drag-drop';
import {
    EntityType,
    HierarchicalData,
    IEntityIdentifier,
    ObjectLinkType,
} from '@datagalaxy/dg-object-model';
import {
    IEntityLinkIdentifier,
    ILinkedEntityInfo,
} from '../../shared/entity/linked-object.types';
import {
    IShift,
    ISizeParams,
    IXY,
    IXYRect,
    TDomElement,
} from '@datagalaxy/core-2d-util';
import { ComponentRef, ViewContainerRef } from '@angular/core';
import {
    ArrowType,
    IDiagNodeData,
    LineStyle,
    TConnectorGeometry,
    TDiagEdge,
} from '@datagalaxy/core-d3-util';
import { BaseDiagramNodeComponent } from './BaseDiagramNodeComponent';
import {
    Column,
    ForeignKey,
    Model,
} from '@datagalaxy/webclient/modeler/data-access';
import {
    DiagramEdgeDto,
    DiagramNodeDto,
} from '@datagalaxy/webclient/diagram/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';
import { CachedData } from './diagram.cache';

export type TNonEntityNodeType = 'note' | 'frame';
export type TNodeType = TNonEntityNodeType | 'entity';
export type TElementType = TNodeType | 'link';

/**
 * - 'fk' stands for ForeignKey
 * */
export type TEdgeType = 'default' | 'fk' | 'fk-inv' | 'link-inv';

export type TNodeEntityChild = Column;

/** types of object a user can drag&drop onto a diagram */
export type TDiagramAssetIdr = IEntityIdentifier | ForeignKey;
export type TDiagramDropItem = TDiagramAssetIdr | IEntityLinkIdentifier;
export interface IRefIdAndType {
    ReferenceId: string;
    entityType?: EntityType;
}

export interface IDiagramElementIdentifier {
    type: TElementType;
    /** null or undefined for creation.
     * Entity referenceId.
     * When dropping from linked objects, this is the target referenceId.
     * When dropping a ForeignKey from the assets panel, this will temporarily be used to store the child table id */
    referenceId?: string;
    /** needed for creation when *type* is 'entity' */
    entityType?: EntityType;

    /** in case of a drop from linked objects, or a FK from assets */
    linkInfo?: ICreateEdgeInfo & {
        /** - For a dropped linked object: the 'previewed' object's entity identifier (aka the 'other' object, aka the object the linked object is linked to)
         * - For a dropped FK: the parent table referenceId */
        sourceIdr?: IEntityIdentifier;
        /** the onstage node from which the preview was opened */
        sourceNodeReferenceId?: string;
    };
}

//#region for edge burger menu
export interface IEdgeElementMenuData {
    x: number;
    y: number;
    edgeElement: DiagramEdgeDto;
    sourceItems: IEdgeElementMenuDataItem[];
    targetItems: IEdgeElementMenuDataItem[];
    canClearEditedGeometry(): boolean;
    clearEditedGeometry(): void;
}
export interface IEdgeElementMenuDataItem {
    name: string;
    id: string;
}
//#endregion

//#region plumb

//#region nodes & edges (definition)
export interface IDiagramNodeSpec {
    id?: string;
    data?: IDiagramNodeSpecData;
}
export interface IDiagramEdgeSpec {
    data: IDiagramEdgeData;
    geometry?: TConnectorGeometry;
    source: string;
    target: string;
}

export interface IDiagramNodeSpecData {
    type?: string;
    item: DiagramNodeDto;
    /** for entity nodes */
    sizeMode?: TSizeMode;
    visualData?: IDiagramNodeVisualData;
    /** for entity nodes */
    hasExtraData?: boolean;
    /** for frame nodes, while moving with contained nodes */
    frameData?: IFrameData;
    /** true when the node is in the selection */
    inSelection?: boolean;
    /** while the node is being moved by drag */
    moving?: boolean;
    /** while the node is being resized by drag */
    resizing?: boolean;
    dbg?: string;
    compRef?: ComponentRef<BaseDiagramNodeComponent>;
}

export interface IFrameData {
    contained?: IReparented[];
    edges?: IDiagramEdge[];
}
/** used to store dom elements of moved contained nodes while moving a frame node */
export interface IReparented {
    node: IDiagramNode;
    element: HTMLElement;
    content: HTMLElement;
    /** relative position to the frame before the move */
    shift?: IShift;
}
//#endregion - nodes & edges (definition)

//#region nodes & edges (live)
export type TDiagramNodeData = IDiagramNodeSpec &
    IDiagNodeData<IDiagramNodeSpecData>;
export interface IDiagramNode {
    id: string;
    type?: string;
    data: TDiagramNodeData;
}
export type IDiagramEdge = TDiagEdge<IDiagramNodeSpecData, IDiagramEdgeData>;
export interface IDiagramEdgeData {
    id?: string;
    type?: TEdgeType;
    item: DiagramEdgeDto;
    /** deserialized from item.VisualData */
    visualData?: IDiagramEdgeVisualData;
    /** cached value computed from item */
    linkType: DiagramLinkType;
    /** cached value computed from linkType */
    linkKind: DiagramLinkKind;
    /** used for propertyMappings; computed from linkType & linkKind */
    arrowKind?: string;
    dbg?: string;
}

export interface IDiagramStartConnectData {
    isUserAction?: boolean;
    linkType?: DiagramLinkType;
}

//#endregion - plumb

//#region diagram-api.service
export interface IDiagramModelData {
    model: Model;
    entity: EntityItem;
}
export type TContentOpType = 'A' | 'U' | 'D';
export type TDiagramDto = DiagramNodeDto | DiagramEdgeDto;
export interface IDiagramContentUpdateOne {
    /** 'A' => add, 'U' => update, 'D' => delete */
    type: TContentOpType;
    dto: TDiagramDto;
}
//#endregion

export interface IDiagramNavHeaderData {
    publicDiagrams: EntityItem[];
    privateDiagrams: EntityItem[];
    currentDiagram: EntityItem;
    modelsHData?: HierarchicalData[];
}

export interface IGraphicalContextMenuData {
    options: IGraphicalToolbarOption[];
    elements?: IBoundingBox | TDomElement[];
}

export type TSizeMode = 'nano' | 'mini' | 'medium' | 'maxi';

/** deserialized from DiagramNodeDTO.VisualData. Must be serializable */
export interface IDiagramNodeVisualData {
    /** for entities and notes */
    color?: GraphicalColor;
    /** only for notes */
    fontSize?: number;
    /** for entities and notes */
    locked?: boolean;
    /** for frames */
    caption?: ICaptionPosition;
}
/** deserialized from DiagramEdgeDTO.VisualData. Must be serializable */
export interface IDiagramEdgeVisualData {
    color?: GraphicalColor;
    thickness?: number;
    lineStyle?: LineStyle;
    arrowType?: ArrowType;
}

export interface IDiagramSourcePort {
    portType: string;
    position: 'head-left' | 'head-right' | 'custom';
    className?: string;
    /** true if the entity is linked in the DG model */
    hasLinks?: boolean;
}

/** Note: Values are stored as numbers in db/json, and names are used for translation */
export enum DiagramExtraData {
    none = 0,
    tags,
    owners,
    stewards,
    status,
    dataQuality,
}
/** Note: properties must match DiagramExtraData names */
export const diagramExtraDataAttributeKey = {
    tags: 'Domains',
    owners: 'DataOwners',
    stewards: 'DataStewards',
    status: 'EntityStatus',
    dataQuality: 'QualityStatus',
};
export function getDiagramExtraDataAttributeKey(ded: DiagramExtraData): string {
    return diagramExtraDataAttributeKey[DiagramExtraData[ded]];
}

export function getDiagramExtraDataKey(attributeKey: string): DiagramExtraData {
    const key = Object.keys(diagramExtraDataAttributeKey).find(
        (key) => diagramExtraDataAttributeKey[key] === attributeKey
    );
    return DiagramExtraData[key];
}

/** Note: Must be serialized (stored in DB as json) */
export interface IDiagramVisualData {
    extraData?: DiagramExtraData[];
    grid?: IDiagramGrid;
}

export interface IEntityUpdatedEvent {
    updatedEntity: EntityItem;
    includesExtraData: boolean;
    includesHData: boolean;
}

export interface ICreatedElements {
    newNodeItems: DiagramNodeDto[];
    newEdgeItems: DiagramEdgeDto[];
    /** loaded or created entities */
    entities?: EntityItem[];
}

export interface IEdgeElementMenuDataItems {
    sourceItems: IEdgeElementMenuDataItem[];
    targetItems: IEdgeElementMenuDataItem[];
}

export interface ICreateEdgeInfo {
    linkReferenceId: string;
    linkType: DiagramLinkType;
}

export interface IDiagramGrid {
    visible?: boolean;
    magnetic?: boolean;
}

export interface IPostInitUIOptions {
    compsContainerRef?: ViewContainerRef;
    surfaceContainer?: HTMLElement;
    miniMapContainer?: HTMLElement;
    backgroundGhost?: HTMLElement;
    dropSurface?: CdkDropList;
    zoomToFit?: boolean;
    selectElementId?: string;
}

export interface IDiagramEdgeStyleSelectorParams
    extends ICellParams<
        IDiagramEdgeStyleSelectorParams,
        IDiagramEdgeStyleSelectorValue
    > {
    thicknesses: number[];
    styles: LineStyle[];
    triggerGlyphClass?: string;
    onValueChange?: (newValue: IDiagramEdgeStyleSelectorValue) => unknown;
}

export interface IDiagramEdgeStyleSelectorValue {
    thickness?: number;
    style?: LineStyle;
}

export enum DiagramLinkKind {
    /** Arrow direction and displayed link type are same as the linkedObject */
    direct = 0,
    /** Arrow direction and displayed link type are inverted compared to the linkedObject */
    inverted,
    /** No arrow, link type is used for displayed text.
     * If arrow is forced by the user, acts like DiagramLinkKind.direct. */
    noArrowDirect,
    /** No arrow, reversed link type is used for displayed text.
     * If arrow is forced by the user, acts like DiagramLinkKind.inverted. */
    noArrowInverted,
}
export enum DiagramLinkType {
    unknown = 0,
    FK = -1,
    FKInversed = -2,

    //#region mapped to ObjectLinkType values
    IsSynonymOf = ObjectLinkType.IsSynonymOf,

    Generalizes = ObjectLinkType.Generalizes,
    Specializes = ObjectLinkType.Specializes,

    IsComputedBy = ObjectLinkType.IsComputedBy,
    IsUsedForComputationOf = ObjectLinkType.IsUsedForComputationOf,

    DependsOn = ObjectLinkType.DependsOn,
    IsNeededBy = ObjectLinkType.IsNeededBy,

    IsLinkedTo = ObjectLinkType.IsLinkedTo,

    ListsValuesFor = ObjectLinkType.ListsValuesFor,
    HasForReferenceData = ObjectLinkType.HasForReferenceData,

    IsDeclinedAccordingToDimension = ObjectLinkType.IsDeclinedAccordingToDimension,
    Declines = ObjectLinkType.Declines,

    Precedes = ObjectLinkType.Precedes,
    Follows = ObjectLinkType.Follows,

    HasForUniverse = ObjectLinkType.HasForUniverse,
    IsUniverseOf = ObjectLinkType.IsUniverseOf,

    HasForDomain = ObjectLinkType.HasForDomain,
    IsDomainOf = ObjectLinkType.IsDomainOf,

    HasForSource = ObjectLinkType.HasForSource,
    IsSourceOf = ObjectLinkType.IsSourceOf,

    IsPartOfDimension = ObjectLinkType.IsPartOfDimension,
    Regroups = ObjectLinkType.Regroups,

    HasForRecordingSystem = ObjectLinkType.HasForRecordingSystem,
    IsRecordingSystemFor = ObjectLinkType.IsRecordingSystemFor,

    IsUsageSourceFor = ObjectLinkType.IsUsageSourceFor,
    HasForUsageSource = ObjectLinkType.HasForUsageSource,

    IsUsageDestinationFor = ObjectLinkType.IsUsageDestinationFor,
    HasForUsageDestination = ObjectLinkType.HasForUsageDestination,

    Calls = ObjectLinkType.Calls,
    IsCalledBy = ObjectLinkType.IsCalledBy,

    Transcodes = ObjectLinkType.Transcodes,

    IsImplementedBy = ObjectLinkType.IsImplementedBy,
    Implements = ObjectLinkType.Implements,

    Uses = ObjectLinkType.Uses,
    IsUsedBy = ObjectLinkType.IsUsedBy,

    HasInput = ObjectLinkType.HasInput,
    IsInputOf = ObjectLinkType.IsInputOf,

    HasOutput = ObjectLinkType.HasOutput,
    IsOutputOf = ObjectLinkType.IsOutputOf,
    //#endregion
}

//#region drag&drop
export enum DiagramDropSource {
    unknown = 0,
    spotLight,
    previewPanel,
    assetsPanel,
    linkExplorer,
}
export interface IDiagramDragDropConfig<TDragItemData = unknown>
    extends IDragDropConfig<
        TDragItemData,
        TDiagramDropItem,
        DiagramDropSource
    > {}
//#endregion - drag&drop

export interface IExistingLinks extends ILinkedEntityInfo {
    /** asTarget.length + asSource.length */
    count: number;
}
export interface IExternalEntityInfo {
    entityId: string;
    entityDisplayName: string;
    /** onstage nodes that are aliases of this entity */
    onStageNodes: IDiagramNode[];
    /** onstage edges for which this entity is the source or target */
    onStageEdges: IDiagramEdge[];
    /** existing links in the workspace where this entity is the source or target */
    existingLinks: IExistingLinks;
}

export interface IOnStageEdgeInfo {
    edge: IDiagramEdge;
    mainNode: IDiagramNode;
    otherNode: IDiagramNode;
}

export type TNodeOrIdr = DiagramNodeDto | IEntityIdentifier;
export interface IEdgeFromLink extends ICreateEdgeInfo {
    source: TNodeOrIdr;
    target: TNodeOrIdr;
}
export interface ICreateElementsFromIdentifierOptions extends Partial<IXYRect> {
    moreEdges?: IEdgeFromLink[];
    shift?: Partial<IShift>;
    sizeMode?: TSizeMode;
}
export interface IResultGetMoreElements {
    nodesToHighlight?: IDiagramNode[];
    edgesToHighlight?: IDiagramEdge[];
    cancelDrop?: boolean;
    newEdges?: IEdgeFromLink[];
    clearLinkInfo?: boolean;
    newNodes?: IEntityIdentifier[];
    shift?: Partial<IShift>;
}

export interface INodeCreationData extends IXY, ISizeParams {
    type: TNonEntityNodeType;
    text?: string;
    visualData?: string;
}

export interface IAddNodeOpt {
    applyShift?: boolean;
    makeSelected?: boolean;
    showContextMenu?: boolean;
}

export interface IEntityAndInfo {
    entity: EntityItem;
    info?: IEntityInfo;
}
export interface IEntityInfo {
    /** *data* is *true* when the entity is linked to other entities in the DG model */
    hasLinks?: CachedData<boolean>;
}
