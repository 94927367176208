export interface ToastActionBtn {
    text: string;
    callback?: () => void;
    glyphClass?: string;
    isPrimary?: boolean;
}

export enum ToastType {
    Success,
    Info,
    Warning,
    Error,
}
