import { NgModule } from '@angular/core';
import { CoreUiModule } from '../core-ui.module';
import { DxyCheckboxFieldControlComponent } from './controls/checkbox-field-control/checkbox-field-control.component';
import { DxyDataTypeSizeFieldControlComponent } from './controls/data-type-size-field-control/data-type-size-field-control.component';
import { DxyFileUploadFieldControlComponent } from './controls/file-upload-field-control/file-upload-field-control.component';
import { DxyHyperlinkFieldControlComponent } from './controls/hyperlink-field-control/hyperlink-field-control.component';
import { DxyRadioFieldControlComponent } from './controls/radio-field-control/radio-field-control.component';
import { DxyFieldBooleanComponent } from './field-boolean/field-boolean.component';
import { DxyFieldDataTypeSizeComponent } from './field-data-type-size/field-data-type-size.component';
import { DxyFieldDateComponent } from './field-date/field-date.component';
import { DxyFieldEmailComponent } from './field-email/field-email.component';
import { DxyFieldFileUploadComponent } from './field-file-upload/field-file-upload.component';
import { DxyFieldHyperlinkComponent } from './field-hyperlink/field-hyperlink.component';
import { DxyFieldNumberComponent } from './field-number/field-number.component';
import { DxyFieldPasswordComponent } from './field-password/field-password.component';
import { DxyFieldRadioItemComponent } from './field-radio-item/field-radio-item.component';
import { DxyFieldRadioComponent } from './field-radio/field-radio.component';
import { DxyMultiSelectFieldControlComponent } from './controls/multi-select-field-control/multi-select-field-control.component';
import { DxyFieldMultiSelectComponent } from './field-multi-select/field-multi-select.component';
import { DxyFieldSelectComponent } from './field-select/field-select.component';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@NgModule({
    imports: [
        CoreUiModule,
        DxyFieldTextComponent,
        //#region field control
        DxyMultiSelectFieldControlComponent,
        DxyCheckboxFieldControlComponent,
        DxyDataTypeSizeFieldControlComponent,
        DxyFileUploadFieldControlComponent,
        DxyHyperlinkFieldControlComponent,
        DxyRadioFieldControlComponent,
        //#endregion field control
        DxyFieldBooleanComponent,
        DxyFieldDataTypeSizeComponent,
        DxyFieldDateComponent,
        DxyFieldEmailComponent,
        DxyFieldFileUploadComponent,
        DxyFieldHyperlinkComponent,
        DxyFieldNumberComponent,
        DxyFieldPasswordComponent,
        DxyFieldRadioComponent,
        DxyFieldRadioItemComponent,
        DxyFieldMultiSelectComponent,
        DxyFieldSelectComponent,
    ],
    exports: [
        DxyFieldTextComponent,
        DxyFieldBooleanComponent,
        DxyFieldDataTypeSizeComponent,
        DxyFieldDateComponent,
        DxyFieldEmailComponent,
        DxyFieldFileUploadComponent,
        DxyFieldHyperlinkComponent,
        DxyFieldNumberComponent,
        DxyFieldPasswordComponent,
        DxyFieldRadioComponent,
        DxyFieldRadioItemComponent,
        DxyFieldMultiSelectComponent,
        DxyFieldSelectComponent,
    ],
})
export class DxyFieldsModule {}
