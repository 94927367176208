import { UIRouterModule, UIView } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { appRoutes } from './app.routes';
import { appRouterConfig } from './app-router.config';
import { AppSharedModule } from './app.shared.module';
import { API_BASE_URL } from './api-config';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { DxyAttributesModule } from './shared/attribute/DxyAttributesModule';

/**
 * @deprecated if you touch this module, don't forget to verify impact on AppModule
 */
@NgModule({
    imports: [
        AppSharedModule,
        DxyAttributesModule,
        UIRouterModule.forRoot({
            states: appRoutes,
            useHash: true,
            otherwise: '/',
            deferIntercept: false,
            config: (uiRouter, injector) => appRouterConfig(uiRouter, injector),
        }),
    ],
    providers: [
        {
            provide: API_BASE_URL,
            useFactory: (appConfigService: AppConfigService) =>
                appConfigService.apiHost,
            deps: [AppConfigService],
        },
    ],
    bootstrap: [UIView],
})
export class LegacyAppModule {
    constructor() {}
}
