import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetObjectUsersAccessResult } from './security';

@Injectable({ providedIn: 'root' })
export class ObjectSecurityApiService {
    constructor(private restApiService: RestApiService) {}

    public async getObjectUsersAccess(referenceId: string) {
        return await this.restApiService.getPromise<GetObjectUsersAccessResult>(
            `/security/object/${referenceId}`
        );
    }
}
