import { deserialize, inheritSerialization } from 'cerialize';
import { ABaseSecurityData } from './base-security-data';

@inheritSerialization(ABaseSecurityData)
export class EntitySecurityData extends ABaseSecurityData {
    @deserialize public DataReferenceId!: string;
    @deserialize public HasOfficialRoleAttributesWriteAccess!: boolean;
    @deserialize public HasEntityStatusWriteAccess!: boolean;
    @deserialize public HasSuggestionModeWriteAccess!: boolean;
}

export interface IHasSecurityData {
    SecurityData: EntitySecurityData;
}
