import { BaseMasterData } from './master-data';
import { INewable } from '@datagalaxy/core-util';
import { GenericDeserialize } from 'cerialize';
import { CDataTypeSettings } from '../data-type-settings';
import { CModel } from '../model';
import { Subject } from 'rxjs';
import { CRefData } from '../ref';

/** Exchange object for post-processing of a deserialization operation. Managed by masterDataService.processDeserialized method */
export interface IDeserializedData<T extends BaseMasterData = BaseMasterData> {
    /** Instantiated by @deserializeAs() decorator */
    instance: CRefData<BaseMasterData>;
    /** (set by masterDataService.processDeserialized) Resulting registered MasterData object */
    result?: T;
    dbg?: {
        /** Origin of the operation */
        logId?: string;
        /** Raw deserialized data, from @deserializeAs() */
        json?: any;
        /** activate verbose tracing when debug is activated for MasterDataService */
        verbose?: boolean;
    };
}

export class MasterDataSerializer {
    public static readonly onDeserialized = new Subject<IDeserializedData>();

    /** DataTypeSettings legacy format deserialisation adapter factory.
     * To be used with @deserializeAs() decorator on DataTypeSettings.
     * Emits an event listened by MasterDataService instance. */
    public static forLegacyDataTypeSettingsFormat(
        logId?: string,
        verbose = false
    ) {
        return MasterDataSerializer.getAdapterFactory(
            CDataTypeSettings,
            logId,
            verbose
        );
    }

    /** Model legacy format deserialisation adapter factory.
     * To be used with @deserializeAs() decorators on LoadDataResultItem.DataContent.
     * Emits an event listened by MasterDataService instance. */
    public static forLoadDataResultItemAsModel(
        logId = 'loadData',
        verbose = false
    ) {
        return MasterDataSerializer.getAdapterFactory(CModel, logId, verbose);
    }

    private static getAdapterFactory<T extends BaseMasterData>(
        adapterType: INewable<CRefData<T>>,
        logId?: string,
        verbose = false
    ) {
        return (json: any) => {
            const instance = GenericDeserialize(json, adapterType);
            const data: IDeserializedData<T> = {
                instance,
                dbg: { logId, json, verbose },
            };
            MasterDataSerializer.onDeserialized.next(data);
            return data.result;
        };
    }
}
