import { Component, Input } from '@angular/core';
import { coerceArray } from '@angular/cdk/coercion';
import { IEllipsisTooltipCellParams } from './ellipsis-tooltip-cell.types';
import { BaseCellComponent } from '../BaseCellComponent';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgFor } from '@angular/common';

/**
 * ## Role
 * Grid cell with a tooltip on hover if text is too long and ellipsis
 */
@Component({
    selector: 'dxy-ellipsis-tooltip-cell',
    templateUrl: 'ellipsis-tooltip-cell.component.html',
    styleUrls: ['ellipsis-tooltip-cell.component.scss'],
    standalone: true,
    imports: [NgFor, EllipsisTooltipDirective],
})
export class DxyEllipsisTooltipCellComponent<TEntity> extends BaseCellComponent<
    TEntity,
    string | string[],
    IEllipsisTooltipCellParams<TEntity>
> {
    @Input() text!: string | string[];
    @Input() highlightTitle?: boolean;

    public get texts() {
        return coerceArray(this.text);
    }

    protected override updateBindings() {
        this.text = this.value;
        this.highlightTitle = this.params?.highlightTitle;
    }
}
