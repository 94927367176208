import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { AttributeUsage } from '../attribute';
import { ItemUsage } from '@datagalaxy/dg-object-model';
import {
    AttributeDTO,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';

@inheritSerialization(BaseServiceParameter)
export class CreateAttributeListValueParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;
    @serialize public DisplayName: string;

    constructor(moduleName: string, attributeKey: string, displayName: string) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
        this.DisplayName = displayName;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateAttributeListValueParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;
    @serialize public Value: string;
    @serialize public DisplayName!: string;
    @serialize public IsActive?: boolean;

    constructor(moduleName: string, attributeKey: string, value: string) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
        this.Value = value;
    }
}

@inheritSerialization(BaseServiceParameter)
export class PreUpdateAttributeListValueParameter extends BaseServiceParameter {
    @serialize public ModuleName!: string;
    @serialize public AttributeKey!: string;
    @serialize public Value!: string;
    @serialize public IsActive!: boolean;
}

@inheritSerialization(BaseServiceResult)
export class GenericAttributeListValueResult extends BaseServiceResult {
    @deserializeAs(AttributeDTO) public Attribute!: AttributeDTO;
    @deserializeAs(AttributeMetaValue) public ListValue!: AttributeMetaValue;
}

@inheritSerialization(ItemUsage)
export class AttributeValueUsage extends ItemUsage {}

@inheritSerialization(BaseServiceResult)
export class PreUpdateAttributeListValueResult extends BaseServiceResult {
    @deserialize public CanBeDeleted!: boolean;
    @deserializeAs(AttributeUsage) Usages!: Array<AttributeValueUsage>;
}
