<dxy-action-list [actions]="actions" [data]="synonym">
    <app-entity-card-cell
        *ngIf="synonym.HddData && !isLocalSynonymType"
        [hierarchicalData]="synonym.HddData"
        [noLabelNavLink]="readOnly"
    ></app-entity-card-cell>
    <div *ngIf="isLocalSynonymType" class="text-container">
        <span class="object-type" translate>UI.Synonyms.LocalSynonym</span>
        <span class="name" dxyEllipsisTooltip>{{ localSynonym }}</span>
    </div>
</dxy-action-list>
