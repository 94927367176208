export interface ITrendPillData {
    trend?: number;
    colorRule: TrendPillColorRule;
}

export enum TrendPillColorRule {
    none = 0,
    shouldIncrease,
    shouldDecrease,
}
