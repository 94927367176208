import { FieldType } from '../../types/enums/field-type.enum';

export const amazonAthenaConfig = [
    { payloadField: 'type', payloadValue: 'MULTI' },
    { payloadField: 'multi-technology', payloadValue: 'amazonathena' },
    {
        payloadField: 'region',
        formField: 'region',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'access-key',
        formField: 'accessKey',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        tradKey: 'secretKey',
        isPassword: true,
        formMandatory: true,
    },
];
