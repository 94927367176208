<ng-container *ngIf="!loading && !isEmpty">
    <div class="lifetime-msg-container" translate>
        UI.Teams.History.lifetimeMsg
    </div>
    <div>
        <app-team-history-item
            *ngFor="let teamHistoryData of teamHistoryDataList"
            [teamHistoryData]="teamHistoryData"
        ></app-team-history-item>
    </div>
</ng-container>

<div *ngIf="!loading && isEmpty" class="placeholder-container">
    <img
        src="/images/placeholders/team-history.svg"
        class="dg_no-item-image"
        alt="no history log"
    />
    <span class="no-item-title" translate>UI.Teams.History.title</span>
    <span class="no-item-text" translate>UI.Teams.History.emptyMsg</span>
</div>

<dxy-spinner *ngIf="loading" class="spinner-container"></dxy-spinner>
