import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SecurityService } from '../../services/security.service';

export function passwordValidator(
    securityService: SecurityService
): ValidatorFn {
    return (passwordControl: AbstractControl) => {
        const error = securityService.checkPasswordStrengthTranslated(
            passwordControl.value
        );
        return error ? { passwordWeak: error } : null;
    };
}
