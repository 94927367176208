<dxy-spinner *ngIf="loading$ | async; else content"></dxy-spinner>

<ng-template #content>
    <header>
        <dxy-search-input
            class="no-border-top"
            (onSearchString)="onSearchStringChange($event.searchString)"
        ></dxy-search-input>
        <button
            dxyIconButton
            (click)="openAddLanguageModal()"
            [matTooltip]="
                'Multilingual.Administration.LanguagesTab.addLanguageTooltip'
                    | translate
            "
        >
            <i class="glyph-add"></i>
        </button>
    </header>
    <div
        *ngFor="
            let code of languageCodes$ | async;
            trackBy: trackByLanguageCode
        "
        class="language"
    >
        <dxy-language-card [languageCode]="code"> </dxy-language-card>
        <div class="progess">
            <div
                *ngIf="
                    (sourceLanguageCode$ | async) === code;
                    else translationProgress
                "
                class="dg5-badge"
                translate
            >
                Multilingual.Administration.sourceLanguage
            </div>
            <ng-template #translationProgress>
                <dxy-translation-progress
                    [languageCode]="code"
                ></dxy-translation-progress>
            </ng-template>
        </div>
    </div>
</ng-template>
