<button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    #menuTrigger
    (keydown.escape)="onEscapeKey()"
>
    <i class="glyph-align-left"></i>
</button>

<mat-menu
    #menu="matMenu"
    [class]="'caption-position-selector--mat-menu ' + this.localClass"
>
    <div class="rows">
        <div class="row">
            <button
                *ngFor="let v of params.types"
                mat-icon-button
                class="select-item"
                [class.selected]="currentValue.type == v"
                (click)="onClick($event, { type: v })"
            >
                <i [ngClass]="'glyph-caption-' + v"></i>
            </button>
        </div>
        <div class="row sep"></div>
        <div class="row">
            <button
                *ngFor="let v of params.alignHs"
                mat-icon-button
                class="select-item"
                [class.selected]="currentValue.alignH == v"
                (click)="onClick($event, { alignH: v })"
            >
                <i [ngClass]="'glyph-align-' + v"></i>
            </button>
        </div>
        <div class="row sep"></div>
        <div class="row">
            <button
                *ngFor="let v of params.alignVs"
                mat-icon-button
                class="select-item"
                [class.selected]="currentValue.alignV == v"
                [disabled]="currentValue.type == 'outside' && v == 'middle'"
                (click)="onClick($event, { alignV: v })"
            >
                <i [ngClass]="'glyph-align-' + v"></i>
            </button>
        </div>
    </div>
</mat-menu>
