import { FieldType } from '../../types/enums/field-type.enum';
import { DatabricksAuthMode } from '../../types/enums/databricks-auth-mode.enum';

export const databricksConfig = [
    {
        payloadField: 'server',
        formField: 'server',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'port',
        formField: 'port',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'httppath',
        formField: 'httpPath',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'mode-auth',
        formField: 'databricksAuthMode',
        formMandatory: false,
        type: FieldType.select,
        payloadValue: DatabricksAuthMode.PERSONAL_ACCESS_TOKEN,
        selectValues: Object.values(DatabricksAuthMode),
        translate: true,
        getDisplayValueTradKey: (value: DatabricksAuthMode) => value,
        dependencies: [
            {
                field: {
                    payloadField: 'password',
                    formField: 'password',
                    isPassword: true,
                    formMandatory: true,
                },
                show: (value: DatabricksAuthMode) =>
                    value === DatabricksAuthMode.PERSONAL_ACCESS_TOKEN,
            },
            {
                field: {
                    payloadField: 'tenant-id',
                    formField: 'tenantId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: DatabricksAuthMode) =>
                    value === DatabricksAuthMode.AZURE_AD_SERVICE_PRINCIPAL,
            },
            {
                field: {
                    payloadField: 'client-id',
                    formField: 'clientId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: DatabricksAuthMode) =>
                    value === DatabricksAuthMode.AZURE_AD_SERVICE_PRINCIPAL ||
                    value === DatabricksAuthMode.DATABRICKS_SERVICE_PRINCIPAL,
            },
            {
                field: {
                    payloadField: 'client-secret',
                    formField: 'clientSecret',
                    isPassword: true,
                    formMandatory: true,
                },
                show: (value: DatabricksAuthMode) =>
                    value === DatabricksAuthMode.AZURE_AD_SERVICE_PRINCIPAL ||
                    value === DatabricksAuthMode.DATABRICKS_SERVICE_PRINCIPAL,
            },
        ],
    },
    {
        payloadField: 'catalog',
        formField: 'catalog',
        payloadValue: [],
        type: FieldType.list,
        isFilter: true,
        listFieldTradKeys: {
            gridTitle: 'UI.Connector.Wizard.Step3.CatalogNames.Grid.title',
            gridAddBtn: 'UI.Connector.Wizard.Step3.CatalogNames.Grid.addBtn',
            modalTitle: 'UI.Connector.Wizard.Step3.CatalogNames.Modal.title',
            modalLabel: 'UI.Connector.Wizard.Step3.CatalogNames.Modal.value',
        },
    },
    {
        payloadField: 'files-path',
        formField: 'filesPath',
        formMandatory: false,
        type: FieldType.text,
    },
];
