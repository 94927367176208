<dxy-spinner *ngIf="loading$ | async; else content"></dxy-spinner>

<ng-template #content>
    <header>
        <dxy-search-input
            class="no-border-top"
            (onSearchString)="onSearchStringChange($event.searchString)"
        ></dxy-search-input>
        <button
            dxyIconButton
            [disabled]="hasPendingBatches$ | async"
            (click)="openAddAttributesModal()"
            [matTooltip]="
                ((hasPendingBatches$ | async)
                    ? 'Multilingual.Administration.AttributesTab.addAttributesDeactivatedTooltip'
                    : 'Multilingual.Administration.AttributesTab.addAttributesTooltip'
                ) | translate
            "
        >
            <i class="glyph-add"></i>
        </button>
    </header>
    <div
        *ngFor="let multilingualAttribute of multilingualAttributes$ | async"
        class="attribute"
    >
        <dxy-attribute-cell [attribute]="multilingualAttribute.attribute">
        </dxy-attribute-cell>

        <button
            dxyIconButton
            color="secondary"
            [matTooltip]="
                'Multilingual.Administration.AttributesTab.removeAttributeTooltip'
                    | translate
            "
            matTooltipPosition="left"
            (click)="removeAttribute(multilingualAttribute)"
        >
            <i class="glyph-delete"></i>
        </button>
    </div>
</ng-template>
