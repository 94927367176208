import { FieldType } from '../../types/enums/field-type.enum';

export const denodoConfig = [
    {
        payloadField: 'server',
        formField: 'server',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'port',
        formField: 'port',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'database',
        formField: 'database',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'user',
        formField: 'user',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        formMandatory: true,
        isPassword: true,
    },
    { payloadField: 'multi-technology', payloadValue: 'denodo' },
    { payloadField: 'type', payloadValue: 'MULTI' },
];
