<div class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.DataQuality
    </div>
    <div class="actions-container"></div>
</div>
<div class="mainContainer panel-content-body">
    <app-data-quality-rules-grid
        [entityData]="entityData"
        [isFullColumnDisplay]="false"
    ></app-data-quality-rules-grid>
</div>
