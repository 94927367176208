import { EventEmitter, Injectable } from '@angular/core';
import {
    Client,
    ClientProviderEvents,
    EvaluationContext,
    OpenFeature,
    Provider,
} from '@openfeature/web-sdk';
import { OpenFeatureInitOptions } from './open-feature-init-options';

@Injectable({ providedIn: 'root' })
export class OpenFeatureService {
    private readyEmitter = new EventEmitter();
    public ready$ = this.readyEmitter.asObservable();

    private client: Client = OpenFeature.getClient();

    constructor() {}

    public async setContext(context: EvaluationContext) {
        await OpenFeature.setContext(context);
    }

    public async setProviderAndWait(provider: Provider) {
        await OpenFeature.setProviderAndWait(provider);
    }

    public async init(options: OpenFeatureInitOptions) {
        await this.setContext({
            targetingKey: options.targetingKey,
        });
        await this.setProviderAndWait(options.provider);
        this.client.addHandler(ClientProviderEvents.Ready, () => {
            this.readyEmitter.emit();
        });
    }

    public getBooleanFeatureFlag(flagKey: string) {
        return this.client.getBooleanDetails(flagKey, false).value;
    }
}
