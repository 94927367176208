<ng-container *ngIf="userChannel$ | async as userChannel">
    <button
        *ngIf="!userChannel.ChannelSetting.Default"
        class="small"
        type="button"
        mat-flat-button
        color="primary"
        (click)="activate()"
        [disabled]="loading$ | async"
        data-tracker-id="Integration_Teams_Notifications_ConnectMeWithTeams"
    >
        <span translate>UI.NotificationChannels.MsTeams.activate</span>
        <span *ngIf="loading$ | async" class="dg5-spinner"></span>
    </button>
    <span
        *ngIf="userChannel.ChannelSetting.Default"
        class="dg5-badge nav-link-badge"
        translate
        >UI.NotificationChannels.MsTeams.alreadyConnected</span
    >
</ng-container>
