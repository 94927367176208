export class NotificationChannel {
    name: string;
    labelKey: string;
    imageUrl: string;
    activated?: boolean;
    trackerName: string;

    public constructor(init?: Partial<NotificationChannel>) {
        Object.assign(this, init);
    }
}
