import { GridScrollBehavior } from './grid-scroll.types';
import { ServerFlatDataSourceConfig } from '../grid-config';

export class ServerFlatScroll<TRow> implements GridScrollBehavior<TRow> {
    public get disableAutoLoad() {
        return this.dataSource.disableAutoLoad || false;
    }

    constructor(private dataSource: ServerFlatDataSourceConfig<TRow>) {}

    public hasReachedInfiniteLoadingThreshold(index: number, rowCount: number) {
        const percentageThreshold =
            this.dataSource.infiniteScrollThreshold || 0.5;

        return index + 1 >= rowCount * percentageThreshold;
    }

    public async loadNextChunk(rowCount: number) {
        return await this.dataSource.getRows({ startRow: rowCount });
    }
}
