import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScriptLoaderService {
    private loadedScripts: Set<string> = new Set();

    loadScript(scriptUrl: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.loadedScripts.has(scriptUrl)) {
                resolve();
                return;
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = scriptUrl;
            script.onload = () => {
                this.loadedScripts.add(scriptUrl);
                resolve();
            };
            script.onerror = (error) => {
                reject(error);
            };

            document.head.appendChild(script);
        });
    }

    unloadScript(scriptUrl: string): void {
        if (this.loadedScripts.has(scriptUrl)) {
            this.loadedScripts.delete(scriptUrl);

            const scriptElement = document.querySelector(
                `script[src="${scriptUrl}"]`
            );
            if (scriptElement) {
                scriptElement.remove();
            }
        }
    }
}
