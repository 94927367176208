<div class="links-container">
    <app-dg-entity-link-shortcut-cell
        *ngFor="let hdd of displayedHdds"
        [hdd]="hdd"
    ></app-dg-entity-link-shortcut-cell>
    <div
        *ngIf="moreCount"
        [matTooltip]="tooltipText"
        class="more-count"
        [style.width]="moreCountSize + 'px'"
    >
        +{{ moreCount }}
    </div>
</div>
