export enum DataQualityResult {
    Unknown = 'unknown',
    Passed = 'passed',
    Warning = 'warning',
    Failed = 'failed',
}

export enum DataQualityRuleType {
    Completeness = 'completeness',
    Accuracy = 'accuracy',
    Consistency = 'consistency',
    Validity = 'validity',
    Uniqueness = 'uniqueness',
    Integrity = 'integrity',
}

export type DataQualityCheck = {
    id: string;
    creationTime: string;
    status: DataQualityResult;
    ruleId: string;
    userId: string;
    message: string;
    detail: string;
};

export type DataQualityRule = {
    id: string;
    entityId: string;
    creationTime: string;
    userId: string;
    statement: string;
    lastChecks: DataQualityCheck[];
    code: string;
    type: DataQualityRuleType;
    fieldIds: string[];
};

export type CreateRuleParameter = {
    entityId: string;
    workspaceId: string;
    type: string;
    code: string;
    statement: string;
    fieldIds: string[];
};

export type UpdateRuleParameter = {
    type: string;
    code: string;
    statement: string;
    fieldIds: string[];
};
