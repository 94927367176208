import { DgModuleDefinition } from '../dg-module-definition';
import { DgModuleName } from '../dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export class UsageDgModule extends DgModuleDefinition {
    public name: DgModuleName = 'Usage';
    public translateKey = 'DgServerTypes.DataGalaxyModule.Usage';
    public glyphClass = 'glyph-software';
    public colorGlyphClass = 'functional-dynamic-color';
    public graphicalColor = GraphicalColor.Wood;
    public position = 4;
    public categoryKey = 'dataKnowledgeCatalog';
}

export default new UsageDgModule();
