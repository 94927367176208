import { DgModuleDefinition } from '../dg-module-definition';
import { DgModuleName } from '../dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export class DiagramDgModule extends DgModuleDefinition {
    public name: DgModuleName = 'Diagram';
    public translateKey = 'DgServerTypes.DataGalaxyModule.Diagram';
    public glyphClass = 'glyph-diagram1';
    public colorGlyphClass = '';
    public graphicalColor = GraphicalColor._none;
    public position = 5;
    public categoryKey = 'dataKnowledgeStudio';
}

export default new DiagramDgModule();
