import { IBaseFilterData } from '../base-filter/base-filter.types';
import { UiOptionSelectDataType } from '../../UiOptionSelect.types';

export interface IValueListFilterData<TOption>
    extends IBaseFilterData<ValueListFilterOperator> {
    values?: TOption[];
    isRestricted?: (value: TOption) => boolean;
}

export enum ValueListFilterOperator {
    Unknown = 0,
    Equals,
    ListContains,
    ListMatchAll,
    ListExcludes,
    FieldIsEmpty,
    FieldHasValue,
}

export interface IValueListFilterConfig<TItem = any> {
    hasSelectAll?: boolean;
    hasSearch?: boolean;
    dataType?: UiOptionSelectDataType;
    sortOptions?: (options: TItem[]) => TItem[];
    onSearch?: (searchTerm: string) => Promise<TItem[]>;
}
