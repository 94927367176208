<div class="search-bar" (click)="onSearchBarClick($event)">
    <div class="glyph-search"></div>

    <input
        type="text"
        class="search-input"
        maxlength="256"
        matInput
        #matInputRef="matInput"
        [ngModel]="searchString"
        (ngModelChange)="onSearch($event)"
        [placeholder]="placeholderKey ?? '' | translate"
        autocomplete="off"
    />

    <button
        *ngIf="isClearSearchVisible"
        mat-icon-button
        class="glyph-cancelsearch"
        (click)="clear($event)"
    ></button>
</div>
