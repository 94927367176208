import { Injectable } from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import {
    AttributeValueTranslation,
    ObjectTranslation,
} from '@datagalaxy/webclient/multilingual/domain';
import { BaseStateService } from '@datagalaxy/utils';

@Injectable()
export class EntityTranslationStore extends BaseStateService<{
    translations: ObjectTranslation[];
}> {
    constructor() {
        super({
            translations: [],
        });
    }
    selectObjectAttributeTranslation(
        entityIdr: IEntityIdentifier,
        languageCode: string,
        attributePath: string
    ) {
        return this.select((state) =>
            state.translations
                .find(
                    (e) =>
                        EntityIdentifier.areSame(
                            e.entityIdentifier,
                            entityIdr
                        ) && e.languageCode === languageCode
                )
                ?.translatedAttributeValues.find(
                    (at) => at.attributePath === attributePath
                )
        );
    }
    selectObjectTranslated(
        entityIdentifier: IEntityIdentifier,
        languageCode: string
    ) {
        return this.select((state) => {
            return state.translations.some(
                (t) =>
                    EntityIdentifier.areSame(
                        t.entityIdentifier,
                        entityIdentifier
                    ) &&
                    t.languageCode === languageCode &&
                    t.translatedAttributeValues.length > 0
            );
        });
    }

    upsertObjectTranslation(translation: ObjectTranslation): void {
        const translations = this.state.translations.filter(
            (t) =>
                !EntityIdentifier.areSame(
                    t.entityIdentifier,
                    translation.entityIdentifier
                )
        );
        this.setState({
            translations: [...translations, translation],
        });
    }

    upsertObjectTranslations(objectTranslations: ObjectTranslation[]) {
        const translations = this.state.translations.filter(
            (t) =>
                !objectTranslations.some((translation) =>
                    EntityIdentifier.areSame(
                        t.entityIdentifier,
                        translation.entityIdentifier
                    )
                )
        );
        this.setState({
            translations: [...translations, ...objectTranslations],
        });
    }

    upsertAttributeTranslation(
        entityIdr: IEntityIdentifier,
        languageCode: string,
        translation: AttributeValueTranslation
    ) {
        this.setState({
            translations: [
                ...this.state.translations.map((t) => {
                    if (
                        EntityIdentifier.areSame(
                            t.entityIdentifier,
                            entityIdr
                        ) &&
                        t.languageCode === languageCode
                    ) {
                        return {
                            ...t,
                            translatedAttributeValues: [
                                ...t.translatedAttributeValues.filter(
                                    (at) =>
                                        at.attributePath !==
                                        translation.attributePath
                                ),
                                translation,
                            ],
                        };
                    }
                    return t;
                }),
            ],
        });
    }

    haveObjectTranslation(
        entityIdentifier: IEntityIdentifier,
        languageCode: string
    ): boolean {
        return this.state.translations.some(
            (t) =>
                EntityIdentifier.areSame(
                    t.entityIdentifier,
                    entityIdentifier
                ) && t.languageCode === languageCode
        );
    }
}
