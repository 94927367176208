import { IDialogButton, IModalDialogData } from './core-dialog.types';
import { DialogResult, DialogType } from './enums';

export class CoreDialogUtil {
    //#region static

    public static readonly resolveFields: (keyof IModalDialogData)[] = [
        'noHeader',
        'titleKey',
        'messageKey',
        'type',
        'title',
        'message',
        'modalClass',
        'featureCode',
        'confirmButtonKey',
        'cancelButtonKey',
        'hasUserInput',
        'userInputMessage',
        'userInputValidationMethod',
        'userInputClass',
        'userInputValue',
        'userInputLabel',
        'userInputLabelKey',
        'userInputInfoKey',
        'confirmButtonColor',
        'confirmButtonGlyph',
    ];

    //#region for modal-dialog
    public static getButtons(params: IModalDialogData) {
        return CoreDialogUtil.getAllButtons(params).filter((btn) =>
            CoreDialogUtil.isButtonVisible(
                btn.type,
                params.type ?? DialogType.Ok
            )
        );
    }
    private static getAllButtons(params: IModalDialogData): IDialogButton[] {
        const { cancelButtonKey, confirmButtonKey, featureCode } = params;
        return [
            {
                type: DialogResult.Cancel,
                textKey: cancelButtonKey || 'UI.Dialog.btnCancel',
            },
            {
                type: DialogResult.Close,
                textKey: 'UI.Dialog.btnClose',
            },
            {
                type: DialogResult.No,
                textKey: cancelButtonKey || 'UI.Dialog.btnNo',
            },
            {
                type: DialogResult.Ok,
                textKey: confirmButtonKey || 'UI.Dialog.btnOk',
                featureCode: featureCode,
                glyphClass: params.confirmButtonGlyph,
                color: params.confirmButtonColor ?? 'primary',
            },
            {
                type: DialogResult.Action,
                textKey: confirmButtonKey, // no default value on purpose
                featureCode: featureCode,
                glyphClass: params.confirmButtonGlyph,
                color: params.confirmButtonColor ?? 'primary',
            },
            {
                type: DialogResult.Yes,
                textKey: confirmButtonKey || 'UI.Dialog.btnYes',
                featureCode: featureCode,
                glyphClass: params.confirmButtonGlyph,
                color: params.confirmButtonColor ?? 'primary',
            },
        ];
    }

    public static isButtonVisible(
        buttonType: DialogResult,
        dialogType: DialogType
    ) {
        switch (buttonType) {
            case DialogResult.Ok:
                return (
                    dialogType === DialogType.Ok ||
                    dialogType === DialogType.OkCancel
                );
            case DialogResult.Cancel:
                return (
                    dialogType === DialogType.OkCancel ||
                    dialogType === DialogType.Action
                );
            case DialogResult.Yes:
                return dialogType === DialogType.YesNo;
            case DialogResult.No:
                return dialogType === DialogType.YesNo;
            case DialogResult.Close:
                return dialogType === DialogType.Close;
            case DialogResult.Action:
                return dialogType === DialogType.Action;
            default:
                return false;
        }
    }

    public static isCancelOrCloseOrNo(dr: DialogResult) {
        return (
            dr == DialogResult.Cancel ||
            dr == DialogResult.Close ||
            dr == DialogResult.No
        );
    }
    //#endregion

    public static isConfirmed(result: DialogResult, dialogType: DialogType) {
        switch (dialogType) {
            case DialogType.OkCancel:
                return result == DialogResult.Ok;
            case DialogType.Action:
                return result == DialogResult.Action;
            case DialogType.YesNo:
                return result == DialogResult.Yes;
            default:
                return false;
        }
    }

    //#endregion  - static
}
