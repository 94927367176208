import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { Model } from './model';
import { CRefIdr, Ref } from '../ref';

@inheritSerialization(Ref)
export class ModelRef extends Ref<Model> {
    static fromId(id: string) {
        return new ModelRef(id);
    }

    constructor(id?: string) {
        super(ServerType.Model, id);
    }
}

/** Ref&lt;Model&gt; legacy generic format adapter */
@inheritSerialization(CRefIdr)
export class CModelRef extends CRefIdr<Model> {
    readonly serverType = ServerType.Model;
}
