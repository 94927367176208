import { Injectable } from '@angular/core';
import { BackendApiService, BaseServiceResult } from '@datagalaxy/data-access';
import { GetInsightsResult } from '@datagalaxy/webclient/user/data-access';
import {
    CloneEntityParameter,
    CloneEntityResult,
    CreateEntityParameter,
    CreateEntityResult,
    DeleteEntityParameter,
    DeleteEntityResult,
    FusionEntitiesParameter,
    FusionEntitiesResult,
    GetDefaultValuesOnCreateEntityParameter,
    GetDefaultValuesOnCreateEntityResult,
    LoadEntityParameter,
    LoadEntityResult,
    LoadMultiEntityParameter,
    LoadMultiEntityResult,
    PreCreateEntityResult,
    SetEntitiesParentParameter,
    SetEntitiesParentResult,
    SetEntitiesTechnologyParameter,
    UpdateEntityAttributeParameter,
    UpdateEntityAttributeResult,
    WatchEntityParameter,
} from './entity';
import {
    AddLinkedEntitiesParameter,
    AddLinkedEntitiesResult,
    DeleteLinkedEntitiesParameter,
    DeleteLinkedEntitiesResult,
    GetAvailableLinkTypesParameter,
    GetAvailableLinkTypesResult,
    GetLinkedDataParameter,
    GetLinkedDataResult,
    UpdateEntityLinkParameter,
    UpdateEntityLinkResult,
} from './entity-link';
import { GetEntityInsightsParameter } from './entity-insight';
import {
    DeleteLocalSynonymParameter,
    DeleteLocalSynonymResult,
    GetSynonymsParameter,
    GetSynonymsResult,
    LocalSynonymParameter,
    LocalSynonymResult,
} from './entity-synonym';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class EntityApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async loadEntity(
        param: LoadEntityParameter
    ): Promise<LoadEntityResult> {
        return await this.backendApiService.postPromise(
            'Entity/LoadEntity',
            param,
            LoadEntityResult
        );
    }

    public async loadMultiEntity<TEntity extends EntityItem>(
        param: LoadMultiEntityParameter
    ): Promise<LoadMultiEntityResult<TEntity>> {
        return await this.backendApiService.postPromise(
            'Entity/LoadMultiEntity',
            param,
            LoadMultiEntityResult<TEntity>
        );
    }

    public async createEntity(param: CreateEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/CreateEntity',
            param,
            CreateEntityResult
        );
    }

    public async getDefaultValuesOnCreate(
        param: GetDefaultValuesOnCreateEntityParameter
    ) {
        return await this.backendApiService.postPromise(
            'Entity/GetDefaultValuesOnCreateEntity',
            param,
            GetDefaultValuesOnCreateEntityResult
        );
    }

    public async updateEntity(param: UpdateEntityAttributeParameter) {
        return await this.backendApiService.postPromise(
            'Entity/UpdateEntityAttribute',
            param,
            UpdateEntityAttributeResult
        );
    }

    public async deleteEntity(param: DeleteEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/DeleteEntity',
            param,
            DeleteEntityResult
        );
    }

    public async preDeleteEntity(param: DeleteEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/PreDeleteEntity',
            param,
            DeleteEntityResult
        );
    }

    public async cloneEntity(param: CloneEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/CloneEntity',
            param,
            CloneEntityResult
        );
    }

    public async fusionEntities(param: FusionEntitiesParameter) {
        return await this.backendApiService.postPromise(
            'Entity/FusionEntities',
            param,
            FusionEntitiesResult
        );
    }

    public async setEntitiesTechnology(param: SetEntitiesTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Entity/SetEntitiesTechnology',
            param,
            UpdateEntityAttributeResult
        );
    }

    public async addEntityLink(param: AddLinkedEntitiesParameter) {
        return await this.backendApiService.postPromise(
            'Entity/AddLinkedEntity',
            param,
            AddLinkedEntitiesResult
        );
    }

    public async deleteEntityLink(param: DeleteLinkedEntitiesParameter) {
        return await this.backendApiService.postPromise(
            'Entity/DeleteLinkedEntity',
            param,
            DeleteLinkedEntitiesResult
        );
    }

    public async getLinkedData(param: GetLinkedDataParameter) {
        return await this.backendApiService.postPromise(
            'Entity/GetLinkedData',
            param,
            GetLinkedDataResult
        );
    }

    public async updateEntityLink(param: UpdateEntityLinkParameter) {
        return await this.backendApiService.postPromise(
            'Entity/UpdateEntityLink',
            param,
            UpdateEntityLinkResult
        );
    }

    public async setEntitiesParent(param: SetEntitiesParentParameter) {
        return await this.backendApiService.postPromise(
            'Entity/SetEntitiesParent',
            param,
            SetEntitiesParentResult
        );
    }

    public async preCreateEntity(parameter: CreateEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/PreCreateEntity',
            parameter,
            PreCreateEntityResult
        );
    }

    public async getAvailableLinkTypes(
        parameter: GetAvailableLinkTypesParameter
    ) {
        return await this.backendApiService.postPromise(
            'Entity/GetAvailableLinkTypes',
            parameter,
            GetAvailableLinkTypesResult
        );
    }

    public async getPropertySynonyms(parameter: GetSynonymsParameter) {
        return await this.backendApiService.postPromise(
            'Entity/GetSynonyms',
            parameter,
            GetSynonymsResult
        );
    }

    public async updateLocalSynonym(parameter: LocalSynonymParameter) {
        return await this.backendApiService.postPromise(
            'Entity/UpdateLocalSynonym',
            parameter,
            LocalSynonymResult
        );
    }

    public async createLocalSynonym(parameter: LocalSynonymParameter) {
        return await this.backendApiService.postPromise(
            'Entity/CreateLocalSynonym',
            parameter,
            LocalSynonymResult
        );
    }

    public async deleteLocalSynonym(parameter: DeleteLocalSynonymParameter) {
        return await this.backendApiService.postPromise(
            'Entity/DeleteLocalSynonym',
            parameter,
            DeleteLocalSynonymResult
        );
    }

    public async watchEntity(parameter: WatchEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/watch',
            parameter,
            BaseServiceResult
        );
    }

    public async unwatchEntity(parameter: WatchEntityParameter) {
        return await this.backendApiService.postPromise(
            'Entity/unwatch',
            parameter,
            BaseServiceResult
        );
    }

    public async getEntityInsights(param: GetEntityInsightsParameter) {
        return await this.backendApiService.postPromise(
            'Insight/GetEntityInsight',
            param,
            GetInsightsResult
        );
    }
}
