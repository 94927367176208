import { FieldType } from '../../types/enums/field-type.enum';

export const salesforcePlatformConfig = [
    {
        payloadField: 'server',
        formField: 'server',
        tradKey: 'authenticationServer',
        formMandatory: true,
        type: FieldType.text,
        payloadValue: 'https://login.salesforce.com',
    },
    {
        payloadField: 'client-id',
        formField: 'clientId',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'client-secret',
        formField: 'clientSecret',
        formMandatory: true,
        isPassword: true,
    },
    {
        payloadField: 'access-token',
        formField: 'accessToken',
        formMandatory: true,
        isPassword: true,
    },
    {
        payloadField: 'username',
        formField: 'username',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        formMandatory: true,
        isPassword: true,
    },
];
