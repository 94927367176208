import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
} from '@angular/core';
import { DxyBaseComponent } from './base.component';
import {
    AbstractControl,
    ControlValueAccessor,
    NgControl,
} from '@angular/forms';
import { ZoneUtils } from '@datagalaxy/utils';

/** Base class for a component exposing ngModel/ngModelChange.
 * Can also:
 * - trigger a native *change* event,
 * - emit a debouncedModelChange ng event.
 *
 * Inherits DxyBaseComponent which provides:
 * - logging methods,
 * - events subscription and unsubscription.
 */
@Directive()
export class DxyBaseValueAccessorComponent<T>
    extends DxyBaseComponent
    implements OnDestroy, ControlValueAccessor
{
    /** To be provided for *debouncedModelChange* eventEmitter to be available */
    @Input() debounceTimeMs = 0;

    /** Event triggered only every *debounceTimeMs* milliseconds on value changes */
    @Output() debouncedModelChange = new EventEmitter<T>();

    /** Internal value of this control */
    protected _value?: T;

    protected propagateChange?: (value?: T) => void;
    protected propagateTouched?: (value?: any) => void;

    private debouncedChangeTimer?: number;

    public get value() {
        return this._value;
    }

    public set value(value: T | undefined) {
        this.setValue(value);
    }

    protected setValue(value?: T) {
        if (this.preventValueChange(value, this._value)) {
            return;
        }
        this._value = value;
        this.onValueChanged(value);
    }

    protected get validationErrors() {
        return this.ngControl?.control?.validator?.({} as AbstractControl);
    }

    protected get isErrorRequired() {
        return this.validationErrors?.['required'];
    }

    /** By default returns false.
     * Returning true will keep the current value. */
    protected preventValueChange(_newValue?: T, _currentValue?: T) {
        return false;
    }

    /** By default returns false.
     * Tested when the value has changed.
     * Returning true will allow to dispatch the native *change* event,
     * which can be used to trigger the *dxyLogfunctional* directive. */
    protected get dispatchNativeChangeEvent() {
        return false;
    }

    protected constructor(
        protected ngControl: NgControl | undefined,
        protected ngZone: NgZone,
        protected elementRef?: ElementRef<HTMLElement>
    ) {
        super();
        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }

    override ngOnDestroy() {
        if (this.debouncedChangeTimer) {
            clearTimeout(this.debouncedChangeTimer);
        }
        super.ngOnDestroy();
    }

    //#region ControlValueAccessor
    public writeValue(value: T): void {
        this.log('writeValue', value);
        // Note: Setting this._value instead of this.value is done on purpose,
        // not to trigger ngModelChange when the value is first set.
        // If needed, this behaviour can be overriden in the sub class.
        this._value = value;
    }

    /**
     * Do not try to call it manually, it is used by form-control-based directive like ngModel
     * So it will be overridden anyway, instead override onValueChanged
     */
    public registerOnChange(fn: (value?: T) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: (value?: any) => void): void {
        this.propagateTouched = fn;
    }

    //#endregion

    /** Called by set value to propagate the change.
     * Optionally dispatch the native *change* event, and emit debouncedModelChange. */
    protected onValueChanged(newValue?: T) {
        this.propagateChange?.(newValue);

        if (this.dispatchNativeChangeEvent) {
            this.elementRef?.nativeElement?.dispatchEvent?.(
                new Event('change')
            );
        }

        if (this.debouncedChangeTimer) {
            clearTimeout(this.debouncedChangeTimer);
        }

        if (this.debounceTimeMs > 0) {
            this.debouncedChangeTimer = ZoneUtils.zoneTimeout(
                () => this.debouncedModelChange.emit(this.value),
                this.debounceTimeMs,
                this.ngZone,
                true
            );
        }
    }
}
