import {
    LinkSuggestionGenerationAbortedEvent,
    LinkSuggestionGenerationEndedEvent,
    LinkSuggestionGenerationFromModuleEndedEvent,
} from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import {
    IDgEventTranslationAdapter,
    IEventTranslationInputs,
} from '../../../interfaces';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

const eventTypes = [
    LinkSuggestionGenerationEndedEvent,
    LinkSuggestionGenerationAbortedEvent,
    LinkSuggestionGenerationFromModuleEndedEvent,
];

export type TLinkSuggestionGenerationEvent = InstanceType<
    (typeof eventTypes)[number]
>;

/**
 * ## Role
 * Adapter to translate link suggestion generation events
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class LinkSuggestionTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TLinkSuggestionGenerationEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);

        if (this.event instanceof LinkSuggestionGenerationEndedEvent) {
            this.translateLinkSuggestionGenerationEndedEvent(
                this.event,
                inputs
            );
        }

        if (
            this.event instanceof LinkSuggestionGenerationFromModuleEndedEvent
        ) {
            this.translateLinkSuggestionGenerationFromModuleEndedEvent(
                this.event,
                inputs
            );
        }

        return inputs;
    }

    private translateLinkSuggestionGenerationEndedEvent(
        event: LinkSuggestionGenerationEndedEvent,
        inputs: IEventTranslationInputs
    ) {
        const sourceHdd = event.SourceHdd;
        inputs.translateParameters.push(
            {
                key: 'linkCount',
                value: event.LinkSuggestionCreatedCount,
            },
            {
                key: 'entityName',
                value: sourceHdd?.DisplayName,
            }
        );

        if (sourceHdd) {
            sourceHdd.setVersionId(event.VersionId);
            inputs.navLinks.push(this.getEntityNavLink(sourceHdd));
        }
    }

    private translateLinkSuggestionGenerationFromModuleEndedEvent(
        event: LinkSuggestionGenerationFromModuleEndedEvent,
        inputs: IEventTranslationInputs
    ) {
        inputs.translateParameters.push(
            {
                key: 'linkCount',
                value: event.LinkSuggestionCreatedCount,
            },
            {
                key: 'sourceModule',
                value: `DgServerTypes.DataGalaxyModule.${
                    DgModule[event.SourceModule]
                }`,
            }
        );
        inputs.navLinks.push({
            linkCssClass: 'nav-link-module',
            parameters: {
                module: event.SourceModule,
                spaceId: event.WorkspaceId,
                versionId: event.VersionId,
            },
        });
    }
}
