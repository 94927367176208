export enum ModalSize {
    Default,
    Large = 'lg',
}

/** Possible overrides for a modal's position. */
export class IModalPosition {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
}

/** Behaviour options passed to the modal instance component (DxyBaseModalComponent, before ngOnInit is called) */
export interface IModalInstanceOptions {
    /** When true, the modal won't close when the escape key is pressed. */
    disableCloseOnEscapeKey?: boolean;

    /** When true, the modal won't close when the backdrop is clicked */
    disableCloseOnBackdropClick?: boolean;
}
