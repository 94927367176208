import { CropMode, TAllowedMimeType } from '@datagalaxy/core-doka-util';

export interface IUiImageInputParams {
    mode: UiImageInputMode;
    getImageUrl: () => string | Promise<string>;
    storeImage?: (main: File, thumbnail: File) => Promise<boolean>;
    deleteImage?: () => Promise<boolean>;
    onDone?: (main?: File) => void;
    /** height of the editor, and max width and height of the displayed image, unless imageSize is set */
    editSize: number;
    /** height/width for the image to display */
    imageSize?: number;
    /** max width and height of the image for its usage out of this component.
     * Note that the actual applied size will be x2, for high-resolution screen compatibility.
     * If not provided, the editSize will be used instead. */
    finalSize?: number;
    /** true to zoom the image when smaller than size */
    upscale?: boolean;
    /** available crop tool(s). Note: When round is used, image is also rounded */
    crop?: CropMode;
    /** to show the resize tool button */
    showResize?: boolean;
    /** text (initials, trigram) to display when no image and no default image  */
    placeHolderText?: string | (() => string);
    /** class to apply to the placeholder when no image and no default image */
    placeHolderClass?: string | (() => string);
    /** default image. not editable */
    defaultImageUrl?: string;
    /** when set, this will force cropMode to Square and apply a custom ratio */
    forcedRatio?: number;
    /** defaults to image/png */
    mimeType?: TAllowedMimeType;
}

export enum UiImageInputMode {
    /** image is displayed */
    noEdit = 0,
    /** image is displayed;
     * editor is activated by clicking on the image, in a modal */
    modalEdit,
    /** the image is displayed;
     * the editor is activated by clicking on the image, in place of the image,
     * not bigger than image, but with minimim tools */
    inplaceEditMini,
    /** the image is displayed;
     * the editor is activated by clicking on the image, takes full width, in place of the image */
    inplaceEditFull,
}

export interface IUiImageInputResolve {
    params: IUiImageInputParams;
    src?: string | Blob;
}
export interface IUiImageInputResult {
    imageFile: File;
    imageSize: ISize;
}
export interface ISize {
    width: number;
    height: number;
}
