import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

export type IconSize = 'xs' | 'medium' | 'xl' | 'xxl';
export type IconType = 'glyph' | 'url';

/**
 * Display a glyph icon or an image url
 */
@Component({
    standalone: true,
    selector: 'dxy-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, NgIf],
})
export class IconComponent {
    @Input() size: IconSize = 'medium';
    @Input() icon = '';
    @Input() type?: IconType = 'glyph';

    @HostBinding('class')
    private get sizeClass() {
        return this.size;
    }
}
