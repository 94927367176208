import * as moment from 'moment';
import { Transition } from '@uirouter/core';
import { StringUtil } from '@datagalaxy/core-util';
import { ErrorLogin } from '../../shared/util/app-types/errors.types';
import { BrowserInformation } from '../../shared/util/app-types/BrowserInformation';
import { ApiServiceError, BaseServiceResult } from '@datagalaxy/data-access';

/**
 * Build exception notification data
 */
export class ExceptionNotificationBuilder {
    //#region static
    private static readonly maximumNotifiedExceptionsPerPeriod = 10;
    private static readonly maximumNotifiedExceptionsPeriod = 1000;

    /** After 1h (60 * 60 * 1000ms), we can relog the exact same exception/stack/url combo if it occurs */
    private static readonly uniqueExceptionTimeoutPeriod = 60 * 60 * 1000;
    private static lastExceptionTimesQueue: moment.Moment[] = [];
    private static uniqueExceptionsHashCodeMap = new Map<
        number,
        moment.Moment
    >();
    //#endregion

    private errorsToIgnore = [ErrorLogin, Transition];

    private get exception() {
        return this.params.exception;
    }
    private get cause() {
        return this.params.cause;
    }
    private get errorType() {
        return this.params.errorType;
    }
    private set errorType(et: string) {
        this.params.errorType = et;
    }

    public data: IExceptionNotificationData;
    public shouldSend = true;

    constructor(private params: IExceptionNotificationBuilderParams) {
        this.build();
    }
    private shouldSendError(): boolean {
        if (
            this.errorsToIgnore.some((e) => this.exception instanceof e) ||
            this.cause === 'signalr' ||
            this.exception?.message === 'The transition was ignored' ||
            (this.exception as any)?.status === 304
        ) {
            return false;
        }
        /*
        if (typeof this.exception === 'string') {
            if (
                this.exception
                    .toString()
                    .startsWith('Possibly unhandled rejection')
            ) {
                return false;
            }
            this.data.message = this.exception.toString();
        }
         */
        return !this.previouslySent();
    }

    private build() {
        this.data = {
            errorType: this.errorType,
            source: this.cause?.toString() ?? '',
            url: window.location.href,
            message: this.exception.message
                ? this.exception.message.substring(
                      0,
                      Math.min(this.exception.message.length, 10000)
                  )
                : '',
            stack: this.exception.stack
                ? this.exception.stack.substring(
                      0,
                      Math.min(this.exception.stack.length, 10000)
                  )
                : '',
            apiRoute: '',
            browserInfo: this.params.browserInfo,
        };
        let anyException = this.params.exception as any;

        if (!this.data.message && anyException.status) {
            this.errorType = 'api';
            this.data.message = `Server Error Received, Status: ${anyException.status}`;

            if (anyException.config?.url) {
                this.data.message = `Server Call: ${anyException.config.url}: ${this.data.message}`;
            }

            if (anyException.config?.data) {
                this.data.apiParameter = anyException.config.data;
            }
        }

        this.shouldSend = this.shouldSendError();
        this.buildApiServiceErrorData();
        this.encodeDataForUri();
    }

    private buildApiServiceErrorData() {
        if (!(this.exception instanceof ApiServiceError)) {
            return;
        }
        const { route, error } = this.exception;
        this.data.apiRoute = route ? `/${route}` : '';
        this.data.apiResult = error;
    }

    private encodeDataForUri() {
        this.data.message = encodeURIComponent(this.data.message ?? '');
        this.data.stack = encodeURIComponent(this.data.stack ?? '');
    }

    /** Two-factor logic for throttling the exception logging process:
     * First: simple time-based: not more than maximumNotifiedExceptionsPerPeriod errors per maximumNotifiedExceptionsPeriod
     * Second: unique error-based: do not log exact same message+stack+url more than once per uniqueExceptionTimeoutPeriod,
     * currently set to an hour */
    private previouslySent(): boolean {
        const currentExceptionMoment = moment();
        const currentExceptionTotalMilliseconds =
            currentExceptionMoment.valueOf();

        // Time-based Throttling logic: maximum maximumNotifiedExceptionsPerPeriod errors per maximumNotifiedExceptionsPeriod, others are ignored
        ExceptionNotificationBuilder.lastExceptionTimesQueue.push(moment());
        if (
            ExceptionNotificationBuilder.lastExceptionTimesQueue.length >
            ExceptionNotificationBuilder.maximumNotifiedExceptionsPerPeriod
        ) {
            const first =
                ExceptionNotificationBuilder.lastExceptionTimesQueue[0].valueOf();
            if (
                currentExceptionTotalMilliseconds - first <
                ExceptionNotificationBuilder.maximumNotifiedExceptionsPeriod
            ) {
                return true;
            }

            ExceptionNotificationBuilder.lastExceptionTimesQueue.shift();
        }

        /** Error Hash Code-based Throttling logic: do not log exact same message+stack+url more than once per uniqueExceptionTimeoutPeriod,
         * currently set to an hour */
        const errorHash = this.computeErrorHash();
        if (
            ExceptionNotificationBuilder.uniqueExceptionsHashCodeMap.has(
                errorHash
            )
        ) {
            const lastExceptionMoment =
                ExceptionNotificationBuilder.uniqueExceptionsHashCodeMap.get(
                    errorHash
                );
            if (
                currentExceptionTotalMilliseconds -
                    lastExceptionMoment.valueOf() <=
                ExceptionNotificationBuilder.uniqueExceptionTimeoutPeriod
            ) {
                // NOT LOGGED TO SERVER: uniqueExceptionTimeoutPeriod delay not expired
                return true;
            }
        }

        ExceptionNotificationBuilder.uniqueExceptionsHashCodeMap.set(
            errorHash,
            currentExceptionMoment
        );
        return false;
    }

    /**  simple naive computation combining hashCodes */
    private computeErrorHash(): number {
        return (
            StringUtil.hashCode(this.data.message) +
            StringUtil.hashCode(this.data.stack) +
            StringUtil.hashCode(window.location.href)
        );
    }
}

export interface IExceptionNotificationBuilderParams {
    exception: Error;
    cause: string;
    errorType: string;
    browserInfo: BrowserInformation;
}

interface IExceptionNotificationData {
    errorType: string;
    message: string;
    stack: string;
    source: string;
    url: string;
    apiRoute: string;
    apiParameter?: any;
    apiResult?: BaseServiceResult;
    browserInfo: BrowserInformation;
}
