import { BehaviorSubject } from 'rxjs';
import { SelectionChange } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DataSource } from '@angular/cdk/table';
import { Sort } from '@angular/material/sort';
import { Type } from '@angular/core';
import { BaseCellComponent } from '../../cell-components/BaseCellComponent';

export interface IEditGridData<TItem> {
    dataSource: DataSource<TItem>;
    colDefs: IColDef<TItem>[];
    options?: IEditGridOptions<TItem>;
}

export interface IEditGridOptions<TItem> {
    /** when true, no cell can be edited. Note this can be a getter. */
    readonly?: boolean | (() => boolean);

    /** when true, some styling is applied for the grid to be more compact*/
    compact?: boolean;

    /** Custom row class */
    customRowClass?: string | ((it: TItem) => string);

    /** Column's id to sort by initially */
    sortBy?: string;
    /** When true, the sort order is descending, else it is ascending */
    sortDesc?: boolean;
    /** When true, the user cannot modify the sort order */
    sortFixed?: boolean;

    /** When true, a selection column with check boxes is added.
     * Note: The datasource must be a *ILocalDataSource* (including *MatTableDataSource*) */
    multiSelect?: boolean;
    /** Whether a row can be selected. Used When *multiSelect* is true. Defaults to `() => true` */
    isSelectable?: (it: TItem) => boolean;

    /** When true, toggle icons are added for to show/hide children rows.
     * Note: The datasource must be a *IHierarchicalDataSource* */
    toggleChildren?: boolean;

    /** when true, onBeginCellEdit is emitted when a cell gets focused;
     * when false, when the cell value is changed by the user */
    beginCellEditOnFocus?: boolean;

    /** css property, number converted to px. Defaults to 25 when using *compact* */
    headerHeight?: number | string;
    /** css property, number converted to px. Defaults to 25 when using *compact* */
    rowHeight?: number | string;

    /** When true or options object, a column is added, allowing ordering by drag &amp; drop */
    orderByDrag?: boolean | IOrderByDragOptions<TItem>;

    toggleChildrenColumnWidth?: TWidthSpec;
    multiSelectColumnWidth?: TWidthSpec;
    orderByDragColumnWidth?: TWidthSpec;
}
export type TWidthSpec =
    | number
    | string
    | {
          width?: number | string;
          minWidth?: number | string;
          maxWidth?: number | string;
      };
export interface IOrderByDragOptions<TItem> {
    /** text for the header */
    headerText?: string;
    /** translate key for the header */
    headerKey?: string;
    /** Order index for a row item. Defaults to *datasource.data.indexOf(it)* */
    getValue?: (it: TItem) => string | number;
    /** To apply new ordering of columns. Defaults to modifying the content of the *datasource.data* array if it exists */
    onDrop?: (event: CdkDragDrop<TItem, TItem, TItem>) => void;
    /** when falsy, only y-axis move is allowed */
    noAxisLock?: boolean;
    /** when true, the value is displayed (by default it is not) */
    showValue?: boolean;
    /** when true, the datasource is not updated  */
    noDataSourceUpdate?: boolean;
}

/** Definition of an edit-grid's column */
export interface IColDef<TItem> {
    //#region at least one is mandatory
    /** Id of the column.
     * To be used when more than one column uses the same *field*, or not using *field* at all. */
    id?: string;
    /** Name of a property of the datasource object type */
    field?: string;
    //#endregion

    //#region either one of both must be provided
    /** translation key for computing the column's header text */
    headerKey?: string;
    /** column's header text, if no translation needed. Can be set to empty string for no header. */
    headerText?: string;
    //#endregion

    /** When true, the column is not displayed */
    hidden?: boolean;

    /** When true, the column cannot be sorted by clicking on its header */
    noSort?: boolean;

    /** When true, the cells of this column cannot be changed by the user */
    readonly?: boolean;

    /** To specify that the cell is readonly. Defaults to the column definition's *readonly* */
    isReadOnly?(it: TItem): boolean;

    /** When returning true, the cell is displayed as empty */
    isDisabled?(it: TItem): boolean;

    /** Defines both the renderer and the input type. Defaults to *EditCellType.text* */
    type?: EditCellType;
    /** Parameters needed by the renderer (depends on *type*) */
    inputs?: IBaseEditCellRendererParams;

    customCellComponent?: Type<BaseCellComponent>;

    /** Note there is also a global *onValueChanged* event on this component.
     * This method is called when an item's value is being changed. Defaults to `item[cd.field] = value`.
     * Note that `item[cd.id ?? cd.field]` is used as the value for sorting a column,
     * and that *item* is not modified by the grid when this *onValueChange* is provided.
     * Thus, one must set item[cd.id] or item[cd.field] in this method for the column to be sortable. */
    onValueChange?(it: TItem, value: any): void;

    /**  only used on boolean toggle slides to set to previous value if change is forbidden.
     * OnValueChange will not be emitted
     * @param it item from the cell
     * @param value value of the event
     */
    editAllowed?(it: TItem, value: any): Promise<boolean>;

    /** To be used when not using the *field* property to get a value from an item. Defaults to `item[cd.field]` */
    getValue?(it: TItem): any;

    /** To provide the displayed text from an item. Defaults to `item[cd.field]` */
    getText?(it: TItem): string;

    /** To provide selector options from an item when column's definition *type* is *EditCellType.selector*.
     * Note: Called pretty often, so this must be fast. */
    getOptions?(it: TItem): ISelectorOption[];

    width?: number | string;
    minWidth?: number | string;
    maxWidth?: number | string;

    /** to be used when type is *action* */
    actionGlyphClass?: string | ((it: TItem) => string);
    /** to be used when type is *action* */
    actionCallback?: (it: TItem) => void;
    /** to be used when type is *action* */
    actionDisabled?: (it: TItem) => boolean;
    /** to be used when type is *action* */
    actionTooltipKey?: string | ((it: TItem) => string);
    /** to be used when type is *action* */
    actionTooltipText?: string | ((it: TItem) => string);
    /** to be used when type is *action* or *booleanSlider*, if true action will be hidden and displayed on row hover */
    isDisplayedOnHover?: boolean | ((it: TItem) => boolean);

    /** Debounces the global *onValuechanged* event firing */
    debounceTimeMs?: number;

    /** data associated ith the column */
    data?: any;
}

export interface ISelectorOption<T = any> {
    value: T;
    /** Displayed text. Defaults to *value* */
    text?: string;
    /** Optional glyph class */
    glyphClass?: string;
}

// to be extended by more specific interfaces (nothing to add here)
export interface IBaseEditCellRendererParams {}

export enum EditCellType {
    /** text */
    text = 0,
    /** translated 'yes' or 'no'; checkbox */
    booleanYesNo,
    /** slider with a tooltip */
    booleanSlider,
    /** radio button cell */
    booleanRadio,
    /** dropdown-list (single-select) */
    selector,
    /** clickable action, using *actionGlyphClass*, *actionCallback*, *actionDisabled* properties. */
    action,
    /** custom cell component; need a ICustomCellParams */
    custom,
}

export interface ICell<TItem> {
    item: TItem;
    colDef: IColDef<TItem>;
}
export interface IValueChange<TItem> extends ICell<TItem> {
    previousValue: any;
    currentValue: any;
}
export interface ISelectionChange<TItem> {
    selection: TItem[];
    changes: SelectionChange<TItem>;
}
export interface IItemSelectedChange<TItem> {
    item: TItem;
    selected: boolean;
}

export interface IEditGridAPI<TItem = unknown> {
    getSelected(): TItem[];
    setSelected(selected: boolean, ...items: TItem[]): void;
    updateColumns(columns: IColDef<TItem>[]): void;
}

//#region datasources

export interface ISortableDataSource<T> extends DataSource<T> {
    sort: Sort;
    /** must be implemented if the data source is not MatTableDataSource */
    applySort?(): void;
}
export interface ILocalDataSource<T> extends DataSource<T> {
    data: T[];
}
export interface IHierarchicalDataSource<T> extends DataSource<T> {
    setExpanded(item: T, expanded: boolean): void;
    toggleExpanded(item: T): void;
    setAllExpanded(expanded: boolean): void;
    isAnyCollapsed(): boolean;
    isAnyExpanded(): boolean;
    isExpandable(it: T): boolean;
    isCollapsible(it: T): boolean;
    hasChildren(it: T): boolean;
    getChildren(it: T): T[] | undefined;
}

export class SimpleDataSource<T> extends DataSource<T> {
    private itemsSubject = new BehaviorSubject<T[]>([]);

    constructor(public items: T[]) {
        super();
        this.itemsSubject.next(items);
    }

    connect() {
        return this.itemsSubject.asObservable();
    }
    disconnect() {
        this.itemsSubject.complete();
    }
}

//#endregion
