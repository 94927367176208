import {
    GenericDeserialize,
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { SpaceMemberDto, SpaceRole } from './user';
import { UserAdminData } from '@datagalaxy/webclient/user/data-access';
import { SpaceGovernanceUserDto } from '@datagalaxy/webclient/workspace/domain';

@inheritSerialization(BaseServiceParameter)
export class GetSpaceUsersParameter extends BaseServiceParameter {
    @serialize SpaceGuid?: string;
    @serialize IncludeRoleData?: boolean;
    @serializeAs(SpaceRole) IncludedRoles?: SpaceRole[];
}

@inheritSerialization(BaseServiceParameter)
export class GetSpaceAdministrationUsersParameter extends BaseServiceParameter {
    @serialize SpaceGuid?: string;
}

@inheritSerialization(BaseServiceResult)
export class GetSpaceAdministrationUsersResult extends BaseServiceResult {
    @deserializeAs(UserAdminData) SpaceUsers!: UserAdminData[];
}

@inheritSerialization(BaseServiceResult)
export class GetSpaceUsersResult extends BaseServiceResult {
    @deserializeAs(SpaceMemberDto) SpaceMembers!: SpaceMemberDto[];
}

@inheritSerialization(BaseServiceParameter)
export class UpdateSpaceGovernanceUserParameter extends BaseServiceParameter {
    @serialize public AddUserList: string[];
    @serialize public DeleteUserList: string[];
    @serialize public DefaultUserGuid: string;
    @serialize public AttributeKey: string;
    @serialize public SpaceId: string;

    constructor(
        addUserList: string[],
        deleteUserList: string[],
        defaultUserId: string,
        attributeKey: string,
        spaceId: string
    ) {
        super();
        this.AddUserList = addUserList;
        this.DeleteUserList = deleteUserList;
        this.DefaultUserGuid = defaultUserId;
        this.AttributeKey = attributeKey;
        this.SpaceId = spaceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class SpaceGovernanceUserResult extends BaseServiceResult {
    @deserialize public SpaceGovernanceUserList!: Map<
        string,
        SpaceGovernanceUserDto[]
    >;

    static OnDeserialized(instance: SpaceGovernanceUserResult, json: any) {
        instance.SpaceGovernanceUserList = new Map(
            json.SpaceGovernanceUserList.map((data: any) => [
                data.Key,
                GenericDeserialize(data.Value, SpaceGovernanceUserDto),
            ])
        );
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetSpaceGovernanceUsersParameter extends BaseServiceParameter {
    @serialize public SpaceId: string;
    @serialize public AttributeKey: string;
    @serialize public IncludeStatusInfo: boolean;

    constructor(
        spaceId: string,
        attributeKey: string,
        includeStatusInfo: boolean
    ) {
        super();
        this.SpaceId = spaceId;
        this.AttributeKey = attributeKey;
        this.IncludeStatusInfo = includeStatusInfo;
    }
}
