export const amazonGlueFieldInfo = {
    region: {
        hint: {
            fr: 'Code de la région AWS, exemple : eu-west-3',
            en: 'AWS region code, example: eu-west-3',
        },
    },
    database: {
        hint: {
            fr: 'Filtrer sur une base de données de votre catalogue de données AWS Glue',
            en: 'Filter a database in your AWS Glue data catalog',
        },
    },
    endpoint: {
        description: {
            fr: 'Identifiant du point de terminaison VPC à utiliser pour communiquer avec la ressource AWS',
            en: 'VPC endpoint identifier to use in order to communicate with the AWS resource',
        },
        hint: {
            fr: 'Identifiant du point de terminaison VPC',
            en: 'VPC endpoint identifier',
        },
    },
    roleArn: {
        description: {
            fr: 'Rôle à utiliser pour accéder à la ressource AWS au format ARN : arn:partition:service:region:account:resource',
            en: 'Role to use to access the AWS resource ARN format: arn:partition:service:region:account:resource ',
        },
    },
    accessKey: {
        hint: {
            fr: "Identifiant de la clé d'accès de votre compte AWS",
            en: 'AWS account access key identifier',
        },
    },
    password: {
        hint: {
            fr: "Clé d'accès secrète de votre compte AWS ",
            en: 'AWS account secret access key',
        },
    },
    stsToken: {
        description: {
            fr: 'Jeton de session pour une ressource AWS ayant le service AWS Security Token Service (STS) actif',
            en: 'Session token for an AWS resource with the AWS Security Token Service (STS) active',
        },
    },
};
