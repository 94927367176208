import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { StringUtil } from '@datagalaxy/core-util';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ImportContext } from '../../../import/shared/ImportContext';
import { IOnlineImportEntity } from '@datagalaxy/webclient/connectivity/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { ConnectionEntitySelectionService } from '../connection-entity-selection.service';
import { ConnectionFormService } from '../connection-form.service';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { FormsModule } from '@angular/forms';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-connection-form-entity-selection',
    templateUrl: './dxy-connection-form-entity-selection.component.html',
    styleUrls: ['./dxy-connection-form-entity-selection.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        SpinnerComponent,
        MatLegacyRadioModule,
        FormsModule,
        TranslateModule,
        SearchInputComponent,
        GridComponent,
    ],
})
export class DxyConnectionFormEntitySelectionComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    @Input() importContext: ImportContext;
    @ViewChild(GridComponent, { static: true })
    grid: GridComponent<IOnlineImportEntity>;

    public get isLoading() {
        return this.connectionEntitySelectionService.isLoading;
    }

    public set isLoading(value: boolean) {
        this.connectionEntitySelectionService.isLoading = value;
    }

    public objects: IOnlineImportEntity[];
    public selectedObjects: IOnlineImportEntity[] = [];

    protected gridConfig: GridConfig<IOnlineImportEntity> = {
        getItemId: (item) => item.name,
        multiSelect: true,
    };
    protected cols: TColDef<IOnlineImportEntity>[] = [
        {
            id: 'name',
            headerLabel: this.translate.instant(
                'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.colLbl'
            ),
            type: GridCellType.text,
            getValue: (item) => item.name,
        },
    ];
    protected rows: IOnlineImportEntity[] = [];

    public searchString = '';
    public isSelectAll = true;

    public get titleText() {
        const message = this.translate.instant(
            'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.title'
        );
        return `${message} ${this.connectionFormService.getPluginTitle()}`;
    }

    constructor(
        private connectionEntitySelectionService: ConnectionEntitySelectionService,
        private connectionFormService: ConnectionFormService,
        private translate: TranslateService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.isLoading = true;
        this.isSelectAll =
            this.connectionEntitySelectionService.selectedObjectNames.length ===
            0;
        this.connectionEntitySelectionService.isAllObjectsSelected =
            this.isSelectAll;
        void this.initConnectionObjects();
    }

    public async onSelectionChange(selectedRows: IOnlineImportEntity[]) {
        this.selectedObjects = selectedRows;
        this.connectionEntitySelectionService.selectedObjectNames =
            this.selectedObjects.map((row) => row.name);
    }

    public async onSearchTermChange() {
        this.rows = StringUtil.filterSearched(
            this.searchString,
            this.objects,
            (object) => object.name
        );
    }

    public onChangeSelectAll() {
        this.connectionEntitySelectionService.isAllObjectsSelected =
            this.isSelectAll;
    }

    private async initConnectionObjects() {
        this.rows = this.objects =
            await this.connectionEntitySelectionService.getCurrentObjects(
                this.importContext.spaceIdr.spaceId,
                this.connectionFormService.connectionId
            );

        if (!this.isSelectAll) {
            this.selectedObjects =
                this.connectionEntitySelectionService.selectedObjectNames.map(
                    (n) => this.objects.find((o) => o.name === n)
                );
            setTimeout(() => {
                this.grid.selection = this.selectedObjects;
            }, 100);
        }
        this.isLoading = false;
    }
}
