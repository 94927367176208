import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { Table } from './table';
import { Ref } from '../ref';

@inheritSerialization(Ref)
export class TableRef extends Ref<Table> {
    static fromIds(ids: string[]) {
        return ids?.map(TableRef.fromId) ?? [];
    }

    static fromId(id: string) {
        return new TableRef(id);
    }

    constructor(id?: string) {
        super(ServerType.Table, id);
    }
}
