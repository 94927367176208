import { MsTeamsIntegrationDeactivatedEvent } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

/**
 * ## Role
 * Adapter to get MsTeamsIntegrationDeactivatedEvent translation inputs
 */
@registerTypedEventTranslationAdapter([MsTeamsIntegrationDeactivatedEvent])
export class MsTeamsIntegrationDeactivatedEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: MsTeamsIntegrationDeactivatedEvent) {
        super(event);
    }

    getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);
        return inputs;
    }
}
