<img src="/images/multilingual-attributes.svg" alt="Attributes" />
<p>
    <strong translate>
        Multilingual.Administration.AttributesSelectionStep.question
    </strong>
    <br />
    <span translate>
        Multilingual.Administration.AttributesSelectionStep.hint
    </span>
</p>

<form [formGroup]="form">
    <dxy-attribute-selector
        formControlName="attributes"
        [labelKey]="'Multilingual.attributes'"
        [availableAttributes]="attributes"
        [mandatory]="true"
    >
    </dxy-attribute-selector>
</form>
