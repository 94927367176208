<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.extensionModal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <div class="extension-container">
        <div class="image">
            <img src="/images/extension.png" />
        </div>
        <span
            class="explanation"
            [innerHTML]="'UI.extensionModal.explanation' | translate"
        ></span>
        <div class="dxy-field pat-input">
            <mat-form-field class="mat-focused">
                <mat-label appearance="standard" floatLabel="always" translate>
                    UI.extensionModal.keyLabel
                </mat-label>
                <input
                    matInput
                    [readonly]="true"
                    [value]="
                        isPatGenerated
                            ? '**********************************'
                            : ('UI.extensionModal.inputValue' | translate)
                    "
                    spellcheck="false"
                />
                <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    [matTooltip]="
                        (isPatGenerated
                            ? 'UI.extensionModal.tooltipCopy'
                            : 'UI.extensionModal.tooltipGenerate'
                        ) | translate
                    "
                    (click)="isPatGenerated ? copyPat() : generatePat()"
                >
                    <i
                        [class]="
                            isPatGenerated ? 'glyph-file-copy' : 'glyph-redo2'
                        "
                    ></i>
                </button>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button mat-flat-button color="primary" (click)="onClickDownload()">
        <span class="glyph-shortcut"></span>
        <span translate>UI.extensionModal.buttonLabel</span>
    </button>
    <button mat-flat-button (click)="onCloseCancel()">
        <span translate>UI.Global.btnClose</span>
    </button>
</div>
