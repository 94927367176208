import { IXYRO, Point, simplifyPath, Vect2 } from '@datagalaxy/core-2d-util';
import { IOrthogonalPathOptions } from '../../../routing';
import { CoreUtil } from '@datagalaxy/core-util';
import { IPathBuilder } from '../path-builder.types';

export class OrthogonalPathBuilder implements IPathBuilder {
    public computePathD(route: IXYRO[], opt?: IOrthogonalPathOptions) {
        if (!route?.length) {
            return '';
        }
        const points = simplifyPath(route);
        const start =
            opt?.arrowPosition && opt.inverted ? opt.arrowPosition : points[0];
        const end =
            opt?.arrowPosition && !opt.inverted
                ? opt.arrowPosition
                : points[points.length - 1];

        return [
            `M${start.x} ${start.y}`,
            ...this.computePathDInternal(points, opt?.radius),
            `L${end.x} ${end.y}`,
        ].join(' ');
    }

    private computePathDInternal(points: Point[], radius = 0): string[] {
        let parts: string[] = [];
        const v = new Vect2(0, 0);
        const rl = points.length - 1;

        if (!radius || points?.length < 3) {
            return points.map((point) => `L${point.x} ${point.y}`);
        }

        for (let i = 1; i < rl; i++) {
            const a = points[i - 1],
                b = points[i],
                c = points[i + 1];
            if (!b) {
                CoreUtil.warn('b?', i, points);
            }
            const lab = Vect2.orthogonalSegmentLength(a, b);
            const lbc = Vect2.orthogonalSegmentLength(b, c);
            if (isNaN(lab) || isNaN(lbc)) {
                CoreUtil.warn('lab?', a, b, 'lbc?', b, c);
                parts.push(`L${a.x} ${a.y}`);
            } else {
                const r = Math.min(radius, Math.min(lab, lbc) / 2);
                v.copy(b)
                    .sub(a)
                    .divideScalar(lab) // unit vector ab
                    .multiplyScalar(lab - r)
                    .add(a)
                    .truncateDecimals(true);
                parts.push(`L${v.x} ${v.y}`);
                v.copy(c)
                    .sub(b)
                    .divideScalar(lbc) // unit vector bc
                    .multiplyScalar(r)
                    .add(b)
                    .truncateDecimals(true);
                const sweepFlag = Vect2.tripletOrientation(a, b, c) < 0 ? 1 : 0;
                parts.push(`A${r} ${r} 0 0 ${sweepFlag} ${v.x} ${v.y}`);
            }
        }
        return parts;
    }
}
