<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.PrimaryKey
    </div>
</div>
<div class="panel-content-body">
    <app-primary-key-settings-content
        *ngIf="settingsData"
        [data]="settingsData"
        [noTableName]="true"
        [readOnly]="!hasWriteAccess"
        (outputChange)="onOutputChange($event)"
    ></app-primary-key-settings-content>
</div>
