import { Injectable } from '@angular/core';
import { FunnelStateService } from './store/funnel-state.service';
import { FunnelDialogService } from './funnel-dialog.service';
import { FunnelOptions } from './funnel-option';

@Injectable({ providedIn: 'root' })
export class FunnelService {
    constructor(
        private funnelStateService: FunnelStateService,
        private funnelDialogService: FunnelDialogService
    ) {
        this.funnelStateService.selectVisible().subscribe((visible) => {
            if (!visible) {
                this.funnelDialogService.close();
            } else {
                this.funnelDialogService.open();
            }
        });
    }

    public startFunnel(options: FunnelOptions) {
        this.funnelStateService.initSteps(options.steps);
        return this.funnelStateService.start();
    }
}
