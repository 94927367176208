import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { TeamMemberDto } from '../team-member';

export class BaseTeamData {
    @deserialize TeamUid!: string;
    @deserialize ReferenceId!: string;
    @deserialize TeamName!: string;
    @deserialize TeamImageHash!: string;
}

@inheritSerialization(BaseTeamData)
export class TeamDto extends BaseTeamData {
    @deserialize Description?: string;
    @deserialize CreationTime?: string;
    @deserialize Email?: string;
    @deserialize MembersCount?: number;
    @deserialize MembershipRequestCount?: number;
    @deserialize AccessType?: TeamAccessType;
    @deserialize TeamOwners?: TeamMemberDto[];
    @deserialize IsCurrentUserMember?: boolean;
    @deserialize CurrentUserHasMembershipRequest?: boolean;
}

@inheritSerialization(BaseTeamData)
export class TeamPublicData extends BaseTeamData {
    @deserialize HasTeamReadAccess!: boolean;
}

export enum TeamAccessType {
    Private,
    Limited,
    Open,
}
SerializableEnumeration(TeamAccessType);
