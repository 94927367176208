<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="UI.Campaign.Form.Title.AddToCampaign"
    ></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <dxy-field-select
            *ngIf="campaigns$ | async as campaigns; else spinner"
            labelKey="UI.Campaign.Form.ActiveCampaign"
            formControlName="campaign"
            [hintBeforeControl]="true"
            [options]="campaigns"
            [adapter]="campaignAdapter"
            [mandatory]="true"
            [errorMessageText]="objectAlreadyInCampaignErrorMessage"
        ></dxy-field-select>
        <ng-template #spinner>
            <dxy-spinner></dxy-spinner>
        </ng-template>
    </div>

    <dxy-modal-footer
        [actionEnabled]="formGroup.valid"
        [actionBtnLbl]="'UI.Dialog.btnAdd' | translate"
        [actionLoading]="loading$ | async"
        (onCloseCancel)="onCloseCancel()"
        featureCode="CAMPAIGN,C"
    ></dxy-modal-footer>
</form>
