import { deserialize, deserializeAs, SerializableEnumeration } from 'cerialize';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export enum ProjectVersionStatus {
    Active,
    ReleaseCandidate,
    Official,
    Archived,
}
SerializableEnumeration(ProjectVersionStatus);

export class ProjectVersionStatusChange {
    @deserializeAs(ProjectVersionStatus) public Status!: ProjectVersionStatus;
    @deserialize public StatusChangeTime!: string;
    @deserialize public StatusChangeUserId!: string;
}

export class ProjectVersion implements ISpaceIdentifier {
    @deserialize public ProjectReferenceId!: string;
    @deserialize public ProjectVersionId!: string;
    @deserialize public ProjectName!: string;
    @deserializeAs(ProjectVersionStatus)
    public VersionStatus!: ProjectVersionStatus;
    @deserialize public VersionCreationTime!: string;
    @deserialize public VersionName!: string;
    @deserialize public VersionDescription!: string;
    @deserialize public IsUserDefaultVersion!: boolean;
    @deserialize public SourceVersionId!: string;
    @deserialize public SourceVersionName!: string;
    @deserialize public VersionCreationUserId!: string;
    @deserializeAs(ProjectVersionStatusChange)
    public StatusChangeList!: ProjectVersionStatusChange[];
    @deserialize public versionCountDifference!: string;

    //#region ISpaceIdentifier
    public get spaceId() {
        return this.ProjectReferenceId;
    }

    public get versionId() {
        return this.ProjectVersionId;
    }
    //#endregion
}
