import {
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StringUtil } from '@datagalaxy/core-util';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { AttributeDataService } from '../../../attribute-data.service';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { DxyUnitaryFieldActionsComponent } from '../../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxyFieldNumberComponent } from '@datagalaxy/core-ui/fields';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-number-input',
    templateUrl: './dxy-attribute-number-input.component.html',
    standalone: true,
    imports: [
        DxyFieldNumberComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
    ],
})
export class DxyAttributeNumberInputComponent extends DxyAttributeBaseInput<number> {
    @Input() min?: number;
    @Input() max?: number;

    @ViewChild('field') field: DxyFieldTextComponent;

    public get isEditMode() {
        return this.isEditEnabled && this.isActive;
    }

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService
    ) {
        super(elementRef, translate);
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {
        AttributeDataService.preventNumberUnsupportedChars(event);
        if (KeyboardUtil.preventEnterKey(event, true)) {
            this.onEnterKeyDown().then();
        }
    }

    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }

    public formatNumber(value: number) {
        return StringUtil.formatNumberGroupDigits(value);
    }
}
