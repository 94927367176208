<div *ngIf="isSpinnerActive$ | async" class="dg_spinner-background">
    <dxy-spinner
        class="xl"
        [message]="translatedMessage$ | async"
    ></dxy-spinner>
</div>

<dxy-nav-sidebar class="clientSideBar"></dxy-nav-sidebar>
<div class="client-container">
    <!-- TOP NAV BAR -->
    <dxy-navbar class="no-grow">
        <div class="dg_navItemContainer">
            <dxy-spotlight-input
                *ngIf="isSpotlightInputVisible"
                [class.hidden]="isSpotlightInputHidden"
                class="spotlight-input"
                data-dtname="Spotlight Input"
                [enableLastSearch]="true"
                [attr.data-tracker-id]="'navbar-spotlight'"
            ></dxy-spotlight-input>

            <dxy-language-selector-button
                *dxyIfFeatureFlag="'multilingual'"
                [administrationLinkVisible]="isCurrentUserClientAdmin"
            ></dxy-language-selector-button>
            <app-suggestion-trigger></app-suggestion-trigger>
            <app-notification-trigger></app-notification-trigger>
        </div>
    </dxy-navbar>
    <div class="mainView client-main-content" ui-view="content"></div>
    <dxy-preview-panel-slider logId="client-main"></dxy-preview-panel-slider>
</div>
<dxy-mat-burger-menu
    logId="main-burger-menu"
    (onLogFunctional)="onLogFunctional($event)"
></dxy-mat-burger-menu>
