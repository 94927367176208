import { BaseDTO } from '@datagalaxy/dg-object-model';
import { deserialize, deserializeAs } from 'cerialize';
import { Filter, IHasFilters } from '@datagalaxy/webclient/filter/domain';

export class RecentSearchDto extends BaseDTO implements IHasFilters {
    @deserialize public RecentSearchId!: string;
    @deserialize public SpaceUid!: string;
    @deserialize public AccessCount!: number;
    @deserialize public LastAccessTime!: string;
    @deserialize public OwnerUserId!: string;
    @deserialize public ClientId!: string;
    @deserialize public DefaultVersionId!: string;
    @deserializeAs(Filter) public ListFilter!: Filter[];

    public get filters() {
        return this.ListFilter;
    }
}
