import {
    IPathBuilder,
    PathCurve,
    StraightPathBuilderOptions,
} from '../path-builder.types';
import { IXYRO, Point, Vect2 } from '@datagalaxy/core-2d-util';

export class StraightPathBuilder implements IPathBuilder {
    public computePathD(
        points: IXYRO[],
        options: StraightPathBuilderOptions
    ): string {
        if (points?.length < 2) {
            return '';
        }

        switch (options.curve) {
            case PathCurve.Bezier:
                return this.generateCubicBezierPath(points, 1.5, options);
            case PathCurve.None:
            default:
                return this.generateStraightPath(points);
        }
    }

    private generateStraightPath(points: Point[]) {
        const start = points[0];
        const end = points[points.length - 1];
        return `M${start.x} ${start.y} L${end.x} ${end.y}`;
    }

    private generateCubicBezierPath(
        points: Point[],
        controlFactor: number = 1 / 3,
        options: StraightPathBuilderOptions
    ): string {
        const path = points.slice(0, 4);
        if (path.length !== 4) {
            throw new Error(
                'Exactly 4 points are required for cubic Bezier path generation.'
            );
        }

        // Calculate control points
        const control1: Point = new Vect2(
            path[0].x + (path[1].x - path[0].x) * controlFactor,
            path[0].y + (path[1].y - path[0].y) * controlFactor
        ).truncateDecimals(true);

        const control2: Point = new Vect2(
            path[3].x - (path[3].x - path[2].x) * controlFactor,
            path[3].y - (path[3].y - path[2].y) * controlFactor
        ).truncateDecimals(true);

        if (options.arrowPosition) {
            const index = options.inverted ? 0 : 3;
            path[index] = options.arrowPosition;
        }

        // Build the SVG path string
        return `M${path[0].x} ${path[0].y} C${control1.x} ${control1.y}, ${control2.x} ${control2.y}, ${path[3].x} ${path[3].y}`;
    }
}
