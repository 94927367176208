<input
    matInput
    [ngClass]="params?.inputClass"
    [readonly]="params?.readOnly"
    [(ngModel)]="text"
    (ngModelChange)="onChanged()"
    [placeholder]="placeholderText"
    [matTooltip]="tooltipText"
    (keydown.enter)="onEnterKey()"
/>
