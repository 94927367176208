export const denodoFieldInfo = {
    server: {
        hint: {
            fr: 'Nom ou adresse du serveur Denodo',
            en: 'Denodo server address or name',
        },
    },
    port: {
        hint: {
            fr: 'Port du server vitual dataport Denodo',
            en: 'Denodo virtual dataport server port',
        },
    },
    database: {
        hint: {
            fr: 'Nom de la base de données Denodo',
            en: 'Denodo database name',
        },
    },
    user: {
        hint: {
            fr: "Nom d'utilisateur Denodo",
            en: 'Denodo user name',
        },
    },
};
