import { ComponentType } from '@angular/cdk/portal';
import { FunnelAction } from './funnel-action';
import { Observable } from 'rxjs';

export interface FunnelStep {
    title: string;
    componentType: ComponentType<FunnelStepComponent>;
    stepperVisible?: boolean;
}

export abstract class FunnelStepComponent {
    abstract actions: FunnelAction[];
}
export interface ValidatedFunnelStepComponent {
    valid$: Observable<boolean>;
}
