import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import './campaign-events/campaign-notification.events';
import { TypedEventTranslationFactory } from '../dg-event-translation/typed-event-translation-factory';
import { getLocalId } from '@datagalaxy/webclient/utils';
import {
    CampaignApiService,
    CampaignBriefDto,
    CampaignDto,
} from '@datagalaxy/webclient/campaign/data-access';
import {
    deserializeTypedEventArray,
    ITypedEvent,
} from '@datagalaxy/dg-object-model';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class CampaignService extends BaseService {
    constructor(
        private campaignApiService: CampaignApiService,
        private typedEventTranslationFactory: TypedEventTranslationFactory
    ) {
        super();
    }

    public async getCampaigns(
        spaceIdr: ISpaceIdentifier
    ): Promise<CampaignBriefDto[]> {
        const spaceGuid = getLocalId(spaceIdr?.spaceId);
        const res = await this.campaignApiService.getCampaigns(
            spaceGuid,
            spaceIdr.versionId
        );
        return res.Campaigns;
    }

    /**
     * Create a campaign
     * @return string corresponding to the created campaign's Guid
     */
    public async createCampaign(
        spaceIdr: ISpaceIdentifier,
        name: string,
        description: string,
        workflowId: string,
        ownerId: string,
        referenceIds: string[]
    ): Promise<string> {
        const param = {
            Name: name,
            Description: description,
            WorkflowGuid: workflowId,
            OwnerGuid: ownerId,
            SpaceGuid: getLocalId(spaceIdr?.spaceId),
            ReferenceIds: referenceIds,
            VersionId: spaceIdr.versionId,
        };

        const res = await this.campaignApiService.createCampaign(param);
        return res?.Guid;
    }

    public async getCampaign(campaignId: string): Promise<CampaignDto> {
        const res = await this.campaignApiService.getCampaign(campaignId);
        return res?.Campaign;
    }

    public async addCampaignEntities(
        campaignGuid: string,
        versionId: string,
        entityIds: string[]
    ): Promise<CampaignDto> {
        const param = {
            Guid: campaignGuid,
            VersionId: versionId,
            ReferenceIds: entityIds,
        };
        const res = await this.campaignApiService.addCampaignEntities(param);
        return res?.Campaign;
    }

    public async removeCampaignEntities(
        campaignGuid: string,
        entityIds: string[]
    ): Promise<CampaignDto> {
        const param = {
            Guid: campaignGuid,
            ReferenceIds: entityIds,
        };
        const res = await this.campaignApiService.removeCampaignEntities(param);
        return res?.Campaign;
    }

    public async setCampaignPhase(
        campaignGuid: string,
        phaseGuid: string
    ): Promise<CampaignDto> {
        const res = await this.campaignApiService.setCampaignPhase(
            campaignGuid,
            phaseGuid
        );
        return res?.Campaign;
    }

    public async deleteCampaign(campaignGuid: string): Promise<void> {
        await this.campaignApiService.deleteCampaign(campaignGuid);
    }

    public async getCampaignEvents(campaignGuid: string) {
        const res = await this.campaignApiService
            .getCampaignEvents(campaignGuid)
            .then((res) => {
                res.CampaignEvents = deserializeTypedEventArray(
                    res.CampaignEvents
                ) as ITypedEvent[];
                return res;
            });

        return await Promise.all(
            res?.CampaignEvents.map((e) =>
                this.typedEventTranslationFactory.translateEvent(e)
            )
        );
    }

    public async updateCampaign(
        campaignGuid: string,
        name: string,
        description: string,
        ownerGuid: string
    ): Promise<void> {
        const param = {
            CampaignGuid: campaignGuid,
            Name: name,
            Description: description,
            OwnerGuid: ownerGuid,
        };

        return await this.campaignApiService.updateCampaign(param);
    }

    public async campaignExistsWithName(
        spaceId: ISpaceIdentifier,
        name: string
    ) {
        const spaceGuid = getLocalId(spaceId.spaceId);
        return await this.campaignApiService.campaignExistsWithName(
            spaceGuid,
            name
        );
    }

    public async getCampaignsFromObject(
        referenceId: string,
        versionId: string
    ) {
        const result = await this.campaignApiService.getCampaignsFromObject(
            referenceId,
            versionId
        );
        return result.Campaigns;
    }
}
