import { deserialize, deserializeAs, SerializableEnumeration } from 'cerialize';

export enum UsageTypeName {
    WorkflowTeamUsage,
    WorkflowAttributeUsage,
    CampaignTeamUsage,
    QuickFilterAttributeUsage,
    WorkflowAttributeValueUsage,
    CampaignAttributeValueUsage,
}

SerializableEnumeration(UsageTypeName);
export class ItemUsage {
    @deserializeAs(UsageTypeName) UsageTypeName!: UsageTypeName;
    @deserialize DisplayName!: string;
    @deserialize AllowsDeletion!: boolean;
}
