import {
    ElementRef,
    Inject,
    Injectable,
    NgZone,
    Optional,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import {
    LegacyTooltipPosition as TooltipPosition,
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY as MAT_TOOLTIP_SCROLL_STRATEGY,
    MatLegacyTooltip as MatTooltip,
    MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';
import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategyOrigin,
    Overlay,
    OverlayPositionBuilder,
    OverlayRef,
    ScrollDispatcher,
} from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import {
    RichTooltipPosition,
    RichTooltipPositionOptions,
} from './rich-tooltip';
import { TemplatePortal } from '@angular/cdk/portal';
import { DomUtils } from '@datagalaxy/utils';

/**
 * ## Role
 * - Add/remove material tooltip on native Element
 * - Show/hide a template content at a given position (for DxyRichTooltipDirective & DxyRichTooltipContent)
 */
@Injectable({ providedIn: 'root' })
export class DxyTooltipService {
    private readonly tooltipsMap = new Map<Element, IMatTooltipItem>();

    constructor(
        private overlay: Overlay,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private platform: Platform,
        private ariaDescriber: AriaDescriber,
        private focusMonitor: FocusMonitor,
        @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) private scrollStrategy: any,
        @Optional() private dir: Directionality,
        @Optional()
        @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
        private defaultOptions: MatTooltipDefaultOptions,
        @Inject(DOCUMENT) private document: Document,
        private overlayPositionBuilder: OverlayPositionBuilder
    ) {}

    //#region MatTooltip

    public setTooltip(
        el: Element,
        message: string,
        position?: TooltipPosition,
        delay?: number
    ) {
        const tt = this.createTooltip(el, message, position, delay);

        this.tooltipsMap.set(el, {
            tooltip: tt,
            removeEventFunctions: [
                DomUtils.addListener(el, 'mouseover', () => {
                    tt.show();
                }),
                DomUtils.addListener(el, 'mouseleave', () => {
                    tt.hide();
                }),
            ],
        });
    }

    public removeTooltip(el: Element) {
        if (!this.tooltipsMap.has(el)) {
            return;
        }

        const tooltipItem = this.tooltipsMap.get(el);
        if (!tooltipItem) {
            return;
        }
        tooltipItem.removeEventFunctions.forEach((removeEvent) =>
            removeEvent()
        );
        tooltipItem.tooltip.ngOnDestroy();
    }

    public removeTooltips(els: Element[]) {
        els?.forEach((el) => this.removeTooltip(el));
    }

    private createTooltip(
        el: Element,
        message: string,
        position?: TooltipPosition,
        delay?: number
    ) {
        const elementRef = new ElementRef<HTMLElement>(el as HTMLElement);
        const options = this.defaultOptions;

        if (position) {
            options.position = position;
        }
        if (delay) {
            options.hideDelay = options.showDelay = delay;
        }

        const tooltip = new MatTooltip(
            this.overlay,
            elementRef,
            this.scrollDispatcher,
            null as unknown as ViewContainerRef,
            this.ngZone,
            this.platform,
            this.ariaDescriber,
            this.focusMonitor,
            this.scrollStrategy,
            this.dir,
            this.defaultOptions,
            this.document
        );
        tooltip.message = message;
        return tooltip;
    }

    //#endregion

    //#region DxyRichTooltipDirective & DxyRichTooltipContent

    public getOverlayRef(
        target: FlexibleConnectedPositionStrategyOrigin,
        position: RichTooltipPosition,
        options?: RichTooltipPositionOptions
    ) {
        const positionStrategy = this.getPositionStrategy(
            target,
            position,
            options
        );
        return this.overlay.create({ positionStrategy });
    }
    public showOverlayRef(
        overlayRef: OverlayRef,
        templateRef: TemplateRef<any>,
        viewContainerRef: ViewContainerRef
    ) {
        if (overlayRef.hasAttached()) {
            this.hideOverlayRef(overlayRef);
        }
        const tooltipPortal = new TemplatePortal(templateRef, viewContainerRef);
        overlayRef?.attach(tooltipPortal);
    }
    public hideOverlayRef(overlayRef: OverlayRef) {
        overlayRef?.detach();
    }

    private getPositionStrategy(
        element: FlexibleConnectedPositionStrategyOrigin,
        position: RichTooltipPosition,
        options?: RichTooltipPositionOptions
    ) {
        return this.overlayPositionBuilder
            .flexibleConnectedTo(element)
            .withPositions([this.getConnectedPosition(position, options)]);
    }

    private getConnectedPosition(
        position: RichTooltipPosition,
        options: RichTooltipPositionOptions = {}
    ): ConnectedPosition {
        switch (position) {
            default:
            case 'above':
                return {
                    ...options,
                    originX: 'center',
                    overlayX: 'center',
                    originY: 'top',
                    overlayY: 'bottom',
                };
            case 'right':
                return {
                    ...options,
                    originX: 'end',
                    overlayX: 'start',
                    originY: 'center',
                    overlayY: 'center',
                };
            case 'below':
                return {
                    ...options,
                    originX: 'center',
                    overlayX: 'center',
                    originY: 'bottom',
                    overlayY: 'top',
                };
            case 'left':
                return {
                    ...options,
                    originX: 'start',
                    overlayX: 'end',
                    originY: 'center',
                    overlayY: 'center',
                };
            case 'top-left':
                return {
                    ...options,
                    originX: 'start',
                    overlayX: 'start',
                    originY: 'top',
                    overlayY: 'bottom',
                };
            case 'over':
                return {
                    ...options,
                    originX: 'center',
                    overlayX: 'center',
                    originY: 'center',
                    overlayY: 'center',
                };
        }
    }

    //#endregion
}

interface IMatTooltipItem {
    tooltip: MatTooltip;
    removeEventFunctions: (() => void)[];
}
