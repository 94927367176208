import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { HierarchicalData, ObjectLinkType } from '@datagalaxy/dg-object-model';
import {
    SuggestionElement,
    SuggestionType,
} from '@datagalaxy/webclient/suggestion/types';

export interface LinkSuggestionDto {
    Id: string;
    Guid: string;
    VersionId: string;
    Score: number;
    SourceGuid: string;
    TargetGuid: string;
    LinkType: ObjectLinkType;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateEntityAttributeFromSuggestionParameter extends BaseServiceParameter {
    @serialize public DataReferenceId?: string;
    @serialize public IsSuggestionAccepted?: boolean;
    @serialize public AttributeKey?: string;
    @serializeAs(SuggestionElement)
    public SuggestionElements?: SuggestionElement[];
}

export class SuggestionAttributeFilter {
    @serialize public AttributePath?: string;
    @serialize public Values?: string[];
}

@inheritSerialization(BaseServiceParameter)
export class GetUserSuggestionsParameter extends BaseServiceParameter {
    @serialize public Size?: number;
    @serialize public SuggestionHashCodes?: string[];
    @serialize public SpaceId?: string;
    @serializeAs(SuggestionType) public SuggestionType?: SuggestionType;
    @serializeAs(SuggestionAttributeFilter)
    public SuggestionAttributeFilter?: SuggestionAttributeFilter;
}

@inheritSerialization(BaseServiceResult)
export class GetUserSuggestionsResult extends BaseServiceResult {
    @deserializeAs(HierarchicalData) public DataList!: HierarchicalData[];
    @deserializeAs(SuggestionElement)
    public SuggestionElements!: SuggestionElement[];
}

@inheritSerialization(BaseServiceResult)
export class UserHasSuggestionsResult extends BaseServiceResult {
    @deserialize HasSuggestions!: boolean;
}

export class ViewedSuggestion {
    @serializeAs(SuggestionType) public SuggestionType?: SuggestionType;
    @serialize public SuggestionHashcode?: string;

    static fromSuggestionElement(element: SuggestionElement) {
        return new ViewedSuggestion(element.SuggestionType, element.Hashcode);
    }

    constructor(suggestionType?: SuggestionType, suggestionHashcode?: string) {
        this.SuggestionType = suggestionType;
        this.SuggestionHashcode = suggestionHashcode;
    }
}

@inheritSerialization(BaseServiceParameter)
export class LogViewedSuggestionsParameter extends BaseServiceParameter {
    @serializeAs(ViewedSuggestion)
    public ViewedSuggestions!: ViewedSuggestion[];
}

export class GetEntitySuggestionsQueryResult {
    @deserializeAs(HierarchicalData) HierarchicalData!: HierarchicalData[];
    @deserialize LinkSuggestions!: LinkSuggestionDto[];
}

export interface ApplySuggestionUserChoiceCommand {
    Guids: string[];
    IsAccepted: boolean;
}

export interface GenerateSuggestionLinkCommand {
    Guids: string[];
}

export interface GetLinksSuggestionsCountQueryResult {
    Count: number;
}
