import {
    DragHandle,
    IXYRO,
    Rect,
    RectSidePoint,
    Vect2,
} from '@datagalaxy/core-2d-util';
import { Connector } from '../../connector';

/** Draggable connection endpoint element, to:
 * - deconnect or change a connection between connected nodes
 * - move an endpoint around a connected node
 * */
export class EndpointHandle<T> extends DragHandle {
    static readonly defaultSize = 10;

    /** true when the side has been specified or set by the client */
    public get isClientSide() {
        return this.rsp?.isClientSide;
    }
    /** true when the side has been fixed by the system */
    public get isSideAutoFixed() {
        return this.rsp?.isSideAutoFixed;
    }
    /** true when the side is fixed (either specified by the client, or after moving a segment) */
    public get isSideFixed() {
        return this.rsp?.isSideFixed;
    }

    public get rsp() {
        return this.ep instanceof RectSidePoint ? this.ep : null;
    }
    private get ep() {
        return this.isSrc ? this.c.src : this.c.tgt;
    }

    constructor(
        public readonly c: Connector<T>,
        public readonly isSrc: boolean,
        size = EndpointHandle.defaultSize
    ) {
        super();
        this.setSize(size, size);
    }

    protected override afterInit() {
        this.updateSideClasses();
    }

    public update() {
        const {
            node: { rect },
            side,
            distance,
        } = this.ep;
        Rect.sidePoint(
            rect,
            Rect.rectSide(side),
            this,
            distance
        ).makeRelativeTo(rect);
        return super.update();
    }

    /** Unmarks the side as auto-fixed, so it can be computed dynamically if not set by the client */
    public unfixSide(makeAutoSide?: boolean) {
        this.rsp?.unfixSide(makeAutoSide);
        return this;
    }

    public updateSideClasses() {
        const cl = this.element.classList;
        cl.toggle('client-fixed', this.isClientSide);
        cl.toggle('auto-fixed', this.isSideAutoFixed);
        cl.toggle('fixed', this.isSideFixed);
        return this;
    }

    public moveOnSide(
        p: IXYRO,
        preserveDistance?: boolean,
        noDecimals?: boolean
    ) {
        this.rsp?.move(p, preserveDistance, noDecimals);
    }

    public toggleClass(className: string, force?: boolean) {
        this.element.classList.toggle(className, force);
    }

    public distanceTo(p: IXYRO) {
        const pos = this.rsp?.position;
        return pos && p && Vect2.distance(pos, p);
    }
}
