import {
    DataProcessingType,
    ServerType,
    SoftwareElementType,
} from '@datagalaxy/dg-object-model';
import { ConnectorModule } from '@datagalaxy/webclient/connectivity/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export class ImportEntityTarget {
    public newEntityName: string;
    public isUpdate = false;
    public module: ConnectorModule;
    public serverType: ServerType;
    public subTypeName: string;
    public availableEntities: EntityItem[];
    public selectedEntityId: string;

    public get isValid() {
        return this.isUpdate ? !!this.selectedEntityId : !!this.newEntityName;
    }

    public get isCatalogModule() {
        return this.module == ConnectorModule.CATALOG;
    }

    public get isUsageModule() {
        return this.module == ConnectorModule.USAGE;
    }

    public get isProcessingModule() {
        return this.module == ConnectorModule.PROCESSING;
    }

    constructor(module: ConnectorModule, sourceTypeName: string) {
        this.module = module;

        this.serverType = this.isCatalogModule
            ? ServerType.Model
            : module == ConnectorModule.USAGE
            ? ServerType.SoftwareElement
            : ServerType.DataProcessing;

        this.initSubTypeName(sourceTypeName);
    }

    private initSubTypeName(sourceTypeName: string) {
        this.subTypeName = this.isCatalogModule
            ? sourceTypeName
            : this.isUsageModule
            ? SoftwareElementType[SoftwareElementType.Application]
            : DataProcessingType[DataProcessingType.DataFlow];
    }
}
