import { deserializeAs, inheritSerialization } from 'cerialize';
import { UserInsightItem } from './user-insights';
import { EntityInsightItem } from './entity-insight-item';
import { BaseServiceResult } from '@datagalaxy/data-access';

@inheritSerialization(BaseServiceResult)
export class GetInsightsResult extends BaseServiceResult {
    @deserializeAs(UserInsightItem) public UserItems?: UserInsightItem[];
    @deserializeAs(EntityInsightItem) public EntityItems?: EntityInsightItem[];

    propagateEntityVersionId() {
        if (this.EntityItems) {
            this.EntityItems.forEach((c) =>
                c.HddData?.setVersionId(c.HddData.VersionId)
            );
        }
    }
}
