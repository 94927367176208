import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { ForeignKey } from './foreign-key';
import { Ref } from '../ref';

@inheritSerialization(Ref)
export class ForeignKeyRef extends Ref<ForeignKey> {
    static fromIds(ids: string[]) {
        return ids?.map(ForeignKeyRef.fromId) ?? [];
    }

    static fromId(id: string) {
        return new ForeignKeyRef(id);
    }

    constructor(id?: string) {
        super(ServerType.ForeignKey, id);
    }
}
