import { Subject } from 'rxjs';
import { CoreEventsService } from '@datagalaxy/core-ui';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { DgZone } from '@datagalaxy/webclient/domain';

/** ## Role
 * Provides filter bar state infos: visibility, filters count; and actions: show, hide
 */
@Injectable({ providedIn: 'root' })
export class FilterBarService extends BaseService {
    public get showHideFilterBar$() {
        return this.showHideFilterBarChange.asObservable();
    }
    private showHideFilterBarChange = new Subject<{
        dgZone: DgZone;
        isOpen: boolean;
    }>();

    private readonly barInfos = new Map<DgZone, FilterBarInfo>();

    public constructor(private coreEventsService: CoreEventsService) {
        super();
        [DgZone.Search, DgZone.Module].forEach((type) =>
            this.barInfos.set(
                type,
                new FilterBarInfo(type, (t) => this.onFilterBarShowHide(t))
            )
        );
    }

    //#region filter bar management
    public isFilterBarVisible(dgZone: DgZone) {
        return this.getFilterBar(dgZone)?.isVisible ?? false;
    }
    public showFilterBar(dgZone: DgZone) {
        this.getFilterBar(dgZone)?.showHide(true);
    }
    public hideFilterBar(dgZone: DgZone) {
        this.getFilterBar(dgZone)?.showHide(false);
    }
    public toggleFilterBar(dgZone: DgZone) {
        this.getFilterBar(dgZone)?.toggle();
    }
    public showHideFilterBar(dgZone: DgZone, show: boolean) {
        this.getFilterBar(dgZone)?.showHide(show);
    }
    private getFilterBar(dgZone: DgZone) {
        return this.barInfos.get(dgZone);
    }
    private onFilterBarShowHide(dgZone: DgZone) {
        const isVisible = this.getFilterBar(dgZone)?.isVisible;
        this.log('onFilterBarShowHide', DgZone[dgZone], isVisible);
        this.showHideFilterBarChange.next({ dgZone, isOpen: isVisible });
        this.coreEventsService.emitMainViewResize(100);
    }

    //#endregion
}

class FilterBarInfo {
    public get isVisible() {
        return this._isVisible;
    }
    private _isVisible = false;

    constructor(
        public readonly dgZone: DgZone,
        private readonly onShowHideChanged?: (dgZone: DgZone) => void
    ) {}

    public clear() {
        this.showHide(false);
    }
    public toggle() {
        this.showHide(!this.isVisible);
    }
    public showHide(show: boolean) {
        show = !!show;
        const change = this._isVisible != show;
        this._isVisible = show;
        if (change) {
            this.onShowHideChanged?.(this.dgZone);
        }
    }
}
