import { IDgEventNavLink } from '../../../interfaces';
import {
    HierarchyDataDescriptor,
    ITypedEvent,
} from '@datagalaxy/dg-object-model';
import {
    getHddNavLink,
    getEntityNavLink,
} from './dg-event-translation-nav-links.helpers';

export class DgEventTranslationAdapterBase {
    constructor(private typedEvent: ITypedEvent) {}

    public getHddNavLink(hdd: HierarchyDataDescriptor, linkCssClass: string) {
        return getHddNavLink({
            event: this.typedEvent,
            hdd,
            linkCssClass,
        });
    }

    public getEntityNavLink(hdd: HierarchyDataDescriptor): IDgEventNavLink {
        return getEntityNavLink({
            event: this.typedEvent,
            hdd,
        });
    }
}
