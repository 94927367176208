import { Subscription } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { CurrentSpaceService } from './currentSpace.service';
import { Injectable } from '@angular/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { AppDataService } from './app-data.service';
import { VersioningService } from '../versioning/services/versioning.service';
import { DataIdentifier } from '@datagalaxy/dg-object-model';
import { SpaceApiService } from '../space/services/space-api.service';
import { NavigationEventService } from '../navigation/services/navigation-event.service';
import { UiSpaceNameInfo } from '../space/space.types';
import { SecurityService } from './security.service';
import { NavigationApiService } from '../navigation/services/navigation-api.service';
import { NotificationMessage } from '../shared/util/server-types/notification.api';
import {
    NavProject,
    NavSpace,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { ABaseSecurityData } from '@datagalaxy/webclient/security/domain';

@Injectable({ providedIn: 'root' })
export class AppSpaceService extends BaseService {
    private static REMEMBERED_SPACE_STORAGEKEY = 'remembered_spaceIdr';

    constructor(
        private spaceApiService: SpaceApiService,
        private navigationApiService: NavigationApiService,
        private versioningService: VersioningService,
        private securityService: SecurityService,
        private navigationEventService: NavigationEventService,
        private currentSpaceService: CurrentSpaceService,
        private appDataService: AppDataService
    ) {
        super();
    }

    //#region current space

    /** Clears the currentSpace of currentSpaceService and calls notifyChangeCurrentSpace if necessary.
     * Should not be called by components. Only by routing.
     *
     * noLastSpace:
     * - if false: lastSpace is set to current currentSpace if defined
     * - if true: lastSpace is cleared
     * @deprecated Replaced by a full page refresh when navigating to a new space
     */
    public clearCurrentSpace(noLastSpace = false, dontNotify = false) {
        const change = this.currentSpaceService.hasCurrentSpace();
        if (change) {
            this.spaceApiService.clearSpaceCache(
                this.currentSpaceService.currentSpace
            );
        }
        this.currentSpaceService._clearCurrentSpace(noLastSpace);
        if (change && !dontNotify) {
            this.navigationEventService.notifyChangeCurrentSpace(null);
        }
    }

    /** Should not be called by components. Only by routing */
    public async setCurrentSpace(
        spaceIdr: ISpaceIdentifier,
        allowNoSpace = false
    ) {
        const space = await this.getSpace(spaceIdr);
        if (!space && !allowNoSpace) {
            return;
        }
        const change = !SpaceIdentifier.areSame(
            space,
            this.currentSpaceService.getCurrentSpace()
        );
        this.currentSpaceService._setCurrentSpace(space);
        if (change) {
            this.navigationEventService.notifyChangeCurrentSpace(space);
        }
        return space;
    }

    public onCurrentSpaceDeleted() {
        this.clearCurrentSpace(true);
    }

    //#region get current, last or default

    public async getASpaceIdentifier(opt?: IGetASpaceOptions) {
        const currentOrLast = this.getCurrentLastOrNone(opt);
        let spaceIdr: ISpaceIdentifier;
        if (currentOrLast) {
            spaceIdr = currentOrLast;
        } else {
            spaceIdr = await this.navigationApiService.getUserDefaultNavSpace(
                opt?.includeNonUserDefault
            );
        }
        spaceIdr = SpaceIdentifier.from(spaceIdr);
        this.log('getASpaceIdentifier', opt, spaceIdr);
        return spaceIdr;
    }

    public async getASpace(opt?: IGetASpaceOptions): Promise<Space> {
        const currentOrLast = this.getCurrentLastOrNone(opt);
        let space = currentOrLast;
        if (!space) {
            const navSpace =
                await this.navigationApiService.getUserDefaultNavSpace(
                    opt?.includeNonUserDefault
                );
            space = await this.spaceApiService.getSpace(navSpace);
        }
        this.log('getASpace', opt, space);
        return space;
    }

    private getCurrentLastOrNone(opt?: {
        includeCurrent?: boolean;
        includeLast?: boolean;
    }) {
        return opt?.includeCurrent
            ? opt?.includeLast
                ? this.currentSpaceService.getCurrentOrLastSpace()
                : this.currentSpaceService.getCurrentSpace()
            : undefined;
    }

    /**
     * Restore retained space (if any, from localStorage)
     * (Meant to be invoked after navigating to a non-workspace page)
     */
    public async loadRememberedSpace() {
        if (this.currentSpaceService.currentSpace) return;
        const loaded = JSON.parse(
            window.localStorage.getItem(
                AppSpaceService.REMEMBERED_SPACE_STORAGEKEY
            ) || 'null'
        ) as ISpaceIdentifier;
        if (loaded) {
            await this.setCurrentSpace(loaded);
        }
    }

    /**
     * Store current-space spaceIdentifier in localStorage
     * (Obviously, to be invoked only after navigating to a space related page)
     */
    public rememberSpace() {
        const { spaceId, versionId } =
            this.currentSpaceService.currentSpace || {};
        if (spaceId) {
            window.localStorage.setItem(
                AppSpaceService.REMEMBERED_SPACE_STORAGEKEY,
                JSON.stringify({ spaceId, versionId })
            );
        }
    }

    //#endregion

    //#endregion

    public isSpaceVersioningEnabled() {
        return this.securityService.isClientVersioningEnabled();
    }

    //#region single workspace

    public isSingleWorkspace() {
        return this.securityService.isSingleWorkspaceClient();
    }
    public async getSingleWorkSpaceIdentifier() {
        const spaceIdr = await this.navigationApiService.getSingleWorkspace();
        return SpaceIdentifier.from(spaceIdr);
    }
    public async getSingleWorkSpace() {
        const spaceIdr = await this.getSingleWorkSpaceIdentifier();
        return await this.getSpace(spaceIdr);
    }

    //#endregion

    public async getCurrentSpaceSetCurrentIfSingle(): Promise<Space> {
        if (this.isSingleWorkspace()) {
            const single = await this.getSingleWorkSpace();
            this.setCurrentSpace(single);
            return single;
        }
        return this.currentSpaceService.getCurrentSpace();
    }

    public async getSpace(spaceIdr: ISpaceIdentifier): Promise<Space> {
        if (!spaceIdr?.spaceId) {
            return null;
        }

        if (this.currentSpaceService.isCurrentSpaceAndVersion(spaceIdr)) {
            return this.currentSpaceService.getCurrentSpace();
        }

        return await this.spaceApiService.getSpace(spaceIdr);
    }

    public async getSpaceVersionDisplayName(spaceIdr: ISpaceIdentifier) {
        const space = await this.getSpace(spaceIdr);
        if (!spaceIdr || !space || space.spaceId != spaceIdr.spaceId) {
            this.log('getSpaceVersionDisplayName', 'no space found');
            return;
        }
        if (space instanceof Project && space.IsVersioningEnabled) {
            const pv = await this.versioningService.getProjectVersion(
                space.spaceId,
                spaceIdr.versionId
            );
            this.log(
                'getSpaceVersionDisplayName-getProjectVersion',
                spaceIdr,
                pv
            );
            return (
                pv && new UiSpaceNameInfo(pv, pv.ProjectName, pv.VersionName)
            );
        }
        this.log('getSpaceVersionDisplayName', spaceIdr, space);
        return new UiSpaceNameInfo(space, space.DisplayName);
    }

    public subscribe(options: {
        onCreate?: (navSPace: NavSpace) => void;
        onUpdate?: (navSPace: NavSpace) => void;
        onDelete?: (dataIdentifier: DataIdentifier) => void;
        onSecurityRightsChange?: (navSPace: NotificationMessage) => void;
        onChangeCurrent?: (navSPace: Space) => void;
        onUserFavoriteChanged?: (navSpace: NavSpace) => void;
    }) {
        const subscription = new Subscription();
        options?.onCreate &&
            subscription.add(
                this.navigationEventService._createSpace$.subscribe(
                    options.onCreate
                )
            );
        options?.onUpdate &&
            subscription.add(
                this.navigationEventService._updateSpace$.subscribe(
                    options.onUpdate
                )
            );
        options?.onDelete &&
            subscription.add(
                this.navigationEventService._deleteSpace$.subscribe(
                    options.onDelete
                )
            );
        options?.onSecurityRightsChange &&
            subscription.add(
                this.navigationEventService._securityRightsChange$.subscribe(
                    options.onSecurityRightsChange
                )
            );
        options?.onChangeCurrent &&
            subscription.add(
                this.navigationEventService._changeCurrentSpace$.subscribe(
                    options.onChangeCurrent
                )
            );
        options?.onUserFavoriteChanged &&
            subscription.add(
                this.navigationEventService._userFavoriteSpaceChanged$.subscribe(
                    options.onUserFavoriteChanged
                )
            );
        return subscription;
    }

    public async getNavSpacesSorted(opt?: {
        projectsOnly?: boolean;
        onlyImportable?: boolean;
        onlyImportableCatalog?: boolean;
    }) {
        const spacesResult = await this.navigationApiService.getNavSpaces();
        const orderedNavSpaces = opt?.projectsOnly
            ? CollectionsHelper.orderByText(
                  spacesResult.Projects,
                  (ns) => ns.DisplayName
              )
            : spacesResult.allSpaces(); // already sorted with organizations first

        // Set Default Space
        const defaultSpaceId = this.appDataService.getDefaultSpaceReferenceId();
        if (defaultSpaceId) {
            CollectionsHelper.withFirstFound(
                orderedNavSpaces,
                (ns) => ns.ReferenceId == defaultSpaceId,
                (ns) => (ns.IsDefaultSpace = true)
            );
        }

        const filter = opt?.onlyImportableCatalog
            ? (navProject: NavProject) => navProject.CanImportEntitiesOnCatalog
            : opt?.onlyImportable
            ? (navSpace: NavSpace) => navSpace.CanImportEntities
            : undefined;

        return filter ? orderedNavSpaces.filter(filter) : orderedNavSpaces;
    }

    public async getDefaultSpaceOrFirstAvailable() {
        const spacesResult = await this.navigationApiService.getNavSpaces();
        const orderedNavSpaces = CollectionsHelper.orderByText(
            spacesResult.Projects,
            (ns) => ns.DisplayName
        );
        const defaultSpaceId = this.appDataService.getDefaultSpaceReferenceId();
        const defaultSpace = orderedNavSpaces.find(
            (ns) => ns.ReferenceId == defaultSpaceId
        );
        if (defaultSpace) return defaultSpace;
        return orderedNavSpaces[0];
    }

    public hasUserDefaultSpace() {
        return !this.appDataService.defaultSpaceUid;
    }

    public async updateDefaultSpace(
        spaceIdr: ISpaceIdentifier,
        isDefault: boolean,
        navSpaces?: NavSpace[]
    ) {
        if (!spaceIdr?.spaceId) {
            return;
        }

        isDefault = !!isDefault;
        await this.spaceApiService.updateDefaultSpace(spaceIdr, isDefault);
        const spaceId = spaceIdr.spaceId;
        this.appDataService.setDefaultSpaceByReferenceId(spaceId);
        navSpaces?.forEach((ns) => {
            if (ns.spaceId == spaceId) {
                if (ns.IsDefaultSpace != isDefault) {
                    ns.IsDefaultSpace = isDefault;
                    this.navigationEventService.notifyFavoriteSpaceChangedEvent(
                        CoreUtil.clone(ns)
                    );
                }
            } else {
                ns.IsDefaultSpace = false;
            }
        });
    }

    public async hasReadAccess(spaceIdr: ISpaceIdentifier) {
        const space = await this.getSpace(spaceIdr);
        return (space?.SecurityData as ABaseSecurityData)?.HasReadAccess;
    }
}

export interface IGetASpaceOptions {
    /** Includes the current space as a candidate, prior to the user's favorite.
     * - Has no effect when not in a module. */
    includeCurrent?: boolean;
    /** Includes the previous current space as a candidate, prior to the user's favorite.
     * - Has no effect when outside of a module, or if includeCurrent is false. */
    includeLast?: boolean;
    /**  Includes the following as candidates:
     * - first Project having IsDefaultSpace set,
     * - first Organization having IsDefaultSpace set,
     * - first Project,
     * - first Organization */
    includeNonUserDefault?: boolean;
}
