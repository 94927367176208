import { Component } from '@angular/core';
import { DxyAttributeTextInputComponent } from '../dxy-attribute-text-input/dxy-attribute-text-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { DxyUnitaryFieldActionsComponent } from '../../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-attribute-multiline-text-input',
    templateUrl: './dxy-attribute-multiline-text-input.component.html',
    standalone: true,
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
        TranslateModule,
    ],
})
export class DxyAttributeMultilineTextInputComponent extends DxyAttributeTextInputComponent {}
