import { Component } from '@angular/core';
import { MultilingualReadMoreButtonDirective } from '../multilingual-read-more-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { AlertMessageComponent } from '@datagalaxy/ui/forms';

@Component({
    selector: 'dxy-multilingual-unavailability',
    templateUrl: './multilingual-unavailability.component.html',
    styleUrls: ['./multilingual-unavailability.component.scss'],
    standalone: true,
    imports: [
        AlertMessageComponent,
        DxyButtonDirective,
        TranslateModule,
        MultilingualReadMoreButtonDirective,
    ],
})
export class MultilingualUnavailabilityComponent {}
