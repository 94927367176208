import {
    CommentaryCreatedEvent,
    CommentaryUpdatedEvent,
} from '@datagalaxy/dg-object-model';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [CommentaryCreatedEvent, CommentaryUpdatedEvent];
type TCommentaryEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to get commentaries events translation inputs
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class CommentaryEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TCommentaryEvent) {
        super(event);
    }

    getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);
        const hdd = this.getCommentaryEntityHdd(this.event);
        inputs.translateParameters.push({
            key: 'entityName',
            value: hdd?.DisplayName,
        });
        if (hdd) {
            inputs.navLinks.push(this.getEntityNavLink(hdd));
        }
        return inputs;
    }

    private getCommentaryEntityHdd(event: TCommentaryEvent) {
        const parents = event.Commentary?.HddData?.Parents ?? [];
        return parents[1] ?? parents[0] ?? undefined;
    }
}
