import { FieldType } from '../../types/enums/field-type.enum';
import { AzureAuthMode } from '../../types/enums/azure-auth-mode.enum';

export const azureSynapseConfig = [
    {
        payloadField: 'server',
        formField: 'server',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'port',
        formField: 'port',
        payloadValue: '1433',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'database',
        formField: 'database',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'authentication-mode',
        formField: 'azureAuthMode',
        formMandatory: false,
        type: FieldType.select,
        payloadValue: AzureAuthMode.SQL,
        selectValues: [
            AzureAuthMode.SQL,
            AzureAuthMode.AAD_PASSWORD,
            AzureAuthMode.SERVICE_PRINCIPAL,
        ],
        translate: true,
        getDisplayValueTradKey: (value: AzureAuthMode) => value,
        dependencies: [
            {
                field: {
                    payloadField: 'user',
                    formField: 'user',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: AzureAuthMode) =>
                    value === AzureAuthMode.SQL ||
                    value === AzureAuthMode.AAD_PASSWORD,
            },
            {
                field: {
                    payloadField: 'password',
                    formField: 'password',
                    isPassword: true,
                    formMandatory: true,
                },
                show: (value: AzureAuthMode) =>
                    value === AzureAuthMode.SQL ||
                    value === AzureAuthMode.AAD_PASSWORD,
            },
            {
                field: {
                    payloadField: 'tenant-id',
                    formField: 'tenantId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: AzureAuthMode) =>
                    value === AzureAuthMode.SERVICE_PRINCIPAL,
            },
            {
                field: {
                    payloadField: 'client-id',
                    formField: 'clientId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: AzureAuthMode) =>
                    value === AzureAuthMode.SERVICE_PRINCIPAL,
            },
            {
                field: {
                    payloadField: 'client-secret',
                    formField: 'clientSecret',
                    isPassword: true,
                    formMandatory: true,
                },
                show: (value: AzureAuthMode) =>
                    value === AzureAuthMode.SERVICE_PRINCIPAL,
            },
        ],
    },
];
