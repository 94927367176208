import { Component, Input } from '@angular/core';
import { BaseCellComponent } from '../BaseCellComponent';
import { IIconCellData } from './icon-cell.types';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'dxy-icon-cell',
    templateUrl: 'icon-cell.component.html',
    styleUrls: ['icon-cell.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, MatLegacyTooltipModule, EllipsisTooltipDirective],
})
export class DxyIconCellComponent<TEntity> extends BaseCellComponent<
    TEntity,
    IIconCellData
> {
    @Input() glyphClass: string;
    @Input() text: string;
    @Input() iconClass: string;
    @Input() glyphTooltip: string;
    @Input() iconText: string;
    @Input() imgUrl: string;

    protected updateBindings() {
        this.glyphClass = this.value?.glyphClass;
        this.text = this.value?.text;
        this.iconClass = this.value?.iconClass;
        this.glyphTooltip = this.value?.glyphTooltip;
        this.iconText = this.value?.iconText;
        this.imgUrl = this.value?.imgUrl;
    }
}
