import { TasksDeletedEvent } from '@datagalaxy/dg-object-model';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IDgEventNavLink } from '../../../interfaces/IEventTranslationInputs';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

/**
 * ## Role
 * Adapter to get TasksDeletedEvent translation inputs
 */
@registerTypedEventTranslationAdapter([TasksDeletedEvent])
export class TasksDeletedEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TasksDeletedEvent) {
        super(event);
    }

    public getTranslationInputs() {
        const inputs = getUserEventTranslationInputs(this.event);
        const translateParam = inputs.translateParameters;
        translateParam.push({
            key: 'entityName',
            value: this.event.EntityHdd?.DisplayName,
        });
        translateParam.push({
            key: 'taskTitle',
            value: this.event.TaskTitles?.[0],
        });
        inputs.navLinks = this.getNavLinksData();
        return inputs;
    }
    private getNavLinksData(): IDgEventNavLink[] {
        return [this.getEntityNavLink(this.event.EntityHdd)];
    }
}
