import { Inject, Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { BACKEND_API_CONFIG, IApiConfig } from '../types';
import { BaseServiceParameter } from '../backend';
import { ObjectUtils } from '@datagalaxy/utils';

/**
 * ## Role
 * Timestamp each request with the request' start date and the last completed
 * request' start date
 */
@Injectable()
export class RequestTimeInterceptor implements HttpInterceptor {
    private previousRequestStartTimeString: string | undefined;

    constructor(@Inject(BACKEND_API_CONFIG) private config: IApiConfig) {}

    intercept(req: HttpRequest<BaseServiceParameter>, next: HttpHandler) {
        const serverTimeString = this.config?.getServerTimeString?.();

        if (!serverTimeString) {
            return next.handle(req);
        }

        const reqClone: HttpRequest<BaseServiceParameter> = req.clone({
            body: ObjectUtils.assign(req.body, {
                RequestStartTimeString: serverTimeString,
                PreviousRequestStartTimeString:
                    this.previousRequestStartTimeString,
            }),
        });

        this.previousRequestStartTimeString =
            reqClone.body?.RequestStartTimeString;

        return next.handle(reqClone);
    }
}
