import { Injectable } from '@angular/core';
import { ConnectivityService } from './connectivity.service';
import { ConnectorStateService } from './connector-state.service';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { UpsertSchedulingParams } from './connector.types';

@Injectable({ providedIn: 'root' })
export class ConnectorSchedulerService {
    constructor(
        private connectivityService: ConnectivityService,
        private connectorStateService: ConnectorStateService,
        private functionalLogService: FunctionalLogService
    ) {}

    public async getScheduling(connectionId: string) {
        return await this.connectivityService.getScheduling(connectionId);
    }

    public async upsertScheduling(
        pluginName: string,
        params: UpsertSchedulingParams
    ): Promise<void> {
        const featureCode = `SCHEDULE_CONNECTION_${pluginName.toUpperCase()}`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.U
        );
        if (!params.hasScheduling) {
            await this.createScheduling(
                params.connectionId,
                params.cron,
                params.suspend,
                params.timezone
            );
        } else {
            await this.updateScheduling(
                params.connectionId,
                params.cron,
                params.suspend,
                params.timezone
            );
        }
        this.connectorStateService.refreshSavedConnections();
    }

    public async createScheduling(
        connectionId: string,
        cron: string,
        suspend?: boolean,
        timezone?: string
    ) {
        return await this.connectivityService.createScheduling(
            connectionId,
            cron,
            suspend,
            timezone
        );
    }

    public async updateScheduling(
        connectionId: string,
        cron?: string,
        suspend?: boolean,
        timezone?: string
    ) {
        return await this.connectivityService.updateScheduling(
            connectionId,
            cron,
            suspend,
            timezone
        );
    }
}
