import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DateTimeUtil, TDateType } from '@datagalaxy/core-util';
import { Moment, MomentInput } from 'moment';
import { DxyAttributeBaseInput } from '../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import { DxyFieldDateComponent } from '@datagalaxy/core-ui/fields';
import { DxyUnitaryFieldActionsComponent } from '../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-attribute-date-input',
    templateUrl: './dxy-attribute-date-input.component.html',
    standalone: true,
    imports: [DxyFieldDateComponent, NgIf, DxyUnitaryFieldActionsComponent],
})
export class DxyAttributeDateInputComponent extends DxyAttributeBaseInput<MomentInput> {
    @Input() public dateType: TDateType = 'string-cdp-date';
    @Input() public withTime: boolean;
    @Input() public minDate: Date | string | Moment;
    @Input() public maxDate: Date | string | Moment;
    @Input() public isClearable: boolean;

    @ViewChild('field') field: DxyFieldDateComponent;

    public get dateValue() {
        return this.getDataValueAsMoment();
    }
    public set dateValue(value: Moment) {
        this.setDataValue(value).then();
    }

    public get minDateValue() {
        return this.getMoment(this.minDate);
    }
    public get maxDateValue() {
        return this.getMoment(this.maxDate);
    }

    public get isClearAvailable() {
        return this.isClearable && !!this.getData();
    }

    constructor(
        elementref: ElementRef<HTMLElement>,
        translate: TranslateService
    ) {
        super(elementref, translate);
    }

    //#region IAttributeBaseInputOverride
    public equals(v1: any, v2: any) {
        return DateTimeUtil.areSame(this.getMoment(v1), this.getMoment(v2));
    }
    //#endregion

    private getMoment(v: MomentInput) {
        return DateTimeUtil.getMomentFromDateType(v, this.dateType);
    }

    private getDataValueAsMoment() {
        const data = this.getData();
        //this.log('getDataValueAsMoment', typeof data, data)
        return this.getMoment(data);
    }

    private async setDataValue(m: Moment) {
        const newValue = DateTimeUtil.fromMomentByDateType(m, this.dateType);
        this.log('setDataValue', newValue, this.dateType);
        await this.setData(newValue);
    }
}
