import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import {
    HierarchicalData,
    HierarchyDataDescriptor,
} from '@datagalaxy/dg-object-model';
import * as moment from 'moment/moment';
import { ActivityLogEntryType } from './activity-log-entry-type';
import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';

@inheritSerialization(HierarchicalData)
export class ActivityLogEntry extends HierarchicalData {
    @deserialize public CommitId!: string;
    @deserialize public UserId!: string;
    @deserialize public UserFirstName!: string;
    @deserialize public UserLastName!: string;
    @deserialize public UserEmail!: string;
    @deserialize public OperationContext!: string;
    @deserialize public CommitString!: string;
    @deserialize public PreviousCommitId!: string;
    @deserialize public LocalOrder!: number;
    @deserialize public Xid!: string;
    @deserialize public LogEntryType!: string;
    @deserialize public CreationTime!: string;
    // public CreationTime: string; // Inherited from ABaseData
    @deserialize public IsTechnical!: boolean;

    @deserialize public PropertyName!: string;

    @deserialize public OldValueRaw!: string;
    @deserialize public NewValueRaw!: string;
    @deserialize public OldValueString!: string;
    @deserialize public NewValueString!: string;

    @deserialize public IsCdp!: boolean;
    /** AttributeMetaType as string */
    @deserialize public AttributeType!: string;

    // Specific EntityLinks fields
    @deserialize public IsEntityLink!: boolean;
    @deserialize public EntityLinkSourceId?: string;
    @deserialize public EntityLinkTargetId?: string;
    @deserialize public EntityLinkTypeValue?: number;

    @deserializeAs(HierarchyDataDescriptor)
    public ChildData: HierarchyDataDescriptor = new HierarchyDataDescriptor();

    public get attributeType() {
        return AttributeMetaType[
            this.AttributeType as keyof typeof AttributeMetaType
        ];
    }

    public get CreationTimeFromNow() {
        return moment(this.CreationTime).fromNow();
    }

    public get EntryType(): ActivityLogEntryType {
        return ActivityLogEntryType[
            this.LogEntryType as keyof typeof ActivityLogEntryType
        ];
    }

    public override setVersionId(versionId: string) {
        super.setVersionId(versionId);
        this.ChildData?.setVersionId(versionId);
    }
}
