import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    DxyRichTextFieldComponent,
    IMentionResolver,
} from '@datagalaxy/core-ui/rich-text';
import { CommentaryService } from '../commentary.service';
import { EntityCommentaryDTO } from '@datagalaxy/dg-object-model';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import {
    INewCommentaryModalResolve,
    INewCommentaryModalResult,
} from '@datagalaxy/webclient/comment/feature';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-new-commentary-modal',
    templateUrl: './dxy-new-commentary-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyRichTextFieldComponent,
        DxyModalFooterComponent,
    ],
})
export class DxyNewCommentaryModalComponent
    extends DxyBaseModalComponent<
        INewCommentaryModalResolve,
        INewCommentaryModalResult
    >
    implements OnInit, AfterViewInit
{
    //#region html bindigns
    public readonly mentionResolvers: IMentionResolver[];
    public newCommentaryContent: string;
    //#region for footer
    public canCreate = false;
    public isCheckedCreateAnother: boolean;
    public creationLoading = false;
    public get featureCode() {
        return EntityFunctionalLogService.getFeatureCode(
            this.entityData,
            'SOCIAL_COMMENT',
            'C'
        );
    }
    //#endregion - for footer
    //#endregion

    private entityData: IMiniEntityContent;
    private notifiedUserIds?: string[];
    private isCreating = false;
    private createdCommentaries: EntityCommentaryDTO[] = [];

    @ViewChild('richTextFieldComment')
    private richTextFieldComment: DxyRichTextFieldComponent;

    constructor(
        private commentaryService: CommentaryService,
        private richTextMentionService: RichTextMentionService,
        dialogRef: MatDialogRef<
            DxyNewCommentaryModalComponent,
            INewCommentaryModalResult
        >,
        @Inject(MAT_DIALOG_DATA) data: INewCommentaryModalResolve
    ) {
        super(dialogRef, data);
        this.mentionResolvers =
            richTextMentionService.getDefaultMentionResolvers(
                this.data.entityData.ReferenceId
            );
    }

    ngOnInit() {
        this.entityData = this.data.entityData;
        this.notifiedUserIds = this.data.targetUserIds;
    }

    ngAfterViewInit() {
        const message = this.data.message;
        if (message) {
            this.richTextFieldComment.addHtml(message);
        }
        const userIds = this.notifiedUserIds;
        if (userIds?.length) {
            const userMentions = userIds.map((userId) =>
                this.richTextMentionService.makeUserMention(userId)
            );
            userMentions.forEach((userMention) => {
                this.richTextFieldComment.addMention(userMention);
            });
        }
    }

    public onChangeCreateAnother() {
        this.isCheckedCreateAnother = !this.isCheckedCreateAnother;
        this.updateCanCreate();
    }
    public onCloseSubmit() {
        this.create();
    }
    public onCloseCancel() {
        this.closeSubmitOrCancel();
    }

    public updateCanCreate(): void {
        if (!this.entityData || this.isCreating || !this.newCommentaryContent) {
            this.canCreate = false;
        }
        this.canCreate = true;
    }

    private async create() {
        if (!this.entityData) {
            return;
        }

        this.creationLoading = true;

        let newCommentary: EntityCommentaryDTO;
        this.isCreating = true;
        try {
            newCommentary = await this.commentaryService.createCommentary(
                this.entityData,
                this.newCommentaryContent
            );
            this.newCommentaryContent = null;
        } finally {
            if (!this.notifiedUserIds?.length && this.entityData.SocialData) {
                this.entityData.SocialData.TotalCommentaryCount++;
            }
            this.isCreating = false;
        }

        if (newCommentary) {
            this.createdCommentaries.push(newCommentary);
        }
        this.updateCanCreate();
        if (this.isCheckedCreateAnother) {
            setTimeout(() => {
                this.creationLoading = false;
            }, 500);
        } else {
            this.closeSubmitOrCancel();
        }
    }

    private closeSubmitOrCancel() {
        this.result = { createdCommentaries: this.createdCommentaries };
        super.onCloseSubmit();
    }
}
