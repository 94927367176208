<dxy-wizard-layout
    [wizardContext]="wizardContext"
    (onLogFunctional)="onLogFunctional($event)"
>
    <dxy-solution-selection
        *ngIf="isSolutionSelectionVisible()"
        [importContext]="importContext"
        (onCloseModal)="closeModal()"
        (onClickSolution)="onClickSolution($event)"
        (onClickCsv)="onClickCsv($event)"
        (onMenuOpenClose)="onPanelOpenClose($event)"
    ></dxy-solution-selection>
    <dxy-solution-details
        *ngIf="isSolutionDetailsVisible()"
        [importContext]="importContext"
        [selectedSolution]="selectedSolution"
    ></dxy-solution-details>
    <dxy-csv-import-settings
        *ngIf="isCsvSettingsVisible()"
        [importContext]="importContext"
    ></dxy-csv-import-settings>
    <dxy-csv-preimport-wrapper
        *ngIf="isCsvPreImportVisible()"
        [importContext]="importContext"
        [isImportLoading]="isImportLoading"
        (onMappingInitDone)="onImport(true)"
    ></dxy-csv-preimport-wrapper>
    <dxy-csv-post-import
        *ngIf="isCsvPostImportVisible()"
        [importContext]="importContext"
        [isImportLoading]="isImportLoading"
    ></dxy-csv-post-import>
    <dxy-connection-form-target
        *ngIf="isOnlineTargetVisible()"
        [importContext]="importContext"
        [selectedSolution]="selectedSolution"
        (onMenuOpenClose)="onPanelOpenClose($event)"
    ></dxy-connection-form-target>
    <dxy-connection-form-settings
        *ngIf="isOnlineSettingsVisible()"
        [importContext]="importContext"
        (onMenuOpenClose)="onPanelOpenClose($event)"
    ></dxy-connection-form-settings>
    <dxy-connection-form-entity-selection
        *ngIf="isOnlineSelectionVisible()"
        [importContext]="importContext"
    ></dxy-connection-form-entity-selection>
</dxy-wizard-layout>
