export const mongoDbFieldInfo = {
    authMechanism: {
        description: {
            fr: "Sélectionnez un mode d'authentification : Scram-sha-256 (mode d’authentification MongoDB par défaut), Scram-sha-1 ou Service principal Azure (pour Azure CosmoDB)",
            en: 'Select an authentication mode: SCRAM-SHA-256 (MongoDB default authentication mode), SCRAM-SHA-1 or Azure Service Principal (for Azure CosmosDB)',
        },
        hint: {
            fr: "Choix du mode d'authentification",
            en: 'Select an authentication mode',
        },
    },
    sampleSize: {
        hint: {
            fr: "Taille de l'échantillon de données utilisé afin pour déduire les schémas (100 par défaut)",
            en: 'Data sample size used in order to infer its schemas, default to 100.',
        },
    },
    azureSubscriptionId: {
        hint: {
            fr: "Identifiant de l'abonnement Azure, GUID : 00000000-0000-0000-0000-0000000000000",
            en: 'Azure subscription identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    azureAccountName: {
        hint: {
            fr: 'Nom de compte Azure ',
            en: 'Azure account name',
        },
    },
    azureResourceGroupName: {
        hint: {
            fr: 'Nom du groupe de ressources Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure resource group name, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    azureClientId: {
        hint: {
            fr: 'Identifiant du compte de service client Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure client service account identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    azureTenantId: {
        hint: {
            fr: 'Identifiant du tenant Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure tenant identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    azureClientSecret: {
        hint: {
            fr: 'Secret du client Azure',
            en: 'Azure customer secret',
        },
    },
    username: {
        hint: {
            fr: 'Utilisateur MongoDB',
            en: 'MongoDB user',
        },
    },
    host: {
        hint: {
            fr: 'Nom ou adresse IP du serveur MongoDB',
            en: 'MongoDB server name or IP address',
        },
    },
    port: {
        hint: {
            fr: 'Port de connexion au serveur MongoDB',
            en: 'MongoDB port',
        },
    },
    database: {
        hint: {
            fr: 'Nom de la base de données MongoDB',
            en: 'MongoDB database name',
        },
    },
    advancedMode: {
        description: {
            fr: "Surcharge l'URL de connexion à une base de données MongoDB",
            en: 'Overide MongoDB connection URL',
        },
    },
    url: {
        hint: {
            fr: 'URL de connexion, exemple : mongodb://mongodb0.example.com:27017/?authSource=admin',
            en: 'Connection URL, example: mongodb://mongodb0.example.com:27017/?authSource=admin',
        },
    },
};
