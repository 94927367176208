import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { AttributeCommonDto } from './attribute-common';
import { ServerType } from '@datagalaxy/dg-object-model';

@inheritSerialization(AttributeCommonDto)
export class AttributeDTO extends AttributeCommonDto {
    @deserialize public AttributeUid!: string;
    @deserialize public IsRecommended!: boolean;
    @deserialize public IsIncludedInGlobalSearch!: boolean;
    @deserialize public IsExactMatchIndexed!: boolean;
    @deserialize public IsDefaultSearchAttribute!: boolean;
    @deserialize public IsGlobalSearchIndexable!: boolean;

    public serverType?: ServerType;

    public get isCommonType(): boolean {
        return this.serverType === ServerType.AllTypes;
    }

    public static override init(instance: AttributeDTO, json: any) {
        super.init(instance, json);
        instance.serverType =
            ServerType[instance.ModuleName as keyof typeof ServerType];
    }
}

export class RtAttributeDTO {
    @deserializeAs(AttributeDTO) public Attribute!: AttributeDTO;

    /**
     * NOTE: This value specifies the number of available Global Search Attribute Count
     * as it might change following an Attribute Creation/Update or Deletion
     * */
    @deserialize public AvailableGlobalSearchAttributeCount?: number;
}
