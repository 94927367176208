<div
    *ngIf="isEntityItem"
    class="dg_result-item"
    (click)="onClickPreviewItem($event)"
>
    <div
        class="entity-item"
        [ngClass]="dragItemClass"
        [class.spotlight-draggable]="!dragDisabled"
        cdkDrag
        [cdkDragData]="dragHdd"
        [cdkDragDisabled]="dragDisabled"
        [cdkDragPreviewContainer]="dragDrop?.previewContainer"
        [cdkDragPreviewClass]="dragDrop?.previewClass"
        (cdkDragStarted)="onDragStart()"
        (cdkDragEnded)="onDragEnded()"
    >
        <app-filtered-entity-card-cell
            *ngIf="filteredEntityItem"
            [filteredEntityItem]="filteredEntityItem"
            [actions]="entityActions"
            [forceIncludeSpace]="forceBreadcrumbSpaceIcon"
            [forceExcludeSpace]="!forceBreadcrumbSpaceIcon"
            [noBreadcrumbNavLink]="dragging"
            [noLabelNavLink]="dragging"
            [breadcrumbOpenPreview]="true"
        ></app-filtered-entity-card-cell>
        <app-entity-card-cell
            *ngIf="!filteredEntityItem"
            [hierarchicalData]="hierarchicalData"
            [actions]="entityActions"
            [forceIncludeSpace]="forceBreadcrumbSpaceIcon"
            [forceExcludeSpace]="!forceBreadcrumbSpaceIcon"
            [noLabelNavLink]="dragging"
            [noBreadcrumbNavLink]="dragging"
            [breadcrumbOpenPreview]="true"
        ></app-entity-card-cell>
    </div>
</div>

<div
    *ngIf="isUserAMV"
    (click)="onSelectAttributeMetaValue()"
    class="mini-object"
>
    <div class="spotlight-person">
        <dxy-profile-avatar [userId]="amv.Key"></dxy-profile-avatar>
    </div>
    <div class="object-combo">
        <div class="object-path" translate>
            {{
                'DgServerTypes.BaseData.fields.' +
                    amv.spaceGovernanceUserDto.AttributeKey
            }}
        </div>
        <div class="object-name">{{ amv.translatedDisplayName }}</div>
    </div>
</div>

<div
    *ngIf="isTagAMV"
    (click)="onSelectAttributeMetaValue()"
    class="mini-object"
>
    <span class="glyph glyph-domain1"></span>
    <div class="tag-name">{{ amv.translatedDisplayName }}</div>
    <dxy-color-point-cell [color]="amv.Color"></dxy-color-point-cell>
</div>

<div
    *ngIf="isDomainAMV"
    (click)="onSelectAttributeMetaValue()"
    class="dg_result-item"
>
    <app-entity-card-cell
        [hierarchicalData]="hierarchicalData"
        [forceIncludeSpace]="forceBreadcrumbSpaceIcon"
        [forceExcludeSpace]="!forceBreadcrumbSpaceIcon"
    ></app-entity-card-cell>
</div>

<div
    *ngIf="isFilterItems"
    (mouseover)="hoverLastFilterItems = true"
    (mouseout)="hoverLastFilterItems = false"
    (click)="onLastSearchClick()"
    class="last-search-container dg_result-item"
    [class.with-search-term]="lastSearchTerm"
>
    <span class="glyph-recent"></span>
    <app-attribute-filter-carousel
        [displayMode]="
            hoverLastFilterItems ? 'quick-filters' : 'recent-filters'
        "
        [filterItems]="filterItems"
        [readonly]="true"
        (scrollNext)="onFilterScroll()"
        (scrollPrevious)="onFilterScroll()"
    ></app-attribute-filter-carousel>
    <div class="searchTerm" dxyEllipsisTooltip>
        {{ lastSearchTerm }}
    </div>
</div>
