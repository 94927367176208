export const azureDataLakeGen2FieldInfo = {
    tenantId: {
        hint: {
            fr: 'Identifiant du tenant Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure tenant identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    storageAccount: {
        hint: {
            fr: 'Nom du compte de stockage',
            en: 'Storage account name',
        },
    },
    containerName: {
        hint: {
            fr: 'Nom du conteneur de fichiers',
            en: 'Filesystem container name',
        },
    },
    path: {
        hint: {
            fr: "Filtre l'arboresence de fichiers à partir du préfixe renseigné",
            en: 'Filters the file path based on the given prefix',
        },
    },
    clientId: {
        hint: {
            fr: 'Identifiant du compte de service client Azure, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Azure client service account identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    password: {
        hint: {
            fr: 'Secret du client Azure',
            en: 'Azure client service account secret',
        },
    },
    pathTransformation: {
        hint: {
            fr: 'Format de représentation du chemin des objets au sein de la plateforme',
            en: 'Format for representing the path of objects within the platform',
        },
    },
};
