<dxy-spinner *ngIf="loading"></dxy-spinner>
<button
    *ngIf="isFlat && !loading"
    mat-flat-button
    class="small"
    color="primary"
    [disabled]="disabled"
>
    <ng-template [ngTemplateOutlet]="buttonContent"></ng-template>
</button>

<ng-container *ngIf="!isFlat && !loading">
    <button class="text-button" [disabled]="disabled">
        <ng-template [ngTemplateOutlet]="buttonContent"></ng-template>
    </button>
    <button mat-icon-button [disabled]="disabled">
        <i class="glyph-more"></i>
    </button>
</ng-container>

<ng-template #buttonContent>
    <span *ngIf="!isLess" [translate]="showMoreLabelKey"></span>
    <span *ngIf="isLess" translate>CoreUI.Global.btnLess</span>
</ng-template>
