<dxy-rich-text-field
    #field
    [(ngModel)]="ngModel"
    [readonly]="readonly"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [mandatory]="mandatory"
    [placeholder]="placeholderKey | translate"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    [mentionResolvers]="mentionResolvers"
    toolbarDisplayMode="focus"
    [noTabCapture]="true"
    (onAcquireMentionData)="onAcquireMentionData($event)"
></dxy-rich-text-field>
<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>

<div class="actions">
    <dxy-attribute-quality-score-dropdown
        *ngIf="showTextQualityScore"
        [qualityStatistics]="qualityTextStatistics"
        [vote]="qualityTextVote"
        [isOutdated]="qualityTextOutdated"
        (onSetUserVote)="onSetUserVote($event)"
    ></dxy-attribute-quality-score-dropdown>
</div>
