import { Point } from '@datagalaxy/core-2d-util';
import { IConnectorSpec } from '../connectors.types';

export interface PathComponentProps extends IConnectorSpec {
    points?: Point[];
    debug?: boolean;
}

/*
    Values are stored as numbers in json in DiagramEdgeDto.VisualData.style
    Value names must be matched by diagram-designer.global.scss#jtk-connector.link-edge.line-style-*
*/
export enum LineStyle {
    none = 0,
    dashed_2_2,
    dashed_3_3,
    dashed_7_2,
    dashed_2_2_6_2,
}
