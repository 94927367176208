import { BaseStateService } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { Injectable } from '@angular/core';
import { EntitiesStore } from '../../entity/entities.store';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { map } from 'rxjs';
import {
    EntityDeleteEventData,
    EntityEventService,
} from '../../shared/entity/services/entity-event.service';
import { TDatasourceConfig } from '@datagalaxy/ui/grid';
import PropertyName = ServerConstants.PropertyName;

export type EntityGridView = 'flat' | 'tree';

export interface EntityGridState {
    totalCount?: number;
    hasReachLimit?: boolean;
    loading?: boolean;
    rootIds: string[];
    children: Record<string, string[]>; // parentId -> childrenIds
    parents: Record<string, string>; // childId -> parentId
    expanded: Record<string, boolean>;
    attributeKeys?: string[];
    dataSource?: TDatasourceConfig<EntityItem>;
}

@Injectable()
export class ModuleEntitiesStore extends BaseStateService<EntityGridState> {
    private entitiesStore = new EntitiesStore(this.entityEventService);

    constructor(private entityEventService: EntityEventService) {
        super({
            rootIds: [],
            children: {},
            parents: {},
            expanded: {},
        });

        this.entityEventService.subscribeEntityDelete(null, (data) =>
            this.onEntitiesDeleted(data)
        );

        this.entityEventService.subscribeEntityTechnologyUpdate(
            null,
            (entity) => {
                this.onEntityTechnologyUpdate(entity);
            }
        );
    }

    reset() {
        this.setState({
            rootIds: [],
            children: {},
            parents: {},
        });
        this.entitiesStore.reset();
    }

    selectTotalCount() {
        return this.select((state) => state.totalCount);
    }

    selectHasReachLimit() {
        return this.select((state) => state.hasReachLimit);
    }

    selectLoading() {
        return this.select((state) => state.loading);
    }

    selectEntities() {
        return this.entitiesStore
            .selectEntities()
            .pipe(
                map((entities) =>
                    entities.filter((entity) =>
                        this.state.rootIds.includes(entity.ReferenceId)
                    )
                )
            );
    }

    selectDataSource() {
        return this.select((state) => state.dataSource);
    }

    setExpanded(id: string, expanded: boolean) {
        this.setState({
            expanded: {
                ...this.state.expanded,
                [id]: expanded,
            },
        });
    }

    setLoading(loading: boolean) {
        this.setState({ loading });
    }

    setAttributeKeys(attributeKeys: string[]) {
        this.setState({ attributeKeys });
    }

    getAttributeKeys() {
        return this.state.attributeKeys;
    }

    setDataSource(dataSource: TDatasourceConfig<EntityItem>) {
        this.setState({ dataSource });
    }

    setRootEntities(
        entities: EntityItem[],
        opts?: { totalCount?: number; hasReachLimit?: boolean }
    ) {
        this.setState({
            rootIds: entities.map((entity) => entity.ReferenceId),
            loading: false,
            totalCount: opts?.totalCount,
            hasReachLimit: opts?.hasReachLimit,
        });
        this.entitiesStore.setEntities(entities);
    }

    addRootEntities(
        entities: EntityItem[],
        opts?: { totalCount?: number; hasReachLimit?: boolean }
    ) {
        this.setState({
            rootIds: [
                ...this.state.rootIds,
                ...entities.map((entity) => entity.ReferenceId),
            ],
            loading: false,
            totalCount: opts?.totalCount,
            hasReachLimit: opts?.hasReachLimit,
        });
        this.entitiesStore.addEntities(entities);
    }

    isExpanded(id: string) {
        return this.state.expanded[id];
    }

    getEntityChildren(parentId: string): EntityItem[] {
        return this.state.children[parentId]
            ?.map((id) => this.entitiesStore.getEntityById(id))
            .filter((entity) => entity !== undefined) as EntityItem[];
    }

    setEntityChildren(parentId: string, children: EntityItem[]) {
        this.setState({
            parents: {
                ...this.state.parents,
                ...Object.fromEntries(
                    children.map((entity) => [entity.ReferenceId, parentId])
                ),
            },
            children: {
                ...this.state.children,
                [parentId]: children.map((entity) => entity.ReferenceId),
            },
        });
        this.entitiesStore.addEntities(children);
    }

    // TODO: check correct deletion of parent children otherwise parent can be considered expandable
    private onEntitiesDeleted(data: EntityDeleteEventData) {
        const collectAllDescendants = (id: string): string[] => {
            const children = this.state.children[id] || [];
            return [id, ...children.flatMap(collectAllDescendants)];
        };

        const deletedIds = data.deletedIds.flatMap(collectAllDescendants);
        const parents = deletedIds.map((id) => this.state.parents[id]);

        parents.forEach((parentId) => {
            this.state.children[parentId] = this.state.children[
                parentId
            ].filter((childId) => !deletedIds.includes(childId));
        });

        this.setState({
            parents: {
                ...Object.fromEntries(
                    Object.entries(this.state.parents).filter(
                        ([childId]) => !deletedIds.includes(childId)
                    )
                ),
            },
            children: {
                ...Object.fromEntries(
                    Object.entries(this.state.children).filter(
                        ([parentId]) => !deletedIds.includes(parentId)
                    )
                ),
            },
            rootIds: [
                ...this.state.rootIds.filter((id) => !deletedIds.includes(id)),
            ],
        });
        this.entitiesStore.deleteEntities(deletedIds);
    }

    private onEntityTechnologyUpdate(entity: EntityItem) {
        const technoCode = entity.getAttributeValue<string>(
            PropertyName.Technology
        );
        const collectAllDescendants = (id: string): string[] => {
            const children = this.state.children[id] || [];
            return [id, ...children.flatMap(collectAllDescendants)];
        };

        const entitiesIds = collectAllDescendants(entity.ReferenceId);

        const entities = entitiesIds
            .map((id) => this.entitiesStore.getEntityById(id))
            .filter((entity) => entity !== undefined) as EntityItem[];

        entities.forEach((entity) => {
            entity.setAttributeValue(PropertyName.Technology, technoCode);
            entity.HddData.TechnologyCode = technoCode;
        });

        this.entitiesStore.updateEntities(entities);
    }
}
