<dxy-tabs-header
    class="tab-header no-grow"
    [tabs]="tabsHeaderData"
    (tabChange)="onTabChangeInternal($event)"
    [customClass]="customClass"
    [activeTabItem]="selectedTabItem"
>
    <ng-content *ngIf="!noUiView" select="[header]"></ng-content>
</dxy-tabs-header>
<div class="tab-content dg_u-pos-rel">
    <ui-view *ngIf="!noUiView"></ui-view>
    <ng-content *ngIf="noUiView"></ng-content>
</div>
