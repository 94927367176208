import { Inject, Injectable } from '@angular/core';
import { Language } from './language';
import { DXY_TRANSLATE_CONFIG, ITranslationConfig } from '@datagalaxy/core-ui';
import { getLanguageName } from '@datagalaxy/webclient/multilingual/ui';

@Injectable({ providedIn: 'root' })
export class AvailableLanguagesService {
    private availableLanguages: Language[];

    constructor(
        @Inject(DXY_TRANSLATE_CONFIG)
        translationConfig: ITranslationConfig
    ) {
        this.availableLanguages = translationConfig.locales
            .map((locale) => {
                return {
                    languageCode: locale,
                    languageName: getLanguageName(locale),
                };
            })
            .filter((language) => !!language.languageName);
    }

    public getAvailableLanguages(): Language[] {
        return this.availableLanguages;
    }
}
