import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { IStartupConfig } from '../startup';
import { APP_CONFIG, IAppConfig, IDgAppVersionInfo } from './app-config.types';
import { IBuildConfig } from '../build';
import { ILanguageConfig } from '../lang';
import { IConfig } from '../config.types';

function asNumber(v: string | number) {
    return typeof v == 'string' ? parseInt(v, 10) : v;
}

function asBoolean(v: string | boolean) {
    return typeof v == 'string' ? v == 'yes' || v == 'true' : v;
}

/**
 #Archi-service-leaf (Does not reference any other app service)
 */
@Injectable({ providedIn: 'root' })
export class AppConfigService {
    public get startupLanguage() {
        return this.languageConfig.startup;
    }

    //#endregion

    public get apiHost() {
        return this.appConfig.apiHost;
    }

    public get appVersionName() {
        return this.appConfig.versionName;
    }

    public get releaseNoteUrl() {
        return (
            this.appConfig.releaseNoteUrl ||
            'https://datagalaxy.freshdesk.com/fr/support/solutions/35000141860'
        );
    }

    public get blogUrl() {
        return this.appConfig.blogUrl || 'https://www.datagalaxy.com/blog/';
    }

    public get showServerErrorDetails() {
        return !this.isProd;
    }

    public get versionCheckMinutes() {
        return asNumber(this.appConfig.versionCheckMinutes);
    }

    public get DISABLE_BLOG() {
        return asBoolean(this.appConfig.disableBlog) ?? false;
    }

    public get DISABLE_CONNECTORS() {
        return asBoolean(this.appConfig.disableConnectors) ?? false;
    }

    public get DISABLE_FIRST_ACCESS() {
        return asBoolean(this.appConfig.disableFirstAccess) ?? false;
    }

    public get DISABLE_FEEDBACK() {
        return asBoolean(this.appConfig.disableFeedback) ?? false;
    }

    public get DISABLE_ONLINE_HELP() {
        return asBoolean(this.appConfig.disableOnlineHelp) ?? false;
    }

    public get DISABLE_PUBLIC_API() {
        return asBoolean(this.appConfig.disablePublicApi) ?? false;
    }

    public get DISABLE_TRACKING() {
        return asBoolean(this.appConfig.disableTracking) ?? false;
    }

    public get DISABLE_EMAILING() {
        return asBoolean(this.appConfig.disableEmailing) ?? false;
    }

    public get DISABLE_RELEASE_NOTE() {
        return asBoolean(this.appConfig.disableReleaseNote) ?? false;
    }

    public get DISABLE_AUTO_LOGIN() {
        return asBoolean(this.appConfig.disableAutoLogin) ?? true;
    }

    public get DISABLE_COMMUNICATION_PREFERENCES() {
        return (
            asBoolean(this.appConfig.disableCommunicationPreferences) ?? false
        );
    }

    public get DISABLE_DATA_TEST_IDS() {
        return asBoolean(this.appConfig.disableDataTestIds) ?? false;
    }

    public get ENABLE_MS_TEAMS_INTEGRATION() {
        return asBoolean(this.appConfig.enableMsTeamsIntegration) ?? false;
    }

    public get ENABLE_AUTH_V2() {
        return asBoolean(this.appConfig.enableAuthV2) ?? false;
    }

    public get ENABLE_CONNECTOR_URN_SUPPORT() {
        return asBoolean(this.appConfig.enableConnectorUrnSupport) ?? false;
    }

    public get PLATFORM_VERSION_NUMBER() {
        return (
            this.appConfig.platformVersionNumber
                ?.toString()
                .replace('platform/', '') || 'latest'
        );
    }

    public get APP_VERSION_NUMBER() {
        return this.appConfig.versionNumber?.toString();
    }

    public get APP_VERSION_BUILD_TIME() {
        return moment(this.buildConfig.buildTime);
    }

    public get APP_VERSION_TECHNICAL_INFO() {
        return this.buildConfig.technicalInfo;
    }

    public get ANALYTICS_SEGMENT_ENABLED() {
        return this.startupConfig.ANALYTICS_SEGMENT_ENABLED;
    }

    public get BUILD_RANDOM_KEY() {
        return this.buildConfig.randomKey;
    }

    public get ANALYTICS_SEGMENT_KEY() {
        return 'nIORnNraIjMBr6cHRYMg6welQAjQwICp';
    }

    public get isEmbeddedConnectorAvailable() {
        return this.appConfig.isEmbeddedConnectorAvailable == 'yes';
    }

    public get dgAppVersionInfo() {
        return this.APP_VERSION_NUMBER == 'latest'
            ? this.getAppVersionFullInfo()
            : this.APP_VERSION_NUMBER;
    }

    public get clientAppHttpHeaderValue() {
        return `webclient:${this.dgAppVersionInfo}`;
    }

    public get ONLINE_HELP() {
        return !this.DISABLE_ONLINE_HELP;
    }

    private isProd: boolean;
    private startupConfig: IStartupConfig;
    private appConfig: IAppConfig;
    private buildConfig: IBuildConfig;
    private languageConfig: ILanguageConfig;

    constructor(@Inject(APP_CONFIG) config: IConfig) {
        moment.locale(config.startup.language);

        this.isProd = config.appConfig?.type == 'prod';
        this.startupConfig = config.startup;
        this.languageConfig = config.languageConfig;
        this.buildConfig = config.buildConfig;
        this.appConfig = config.appConfig;
    }

    public getAppVersionInfo(): IDgAppVersionInfo {
        return {
            versionName: this.appVersionName,
            platformVersionNumber: this.PLATFORM_VERSION_NUMBER,
            versionNumber: this.APP_VERSION_NUMBER,
            buildString: this.APP_VERSION_TECHNICAL_INFO,
        };
    }

    public getAppVersionFullInfo() {
        return `${this.APP_VERSION_NUMBER} - ${this.appVersionName} - ${this.APP_VERSION_TECHNICAL_INFO}`;
    }

    public getWindowTitle() {
        const title = 'DataGalaxy';
        return this.appVersionName == 'Production'
            ? title
            : `${title} - ${this.appVersionName}`;
    }
}
