import { BaseComponent } from '@datagalaxy/utils';

/**
 * decorator to disable the possibility of executing an async method twice.
 * This decorator works for methods inside classes extended from BaseComponent
 * @example
 * -@executeOnce()
    protected async asyncMethod() {
        await this.apiCall()
    }
 */
export function executeOnce() {
    return function (
        _target: BaseComponent,
        _key: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;
        let isExecuting = false;

        descriptor.value = async function (...args: any[]) {
            if (isExecuting) {
                return;
            }
            isExecuting = true;
            try {
                const result = await originalMethod.apply(this, args);
                return result;
            } finally {
                isExecuting = false;
            }
        };

        return descriptor;
    };
}
