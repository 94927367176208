//#region ImpactAnalysis (exploratoty & lineage)
/** used as number in the querystring */

export enum ImpactAnalysisTool {
    None = 0,
    LineageBoth,
    LineageRight,
    LineageLeft,
    /** @deprecated - do not use */
    LineageNaturalFlow,
    Explorer,
    Lineage,
}
