import { HierarchicalData, IHasHddData } from '@datagalaxy/dg-object-model';
import { deserialize, deserializeAs, SerializableEnumeration } from 'cerialize';

export enum CompareOperation {
    Added,
    Deleted,
    Updated,
    NotUpdated,
}
SerializableEnumeration(CompareOperation);

export enum CompareModule {
    Glossary,
    Model,
    Software,
    DataProcessing,
}
SerializableEnumeration(CompareModule);

export enum CompareType {
    Table,
    Column,
    PrimaryKey,
    ForeignKey,
}
SerializableEnumeration(CompareType);

export class ComparisonItem implements IHasHddData {
    @deserializeAs(CompareOperation) public Operation!: CompareOperation;
    @deserialize public Module!: string;
    @deserialize public Type!: string;
    @deserializeAs(HierarchicalData) public Item!: HierarchicalData;

    public get HddData() {
        return this.Item;
    }
}

export class EntityComparisonItem {
    @deserializeAs(CompareOperation) public Operation!: CompareOperation;
    @deserialize public VersionId!: string;
    @deserialize public ModifiedAttributeKeys!: string[];
    @deserialize public Attributes!: Object;
    @deserialize public DifferenceCount!: number;
}

export class CompareEntity {
    @deserialize public ModifiedAttributeKeys!: string[];
    @deserialize public sourceAttributes!: object;
    @deserialize public targetAttributes!: object;
}
