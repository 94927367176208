export const snowflakeFieldInfo = {
    snowflakeImportMode: {
        description: {
            fr: `Attention au mode "Import de plusieurs bases de données" ! Ce nouveau mode crée un niveau supplémentaire dans la hiérarchie des objets par rapport au mode mono-BD historique :\n\n- Mono-BD : Snowflake/Model/Table/… \n- Multi-BD: Snowflake/BD/Model/Table/…\n\nSi vous avez précédemment importé vos objets Snowflake dans la plateforme avec le mode mono-BD, il est préférable d'ajouter auparavant ce nouveau niveau intermédiaire. Veuillez suivre la documentation Snowflake en ligne pour migrer vers la nouvelle structure avant de lancer un import multi-BD.`,
            en: `Be careful with the "Multiple databases import" mode! This new mode creates an additional level in the objects hierarchy, compared to the historical mono-DB mode:\n\n- Mono-DB: Snowflake/Model/Table/…\n- Multi-DB: Snowflake/DB/Model/Table/…\n\nIf you have previously imported your Snowflake objects in the platform using the mono-DB mode, it is recommended to add this new intermediate level. Please follow the online Snowflake documentation to migrate to the new structure before launching a multi-DB import`,
        },
        hint: {
            fr: 'Importer une ou plusieurs base de données Snowflake',
            en: 'Import one or more Snowflake databases',
        },
    },
    accountName: {
        description: {
            fr: "Identifiant du compte Snowflake (consultez la documentation snowflake pour savoir comment récupérer cette information). Seul l'identifiant du compte est attendu, pas le nom complet : si le nom complet est <account_id>.snowflakecomputing.com, il faut renseigner <account_id>",
            en: 'Snowflake account identifier (see snowflake documentation more information). Only the account identifier is expected, not the full name: if the full name is <account_id>.snowflakecomputing.com, you should fill in <account_id>',
        },
        hint: {
            fr: 'Identifiant du compte Snowflake, exemple : xx54260.eu-central-1',
            en: 'Snowflake account identifier, example : xx54260.eu-central-1',
        },
    },
    database: {
        hint: {
            fr: 'Limite le périmètre à la base de données spécifiée',
            en: 'Limits the scope to the specified database',
        },
    },
    warehouse: {
        hint: {
            fr: 'Entrepôt de données Snowflake',
            en: 'Snowflake data warehouse name',
        },
    },
    schema: {
        hint: {
            fr: 'Limite le périmètre au schéma spécifié',
            en: 'Limits the scope to the specified schema',
        },
    },
    role: {
        description: {
            fr: 'Limite le périmètre au rôle spécifié, consultez la documentation DataGalaxy pour connaître les droits nécessaires pour la récupération des métadonnées Snowflake.',
            en: 'Limits the scope to the specified role, see DataGalaxy documentation for the rights needed to retrieve Snowflake metadata.',
        },
        hint: {
            fr: 'Limite le périmètre au rôle spécifié',
            en: 'Limits the scope to the specified role',
        },
    },
    user: {
        hint: {
            fr: "Nom d'utilisateur Snowflake",
            en: 'Snowflake user account',
        },
    },
};
