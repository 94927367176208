<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" [translate]="modalTitleKey"></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content [class.modal-disable]="isLoading">
        <dxy-field-text
            name="taskTitle"
            #taskTitleField
            labelKey="UI.TaskContainer.lblPlaceHolderTitle"
            [(ngModel)]="taskTitle"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
            [required]="true"
            [readonly]="!isTitleActive"
            [errorMessageKey]="taskTitleField.touched && taskTitleErrorKey"
        ></dxy-field-text>

        <dxy-field-select
            *ngIf="taskAssigneeReady"
            name="taskAssignee"
            labelKey="UI.TaskContainer.lblTaskAssigneeList"
            [adapter]="taskAssignee"
            [search]="true"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>

        <dxy-field-select
            name="taskType"
            labelKey="UI.TaskContainer.lblTaskType"
            [adapter]="taskType"
            [readonly]="!isCommonFieldActive"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>

        <dxy-field-select
            *ngIf="!isCreation"
            labelKey="UI.TaskContainer.lblTaskStatus"
            [adapter]="taskStatusSelectAdapter"
            [readonly]="isStatusReadonly"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>

        <dxy-field-date
            name="taskDueTime"
            labelKey="UI.TaskContainer.lblTaskDueTime"
            [(ngModel)]="taskDueTime.moment"
            [isClearable]="true"
            (dateChange)="onDateChange()"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-date>

        <dxy-rich-text-field
            name="taskText"
            #taskTextField
            labelKey="UI.TaskContainer.lblTaskText"
            [(ngModel)]="taskText"
            [readonly]="!isTextActive"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
            [required]="true"
            [errorMessageKey]="taskTextField.touched && taskTextErrorKey"
            [noTabCapture]="true"
            toolbarDisplayMode="focus"
            [mentionResolvers]="mentionResolvers"
            (onAcquireMentionData)="onPanelOpenClose($event)"
        ></dxy-rich-text-field>

        <dxy-rich-text-field
            *ngIf="isResponseVisible"
            labelKey="UI.TaskContainer.lblTaskResponse"
            [(ngModel)]="taskCopy.Response"
            toolbarDisplayMode="focus"
            [mentionResolvers]="mentionResolvers"
            [noTabCapture]="true"
            (onAcquireMentionData)="onPanelOpenClose($event)"
            name="response"
        ></dxy-rich-text-field>
    </div>
    <dxy-modal-footer
        [actionEnabled]="actionEnabled"
        [actionBtnLbl]="actionBtnLabel | translate"
        [actionLoading]="isLoading"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
        [showCreateAnother]="isCreation"
        [isCheckedCreateAnother]="isCheckedCreateAnother"
        (onChangeCreateAnother)="onChangeCreateAnother()"
        (onActionPointerDown)="onCloseSubmit()"
    ></dxy-modal-footer>
</form>
