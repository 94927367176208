import { StringUtil } from '@datagalaxy/core-util';

export class UiUtil {
    //#archi-core-style
    static getColorClassFromString(word: string): string {
        const firstLetter =
            word &&
            StringUtil.removeDiacritics(
                word.toLocaleLowerCase().substring(0, 1)
            );
        switch (firstLetter) {
            case 'a':
            case 's':
                return 'OrangeD';
            case 'b':
            case 't':
                return 'OrangeM';
            case 'c':
            case 'u':
                return 'OrangeL';
            case 'd':
            case 'v':
                return 'RedD';
            case 'e':
            case 'w':
                return 'RedM';
            case 'f':
            case 'x':
                return 'RedL';
            case 'g':
            case 'y':
                return 'PinkD';
            case 'h':
            case 'z':
                return 'PinkM';
            case 'i':
            case '1':
                return 'PinkL';
            case 'j':
            case '2':
                return 'PurpleD';
            case 'k':
            case '3':
                return 'PurpleM';
            case 'l':
            case '4':
                return 'PurpleL';
            case 'm':
            case '5':
                return 'BlueD';
            case 'n':
            case '6':
                return 'BlueM';
            case 'o':
            case '7':
                return 'BlueL';
            case 'p':
            case '8':
                return 'GreenD';
            case 'q':
            case '9':
                return 'GreenM';
            case 'r':
            case '0':
                return 'GreenL';
            default:
                return 'OrangeD';
        }
    }
}
