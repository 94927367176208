<div *ngIf="status" [ngSwitch]="status" class="import-status">
    <dxy-spinner
        *ngSwitchCase="ImportStatus.running"
        class="no-margin"
    ></dxy-spinner>
    <span
        *ngSwitchCase="ImportStatus.error"
        class="glyph-close-circle-filled result-icon failed large"
    ></span>
    <span
        *ngSwitchCase="ImportStatus.success"
        class="glyph-check-circle-filled result-icon success large"
    ></span>
</div>
<p *ngIf="importDate">{{ importDate }}</p>
