export class FileUtil {
    /** Returns true if file url contains an extension */
    public static hasExtension(fileUrl: string) {
        // data uri format always contains extension
        if (FileUtil.isDataUri(fileUrl)) {
            return true;
        }
        const match = /\.([^./]*?)$/g.exec(fileUrl);
        return !!match;
    }

    /** Returns true if file url is a data uri (example: "data:image/png;base64,...") */
    private static isDataUri(fileUrl: string) {
        return fileUrl.startsWith('data:');
    }
}

export function getRawTextFromFile(file: File) {
    if (!file) {
        return;
    }
    const reader = new FileReader();
    return new Promise<string>((resolve, reject) => {
        reader.onloadend = () => {
            const fileContent = reader.result as string;
            resolve(fileContent);
        };
        reader.onerror = reject;
        reader.readAsText(file);
    });
}
