import { BrowserInformation } from './app-types/BrowserInformation';

export class BrowserInfoUtil {
    public static getBrowserInfo(): BrowserInformation {
        const browserInfo = new BrowserInformation();

        browserInfo.userAgent = navigator.userAgent;
        browserInfo.appName = navigator.appName;
        browserInfo.appVersion = navigator.appVersion;
        browserInfo.appCodeName = navigator.appCodeName;
        if (window.devicePixelRatio) {
            browserInfo.devicePixelRatio = window.devicePixelRatio;
        }
        browserInfo.platform = navigator.platform;
        browserInfo.cookieEnabled = navigator.cookieEnabled;
        browserInfo.language = navigator.language;

        browserInfo.inResolution = `${window.innerWidth}x${window.innerHeight}`;
        browserInfo.outResolution = `${window.outerWidth}x${window.outerHeight}`;

        return browserInfo;
    }
}
