import { deserialize, serialize } from 'cerialize';
import { Connection, IOnlineImportEntity } from './connection.types';
import { ObjectUtils } from '@datagalaxy/utils';

export class CreateConnectionBody {
    @serialize public credentials!: string;
    @serialize public creationUserId!: string;
    @serialize public name!: string;
    @serialize public entityId?: string;
    @serialize public integrationToken!: string;
    @serialize public versionId!: string;
    @serialize public pluginName!: string;
    @serialize public targetModule!: string;
    @serialize public selectedObjectNames?: string[];

    constructor(body: CreateConnectionBody) {
        ObjectUtils.assign(this, body);
    }
}

export class UpdateConnectionBody {
    @serialize public credentials?: string;
    @serialize public name?: string;
    @serialize public entityId?: string;
    @serialize public integrationToken?: string;
    @serialize public pluginName?: string;
    @serialize public settings?: string;
    @serialize public selectedObjectNames?: string[];

    constructor(body: UpdateConnectionBody) {
        ObjectUtils.assign(this, body);
    }
}

export class ExecuteConnectionBody {
    @serialize public userId!: string;
}

export class CreateSchedulingBody {
    @serialize public suspend!: boolean;
    @serialize public cron!: string;
    @serialize public executeBody!: ExecuteConnectionBody;
    @serialize public timezone?: string;
}

export class UpdateSchedulingBody {
    @serialize public suspend?: boolean;
    @serialize public cron?: string;
    @serialize public timezone?: string;

    constructor(body: UpdateSchedulingBody) {
        ObjectUtils.assign(this, body);
    }
}

export class ExecuteConnectionResponse {
    @deserialize public operationId!: string;
}

export class GetConnectionEntitiesResponse {
    @deserialize public objects!: IOnlineImportEntity[];
}

export class GetConnectionsResponse {
    @deserialize public connections!: Connection[];
}

export class TestConnectionResponse {
    // REMIND: remove boolean type once connectivity-api 2.3.0 is released
    @deserialize public reachable!:
        | ReachableConnection
        | UnreachableConnection
        | boolean;
}

/**
 * A reachable status response form the Java connector server version 2+
 * @since version 2
 */
export type ReachableConnection = {
    success: true;
    context: { [key: string]: string };
};

/**
 * An unreachable status response
 * @since version 2
 */
export type UnreachableConnection = {
    success: false;
    message: string;
};
