import { Injectable } from '@angular/core';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { saveAs } from 'file-saver';
import { ConnectorDownloadApiService } from '@datagalaxy/webclient/connectivity/data-access';

@Injectable({ providedIn: 'root' })
export class ConnectorDownloadService {
    constructor(
        private connectorDownloadApiService: ConnectorDownloadApiService,
        private functionalLogService: FunctionalLogService
    ) {}

    public async downloadConnector(name: string) {
        this.functionalLogService.logFunctionalAction(
            `DOWNLOAD_PLUGIN_${name.toUpperCase()}`,
            CrudOperation.A,
            CrudActionType.Download
        );
        const apiResult =
            await this.connectorDownloadApiService.downloadConnectorPackage(
                name
            );
        return saveAs(
            new Blob([apiResult.contents], {
                type: 'application/java-archive',
            }),
            apiResult.filename
        );
    }

    public async downloadConnectorDesktop(arch: '64' | '32') {
        this.functionalLogService.logFunctionalAction(
            'DOWNLOAD_CONNECTOR_DESTK',
            CrudOperation.A,
            CrudActionType.Download
        );
        const apiResult =
            await this.connectorDownloadApiService.downloadConnectorDesktop(
                arch
            );
        return saveAs(
            new Blob([apiResult.contents], {
                type: 'application/x-zip-compressed',
            }),
            apiResult.filename
        );
    }
}
