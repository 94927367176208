export const amazonS3FieldInfo = {
    region: {
        hint: {
            fr: 'Code de la région AWS, exemple : eu-west-3',
            en: 'AWS region code, example: eu-west-3',
        },
    },
    bucketName: {
        hint: {
            fr: 'Nom du bucket où sont stockés vos objets dans AWS S3',
            en: 'Name of the bucket where your objects are stored in AWS S3',
        },
    },
    filesPath: {
        hint: {
            fr: "Filtre l'arboresence de fichiers AWS à partir du préfixe renseigné, exemple : MyRootFolderFilter/MySubFolderFilter",
            en: 'Filters the AWS file path based on the given prefix, example: MyRootFolderFilter/MySubFolderFilter',
        },
    },
    accessKey: {
        hint: {
            fr: "Identifiant de la clé d'accès de votre compte AWS",
            en: 'AWS account access key identifier',
        },
    },
    password: {
        hint: {
            fr: "Clé d'accès secrète de votre compte AWS ",
            en: 'AWS account secret access key',
        },
    },
    stsToken: {
        description: {
            fr: 'Jeton de session pour une ressource AWS ayant le service AWS Security Token Service (STS) actif',
            en: 'Session token for an AWS resource with the AWS Security Token Service (STS) active',
        },
    },
    pathTransformation: {
        hint: {
            fr: 'Format de représentation du chemin des objets au sein de la plateforme',
            en: 'Format for representing the path of objects within the platform',
        },
    },
};
