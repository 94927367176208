import { deserialize, inheritSerialization } from 'cerialize';
import { registerTypedEvent, UserEventBase } from '../events';

@inheritSerialization(UserEventBase)
@registerTypedEvent('TeamMembersAddedEvent')
export class TeamMembersAddedEvent extends UserEventBase {
    @deserialize TeamGuid: string;
    @deserialize TeamName: string;
    @deserialize AddedMemberGuids: string[];
    @deserialize IsMembershipRequest: boolean;
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('TeamMembersDeletedEvent')
export class TeamMembersDeletedEvent extends UserEventBase {
    @deserialize TeamGuid: string;
    @deserialize TeamName: string;
    @deserialize DeletedMemberGuids: string[];
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('TeamMembershipRequestCreatedEvent')
export class TeamMembershipRequestCreatedEvent extends UserEventBase {
    @deserialize TeamGuid: string;
    @deserialize TeamName: string;
    @deserialize MembershipUserGuid: string;
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('TeamMembershipRequestResponseEvent')
export class TeamMembershipRequestResponseEvent extends UserEventBase {
    @deserialize TeamGuid: string;
    @deserialize TeamName: string;
    @deserialize Accepted: boolean;
    @deserialize MembershipUserGuids: string[];
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('TeamOwnersAddedEvent')
export class TeamOwnersAddedEvent extends UserEventBase {
    @deserialize TeamGuid: string;
    @deserialize TeamName: string;
    @deserialize NewOwnerGuids: string[];
}
