<input
    matInput
    type="number"
    [(ngModel)]="value.value"
    (ngModelChange)="onValueChange($event)"
    [ngModelOptions]="ngModelOptions"
    [readonly]="value.readonly"
    [min]="value.min"
    [max]="value.max"
    [ngClass]="value.className"
/>
