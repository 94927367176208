import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';

/**
 * ## Role
 * Display a message to the user when he is about to be logged out due to inactivity
 */
@Component({
    selector: 'dxy-inactivity-logout-modal',
    templateUrl: './inactivity-logout-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, TranslateModule, DxyButtonDirective],
})
export class InactivityLogoutModalComponent extends DxyBaseModalComponent<
    void,
    void
> {
    constructor(dialogRef: MatDialogRef<InactivityLogoutModalComponent>) {
        super(dialogRef);
    }
}
