import { FieldType } from '../../types/enums/field-type.enum';
import { MongoAuthMechanism } from '../../types/enums/mongo-auth-mechanism.enum';

export const mongoDbConfig = [
    {
        payloadField: 'auth-mechanism',
        formField: 'authMechanism',
        formMandatory: true,
        type: FieldType.select,
        payloadValue: MongoAuthMechanism.SCRAM_256,
        selectValues: [
            MongoAuthMechanism.AZURE,
            MongoAuthMechanism.SCRAM,
            MongoAuthMechanism.SCRAM_256,
        ],
        translate: true,
        getDisplayValueTradKey: (value: MongoAuthMechanism) =>
            value === MongoAuthMechanism.AZURE
                ? 'azure'
                : value === MongoAuthMechanism.SCRAM
                ? 'scram'
                : 'scram256',
        dependencies: [
            {
                field: {
                    payloadField: 'username',
                    formField: 'username',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'password',
                    formField: 'password',
                    isPassword: true,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'advanced-mode',
                    formField: 'advancedMode',
                    type: FieldType.toggle,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'host',
                    formField: 'host',
                    tradKey: 'server',
                    type: FieldType.text,
                    formMandatory: true,
                    isDisabled: ({ advancedMode }) => advancedMode,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'port',
                    formField: 'port',
                    payloadValue: '27017',
                    type: FieldType.text,
                    formMandatory: true,
                    isDisabled: ({ advancedMode }) => advancedMode,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'database-name',
                    formField: 'database',
                    type: FieldType.text,
                    formMandatory: true,
                    isDisabled: ({ advancedMode }) => advancedMode,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'url',
                    formField: 'url',
                    type: FieldType.text,
                    formMandatory: true,
                    isDisabled: ({ advancedMode }) => !advancedMode,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.SCRAM ||
                    value === MongoAuthMechanism.SCRAM_256,
            },
            {
                field: {
                    payloadField: 'subscription-id',
                    formField: 'azureSubscriptionId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.AZURE,
            },
            {
                field: {
                    payloadField: 'account-name',
                    formField: 'azureAccountName',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.AZURE,
            },
            {
                field: {
                    payloadField: 'resource-group-name',
                    formField: 'azureResourceGroupName',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.AZURE,
            },
            {
                field: {
                    payloadField: 'client-id',
                    formField: 'azureClientId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.AZURE,
            },
            {
                field: {
                    payloadField: 'tenant-id',
                    formField: 'azureTenantId',
                    type: FieldType.text,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.AZURE,
            },
            {
                field: {
                    payloadField: 'client-secret',
                    formField: 'azureClientSecret',
                    isPassword: true,
                    formMandatory: true,
                },
                show: (value: MongoAuthMechanism) =>
                    value === MongoAuthMechanism.AZURE,
            },
        ],
    },
    {
        payloadField: 'sample-size',
        formField: 'sampleSize',
        payloadValue: 100,
        type: FieldType.text,
        formMandatory: true,
        format: (value: string) => Number(value),
    },
];
