<!-- Module header section -->
<div class="section-header-container" (click)="onToggleHeaderCollapse()">
    <div class="section-header-module">
        <span class="glyph" [ngClass]="moduleGlyph"></span>
        <span
            class="section-header-title"
            [translate]="moduleTranslateKey"
        ></span>
    </div>
    <div class="section-header-summary">
        <span
            class="results-summary"
            translate
            [translateParams]="summaryTranslateParams"
        >
            {{ summaryTranslateKey }}
        </span>
        <span
            class="glyph-arrow-drop-down"
            [hidden]="!hasItems"
            [class.glyph-arrow-drop-down--collapsed]="isSectionCollapsed"
        ></span>
    </div>
</div>

<!-- Module diff content section -->
<div *ngIf="isContentExpanded" class="section-content-container">
    <dxy-versioning-comparator-item
        *ngFor="let item of displayedItems; trackBy: getItemId"
        [comparisonItem]="item"
        [selectedVersion]="selectedVersion"
    >
    </dxy-versioning-comparator-item>
</div>

<!-- Module footer section -->
<div *ngIf="isFooterAvailable" class="section-footer-container">
    <div *ngIf="isDisplayMoreAvailable" class="dg_comparator-section-btn">
        <a class="btn btn--comparator" (click)="onDisplayMore()">
            <span translate [translateParams]="{ usedOffset }">
                UI.Versioning.Comparator.loadMore </span
            >&nbsp;
            <span class="badge">{{ badgeCountString }}</span>
        </a>
    </div>
    <div *ngIf="isDisplayLessAvailable" class="dg_comparator-section-btn">
        <a class="btn btn--comparator" (click)="onDisplayLess()">
            <span translate="UI.Spotlight.lblLessResult"></span>
        </a>
    </div>
</div>
