import { deserialize } from 'cerialize';

export abstract class BaseMessage {
    @deserialize public $id!: string; //#archi-abasedata-$id
    @deserialize public IsWarning!: boolean;
    @deserialize public IsError!: boolean;
    @deserialize public MessageCode!: string;
    @deserialize public RawMessage!: string;
    @deserialize public Parameters: string[] = [];
}
