import { SerializableEnumeration } from 'cerialize';

export enum ActivityLogEntryType {
    Create,
    AddObject,
    AddReference,
    Update,
    Delete,
    DeleteObject,
    DeleteReference,
}
SerializableEnumeration(ActivityLogEntryType);
