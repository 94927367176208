import { getBootLogger } from '../../../boot';
const logBoot = getBootLogger('translation.factory.ts');
logBoot('IN');
import { ITranslationConfig } from '@datagalaxy/core-ui';
import { ClientErrorService } from '../../services/clientError.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { IConfig } from '@datagalaxy/webclient/config';

export function translateConfigFactory(
    config: IConfig,
    clientErrorService: ClientErrorService
) {
    return getTranslateConfig(config, clientErrorService);
}

/** To be called as soon as the config has been downloaded */
function getTranslateConfig(
    config: IConfig,
    clientErrorService: ClientErrorService
) {
    logBoot('translationInit-start');
    const { buildConfig, languageConfig } = config;
    const suffix = `.json?v=${buildConfig.randomKey}`;
    const platformTranlsationConfig: ITranslationConfig = {
        locales: languageConfig.codes,
        defaultLang: 'en',
        preferedLang: languageConfig.startup,
        buildRandomKey: buildConfig.randomKey,
        resources: [
            { prefix: '/i18n/core-ui/', suffix },
            { prefix: '/i18n/webclient/auth/', suffix },
            { prefix: '/i18n/webclient/dashboard/', suffix },
            { prefix: '/i18n/webclient/entity/', suffix },
            { prefix: '/i18n/webclient/filter/', suffix },
            { prefix: '/i18n/webclient/multilingual/', suffix },
            { prefix: '/i18n/main/', suffix },
            { prefix: '/i18n/translation-common/', suffix },
            //#Archi-adminsite: translation files are still in use (keyword: AdminUI)
            { prefix: '/i18n/admin/', suffix },
        ],
        messageFormatPatch: {
            doReplaceShortcutAll: true,
            doLog: false,
            doCheckAllOnError: false,
        },
        onError: (error: Error, cause: string, type: string) => {
            if (!error) {
                return;
            }
            try {
                clientErrorService.notify(error, cause, type);
            } catch (e) {
                if (!CoreUtil.isProduction) {
                    console.warn(e);
                }
            }
        },
    };
    logBoot('translationInit-end');
    return platformTranlsationConfig;
}

logBoot('OUT');
