import { ImportContext } from '../../../shared/ImportContext';
import { ModelType } from '@datagalaxy/dg-object-model';
import { IImportDataParameterBase } from '@datagalaxy/webclient/data-port/data-access';

export interface IGenericImportWizardResolve {
    importContext: ImportContext;
}

export enum ImportSteps {
    solutionSelection = 0,
    solutionDetail = 1,
    csvSettings = 2,
    csvPreImport = 3,
    csvPostImport = 4,
    onlineConnectionTarget = 12,
    onlineConnectionSettings = 13,
    onlineConnectionSelection = 14,
}

export interface IImportDataParameterBaseWithSourceCreation
    extends IImportDataParameterBase {
    modelName: string;
    modelType: ModelType;
    createDataTypeIfMissing: boolean;
}

export interface IImportDataParameterBaseWithSourceUpdate
    extends IImportDataParameterBase {
    modelId: string;
    createDataTypeIfMissing: boolean;
}
