<dxy-field-boolean
    #field
    [(ngModel)]="ngModel"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [readonly]="readonly"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    [class.mini]="mini"
></dxy-field-boolean>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>
