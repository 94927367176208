<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname">
        <span translate>UI.EntityDockingPane.Tool.Assets</span>
        <span *ngIf="totalCount" class="dg5-badge">{{ totalCount }}</span>
    </div>
</div>
<div class="panel-content-body">
    <dxy-search-input
        *ngIf="isSearchAvailable"
        class="height-50"
        placeholderKey="UI.Global.filter.filterByName"
        (onSearchString)="onSearchTerm($event)"
        [showCancelSearch]="true"
    ></dxy-search-input>
    <div *ngIf="hasSections" class="sections">
        <ng-container *ngFor="let section of filteredSections">
            <dxy-collapsible
                [titleKey]="section.title"
                [titleCount]="section.count"
            >
                <app-entity-card-cell
                    *ngFor="let entity of section.entities"
                    [hierarchicalData]="entity"
                    [noBreadcrumbNavLink]="noNavLink"
                    [noLabelNavLink]="noNavLink"
                    [actions]="actions"
                    (click)="onEntityClick(entity)"
                ></app-entity-card-cell>
            </dxy-collapsible>
        </ng-container>
    </div>
    <div *ngIf="emptyAssets" class="no-item-container">
        <div class="image-container">
            <img
                src="/images/placeholders/diagram-assets.svg"
                alt="diagram-assets-empty"
                class="dg_no-item-image"
            />
        </div>
        <div class="no-item-title" translate>
            UI.EntityDockingPane.Assets.Title
        </div>
        <div class="no-item-text" translate>
            UI.EntityDockingPane.Assets.Subtitle
        </div>
        <button mat-flat-button color="primary" (click)="goToDiagram()">
            <i class="glyph-add"></i
            ><span translate>UI.EntityDockingPane.Assets.EditDiagramBtn</span>
        </button>
    </div>
    <dxy-spinner
        *ngIf="loading | async"
        class="absolute-centered xl"
    ></dxy-spinner>
</div>
