export const databricksFieldInfo = {
    server: {
        hint: {
            fr: 'Nom du serveur Databricks',
            en: 'Databricks server hostname',
        },
    },
    port: {
        hint: {
            fr: 'Port de connexion au serveur Databricks',
            en: 'Databricks server connection port',
        },
    },
    catalog: {
        description: {
            fr: 'Permet de limiter le périmètre pour une liste de catalogues données',
            en: 'Allows you to limit the scope for a given list of catalogs',
        },
    },
    httpPath: {
        hint: {
            fr: 'URL des ressources de calcul de Databricks, exemple : sql/protocolv1/o/XXXX/0125-105531-okp9kyqn',
            en: 'Databricks compute resources URL, example: sql/protocolv1/o/XXXX/0125-105531-okp9kyqn',
        },
    },
    filesPath: {
        hint: {
            fr: "Filtre l'arborescence de dossiers à partir du préfixe renseigné, exemple de valeur : /Shared",
            en: 'Filters the file path based on the given prefix, example: /Shared',
        },
    },
    databricksAuthMode: {
        hint: {
            fr: 'Sélectionner un mode d’authentification ',
            en: 'Select an authentication mode',
        },
    },
    clientId: {
        hint: {
            fr: 'Identifiant du compte de service client, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Client service account identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    tenantId: {
        hint: {
            fr: 'Identifiant du tenant, GUID : 00000000-0000-0000-0000-0000000000000',
            en: 'Tenant identifier, GUID: 00000000-0000-0000-0000-0000000000000',
        },
    },
    clientSecret: {
        hint: {
            fr: 'Secret du client',
            en: 'Customer secret',
        },
    },
};
