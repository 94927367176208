<button
    class="dg5-filter-item"
    type="button"
    [class.disabled]="readonly"
    [matMenuTriggerFor]="noMenu ? null : matMenu"
    #matMenuTrigger="matMenuTrigger"
    (mousedown)="onMenuTriggerMouseDown()"
    (onMenuOpen)="onMenuOpen()"
    (onMenuClose)="onMenuClose()"
>
    <div *ngIf="showIconClass" class="filter-icon" [ngClass]="iconClass"></div>
    <div *ngIf="showIconUrl" class="filter-image"><img [src]="iconUrl" /></div>
    <ng-content select="[customIconTemplate]"></ng-content>
    <div class="filter-group">
        <div
            class="filter-label"
            [class.is-resolved]="isResolved"
            dxyEllipsisTooltip
        >
            <span>{{ label }}</span>
        </div>
        <div *ngIf="isResolved" class="filter-value">
            <span *ngIf="text">{{ text }}</span>
            <ng-content select="[customTextTemplate]"></ng-content>
        </div>
    </div>
    <span *ngIf="!noMenu" class="glyph-arrow-drop-down"></span>
    <div [matTooltip]="removeDisabledTooltip">
        <button
            *ngIf="hasRemove"
            mat-icon-button
            [disabled]="removeDisabled"
            type="button"
            class="dg5-small-icon-button glyph-cancelsearch"
            (click)="removeClick.emit($event)"
        ></button>
    </div>
</button>
<mat-menu
    #matMenu="matMenu"
    [yPosition]="yMenuPosition"
    [xPosition]="xMenuPosition"
    class="dg5-mat-menu fixed-width-400 button-filter--mat-menu no-padding {{
        hasSmallPanel ? 'small-panel' : ''
    }}"
>
    <div
        *ngIf="isMenuOpen"
        class="not-an-item"
        [class.has-operators]="hasOperators"
        (click)="$event.stopPropagation()"
    >
        <div *ngIf="hasOperators" class="operators">
            <button
                mat-button
                [matMenuTriggerFor]="operatorMenu"
                #operatorMenuTrigger="matMenuTrigger"
            >
                <span>{{ selectedOperatorLabel }}</span>
                <span mat-icon-button class="glyph-arrow-drop-down"></span>
            </button>
            <div *ngIf="showAndOrOperators" class="vertical-separator"></div>
            <mat-radio-group
                *ngIf="showAndOrOperators"
                [ngModel]="isOrOperator"
                (ngModelChange)="onAndOrOperatorChange($event)"
                class="radio-buttons"
            >
                <mat-radio-button [value]="true">
                    <span translate>CoreUI.Global.OrSeparator</span>
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    <span translate>CoreUI.Global.AndSeparator</span>
                </mat-radio-button>
            </mat-radio-group>
            <mat-menu #operatorMenu="matMenu" class="dg5-mat-menu">
                <ng-container *ngFor="let operator of operators">
                    <button
                        *ngIf="!isAndOperator(operator)"
                        mat-menu-item
                        [class.selected]="isSelectedOperator(operator)"
                        (click)="onSelectOperator(operator, $event)"
                    >
                        {{ getOperatorLabel(operator) }}
                    </button>
                </ng-container>
            </mat-menu>
        </div>
        <div class="menu-content">
            <ng-content></ng-content>
        </div>
    </div>
</mat-menu>
