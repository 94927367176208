import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { LogFn } from '@datagalaxy/core-util';
import {
    ITooltipContentShowParams,
    RichTooltipPosition,
    RichTooltipPositionOptions,
} from '@datagalaxy/ui/tooltip';
import { Subject } from 'rxjs';

/** ## Role
 * Helper to emit an asynchronous and debounced event,
 * to show & hide the RichTooltipContent component
 */
export class RichTooltipContentHelper<
    TParamsData = unknown,
    TParams extends IRichTooltipContentHelperParams<TParamsData> = IRichTooltipContentHelperParams<TParamsData>
> {
    public get showHide$() {
        return this._showHide.asObservable();
    }
    public get isShowing() {
        return this.showing;
    }

    private readonly _showHide = new Subject<TParams>();
    private showing = false;
    private shown = false;
    private timer: number;
    private p: TParams;

    constructor(private showDelayMs = 222, private logger?: LogFn) {}

    public show(
        target: FlexibleConnectedPositionStrategyOrigin,
        text?: string,
        position?: RichTooltipPosition,
        options?: RichTooltipPositionOptions,
        data?: TParamsData
    ) {
        this.showHide({ target, text, position, options, data } as TParams);
    }

    public hide() {
        this.showHide();
    }

    public showHide(p?: TParams | (() => TParams)) {
        const show = !!p;
        this.log('showHide', show, this.showing, this.shown);
        if (show && this.showing) {
            return;
        }
        this.showing = show;
        window.clearTimeout(this.timer);
        if (show) {
            this.timer = window.setTimeout(() => {
                p = this.p = typeof p == 'function' ? p() : p;
                if (!p) {
                    this.showing = false;
                }
                this._showHide.next(p);
                this.shown = !!p;
                p?.onShowHide?.(this.shown, p.data);
            }, this.showDelayMs);
        } else if (this.shown) {
            this._showHide.next(null);
            this.shown = false;
            this.p?.onShowHide?.(false, this.p.data);
            this.p = undefined;
        }
    }

    public isShown(data: TParamsData) {
        return this.shown && this.p?.data === data;
    }

    private log(...args: any[]) {
        this.logger?.(this.constructor.name, ...args);
    }
}

export interface IRichTooltipContentHelperParams<TParamsData = unknown>
    extends ITooltipContentShowParams<TParamsData> {
    onShowHide?: (shown: boolean, e: TParamsData) => any;
}
