import { Document, DocumentReference } from '../Document.types';
import { IRichTextContent } from './core-rich-text.types';

/** Serializable rich-text content. Contains flat and formatted text, and data  */
export class RichTextContent implements IRichTextContent {
    //#region static

    private static readonly RawTextKey = 'RawTextContent';
    private static readonly HtmlTextKey = 'HtmlTextContent';
    private static readonly DeltaKey = 'DataContent';

    /** Returns true if the given text is empty or undefined,
     * or, when a valid serialized RichTextContent, the extracted RawText is empty */
    public static isEmptyRawText(text: string) {
        if (!text) {
            return true;
        }
        if (RichTextContent.isValidJsonString(text)) {
            const doc = new RichTextContent();
            doc.fromJsonString(text);
            return doc.isEmpty();
        }
        return false;
    }

    /** If the given text is a valid serialized RichTextContent,
     * returns its extracted RawText,
     * otherwise returns the given text, or an empty string */
    public static getRawText(text: string, emptyStringIfNullOrInvalid = false) {
        if (text) {
            if (!RichTextContent.isValidJsonString(text)) {
                return emptyStringIfNullOrInvalid ? '' : text;
            }
            const doc = new RichTextContent();
            doc.fromJsonString(text);
            return doc.RawText;
        }
        if (emptyStringIfNullOrInvalid) {
            return '';
        }
    }

    /** If the given text is a valid serialized RichTextContent, returns its extracted HtmlText, otherwise returns the given text, or an empty string if undefined */
    public static getHTMLText(text: string) {
        text ??= '';
        if (text && RichTextContent.isValidJsonString(text)) {
            const doc = new RichTextContent();
            doc.fromJsonString(text);
            return doc.HtmlText;
        }
        return text;
    }

    /** Returns a RichTextContent,
     * deserialized from the given text if a valid serialized RichTextContent,
     * otherwise with its RawText set to the given text */
    public static fromJsonOrRawText(text: string) {
        const doc = new RichTextContent();
        if (text) {
            if (RichTextContent.isValidJsonString(text)) {
                doc.fromJsonString(text);
            } else {
                doc.RawText = text;
                doc.HtmlText = null;
                doc.Delta = null;
            }
        }
        return doc;
    }

    /** Returns true if the given string can be parsed as a valid json object */
    private static isValidJsonString(jsonString: string) {
        //#Archi-richText TODO (fbo) test with  /\{.*\n.*\}/.test(jsonString)
        if (jsonString?.includes('{') && jsonString.includes('}')) {
            try {
                JSON.parse(jsonString);
                return true;
            } catch (e) {
                return false;
            }
        }
        return false;
    }

    //#endregion

    private readonly doc = new Document();

    /** Unformatted text */
    public get RawText() {
        return this.doc.getItemContent(RichTextContent.RawTextKey);
    }
    public set RawText(value: string) {
        this.setItemByKey(RichTextContent.RawTextKey, value, 'RawText');
    }

    /** Formatted text.
     * Note: Currently, if this content contains mentions (rich-text-mention, dxy-rich-text-mention),
     * those won't be rendered unless using rich-text-editor or dxy-rich-text-field-control. */
    // #Archi-richtext (fbo) We could solve this issue by replacing and recompiling the content.
    public get HtmlText() {
        return this.doc.getItemContent(RichTextContent.HtmlTextKey);
    }
    public set HtmlText(value: string) {
        this.setItemByKey(RichTextContent.HtmlTextKey, value, 'HtmlText');
    }

    /** Data. For Quill editor, type is DeltaOperation[] */
    public get Delta() {
        return this.doc.getItemContent(RichTextContent.DeltaKey);
    }
    public set Delta(value: any) {
        this.setItemByKey(RichTextContent.DeltaKey, value, 'QuillDelta');
    }

    private setItemByKey(key: string, value: any, itemTypeForAdd: string) {
        if (this.doc.hasItemByKey(key)) {
            this.doc.setItemContent(key, value);
        } else {
            this.doc.addItem(key, itemTypeForAdd, value);
        }
    }

    public isEmpty() {
        return !this.HtmlText || this.HtmlText === '<p><br></p>';
    }

    //#region Document References Management
    public getReferences() {
        return this.doc.getReferences();
    }
    public getReference(docRefId: string) {
        return this.doc.getReferenceById(docRefId);
    }
    public addReference(
        id: string,
        kind?: string,
        targetId?: string,
        targetType?: string,
        serializableContent?: any
    ) {
        this.doc.addReference(
            new DocumentReference(
                id,
                kind,
                targetId,
                targetType,
                serializableContent
            )
        );
    }
    public deleteReference(docRefId: string) {
        this.doc.deleteReference(docRefId);
    }
    //#endregion

    //#region de/serialization
    public fromJsonString(jsonString: string) {
        this.doc.fromJsonString(jsonString);
    }
    public toJsonString() {
        return this.doc.toJsonString();
    }
    //#endregion
}
