import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import {
    emitFunctionalEventIfNeeded,
    TFunctionalLogCode,
} from '../../functional-log.util';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import {
    CaptionPositionInsideLeftTop,
    getCaptionClasses,
    ICaptionPosition,
} from '../caption-position';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ZoneUtils } from '@datagalaxy/utils';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

/** ## Role
 * Editable zone of text, supporting inside/outside positioning and auto-height */
@Component({
    selector: 'dxy-graphical-textarea',
    templateUrl: 'graphical-textarea.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatLegacyInputModule, TextFieldModule, FormsModule, NgClass],
})
export class DxyGraphicalTextAreaComponent
    extends DxyBaseComponent
    implements OnInit, OnDestroy
{
    public get text() {
        return this._text;
    }
    @Input() set text(value: string) {
        this.textChange.emit((this._text = value));
    }
    @Output() readonly textChange = new EventEmitter<string>();
    /** text changed event emission mode - default is *blur* */
    @Input() updateOn: 'change' | 'blur' = 'blur';

    /** required for height management when the parent component has no height defined - must be provided at init */
    @Input() heightAncestorSelector: string;

    @Input() set fontSize(value: number) {
        this.setFontSize(value);
    }

    /** Position of the textarea relative to this component's parent:
     * - inside or outside
     * - top/middle/bottom
     * - left/center/right
     *
     * Defaults to inside,top,left
     *
     * Note: for UI refresh to happen, provide a new instance each time */
    @Input() set position(value: ICaptionPosition) {
        this._position = value;
        this.updateCaptionClasses();
    }
    /** positioning default properties - defaults to inside, top, left */
    @Input() positionDefaults: ICaptionPosition;

    @Input() readonly: boolean;

    @Input() placeholderTranslatekey: string;

    @Input() functionalCode: TFunctionalLogCode;
    @Output() get functional() {
        return (this._functional ??= new EventEmitter<IFunctionalEvent>());
    }

    /** When true, the textarea takes the focus when clicking anywhere on the component - checked at init only */
    @Input() autoFocus: boolean;
    /** while true, the textarea will not take focus */
    @Input() preventFocus: boolean;

    protected fontSizePx: number;
    protected captionClasses: string[];
    protected get autoSize() {
        return (
            this.isHeightManaged &&
            !this.isOutside &&
            this.isTextSmallerThanContainer
        );
    }
    protected get fullHeight() {
        return (
            this.isHeightManaged &&
            !this.isOutside &&
            !this.isTextSmallerThanContainer
        );
    }
    protected get placeholder() {
        return this.placeholderTranslatekey
            ? this.translate.instant(this.placeholderTranslatekey)
            : '';
    }

    @ViewChild('textarea') private textareaRef: ElementRef<HTMLTextAreaElement>;
    @ViewChild('textareaAutosize')
    private textareaAutosize: CdkTextareaAutosize;

    //for debug
    // @HostBinding('class.to-full-height') get classToFullHeight() { return this.fullHeight }
    // @HostBinding('class.smaller-text') get classSmaller() { return this.isTextSmallerThanContainer }
    // @HostBinding('class.bigger-text') get classBigger() { return !this.isTextSmallerThanContainer }
    // @HostBinding('class.height-managed') get classManaged() { return this.isHeightManaged }
    // @HostBinding('class.no-autosize') get classNoAutosize() { return !this.autoSize }
    // @HostBinding('class.hide-scrollbar') get classHideScrollBar() { return this.shouldHideScrollBar }

    private _text: string;
    private _position: ICaptionPosition;
    private _functional: EventEmitter<IFunctionalEvent>;
    private _heightAncestor: HTMLElement;
    private removeTouchListener: () => void;
    private removeClickListener: () => void;
    private timerTouched: number;
    private attached: boolean;
    private noAutoFocus: boolean;
    private get textarea() {
        return this.textareaRef?.nativeElement;
    }
    private get isScrollbarVisible() {
        const t = this.textarea;
        return t && t.clientHeight < t.scrollHeight;
    }
    private get textHeight() {
        const t = this.textarea;
        return (t && Math.max(t.scrollHeight, t.clientHeight)) || 0;
    }
    private get shouldHideScrollBar() {
        return this.isScrollbarVisible && this.isTextSmallerThanContainer;
    }
    private get isTextSmallerThanContainer() {
        return (
            this.isHeightManaged &&
            this.textHeight < (this.heightAncestor?.offsetHeight ?? 0)
        );
    }
    private get isHeightManaged() {
        return !!this.heightAncestorSelector;
    }
    private get isOutside() {
        return this._position?.type == 'outside';
    }
    private get heightAncestor() {
        return (
            (this._heightAncestor ??= this.textarea?.closest<HTMLElement>(
                this.heightAncestorSelector
            )) ?? this.elementRef.nativeElement
        );
    }
    private get isTextFocused() {
        return window.document.activeElement == this.textarea;
    }
    //#endregion

    constructor(
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        super();
    }

    ngOnInit() {
        this.positionDefaults ??= CaptionPositionInsideLeftTop;
        this.updateCaptionClasses();
        this.textTouch();
        this.initClickListener();
    }
    ngOnDestroy() {
        this.detach();
        this.removeClickListener?.();
        super.ngOnDestroy();
    }

    //#region API
    public refresh(origin?: string) {
        this.log('refresh', origin ?? '');
        this.adaptHeight(true);
    }
    public focus() {
        this.log('focus');
        this.textarea?.focus();
    }
    //#endregion

    protected attach() {
        if (this.attached) {
            return;
        }
        this.attached = true;
        // we use keyup instead of keypress to detect backspace (at least on mac)
        this.removeTouchListener = DomUtil.addListener(
            this.textarea,
            ['keyup', 'cut', 'paste'],
            (e) => this.textTouch(e)
        );
        this.log('attached');
    }
    protected detach() {
        if (!this.attached) {
            return;
        }
        this.attached = false;
        this.removeTouchListener?.();
        this.log('detached');
    }

    private textTouch(e?: Event) {
        this.log('textTouch');
        window.clearTimeout(this.timerTouched);
        this.timerTouched = ZoneUtils.zoneTimeout(
            () => this.onTextTouched(e),
            333,
            this.ngZone,
            true
        );
    }
    private onTextTouched(e?: Event) {
        this.adaptHeight();
        e &&
            emitFunctionalEventIfNeeded(
                this.functionalCode,
                this._functional,
                e
            );
    }

    private updateCaptionClasses() {
        const p = this._position;
        this.captionClasses = p
            ? ['caption', ...getCaptionClasses(p, this.positionDefaults)]
            : null;
        this.cd.detectChanges();
        this.log('updateCaptionClasses', this.captionClasses, p);
    }

    private setFontSize(value: number) {
        this.log('setFontSize', value);
        this.fontSizePx = value;
        this.cd.detectChanges();
        if (this.isHeightManaged && this.autoSize) {
            this.resizeToFitContent();
        }
    }
    private resizeToFitContent() {
        this.log('resizeToFitContent');
        this.textareaAutosize?.resizeToFitContent(true);
        if (this.shouldHideScrollBar) {
            this.adaptHeightToHideScrollbar();
        }
        this.cd.detectChanges();
    }

    private adaptHeight(fromRefresh?: boolean) {
        if (this.shouldHideScrollBar) {
            this.adaptHeightToHideScrollbar();
        } else if (!this.isOutside && !this.autoSize && this.textarea) {
            const action = () => {
                this.textarea.style.height = '';
                this.cd.detectChanges();
            };
            action();
            if (fromRefresh) {
                setTimeout(action);
            }
        }
    }

    private adaptHeightToHideScrollbar() {
        this.log('adaptHeightToHideScrollbar');
        this.cd.detectChanges();
        const t = this.textarea;
        t.style.height = `${t.scrollHeight + 2}px`;
    }

    private initClickListener() {
        this.log('initClickListener', this.autoFocus);
        if (!this.autoFocus) {
            return;
        }
        this.removeClickListener = DomUtil.addListener(
            this.elementRef.nativeElement,
            ['mousedown', 'mouseup'],
            (e) => this.clicking(e),
            this.ngZone,
            true
        );
    }
    private clicking(e: MouseEvent) {
        if (this.readonly) {
            return;
        }
        const { isTextFocused, textarea } = this;
        this.debug &&
            this.log('clicking', e.type, {
                isTextFocused,
                autoFocus: this.autoFocus,
                preventFocus: this.preventFocus,
                noAutoFocus: this.noAutoFocus,
            });
        if (e.type == 'mousedown') {
            this.noAutoFocus = isTextFocused;
            if (e.target == textarea) {
                e.stopPropagation();
            }
        } else if (!isTextFocused) {
            if (this.preventFocus) {
                this.log('preventFocus');
            } else if (this.autoFocus) {
                if (this.noAutoFocus) {
                    this.log('noAutoFocus');
                } else {
                    this.log('autoFocus');
                    textarea.focus();
                }
            }
            this.noAutoFocus = false;
        }
    }
}
