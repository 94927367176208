import { IUserBadgeCellData } from '@datagalaxy/core-ui';
import {
    IUserCommonDto,
    UserPublicData,
} from '@datagalaxy/webclient/user/domain';

export abstract class UserPublicService {
    public abstract getPublicUserData(userId: string): UserPublicData;
    public abstract getUserBadgeData(user: IUserCommonDto): IUserBadgeCellData;
}
