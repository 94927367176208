<dxy-alert-message
    alertType="warning"
    [message]="'Multilingual.Administration.unavailabilityMessage' | translate"
></dxy-alert-message>

<div class="dxy-button-group">
    <a href="mailto:accountmanagement@datagalaxy.com" dxyButton translate
        >Multilingual.Administration.contactCustomerService</a
    >
    <a dxyMultilingualReadMoreButton dxyButton color="secondary"></a>
</div>
