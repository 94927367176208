/**
 * @deprecated use CollectionsHelper.getEnumValues or getEnumValueNames
 */
export class EnumUtil {
    static getNames(e: any) {
        return Object.keys(e).filter((v) => isNaN(parseInt(v, 10)));
    }

    static getValues(e: any) {
        return Object.keys(e)
            .map((v) => parseInt(v, 10))
            .filter((v) => !isNaN(v));
    }

    static getStringValues(e: any): string[] {
        return Object.keys(e)
            .map((v) => e[v]?.toString())
            .filter((v) => !isNaN(v));
    }

    static getNamesAndValues(e: any) {
        return EnumUtil.getValues(e).map((v) => ({
            value: e[v] as string,
            id: v,
        }));
    }

    static getValuesAndNames(e: any) {
        return EnumUtil.getValues(e).map((v) => ({
            id: v,
            value: e[v] as string,
        }));
    }
}
