import { Component, Input, OnInit } from '@angular/core';
import { StringUtil } from '@datagalaxy/core-util';
import { UserService } from '../../services/user.service';
import { UserInsightItem } from '@datagalaxy/webclient/user/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyMiniAvatarsComponent } from '../../shared/shared-ui/dxy-mini-avatars/dxy-mini-avatars.component';
import { NgFor } from '@angular/common';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-insight-top-contributors',
    templateUrl: 'dxy-insight-top-contributors.component.html',
    styleUrls: ['dxy-insight-top-contributors.component.scss'],
    standalone: true,
    imports: [
        DxyCollapsibleComponent,
        NgFor,
        DxyMiniAvatarsComponent,
        MatLegacyTooltipModule,
        TranslateModule,
    ],
})
export class DxyInsightTopContributorsComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() public topUsers: UserInsightItem[];

    constructor(private userService: UserService) {
        super();
    }

    ngOnInit() {
        // remove deleted users
        this.topUsers = this.topUsers
            .filter((user) => this.userService.getUserName(user.UserGuid))
            .slice(0, 3);
    }

    public getDisplayedCount(user: UserInsightItem) {
        return StringUtil.formatNumber(user.Count);
    }
}
