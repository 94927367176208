import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    IVersioningUpdateModalResolve,
    IVersioningUpdateModalResult,
} from '../versioning-update-modal.types';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-versioning-update-modal',
    templateUrl: './dxy-versioning-update-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        FormsModule,
        DxyFieldTextComponent,
        DxyModalFooterComponent,
    ],
})
export class DxyVersioningUpdateModalComponent
    extends DxyBaseModalComponent<
        IVersioningUpdateModalResolve,
        IVersioningUpdateModalResult
    >
    implements OnInit
{
    public canCreate = false;

    constructor(
        dialogRef: MatDialogRef<
            DxyVersioningUpdateModalComponent,
            IVersioningUpdateModalResult
        >,
        @Inject(MAT_DIALOG_DATA) data: IVersioningUpdateModalResolve
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        const v = this.data.currentVersion;
        this.result = {
            versionName: v.VersionName,
            versionDescription: v.VersionDescription,
        };
    }

    public updateCanCreate() {
        this.canCreate = this.isFormValidAndModified();
    }

    @HostListener('keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent) {
        if (KeyboardUtil.isEnterKey(event)) {
            if (this.isFormValidAndModified()) {
                this.onCloseSubmit();
            } else {
                this.onCloseCancel();
            }
        }
    }

    private isFormValidAndModified() {
        const r = this.result,
            v = this.data.currentVersion;
        return (
            r.versionName &&
            (v.VersionName != r.versionName ||
                v.VersionDescription != r.versionDescription)
        );
    }
}
