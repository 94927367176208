<div class="section-header" (click)="onShowAll.emit()">
    <span class="section-title" [translate]="section.translateKey"></span>
    <span *ngIf="showBadge" class="dg5-badge">{{ totalItemCountText }}</span>
    <span *ngIf="isShowAllEnable" class="section-show-all" translate>
        UI.Spotlight.lblMoreResult
    </span>
    <div class="section-header-separator"></div>
    <span
        *ngIf="isClearSectionEnabled"
        (click)="onClearResults()"
        class="clear-wrapper"
        translate
    >
        UI.Spotlight.clearResults
    </span>
</div>
<div class="section-body">
    <dxy-spotlight-result-item
        *ngFor="let item of sectionItems"
        [spaceIdr]="spaceIdr"
        [item]="item"
        (onSelectFilterValue)="onSelectFilterItem.emit($event)"
        [section]="section"
        (lastSearchClick)="onClickFilteredSearch.emit($event)"
        (onClickResultItem)="onClickResultItem.emit()"
    ></dxy-spotlight-result-item>
    <dxy-show-more-button
        *ngIf="isShowMoreAvailable()"
        (click)="onShowMore()"
        [labelKey]="
            currentMaxCount == maxCount
                ? 'UI.Spotlight.lblMoreResult'
                : undefined
        "
    ></dxy-show-more-button>
</div>
