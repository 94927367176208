import { generateGuid } from '@datagalaxy/utils';

export function getContextId(dataId?: string | null): string {
    return dataId?.split(':')[0] || '';
}

export function getLocalId(dataId?: string | null): string {
    if (!dataId) {
        return '';
    }
    const parsedId = dataId.split(':');
    return parsedId.length == 2 ? parsedId[1] : dataId;
}

export function getReferenceId(contextId: string, localId: string) {
    return `${getContextId(contextId)}:${getLocalId(localId)}`;
}

export function isSpaceReferenceId(dataId: string) {
    return !!dataId && getLocalId(dataId) == getContextId(dataId);
}

export function generateReferenceId(contextId: string) {
    return getReferenceId(contextId, generateGuid());
}
