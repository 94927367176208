import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { DataType } from './data-type';
import { CRefIdr, Ref } from '../ref';

/** Ref&lt;DataType&gt; legacy generic format adapter */
@inheritSerialization(CRefIdr)
export class CDataTypeRef extends CRefIdr<DataType> {
    readonly serverType = ServerType.DataType;
}

@inheritSerialization(Ref)
export class DataTypeRef extends Ref<DataType> {
    static fromIds(ids: string[]) {
        return ids?.map(DataTypeRef.fromId) ?? [];
    }

    static fromId(id: string) {
        return new DataTypeRef(id);
    }

    constructor(id?: string) {
        super(ServerType.DataType, id);
    }
}
