import { DgModuleDefinition } from '../dg-module-definition';
import { DgModuleName } from '../dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export class GlossaryDgModule extends DgModuleDefinition {
    public name: DgModuleName = 'Glossary';
    public translateKey = 'DgServerTypes.DataGalaxyModule.Glossary';
    public glyphClass = 'glyph-glossary';
    public colorGlyphClass = 'functional-static-color';
    public graphicalColor = GraphicalColor.Green;
    public position = 1;
    public categoryKey = 'dataKnowledgeCatalog';
}

export default new GlossaryDgModule();
