<div class="match-fields">
    <!--Left Menu-->
    <div #sourceFieldsList class="source-fields-menu">
        <span class="section-title" translate
            >Import.GenericImportWizard.CsvPreImport.Mapping.FieldsTitle</span
        >
        <span class="unmapped-fields-lbl">{{ getUnmappedFieldsText() }}</span>

        <mat-checkbox [(ngModel)]="hideMatchedFields">
            <span translate
                >Import.GenericImportWizard.CsvPreImport.Mapping.HideMappedFieldsLbl</span
            >
        </mat-checkbox>

        <dxy-search-input
            *ngIf="isSearchBarVisible()"
            placeholderKey="Import.Wizard.MatchFields.FilterByName"
            [searchString]="searchString"
            (onSearchString)="onSearchField($event.searchString)"
        >
        </dxy-search-input>

        <div class="draggable-items">
            <div
                *ngFor="let f of fields"
                class="dg5-match-tag"
                [class.disabled-tag]="isImportLoading"
                [hidden]="f.isFilteredOut || (hideMatchedFields && f.isMatched)"
                dxyDraggableField
                [dragData]="f.dragName()"
                [isDisabled]="isImportLoading"
            >
                <!-- TODO vbo : since wizard rework : we lost the attributes highlight when dragging a field : challenge Xavier & Debo -->
                <div class="match-tag-container" [class.matched]="f.isMatched">
                    <span class="match-tag-text">{{ f.name }}</span>
                </div>
            </div>
        </div>
    </div>

    <!--Right Content-->
    <div class="match-containers">
        <span class="section-title" translate
            >Import.GenericImportWizard.CsvPreImport.Mapping.DropZoneTitle</span
        >
        <span class="section-description" translate
            >Import.GenericImportWizard.CsvPreImport.Mapping.DropZoneDescription</span
        >
        <div *ngIf="canOverrideEmptyValues" class="warning-message">
            <mat-checkbox [(ngModel)]="isEmptyValuesOverride">
                <span translate
                    >Import.GenericImportWizard.CsvPreImport.Mapping.OverrideWarningLbl</span
                >
            </mat-checkbox>
            <span *ngIf="isEmptyValuesOverride" class="warning-text" translate
                >Import.GenericImportWizard.CsvPreImport.Mapping.OverrideWarningText</span
            >
        </div>
        <div class="match-container" *ngFor="let g of categories">
            <div
                *ngIf="!isCurrentOperationHardCoded"
                class="section-header"
                (click)="onClickCategory(g)"
            >
                <button mat-icon-button>
                    <i
                        class="glyph-arrow-drop-down"
                        [class.glyph-arrow-drop-down--collapsed]="g.isCollapsed"
                    ></i>
                </button>
                <span class="section-title">{{ g.title }}</span>
            </div>

            <!--Dynamic-->
            <div
                *ngIf="!isCurrentOperationHardCoded"
                class="match-container-content"
                [class.section-expanded]="!g.isCollapsed"
            >
                <dxy-attribute-match
                    *ngFor="let it of g.items"
                    class="match-container-content-item"
                    [item]="it"
                    (onMatched)="onMatched($event)"
                    (onUnmatched)="onUnmatched($event)"
                    [disabledMatch]="isImportLoading"
                >
                </dxy-attribute-match>
            </div>

            <!--Hard Coded-->
            <div
                *ngIf="isCurrentOperationHardCoded"
                class="match-container-content hardcoded"
            >
                <!--DataProcessings => DataProcessingOutput-->
                <div
                    *ngIf="
                        isHardCodedVisible(EOperationType.DataProcessingOutput)
                    "
                    class="hardcoded-outputcolumns"
                >
                    <div class="hardcoded-header">
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('DataProcessingPath')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('DataProcessingType')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>

                    <div class="hardcoded-columns-content">
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnIn
                            </p>
                        </div>
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnProcessing
                            </p>
                            <div class="circle">
                                <svg
                                    class="arrow"
                                    width="42px"
                                    height="26px"
                                    viewBox="0 0 21 13"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="Import-3_Traitement-Colonnes-out"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        transform="translate(-344.000000, -394.000000)"
                                    >
                                        <g
                                            id="Page-1-Copy"
                                            transform="translate(344.000000, 395.000000)"
                                            stroke-width="2"
                                        >
                                            <path
                                                d="M0,0.0001 L3.654,0.0001 C5.7,0.0001 7.57,1.0701 8.485,2.7641 L9.82,5.2361 C10.735,6.9301 12.605,8.0001 14.651,8.0001 L19,8.0001"
                                                id="Stroke-1"
                                            ></path>
                                            <polyline
                                                id="Stroke-3"
                                                points="16.0303 5.0303 19.0003 8.0003 16.0303 10.9693"
                                            ></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnOut
                            </p>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('CatalogPath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('CatalogType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>
                </div>

                <!--DataProcessings => DataProcessingInput-->
                <div
                    *ngIf="
                        isHardCodedVisible(EOperationType.DataProcessingInput)
                    "
                    class="hardcoded-inputcolumns"
                >
                    <div class="hardcoded-header">
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('DataProcessingPath')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('DataProcessingType')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>
                    <div class="hardcoded-columns-content">
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnIn
                            </p>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('CatalogPath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('CatalogType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnProcessing
                            </p>
                            <div class="circle">
                                <svg
                                    class="arrow"
                                    width="42px"
                                    height="26px"
                                    viewBox="0 0 21 13"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="Import-3_Traitement-Colonnes-out"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        transform="translate(-344.000000, -394.000000)"
                                    >
                                        <g
                                            id="Page-1-Copy"
                                            transform="translate(344.000000, 395.000000)"
                                            stroke-width="2"
                                        >
                                            <path
                                                d="M0,0.0001 L3.654,0.0001 C5.7,0.0001 7.57,1.0701 8.485,2.7641 L9.82,5.2361 C10.735,6.9301 12.605,8.0001 14.651,8.0001 L19,8.0001"
                                                id="Stroke-1"
                                            ></path>
                                            <polyline
                                                id="Stroke-3"
                                                points="16.0303 5.0303 19.0003 8.0003 16.0303 10.9693"
                                            ></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnOut
                            </p>
                        </div>
                    </div>
                </div>

                <!--DataProcessings => DataProcessingItems-->
                <div
                    *ngIf="
                        isHardCodedVisible(EOperationType.DataProcessingItems)
                    "
                    class="hardcoded-dpi"
                >
                    <div class="hardcoded-header">
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('DataProcessingPath')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>
                    <div class="hardcoded-columns-content">
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnIn
                            </p>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('CatalogInPath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item subtype"
                                [item]="getAttributeItem('CatalogInType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnDpi
                            </p>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('Technical Label')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('Functional Label')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('Type')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('Summary')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('Description')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="column-container">
                            <p class="section-title" translate>
                                Import.Wizard.DataProcessings.ColumnOut
                            </p>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('CatalogOutPath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item subtype"
                                [item]="getAttributeItem('CatalogOutType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>
                </div>

                <!--Glossary, DataProcessing, Software => Relations (EntityLinks) -->
                <div
                    *ngIf="isEntityLinksOperation()"
                    class="hardcoded-relations"
                >
                    <div class="match-container-content-item left-item">
                        <dxy-attribute-match
                            [item]="getAttributeItem('SourceEntityPath')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                        <dxy-attribute-match
                            [item]="getAttributeItem('SourceEntityType')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>
                    <svg
                        class="line-dot"
                        width="6px"
                        height="6px"
                        viewBox="0 0 5 5"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs></defs>
                        <g
                            id="Import-3_Relation"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            transform="translate(-373.000000, -311.000000)"
                        >
                            <rect
                                id="Rectangle-2"
                                fill="#8E8E8E"
                                x="373"
                                y="311"
                                width="5"
                                height="5"
                            ></rect>
                        </g>
                    </svg>
                    <svg
                        class="line1"
                        viewBox="0 0 40 2"
                        width="40"
                        height="7"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            stroke-dasharray="5, 5"
                            x1="0"
                            y1="2"
                            x2="40"
                            y2="2"
                        ></line>
                    </svg>
                    <dxy-attribute-match
                        class="match-container-content-item middle-item"
                        [item]="getAttributeItem('EntityLinkType')"
                        (onMatched)="onMatched($event)"
                        (onUnmatched)="onUnmatched($event)"
                        [disabledMatch]="isImportLoading"
                    >
                    </dxy-attribute-match>
                    <svg
                        class="line2"
                        viewBox="0 0 40 2"
                        width="40"
                        height="7"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            stroke-dasharray="5, 5"
                            x1="0"
                            y1="2"
                            x2="40"
                            y2="2"
                        ></line>
                    </svg>
                    <svg
                        class="arrow"
                        width="6px"
                        height="6px"
                        viewBox="0 0 6 6"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs></defs>
                        <g
                            id="Import-3_Relation"
                            stroke-width="2"
                            fill="none"
                            fill-rule="evenodd"
                            transform="translate(-533.000000, -311.000000)"
                        >
                            <polyline
                                id="Path-22-Copy"
                                points="534.027946 317 537 314.014038 534 311"
                            ></polyline>
                        </g>
                    </svg>
                    <div class="match-container-content-item right-item">
                        <dxy-attribute-match
                            [item]="getAttributeItem('LinkedEntityPath')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                        <dxy-attribute-match
                            [item]="getAttributeItem('LinkedEntityType')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>
                </div>

                <!--Models => PKs-->
                <div
                    *ngIf="isHardCodedVisible(EOperationType.PKs)"
                    class="hardcoded-pks"
                >
                    <div class="table-layout">
                        <div class="row-container">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('TablePath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('TableType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="row-container">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ColumnName')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('PkOrder')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="row-container">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('PKTechnicalName')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>
                </div>

                <!--Models => FKs-->
                <div
                    *ngIf="isHardCodedVisible(EOperationType.FKs)"
                    class="hardcoded-fks"
                >
                    <div class="left-content">
                        <div class="top">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('PkTablePath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('PkTableType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="middle">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('PkColumnName')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                        <div class="bottom">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('PKTechnicalName')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>
                    <div class="middle-content">
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('FKTechnicalName')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('FKDisplayName')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>
                    <div class="right-content">
                        <div class="top">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ChildTablePath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <div class="bottom">
                                <dxy-attribute-match
                                    class="match-container-content-item"
                                    [item]="getAttributeItem('ChildTableType')"
                                    (onMatched)="onMatched($event)"
                                    (onUnmatched)="onUnmatched($event)"
                                    [disabledMatch]="isImportLoading"
                                >
                                </dxy-attribute-match>
                            </div>
                        </div>
                        <div class="middle">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ColumnName')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>
                    <svg
                        class="connector"
                        xmlns="http://www.w3.org/2000/svg"
                        width="280"
                        height="65"
                        viewBox="0 0 1912 1024"
                    >
                        <title></title>
                        <g id="icomoon-ignore"></g>
                        <path
                            fill="none"
                            stroke-dasharray="40"
                            stroke="#979797"
                            stroke-width="20.0784"
                            stroke-miterlimit="4"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            d="M-600 1006.483c362.898 0 605.219 0 1000.962 0 182.615 0 314.949-2.392 314.949-180.733s0-501.093 0-611.263c0-110.17 53.348-196.97 176.934-196.97 82.39 0 313.457 0 1800 0"
                        ></path>
                    </svg>

                    <svg
                        class="dot"
                        width="5px"
                        height="5px"
                        viewBox="0 0 5 5"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs></defs>
                        <g
                            id="Import-3_Relation"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            transform="translate(-373.000000, -311.000000)"
                        >
                            <rect
                                id="Rectangle-2"
                                fill="#8E8E8E"
                                x="373"
                                y="311"
                                width="5"
                                height="5"
                            ></rect>
                        </g>
                    </svg>

                    <svg
                        class="arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="6"
                        viewBox="0 0 853 1024"
                    >
                        <title></title>
                        <g id="icomoon-ignore"></g>
                        <path
                            fill="none"
                            stroke="#b2b2b2"
                            stroke-width="170.6667"
                            stroke-miterlimit="4"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            d="M677.564 1024l-507.231-509.604 512-514.396"
                        ></path>
                    </svg>
                </div>

                <!--Models => FFKs-->
                <div
                    *ngIf="isHardCodedVisible(EOperationType.FFKs)"
                    class="hardcoded-ffks"
                >
                    <div class="left-content">
                        <div class="top">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ParentTablePath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ParentTableType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>
                    <div class="middle-content">
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('Functional Label')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                        <dxy-attribute-match
                            class="match-container-content-item"
                            [item]="getAttributeItem('Summary')"
                            (onMatched)="onMatched($event)"
                            (onUnmatched)="onUnmatched($event)"
                            [disabledMatch]="isImportLoading"
                        >
                        </dxy-attribute-match>
                    </div>
                    <div class="right-content">
                        <div class="top">
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ChildTablePath')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                            <dxy-attribute-match
                                class="match-container-content-item"
                                [item]="getAttributeItem('ChildTableType')"
                                (onMatched)="onMatched($event)"
                                (onUnmatched)="onUnmatched($event)"
                                [disabledMatch]="isImportLoading"
                            >
                            </dxy-attribute-match>
                        </div>
                    </div>

                    <svg
                        class="connector"
                        xmlns="http://www.w3.org/2000/svg"
                        width="280"
                        height="65"
                        viewBox="0 0 1912 1024"
                    >
                        <title></title>
                        <g id="icomoon-ignore"></g>
                        <path
                            fill="none"
                            stroke-dasharray="40"
                            stroke="#979797"
                            stroke-width="20.0784"
                            stroke-miterlimit="4"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            d="M-0.023 1006.483c362.898 0 605.219 0 1300.962 0 182.615 0 314.949-2.392 314.949-180.733s0-501.093 0-611.263c0-110.17 53.348-196.97 176.934-196.97 82.39 0 313.457 0 2000.201 0"
                        ></path>
                    </svg>

                    <svg
                        class="dot"
                        width="5px"
                        height="5px"
                        viewBox="0 0 5 5"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs></defs>
                        <g
                            id="Import-3_Relation"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            transform="translate(-373.000000, -311.000000)"
                        >
                            <rect
                                id="Rectangle-2"
                                fill="#8E8E8E"
                                x="373"
                                y="311"
                                width="5"
                                height="5"
                            ></rect>
                        </g>
                    </svg>

                    <svg
                        class="arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="6"
                        viewBox="0 0 853 1024"
                    >
                        <title></title>
                        <g id="icomoon-ignore"></g>
                        <path
                            fill="none"
                            stroke="#b2b2b2"
                            stroke-width="170.6667"
                            stroke-miterlimit="4"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            d="M677.564 1024l-507.231-509.604 512-514.396"
                        ></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>
