<dxy-splash-screen></dxy-splash-screen>
<div
    class="form-section"
    [@hideShow]="hideControls ? 'hide' : 'show'"
    (@hideShow.done)="onSubmissionTransitionEnd($event)"
>
    <h1 [innerHTML]="'UI.UserFirstAccess.header' | translate"></h1>

    <!-- Avatar -->
    <dxy-image-input
        [params]="avatarInputParams"
        class="big-initials"
    ></dxy-image-input>
    <span class="user-name">{{ user.FullName }}</span>

    <!-- First access form -->
    <form
        class="account-form"
        [formGroup]="firstAccessForm"
        (ngSubmit)="submitForm()"
    >
        <!-- user language field -->
        <dxy-field-select
            formControlName="language"
            [labelText]="'UI.UserFirstAccess.languageFieldLabel' | translate"
            [adapter]="selectedLanguage"
            [mandatory]="true"
            [required]="true"
        ></dxy-field-select>

        <!-- user profile field ­-->
        <dxy-field-select
            #profile
            formControlName="profile"
            [labelText]="'UI.UserFirstAccess.profileFieldLabel' | translate"
            [hint]="'UI.UserFirstAccess.profileFieldHint' | translate"
            [adapter]="selectedProfile"
            [mandatory]="true"
            [required]="true"
        ></dxy-field-select>

        <!-- DG legal agreement ­-->
        <dxy-checkbox
            formControlName="acceptedTermsOfUse"
            [label]="'UI.UserFirstAccess.legalTermsFieldLabel' | translate"
        ></dxy-checkbox>
        <!-- Privacy policy agreement ­-->
        <dxy-checkbox
            formControlName="acceptedPrivacyPolicy"
            [label]="'UI.UserFirstAccess.privacyPolicyFieldLabel' | translate"
        ></dxy-checkbox>

        <!-- Submit button -->
        <button
            mat-flat-button
            [disabled]="!firstAccessForm.valid"
            class="account-button"
            dxyDataTestId="first-access-submit-button"
            color="primary"
            type="submit"
        >
            <span translate> UI.Global.btnSubmit </span>
        </button>
    </form>
</div>
