import { Component, NgZone, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { ToastActionBtn, ToastType } from './toast.types';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { NgClass, NgIf, NgFor } from '@angular/common';

/**
 * ## Role
 * Small section to display short-lived message
 */
@Component({
    selector: 'dxy-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['toast.component.scss'],
    animations: [
        trigger('flyInOut', [
            state(
                'inactive',
                style({
                    opacity: 0,
                })
            ),
            transition(
                'inactive => active',
                animate(
                    '500ms cubic-bezier(.68, -.55, .265, 1.55)',
                    keyframes([
                        style({
                            transform: 'translate3d(100%, 0, 0)',
                        }),
                        style({
                            transform: 'none',
                            opacity: 1,
                        }),
                    ])
                )
            ),
            transition(
                'active => removed',
                animate(
                    '500ms cubic-bezier(.68, -.55, .265, 1.55)',
                    keyframes([
                        style({
                            opacity: 1,
                        }),
                        style({
                            transform: 'translate3d(100%, 0, 0)',
                            opacity: 0,
                        }),
                    ])
                )
            ),
        ]),
    ],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, DxyButtonDirective, DxyIconButtonDirective],
})
export class DxyToastComponent extends Toast implements OnInit {
    public notifTitle?: string;
    public notifMessage?: string | null;
    public showCloseBtn = true;
    public hasTimerBar = true;
    public hasActionBtn?: boolean;
    public actionsBtn?: ToastActionBtn[];
    public get timerBarProgress() {
        // If toast is active & hovered : hide timer bar
        if (this.state.value == 'active' && this.width <= 0) {
            return 0;
        }
        return 100 - this.width;
    }
    public get colorClass() {
        switch (this.color) {
            case ToastType.Success:
                return 'bg-green';
            case ToastType.Error:
                return 'bg-red';
            case ToastType.Info:
                return 'bg-blue';
            case ToastType.Warning:
                return 'bg-orange';
            default:
                return '';
        }
    }

    public color?: ToastType;

    constructor(
        toastrService: ToastrService,
        toastPackage: ToastPackage,
        ngZone: NgZone
    ) {
        super(toastrService, toastPackage, ngZone);
    }

    ngOnInit() {
        this.notifTitle = this.title;
        this.notifMessage = this.message;
        this.showCloseBtn = this.options.closeButton;
        this.hasTimerBar = this.options.progressBar;
        this.hasActionBtn = !!this.actionsBtn?.length;
    }

    public getActionBtnColor(btn: ToastActionBtn) {
        return btn.isPrimary ? 'primary' : 'secondary';
    }
}
