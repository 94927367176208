<div class="languages">
    <dxy-field-text
        labelKey="Multilingual.Administration.sourceLanguage"
        [ngModel]="sourceLanguageName"
        [readonly]="true"
    ></dxy-field-text>
    <i class="glyph-arrow-right"></i>
    <dxy-field-text
        labelKey="Multilingual.Administration.targetLanguage"
        [ngModel]="targetLanguageName"
        [readonly]="true"
    ></dxy-field-text>
</div>

<ng-container *ngIf="lexiconStatistics$ | async as statistics">
    <div *ngIf="statistics.entryCount">
        <div class="dxy-label" translate>Multilingual.lexicon</div>
        <div class="lexicon-file-name">
            <i class="glyph-csv-file"></i> {{ lexiconFileName }}
        </div>
        <div class="lexicon-statistics">
            {{
                'Multilingual.Administration.LexiconForm.statisticsShort' | translate:{
                    languagesCount: statistics.languages.length,  
                    termsCount: statistics.entryCount,
                }
            }}
        </div>
    </div>
</ng-container>

<dxy-attribute-selector
    [ngModel]="attributes"
    [readonly]="true"
    labelKey="Multilingual.attributes"
>
</dxy-attribute-selector>
