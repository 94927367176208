import { IWidthHeight, Point } from '@datagalaxy/core-2d-util';

export interface IGridOptions {
    /** defaults to 10 */
    cellSize?: number | IWidthHeight;
    /** defaults to 1 */
    dotRadius?: number;
    /** defaults to *gray* */
    dotColor?: string;
    /** defaults to *false* */
    visible?: boolean;
    /** set to true to enable snap-to-grid on click, move, resize */
    magnetic?: boolean;
}

export enum GridMode {
    off = 0,
    /** magnetic */
    hidden,
    /** visible & magnetic */
    on,
}

export interface GridComponentProps {
    cellSize?: number | IWidthHeight;
    dotRadius?: number;
    dotColor?: string;
    width: number;
    height: number;
    position: Point;
}
