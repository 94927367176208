import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { IActionOption, withLoading } from '@datagalaxy/core-ui';
import { ISuggestion, ISuggestionGroup } from '../suggestion.types';
import { SuggestionService } from '../suggestion.service';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { DxyActionListComponent } from '@datagalaxy/core-ui';

/**
 * ## Role
 * Buttons to accept or decline a suggestion
 */
@Component({
    selector: 'app-suggestion-choice-actions',
    templateUrl: 'suggestion-choice-actions.component.html',
    styleUrls: ['suggestion-choice-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DxyActionListComponent, NgIf, SpinnerComponent, AsyncPipe],
})
export class SuggestionChoiceActionsComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() suggestion: ISuggestion | ISuggestionGroup;
    @Input() featureCodePrefix: string;
    @Input() hideAcceptBtn?: boolean;

    public actions?: IActionOption[];

    private get featureCode() {
        return `${this.featureCodePrefix}${this.featureCodeSuffix}`;
    }
    private get featureCodeSuffix() {
        return SuggestionType[this.suggestion.type]?.toUpperCase();
    }

    constructor(
        private functionalLogService: FunctionalLogService,
        private suggestionService: SuggestionService
    ) {
        super();
    }

    ngOnInit() {
        this.initActions();
    }

    @withLoading()
    public async applySuggestion(accepted: boolean) {
        this.functionalLogService.logFunctionalAction(
            this.featureCode,
            CrudOperation.A,
            accepted ? CrudActionType.Accept : CrudActionType.Decline
        );
        return await this.suggestionService.applySuggestion(
            this.suggestion,
            accepted
        );
    }

    private initActions() {
        this.actions = [
            {
                callback: () => this.applySuggestion(false),
                tooltipTranslateKey: 'UI.Suggestions.MultiValue.DeclineTT',
                glyphClass: 'glyph-cancelsearch decline-btn',
                alwaysVisible: true,
            },
            {
                hidden: this.hideAcceptBtn,
                callback: () => this.applySuggestion(true),
                tooltipTranslateKey: 'UI.Suggestions.MultiValue.AcceptTT',
                glyphClass: 'glyph-check accept-btn',
                alwaysVisible: true,
            },
        ];
    }
}
