import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseMasterData, ITypeMetadata } from '../master-data';
import { IHasTechnicalName } from '@datagalaxy/data-access';
import {
    EntityType,
    HierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ModelRef } from '../model';
import { ColumnRef } from '../column';
import { PrimaryKeyRef } from '../primary-key';

export interface ITable {
    ReferenceId: string;
}

// NOTE: To simplify i18n translation automation, we name this enum TableType, instead of DataStructureType, used on server, temporarily
export enum TableType {
    Table = 0,
    // Folder = 1,
    File = 2,
    Document = 3,
    SubStructure = 4,
    Tag = 5,
    View = 6,
}
SerializableEnumeration(TableType);

@inheritSerialization(BaseMasterData)
export class Table extends BaseMasterData implements IHasTechnicalName {
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'Tables',
        childPropertyName: 'ModelRef',
    };

    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public TechnicalName!: string;
    @deserialize public IsNameSyncEnabled = true;
    @deserialize public TechnicalComments!: string;
    @deserializeAs(TableType) public Type!: TableType; //#archi-abasedata-cleanup-info: seems not sent by the server
    @deserializeAs(Number) public EntityType!: EntityType;
    @deserializeAs(HierarchicalData) public HddData!: HierarchicalData;
    @deserializeAs(ModelRef.fromId, 'ModelId') public ModelRef = new ModelRef();
    @deserializeAs(ColumnRef.fromIds, 'ColumnIds') public Columns: ColumnRef[] =
        []; //#abasedata-metadata
    @deserializeAs(PrimaryKeyRef.fromId, 'PrimaryKeyId') public PrimaryKeyRef =
        new PrimaryKeyRef();

    public readonly ServerType = ServerType.Table;

    static OnDeserialized(instance: Table) {
        //#archi-d15n this should be sent as an empty array instead of null
        instance.Columns ??= [];
    }

    constructor(referenceId?: string) {
        super();
        if (referenceId) {
            super.setId(referenceId);
        }
    }
}
