import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtil } from '@datagalaxy/core-util';
import * as moment from 'moment';
import { Moment } from 'moment';

@Pipe({
    name: 'dateFormat',
    standalone: true,
})
export class DateFormatPipe implements PipeTransform {
    transform(date: string | Date | Moment, withTimePart?: boolean): any {
        return DateTimeUtil.formatForDisplay(
            date,
            withTimePart,
            moment.locale(),
            true
        );
    }
}
