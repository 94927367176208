import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    getViewIdentifierFromModuleDefinition,
    ViewIdentifier,
} from '../../shared/util/ViewIdentifier';
import { StateName } from '@datagalaxy/webclient/routing';
import { NavigationService } from '../../services/navigation.service';
import { ModuleStore } from '../../module/module.store';
import {
    DgModuleDefinition,
    DgModuleName,
} from '@datagalaxy/shared/dg-module/domain';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { DxyNavSidebarItemComponent } from '../dxy-nav-sidebar-item/dxy-nav-sidebar-item.component';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-nav-sidebar-module',
    templateUrl: './nav-sidebar-module.component.html',
    styleUrls: ['./nav-sidebar-module.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, DxyNavSidebarItemComponent, AsyncPipe],
})
export class NavSidebarModuleComponent extends DxyBaseComponent {
    @Input() showItemsTooltip = true;
    @Input() spaceIdr: SpaceIdentifier;

    protected accessibleModules$ = this.moduleStore.selectModules();

    protected readonly ViewIdentifier = ViewIdentifier;
    protected readonly StateName = StateName;

    constructor(
        private navigationService: NavigationService,
        private moduleStore: ModuleStore
    ) {
        super();
    }

    protected getViewIdentifier(module: DgModuleDefinition) {
        return getViewIdentifierFromModuleDefinition(module);
    }

    protected getStateName(moduleName: DgModuleName) {
        switch (moduleName) {
            case 'Glossary':
                return StateName.Glossary;
            case 'Catalog':
                return StateName.Modeler;
            case 'Processing':
                return StateName.DataProcessing;
            case 'Usage':
                return StateName.Software;
        }
    }

    protected async navigateTo(itemIdentifier: string) {
        await this.navigationService.goTo(itemIdentifier, this.spaceIdr);
    }
}
