import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { deserializeAs } from 'cerialize';

/**
 * API definition: DataGalaxy.Core.Data.Service.Entity.TypeMetaInfo.cs
 */
export class EntityTypeMeta {
    constructor(meta: AttributeMetaInfo[]) {
        this.Attributes = meta;
    }

    @deserializeAs(AttributeMetaInfo) public Attributes: AttributeMetaInfo[];
}
