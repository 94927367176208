import { Injectable } from '@angular/core';
import {
    GlossaryGenerationEndedEvent,
    SuggestionsReminderEvent,
} from '@datagalaxy/dg-object-model';
import { IDgEventInterpolationProvider } from './dg-event-interpolation-provider';
import { metaBotUserFullName } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class MetaBotInterpolationProvider
    implements IDgEventInterpolationProvider
{
    public static eventTypes = [
        SuggestionsReminderEvent,
        GlossaryGenerationEndedEvent,
        GlossaryGenerationEndedEvent,
    ];

    constructor() {}

    public resolveParameters() {
        return {
            authorFullName: metaBotUserFullName,
        };
    }
}
