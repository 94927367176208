import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    AsyncPipe,
    JsonPipe,
    NgClass,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { CheckboxComponent } from '@datagalaxy/ui/forms';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { IconComponent } from '@datagalaxy/ui/icon';
import { GridCellComponent } from '../grid-cell/grid-cell.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridConfig } from '../grid-config';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { Cell, Column, Header, Row } from '@tanstack/table-core';
import { TColDef } from '../grid-column/grid-column.types';
import { PersistedGridState } from '../grid-persisted-state/grid-persisted-state.types';
import { GridTable } from '../grid-table/grid-table';
import {
    CdkVirtualScrollViewport,
    ScrollingModule,
} from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { GridExpandButtonComponent } from '../grid-expand-button/grid-expand-button.component';
import {
    applyPersistedState,
    getPersistedState,
} from '../grid-persisted-state/grid-persisted-state.utils';
import { DomUtils } from '@datagalaxy/utils';
import { ServerSideSortEvent } from '../grid-sorting';

@Component({
    selector: 'dxy-grid',
    standalone: true,
    imports: [
        NgForOf,
        CheckboxComponent,
        GridCellComponent,
        CdkDrag,
        CdkDropList,
        AsyncPipe,
        SpinnerComponent,
        IconComponent,
        ScrollingModule,
        MatTooltipModule,
        JsonPipe,
        DxyButtonsModule,
        NgTemplateOutlet,
        EllipsisTooltipDirective,
        NgClass,
        GridExpandButtonComponent,
        NgIf,
    ],
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent<TRow = unknown>
    extends DxyBaseComponent
    implements OnChanges, OnInit, AfterViewInit
{
    @Input() config?: GridConfig<TRow>;
    @Input() items: TRow[] = [];
    @Input() gridState?: PersistedGridState;
    @Input() columns: TColDef<TRow>[] = [];

    @Output() rowClick = new EventEmitter<TRow>();
    @Output() gridStateChange = new EventEmitter<PersistedGridState>();
    @Output() selectionChange = new EventEmitter<TRow[]>();
    @Output() activeRowIdChange = new EventEmitter<string>();
    @Output() serverSideSort = new EventEmitter<ServerSideSortEvent>();

    @HostBinding('style.--table-height') get heightStyle() {
        if (this.config?.autoHeight) {
            let tableHeightInPx = (this.tableRows.length + 1) * this.rowHeight;
            if (this.config.horizontalScroll) {
                tableHeightInPx += 10;
            }
            return `${tableHeightInPx}px`;
        }
        return 'auto';
    }

    @HostBinding('style.--row-height') get rowHeightStyle() {
        return `${this.rowHeight}px`;
    }

    @HostBinding('class.horizontal-scroll') get horizontalScroll() {
        return this.config?.horizontalScroll;
    }

    @ViewChildren(GridCellComponent)
    private cellsComponents!: QueryList<GridCellComponent<TRow>>;
    @ViewChild(CdkVirtualScrollViewport)
    private cdkVirtualScrollViewport?: CdkVirtualScrollViewport;

    protected gridTable = new GridTable<TRow>();
    protected table$ = this.gridTable.table$;

    private resizingHeader?: Header<TRow, unknown>;
    private currentActivePath?: string[];
    private columnsSubject = new BehaviorSubject<TColDef<TRow>[]>([]);

    public get selection(): TRow[] {
        return this.gridTable.selection.map((row: Row<TRow>) => row.original);
    }

    public set selection(rows: TRow[]) {
        this.gridTable.setSelection(
            rows.map((r) => this.config?.getItemId(r) ?? '')
        );
    }

    public get columns$() {
        return this.columnsSubject.asObservable();
    }

    protected get rowHeight(): number {
        return this.config?.rowHeight ?? 50;
    }

    protected get selectionEnabled() {
        return this.config?.multiSelect;
    }

    protected get orderingEnabled() {
        return !this.config?.disableOrdering;
    }

    protected get tableRows() {
        return this.gridTable.table.getRowModel().rows;
    }

    protected get infiniteScrollLoading$() {
        return this.gridTable.scroll.loading$;
    }

    protected get isTree() {
        return this.gridTable.tree.enabled;
    }

    protected get activeRowId() {
        return this.currentActivePath?.[this.currentActivePath.length - 1];
    }

    private get availableWidth() {
        const staticUsedWidth = this.config?.multiSelect ? 60 : 0;
        return this.elementRef.nativeElement.clientWidth - staticUsedWidth;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private cd: ChangeDetectorRef
    ) {
        super();
        this.subscribeToResizeToRefreshCellsLayout();
        this.initSelectionChangeEventEmitter();
    }

    ngOnInit() {
        this.initTable();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.gridTable.fitColumnsToWidth(this.availableWidth);
        }, 1);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            ['columns', 'config'],
            () => {
                this.gridTable.setup(this.columns, this.config);
                this.columnsSubject.next(this.columns);
                this.gridTable.fitColumnsToWidth(this.availableWidth);
            },
            true
        );

        super.onChange(
            changes,
            'items',
            () => this.gridTable.setItems(this.items),
            true
        );

        super.onChange(
            changes,
            'gridState',
            () => this.onGridStateChange(),
            true
        );
    }

    /**
     * The Mouseup event is listened on the window to ensure that resizing
     * of the header is detected when the mouse is released outside the grid.
     */
    @HostListener('window:mouseup') onMouseUp() {
        if (this.resizingHeader) {
            this.refreshCellsLayout();
            this.gridStateChange.emit(getPersistedState(this.gridTable.table));
        }
        this.resizingHeader = undefined;
    }

    public resetColumns() {
        this.gridTable.resetColumns();
        this.gridTable.fitColumnsToWidth(this.availableWidth);
        this.gridStateChange.emit(getPersistedState(this.gridTable.table));
    }

    public isColumnVisible(colId: string) {
        return this.gridTable.isColumnVisible(colId);
    }

    public toggleColumnVisibility(colId: string) {
        this.gridTable.toggleColumnVisibility(colId, this.availableWidth);
        this.gridTable.fitColumnsToWidth(this.availableWidth);
        this.gridStateChange.emit(getPersistedState(this.gridTable.table));
    }

    public scrollToIndex(
        id: string,
        behavior: ScrollBehavior = 'smooth'
    ): void {
        const row = this.tableRows.find((row) => row.id === id);

        if (!row) {
            return;
        }

        const flatIndex = this.tableRows.indexOf(row);
        this.cdkVirtualScrollViewport?.scrollToIndex(flatIndex, behavior);

        this.cd.detectChanges();
    }

    public async focusRow(path: string[]) {
        if (!path?.length) {
            return;
        }

        for (const route of path.slice(0, -1)) {
            const row = this.tableRows.find((r) => r.id === route);

            if (!row) {
                break;
            }

            if (row.getIsExpanded()) {
                continue;
            }
            await this.onToggleExpansion(row);
        }

        const lastRoute = path[path.length - 1];
        const row = this.tableRows.find((r) => r.id === lastRoute);

        if (!row) {
            return;
        }

        this.scrollToIndex(row.id, 'auto');
        this.currentActivePath = path;
        this.cd.detectChanges();
    }

    public getRowRectPosition(id: string): DOMRect | undefined {
        const elem = this.elementRef.nativeElement.querySelector(
            `[id="${id}"]`
        );

        return elem?.getBoundingClientRect();
    }

    public refreshCellsLayout() {
        this.cellsComponents.forEach((c) => c.refreshLayout());
    }

    protected getCellColumn(cell: Cell<TRow, unknown>) {
        return cell.column.columnDef.meta as TColDef<TRow>;
    }

    protected getGroupingText(row: Row<TRow>): string | number {
        const column = this.columns.find((c) => c.id === row.groupingColumnId);

        if (!column) {
            return '';
        }

        const getGroupingText = column.getGroupingText;
        const groupingValue = row.groupingValue as string | number;

        return getGroupingText ? getGroupingText(groupingValue) : groupingValue;
    }

    protected isActive(row: Row<TRow>) {
        return row.getIsSelected() || this.activeRowId === row.id;
    }

    protected onHeaderCellDrop(event: CdkDragDrop<Column<TRow>>) {
        const minIndex = this.columns.filter((c) => c.fixed).length;
        this.gridTable.reorderHeader(
            event.previousIndex,
            Math.max(event.currentIndex, minIndex)
        );
        this.gridStateChange.emit(getPersistedState(this.gridTable.table));
    }

    protected onColumnSorting(column: Column<TRow>) {
        if (!column.getCanSort()) {
            return;
        }

        if (!this.config?.sorting?.isServerSide) {
            column.toggleSorting();
        } else {
            this.serverSideSort.emit({
                colId: column.id,
                direction: column.getNextSortingOrder(),
            });
        }
    }

    protected async onToggleExpansion(row: Row<TRow>) {
        await this.gridTable.tree.toggleExpand(row);
    }

    protected trackByHeader(_index: number, header: Header<TRow, unknown>) {
        return header.column.id;
    }

    protected trackByRow(_index: number, row: Row<TRow>) {
        return row.id;
    }

    protected trackByCol(_index: number, col: TColDef<TRow>) {
        return col.id;
    }

    protected getSortIcon(header: Header<TRow, unknown>) {
        const sort = header.column.getIsSorted();

        if (!sort) {
            return 'glyph-unsorted';
        }
        return sort === 'asc' ? 'glyph-asc-sort' : 'glyph-desc-sort';
    }

    protected isRowLoadingChildren(row: Row<TRow>) {
        return this.gridTable.tree.isRowLoading(row.original);
    }

    protected onRowClick(row: Row<TRow>) {
        if (row.getIsGrouped()) {
            return;
        }
        this.currentActivePath = this.getRowPathIds(row);
        this.rowClick.emit(row.original);
    }

    protected onResizeHeader(event: MouseEvent, header: Header<TRow, unknown>) {
        this.resizingHeader = header;
        this.gridTable.onResizeHeader(event, header);
    }

    protected async onScrollIndexChange(index: number) {
        await this.gridTable.onScrollIndexChange(index);
    }

    private initTable() {
        this.gridTable.fitColumnsToWidth(this.availableWidth);
    }

    private onGridStateChange() {
        if (!this.gridState) {
            return;
        }
        applyPersistedState(this.gridTable.table, this.gridState);
        this.gridTable.fitColumnsToWidth(this.availableWidth);
    }

    private subscribeToResizeToRefreshCellsLayout() {
        super.subscribe(
            DomUtils.resizeObservable(this.elementRef.nativeElement, 100),
            () => {
                this.gridTable.fitColumnsToWidth(this.availableWidth);
                this.cdkVirtualScrollViewport?.checkViewportSize();
                this.cd.detectChanges();
                this.refreshCellsLayout();
            }
        );
    }

    private initSelectionChangeEventEmitter() {
        // On every table state change
        super.subscribe(
            this.gridTable.table$.pipe(
                map((t) =>
                    t.getSelectedRowModel().flatRows.map((f) => f.original)
                ),
                // Check that selection has changed compared to the previous state
                distinctUntilChanged(
                    (prev, curr) =>
                        prev.map((p) => this.config?.getItemId(p)).join() ===
                        curr.map((p) => this.config?.getItemId(p)).join()
                )
            ),
            (rows) => {
                // if selection changed, emit the new selection
                this.selectionChange.emit(rows);
            }
        );
    }

    private getRowPathIds(row: Row<TRow>) {
        return [...row.getParentRows().map((r) => r.id), row.id];
    }
}
