import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DialogType, DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IListOption } from '@datagalaxy/core-ui';
import {
    BulkActionTypes,
    IEntityBulkEditModalInputs,
} from './entity-bulk-edit-modal.types';
import {
    EntityFormKind,
    IEntityForm,
} from '../interfaces/entity-form.interface';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import {
    EntityLinkTypeKind,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { AMIFieldSelectAdapter } from '../../fields/field-select-adapters/AMIFieldSelectAdapter';
import { EntityService } from '../services/entity.service';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { AttributeFieldInfo } from '../../attribute/attribute.types';
import { isUnsuccessfulApiError } from '@datagalaxy/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DxyLogFunctionalDirective } from '../../../directives/dxy-log-functional.directive';
import { DxySplitButtonComponent } from '@datagalaxy/core-ui';
import { DxyEntityAttributeInputComponent } from '../../attribute/dxy-entity-attribute-input/dxy-entity-attribute-input.component';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgForOf, NgIf } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'dxy-entity-bulk-edit-modal',
    templateUrl: './dxy-entity-bulk-edit-modal.component.html',
    styleUrls: ['./dxy-entity-bulk-edit-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        NgIf,
        SpinnerComponent,
        DxyFieldSelectComponent,
        DxyEntityAttributeInputComponent,
        DxySplitButtonComponent,
        DxyLogFunctionalDirective,
        NgForOf,
    ],
})
export class DxyEntityBulkEditModalComponent
    extends DxyBaseModalComponent<IEntityBulkEditModalInputs, void>
    implements OnInit
{
    //#region html bindings
    public splitBtnOptions: IListOption[] = [
        {
            labelKey: 'UI.BulkEdit.dropdown.update.title',
            descriptionTranslateKey: 'UI.BulkEdit.dropdown.update.subtitle',
            callback: () =>
                this.onSelectBulkEditAction(BulkActionTypes.Replace),
        },
        {
            labelKey: 'UI.BulkEdit.dropdown.add.title',
            descriptionTranslateKey: 'UI.BulkEdit.dropdown.add.subtitle',
            callback: () => this.onSelectBulkEditAction(BulkActionTypes.Append),
        },
    ];
    public readonly attributeAdapter: AMIFieldSelectAdapter;
    public isSaving = false;
    public isBulkEnabled = false;
    public isSummaryDisplayed = false;
    public errorMessage: string;
    public summaryMessage: string;
    public selectedFieldInfo: AttributeFieldInfo;
    public get hasBulkAction() {
        return this.hasWriteAccess && !this.errorMessage;
    }
    public get hasWriteAccess() {
        return this.data?.hasWriteAccess;
    }

    public get noWriteAccessEntityDataList() {
        return this.data.entityDataList.filter(
            (entity) => !entity.SecurityData.HasWriteAccess
        );
    }
    public get cancelButtonTranslateKey() {
        return this.isSummaryDisplayed || this.errorMessage
            ? 'UI.Dialog.btnClose'
            : 'UI.Dialog.btnCancel';
    }
    public get submitButtonTranslateKey() {
        return this.isAppendValue
            ? 'UI.Dialog.NewItem.lblAdd'
            : 'UI.Dialog.NewItem.lblSave';
    }
    public get featureCode() {
        return this.entityDataList?.length > 1
            ? 'REFERENCEDATAVALUES,U'
            : 'REFERENCEDATAVALUE,U';
    }
    public get showFooterDropDownButton() {
        return (
            !this.isClearValue &&
            this.hasBulkAction &&
            this.isReplaceOptionSupported
        );
    }
    //#endregion

    private entityForm: IEntityForm<unknown>;
    private entityData: EntityItem;
    private currentEntityAttributeValue: any; //#Archi-AttributeValues/typing (fbo) Which possible type(s) ?
    private isAppendValue = false;
    private isReplaceValue = false;
    private isClearValue = false;
    private get entityServerType() {
        return this.data?.entityServerType;
    }
    private get entityDataList() {
        return this.data?.entityDataList;
    }
    private get selectedAMI() {
        return this.selectedFieldInfo?.attributeMeta;
    }
    private get isReplaceOptionSupported() {
        return AttributeDataService.isReplaceOptionSupported(this.selectedAMI);
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
        private linkedObjectService: EntityLinkService,
        private dxyModalService: DxyModalService,
        private entityService: EntityService,
        private attributeDataService: AttributeDataService,
        dialogRef: MatDialogRef<DxyEntityBulkEditModalComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: IEntityBulkEditModalInputs
    ) {
        super(dialogRef, data);

        this.attributeAdapter = new AMIFieldSelectAdapter(
            attributeDataService,
            (a) => this.onChangeEntityAttribute(a)
        );
    }

    ngOnInit() {
        this.init();
    }

    public async onSubmit() {
        if (
            this.isClearValue ||
            (this.isReplaceValue &&
                !this.currentEntityAttributeValue?.length &&
                this.isMultiValue(this.selectedAMI))
        ) {
            const confirmed = await this.confirmSave();
            if (!confirmed) {
                return;
            }
        }
        await this.save();
    }

    public getSelectedCountInfo() {
        const count = this.entityDataList?.length;
        return this.translate.instant('UI.BulkEdit.selectedCount', { count });
    }

    public onSelectBulkEditAction(action: BulkActionTypes) {
        if (action == BulkActionTypes.Append) {
            this.isAppendValue = true;
            this.isReplaceValue = this.isClearValue = false;
        }
        if (action == BulkActionTypes.Replace) {
            this.isReplaceValue = true;
            this.isAppendValue = this.isClearValue = false;
        }
        if (action == BulkActionTypes.Clear) {
            this.isClearValue = true;
            this.isAppendValue = this.isReplaceValue = false;
        }
    }

    private init() {
        this.entityData = new EntityItem();
        const firstEntity = this.entityDataList?.[0];
        this.entityData.ReferenceId = firstEntity?.ReferenceId;
        this.entityData.VersionId = firstEntity?.VersionId;
        this.entityData.DataTypeName = ServerType[this.entityServerType];

        this.entityForm = this.buildEntityForm();

        let attributes = this.data?.entityAttributes;
        this.attributeAdapter.init(attributes, this.entityServerType);
    }

    private onChangeEntityAttribute(ami: AttributeMetaInfo) {
        const defaultValue = this.isBoolean(ami)
            ? false
            : !ami.DefaultValue &&
              AttributeDataService.isTagOrUserOrPersonAttribute(
                  ami.AttributeType
              )
            ? []
            : ami.DefaultValue;

        // remove then re-add the entity-attribute-input component,
        // which will compile the proper attribute-* component
        this.selectedFieldInfo = null;
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.selectedFieldInfo = new AttributeFieldInfo(
                ami,
                this.entityForm
            );
            this.setAttributeValue(defaultValue);
            this.changeDetector.detectChanges();
        });
    }

    private getBulkEditSummaryMsg() {
        return this.translate.instant(
            'UI.BulkEdit.attributeBulkUpdateSummary',
            {
                attributeName: this.selectedAMI.translatedDisplayName,
                countMsg: this.getSelectedCountInfo(),
            }
        );
    }

    private clearCurrentEntityAttributeValue() {
        const ami = this.selectedAMI;
        if (
            AttributeDataService.isReplaceOptionSupported(ami) ||
            AttributeDataService.isShortcutAttribute(ami.AttributeType)
        ) {
            this.currentEntityAttributeValue = [];
        } else if (this.isBoolean(ami)) {
            this.currentEntityAttributeValue = false;
        } else if (ami.AttributeType === AttributeMetaType.Text) {
            this.currentEntityAttributeValue = '';
        } else {
            this.currentEntityAttributeValue = null;
        }
    }

    private async save() {
        this.isSaving = true;

        let kind: EntityLinkTypeKind;
        let universalObjectLinkType: ObjectLinkType;
        const ami = this.selectedAMI;
        if (ami.AttributeType == AttributeMetaType.EntityLinkShortcut) {
            const kinds = this.data.availableLinkTypes.map(
                (a) => a.EntityLinkTypeKind
            );
            kind = ami.EntityLinkTypeKinds.find((eltk) => kinds.includes(eltk));
            universalObjectLinkType =
                this.linkedObjectService.getUniversalObjectLinkType(
                    kind,
                    ami.IsReversedEntityLink
                );
        } else {
            universalObjectLinkType = ami.ObjectLinkType;
        }

        this.isReplaceValue = this.getIsReplaceValue();

        if (this.isClearValue) {
            this.clearCurrentEntityAttributeValue();
        }

        try {
            await this.entityService.updateEntities(
                this.entityDataList?.map((e) => e.ReferenceId),
                this.entityDataList?.[0].VersionId,
                ami,
                this.currentEntityAttributeValue,
                universalObjectLinkType,
                this.isReplaceValue,
                this.isClearValue
            );
            this.summaryMessage = this.getBulkEditSummaryMsg();
            this.isSummaryDisplayed = true;
            this.selectedFieldInfo = null;
            this.attributeAdapter.current = null;
            this.isBulkEnabled = false;
        } catch (error) {
            if (isUnsuccessfulApiError(error) && error.error?.ErrorDetails) {
                this.errorMessage = error.error.ErrorDetails;
            }
            throw error;
        } finally {
            this.isSaving = false;
        }
    }

    private updateAvailableActions(attributeValue: any) {
        const ami = this.selectedAMI;
        const isMultiValue = this.isMultiValue(ami);
        this.isBulkEnabled = true;

        if (isMultiValue && attributeValue?.length) {
            this.onSelectBulkEditAction(
                this.isReplaceValue
                    ? BulkActionTypes.Replace
                    : BulkActionTypes.Append
            );
        } else if (this.isBoolean(ami)) {
            this.onSelectBulkEditAction(BulkActionTypes.Replace);
        } else if (attributeValue && !isMultiValue) {
            this.onSelectBulkEditAction(BulkActionTypes.Replace);
        } else {
            if (this.isClearAttributeValueAllowed(ami)) {
                this.onSelectBulkEditAction(BulkActionTypes.Clear);
            } else {
                if (isMultiValue) {
                    this.onSelectBulkEditAction(BulkActionTypes.Append);
                } else {
                    this.onSelectBulkEditAction(BulkActionTypes.Replace);
                }
                this.isBulkEnabled = false;
            }
        }
    }

    private buildEntityForm(): IEntityForm<unknown> {
        return {
            kind: EntityFormKind.bulk,
            isEditEnabled: true,
            isLabelDisplayed: true,
            getEntityData: () => this.entityData,
            getEntityDataList: () => this.entityDataList,
            getSpaceIdr: () =>
                SpaceIdentifier.fromEntity(this.entityData, true),

            getAttributeValue: () => this.currentEntityAttributeValue,

            setAttributeValue: async (attributeKey: string, newValue: any) =>
                this.setAttributeValue(newValue),

            setCurrentAttributeKey: (attributeKey: string) => {
                const ami = this.selectedAMI;
                if (ami) {
                    ami.AttributeKey = attributeKey;
                }
            },

            loadReferenceOptions: async (attributeMeta: AttributeMetaInfo) =>
                await this.attributeDataService.loadReferenceOptionsEntity(
                    attributeMeta,
                    this.entityData
                ),
        };
    }

    private setAttributeValue(newValue: any) {
        this.updateAvailableActions(newValue);
        this.currentEntityAttributeValue = newValue;
    }

    private getIsReplaceValue() {
        const ami = this.selectedAMI;
        const isReplaceSupported =
            AttributeDataService.isReplaceOptionSupported(ami);
        if (AttributeDataService.isShortcutAttribute(ami?.AttributeType)) {
            return this.isReplaceValue && isReplaceSupported;
        } else {
            return this.isReplaceValue || !isReplaceSupported;
        }
    }

    private async confirmSave() {
        const ami = this.selectedAMI;
        return this.dxyModalService.confirm({
            title: this.translate.instant('UI.BulkEdit.clearDialog.title', {
                attribute: ami.DisplayName,
            }),
            message: this.translate.instant('UI.BulkEdit.clearDialog.message', {
                attribute: ami.translatedDisplayName,
                count: this.entityDataList.length,
            }),
            type: DialogType.YesNo,
        });
    }

    private isBoolean(ami: AttributeMetaInfo) {
        return !!ami && ami.AttributeType == AttributeMetaType.Boolean;
    }
    private isMultiValue(ami: AttributeMetaInfo) {
        return (
            !!ami &&
            (ami.IsMultiValue ||
                AttributeDataService.isTagOrUserOrPersonAttribute(
                    ami.AttributeType
                ))
        );
    }
    private isClearAttributeValueAllowed(ami: AttributeMetaInfo) {
        return (
            !!ami &&
            ami.AttributeKey != PropertyName.EntityStatus &&
            !AttributeDataService.isDataOwnerOrDataStewardAttribute(ami)
        );
    }
}
