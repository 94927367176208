import { Dom2dUtil, IShift, Point, Rect } from '@datagalaxy/core-2d-util';
import { IGraphItem } from '../graph-surface.types';
import { NodeSpec } from './node.types';
import { DomUtil } from '@datagalaxy/core-util';
import { SurfaceLayer } from '../layer';
import { NodePort, PortKind } from '../ports';

/** ## Role
 * ZoomSurface managed item.
 * Represents a node in a diagram.
 * Associates a rectangle and a layer with a client DOM element and data object.
 */
export class ManagedItem<D = unknown> implements IGraphItem<D>, NodeSpec {
    public static readonly class = 'gs-node';

    public readonly graphType = 'node';

    private _rect: Rect = new Rect();
    private classes: string[] = [];
    private _ports: NodePort<D>[] = [];

    public get id() {
        return this.spec.id;
    }
    public get el() {
        return this.spec.el;
    }
    public get unDraggable() {
        return this.spec.unDraggable;
    }
    public get unResizable() {
        return this.spec.unResizable;
    }
    public get resizeCardinals() {
        return this.spec.resizeCardinals;
    }
    public get minSize() {
        return this.spec.minSize;
    }
    public get maxSize() {
        return this.spec.maxSize;
    }
    public get isContainer() {
        return this.spec.isContainer;
    }
    public get uncontainable() {
        return this.spec.uncontainable;
    }
    public get data() {
        return this.spec.data as D;
    }
    public get layer() {
        return this.spec.layer;
    }
    public get rect() {
        return this._rect;
    }
    public get cssClass() {
        return this.spec.cssClass;
    }

    /**
     * True to use style properties for position and size, instead of attributes.
     * Defaults to true when the element is an HTMLElement.
     */
    public get useStyle() {
        return this.spec.el instanceof HTMLElement;
    }

    constructor(private spec: NodeSpec) {
        this.updateProps(spec);
    }

    public dispose(): void {
        this.el?.remove();
    }

    public updateProps(spec: Partial<NodeSpec>): void {
        Object.assign(this.spec, spec);

        const { minSize, maxSize } = this.spec;

        Rect.from(spec.rect, this._rect)
            .clampSize(minSize, maxSize)
            .truncateSelf(true);

        this._ports = this.updatePorts();
    }

    public draw(): void {
        const classes = this.getClasses();
        DomUtil.replaceClasses(this.el, classes, this.classes);
        this.classes = classes;

        if (this.layer === SurfaceLayer.none) {
            return;
        }
        Dom2dUtil.setBounds(this.el, this.rect, this.useStyle);
    }

    public getPorts(): NodePort<D>[] {
        return this._ports;
    }

    public getPortById(portId: string): NodePort<D> {
        return this._ports?.find((port) => port.localId === portId);
    }

    public setPosition(point: Point): void {
        this.rect.setPosition(point.x, point.y);
    }

    public shiftPosition(shift: IShift): void {
        this.rect.shift(shift);
    }

    private getClasses(): string[] {
        const { highlighted, selected, layer } = this.spec;
        const highlightedClass = highlighted ? 'highlighted' : '';
        const selectedClass = selected ? 'selected' : '';
        const noLayer = layer === SurfaceLayer.none ? `no-layer` : '';
        const classes = this.spec?.cssClass?.split(' ') || [];

        return [
            ManagedItem.class,
            highlightedClass,
            selectedClass,
            noLayer,
            ...classes,
        ].filter((c) => !!c);
    }

    private updatePorts(): NodePort<D>[] {
        const portSpecs = this.spec.ports;

        if (!portSpecs?.length) {
            return [new NodePort<D>(this, { kind: PortKind.node })];
        }

        return portSpecs.map((spec) => new NodePort<D>(this, spec));
    }
}
