import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

@Directive({
    selector: '[dxyDroppableAttribute]',
    standalone: true,
})
export class DxyDroppableAttributeDirective
    extends DxyBaseDirective
    implements AfterViewInit
{
    private debugDetailed = false;

    @Input() dragData: string;
    @Input() drop: (droppedData: string) => boolean | void;
    @Output() dragEnd = new EventEmitter<{ droppedOnSelf: boolean }>();

    private droppedOnSelf: boolean;
    private get element() {
        return this.elementRef?.nativeElement;
    }

    constructor(private elementRef: ElementRef<Element>) {
        super();
    }

    ngAfterViewInit() {
        this.log('ngAfterViewInit', this.element);
    }

    @HostListener('dragover', ['$event'])
    onDragOver(e: DragEvent) {
        this.preventAll(e);
        e.dataTransfer.dropEffect = 'move';
        this.debugDetailed && this.log('onDragOver', this.element);
        return true;
    }

    @HostListener('dragenter', ['$event'])
    onDragEnter(e: DragEvent) {
        this.preventAll(e);
        this.log('onDragEnter', this.element);
        return false;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(e: DragEvent) {
        this.preventAll(e);
        const el = e.target as Element;
        el.classList.remove('dragover');
        this.log('onDragLeave', this.element);
        return false;
    }

    @HostListener('drop', ['$event'])
    onDrop(e: DragEvent) {
        this.log('onDrop', this.element);
        this.preventAll(e);
        const el = e.target as Element;
        el.classList.remove('dragover');
        const data = e.dataTransfer.getData('Text');
        this.droppedOnSelf = this.drop(data) as boolean;
        this.log('onDrop-droppedOnSelf', this.droppedOnSelf);
    }

    @HostListener('dragstart', ['$event'])
    onDragStart(e: DragEvent) {
        this.log('onDragStart', this.element);
        e.dataTransfer.effectAllowed = 'move';
        this.droppedOnSelf = undefined;
        e.dataTransfer.setData('Text', this.dragData);
    }

    @HostListener('dragend', ['$event'])
    onDragEnd(_e: DragEvent) {
        this.log('onDragEnd', this.element);
        this.dragEnd.emit({ droppedOnSelf: this.droppedOnSelf });
    }

    private preventAll(e: Event) {
        e.stopImmediatePropagation();
        e.preventDefault();
    }
}
