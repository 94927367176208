import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    LanguageIconComponent,
    LanguageNamePipe,
} from '@datagalaxy/webclient/multilingual/ui';

@Component({
    selector: 'dxy-language-card',
    templateUrl: './language-card.component.html',
    styleUrls: ['./language-card.component.scss'],
    imports: [LanguageIconComponent, LanguageNamePipe],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageCardComponent {
    @Input() languageCode!: string;
}
