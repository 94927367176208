import { GenericDeserialize } from 'cerialize/dist/serialize';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { BaseService, UiSpinnerService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    DataIdentifier,
    EntityLinkTypeKind,
    EntityType,
    EntityTypeUtil,
    HierarchyDataDescriptor,
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { IEntityExportParameters } from '../../../export/export-api.types';
import {
    EntityDeleteEventData,
    EntityEventService,
} from './entity-event.service';
import { DataUtil } from '../../util/DataUtil';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { ScreenService } from '../../../screens/screen.service';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { ViewType } from '../../util/app-types/ViewType';
import { ViewTypeService } from '../../../services/viewType.service';
import {
    ApiServiceError,
    ApiServiceErrorType,
    isUnmodifiedApiError,
    isUnsuccessfulApiError,
} from '@datagalaxy/data-access';
import { getSpaceIdFromEntityId } from '@datagalaxy/webclient/utils';
import {
    AddLinkAction,
    AddLinkedEntitiesParameter,
    AddLinkedEntitiesResult,
    AttributeValueInfo,
    CreateEntityOperation,
    CreateEntityParameter,
    DeleteEntityParameter,
    DeleteLinkedEntitiesParameter,
    DeleteLinkedEntitiesResult,
    DeleteLocalSynonymParameter,
    EntityApiService,
    EntityFunctionalLogService,
    FusionEntitiesParameter,
    GetAvailableLinkTypesParameter,
    GetDefaultValuesOnCreateEntityParameter,
    GetEntityInsightsParameter,
    GetLinkedDataParameter,
    GetSynonymsParameter,
    IUpdateLinksResult,
    LoadMultiEntityParameter,
    LocalSynonymParameter,
    SetEntitiesParentParameter,
    SetEntitiesParentResult,
    SetEntitiesTechnologyParameter,
    UpdateAttributeAction,
    UpdateEntityAttributeParameter,
    UpdateEntityAttributeResult,
    UpdateEntityLinkParameter,
    UpdateLinkAction,
} from '@datagalaxy/webclient/entity/data-access';
import { ScreenDTO } from '@datagalaxy/webclient/screen/data-access';
import {
    GetFilteredEntitiesParameter,
    GetRecentEntitiesParameter,
    SearchApiService,
} from '@datagalaxy/webclient/search/data-access';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import {
    DataPortApiService,
    EntityExportChildrenOption,
    EntityExportParentsOption,
    EntityExportReferencesOption,
    ExportDataOperation,
    ExportDataParameter,
} from '@datagalaxy/webclient/data-port/data-access';
import { emptyRef } from '@datagalaxy/webclient/modeler/data-access';
import {
    EntityCreator,
    EntityLoader,
    EntityMetaService,
} from '@datagalaxy/webclient/entity/feature';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import {
    EntityItem,
    EntityTypeMeta,
    HddDataKind,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;
import EntityTypeFilterKey = ServerConstants.Search.EntityTypeFilterKey;

@Injectable({ providedIn: 'root' })
export class EntityService extends BaseService {
    private static readonly attributesForHierarchical = [
        PropertyName.LogicalChildrenCount,
        PropertyName.LogicalAllLevelChildrenCount,
        PropertyName.LogicalParentId,
    ];

    constructor(
        private translate: TranslateService,
        private dataPortApiService: DataPortApiService,
        private searchApiService: SearchApiService,
        private entityApiService: EntityApiService,
        private uiSpinnerService: UiSpinnerService,
        private attributeDataService: AttributeDataService,
        private entityEventService: EntityEventService,
        private functionalLogService: FunctionalLogService,
        private entityFunctionalLogService: EntityFunctionalLogService,
        private screenService: ScreenService,
        private viewTypeService: ViewTypeService,
        private entityLoader: EntityLoader,
        private entityMetaService: EntityMetaService,
        private entityCreator: EntityCreator
    ) {
        super();
    }

    //#region attributes
    /**
     * To update a multi value attribute we dont need to send the full AttributeMetaValue object
     * Only NewValue, Value and Key properties are needed
     */
    private static getMinimalAttributeValueForAttributeUpdate(
        attributeValue: AttributeMetaValue[]
    ) {
        return attributeValue?.map((value) => ({
            NewValue: value.NewValue,
            Value: value.Value,
            Key: value.Key,
        }));
    }

    public async getEntityAttributesForDetails(serverType: ServerType) {
        return this.getEntityAttributesFromCache([serverType], {
            includeReferences: true,
            includeComputed: true,
        });
    }

    public async getEntityAttributesForMainSearch() {
        return this.getEntityAttributesForGrid(
            DataUtil.getSearchableServerTypes(),
            {
                includeObjectValueList: true,
                excludeObjectValuesStringCounterpart: true,
                includeEntityLogicalParent: true,
                includeObjectValuesSource: true,
                excludeComputed: true,
                unwantedKeys: [
                    PropertyName.FullDisplayName,
                    PropertyName.FullTechnicalName,
                ],
                wantedKeys: [
                    PropertyName.EntityType,
                    PropertyName.EntityDiagramCount,
                    PropertyName.SoftwareTotalLinkCount,
                    PropertyName.Parents,
                    PropertyName.LogicalParentData,
                    PropertyName.TableDisplayName,
                    PropertyName.TableTechnicalName,
                    PropertyName.DataTypeDisplayName,
                    PropertyName.LogicalChildrenCount,
                    PropertyName.LogicalAllLevelChildrenCount,
                    PropertyName.LinkShortcutDimensionIds,
                    PropertyName.LinkShortcutDomainIds,
                    PropertyName.ImplementationLinkCount,
                    PropertyName.EntityLinkCount,
                    PropertyName.LogicalPath,
                    PropertyName.LogicalPathString,
                    PropertyName.LogicalParentId,
                    PropertyName.LinkShortcutRecordingSystemIds,
                    PropertyName.LinkShortcutReferenceDataId,
                    PropertyName.LinkShortcutUniverseId,
                    PropertyName.LinkShortcutUsageIds,
                    PropertyName.ContainerContainerCount,
                    PropertyName.TechnologyName,
                    PropertyName.DataTypeDisplayName,
                    PropertyName.ModelForeignKeyCount,
                    PropertyName.ModelPrimaryKeyCount,
                    PropertyName.Order,
                    PropertyName.PkOrder,
                    PropertyName.DataTypeIsPrecisionRequired,
                    PropertyName.PrimaryKeyTechnicalName,
                    PropertyName.IsScriptGenerationEnabled,
                    PropertyName.SizeString,
                    PropertyName.DataTypeIsSizeRequired,
                    PropertyName.ModelColumnCount,
                    PropertyName.ModelDisplayName,
                    PropertyName.Type,
                    PropertyName.TableDescription,
                    PropertyName.TableColumnCount,
                    PropertyName.TableType,
                    PropertyName.ContainerTableCount,
                    PropertyName.CreationUserId,
                ],
            }
        );
    }

    public async getEntityAttributesForEntityGrid(rootServerType: ServerType) {
        const serverTypes =
            rootServerType == ServerType.Model
                ? ModelerDataUtil.modelerServerTypes
                : [rootServerType];
        return this.getEntityAttributesForGrid(serverTypes, {
            includeObjectValueList: true,
            excludeObjectValuesStringCounterpart: true,
            includeEntityLogicalParent: true,
            unwantedKeys: [
                PropertyName.FullDisplayName,
                PropertyName.FullTechnicalName,
                PropertyName.FilterDescendentId,
                PropertyName.FilterDirectParentId,
            ],
            wantedKeys: [PropertyName.CreationUserId],
        });
    }

    public async getEntityAttributesForDiagramGrid() {
        return this.getEntityAttributesForGrid([ServerType.Diagram], {
            includeObjectValueList: true,
            excludeObjectValuesStringCounterpart: true,
            includeEntityLogicalParent: true,
            wantedKeys: [PropertyName.CreationUserId],
            unwantedKeys: [
                PropertyName.FullDisplayName,
                PropertyName.FullTechnicalName,
                PropertyName.FilterDescendentId,
                PropertyName.FilterDirectParentId,
                ServerConstants.Diagram.DiagramEntityIds,
                PropertyName.EntityDiagramCount,
                PropertyName.Type,
                ServerConstants.Diagram.VisualData,
                ServerConstants.PropertyName.IsWatchedByCurrentUser,
            ],
        });
    }

    public async getEntityAttributesForCampaignGrid() {
        return this.getEntityAttributesForGrid([ServerType.Diagram], {
            includeObjectValueList: true,
            excludeObjectValuesStringCounterpart: true,
            includeEntityLogicalParent: true,
            unwantedKeys: [],
        });
    }

    private async getEntityAttributesForGrid(
        serverTypes: ServerType[],
        opt: {
            includeObjectValueList?: boolean;
            excludeObjectValuesStringCounterpart?: boolean;
            includeEntityLogicalParent?: boolean;
            includeObjectValuesSource?: boolean;
            excludeComputed?: boolean;
            unwantedKeys?: string[];
            wantedKeys?: string[];
        } = {}
    ) {
        const {
            excludeComputed,
            includeObjectValuesSource,
            includeObjectValueList,
            excludeObjectValuesStringCounterpart,
        } = opt;

        const AMT = AttributeMetaType;
        const unwantedTypes = [
            AMT.Reference,
            AMT.ClientTag,
            AMT.StewardUserReference,
            AMT.ReferenceList,
            AMT.UserReference,
            AMT.PersonReference,
            AMT.ManagedTag,
            AMT.MultiValueList,
            AMT.DataTypeAndSize,
            AMT.Hierarchy,
            AMT.ReferenceId,
            AMT.ObjectLink,
            AMT.TimeSeriesLastEntries,
            AMT.TimeSeriesObject,
            AMT.AllLinkedData,
        ];
        if (!includeObjectValueList) {
            unwantedTypes.push(AMT.ObjectValueList);
        }
        if (!opt?.includeEntityLogicalParent) {
            unwantedTypes.push(AMT.EntityLogicalParent);
        }

        const wantedKeys = [
            PropertyName.CreationTime,
            PropertyName.LastModificationTime,
            ...(opt?.wantedKeys ?? []),
        ];

        // Manually Exclude Attributes that we do not want to display (Quality-Data because we don't have a display, and more importantly a fast processing time yet)
        const unwantedKeys = [
            PropertyName.IsGdpr,
            PropertyName.DataFillPercentage,
            PropertyName.RecommendedDataFillPercentage,
            PropertyName.LongDescriptionRaw,
            ...(opt?.unwantedKeys ?? []),
        ];

        const attributes = await this.getEntityAttributesFromCache(
            serverTypes,
            { includeReferences: true, includeComputed: true }
        );

        let result = attributes.filter(
            (att) =>
                !att.IsRawTextComputedAttribute &&
                (!att.IsSystemTechnicalField ||
                    att.IsScreenDisplayable ||
                    wantedKeys.includes(att.AttributeKey)) &&
                !unwantedKeys.includes(att.AttributeKey) &&
                !unwantedTypes.includes(att.AttributeType) &&
                (!excludeComputed ||
                    !att.IsComputed ||
                    wantedKeys.includes(att.AttributeKey))
        );
        if (includeObjectValueList && excludeObjectValuesStringCounterpart) {
            const stringCounterpartAttributes =
                this.getObjectValueListAttributes(
                    result
                ).stringCounterpartAttributes;
            result = result.filter(
                (ami) => !stringCounterpartAttributes.includes(ami)
            );
        }
        if (includeObjectValuesSource && includeObjectValueList) {
            result.forEach((ami) => {
                if (ami.AttributeType === AttributeMetaType.ObjectValueList) {
                    const sourcePath = ami.AttributePath.replace(
                        'ObjectValues',
                        ''
                    );
                    result.push(
                        attributes.find(
                            (attr) => attr.AttributePath === sourcePath
                        )
                    );
                }
            });
        }
        this.debug &&
            this.log(
                'getEntityAttributesForGrid',
                serverTypes.map((st) => ServerType[st]),
                opt,
                result
            );
        return result;
    }

    public getObjectValueListAttributes(attributes: AttributeMetaInfo[]): {
        objectValuesAttributes: AttributeMetaInfo[];
        stringCounterpartAttributes: AttributeMetaInfo[];
    } {
        const objectValuesAttributes = attributes.filter(
            (ami) =>
                ami.AttributeType === AttributeMetaType.ObjectValueList &&
                StringUtil.endsWith(ami.AttributeKey, 'ObjectValues')
        );
        const stringCounterpartAttributeKeys = objectValuesAttributes.map(
            (ami) => ami.AttributeKey.replace('ObjectValues', 'String')
        );
        const stringCounterpartAttributes = attributes.filter((ami) =>
            stringCounterpartAttributeKeys.includes(ami.AttributeKey)
        );

        return { objectValuesAttributes, stringCounterpartAttributes };
    }

    public async getEntityAttributesForBulk(
        serverTypeList: ServerType[],
        isMultiEntityTypes: boolean = false
    ) {
        const firstServerType = serverTypeList[0];
        const primaryAttribute =
            this.getPrimaryNameAttributeKey(firstServerType);
        const primaryAttributeFunctional = this.getPrimaryNameAttributeKey(
            firstServerType,
            ViewType.Functional
        );
        const primaryAttributeTechnical = this.getPrimaryNameAttributeKey(
            firstServerType,
            ViewType.Technical
        );
        const excludedAttributes = CollectionsHelper.distinct([
            primaryAttribute,
            primaryAttributeFunctional,
            primaryAttributeTechnical,
        ]);
        const typeAttributes = await this.getEntityAttributesFromCache(
            serverTypeList,
            {
                includeReferences: true,
                includeComputed: true,
                excludedAttributes,
                keepCommonAttributesOnly: true,
            }
        );
        return this.filterNonBulkEditableAttributes(
            typeAttributes,
            isMultiEntityTypes
        );
    }

    private filterNonBulkEditableAttributes(
        attributes: AttributeMetaInfo[],
        isMultiEntityTypes: boolean = false
    ) {
        return attributes.filter((att) => {
            const isBulkEditable = isMultiEntityTypes
                ? att.IsMultiTypeBulkEditable
                : att.IsBulkEditable;
            return (
                !att.IsReadOnly && !att.IsSystemTechnicalField && isBulkEditable
            );
        });
    }

    private async getEntityAttributesFromCache(
        serverTypes: ServerType[],
        opt?: {
            includeReferences?: boolean;
            includeComputed?: boolean;
            excludedAttributes?: string[];
            forceReload?: boolean;
            keepCommonAttributesOnly?: boolean;
        }
    ) {
        const dataTypeNames = serverTypes.map((st) => ServerType[st]);
        await this.attributeDataService.loadAttributes(
            dataTypeNames,
            opt?.forceReload
        );
        return this.attributeDataService.getAttributes(
            dataTypeNames,
            opt?.includeReferences,
            opt?.includeComputed,
            opt?.excludedAttributes ?? [],
            opt?.keepCommonAttributesOnly
        );
    }

    //#endregion    attributes

    public async getEntityForEntityDockingPane(
        identifier: IEntityIdentifier
    ): Promise<EntityItem> {
        return this.getEntityForDetails(identifier);
    }

    /**
     * Load Entity with only needed attributes for entity details header + screen
     * @param entityIdr
     * @param loadTabAttributes specifies if we need entity dashboard tab counters.
     */
    public async getMinimalEntityForDetails(
        entityIdr: IEntityIdentifier,
        loadTabAttributes?: boolean
    ) {
        const spaceId = getSpaceIdFromEntityId(entityIdr.ReferenceId);

        await this.screenService.ensureSpaceScreensAreLoaded(
            spaceId,
            entityIdr.VersionId
        );
        const moduleName = ServerType[entityIdr.ServerType];
        const subTypeName = EntityTypeUtil.getMapping(
            entityIdr.entityType
        ).SubTypeName;
        const screen: ScreenDTO = this.screenService.getScreen(
            spaceId,
            moduleName,
            subTypeName
        );
        const detailsAttributes =
            AttributeDataService.getEntityDetailsAttributes(
                loadTabAttributes ? entityIdr.ServerType : null
            );
        const attributesFilter = CollectionsHelper.distinct([
            ...detailsAttributes,
            ...(screen?.Attributes || []).map((attr) => attr.AttributePath),
        ]);
        return await this.getEntityForDetails(entityIdr, attributesFilter);
    }

    /** Note: identifier.EntityType is not used */
    public async getEntityForDetails(
        identifier: IEntityIdentifier,
        includedAttributesFilter: string[] = null,
        includeHddSecurityAndSocialData: boolean = true
    ) {
        return this.getEntity(identifier, {
            includeHddSecurityAndSocialData,
            includedAttributesFilter,
        });
    }

    /** Returns one EntityItem with its HierarchicalData, SecurityData, no attributes nor SocialData.
     *
     * Note: identifier.EntityType is not used
     */
    public async getEntityMini(
        identifier: IEntityIdentifier
    ): Promise<EntityItem> {
        if (!identifier) {
            return null;
        }
        const spaceId = getSpaceIdFromEntityId(identifier.ReferenceId);
        const p = new LoadMultiEntityParameter(
            spaceId,
            identifier.ServerType,
            true,
            0,
            1,
            null,
            null,
            null,
            null,
            null
        );
        p.DataReferenceIdList = [identifier.ReferenceId];
        p.VersionId = identifier.VersionId;
        const result = await this.entityApiService.loadMultiEntity(p);
        return result.Entities[0];
    }

    public async getEntity(
        identifier: IEntityIdentifier,
        opt: {
            includeHddSecurityAndSocialData?: boolean;
            includeHdd?: boolean;
            includeSecurity?: boolean;
            includeSocialData?: boolean;
            includedAttributesFilter?: string[];
        } = {}
    ): Promise<EntityItem> {
        const entityId = identifier.ReferenceId;
        const lastCreatedEntity = this.entityCreator.lastCreatedEntity;
        this.entityCreator.lastCreatedEntity = null;

        if (lastCreatedEntity && lastCreatedEntity.ReferenceId === entityId) {
            return lastCreatedEntity;
        }
        if (!entityId || entityId === emptyRef) {
            return null;
        }

        const entity = await this.entityLoader.loadEntity(identifier, opt);
        const serverTypeName = ServerType[entity.ServerType];

        await this.attributeDataService.loadAttributes([serverTypeName]);
        const attributes = this.attributeDataService.getAttributes(
            [serverTypeName],
            null,
            null
        );
        const meta = new EntityTypeMeta(attributes);
        await this.entityMetaService.setEntityMeta(entity, meta);
        return entity;
    }

    public async getEntityInsights(entityId: string) {
        const parameter = new GetEntityInsightsParameter(entityId);
        const result = await this.entityApiService.getEntityInsights(parameter);
        result.propagateEntityVersionId();
        return result;
    }

    public async getLastViewedEntities(
        size: number,
        includedAttributesFilter: string[]
    ) {
        const parameter = new GetRecentEntitiesParameter();
        parameter.Size = size;
        parameter.IncludedAttributesFilter = includedAttributesFilter;
        const recentEntitiesResult =
            await this.searchApiService.getRecentEntities(parameter);
        return recentEntitiesResult.Entities ?? [];
    }

    public async loadMultiEntity(opt: ILoadMultiEntityOptions) {
        const p = new LoadMultiEntityParameter(
            opt.parentReferenceId,
            opt.dataTypes,
            opt.includeHdd ?? false,
            opt.startIndex ?? 0,
            opt.size ?? 1000,
            opt.multiColumnsSearchString ?? null,
            opt.searchColumns ?? null,
            opt.sortKey ?? null,
            opt.includedAttributesFilter ?? null,
            opt.filters ?? null,
            opt.hddDataKind ?? HddDataKind.Both,
            opt.isLogicalHierarchyMode ?? false,
            opt.maxHierarchyLevel,
            opt.excludedIds ?? null,
            opt.dataReferenceIdList ?? null,
            opt.includeOnlyHasWriteAccess,
            opt.includeHData,
            opt.includeNoAccessData
        );
        p.VersionId = opt.versionId;
        return this.entityApiService.loadMultiEntity(p);
    }

    /** NOTE: Specifying Size = 0 will only return Count, and no entity */
    public async getEntities(
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        includeHdd: boolean,
        startIndex: number,
        size: number = 20,
        filters: Filter[] = [],
        includedAttributesFilter: string[] = null,
        sortKey?: string,
        isLogicalHierarchyMode = false,
        maxHierarchicalLevel = 0
    ) {
        const primaryName = this.getPrimaryNameAttributeKey(
            serverType,
            this.viewTypeService.viewType
        );

        return this.entityLoader.getEntities(
            spaceIdr,
            serverType,
            includeHdd,
            startIndex,
            size,
            filters,
            includedAttributesFilter,
            primaryName,
            sortKey,
            isLogicalHierarchyMode,
            maxHierarchicalLevel
        );
    }

    public async getEntitiesCount(
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        filters: Filter[] = []
    ) {
        return this.getEntities(spaceIdr, serverType, false, 0, 0, filters);
    }

    public async getModulesEntitiesCountForGlossaryAutoGeneration(
        spaceIdr: ISpaceIdentifier
    ): Promise<Map<DgModule, number>> {
        const map = new Map<DgModule, number>();
        const modules = [DgModule.Glossary, DgModule.Catalog, DgModule.Usage];
        const moduleEntityTypes = [
            { module: DgModule.Glossary, entityTypes: [EntityType.Universe] },
            { module: DgModule.Usage, entityTypes: [EntityType.Application] },
            {
                module: DgModule.Catalog,
                entityTypes: [
                    EntityType.RelationalModel,
                    EntityType.NonRelationalModel,
                    EntityType.NoSqlModel,
                    EntityType.TagBase,
                ],
            },
        ];
        const getFilter = (module: DgModule) => {
            return {
                AttributePath: EntityTypeFilterKey,
                FilterOperator: FilterOperator[FilterOperator.ListContains],
                SearchValues: moduleEntityTypes
                    .find((m) => m.module === module)
                    .entityTypes?.map((et) => EntityType[et]),
            } as Filter;
        };
        await Promise.all(
            modules.map((module) =>
                this.getEntitiesCount(
                    spaceIdr,
                    DataUtil.getServerTypesFromModule(module),
                    [getFilter(module)]
                ).then((res) => map.set(module, res?.TotalCount))
            )
        );
        return map;
    }

    public async getEntitiesForList(
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        startIndex: number,
        size: number,
        withCounts: boolean = true
    ) {
        const result = await this.getEntitiesForFlat(
            spaceIdr,
            serverType,
            startIndex,
            size,
            withCounts
        );
        return result.Entities;
    }

    public async getEntitiesForFlat(
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        startIndex: number,
        size: number,
        withCounts: boolean = true,
        neededAttributes: string[] = null,
        filters: Filter[] = [],
        sortKey?: string,
        rootEntityReferenceId: string = null
    ) {
        const addedAttributes = [
            this.getPrimaryNameAttributeKey(serverType),
            PropertyName.LegacySubTypeAttributeKey,
            PropertyName.DisplayName,
            PropertyName.LogicalParentId,
        ];
        if (neededAttributes) {
            addedAttributes.push(...neededAttributes);
        }

        if (withCounts) {
            addedAttributes.push(PropertyName.LogicalAllLevelChildrenCount);
            addedAttributes.push(PropertyName.LogicalChildrenCount);
        }

        const includedAttributes = CollectionsHelper.distinct(addedAttributes);

        if (rootEntityReferenceId) {
            filters.push(
                new Filter(
                    ServerConstants.PropertyName.Parents,
                    FilterOperator.Equals,
                    [rootEntityReferenceId]
                )
            );
        }

        return this.getEntities(
            spaceIdr,
            serverType,
            true,
            startIndex,
            size,
            filters,
            includedAttributes,
            sortKey
        );
    }

    public async getEntitiesForHierarchical(
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        filters: Filter[] = [],
        neededAttributes: string[] = null,
        rootEntityReferenceId: string = null,
        maxSize = 1000,
        sortKey?: string
    ) {
        const nameAttributes =
            serverType == ServerType.Property
                ? [PropertyName.DisplayName]
                : [PropertyName.DisplayName, PropertyName.TechnicalName];
        const includedAttributes = CollectionsHelper.distinct([
            PropertyName.LegacySubTypeAttributeKey,
            ...nameAttributes,
            ...EntityService.attributesForHierarchical,
            ...(neededAttributes ?? []),
        ]);
        //console.log('getEntitiesForHierarchical', neededAttributes, includedAttributes)
        filters = this.getFiltersForHierarchical(
            filters,
            rootEntityReferenceId
        );
        return this.getEntities(
            spaceIdr,
            serverType,
            true,
            0,
            maxSize,
            filters,
            includedAttributes,
            sortKey,
            true
        );
    }

    public async getEntitiesForDataMap(
        spaceIdr: ISpaceIdentifier,
        dgModule: DgModule,
        filters: Filter[] = [],
        firstLevelOnly: boolean
    ) {
        const serverType = DataUtil.getDefaultServerTypeFromModule(dgModule);
        const includedAttributes = CollectionsHelper.distinct([
            this.getPrimaryNameAttributeKey(serverType),
            PropertyName.LegacySubTypeAttributeKey,
            ...EntityService.attributesForHierarchical,
        ]);
        filters = this.getFiltersForHierarchical(filters, null);
        return this.getEntities(
            spaceIdr,
            serverType,
            true,
            0,
            5000,
            filters,
            includedAttributes,
            undefined,
            true,
            firstLevelOnly ? 0 : null
        );
    }

    private getPrimaryNameAttributeKey(
        serverType: ServerType | ServerType[],
        viewType?: ViewType
    ) {
        return (
            serverType != undefined &&
            this.viewTypeService.getPrimaryNameAttribute(
                Array.isArray(serverType) ? serverType[0] : serverType,
                viewType
            )
        );
    }

    private getFiltersForHierarchical(
        filters: Filter[],
        rootEntityReferenceId: string
    ) {
        const result = filters?.slice() || [];
        CollectionsHelper.remove(
            result,
            (f) => f.AttributeKey == PropertyName.LogicalParentId
        );
        result.push(
            new Filter(PropertyName.LogicalParentId, FilterOperator.Equals, [
                rootEntityReferenceId,
            ])
        );
        return result;
    }

    private async addMultiEntityObjectLink(
        sourcesIds: string[],
        objectLinkType: ObjectLinkType,
        versionId: string,
        linkToAdd?: LinkedDataItem[],
        addLinkAction: AddLinkAction = AddLinkAction.Append
    ) {
        const linkIds =
            linkToAdd?.map((item) => item.LinkedData.DataReferenceId) ?? [];
        return this.addObjectLink(
            sourcesIds,
            linkIds,
            objectLinkType,
            versionId,
            addLinkAction
        );
    }

    public async getDefaultValuesOnCreate(
        includedAttributes: string[],
        parentOrSpaceId: string,
        versionId: string
    ) {
        const params = new GetDefaultValuesOnCreateEntityParameter();
        params.ParentReferenceId = parentOrSpaceId;
        params.VersionId = versionId;
        params.IncludedAttributes = includedAttributes;
        return this.entityApiService.getDefaultValuesOnCreate(params);
    }

    public async updateEntity(
        entity: EntityItem,
        attributeKey: string,
        /** for set parent (AttributeMetaType.EntityLogicalParent), this has to be a HierarchyDataDescriptor */
        attributeValue: any,
        opt?: {
            action?: UpdateAttributeAction;
            linkChangeInfo?: IEntityLinksChangeInfo;
            includeSecurity?: boolean;
            includeQuality?: boolean;
        }
    ): Promise<
        | SetEntitiesParentResult
        | AddLinkedEntitiesResult
        | DeleteLinkedEntitiesResult
        | UpdateEntityAttributeResult
    > {
        const serverType = entity.Type,
            action = opt?.action ?? UpdateAttributeAction.SetValue,
            attribute = this.attributeDataService.getAttribute(
                serverType,
                attributeKey
            );

        if (attribute.AttributeType == AttributeMetaType.EntityLogicalParent) {
            const parentId = (attributeValue as HierarchyDataDescriptor)
                .DataReferenceId;
            return this.setEntitiesParent(
                parentId,
                [entity.ReferenceId],
                entity.VersionId
            );
        }

        if (attribute.AttributeType == AttributeMetaType.EntityLinkShortcut) {
            const linkChangeInfo = opt?.linkChangeInfo;
            const universalObjectLinkType =
                attribute.ObjectLinkType == ObjectLinkType.EntityLink
                    ? EntityTypeUtil.getUniversalObjectLinkType(
                          linkChangeInfo?.kind,
                          attribute.IsReversedEntityLink
                      )
                    : attribute.ObjectLinkType;
            if (linkChangeInfo?.linksToDelete?.length) {
                const linkIdsToDelete = linkChangeInfo.linksToDelete.map(
                    (a) => a.LinkEntityData.DataReferenceId
                );
                const deleteResult = this.deleteMultiEntityLink(
                    [entity.ReferenceId],
                    entity.VersionId,
                    linkIdsToDelete,
                    universalObjectLinkType
                );
                if (!linkChangeInfo.linksToAdd?.length) {
                    return deleteResult;
                }
            }

            return this.addMultiEntityObjectLink(
                [entity.ReferenceId],
                universalObjectLinkType,
                entity.VersionId,
                linkChangeInfo?.linksToAdd
            );
        }

        if (attribute.IsMultiValue) {
            attributeValue =
                EntityService.getMinimalAttributeValueForAttributeUpdate(
                    attributeValue
                );
        }

        const parameter = new UpdateEntityAttributeParameter(
            [entity.ReferenceId],
            ServerType[serverType],
            false,
            attributeKey,
            attributeValue,
            action,
            opt?.includeQuality,
            opt?.includeSecurity
        );
        parameter.VersionId = entity.VersionId;
        try {
            const isTechnologyUpdate = attributeKey == PropertyName.Technology;

            const result = isTechnologyUpdate
                ? await this.updateEntityTechnology(
                      attributeValue,
                      [entity.ReferenceId],
                      entity.VersionId,
                      opt?.includeQuality
                  )
                : await this.entityApiService.updateEntity(parameter);

            const updatedEntity = result.UpdatedEntities[0];

            if (isTechnologyUpdate) {
                this.entityEventService.notifyEntityTechnologyUpdate(
                    updatedEntity
                );
            } else {
                this.entityEventService.notifyEntityUpdate(updatedEntity);
            }

            if (opt?.includeSecurity) {
                this.entityEventService.notifyEntitySecurityUpdate(
                    updatedEntity
                );
            }

            this.entityFunctionalLogService.logEntityItemAction(
                entity,
                CrudOperation.U
            );
            return result;
        } catch (error) {
            if (
                error instanceof ApiServiceError &&
                error.type === ApiServiceErrorType.UnmodifiedContent
            ) {
                const result = new UpdateEntityAttributeResult();
                result.UpdatedEntities = [entity];
                return result;
            }
            throw error;
        }
    }

    // Used for Mass/Bulk Edit of Entity Attribute
    public async updateEntities(
        entityIds: string[],
        versionId: string,
        attribute: AttributeMetaInfo,
        attributeValue: any,
        universalObjectLinkType: ObjectLinkType,
        isReplaceValue: boolean = false,
        isClearValue: boolean = false
    ): Promise<
        | UpdateEntityAttributeResult
        | AddLinkedEntitiesResult
        | DeleteLinkedEntitiesResult
        | SetEntitiesParentResult
    > {
        // TODO: Use specific API to set Logical Parent, as the effects can be much more important (When used on Modeler Entity)
        if (attribute.AttributeType == AttributeMetaType.EntityLogicalParent) {
            const parentReferenceId = isClearValue
                ? getSpaceIdFromEntityId(entityIds[0])
                : (attributeValue as HierarchyDataDescriptor).DataReferenceId;
            return this.setEntitiesParent(
                parentReferenceId,
                entityIds,
                versionId
            );
        }
        if (attribute.AttributeType == AttributeMetaType.EntityLinkShortcut) {
            if (isClearValue) {
                return this.deleteMultiEntityLink(
                    entityIds,
                    versionId,
                    [],
                    universalObjectLinkType,
                    isClearValue
                );
            }
            const targetIds = attributeValue as LinkedDataItem[];
            const actionLinkType = isReplaceValue
                ? AddLinkAction.Replace
                : AddLinkAction.Append;
            return this.addMultiEntityObjectLink(
                entityIds,
                universalObjectLinkType,
                versionId,
                targetIds,
                actionLinkType
            );
        }

        if (attribute.IsMultiValue) {
            attributeValue =
                EntityService.getMinimalAttributeValueForAttributeUpdate(
                    attributeValue
                );
        }
        const actionType = isClearValue
            ? UpdateAttributeAction.SetValue
            : isReplaceValue
            ? UpdateAttributeAction.SetValue
            : UpdateAttributeAction.AddExistingData;
        const includeSecurityData =
            AttributeDataService.isOfficialRoleAttribute(attribute) ||
            attribute.AttributeKey == PropertyName.EntityStatus;
        const parameter = new UpdateEntityAttributeParameter(
            entityIds,
            attribute.DataTypeName,
            true,
            attribute.AttributeKey,
            attributeValue,
            actionType,
            false,
            includeSecurityData
        );

        try {
            const result = await this.entityApiService.updateEntity(parameter);
            this.entityEventService.notifyEntityBulkUpdateEvent(
                result.UpdatedEntities
            );
            return result;
        } catch (error) {
            if (isUnmodifiedApiError(error)) {
                return new UpdateEntityAttributeResult();
            }

            throw error;
        }
    }

    public async deleteEntity(entityData: IEntityIdentifier) {
        const entityId = entityData.ReferenceId;
        const serverType = entityData.ServerType;
        const parameter = new DeleteEntityParameter(
            [entityId],
            ServerType[serverType]
        );
        const result = await this.entityApiService.deleteEntity(parameter);
        this.entityEventService.notifyEntityDelete(
            serverType,
            new EntityDeleteEventData([entityId], result.TotalDeletedCount)
        );
        return entityId;
    }

    public async deleteEntities(
        entities: IEntityIdentifier[],
        serverType: ServerType
    ) {
        const entityIds = entities.map((e) => e.ReferenceId);
        const parameter = new DeleteEntityParameter(
            entityIds,
            ServerType[serverType]
        );
        const deleteAsync = async () => {
            const result = await this.entityApiService.deleteEntity(parameter);
            this.entityEventService.notifyEntityDelete(
                serverType,
                new EntityDeleteEventData(entityIds, result.TotalDeletedCount)
            );
            return result;
        };
        return await this.uiSpinnerService.executeWithSpinner(deleteAsync);
    }

    public async preDeleteEntities(
        entityIds: string[],
        serverType: ServerType
    ) {
        const parameter = new DeleteEntityParameter(
            entityIds,
            ServerType[serverType]
        );
        return await this.entityApiService.preDeleteEntity(parameter);
    }

    public async exportEntity(
        dataReferenceIdList: string[],
        spaceIdr: ISpaceIdentifier,
        opt: IEntityExportParameters
    ) {
        const exportDataParameter = new ExportDataParameter(
            spaceIdr.spaceId,
            ExportDataOperation.ExportEntities
        );
        exportDataParameter.DataReferenceIdList = dataReferenceIdList;
        exportDataParameter.ExportFileName = opt.filename;
        exportDataParameter.ChildrenOption =
            EntityExportChildrenOption[opt.childrenOption];
        exportDataParameter.ParentsOption =
            EntityExportParentsOption[opt.parentsOption];
        exportDataParameter.ReferencesOptions = opt.referenceOptions?.map(
            (ref) => EntityExportReferencesOption[ref]
        );
        exportDataParameter.IncludeLinks = opt.includeLinkedObjects;
        exportDataParameter.Filters = opt.filters;
        exportDataParameter.Module = DgModule[opt.module];
        exportDataParameter.setVersionId(spaceIdr.versionId);
        exportDataParameter.TextEntrySeparator = opt.textEntrySeparator;
        exportDataParameter.TextFieldSeparator = opt.textFieldSeparator;
        exportDataParameter.EncodingWebName = opt.encodingWebName;
        await this.uiSpinnerService.executeWithSpinner(() =>
            this.dataPortApiService.exportData(exportDataParameter)
        );
    }

    public async exportEntityTimeSeriesAttribute(
        entityIdr: IEntityIdentifier,
        attributeKey: string
    ) {
        this.functionalLogService.logFunctionalAction(
            'TIMESERIES_VALUES',
            CrudOperation.A,
            CrudActionType.Export
        );
        const exportDataParameter = new ExportDataParameter(
            null,
            ExportDataOperation.ExportEntityTimeSeries,
            null,
            [entityIdr.ReferenceId],
            null,
            attributeKey
        );
        exportDataParameter.setVersionId(entityIdr?.VersionId);
        const exportAsync = () =>
            this.dataPortApiService.exportData(exportDataParameter);
        await this.uiSpinnerService.executeWithSpinner(exportAsync);
    }

    public async exportModel(modelIdentifier: IEntityIdentifier) {
        if (modelIdentifier?.ServerType != ServerType.Model) {
            return;
        }
        const exportDataParameter = new ExportDataParameter(
            modelIdentifier.ReferenceId,
            ExportDataOperation.ExportModuleEntities,
            DgModule.Catalog
        );
        exportDataParameter.setVersionId(modelIdentifier?.VersionId);
        await this.uiSpinnerService.executeWithSpinner(() =>
            this.dataPortApiService.exportData(exportDataParameter)
        );
    }

    public async exportModule(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        exportFileName: string
    ) {
        const exportDataParameter = new ExportDataParameter(
            spaceIdr.spaceId,
            ExportDataOperation.ExportModuleEntities,
            dgModule
        );
        exportDataParameter.setVersionId(spaceIdr?.versionId);
        exportDataParameter.ExportFileName = exportFileName;
        await this.uiSpinnerService.executeWithSpinner(() =>
            this.dataPortApiService.exportData(exportDataParameter)
        );
    }

    public async fusionEntities(
        serverType: ServerType,
        targetEntityId: string,
        sourceEntityIds: string[],
        includeDetails: boolean,
        includeRelations: boolean,
        includeImplementations: boolean
    ) {
        const dataTypeName: string = ServerType[serverType];
        const parameter = new FusionEntitiesParameter(
            dataTypeName,
            targetEntityId,
            sourceEntityIds,
            includeDetails,
            includeRelations,
            includeImplementations,
            false
        );
        const result = await this.entityApiService.fusionEntities(parameter);
        this.entityEventService.notifyEntityDelete(
            serverType,
            new EntityDeleteEventData(result.DeletedEntityIds)
        );
        this.entityEventService.notifyEntityUpdate(result.FusionedEntity);
        return result;
    }

    public async addEntityReferencesGeneric(
        parameter: AddLinkedEntitiesParameter,
        notifyUpdatedEntities = false
    ): Promise<AddLinkedEntitiesResult> {
        try {
            const result = await this.entityApiService.addEntityLink(parameter);

            // NOTE: In the case of an EntityLink type of relation, the Parent is in parameter.DataReferenceId unless the field IsReverse is specified
            // (Eventually, it would be best if the Source property was always on the same side, but for now that is the current logic implemented).
            // You can also look at the method: setEntitiesLogicalParent, used when setting the Parent (in the attribute-logical-parent-input).

            // REMINDER: The parent can be set by three ways:
            // * Using the logical parent input
            // * Using the Entity links tab in the Property/Usage details
            // * Using the Linked Objects pane tool

            // The following code is to exclude the Parent Entities from the Update Entity events.

            const updatedEntitiesToInclude: string[] = [];

            result.UpdatedEntities?.forEach((ue) => {
                this.entityEventService.notifyEntityLinkAdd(ue);
                const identifier = new DataIdentifier(
                    ue.ReferenceId,
                    ue.DataTypeName
                );
                this.entityEventService.notifyEntityLinkIdentifierAdd(
                    identifier
                );
                if (
                    !notifyUpdatedEntities &&
                    !updatedEntitiesToInclude.includes(ue.ReferenceId)
                ) {
                    return;
                }
                this.entityEventService.notifyEntityUpdate(ue, false);
            });

            result.Links?.forEach((link) => {
                link.LinkedEntities.forEach((le) => {
                    const leIdentifier = new DataIdentifier(
                        le.ReferenceId,
                        le.DataTypeName
                    );
                    this.entityEventService.notifyEntityLinkIdentifierAdd(
                        leIdentifier
                    );
                    this.entityEventService.notifyEntityLinkAdd(le);
                    if (
                        !notifyUpdatedEntities &&
                        !updatedEntitiesToInclude.includes(le.ReferenceId)
                    ) {
                        return;
                    }
                    // Partial properties update
                    this.entityEventService.notifyEntityUpdate(le, false);
                });
            });

            return result;
        } catch (e) {
            if (isUnsuccessfulApiError<AddLinkedEntitiesResult>(e)) {
                this.handleUpdateLinkError(e.error);
            }
            throw e;
        }
    }

    public async deleteEntityReferencesGeneric(
        parameter: DeleteLinkedEntitiesParameter
    ) {
        try {
            const result = await this.entityApiService.deleteEntityLink(
                parameter
            );
            result.UpdatedEntities.forEach((ue) => {
                this.entityEventService.notifyEntityLinkDelete(ue);
                const identifier = new DataIdentifier(
                    ue.ReferenceId,
                    ue.DataTypeName
                );
                this.entityEventService.notifyEntityLinkDeleteIdentifier(
                    identifier
                );
                this.entityEventService.notifyEntityUpdate(ue, false);
            });
            result.DeletedEntityIds.forEach((entityId) => {
                this.entityEventService.notifyEntityLinkDeleteIdentifier(
                    new DataIdentifier(entityId)
                );
            });
            return result;
        } catch (e) {
            if (isUnsuccessfulApiError<DeleteLinkedEntitiesResult>(e)) {
                this.handleDeleteLinkResult(e.error);
            }
            throw e;
        }
    }

    public async updateEntityLink(
        entityLinkId: string,
        action: UpdateLinkAction,
        objectLinkType?: ObjectLinkType
    ) {
        const param = new UpdateEntityLinkParameter(
            entityLinkId,
            action,
            objectLinkType
        );
        const result = await this.entityApiService.updateEntityLink(param);
        this.entityEventService.notifyEntityLinkUpdateGoldenLink(result);
        return true;
    }

    public async preCreateEntity(
        parentDataId: string,
        entityType: EntityType,
        displayName: string,
        checkParent: boolean,
        versionId: string,
        code?: string,
        technicalName?: string,
        attributeValues?: AttributeValueInfo[]
    ) {
        const parameter = new CreateEntityParameter(
            parentDataId,
            versionId,
            entityType,
            displayName,
            null,
            code,
            technicalName,
            attributeValues
        );
        parameter.Operation = CreateEntityOperation.Validate;
        parameter.CheckInParentSpace = checkParent;
        return await this.entityApiService.preCreateEntity(parameter);
    }

    /** Note: entityIdentifier.EntityType is not used, so any value is OK */
    public async getEntityLinks(
        entityIdentifier: IEntityIdentifier,
        onlyLinkTypes?: ObjectLinkType[],
        includeEntities?: boolean
    ) {
        const parameter = new GetLinkedDataParameter(
            entityIdentifier.ReferenceId,
            includeEntities
        );
        parameter.setVersionId(entityIdentifier.VersionId);
        parameter.IncludedTypes = onlyLinkTypes ?? [];
        return await this.entityApiService.getLinkedData(parameter);
    }
    public async getAvailableLinkTypes(
        sourceEntityType: EntityType,
        targetEntityTypes: EntityType[],
        targetIds: string[]
    ) {
        const parameter = new GetAvailableLinkTypesParameter(
            sourceEntityType,
            targetEntityTypes,
            targetIds
        );
        const result = await this.entityApiService.getAvailableLinkTypes(
            parameter
        );
        return result.AvailableLinkTypes;
    }

    public async getPropertySynonyms(entityId: string, versionId: string) {
        const parameter = new GetSynonymsParameter(entityId);
        parameter.VersionId = versionId;
        const result = await this.entityApiService.getPropertySynonyms(
            parameter
        );
        result.Synonyms.forEach((hdd) => hdd.HddData.setVersionId(versionId));
        return result.Synonyms;
    }

    public async updateLocalSynonym(
        localSynonymReferenceId: string,
        displayName: string,
        description: string,
        versionId: string
    ) {
        const parameter = new LocalSynonymParameter(
            localSynonymReferenceId,
            displayName,
            description
        );
        parameter.VersionId = versionId;
        const result = await this.entityApiService.updateLocalSynonym(
            parameter
        );
        result.SynonymDto.HddData.setVersionId(versionId);
        return result.SynonymDto;
    }
    public async createLocalSynonym(
        parentDataReferenceId: string,
        displayName: string,
        description: string,
        versionId: string
    ) {
        const parameter = new LocalSynonymParameter(
            parentDataReferenceId,
            displayName,
            description
        );
        parameter.VersionId = versionId;
        const result = await this.entityApiService.createLocalSynonym(
            parameter
        );
        result.SynonymDto.HddData.setVersionId(versionId);
        return result.SynonymDto;
    }
    public async deleteLocalSynonym(
        localSynonymReferenceId: string,
        versionId: string
    ) {
        const parameter = new DeleteLocalSynonymParameter(
            localSynonymReferenceId
        );
        parameter.VersionId = versionId;
        const result = await this.entityApiService.deleteLocalSynonym(
            parameter
        );
        result.DeletedSynonymDto.HddData.setVersionId(versionId);
        return result.DeletedSynonymDto;
    }

    public async addObjectLink(
        sourcesIds: string[],
        targetEntityIds: string[],
        objectLinkType: ObjectLinkType,
        versionId?: string,
        addLinkAction = AddLinkAction.Append
    ) {
        const param = AddLinkedEntitiesParameter.createModern(
            sourcesIds,
            objectLinkType,
            targetEntityIds,
            null,
            null,
            null,
            addLinkAction
        );
        param.VersionId = versionId;

        const result = await this.addEntityReferencesGeneric(param);
        result.Links?.forEach((link) => {
            link.LinkedEntities = link.LinkedEntities.map((entity) =>
                GenericDeserialize(entity, EntityItem)
            );
            link.LinkEntity = GenericDeserialize(link.LinkEntity, EntityItem);
        });
        return result;
    }

    public async deleteEntityLink(
        inputEntityId: string,
        entityLinkId: string,
        versionId: string,
        linkType: ObjectLinkType
    ) {
        const parameter = DeleteLinkedEntitiesParameter.createModern(
            [inputEntityId],
            linkType,
            [entityLinkId]
        );
        parameter.setVersionId(versionId);

        return await this.deleteEntityReferencesGeneric(parameter);
    }
    public async deleteMultiEntityLink(
        inputEntityIds: string[],
        versionId: string,
        entityLinkIds: string[],
        linkType: ObjectLinkType,
        isClearValue: boolean = false
    ) {
        const parameter = DeleteLinkedEntitiesParameter.createModern(
            inputEntityIds,
            linkType,
            entityLinkIds,
            isClearValue
        );
        parameter.setVersionId(versionId);
        return await this.deleteEntityReferencesGeneric(parameter);
    }

    /// When setting logical Parent, the Parent is HierarchyDataDescriptor object
    // TODO: Use specific API to set Logical Parent, as the effects can be much more important (When used on Modeler Entity)

    //#Archi-versionId versionId is never passed
    private async setEntitiesParent(
        parentId: string,
        childIds: string[],
        versionId: string
    ) {
        const parameter = new SetEntitiesParentParameter(
            parentId,
            childIds,
            null,
            null
        );
        parameter.setVersionId(versionId);

        try {
            const result = await this.entityApiService.setEntitiesParent(
                parameter
            );
            this.entityEventService.notifyEntityParentUpdate(result, false);
            // TODO (NEW CAT): We can surely simplify the data returned on the SetParents call
            // NOTE: In the case of an EntityLink type of relation, the Parent is in parameter.DataReferenceId unless the field IsReverse is specified
            // (Eventually, it would be best if the Source property was always on the same side, but for now that is the current logic implemented).
            // You can also look at the method: setEntitiesLogicalParent, used when setting the Parent (in the attribute-logical-parent-input).
            // REMINDER: The parent can be set by three ways:
            // * Using the logical parent input
            // * Using the Entity links tab in the Property/Usage details
            // * Using the Linked Objects pane tool
            // The following code is to exclude the Parent Entities from the Update Entity events.
            //const updatedEntitiesToInclude = parameter.DataReferenceIdList.slice()
            result.UpdatedEntities?.forEach((ue) => {
                this.entityEventService.notifyEntityLinkAdd(ue);
                const identifier = new DataIdentifier(
                    ue.ReferenceId,
                    ue.DataTypeName
                );
                this.entityEventService.notifyEntityLinkIdentifierAdd(
                    identifier
                );
            });
            result.Links?.forEach((link) =>
                link.LinkedEntities.forEach((le) => {
                    const leIdentifier = new DataIdentifier(
                        le.ReferenceId,
                        le.DataTypeName
                    );
                    this.entityEventService.notifyEntityLinkIdentifierAdd(
                        leIdentifier
                    );
                    this.entityEventService.notifyEntityLinkAdd(le);
                })
            );
            return result;
        } catch (e) {
            if (isUnsuccessfulApiError<SetEntitiesParentResult>(e)) {
                this.handleUpdateLinkError(e.error);
            }
            throw e;
        }
    }

    private handleUpdateLinkError(result: IUpdateLinksResult) {
        if (result.IsErrorWouldCreateCycle) {
            result.errorDetailsKey =
                'DgServerTypes.ServiceError.IsErrorWouldCreateCycle';
            result.ErrorDetails = this.translate.instant(
                result.errorDetailsKey
            );
        }

        if (result.IsErrorWouldBreakUniquenessRule) {
            result.errorDetailsKey =
                'DgServerTypes.ServiceError.IsErrorWouldBreakUniquenessRule';
            result.ErrorDetails = this.translate.instant(
                result.errorDetailsKey
            );
        }
        // Unhandled cases, or no error
        return false;
    }

    private async updateEntityTechnology(
        technologyCode: string,
        dataReferenceIds: string[],
        versionId: string,
        includeQualityData?: boolean
    ) {
        const param = new SetEntitiesTechnologyParameter(
            technologyCode,
            dataReferenceIds,
            includeQualityData
        );
        param.VersionId = versionId;
        return await this.entityApiService.setEntitiesTechnology(param);
    }

    private handleDeleteLinkResult(result: DeleteLinkedEntitiesResult) {
        if (result.IsErrorWouldBreakUniquenessRule) {
            result.errorDetailsKey =
                'DgServerTypes.ServiceError.IsErrorWouldBreakUniquenessRule';
            result.ErrorDetails = this.translate.instant(
                result.errorDetailsKey
            );
        }

        // Unhandled cases
        return false;
    }

    public async getEntitiesForSearch(
        searchTerm: string,
        spaceIdr: ISpaceIdentifier,
        searchServerTypes: ServerType[],
        includedEntityTypes?: EntityType[],
        excludedEntityTypes?: EntityType[],
        includedIds?: string[],
        excludedIds?: string[],
        includeOnlyHasWriteAccess?: boolean
    ) {
        const filters = new Array<Filter>();
        if (includedEntityTypes?.length) {
            filters.push(
                new Filter(
                    ServerConstants.Search.EntityTypeFilterKey,
                    FilterOperator.ListContains,
                    includedEntityTypes.map((e) => EntityType[e])
                )
            );
        }

        if (excludedEntityTypes?.length) {
            filters.push(
                new Filter(
                    ServerConstants.Search.EntityTypeFilterKey,
                    FilterOperator.ListExcludes,
                    excludedEntityTypes.map((e) => EntityType[e])
                )
            );
        }

        /** Search in both Technical And Functional Names */
        const attributeNames = [
            PropertyName.DisplayName,
            PropertyName.TechnicalName,
        ].toString();

        const parameter = new LoadMultiEntityParameter(
            spaceIdr.spaceId,
            searchServerTypes,
            true,
            0,
            100,
            searchTerm,
            attributeNames,
            '_score',
            [
                PropertyName.DisplayName,
                PropertyName.TechnicalName,
                PropertyName.LegacySubTypeAttributeKey,
            ],
            filters,
            null,
            false,
            null,
            excludedIds,
            includedIds,
            includeOnlyHasWriteAccess
        );
        parameter.setVersionId(spaceIdr?.versionId);
        return this.entityApiService.loadMultiEntity(parameter);
    }

    public async getTargetEntitiesFromServerType(
        textSearch: string,
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType,
        maxSize: number = null,
        filters: Filter[] = []
    ) {
        const entityParameter = new LoadMultiEntityParameter(
            spaceIdr.spaceId,
            serverType,
            true,
            0,
            maxSize,
            textSearch,
            null,
            PropertyName.DisplayName,
            [
                PropertyName.DisplayName,
                PropertyName.TechnicalName,
                PropertyName.LegacySubTypeAttributeKey,
            ],
            filters
        );
        entityParameter.setVersionId(spaceIdr?.versionId);
        return await this.entityApiService.loadMultiEntity(entityParameter);
    }
    public async getModelEntities(spaceIdr: ISpaceIdentifier) {
        return await this.getTargetEntitiesFromServerType(
            null,
            spaceIdr,
            ServerType.Model,
            1000
        );
    }

    /** returns true if the given entity is linked to any other entity */
    public async isEntityLinked(entityIdr: IEntityIdentifier) {
        if (!entityIdr?.ReferenceId) {
            return;
        }
        const p = new GetFilteredEntitiesParameter();
        p.DataReferenceIdList = [entityIdr.ReferenceId];
        p.MaxCount = 0;
        p.Filters = [
            new Filter(
                PropertyName.AllLinkedData,
                FilterOperator.FieldHasValue,
                []
            ),
        ];
        p.VersionId = entityIdr.VersionId;
        p.ParentReferenceId = SpaceIdentifier.fromEntity(entityIdr).spaceId;
        const res = await this.searchApiService.getFilteredEntities(p);
        return res.TotalCount > 0;
    }
}

/* Links changes to be made on an entity */
export interface IEntityLinksChangeInfo {
    linksToAdd?: LinkedDataItem[];
    linksToDelete?: LinkedDataItem[];
    kind?: EntityLinkTypeKind;
}

export interface ILoadMultiEntityOptions {
    //#region mandatory
    parentReferenceId: string;
    versionId: string;
    //#endregion
    //#region optional
    dataTypes?: ServerType | ServerType[];
    includeHdd?: boolean;
    startIndex?: number;
    size?: number;
    multiColumnsSearchString?: string;
    searchColumns?: string;
    sortKey?: string;
    includedAttributesFilter?: string[];
    filters?: Filter[];
    hddDataKind?: HddDataKind;
    isLogicalHierarchyMode?: boolean;
    maxHierarchyLevel?: number;
    excludedIds?: string[];
    dataReferenceIdList?: string[];
    includeOnlyHasWriteAccess?: boolean;
    includeHData?: boolean;
    includeNoAccessData?: boolean;
    //#endregion
}

export interface IModuleCount {
    module: DgModule;
    count: number;
}
