import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IListOption } from '@datagalaxy/core-ui';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { CoreUtil } from '@datagalaxy/core-util';
import { IFileOptionConfig } from '../file-option-form.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { FormsModule } from '@angular/forms';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-file-options-form',
    templateUrl: './dxy-file-options-form.component.html',
    styleUrls: ['./dxy-file-options-form.component.scss'],
    standalone: true,
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        DxyFieldSelectComponent,
        TranslateModule,
    ],
})
export class DxyFileOptionsFormComponent
    extends DxyBaseComponent
    implements OnInit
{
    //#region html bindings
    @Input() delimiter: string;
    @Input() allowedEncodings: string[];
    @Output() onConfigChange = new EventEmitter<IFileOptionConfig>();

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();
    //#endregion

    public readonly carriageAdapter: IFieldSelectAdapter<IListOption<string>> =
        {
            getText: (o) => CoreUtil.fromFnOrValue(o.labelText),
            getTextKey: (o) => CoreUtil.fromFnOrValue(o.labelKey),
            isModel: true,
            onSelectionChange: () => this.emitValues(),
            options: [
                {
                    labelKey: 'Import.Wizard.FileDeposit.CarriageAutoDetect',
                    data: '',
                },
                {
                    labelText: '\\r\\n (windows)',
                    data: '\r\n',
                },
                {
                    labelText: '\\n (unix, osx, linux)',
                    data: '\n',
                },
            ],
        };

    public readonly encodingAdapter: IFieldSelectAdapter<string> = {
        isModel: true,
        onSelectionChange: () => this.emitValues(),
    };

    private isDelimiterManuallyUpdated = false;

    constructor() {
        super();
    }

    ngOnInit() {
        this.carriageAdapter.current = this.carriageAdapter.options[0];
        this.encodingAdapter.current = (this.encodingAdapter.options =
            this.allowedEncodings)[0];
        this.emitValues();
    }

    public onManuallyChangeDelimiter() {
        this.isDelimiterManuallyUpdated = true;
        this.emitValues();
    }

    private emitValues() {
        this.onConfigChange.emit({
            delimiter: this.delimiter,
            carriage: this.carriageAdapter.current.data,
            encoding: this.encodingAdapter.current,
            isDelimiterModified: this.isDelimiterManuallyUpdated,
        });
    }
}
