<div class="left-section">
    <dxy-csv-file-deposit-card
        (onUploadFile)="onClickCsvBtn($event)"
        [showInfoBtn]="true"
    >
    </dxy-csv-file-deposit-card>
    <div *ngIf="showExistingConnections" class="card">
        <span
            class="title"
            translate
            [translateParams]="{ count: existingConnexionsCount }"
        >
            Import.GenericImportWizard.SolutionSelection.ExistingCard.CardTitle
        </span>
        <img
            class="existing-connections"
            src="/images/import/existing_connexions.svg"
            alt=""
        />
        <button mat-flat-button (click)="onClickConnector()">
            <span translate
                >Import.GenericImportWizard.SolutionSelection.ExistingCard.CardBtn</span
            >
        </button>
    </div>
</div>
<div class="right-section">
    <div class="filter-header">
        <!-- Module selection -->
        <dxy-value-list-filter
            labelKey="Import.GenericImportWizard.SolutionSelection.FiltersHeader.ModuleLbl"
            [(filter)]="moduleFilter"
            [items]="moduleFilterOptions$ | async"
            [noOperator]="true"
            [adapter]="moduleFilterAdapter"
            (filterChange)="onModuleFilterChange()"
        ></dxy-value-list-filter>
        <!-- Type selection -->
        <dxy-value-list-filter
            labelKey="Import.GenericImportWizard.SolutionSelection.FiltersHeader.ConnectionTypeLbl"
            [(filter)]="connectionTypeFilter"
            [items]="connectionTypeOptions"
            [noOperator]="true"
            [adapter]="connectionTypeFilterAdapter"
            (filterChange)="onConnectionTypeFilterChange()"
        ></dxy-value-list-filter>
        <div *ngIf="isSourceTypeDisplayed" class="dg5-filter-item">
            <div class="filter-group">
                <div class="filter-label" translate>
                    Import.GenericImportWizard.SolutionSelection.FiltersHeader.SourceTypeLbl
                </div>
                <div
                    class="filter-value"
                    [translate]="sourceTypeTranslationKey"
                ></div>
            </div>
            <button
                mat-icon-button
                class="dg5-small-icon-button"
                (click)="onClickDeleteSourceTypeFilter()"
            >
                <i class="glyph-cancelsearch"></i>
            </button>
        </div>

        <div class="search-bar">
            <div class="glyph glyph-search"></div>
            <input
                class="filter-value"
                type="text"
                [placeholder]="
                    'UI.Spotlight.filters.searchTermPlaceholder' | translate
                "
                [(ngModel)]="searchTerm"
                (ngModelChange)="onUpdateSearchTerm()"
            />
        </div>

        <!-- Api Button -->
        <a
            mat-button
            [href]="publicApiUrl"
            target="_blank"
            dxyLogFunctional="IMPORT_SELECT_API,R"
        >
            <i class="glyph-api"></i
            ><span translate>UI.NavBar.apiDocumentation</span>
        </a>
    </div>
    <div
        *ngIf="!isLoading"
        class="solutions-container"
        data-tracker-id="import-modal-techno-list"
    >
        <div class="filtered-solutions">
            <div
                *ngFor="let solution of filteredSolutions"
                (click)="onClickSolutionCard(solution)"
                class="card solution-card disable-description"
                [ngClass]="getSolutionClass(solution)"
                [dxyDataTestId]="getTestId(solution)"
            >
                <img
                    [src]="solution.iconUrl || 'images/dg3-connector.png'"
                    alt=""
                />
                <span class="title">{{ getSolutionTitle(solution) }}</span>
                <span class="description">{{
                    getSolutionDescription(solution)
                }}</span>

                <div class="solution-tag" [ngClass]="getTagClass(solution)">
                    <span [ngClass]="getTagIconClass(solution)"></span>
                    <span [translate]="getTagTranslateKey(solution)"></span>
                </div>
                <div
                    *ngIf="isOnlineSolution(solution)"
                    (click)="onClickSolutionCard(solution, $event, true)"
                    class="glyph-info"
                    dxyLogFunctional="IMPORT_ONLINE_OPEN_HELP,R"
                ></div>
            </div>
            <div
                (click)="onClickApiCard()"
                class="card solution-card"
                dxyLogFunctional="IMPORT_SELECT_API,R"
            >
                <img src="/images/connectors.svg" alt="api-img" />
                <span class="title" translate
                    >Import.GenericImportWizard.SolutionSelection.ApiCard.CardTitle</span
                >
                <span class="description" translate
                    >Import.GenericImportWizard.SolutionSelection.ApiCard.CardDescription</span
                >
                <div class="solution-tag" translate>
                    Import.GenericImportWizard.SolutionSelection.ApiCard.CardTag
                </div>
            </div>
            <div *ngIf="showFeedback" class="card solution-card feedback-card">
                <span class="title" translate
                    >Import.GenericImportWizard.SolutionSelection.FeedbackCard.CardTitle</span
                >
                <button mat-flat-button (click)="onClickFeedBack()">
                    <span translate
                        >Import.GenericImportWizard.SolutionSelection.FeedbackCard.BtnLbl</span
                    >
                </button>
            </div>
        </div>
    </div>
    <dxy-spinner *ngIf="isLoading" class="xl"></dxy-spinner>
</div>
