<ng-template #templateRef>
    <div class="dxy-graphical-context-menu--overlay-content">
        <dxy-graphical-toolbar
            [class]="toolbarClass"
            [options]="options"
            orientation="h"
            (functional)="onFunctional($event)"
        ></dxy-graphical-toolbar>
    </div>
</ng-template>
