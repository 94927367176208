import { clamp, roundToMultiple } from '@datagalaxy/core-util';
import {
    IRect,
    IShift,
    ISizeParams,
    ISizeRO,
    IWidthHeight,
    IXY,
    IXYParams,
    IXYRect,
    IXYRectParams,
    IXYRectRO,
    IXYRO,
    Point,
    TInputRect,
} from './2d.types';
import { Vect2 } from './Vect2';

/** DOMRect-like rectangle object and utility */
export class Rect implements DOMRect {
    //#region static
    private static readonly _papb = { pa: {} as IXY, pb: {} as IXY };

    //#region factories
    public static from<T extends IXYRect = Rect>(
        source?: TInputRect,
        result = new Rect() as IXYRect as T
    ) {
        if (source) {
            result.x = source?.x ?? source?.left;
            result.y = source?.y ?? source?.top;
            result.width = source?.width ?? source.w;
            result.height = source?.height ?? source.h;
        }
        return result;
    }

    public static fromSize(size: number | (IXYParams & ISizeParams)) {
        return Rect.setSize(new Rect(), size);
    }
    public static fromSizeCentered(size: number | (IXYParams & ISizeParams)) {
        const r = Rect.fromSize(size);
        return Rect.setPosition(r, -r.width / 2, -r.height / 2);
    }

    public static fromPoints(points: Point[]) {
        if (!points) {
            return null;
        }
        const xAxis = points.map((point) => point.x);
        const yAxis = points.map((point) => point.y);
        const minX = Math.min(...xAxis);
        const minY = Math.min(...yAxis);

        return Rect.from({
            x: minX,
            y: minY,
            width: Math.max(...xAxis) - minX,
            height: Math.max(...yAxis) - minY,
        });
    }

    //#endregion - factories

    //#region modify
    public static clear<T extends Partial<IXYRect>>(r: T) {
        r.x = r.y = r.width = r.height = 0;
        return r;
    }
    public static set<T extends Partial<IXYRect>>(
        r: T,
        x: number,
        y: number,
        width: number,
        height: number
    ) {
        r.x = x;
        r.y = y;
        r.width = width;
        r.height = height;
        return r;
    }
    public static setPosition<T extends Partial<IXY>>(
        r: T,
        x: number,
        y: number
    ) {
        r.x = x;
        r.y = y;
        return r;
    }
    public static setFromStartAndCorner<T extends IXYRect>(
        res: T,
        start: IXYRO,
        corner: IXYRO
    ) {
        if (start.x < corner.x) {
            res.width = Math.abs(corner.x - (res.x = start.x));
        } else {
            res.x = start.x - (res.width = Math.abs(corner.x - start.x));
        }
        if (start.y < corner.y) {
            res.height = Math.abs(corner.y - (res.y = start.y));
        } else {
            res.y = start.y - (res.height = Math.abs(corner.y - start.y));
        }
        return res;
    }
    public static setSize<T extends IWidthHeight>(
        r: T,
        size: number | (IXYParams & ISizeParams)
    ) {
        return typeof size == 'number'
            ? Rect.setWidthHeight(r, size, size, true)
            : Rect.setWidthHeight(
                  r,
                  size.width ?? size.x,
                  size.height ?? size.y,
                  true
              );
    }
    public static setWidthHeight<T extends IWidthHeight>(
        r: T,
        width?: number,
        height?: number,
        zeroIfUndefined?: boolean
    ) {
        if (zeroIfUndefined) {
            r.width = width || 0;
            r.height = height || 0;
        } else {
            if (width != undefined) {
                r.width = width;
            }
            if (height != undefined) {
                r.height = height;
            }
        }
        return r;
    }

    private static setSideInternal(r: IXYRect, side: RectSide, v: number) {
        switch (side) {
            case RectSide.left:
                r.width -= v - r.x;
                r.x = v;
                break;
            case RectSide.top:
                r.height -= v - r.y;
                r.y = v;
                break;
            case RectSide.right:
                r.width = v - r.x;
                break;
            case RectSide.bottom:
                r.height = v - r.y;
                break;
        }
    }

    public static setSides<T extends IXYRect = Rect>(
        r: IXYRectRO,
        sides: IRect2Sides,
        v: IXY,
        res = new Rect() as IXYRect as T
    ) {
        if (!r || !v) {
            return res;
        }
        Rect.copy(res, r);
        if (sides.lr) this.setSideInternal(res, sides.lr, v.x ?? 0);
        if (sides.tb) this.setSideInternal(res, sides.tb, v.y ?? 0);
        return res;
    }

    public static clamp<T extends IXYRect>(
        r: T,
        min: IXYRect,
        max?: IXYRect,
        res = new Rect() as IXYRect as T
    ) {
        if (!r) {
            return res;
        }
        res.x = clamp(r.x, min?.x, max?.x);
        res.y = clamp(r.y, min?.y, max?.y);
        res.width = clamp(r.width, min?.width, max?.width);
        res.height = clamp(r.height, min?.height, max?.height);
        return res;
    }
    public static clampSize<T extends IXYRect>(
        r: IXYRectRO,
        min: ISizeParams,
        max?: ISizeParams,
        res: T = new Rect() as IXYRect as T
    ) {
        if (!r) {
            return res;
        }
        res.width = clamp(r.width, min?.width, max?.width);
        res.height = clamp(r.height, min?.height, max?.height);
        return res;
    }

    private static clampSideInternal(
        r: IXYRect,
        side: RectSide,
        minSize: number,
        maxSize: number
    ) {
        switch (side) {
            case RectSide.left:
                return Rect.setSideInternal(
                    r,
                    side,
                    r.x + r.width - clamp(r.width, minSize, maxSize)
                );
            case RectSide.top:
                return Rect.setSideInternal(
                    r,
                    side,
                    r.y + r.height - clamp(r.height, minSize, maxSize)
                );
            case RectSide.right:
                return Rect.setSideInternal(
                    r,
                    side,
                    r.x + clamp(r.width, minSize, maxSize)
                );
            case RectSide.bottom:
                return Rect.setSideInternal(
                    r,
                    side,
                    r.y + clamp(r.height, minSize, maxSize)
                );
        }
    }
    public static clampSides<T extends IXYRect = Rect>(
        r: IXYRectRO,
        sides: IRect2Sides,
        min: ISizeParams,
        max?: ISizeParams,
        res = new Rect() as IXYRect as T
    ) {
        Rect.copy(res, r);
        if (!r) {
            return res;
        }
        if (sides.lr) {
            Rect.clampSideInternal(res, sides.lr, min?.width ?? 0, max?.width);
        }
        if (sides.tb) {
            Rect.clampSideInternal(
                res,
                sides.tb,
                min?.height ?? 0,
                max?.height
            );
        }
        return res;
    }

    public static round<T extends IXYRect>(
        r: T,
        cellSize?: ISizeParams,
        res = new Rect() as IXYRect as T
    ) {
        Rect.roundPosition(r, cellSize, res);
        return Rect.roundSize(r, cellSize, res);
    }
    public static roundPosition<T extends IXYRect = Rect>(
        r: IXYRectRO,
        cellSize?: ISizeParams,
        res = new Rect() as IXYRect as T
    ) {
        if (!r) {
            return res;
        }
        res.x = roundToMultiple(r.x, cellSize?.width);
        res.y = roundToMultiple(r.y, cellSize?.height);
        return res as T;
    }
    public static roundSize<T extends IXYRect = Rect>(
        r: IXYRectRO,
        cellSize?: ISizeParams,
        res = new Rect() as IXYRect as T
    ) {
        if (!r) {
            return res;
        }
        res.width = roundToMultiple(r.width, cellSize?.width);
        res.height = roundToMultiple(r.height, cellSize?.height);
        return res;
    }

    private static roundSideInternal(
        r: IXYRect,
        side: RectSide,
        size?: number
    ) {
        switch (side) {
            case RectSide.left:
                return Rect.setSideInternal(
                    r,
                    side,
                    roundToMultiple(r.x, size)
                );
            case RectSide.top:
                return Rect.setSideInternal(
                    r,
                    side,
                    roundToMultiple(r.y, size)
                );
            case RectSide.right:
                return Rect.setSideInternal(
                    r,
                    side,
                    roundToMultiple(r.x + r.width, size)
                );
            case RectSide.bottom:
                return Rect.setSideInternal(
                    r,
                    side,
                    roundToMultiple(r.y + r.height, size)
                );
        }
    }
    public static roundSides<T extends IXYRect = Rect>(
        r: IXYRectRO,
        sides: IRect2Sides,
        size?: ISizeParams,
        res = new Rect() as IXYRect as T
    ) {
        Rect.copy(res, r);
        if (!r || !sides) {
            return res;
        }
        if (sides.lr) {
            Rect.roundSideInternal(res, sides.lr, size?.width);
        }
        if (sides.tb) {
            Rect.roundSideInternal(res, sides.tb, size?.height);
        }
        return res;
    }

    public static shift<T extends IXYRect>(r: T, shift: Partial<IShift>) {
        return shift ? Rect.shiftXY(r, shift.dx, shift.dy) : r;
    }
    public static shiftXY<T extends IXYRect>(
        r: T,
        shiftX: number,
        shiftY: number
    ) {
        if (r) {
            r.x = (r.x || 0) + (shiftX || 0);
            r.y = (r.y || 0) + (shiftY || 0);
        }
        return r;
    }

    public static augmentBy<T extends IXYRect>(r: T, x: number, y: number) {
        if (!r || (!x && !y)) {
            return r;
        }
        r.x = (r.x || 0) - x / 2;
        r.y = (r.y || 0) - y / 2;
        r.width = (r.width || 0) + x;
        r.height = (r.height || 0) + y;
        return r;
    }

    public static augment<T extends IXYRect>(r: T, value: number | IXY) {
        return value && r
            ? typeof value == 'number'
                ? Rect.augmentBy(r, value, value)
                : Rect.augmentBy(r, value.x, value.y)
            : r;
    }

    public static copy<T extends Partial<IXYRect>>(
        target: T,
        source: Partial<IXYRect>,
        zeroIfUndefined?: boolean
    ) {
        return !source || !target || (source == target && !zeroIfUndefined)
            ? target
            : Rect.set(
                  target,
                  source.x ?? (zeroIfUndefined ? 0 : target.x),
                  source.y ?? (zeroIfUndefined ? 0 : target.y),
                  source.width ?? (zeroIfUndefined ? 0 : target.width),
                  source.height ?? (zeroIfUndefined ? 0 : target.height)
              );
    }

    public static centerOn<T extends IXYRect>(r: T, o: IXY) {
        return Rect.set(
            r,
            o.x - r.width / 2,
            o.y - r.height / 2,
            r.width,
            r.height
        );
    }

    public static normalize<T extends IXYRect>(
        r: T,
        maxWidth: number,
        maxHeight: number
    ) {
        return Rect.setWidthHeight(r, r.width / maxWidth, r.height / maxHeight);
    }

    public static truncateDecimals<T extends Partial<IXYRect>>(
        r: T,
        truncate?: boolean
    ) {
        if (truncate !== undefined && !truncate) {
            return r;
        }
        r.x = r.x | 0;
        r.y = r.y | 0;
        r.width = r.width | 0;
        r.height = r.height | 0;
        return r;
    }

    public static transform<T extends IXYRect = Rect>(
        r: IXYRectRO,
        transformX: (n: number) => number,
        transformY: (n: number) => number,
        /** can be *r* or another *IXYRect*, or nothing so to instanciate a new *Rect* */
        result: T = new Rect() as IXYRect as T
    ) {
        const x = transformX(r.x);
        const y = transformY(r.y);
        result.width = transformX(r.x + r.width) - x;
        result.height = transformY(r.y + r.height) - y;
        result.x = x;
        result.y = y;
        return result;
    }

    //#endregion - modify

    //#region query

    public static areSame(a: IXYRectRO, b: IXYRectRO) {
        return (
            a == b ||
            (a &&
                b &&
                a.x == b.x &&
                a.y == b.y &&
                a.width == b.width &&
                a.height == b.height)
        );
    }

    public static center<T extends IXY = IXY>(r: IXYRectRO, result = {} as T) {
        result.x = r.x + r.width / 2;
        result.y = r.y + r.height / 2;
        return result;
    }

    public static size<T extends IWidthHeight = IWidthHeight>(
        r: ISizeRO,
        result = {} as T
    ) {
        result.width = r.width;
        result.height = r.height;
        return result;
    }

    /** sets the result as the coordinates of either:
     * - the middle of the side if given 1 side
     * - the corner of the 2 given adjacent sides
     * - the center of the rectangle */
    public static getXY<T extends IXY = IXY>(
        r: IXYRect,
        sides: IRect2Sides,
        result = {} as T
    ) {
        switch (sides.lr) {
            case RectSide.left:
                result.x = r.x;
                break;
            case RectSide.right:
                result.x = r.x + r.width;
                break;
            default:
                result.x = r.x + r.width / 2;
        }
        switch (sides.tb) {
            case RectSide.top:
                result.y = r.y;
                break;
            case RectSide.bottom:
                result.y = r.y + r.height;
                break;
            default:
                result.y = r.y + r.height / 2;
        }
        return result;
    }

    /** sets the result as the smallest rectangle enclosing the 2 given ones */
    public static box<T extends IXYRect = Rect>(
        a: IXYRectRO,
        b: IXYRectRO,
        result = new Rect() as IXYRect as T
    ) {
        result.x = Math.min(a.x, b.x);
        result.y = Math.min(a.y, b.y);
        result.width = Math.max(a.x + a.width, b.x + b.width) - result.x;
        result.height = Math.max(a.y + a.height, b.y + b.height) - result.y;
        return result;
    }

    public static includes(r: IXYRectRO, target: IXYRectRO, strict?: boolean) {
        const t = target;
        const rxmax = r.x + r.width,
            rymax = r.y + r.height,
            txmax = t.x + t.width,
            tymax = t.y + t.height;
        return strict
            ? r.x < t.x && txmax < rxmax && r.y < t.y && tymax < rymax
            : r.x <= t.x && txmax <= rxmax && r.y <= t.y && tymax <= rymax;
    }

    /** returns the minimal rectangle enclosing every given element */
    public static boundingBox<T extends TInputRect>(items: TItems<T>) {
        let xmin = Infinity,
            xmax = -Infinity;
        let ymin = Infinity,
            ymax = -Infinity;

        const withEach = (r: TInputRect) => {
            const left = r.x ?? r.left;
            const top = r.y ?? r.top;
            const right = left + (r.width ?? r.w);
            const bottom = top + (r.height ?? r.h);
            if (left < xmin) {
                xmin = left;
            }
            if (right > xmax) {
                xmax = right;
            }
            if (top < ymin) {
                ymin = top;
            }
            if (bottom > ymax) {
                ymax = bottom;
            }
        };

        const isArray = Array.isArray(items);
        if (
            !items ||
            (isArray && !items.length) ||
            (!isArray && items.empty())
        ) {
            xmin = xmax = ymin = ymax = 0;
        } else if (isArray) {
            items.forEach(withEach);
        } else {
            items.each(withEach);
        }

        return new Rect(xmin, ymin, xmax - xmin, ymax - ymin);
    }

    /** Returns a function that, given an object, returns true if this object is contained within the bounds of the provided *rect*.
     * @param strict true to to use &lt; and &gt; comparisons, instead of &lt;= and &gt;=
     * @param noDecimals true to process coordinates as integers (can be a bit faster)
     * */
    public static makeIsContained<
        TRect extends TInputRect,
        TObj extends TInputRect
    >(
        rect: TRect,
        strict?: boolean,
        noDecimals?: boolean,
        filter?: TFilterIsContainedFn<TObj, TRect>
    ): (o: TObj) => boolean {
        const { top, left } = rect,
            bottom =
                ((rect.top ?? rect.y) || 0) + ((rect.height ?? rect.h) || 0),
            right =
                ((rect.left ?? rect.x) || 0) + ((rect.width ?? rect.w) || 0);
        return noDecimals
            ? (o: TObj) => {
                  if (filter && !filter(o, rect)) {
                      return false;
                  }
                  const l = (o.x ?? o.left) | 0,
                      t = (o.y ?? o.top) | 0,
                      r = l + ((o.width ?? o.w) | 0),
                      b = t + ((o.height ?? o.h) | 0);
                  return strict
                      ? l > left && r < right && t > top && b < bottom
                      : l >= left && r <= right && t >= top && b <= bottom;
              }
            : (o: TObj) => {
                  if (filter && !filter(o, rect)) {
                      return false;
                  }
                  const l = (o.x ?? o.left) || 0,
                      t = (o.y ?? o.top) || 0,
                      r = l + ((o.width ?? o.w) || 0),
                      b = t + ((o.height ?? o.h) || 0);
                  return strict
                      ? l > left && r < right && t > top && b < bottom
                      : l >= left && r <= right && t >= top && b <= bottom;
              };
    }

    /** returns a [x, y, width, height] array made of the given rectangle's coordinates */
    public static asArray(
        r: IXYRectRO,
        res?: [number, number, number, number]
    ): [number, number, number, number] {
        if (res) {
            res[0] = r.x || 0;
            res[1] = r.y || 0;
            res[2] = r.width || 0;
            res[3] = r.height || 0;
            return res;
        }
        return [r.x || 0, r.y || 0, r.width || 0, r.height || 0];
    }

    /** returns the scale factor to apply to {sw, sh} to fit in (or *out* of) {dw, dh} */
    static scaleFactorToFit(
        sw: number,
        sh: number,
        dw: number,
        dh: number,
        out?: boolean
    ) {
        return sh * (out ? sw / dw : dw / sw) > dh ? dh / sh : dw / sw;
    }

    //#region corners

    public static topLeft<T extends IXY = IXY>(r: IXYRectRO, result = {} as T) {
        result.x = r.x;
        result.y = r.y;
        return result;
    }

    public static topRight<T extends IXY = IXY>(
        r: IXYRectRO,
        result = {} as T
    ) {
        result.x = r.x + r.width;
        result.y = r.y;
        return result;
    }

    public static bottomRight<T extends IXY = IXY>(
        r: IXYRectRO,
        result = {} as T
    ) {
        result.x = r.x + r.width;
        result.y = r.y + r.height;
        return result;
    }
    public static bottomLeft<T extends IXY = IXY>(
        r: IXYRectRO,
        result = {} as T
    ) {
        result.x = r.x;
        result.y = r.y + r.height;
        return result;
    }

    public static corner<T extends IXY = IXY>(
        r: IXYRectRO,
        right: boolean,
        bottom: boolean,
        result = {} as T
    ) {
        if (right && bottom) {
            Rect.bottomRight(r, result);
        } else if (right) {
            Rect.topRight(r, result);
        } else if (bottom) {
            Rect.bottomLeft(r, result);
        } else {
            Rect.topLeft(r, result);
        }
        return result;
    }

    //#endregion - corners

    //#region sides

    /** returns a point along the given side - defaults to the middle of the side */
    public static sidePoint<T extends IXY = IXY>(
        r: IXYRectRO,
        side: RectSide,
        res = {} as IXY as T,
        normalizedDistance = 0.5
    ) {
        switch (side) {
            case RectSide.top:
                res.x = r.x + r.width * normalizedDistance;
                res.y = r.y;
                break;
            case RectSide.right:
                res.x = r.x + r.width;
                res.y = r.y + r.height * normalizedDistance;
                break;
            case RectSide.bottom:
                res.x = r.x + r.width * normalizedDistance;
                res.y = r.y + r.height;
                break;
            case RectSide.left:
                res.x = r.x;
                res.y = r.y + r.height * normalizedDistance;
                break;
        }
        return res;
    }

    /** returns the coordinates of the starting point of the given side of the given rectangle */
    public static sideStart<T extends IXY = Vect2>(
        r: IXYRectRO,
        side: RectSide,
        result = new Vect2() as IXY as T,
        orientation?: SideOrientation
    ) {
        if (!r) {
            return result;
        }
        switch (orientation) {
            default:
                switch (side) {
                    case RectSide.top:
                        return Rect.topLeft(r, result);
                    case RectSide.bottom:
                        return Rect.bottomLeft(r, result);
                    case RectSide.right:
                        return Rect.topRight(r, result);
                    case RectSide.left:
                        return Rect.topLeft(r, result);
                }
                break;
            case SideOrientation.CW:
                switch (side) {
                    case RectSide.top:
                        return Rect.topLeft(r, result);
                    case RectSide.bottom:
                        return Rect.bottomRight(r, result);
                    case RectSide.right:
                        return Rect.topRight(r, result);
                    case RectSide.left:
                        return Rect.bottomLeft(r, result);
                }
                break;
            case SideOrientation.CCW:
                switch (side) {
                    case RectSide.top:
                        return Rect.topRight(r, result);
                    case RectSide.bottom:
                        return Rect.bottomLeft(r, result);
                    case RectSide.right:
                        return Rect.bottomRight(r, result);
                    case RectSide.left:
                        return Rect.topLeft(r, result);
                }
                break;
        }
        return result;
    }

    /** returns the coordinates of the ending point of the given side of the given rectangle */
    public static sideEnd<T extends IXY = Vect2>(
        r: IXYRectRO,
        side: RectSide,
        result = new Vect2() as IXY as T,
        orientation?: SideOrientation
    ) {
        if (!r) {
            return result;
        }
        switch (orientation) {
            default:
                switch (side) {
                    case RectSide.top:
                        return Rect.topRight(r, result);
                    case RectSide.bottom:
                        return Rect.bottomRight(r, result);
                    case RectSide.right:
                        return Rect.bottomRight(r, result);
                    case RectSide.left:
                        return Rect.bottomLeft(r, result);
                }
                break;
            case SideOrientation.CW:
                switch (side) {
                    case RectSide.top:
                        return Rect.topRight(r, result);
                    case RectSide.bottom:
                        return Rect.bottomLeft(r, result);
                    case RectSide.right:
                        return Rect.bottomRight(r, result);
                    case RectSide.left:
                        return Rect.topLeft(r, result);
                }
                break;
            case SideOrientation.CCW:
                switch (side) {
                    case RectSide.top:
                        return Rect.bottomLeft(r, result);
                    case RectSide.bottom:
                        return Rect.bottomRight(r, result);
                    case RectSide.right:
                        return Rect.topRight(r, result);
                    case RectSide.left:
                        return Rect.bottomLeft(r, result);
                }
                break;
        }
        return result;
    }
    /** returns the length of the given side of the given rectangle */
    public static sideLength(r: IXYRectRO, side: RectSide) {
        switch (side) {
            case RectSide.top:
            case RectSide.bottom:
                return r.width;
            case RectSide.right:
            case RectSide.left:
                return r.height;
        }
    }

    /** returns, for each of the 2 given rectangles, the side
     * for which the point (middle - by default) is the nearest to the other one. */
    public static nearestSides<T extends Partial<IRectABSides> = IRectABSides>(
        ra: IXYRectRO,
        rb: IXYRectRO,
        res = {} as IRectABSides as T,
        normalizedDistanceA = 0.5,
        normalizedDistanceB = 0.5,
        excludedSidesForA?: RectSide[],
        excludedSidesForB?: RectSide[]
    ) {
        const { pa, pb } = Rect._papb;
        let minDist = Infinity;
        rectSides.forEach((sa) => {
            if (excludedSidesForA?.includes(sa)) {
                return;
            }
            Rect.sidePoint(ra, sa, pa, normalizedDistanceA);
            rectSides.forEach((sb) => {
                if (excludedSidesForB?.includes(sb)) {
                    return;
                }
                Rect.sidePoint(rb, sb, pb, normalizedDistanceB);
                const d = Vect2.distanceSquared(pa, pb);
                if (d < minDist) {
                    res.a = sa;
                    res.b = sb;
                    minDist = d;
                }
            });
        });
        return res;
    }

    /** Returns, for the given *ra* rectangle, the side
     * for which the point (middle - by default) is the nearest to the given *sb* side's point
     * of the given *rb* rectangle */
    public static nearestSideToSide(
        ra: IXYRectRO,
        rb: IXYRectRO,
        sb: RectSide,
        normalizedDistanceA = 0.5,
        normalizedDistanceB = 0.5,
        excludedSides?: RectSide[]
    ) {
        const { pa, pb } = Rect._papb;
        let s: RectSide;
        Rect.sidePoint(rb, sb, pb, normalizedDistanceB);
        let minDist = Infinity;
        rectSides.forEach((sa) => {
            if (excludedSides?.includes(sa)) {
                return;
            }
            Rect.sidePoint(ra, sa, pa, normalizedDistanceA);
            const d = Vect2.distanceSquared(pa, pb);
            if (d < minDist) {
                s = sa;
                minDist = d;
            }
        });
        return s;
    }

    /** Returns, for the given *r* rectangle, the side
     * for which the point (middle - by default) is the nearest to the given *p* point.
     * *normalizedDistance* (.5 by default) is the normalized distance of the point representing the side,
     * along that side and from the start of that side, start/end orientation being:
     * top & bottom sides are left-to-right, left & right sides are top-to-bottom. */
    public static nearestSide(
        r: IXYRectRO,
        p: IXYRO,
        normalizedDistance = 0.5
    ) {
        const { pa } = Rect._papb;
        let s: RectSide;
        let minDist = Infinity;
        rectSides.forEach((rs) => {
            Rect.sidePoint(r, rs, pa, normalizedDistance);
            const d = Vect2.distanceSquared(pa, p);
            if (d < minDist) {
                s = rs;
                minDist = d;
            }
        });
        return s;
    }

    public static oppositeSide(side: RectSide) {
        switch (side) {
            case RectSide.top:
                return RectSide.bottom;
            case RectSide.right:
                return RectSide.left;
            case RectSide.bottom:
                return RectSide.top;
            case RectSide.left:
                return RectSide.right;
        }
    }

    public static side(s: RectSide | Side): Side {
        switch (s) {
            case RectSide.top:
            case 'top':
                return 'top';
            case RectSide.right:
            case 'right':
                return 'right';
            case RectSide.bottom:
            case 'bottom':
                return 'bottom';
            case RectSide.left:
            case 'left':
                return 'left';
        }
    }
    public static rectSide(s: Side | RectSide): RectSide {
        switch (s) {
            case 'top':
            case RectSide.top:
                return RectSide.top;
            case 'right':
            case RectSide.right:
                return RectSide.right;
            case 'bottom':
            case RectSide.bottom:
                return RectSide.bottom;
            case 'left':
            case RectSide.left:
                return RectSide.left;
        }
    }

    //#endregion - sides

    //#endregion - query

    //#region tostring
    public static rectToString(r: TInputRect, noDecimals?: boolean) {
        const truncate = noDecimals ? (n: number) => n | 0 : (n: number) => n;
        return `{ x: ${truncate(r.x ?? r.top)}, y: ${truncate(
            r.y ?? r.left
        )}, width: ${truncate(r.width)}, height: ${truncate(r.height)} }`;
    }
    public static toXYWidthHeight(r: TInputRect) {
        return r ? { x: r.x, y: r.y, width: r.width, height: r.height } : null;
    }

    public static toJSON(r: TInputRect) {
        if (!r) {
            return null;
        }
        const res = {} as IXYRect & IRect;
        ['x', 'y', 'width', 'height', 'top', 'left'].forEach((k) => {
            if (k in r) {
                res[k] = r[k];
            }
        });
        return res;
    }
    //#endregion

    //#endregion - static

    //#region instance

    get left() {
        return this.x;
    }
    set left(value: number) {
        this.x = value;
    }

    get top() {
        return this.y;
    }
    set top(value: number) {
        this.y = value;
    }

    get right() {
        return this.x + this.width;
    }
    set right(value: number) {
        this.width = value - this.x;
    }

    get bottom() {
        return this.y + this.height;
    }
    set bottom(value: number) {
        this.height = value - this.y;
    }

    constructor(
        public x = 0,
        public y = 0,
        public width = 0,
        public height = 0
    ) {}

    public clear() {
        return Rect.clear(this);
    }

    public copy(o: IXYRectParams) {
        return this.set(o.x, o.y, o.width, o.height);
    }

    public copySize(v: ISizeParams) {
        return this.setSize(v.width, v.height);
    }

    public centerOn(o: IXY) {
        return Rect.centerOn(this, o);
    }

    public set(x?: number, y?: number, width?: number, height?: number): Rect {
        return Rect.set(
            this,
            x ?? this.x,
            y ?? this.y,
            width ?? this.width,
            height ?? this.height
        );
    }
    public setPosition(x?: number, y?: number) {
        return Rect.setPosition(this, x ?? this.x, y ?? this.y);
    }

    public setFromStartAndCorner(start: IXYRO, corner: IXYRO) {
        return Rect.setFromStartAndCorner(this, start, corner);
    }

    public setSize(width?: number, height?: number) {
        return Rect.setWidthHeight(
            this,
            width ?? this.width,
            height ?? this.height
        );
    }

    public setSides(side: IRect2Sides, v: IXY) {
        return Rect.setSides(this, side, v, this);
    }

    public clamp(min: IXYRect, max?: IXYRect) {
        return Rect.clamp(this, min, max);
    }

    public clampSize(min: ISizeParams, max?: ISizeParams) {
        return Rect.clampSize(this, min, max, this);
    }

    public round(cellSize?: ISizeParams) {
        return Rect.round(this, cellSize, this);
    }

    public shiftXY(x: number, y: number) {
        return Rect.shiftXY(this, x, y);
    }

    public shift(shift: Partial<IShift>) {
        return Rect.shift(this, shift);
    }

    public augmentBy(x: number, y: number) {
        return Rect.augmentBy(this, x, y);
    }

    public augment(value: number | IXY) {
        return Rect.augment(this, value);
    }

    public transformSelf(
        transformX: (n: number) => number,
        transformY: (n: number) => number
    ) {
        return Rect.transform(this, transformX, transformY, this);
    }

    public truncateSelf(truncate?: boolean) {
        return Rect.truncateDecimals(this, truncate);
    }

    /** subtract the given *r* rectangle's position from this rectangle's position */
    public makeRelativeTo(r: IXYRectRO) {
        return Rect.shiftXY(this, -r.x, -r.y);
    }

    public clone() {
        return Rect.from(this);
    }
    public copyTo(r: IXYRect) {
        return r && Rect.copy(r, this);
    }

    /** returns true if this rectangle includes the given one */
    public includes(r: IXYRectRO) {
        return Rect.includes(this, r);
    }

    public center<T extends IXY = IXY>(result?: T) {
        return Rect.center(this, result);
    }

    public size<T extends IWidthHeight = IWidthHeight>(result?: T) {
        return Rect.size(this, result);
    }

    public corner<T extends IXY = IXY>(
        right: boolean,
        bottom: boolean,
        result?: T
    ) {
        return Rect.corner(this, right, bottom, result);
    }

    /** returns a point along the given side (the middle if *distance* is undefined or .5) */
    public sidePoint<T extends IXY = IXY>(
        side: RectSide,
        result?: T,
        distance?: number
    ) {
        return Rect.sidePoint(this, side, result, distance);
    }

    /** returns the scale factor to apply to this rectangle to fit in (or *out* of) the given *r* rectangle */
    public scaleFactorToFit(r: IWidthHeight, out?: boolean) {
        return Rect.scaleFactorToFit(
            this.width,
            this.height,
            r.width,
            r.height,
            out
        );
    }

    public toXYWidthHeight() {
        return Rect.toXYWidthHeight(this);
    }

    public toJSON() {
        return Rect.toJSON(this);
    }

    //#endregion - instance
}

//#region types

type TD3Selection<T extends TInputRect> = {
    empty(): boolean;
    each(withEach: (r: T) => void): void;
};
type TItems<T extends TInputRect> = T[] | TD3Selection<T>;

export type TFilterIsContainedFn<
    TObj extends TInputRect = TInputRect,
    TRect extends TInputRect = TInputRect
> = (o: TObj, container: TRect) => boolean;

/** Side of a rectangle */
export enum RectSide {
    none = 0,
    top,
    right,
    bottom,
    left,
}
/** the 4 sides of a rectangle, clockwise from top */
export const rectSides: Readonly<RectSide>[] = [
    RectSide.top,
    RectSide.right,
    RectSide.bottom,
    RectSide.left,
];

export type Side = 'top' | 'right' | 'bottom' | 'left';
export const sides: Readonly<Side>[] = ['top', 'right', 'bottom', 'left'];

/** Orientation of sides, for start/end computation */
export enum SideOrientation {
    /** top & bottom sides are oriented left-to-right;
     *  left & right sides are oriented top-to-bottom */
    default = 0,
    /** clockwise */
    CW,
    /** counterclockwise */
    CCW,
}

/** 1 side, or 2 adjacent sides of a rectangle */
export interface IRect2Sides {
    lr?: RectSide.left | RectSide.right;
    tb?: RectSide.top | RectSide.bottom;
}
/** 2 sides - for example the nearest sides of 2 rectangles */
export interface IRectABSides {
    a: RectSide;
    b: RectSide;
}

//#endregion types
