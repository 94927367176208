import {
    EntityLinkCreatedEvent,
    EntityLinkDeletedEvent,
    EntityLinkUpdatedEvent,
    HierarchyDataDescriptor,
    ObjectLinkType,
} from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [
    EntityLinkCreatedEvent,
    EntityLinkUpdatedEvent,
    EntityLinkDeletedEvent,
];
type TEntityLinkEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to get entity link events translation inputs
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class EntityLinkEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TEntityLinkEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const data = getUserEventTranslationInputs(this.event);
        this.addLinkTranslationData(data);
        return data;
    }

    private addLinkTranslationData(data: IEventTranslationInputs) {
        const sourceHdd = this.event.SourceHdd;
        const targetHdd = this.event.TargetHdd;
        if (sourceHdd) {
            data.navLinks.push(this.getSourceEntityNavLink(sourceHdd));
        }
        if (targetHdd) {
            data.navLinks.push(this.getTargetEntityNavLink(targetHdd));
        }
        data.translateParameters.push({
            key: 'sourceEntityName',
            value: sourceHdd?.DisplayName,
        });
        data.translateParameters.push({
            key: 'targetEntityName',
            value: targetHdd?.DisplayName,
        });
        data.translateParameters.push({
            key: 'entityLinkName',
            valueTranslationKey: this.getEntityLinkName(),
        });
    }

    private getEntityLinkName() {
        const linkType = this.event.LinkType;
        return `DgServerTypes.ObjectLinkType.${ObjectLinkType[linkType]}`;
    }

    private getSourceEntityNavLink(targetHdd: HierarchyDataDescriptor) {
        return this.getHddNavLink(targetHdd, 'nav-link-source-entity');
    }

    private getTargetEntityNavLink(targetHdd: HierarchyDataDescriptor) {
        return this.getHddNavLink(targetHdd, 'nav-link-target-entity');
    }
}
