import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';

/**
 * Those enums are stored in database using SearchFacetFilterSettings class
 * The string value is saved
 */
enum SearchFacetFilterType {
    Module = 'Module',
    Technology = 'TechnologyCode',
    EntityType = '_EntityType',
    EntityStatus = 'EntityStatus',
    Domains = 'Domains',

    CdoUsers = 'CdoUsers',
    CisoUsers = 'CisoUsers',
    DpoUsers = 'DpoUsers',
    ExpertUsers = 'ExpertUsers',
    DataOwners = 'DataOwners',
    DataStewards = 'DataStewards',

    GdprContainsPersonalData = 'GdprContainsPersonalData',
    GdprContainsSensitiveData = 'GdprContainsSensitiveData',
    GdprIsPersonalData = 'GdprIsPersonalData',
    GdprIsSensitiveData = 'GdprIsSensitiveData',
    GdprRiskLevel = 'GdprRiskLevel',
    GdprPersonalDataType = 'GdprPersonalDataType',
    GdprSensitiveDataType = 'GdprSensitiveDataType',
    GdprPhysicalPersonType = 'GdprPhysicalPersonType',

    CreationTime = 'CreationTime',
    LastModificationTime = 'LastModificationTime',
}

export class SearchFacetFilterSettings {
    public static defaultSettings: SearchFacetFilterSettings = {
        version: '1.2',
        groups: [
            {
                translateTextKey: 'UI.Search.facets.groups.general',
                filters: [
                    {
                        type: SearchFacetFilterType.Module,
                        selected: true,
                        attributeMetaType: AttributeMetaType.ValueList,
                    },
                    {
                        type: SearchFacetFilterType.Technology,
                        selected: true,
                        attributeMetaType: AttributeMetaType.ValueList,
                    },
                    {
                        type: SearchFacetFilterType.EntityType,
                        selected: true,
                        attributeMetaType: AttributeMetaType.ValueList,
                    },
                    {
                        type: SearchFacetFilterType.EntityStatus,
                        selected: true,
                        attributeMetaType: AttributeMetaType.ValueList,
                    },
                    {
                        type: SearchFacetFilterType.Domains,
                        selected: true,
                        attributeMetaType: AttributeMetaType.ClientTag,
                    },
                    {
                        type: SearchFacetFilterType.LastModificationTime,
                        selected: true,
                        attributeMetaType: AttributeMetaType.Date,
                    },
                    {
                        type: SearchFacetFilterType.CreationTime,
                        selected: true,
                        attributeMetaType: AttributeMetaType.Date,
                    },
                ],
            },
            {
                translateTextKey: 'UI.Search.facets.groups.managers',
                filters: [
                    {
                        type: SearchFacetFilterType.DataOwners,
                        selected: true,
                        attributeMetaType:
                            AttributeMetaType.StewardUserReference,
                    },
                    {
                        type: SearchFacetFilterType.DataStewards,
                        selected: true,
                        attributeMetaType:
                            AttributeMetaType.StewardUserReference,
                    },
                    {
                        type: SearchFacetFilterType.CdoUsers,
                        selected: false,
                        attributeMetaType:
                            AttributeMetaType.StewardUserReference,
                    },
                    {
                        type: SearchFacetFilterType.CisoUsers,
                        selected: false,
                        attributeMetaType:
                            AttributeMetaType.StewardUserReference,
                    },
                    {
                        type: SearchFacetFilterType.DpoUsers,
                        selected: false,
                        attributeMetaType:
                            AttributeMetaType.StewardUserReference,
                    },
                    {
                        type: SearchFacetFilterType.ExpertUsers,
                        selected: false,
                        attributeMetaType:
                            AttributeMetaType.StewardUserReference,
                    },
                ],
            },
            {
                translateTextKey: 'UI.Search.facets.groups.gdpr',
                filters: [
                    {
                        type: SearchFacetFilterType.GdprContainsPersonalData,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Boolean,
                    },
                    {
                        type: SearchFacetFilterType.GdprContainsSensitiveData,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Boolean,
                    },
                    {
                        type: SearchFacetFilterType.GdprIsPersonalData,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Boolean,
                    },
                    {
                        type: SearchFacetFilterType.GdprIsSensitiveData,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Boolean,
                    },
                    {
                        type: SearchFacetFilterType.GdprRiskLevel,
                        selected: false,
                        attributeMetaType: AttributeMetaType.ValueList,
                    },
                    {
                        type: SearchFacetFilterType.GdprPersonalDataType,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Hierarchy,
                    },
                    {
                        type: SearchFacetFilterType.GdprSensitiveDataType,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Hierarchy,
                    },
                    {
                        type: SearchFacetFilterType.GdprPhysicalPersonType,
                        selected: false,
                        attributeMetaType: AttributeMetaType.Hierarchy,
                    },
                ],
            },
        ],
    };

    version: string;
    groups: SearchFacetFilterSettingsGroup[];
}

export class SearchFacetFilterSettingsItem {
    type: SearchFacetFilterType;
    selected?: boolean;
    displayName?: string;
    highlightText?: string;
    attributeMetaType: AttributeMetaType;
}

export class SearchFacetFilterSettingsGroup {
    translateTextKey: string;
    filters: SearchFacetFilterSettingsItem[];
}
