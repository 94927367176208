import { DataUtil } from '../shared/util/DataUtil';
import { GlyphUtil } from '../shared/util/GlyphUtil';
import { Injectable } from '@angular/core';
import { IHierarchicalData, ServerType } from '@datagalaxy/dg-object-model';
import {
    DgModule,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';

/**
 * #Archi-service-leaf (Does not reference any other app service)
 */
@Injectable({ providedIn: 'root' })
export class GlyphService {
    public static getColorClassFromServerType(serverType: ServerType) {
        const module = DataUtil.getModuleFromServerType(serverType);
        return GlyphUtil.getColorClassFromModule(module);
    }

    public static getColoredHdDataGlyph(hd: IHierarchicalData) {
        return (
            hd &&
            `${GlyphUtil.getHdDataGlyph(
                hd
            )} ${GlyphService.getColorClassFromServerType(hd.DataServerType)}`
        );
    }

    public getDataGlyphClass(typeName: string, subTypeName: string = null) {
        return GlyphUtil.getServerTypeGlyphClass(
            ServerType[typeName],
            subTypeName
        );
    }

    public getModuleColoredGlyphClass(dgModule: DgModule) {
        return dgModule
            ? getModuleDefinition(DgModule[dgModule]).coloredGlyphClass
            : '';
    }
}
