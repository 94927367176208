import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxyToastComponent } from './toast/toast.component';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports: [
        CommonModule,
        DxyButtonsModule,
        ToastrModule.forRoot({
            toastComponent: DxyToastComponent,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
        }),
        DxyToastComponent,
    ],
    exports: [DxyToastComponent],
})
export class ToasterModule {}
