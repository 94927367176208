import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SecurityService } from '../../services/security.service';

export function mailValidator(securityService: SecurityService): ValidatorFn {
    return (mailControl: AbstractControl) => {
        const error = securityService.checkEmailFormatTranslated(
            mailControl.value
        );
        return error ? { mailFormat: error } : null;
    };
}
