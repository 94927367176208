<div
    *ngIf="displayedItems"
    class="collection-cell-container"
    [class.align-right]="alignRight"
    [style.max-width]="maxWidthString"
    [style.max-height]="maxHeight"
    [style.min-height]="lineHeightString"
    [style.flex-wrap]="maxLines > 1 ? 'wrap' : 'nowrap'"
    [dxyRichTooltip]="moreCountTooltipRef"
    [richTooltipDisabled]="!showUniqueTooltip"
    richTooltipPosition="below"
    [richTooltipPositionOptions]="{ offsetY: 2 }"
    (mouseenter)="showUniqueTooltip && ajustTooltipItems()"
    (mouseleave)="showUniqueTooltip && destroyTooltip()"
>
    <div
        *ngFor="let item of displayedItems; let index = index"
        class="collection-item"
        [class.hide-ellipsed]="hideEllipsedLabel"
        [style.margin-left]="index % itemsPerLine > 0 ? spacingString : '0px'"
        [style.min-width.px]="itemSize"
        [style.height]="lineHeightString"
    >
        <dxy-renderer
            [matTooltip]="labelsHidden ? getText(item) : ''"
            [matTooltipDisabled]="!showSeparateTooltip"
            [style.width.px]="itemSize"
            [isMini]="mini"
            [renderer]="item.renderData.renderer"
            [param]="item.renderData.param"
        ></dxy-renderer>
        <div
            *ngIf="!this.labelsHidden && item.itemText"
            class="item-text"
            [dxyEllipsisTooltip]="item.tooltipText"
            [style.margin-right.px]="
                spacing < 0 ? -spacing + config.labelPadding : 0
            "
            [dxyEllipsisTooltipDisabled]="!showSeparateTooltip"
        >
            {{ item.itemText }}
        </div>
    </div>
    <ng-container *ngIf="ellipsisText">
        <div
            *ngIf="!moreCountAsOption"
            class="more-count-container"
            [style.width]="moreCountSizeString"
            [dxyRichTooltip]="moreCountTooltipRef"
            [richTooltipDisabled]="!showMoreCountTooltip"
            richTooltipPosition="below"
            (mouseenter)="showMoreCountTooltip && ajustTooltipItems()"
            (mouseleave)="showMoreCountTooltip && destroyTooltip()"
        >
            <div class="more-count">
                {{ ellipsisText }}
            </div>
        </div>
        <div
            *ngIf="moreCountAsOption"
            class="more-count-container collection-item"
            [style.margin-left]="spacingString"
            [style.flex-basis.px]="itemSize"
            [style.width.px]="itemSize"
            [style.height]="lineHeightString"
            [dxyRichTooltip]="moreCountTooltipRef"
            [richTooltipDisabled]="!showMoreCountTooltip"
            richTooltipPosition="below"
            (mouseenter)="showMoreCountTooltip && ajustTooltipItems()"
            (mouseleave)="showMoreCountTooltip && destroyTooltip()"
        >
            <div class="more-count" [style.width.px]="itemSize">
                {{ ellipsisText }}
            </div>
        </div>
    </ng-container>
</div>

<ng-template #moreCountTooltipRef>
    <div class="dg5-custom-tooltip more-count-tooltip">
        <h3 *ngIf="tooltipTitle">
            {{ tooltipTitle }}
        </h3>
        <div
            *ngFor="let item of options | slice : 0 : maxMoreCountOptions"
            class="collection-item"
            [style.height]="lineHeightString"
        >
            <dxy-renderer
                [style.width.px]="itemSize"
                [isMini]="mini"
                [renderer]="item.renderData.renderer"
                [param]="item.renderData.param"
            ></dxy-renderer>
            <div class="item-text">
                {{ item.labelText }}
            </div>
        </div>
        <div
            *ngIf="maxMoreCountOptions < options.length"
            class="collection-item"
        >
            + {{ options.length - maxMoreCountOptions }}
        </div>
    </div>
</ng-template>
