<div
    #menuTrigger="matMenuTrigger"
    class="menu-trigger"
    [style.left.px]="position?.left"
    [style.top.px]="position?.top"
    [matMenuTriggerFor]="menu"
    (menuClosed)="onMenuClose()"
></div>
<mat-menu #menu="matMenu" class="dg5-mat-menu no-max-width" xPosition="before">
    <dxy-burger-menu
        [options]="options"
        (onLogFunctional)="onLogFunctional.emit($event)"
    ></dxy-burger-menu>
</mat-menu>
