import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { WorkflowBriefDto } from './workflow';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowApiService {
    constructor(private restApiService: RestApiService) {}

    public getWorkflows(spaceGuid: string): Observable<WorkflowBriefDto[]> {
        return this.restApiService.get<WorkflowBriefDto[]>(
            `workflow/space/${spaceGuid}/workflows`
        );
    }
}
