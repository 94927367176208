<dxy-date-filter
    [(filter)]="filter"
    [labelText]="label"
    [isMini]="isMini"
    [readonly]="readOnly"
    [operators]="operators"
    [hasRemove]="showRemoveButton"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    (filterChange)="onFilterChange()"
    (remove)="onRemove()"
    (onOpenClose)="onOpenCloseMenu($event)"
></dxy-date-filter>
