import { SuggestionsReminderEvent } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IDgEventNavLink,
    IEventTranslationInputs,
} from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';
import { resolveClientBaseRelativeUrl } from '../common/dg-event-translation-nav-links.helpers';

/**
 * ## Role
 * Adapter to get SuggestionsReminderEvent translation inputs
 */
@registerTypedEventTranslationAdapter([SuggestionsReminderEvent])
export class SuggestionsReminderEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: SuggestionsReminderEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const data = getUserEventTranslationInputs(this.event);
        data.navLinks = [this.getNavLinkData()];
        return data;
    }

    private getNavLinkData(): IDgEventNavLink {
        return {
            linkCssClass: 'nav-link-suggestion-panel',
            relativeUrl: resolveClientBaseRelativeUrl(this.event),
        };
    }
}
