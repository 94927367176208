<!-- selected items horizontal list, with remove action in edit mode -->
<div
    tabindex="0"
    class="field-value"
    [matMenuTriggerFor]="menu"
    #matMenuTrigger="matMenuTrigger"
    (mousedown)="onMenuTriggerMouseDown()"
    (menuOpened)="onToggle(true)"
    (menuClosed)="onToggle(false)"
>
    <!-- on class="mini-tag" [class.default]="option.isDefaultValue" -->
    <div
        *ngIf="!selectedValueContentRef.children.length"
        class="select-entry-icon"
    >
        <div class="multi-tag">
            <div
                *ngFor="let option of orderedSelectedItems"
                class="tag-wrapper"
            >
                <dxy-option-item
                    class="mini-tag"
                    [option]="option"
                    [class.disabled]="disabled"
                    [isMini]="true"
                    [dataType]="dataType"
                    tooltipsPlacement="above"
                    (click)="onSelectedItemClick($event, option)"
                ></dxy-option-item>
                <button
                    *ngIf="showRemoveOption(option)"
                    (click)="onRemoveOption(option, $event)"
                    [dxyDataTestId]="'remove-' + option.valueId"
                    type="button"
                    class="remove-tag-btn glyph-cancelsearch"
                ></button>
            </div>
            <div *ngIf="moreTagCount > 0" class="tag-ellipse">
                <div
                    class="tag-ellipse-text"
                    [translateParams]="{ count: moreTagCount }"
                    translate
                >
                    CoreUI.MultiSelect.lblSelectedMore
                </div>
            </div>
        </div>
    </div>
    <div
        #selectedValueContentRef
        class="selected-value-content"
        [class.hidden]="!selectedValueContentRef.children.length"
    >
        <ng-content select="[selectedValueContent]"></ng-content>
    </div>
    <button
        *ngIf="!isReadonly"
        mat-icon-button
        class="action-icon-arrow-drop-down dg5-small-icon-button"
        type="button"
        dxyDataTestId="multi-select-drop-down-button"
    >
        <span class="glyph-arrow-drop-down"></span>
    </button>
</div>
<!-- menu (multi-select, edit mode only) -->
<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu fixed-width-400 no-padding"
    [yPosition]="yMenuPosition"
>
    <dxy-multiselect-list
        *ngIf="isMenuOpen"
        class="inside-dropdown not-an-item"
        [data]="data"
        (selectionChange)="onListSelectionChange($event)"
    >
        <ng-content select="[emptyResult]" emptyResult></ng-content>
        <ng-content
            select="[headerBeforeSearch]"
            headerBeforeSearch
        ></ng-content>
        <ng-content select="[headerAfterSearch]" headerAfterSearch></ng-content>
    </dxy-multiselect-list>
</mat-menu>
