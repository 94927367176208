import { TranslateService } from '@ngx-translate/core';
import { ActivityLogEntryDataTypeName } from '../activityLog.constants';
import {
    IActivityLogEntryAdapterParams,
    IActivityLogEntryTranslationAdapter,
    IActivityLogEntryTranslationData,
} from './activity-log-entry-translation.type';
import { UserService } from '../../services/user.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { EntityLinkService } from '../../entity-links/entity-link.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';
import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

/**
 * ## Role
 * Default Activity log adapter
 */
export class DefaultLogEntryAdapter
    implements IActivityLogEntryTranslationAdapter
{
    protected readonly baseTranslateKey = 'UI.ActivityLogData.Entries.';
    protected userService: UserService;
    protected translate: TranslateService;
    protected attributeDataService: AttributeDataService;
    protected linkedObjectService?: EntityLinkService;

    constructor(params: IActivityLogEntryAdapterParams) {
        this.userService = params.userService;
        this.linkedObjectService = params.linkedObjectService;
        this.translate = params.translate;
        this.attributeDataService = params.attributeDataService;
    }

    public getEntryTranslationData(
        entry: ActivityLogEntry
    ): IActivityLogEntryTranslationData {
        const hdata = this.getHData(entry);
        const parentDataName = hdata?.DisplayName ?? undefined;

        const childHData = this.getChildHData(entry);
        const childDataName = childHData?.DisplayName ?? undefined;

        const data: IActivityLogEntryTranslationData = {
            translateKey: this.getTranslateKey(entry),
            translateParams: {
                parentDataName,
                childDataName,
            },
            navLinkData: {
                hdata,
                childHData,
            },
        };

        if (entry.EntryType == ActivityLogEntryType.Update) {
            this.setUpdateData(entry, data);
        }

        if (
            childHData &&
            !childDataName &&
            childHData.DataServerType != undefined
        ) {
            const key = `DgServerTypes.ServerTypeName.${
                ServerType[childHData.DataServerType]
            }`;
            data.translateParams.childDataTypeName =
                this.translate.instant(key);
        }

        if (hdata && !parentDataName && hdata.DataServerType != undefined) {
            const key = `DgServerTypes.ServerTypeName.${
                ServerType[hdata.DataServerType]
            }`;
            data.translateParams.parentDataTypeName =
                this.translate.instant(key);
        }

        return data;
    }

    protected getTranslateKey(entry: ActivityLogEntry) {
        return `${this.baseTranslateKey}${entry.LogEntryType}`;
    }

    protected getUsedDataDescriptor(
        entry: ActivityLogEntry,
        hdd: HierarchyDataDescriptor
    ) {
        const parentDataDescriptors = entry.Parents;

        if (!hdd) {
            return;
        }

        switch (hdd.DataServerType) {
            case ServerType.ModelSettings:
                return this.getParentDataDescriptorByType(
                    parentDataDescriptors,
                    ServerType.Model
                );
            case ServerType.DataProcessingLink:
                return this.getParentDataDescriptorByType(
                    parentDataDescriptors,
                    ServerType.DataProcessing
                );
            case ServerType.ObjectSocialContainer:
                return this.getParentDataDescriptorByType(
                    parentDataDescriptors,
                    ServerType.Organization,
                    ServerType.Project,
                    ServerType.Property,
                    ServerType.DataProcessing,
                    ServerType.Column,
                    ServerType.Table,
                    ServerType.Model,
                    ServerType.Container,
                    ServerType.SoftwareElement
                );
            case ServerType.ObjectUserOpinion:
                return this.getParentDataDescriptorByType(
                    parentDataDescriptors,
                    ServerType.Organization,
                    ServerType.Project,
                    ServerType.Property,
                    ServerType.DataProcessing,
                    ServerType.Model,
                    ServerType.SoftwareElement
                );
            case ServerType.SpaceSettings:
                return this.getParentDataDescriptorByType(
                    parentDataDescriptors,
                    ServerType.Organization,
                    ServerType.Project
                );
            default:
                return hdd;
        }
    }

    protected getHData(entry: ActivityLogEntry) {
        const hdd = this.getUsedDataDescriptor(entry, entry.Data);
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }
    protected getChildHData(entry: ActivityLogEntry) {
        const childHdd = entry?.ChildData;
        const hdd = childHdd && this.getUsedDataDescriptor(entry, childHdd);
        return hdd && new HierarchicalData(hdd, [entry.Data, ...entry.Parents]);
    }

    protected setUpdateData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        let key = 'UpdateWithoutOldValue';
        if (entry.OldValueString && !this.isScreenLayoutUpdate(entry)) {
            key =
                entry.PropertyName == ServerConstants.Diagram.PublishingStatus
                    ? 'PublishingStatus'
                    : 'UpdateWithOldValue';
        }
        this.setUpdateTranlateParams(key, entry, data);
    }

    protected setUpdateTranlateParams(
        key: string,
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        data.translateKey = `${this.baseTranslateKey}${key}`;
        const useTranslation = this.usePropertyValueTranslation(entry);
        data.translateParams = {
            ...data.translateParams,
            useTranslateValues: useTranslation,
            propertyName: this.getPropertyNameTranslation(entry, entry.Data),
            oldValue:
                useTranslation && this.getPropertyValueTranslation(entry, true),
            newValue:
                useTranslation &&
                this.getPropertyValueTranslation(entry, false),
        };
    }

    protected getHDataById(entry: ActivityLogEntry, id: string) {
        const hdd = entry.Parents.find((c) => c.DataReferenceId == id);
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }

    protected getEntityLinkName(entry: ActivityLogEntry) {
        if (!entry.EntityLinkTypeValue || !this.linkedObjectService) {
            return '';
        }
        const translateKey =
            this.linkedObjectService.getEntityLinkTypeTranslateKey(
                entry.EntityLinkTypeValue
            );
        return translateKey ? this.translate.instant(translateKey) : undefined;
    }

    protected getPropertyNameTranslation(
        entry: ActivityLogEntry,
        hierarchyDataDescriptor: HierarchyDataDescriptor
    ) {
        if (!hierarchyDataDescriptor) {
            return;
        }
        return this.attributeDataService.getAttributeDisplayNameInternal(
            entry.PropertyName,
            entry.IsCdp,
            entry.PropertyName,
            hierarchyDataDescriptor.DataServerType
        );
    }

    protected getPropertyValueTranslation(
        entry: ActivityLogEntry,
        isForOldValue: boolean
    ): string {
        const value = isForOldValue
            ? entry.OldValueString
            : entry.NewValueString;

        switch (entry.attributeType) {
            case AttributeMetaType.Text:
                return value;
            case AttributeMetaType.Boolean: {
                if (value == null) {
                    return null;
                }
                const translateKey = `UI.ActivityLogData.BooleanPropertyValues.${value.toLowerCase()}`;
                return this.translate.instant(translateKey);
            }

            case AttributeMetaType.ValueList:
                if (entry.IsCdp) {
                    // WARNING : Not Handle Values By Server !!!!
                    return value;
                } else {
                    const attributeName = entry.PropertyName;
                    const attribute = this.attributeDataService.getAttribute(
                        entry.ServerType,
                        attributeName
                    );
                    return this.attributeDataService.getSystemAttributeValueListOptionDisplayName(
                        attribute.ListTypeName,
                        value
                    );
                }

            default:
                return null;
        }
    }

    private getParentDataDescriptorByType(
        parentDataDescriptors: HierarchyDataDescriptor[],
        ...serverTypes: ServerType[]
    ) {
        return parentDataDescriptors.find((dataDescriptor) =>
            serverTypes.includes(dataDescriptor.DataServerType)
        );
    }

    private isScreenLayoutUpdate(entry: ActivityLogEntry) {
        return (
            entry.EntryType === ActivityLogEntryType.Update &&
            entry.PropertyName === ServerConstants.PropertyName.Layout &&
            entry.Data.DataTypeName == ActivityLogEntryDataTypeName.screen
        );
    }

    private usePropertyValueTranslation(entry: ActivityLogEntry) {
        if (this.isScreenLayoutUpdate(entry)) {
            return false;
        }

        const fieldType = entry.attributeType;
        return (
            fieldType === AttributeMetaType.Boolean ||
            fieldType === AttributeMetaType.ValueList ||
            fieldType === AttributeMetaType.Text
        );
    }
}
