import { Injectable } from '@angular/core';
import { FunnelStep } from '../funnel-step';
import { FunnelState } from './funnel-state';
import { CurrentFunnelStep } from './current-funnel-step';
import { FunnelAction } from '../funnel-action';
import { distinctUntilChanged, filter, firstValueFrom } from 'rxjs';
import { BaseStateService } from '@datagalaxy/utils';

@Injectable({ providedIn: 'root' })
export class FunnelStateService extends BaseStateService<FunnelState> {
    constructor() {
        super({
            steps: [],
            index: 0,
            visible: false,
        });
    }

    public selectVisible() {
        return this.select((s) => s.visible);
    }

    public selectClosed() {
        return this.selectVisible()
            .pipe(filter((v) => !v))
            .pipe(distinctUntilChanged());
    }

    public selectCurrentStep() {
        return this.select((s) => {
            const stepsWithStepper =
                s.steps?.filter((s) => s.stepperVisible) ?? [];
            return {
                step: s.steps?.[s.index],
                currentIndex: s.index,
                currentStepperIndex: stepsWithStepper.indexOf(
                    s.steps?.[s.index]
                ),
                stepperCount: stepsWithStepper.length,
            } as CurrentFunnelStep;
        });
    }

    public initSteps(steps: FunnelStep[]) {
        this.setState({
            steps,
            index: 0,
        });
    }

    public start() {
        this.setState({
            visible: true,
        });
        return firstValueFrom(this.selectClosed());
    }

    public stop() {
        this.setState({
            visible: false,
        });
    }

    public executeFunnelAction(action: FunnelAction) {
        switch (action.actionType) {
            case 'previous':
                this.goPrevious();
                break;
            case 'confirmation':
            case 'next':
                this.goNext();
                break;
            case 'cancel':
            case 'finish':
                this.setState({
                    visible: false,
                });
                break;
        }
    }

    private goPrevious() {
        const newIndex = this.state.index - 1;
        if (newIndex < 0) {
            return;
        }
        this.setState({
            index: newIndex,
        });
    }

    private goNext() {
        const newIndex = this.state.index + 1;
        if (newIndex >= this.state.steps.length) {
            return;
        }
        this.setState({
            index: newIndex,
        });
    }
}
