import {
    EntityType,
    EntityTypeUtil,
    HierarchyDataDescriptor,
    IDataIdentifier,
    IEntityIdentifier,
    IHasHddData,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    getContextId,
    getReferenceId,
    getSpaceIdFromEntityId,
} from '@datagalaxy/webclient/utils';

export class EntityIdentifier implements IEntityIdentifier, IDataIdentifier {
    //#region static

    public static fromIHasHddData(e: IHasHddData) {
        return e && EntityIdentifier.fromIHierarchicalData(e.HddData);
    }

    public static fromIHierarchicalData(hd: IHierarchicalData) {
        return EntityIdentifier.fromIHierarchicalDataAndEntityType(
            hd,
            hd.EntityType || EntityType.None
        );
    }

    public static fromIHierarchicalDataAndEntityType(
        hd: IHierarchicalData,
        entityType: EntityType
    ) {
        return (
            hd &&
            new EntityIdentifier(hd.DataReferenceId, hd.VersionId, entityType)
        );
    }

    public static from(
        entity: IEntityIdentifier,
        entityType = entity?.entityType
    ) {
        return (
            entity &&
            new EntityIdentifier(
                entity.ReferenceId,
                entity.VersionId,
                entityType || EntityType.None
            )
        );
    }

    public static fromHdd(hdd: HierarchyDataDescriptor) {
        return (
            hdd &&
            new EntityIdentifier(
                hdd.DataReferenceId,
                hdd.VersionId,
                hdd.EntityType || EntityType.None
            )
        );
    }

    public static fromLocalId(
        entityLocalId: string,
        entityType: EntityType,
        contextAndVersionIdentifier: IEntityIdentifier
    ) {
        if (!contextAndVersionIdentifier || !entityLocalId || !entityType) {
            return;
        }
        const referenceId = getReferenceId(
            getContextId(contextAndVersionIdentifier.ReferenceId),
            entityLocalId
        );
        return new EntityIdentifier(
            referenceId,
            contextAndVersionIdentifier.VersionId,
            entityType
        );
    }

    /** returns true if ReferenceId, ServerType, VersionId and entityType are the same */
    public static areSame(a: IEntityIdentifier, b: IEntityIdentifier) {
        return (
            a &&
            b &&
            a.ReferenceId === b.ReferenceId &&
            a.entityType === b.entityType &&
            ((!a.VersionId && !b.VersionId) || a.VersionId === b.VersionId)
        );
    }

    /** returns true if ReferenceId, ServerType and entityType are defined and not falsy, otherwise false. */
    public static isValid(idr: IEntityIdentifier) {
        return !!(idr && idr.ReferenceId && idr.ServerType && idr.entityType);
    }

    //#endregion

    public readonly serverType: ServerType;

    public get ReferenceId() {
        return this.referenceId;
    }

    public get VersionId() {
        return this.versionId;
    }

    public get ServerType() {
        return this.serverType;
    }

    public get DataTypeName() {
        return ServerType[this.serverType];
    }

    public get DataServerType() {
        return this.serverType;
    }

    public get DataReferenceId() {
        return this.referenceId;
    }

    public get SpaceId() {
        return getSpaceIdFromEntityId(this.referenceId);
    }

    constructor(
        public referenceId: string,
        public versionId: string,
        public readonly entityType: EntityType
    ) {
        this.serverType =
            EntityTypeUtil.getServerType(this.entityType) || ServerType.Unknown;
    }

    public toString() {
        return `{ referenceId: ${this.referenceId}, serverType: ${
            this.serverType
        }:${this.DataTypeName}, versionId: ${this.versionId}, entityType: ${
            EntityType[this.entityType]
        } }`;
    }
}
