import { SerializableEnumeration } from 'cerialize';

/*
    WARNING: Do not rename properties marked with #abasedata-metadata
    as every property of type Ref may be registered in MasterDataService.typeMap
*/
//#region enums

export enum ModelType {
    /** DataBase */
    Relational = 1,
    /** FileStore */
    NonRelational = 2,
    NoSql = 3,
    TagBase = 4,
}
SerializableEnumeration(ModelType);
