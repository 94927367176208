import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { SynonymDto } from './entity-synonym';

@inheritSerialization(BaseServiceParameter)
export class GetSynonymsParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;

    constructor(dataReferenceId: string) {
        super();
        this.DataReferenceId = dataReferenceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetSynonymsResult extends BaseServiceResult {
    @deserializeAs(SynonymDto) public Synonyms!: SynonymDto[];
}

@inheritSerialization(BaseServiceParameter)
export class LocalSynonymParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;

    constructor(
        dataReferenceId: string,
        displayName: string,
        description: string
    ) {
        super();
        this.DataReferenceId = dataReferenceId;
        this.DisplayName = displayName;
        this.Description = description;
    }
}

@inheritSerialization(BaseServiceResult)
export class LocalSynonymResult extends BaseServiceResult {
    @deserializeAs(SynonymDto) public SynonymDto!: SynonymDto;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteLocalSynonymParameter extends BaseServiceParameter {
    @serialize public LocalSynonymDataReferenceId: string;

    constructor(localSynonymDataReferenceId: string) {
        super();
        this.LocalSynonymDataReferenceId = localSynonymDataReferenceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteLocalSynonymResult extends BaseServiceResult {
    @deserializeAs(SynonymDto) public DeletedSynonymDto!: SynonymDto;
}
