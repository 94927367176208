import { AfterContentInit, Directive, ElementRef } from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

@Directive({
    selector: '[dxyAutoFocusInput]',
    standalone: true,
})
export class DxyAutoFocusInputDirective
    extends DxyBaseDirective
    implements AfterContentInit
{
    constructor(private elementRef: ElementRef<HTMLInputElement>) {
        super();
    }

    ngAfterContentInit() {
        setTimeout(() => {
            this.elementRef.nativeElement.focus();
        }, 250);
    }
}
