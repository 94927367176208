<mat-table
    [dataSource]="datasource"
    cdkDropList
    (cdkDropListDropped)="onDrop($event)"
    ngModelGroup="foreignKeys"
>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
        *matRowDef="let row; columns: displayedColumns"
        cdkDrag
        cdkDragLockAxis="y"
    ></mat-row>
    <ng-container matColumnDef="primaryKey">
        <mat-header-cell *matHeaderCellDef translate
            >UI.Modeler.ForeignKeyColumnsTable.lblPrimaryKeyColumn</mat-header-cell
        >
        <mat-cell *matCellDef="let column">{{
            getPrimaryKeyColumnName(column)
        }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="columnType">
        <mat-header-cell *matHeaderCellDef translate
            >UI.Modeler.ForeignKeyColumnsTable.lblColumnType</mat-header-cell
        >
        <mat-cell *matCellDef="let column">{{
            getPrimaryKeyColumnTypeName(column)
        }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="newColumn">
        <mat-header-cell *matHeaderCellDef translate
            >UI.Modeler.ForeignKeyColumnsTable.lblNewColumn</mat-header-cell
        >
        <mat-cell *matCellDef="let column; let index = index">
            <div class="new-column-wrapper">
                <mat-checkbox
                    (change)="onCheckNewColumn(column)"
                    [disabled]="!hasWriteAccess"
                    [(ngModel)]="column.isNewColumn"
                    name="column-new-{{ index }}"
                ></mat-checkbox>
            </div>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="childTable">
        <mat-header-cell *matHeaderCellDef translate
            >UI.Modeler.ForeignKeyColumnsTable.lblChildTableColumn</mat-header-cell
        >
        <mat-cell *matCellDef="let column; let index = index">
            <dxy-field-text
                *ngIf="column.isNewColumn"
                [(ngModel)]="column.newColumnName"
                [placeholder]="
                    'UI.Modeler.ForeignKeyColumnsTable.lblChildTableColumn'
                        | translate
                "
                [disabled]="!hasWriteAccess"
                [compact]="true"
                name="column-name-{{ index }}"
                required
            ></dxy-field-text>
            <dxy-field-select
                *ngIf="!column.isNewColumn"
                [options]="availableChildColumnsByMapping.get(column)"
                [compact]="true"
                [adapter]="adapter"
                [disabled]="!hasWriteAccess"
                [(ngModel)]="column.childColumn"
                (ngModelChange)="onChildColumnSelected(column)"
                name="child-column-{{ index }}"
                required
            ></dxy-field-select>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let column">
            <div class="status-wrapper">
                <span
                    *ngIf="isMappingSuccess(column)"
                    class="glyph glyph-check-circle-filled dg_icon-medium text-success"
                    aria-hidden="true"
                >
                </span>
            </div>
        </mat-cell>
    </ng-container>
</mat-table>
