import { deserialize } from 'cerialize';

export abstract class ABaseSecurityData {
    @deserialize public HasAdministratorAccess!: boolean;
    @deserialize public HasReadAccess!: boolean;
    @deserialize public HasWriteAccess!: boolean;
    @deserialize public HasCreateAccess!: boolean;
    @deserialize public HasDeleteAccess!: boolean;
    @deserialize public HasImportAccess!: boolean;
    @deserialize public HasExportAccess!: boolean;

    /** Management Access is not dependent on Version Status: it verifies if a user has Administrator Access for Management. */
    @deserialize public HasManagementAccess!: boolean;

    /** Handle space case for catalog */
    @deserialize(String, 'HasImportAccess')
    public hasModuleImportAccess!: boolean;
}
