import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import { BaseMessage } from '@datagalaxy/data-access';

export enum GenerateScriptCaseOption {
    None = 0,
    ToLowercase = 1,
    ToUppercase = 2,
    WarnIfLowercase = 4,
    WarnIfUppercase = 8,
}
SerializableEnumeration(GenerateScriptCaseOption);

export enum GenerateScriptLineEndingOption {
    Default = 0,
    Windows = 1,
    Unix = 2,
}
SerializableEnumeration(GenerateScriptLineEndingOption);

export enum GenerateScriptQuoteOption {
    None = 0,
    SingleQuote = 1,
    DoubleQuote = 2,
    Brackets = 3,
}
SerializableEnumeration(GenerateScriptQuoteOption);

//#archi-cleanup should be named GenerateScriptParameterItem. can we rename ?
export class GenerateScriptItem {
    @serialize public DataReferenceId?: string;
    @serialize public DataTypeName?: string;

    constructor(dataReferenceId?: string, dataTypeName?: string) {
        this.DataReferenceId = dataReferenceId;
        this.DataTypeName = dataTypeName;
    }
}

//#archi-cleanup should be named GenerateScriptResultItemMessage. can we rename ?
@inheritSerialization(BaseMessage)
export class GenerateScriptMessage extends BaseMessage {
    @deserialize public MessageContext!: string;
}

export class GenerateScriptResultItem {
    @deserialize public IsScriptGenerated!: boolean;
    @deserialize public Script!: string;
    @deserializeAs(GenerateScriptMessage)
    public Messages: GenerateScriptMessage[] = [];
}
