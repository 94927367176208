import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetUserInfoQueryResult } from './get-user-info-query-result';

@Injectable({ providedIn: 'root' })
export class UserInfoApiService {
    constructor(private restApiService: RestApiService) {}

    public async getUserInfo() {
        return await this.restApiService.getPromise<GetUserInfoQueryResult>(
            'user-info'
        );
    }
}
