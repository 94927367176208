<div class="commentary-header">
    <span class="commentary-user-info">
        <dxy-profile-avatar
            [userId]="commentary.CreationUserId"
            [isMiniIcon]="true"
        ></dxy-profile-avatar>
        <span class="commentary-user-name">{{ userName }}</span>
    </span>
    <span class="commentary-date">{{ modificationDate }}</span>
    <div class="header-actions">
        <button
            *ngIf="isDeleteEnabled"
            mat-icon-button
            (click)="deleteCommentary()"
            [matTooltip]="'UI.CommentaryContainer.btnDelete' | translate"
            matTooltipPosition="below"
        >
            <i class="glyph-delete"></i>
        </button>
        <button
            *ngIf="isEditEnabled"
            mat-icon-button
            class="glyph-edit"
            (click)="editCommentary()"
            [matTooltip]="'UI.CommentaryContainer.btnEdit' | translate"
            matTooltipPosition="below"
        >
            <i class="glyph-edit"></i>
        </button>
    </div>
</div>
<form
    name="commentaryEditForm"
    class="commentary-body"
    (ngSubmit)="submitUpdateCommentary()"
>
    <dxy-rich-text-field
        name="contentText"
        class="commentary-text no-padding no-underline no-min-width"
        [readonly]="!isEditActive"
        [class.read-only]="!isEditActive"
        [class.form-control]="isEditActive"
        [(ngModel)]="commentary.Text"
        toolbarDisplayMode="focus"
        [takeFocus]="true"
        [mentionResolvers]="mentionResolvers"
        [noTabCapture]="true"
    ></dxy-rich-text-field>
    <div *ngIf="isEditActive" class="form-actions">
        <button
            type="button"
            mat-flat-button
            (click)="cancelUpdateCommentary()"
        >
            <span class="glyph-cancelsearch"></span>
            <span translate>UI.CommentaryContainer.btnCancel</span>
        </button>
        <button
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="isSubmitUpdateCommentaryDisabled"
            dxyLogFunctional="featureCode"
        >
            <span class="glyph glyph-check"></span>
            <span translate>UI.CommentaryItem.btnSubmit</span>
        </button>
    </div>
</form>
