<dxy-value-list-filter
    labelKey="UI.Filter.applyFiltersBtn"
    [(filter)]="typeFilter"
    [noOperator]="true"
    [items]="typeFilterOptions"
    [adapter]="typeFilterAdapter"
    [hasRemove]="hasSelectedTypes"
    (filterChange)="onFilterTypeChange()"
    (remove)="resetTypesFilter()"
    [forceSingleSelection]="true"
    [config]="filterConfig"
></dxy-value-list-filter>
<dxy-dropdown-section
    [sections]="sortSections"
    [btnTitle]="sortTitle"
    [btnGlyphClass]="'glyph-sort'"
    [btnClass]="'mat-button-base mat-button'"
    [enableSearch]="false"
    [flattenSections]="true"
></dxy-dropdown-section>
