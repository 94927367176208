import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { UserNotificationChannelsStateService } from '../../../notification-channels/state/user-notification-channels-state.service';
import { Observable } from 'rxjs';
import { msTeamsNotificationChannel } from '../../../notification-channels/ms-teams-notification-channel';
import { ClientNotificationChannelIntegrationDto } from '../../../notification-channels/api/queries/GetClientNotificationChannelIntegrationsQuery';
import { NotificationPanelUiService } from '../../../notifications/notification-panel-ui.service';
import { MsTeamsIntegrationUiService } from '../../../client-admin/integration/ms-teams/ms-teams-integration-ui.service';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * Button inside a notification to activate client Ms Teams Intégration
 */
@Component({
    selector: 'app-ms-teams-activate-integration-button',
    templateUrl: './ms-teams-activate-integration-button.component.html',
    styleUrls: [
        './ms-teams-activate-integration-button.component.scss',
        '../nav-link.scss',
    ],
    standalone: true,
    imports: [NgIf, TranslateModule, MatLegacyButtonModule, AsyncPipe],
})
export class MsTeamsActivateIntegrationButtonComponent extends BaseCellComponent<
    unknown,
    unknown,
    unknown
> {
    protected integration$: Observable<ClientNotificationChannelIntegrationDto>;

    constructor(
        userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private notificationPanelUiService: NotificationPanelUiService,
        private msTeamsIntegrationUiService: MsTeamsIntegrationUiService
    ) {
        super();
        userNotificationChannelsStateService.initClientIntegration();
        this.integration$ =
            userNotificationChannelsStateService.selectClientIntegration(
                msTeamsNotificationChannel.name
            );
    }

    protected connect() {
        this.notificationPanelUiService.closePanel();
        this.msTeamsIntegrationUiService.openActivateTeamsIntegrationModal();
    }
}
