import { deserialize, deserializeAs, serialize } from 'cerialize';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { FilterOperator } from './filter-operator';

export class Filter {
    @deserialize public AttributeKey: string;
    @serialize @deserialize public AttributePath: string;
    @serialize @deserialize public FilterOperator: string;
    @serialize @deserialize public SearchValues: string[];
    @deserializeAs(HierarchicalData)
    public SearchHddValues?: HierarchicalData[];
    @serialize public isQuickFilter?: boolean;
    @serialize public isFromQuickFilter?: boolean;

    public static equals(a: Filter, b: Filter, sameOrder = true) {
        return (
            a === b ||
            (!!(a && b) &&
                a.AttributeKey === b.AttributeKey &&
                a.FilterOperator === b.FilterOperator &&
                CollectionsHelper.containSameStrings(
                    a.SearchValues,
                    b.SearchValues,
                    sameOrder,
                    false,
                    true,
                    true
                ))
        );
    }

    //TODO : Send Only Attribute Path
    constructor(
        attributePath: string,
        filterOperator: FilterOperator,
        searchValues: string[],
        isFromQuickFilter?: boolean,
        searchHddValues?: HierarchicalData[]
    ) {
        this.AttributeKey = attributePath;
        this.AttributePath = attributePath;
        this.FilterOperator = FilterOperator[filterOperator];
        this.SearchValues = searchValues;
        this.isFromQuickFilter = isFromQuickFilter;
        this.SearchHddValues = searchHddValues;
    }

    public equals(filter: Filter, sameOrder = true) {
        return Filter.equals(this, filter, sameOrder);
    }
}

export interface IHasFilters {
    readonly filters: Filter[];
}
