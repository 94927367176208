import { Inject, Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { IApiConfig, BACKEND_API_CONFIG } from '../types';
import { from, lastValueFrom } from 'rxjs';

/**
 * ## Role
 * Add Authorization header to the request to specify the JWT
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(@Inject(BACKEND_API_CONFIG) private config: IApiConfig) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return from(this.tokenHandler(req, next));
    }

    async tokenHandler(req: HttpRequest<any>, next: HttpHandler) {
        const jwt = await this.config?.getJWT?.();
        if (!jwt) {
            return lastValueFrom(next.handle(req));
        }
        const reqClone: HttpRequest<any> = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${jwt}`),
        });
        return lastValueFrom(next.handle(reqClone));
    }
}
