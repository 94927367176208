import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

export enum TimeSeriesFrequency {
    Undefined = 0,
    Daily,
    Weekly,
    Monthly,
}
SerializableEnumeration(TimeSeriesFrequency);

export enum TimeSeriesColorRule {
    None = 0,
    ShouldIncrease,
    ShouldDecrease,
}
SerializableEnumeration(TimeSeriesColorRule);

export enum UpdateTimeSeriesAttributeEntryAction {
    Unknown,
    DeleteEntry,
    AddEntry,
    UpdateEntry,
}
SerializableEnumeration(UpdateTimeSeriesAttributeEntryAction);

export class TimeSeriesEntry {
    @deserialize public Date!: Date;
    @deserialize public Value!: number;
}

export class TimeSeriesObject {
    @deserialize public Entries!: TimeSeriesEntry[];
    @deserialize public LastEntry!: TimeSeriesEntry;
    @deserialize public Trend!: number;
}

@inheritSerialization(BaseDTO)
export class UpdateTimeSeriesEntryDto {
    @deserialize public Date!: Date;
    @deserialize public Value!: number;
    @deserialize public OldDate?: Date;
    @deserialize public OldValue?: number;
    @deserialize public Action!: UpdateTimeSeriesAttributeEntryAction;
}
