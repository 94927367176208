export interface IConnectorSchedulerModalData {
    connection: {
        id: string;
        hasScheduling: boolean;
    };
    pluginName: string;
}

export enum ConnectorSchedulerFrequencyType {
    Unknown = 0,
    Daily,
    Weekly,
    Monthly,
}
