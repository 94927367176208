<dxy-burger-menu-button
    [options]="menuOptions"
    [buttonColor]="translationKo ? 'danger-secondary' : 'ai-secondary'"
    glyphClass="glyph-language-ai"
    [matTooltip]="
        (translationKo
            ? 'Multilingual.AttributeTranslationButton.koTooltip'
            : 'Multilingual.AttributeTranslationButton.tooltip'
        ) | translate
    "
>
    <header menu-header [class.ko]="translationKo">
        <h3
            [translate]="
                translationKo
                    ? 'Multilingual.AttributeTranslationButton.koTitle'
                    : 'Multilingual.AttributeTranslationButton.title'
            "
            [translateParams]="{
                sourceLanguageCode: sourceLanguageCode.toUpperCase(),
            }"
        ></h3>
        <ng-container *ngIf="!translationKo">
            <dxy-spinner
                *ngIf="loading$ | async; else source"
                class="xs no-padding"
            ></dxy-spinner>
            <ng-template #source>
                <p>{{ translationSource }}</p>
            </ng-template>
        </ng-container>
    </header>
</dxy-burger-menu-button>
