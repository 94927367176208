import { Component, Input } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgClass } from '@angular/common';

/**
 * ## Role
 * Circle with colorized stroke sized proportionally to percentage
 */
@Component({
    selector: 'dxy-percentage-donut',
    templateUrl: './percentage-donut.component.html',
    styleUrls: ['./percentage-donut.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class DxyPercentageDonutComponent extends DxyBaseComponent {
    @Input() percentage: number;
    /** if true show only percentage number without '%' */
    @Input() noPercentCharacter: boolean;

    public get strokeDasharray() {
        return `${this.percentage} ${100 - this.percentage}`;
    }

    constructor() {
        super();
    }

    public getColorClass(): string {
        const value =
            this.percentage == null ? null : Math.round(this.percentage);
        if (!value) {
            return;
        }
        if (value <= 33) {
            return 'danger';
        } else if (value <= 66) {
            return 'warning';
        }
        return 'safe';
    }

    public getTextPercentage() {
        if (this.percentage == null) {
            return '-';
        }
        return `${Math.round(this.percentage)} ${
            this.noPercentCharacter ? '' : '%'
        }`;
    }
}
