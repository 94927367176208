import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ImportContext } from '../../../shared/ImportContext';
import { ImportDataStatisticsItem } from '@datagalaxy/webclient/data-port/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { EntityType } from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../../../../shared/attribute/attribute-data.service';
import { DxyCsvPreImportCheckComponent } from '../dxy-csv-preimport-check/dxy-csv-preimport-check.component';
import { GridComponent } from '@datagalaxy/ui/grid';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-csv-post-import',
    templateUrl: './dxy-csv-post-import.component.html',
    styleUrls: ['dxy-csv-post-import.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        GridComponent,
        DxyCsvPreImportCheckComponent,
    ],
})
export class DxyCsvPostImportComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() importContext: ImportContext;
    @Input() isImportLoading: boolean;

    protected gridConfig: GridConfig<ImportDataStatisticsItem> = {
        getItemId: (item) => item.Name,
    };
    protected cols: TColDef<ImportDataStatisticsItem>[] = [
        {
            id: 'Name',
            headerLabel: this.translate.instant(
                this.getTranslationKey('ColumnTypeLbl')
            ),
            minWidth: 100,
            width: 200,
            type: GridCellType.text,
            getValue: (item) => this.getColumnTypeName(item.Name),
            sortable: true,
        },
        {
            id: 'AddedCount',
            headerLabel: this.translate.instant(
                this.getTranslationKey('ColumnCreatedLbl')
            ),
            type: GridCellType.text,
            minWidth: 100,
            sortable: true,
        },
        {
            id: 'UpdatedCount',
            headerLabel: this.translate.instant(
                this.getTranslationKey('ColumnModifiedLbl')
            ),
            type: GridCellType.text,
            minWidth: 100,
            sortable: true,
        },
        {
            id: 'UnchangedCount',
            headerLabel: this.translate.instant(
                this.getTranslationKey('ColumnNonModifiedLbl')
            ),
            type: GridCellType.text,
            minWidth: 100,
            sortable: true,
        },
    ];
    protected resultItems: ImportDataStatisticsItem[];

    protected get isImportSuccess() {
        return this.importContext.lastImportResult.IsSuccess;
    }

    constructor(
        private translate: TranslateService,
        private attributeDataService: AttributeDataService
    ) {
        super();
    }

    ngOnChanges(onChangesObj: SimpleChanges) {
        super.onChange(onChangesObj, 'isImportLoading', () =>
            this.onUpdateImportResult()
        );
    }

    ngOnInit() {
        this.onUpdateImportResult();
    }

    private getTranslationKey(translationKeySuffix: string) {
        return `Import.GenericImportWizard.CsvPostImport.${translationKeySuffix}`;
    }

    private onUpdateImportResult() {
        if (this.importContext.lastImportResult.isPreImportResult) {
            return;
        }
        this.resultItems = this.importContext.lastImportResult.Stats.Details;
    }

    private getColumnTypeName(entityTypeString: string) {
        return (
            this.attributeDataService.getEntityTypeTranslation(
                EntityType[entityTypeString]
            ) ??
            this.translate.instant(
                `DgServerTypes.ServerTypeName.${entityTypeString}`
            )
        );
    }
}
