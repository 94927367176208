<div
    dxyFileDrop
    (droppedFilesChange)="handleFiles($event)"
    (click)="openFileExplorer()"
>
    <i *ngIf="glyphVisible" [ngClass]="glyphClass"></i>
    <i *ngIf="successIconVisible" class="glyph-check"></i>
    <i *ngIf="errorIconVisible" class="glyph-cancelsearch"></i>
    <dxy-spinner *ngIf="loading" class="xl no-margin"></dxy-spinner>
    <input
        #fileInputRef
        type="file"
        id="fileInput"
        [name]="name"
        [accept]="acceptedMimeTypes"
        (change)="onFilesSelected($event)"
    />
</div>

<p>
    {{ label }} <br />
    <button class="dxy-link" (click)="openFileExplorer()">
        {{ buttonText }}
    </button>
</p>
