import { DateFilterOperator, IDateFilterData } from './date-filter.types';

export class DateFilterUtils {
    public static isValueLessOperator(operator: DateFilterOperator) {
        return [
            DateFilterOperator.DateTimePastHour,
            DateFilterOperator.DateTimeToday,
            DateFilterOperator.DateTimeBeforeToday,
            DateFilterOperator.DateTimeYesterday,
            DateFilterOperator.DateTimeCurrentWeek,
            DateFilterOperator.DateTimePastWeek,
            DateFilterOperator.DateTimeBeforeCurrentWeek,
            DateFilterOperator.DateTimeBeforePastWeek,
            DateFilterOperator.DateTimeCurrentMonth,
            DateFilterOperator.DateTimePastMonth,
            DateFilterOperator.DateTimeBeforeCurrentMonth,
            DateFilterOperator.DateTimeBeforePastMonth,
            DateFilterOperator.DateTimeCurrentYear,
            DateFilterOperator.FieldIsEmpty,
            DateFilterOperator.FieldHasValue,
        ].includes(operator);
    }

    public static isResolved(filter: IDateFilterData) {
        if (!filter) {
            return false;
        }
        return (
            DateFilterUtils.isValueLessOperator(filter.operator) ||
            !!filter.startDate ||
            !!filter.endDate
        );
    }

    public static getOperatorTranslateKey(operator: DateFilterOperator) {
        if (operator == undefined) {
            return;
        }
        const operatorName = DateFilterOperator[operator];
        return `CoreUI.Filter.Operators.DateTime.${operatorName}`;
    }
}
