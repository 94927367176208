import { OrthogonalRouter } from './orthogonal';
import { IConnectorRouter } from './routing.types';
import { IConnectorSpec } from '../connectors.types';
import { StraightRouter } from './straight/straight.router';

export function getRouterStrategy<D>(
    spec: IConnectorSpec<D>
): IConnectorRouter {
    switch (spec.pathBuilderOptions?.routing) {
        case 'straight': {
            return new StraightRouter();
        }
        case 'orthogonal':
        default: {
            return new OrthogonalRouter(spec.pathBuilderOptions);
        }
    }
}
