import { IConnectorRouter } from '../routing.types';
import { Point, simplifyPath, Vect2 } from '@datagalaxy/core-2d-util';
import { ConnectorEndpoint } from '../../endpoint';
import {
    IRouteEndpointsParams,
    OrthogonalConnector,
    OrthogonalConnectorByproduct,
} from './path-finder';
import { OrthoRouteUtil } from './OrthoRouteUtil';

/**
 * # Role
 * Compute a path of point between two ConnectorEndpoint.
 * It can be a full auto path with a path finding algorithm
 * Or just an orthogonal fixed path if there is fixed points
 */
export class OrthogonalRouter implements IConnectorRouter {
    private static readonly noShapeMargin = true;

    /** for debug - byproducts of routing computation */
    public byproduct?: OrthogonalConnectorByproduct;

    constructor(private options: IRouteEndpointsParams) {}

    public computePoints(
        src: ConnectorEndpoint,
        tgt: ConnectorEndpoint,
        fixedPoints?: Point[]
    ): Point[] {
        const p: IRouteEndpointsParams = { ...this.options };
        /**
         * shapeMargin is correctly working in routeEndpointsWithFixed but not
         * well-supported by the client aka auto side ect
         * Note: set 1 instead of 0 to avoid flickering
         */
        if (p && OrthogonalRouter.noShapeMargin) {
            p.shapeMargin = 1;
        }

        OrthoRouteUtil.computeEndpoints(src, tgt, fixedPoints);

        if (fixedPoints?.length) {
            this.alignFixedPointWithStubs(src, tgt, fixedPoints);
            return this.cleanupPoints([
                src.updatePosition(),
                ...fixedPoints,
                tgt.updatePosition(),
            ]);
        }

        const points = OrthoRouteUtil.routeEndpoints(src, tgt, p);
        if (p.withByproduct) {
            this.byproduct = OrthogonalConnector.byproduct;
        }
        return points;
    }

    private cleanupPoints(points: Point[]) {
        points?.forEach((p) => Vect2.truncateDecimals(p, p));
        return simplifyPath(points);
    }

    /**
     * We always want the first and last point of a fixed route to be aligned with
     * source and target node's stubs. Then we align the adjacent point to the stubs
     * regarding if they are horizontal are vertical
     *
     * On a side note, this is called any time a source/target node is moved or
     * at the init of a connector when building it from the database source.
     * It is needed since we only save a node position update after it moved and
     * not the edge updated route
     */
    private alignFixedPointWithStubs(
        src: ConnectorEndpoint,
        tgt: ConnectorEndpoint,
        fixedPoints: Point[]
    ) {
        const fixed = fixedPoints;
        if (!fixed?.length) {
            return;
        }
        const shapeMargin = this.options.shapeMargin;

        /**
         * A fixed path should always have at least 3 points
         * (2 stubs + 1 fixed point)
         * If there is only 1 or 2 points, we try to add the stubs to fix a
         * corrupted path
         */
        if (fixed.length < 3) {
            fixed.unshift(src.getStubPoint(shapeMargin));
            fixed.push(tgt.getStubPoint(shapeMargin));
        }

        const startStubPoint = src.getStubPoint(shapeMargin);
        if (Vect2.isV(fixed[0], fixed[1]) || Vect2.isH(fixed[1], fixed[2])) {
            fixed[0] = startStubPoint;
            fixed[1].x = startStubPoint.x;
        } else {
            fixed[0] = startStubPoint;
            fixed[1].y = startStubPoint.y;
        }

        const length = fixed.length - 1;
        const endStubPoint = tgt.getStubPoint(shapeMargin);
        if (
            Vect2.isV(fixed[length], fixed[length - 1]) ||
            Vect2.isH(fixed[length - 1], fixed[length - 2])
        ) {
            fixed[length] = endStubPoint;
            fixed[length - 1].x = endStubPoint.x;
        } else {
            fixed[length] = endStubPoint;
            fixed[length - 1].y = endStubPoint.y;
        }
    }
}
