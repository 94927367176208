import { deserializeAs, inheritSerialization } from 'cerialize';
import { BaseServiceResult, JsonTypeData } from '@datagalaxy/data-access';
import { EntityTypeMapping } from '@datagalaxy/dg-object-model';
import { SecurityRole } from '@datagalaxy/webclient/security/data-access';
import {
    DataTypeSettings,
    MasterDataSerializer,
} from '@datagalaxy/webclient/modeler/data-access';
import { UserGroup } from './system-data';

@inheritSerialization(BaseServiceResult)
export class LoadSystemDataResult extends BaseServiceResult {
    @deserializeAs(EntityTypeMapping)
    public EntityTypeMappings: EntityTypeMapping[] = [];
    @deserializeAs(UserGroup) public SystemUserGroups: UserGroup[] = [];
    @deserializeAs(SecurityRole) public SystemRoles: SecurityRole[] = [];
    @deserializeAs(JsonTypeData) public JsonTypes: JsonTypeData[] = [];
    @deserializeAs(
        MasterDataSerializer.forLegacyDataTypeSettingsFormat(
            'SystemDataTypeSettings'
        )
    )
    public SystemDataTypeSettings!: DataTypeSettings;
}
