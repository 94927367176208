import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DxyBaseFieldComponent } from './base-field.component';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { INgModelOptions } from './field.types';
import { NgControl } from '@angular/forms';
import { LoggerService } from '@datagalaxy/shared/logger';

/*
    [ngModelOptions.updateOn](https://stackoverflow.com/questions/53935844/passing-ngmodeloptions-through-from-a-custom-component-to-a-contained-native-e)
*/
/** Base class for a dxy-field supporting focus/blur. */
@Directive()
export abstract class DxyBaseFocusableFieldComponent<T>
    extends DxyBaseFieldComponent<T>
    implements OnInit, OnChanges, AfterViewInit
{
    /** When true, the field takes focus when it is initialized */
    @Input() takeFocus = false;
    /** When true, inner control's updateOn is triggered only when the field looses focus.
     * Note: This overwrites ngModelOptions.updateOn if provided. */
    @Input() updateOnBlur = false;

    /** Triggered when the field takes focus */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() focus = new EventEmitter<FocusEvent>();
    /** Triggered when the field loses focus */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() blur = new EventEmitter<FocusEvent>();

    private loggerService = Inject(LoggerService);

    /** The inner MatInput, MatSelect, or custom MatFormFieldControl */
    protected abstract override fieldControl?: MatFormFieldControl<any> & {
        focus?(): void;
        blur?(): void;
    };

    /** Reflects the *ngModelOptions.updatedOn* set on this field.
     * To be set on the inner control(s).
     * Allows *ngModelChange* to be triggered on *change* (default), *blur*, or *submit*. */
    public readonly modelOptionsForUpdateOn: INgModelOptions;

    public get focused() {
        return this.isFocused;
    }

    protected override get dispatchNativeChangeEvent() {
        return this.isFocused;
    }

    private isFocused = false;
    private isClickingInside = false;

    protected constructor(
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        ngControl?: NgControl
    ) {
        super(elementRef, ngZone, ngControl);

        const updateOnBlur = this.updateOnBlur;
        this.modelOptionsForUpdateOn = {
            get updateOn() {
                return updateOnBlur ? 'blur' : ngControl?.control?.updateOn;
            },
        };
    }

    override ngOnInit() {
        super.ngOnInit();
        this.addListenerOutside('pointerdown', (e: PointerEvent) =>
            this.pointerdown(e)
        );
        this.addListenerOutside('pointerup', (e: PointerEvent) =>
            this.pointerup(e)
        );
        this.addListenerOutside('focusin', (e: FocusEvent) => this.focusin(e));
        this.addListenerOutside('focusout', (e: FocusEvent) =>
            this.focusout(e)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['readonly', 'disabled'], () =>
            this.takeFocusIfNeeded()
        );
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit();
        this.takeFocusIfNeeded();
    }

    //#region API

    /** called when the inner MatFormFieldControl must be focused programmatically.
     * Note: The fieldControl must implement the focus() method, otherwise this doFocus method must be overriden.
     * Info: MatInput and MatSelect implement the focus() method. */
    public doFocus() {
        if (!this.fieldControl) {
            return;
        }
        this.log('doFocus');
        if (this.fieldControl.focus) {
            this.fieldControl.focus();
        } else {
            this.loggerService.warn('focus() not implemented');
        }
    }

    /** Called when the inner MatFormFieldControl must be blurred programmatically.
     * Note: If the fieldControl does not implement a blur() method, this doBlur method should be overriden.
     * Info: MatInput and MatSelect don't implement a blur() method. */
    public doBlur() {
        this.log('doBlur');
        if (this.fieldControl?.blur) {
            this.fieldControl.blur();
        } else {
            this.loggerService.warn('blur() not implemented');
            document.dispatchEvent(new Event('click'));
        }
    }

    //#endregion

    /** Called on focusout. Returning true will prevent the blur event to be emitted
     * @param _event Triggered by the fieldControl
     * @param _isClickingInside True when clicking into the field */
    protected preventBlur(_event: FocusEvent, _isClickingInside: boolean) {
        return false;
    }

    //#region helpers

    /** returns true if the given event's relatedTarget is a mat-option */
    protected isRelatedTargetMatOption(event: FocusEvent) {
        return (event.relatedTarget as Element)?.classList?.contains(
            'mat-option'
        );
    }

    //#endregion - helpers

    /** Triggered by the MatFormFieldControl */
    private focusin(event: FocusEvent) {
        if (this.isFocused || this.readonly) {
            return;
        }

        this.log('focusin-focus.emit', event);
        this.isFocused = true;
        this.focus.emit(event);
    }

    /** Triggered by the MatFormFieldControl */
    private focusout(event: FocusEvent) {
        if (!this.isFocused || this.preventBlur(event, this.isClickingInside)) {
            return;
        }

        this.log('focusout-blur', event);
        this.isFocused = false;
        this.propagateTouched?.();
        this.blur.emit(event);
    }

    private pointerdown(event: PointerEvent) {
        this.log('pointerdown', event);
        this.isClickingInside = true;
    }

    private pointerup(event: PointerEvent) {
        this.log('pointerup', event);
        this.isClickingInside = false;
    }

    private takeFocusIfNeeded() {
        if (this.takeFocus && !this.readonly && !this.disabled) {
            setTimeout(() => this.doFocus(), 333);
        }
    }
}
