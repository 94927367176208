<button *ngIf="team" class="nav-link-button" (click)="openTeamPreview()">
    <div class="icon-wrapper">
        <img *ngIf="imgUrl" [src]="imgUrl" class="team-img" alt="teamIcon" />
        <span
            *ngIf="glyphClass"
            [ngClass]="glyphClass"
            class="team-glyph"
        ></span>
    </div>
    <div class="text-wrapper" dxyEllipsisTooltip>{{ name }}</div>
</button>
