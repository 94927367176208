import { deserialize, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';

@inheritSerialization(BaseServiceParameter)
export class UpdateClientSpaceParameter extends BaseServiceParameter {
    @serialize public ClientFriendlyName: string;
    @serialize public ClientDescription: string;
    @serialize public UserSessionTimeoutMinutes: number;
    @serialize public ClientUISettingsJSON: string;

    constructor(
        clientFriendlyName: string,
        clientDescription: string,
        userSessionTimeoutMinutes: number,
        clientUISettingsJSON: string
    ) {
        super();
        this.ClientFriendlyName = clientFriendlyName;
        this.ClientDescription = clientDescription;
        this.UserSessionTimeoutMinutes = userSessionTimeoutMinutes;
        this.ClientUISettingsJSON = clientUISettingsJSON;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateClientSpaceResult extends BaseServiceResult {
    @deserialize public ClientFriendlyName!: string;
    @deserialize public ClientDescription!: string;
    @deserialize public UserSessionTimeoutMinutes!: number;
    @deserialize public ClientUISettingsJSON!: string;
}
