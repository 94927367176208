<div class="modal-footer">
    <!-- optional label "* mandatory" -->
    <div *ngIf="!noMandatory" class="mandatory-field">
        <span translate>UI.EntityCreateModal.mandatoryField</span>
    </div>

    <!-- optional checkbox "create another" -->
    <div *ngIf="showCreateAnother" class="create-another">
        <mat-checkbox
            [(ngModel)]="isCheckedCreateAnother"
            (change)="onChangeCreateAnother.emit()"
        >
            <span translate>UI.EntityCreateModal.lblCreateNew</span>
        </mat-checkbox>
    </div>

    <!-- cancel button -->
    <button
        (click)="onCloseCancel.emit()"
        mat-flat-button
        type="button"
        dxyDataTestId="modal-cancel"
    >
        <span translate>UI.Dialog.btnCancel</span>
    </button>

    <!-- action button -->
    <button
        *ngIf="actionBtnLbl"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="disabled"
        [dxyLogFunctional]="featureCode"
        dxyDataTestId="modal-submit"
        (pointerdown)="actionPointerDown($event)"
    >
        <span
            [attr.data-dtname]="getDtName()"
            [dxyLogFunctional]="featureCodeSecond"
        >
            {{ actionBtnLbl }}
        </span>
        <span *ngIf="actionLoading" class="dg5-spinner"></span>
    </button>
</div>
