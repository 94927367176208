import { CoreUtil, DebugUtil } from '@datagalaxy/core-util';

export abstract class LogService {
    private _debug = false;

    public get debug() {
        return this._debug;
    }

    public set debug(value: boolean) {
        this._debug = value;
    }

    public constructor() {
        if (DebugUtil.registerService(this)?.logging) {
            this._debug = true;
        }

        if (!CoreUtil.isProduction && CoreUtil.isDebuggingBoot()) {
            console.log(this.constructor.name, 'base-ctor');
        }
    }

    protected warn(...args: any[]) {
        if (!CoreUtil.isProduction) {
            console.warn(...args);
        }
    }

    protected get logger() {
        return (...args: any[]) => this.log(...args);
    }

    protected log(...args: any[]) {
        if (this._debug) {
            console.log(this.constructor.name, ...args);
        }
    }
}
