import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    EntityType,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export class EntitySelectionTypeConfigurationItem {
    private _typeName: string;
    private _isUsed: boolean;

    constructor(typeName: string, isUsed: boolean) {
        this._typeName = typeName;
        this._isUsed = isUsed;
    }

    public get isUsed() {
        return this._isUsed;
    }

    public set isUsed(isUsed: boolean) {
        this._isUsed = isUsed;
    }

    public get typeName() {
        return this._typeName;
    }

    public getTranslateKey() {
        return `DgServerTypes.ServerTypeName.${this._typeName}`;
    }
}

export class EntitySelectorTypeConfiguration {
    private _availableTypes: EntitySelectionTypeConfigurationItem[];
    private _isRestrictionEnable: boolean;

    constructor(availableTypeNames: string[], isRestrictionEnable: boolean) {
        this._availableTypes = availableTypeNames.map(
            (typeName) =>
                new EntitySelectionTypeConfigurationItem(typeName, true)
        );
        this._isRestrictionEnable = isRestrictionEnable;
    }

    public getUsedTypes() {
        if (this._isRestrictionEnable) {
            return this._availableTypes.filter(
                (availableType) => availableType.isUsed
            );
        } else {
            return this.getAvailableTypes();
        }
    }

    public getUsedTypeNames() {
        return this.getUsedTypes().map(
            (availableType) => availableType.typeName
        );
    }

    public getAvailableTypes() {
        return this._availableTypes;
    }

    public getAvailableTypeNames() {
        return this.getAvailableTypes().map(
            (availableType) => availableType.typeName
        );
    }

    public isRestrictionAvailable() {
        return this._isRestrictionEnable;
    }
}

/** Inputs of the dxy-entity-selector component */
export interface IDxyEntitySelectorInputs extends IEntitySelectorInputs {}

/** Inputs of the entity-selector and dxy-entity-selector components */
export interface IEntitySelectorInputs extends IEntitySelectorData {
    includeOnlyHasWriteAccess?: boolean;

    /** Can be used for detailed/advanced type configuration (and displaying a type-multi-checkbox popup),
     * as in the impact-analysis-main component */
    availableTypeNames?: string[];
    enableTypeNamesRestriction?: boolean;
}

export interface IEntitySelectorData {
    disabled?: boolean;
    minChars?: number;
    /** Trigger a search at init with empty search term string */
    initialSearch?: boolean;
    spaceIdr?: ISpaceIdentifier;
    includedEntityTypes?: EntityType[];
    excludedEntityTypes?: EntityType[];
    excludedIds?: string[];
    includedIds?: string[];
    includedAttributesFilter?: string[];
    includeSearchPreferences?: boolean;
    useParentsFilter?: boolean;
}

export class EntitySelectorData {
    private readonly excludedIds: string[] = [];
    private readonly includedIds: string[] = [];

    private readonly serverTypes: ServerType[] = [];
    private readonly includedEntityTypes: EntityType[] = [];
    private disabled = false;
    private minChars = 1;
    private spaceIdr = new SpaceIdentifier(null);
    private initialSearch: boolean;

    private _instance: IEntitySelectorData;

    public get instance() {
        return this._instance;
    }
    public get isEnabled() {
        return !this.disabled;
    }

    public get hasExcludedIds() {
        return this.excludedIds.length > 0;
    }
    public get hasIncludedIds() {
        return this.includedIds.length > 0;
    }

    private updateSuspended = false;
    constructor(private replaceInstanceOnChange = false) {
        this.updateInstance();
    }

    //#region high-level methods

    /** sets excludedIds, spaceIdr, serverTypes, entityTypes */
    public setupForParentChange(entityIdr: IEntityIdentifier) {
        this.setExcludedIds([entityIdr.ReferenceId]);
        this.setSpaceIdr(SpaceIdentifier.fromEntity(entityIdr));
        this.setServerTypesAndIncludedEntityTypesFromEntityType(
            entityIdr.entityType
        );
    }

    public setServerTypesAndIncludedEntityTypesFromEntityType(
        entityType: EntityType
    ) {
        const etm = EntityTypeUtil.getMapping(entityType);
        const includedEntityTypes = etm.ParentRules[0].AllowedParentTypes;
        this.setIncludedEntityTypes(includedEntityTypes);
    }

    //#endregion

    /** returns a copy of the internal array, not the array itself  */
    public getExcludedIds() {
        return this.excludedIds.slice();
    }
    /** returns a copy of the internal array, not the array itself  */
    public getIncludedIds() {
        return this.includedIds.slice();
    }

    public addExcludedId(id: string) {
        if (!id) {
            return;
        }
        this.excludedIds.push(id);
        this.updateInstanceIfNeeded();
    }
    public addIncludedId(id: string) {
        if (!id) {
            return;
        }
        this.includedIds.push(id);
        this.updateInstanceIfNeeded();
    }

    public removeExcludedId(id: string) {
        const result =
            CollectionsHelper.removeElement(this.excludedIds, id)?.length > 0;
        this.updateInstanceIfNeeded();
        return result;
    }
    public removeIncludedId(id: string) {
        const result =
            CollectionsHelper.removeElement(this.includedIds, id)?.length > 0;
        this.updateInstanceIfNeeded();
        return result;
    }

    public clearExcludedIds() {
        this.excludedIds.length = 0;
        this.updateInstanceIfNeeded();
    }
    public clearIncludedIds() {
        this.includedIds.length = 0;
        this.updateInstanceIfNeeded();
    }

    public setExcludedIds(ids: string[], onlyNotIncluded = false) {
        this.excludedIds.length = 0;
        if (onlyNotIncluded) {
            ids = CollectionsHelper.difference(ids, this.includedIds);
        }
        ids && this.excludedIds.push(...CollectionsHelper.distinct(ids));
        this.updateInstanceIfNeeded();
    }
    public setIncludedIds(ids: string[]) {
        this.includedIds.length = 0;
        ids && this.includedIds.push(...ids);
        this.updateInstanceIfNeeded();
    }

    public setIncludedEntityTypes(entityTypes: EntityType[]) {
        this.includedEntityTypes.length = 0;
        entityTypes && this.includedEntityTypes.push(...entityTypes);
        this.updateInstanceIfNeeded();
    }

    public setEnabled(enabled: boolean) {
        this._instance.disabled = this.disabled = !enabled;
        this.updateInstanceIfNeeded();
    }
    public setMinChars(minChars: number) {
        this._instance.minChars = this.minChars = minChars;
        this.updateInstanceIfNeeded();
    }
    public setSpaceIdr(spaceIdr: ISpaceIdentifier) {
        this._instance.spaceIdr = this.spaceIdr = SpaceIdentifier.from(
            spaceIdr,
            true
        );
        this.updateInstanceIfNeeded();
    }
    public setInitialSearch(searchOnInit: boolean) {
        this._instance.initialSearch = this.initialSearch = searchOnInit;
        this.updateInstanceIfNeeded();
    }

    public useParentFilter() {
        this._instance.useParentsFilter = true;
        this.updateInstanceIfNeeded();
    }

    /** if replaceInstanceOnChange was given true to the constructor,
     * suspends the replacement of the instance object untill applyUpdateBatch is called */
    public beginUpdateBatch() {
        this.updateSuspended = true;
    }
    /** if replaceInstanceOnChange was given true to the constructor,
     * applies the replacement of the instance object */
    public applyUpdateBatch() {
        if (!this.replaceInstanceOnChange) {
            return;
        }
        this.updateSuspended = false;
        this.updateInstance();
    }

    public updateInstance() {
        this._instance = {
            excludedIds: this.excludedIds,
            includedIds: this.includedIds,
            includedEntityTypes: this.includedEntityTypes,
            disabled: this.disabled,
            minChars: this.minChars,
            spaceIdr: this.spaceIdr,
            initialSearch: this.initialSearch,
        };
    }

    private updateInstanceIfNeeded() {
        if (!this.replaceInstanceOnChange || this.updateSuspended) {
            return;
        }
        this.updateInstance();
    }
}
