import { deserialize } from 'cerialize';
import {
    ConnectorModule,
    ConnectorRunMode,
    ConnectorTranslations,
    ConnectorVersionDto,
    IConnectorPluginSettings,
} from './connector.types';

/** string enum */
export enum PluginType {
    Desktop = 'Desktop',
    Online = 'Embedded',
}

export enum DataStructure {
    Tree = 'TREE',
    Urn = 'URN',
}

export class PluginVersionDescription {
    @deserialize public Title!: { Fr: string; En: string };
    @deserialize public Description!: { Fr: string; En: string };
}

export interface IConnectorPlugin {
    name: string;
    version?: string;
    title: ConnectorTranslations;
    description: ConnectorTranslations;
    modules: string[];
    aliases?: string[];
    settings?: IConnectorPluginSettings;
    runModes: ConnectorRunMode[];
    sourceType: string;
    iconUrl?: string;
    pluginType?: PluginType;
    versions?: ConnectorVersionDto[];
}

export interface IPluginVersion {
    PluginName: string;
    Description: PluginVersionDescription;
    Icon: string;
    Versions: ConnectorVersionDto[];
    /** ModelType enum value as a string */
    SourceType: string;

    iconUrl?: string;
    /** string enum value */
    pluginType?: PluginType;
    runModes: ConnectorRunMode[];
    Module: ConnectorModule;
}

export class PluginVersionDto implements IPluginVersion {
    @deserialize public PluginName!: string;
    @deserialize public Description!: PluginVersionDescription;
    @deserialize public Icon!: string;
    @deserialize public Versions!: ConnectorVersionDto[];
    /** ModelType enum value as a string */
    @deserialize public SourceType!: string;

    public iconUrl?: string;
    /** string enum value */
    public pluginType?: PluginType;
    public Module!: ConnectorModule;
    public runModes!: ConnectorRunMode[];
}

export class PluginModuleDto {
    @deserialize public Module!: string;
    @deserialize public Plugins!: PluginVersionDto[];
}
