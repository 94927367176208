import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { DiagramIdentifier, IDiagramIdentifier } from '../DiagramIdentifier';
import {
    DiagramExtraData,
    getDiagramExtraDataAttributeKey,
    IDiagramContentUpdateOne,
    IDiagramModelData,
    IDiagramNodeVisualData,
    IDiagramVisualData,
    IEntityAndInfo,
} from './diagram.types';
import { IHasVisualData } from '../../shared/util/server-types/diagram.dto';
import {
    IEntityIdentifier,
    IHierarchicalData,
} from '@datagalaxy/dg-object-model';
import { getReferenceId } from '@datagalaxy/webclient/utils';
import { Model } from '@datagalaxy/webclient/modeler/data-access';
import {
    DiagramEdgeDto,
    DiagramNodeDto,
    DiagramNodeKind,
    GetDiagramContentResult,
    PublishingStatus,
} from '@datagalaxy/webclient/diagram/data-access';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { generateGuid } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

type TNode = DiagramNodeDto;
type TEdge = DiagramEdgeDto;

/**
 * ## Role
 * In-memory content of a diagram
 */
export class DiagramData {
    public static getEntitiesHierarchicalData(
        content: GetDiagramContentResult
    ) {
        /** For now only Entities DiagramNodeKind have a hierarchicalData */
        const hds = content?.Nodes?.filter(
            (n) => n.NodeKind === DiagramNodeKind.Entity
        ).map((n) => n.HddData);
        return CollectionsHelper.distinctByProperty(
            hds,
            (hd) => hd.DataReferenceId
        );
    }

    public readonly spaceIdr: ISpaceIdentifier;
    public readonly visualData: IDiagramVisualData;

    public get diagramId() {
        return this.diagramEntity.ReferenceId;
    }

    public set publishingStatus(value: PublishingStatus) {
        this.diagramEntity.setAttributeValue(
            ServerConstants.Diagram.PublishingStatus,
            PublishingStatus[value]
        );
    }

    public get versionId() {
        return this.diagramEntity.VersionId;
    }
    public get spaceId() {
        return this.spaceIdr.spaceId;
    }

    public get hasModel() {
        return (
            DiagramIdentifier.isModelerDiagram(this.diagramIdr) &&
            this.hasModelInternal
        );
    }
    public get hasNoModel() {
        return (
            DiagramIdentifier.isModelerDiagram(this.diagramIdr) &&
            !this.hasModelInternal
        );
    }
    public get isMultiSource() {
        return (
            DiagramIdentifier.isMultiSourceDiagram(this.diagramIdr) &&
            this.modelsData.length > 1
        );
    }
    public get isMonoSource() {
        return (
            DiagramIdentifier.isMonoSourceDatabaseDiagram(this.diagramIdr) &&
            this.modelsData.length == 1
        );
    }
    public get monoSourceModel() {
        return this.isMonoSource ? this.modelsData[0].model : undefined;
    }
    public get monoSourceEntity() {
        return this.isMonoSource ? this.modelsData[0].entity : undefined;
    }
    public get monoSourceHdd() {
        return this.isMonoSource
            ? this.monoSourceEntity.HddData.Data
            : undefined;
    }
    public get modelsHData() {
        return this.modelsData.map((md) => md.entity.HddData);
    }
    public get sourceEntities() {
        return this.modelsData.map((md) => md.entity);
    }
    public get isEmpty() {
        return !this.content.Nodes?.length;
    }

    public get hasExtraData() {
        return this.visualData.extraData?.length > 0;
    }
    public get extraData() {
        return this.visualData.extraData;
    }
    public set extraData(value: DiagramExtraData[]) {
        this.visualData.extraData = value;
    }
    public get extraDataAttributeKeys() {
        return this.extraData?.map(getDiagramExtraDataAttributeKey) ?? null;
    }

    public get loadedEntitiesCount() {
        return this.loadedEntities.size;
    }

    private readonly diagramIdr: IDiagramIdentifier;
    private readonly loadedEntities = new Map<string, IEntityAndInfo>();
    private get hasModelInternal() {
        return this.modelsData.length > 0;
    }
    private get contextId() {
        return this.diagramEntity.ContextId;
    }

    constructor(
        public readonly diagramEntity: EntityItem,
        private readonly content: GetDiagramContentResult,
        private readonly modelsData?: IDiagramModelData[]
    ) {
        if (!diagramEntity) {
            CoreUtil.warn('no diagramEntity');
        }
        if (!content) {
            CoreUtil.warn('no content');
        }
        this.diagramIdr = DiagramIdentifier.fromEntity(diagramEntity);
        this.spaceIdr = SpaceIdentifier.fromEntity(this.diagramIdr, true);
        this.modelsData ??= [];

        const visualDataString = diagramEntity.getAttributeValue<string>(
            ServerConstants.Diagram.VisualData
        );
        try {
            this.visualData = JSON.parse(visualDataString || null) ?? {};
        } catch (e) {
            CoreUtil.warn(e);
            this.visualData = {};
        }
    }

    public initModels(
        modelsData: IDiagramModelData[],
        sourcesHData: IHierarchicalData[]
    ) {
        this.modelsData.length = 0;
        this.modelsData.push(...modelsData);
        this.diagramEntity.setAttributeValue(
            ServerConstants.Diagram.ObjectLinks_DiagramHasSource_HData,
            sourcesHData
        );
    }

    public preparePersistVisualData() {
        // we use an empty string to clear the diagram visual data
        const visualDataString = this.visualData
            ? JSON.stringify(this.visualData)
            : '';
        this.diagramEntity.setAttributeValue(
            ServerConstants.Diagram.VisualData,
            visualDataString
        );
        return visualDataString;
    }

    public generateReferenceId() {
        return getReferenceId(this.contextId, generateGuid());
    }

    //#region update content

    public updateContent(ops: IDiagramContentUpdateOne[]) {
        ops.forEach((op) => this.updateOne(op));
    }
    private updateOne(op: IDiagramContentUpdateOne) {
        const dto = op.dto;
        const items: { ReferenceId: string }[] =
            dto instanceof DiagramNodeDto
                ? this.content.Nodes
                : dto instanceof DiagramEdgeDto
                ? this.content.Edges
                : undefined;
        if (!items) {
            CoreUtil.warn('items?', op);
            return;
        }
        switch (op.type) {
            case 'A':
                items.push(dto);
                break;
            case 'D':
                CollectionsHelper.removeOne(
                    items,
                    (it) => it.ReferenceId == dto.ReferenceId
                );
                break;
        }
    }

    public removeEdge(...edges: DiagramEdgeDto[]) {
        edges.forEach((e) =>
            CollectionsHelper.removeOne(
                this.content.Edges,
                (dto) => dto.ReferenceId == e.ReferenceId
            )
        );
    }
    public removeNode(...nodes: DiagramNodeDto[]) {
        nodes.forEach((n) =>
            CollectionsHelper.removeOne(
                this.content.Nodes,
                (dto) => dto.ReferenceId == n.ReferenceId
            )
        );
    }

    //#endregion

    //#region retrieve items

    public getNodeByLocalId(nodeLocalId: string) {
        return (
            nodeLocalId &&
            this.getNodeByRefId(getReferenceId(this.contextId, nodeLocalId))
        );
    }

    public getNodeByRefId(nodeReferenceId: string) {
        return this.getNode((n) => n.ReferenceId == nodeReferenceId);
    }

    public getNode(filter: (ne: TNode) => boolean) {
        return this.content.Nodes.find(filter);
    }

    public getEdges(filter?: (ee: TEdge) => boolean) {
        return CollectionsHelper.filter(this.content.Edges, filter, true);
    }

    public getNodes(filter?: (ne: TNode) => boolean) {
        return CollectionsHelper.filter(this.content.Nodes, filter, true);
    }

    public getNodesCount(filter?: (ne: TNode) => boolean) {
        return filter
            ? CollectionsHelper.count(this.content.Nodes, filter)
            : this.content.Nodes?.length ?? 0;
    }

    public hasNodes(filter: (ne: TNode) => boolean) {
        return this.content.Nodes.some(filter);
    }

    public hasNodeForEntityId(entityId: string) {
        return (
            !!entityId &&
            this.content.Nodes.some((ne) => ne.EntityReferenceId == entityId)
        );
    }

    //#region entities
    public initLoadedEntities(entities: EntityItem[]) {
        this.loadedEntities.clear();
        this.addLoadedEntities(entities);
    }

    public addLoadedEntities(entities: EntityItem[]) {
        entities?.forEach((ei) => this.setLoadedEntity(ei));
    }

    public setLoadedEntity(entityItem: EntityItem) {
        if (!entityItem?.ReferenceId) {
            return;
        }
        this.loadedEntities.set(entityItem.ReferenceId, {
            entity: entityItem,
            info: {},
        });
    }

    public getLoadedEntity(entityReferenceId: string) {
        return this.loadedEntities.get(entityReferenceId)?.entity;
    }

    public hasLoadedEntity(entityReferenceId: string) {
        return (
            !!entityReferenceId && this.loadedEntities.has(entityReferenceId)
        );
    }
    //#endregion - entities

    //#endregion - retrieve items

    //#region extract info from items

    public getEntityIdrs(): IEntityIdentifier[] {
        return CollectionsHelper.distinctByProperty(
            this.getEntityNodes(),
            (n) => n.EntityReferenceId
        ).map(EntityIdentifier.fromIHasHddData);
    }

    public getEntityNodes() {
        return this.getNodes((n) => n.NodeKind == DiagramNodeKind.Entity);
    }

    public getNodeEntity(ne: TNode) {
        return this.getNodeEntityAndInfo(ne)?.entity;
    }

    public getNodeEntityAndInfo(ne: TNode) {
        return ne && this.loadedEntities.get(ne.EntityReferenceId);
    }

    public getNodeEntityInfo(ne: TNode) {
        return this.getNodeEntityAndInfo(ne)?.info;
    }

    public getEdgeEntities(ee: TEdge) {
        return {
            source:
                ee && this.getNodeEntity(this.getNodeByRefId(ee.SourceNodeId)),
            target:
                ee && this.getNodeEntity(this.getNodeByRefId(ee.TargetNodeId)),
        };
    }

    public isEntityInCurrentDiagramById(entityId: string) {
        return this.hasNodes((n) => n.EntityReferenceId == entityId);
    }

    /** Returns the index of one element among the ones referencing the same entity.
     * Note: undefined is returned if it is the only one referencing that entity. */
    public getEntityInstanceIndex(ne: TNode) {
        const entityId = ne.EntityReferenceId;
        const elementId = ne.ReferenceId;
        const items = this.getNodes((n) => n.EntityReferenceId == entityId);
        const index =
            items.length < 2
                ? undefined
                : items.findIndex((it) => it.ReferenceId == elementId);
        return index == -1 ? undefined : index;
    }

    public getAliasNameSuffix(ne: TNode) {
        const index = this.getEntityInstanceIndex(ne);
        return index == undefined ? '' : ` : ${1 + index}`;
    }

    public getEntityIdentifiersFromEdge(ee: TEdge) {
        const sourceNode = this.getNodeByRefId(ee.SourceNodeId);
        const targetNode = this.getNodeByRefId(ee.TargetNodeId);
        if (!sourceNode || !targetNode) {
            CoreUtil.warn('not loaded ?', { sourceNode, targetNode });
        }
        return {
            sourceIdr: EntityIdentifier.fromIHasHddData(sourceNode),
            targetIdr: EntityIdentifier.fromIHasHddData(targetNode),
        };
    }

    public getItemVisualData(item: IHasVisualData): IDiagramNodeVisualData {
        if (!item?.VisualData) {
            return;
        }
        try {
            return JSON.parse(item.VisualData);
        } catch (e) {
            CoreUtil.warn(e, item?.VisualData);
        }
    }

    //#endregion - extract info from item

    //#region model

    public getModels() {
        return this.modelsData.map((md) => md.model);
    }

    public getModelById(modelId: string) {
        if (!modelId) {
            return;
        }
        return this.getFirstFromModelData((md) =>
            md.model.ReferenceId == modelId ? md.model : undefined
        );
    }

    public getFirstFromModel<T>(getValue: (m: Model) => T) {
        return this.getFirstFromModelData((md) => getValue(md.model));
    }

    public getModelEntityByModelId(modelId: string) {
        if (!modelId) {
            return;
        }
        return this.getFirstFromModelData((md) =>
            md.entity.ReferenceId == modelId ? md.entity : undefined
        );
    }

    private getFirstFromModelData<T>(getValue: (md: IDiagramModelData) => T) {
        if (!this.hasModelInternal) {
            return;
        }
        for (const md of this.modelsData) {
            const res = getValue(md);
            if (res != undefined) {
                return res;
            }
        }
    }

    //#endregion - model
}
