import {
    BaseFieldSelectAdapter,
    ICellRenderData,
    IFieldSelectAdapterWithId,
} from '@datagalaxy/core-ui';
import { UserCellComponent } from '../user-cell';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

export class UserFieldSelectAdapter
    extends BaseFieldSelectAdapter<UserPublicData>
    implements IFieldSelectAdapterWithId<UserPublicData, string>
{
    constructor(onSelectionChange?: (u: UserPublicData) => void) {
        super(onSelectionChange);
    }

    private static getUserOrPersonRenderData(userId: string): ICellRenderData {
        return {
            renderer: UserCellComponent,
            param: { value: { userId } },
        };
    }

    public init(options: UserPublicData[], selectedUserId?: string) {
        super.initInternal(options);
        if (selectedUserId) {
            this.selectById(selectedUserId);
        }
    }

    public getText(u: UserPublicData) {
        return u?.FullName;
    }
    public getId(u: UserPublicData) {
        return u?.UserId;
    }
    public selectById(userId: string) {
        userId && super.selectByIdInternal(userId);
    }
    public getRenderData(o: UserPublicData) {
        return o && UserFieldSelectAdapter.getUserOrPersonRenderData(o.UserId);
    }
}
