<dxy-collapsible
    class="reflex-shadow big-header body-padding"
    titleKey="UI.EntityDockingPane.Insights.Activity.title"
>
    <div class="activity-container">
        <div *ngIf="isCreationUserIdAvailable" class="activity-line">
            <div class="activity-label" translate>
                UI.EntityDockingPane.Insights.Activity.creation
            </div>
            <div class="activity-user">
                <dxy-mini-avatars
                    class="mini-avatars"
                    [usersIds]="[creationUserId]"
                ></dxy-mini-avatars>
                <span *ngIf="isCreationTimeAvailable" class="activity-date">{{
                    creationTime
                }}</span>
            </div>
        </div>
        <div *ngIf="isLastModificationUserAvailable" class="activity-line">
            <div class="activity-label" translate>
                UI.EntityDockingPane.Insights.Activity.lastUpdate
            </div>
            <div class="activity-user">
                <dxy-mini-avatars
                    class="mini-avatars"
                    [usersIds]="[lastModificationUserId]"
                ></dxy-mini-avatars>
                <span
                    *ngIf="isLastModificationTimeAvailable"
                    class="activity-date"
                    >{{ lastModificationTime }}</span
                >
            </div>
        </div>
    </div>
</dxy-collapsible>
