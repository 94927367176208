import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { RestApiService } from '@datagalaxy/data-access';
import { ActivateMsTeamsIntegrationCommand } from './commands/ActivateMsTeamsIntegrationCommand';

@Injectable({ providedIn: 'root' })
export class MsTeamsApiService extends BaseService {
    constructor(private restApiService: RestApiService) {
        super();
    }

    public async activateTeamsIntegration(tenantId: string) {
        const parameter = new ActivateMsTeamsIntegrationCommand();
        parameter.TenantId = tenantId;
        await this.restApiService.postPromise(
            'notifications/integrations/msteams',
            parameter
        );
    }

    public async deactivateTeamsIntegration() {
        await this.restApiService.deletePromise(
            'notifications/integrations/msteams'
        );
    }

    public async activateNotifications() {
        await this.restApiService.postPromise('notifications/channels/msteams');
    }

    public async deactivateNotifications() {
        await this.restApiService.deletePromise(
            'notifications/channels/msteams'
        );
    }

    public async askIntegrationActivation() {
        await this.restApiService.postPromise(
            'notifications/integrations/ask/msteams'
        );
    }
}
