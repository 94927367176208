import { inheritSerialization } from 'cerialize';
import { ServerType } from '@datagalaxy/dg-object-model';
import { Column } from './column';
import { Ref } from '../ref';

@inheritSerialization(Ref)
export class ColumnRef extends Ref<Column> {
    static fromIds(ids: string[]) {
        return ids?.map(ColumnRef.fromId) ?? [];
    }

    static fromId(id: string) {
        return new ColumnRef(id);
    }

    constructor(id?: string) {
        super(ServerType.Column, id);
    }
}
