/** Warning: values are stored in db as json - dot not rename */
export type TCaptionPositionType = 'inside' | 'outside';
/** Warning: values are stored in db as json - dot not rename */
export type TCaptionAlignH = 'left' | 'center' | 'right';
/** Warning: values are stored in db as json - dot not rename */
export type TCaptionAlignV = 'top' | 'middle' | 'bottom';

/** Positioning of a caption: top/middle/bottom, left/center/right, inside or outside its container */
export interface ICaptionPosition {
    /** *inside* or *outside* - Warning: name stored in db as json - dot not rename */
    type?: TCaptionPositionType;
    /** horizontal alignment - Warning: name stored in db as json - dot not rename */
    alignH?: TCaptionAlignH;
    /** vertical alignment - Warning: name stored in db as json - dot not rename */
    alignV?: TCaptionAlignV;
}

export interface ICaptionPositionAvailableValues {
    types: TCaptionPositionType[];
    alignHs: TCaptionAlignH[];
    alignVs: TCaptionAlignV[];
}

export const CaptionPositionTypes: TCaptionPositionType[] = [
    'inside',
    'outside',
];
export const CaptionPositionAlignHs: TCaptionAlignH[] = [
    'left',
    'center',
    'right',
];
export const CaptionPositionAlignVs: TCaptionAlignV[] = [
    'top',
    'middle',
    'bottom',
];

export type TCaptionPositionProperty =
    | TCaptionPositionType
    | TCaptionAlignH
    | TCaptionAlignV;
export type TCaptionPositionKey = keyof ICaptionPosition;
export const CaptionPositionKeys: TCaptionPositionKey[] = [
    'type',
    'alignH',
    'alignV',
];

export const CaptionPositionValuesByKey: {
    [K in TCaptionPositionKey]: TCaptionPositionProperty[];
} = {
    type: CaptionPositionTypes,
    alignH: CaptionPositionAlignHs,
    alignV: CaptionPositionAlignVs,
};

export const CaptionPositionInsideLeftTop: Readonly<ICaptionPosition> = {
    type: 'inside',
    alignH: 'left',
    alignV: 'top',
};
