import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MultilingualStateService } from '../../multilingual-state.service';
import { MultilingualUnavailabilityComponent } from '../multilingual-unavailability/multilingual-unavailability.component';
import { MultilingualActivatorComponent } from '../multilingual-activator/multilingual-activator.component';
import { FeatureFlagDirective } from '@datagalaxy/webclient/feature-flag';
import { TranslateModule } from '@ngx-translate/core';
import { MultilingualConfiguratorComponent } from '../multilingual-configurator/multilingual-configurator.component';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { AdminHeaderComponent } from '@datagalaxy/webclient/admin/ui';

@Component({
    selector: 'dxy-multilingual-administration',
    templateUrl: './multilingual-administration.component.html',
    styleUrls: ['./multilingual-administration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AdminHeaderComponent,
        NgIf,
        SpinnerComponent,
        MultilingualConfiguratorComponent,
        TranslateModule,
        FeatureFlagDirective,
        MultilingualActivatorComponent,
        MultilingualUnavailabilityComponent,
        AsyncPipe,
    ],
})
export class MultilingualAdministrationComponent {
    protected multilingualEnabled$ =
        this.multilingualStateService.selectEnabled();

    protected loading$ = this.multilingualStateService.selectLoading();

    constructor(private multilingualStateService: MultilingualStateService) {}
}
