import { Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { DataIdentifier } from '@datagalaxy/dg-object-model';
import { RealTimeCommService } from '../../services/realTimeComm.service';
import { NotificationMessage } from '../../shared/util/server-types/notification.api';
import { NavSpace, Space } from '@datagalaxy/webclient/workspace/data-access';

/**
 * #Archi-services-others: AppConfigService, RealTimeCommService
 */
@Injectable({ providedIn: 'root' })
export class NavigationEventService extends BaseService {
    public get openDeleteModal$() {
        return this.openDeleteModal.asObservable();
    }
    private readonly openDeleteModal = new Subject<void>();

    /** Reserved for services only. For components, use super.registerSubscription(this.appSpaceService.subscribe instead */
    public get _changeCurrentSpace$() {
        return this.changeCurrentSpace.asObservable();
    }
    private readonly changeCurrentSpace = new Subject<Space>();

    /** Reserved for services only. For components, use super.registerSubscription(this.appSpaceService.subscribe instead */
    public get _createSpace$() {
        return this.createSpace.asObservable();
    }
    private readonly createSpace = new Subject<NavSpace>();

    /** Reserved for services only. For components, use super.registerSubscription(this.appSpaceService.subscribe instead */
    public get _userFavoriteSpaceChanged$() {
        return this.userFavoriteSpaceChanged.asObservable();
    }
    private readonly userFavoriteSpaceChanged = new Subject<NavSpace>();

    /** Reserved for services only. For components, use super.registerSubscription(this.appSpaceService.subscribe instead */
    public get _updateSpace$() {
        return this.updateSpace.asObservable();
    }
    private readonly updateSpace = new Subject<NavSpace>();

    /** Reserved for services only. For components, use super.registerSubscription(this.appSpaceService.subscribe instead */
    public get _deleteSpace$() {
        return this.deleteSpace.asObservable();
    }
    private readonly deleteSpace = new Subject<DataIdentifier>();

    /** Reserved for services only. For components, use super.registerSubscription(this.appSpaceService.subscribe instead */
    public get _securityRightsChange$() {
        return this.securityRightsChange.asObservable();
    }
    private readonly securityRightsChange = new Subject<NotificationMessage>();

    constructor(private realTimeCommService: RealTimeCommService) {
        super();
        this.realTimeCommService.subscribeCreateOrganizationEvent(
            (userData, data) => this.notifyCreateSpaceEvent(data)
        );
        this.realTimeCommService.subscribeUpdateOrganizationEvent(
            (userData, data) => this.notifyUpdateSpaceEvent(data)
        );
        this.realTimeCommService.subscribeCreateProjectEvent((userData, data) =>
            this.notifyCreateSpaceEvent(data)
        );
        this.realTimeCommService.subscribeUpdateProjectEvent((userData, data) =>
            this.notifyUpdateSpaceEvent(data)
        );
        this.realTimeCommService.subscribeDeleteSpaceEvent((userData, data) =>
            this.notifyDeleteSpaceEvent(data)
        );
        this.realTimeCommService.subscribeSecurityRightsChangeEvent((data) =>
            this.notifySecurityRightsChange(data)
        );
    }

    public notifyDeleteModalOpen() {
        this.openDeleteModal.next();
    }

    public notifyChangeCurrentSpace(space: Space) {
        this.changeCurrentSpace.next(space);
    }

    public notifyCreateSpaceEvent(navSpace: NavSpace) {
        this.createSpace.next(navSpace);
    }

    public notifyFavoriteSpaceChangedEvent(navSpace: NavSpace) {
        this.userFavoriteSpaceChanged.next(navSpace);
    }

    public notifyUpdateSpaceEvent(navSpace: NavSpace) {
        this.updateSpace.next(navSpace);
    }

    public notifyDeleteSpaceEvent(spaceIdentifier: DataIdentifier) {
        this.deleteSpace.next(spaceIdentifier);
    }

    private notifySecurityRightsChange(notifMsg: NotificationMessage) {
        this.securityRightsChange.next(notifMsg);
    }
}
