import { NgModule } from '@angular/core';
import {
    AbstractSecurityStorage,
    AuthModule,
    DefaultLocalStorageService,
    StsConfigLoader,
} from 'angular-auth-oidc-client';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { stsConfigHttpLoaderFactory } from './sts-config-http-loader-factory';
import { WebclientAuthUiModule } from '@datagalaxy/webclient/auth/ui';
import {
    BACKEND_HTTP_INTERCEPTORS,
    REST_HTTP_INTERCEPTORS,
} from '@datagalaxy/data-access';
import { InactivityTimerInterceptor } from './inactivity/inactivity-timer.interceptor';

@NgModule({
    imports: [
        HttpClientModule,
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: stsConfigHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        WebclientAuthUiModule,
    ],
    providers: [
        {
            provide: AbstractSecurityStorage,
            useClass: DefaultLocalStorageService,
        },
        {
            provide: BACKEND_HTTP_INTERCEPTORS,
            useClass: InactivityTimerInterceptor,
            multi: true,
        },
        {
            provide: REST_HTTP_INTERCEPTORS,
            useClass: InactivityTimerInterceptor,
            multi: true,
        },
    ],
    exports: [AuthModule],
})
export class AuthenticationModule {}
