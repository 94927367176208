import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
} from '@angular/core';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { CheckboxComponent } from '@datagalaxy/ui/forms';
import { GridComponent } from '../grid/grid.component';
import { TColDef } from '../grid-column/grid-column.types';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { SearchInputComponent } from '@datagalaxy/ui/search';

@Component({
    standalone: true,
    selector: 'dxy-grid-column-selector',
    templateUrl: './grid-column-selector.component.html',
    styleUrls: ['./grid-column-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DxyButtonsModule,
        TranslateModule,
        MatLegacyMenuModule,
        MatLegacyTooltipModule,
        NgForOf,
        CheckboxComponent,
        NgIf,
        NgClass,
        EllipsisTooltipDirective,
        SearchInputComponent,
        AsyncPipe,
    ],
})
export class DxyGridColumnSelectorComponent<T> implements OnChanges {
    @Input() forGrid?: GridComponent<T>;
    @Input() enableSearch = false;

    protected columns$?: Observable<TColDef<T>[]>;
    private searchTerm$ = new BehaviorSubject<string>('');

    protected hasBeforeGlyph(column: TColDef<T>) {
        /**
         * In case not all columns has glyph value, for the one with empty string
         * we considered it as having glyph to make sure the glyph space is taken
         * for all columns.
         */
        return column.glyph?.before || column.glyph?.before === '';
    }

    ngOnChanges() {
        const grid = this.forGrid;

        if (!grid) {
            return;
        }

        this.columns$ = this.searchTerm$.pipe(
            switchMap((searchTearm) =>
                grid.columns$.pipe(
                    map((columns) => this.filterColumns(searchTearm, columns))
                )
            )
        );
    }

    protected isColumnVisible(column: TColDef<T>) {
        return this.forGrid?.isColumnVisible(column.id);
    }

    protected onColumnToggleVisibility(column: TColDef<T>) {
        this.forGrid?.toggleColumnVisibility(column.id);
    }

    protected onResetClick() {
        this.forGrid?.resetColumns();
    }

    protected onSearch(searchTerm: string | undefined) {
        this.searchTerm$.next(searchTerm || '');
    }

    private filterColumns(searchTerm: string, columns: TColDef<T>[]) {
        const cols = columns.slice(1) || [];
        const orderedCols = CollectionsHelper.orderBy(
            cols,
            (col) => col.headerLabel || ''
        );

        const filteredColumns = StringUtil.filterSearched(
            searchTerm,
            orderedCols,
            (col) => col.headerLabel || ''
        );

        return CollectionsHelper.orderBy(filteredColumns, (col) =>
            StringUtil.normalizeForSearch(col.headerLabel || '')
        );
    }
}
