<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 514 290"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
>
    <g transform="translate(-42-151)" [attr.mask]="maskUrl">
        <g transform="translate(.000001 0.000001)">
            <rect
                width="1992.872156"
                height="1992.872155"
                rx="0"
                ry="0"
                transform="translate(-168.086077-564.036084)"
                fill="#3d5cfe"
                stroke-width="0"
            />
        </g>
        <mask
            [id]="maskId"
            mask-type="luminance"
            x="-150%"
            y="-150%"
            height="400%"
            width="400%"
        >
            <g transform="matrix(.301073 0 0 0.301073 50.60618 168.816037)">
                <path
                    d="M1074.8,152.9C1047.6,64.5,965.2,0,867.9,0C762.5,0,674.6,75.7,655.4,175.5c-26.9-16.2-58.3-25.5-91.9-25.5-98.5,0-178.7,80.2-178.7,178.7s80.2,178.7,178.7,178.7h479.4c98.5,0,178.7-80.2,178.7-178.7c0-87.6-63.4-160.6-146.8-175.8v0Z"
                    fill="#3d5cfe"
                />
                <rect
                    width="1992.872156"
                    height="1992.872155"
                    rx="0"
                    ry="0"
                    transform="translate(-168.086077-564.036084)"
                    stroke-width="0"
                />
                <path
                    id="eTzBHqmb14p9"
                    d="M1074.8,152.9C1047.6,64.5,965.2,0,867.9,0C762.5,0,674.6,75.7,655.4,175.5c-26.9-16.2-58.3-25.5-91.9-25.5-98.5,0-178.7,80.2-178.7,178.7s80.2,178.7,178.7,178.7h479.4c98.5,0,178.7-80.2,178.7-178.7c0-87.6-63.4-160.6-146.8-175.8v0ZM458.1,328.7c0-58.1,47.3-105.4,105.3-105.4s105.4,47.3,105.4,105.4-47.3,105.4-105.4,105.4-105.3-47.3-105.3-105.4v0Zm584.8,105.4c-27.4,0-52.4-10.5-71.1-27.7-18.2-16.7-30.5-39.6-33.5-65.3-20.8,11.8-44.8,18.6-70.4,18.6-.3,0-.7,0-1,0-78.5-.5-142.1-64.5-142.1-143.1s64.2-143.2,143.1-143.2c57.3,0,106.8,33.8,129.6,82.5c8.7,18.4,13.5,38.9,13.5,60.6c0,4.1-.2,8-.5,12c10.2-3.3,21.1-5.1,32.4-5.1c14.5,0,28.3,2.9,40.9,8.3c37.8,16,64.4,53.5,64.4,97.1c0,58-47.2,105.3-105.3,105.3v0Z"
                    fill="#fff"
                    stroke="#fff"
                    stroke-width="0"
                    stroke-miterlimit="1"
                />
                <g
                    id="eTzBHqmb14p10_ts"
                    transform="translate(1042.900024,328.700027) scale(1,1)"
                >
                    <circle
                        r="105.4"
                        transform="translate(-0.000024,-0.000027)"
                    />
                </g>
                <g
                    id="eTzBHqmb14p11_ts"
                    transform="translate(563.500015,328.700027) scale(1,1)"
                >
                    <circle
                        r="105.4"
                        transform="translate(-0.000015,-0.000027)"
                    />
                </g>
                <g
                    id="eTzBHqmb14p12_ts"
                    transform="translate(867.900055,216.5) scale(1,1)"
                >
                    <circle r="143.1" transform="translate(-0.000055,0)" />
                </g>
            </g>
        </mask>
    </g>
    <g transform="matrix(1.224487 0 0 1.224487-109.663256-224.883372)">
        <g transform="matrix(.246307 0 0 0.246307 95.971597 198.199325)">
            <path
                d="M-1.4,622h85.6c20.5,0,38.5,3.9,54.2,11.7s27.9,18.8,36.6,33s13.1,30.7,13.1,49.5-4.4,35.4-13.1,49.5c-8.7,14.2-20.9,25.2-36.6,33s-33.8,11.7-54.2,11.7h-85.6v-188.4v0ZM82.1,774.6c18.8,0,33.9-5.2,45.1-15.8c11.2-10.5,16.8-24.7,16.8-42.7s-5.6-32.2-16.8-42.7-26.2-15.8-45.1-15.8h-39.8v116.8h39.8v.2Z"
                fill="#29235c"
            />
            <path
                d="M365,665.6v144.8h-40.1v-16.7c-10.4,12.6-25.5,18.8-45.2,18.8-13.6,0-26-3-37-9.2-11-6.1-19.7-14.8-26-26.1s-9.4-24.4-9.4-39.3s3.1-28,9.4-39.3s14.9-20,26-26.1c11-6.1,23.4-9.2,37-9.2c18.5,0,32.9,5.8,43.3,17.5v-15.3h42v.1ZM313.4,767.2c7-7.3,10.5-17,10.5-29.2s-3.5-21.9-10.5-29.2-15.8-10.9-26.4-10.9-19.6,3.6-26.6,10.9-10.5,17-10.5,29.2s3.5,21.9,10.5,29.2s15.9,10.9,26.6,10.9s19.4-3.6,26.4-10.9Z"
                fill="#29235c"
            />
            <path
                d="M497.2,803.4c-4.1,3-9.2,5.3-15.2,6.9-6,1.5-12.3,2.3-19,2.3-17.2,0-30.6-4.4-40-13.2s-14.1-21.7-14.1-38.8v-59.5h-22.4v-32.3h22.4v-35.3h42v35.3h36.1v32.3h-36.1v59c0,6.1,1.6,10.8,4.7,14.1s7.6,5,13.3,5c6.6,0,12.3-1.8,17-5.4l11.3,29.6v0Z"
                fill="#29235c"
            />
            <path
                d="M663.3,665.6v144.8h-40.1v-16.7c-10.4,12.6-25.5,18.8-45.2,18.8-13.6,0-26-3-37-9.2-11-6.1-19.7-14.8-26-26.1s-9.4-24.4-9.4-39.3s3.1-28,9.4-39.3s14.9-20,26-26.1c11-6.1,23.4-9.2,37-9.2c18.5,0,32.9,5.8,43.3,17.5v-15.3h42v.1ZM611.7,767.2c7-7.3,10.5-17,10.5-29.2s-3.5-21.9-10.5-29.2-15.8-10.9-26.4-10.9-19.7,3.6-26.7,10.9-10.5,17-10.5,29.2s3.5,21.9,10.5,29.2s15.9,10.9,26.7,10.9s19.4-3.6,26.4-10.9Z"
                fill="#29235c"
            />
            <path
                d="M832.4,713.2h39.8v76.5c-10.2,7.7-22.1,13.6-35.5,17.8-13.5,4.1-27,6.2-40.7,6.2-19.6,0-37.2-4.2-52.8-12.5s-27.9-19.9-36.8-34.7-13.3-31.5-13.3-50.2s4.4-35.4,13.3-50.2s21.2-26.4,37-34.7s33.6-12.5,53.3-12.5c16.5,0,31.5,2.8,45,8.3c13.5,5.6,24.8,13.6,33.9,24.2l-28,25.8C834.3,663,818,655.9,799,655.9c-12,0-22.7,2.5-32,7.5s-16.6,12.1-21.8,21.3-7.8,19.7-7.8,31.5s2.6,22.1,7.8,31.2c5.2,9.2,12.4,16.3,21.7,21.4c9.2,5.1,19.8,7.7,31.6,7.7c12.6,0,23.9-2.7,33.9-8.1v-55.2v0Z"
                fill="#29235c"
            />
            <path
                d="M1056.7,665.6v144.8h-40.1v-16.7c-10.4,12.6-25.5,18.8-45.2,18.8-13.6,0-26-3-37-9.2-11-6.1-19.7-14.8-26-26.1s-9.4-24.4-9.4-39.3s3.1-28,9.4-39.3s14.9-20,26-26.1c11-6.1,23.4-9.2,37-9.2c18.5,0,32.9,5.8,43.3,17.5v-15.3h42v.1ZM1005,767.2c7-7.3,10.5-17,10.5-29.2s-3.5-21.9-10.5-29.2-15.8-10.9-26.4-10.9-19.7,3.6-26.7,10.9-10.5,17-10.5,29.2s3.5,21.9,10.5,29.2s15.9,10.9,26.7,10.9s19.4-3.6,26.4-10.9Z"
                fill="#29235c"
            />
            <path d="M1095.4,610.6h42v199.8h-42v-199.8Z" fill="#29235c" />
            <path
                d="M1323.5,665.6v144.8h-40.1v-16.7c-10.4,12.6-25.5,18.8-45.2,18.8-13.6,0-26-3-37-9.2-11-6.1-19.7-14.8-26-26.1s-9.4-24.4-9.4-39.3s3.1-28,9.4-39.3s14.9-20,26-26.1c11-6.1,23.4-9.2,37-9.2c18.5,0,32.9,5.8,43.3,17.5v-15.3h42v.1Zm-51.7,101.6c7-7.3,10.5-17,10.5-29.2s-3.5-21.9-10.5-29.2-15.8-10.9-26.4-10.9-19.7,3.6-26.7,10.9-10.5,17-10.5,29.2s3.5,21.9,10.5,29.2s15.9,10.9,26.7,10.9s19.4-3.6,26.4-10.9Z"
                fill="#29235c"
            />
            <path
                d="M1454.3,810.4L1422.5,766l-32.8,44.4h-45.8l55.7-73-53.8-71.9h47.1l30.7,42.3l31.5-42.3h44.7L1446,736.3l56,74-47.7.1v0Z"
                fill="#29235c"
            />
            <path
                d="M1658.1,665.6l-65.4,153.7c-6.6,16.7-14.8,28.4-24.6,35.3-9.8,6.8-21.6,10.2-35.4,10.2-7.5,0-15-1.2-22.3-3.5-7.4-2.3-13.4-5.6-18-9.7l15.3-29.9c3.2,2.9,6.9,5.1,11.2,6.7c4.2,1.6,8.4,2.4,12.5,2.4c5.7,0,10.4-1.4,14-4.2s6.8-7.4,9.7-13.9l.5-1.3-62.7-145.9h43.3l40.7,98.3l40.9-98.3l40.3.1v0Z"
                fill="#29235c"
            />
        </g>
    </g>
</svg>
