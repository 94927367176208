import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import { CreateEntityCheckParentMode } from '../entity.types';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { CreateEntityOperation } from '@datagalaxy/webclient/entity/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaValue,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';
import { EntityCreationOrigin } from '@datagalaxy/webclient/entity/feature';

export interface IOpenCreationModalOptions {
    parentData?: EntityItem;
    serverType?: ServerType;
    modelHdd?: HierarchyDataDescriptor;
    filterEntityTypes?: EntityType[];
}

export interface IEntityCreateModalInput {
    serverType: ServerType;
    consolidationMode: CreateEntityCheckParentMode;
    currentSpace?: Space;
    actionOrigin: EntityCreationOrigin;
    checkNewCreate?: boolean;
    filterEntityTypes?: EntityType[];
    modelHdd: HierarchyDataDescriptor;
    parentData?: EntityItem;
    defaultName?: string;
}

export interface IEntityCreateModalOutput {
    subTypeName?: string;
    displayName: string;
    description?: string;
    operation: CreateEntityOperation;
    existingEntity: EntityItem;
    createdEntity?: EntityItem;
    referenceId?: string;
}

export class CreationContext {
    spaceServerType: ServerType;
    serverType: ServerType;
    spaceId: string;
    parentId: string;
    versionId: string;
    subTypeName: string;
    code: string;
}
export type TValue =
    | string
    | string[]
    | boolean
    | HierarchyDataDescriptor
    | AttributeObjectValue[]
    | AttributeMetaValue[];
