import { SerializableEnumeration } from 'cerialize';
import { EntityLinkTypeKind } from './EntityLinkTypeKind.enum';

export enum ObjectLinkType {
    Unknown,
    /** Custom link type (client side only) */
    Virtual = 1,

    // DO NOT ADD NEW VALUES OF OBJECT LINK TYPES BEYOND THIS LIMIT
    // ===================================
    // SPECIAL VALUES for specific logic:
    // ===================================
    // Entity -> ClientPersonReference (DataOwners/DataStewards/CDO/etc.)
    UserLink = 500,

    // Entity => ClientTagReference (Domains)
    TagLink = 501,

    //Entity -> Entity
    EntityLink,

    IsParentOfTableColumnCatalogException,
    IsChildOfTableColumnCatalogException,
    IsParentOfModelTableCatalogException,
    IsChildOfModelTableCatalogException,

    // ====================================================
    // Starting from version 2.24, the list contains also the values for the EntityLinkTypeKind values
    // However, no API uses those values for now
    // DO NOT ADD NEW VALUES OF OBJECT LINK TYPES BEYOND THIS LIMIT
    // ====================================================
    IsSynonymOf = 1000 + EntityLinkTypeKind.Synonym,

    Generalizes = 1000 + EntityLinkTypeKind.Generalization,
    Specializes = 2000 + EntityLinkTypeKind.Generalization,

    IsComputedBy = 1000 + EntityLinkTypeKind.Formula,
    IsUsedForComputationOf = 2000 + EntityLinkTypeKind.Formula,

    IsParentOfGeneric = 1000 + EntityLinkTypeKind.IsParentOf,
    IsChildOfGeneric = 2000 + EntityLinkTypeKind.IsParentOf,

    DependsOn = 1000 + EntityLinkTypeKind.Dependency,
    IsNeededBy = 2000 + EntityLinkTypeKind.Dependency,

    IsLinkedTo = 1000 + EntityLinkTypeKind.GenericLink,

    ListsValuesFor = 1000 + EntityLinkTypeKind.RefDataDescription,
    HasForReferenceData = 2000 + EntityLinkTypeKind.RefDataDescription,

    IsDeclinedAccordingToDimension = 1000 +
        EntityLinkTypeKind.DimensionDeclination,
    Declines = 2000 + EntityLinkTypeKind.DimensionDeclination,

    Precedes = 1000 + EntityLinkTypeKind.IsFollowedBy,
    Follows = 2000 + EntityLinkTypeKind.IsFollowedBy,

    HasForUniverse = 1000 + EntityLinkTypeKind.HasUniverse,
    IsUniverseOf = 2000 + EntityLinkTypeKind.HasUniverse,

    HasForDomain = 1000 + EntityLinkTypeKind.HasDomain,
    IsDomainOf = 2000 + EntityLinkTypeKind.HasDomain,

    HasForSource = 1000 + EntityLinkTypeKind.UsageHasSource,
    IsSourceOf = 2000 + EntityLinkTypeKind.UsageHasSource,

    IsPartOfDimension = 1000 + EntityLinkTypeKind.DimensionRegroups,
    Regroups = 2000 + EntityLinkTypeKind.DimensionRegroups,

    HasForRecordingSystem = 1000 +
        EntityLinkTypeKind.PropertyHasRecordingSystem,
    IsRecordingSystemFor = 2000 + EntityLinkTypeKind.PropertyHasRecordingSystem,

    IsUsageSourceFor = 1000 + EntityLinkTypeKind.InputUsage,
    HasForUsageSource = 2000 + EntityLinkTypeKind.InputUsage,

    IsUsageDestinationFor = 1000 + EntityLinkTypeKind.OutputUsage,
    HasForUsageDestination = 2000 + EntityLinkTypeKind.OutputUsage,

    Calls = 1000 + EntityLinkTypeKind.UsageCalls,
    IsCalledBy = 2000 + EntityLinkTypeKind.UsageCalls,

    Transcodes = 1000 + EntityLinkTypeKind.Transcode,

    IsImplementedBy = 1000 + EntityLinkTypeKind.IsImplementedBy,
    Implements = 2000 + EntityLinkTypeKind.IsImplementedBy,

    Uses = 1000 + EntityLinkTypeKind.Uses,
    IsUsedBy = 2000 + EntityLinkTypeKind.Uses,

    HasInput = 1000 + EntityLinkTypeKind.HasInput,
    IsInputOf = 2000 + EntityLinkTypeKind.HasInput,

    HasOutput = 1000 + EntityLinkTypeKind.HasOutput,
    IsOutputOf = 2000 + EntityLinkTypeKind.HasOutput,
}
SerializableEnumeration(ObjectLinkType);
