import { BaseService, UiSpinnerService } from '@datagalaxy/core-ui';
import { DxyVersioningNavigatorModalComponent } from '../dxy-versioning-navigator/dxy-versioning-navigator-modal.component';
import { IVersioningNavigatorModalResolve } from '../versioning-navigator.types';
import {
    IVersioningCreateModalResolve,
    IVersioningCreateModalResult,
} from '../versioning-create-modal.types';
import { DxyVersioningCreateModalComponent } from '../dxy-versioning-create-modal/dxy-versioning-create-modal.component';
import {
    IVersioningUpdateModalResolve,
    IVersioningUpdateModalResult,
} from '../versioning-update-modal.types';
import { DxyVersioningUpdateModalComponent } from '../dxy-versioning-update-modal/dxy-versioning-update-modal.component';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { Injectable } from '@angular/core';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { VersioningService } from './versioning.service';
import { NavigationService } from '../../services/navigation.service';
import { VersioningStatusGraphData } from '../versioning.types';
import { Project } from '@datagalaxy/webclient/workspace/data-access';
import {
    ProjectVersion,
    ProjectVersionStatus,
} from '@datagalaxy/webclient/versioning/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class VersioningUiService extends BaseService {
    constructor(
        private dxyModalService: DxyModalService,
        private versioningService: VersioningService,
        private uiSpinnerService: UiSpinnerService,
        private navigationService: NavigationService
    ) {
        super();
    }

    public async goToVersion(spaceIdr: ISpaceIdentifier) {
        return await this.navigationService.goToSpaceHome(spaceIdr);
    }

    public async changeProjectVersionStatus(
        projectId: string,
        versionId: string,
        newStatus: ProjectVersionStatus
    ) {
        return await this.versioningService.changeProjectVersionStatus(
            projectId,
            versionId,
            newStatus
        );
    }

    public async createProjectVersion(projectId: string, versionId?: string) {
        const modalResult = await this.openCreateVersioningModal(
            projectId,
            true,
            false,
            versionId
        );
        if (!modalResult) {
            return;
        }
        return await this.uiSpinnerService.executeWithSpinner(() =>
            this.versioningService.createProjectVersion(
                projectId,
                modalResult.versionName,
                modalResult.versionDescription,
                modalResult.sourceProjectVersionId
            )
        );
    }

    public async updateProjectVersion(projectVersion: ProjectVersion) {
        const modalResult = await this.openUpdateVersioningModal(
            projectVersion
        );
        if (!modalResult) {
            return;
        }
        return await this.versioningService.updateProjectVersion(
            projectVersion,
            modalResult.versionName,
            modalResult.versionDescription
        );
    }

    public async updateDefaultVersion(
        projectVersion: ProjectVersion,
        isDefault: boolean
    ) {
        return await this.versioningService.updateDefaultVersion(
            projectVersion,
            isDefault
        );
    }

    public async activateVersioning(projectId: string) {
        const modalResult = await this.openCreateVersioningModal(
            projectId,
            false,
            true
        );
        if (!modalResult) {
            return;
        }
        await this.versioningService.activateVersioning(
            projectId,
            modalResult.versionName,
            modalResult.versionDescription
        );
    }

    /** warning: returns undefined if the matching project is not versionned */
    public async getProjectVersionFromSpaceIdr(spaceIdr: ISpaceIdentifier) {
        if (!SpaceIdentifier.isProject(spaceIdr)) {
            return;
        }
        return await this.versioningService.getProjectVersion(
            spaceIdr.spaceId,
            spaceIdr.versionId
        );
    }
    /** warning: returns undefined if the matching project is not versionned */
    public async getProjectVersion(projectId: string, versionId: string) {
        return await this.versioningService.getProjectVersion(
            projectId,
            versionId
        );
    }
    public async getProjectVersions(projectId: string) {
        return await this.versioningService.getProjectVersions(projectId);
    }

    public async compareVersions(
        srcVersionId: string,
        dstVersionId: string,
        currentVersionId: string,
        projectId?: string,
        entityId?: string
    ) {
        return await this.versioningService.compareVersions(
            srcVersionId,
            dstVersionId,
            projectId,
            entityId
        );
    }
    public async entityCompareVersions(
        srcVersionId: string,
        dstVersionId: string,
        currentVersionId: string,
        projectId?: string,
        entityId?: string
    ) {
        return await this.versioningService.entityCompareVersions(
            srcVersionId,
            dstVersionId,
            projectId,
            entityId
        );
    }
    public async entityCompareVersionsDifference(
        srcVersionId: string,
        currentVersionId: string,
        projectId?: string,
        entityId?: string
    ) {
        return await this.versioningService.entityCompareVersionsDifference(
            srcVersionId,
            projectId,
            entityId
        );
    }

    public getVersioningStatusGraphData(projectVersion: ProjectVersion) {
        const versioningStatusGraphData = new VersioningStatusGraphData(
            projectVersion.ProjectReferenceId,
            projectVersion.ProjectVersionId
        );
        versioningStatusGraphData.setCurrentStatus(
            projectVersion.VersionStatus
        );
        versioningStatusGraphData.setStatusChangeList(
            projectVersion.StatusChangeList
        );
        return versioningStatusGraphData;
    }

    public handleEnableVersioningResult(projectVersion: ProjectVersion) {
        this.versioningService.handleEnableVersioningResult(projectVersion);
    }

    public async openCreateVersioningModal(
        projectId: string,
        isProjectVersioningEnabled: boolean,
        isActivateVersion: boolean,
        sourceVersionId: string = null
    ) {
        const projectVersions = isProjectVersioningEnabled
            ? await this.versioningService.getProjectVersions(projectId)
            : [];
        return await this.dxyModalService.open<
            DxyVersioningCreateModalComponent,
            IVersioningCreateModalResolve,
            IVersioningCreateModalResult
        >({
            componentType: DxyVersioningCreateModalComponent,
            data: { projectVersions, sourceVersionId, isActivateVersion },
        });
    }

    public async openUpdateVersioningModal(currentVersion: ProjectVersion) {
        return await this.dxyModalService.open<
            DxyVersioningUpdateModalComponent,
            IVersioningUpdateModalResolve,
            IVersioningUpdateModalResult
        >({
            componentType: DxyVersioningUpdateModalComponent,
            data: { currentVersion },
        });
    }

    public async openVersioningNavigatorModal(projectData: Project) {
        await this.dxyModalService.open<
            DxyVersioningNavigatorModalComponent,
            IVersioningNavigatorModalResolve,
            void
        >({
            componentType: DxyVersioningNavigatorModalComponent,
            size: ModalSize.Large,
            data: { projectData },
        });
    }
}

export interface IVersionModalResult {
    versionName: string;
    versionDescription: string;
    sourceProjectVersionId?: string;
}
