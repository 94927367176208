import { StateName } from '@datagalaxy/webclient/routing';
import { MultilingualAdministrationComponent } from './administration/multilingual-administration/multilingual-administration.component';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { MultilingualLanguagesComponent } from './administration/multilingual-languages/multilingual-languages.component';
import { MultilingualAttributesComponent } from './administration/multilingual-attributes/multilingual-attributes.component';
import { MultilingualLexiconComponent } from './administration/multilingual-lexicon/multilingual-lexicon.component';

export const multilingualUrlWord = {
    multilingual: 'multilingual',
    languages: 'languages',
    attributes: 'attributes',
    lexicon: 'lexicon',
};
export namespace MultilingualStateName {
    export const ClientAdminMultilingual = `${StateName.ClientAdmin}.${multilingualUrlWord.multilingual}`;
    export const Languages = `${MultilingualStateName.ClientAdminMultilingual}.${multilingualUrlWord.languages}`;
    export const Attributes = `${MultilingualStateName.ClientAdminMultilingual}.${multilingualUrlWord.attributes}`;
    export const Lexicon = `${MultilingualStateName.ClientAdminMultilingual}.${multilingualUrlWord.lexicon}`;
}

export const multilingualFeatureRoutes: Ng2StateDeclaration[] = [
    {
        name: MultilingualStateName.ClientAdminMultilingual,
        url: `/${multilingualUrlWord.multilingual}`,
        component: MultilingualAdministrationComponent,
        redirectTo: MultilingualStateName.Languages,
    },
    {
        name: MultilingualStateName.Languages,
        url: `/${multilingualUrlWord.languages}`,
        component: MultilingualLanguagesComponent,
    },
    {
        name: MultilingualStateName.Attributes,
        url: `/${multilingualUrlWord.attributes}`,
        component: MultilingualAttributesComponent,
    },
    {
        name: MultilingualStateName.Lexicon,
        url: `/${multilingualUrlWord.lexicon}`,
        component: MultilingualLexiconComponent,
    },
];
