import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { registerTypedEvent, UserEventBase } from '../events';
import { HierarchyDataDescriptor } from '../core';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@inheritSerialization(UserEventBase)
@registerTypedEvent('GlossaryGenerationEndedEvent')
export class GlossaryGenerationEndedEvent extends UserEventBase {
    @deserialize EntityCreatedCount: number;
    @deserialize LinkCreatedCount: number;
    @deserializeAs(HierarchyDataDescriptor)
    TargetUniverseHdd: HierarchyDataDescriptor;
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('GlossaryGenerationAbortedEvent')
export class GlossaryGenerationAbortedEvent extends UserEventBase {
    @deserialize ErrorMessage: string;
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('LinkSuggestionGenerationFromSourceEntityEndedEvent')
export class LinkSuggestionGenerationEndedEvent extends UserEventBase {
    @deserializeAs(HierarchyDataDescriptor) SourceHdd?: HierarchyDataDescriptor;
    @deserialize LinkSuggestionCreatedCount: number;
    @deserialize VersionId: string;
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('LinkSuggestionGenerationFromSourceModuleEndedEvent')
export class LinkSuggestionGenerationFromModuleEndedEvent extends UserEventBase {
    @deserializeAs(DgModule) SourceModule?: DgModule;
    @deserialize LinkSuggestionCreatedCount: number;
    @deserialize WorkspaceId: string;
    @deserialize VersionId: string;
}

@inheritSerialization(UserEventBase)
@registerTypedEvent('LinkSuggestionGenerationAbortedEvent')
export class LinkSuggestionGenerationAbortedEvent extends UserEventBase {
    @deserialize ErrorMessage: string;
}
