import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { SelectionTool } from '../selection';
import { OrthoEditor } from './ortho-editor';
import { Connector, IEdgeOrthoConnectorOptions } from '../../connector';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { GraphManager } from '../../graph-manager';
import { IEdgeManagerOptions } from '../../edge';
import { IOrthoEditorOptions } from './editor.types';
import { EndpointTool } from '../endpoint';
import { LayerManager } from '../../layer';

/**
 *  ## Role
 *  Attach a connector editor for each selected connector
 */
export class ConnectorEditorTool<
    NodeData = unknown,
    EdgeData = unknown
> extends BaseGraphicalManager {
    private connectors = new Map<Connector<NodeData, EdgeData>, OrthoEditor>();

    public get editors() {
        return Array.from(this.connectors.values());
    }

    constructor(
        private zoomAdapter: ZoomedViewAdapter,
        private layerManager: LayerManager,
        endpointTool: EndpointTool<NodeData, EdgeData>,
        private graph: GraphManager<NodeData, EdgeData>,
        selectionManager: SelectionTool<NodeData, EdgeData>,
        private options?: IEdgeManagerOptions
    ) {
        super();

        super.subscribe(selectionManager.edgeSelection$, (connectors) =>
            this.onSelectionChanged(connectors)
        );
        super.subscribe(endpointTool.connectorPortChanged$, (connectors) =>
            this.onEndpointChange(connectors)
        );
    }

    public draw() {
        this.editors?.forEach((editor) => editor.onConnectorUpdated());
    }

    private onSelectionChanged(connectors: Connector<NodeData, EdgeData>[]) {
        if (this.options?.noEditOnClick) {
            return;
        }
        const editingEdges = Array.from(this.connectors.keys());
        const removedConnectors = editingEdges.filter(
            (edge) => !connectors.includes(edge)
        );

        removedConnectors.forEach((connector) =>
            this.stopConnectorEdition(connector)
        );
        connectors.map((connector) => this.getConnectorEditor(connector));
    }

    private onEndpointChange(connectors: Connector<NodeData, EdgeData>[]) {
        connectors.forEach((c) => {
            const editor = this.connectors.get(c);
            if (!editor) {
                return;
            }
            editor.onConnectorUpdated();
        });
    }

    private getConnectorEditor(connector: Connector<NodeData, EdgeData>) {
        if (this.connectors.has(connector)) {
            return;
        }
        const editor = new OrthoEditor(this.zoomAdapter);

        const oco = this.options?.connectors as IEdgeOrthoConnectorOptions;
        const editOptions: IOrthoEditorOptions = {
            ...(oco?.edit ?? {}),
            shapeMargin: oco?.shapeMargin ?? 20,
            debug: this.debug,
        };
        const editorOptions = { ...oco, ...editOptions };
        const el = editor.init(editorOptions);
        this.layerManager.addToTools(el);
        editor.startEdit(connector as Connector<NodeData, EdgeData>);

        this.connectors.set(connector, editor);

        return editor;
    }

    private stopConnectorEdition(connector: Connector<NodeData, EdgeData>) {
        const editor = this.connectors.get(connector);
        editor.endEdit();
        void this.onEditorEnd(connector as Connector<NodeData, EdgeData>);
        this.connectors.delete(connector);
    }

    private async onEditorEnd(c: Connector<NodeData, EdgeData>) {
        // Ensure that the connector is still present in the graph
        const connector = this.graph.getConnectorById(c.id);
        const edge = this.graph.getEdgeFromConnector(connector);
        if (!edge) {
            return;
        }
        edge.geometry = c.getGeometry();
        //TODO: put the saving call outside of the graph, the client should only react to connectors changes and save by itself
        await this.options?.saveGeometry?.(edge, edge.geometry);
    }
}
