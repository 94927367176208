import { autoserialize, SerializableEnumeration } from 'cerialize';

export enum CommunicationType {
    DataGalaxyNews,
    DataNews,
    ProductNews,
    CommunityNews,
    PartnerNews,
    All,
}
SerializableEnumeration(CommunicationType);

export class CommunicationSetting {
    @autoserialize CommunicationType?: CommunicationType;
    @autoserialize EnableEmail?: boolean;
    @autoserialize EnableInApp?: boolean;
}
