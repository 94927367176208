import { Injectable } from '@angular/core';
import { formConfigs } from './form-configs/connection-form-configs';
import { ConnectorUtil } from '../ConnectorUtil';
import { IConfigFieldDef } from './form-configs/types/interfaces/config-field-def.interface';
import { ConnectionFormService } from './connection-form.service';
import { JsonObject } from './connection-form.types';
import { ConnectorStateService } from '../connector-state.service';

@Injectable({ providedIn: 'root' })
export class ConnectionSettingsService {
    private _isSettingsValidated = false;

    constructor(
        private connectionFormService: ConnectionFormService,
        private connectorStateService: ConnectorStateService
    ) {}

    public get listFieldUpdateCalled$() {
        return this.connectorStateService.listFieldUpdateCalled$;
    }

    public listFieldUpdated() {
        return this.connectorStateService.listFieldUpdated();
    }

    get isSettingsValidated(): boolean {
        return this._isSettingsValidated;
    }

    set isSettingsValidated(value: boolean) {
        this._isSettingsValidated = value;
    }

    public get pluginName() {
        return this.connectionFormService.getPlugin().name;
    }

    public get currentConnection() {
        return this.connectionFormService.getCurrentConnection();
    }

    public setCurrentConnection(value) {
        return this.connectionFormService.setCurrentConnection(value);
    }

    public get fields() {
        return this.connectionFormService.getFields();
    }

    public get savedConnection(): JsonObject {
        return this.connectionFormService.getSavedConnection();
    }

    public get savedTransform(): JsonObject {
        return this.connectionFormService.getSavedTransform();
    }

    public get advancedModeText() {
        return JSON.stringify(this.currentConnection, null, 4);
    }

    public init() {
        if (this.savedConnection) {
            this.setCurrentConnection(this.savedConnection);
        } else {
            this.setDefaultValues();
        }
        this.loadValues(this.currentConnection, this.savedTransform);
    }

    public updateCurrentConnection(jsonText: string) {
        return this.connectionFormService.setCurrentConnection(
            JSON.parse(jsonText)
        );
    }

    public updateConnection() {
        const formConfig = formConfigs[this.pluginName];
        const connection = ConnectorUtil.buildPayload(
            this.fields,
            formConfig,
            this.connectionFormService.getDataStructure()
        );
        this.fields.maskList?.forEach(
            (mask, i) => (connection[`structure-masks.${i}`] = mask)
        );
        connection[`data-structure`] = this.connectionFormService
            .getDataStructure()
            .toString();
        this.setCurrentConnection(connection);
    }

    public getCurrentConnection(): any {
        return this.currentConnection;
    }

    private loadValues(
        connection: JsonObject,
        transform: JsonObject | undefined
    ) {
        this.setInitialValues(formConfigs[this.pluginName], connection);
        this.fields.maskList = ConnectorUtil.buildMaskList(connection);
        this.fields.pathTransformation = transform?.['structure-path'];
        this.connectorStateService.listFieldUpdated();
    }

    private setDefaultValues() {
        const port = ConnectorUtil.getDefaultPort(this.pluginName);
        this.fields.port = port?.toString() ?? '';
    }

    private setInitialValues(
        confs: IConfigFieldDef[],
        savedConnection: object
    ) {
        confs.forEach((conf) => {
            if (savedConnection[conf.payloadField] != undefined) {
                this.fields[conf.formField] =
                    savedConnection[conf.payloadField];
            }
            if (!conf.dependencies) {
                return;
            }
            conf.dependencies.forEach((dep) => {
                if (
                    savedConnection[dep.field.payloadField] != undefined &&
                    dep.show(savedConnection[conf.payloadField])
                ) {
                    this.fields[dep.field.formField] =
                        savedConnection[dep.field.payloadField];
                }
            });
        });
    }
}
