import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ProblemDetails } from '../problem-details/problem-details';

/**
 * ## Role
 * Intercept all HttpErrorResponse containg a Problem details to handle them and throw a ProblemDetails
 * Problem details can be returned by the V3 API when an error occurs
 * Problem details are defined in RFC 7807
 * @see https://tools.ietf.org/html/rfc7807
 */
@Injectable()
export class ProblemDetailsInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next
            .handle(req)
            .pipe(catchError((error: any) => this.handleResponseError(error)));
    }

    private handleResponseError(errorResponse: any) {
        if (errorResponse instanceof HttpErrorResponse) {
            const error = errorResponse.error;
            if (ProblemDetails.isProblemDetails(error)) {
                const problemDetails = ProblemDetails.fromJson(error);
                return throwError(() => problemDetails);
            }
        }
        return throwError(() => errorResponse);
    }
}
