import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseDTO, HierarchicalData } from '@datagalaxy/dg-object-model';

export enum SynonymType {
    LocalSynonym = 0,
    LinkedObject = 1,
}
SerializableEnumeration(SynonymType);

@inheritSerialization(BaseDTO)
export class SynonymDto extends BaseDTO {
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public Type!: SynonymType;
    @deserializeAs(HierarchicalData) public HddData!: HierarchicalData;

    public get ReferenceId() {
        return this.HddData.DataReferenceId;
    }
}
