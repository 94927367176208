import { CoreUtil } from '@datagalaxy/core-util';
import { GenericDeserialize } from 'cerialize';
import { dgEventTypeMappings } from './event-type-registry';
import { ITypedEvent } from './UserEventBase';

export function deserializeTypedEvent(typedEvent: ITypedEvent) {
    const typeConstructor = dgEventTypeMappings.get(typedEvent.EventTypeName);
    if (!typeConstructor) {
        CoreUtil.warn(`unknown dgEvent: ${typedEvent.EventTypeName}`);
        return;
    }
    return GenericDeserialize(typedEvent, typeConstructor);
}

export function deserializeTypedEventArray(typedEvents: ITypedEvent[]) {
    return (
        typedEvents?.map((e) => deserializeTypedEvent(e)).filter((e) => e) ?? []
    );
}
