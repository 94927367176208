import {
    IEntityLinkIdentifier,
    IObjectLinkIdentifier,
} from '../linked-object.types';
import { TypeLinkDataInfo } from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export enum EntityLinkedObjectMode {
    undefined = 0,
    /** used to delete link(s) */
    Delete,
    /** used to create link(s) */
    Create,
    /** used to create link(s) or replace all existing links type */
    CreateOrReplace,
    /** used to create link(s) or to retrieve existing links */
    CreateOrGetExisting,
}

export interface ILinkedObjectModalInputOptions {
    modalTitleTranslateKey?: string;
    /** if specified the target data list will be readonly */
    targetEntityDataList?: EntityItem[];
    linkToUpdate?: IObjectLinkIdentifier;
    noCheckAccessRights?: boolean;
    onlyExistingLinks?: boolean;
}

export interface ILinkedObjectModalInput
    extends ILinkedObjectModalInputOptions {
    mode: EntityLinkedObjectMode;
    entityDataList: EntityItem[];
    /** mandatory */
    availableLinkTypes: TypeLinkDataInfo[];
}

export interface ILinkedObjectModalOutput {
    linkIdrs: IEntityLinkIdentifier[];
}
