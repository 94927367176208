import { Injectable } from '@angular/core';
import {
    ObjectAccessType,
    ObjectSecurityApiService,
} from '@datagalaxy/webclient/security/data-access';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class ObjectSecurityService {
    constructor(
        private objectSecurityApiService: ObjectSecurityApiService,
        private userService: UserService
    ) {}

    public async getObjectUsersAccess(referenceId: string) {
        const userAccesses =
            await this.objectSecurityApiService.getObjectUsersAccess(
                referenceId
            );
        const userIdsWithAccess = userAccesses.UsersAccess.filter(
            (u) => u?.UserId && u.ObjectAccessType !== ObjectAccessType.NoAccess
        ).map((u) => u.UserId);
        return this.userService
            .getUserList()
            .filter(
                (user) => user.UserId && userIdsWithAccess.includes(user.UserId)
            );
    }
}
