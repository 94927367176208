import { deserialize, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseDTO)
export class ClientIntegrationDTO {
    @deserialize public ClientIntegrationId!: number;
    @deserialize public DisplayName!: string;
    @deserialize public IsEnabled: boolean;
    @deserialize public IntegrationToken!: string;
    @deserialize public IntegrationUserId!: string;
    @deserialize public IsClientAdmin!: boolean;
    public integrationUserName!: string;

    constructor(isEnabled?: boolean) {
        this.IsEnabled = !!isEnabled;
    }
}
