import { IConfigFieldDef } from './types/interfaces/config-field-def.interface';
import { azureDataLakeGen2Config } from './plugins/azure-data-lake-gen2/azure-data-lake-gen2-config';
import { azureSqlConfig } from './plugins/azure-sql/azure-sql-config';
import { snowflakeConfig } from './plugins/snowflake/snowflake-config';
import { sqlServerConfig } from './plugins/sql-server/sql-server-config';
import { amazonS3Config } from './plugins/amazon-s3/amazon-s3-config';
import { talendCloudConfig } from './plugins/talend-cloud/talend-cloud-config';
import { denodoConfig } from './plugins/denodo/denodo-config';
import { googleBigQueryConfig } from './plugins/google-big-query/google-big-query-config';
import { powerBiConfig } from './plugins/power-bi/power-bi-config';
import { tableauConfig } from './plugins/tableau/tableau-config';
import { lookerConfig } from './plugins/looker/looker-config';
import { oracleConfig } from './plugins/oracle/oracle-config';
import { amazonAthenaConfig } from './plugins/amazon-athena/amazon-athena-config';
import { amazonGlueConfig } from './plugins/amazon-glue/amazon-glue-config';
import { mongoDbConfig } from './plugins/mongo-db/mongo-db-config';
import { amazonDynamoDbConfig } from './plugins/amazon-dynamo-db/amazon-dynamo-db-config';
import { flakyConfig } from './plugins/flaky/flaky-config';
import { azureSynapseConfig } from './plugins/azure-synapse/azure-synapse-config';
import { databricksConfig } from './plugins/databricks/databricks-config';
import { hubspotConfig } from './plugins/hubspot/hubspot-config';
import { qlikcloudConfig } from './plugins/qlikcloud/qlikcloud-config';
import { starburstConfig } from './plugins/starburst/starburst-config';
import { salesforcePlatformConfig } from './plugins/salesforce-platform/salesforce-platform-config';

export const formConfigs: { [key: string]: IConfigFieldDef[] } = {
    azuredatalakegen2: azureDataLakeGen2Config,
    azuresql: azureSqlConfig,
    snowflake: snowflakeConfig,
    sqlserver: sqlServerConfig,
    amazons3: amazonS3Config,
    talendcloud: talendCloudConfig,
    denodo: denodoConfig,
    googlebigquery: googleBigQueryConfig,
    powerbi: powerBiConfig,
    tableau: tableauConfig,
    looker: lookerConfig,
    oracle: oracleConfig,
    amazonathena: amazonAthenaConfig,
    amazonglue: amazonGlueConfig,
    mongodb: mongoDbConfig,
    amazondynamodb: amazonDynamoDbConfig,
    flaky: flakyConfig,
    azuresynapse: azureSynapseConfig,
    databricks: databricksConfig,
    hubspot: hubspotConfig,
    qlikcloud: qlikcloudConfig,
    starburst: starburstConfig,
    salesforceplatform: salesforcePlatformConfig,
};
