import * as moment from 'moment';
import { BaseService } from '@datagalaxy/core-ui';
import {
    EntityType,
    EntityTypeUtil,
    HierarchyDataDescriptor,
    IEntityIdentifier,
    IHasHddData,
    IHierarchicalData,
    ModelType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { GetUserSettingValueResult } from '@datagalaxy/webclient/user/data-access';
import { CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { NavigationEventService } from '../navigation/services/navigation-event.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable, Injector, Type } from '@angular/core';
import { ViewTypeService } from './viewType.service';
import { AppDataService } from './app-data.service';
import { Constants } from '../shared/util/Constants';
import { FilterBarService } from '../shared/filter/services/filterBar.service';
import { StateName } from '@datagalaxy/webclient/routing';
import { DataUtil } from '../shared/util/DataUtil';
import { CurrentSpaceService } from './currentSpace.service';
import { LandingPageType } from '../user/user-settings.types';
import { EntityPanelTool } from '../entity-panels/entity-panels.types';
import { HddUtil } from '../shared/util/HddUtil';
import { UserService } from './user.service';
import {
    RawParams,
    StateObject,
    StateOrName,
    StateParams,
    StateService,
    Transition,
    TransitionOptions,
    UIRouterGlobals,
} from '@uirouter/core';
import { AppEventsService } from './AppEvents.service';
import {
    EntityStateNamesIndex,
    getEntityStateNames,
} from '../shared/util/states/CommonStateDefinitions.utils';
import { FilteredViewService } from '../shared/filter/services/filteredView.service';
import { ViewIdentifier } from '../shared/util/ViewIdentifier';
import { ModelerDataUtil } from '../shared/util/ModelerDataUtil';
import { EntityDockingPaneService } from '../shared/entity/services/entity-docking-pane.service';
import { ImpactAnalysisTool } from '../shared/util/app-types/impact-analysis.types';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { IHasTechnicalName } from '@datagalaxy/data-access';
import { FilteredViewsUtil } from '@datagalaxy/webclient/filter/data-access';
import {
    getContextId,
    getLocalId,
    getReferenceId,
} from '@datagalaxy/webclient/utils';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import UrlWord = Constants.Nav.UrlWord;
import ParamKey = Constants.Nav.ParamKey;
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class NavigationService extends BaseService {
    //#region debug
    private debugUrlParams = true;
    private debugDetailed = false;
    private traceStateGo = false;
    //#endregion

    //#region static

    //#region constants

    public static readonly navToTabPrefix = 'ObjectTab_';

    /** value for the isHierarchical flag at init */
    private static readonly DefaultIsHierarchical = true;

    /** value for the issEntityFullPage flag at init */
    private static readonly DefaultIsEntityFullPage = false;

    /** Omit the word in the url when it is the default value */
    public static readonly NoDefaultParamValueInUrl = false;

    //#endregion

    //#region state parameters handling

    //#region navId <-> spaceId

    /** a spaceId is in the form 'contextId:contextId',
     * a navId is in the form 'contextId' */
    public static getNavIdFromSpaceId(spaceId: string) {
        return getContextId(spaceId);
    }

    /** a spaceId is in the form 'contextId:contextId',
     * a navId is in the form 'contextId' */
    private static getSpaceIdFromNavId(navId: string) {
        return navId
            ? getReferenceId(getContextId(navId), getLocalId(navId))
            : undefined;
    }

    private static getSpaceIdFromParams(params: { [paramName: string]: any }) {
        return (
            params &&
            NavigationService.getSpaceIdFromNavId(
                params[Constants.Nav.ParamKey.NavId]
            )
        );
    }

    private static getEntityTypeFromParams(params: {
        [paramName: string]: any;
    }) {
        return params[Constants.Nav.ParamKey.EntityType];
    }

    public static getSpaceIdrFromParams(params: { [paramName: string]: any }) {
        const spaceId = NavigationService.getSpaceIdFromParams(params);
        const versionId = params[Constants.Nav.ParamKey.VersionId];
        return spaceId && new SpaceIdentifier(spaceId, versionId);
    }

    //#endregion

    //#region isHierarchical

    private static getIsHierarchicalUrlWord(isHierachical: boolean) {
        return isHierachical
            ? UrlWord.tree
            : NavigationService.NoDefaultParamValueInUrl
            ? null
            : UrlWord.flat;
    }
    private static getIsHierarchicalFromUrlWord(urlWord: string) {
        return urlWord?.trim().toLowerCase() == UrlWord.tree;
    }

    //#endregion

    //#region isEntityFullPage

    private static getIsEntityFullPageUrlWord(isEntityFullPage: boolean) {
        return isEntityFullPage
            ? UrlWord.full
            : NavigationService.NoDefaultParamValueInUrl
            ? null
            : UrlWord.browse;
    }
    private static getIsEntityFullPageFromUrlWord(urlWord: string) {
        return urlWord?.trim().toLowerCase() == UrlWord.full
            ? true
            : NavigationService.NoDefaultParamValueInUrl
            ? null
            : false;
    }
    //#endregion

    //#region entity id

    private static setParamEntityLocalId(
        entityId: string,
        dgModule: DgModule,
        params: RawParams
    ) {
        const paramName = NavigationService.getEntityIdParamName(dgModule);
        params[paramName] = getLocalId(entityId);
    }

    public static getEntityIdFromParams(dgModule: DgModule, params: RawParams) {
        const spaceId = NavigationService.getSpaceIdFromParams(params);
        const contextId = getContextId(spaceId);
        const paramName = NavigationService.getEntityIdParamName(dgModule);
        const entityLocalId = params[paramName];
        const entityId =
            entityLocalId &&
            contextId &&
            getReferenceId(contextId, entityLocalId);
        //console.log('getEntityIdFromParam', DgModule[dgModule], JSON.stringify(params, null, 2), paramName, paramValue)
        return entityId;
    }

    public static getEntityIdrFromParams(
        entityServerType: ServerType,
        params: IStateParams,
        logWarn = false
    ) {
        const dgModule = DataUtil.getModuleFromServerType(entityServerType);
        const entityId = NavigationService.getEntityIdFromParams(
            dgModule,
            params
        );
        if (!entityId) {
            logWarn &&
                console.warn(
                    'getEntityIdrFromParams',
                    `no entityId for ${ServerType[entityServerType]}`
                );
            return;
        }

        let entityType = NavigationService.getEntityTypeFromParams(params);
        if (!entityType) {
            entityType = EntityTypeUtil.getEntityType(
                ServerType[entityServerType]
            );
            logWarn &&
                console.warn(
                    'getEntityIdrFromParams',
                    `no entityType for ${ServerType[entityServerType]}. Defaulting to ${EntityType[entityType]}`
                );
        }

        const spaceIdr = NavigationService.getSpaceIdrFromParams(params);
        if (!spaceIdr) {
            logWarn && console.warn('getEntityIdrFromParams', 'no spaceId');
            return;
        }

        if (getContextId(spaceIdr.spaceId) != getContextId(entityId)) {
            logWarn &&
                console.log(
                    'getEntityIdrFromParams',
                    'contextId mismatch',
                    spaceIdr,
                    entityId
                );
            return;
        }

        return new EntityIdentifier(entityId, spaceIdr.versionId, entityType);
    }

    // #Archi-cleanup (fbo): those parameter names could eventually be replaced by a common 'entityId' parameter,
    // since ModelElementId (not ModelId) param name is used only for this case
    public static getEntityIdParamName(dgModule: DgModule) {
        switch (dgModule) {
            case DgModule.Glossary:
                return ParamKey.PropertyId;
            case DgModule.Processing:
                return ParamKey.DataProcessingId;
            case DgModule.Usage:
                return ParamKey.SoftwareId;
            case DgModule.Catalog:
                return ParamKey.ModelElementId;
        }
    }

    //#endregion

    //#endregion

    //#region states logic

    /** Handle incompatibilities between the given state and selected entity
     * (e.g.: Glossary Mappings are only enabled for BusinessTerm/Concept/Indicator) */
    private static getAvailableEntityDetailsTabActiveState(
        targetStateName: string,
        spaceIdr: ISpaceIdentifier,
        entity: IHasHddData | EntityItem
    ) {
        let result = '.';

        const dgModule = DataUtil.getModuleFromServerType(
            entity.HddData.Data.DataServerType
        );
        const entityType = entity.HddData.Data.EntityType;

        switch (dgModule) {
            case DgModule.Glossary:
                if (
                    (targetStateName == StateName.GlossaryMapping &&
                        !DataUtil.isGlossaryMappingEnabled(
                            spaceIdr,
                            entityType
                        )) ||
                    (targetStateName == StateName.GlossaryLinkedObjects &&
                        !DataUtil.isLinkedObjectsEnabled(entityType))
                ) {
                    result = StateName.GlossaryDetails;
                }
                break;

            case DgModule.Processing:
                if (
                    targetStateName == StateName.DataProcessingMapping &&
                    !DataUtil.isDataProcessingMappingEnabled(entityType)
                ) {
                    result = StateName.DataProcessingDetails;
                }
                break;

            case DgModule.Usage:
                if (
                    (targetStateName == StateName.SoftwareFields &&
                        !EntityTypeUtil.hasEntityTypeAsChild(
                            entityType,
                            EntityType.UsageField
                        )) ||
                    (targetStateName == StateName.SoftwareComponents &&
                        !EntityTypeUtil.hasEntityTypeAsChild(
                            entityType,
                            EntityType.UsageComponent
                        ))
                ) {
                    result = StateName.SoftwareDetails;
                }
                break;

            case DgModule.Catalog: {
                const diagramStates = [StateName.ModelerDiagramsTab];
                const dataQualityStates = [StateName.ModelerDataQuality];
                const pkFkListStates = [
                    StateName.ModelerModelPrimaryKeysTab,
                    StateName.ModelerModelForeignKeysTab,
                ];
                let forbidden = false;
                if (
                    diagramStates.includes(targetStateName) &&
                    !DataUtil.isModelDiagramsEnabled(entity.HddData)
                ) {
                    forbidden = true;
                } else if (
                    targetStateName == StateName.ModelerModelSettingsTab &&
                    entity instanceof EntityItem &&
                    !ModelerDataUtil.isModelSettingEnabled(entity)
                ) {
                    forbidden = true;
                } else if (
                    pkFkListStates.includes(targetStateName) &&
                    entity instanceof EntityItem &&
                    !ModelerDataUtil.isModelPrimaryKeyForeignKeyListsEnabled(
                        entity
                    )
                ) {
                    forbidden = true;
                } else if (
                    dataQualityStates.includes(targetStateName) &&
                    entity instanceof EntityItem &&
                    entity.EntityType != EntityType.View &&
                    entity.EntityType != EntityType.Table
                ) {
                    forbidden = true;
                }
                if (forbidden) {
                    const states = getEntityStateNames(DgModule.Catalog);
                    result = states[EntityStateNamesIndex.Details];
                }
                break;
            }
        }

        return result;
    }

    public static getModuleFromStateName(stateName: string) {
        return !stateName
            ? DgModule.unknown
            : stateName.includes(StateName.Modeler)
            ? DgModule.Catalog
            : stateName.includes(StateName.DataProcessing)
            ? DgModule.Processing
            : stateName.includes(StateName.Software)
            ? DgModule.Usage
            : stateName.includes(StateName.GlossaryRoot)
            ? DgModule.Glossary
            : stateName.includes(StateName.Diagrams)
            ? DgModule.Diagram
            : DgModule.unknown;
    }

    //#endregion

    //#endregion static

    //#region properties

    //#region public properties

    public get currentModelId() {
        return this._currentModelId;
    }
    public get currentModelType() {
        return this._currentModelType;
    }

    /** when to hide the EntityList when displaying an entity */
    public get isEntityFullPage() {
        return this._currentIsEntityFullPage;
    }

    /** when to display list or grid as hierarchical */
    public get isHierarchical() {
        return this._currentIsHierarchical;
    }

    public get isInSpaceHomeView() {
        return this.currentStateName == StateName.SpaceHome;
    }
    public get isInSearchResultsView() {
        return this.currentStateNameContains(StateName.ClientSearchResults);
    }
    public get isInClientTaskView() {
        return this.currentStateNameContains(StateName.ClientTasks);
    }
    public get isInModuleView() {
        return this.currentStateNameContains(
            StateName.GlossaryMain,
            StateName.ModelerMain,
            StateName.SoftwareMain,
            StateName.DataProcessingMain,
            StateName.DiagramsMain
        );
    }
    public get isUserSessionTimeout() {
        return this._isUserSessionTimeout;
    }
    public get isInDiagramsListView() {
        return this.currentStateNameContains(StateName.Diagrams);
    }

    //#endregion - public properties

    //#region private properties

    //#region Current model identifier
    private _currentModelId: string;
    private _currentModelType: ModelType;
    //#endregion

    //#region  Memo state data
    private _memoStateName: string;
    private _memoStateParams: Object;
    //#endregion

    private _currentIsHierarchical = NavigationService.DefaultIsHierarchical;
    private _currentIsEntityFullPage =
        NavigationService.DefaultIsEntityFullPage;

    /** when true, stateGo returns the target state url,
     * instead of calling $state.go and returning a StateObject */
    private _isGetUrlInsteadOfGo: boolean;

    /** true while in stateGo, to prevent url changes to cancel the state transition */
    private isTransitionning: boolean;

    /** true when the user's session has been ended */
    private _isUserSessionTimeout = false;

    private get currentStateParams() {
        return this.uiRouterGlobals.params as StateParams & IStateParams;
    }
    private get currentStateName() {
        return this.uiRouterGlobals.current?.name;
    }
    private get parentStateName() {
        return this.uiRouterGlobals.$current?.parent?.name;
    }

    private get uiRouterGlobals() {
        return (this._uiRouterGlobals ??= this.getService(UIRouterGlobals));
    }
    private _uiRouterGlobals: UIRouterGlobals;

    private get entityDockingPaneService() {
        return (this._entityDockingPaneService ??= this.getService(
            EntityDockingPaneService
        ));
    }
    private _entityDockingPaneService: EntityDockingPaneService;

    private getService<T>(type: Type<T>) {
        this.log('getService', type.name);
        return this.injector.get(type);
    }

    //#endregion - private properties

    //#endregion - properties

    constructor(
        private injector: Injector,
        private cookieService: CookieService,
        private stateService: StateService,
        private functionalLogService: FunctionalLogService,
        private appEventsService: AppEventsService,
        private navigationEventService: NavigationEventService,
        private appDataService: AppDataService,
        private currentUserService: CurrentUserService,
        private filterBarService: FilterBarService,
        private currentSpaceService: CurrentSpaceService,
        private filteredViewService: FilteredViewService,
        private userService: UserService,
        private viewTypeService: ViewTypeService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        this.navigationEventService._deleteSpace$.subscribe(
            (spaceIdentifier) => {
                if (
                    this.getCurrentSpaceIdr()?.spaceId ===
                    spaceIdentifier.DataReferenceId
                ) {
                    this.log(
                        'deleteSpace$ is current space -> goToClientSpacesList'
                    );
                    this.goToClientSpacesList().then();
                }
            }
        );
    }

    //#region previous state management

    public hasMemoState() {
        return this._memoStateName != undefined;
    }

    public clearMemoState() {
        this._memoStateName = null;
        this._memoStateParams = null;
    }

    public setMemoState(transition: Transition, useTargetState: boolean) {
        if (useTargetState) {
            this._memoStateName = transition.$to().name;
            this._memoStateParams = transition.params('to');
        } else {
            this._memoStateName = transition.$from().name;
            this._memoStateParams = transition.params('from');
        }
        this.log(
            'setMemoState',
            useTargetState,
            this.debug && this.getMemoStateUrl()
        );
    }

    public async goToMemoState() {
        this.log('goToMemoState', this.debug && this.getMemoStateUrl());
        await this.stateGo(this._memoStateName, this._memoStateParams);
        this.clearMemoState();
    }

    private getMemoStateUrl() {
        const url =
            this.hasMemoState() &&
            this.stateService.href(this._memoStateName, this._memoStateParams);
        return url && url.replace(/~2F/g, '/');
    }
    //#endregion previous state management

    //#region current state  (get, reload)

    //#region tabs
    public isUiTabActive(tabItem: IUiTab) {
        if (!tabItem?.tabStateName) {
            return;
        }
        return tabItem.isStateNameRelative
            ? this.currentStateNameContains(tabItem.tabStateName)
            : tabItem.tabStateName == this.getCurrentStateName();
    }
    public async gotoUiTabState(tabItem: { tabStateName?: string }) {
        return this.gotoRouteIdentifier(tabItem?.tabStateName);
    }
    public async gotoRouteIdentifier(routeIdentifier: string) {
        return routeIdentifier
            ? this.stateGo(routeIdentifier)
            : this.stateNoGo();
    }
    /** why not calling reloadCurrentState instead ? */
    public async gotoCurrentState() {
        const currentStateName = this.getCurrentStateName();
        return currentStateName
            ? this.stateGo(currentStateName)
            : this.stateNoGo();
    }
    //#endregion
    /**
     * @deprecated to remove after authV2 ending and authV1 cleanup
     */
    public getCurrentStateClientId() {
        return this.getCurrentStateParamValue<string>(ParamKey.ClientId);
    }
    public getCurrentStateName() {
        return this.currentStateName;
    }
    public getCurrentStateParticle() {
        const fullName = this.currentStateName;
        return fullName.substr(fullName.lastIndexOf('.') + 1);
    }
    public getCurrentStatePropId() {
        return this.getCurrentStateParamValue<string>(ParamKey.PropId);
    }

    public async reloadCurrentState() {
        await this.stateService.reload();
    }

    private getCurrentStateParamValue<T>(paramKey: string) {
        return this.currentStateParams[paramKey] as T;
    }

    /** returns true if the current state name contains one of the given relative state names */
    private currentStateNameContains(...relativeStateNames: string[]) {
        return StringUtil.containsAny(
            this.currentStateName,
            relativeStateNames
        );
    }

    /** The method that actually does the state transition,
     *  or returns the target state url if _isGetUrlInsteadOfGo is true.
     *  - this method should(must) be the only one to call $state.go() */
    private async stateGo(
        to: StateOrName,
        params?: IStateParams,
        options?: TransitionOptions
    ): Promise<StateObject> {
        const debugData = () => {
            if (!this.debug) {
                return;
            }
            const data = {
                to,
                params,
                options,
                current: this.currentStateName,
                href: this.stateService.href(to, params, options),
            };
            return !this.debugDetailed ? data : JSON.stringify(data, null, 2);
        };

        if (this._isGetUrlInsteadOfGo === true) {
            // compute and return the target url instead of transitioning
            this._isGetUrlInsteadOfGo = false;
            const url = this.stateService.href(to, params, options) || '.';
            this.log('stateGo(_isGetUrlInsteadOfGo)', url, debugData());
            // Here we force a fake return type, to avoid modifying many function calls
            // This case is used only by getUrlToGoToWithHierarchicalData, for the 'Open in new tab' right-click action.
            return url as any as StateObject;
        }

        this.traceStateGo
            ? this.logTrace('stateGo', 'from:', debugData())
            : this.log('stateGo', debugData());

        this.isTransitionning = true;
        return new Promise<StateObject>((resolve, reject) => {
            this.stateService.go(to, params, options).then(
                (state) => {
                    this.log('stateGo resolve');
                    this.isTransitionning = false;
                    resolve(state);
                },
                (reason) => {
                    this.log('stateGo reject', reason, { to, params, options });
                    this.isTransitionning = false;
                    reject(reason);
                }
            );
        });
    }

    private async stateNoGo(): Promise<StateObject> {
        return this._isGetUrlInsteadOfGo ? ('.' as any as StateObject) : null;
    }

    //#endregion current state

    //#region space and version  (get, set, reload, goto)

    private makeSpaceParameters(spaceIdr: ISpaceIdentifier) {
        const params = {} as IStateParams;
        params[ParamKey.NavId] =
            NavigationService.getNavIdFromSpaceId(spaceIdr.spaceId) || null;
        params[ParamKey.VersionId] = spaceIdr.versionId || null;
        return params;
    }

    public async reloadCurrentProject() {
        return this.stateService.reload(StateName.Space);
    }

    /** Redirects to the space home of the given space/version */
    public async goToSpaceHome(
        spaceIdr: ISpaceIdentifier,
        opt?: {
            /** when true, the version of the application's current space will be used
             * if the given space matches it
             * and the current version is not null */
            keepCurrentVersion?: boolean;
        }
    ) {
        if (spaceIdr && opt?.keepCurrentVersion) {
            const currentSpaceIdr = this.getCurrentSpaceIdr();
            if (
                currentSpaceIdr &&
                spaceIdr.spaceId == currentSpaceIdr.spaceId
            ) {
                spaceIdr = new SpaceIdentifier(
                    spaceIdr.spaceId,
                    currentSpaceIdr.versionId
                );
            }
        }

        if (spaceIdr) {
            this.log('goToSpaceHome', spaceIdr, opt);
        } else {
            this.logTrace('goToSpaceHome-nospaceIdr', opt);
        }

        return this.goToSpaceState(StateName.SpaceHome, spaceIdr);
    }

    private async gotoSpaceDashboard(space: ISpaceIdentifier) {
        return this.goToSpaceState(StateName.SpaceDashboard, space);
    }

    public async goToSpaceRolesAdmin(space: ISpaceIdentifier) {
        return this.goToSpaceState(StateName.SpaceRoles, space);
    }
    public async goToSpaceDetailsAdmin(space: ISpaceIdentifier) {
        return this.goToSpaceState(StateName.SpaceDetails, space);
    }

    public async goToSpaceConnector(space: ISpaceIdentifier) {
        return this.goToSpaceState(StateName.SpaceConnector, space);
    }

    private async goToSpaceState(
        stateName: string,
        spaceIdr: ISpaceIdentifier,
        params = {} as IStateParams,
        reload = false
    ) {
        params[ParamKey.NavId] = NavigationService.getNavIdFromSpaceId(
            spaceIdr.spaceId
        );
        params[ParamKey.VersionId] = spaceIdr.versionId;
        const options = reload ? { reload: stateName } : undefined;
        return this.stateGo(stateName, params, options);
    }

    //#endregion space and version

    //#region params with url word (isHierarchical, isEntityFullPage) (get, set)

    public showHierarchicalSwitch() {
        return this.getActiveModuleView() != ModuleView.datamap;
    }

    /** update the internal value and returns the new value as a promise.
     * If the value is changed:
     * - changes the url accordingly,
     * - emits the entityFullPageChanged event (via appEventsService) */
    public async setIsEntityFullPage(
        isEntityFullPage: boolean
    ): Promise<boolean> {
        this.log(
            'setIsEntityFullPage',
            isEntityFullPage,
            this._currentIsEntityFullPage
        );
        const value = !!isEntityFullPage;
        if (value == this._currentIsEntityFullPage) {
            return value;
        }

        if (this.isTransitionning) {
            this.log('setIsEntityFullPage-transitioning');
        } else if (this.currentStateName == '') {
            this.log('setIsEntityFullPage-emptyState');
        } else {
            try {
                const params = {
                    [ParamKey.IsEntityFullPageWord]:
                        NavigationService.getIsEntityFullPageUrlWord(value),
                };
                this.log('setIsEntityFullPage-updateUrl', params);
                await this.updateUrlWithoutChangingState(params);
            } catch (e) {
                this.warn(e);
            } finally {
                this._currentIsEntityFullPage = value;
                this.log('setIsEntityFullPage-updateUrl-finally', value);
                this.appEventsService.emitEntityFullPageChange(value);
            }
        }
        return value;
    }
    public async toggleIsEntityFullPage() {
        return this.setIsEntityFullPage(!this._currentIsEntityFullPage);
    }

    // Full Page Rule: If target Module is different than current module, then the target will be in full page
    private adjustIsEntityFullPage(
        targetModule: DgModule,
        opt: IGotoFullpageOptions
    ) {
        if (opt.isEntityFullPage != undefined) {
            return;
        }

        const currentModule = this.getCurrentModule();
        opt.isEntityFullPage = currentModule != targetModule;
    }
    public initIsHierarchical(urlWord: string) {
        const value =
            urlWord == undefined
                ? NavigationService.DefaultIsHierarchical
                : NavigationService.getIsHierarchicalFromUrlWord(urlWord);
        this._currentIsHierarchical = value;
        const result = NavigationService.getIsHierarchicalUrlWord(value);
        this.debugUrlParams && this.log('initIsHierarchical', urlWord, result);
        return result;
    }
    public async initIsEntityFullPage(urlWord: string) {
        const value =
            urlWord == undefined
                ? NavigationService.DefaultIsEntityFullPage
                : NavigationService.getIsEntityFullPageFromUrlWord(urlWord);
        this.log('initIsEntityFullPage', urlWord, value);
        await this.setIsEntityFullPage(value);
    }

    // Update the URL without page reload!
    public async updateUrlWithoutChangingState(params: {
        [paramName: string]: string;
    }): Promise<void> {
        this.log('updateUrlWithoutChangingState', params);

        const setDynamicParamState = (
            state: StateObject,
            paramName: string,
            isDynamic: boolean
        ) => {
            let current = state;
            while (current) {
                const paramDefinition = current.params[paramName];
                if (paramDefinition) {
                    paramDefinition.dynamic = paramDefinition.config.dynamic =
                        isDynamic;
                    break;
                }
                if (current.parent) {
                    current = current.parent;
                } else {
                    throw new Error(
                        `Parameter "${paramName}" is not defined for the current state ("${state?.name}").`
                    );
                }
            }
        };

        const state = this.uiRouterGlobals?.current?.$$state();
        const paramNames = Object.keys(params);
        paramNames.forEach((paramName) =>
            setDynamicParamState(state, paramName, true)
        );

        return new Promise<void>((resolve, reject) => {
            this.stateService
                .go('.', params, { location: 'replace' })
                .then(() =>
                    paramNames.forEach((paramName) =>
                        setDynamicParamState(state, paramName, false)
                    )
                )
                .then(
                    () => resolve(),
                    (reason) => reject(reason)
                );
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private setParamModuleFilter(params: IStateParams, filteredViewId: number) {
        // deactivated, not to reload state chain from .data when .list or .details is requested
        //params[ParamKey.FilteredViewId] = filteredViewId || -1
        params[ParamKey.FilteredViewId] = -1;
    }

    private setParamPreFilter(params: IStateParams) {
        // kept for compatibility
        params[ParamKey.PreFilterWord] =
            NavigationService.NoDefaultParamValueInUrl ? null : UrlWord.all;
    }
    private setParamIsHierarchical(
        isHierarchical: boolean,
        params: IStateParams
    ) {
        const value = isHierarchical ?? this._currentIsHierarchical;
        const word = NavigationService.getIsHierarchicalUrlWord(value);
        params[ParamKey.IsHierarchicalWord] = word;
        this.debugUrlParams && this.log('setParamIsHierarchical', word);
        return value != this._currentIsHierarchical;
    }
    private setParamIsEntityFullPage(
        isFullPage: boolean,
        params: IStateParams
    ) {
        const value = isFullPage ?? this._currentIsEntityFullPage;
        const word = NavigationService.getIsEntityFullPageUrlWord(value);
        params[ParamKey.IsEntityFullPageWord] = word;
        this.debugUrlParams && this.log('setParamIsEntityFullPage', word);
        return value != this._currentIsEntityFullPage;
    }

    //#endregion

    //#region module (get, goto)

    public isEntityDashboard() {
        return this.currentStateNameContains(StateName.RelativeDashboard);
    }

    public getActiveModuleView() {
        return this.currentStateNameContains(StateName.RelativeList)
            ? ModuleView.entityList
            : this.currentStateNameContains(StateName.RelativeGrid)
            ? ModuleView.entityGrid
            : this.currentStateNameContains(StateName.RelativeDataMap)
            ? ModuleView.datamap
            : ModuleView.none;
    }

    public isActiveModule(identifier: string) {
        return this.currentStateNameContains(identifier);
    }

    public getCurrentModule() {
        return NavigationService.getModuleFromStateName(this.currentStateName);
    }

    public getCurrentZoneFeatureCode() {
        switch (this.getCurrentModule()) {
            case DgModule.Catalog:
                return 'DICTIONARY';
            case DgModule.Processing:
                return 'PROCESSING';
            case DgModule.Glossary:
                return 'GLOSSARY';
            case DgModule.Usage:
                return 'USAGE';
            default:
                break;
        }

        const stateName = this.currentStateName;
        if (
            stateName == StateName.SpaceDashboard ||
            stateName == StateName.ClientDashboard
        ) {
            return 'DASHBOARD';
        }
        if (stateName == StateName.ClientTasks) {
            return 'MAIN_TASK';
        }
        if (stateName.includes(StateName.ImpactAnalysis)) {
            return 'IMPACT_ANALYSIS';
        }
        if (stateName == StateName.ClientSearchResults) {
            return 'MAIN_SEARCH_RESULTS';
        }
        if (stateName.includes(StateName.SpaceSettings)) {
            return 'SPACE_SETTINGS';
        }
        if (stateName.includes(StateName.ClientSpacesList)) {
            return 'MANAGE_WORKSPACE';
        }
        return '';
    }

    /** this is a shortcut to goTo(itemIdentifier: string, ...) */
    public async goToModule(dgModule: DgModule, spaceIdr: ISpaceIdentifier) {
        if (!dgModule) {
            return this.stateNoGo();
        }
        return this.goTo(this.getItemIdentifierFromModule(dgModule), spaceIdr);
    }
    private getItemIdentifierFromModule(dgModule: DgModule) {
        switch (dgModule) {
            case DgModule.Glossary:
                return ViewIdentifier.Glossary;
            case DgModule.Catalog:
                return ViewIdentifier.Modeler;
            case DgModule.Processing:
                return ViewIdentifier.DataProcessing;
            case DgModule.Usage:
                return ViewIdentifier.Software;
            case DgModule.Diagram:
                return ViewIdentifier.Diagrams;
        }
    }

    // TODO (fbo) enum or constants
    public async goTo(
        itemIdentifier: string,
        spaceIdr?: ISpaceIdentifier,
        options?: { isSpaceOrVersionChange: boolean }
    ) {
        let opt: IGotoEntityViewOptions;
        if (options?.isSpaceOrVersionChange) {
            opt = { isAfterSpaceOrVersionChange: true };

            // Force Glossary if Change From Project To Orga and current module is not Glossary
            const projectOnlyIdentifiers = Object.values<string>([
                ViewIdentifier.Modeler,
                ViewIdentifier.DataProcessing,
                ViewIdentifier.Software,
            ]);

            if (
                SpaceIdentifier.isOrga(spaceIdr) &&
                projectOnlyIdentifiers.includes(itemIdentifier)
            ) {
                itemIdentifier = ViewIdentifier.Glossary;
            }
        }

        switch (itemIdentifier) {
            case ViewIdentifier.SpaceDashboard:
                return this.gotoSpaceDashboard(spaceIdr);
            case ViewIdentifier.SpaceHome:
                return this.goToSpaceHome(spaceIdr);
            case ViewIdentifier.Glossary:
                return this.goToEntityList(DgModule.Glossary, spaceIdr, opt);
            case ViewIdentifier.Modeler:
                return this.goToEntityList(DgModule.Catalog, spaceIdr, opt);
            case ViewIdentifier.DataProcessing:
                return this.goToEntityList(DgModule.Processing, spaceIdr, opt);
            case ViewIdentifier.Diagrams:
                return this.goToDiagrams(spaceIdr);
            case ViewIdentifier.ImpactAnalysis:
                return this.goToImpactAnalysis(spaceIdr);
            case ViewIdentifier.Software:
                return this.goToEntityList(DgModule.Usage, spaceIdr, opt);
            case ViewIdentifier.SpaceSettings:
                return this.goToSpaceDetailsAdmin(spaceIdr);
            case ViewIdentifier.ClientTasks:
                return this.stateGo(StateName.ClientTasks);
            case ViewIdentifier.ClientDashboard:
                return this.goToClientDashboard(false, null, true);
            case ViewIdentifier.Campaigns:
                return this.goToCampaigns(spaceIdr);

            default:
                this.warn('goToModule: not implemented', itemIdentifier);
                return this.stateNoGo();
        }
    }

    public async toggleHierarchicalView(
        dgModule: DgModule,
        currentEntity: IHasHddData,
        spaceIdr: ISpaceIdentifier
    ) {
        this.log('toggleHierarchicalView');
        const isHierarchical = !this._currentIsHierarchical;
        if (
            currentEntity &&
            this.getActiveModuleView() == ModuleView.entityList
        ) {
            const opt: IGotoDetailsOptions = { isHierarchical };
            opt.entityObject = currentEntity;
            opt.modelId = HddUtil.getModelId(currentEntity.HddData);
            const entityIdr = EntityIdentifier.fromIHasHddData(currentEntity);
            return this.goToEntityDetails(dgModule, entityIdr, opt);
        } else {
            return this.goToModuleActiveView(dgModule, spaceIdr, {
                isHierarchical,
            });
        }
    }

    public toggleUrlModuleListView(view: 'flat' | 'tree') {
        this._currentIsHierarchical = view == 'tree';
        this.stateService.go('.', {
            [ParamKey.IsHierarchicalWord]: view,
        });
    }

    public clearCurrentEntity() {
        this.clearEntityIdFromCurrentState();
        this.entityDockingPaneService.resetPanel();
    }

    public async goToFilteredView(
        filteredView: FilteredViewDto,
        spaceIdr: ISpaceIdentifier,
        moduleView?: ModuleView
    ) {
        this.log('goToFilteredView', filteredView, spaceIdr);
        if (
            !filteredView ||
            (filteredView.DgZone == DgZone.Module && !spaceIdr)
        ) {
            return this.stateNoGo();
        }

        const dgZone = filteredView.DgZone;
        if (
            filteredView.FilteredViewId !=
            this.filteredViewService.getCurrentViewId(dgZone)
        ) {
            this.clearCurrentEntity();
        }
        switch (dgZone) {
            case DgZone.Module: {
                const dgModule = FilteredViewsUtil.dgModuleFromModuleName(
                    filteredView.ModuleName
                );
                this.filterBarService.showFilterBar(DgZone.Module);
                this.setCurrentFilteredView(filteredView);
                if (moduleView == ModuleView.entityGrid) {
                    return this.goToGridView(dgModule, spaceIdr, {
                        keepFilteredView: true,
                        showModuleFilterBar: true,
                        isHierarchical: false,
                    });
                } else {
                    return this.goToModuleActiveView(dgModule, spaceIdr, {
                        keepFilteredView: true,
                    });
                }
            }
            case DgZone.Search: {
                filteredView.SpaceUid = getContextId(spaceIdr?.spaceId);
                filteredView.DefaultVersionId = spaceIdr?.versionId;
                this.setCurrentFilteredView(filteredView);
                return this.goToClientSearchResults();
            }
        }
        this.warn('not implemented: ', DgZone[dgZone]);
        return this.stateNoGo();
    }

    public async reloadModuleActiveView(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        clearFilteredView = false
    ) {
        this.log('reloadModuleActiveView', DgModule[dgModule], spaceIdr);
        if (!dgModule || !spaceIdr) {
            return this.stateNoGo();
        }
        if (clearFilteredView) {
            this.clearCurrentFilteredView(DgZone.Module, dgModule);
        }
        return this.goToModuleActiveView(dgModule, spaceIdr, { reload: true });
    }

    private async goToModuleActiveView(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        opt: IGotoModuleViewOptions = {}
    ) {
        this.log('goToModuleActiveView', DgModule[dgModule], spaceIdr, opt);
        switch (this.getActiveModuleView()) {
            case ModuleView.entityGrid:
                return this.goToGridView(dgModule, spaceIdr, opt);
            case ModuleView.datamap:
                return this.goToDataMapView(dgModule, spaceIdr, opt);
            default:
                return this.goToEntityList(dgModule, spaceIdr, opt);
        }
    }

    private async goToGridView(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        opt?: IGotoDetailsOrGridOptions
    ) {
        this.log('goToGridView', DgModule[dgModule], spaceIdr, opt);

        const { stateNames, params, options } = this.prepareInModuleGoto(
            dgModule,
            EntityStateNamesIndex.Grid,
            CoreUtil.merge({ spaceIdr } as IPrepareGotoOptions, opt)
        );

        const stateName = stateNames[EntityStateNamesIndex.Grid];
        const result = await this.stateGo(stateName, params, options);
        if (opt.showModuleFilterBar) {
            this.filterBarService.showFilterBar(DgZone.Module);
        }
        return result;
    }

    /** goto Entitylist with a reset filteredView */
    public async goToEntityListAll(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier
    ) {
        this.log('goToEntityListAll', DgModule[dgModule], spaceIdr);
        if (!dgModule || !spaceIdr) {
            return this.stateNoGo();
        }
        this.clearCurrentFilteredView(DgZone.Module, dgModule);
        return this.goToEntityList(dgModule, spaceIdr, { reload: true });
    }

    private async goToEntityList(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        opt?: IGotoEntityListOptions
    ) {
        this.log('goToEntityList');

        if (this.isNoopModuleChange(dgModule, spaceIdr, opt)) {
            this.log('isNoopModuleChange');
            return this.stateNoGo();
        }

        const opt2: IGotoDetailsOptions = opt ?? {};
        opt2.isEntityFullPage = false;
        opt2.spaceIdr = spaceIdr;
        return this.goToEntityDetails(dgModule, null, opt2);
    }

    /** Detect a user is clicking on the current module (from nav-side-bar): Noop navigation.
     * When using this and doing a no-op, please return this.stateNoGo() instead of nothing ! **/
    private isNoopModuleChange(
        targetModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        opt: IGotoEntityListOptions
    ) {
        if (opt != null) {
            return false;
        }

        if (!SpaceIdentifier.areSame(spaceIdr, this.getCurrentSpaceIdr())) {
            return false;
        }

        return !this.isModuleChange(targetModule);
    }

    private isModuleChange(targetModule: DgModule) {
        return (this.getCurrentModule() || 0) != (targetModule || 0);
    }

    //#endregion module

    //#region entity, filteredView  (get, set, goto)

    public isCurrentEntity(entityIdr: IEntityIdentifier) {
        if (!entityIdr) {
            return false;
        }
        const currentIdr = NavigationService.getEntityIdrFromParams(
            entityIdr.ServerType,
            this.currentStateParams
        );
        return EntityIdentifier.areSame(entityIdr, currentIdr);
    }

    public clearEntityIdFromCurrentState() {
        const params = this.currentStateParams;
        params[ParamKey.PropertyId] = undefined;
        params[ParamKey.DataProcessingId] = undefined;
        params[ParamKey.SoftwareId] = undefined;
        params[ParamKey.ModelElementId] = undefined;
        params[ParamKey.EntityType] = undefined;
    }

    private async loadFilterAndGoToEntityDetails(
        viewModule: DgModule,
        filteredViewGuid: string,
        versionId: string
    ) {
        const filteredView = await this.filteredViewService.loadView(
            filteredViewGuid,
            DgZone.Module
        );
        if (filteredView) {
            this.setCurrentFilteredView(filteredView);
        } else {
            this.clearCurrentFilteredView(DgZone.Module, viewModule);
        }
        const spaceId = getReferenceId(
            filteredView.SpaceUid,
            filteredView.SpaceUid
        );
        const spaceIdr = new SpaceIdentifier(spaceId, versionId);
        return this.goToEntityDetails(viewModule, null, {
            spaceIdr,
            isEntityFullPage: false,
            reload: true,
            keepFilteredView: true,
            showModuleFilterBar: true,
        });
    }

    private async loadFilterAndGoToSearch(filteredViewGuid: string) {
        const filteredView = await this.filteredViewService.loadView(
            filteredViewGuid,
            DgZone.Search
        );
        if (filteredView) {
            this.setCurrentFilteredView(filteredView);
        } else {
            this.clearCurrentFilteredView(DgZone.Search);
        }
        return this.goToClientSearchResults();
    }

    public async goToEntityTypeFilteredGridView(
        entityType: EntityType,
        spaceIdr: ISpaceIdentifier
    ) {
        const filteredView = this.filteredViewService.getNewFilteredView(
            DgZone.Module,
            { autoFilterEntityType: entityType }
        );
        const dgModule = FilteredViewsUtil.dgModuleFromModuleName(
            filteredView.ModuleName
        );
        this.setCurrentFilteredView(filteredView);
        return this.goToGridView(dgModule, null, {
            spaceIdr,
            showModuleFilterBar: true,
            keepFilteredView: true,
            isHierarchical: false,
        });
    }

    /** calls goToEntityDetails, with space information being inferred from the given entityIdentifier, unless explicitly specified */
    public async goToEntityDetailsByIdentifier(
        entityIdentifier: IEntityIdentifier,
        options: IGotoDetailsOptions = {}
    ) {
        this.log('goToEntityDetailsByIdentifier', entityIdentifier, options);
        if (
            !entityIdentifier ||
            !DataUtil.isAvailableForDetailsPage(entityIdentifier.ServerType)
        ) {
            return this.stateNoGo();
        }

        options.spaceIdr = SpaceIdentifier.fromEntity(entityIdentifier);

        const dgModule = DataUtil.getModuleFromServerType(
            entityIdentifier.ServerType
        );
        return this.goToEntityDetails(dgModule, entityIdentifier, options);
    }

    /** Either entityIdr or options.spaceIdr must be provided */
    public async goToEntityDetails(
        dgModule: DgModule,
        entityIdr: IEntityIdentifier,
        options: IGotoDetailsOrGridOptions = {}
    ): Promise<StateObject> {
        //console.trace()
        this.log(
            'goToEntityDetails',
            DgModule[dgModule],
            entityIdr,
            this.debugDetailsOptions(options)
        );

        const spaceIdr = entityIdr
            ? SpaceIdentifier.fromEntity(entityIdr)
            : options.spaceIdr;
        if (!spaceIdr) {
            this.warn('no entityIdr nor spaceIdr');
            return this.stateNoGo();
        }

        if (!options.reload && this.isCurrentEntity(entityIdr)) {
            this.log('goToEntityDetails alreadyLoaded');
        }
        this.adjustIsEntityFullPage(dgModule, options);
        const setFullPageBefore = this.currentStateNameContains(
            StateName.RelativeList
        );
        const showModuleFilterBar =
            options.showModuleFilterBar && !options.isEntityFullPage;

        const entityType =
            entityIdr instanceof EntityItem ? entityIdr.EntityType : null;
        const options2: IGotoDetailsGenericOptions = CoreUtil.merge(
            { spaceIdr, entityType },
            options
        );

        if (setFullPageBefore) {
            await this.setIsEntityFullPage(options.isEntityFullPage);
        }
        const result = await this.goToEntityDetailsGeneric(
            dgModule,
            entityIdr?.ReferenceId,
            options2
        );
        if (!setFullPageBefore) {
            await this.setIsEntityFullPage(options.isEntityFullPage);
        }
        if (showModuleFilterBar) {
            this.filterBarService.showFilterBar(DgZone.Module);
        }
        return result;
    }

    private async goToEntityDetailsWithSpecificDockingPaneTool(
        dgModule: DgModule,
        entityId: string,
        spaceIdr: ISpaceIdentifier,
        dockingPaneTool: EntityPanelTool,
        modelId?: string
    ) {
        const params = this.makeSpaceParameters(spaceIdr);
        NavigationService.setParamEntityLocalId(entityId, dgModule, params);

        if (modelId) {
            params[ParamKey.ModelId] = modelId;
        }

        this.setParamIsEntityFullPage(true, params);

        this.entityDockingPaneService.forceActiveTool(dockingPaneTool);

        const states = getEntityStateNames(dgModule);
        const stateName = states[EntityStateNamesIndex.Details];
        return this.stateGo(stateName, params);
    }

    private clearCurrentFilteredView(dgZone: DgZone, dgModule?: DgModule) {
        this.log('clearCurrentFilteredView', DgZone[dgZone]);
        this.filteredViewService.resetCurrentFilteredView(
            dgZone,
            { dgModule },
            { isReloading: true }
        );
    }
    private setCurrentFilteredView(fv: FilteredViewDto) {
        this.log('setCurrentFilteredView', fv, DgZone[fv?.DgZone]);
        if (!fv) {
            return;
        }
        this.filteredViewService.setCurrentFilteredView(fv, {
            isReloading: true,
        });
    }

    private prepareInModuleGoto(
        dgModule: DgModule,
        targetState: EntityStateNamesIndex,
        opt: IPrepareGotoOptions
    ) {
        const {
            isHierarchical,
            spaceIdr,
            isEntityFullPage,
            modelId,
            keepFilteredView,
        } = opt;

        // params

        const params = this.makeSpaceParameters(spaceIdr);
        this.log(
            'prepareInModuleGoto-setFilterParams',
            this.currentStateParams,
            params
        );
        if (
            this.isModuleChange(dgModule) &&
            !keepFilteredView &&
            !this._isGetUrlInsteadOfGo
        ) {
            this.clearCurrentFilteredView(DgZone.Module, dgModule);
        }
        this.setParamModuleFilter(
            params,
            this.filteredViewService.getCurrentViewId(DgZone.Module)
        );
        this.setParamPreFilter(params);
        params[ParamKey.EntityType] = opt.entityType;
        const isHierarchicalChanged = this.setParamIsHierarchical(
            isHierarchical,
            params
        );
        const isEntityFullPageChanged = this.setParamIsEntityFullPage(
            isEntityFullPage,
            params
        );
        if (modelId) {
            params[ParamKey.ModelId] = modelId;
        }

        // reload option

        const changes = { isHierarchicalChanged, isEntityFullPageChanged };
        this.log('prepareInModuleGoto-changes:', changes);

        const { stateNames, reloadStateIndex } = this.getStateToReload(
            dgModule,
            targetState,
            changes
        );
        const options: TransitionOptions = {};
        if (reloadStateIndex != undefined) {
            options.reload = stateNames[reloadStateIndex];
        } else if (opt.reload) {
            options.reload = true;
        }

        return { stateNames, params, options };
    }
    private getStateToReload(
        dgModule: DgModule,
        targetStateIndex: EntityStateNamesIndex,
        changes: {
            isHierarchicalChanged?: boolean;
            isEntityFullPageChanged?: boolean;
        } = {}
    ) {
        const stateNames = getEntityStateNames(dgModule);

        this.debug &&
            this.log('getStateToReload-in', {
                dgModule,
                targetStateIndex,
                changes,
            });

        let reloadStateIndex: EntityStateNamesIndex;

        let dbgCase = '-';
        /**
         * No change, no filter specified, reloadStateIndex = targetStateIndex
         */

        // We are browsing from one entity to another in the same module, probably by a nav-link, in this case
        // the reload state is the target state (either Details or List)
        if (
            reloadStateIndex == undefined &&
            !changes.isEntityFullPageChanged &&
            !changes.isHierarchicalChanged
        ) {
            reloadStateIndex = targetStateIndex;
            dbgCase = 'A';
        }

        this.debug &&
            this.log(
                'getStateToReload-out',
                'case ' + dbgCase,
                '(targetState,reloadState)',
                stateNames?.[targetStateIndex],
                stateNames?.[reloadStateIndex]
            );
        return { stateNames, reloadStateIndex };
    }

    private async goToEntityDetailsGeneric(
        dgModule: DgModule,
        entityId: string,
        opt: IGotoDetailsGenericOptions
    ) {
        if (this.isTransitionning) {
            this.logTrace('already isTransitionning !');
            return this.stateNoGo();
        }

        this.log('goToEntityDetailsGeneric', DgModule[dgModule], entityId, opt);

        const { entityObject } = opt;

        // we got entityObject, so it replaces entityId
        const hdData = entityObject?.HddData;
        if (hdData && entityId != hdData.DataReferenceId) {
            this.log(
                'goToEntityDetailsGeneric (entityObject)',
                entityId,
                this.debugHData(entityObject)
            );
            entityId = hdData.DataReferenceId;
        }

        // if we have an entityId, show its details or tab.
        // If we don't, goto the list. it will come back with its first entity's id
        const targetStateIndex = entityId
            ? EntityStateNamesIndex.Details
            : EntityStateNamesIndex.List;
        const { stateNames, params, options } = this.prepareInModuleGoto(
            dgModule,
            targetStateIndex,
            opt
        );
        NavigationService.setParamEntityLocalId(entityId, dgModule, params);

        const parentStateName = this.parentStateName;
        const alreadyInDashboardTab =
            parentStateName &&
            parentStateName == stateNames[EntityStateNamesIndex.Dashboard];

        let targetStateName = entityId
            ? opt.tabName //TODO (fbo) check the tabName for existence
                ? StringUtil.replaceSuffix(
                      stateNames[EntityStateNamesIndex.Details],
                      StateName.RelativeTabDetails,
                      `.${opt.tabName.replace(/^\./, '')}`
                  )
                : alreadyInDashboardTab
                ? '.'
                : stateNames[EntityStateNamesIndex.Details]
            : stateNames[EntityStateNamesIndex.List];
        this.log(
            'goToEntityDetailsGeneric tabName,targetStateName:',
            opt.tabName,
            targetStateName
        );

        if (alreadyInDashboardTab) {
            this.log('goToEntityDetailsGeneric-alreadyInDashboardTab');

            // store (or clear) locally to inform the list it is already the current
            NavigationService.setParamEntityLocalId(
                entityId,
                dgModule,
                this.currentStateParams
            );

            if (entityObject) {
                const resolvedTargetStateName =
                    targetStateName == '.'
                        ? this.currentStateName
                        : targetStateName;
                const entityIdr =
                    EntityIdentifier.fromIHasHddData(entityObject);
                const spaceIdr = SpaceIdentifier.fromEntity(entityIdr);
                const allowedStateName =
                    NavigationService.getAvailableEntityDetailsTabActiveState(
                        resolvedTargetStateName,
                        spaceIdr,
                        entityObject
                    );
                this.log(
                    'goToEntityDetailsGeneric (alreadyInDashboardTab,entityObject) allowedStateName,resolvedTargetStateName:',
                    allowedStateName,
                    resolvedTargetStateName
                );
                if (
                    allowedStateName != '.' &&
                    allowedStateName != resolvedTargetStateName
                ) {
                    targetStateName = allowedStateName;
                }
            }
        }
        return this.stateGo(
            targetStateName,
            CoreUtil.merge(opt.params, params),
            options
        );
    }

    public async goToEntityLineage(
        data: IHierarchicalData,
        tool = ImpactAnalysisTool.None
    ) {
        await this.goToEntityDetailsByIdentifier(
            EntityIdentifier.fromHdd(data.Data),
            {
                tabName: StateName.RelativeTabImpactAnalysis,
                params: {
                    [Constants.Nav.ParamKey.ImpactAnalysisTool]: (
                        tool || ImpactAnalysisTool.None
                    ).toString(),
                },
            }
        );
    }

    //#endregion

    //#region dataprocessing  (goto)

    private async goToDataProcessingMapping(
        dataProcessingId: string,
        spaceIdr: ISpaceIdentifier
    ) {
        const params = this.makeSpaceParameters(spaceIdr);
        NavigationService.setParamEntityLocalId(
            dataProcessingId,
            DgModule.Processing,
            params
        );
        return this.stateGo(StateName.DataProcessingMapping, params);
    }

    //#endregion dataprocessing

    //#region modeler  (get, set, goto)

    /** @deprecated Use viewTypeService instead */
    public getTechnicalOrDisplayName(obj: IHasTechnicalName) {
        return this.viewTypeService.getTechnicalOrDisplayName(obj);
    }

    public getModelerSettingsStateName() {
        return StateName.ModelerModelSettingsTab;
    }

    public setCurrentModelData(modelDescriptor: HierarchyDataDescriptor) {
        if (modelDescriptor?.DataServerType != ServerType.Model) {
            return;
        }
        this._currentModelId = modelDescriptor.DataReferenceId;
        this._currentModelType = modelDescriptor.SubType as ModelType;
    }
    private clearCurrentModelIdentifier() {
        this._currentModelId = this._currentModelType = null;
    }

    public async goToModelerKeys(
        spaceIdr: ISpaceIdentifier,
        modelId: string,
        state: string
    ) {
        const params = this.makeSpaceParameters(spaceIdr);
        NavigationService.setParamEntityLocalId(
            modelId,
            DgModule.Catalog,
            params
        );
        return this.stateGo(state, params);
    }

    private async goToModelerSettings(
        spaceIdr: ISpaceIdentifier,
        modelId: string
    ) {
        const params = this.makeSpaceParameters(spaceIdr);
        params[ParamKey.ModelId] = modelId;

        const stateName = this.getModelerSettingsStateName();
        return this.stateGo(stateName, params);
    }

    public async goToDiagram(
        diagram: IEntityIdentifier,
        diagElementId?: string
    ) {
        this.log('goToDiagram', diagram, diagElementId);
        const params = this.makeSpaceParameters(
            SpaceIdentifier.fromEntity(diagram)
        );
        params[ParamKey.DiagId] = diagram.ReferenceId;
        params[ParamKey.DiagElementId] = diagElementId;
        return this.stateGo(StateName.GenericDiagram, params);
    }

    //#endregion modeler

    //#region HierarchicalData  (get, goto)

    public async getUrlToGoToWithHierarchicalData(
        hData: IHierarchicalData,
        destination: NavigateTo,
        withEntityFullPage?: boolean
    ): Promise<string> {
        this._isGetUrlInsteadOfGo = true;
        try {
            const result = await this.goToWithHierarchicalDataInternal(hData, {
                destination,
                isEntityFullPage: withEntityFullPage,
            });
            return result as any as string;
        } finally {
            this._isGetUrlInsteadOfGo = false;
        }
    }

    public async goToWithHierarchicalData(
        hData: IHierarchicalData,
        opt?: IGotoWithHierarchicalData
    ) {
        this.log('goToWithHierarchicalData', hData, opt);
        const opti = {
            destination: opt?.destination ?? NavigateTo.Object,
            isHierarchical: opt?.isFromHierarchicalView || undefined,
            isEntityFullPage: opt?.withEntityFullPage,
        };
        const isSuccess = await this.goToWithHierarchicalDataInternal(
            hData,
            opti
        );
        if (opt?.isFromBreadcrumb) {
            this.functionalLogService.logFunctionalAction(
                'NAV_BREADCRUMB',
                CrudOperation.R
            );
        }
        return isSuccess;
    }

    public async gotoCurrentStateWithHierarchyDataDescriptor(
        hdd: HierarchyDataDescriptor
    ) {
        if (!hdd) {
            return this.stateNoGo();
        }
        const params = {
            [Constants.Nav.ParamKey.PropId]: hdd.DataReferenceId,
            [Constants.Nav.ParamKey.PropType]: hdd.DataTypeName,
        };
        return this.stateGo('.', params);
    }

    private async goToWithHierarchicalDataInternal(
        hData: IHierarchicalData,
        opt?: IGotoWithHierarchicalDataInternal
    ): Promise<StateObject> {
        const destination = opt?.destination ?? NavigateTo.Object;
        let navResult: StateObject;
        switch (destination) {
            case NavigateTo.Lineage:
                navResult = await this.goToLineageWithHierarchicalData(hData);
                break;

            default:
                opt.tabName = StringUtil.getSuffix(
                    NavigateTo[destination],
                    NavigationService.navToTabPrefix
                );
                navResult = await this.goToObjectWithHierarchicalData(
                    hData,
                    opt
                );
                break;
        }

        if (typeof navResult == 'string') {
            // handle the _isGetUrlInsteadOfGo case
            return navResult;
        }

        if (!navResult) {
            return null;
        }

        if (navResult && opt?.broadcastStateChangeSuccess) {
            this.log('broadcastStateChangeSuccess', navResult?.name);
            this.appEventsService.notifyStateChanged(navResult?.name);
        }

        return navResult;
    }

    private async goToLineageWithHierarchicalData(hdata: IHierarchicalData) {
        const entityIdr = EntityIdentifier.fromHdd(hdata.Data);
        const spaceIdr = SpaceIdentifier.fromEntity(entityIdr);
        const tool = ImpactAnalysisTool.Explorer;
        return this.goToImpactAnalysis(spaceIdr, entityIdr, tool);
    }

    private async goToObjectWithHierarchicalData(
        hdata: IHierarchicalData,
        opt?: IGotoWithHierarchicalDataInternal
    ) {
        const hdd = hdata.Data;
        const dataTypeName = hdd.DataTypeName;
        const dataServerType: ServerType = ServerType[dataTypeName];
        const dataReferenceId = hdd.DataReferenceId;
        const versionId = hdd.VersionId;

        this.log(
            'goToObjectWithHierarchicalData',
            hdata,
            dataTypeName,
            dataReferenceId,
            versionId
        );

        //#region cases for which a spaceDataDescriptor is NOT mandatory

        switch (dataServerType) {
            case ServerType.Organization:
            case ServerType.Project:
                return this.goToSpaceHome(
                    new SpaceIdentifier(dataReferenceId, versionId)
                );

            case ServerType.FilteredView: {
                this.functionalLogService.logFunctionalAction(
                    'FILTER_SPOTLIGHT',
                    CrudOperation.R
                );
                const dgModule = DgModule[hdata.FilteredViewData.ModuleName];
                if (hdata.FilteredViewData.ModuleName == 'MainSearch') {
                    return this.loadFilterAndGoToSearch(
                        hdata.FilteredViewData.FilteredViewGuid
                    );
                }
                return this.loadFilterAndGoToEntityDetails(
                    dgModule,
                    hdata.FilteredViewData.FilteredViewGuid,
                    versionId
                );
            }
        }

        //#endregion

        //#region cases for which a spaceDataDescriptor IS mandatory

        let spaceIdr: ISpaceIdentifier;
        const spaceDataDescriptor = HddUtil.getParentDataDescriptorByType(
            hdata,
            DataUtil.getPossibleParentSpaceDataTypes(dataServerType)
        );
        if (spaceDataDescriptor) {
            spaceIdr = new SpaceIdentifier(
                spaceDataDescriptor?.DataReferenceId,
                versionId
            );
        } else {
            if (!hdata.Parents?.length) {
                this.warn(
                    'goToObjectWithHierarchicalData: hdata has no parents',
                    hdata
                );
            }

            if (versionId) {
                spaceIdr = SpaceIdentifier.fromDataIdentifier(hdd, versionId);
            } else {
                this.warn(
                    'goToObjectWithHierarchicalData: missing spaceDataDescriptor',
                    hdata,
                    dataTypeName
                );
                return this.stateNoGo();
            }
        }

        const dgModule = DataUtil.getModuleFromServerType(dataServerType);
        const isCatalog =
            dgModule == DgModule.Catalog ||
            ModelerDataUtil.canBePartOfModel(dataServerType);
        const modelDataDescriptor =
            isCatalog &&
            HddUtil.getParentDataDescriptorByType(hdata, [ServerType.Model]);
        const modelId = modelDataDescriptor?.DataReferenceId;

        this.adjustIsEntityFullPage(dgModule, opt);

        //handle the case that the tabName is not set when clicking on UsageField/UsageComponent link from the Fields tab of a softwareElement
        if (
            (hdd.EntityType == EntityType.UsageField ||
                hdd.EntityType == EntityType.UsageComponent) &&
            opt &&
            !opt.tabName
        ) {
            opt.tabName = 'details';
        }

        const getOptions = (
            noModelId = false,
            noIsHierarchical = false
        ): IGotoDetailsGenericOptions => ({
            spaceIdr,
            modelId: noModelId ? undefined : modelId,
            isHierarchical: noIsHierarchical ? undefined : opt?.isHierarchical,
            isEntityFullPage: opt?.isEntityFullPage,
            tabName: opt?.tabName,
            entityType: hdd.EntityType,
        });

        const ST = ServerType;
        switch (dataServerType) {
            case ST.Column:
            case ST.Container:
            case ST.Table:
            case ST.Model:
            case ST.Property:
            case ST.SoftwareElement:
            case ST.DataProcessing:
                if (dgModule == DgModule.Catalog) {
                    const dataDescriptor =
                        HddUtil.getParentDataDescriptorByType(hdata, [
                            dataServerType,
                        ]);
                    if (!dataDescriptor || !modelDataDescriptor) {
                        break;
                    }
                }
                return this.goToEntityDetailsGeneric(
                    dgModule,
                    dataReferenceId,
                    getOptions()
                );

            case ST.DataProcessingItem: {
                const dataProcessingDataDescriptor =
                    HddUtil.getParentDataDescriptorByType(hdata, [
                        ST.DataProcessing,
                    ]);
                if (!dataProcessingDataDescriptor) break;
                return this.goToDataProcessingMapping(
                    dataProcessingDataDescriptor.DataReferenceId,
                    spaceIdr
                );
            }

            case ST.PrimaryKey:
                if (!modelDataDescriptor) break;
                return this.goToModelerKeys(
                    spaceIdr,
                    modelId,
                    StateName.ModelerModelPrimaryKeysTab
                );

            case ST.ForeignKey:
                if (!modelDataDescriptor) break;
                return this.goToModelerKeys(
                    spaceIdr,
                    modelId,
                    StateName.ModelerModelForeignKeysTab
                );

            case ST.DataType:
            case ST.DataTypeMappingItem:
                if (!modelDataDescriptor) {
                    break;
                }
                return this.goToModelerSettings(spaceIdr, modelId);

            case ST.ObjectTask:
            case ST.ObjectCommentary: {
                const objectDataDescriptor =
                    HddUtil.getParentDataDescriptorByType(hdata, [
                        ST.DataProcessing,
                        ST.Property,
                        ST.SoftwareElement,
                        ST.Model,
                        ST.Container,
                        ST.Table,
                        ST.Column,
                        ST.Project,
                        ST.Organization,
                    ]);
                if (!objectDataDescriptor) {
                    break;
                }

                const objectServerType = ST[objectDataDescriptor.DataTypeName];
                const objectId = objectDataDescriptor.DataReferenceId;
                switch (objectServerType) {
                    case ST.Organization:
                    case ST.Project:
                        if (!versionId && objectServerType == ST.Project) {
                            console.error('no versionId');
                            return this.stateNoGo();
                        }
                        return dataServerType == ST.ObjectTask
                            ? this.goToClientTasks()
                            : this.stateNoGo();

                    case ST.Model:
                    case ST.Container:
                    case ST.Table:
                    case ST.Column:
                    case ST.Property:
                    case ST.SoftwareElement:
                    case ST.DataProcessing: {
                        const dockingPaneTool =
                            dataServerType == ST.ObjectTask
                                ? EntityPanelTool.Tasks
                                : EntityPanelTool.Commentaries;
                        const targetDgModule =
                            DataUtil.getModuleFromServerType(objectServerType);
                        return this.goToEntityDetailsWithSpecificDockingPaneTool(
                            targetDgModule,
                            objectId,
                            spaceIdr,
                            dockingPaneTool,
                            modelId
                        );
                    }
                }
                break;
            }

            case ST.LocalSynonym: {
                const dataDescriptor = HddUtil.getParentDataDescriptorByType(
                    hdata,
                    [ST.Property]
                );
                if (!dataDescriptor) {
                    break;
                }
                return this.goToEntityDetailsGeneric(
                    dgModule,
                    dataDescriptor.DataReferenceId,
                    getOptions()
                );
            }

            case ST.Diagram:
                return this.goToDiagram(EntityIdentifier.fromHdd(hdd));

            case ST.DiagramNode: {
                const diagramHdd = HddUtil.getFirstHddByType(hdata, ST.Diagram);
                return this.goToDiagram(
                    EntityIdentifier.fromHdd(diagramHdd),
                    hdd.ReferenceId
                );
            }

            default:
                this.warn(
                    'goToObjectWithHierarchicalData: unhandled case',
                    hdata,
                    opt
                );
                return this.stateNoGo();
        }

        //#endregion

        this.warn('goToObjectWithHierarchicalData: missing data');
        return this.stateNoGo();
    }

    //#endregion HierarchicalData

    //#region ImpactAnalysis (goto)

    public async goToEntityImpactAnalysis(data: IHierarchicalData) {
        await this.goToEntityDetailsByIdentifier(
            EntityIdentifier.fromHdd(data.Data),
            {
                tabName: StateName.RelativeTabImpactAnalysis,
                params: {
                    [Constants.Nav.ParamKey.ImpactAnalysisTool]:
                        ImpactAnalysisTool.Explorer?.toString(),
                },
            }
        );
    }

    public async goToImpactAnalysis(
        spaceIdr: ISpaceIdentifier,
        entityIdr?: IEntityIdentifier,
        analysisTool?: ImpactAnalysisTool
    ) {
        this.log('goToImpactAnalysis', spaceIdr, entityIdr, analysisTool);
        if (entityIdr) {
            spaceIdr = SpaceIdentifier.fromEntity(entityIdr);
        }
        const params = {} as IStateParams;
        params[ParamKey.PropId] = entityIdr?.ReferenceId || null;
        params[ParamKey.PropType] = ServerType[entityIdr?.ServerType] || null;
        params[ParamKey.ImpactAnalysisTool] = analysisTool;
        params[ParamKey.EntityType] = entityIdr?.entityType;

        const stateName =
            analysisTool == ImpactAnalysisTool.Explorer
                ? StateName.ImpactAnalysisExploratory
                : StateName.ImpactAnalysisLineage;
        return this.goToSpaceState(stateName, spaceIdr, params);
    }

    //#endregion

    //#region Diagrams (goto)

    public async goToDiagrams(spaceIdr: ISpaceIdentifier) {
        this.log('goToDiagrams', spaceIdr);
        return this.goToSpaceState(StateName.DiagramsGlobalList, spaceIdr);
    }

    //#endregion

    //#region Campaigns (goto)
    public async goToCampaigns(spaceIdr: ISpaceIdentifier) {
        this.log('goToCampaigns', spaceIdr);
        return this.goToSpaceState(StateName.Campaigns, spaceIdr);
    }

    public async goToCampaignDetails(
        campaignId: string,
        spaceIdr: ISpaceIdentifier
    ) {
        const params = this.makeSpaceParameters(spaceIdr);
        params[ParamKey.CampaignId] = campaignId;
        return this.stateGo(StateName.CampaignDetailsInfos, params);
    }
    //#endregion Campaigns

    //#region datamap (get, goto)

    public async gotoModuleView(
        dgModule: DgModule,
        moduleView: ModuleView,
        spaceIdr: ISpaceIdentifier
    ) {
        if (!spaceIdr) {
            return this.stateNoGo();
        }
        const opt = { reload: true };
        switch (moduleView) {
            case ModuleView.entityList:
                return this.goToEntityList(dgModule, spaceIdr, opt);
            case ModuleView.entityGrid:
                return this.goToGridView(dgModule, spaceIdr, opt);
            case ModuleView.datamap:
                return this.goToDataMapView(dgModule, spaceIdr, opt);
            default:
                return this.stateNoGo();
        }
    }

    private async goToDataMapView(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        opt?: {
            reload?: boolean;
        }
    ) {
        const { stateNames, params, options } = this.prepareInModuleGoto(
            dgModule,
            EntityStateNamesIndex.Datamap,
            {
                spaceIdr,
                reload: opt?.reload,
            }
        );

        const stateName = stateNames[EntityStateNamesIndex.Datamap];
        return this.stateGo(stateName, params, options);
    }

    //#endregion

    //#region clientspace (goto)

    public async goToClientSpacesList() {
        return this.stateGo(StateName.ClientSpacesList);
    }

    public async goToSpecificClientSpacesList(clientId: string) {
        const params: IStateParams = clientId
            ? { [ParamKey.ClientId]: clientId }
            : undefined;
        return this.stateGo(StateName.ClientSpacesList, params);
    }

    public async goToClientSearchResults() {
        return this.stateGo(StateName.ClientSearchResults);
    }

    private async goToClientTasks() {
        this.entityDockingPaneService.forceActiveTool(EntityPanelTool.Tasks);
        return this.stateGo(StateName.ClientTasks);
    }

    public async goToClientDashboard(
        forceReload = false,
        dashboardId = null,
        isFromSideBar = false
    ) {
        if (
            isFromSideBar &&
            this.currentStateName ==
                this.parentStateName + StateName.RelativeDashboard
        ) {
            return this.stateNoGo();
        }

        const params: IStateParams = { [ParamKey.DashboardId]: dashboardId };
        const options = forceReload ? { reload: true } : undefined;
        return this.stateGo(StateName.ClientDashboard, params, options);
    }

    public async goToClientMain(clientId?: string) {
        const params = {} as IStateParams;
        if (clientId) {
            params[ParamKey.ClientId] = clientId;
        }
        return this.stateGo(StateName.Client, params);
    }

    //#endregion clientspace

    //#region misc  (get, set, goto)

    private getCurrentSpaceIdr() {
        return NavigationService.getSpaceIdrFromParams(this.currentStateParams);
    }

    public getNavTo(navToLineage = false, navToTabName?: string) {
        let navTo = navToLineage ? NavigateTo.Lineage : NavigateTo.Object;
        if (navToTabName && navTo == NavigateTo.Object) {
            const navToTab =
                NavigateTo[NavigationService.navToTabPrefix + navToTabName];
            if (navToTab) {
                navTo = navToTab;
            }
        }
        //this.log('getNavTo', navTo, navToLineage, navToHome, navToTabName)
        return navTo;
    }

    public logoutOnChangeClient() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.clearCurrentContext();
    }

    /** Clears :
     * - currentSpace,
     * - lastSpace,
     * - currentModelId,
     * - currentModelType,
     * - LineageCache,
     * - ExploratoryCache */
    public clearCurrentContext() {
        this.clearCurrentModelIdentifier();
        // calls clearLineageCache, clearExploratoryCache on ImpactAnalysisService
        this.currentSpaceService._clearCurrentAndLastSpace();
    }

    public async goToRelativeTab(relativeStateName: string) {
        const targetStateName = this.parentStateName + relativeStateName;
        if (targetStateName == this.currentStateName) {
            return this.stateNoGo();
        }
        return this.stateGo(targetStateName);
    }

    public async goToMainLogin() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        return this.stateGo(StateName.AccountLogin);
    }

    public async goLogout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.clearCurrentContext();
        this.clearMemoState();
        return this.stateGo(StateName.AccountLogin);
    }

    public async goExternalLogout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.clearCurrentContext();
        return this.stateGo(StateName.AccountExternalLogout);
    }

    public async goLogoutSessionTimeout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.setMemoState(
            this.uiRouterGlobals.successfulTransitions.peekHead(),
            true
        );
        this.clearCurrentContext();
        this._isUserSessionTimeout = true;
        return this.stateGo(StateName.AccountLogin);
    }

    public clearIsUserSessionTimeout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this._isUserSessionTimeout = false;
    }
    /**
     * @deprecated
     */
    public async goToMainPrivateIndex() {
        return this.stateGo(StateName.MainPrivateIndex);
    }

    // Used mainly for login error in SAML-enabled scenario
    public async goToMainError(
        errorMessage: string,
        showLoginScreen: boolean = true
    ) {
        const params = {
            [ParamKey.ErrorMessage]: errorMessage,
            [ParamKey.AccessLevel]: showLoginScreen ? 'login' : 'nologin',
        };
        return this.stateGo(StateName.MainError, params);
    }

    public async goToExpiredTrialError() {
        return this.stateGo(StateName.MainExpiredLicense);
    }

    public async goToMainPrivateError(errorMessage?: string) {
        const params = { [ParamKey.ErrorMessage]: errorMessage };
        return this.stateGo(StateName.MainPrivateError, params);
    }

    /**
     * Redirect the user based on its navigation settings
     * @param space: the space used if needed when redirected, by default we pass the user's favorite space
     */
    public async goToLandingPage(space: Space) {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.navigation.category,
            userSettingsValues.navigation.routes.landingPage
        );
        const identifier = this.getLandingPageIdentifier(res);
        const spaceIdr = new SpaceIdentifier(
            space.ReferenceId,
            space.VersionId
        );
        return this.goTo(identifier, spaceIdr);
    }
    private getLandingPageIdentifier(res: GetUserSettingValueResult) {
        const landingPage = LandingPageType[res?.Value];
        switch (landingPage) {
            case LandingPageType.WorkspaceHome:
                return ViewIdentifier.SpaceHome;
            case LandingPageType.Dashboard:
                return ViewIdentifier.ClientDashboard;
            case LandingPageType.Tasks:
                return ViewIdentifier.ClientTasks;
            default:
                return ViewIdentifier.SpaceHome;
        }
    }

    public async gotoMemoStateOrClientMain() {
        if (this.hasMemoState()) {
            return this.goToMemoState();
        }
        const clientId = this.featureFlagService.isFeatureEnabled(
            'ENABLE_AUTH_V2'
        )
            ? this.currentUserService.clientId
            : this.appDataService.clientId;
        const result = await this.goToClientMain(clientId);
        this.clearMemoState();
        return result;
    }

    public async goToObjectNotFound() {
        return this.stateGo(StateName.ClientError);
    }

    public async goToUserProfile() {
        return this.stateGo(StateName.UserProfile);
    }

    public async goToUserSettingsSearch() {
        return this.stateGo(StateName.UserSettings, {
            [ParamKey.UserSettingsViewIdentifier]: 'Search',
        });
    }

    //#endregion

    //#region open url in new tab

    /***
     * @deprecated no cookie is needed with authV2
     */
    public setCookieNavNewTab(url: string, keepHash = false) {
        this.log('setCookieNavNewTab');
        const dc = CookieNavNewTab;
        if (url && !keepHash && url.startsWith('#')) {
            url = url.slice(1);
        }
        this.cookieService.set(dc.CookieKey, dc.makeCookieValue(url), {
            expires: dc.makeCookieExpires(),
            secure: true,
        });
    }

    /***
     * @deprecated no cookie is needed with authV2
     */
    public clearCookieNavNewTab() {
        this.log('clearCookieNavNewTab');
        this.cookieService.delete(CookieNavNewTab.CookieKey);
    }

    /***
     * @deprecated no cookie is needed with authV2
     */
    public getUrlInCookieNavNewTab(noDecode = false) {
        const dc = CookieNavNewTab;
        const cookieString = this.cookieService.get(dc.CookieKey);
        let url = dc.extractUrlFromCookie(cookieString);
        if (!noDecode) {
            url = decodeURIComponent(url);
        }
        this.log('getUrlInCookieNavNewTab', noDecode, url);
        return url;
    }

    //#endregion open url in new tab

    //#region debug

    private debugHData(e: IHasHddData) {
        if (!this.debug) {
            return;
        }
        return [
            '{',
            e instanceof EntityItem ? '(EntityItem)' : '',
            ServerType[e?.HddData?.DataServerType],
            e?.HddData?.DataReferenceId,
            '}',
        ].join(' ');
    }
    private debugDetailsOptions(options: IGotoDetailsOptions) {
        if (!this.debug) {
            return;
        }
        if (!this.debugDetailed) {
            return options;
        }
        const replacer = (k: string, v: any) =>
            k == 'entityObject' ? this.debugHData(v) : v;
        return JSON.stringify(options, replacer, 2);
    }

    //#endregion
}

//#region internal types

// TODO (fbo) manage multiple urls, and extend expires on each url added
/**
 * @deprecated cookies will be removed with AuthV2
 */
class CookieNavNewTab {
    public static readonly CookieKey = 'DG_COOKIE_NAVNEWTAB';
    /** number of seconds during which the user is automatically authorized,
     *  thus allowing to skip the login page on the new opened tab */
    public static readonly authorizedDelaySeconds = 10;
    public static makeCookieValue(url: string) {
        return JSON.stringify(new CookieNavNewTab(url));
    }
    public static makeCookieExpires() {
        return moment()
            .add(CookieNavNewTab.authorizedDelaySeconds, 'second')
            .toDate();
    }
    public static extractUrlFromCookie(cookieString: string) {
        try {
            const cookie: CookieNavNewTab =
                cookieString && JSON.parse(cookieString);
            return cookie?.url || '';
        } catch (e) {
            return '';
        }
    }
    constructor(public url: string) {}
}

type IStateParams = RawParams;

interface IPrepareGotoOptions extends IGotoEntityViewOptions {
    spaceIdr?: ISpaceIdentifier;
    entityType?: EntityType;
}

interface IGotoFullpageOptions {
    isEntityFullPage?: boolean;
}

interface IGotoWithHierarchicalDataInternal extends IGotoFullpageOptions {
    destination: NavigateTo;
    isHierarchical?: boolean;
    tabName?: string;
    broadcastStateChangeSuccess?: boolean;
}
interface IUiTab {
    isStateNameRelative?: boolean;
    tabStateName?: string;
}

//#endregion

//#region exported types

export interface IGotoEntityListOptions extends IGotoEntityViewOptions {}
export interface IGotoDetailsGenericOptions extends IGotoDetailsOptions {
    spaceIdr: ISpaceIdentifier;
    entityType?: EntityType;
}
export interface IGotoDetailsOptions extends IGotoDetailsOrGridOptions {
    entityObject?: IHasHddData | EntityItem;
    tabName?: string;
    /**
     * Additional params to add to state redirection
     * Internal ones will not be replaced/overridden
     */
    params?: { [key: string]: string };
}
export interface IGotoDetailsOrGridOptions extends IGotoEntityViewOptions {
    showModuleFilterBar?: boolean;
}
export interface IGotoEntityViewOptions extends IGotoModuleViewOptions {
    spaceIdr?: ISpaceIdentifier;
    modelId?: string;
    /** when defined, sets the full page mode (show/hide the list and the header) */
    isEntityFullPage?: boolean;
    /** Goto is triggered after a space or version change: we must reload the list */
    isAfterSpaceOrVersionChange?: boolean;
}
interface IGotoModuleViewOptions {
    /** when defined, sets the list or grid in hierachical or flat mode.*/
    isHierarchical?: boolean;
    /** true will force a reload of the view */
    reload?: boolean;
    /** if falsy and module changing, the current filteredview will be cleared */
    keepFilteredView?: boolean;
}

export interface IGotoSpaceOptions {
    spaceIdr: ISpaceIdentifier;
}

export interface IGotoWithHierarchicalData {
    destination?: NavigateTo;
    isFromHierarchicalView?: boolean;
    withEntityFullPage?: boolean;
    isFromBreadcrumb?: boolean;
    broadcastStateChangeSuccess?: boolean;
}

export enum NavigateTo {
    Object = 0,
    Lineage,
    ObjectTab_diagrams,
}

export enum ModuleView {
    none = 0,
    entityList,
    entityGrid,
    datamap,
}

//#endregion
