import { saveAs } from 'file-saver';
import { formConfigs } from './connection-form/form-configs/connection-form-configs';
import { ConnectivityService } from './connectivity.service';
import { Injectable } from '@angular/core';
import { SecurityService } from '../services/security.service';
import {
    Connector,
    ConnectorDownloadApiService,
    ConnectorPackage,
} from '@datagalaxy/webclient/connectivity/data-access';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';

export const IS_OTHER_USER_PAT = 'otherUserPat';

@Injectable({ providedIn: 'root' })
export class ConnectorService {
    constructor(
        private connectorDownloadApiService: ConnectorDownloadApiService,
        private connectivityService: ConnectivityService,
        private securityService: SecurityService,
        private functionalLogService: FunctionalLogService
    ) {}

    public async getConnectorPackages(): Promise<ConnectorPackage[]> {
        return await this.connectorDownloadApiService.getConnectorPackages();
    }

    public async downloadConnectorDesktop(arch: '64' | '32') {
        this.functionalLogService.logFunctionalAction(
            'DOWNLOAD_CONNECTOR_DESTK',
            CrudOperation.A,
            CrudActionType.Download
        );
        const apiResult =
            await this.connectorDownloadApiService.downloadConnectorDesktop(
                arch
            );
        return saveAs(
            new Blob([apiResult.contents], {
                type: 'application/x-zip-compressed',
            }),
            apiResult.filename
        );
    }

    public getOnlineConnectorImageUrl(connectorName: string) {
        return this.connectivityService.getConnectorImageUrl(connectorName);
    }

    public async getConnectionsCount(): Promise<number> {
        return (await this.connectivityService.getConnections()).connections
            .length;
    }

    /**
     * Requests Connectivity API to fetch the list of existing Online Connector Plugins.
     * @returns List of Online Connector Plugins that have a registered form in `connection-form-configs.ts`
     * or all Plugins if Client Access Flag EnableOnlineConnectionTextEditor is true
     */
    public async getConnectorsOnline(): Promise<Connector[]> {
        const result = await this.connectivityService.getConnectors();
        const registeredConnectorForms = Object.keys(formConfigs);
        return result.connectors.filter(
            (c) =>
                registeredConnectorForms.includes(c.name) ||
                this.securityService.isOnlineConnectorTextEditorEnabled()
        );
    }
}
