import {
    trigger,
    transition,
    animate,
    style,
    keyframes,
} from '@angular/animations';

/** Create a fadeOut animation on enter */
export const fadeOutAtEnterAnimation = () =>
    trigger('fadeOut', [
        transition(':enter', [
            animate('2s', keyframes([visibleStyle, hiddenStyle])),
        ]),
    ]);

/** Create a fadeIn animation on enter and a fadeOut animation on leave */
export const fadeInFadeOutAnimation = (duration = '.3s') =>
    trigger('fadeInFadeOut', [
        transition(':enter', [
            animate(duration, keyframes([hiddenStyle, visibleStyle])),
        ]),
        transition(':leave', [
            animate(duration, keyframes([visibleStyle, hiddenStyle])),
        ]),
    ]);

const hiddenStyle = style({
    visibility: 'hidden',
    opacity: 0,
});
const visibleStyle = style({
    visibility: 'visible',
    opacity: 1,
});
