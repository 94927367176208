import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IListOption } from '..';
import { BaseService } from '../base';

/**
 * ## Role
 * Manage the global burger menu
 *
 * - setup burger menu
 * - close burger menu
 *
 * #Archi-service-leaf (ie Must not reference any app service)
 */

@Injectable({ providedIn: 'root' })
export class BurgerMenuService extends BaseService {
    public get visible() {
        return this.onBurgerMenu.value.open;
    }

    public get onBurgerMenu$() {
        return this.onBurgerMenu.asObservable();
    }
    private readonly onBurgerMenu = new BehaviorSubject<IBurgerMenuData>({
        open: false,
    });

    constructor() {
        super();
    }

    public setupBurgerMenu(configuration: IBurgerMenuConfiguration) {
        this.onBurgerMenu.next({
            open: true,
            configuration,
        });
    }
    public close() {
        this.onBurgerMenu.next({ open: false });
    }
}

export interface IBurgerMenuData {
    open: boolean;
    configuration?: IBurgerMenuConfiguration;
}

export interface IBurgerMenuConfiguration {
    options?: IListOption[];
    position?: { top: number; left: number };
}
