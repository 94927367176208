import {
    BaseFieldSelectAdapter,
    IFieldSelectAdapterWithId,
} from '@datagalaxy/core-ui';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';

export class ProjectVersionFieldSelectAdapter
    extends BaseFieldSelectAdapter<ProjectVersion>
    implements IFieldSelectAdapterWithId<ProjectVersion, string>
{
    constructor(onSelectionChange?: (selected: ProjectVersion) => void) {
        super(onSelectionChange);
    }

    public init(options: ProjectVersion[], current?: ProjectVersion) {
        return super.initInternal(options, current);
    }

    public getText(pv: ProjectVersion) {
        return pv.VersionName;
    }
    public getId(pv: ProjectVersion) {
        return pv.ProjectVersionId;
    }
    public selectById(projectVersionId: string) {
        projectVersionId && super.selectByIdInternal(projectVersionId);
    }
}
