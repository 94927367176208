import {
    AfterContentInit,
    Directive,
    ElementRef,
    Inject,
    InjectionToken,
    Input,
    Optional,
} from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

export type DataTestIdValue<T> = HasValue<T> | ToString;

interface HasValue<T> {
    value: T;
}

interface ToString {
    toString(): string;
}

export interface DataTestIdConfig {
    disabled?: boolean;
}

export const DATA_TEST_ID_CONFIG = new InjectionToken<DataTestIdConfig>(
    'DATA_TEST_ID_CONFIG'
);

/**
 * Add data-testid attribute to an element
 * @example <button dxyDataTestId="save-button">
 * // will generate => <button data-testid="save-button">
 * @example <button [dxyDataTestId]="buttonId">
 * // will generate => <button data-testid="[buttonId]">
 * @example <button dxyDataTestId [dataTestValue]="value">
 * // will generate => <button data-testid="button-[value]">
 */
@Directive({
    standalone: true,
    selector: '[dxyDataTestId]',
})
export class DxyDataTestIdDirective<TValue>
    extends DxyBaseDirective
    implements AfterContentInit
{
    public static readonly E2EID = 'data-testid';

    /**
     * When true, data-testid attribute is not added to the dom
     * Needs to be set at application initialisation
     */
    private static disabled = false;

    @Input('dxyDataTestId') dataTestId?: string | (() => string);

    /** value to construct dataTestId attribute
     *  If provided, data-testid is [element-node-name]-[value]
     * */
    @Input('dxyDataTestIdValue') value?: DataTestIdValue<TValue>;

    /**
     * css selector targeting a sub element.
     * If provided, data-testid will be added to this element.
     * If the sub element is not found, we fallback to the current element
     */
    @Input('dxyDataTestIdTarget') target?: string;

    /** Sets a *data-testid* attribute on the given element with the given value.
     * Returns true if the attribute has been set with a value. */
    public static setE2EId(element: Element, e2eId: string | undefined): void {
        if (DxyDataTestIdDirective.disabled || !e2eId || !element) {
            return;
        }
        element.setAttribute(DxyDataTestIdDirective.E2EID, e2eId);
    }

    private get el() {
        return (
            (this.target &&
                this.elementRef.nativeElement.querySelector<HTMLElement>(
                    this.target
                )) ||
            this.elementRef.nativeElement
        );
    }

    private get testIdString() {
        if (!this.value) {
            return typeof this.dataTestId === 'function'
                ? this.dataTestId()
                : this.dataTestId;
        }

        if (!this.dataTestId) {
            return this.el.nodeName;
        }

        const value = this.value;
        const stringValue = String('value' in value ?? value.toString?.());

        return this.getIdString(`${this.el.nodeName}-${stringValue}`);
    }

    constructor(
        private elementRef: ElementRef<HTMLInputElement>,
        @Optional()
        @Inject(DATA_TEST_ID_CONFIG)
        private config?: DataTestIdConfig
    ) {
        super();
        DxyDataTestIdDirective.disabled = this.config?.disabled || false;
    }

    ngAfterContentInit() {
        DxyDataTestIdDirective.setE2EId(this.el, this.testIdString);
    }

    /** returns 'this-is-a-string' when given ' this Is A String ' */
    private getIdString(text: string) {
        return text
            ?.trim()
            .replace(/[\s\n]+/g, '-')
            .toLowerCase();
    }
}
