import { Inject, Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
    ApiServiceError,
    ApiServiceErrorType,
    BACKEND_API_CONFIG,
    IApiConfig,
} from '../types';
import { BaseServiceResult, IS_BACKEND_API } from '../backend';

/**
 * ## Role
 * Intercept all HttpErrorResponse to handle them and return an ApiServiceError
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    protected get baseUrl() {
        return this.config.baseUrl;
    }

    constructor(@Inject(BACKEND_API_CONFIG) private config: IApiConfig) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) =>
                req.context.get(IS_BACKEND_API)
                    ? this.handleUnsuccessfulResponse(req, event)
                    : event
            ),
            catchError((error: any) => this.handleResponseError(req, error))
        );
    }

    private handleUnsuccessfulResponse(
        req: HttpRequest<any>,
        event: HttpEvent<any>
    ) {
        if (
            event instanceof HttpResponse &&
            event.body?.IsSuccess != null &&
            !event.body.IsSuccess
        ) {
            const result: BaseServiceResult = event.body;

            throw this.buildApiServiceError(
                req,
                ApiServiceErrorType.UnsuccessfulRequest,
                result.ErrorDetails,
                result
            );
        }
        return event;
    }

    private handleResponseError(req: HttpRequest<any>, error: any) {
        if (error instanceof HttpErrorResponse) {
            const apiErrorType: ApiServiceErrorType =
                this.getApiErrorType(error) ||
                ApiServiceErrorType.UnhandledServerError;
            return throwError(() =>
                this.buildApiServiceError(
                    req,
                    apiErrorType,
                    this.getApiErrorMessage(error),
                    error
                )
            );
        }
        return throwError(() => error);
    }

    private getApiErrorMessage(
        errorResponse: HttpErrorResponse
    ): string | undefined {
        return errorResponse.error?.Message || errorResponse.message;
    }

    private getApiErrorType(error: HttpErrorResponse): ApiServiceErrorType {
        switch (error.status) {
            case 0:
                return this.handleSubError(error.error);
            case 304:
                return ApiServiceErrorType.UnmodifiedContent;
            case 400:
                return ApiServiceErrorType.BadParameterValues;
            case 401:
                return ApiServiceErrorType.Unauthorized;
            case 403:
                return ApiServiceErrorType.AccessDenied;
            case 404:
                return ApiServiceErrorType.ObjectNotFound;
            case 408:
                return ApiServiceErrorType.Timeout;
            case 409:
                return ApiServiceErrorType.Conflicts;
            case 500:
                return ApiServiceErrorType.InternalServerError;
            case 503:
                return ApiServiceErrorType.ServerUnreachable;
            default:
                return ApiServiceErrorType.Unknown;
        }
    }

    private handleSubError(error: any) {
        if (error instanceof ProgressEvent) {
            switch (error.type) {
                case 'timeout':
                    return ApiServiceErrorType.Timeout;
                case 'error':
                default:
                    return ApiServiceErrorType.ServerUnreachable;
            }
        }
        return ApiServiceErrorType.Unknown;
    }

    private buildApiServiceError(
        req: HttpRequest<any>,
        type: ApiServiceErrorType,
        message?: string,
        error?: any
    ): ApiServiceError {
        const apiPath = req.url
            .replace(this.config.baseUrl, '')
            .replace('api/', '');
        return new ApiServiceError(
            message ?? ApiServiceErrorType[type],
            apiPath,
            type,
            req.body ?? undefined,
            error
        );
    }
}
