import { GridComponentProps } from './grid.types';
import { Dom2dUtil, IWidthHeight, Rect, Vect2 } from '@datagalaxy/core-2d-util';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { ISvgGridElements } from './grid-util';

/**
 * # Role
 * Responsible for showing a svg grid component with a repetitive circle pattern
 *
 * ## Notes
 * For now, only width/height & position can be updated, it is unlikely that we
 * want to update initial setup (dotRadius, cellSize & dotColor)
 */
export class GridComponent {
    public static readonly class = 'gs-grid';
    public static readonly defaultProps: Partial<GridComponentProps> = {
        dotRadius: 1,
        dotColor: 'rgba(0,0,100,0.2)',
        cellSize: 10,
    };

    public el?: SVGElement;

    private pattern: SVGPatternElement;

    constructor(private props: GridComponentProps) {
        CoreUtil.assignIfUndefined(props, GridComponent.defaultProps);
        const { pattern, rect } = this.buildSvgGridElements();
        const defs = DomUtil.createSvgElement('defs', null, pattern);
        this.el = DomUtil.createSvgElement(
            'svg',
            GridComponent.class,
            defs,
            rect
        );
        this.pattern = pattern;
    }

    public dispose() {
        this.pattern?.remove();
        this.el?.remove();
        this.el = this.pattern = null;
    }

    public updateProps(newProps: Partial<GridComponentProps>) {
        Object.assign(this.props, newProps);
    }

    public draw(): void {
        const { width, height, position, cellSize, dotRadius } = this.props;
        const size = this.gridCellSize(cellSize);
        const positionTruncated = Vect2.from(position).truncateDecimals(true);
        const rect = new Rect(
            positionTruncated.x,
            positionTruncated.y,
            width,
            height
        );

        Dom2dUtil.setBounds(this.el, rect, true);
        Dom2dUtil.setLocation(
            this.pattern,
            -positionTruncated.x - (size.width / 2 - dotRadius),
            -positionTruncated.y - (size.height / 2 - dotRadius)
        );
    }

    private buildSvgGridElements(): ISvgGridElements {
        const { dotRadius, dotColor, cellSize: cellSize0 } = this.props;
        const create = DomUtil.createSvgElement;
        const patternId = CoreUtil.getRandomString(5);
        const circles = [];

        const cellSize = this.gridCellSize(cellSize0);
        let pattern: SVGPatternElement;
        let rect: SVGRectElement;

        const c = create('circle', 'grid-dot');
        const set = this.set;
        set(c, 'cx', cellSize.width / 2 - dotRadius);
        set(c, 'cy', cellSize.height / 2 - dotRadius);
        set(c, 'r', dotRadius);
        set(c, 'fill', dotColor);
        set(c, 'stroke', 'none');
        circles.push(c);

        pattern = create('pattern', 'gs-grid-pattern', ...circles);
        set(pattern, 'id', patternId);
        set(pattern, 'width', cellSize.width);
        set(pattern, 'height', cellSize.height);
        set(pattern, 'patternUnits', 'userSpaceOnUse');

        rect = create('rect', 'grid-rect');

        set(rect, 'x', 0);
        set(rect, 'y', 0);
        set(rect, 'width', '100%');
        set(rect, 'height', '100%');

        set(rect, 'stroke', 'none');
        set(rect, 'fill', `url(#${patternId})`);

        return { pattern, rect };
    }

    private set(el: SVGElement, key: string, value: any) {
        el.setAttribute(key, value.toString());
    }

    private gridCellSize(cellSize: number | IWidthHeight = 10): IWidthHeight {
        return typeof cellSize == 'number'
            ? { width: cellSize, height: cellSize }
            : cellSize;
    }
}
