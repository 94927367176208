import { AskMsTeamsIntegrationActivationEvent } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IDgEventNavLink,
    IEventTranslationInputs,
} from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';
import { resolveIntegrationAdminUrl } from '../common/dg-event-translation-nav-links.helpers';

/**
 * ## Role
 * Adapter to get AskMsTeamsIntegrationActivationEvent translation inputs
 */
@registerTypedEventTranslationAdapter([AskMsTeamsIntegrationActivationEvent])
export class AskMsTeamsIntegrationActivationTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: AskMsTeamsIntegrationActivationEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);
        this.addNavLinks(inputs);
        return inputs;
    }

    private addNavLinks(inputs: IEventTranslationInputs) {
        inputs.navLinks = [this.getConnectTeamsNavLink()];
    }

    private getConnectTeamsNavLink(): IDgEventNavLink {
        return {
            linkCssClass: 'nav-link-teams-integration',
            relativeUrl: resolveIntegrationAdminUrl(this.event),
        };
    }
}
