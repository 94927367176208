import { AttributeMatch } from './AttributeMatch';

export class AttributeCategory {
    public isCollapsed = false;

    constructor(
        public title: string,
        public items = new Array<AttributeMatch>()
    ) {}
}
