<div *ngIf="isReadonly" class="read-only-field">
    <label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
        >{{ label }}</label
    >
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <p class="value-container">{{ valueText }}</p>
    <div class="read-only-separator"></div>
    <span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
</div>

<mat-form-field
    *ngIf="!isReadonly"
    floatLabel="always"
    appearance="standard"
    [class.dg5-compact]="compact"
>
    <mat-label
        [dxyEllipsisTooltip]="labelTooltip"
        dxyEllipsisMode="alwaysVisible"
    >
        {{ label }}
        <span
            *ngIf="infoTooltip"
            class="glyph-info"
            [matTooltip]="infoTooltip"
        ></span>
        <span *ngIf="beta" class="dg5-beta-badge"></span>
    </mat-label>
    <span *ngIf="hintBeforeControl" class="mat-hint">{{ hint }}</span>
    <dxy-checkbox-field-control
        #fieldControl
        [(ngModel)]="value"
        [ngModelOptions]="modelOptionsForUpdateOn"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="disabled"
        [textValueTrue]="textValueTrue"
        [textValueFalse]="textValueFalse"
        [allowIndeterminate]="allowIndeterminate"
        [textValueIndeterminate]="textValueIndeterminate"
        labelPosition="after"
        [required]="required"
    ></dxy-checkbox-field-control>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
<span *ngIf="!hintBeforeControl" class="mat-hint">{{ hint }}</span>
