<ng-container *ngIf="statistics$ | async as statistics; else spinner">
    <ng-container *ngIf="statistics.entryCount; else noLexiconIntro">
        <div class="dxy-label" translate>Multilingual.lexicon</div>
        <div class="lexicon-download-button-wrapper">
            <i class="glyph-csv-file"></i>
            <button (click)="downloadLexicon()" class="dxy-link">
                {{ lexiconFileName }}
                <i class="glyph-upload"></i>
            </button>
        </div>
        <p class="statistics">
            {{
                'Multilingual.Administration.LexiconForm.statistics' | translate:{
                    languagesCount: statistics.languages.length,  
                    termsCount: statistics.entryCount,
                }
            }}
            <br />
            <br />
            <ng-container *ngIf="statistics.lastModified">
                <span translate>
                    Multilingual.Administration.LexiconForm.lastModificationDate
                </span>
                <span>
                    {{ statistics.lastModified | dateFormat }}
                </span>
            </ng-container>
        </p>
    </ng-container>

    <ng-template #noLexiconIntro>
        <p translate>Multilingual.Administration.LexiconForm.recommendation</p>
        <p>
            <span translate>
                Multilingual.Administration.LexiconForm.exportEmptyFile
            </span>
            <button class="dxy-link" (click)="downloadCsvTemplate()" translate>
                {{ teamplateFileName }}
            </button>
        </p>
    </ng-template>

    <dxy-file-input
        acceptedFormat="application/csv"
        (fileChange)="uploadFile($event)"
        [loading]="loading$ | async"
        [succeeded]="succeeded"
        [label]="
            'Multilingual.Administration.LexiconForm.fileUploadLabel'
                | translate
        "
        [buttonText]="
            'Multilingual.Administration.LexiconForm.fileUploadButtonText'
                | translate
        "
    ></dxy-file-input>
</ng-container>
<ng-template #spinner>
    <dxy-spinner class="xl no-margin"></dxy-spinner>
</ng-template>
