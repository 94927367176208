import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import {
    Dom2dUtil,
    getPathOriginPosition,
    isOrthogonalPath,
    Point,
    Rect,
    RectSide,
    simplifyPath,
    Vect2,
} from '@datagalaxy/core-2d-util';
import { IConnector, IConnectorSpec } from './connectors.types';
import { ConnectorEndpoint } from './endpoint';
import { select } from 'd3-selection';
import { ConnectorGeometry, ConnectorGeometryType } from './geometry';
import { IConnectorRouter } from './routing';
import { ConnectorPath } from './path';
import { ConnectorLabel } from './label';
import { getRouterStrategy } from './routing/routing.factory';
import { OrthogonalRouterDebug } from './routing/orthogonal/orthogonal.router.debug';
import { IGraphItem } from '../graph-surface.types';
import { OrthoRouteUtil } from './routing/orthogonal/OrthoRouteUtil';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

/** ## Role
 * Graphically connects 2 rectangular endpoints with a svg path.
 * Supports real-time.
 * ## Features
 * - computes nearest sides of the 2 rectangles
 * - dual SVG path (second for easier hovering detection)
 * - html div label, with dynamic text and positioning along the path
 * - optional gap before endpoint
 * - rounded angles between segments
 * - supports fixed segments
 */
export class Connector<N = unknown, D = unknown>
    implements IConnector<N, D>, IGraphItem<D>
{
    public static readonly class = 'gs-connector';

    public readonly graphType = 'edge';
    public el: SVGGElement;

    private g: SVGGElement;
    private debug: boolean;
    private position: Point;
    private pathComponent: ConnectorPath;
    private labelComponent: ConnectorLabel;
    private _points: Point[];
    private _fixedPoints: Point[];
    private classes: string[] = [];

    public get data() {
        return this.spec.data;
    }
    public get id() {
        return this.spec.id;
    }
    public get thickness() {
        return this.spec.thickness;
    }
    public get hidden() {
        return this.spec.hidden;
    }
    public get selected() {
        return this.spec.selected;
    }
    public get layer() {
        return this.spec.layer;
    }
    public get rect(): Rect {
        return Rect.fromPoints(this._points);
    }
    public get points() {
        return this._points;
    }
    public get isFixed() {
        return !!this.fixedPoints?.length;
    }
    public get srcNode() {
        return this.spec.source;
    }
    public get tgtNode() {
        return this.spec.target;
    }
    public get inverted() {
        return this.spec.inverted;
    }
    public get srcPort() {
        return this.srcNode?.getPortById(this.spec.sourcePort);
    }
    public get tgtPort() {
        return this.tgtNode?.getPortById(this.spec.targetPort);
    }
    public get cssClass() {
        return this.spec.cssClass;
    }
    public get label() {
        return this.spec.label;
    }

    private get fixedPoints() {
        return this._fixedPoints;
    }

    private readonly router: IConnectorRouter;
    public src: ConnectorEndpoint<N>;
    public tgt: ConnectorEndpoint<N>;

    constructor(private spec?: IConnectorSpec<N, D>) {
        const svg = (this.el = DomUtil.createSvgElement('svg'));
        const g = (this.g = DomUtil.createSvgElement('g'));
        svg.append(g);

        spec.thickness ??= 1;
        spec.color ??= GraphicalColor.Black;

        this.router = getRouterStrategy(spec);
        this.pathComponent = new ConnectorPath(spec);

        const geometry = spec?.geometry;

        this.src = new ConnectorEndpoint<N>(this.srcPort, geometry?.src);
        this.tgt = new ConnectorEndpoint<N>(this.tgtPort, geometry?.tgt);
        this.src.connector = this.tgt.connector = this;
        this.setGeometry(geometry);

        this.g.append(this.pathComponent.g);

        this.updateProps(spec);
    }

    public updateProps(newSpec: Partial<IConnectorSpec<N, D>>) {
        const spec = Object.assign(this.spec, newSpec);

        this.updateLabel(spec);
        this.pathComponent.updateProps(newSpec);
    }

    public setSrcSide(side: RectSide, distance?: number) {
        this.src.fixSide(side, distance);
    }

    public setTgtSide(side: RectSide, distance?: number) {
        this.tgt.fixSide(side, distance);
    }

    public computeEndpoints() {
        const c = this;
        const srcFixed = c.src.isSideFixed;
        const tgtFixed = c.tgt.isSideFixed;

        if (srcFixed && tgtFixed) {
            return;
        }

        if (!srcFixed) {
            c.src.unfixSide();
        }

        if (!tgtFixed) {
            c.tgt.unfixSide();
        }

        OrthoRouteUtil.computeEndpoints(c.src, c.tgt, c.getFixedPoints());
    }

    public computePoints() {
        const points = (this._points = this.router.computePoints(
            this.src,
            this.tgt,
            this.fixedPoints
        ));

        this.position = getPathOriginPosition(points);
        const relativePoints = this.translateToOrigin(points);

        this.labelComponent?.updateProps({ points: relativePoints });
        this.pathComponent.updateProps({ points: relativePoints });
    }

    /**
     * Returns a copy of the connector's fixed points
     * It avoids any alteration of those one.
     * Refer to setFixedPoints(points: Point[]) if you truly want to update
     * the fixed path
     */
    public getFixedPoints(): Point[] {
        return this.fixedPoints?.slice();
    }

    public updateFixedPoints(points: Point[]) {
        this._fixedPoints = points;
        this.computePoints();
    }

    /** Removes every html or svg element from its parent */
    public dispose() {
        this.labelComponent?.dispose();
        this.pathComponent.dispose();
        this.g?.remove();
        this.el?.remove();

        this.labelComponent = this.pathComponent = this.g = this.el = null;
    }

    public draw() {
        const classes = this.getClasses();
        const rectPadding = 20;
        DomUtil.replaceClasses(this.el, classes, this.classes);
        this.classes = classes;
        Dom2dUtil.setBounds(this.el, this.rect?.augment(rectPadding), true);
        this.g.setAttribute(
            'transform',
            `translate(${rectPadding / 2}, ${rectPadding / 2})`
        );

        this.labelComponent?.draw();
        this.pathComponent.draw();

        if (this.debug) {
            this.drawDummies();
            OrthogonalRouterDebug.addDebugGroup(
                this.router,
                this.g,
                this._points,
                this.position
            );
        }
    }

    /** Removes the fixed part of the connector's path if any */
    public clearGeometry() {
        this.src.setAutoSide(true);
        this.tgt.setAutoSide(true);
        this._fixedPoints = [];
        this.computePoints();
    }

    public getGeometry() {
        if (
            this.fixedPoints?.length ||
            this.src.isClientSide ||
            this.tgt.isClientSide
        ) {
            return {
                type: ConnectorGeometryType.ortho,
                points: this.fixedPoints ?? [],
                src: this.src.isClientSide ? this.src.sideAndDistance() : null,
                tgt: this.tgt.isClientSide ? this.tgt.sideAndDistance() : null,
            };
        }
        return undefined;
    }

    private getClasses() {
        const { hidden, highlighted, selected } = this.spec;
        const hiddenClass = hidden ? 'hidden' : '';
        const highlightedClass = highlighted ? 'highlighted' : '';
        const selectedClass = selected ? 'selected' : '';
        const classes = this.spec?.cssClass?.split(' ') || [];

        return [
            Connector.class,
            ...classes,
            hiddenClass,
            highlightedClass,
            selectedClass,
        ];
    }

    private setGeometry(geometry: ConnectorGeometry) {
        if (!geometry) {
            return;
        }

        if (geometry.points?.length && isOrthogonalPath(geometry.points)) {
            this._fixedPoints = this.cleanupPoints(geometry.points).slice();
        } else if (geometry.points?.length) {
            CoreUtil.warn('setGeometry-baddata', geometry, this);
            this._fixedPoints = [];
        }
    }

    private cleanupPoints(points: Point[]) {
        points?.forEach((p) => Vect2.truncateDecimals(p, p));
        return simplifyPath(points);
    }

    private translateToOrigin(points: Point[]) {
        return points?.map((p) => Vect2.from(p).sub(this.position));
    }

    private updateLabel(spec: IConnectorSpec<N, D>) {
        if (!spec.label?.text) {
            this.labelComponent?.dispose();
            this.labelComponent = null;
            return;
        }

        if (!this.labelComponent) {
            this.labelComponent = new ConnectorLabel(spec.label);
            this.g.append(this.labelComponent.el);
        }

        this.labelComponent.updateProps(spec.label);
    }

    private drawDummies() {
        const dbg = select(this.g)
            .selectAll('g.dbg')
            .data([0])
            .join('g')
            .attr('class', 'dbg')
            .style('pointer-events', 'none');

        const points = this.translateToOrigin(this._points);
        //#region connector endpoints side
        const data = [
                { ep: this.src, p: points[0] },
                { ep: this.tgt, p: points[points.length - 1] },
            ],
            l = 10;
        dbg.selectAll('rect.s')
            .data(data)
            .join('rect')
            .attr('class', 's')
            .attr('x', (d) => d.p.x - l / 2)
            .attr('y', (d) => d.p.y - l / 2)
            .attr('width', l)
            .attr('height', l)
            .style('stroke', (d) =>
                d.ep.isClientSide
                    ? 'white'
                    : d.ep.isSideAutoFixed
                    ? 'black'
                    : null
            )
            .style('opacity', 0.7);
        //#endregion

        const fixed = this.translateToOrigin(this.fixedPoints);
        if (fixed?.length) {
            // circle
            dbg.selectAll('circle.f')
                .data(fixed)
                .join('circle')
                .attr('class', 'f')
                .attr('cx', (d) => d.x)
                .attr('cy', (d) => d.y)
                .attr('r', 5)
                .style('fill', 'red')
                .style('stroke', 'none');
            // index text
            dbg.selectAll('text.fid')
                .data(fixed)
                .join('text')
                .attr('class', 'fid')
                .attr('x', (d) => d.x - 12)
                .attr('y', (d) => d.y + 10)
                .text((_, i) => i)
                .style('fill', 'red');
        } else {
            dbg.selectAll('circle.f,text.fid').remove();
        }
    }
}
