import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { EntityActivityEventBase, registerTypedEvent } from '../../events';
import { EntityTaskDTO } from './EntityTaskDTO';

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('TaskCreatedEvent')
export class TaskCreatedEvent extends EntityActivityEventBase {
    @deserializeAs(EntityTaskDTO) Task: EntityTaskDTO;
}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('TaskAssignedEvent')
export class TaskAssignedEvent extends EntityActivityEventBase {
    @deserializeAs(EntityTaskDTO) Task: EntityTaskDTO;
}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('TasksDeletedEvent')
export class TasksDeletedEvent extends EntityActivityEventBase {
    @deserialize TaskTitles: string[];
}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('TaskUpdatedEvent')
export class TaskUpdatedEvent extends EntityActivityEventBase {
    @deserializeAs(EntityTaskDTO) PreviousTask: EntityTaskDTO;
    @deserializeAs(EntityTaskDTO) Task: EntityTaskDTO;
}
