import { inheritSerialization } from 'cerialize';
import { DataTypeSettings } from './data-type-settings';
import { ServerType } from '@datagalaxy/dg-object-model';
import { Ref } from '../ref';

@inheritSerialization(Ref)
export class DataTypeSettingsRef extends Ref<DataTypeSettings> {
    static fromId(id: string) {
        return new DataTypeSettingsRef(id);
    }

    constructor(id?: string) {
        super(ServerType.DataTypeSettings, id);
    }
}
