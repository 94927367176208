import { autoserializeAs, deserialize, deserializeAs } from 'cerialize';
import { generateGuid } from '@datagalaxy/utils';
import { AttributeDTO } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export class ScreenProperty {
    @autoserializeAs(String) public Key!: string;
    @autoserializeAs(Boolean) IsSystem!: boolean;

    constructor(key?: string, isSystem?: boolean) {
        if (key != undefined) {
            this.Key = key;
        }
        if (isSystem != undefined) {
            this.IsSystem = isSystem;
        }
    }
}

export class ScreenCategory {
    @autoserializeAs(String) public Id: string = generateGuid();
    @autoserializeAs(String) public DisplayName!: string;
    @autoserializeAs(ScreenProperty) public Properties: ScreenProperty[] = [];
    @autoserializeAs(Boolean) public IsOpen = true;
    @autoserializeAs(Boolean) public IsHidden = false;
    @autoserializeAs(Boolean) public IsSystem = false;
    @autoserializeAs(String) public SpecialKey = '';

    /** component selector (i.e. ngJS component name) */
    public UsedComponent? = '';

    public get IsSpecialSystem() {
        return !!this.SpecialKey;
    }

    public get IsHeader() {
        return (
            this.SpecialKey == ServerConstants.ScreenCategory.HeaderSpecialKey
        );
    }
}

export class ScreenLayout {
    @autoserializeAs(ScreenCategory) public Categories: ScreenCategory[] = [];
}

export class ScreenDTO {
    //#Archi-moduleName (fbo) ?
    @deserialize public ModuleName!: string;
    @deserialize public SubTypeName!: string;
    @deserialize public IsClientLevel!: boolean;
    @deserialize public IsSpaceLevel!: boolean;
    /** This flag indicates that we have a client defined Layout */
    @deserialize public IsLayoutOverridden!: boolean;
    /** This flag indicates that the Stored Layout includes changes within the system part of the layout */
    @deserialize public IsSystemLayoutOverridden!: boolean;
    @deserialize public SpaceId!: string;
    @deserialize public VersionId!: string;
    @deserialize public Layout!: string;
    @deserialize public DisplayOrder!: number;
    @deserializeAs(AttributeDTO) public Attributes: Array<AttributeDTO> = [];
    @deserialize public IsFixed!: boolean;

    public isTemplateScreen!: boolean;

    public get DisplayName(): string {
        let resolvedDisplayName = `${this.ModuleName}`; // may be '', but also 'undefined', 'null'
        if (this.ModuleName) {
            resolvedDisplayName = `${this.ModuleName}: ${this.SubTypeName}`;
        }

        return resolvedDisplayName;
    }
}
