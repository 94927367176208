<div class="modal-header">
    <div class="modal-title-container">
        <h3
            *ngIf="!hasMultipleSources"
            mat-dialog-title
            class="modal-title"
            translate
        >
            {{ titleTranslateKey }}
        </h3>
        <h4
            *ngIf="hasMultipleSources"
            mat-dialog-title
            class="modal-title"
            translate
        >
            {{ titleTranslateKey }}
        </h4>
        <span class="modal-subtitle" *ngIf="hasMultipleSources">{{
            selectedCountInfo
        }}</span>
    </div>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
        <span class="glyph glyph-close-circle-filled"></span>
        {{ errorMessage }}
    </div>
    <div
        *ngIf="showSummary"
        class="alert alert-info statistics-container"
        role="alert"
    >
        <img src="/images/success.svg" class="summary-success" />
        <div class="summary-title" translate>
            UI.EntityLinkedObjects.creationModal.summaryTitle
        </div>
        <div class="summary-info">
            <span class="summary-info-count">{{ summaryLinkCount }} </span>
            <span class="summary-info-text" [translate]="summaryText"></span>
        </div>
    </div>
    <div *ngIf="showFormContent" class="form-horizontal">
        <div class="field-group no-margin">
            <app-entity-card-cell
                *ngIf="!hasMultipleSources"
                [hierarchicalData]="entityData.HddData"
                [actions]="sourceEntityActions"
                [noBreadcrumbNavLink]="true"
                [noLabelNavLink]="true"
                [attributes]="attributes"
            ></app-entity-card-cell>
            <dxy-entity-selector-field
                *ngIf="hasMultipleSources"
                class="readonly-no-bottom-margin"
                fieldClass="readonly-no-bottom-margin"
                labelKey="UI.Glossary.fusion.lblSources"
                [ngModel]="entityDataList"
                [readonly]="true"
                [isMultiValue]="true"
            ></dxy-entity-selector-field>
        </div>
        <div class="link-separator"></div>
        <div class="link-selector" (click)="onLinkSelectorClick()">
            <button mat-icon-button class="link-btn">
                <i class="glyph-relation"></i>
            </button>
            <span
                class="placeholder"
                *ngIf="!hasSelectedLinkType"
                [translate]="selectLinkPlaceholder"
            ></span>
            <dxy-option-list
                #linkSelectorOptionList
                class="option-list"
                [isDropdownMenu]="true"
                [isDropdownMenuRight]="false"
                [dropdownMenuSelected]="selectedLinkTypeOption"
                [selectDefaultValue]="false"
                [options]="linkTypeOptions"
                [categories]="linkTypeOptionsCategories"
                (onDropdownMenuSelected)="onSelectLinkTypeOption($event)"
                (click)="$event.stopPropagation()"
                (openClose)="onPanelOpenClose($event)"
            ></dxy-option-list>
        </div>
        <div class="link-separator"></div>
        <div class="field-group">
            <dxy-entity-selector-field
                class="field"
                [labelKey]="targetFieldLabelKey"
                [ngModel]="targetEntities"
                [isMultiValue]="true"
                [menuTakeFullWidth]="true"
                [readonly]="isTargetReadOnly"
                (ngModelChange)="onTargetSelectionChange($event)"
                [options]="targetEntitiesSelectorData.instance"
                [openPreviewOnSelectedItemClick]="true"
                (openClose)="onPanelOpenClose($event)"
                [errorMessageKey]="targetErrorMessageKey"
            ></dxy-entity-selector-field>
        </div>
    </div>
</div>
<div class="modal-footer">
    <ng-container *ngIf="showSummary">
        <button (click)="onAddMoreLinks()" mat-flat-button>
            <i class="glyph-add"></i>
            <span translate
                >UI.EntityLinkedObjects.creationModal.addNewLinks</span
            >
        </button>
        <button mat-flat-button color="primary" (click)="onCloseCancel()">
            <span translate>UI.Dialog.btnClose</span>
        </button>
    </ng-container>
    <ng-container *ngIf="showFormContent">
        <button mat-flat-button (click)="onCloseCancel()">
            <span translate>UI.Dialog.btnCancel</span>
        </button>

        <button
            *ngIf="isDelete"
            (click)="onSubmit()"
            mat-flat-button
            color="primary"
            [disabled]="isSaving || !isActionValid"
            dxyLogFunctional="LINKED_OBJECTS,D"
        >
            {{ actionButtonTranslateKey }}
            <i *ngIf="isSaving" class="dg5-spinner xs-spinner"></i>
        </button>
        <dxy-split-button
            *ngIf="!isDelete"
            (click)="onSubmit()"
            color="primary"
            [options]="splitBtnOptions"
            [disabled]="isSaving || !isActionValid"
            [textKey]="actionButtonTranslateKey"
            [showSplitButton]="showReplaceSplitOption"
            [dxyLogFunctional]="featureCode"
            [isLoading]="isSaving"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-split-button>
    </ng-container>
</div>
