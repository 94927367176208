import {
    AfterViewInit,
    Directive,
    ElementRef,
    inject,
    Input,
    NgZone,
    OnInit,
    SecurityContext,
} from '@angular/core';
import { IBaseField } from './field.types';
import {
    MatLegacyFormFieldAppearance as MatFormFieldAppearance,
    MatLegacyFormFieldControl as MatFormFieldControl,
} from '@angular/material/legacy-form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractControl, NgControl } from '@angular/forms';
import { DxyBaseValueAccessorComponent } from '@datagalaxy/ui/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Base class for a 'field' component, to:
 * - encapsulate an input control (MatFormFieldControl) in an Angular Material MatFormField,
 * - or display a value for read-only, with an optional label.
 *
 * Inherits DxyBaseValueAccessorComponent which provides the ControlValueAccessor interface (ngModel/ngModelChange),
 * and can also:
 * - trigger a native *change* event,
 * - emit a debouncedModelChange ng event.
 *
 * Inherits DxyBaseComponent which provides:
 * - logging methods,
 * - events subscription and unsubscription.
 *
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'dxy-field' },
})
export abstract class DxyBaseFieldComponent<T>
    extends DxyBaseValueAccessorComponent<T>
    implements IBaseField, OnInit, AfterViewInit
{
    //#region IBaseField
    @Input() labelText = '';
    @Input() labelKey = '';
    @Input() labelTooltipKey = '';
    @Input() labelTooltipText = '';
    // In template, use *isReadonly* instead, to avoid NG0100 errors.
    @Input() readonly: boolean | string = false;
    @Input() hint = '';
    @Input() htmlHint = '';
    /** if true, hint will be displayed between the label and the field*/
    @Input() hintBeforeControl?: boolean;
    @Input() compact = false;
    @Input() disabled: boolean | string = false;
    @Input() errorMessageText = '';
    @Input() errorMessageKey?: string;
    @Input() mandatory: boolean | string = false;
    @Input() required: boolean | string = false;
    //#endregion

    /** should be either 'standard' (no background) or 'fill' (padding and background on hover & focus). Default is 'standard' */
    @Input() fieldAppearance?: MatFormFieldAppearance = 'standard';

    public readonly errorStateMatcher: ErrorStateMatcher = {
        isErrorState: () =>
            !!(
                (this.invalid && this.touched) ||
                this.errorMessageText ||
                this.errorMessageKey
            ),
    };
    protected fieldControl?: MatFormFieldControl<any>;
    private sanitizer = inject(DomSanitizer);

    public get touched() {
        return this.refNgControl?.touched;
    }

    public get dirty() {
        return this.refNgControl?.dirty;
    }

    public get invalid() {
        return this.refNgControl?.invalid;
    }

    public get isReadonly() {
        return coerceBooleanProperty(this.readonly);
    }

    protected sanitizedHint() {
        return this.sanitizer.sanitize(SecurityContext.HTML, this.htmlHint);
    }

    /** To be overridden when a field-control is used */
    protected get empty() {
        return this.value == undefined;
    }

    protected get isDisabled() {
        return coerceBooleanProperty(this.disabled);
    }

    protected get isMandatory() {
        return coerceBooleanProperty(this.mandatory);
    }

    protected get isRequired() {
        return coerceBooleanProperty(this.required);
    }

    protected override get isErrorRequired() {
        return this.validationErrors?.['required'] && !this.value;
    }

    protected override get validationErrors() {
        return this.refNgControl?.control?.validator?.({} as AbstractControl);
    }

    protected get refNgControl() {
        return this.ngControl ?? this.fieldControl?.ngControl;
    }

    protected constructor(
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        ngControl?: NgControl
    ) {
        super(ngControl, ngZone, elementRef);
    }

    ngOnInit() {
        this.log('(super)onInit');
        this.addListenerOutside('click', (e) => this.onClickEvent(e));
        this.addListenerOutside('change', (e) => this.onChangeEvent(e));
    }

    ngAfterViewInit() {
        this.log('(super)ngAfterViewInit');
    }

    /** By default, the native *click* event is canceled when the field is readonly or disabled.
     * This allows to prevent the *dxyLogFunctional* directive from being triggered. */
    protected onClickEvent(e: Event) {
        const prevent = this.isReadonly || this.isDisabled;
        this.log('onClickEvent-prevent', prevent);
        if (prevent) {
            e.stopImmediatePropagation();
        }
    }

    /** By default, the native *change* event is canceled when the field is readonly or disabled.
     * This allows to prevent the *dxyLogFunctional* directive from being triggered. */
    protected onChangeEvent(e: Event) {
        const prevent = this.isReadonly || this.isDisabled;
        this.log('onChangeEvent-prevent', prevent);
        if (prevent) {
            e.stopImmediatePropagation();
        }
    }

    /** Returns the label text, translated if needed.
     * Adds a '*' to it when *mandatory* and not *readonly*. */
    protected getLabel(translate: TranslateService) {
        const label =
            this.labelText ||
            (this.labelKey && translate.instant(this.labelKey)) ||
            '';
        return label && this.isMandatory && !this.isRequired && !this.isReadonly
            ? `${label} *`
            : label;
    }

    protected getLabelTooltip(translate: TranslateService) {
        return (
            this.labelTooltipText ||
            (this.labelTooltipKey && translate.instant(this.labelTooltipKey)) ||
            ''
        );
    }

    /** Returns the error message, translated if needed. */
    protected getErrorMessage(translate: TranslateService) {
        return (
            this.errorMessageText ||
            (this.errorMessageKey && translate.instant(this.errorMessageKey)) ||
            (this.isErrorRequired &&
                translate.instant('CoreUI.Global.msgRequired')) ||
            ''
        );
    }

    /** Adds an event listener on hte native element, that do not trigger change detection */
    protected addListenerOutside<T extends Event>(
        eventName: string,
        listener: (e: T) => void
    ) {
        this.ngZone.runOutsideAngular(() =>
            this.elementRef?.nativeElement.addEventListener(
                eventName,
                (event: Event) => listener(event as T)
            )
        );
    }
}
