import { CoreUtil } from '@datagalaxy/core-util';
import {
    DataType,
    DataTypeMappingItem,
} from '@datagalaxy/webclient/modeler/data-access';

export class DataTypeMappingGridItem {
    public get isReadOnly() {
        return this.dt?.IsSystem;
    }
    public get dataTypeMappingItem() {
        return this.dtmi;
    }

    public get dataTypeName() {
        return this.dt?.DisplayName;
    }
    public set dataTypeName(value: string) {
        this.dt.DisplayName = value;
    }

    public get dataTypeMappingName() {
        return this.dtmi.DisplayName;
    }
    public set dataTypeMappingName(value: string) {
        this.dtmi.DisplayName = value;
    }

    constructor(private dtmi: DataTypeMappingItem, private dt: DataType) {
        if (!dtmi) {
            CoreUtil.warn('no dtmi', this);
        }
        if (!dt) {
            CoreUtil.warn('no dt', this);
        }
    }
}
