<div class="mention" [matTooltip]="tooltipKey | translate">
    <span
        *ngIf="!userId && iconClass"
        [ngClass]="iconClass"
        class="mention-icon"
    ></span>
    <dxy-profile-avatar
        *ngIf="userId"
        [userId]="userId"
        [isMiniIcon]="true"
    ></dxy-profile-avatar>
    <img *ngIf="iconUrl" class="mention-img" [src]="iconUrl" />
    <span class="label-wrapper">{{ label }}</span>
</div>
