import { PersistedGridState } from './grid-persisted-state.types';
import { Table } from '@tanstack/table-core';
import { ArrayUtils } from '@datagalaxy/utils';

export function getPersistedState<TRow>(
    table: Table<TRow>
): PersistedGridState {
    const columOrder = table.getState().columnOrder;
    const columns = table
        .getVisibleLeafColumns()
        .sort(ArrayUtils.sortByIndexOf(columOrder || [], (col) => col.id));

    return {
        version: 'dxy-grid-v1',
        columns: columns.map((col) => {
            const columnWidth = col.getSize();
            const columnVisible = col.getIsVisible();

            return {
                id: col.id,
                hidden: !columnVisible,
                width: columnWidth,
            };
        }),
    };
}

export function applyPersistedState<TRow>(
    table: Table<TRow>,
    state: PersistedGridState
) {
    const visibleColumns = state.columns.filter((col) => !col.hidden);
    const columnOrder = visibleColumns.map((col) => col.id);
    const columnSizing = ArrayUtils.toRecord(visibleColumns, (col) => [
        col.id,
        col.width,
    ]);
    const columnVisibility = ArrayUtils.toRecord(visibleColumns, (col) => [
        col.id,
        !col.hidden,
    ]);

    table.setColumnOrder(columnOrder);
    table.setColumnSizing(columnSizing);
    table.setColumnVisibility((old) => ({
        ...old,
        ...columnVisibility,
    }));
}
