import { deserialize } from 'cerialize';
import { IIdr } from './IIdr';
import { ServerType } from './ServerType.enum';
import { IDataIdentifier } from './IDataIdentifier';

export class DataIdentifier implements IDataIdentifier {
    static asIdr(di: IDataIdentifier): IIdr {
        return (
            di && {
                ReferenceId: di.DataReferenceId,
                ServerType: ServerType[
                    di.DataTypeName as keyof typeof ServerType
                ] as ServerType,
            }
        );
    }

    @deserialize public DataReferenceId: string;
    @deserialize public DataTypeName: string;

    public get DataServerType(): ServerType {
        return ServerType[this.DataTypeName as keyof typeof ServerType];
    }

    public constructor(
        dataReferenceId: string,
        dataTypeName: string | null = null
    ) {
        this.DataReferenceId = dataReferenceId;
        this.DataTypeName = dataTypeName as string;
    }
}
