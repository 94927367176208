/** import module type identifiers
 * - thoses names are also used as translation sub keys of Import.Wizard.
 */
export enum EModuleType {
    undefined = 0,
    Glossary,
    Catalog,
    DataProcessings,
    SoftwareElements,
    Attribute,
    User,
    TimeSeries,
    Teams,
}
