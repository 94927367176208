import {
    Component,
    forwardRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import {
    DxyOptionListComponent,
    IActionOption,
    IListOption,
    IOptionCategory,
} from '@datagalaxy/core-ui';
import { EntityService } from '../services/entity.service';
import { EntityPreviewPanelService } from '../services/entity-preview-panel.service';
import {
    EntitySelectorData,
    IDxyEntitySelectorInputs,
} from '../../entitySelector/entity-selector.types';
import {
    EntityLinkedObjectMode,
    ILinkedObjectModalInput,
    ILinkedObjectModalOutput,
} from './linked-object-modal.types';
import {
    IEntityIdentifier,
    ServerType,
    TypeLinkDataInfo,
} from '@datagalaxy/dg-object-model';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import { EntityUiService } from '../services/entity-ui.service';
import { IEntityLinkIdentifier } from '../linked-object.types';
import { AddLinkAction } from '@datagalaxy/webclient/entity/data-access';
import { isUnsuccessfulApiError } from '@datagalaxy/data-access';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DataUtil } from '../../util/DataUtil';
import { ModuleStore } from '../../../module/module.store';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { DxySplitButtonComponent } from '@datagalaxy/core-ui';
import { DxyLogFunctionalDirective } from '../../../directives/dxy-log-functional.directive';
import { FormsModule } from '@angular/forms';
import { DxyEntitySelectorFieldComponent } from '../../entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { EntityCardCellComponent } from '../../entityCard/entity-card/entity-card-cell.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Modal to add or edit links between entities
 */
@Component({
    selector: 'app-linked-object-modal',
    templateUrl: './linked-object-modal.component.html',
    styleUrls: ['./linked-object-modal.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        EntityCardCellComponent,
        forwardRef(() => DxyEntitySelectorFieldComponent),
        FormsModule,
        DxyOptionListComponent,
        DxyLogFunctionalDirective,
        DxySplitButtonComponent,
    ],
})
export class LinkedObjectModalComponent
    extends DxyBaseModalComponent<
        ILinkedObjectModalInput,
        ILinkedObjectModalOutput
    >
    implements OnInit
{
    @ViewChild('linkSelectorOptionList') linkOptionList: DxyOptionListComponent;

    //#region html bindings
    public readonly targetEntitiesSelectorData = new EntitySelectorData();
    public readonly splitBtnOptions: IListOption[] = [
        {
            labelKey: 'UI.EntityLinkedObjects.modal.submit.add.title',
            descriptionTranslateKey:
                'UI.EntityLinkedObjects.modal.submit.add.subtitle',
            callback: () => this.onSetActionAdd(),
        },
        {
            labelKey: 'UI.EntityLinkedObjects.modal.submit.update.title',
            descriptionTranslateKey:
                'UI.EntityLinkedObjects.modal.submit.update.subtitle',
            callback: () => this.onSetActionUpdate(),
        },
    ];
    public isSaving: boolean;
    public errorMessage: string;
    public isSummaryDisplayed = false;

    public linkTypeOptions: IListOption<TypeLinkDataInfo>[];
    public linkTypeOptionsCategories: IOptionCategory[] = [
        {
            key: 'exist',
            translateKey:
                'UI.EntityLinkedObjects.creationModal.linkTypeExistOptions',
        },
        {
            key: 'new',
            translateKey:
                'UI.EntityLinkedObjects.creationModal.linkTypeNewOptions',
        },
    ];
    public selectedLinkTypeOption: IListOption<TypeLinkDataInfo>;
    public targetErrorMessageKey: string;
    public targetEntities: EntityItem[] = [];
    /** not used for now, we have to wait for panel preview to be in overlay */
    public sourceEntityActions: IActionOption[] = [
        {
            glyphClass: 'glyph-object-preview',
            tooltipTranslateKey: 'UI.Global.openPreview',
            alwaysVisible: true,
            callback: () =>
                this.entityPreviewPanelService.setupPanel({
                    entityIdr: EntityIdentifier.fromIHasHddData(
                        this.entityData
                    ),
                }),
        },
    ];
    protected entitySelectorOpt: IDxyEntitySelectorInputs = {
        useParentsFilter: true,
    };

    public get isUpdate() {
        return !!this.data.linkToUpdate;
    }

    public get isDelete() {
        return this.data.mode === EntityLinkedObjectMode.Delete;
    }

    public get isAddOnly() {
        return this.data.mode === EntityLinkedObjectMode.Create;
    }

    public get allowExistingLinks() {
        return (
            this.isUpdate ||
            this.data.mode === EntityLinkedObjectMode.CreateOrGetExisting
        );
    }

    public get allowNewLinks() {
        return this.hasWriteAccess;
    }

    public get showReplaceSplitOption() {
        return this.data.mode === EntityLinkedObjectMode.CreateOrReplace;
    }

    public get showFormContent() {
        return !this.showSummary;
    }

    public get isDisabled(): boolean {
        return !this.hasWriteAccess || !this.availableLinkTypes?.length;
    }

    public get isActionValid() {
        return (
            !!this.targetEntities?.length &&
            this.hasSelectedLinkType &&
            (!this.isUpdate || this.canUpdate)
        );
    }

    /** Summary is skipped if there is only one link created/deleted/updated aka one source and one target */
    public get shouldShowSummary() {
        return (
            this._entityDataList?.length > 1 || this.targetEntities?.length > 1
        );
    }

    public get showSummary() {
        return (
            this.isSummaryDisplayed &&
            !this.errorMessage &&
            this.shouldShowSummary
        );
    }

    public get titleTranslateKey() {
        return !this.data
            ? ''
            : this.data.modalTitleTranslateKey ||
                  (this.isDelete
                      ? 'UI.BulkEdit.btnDeleteLinkSelection'
                      : this.isAddOnly
                      ? 'UI.EntityLinkedObjects.creationModal.title'
                      : 'UI.BulkEdit.btnLinkedObjectSelection');
    }

    public get selectLinkPlaceholder() {
        const existingLinkCount = this.linkTypeOptions?.filter(
            (option) => option.categoryKey === 'exist'
        )?.length;
        return this.allowExistingLinks && existingLinkCount
            ? this.translate.instant(
                  'UI.EntityLinkedObjects.creationModal.existingLinkCount',
                  { count: existingLinkCount }
              )
            : 'UI.EntityLinkedObjects.creationModal.linkSelectPlaceholder';
    }

    public get selectedCountInfo() {
        const count = this._entityDataList.length ?? 0;
        return this.translate.instant('UI.BulkEdit.selectedCount', { count });
    }

    public get targetFieldLabelKey() {
        return this.isDelete
            ? 'UI.EntityLinkedObjects.creationModal.targetFieldLabelDelete'
            : 'UI.EntityLinkedObjects.creationModal.targetFieldLabel';
    }

    public get summaryLinkCount() {
        return this.result?.linkIdrs?.length;
    }

    public get summaryText() {
        return this.isDelete
            ? 'UI.BulkEdit.deletedLink'
            : this.isActionReplace
            ? 'UI.BulkEdit.replacedLink'
            : 'UI.BulkEdit.addedLink';
    }

    public get actionButtonTranslateKey() {
        const suffix = this.isDelete
            ? 'lblDelete'
            : this.isActionReplace || this.isUpdate
            ? 'lblSave'
            : 'lblAdd';
        return this.translate.instant('UI.Dialog.NewItem.' + suffix);
    }

    public get featureCode() {
        const feature =
            this._entityDataList?.length > 1
                ? 'LINKED_OBJECTS'
                : 'LINKED_OBJECT';
        const opcode = this.isDelete ? 'D' : this.isActionReplace ? 'U' : 'C';
        return `${feature},${opcode}`;
    }

    public get hasMultipleSources() {
        return this._entityDataList?.length > 1;
    }

    public get entityData() {
        return this._entityDataList?.[0];
    }

    protected get attributes() {
        return this.entityData?.Attributes;
    }

    public get hasSelectedLinkType() {
        return !!this.selectedLinkType;
    }

    public get isTargetReadOnly() {
        return !!this.data.targetEntityDataList?.length;
    }

    public get entityDataList() {
        return this._entityDataList;
    }

    //#endregion

    private selectedAction = LinkAction.Append;
    private _entityDataList: EntityItem[];
    private selectedLinkType: TypeLinkDataInfo;
    private availableLinkTypes: TypeLinkDataInfo[];
    private noWriteAccessEntityDataList: EntityItem[];
    private spaceIdr: ISpaceIdentifier;

    private get hasWriteAccess(): boolean {
        return (
            this.data.noCheckAccessRights ||
            !this.noWriteAccessEntityDataList?.length
        );
    }

    private get isActionReplace() {
        return this.selectedAction == LinkAction.Replace;
    }

    private get canUpdate() {
        return (
            this.isUpdate &&
            this.data.linkToUpdate.linkType !=
                this.selectedLinkType.UniversalObjectLinkType
        );
    }

    constructor(
        dialogRef: MatDialogRef<
            LinkedObjectModalComponent,
            ILinkedObjectModalOutput
        >,
        @Inject(MAT_DIALOG_DATA) data: ILinkedObjectModalInput,
        private translate: TranslateService,
        private entityUiService: EntityUiService,
        private linkedObjectService: EntityLinkService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private entityService: EntityService,
        private moduleStore: ModuleStore
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.log('ngOnInit', this);
        this.init();
    }

    //#region event handlers
    public onLinkSelectorClick() {
        this.linkOptionList.toggleMenu();
    }

    public onAddMoreLinks() {
        this.isSummaryDisplayed = false;
        this.resetForm();
    }

    public onSelectLinkTypeOption(option: IListOption) {
        this.log('onSelectLinkTypeOption', option);
        if (option.data === this.selectedLinkType) {
            return;
        }
        this.selectedLinkTypeOption = option as IListOption<TypeLinkDataInfo>;
        this.onSelectLinkType(option.data as TypeLinkDataInfo);
        this.updateEntitySelectorData();
    }

    public onTargetSelectionChange(entityItems: EntityItem[]) {
        this.log('onTargetSelectionChange', entityItems);
        this.targetEntities = entityItems;
        this.updateAvailableLinkTypes();
    }

    public async onSubmit() {
        const linkIdrs = await this.entityLinkedObjectAction();
        this.result = { linkIdrs };
        this.isSummaryDisplayed = true;
        if (!this.shouldShowSummary) {
            this.onCloseSubmit();
        }
    }

    public onCloseCancel() {
        if (!this.isDelete && this.result) {
            super.onCloseSubmit();
        } else {
            super.onCloseCancel();
        }
    }

    private onSetActionAdd() {
        this.selectedAction = LinkAction.Append;
    }

    private onSetActionUpdate() {
        this.selectedAction = LinkAction.Replace;
    }

    private onSelectLinkType(linkType: TypeLinkDataInfo) {
        this.selectedLinkType = linkType;
    }

    //#endregion - event handlers

    private resetForm() {
        this.targetEntities = [];
        this.selectedLinkType = null;
        this.selectedLinkTypeOption = null;
        this.linkTypeOptions = this.buildLinkTypeOptions(
            this.availableLinkTypes
        );
        this.updateEntitySelectorData();
    }

    private updateAvailableLinkTypes() {
        const linkTypes = this.filterLinkTypesWithTargets(
            this.targetEntities,
            this.isDelete
        );
        this.linkTypeOptions = this.buildLinkTypeOptions(linkTypes);
        if (!this.isDelete) {
            this.targetErrorMessageKey = !linkTypes.length
                ? 'UI.BulkEdit.noLinkTypeAvailableForTargets'
                : '';
        }
    }

    private filterLinkTypesWithTargets(
        targetEntities: EntityItem[],
        isDelete?: boolean
    ) {
        const availableLinkTypes = this.availableLinkTypes;
        if (!targetEntities?.length) {
            return availableLinkTypes;
        }

        return availableLinkTypes.filter((linkType) => {
            if (isDelete) {
                return this.areEntitiesLinkedToType(targetEntities, linkType);
            }
            return (
                this.areEntityTypesHandledByType(targetEntities, linkType) &&
                (!this.areEntitiesLinkedToType(targetEntities, linkType) ||
                    this.allowExistingLinks)
            );
        });
    }

    private areEntityTypesHandledByType(
        entities: IEntityIdentifier[],
        linkType: TypeLinkDataInfo
    ) {
        const entityTypes = entities.map((entity) => entity.entityType);
        return entityTypes.every((entityType) =>
            linkType.TargetEntityTypes.includes(entityType)
        );
    }

    private areEntitiesLinkedToType(
        entities: IEntityIdentifier[],
        linkType: TypeLinkDataInfo
    ) {
        const entityIds = entities.map((entity) => entity.ReferenceId);
        return entityIds.every((id) =>
            linkType.ExcludedEntitiesIds.includes(id)
        );
    }

    private init() {
        this._entityDataList = this.data.entityDataList.slice() ?? [];
        this.spaceIdr = SpaceIdentifier.fromEntity(this.entityData, true);
        const linkTypes = (this.availableLinkTypes = this.getAvailableLinkTypes(
            this.isDelete
        ));
        this.linkTypeOptions = this.buildLinkTypeOptions(linkTypes);
        this.noWriteAccessEntityDataList = this._entityDataList.filter(
            (a) => a.SecurityData.HasWriteAccess == false
        );
        this.targetEntities = this.data.targetEntityDataList;

        if (this.data.targetEntityDataList) {
            this.updateAvailableLinkTypes();
        }
        this.updateEntitySelectorData();
    }

    private getAvailableLinkTypes(onlyLinkTypeUsed: boolean) {
        const availableLinkTypes =
            this.getAvailableLinkTypesFilteredByModules();
        return CoreUtil.cloneDeep(
            onlyLinkTypeUsed /** Get Only linkTypes with linked Data */
                ? availableLinkTypes.filter(
                      (link) => link.LinkedEntitiesIds.length > 0
                  )
                : availableLinkTypes
        );
    }

    private getAvailableLinkTypesFilteredByModules() {
        const accessibleModules = this.moduleStore.modules.map(
            (module) => DgModule[module.name]
        );
        return this.data.availableLinkTypes.filter((linkType) =>
            linkType.TargetEntityTypes.some((et) =>
                accessibleModules.includes(DataUtil.getModuleFromEntityType(et))
            )
        );
    }

    private buildLinkTypeOptions(
        linkTypes: TypeLinkDataInfo[]
    ): IListOption<TypeLinkDataInfo>[] {
        const options: IListOption<TypeLinkDataInfo>[] = linkTypes
            ?.map((linkType) => {
                const isExistingLink = this.targetEntities.every((entity) =>
                    linkType.ExcludedEntitiesIds.includes(
                        entity.DataReferenceId
                    )
                );
                if (!isExistingLink && this.data.onlyExistingLinks) {
                    return;
                }
                const option: IListOption<TypeLinkDataInfo> = {
                    labelKey: linkType.displayNameTranslateKey,
                    data: linkType,
                };
                if (this.allowExistingLinks) {
                    option.categoryKey = isExistingLink ? 'exist' : 'new';
                }
                return option;
            })
            .filter(
                (o) => o && (o.categoryKey !== 'new' || this.allowNewLinks)
            );
        if (options?.length === 1) {
            this.onSelectLinkTypeOption(options[0]);
            return options;
        }

        if (this.isUpdate && options?.length) {
            const olt = this.data.linkToUpdate.linkType;
            const opt = options.find(
                (o) => o.data.UniversalObjectLinkType == olt
            );
            opt && this.onSelectLinkTypeOption(opt);
        }

        if (!this.isTargetReadOnly) {
            options.unshift({ labelText: '', data: null });
        }
        return CollectionsHelper.orderBy(options, (o) =>
            o.categoryKey ? (o.categoryKey === 'exist' ? -1 : 1) : 0
        );
    }

    private async entityLinkedObjectAction(): Promise<IEntityLinkIdentifier[]> {
        const selectedLinkType = this.selectedLinkType;
        this.isSaving = true;
        try {
            if (this.isUpdate) {
                const linkReferenceId = this.data.linkToUpdate.linkReferenceId;
                if (
                    !(await this.linkedObjectService.updateLinkType(
                        linkReferenceId,
                        selectedLinkType.UniversalObjectLinkType
                    ))
                ) {
                    return;
                }
                return [
                    {
                        source: EntityIdentifier.from(
                            this.data.entityDataList[0]
                        ),
                        target: EntityIdentifier.from(
                            this.data.targetEntityDataList[0]
                        ),
                        linkReferenceId,
                        linkType: selectedLinkType.UniversalObjectLinkType,
                    },
                ];
            } else {
                const entities = this._entityDataList;
                const versionId = entities[0].VersionId;
                const sourceEntities = entities.map(
                    (entity) => entity.HddData.Data
                );
                const sourceListIds = sourceEntities.map(
                    (hdd) => hdd.DataReferenceId
                );
                const targetListIds = this.targetEntities.map(
                    (a) => a.HddData.Data.DataReferenceId
                );
                if (this.isDelete) {
                    const confirmed = await this.entityUiService.confirmDelete(
                        ServerType.EntityLink,
                        { count: targetListIds.length }
                    );
                    if (!confirmed) {
                        return;
                    }
                    const sourceServerType = entities[0].DataServerType;
                    await this.linkedObjectService.bulkUnlinkData(
                        selectedLinkType.UniversalObjectLinkType,
                        sourceListIds,
                        sourceServerType,
                        targetListIds,
                        versionId
                    );
                    CollectionsHelper.remove(
                        selectedLinkType.LinkedEntitiesIds,
                        (id) => targetListIds.includes(id)
                    );
                    return;
                } else {
                    const actionType = this.isActionReplace
                        ? LinkAction.Replace
                        : LinkAction.Append;
                    const allLinksAlreadyExist = this.targetEntities.every(
                        (entity) =>
                            selectedLinkType.ExcludedEntitiesIds.includes(
                                entity.DataReferenceId
                            )
                    );
                    const linkType = selectedLinkType.UniversalObjectLinkType;
                    if (allLinksAlreadyExist) {
                        const los = await this.getExistingEntityLinkedObjects(
                            this._entityDataList,
                            this.targetEntities,
                            selectedLinkType
                        );
                        return los.map((lo) => lo.linkIdr);
                    }
                    try {
                        const result = await this.linkedObjectService.linkData(
                            selectedLinkType.UniversalObjectLinkType,
                            sourceListIds,
                            targetListIds,
                            versionId,
                            actionType as number
                        );

                        selectedLinkType.ExcludedEntitiesIds.push(
                            ...targetListIds
                        );
                        this.selectedAction = LinkAction.Append;
                        return result?.Links.map((link) => ({
                            linkReferenceId: link.LinkEntity.DataReferenceId,
                            linkType,
                            // (fbo) not sure when multiple entities... and not needed so far.
                            source:
                                entities.length == 1 ? entities[0] : undefined,
                            target:
                                link.LinkedEntities?.length == 1
                                    ? link.LinkedEntities[0]
                                    : undefined,
                        }));
                    } catch (e) {
                        this.targetEntities.length = 0;
                        if (
                            isUnsuccessfulApiError(e) &&
                            e.error?.ErrorDetails
                        ) {
                            this.errorMessage = e.error.ErrorDetails;
                        } else {
                            throw e;
                        }
                    }
                }
            }
        } finally {
            this.isSaving = false;
        }
    }

    private async getExistingEntityLinkedObjects(
        sourceEntities: EntityItem[],
        targetEntities: EntityItem[],
        linkType: TypeLinkDataInfo
    ) {
        const promiseLinkIds = CollectionsHelper.flattenGroups(
            sourceEntities,
            (source) =>
                targetEntities?.map((target) =>
                    this.getExistingEntityLinkedObject(source, target, linkType)
                )
        );
        return Promise.all(promiseLinkIds);
    }

    private async getExistingEntityLinkedObject(
        sourceElement: EntityItem,
        targetElement: EntityItem,
        linkTypeInfo: TypeLinkDataInfo
    ) {
        const sourceIdr = sourceElement;
        const objectLinkType = linkTypeInfo.UniversalObjectLinkType;
        const result = await this.entityService.getEntityLinks(sourceIdr, [
            objectLinkType,
        ]);
        const group = result.Groups.find(
            (g) => g.UniversalObjectLinkType == objectLinkType
        );
        const targetId = targetElement.DataReferenceId;
        const ldi = group?.Items?.find(
            (it) => it.LinkedData.DataReferenceId == targetId
        );

        if (!ldi) {
            this.log('linkObject not found', {
                sourceElement,
                targetElement,
                group,
                result,
            });
        }
        return EntityLinkService.makeLinkedObject(
            sourceElement,
            ldi,
            objectLinkType
        );
    }

    private getIncludedEntityTypes() {
        return this.getSelectedLinkTypePropertyOrFlattenProperties(
            (linkType) => linkType.TargetEntityTypes
        );
    }

    private getExcludedIds(): string[] {
        if (this.isDelete) {
            return [];
        }
        const sourceIds = this._entityDataList.map(
            (entity) => entity.DataReferenceId
        );
        const selectedLinkType = this.selectedLinkType;
        const excludedIds = selectedLinkType
            ? selectedLinkType.ExcludedEntitiesIds
            : CollectionsHelper.getArraysCommonValues(
                  ...this.availableLinkTypes.map(
                      (linkType) => linkType.ExcludedEntitiesIds
                  )
              );
        return [...sourceIds, ...(excludedIds ?? [])];
    }

    private getIncludedIds(): string[] {
        if (!this.isDelete) {
            return [];
        }
        return this.getSelectedLinkTypePropertyOrFlattenProperties(
            (linkType) => linkType.LinkedEntitiesIds
        );
    }

    private getSelectedLinkTypePropertyOrFlattenProperties(
        getProperty: (linkType: TypeLinkDataInfo) => any
    ) {
        const selectedLinkType = this.selectedLinkType;
        return selectedLinkType
            ? getProperty(selectedLinkType)
            : CollectionsHelper.flatten(
                  this.availableLinkTypes.map(getProperty),
                  true
              );
    }

    private updateEntitySelectorData() {
        if (this.isTargetReadOnly) {
            return;
        }
        const tesd = this.targetEntitiesSelectorData;
        const includedEntityTypes = this.getIncludedEntityTypes();
        const excludedIds = this.getExcludedIds();
        const includedIds = this.getIncludedIds();
        const isSpotlightEnabled = !!includedIds?.length || !this.isDelete;

        this.log('updateEntitySelectorData targetEntitiesSelectorData', {
            spaceIdr: this.spaceIdr,
            excludedIds,
            includedIds,
            includedEntityTypes,
            isSpotlightEnabled,
        });

        tesd.setSpaceIdr(this.spaceIdr);
        tesd.setMinChars(0);
        tesd.setExcludedIds(excludedIds);
        tesd.setIncludedIds(includedIds);
        tesd.setEnabled(isSpotlightEnabled);
        tesd.setIncludedEntityTypes(includedEntityTypes);
        tesd.setInitialSearch(true);
        tesd.updateInstance();
        tesd.useParentFilter();
    }
}

enum LinkAction {
    Append = AddLinkAction.Append,
    Replace = AddLinkAction.Replace,
}
