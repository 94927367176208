import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { ClientIntegrationDTO } from './integration.dto';

@inheritSerialization(BaseServiceParameter)
export class ClientIntegrationParameter extends BaseServiceParameter {
    @serialize public ClientIntegrationId?: number;
    @serialize public DisplayName: string;
    @serialize public IsEnabled?: boolean;
    @serialize public IsClientAdmin: boolean;

    constructor(
        displayName?: string,
        isClientAdmin: boolean = false,
        integrationId?: number,
        isEnabled?: boolean
    ) {
        super();
        this.DisplayName = displayName ?? '';
        this.ClientIntegrationId = integrationId;
        this.IsEnabled = isEnabled;
        this.IsClientAdmin = isClientAdmin;
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetUserIntegrationParameter extends BaseServiceParameter {
    @serialize public ClientId: string;

    constructor(ClientId: string) {
        super();
        this.ClientId = ClientId;
    }
}

@inheritSerialization(BaseServiceResult)
export class ClientIntegrationResult extends BaseServiceResult {
    @deserializeAs(ClientIntegrationDTO)
    public Integration!: ClientIntegrationDTO;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class GetClientIntegrationsResult extends BaseServiceResult {
    @deserializeAs(ClientIntegrationDTO)
    public Integrations!: ClientIntegrationDTO[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserIntegrationResult extends BaseServiceResult {
    @deserialize public SpaceUId!: string;
    @deserialize public IsEnabled!: boolean;
    @deserialize public State!: string;
    @deserialize public WebHook!: string;
    @deserialize public Url!: string;
    @deserialize public ClientId!: string;

    constructor() {
        super();
    }
}
