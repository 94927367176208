import { TranslationBatch } from '@datagalaxy/webclient/multilingual/domain';
import { MultilingualState } from '../../multilingual-state';

export const selectPendingTranslationMessageKeys =
    (languageCode: string) =>
    (state: MultilingualState): string[] => {
        const batches =
            state.pendingBatches?.filter(
                (b) => b.targetLanguageCode === languageCode
            ) ?? [];
        const importIds = state.liveImportIds;
        return batches
            .map((b) => getBatchMessageKey(b))
            .concat(importIds.map(() => 'importMessage'));
    };

function getBatchMessageKey(batch: TranslationBatch): string {
    let messageKey = 'addLanguageMessage';
    switch (batch.origin) {
        case 'languageAdded':
            messageKey = 'addLanguageMessage';
            break;
        case 'attributeAdded':
            messageKey = 'addAttributesMessage';
            break;
        case 'import':
            messageKey = 'importMessage';
    }

    return `Multilingual.Administration.LanguagesTab.TranslationProgressTooltip.${messageKey}`;
}
