import { CoreUtil } from '@datagalaxy/core-util';
import {
    EntityType,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { DiagramKind } from '@datagalaxy/webclient/diagram/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export interface IDiagramIdentifier extends IEntityIdentifier {
    readonly diagramKind: DiagramKind;
}

export class DiagramIdentifier implements IDiagramIdentifier {
    //#region static

    public static fromEntity(diagram: EntityItem) {
        if (!diagram) {
            CoreUtil.warn('no entity');
            return;
        }
        const diagramKind =
            DiagramKind[
                diagram.getAttributeValue<string>(
                    ServerConstants.Diagram.DiagramKind
                )
            ];
        if (!diagramKind) {
            CoreUtil.warn('no DiagramKind');
            return;
        }
        return new DiagramIdentifier(
            diagram.ReferenceId,
            diagram.VersionId,
            diagramKind
        );
    }

    public static getEntityTypeFromDiagramKind(diagramKind: DiagramKind) {
        switch (diagramKind) {
            case DiagramKind.Free:
                return EntityType.FreeDiagram;
            case DiagramKind.MonoSourceDatabase:
            case DiagramKind.MultiSources:
                return EntityType.PhysicalDiagram;
        }
    }

    public static isDiagramEntity(entity: EntityItem) {
        return entity?.ServerType == ServerType.Diagram;
    }

    public static isModelerDiagramKind(diagramKind: DiagramKind) {
        return DiagramIdentifier.isModelerDiagramEntityType(
            DiagramIdentifier.getEntityTypeFromDiagramKind(diagramKind)
        );
    }
    public static isModelerDiagramEntityType(diagramEntityType: EntityType) {
        return diagramEntityType == EntityType.PhysicalDiagram;
    }

    public static isModelerDiagram(idr: IDiagramIdentifier) {
        return DiagramIdentifier.isModelerDiagramEntityType(idr?.entityType);
    }
    public static isMultiSourceDiagram(idr: IDiagramIdentifier) {
        return (
            idr &&
            DiagramIdentifier.isModelerDiagram(idr) &&
            idr.diagramKind == DiagramKind.MultiSources
        );
    }
    public static isMonoSourceDatabaseDiagram(idr: IDiagramIdentifier) {
        return (
            idr &&
            DiagramIdentifier.isModelerDiagram(idr) &&
            idr.diagramKind == DiagramKind.MonoSourceDatabase
        );
    }

    //#endregion - static

    public readonly ServerType: ServerType;
    public readonly entityType: EntityType;

    public constructor(
        public readonly ReferenceId: string,
        public readonly VersionId: string,
        public readonly diagramKind: DiagramKind
    ) {
        if (typeof diagramKind == 'string') {
            CoreUtil.warn('diagramKind as string');
            this.diagramKind = DiagramKind[
                diagramKind
            ] as unknown as DiagramKind;
        }
        this.entityType = DiagramIdentifier.getEntityTypeFromDiagramKind(
            this.diagramKind
        );
        this.ServerType = EntityTypeUtil.getServerType(this.entityType);
    }
}
