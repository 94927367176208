<ng-container *ngIf="!isHeader">
    <dxy-collapsible [titleKey]="titleKey" class="reflex-shadow big-header">
        <form class="form-horizontal entity-form">
            <dxy-entity-attribute-input
                *ngFor="let fi of fieldInfos"
                [fieldInfo]="fi"
                [translatable]="true"
            ></dxy-entity-attribute-input>
        </form>
    </dxy-collapsible>
</ng-container>
<ng-container *ngIf="isHeader">
    <form class="entity-header-form">
        <dxy-entity-attribute-input
            *ngFor="let fi of fieldInfos"
            [fieldInfo]="fi"
            [mini]="true"
            [enablePopover]="true"
        ></dxy-entity-attribute-input>
    </form>
</ng-container>
