<div class="header">
    <div class="header-actions">
        <!--Search-->
        <dxy-search-input
            class="no-border-top"
            [searchString]="searchString"
            (onSearchString)="onSearch($event.searchString)"
            [showCancelSearch]="true"
        ></dxy-search-input>

        <span class="silent-label" translate> UI.Global.silent </span>
        <mat-slide-toggle
            color="primary"
            [ngModel]="realtimeNotificationDisabled"
            (ngModelChange)="onRealtimeNotificationDisabledChange($event)"
            [dxyLogFunctional]="getFeatureCodeNotifActive()"
        >
        </mat-slide-toggle>
    </div>

    <dxy-tabs-header
        [tabs]="headerTabs"
        (tabChange)="onChangeTab($event)"
    ></dxy-tabs-header>

    <span
        *ngIf="showAcknowledgeAll"
        (click)="markAllAsAcknowledged($event)"
        class="acknowledge-all"
        translate
    >
        UI.NotificationContainer.AcknowledgeAll
    </span>
</div>

<div class="body">
    <dxy-spinner *ngIf="loading" class="xl vertically-centered"></dxy-spinner>
    <cdk-virtual-scroll-viewport *ngIf="!showPlaceholder" itemSize="50">
        <app-notification-item
            *cdkVirtualFor="let notification of filteredNotifications"
            [notification]="notification"
        ></app-notification-item>
    </cdk-virtual-scroll-viewport>

    <div *ngIf="showPlaceholder" class="no-notif-container">
        <img
            src="/images/placeholders/notifications.svg"
            class="dg_no-item-image"
            alt="no notification"
        />
        <span class="no-notif-title" translate
            >UI.NotificationContainer.titleLbl</span
        >
        <span class="no-notif-message" translate
            >UI.NotificationContainer.msgNoNotification</span
        >
    </div>
</div>
