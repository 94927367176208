<dxy-field-date
    [(date)]="dateValue"
    [minDate]="minDateValue"
    [maxDate]="maxDateValue"
    [readonly]="readonly"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [mandatory]="mandatory"
    [isClearable]="isClearAvailable"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    (openClose)="onPanelOpenClose.emit($event)"
    [class.mini]="mini"
></dxy-field-date>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>
