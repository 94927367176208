import {
    style,
    animate,
    trigger,
    transition,
    state,
} from '@angular/animations';

/** Create a right to left slide animation at dom enter
 * @param {string} xTranslation - animated Component translation distance
 * @param {string} duration - animation duration
 * @param {string} closedOpacity - element opactiy in closed state
 * @example
 * ```
 * <div *ngIf="open" @rightToLeftEnter></div>
 * ```
 */
export const rightToLeftEnterAnimation = (
    xTranslation = '500px',
    duration = '.2s',
    closedOpacity = 0
) => {
    const { openTransition, closeTransition } = getParams(
        xTranslation,
        duration,
        closedOpacity
    );
    return trigger('rightToLeftEnter', [
        transition(':enter', openTransition),
        transition(':leave', closeTransition),
    ]);
};

/** Create a right to left slide animation at state change
 * @param {string} width - animated Component width
 * @example <div [@rightToLeftOpen]="open ? 'open': 'closed'"></div>
 */
export const rightToLeftOpenAnimation = (width = '500px') => {
    const { openStyle, closedStyle, openTransition, closeTransition } =
        getParams(width);
    return trigger('rightToLeftOpen', [
        state('open', openStyle),
        state('closed', closedStyle),
        transition('open => closed', closeTransition),
        transition('closed => open', openTransition),
    ]);
};

const getParams = (
    xTranslation: string,
    duration = '.15s',
    closedOpacity = 0
) => {
    const openStyle = style({ transform: `translateX(0px)`, opacity: 1 });
    const closedStyle = style({
        transform: `translateX(${xTranslation})`,
        opacity: closedOpacity,
    });

    return {
        openStyle,
        closedStyle,
        openTransition: [
            closedStyle,
            animate(`${duration} ease-out`, openStyle),
        ],
        closeTransition: [animate(`${duration} ease-in`, closedStyle)],
    };
};
