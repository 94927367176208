import { SerializableEnumeration } from 'cerialize';

export enum ObjectTaskType {
    None = 0,
    AddDescription,
    UpdateDescription,
    AddContact,
    UpdateContact,
    AddDomain,
    UpdateDomain,
    AddPII,
    UpdatePII,
    Duplicate,
    Question,
    AddObject,
    UpdateAttribute,
    MoveObject,
    GrantAccess,
    ValidateChange,
    DataQualityReview,
}
SerializableEnumeration(ObjectTaskType);
