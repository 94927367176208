import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import {
    IValueListFilterData,
    ValueListFilterOperator,
} from '@datagalaxy/core-ui/filters';
import { ImportContext, ImportMode } from '../../../shared/ImportContext';
import { GlyphUtil } from '../../../../shared/util/GlyphUtil';
import { SupportTicketService } from '../../../../shared/support/feedback/support-ticket.service';
import { SecurityService } from '../../../../services/security.service';
import { NavigationService } from '../../../../services/navigation.service';
import { CurrentSpaceService } from '../../../../services/currentSpace.service';
import { ConnectorService } from '../../../../connector/connector.service';
import { ModelType } from '@datagalaxy/dg-object-model';
import { EntitySecurityService } from '../../../../shared/entity/services/entity-security.service';
import {
    Connector,
    ConnectorPackage,
    ConnectorRunMode,
    IConnectorPlugin,
    PluginType,
} from '@datagalaxy/webclient/connectivity/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ModuleStore } from '../../../../module/module.store';
import { map, Observable } from 'rxjs';
import { DgModuleDefinition } from '@datagalaxy/shared/dg-module/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { publicApiDocumentationUrl } from '@datagalaxy/data-access';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyLogFunctionalDirective } from '../../../../directives/dxy-log-functional.directive';
import { FormsModule } from '@angular/forms';
import { DxyValueListFilterComponent } from '@datagalaxy/core-ui/filters';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { DxyCsvFileDepositCardComponent } from '../dxy-csv-file-deposit-card/dxy-csv-file-deposit-card.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-solution-selection',
    templateUrl: './dxy-solution-selection.component.html',
    styleUrls: ['./dxy-solution-selection.component.scss'],
    standalone: true,
    imports: [
        DxyCsvFileDepositCardComponent,
        NgIf,
        TranslateModule,
        MatLegacyButtonModule,
        DxyValueListFilterComponent,
        FormsModule,
        DxyLogFunctionalDirective,
        NgFor,
        NgClass,
        DxyDataTestIdDirective,
        SpinnerComponent,
        AsyncPipe,
    ],
})
export class DxySolutionSelectionComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() importContext: ImportContext;
    @Output() readonly onCloseModal = new EventEmitter<void>();
    @Output() readonly onClickSolution = new EventEmitter<{
        solution: IConnectorPlugin;
        forceDetails: boolean;
    }>();
    @Output() readonly onClickCsv = new EventEmitter<{
        files: File[];
        forceDetails: boolean;
    }>();
    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();

    public readonly DataGalaxyModule = DgModule;
    public publicApiUrl = publicApiDocumentationUrl;
    public filteredSolutions: IConnectorPlugin[];
    public searchTerm = '';
    public moduleFilter: IValueListFilterData<DgModule> = {
        operator: ValueListFilterOperator.Equals,
        values: [DgModule.unknown],
    };
    public moduleFilterAdapter = {
        getTextKey: (o) => `DgServerTypes.DataGalaxyModule.${DgModule[o]}`,
        getGlyphClass: (o) => GlyphUtil.getModuleColoredGlyphClass(o),
    };
    public moduleFilterOptions$: Observable<DgModule[]>;
    public connectionTypeFilter: IValueListFilterData<ImportMode> = {
        operator: ValueListFilterOperator.Equals,
        values: [ImportMode.Unknown],
    };
    public connectionTypeFilterAdapter = {
        getTextKey: (o) =>
            `Import.GenericImportWizard.SolutionSelection.FiltersHeader.connectionType.${ImportMode[o]}`,
        getGlyphClass: (o) => GlyphUtil.getImportModeGlyphClass(o),
    };
    public connectionTypeOptions: ImportMode[];
    public isLoading = true;
    public existingConnexionsCount = 0;

    public get shouldRequestDesktopPlugins() {
        return this.securityService.isDesktopConnectorEnabled();
    }

    public get shouldRequestOnlinePlugins() {
        return this.securityService.isInstanceOnlineConnectorEnabled();
    }

    public get isOnlineConnectorEnabled() {
        return this.securityService.isOnlineConnectorEnabled();
    }

    public get hasManagementAccess() {
        return this.securityService.hasManagementAccess(
            this.currentSpaceService.getCurrentOrLastSpace()
        );
    }

    public get showExistingConnections() {
        return (
            this.isOnlineConnectorEnabled &&
            this.importContext.hasInitialWorkspace &&
            this.hasManagementAccess
        );
    }

    public get isSourceTypeDisplayed() {
        return !!this.importContext.initialSourceType;
    }

    public get showFeedback() {
        return this.supportTicketService.isFeedbackEnabled;
    }

    public get selectedModule() {
        return this.moduleFilter.values?.[0] ?? DgModule.unknown;
    }

    public get selectedConnectionType() {
        return this.connectionTypeFilter.values?.[0] ?? ImportMode.Unknown;
    }

    public set selectedConnectionType(value: ImportMode) {
        this.connectionTypeFilter.values = [value];
    }

    public get sourceTypeTranslationKey() {
        return `DgServerTypes.ModelType.${
            ModelType[this.importContext.initialSourceType]
        }`;
    }

    private onlineSolutions: IConnectorPlugin[];
    private connectorsDesktop: IConnectorPlugin[];
    private connectorsOnline: IConnectorPlugin[];

    constructor(
        private supportTicketService: SupportTicketService,
        private securityService: SecurityService,
        private entitySecurityService: EntitySecurityService,
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
        private connectorService: ConnectorService,
        private currentSpaceService: CurrentSpaceService,
        private moduleStore: ModuleStore
    ) {
        super();
    }

    ngOnInit() {
        this.connectionTypeOptions = [ImportMode.Unknown]; // all
        if (this.shouldRequestDesktopPlugins) {
            this.connectionTypeOptions.push(ImportMode.DesktopConnector);
        }
        if (this.shouldRequestOnlinePlugins) {
            this.connectionTypeOptions.push(ImportMode.OnlineConnector);
        }
        this.selectedConnectionType = this.importContext.initialImportMode;
        if (this.selectedConnectionType === ImportMode.Csv) {
            this.selectedConnectionType = ImportMode.Unknown;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => this.init().then());
    }

    public getSolutionDescription(solution: IConnectorPlugin) {
        return solution?.description[this.getLanguageCode()];
    }

    public getSolutionClass(solution: IConnectorPlugin) {
        return solution?.pluginType === PluginType.Online &&
            !this.isOnlineConnectorEnabled
            ? 'disabled'
            : undefined;
    }

    public getTagTranslateKey(solution: IConnectorPlugin) {
        return `Import.GenericImportWizard.SolutionSelection.Tag${solution.pluginType}`;
    }

    public getTagClass(solution: IConnectorPlugin) {
        return solution.pluginType == PluginType.Online
            ? 'online-tag'
            : 'desktop-tag';
    }

    public getTagIconClass(solution: IConnectorPlugin) {
        return solution.pluginType == PluginType.Online
            ? 'glyph-online'
            : 'glyph-desktop';
    }

    public getSolutionTitle(solution: IConnectorPlugin) {
        return solution?.title[this.getLanguageCode()];
    }

    public isOnlineSolution(solution: IConnectorPlugin) {
        return solution.pluginType === PluginType.Online;
    }

    public getTestId(solution: IConnectorPlugin) {
        return `${solution.name}-${
            solution.pluginType === PluginType.Online ? 'online' : 'desktop'
        }`;
    }

    //#region event handlers

    public async onClickDeleteSourceTypeFilter() {
        this.importContext.initialSourceType = null;
        await this.updateFilteredSolutions();
    }

    public onClickConnector() {
        this.navigationService.goToSpaceConnector(this.importContext.spaceIdr);
        this.onCloseModal.emit();
    }

    public onClickSolutionCard(
        solution: IConnectorPlugin,
        $event?: MouseEvent,
        forceDetails = false
    ) {
        if (forceDetails) {
            $event.stopPropagation();
        }
        forceDetails = forceDetails || !this.hasManagementAccess;
        this.onClickSolution.emit({ solution, forceDetails });
    }

    public onClickApiCard() {
        window.open(this.publicApiUrl);
    }

    public onClickCsvBtn(result: { files: File[]; forceDetails?: boolean }) {
        const selectedFiles = result.files ?? [];
        this.onClickCsv.emit({
            files: selectedFiles,
            forceDetails: result.forceDetails,
        });
    }

    public onClickFeedBack() {
        this.supportTicketService.openFeedbackGuide();
        this.onCloseModal.emit();
    }

    public async onUpdateSearchTerm() {
        this.log('onUpdateSearchTerm', this.searchTerm);
        await this.updateFilteredSolutions();
    }

    public async onConnectionTypeFilterChange() {
        this.importContext.initialImportMode = this.selectedConnectionType;
        await this.updateConnectionType(this.selectedConnectionType);
    }

    public async onModuleFilterChange() {
        this.moduleFilter.values = [this.selectedModule];
        this.importContext.initialModule = this.selectedModule;
        await this.updateFilteredSolutions();
    }

    //#endregion

    private async init() {
        this.log('init-start');
        try {
            if (
                !this.shouldRequestDesktopPlugins &&
                !this.shouldRequestOnlinePlugins
            ) {
                return;
            }
            this.initModuleOptions();
            await this.initPlugins();
            await this.updateFilteredSolutions();
            if (!this.showExistingConnections) {
                return;
            }
            this.existingConnexionsCount =
                await this.connectorService.getConnectionsCount();
        } catch (err) {
            this.log('init-err', err);
        } finally {
            this.log('init-end');
            this.isLoading = false;
        }
    }

    private initModuleOptions() {
        const space = this.currentSpaceService.getCurrentOrLastSpace();

        let options = CollectionsHelper.getEnumValues(
            DgModule,
            DgModule.Diagram
        );
        if (space) {
            options = options.filter((module) =>
                this.entitySecurityService.hasImportAccessToModule(
                    space,
                    module
                )
            );
            if (options.length > 1) {
                options.unshift(DgModule.unknown);
            }
        }

        this.moduleFilterOptions$ = this.moduleStore
            .selectModules()
            .pipe(map((modules) => this.loadModuleOptions(modules)));
        const initialValue = this.importContext.initialModule;
        this.moduleFilter.values = options.includes(initialValue)
            ? [this.importContext.initialModule]
            : options.length
            ? [options[0]]
            : [DgModule.unknown];
    }

    private loadModuleOptions(modules: DgModuleDefinition[]): DgModule[] {
        const moduleOptions = modules.map((module) => DgModule[module.name]);
        moduleOptions.unshift(DgModule.unknown);

        return moduleOptions;
    }

    private async initPlugins() {
        this.log('initPlugins-start');
        let allPlugins: IConnectorPlugin[] = [];
        if (this.shouldRequestDesktopPlugins) {
            try {
                const connectorPackages = await this.getConnectorPackages();
                this.connectorsDesktop = connectorPackages.map((pgk) =>
                    this.packageToConnectorPlugin(pgk)
                );
            } catch (err) {
                CoreUtil.warn('initPlugins', err);
            }
            if (
                this.connectorsDesktop?.length &&
                this.selectedConnectionType !== ImportMode.OnlineConnector
            ) {
                allPlugins.push(...this.connectorsDesktop);
            }
            this.log(
                'initPlugins-connectorsDesktop',
                this.connectorsDesktop?.length
            );
        }

        if (this.shouldRequestOnlinePlugins) {
            this.connectorsOnline = (
                await this.connectorService.getConnectorsOnline()
            ).map((c) => this.connectorToConnectorPlugin(c));
            this.log(
                'initPlugins-connectorsOnline',
                this.connectorsOnline?.length
            );
            if (
                this.connectorsOnline?.length &&
                this.selectedConnectionType !== ImportMode.DesktopConnector
            ) {
                allPlugins.push(...this.connectorsOnline);
            }
        }
        this.filteredSolutions = CollectionsHelper.orderByText(
            allPlugins,
            (plugin) => plugin.name
        );
        if (this.importContext.showOnlineConnectorsOnly) {
            await this.updateConnectionType(ImportMode.OnlineConnector);
        }
        this.log(
            'initPlugins-end',
            this.onlineSolutions?.length,
            this.filteredSolutions?.length
        );
    }

    private async getConnectorPackages() {
        this.log('getConnectorPackages-start');
        const connectorPackages =
            await this.connectorService.getConnectorPackages();
        this.log('getConnectorPackages-end', connectorPackages?.length);
        return connectorPackages;
    }

    private getLanguageCode(): string {
        return this.currentUserService.userInfo.LanguageCode;
    }

    private async updateConnectionType(connectionType: ImportMode) {
        this.log('updateConnectionType-start');
        this.selectedConnectionType = connectionType;
        await this.updateFilteredSolutions();
        this.log('updateConnectionType-end');
    }

    private async updateFilteredSolutions() {
        this.log('updateFilteredSolutions-start');
        const connectors = [];
        if (this.selectedConnectionType === ImportMode.DesktopConnector) {
            connectors.push(...this.connectorsDesktop);
        } else if (this.selectedConnectionType === ImportMode.OnlineConnector) {
            connectors.push(...this.connectorsOnline);
        } else {
            connectors.push(...this.connectorsDesktop);
            connectors.push(...this.connectorsOnline);
        }
        const searchTerm = this.searchTerm.trim().toLowerCase();
        const searchTermFilter =
            searchTerm !== ''
                ? (c: IConnectorPlugin) =>
                      c.title.fr.toLowerCase().includes(searchTerm) ||
                      c.title.en.toLowerCase().includes(searchTerm) ||
                      c.name.toLowerCase().includes(searchTerm)
                : () => true;

        const sourceType = ModelType[this.importContext.initialSourceType];
        const sourceTypeFilter = this.isSourceTypeDisplayed
            ? (c: IConnectorPlugin) => c.sourceType === sourceType
            : () => true;

        const selectedModule = this.selectedModule;
        const moduleFilter =
            selectedModule !== DgModule.unknown
                ? (c: IConnectorPlugin) =>
                      c.modules.map((m) => DgModule[m]).includes(selectedModule)
                : () => true;

        const filteredConnectors = connectors.filter(
            (c) => searchTermFilter(c) && sourceTypeFilter(c) && moduleFilter(c)
        );

        this.filteredSolutions = CollectionsHelper.orderByText(
            filteredConnectors,
            (p) => p.name
        );
        this.log('updateFilteredSolutions-end', this.filteredSolutions?.length);
    }

    private connectorToConnectorPlugin(connector: Connector): IConnectorPlugin {
        const iconUrl = this.connectorService.getOnlineConnectorImageUrl(
            connector.name
        );
        return {
            ...connector,
            iconUrl,
            pluginType: PluginType.Online,
        };
    }

    private packageToConnectorPlugin(pkg: ConnectorPackage): IConnectorPlugin {
        const iconUrl = this.connectorService.getOnlineConnectorImageUrl(
            pkg.name
        );
        return {
            name: pkg.name,
            title: {
                fr: pkg._meta.title.fr,
                en: pkg._meta.title.en,
            },
            description: {
                fr: pkg._meta.description.fr,
                en: pkg._meta.description.en,
            },
            runModes: [ConnectorRunMode.JAR],
            modules: pkg._meta.modules,
            iconUrl,
            sourceType: pkg._meta.sourceType,
            version: pkg.version,
            pluginType: PluginType.Desktop,
        };
    }
}
