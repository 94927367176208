import { Point, simplifyPath, Vect2 } from '@datagalaxy/core-2d-util';
import { ChainList } from '@datagalaxy/utils';

/** Vertical or horizontal chained segment */
export class OrthoSegment<T = unknown> extends ChainList<OrthoSegment<T>> {
    /**
     * Creates an array of OrthoSegments from an array of points.
     *
     * @template T - The type of data to associate with each OrthoSegment.
     * @param {Point[]} points - An array of points representing the vertices of the segments.
     * @param {OrthoSegment<T>} [prev=null] - The previous OrthoSegment in the sequence, if any.
     * @returns {OrthoSegment<T>[]} - An array of OrthoSegments created from the given points.
     */
    public static fromPoints<T>(
        points: Point[],
        prev: OrthoSegment<T> = null
    ): OrthoSegment<T>[] {
        if (!(points?.length > 1)) {
            return [];
        }
        const res: OrthoSegment<T>[] = [];
        const l = points.length - 1;
        for (let i = 0; i < l; i++) {
            const s = new OrthoSegment(points[i], points[i + 1], prev, null);
            res.push(s);
            prev = s;
        }
        return res;
    }

    public get length() {
        return Vect2.fromPoints(this.startPoint, this.endPoint)?.length();
    }
    public get isVertical() {
        return Vect2.isV(this.startPoint, this.endPoint);
    }
    public get isHorizontal() {
        return Vect2.isH(this.startPoint, this.endPoint);
    }

    public get data() {
        return this._data;
    }
    public set data(data: T) {
        this._data = data;
    }

    public get startPoint() {
        return this._startPoint;
    }
    public get endPoint() {
        return this._endPoint;
    }

    constructor(
        private _startPoint: Point,
        private _endPoint: Point,
        prev?: OrthoSegment<T>,
        next?: OrthoSegment<T>,
        private _data?: T
    ) {
        super(prev, next);
    }

    public getFirst(): OrthoSegment {
        let first: OrthoSegment = this;
        while (first._prev) {
            first = first._prev;
        }

        return first;
    }

    public getLast(): OrthoSegment {
        let last: OrthoSegment = this;
        while (last._next) {
            last = last._next;
        }

        return last;
    }

    /**
     * Returns the midpoint of the segment.
     *
     * @param {boolean} [noDecimals=false] - If true, the coordinates of the midpoint will be rounded to the nearest integer.
     *                                       If false or omitted, the coordinates may include decimal values.
     * @returns {Point} - The midpoint of the segment as a Point object.
     */
    public getMidPoint(noDecimals?: boolean): Point {
        const x = (this.startPoint.x + this.endPoint.x) / 2;
        const y = (this.startPoint.y + this.endPoint.y) / 2;
        return {
            x: noDecimals ? x | 0 : x,
            y: noDecimals ? y | 0 : y,
        };
    }

    /**
     * Translates the segment by the given vector.
     *
     * @param {Point} position - The position where to set the translated the segment.
     * @param {boolean} [noDecimals=false] - If true, the translation will be rounded to the nearest integer.
     *                                       If false or omitted, the translation may include decimal values.
     * @returns {OrthoSegment} - The updated Segment object after translation.
     */
    public translate(position: Point, noDecimals?: boolean): OrthoSegment {
        if (this.isVertical) {
            const x =
                (this.endPoint.x =
                this.startPoint.x =
                    noDecimals ? position.x | 0 : position.x);
            if (this.prev) {
                this.prev.endPoint.x = x;
            }
            if (this.next) {
                this.next.startPoint.x = x;
            }
        } else {
            const y =
                (this.endPoint.y =
                this.startPoint.y =
                    noDecimals ? position.y | 0 : position.y);
            if (this.prev) {
                this.prev.endPoint.y = y;
            }
            if (this.next) {
                this.next.startPoint.y = y;
            }
        }
        return this;
    }

    /**
     * Shortens the segment by a specified length from either the start point or endpoint.
     *
     * @param {number} length - The length by which to shorten the segment.
     * @param {boolean} [fromStart=true] - If true, shortens from the start point; if false, shortens from the endpoint.
     * @returns {OrthoSegment} - The updated Segment object after shortening.
     */
    public shorten(length: number, fromStart = true): OrthoSegment {
        const { startPoint, endPoint } = this;
        const pointToShorten = fromStart ? startPoint : endPoint;
        const coefficient = fromStart ? 1 : -1;
        const adjustedLength = Math.min(length, this.length);

        if (this.isHorizontal) {
            const segmentDirection = startPoint.x < endPoint.x ? 1 : -1;
            pointToShorten.x += adjustedLength * segmentDirection * coefficient;
        } else {
            const segmentDirection = startPoint.y < endPoint.y ? 1 : -1;
            pointToShorten.y += adjustedLength * segmentDirection * coefficient;
        }
        return this;
    }

    /**
     * Return a list of points from the first segment to the last one
     */
    public getPoints() {
        let segment = this.getFirst();
        const fixed = [segment.startPoint];
        while (segment.next) {
            segment = segment.next;
            fixed.push(segment.startPoint);
        }
        fixed.push(segment.endPoint);
        return simplifyPath(fixed);
    }

    /** Changes this segment's points position along the vertical or horizontal axis,
     * so it becomes aligned with the nearest of its prev and next,
     * and removes any zero-length prev/next */
    public alignOnNearest() {
        const { startPoint, endPoint, _prev: p, _next: n } = this;
        if (!p || !n) {
            return;
        }
        const isv = this.isVertical;
        const nearestSeg = isv
            ? Math.abs(p.startPoint.x - startPoint.x) <
              Math.abs(n.endPoint.x - endPoint.x)
                ? p
                : n
            : Math.abs(p.startPoint.y - startPoint.y) <
              Math.abs(n.endPoint.y - endPoint.y)
            ? p
            : n;
        let nearestPoint: Point,
            m = 0;
        if (nearestSeg == p) {
            nearestPoint = p.startPoint;
        } else if (nearestSeg == n) {
            nearestPoint = n.endPoint;
        } else {
            return;
        }
        if (isv) {
            endPoint.x = startPoint.x = nearestPoint.x + m;
        } else {
            endPoint.y = startPoint.y = nearestPoint.y + m;
        }
    }
}
