import {
    select,
    Selection,
    ArrayLike,
    BaseType,
    EnterElement,
} from 'd3-selection';
import 'd3-transition'; // Needed by Jest to compile transitionToVisibility function
import { D3ZoomEvent, zoomIdentity, ZoomTransform } from 'd3-zoom';
import { D3DragEvent } from 'd3-drag';
import { Simulation } from 'd3-force';
import {
    IXYRect,
    IXY,
    IRoundRect,
    IGetRectOptions,
    Dom2dUtil,
    Rect,
    TDomElement,
    IMarkerDef,
} from '@datagalaxy/core-2d-util';
import { IKbdAltShiftCtrl, KeyboardUtil } from '@datagalaxy/utils';

/** ## Role
 * Set of utility functions for handling SVG and HTML elements and events with [d3](https://d3js.org/) */
export class D3Helper {
    //#region text elements
    public static truncateText(
        index: number,
        sd3: SVGTextElement[] | ArrayLike<SVGTextElement>,
        width: number,
        delimiter = '...'
    ) {
        let self = select(sd3[index]),
            textLength = self.node().getComputedTextLength(),
            text = self.text();
        while (textLength > width && text.length > 0) {
            text = text.slice(0, -1);
            self.text(text + delimiter);
            textLength = self.node().getComputedTextLength();
        }
    }

    /** sets the text of the given elements,
     * truncated with an ellipsis if whole text is too big.
     * Calls the optional callback with computed whole text's width, node and whole text */
    public static setTextWithEllipsis<T>(
        sel: Selection<SVGTextElement, T, any, any>,
        getText: (d: T) => string,
        getWidth: (d: T) => number,
        withWholeTextWidth?: (
            item: T,
            wholeTextWidth: number,
            node: SVGTextElement,
            wholeText: string
        ) => void
    ) {
        sel.each((d, i, n) => {
            const node = n[i],
                wholeText = getText(d),
                maxWidth = getWidth(d);
            const wholeTextWidth = D3Helper.ellipsisNodeText(
                node,
                wholeText,
                maxWidth
            );
            withWholeTextWidth?.(d, wholeTextWidth, node, wholeText);
        });
    }
    private static ellipsisNodeText(
        node: SVGTextElement,
        text: string,
        maxWidth: number
    ) {
        // set whole text
        node.textContent = text;

        const wholeTextWidth = node.getComputedTextLength();

        if (wholeTextWidth < maxWidth) {
            // no ellipsis needed
            return wholeTextWidth;
        }

        const ellipsis = '...';

        // compute ellipsis width
        node.textContent = ellipsis;
        const ellipsisWidth = node.getComputedTextLength();

        if (ellipsisWidth > maxWidth) {
            // ellipsis alone will overflow
            return wholeTextWidth;
        }

        // available width for remaining text
        maxWidth -= ellipsisWidth;

        // binary search for best length
        let left = 0,
            right = text.length - 1;
        while (left <= right) {
            const middle = Math.floor((left + right) / 2);

            node.textContent = text.substr(0, middle + 1);
            const textWidth = node.getComputedTextLength();

            if (textWidth < maxWidth) left = middle + 1;
            else if (textWidth > maxWidth) right = middle - 1;
            else break;
        }

        node.textContent += ellipsis;

        return wholeTextWidth;
    }

    //#endregion text elements

    //#region svg defs

    //#region filters

    public static createDropShadowFilter(
        defs: SD3SvgDefs,
        filterId = 'drop-shadow',
        dx = 2,
        dy = 2,
        stdDeviation = 3,
        floodColor = 'rgba(2,22,142,0.2)',
        heightRatio = 1.3
    ) {
        const filter = defs
            .append('filter')
            .attr('id', filterId)
            .attr('height', heightRatio * 100 + '%');

        filter
            .append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', stdDeviation)
            .attr('result', 'blur');

        filter
            .append('feOffset')
            .attr('in', 'blur')
            .attr('dx', dx)
            .attr('dy', dy)
            .attr('result', 'offsetBlur');

        filter
            .append('feFlood')
            .attr('flood-color', floodColor)
            .attr('result', 'flood');

        filter
            .append('feComposite')
            .attr('in', 'flood')
            .attr('in2', 'offsetBlur')
            .attr('operator', 'in')
            .attr('result', 'shadow');

        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode').attr('in', 'shadow');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    }

    //#endregion filters

    public static appendMarker(
        defs: SD3<SVGDefsElement>,
        spec: IMarkerDef
    ): SD3<SVGMarkerElement> {
        const marker = Dom2dUtil.makeMarker(spec);
        defs.node().append(marker);
        return select(marker);
    }

    //#endregion

    //#region rectangles
    /** returns an array of rectangles of each element in the given selection */
    public static getRects<T extends TDomElement>(
        sel: SD3<T>,
        opt?: IGetRectOptions
    ) {
        return sel?.nodes().map((n) => Dom2dUtil.getRect(n, opt));
    }
    public static rectsToString<T extends TDomElement>(
        items: SD3<T>,
        opt?: IGetRectOptions
    ) {
        return D3Helper.getRects(items, opt).map((r) => Rect.rectToString(r));
    }

    /** creates a SVGRectElement with the given class and bounds, or update its bounds if it already exists,
     * and returns a selection containing the rect. */
    public static createRectOrUpdate<T>(
        container: SD3D<T>,
        className: string,
        bounds: IRoundRect,
        truncate = false
    ) {
        if (!container) {
            return;
        }
        const sel = D3Helper.getRectOrCreate(container, className);
        D3Helper.setBounds(sel, bounds, false, truncate);
        return sel;
    }
    /** creates a SVGRectElement with the given class if it doesn't already exist,
     * and returns a selection containing the rect. */
    public static getRectOrCreate<T>(container: SD3D<T>, className: string) {
        if (!container) {
            return;
        }
        let sel = container.select<SVGRectElement>(`rect.${className}`);
        if (sel.empty()) {
            sel = container.append('rect').attr('class', className);
        }
        return sel;
    }

    /** creates a HTMLDivElement with the given class and bounds, or update its bounds if it already exists,
     * and returns a selection containing the div. */
    public static createDivOrUpdate<T>(
        container: SD3D<T>,
        className: string,
        bounds: IRoundRect,
        truncate = false
    ) {
        if (!container) {
            return;
        }
        const sel = D3Helper.getDivOrCreate(container, className);
        D3Helper.setBounds(sel, bounds, true, truncate);
        return sel;
    }
    /** creates a HTMLDivElement with the given class if it doesn't already exist,
     * and returns a selection containing the div. */
    public static getDivOrCreate<T>(
        container: SD3D<T>,
        className: string,
        noAbsolute?: boolean
    ) {
        if (!container) {
            return;
        }
        let sel = container.select<HTMLDivElement>(`div.${className}`);
        if (sel.empty()) {
            sel = container.append('div').attr('class', className);
            if (!noAbsolute) {
                sel.style('position', 'absolute');
            }
        }
        return sel;
    }

    /** sets pointer-events/fill to none, and stroke/stroke-width to the given values */
    public static setupDebugRect(
        rect: SD3<SVGRectElement>,
        stroke: string,
        strokeWidth = 1
    ) {
        return rect
            ?.attr('pointer-events', 'none')
            .attr('fill', 'none')
            .attr('stroke', stroke)
            .attr('stroke-width', strokeWidth);
    }
    /** sets pointer-events to none, and border to the given values */
    public static setupDebugDiv(
        div: SD3<HTMLDivElement>,
        border = '1px solid red'
    ) {
        return div?.style('pointer-events', 'none').style('border', border);
    }
    /** sets width and height style properties or attributes */
    public static setWidthHeight<E extends BaseType>(
        sel: SD3<E>,
        w: number,
        h: number,
        useStyle?: boolean
    ) {
        if (!sel) {
            return;
        }
        useStyle
            ? sel.style('width', `${w}px`).style('height', `${h}px`)
            : sel.attr('width', w).attr('height', h);
        return sel;
    }
    /** sets top and left style properties if useStyle is true, else x and y attributes */
    public static setXY<E extends BaseType>(
        sel: SD3<E>,
        x: number,
        y: number,
        useStyle?: boolean
    ) {
        if (!sel) {
            return;
        }
        useStyle
            ? sel.style('left', `${x}px`).style('top', `${y}px`)
            : sel.attr('x', x).attr('y', y);
        return sel;
    }
    /** sets width and height, top and left style properties if useStyle is true, else x and y attributes */
    public static setXYWidthHeight<E extends BaseType>(
        sel: SD3<E>,
        x: number,
        y: number,
        w: number,
        h: number,
        useStyle?: boolean
    ) {
        if (!sel) {
            return;
        }
        useStyle
            ? sel
                  .style('left', `${x}px`)
                  .style('top', `${y}px`)
                  .style('width', `${w}px`)
                  .style('height', `${h}px`)
            : sel.attr('x', x).attr('y', y).attr('width', w).attr('height', h);
        return sel;
    }
    /** sets x, y (or left & top), width, height, and rx, ry if defined (rx, ry are corner radii) */
    public static setBounds<E extends BaseType>(
        sel: SD3<E>,
        r: IRoundRect,
        useStyle?: boolean,
        truncate?: boolean
    ) {
        if (!sel) {
            return;
        }
        const or0 = (n: number) => (truncate ? n | 0 : n || 0);
        D3Helper.setXYWidthHeight(
            sel,
            or0(r.x),
            or0(r.y),
            or0(r.width),
            or0(r.height),
            useStyle
        );
        if (r.rx != null || r.ry != null) {
            useStyle
                ? sel.style('border-radius', `${r.rx}px ${r.ry}px`)
                : sel.attr('rx', r.rx).attr('ry', r.ry);
        }
        return sel;
    }

    //#endregion rectangles

    //#region zoom transform

    public static zoomTransformScaleBy(
        t: ZoomTransform,
        k: number,
        cx: number,
        cy: number
    ) {
        const tx = cx / k - (cx - t.x) / t.k,
            ty = cy / k - (cy - t.y) / t.k;
        return t.scale(k).translate(tx, ty);
    }
    public static zoomTransformTranslateTo(
        t: ZoomTransform,
        x: number,
        y: number,
        cx: number,
        cy: number
    ) {
        return zoomIdentity.translate(cx, cy).scale(t.k).translate(-x, -y);
    }
    public static zoomTransformToIdentity(
        t: ZoomTransform,
        cx: number,
        cy: number
    ) {
        const ts = D3Helper.zoomTransformScaleBy(t, 1 / t.k, cx, cy);
        return D3Helper.zoomTransformTranslateTo(ts, cx, cy, cx, cy);
    }

    public static getTransformTranslation(single: SD3<SVGGraphicsElement>) {
        return Dom2dUtil.getSVGElementTransformTranslation(single.node());
    }

    //#endregion

    //#region events

    public static bindClick<E extends D3El, D>(
        sel: SD3ED<E, D>,
        action: (d: D, node: E, event: any) => void,
        stopPropagation = false,
        preventDblClick = true
    ) {
        sel = sel.on('click', function (event, d) {
            if (stopPropagation) {
                event.stopPropagation();
            }
            action(d, this, event);
        });
        return preventDblClick ? D3Helper.preventDblClick(sel) : sel;
    }

    public static bindHover<E extends D3El, D>(
        sel: SD3ED<E, D>,
        action: (isOnOver: boolean, d: D, node: E) => void
    ) {
        return sel
            .on('mouseenter', function (event, d) {
                action(true, d, this);
            })
            .on('mouseleave', function (event, d) {
                action(false, d, this);
            });
    }

    public static preventDblClick<E extends D3El, D>(sel: SD3ED<E, D>) {
        return sel.on('dblclick', function (event) {
            event.stopPropagation();
        });
    }

    //#endregion

    //#region force

    public static getFullTicks(simulation: Simulation<any, any>) {
        return Math.ceil(
            Math.log(simulation.alphaMin()) /
                Math.log(1 - simulation.alphaDecay())
        );
    }
    public static getDefaultAlphaDecay(alphaMin = 0.001) {
        return 1 - Math.pow(alphaMin, 1 / 300);
    }

    public static getSimulationState(simulation: Simulation<any, any>) {
        return (
            simulation && {
                alpha: simulation.alpha(),
                alphaTarget: simulation.alphaTarget(),
                alphaMin: simulation.alphaMin(),
                alphaDecay: simulation.alphaDecay(),
                fullTicks: D3Helper.getFullTicks(simulation),
            }
        );
    }

    //#endregion

    //#region viewbox

    public static autoViewBox(d: any, i: number, n: TGGroups) {
        return Dom2dUtil.autoViewBox(n[i]);
    }
    public static setAutoViewBox<T>(d3svg: SD3ED<SVGSVGElement, T>) {
        return d3svg.attr('viewBox', D3Helper.autoViewBox);
    }
    public static setCenteredViewBox<E extends BaseType>(
        sel: SD3<E>,
        w: number,
        h: number
    ) {
        return sel.attr('viewBox', Dom2dUtil.getCenteredViewBox(w, h));
    }

    //#endregion

    //#region helpers

    public static isEmpty<T>(items: Array<T> | SD3D<T>) {
        return Array.isArray(items)
            ? items.length == 0
            : !items || typeof items.empty != 'function' || items.empty();
    }

    /** returns the minimal rectangle enclosing every given element */
    public static getBoundingBox<
        T extends IXYRect,
        TItems extends T[] | SD3D<any>
    >(items: TItems) {
        return Rect.boundingBox(items);
    }

    /** Given 2 points, returns the rotation transform to apply
     * for a text displayed at mid distance of those points to be readable from left to right */
    public static getSVGRotationTransformLTR<T extends IXY>(
        src: T,
        tgt: T,
        precision = 6
    ) {
        return Dom2dUtil.getSVGRotationTransformLTR(src, tgt, precision);
    }

    /** creates a cross with the given class and position, or update its position if it already exists */
    public static createCrossOrUpdate<T>(
        container: SD3D<T>,
        className: string,
        x: number,
        y: number,
        length = 20,
        stroke = 'red',
        strokeWidth = '1px'
    ) {
        let cross = container.select<SVGGElement>('.' + className);
        if (cross.empty()) {
            cross = container
                .append('path')
                .attr('class', className)
                .attr('stroke', stroke)
                .attr('stroke-width', strokeWidth);
        }
        const hl = length / 2;
        cross.attr(
            'd',
            `M${x - hl},${y} L${x + hl},${y} M${x},${y - hl} L${x},${y + hl}`
        );
        return cross;
    }

    public static positionLine(
        line: SD3<SVGLineElement>,
        x1: number,
        y1: number,
        x2: number,
        y2: number
    ) {
        return line.attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2);
    }

    public static transitionToVisibility(
        sel: SD3<any>,
        toVisible: boolean,
        fast = false
    ) {
        const durationMs = fast ? 333 : 666;
        return sel
            .attr('opacity', toVisible ? 0 : 1)
            .transition()
            .duration(durationMs)
            .attr('opacity', toVisible ? 1 : 0);
    }

    /** Returns the state of the alt, shift, and ctrl keys during the given event,
     * if any is pressed, otherwise undefined.
     *  Note: On Mac, ctrl key is used for right-click, so we use the cmd key instead */
    public static getKbdAltShiftCtrl(d3Event: {
        sourceEvent: any;
    }): IKbdAltShiftCtrl {
        return KeyboardUtil.getAltShiftCtrl(d3Event?.sourceEvent);
    }

    /** Sorts the dom elements associated with the given data *items*
     * so the element associated with the given data *item*
     * is the last in the dom, so it appears in front of the others */
    public static bringToFront<E extends Element, D = unknown>(
        item: D,
        items: SD3ED<E, D>
    ) {
        items.sort((a, b) => (a === item ? 1 : b === item ? -1 : 0));
    }

    //#endregion
}
/** Prefix is 'scheme'. Usage example: `const color = d3.scaleOrdinal(d3['scheme' + d3ColorSchemeSuffix] as string[])` */
export const d3ColorSchemeSuffixes = [
    'Category10',
    'Accent',
    'Dark2',
    'Paired',
    'Pastel1',
    'Pastel2',
    'Set1',
    'Set2',
    'Set3',
    'Tableau10',
];

export interface ID3MouseEvent {
    sourceEvent: MouseEvent;
}

// shortcut definitions for D3 types
export type D3El = Element | EnterElement;
export type SD3<TElem extends BaseType = BaseType> = Selection<
    TElem,
    any,
    any,
    any
>;
export type SD3ED<TElem extends D3El, TData> = Selection<
    TElem,
    TData,
    any,
    any
>;
export type SD3SvgDefs = SD3<SVGDefsElement>;
export type SD3D<TData> = SD3ED<D3El, TData>;
export type TD3DragEventD<TData> = D3DragEvent<Element, TData, any>;
export type TEArray<TElem> = TElem[] | ArrayLike<TElem>;
export type TGGroups = TEArray<SVGGraphicsElement>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TD3Subject = any;
export interface TD3ZoomEvent extends D3ZoomEvent<TDomElement, void> {
    sourceEvent: MouseEvent;
}
export interface ID3DragEvent<
    E extends TDomElement = TDomElement,
    D = unknown,
    S = unknown
> extends D3DragEvent<E, D, S> {
    sourceEvent: MouseEvent;
}
export type TD3DragEventHandler<
    E extends TDomElement = TDomElement,
    D = unknown,
    S = unknown
> = (d: D, event: D3DragEvent<E, D, S>, el: E) => unknown;
