<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Versioning.VersioningModal.createTitle
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-select
            *ngIf="!isActivateVersion"
            name="sourceVersionName"
            labelKey="UI.Versioning.VersioningModal.sourceVersionName"
            [adapter]="projectVersions"
            [mandatory]="true"
            [disabled]="isSourceVersionFixed"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>

        <dxy-field-text
            name="versionName"
            labelKey="UI.Versioning.VersioningModal.versionName"
            [(ngModel)]="versionName"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
        ></dxy-field-text>

        <dxy-field-text
            name="versionDescription"
            labelKey="UI.Versioning.VersioningModal.versionDescription"
            [(ngModel)]="versionDescription"
        ></dxy-field-text>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblCreate' | translate"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
    ></dxy-modal-footer>
</form>
