<dxy-filter-button
    [xMenuPosition]="xMenuPosition"
    [labelKey]="labelKey"
    [labelText]="labelText"
    [isResolved]="isResolved"
    [hasRemove]="hasRemove"
    [isMini]="isMini"
    [operator]="operator"
    [operators]="operators"
    [hasSmallPanel]="true"
    [readonly]="readonly"
    [noOperator]="noOperator"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    iconClass="glyph-date"
    [getOperatorTranslateKey]="getOperatorTranslateKey"
    (operatorChange)="onOperatorChange($event)"
    (removeClick)="onRemove($event)"
    (onOpenClose)="onOpenClose.emit($event)"
>
    <div customTextTemplate class="filter-value-wrapper">
        <span
            *ngIf="!isRangeDate"
            class="operator-name light-operator"
            [translate]="operatorKey"
        ></span>
        <ng-container *ngIf="isRangeDate">
            <ng-container *ngIf="hasOnlyStartDate">
                <span class="operator-name light-operator" translate
                    >CoreUI.Filter.Operators.DateTime.GreaterEqual</span
                >
                <span>{{ filter.startDate | dateFormat }}</span>
            </ng-container>
            <ng-container *ngIf="hasOnlyEndDate">
                <span class="operator-name light-operator" translate
                    >CoreUI.Filter.Operators.DateTime.Lower</span
                >
                <span>{{ filter.endDate | dateFormat }}</span>
            </ng-container>
            <ng-container *ngIf="hasStartAndEndDate">
                <span class="operator-name light-operator" translate
                    >CoreUI.Filter.Date.Criteria.From</span
                >
                <span>{{ filter.startDate | dateFormat }}</span>
                <span
                    class="operator-name light-operator second-operator"
                    translate
                    >CoreUI.Filter.Date.Criteria.To</span
                >
                <span>{{ filter.endDate | dateFormat }}</span>
            </ng-container>
        </ng-container>
    </div>
    <div class="range-date" *ngIf="isRangeDate">
        <div class="fit-content" (click)="$event.stopPropagation()">
            <dxy-field-date
                labelKey="CoreUI.Filter.Date.Criteria.From"
                [(ngModel)]="filter.startDate"
                (dateChange)="onStartDateChange()"
                [minDate]="minDate"
                [maxDate]="maxDate"
            ></dxy-field-date>
        </div>
        <div class="fit-content" (click)="$event.stopPropagation()">
            <dxy-field-date
                labelKey="CoreUI.Filter.Date.Criteria.To"
                [(ngModel)]="filter.endDate"
                (dateChange)="onEndDateChange()"
                [minDate]="minDate"
                [maxDate]="maxDate"
            ></dxy-field-date>
        </div>
    </div>
</dxy-filter-button>
