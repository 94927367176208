import { Component, Input } from '@angular/core';
import { ActivityLogEntryElement } from '../activityLog.types';
import { ServerTimeService } from '../../services/serverTime.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyActivityLogItemComponent } from '../dxy-activity-log-item/dxy-activity-log-item.component';
import { DxyProfileAvatarComponent } from '../../shared/shared-ui/dxy-profile-avatar/dxy-profile-avatar.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-activity-log-element',
    templateUrl: 'dxy-activity-log-element.component.html',
    styleUrls: ['./dxy-activity-log-element.component.scss'],
    standalone: true,
    imports: [NgIf, DxyProfileAvatarComponent, DxyActivityLogItemComponent],
})
export class DxyActivityLogElementComponent extends DxyBaseComponent {
    @Input() activityLogEntryElement: ActivityLogEntryElement;
    @Input() disableNavigation?: boolean;

    public get isGroupHeader() {
        return this.activityLogEntryElement.isGroupHeader;
    }
    public get activityLogEntry() {
        return this.activityLogEntryElement.entry;
    }

    constructor(private serverTimeService: ServerTimeService) {
        super();
    }

    public getCreationTimeFromNow() {
        const dateString = this.activityLogEntryElement.entry.CreationTime;
        return this.serverTimeService.getServerTimeFromNowString(
            dateString,
            true
        );
    }
}
