import { Inject, Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { IApiConfig, BACKEND_API_CONFIG } from '../types';

/**
 * ## Role
 * Add x-client-app header to the request to specify the client version
 */
@Injectable()
export class ClientVersionInterceptor implements HttpInterceptor {
    constructor(@Inject(BACKEND_API_CONFIG) private config: IApiConfig) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const clientVersion = this.config?.clientVersion;

        if (!clientVersion) {
            return next.handle(req);
        }

        const reqClone: HttpRequest<any> = req.clone({
            headers: req.headers.set('x-client-app', clientVersion),
        });

        return next.handle(reqClone);
    }
}
