import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';
import { CampaignPhaseDto } from '../campaign-phase';
import { UserDto } from '@datagalaxy/webclient/user/domain';

@inheritSerialization(BaseDTO)
export class CampaignBriefDto extends BaseDTO {
    @deserialize public Guid!: string;
    @deserialize public Name!: string;
    @deserialize public AttributePath!: string;
    @deserialize public Description!: string;
    @deserializeAs(UserDto) public Owner!: UserDto;
    @deserializeAs(UserDto) public CreationUser!: UserDto;
    @deserialize public SpaceGuid!: string;
    @deserialize public VersionId!: string;
    @deserialize public Ended!: boolean;
    @deserialize public WorkflowName!: string;
    @deserialize public CurrentPhase!: CampaignPhaseDto;
}

@inheritSerialization(CampaignBriefDto)
export class CampaignDto extends CampaignBriefDto {
    @deserialize public ReferenceIds!: string[];
    @deserialize public SupportedAttributeValues!: string[];
}
