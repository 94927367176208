import {
    TeamAccessType,
    TeamDto,
    TeamPublicData,
} from '@datagalaxy/webclient/team/data-access';

export class TeamUtil {
    public static publicTeamFromTeamDto(team: TeamDto) {
        const publicTeam = new TeamPublicData();

        publicTeam.TeamUid = team.TeamUid;
        publicTeam.HasTeamReadAccess = TeamUtil.hasReadAccess(team);
        publicTeam.TeamName = team.TeamName;
        publicTeam.TeamImageHash = team.TeamImageHash;

        return publicTeam;
    }

    public static hasReadAccess(team: TeamDto) {
        return (
            team.IsCurrentUserMember ||
            team.AccessType != TeamAccessType.Private
        );
    }
}
