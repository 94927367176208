import { DateTimeUtil } from '@datagalaxy/core-util';
import { Moment } from 'moment';

export interface IMomentDateRange {
    start: Moment;
    end: Moment;
}

export function areSameMomentDateRange(
    a: IMomentDateRange,
    b: IMomentDateRange,
    includeTime: boolean
) {
    return (
        (!a && !b) ||
        (a &&
            b &&
            DateTimeUtil.areSameMoment(a.start, b.start, includeTime) &&
            DateTimeUtil.areSameMoment(a.end, b.end, includeTime))
    );
}
