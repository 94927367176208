import { IBaseFilterData } from '../base-filter/base-filter.types';

export interface ITextFilterData extends IBaseFilterData<TextFilterOperator> {
    value?: string;
}

export enum TextFilterOperator {
    Unknown = 0,
    TextContains,
    TextEquals,
    TextStartsWith,
    TextEndsWith,
    FieldIsEmpty,
    FieldHasValue,
}
