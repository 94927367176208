export enum SlackRedirectionIdentifier {
    integrationActivation = 'slackIntegrationActivation',
    notficationsActivation = 'slackNotificationsActivation',
}

export enum SlackRedirectionQueryParams {
    callbackIdentifier = 'callbackIdentifier',
    redirectUri = 'redirectUri',
    code = 'code',
}
