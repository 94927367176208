import { BaseService } from '@datagalaxy/core-ui';
import {
    EntityExportType,
    IEntityExportModalInput,
} from '../export/export-modal.types';
import { Injectable } from '@angular/core';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { EntityExportModalComponent } from '../export/entity-export-modal/entity-export-modal.component';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class ExportService extends BaseService {
    constructor(private dxyModalService: DxyModalService) {
        super();
    }

    public async exportFilteredView(fielterdView: FilteredViewDto) {
        return await this.exportEntityOrFilteredView(
            fielterdView,
            EntityExportType.FilteredView
        );
    }

    public async exportEntity(entity: EntityItem) {
        return await this.exportEntityOrFilteredView(
            entity,
            EntityExportType.Unitary
        );
    }

    public async exportEntities(entities: EntityItem[]) {
        return await this.exportEntityOrFilteredView(
            entities,
            EntityExportType.List
        );
    }

    private async exportEntityOrFilteredView(
        data: EntityItem | EntityItem[] | FilteredViewDto,
        exportType: EntityExportType
    ) {
        await this.dxyModalService.open<
            EntityExportModalComponent,
            IEntityExportModalInput,
            void
        >({
            componentType: EntityExportModalComponent,
            data: { data, exportType },
        });
    }
}
