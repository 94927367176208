import { ITypedEvent } from '@datagalaxy/dg-object-model';
import { IEventTranslationInputs } from './interfaces/IEventTranslationInputs';
import { IDgEventTranslationAdapter } from './interfaces/IDgEventTranslationAdapter';
import { dgEventTranslationAdapterMappings } from './adapter-registry';

export class DgEventTranslationFactory {
    buildTranslationInputs(typedEvent: ITypedEvent): IEventTranslationInputs {
        const adapter = this.getAdapter(typedEvent);

        const translationInputs = adapter.getTranslationInputs();

        return translationInputs;
    }

    getAdapter(typedEvent: ITypedEvent): IDgEventTranslationAdapter {
        const adapter = dgEventTranslationAdapterMappings.get(
            typedEvent.EventTypeName
        );
        if (!adapter) {
            throw new Error(`No adapter found for ${typedEvent.EventTypeName}`);
        }
        return new adapter(typedEvent);
    }
}
