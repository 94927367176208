<dxy-boolean-filter
    [(filter)]="filter"
    [labelText]="label"
    [isMini]="isMini"
    [readonly]="readOnly"
    [hasRemove]="showRemoveButton"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    [iconClass]="iconClass"
    (filterChange)="onFilterChange()"
    (remove)="onRemove()"
    (onOpenClose)="onOpenCloseMenu($event)"
></dxy-boolean-filter>
