<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="UI.Dialog.NewItem.Diagram.title"
    ></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="createDiagram()">
    <div mat-dialog-content [class.modal-disable]="loading">
        <app-diagram-configurator
            [spaceIdr]="spaceIdr"
            [sourceIdr]="data.sourceIdr"
            [isSourceReadonly]="data.isSourceReadonly"
            (panelOpenClose)="onPanelOpenClose($event)"
            (configChanged)="onConfigChanged($event)"
        ></app-diagram-configurator>
    </div>
    <dxy-modal-footer
        [actionEnabled]="isActionEnabled"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblCreate' | translate"
        [actionLoading]="loading"
        (onCloseCancel)="onCloseCancel()"
        featureCode="DIAGRAM,C"
    ></dxy-modal-footer>
</form>
