import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseCellComponent } from '..';

/**
 * ## Role
 * Color point
 *
 * ## Notes
 * Could be used with inputs or css classes
 * - Inputs
 *   - color: point background color
 *   - size: point size in px
 * - Classes
 *   - primary: set point background color to $dg5-primary-color
 *   - inactive: set background color to grey
 *   - small/large: set point size to a standard size
 *   - inline: set display mode to inline-block
 */
@Component({
    standalone: true,
    selector: 'dxy-color-point-cell',
    templateUrl: './color-point-cell.component.html',
    styleUrls: ['./color-point-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyColorPointCellComponent extends BaseCellComponent<
    any,
    IColorPointCellData
> {
    @Input() color: string;
    @Input() size?: number;

    public get isBordered() {
        return ['white', '#ffffff', '', null, undefined].includes(
            this.color?.toLowerCase()
        );
    }

    constructor() {
        super();
    }

    protected override updateBindings() {
        this.color = this.value.color;
        this.size = this.value.size;
    }
}

export interface IColorPointCellData {
    color: string;
    size?: number;
}
