import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MultilingualStateService } from '../../multilingual-state.service';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { executeOnce, withLoading } from '@datagalaxy/core-ui';
import {
    AttributesForm,
    isTranslatableAttribute,
} from '../../attributes/attributes-selection-helpers';
import {
    ATTRIBUTES_FINDER_TOKEN,
    AttributeSelectorComponent,
    AttributesFinder,
} from '@datagalaxy/webclient/attribute/feature';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { AlertMessageComponent } from '@datagalaxy/ui/forms';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-add-attributes-modal',
    templateUrl: './add-attributes-modal.component.html',
    styleUrls: ['./add-attributes-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        AttributeSelectorComponent,
        AlertMessageComponent,
        SpinnerComponent,
        DxyButtonDirective,
        AsyncPipe,
    ],
})
export class AddAttributesModalComponent
    extends DxyBaseModalComponent<void, void>
    implements AfterViewInit
{
    protected form: FormGroup<AttributesForm>;
    protected attributes: AttributeMetaInfo[] = [];

    constructor(
        dialogRef: MatDialogRef<AddAttributesModalComponent>,
        private stateService: MultilingualStateService,
        private formBuilder: FormBuilder,
        private multilingualApiService: MultilingualApiService,
        @Inject(ATTRIBUTES_FINDER_TOKEN)
        private attributesFinder: AttributesFinder,
        private cd: ChangeDetectorRef
    ) {
        super(dialogRef);
        this.form = this.formBuilder.group<AttributesForm>({
            attributes: new FormControl(null, [Validators.required]),
        });
    }

    ngAfterViewInit() {
        this.initAttributes();
    }

    @withLoading()
    @executeOnce()
    protected async onSubmit() {
        if (!this.form.valid) {
            return;
        }
        const attributes = this.form.controls.attributes.value ?? [];
        if (attributes.length === 0) {
            return;
        }
        const settings = attributes.map((a) => ({
            attributePath: a.AttributePath ?? '',
            enabled: true,
        }));
        const result = await this.multilingualApiService.addAttributeSettings(
            settings
        );
        this.stateService.addAttributeSettings(result);
        this.onCloseSubmit();
    }

    private async initAttributes() {
        const alreadySelectedAttributes =
            this.stateService.state.settings?.attributes ?? [];
        this.attributes = await this.attributesFinder.searchAttributes(
            (a) =>
                isTranslatableAttribute(a) &&
                !alreadySelectedAttributes.some(
                    (selected) => selected.attributePath === a.AttributePath
                )
        );
        this.cd.detectChanges();
    }
}
