import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    NavOrganization,
    NavProject,
    NavSpace,
    SpaceSecurityProfileType,
    Organization,
    Project,
} from './workspace';

@inheritSerialization(BaseServiceResult)
export class LoadSpacesResult extends BaseServiceResult {
    @deserializeAs(NavProject) public Projects!: NavProject[];
    @deserializeAs(NavOrganization) public Organizations!: NavOrganization[];

    constructor() {
        super();
    }

    public allSpaces() {
        const sortedProjects = CollectionsHelper.orderBy(
            this.Projects,
            (p) => p.DisplayName
        );
        return this.Organizations.concat(sortedProjects) as NavSpace[];
    }

    public find(predicate: (s: NavSpace) => boolean) {
        return this.allSpaces().find(predicate);
    }

    public findById(spaceId: string) {
        return spaceId ? this.find((s) => s.ReferenceId == spaceId) : undefined;
    }

    /** return the first project if only one is available. returns undefined otherwise  */
    public getSingleProject() {
        return this.Projects?.length == 1 ? this.Projects[0] : undefined;
    }

    /** returns the first project having IsDefaultSpace set */
    public getDefaultProject() {
        return this.Projects?.find((p) => p.IsDefaultSpace);
    }

    /** returns the first space having IsDefaultSpace set */
    public getDefaultSpace() {
        return this.find((s) => s.IsDefaultSpace);
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateSpaceParameter extends BaseServiceParameter {
    @serialize public ParentOrganizationId: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serialize public DefaultOwnerUserId: string;
    @serialize public DefaultStewardUserId: string;
    @serializeAs(SpaceSecurityProfileType)
    public SecurityProfileType: SpaceSecurityProfileType;
    @serialize public Trigram: string;

    constructor(
        parentOrganizationReferenceId: string,
        projectName: string,
        projectTrigram: string,
        projectDescription: string,
        defaultDataOwnerUserId: string,
        defaultDataStewardUserId: string,
        securityProfileType: SpaceSecurityProfileType = SpaceSecurityProfileType.Private
    ) {
        super();
        this.ParentOrganizationId = parentOrganizationReferenceId;
        this.DisplayName = projectName;
        this.Trigram = projectTrigram;
        this.Description = projectDescription;
        this.DefaultOwnerUserId = defaultDataOwnerUserId;
        this.DefaultStewardUserId = defaultDataStewardUserId;
        this.SecurityProfileType = securityProfileType;
    }
}

export enum PreSetupSpaceTrigramStatus {
    Ok,
    Exists,
    InvalidSpaceName,
    InvalidTrigram,
}
SerializableEnumeration(PreSetupSpaceTrigramStatus);

@inheritSerialization(BaseServiceResult)
export class PreSetupSpaceTrigramResult extends BaseServiceResult {
    @deserialize public Trigram!: string;
    @deserializeAs(PreSetupSpaceTrigramStatus)
    public PreSetupSpaceTrigramStatus!: PreSetupSpaceTrigramStatus;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateSpaceParameter extends BaseServiceParameter {
    @serialize public SpaceId: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serialize public IsDefaultSpace?: boolean;
    @serialize public Trigram: string;
    @serializeAs(SpaceSecurityProfileType)
    public SecurityProfileType?: SpaceSecurityProfileType;

    constructor(
        spaceId: string,
        displayName: string,
        trigram: string,
        description: string,
        isDefaultSpace?: boolean,
        securityProfileType?: SpaceSecurityProfileType
    ) {
        super();
        this.SpaceId = spaceId;
        this.DisplayName = displayName;
        this.Trigram = trigram;
        this.Description = description;
        this.IsDefaultSpace = isDefaultSpace;
        this.SecurityProfileType = securityProfileType;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CheckSpaceTrigramValidityParameter extends BaseServiceParameter {
    @serialize public SpaceId: string;
    @serialize public Trigram: string;

    constructor(spaceId: string, trigram: string) {
        super();
        this.SpaceId = spaceId;
        this.Trigram = trigram;
    }
}

export class UpdateSpaceDto {
    @deserialize public SpaceId?: string;
    @deserialize public DataTypeName?: string;
    @deserialize public DisplayName?: string;
    @deserialize public Description?: string;
    @deserialize public Trigram?: string;
}

@inheritSerialization(BaseServiceParameter)
export class LoadOrganizationParameter extends BaseServiceParameter {
    @serialize public OrganizationReferenceId: string;

    constructor(organizationId: string) {
        super();
        this.OrganizationReferenceId = organizationId;
        this.VersionId = '';
    }
}

@inheritSerialization(BaseServiceResult)
export class LoadOrganizationResult extends BaseServiceResult {
    @deserialize public IsErrorBadOrganizationId!: boolean;
    @deserializeAs(Organization) public Organization!: Organization;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateOrganizationResult extends BaseServiceResult {
    @deserialize public IsErrorBadParentId!: string;
    @deserializeAs(Organization) public Organization!: Organization;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteOrganizationParameter extends BaseServiceParameter {
    @serialize public ParentOrganizationId: string;
    @serialize public OrganizationId: string;

    constructor(parentOrganizationReferenceId: string, organizationId: string) {
        super();
        this.ParentOrganizationId = parentOrganizationReferenceId;
        this.OrganizationId = organizationId;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteOrganizationResult extends BaseServiceResult {
    @deserializeAs(HierarchicalData) public DeletedSpaces!: HierarchicalData[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class LoadProjectParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;

    constructor(projectId: string, versionId?: string) {
        super();
        this.ProjectReferenceId = projectId;
        this.VersionId = versionId || '';
    }
}

@inheritSerialization(BaseServiceResult)
export class LoadProjectResult extends BaseServiceResult {
    @deserialize public IsBadProjectId!: boolean;
    @deserializeAs(Project) public Project!: Project;
}

@inheritSerialization(BaseServiceResult)
export class CreateProjectResult extends BaseServiceResult {
    @deserialize public IsErrorBadParentId!: string;
    @deserializeAs(Project) public Project!: Project;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteProjectParameter extends BaseServiceParameter {
    @serialize public ParentOrganizationId: string;
    @serialize public ProjectId: string;

    constructor(parentOrganizationReferenceId: string, projectId: string) {
        super();
        this.ParentOrganizationId = parentOrganizationReferenceId;
        this.ProjectId = projectId;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteProjectResult extends BaseServiceResult {
    @deserialize public DeletedProjectId!: string;
}
