import {
    deserialize,
    GenericDeserialize,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { coerceArray } from '@angular/cdk/coercion';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';

@inheritSerialization(BaseServiceParameter)
export class UpdateClientQuickFiltersParameter extends BaseServiceParameter {
    @serialize public ProfileName?: string;
    @serialize public SetDefaultValue?: boolean;
    @serialize public QuickFilters?: Filter[];
}

@inheritSerialization(BaseServiceResult)
export class GetClientQuickFiltersResult extends BaseServiceResult {
    @deserialize public QuickFilters!: Map<LicenseLevel, Filter[]>;

    public static OnDeserialized(instance: GetClientQuickFiltersResult) {
        instance.QuickFilters = new Map(
            Object.entries(instance.QuickFilters)
                .filter(([k]) => k != '$id' && k != '$type')
                .map(([key, values]) => [
                    LicenseLevel[key as keyof typeof LicenseLevel],
                    coerceArray(values).map((value) =>
                        GenericDeserialize(value, Filter)
                    ),
                ])
        );
    }
}
