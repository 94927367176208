import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '@datagalaxy/utils';
import { MultilingualStateService } from '../../multilingual-state.service';
import { LexiconUploadFormComponent } from '../lexicon-upload-form/lexicon-upload-form.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'dxy-multilingual-lexicon',
    templateUrl: './multilingual-lexicon.component.html',
    styleUrls: ['./multilingual-lexicon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, LexiconUploadFormComponent, AsyncPipe],
})
export class MultilingualLexiconComponent extends BaseComponent {
    protected languageCodes$ =
        this.multilingualStateService.selectAllLanguageCodes();
    constructor(private multilingualStateService: MultilingualStateService) {
        super();
    }
}
