import { StateName } from '@datagalaxy/webclient/routing';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export enum EntityStateNamesIndex {
    Details = 0,
    Dashboard = 1,
    Grid = 2,
    List = 3,
    Main = 4,
    Data = 5,
    Root = 6,
    Datamap = 7,
}

/** Returns an array containing the common state names for the given dgModule, in this order:
 * Details, Dashboard, Grid, List, Main, Data, Root, DataMap.
 * Use the EntityStateNamesIndex enum to use them */
export function getEntityStateNames(dgModule: DgModule) {
    switch (dgModule) {
        case DgModule.Glossary:
            return [
                StateName.GlossaryDetails,
                StateName.GlossaryDashboard,
                StateName.GlossaryGrid,
                StateName.GlossaryList,
                StateName.GlossaryMain,
                StateName.GlossaryData,
                StateName.Glossary,
                StateName.GlossaryDataMap,
            ];
        case DgModule.Usage:
            return [
                StateName.SoftwareDetails,
                StateName.SoftwareDashboard,
                StateName.SoftwareGrid,
                StateName.SoftwareList,
                StateName.SoftwareMain,
                StateName.SoftwareData,
                StateName.Software,
                StateName.SoftwareDataMap,
                StateName.SoftwareFields, // is this used ? how ? (max EntityStateNamesIndex is DataMap)
            ];
        case DgModule.Processing:
            return [
                StateName.DataProcessingDetails,
                StateName.DataProcessingDashboard,
                StateName.DataProcessingGrid,
                StateName.DataProcessingList,
                StateName.DataProcessingMain,
                StateName.DataProcessingData,
                StateName.DataProcessing,
                StateName.DataProcessingDataMap,
            ];
        case DgModule.Catalog:
            return [
                StateName.ModelerDetailsTab,
                StateName.ModelerDashboard,
                StateName.ModelerGrid,
                StateName.ModelerList,
                StateName.ModelerMain,
                StateName.ModelerData,
                StateName.Modeler,
                undefined,
            ];
        case DgModule.Diagram:
            return [
                StateName.DiagramsDetails,
                StateName.DiagramsDashboard,
                StateName.DiagramsGrid,
                StateName.DiagramsList,
                StateName.DiagramsMain,
                StateName.DiagramsData,
                StateName.Diagrams,
                undefined,
            ];
    }
}
