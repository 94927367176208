import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IActionOption } from './action-list.types';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { ListOptionUtil } from '../../IListOption';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyActionListItemComponent } from '../action-list-item/action-list-item.component';
import { NgIf, NgFor } from '@angular/common';

/**
 * ## Role
 * Display action buttons next to a transcluded content.
 * An action can be a burger menu.
 */
@Component({
    selector: 'dxy-action-list',
    templateUrl: './action-list.component.html',
    styleUrls: ['./action-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, DxyActionListItemComponent],
})
export class DxyActionListComponent<T> extends DxyBaseComponent {
    @Input() actions: IActionOption[];
    @Input() data?: T;
    @Input() hideActions?: boolean;
    @Input() actionsCaptureMouseDown?: boolean;
    /**
     * When true, buttons will always have the same position.
     * Used to keep visible buttons in place on hover.
     */
    @Input() fixButtonPositions?: boolean;

    @Output() onLogFunctional = new EventEmitter<IFunctionalEvent>();

    public isBurgerMenuOpened: boolean;

    constructor() {
        super();
    }

    public isHidden(action: IActionOption) {
        return ListOptionUtil.isHidden(action, this.data);
    }

    public onBurgerMenuOpenClose(open: boolean) {
        this.isBurgerMenuOpened = open;
    }
}
