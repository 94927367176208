<ng-container *ngIf="hierarchicalData">
    <app-entity-card-cell
        [params]="params"
        [hierarchicalData]="hierarchicalData"
        [noLabelNavLink]="noLabelNavLink"
        [noBreadcrumbNavLink]="noBreadcrumbNavLink"
        [breadcrumbOpenPreview]="breadcrumbOpenPreview"
        [hideBreadcrumb]="hideBreadcrumb"
        [isFromHierarchical]="isFromHierarchical"
        [withEntityFullPage]="withEntityFullPage"
        [forceIncludeSpace]="forceIncludeSpace"
        [forceExcludeSpace]="forceExcludeSpace"
        [dtContext]="dtContext"
        [actions]="actions"
        [attributes]="attributes"
    ></app-entity-card-cell>

    <div *ngIf="exactMatch" class="exact-match">
        <div class="result-item-attribute">
            <div class="attribute-key">
                {{ exactMatch.displayName
                }}<span translate>UI.Global.colon</span>
            </div>
            <span class="attribute-value">{{ exactMatch.text }}</span>
        </div>
    </div>
</ng-container>
