import { inheritSerialization, deserialize } from 'cerialize';
import { EntityActivityEventBase, registerTypedEvent } from '../events';

@inheritSerialization(EntityActivityEventBase)
export abstract class LocalSynonymEventBase extends EntityActivityEventBase {
    @deserialize DisplayName: string;
    @deserialize Description: string;
}

@inheritSerialization(LocalSynonymEventBase)
@registerTypedEvent('LocalSynonymCreatedEvent')
export class LocalSynonymCreatedEvent extends LocalSynonymEventBase {}
@inheritSerialization(LocalSynonymEventBase)
@registerTypedEvent('LocalSynonymDeletedEvent')
export class LocalSynonymDeletedEvent extends LocalSynonymEventBase {}
@inheritSerialization(LocalSynonymEventBase)
@registerTypedEvent('LocalSynonymUpdatedEvent')
export class LocalSynonymUpdatedEvent extends LocalSynonymEventBase {
    @deserialize PreviousDisplayName: string;
    @deserialize PreviousDescription: string;
}
