import { EntityType } from '@datagalaxy/dg-object-model';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';

export class PrimaryKeyGridData {
    public get tableId() {
        return this.pkTable.ReferenceId;
    }
    public get primaryKeyTechnicalName() {
        return this.pk.TechnicalName;
    }
    public get tableTechnicalName() {
        return this.pkTable.TechnicalName;
    }
    public get columnTechnicalName() {
        return this.pkColumn.TechnicalName;
    }
    public get primaryKeyColumnOrder() {
        return this.pkColumn.PkOrder;
    }
    public get tableColumnOrder() {
        return this.pkColumn.DisplayOrder;
    }
    public get primaryKeyStructureType() {
        return EntityTypeUtil.getMapping(
            this.pkTable.EntityType
        ).getTranslateKey();
    }

    constructor(
        private pk: { TechnicalName: string },
        private pkTable: {
            ReferenceId: string;
            TechnicalName: string;
            EntityType: EntityType;
        },
        private pkColumn: {
            TechnicalName: string;
            PkOrder?: number;
            DisplayOrder: number;
        }
    ) {}
}
