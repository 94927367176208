import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { DxyBaseFilterComponent } from '../base-filter/base-filter.component';
import { INumberFilterData, NumberFilterOperator } from './number-filter.types';
import { NumberFilterUtils } from './number-filter.utils';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DxyAutoFocusInputDirective } from '../../directives/auto-focus-input.directive';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DxyFilterButtonComponent } from '../filter-button/filter-button.component';

/**
 * ## Role
 * Display a number filter button
 */
@Component({
    selector: 'dxy-number-filter',
    templateUrl: './number-filter.component.html',
    styleUrls: ['./number-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyFilterButtonComponent,
        NgIf,
        TranslateModule,
        MatLegacyMenuModule,
        MatLegacyInputModule,
        FormsModule,
        DxyAutoFocusInputDirective,
    ],
})
export class DxyNumberFilterComponent
    extends DxyBaseFilterComponent<INumberFilterData, NumberFilterOperator>
    implements OnInit
{
    public get isResolved() {
        return NumberFilterUtils.isResolved(this.filter);
    }
    public get hasMin() {
        return this.filter?.min != null;
    }
    public get hasMax() {
        return this.filter?.max != null;
    }
    public get isNumberRange() {
        return this.filter?.operator === NumberFilterOperator.RangeContains;
    }
    public get showOperator() {
        return this.operator !== NumberFilterOperator.Equals;
    }
    public get operatorTranslateKey() {
        return this.getOperatorTranslateKey(this.operator);
    }
    public get isValueLessOperator() {
        return NumberFilterUtils.isValueLessOperator(this.operator);
    }

    private minChange$ = new Subject<number>();
    private maxChange$ = new Subject<number>();

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.init();

        super.registerSubscriptions(
            this.minChange$
                .pipe(debounceTime(444), distinctUntilChanged())
                .subscribe((value) => {
                    this.filter.min = value;
                    this.onFilterChange();
                }),
            this.maxChange$
                .pipe(debounceTime(444), distinctUntilChanged())
                .subscribe((value) => {
                    this.filter.max = value;
                    this.onFilterChange();
                })
        );
    }

    public onOperatorChange(operator: NumberFilterOperator) {
        this.log('onOperatorChange', operator);
        this.filter.operator = operator;
        this.onFilterChange();
    }

    public onMinChange(value: number) {
        this.minChange$.next(value);
    }

    public onMaxChange(value: number) {
        this.maxChange$.next(value);
    }

    public getOperatorTranslateKey(operator: NumberFilterOperator) {
        return NumberFilterUtils.getOperatorTranslateKey(operator);
    }

    private init() {
        if (!this.filter) {
            this.filter = {};
        }
        if (!this.operator) {
            this.operator = NumberFilterOperator.Equals;
        }
        this.operators ??=
            CollectionsHelper.getEnumValues<NumberFilterOperator>(
                NumberFilterOperator
            ).filter(
                (o) =>
                    !this.excludedOperators?.includes(o) &&
                    o !== NumberFilterOperator.Unknown
            );
    }

    private onFilterChange() {
        this.cd.detectChanges();
        this.filterChange.emit(this.filter);
    }
}
