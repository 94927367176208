<div
    tabindex="-1"
    class="vcontainerDiv modal-container"
    [ngClass]="data?.modalClass"
    (keydown.enter)="onKeyDownEnter($event)"
>
    <div *ngIf="!data.noHeader" class="modal-header">
        <h3 mat-dialog-title class="modal-title">
            {{ data.noHeader }}
            <span
                *ngIf="data?.titleKey"
                [innerHTML]="data.titleKey | translate"
            ></span>
            <span *ngIf="data?.title" [innerText]="data.title"></span>
        </h3>
        <button
            mat-icon-button
            (click)="onCloseCancel()"
            class="modal-close-btn"
            dxyDataTestId="modal-close-button"
        ></button>
    </div>

    <div *ngIf="data?.messageKey" mat-dialog-content>
        <p
            [innerHTML]="data.messageKey | translate"
            [dxyDataTestId]="'modal-message-' + data.messageKey"
        ></p>
    </div>

    <div *ngIf="data?.message" mat-dialog-content>
        <p [innerHTML]="data.message" dxyDataTestId="modal-message"></p>
    </div>

    <div *ngIf="data?.hasUserInput" mat-dialog-content>
        <p
            *ngIf="data.userInputMessage"
            [innerHTML]="data.userInputMessage"
        ></p>
        <dxy-field-text
            [ngClass]="data.userInputClass"
            [labelText]="data.userInputLabel"
            [labelKey]="data.userInputLabelKey"
            [(ngModel)]="userInputValue"
            [infoTooltip]="data.userInputInfoKey | translate"
            [takeFocus]="true"
            dxyDataTestId="modal-input"
        ></dxy-field-text>
    </div>

    <div class="modal-footer dxy-button-group">
        <button
            *ngFor="let btn of buttons"
            dxyButton
            [color]="btn.color ?? 'secondary'"
            [disabled]="isBtnDisabled(btn)"
            (click)="onBtnClick(btn, $event)"
            [dxyDataTestId]="getBtnDataTestId(btn)"
        >
            <i *ngIf="btn.glyphClass" [ngClass]="btn.glyphClass"></i>
            <span [translate]="btn.textKey"></span>
        </button>
    </div>
</div>
