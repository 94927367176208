import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';

/**
 * ## Role
 * Manage component visibility based on a feature flag
 *
 * ## Features
 * - Display host if feature flag value is true
 * - Display a templateRef if feature flag value is false
 */
@Directive({
    selector: '[dxyIfFeatureFlag]',
    standalone: true,
})
export class FeatureFlagDirective implements OnInit {
    @Input() dxyIfFeatureFlag!: string | string[];
    @Input() dxyIfFeatureFlagElse?: TemplateRef<unknown>;

    private get featureFlags() {
        if (Array.isArray(this.dxyIfFeatureFlag)) {
            return this.dxyIfFeatureFlag;
        }
        return [this.dxyIfFeatureFlag];
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private featureFlagService: FeatureFlagService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        try {
            const flagEnabled =
                !this.dxyIfFeatureFlag ||
                this.featureFlagService.haveFeatureEnabled(this.featureFlags);
            flagEnabled ? this.onIf() : this.onElse();
        } catch {
            this.onElse();
        }
    }
    private onIf() {
        this.createView(this.templateRef);
    }

    private onElse() {
        if (!this.dxyIfFeatureFlagElse) {
            return;
        }
        this.createView(this.dxyIfFeatureFlagElse);
    }

    private createView(templateRef: TemplateRef<unknown>): void {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(templateRef);
        this.cd.detectChanges();
    }
}
