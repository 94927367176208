import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    UserSettingCategoryRouteDto,
    UserSettingCategoryValueDto,
    UserSettingsDto,
} from './user-settings.types';
import { BaseUserServiceParameter } from '../user';

@inheritSerialization(BaseServiceParameter)
export class GetUserSettingValueParameter extends BaseServiceParameter {
    @serialize public Category: string;
    @serialize public Route?: string;
    @serialize public GoldenSettingsUserLocalId?: string;

    constructor(
        category: string,
        route: string,
        goldenSettingsUserLocalId?: string
    ) {
        super();
        this.Category = category;
        this.Route = route;
        this.GoldenSettingsUserLocalId = goldenSettingsUserLocalId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingValueResult extends BaseServiceResult {
    @deserialize public Value?: string;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class SetUserSettingValueParameter extends BaseServiceParameter {
    @serialize public Category: string;
    @serialize public Route?: string;
    @serialize public Value: string;
    @serialize public Meta?: string;

    constructor(category: string, route: string, value: string, meta?: string) {
        super();
        this.Category = category;
        this.Route = route;
        this.Value = value;
        this.Meta = meta;
    }
}

@inheritSerialization(BaseServiceResult)
export class SetUserSettingValueResult extends BaseServiceResult {
    constructor() {
        super();
    }
}

export class GetUserSettingCategoryValuesParameter extends BaseServiceParameter {
    @serialize public Category: string;

    constructor(category: string) {
        super();
        this.Category = category;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingCategoryValuesResult extends BaseServiceResult {
    @deserialize public Values?: UserSettingCategoryValueDto[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingCategoryRoutesResult extends BaseServiceResult {
    @deserialize public Routes?: UserSettingCategoryRouteDto[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseUserServiceParameter)
export class SetUserSettingsParameter extends BaseUserServiceParameter {
    @serialize public ClientId: string;
    @serialize public NotificationCleanupPeriod?: number;
    @serialize public NotificationRealTimeBehavior?: number;
    @serialize public UserLanguageCode?: string;

    constructor(clientId: string, userId: string) {
        super(userId);
        this.ClientId = clientId;
    }
}

@inheritSerialization(BaseServiceResult)
export class SetUserSettingsResult extends BaseServiceResult {
    @deserialize IsErrorUnknownClientLocalId!: boolean;
    @deserialize IsErrorUnknownUserLocalId!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteUserSettingValueParameter extends BaseServiceParameter {
    @serialize public Category: string;
    @serialize public Route?: string;

    constructor(category: string, route: string) {
        super();
        this.Category = category;
        this.Route = route;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteUserSettingValueResult extends BaseServiceResult {
    constructor() {
        super();
    }
}

@inheritSerialization(BaseUserServiceParameter)
export class GetUserSettingsParameter extends BaseUserServiceParameter {
    @serialize public ClientId: string;

    constructor(clientId: string, userId: string) {
        super(userId);
        this.ClientId = clientId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingsResult extends BaseServiceResult {
    @deserialize IsErrorUnknownClientLocalId!: boolean;
    @deserialize IsErrorUnknownUserLocalId!: boolean;
    @deserializeAs(UserSettingsDto) UserSettings!: UserSettingsDto;
}
