import { SerializableEnumeration } from 'cerialize';

/** Alias for *DataGalaxyModule* enum.
 *
 *  Values are stored (in db) both by their number
 *  and their name (Filtered View ModuleName)
 * */
export enum DataGalaxyModule {
    unknown = 0,
    Glossary = 1,
    Catalog = 2,
    Processing = 3,
    Usage = 4,
    Diagram = 5,
}
SerializableEnumeration(DataGalaxyModule);

export enum DgModule {
    unknown = DataGalaxyModule.unknown,

    /** (functional roles:  functional, static) */
    Glossary = DataGalaxyModule.Glossary,

    /** (functional roles: technical, static) */
    Catalog = DataGalaxyModule.Catalog,

    /** ex 'Data Processings'. (functional roles: technical, dynamic) */
    Processing = DataGalaxyModule.Processing,

    /** ex 'Sotftwares'. (functional roles: functional, dynamic) */
    Usage = DataGalaxyModule.Usage,

    /** Generic diagrams */
    Diagram = DataGalaxyModule.Diagram,
}
SerializableEnumeration(DgModule);
