<dxy-field-data-type-size
    #field
    [(ngModel)]="fieldValue"
    [availableDataTypes]="availableDataTypes"
    [adapter]="adapter"
    [noTypeLabel]="true"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [readonly]="readonly"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    [hint]="hint"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
></dxy-field-data-type-size>

<dxy-unitary-field-actions
    *ngIf="!readonly"
    [input]="actionsInput"
></dxy-unitary-field-actions>
