<span *ngIf="iconText" class="cell-icon" [ngClass]="iconClass">{{
    iconText
}}</span>
<span
    *ngIf="glyphClass"
    class="cell-icon"
    [ngClass]="glyphClass"
    [class.no-text]="!text"
    [matTooltip]="glyphTooltip"
></span>
<img *ngIf="imgUrl" class="cell-image" [src]="imgUrl" [class.no-text]="!text" />
<span class="cell-text" dxyEllipsisTooltip> {{ text }} </span>
