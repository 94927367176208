import * as _ from 'lodash';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { INgZone, ZoneUtils } from '@datagalaxy/utils';

export interface INewable<T> {
    new (...args: any[]): T;
}

export class CoreUtil {
    // Every app using CoreUtil has to set these values at startup
    static isProduction = false;
    static isDebuggingBoot = () => false;

    /** execute console.warn if not in production mode */
    static warn(message?: unknown, ...optionalParams: unknown[]) {
        if (!CoreUtil.isProduction) {
            console.warn(message, ...optionalParams);
        }
    }
    /** execute console.log if not in production mode */
    static log(...args: unknown[]) {
        if (!CoreUtil.isProduction) {
            console.log(...args);
        }
    }

    /**
     * Assigns own enumerable string keyed properties of source objects to the destination object.
     * Source objects are applied from left to right. Subsequent sources overwrite property assignments of previous sources.
     *
     * Note: This method mutates object and is loosely based on Object.assign.
     * @deprecated Use `ObjectUtils.assign()` from `@datagalaxy/utils` instead
     */
    static assign<TObject, TSource>(
        object: TObject,
        source: TSource
    ): TObject & TSource {
        return _.assign(object, source);
    }

    /**
     * This method creates an object composed of the own and inherited
     * enumerable properties of *object* that *omitIfTruthy* doesn't return truthy for
     */
    static omitBy<TObject extends object>(
        object: TObject,
        omitIfTruthy: (object: any) => boolean
    ) {
        return _.omitBy(object, omitIfTruthy);
    }

    static merge<TObject, TSource>(
        object: TObject,
        source: TSource
    ): TObject & TSource {
        return _.merge(object, source);
    }

    static mergeWith<TObject, TSource>(
        object: TObject,
        source: TSource,
        customizer?: _.MergeWithCustomizer
    ): TObject & TSource {
        return _.mergeWith(object, source, customizer);
    }

    /** Merges *keys* properties of *source* into *object* */
    static mergePartial<I, T extends Partial<I>>(
        object: T,
        source: I,
        ...keys: (keyof I)[]
    ) {
        if (object && source && keys) {
            keys.forEach((k) => (object[k as string | number] = source[k]));
        }
        return object;
    }
    /** Merges *keys* properties of *source* into *object*, only if not *null* nor *undefined* in *source* */
    static mergePartialSourceDefined<I, T extends Partial<I>>(
        object: T,
        source: I,
        ...keys: (keyof I)[]
    ) {
        if (object && source && keys) {
            keys.filter((k) => source[k] !== undefined).forEach(
                (k) => (object[k as string | number] = source[k])
            );
        }
        return object;
    }

    /** Instanciates a new T with *ctorArgs* and merge *keys* properties of *source* into it */
    static buildPartial<I, T extends I>(
        ctor: INewable<T>,
        source: I,
        keys: (keyof I)[],
        ...ctorArgs: unknown[]
    ) {
        return CoreUtil.mergePartial(new ctor(...ctorArgs), source, ...keys);
    }

    static clone<T>(o: T): T {
        return _.clone(o);
    }

    static cloneDeep<T>(o: T): T {
        return _.cloneDeep(o);
    }

    static areEqual<TObject>(object1: TObject, object2: TObject): boolean {
        return _.isEqual(object1, object2);
    }

    static assignIfUndefined<T>(target: T, source: Partial<T>): T {
        for (const key in source) {
            if (target[key] !== undefined || source[key] === undefined) {
                continue;
            }
            target[key] = source[key];
        }
        return target;
    }

    /**
     * Gets the property value at path of object. If the resolved value is undefined the defaultValue is used in its place.
     * Path can be a string in the following form : 'a.b.c' or 'a[0].b.c'
     * or an array : ['a', '0', 'b', 'c']
     */
    static getObjectPathValue<TObject extends object, T>(
        object: TObject,
        path: string | string[],
        defaultValue?: T
    ) {
        return _.get(object, path, defaultValue);
    }

    /** Escapes the RegExp special characters "^", "$", '"', ".", "*", "+", "?", "(", ")", "[", "]", "{", "}", and "|" in string. */
    static escapeRegExp(s: string) {
        return _.escapeRegExp(s);
    }

    /** returns a random string of the given length, made of lowercase and digits */
    static getRandomString(length: number) {
        let s = '';
        do {
            s += Math.random().toString(36).substr(2);
        } while (s.length < length);
        return s.substr(0, length);
    }

    /** Executes the given *test* function every *pollDelayMs* milliseconds until:
     * - it returns something other than null or undefined,
     * - the optional *timeoutMs* (in miliseconds) is elapsed,
     * - the optional *cancel* function (executed right after each non resulting *test*()) returns a truthy value
     * (a non-empty string will be used as the rejection reason.
     *
     * If *test* throws an error, the error will be used as the rejection reason.
     * If the optional *resolveWithNullOnError* is true,
     * the returned promise will be resolved with *null* instead of being rejected */
    public static async waitFor<T>(test: () => T, opt?: IOptionsWaitFor) {
        return new Promise<T>((resolve, reject) => {
            let timerPoll: number;
            let timerTimeout: number;
            let timedOut: boolean;

            const log = (...args: unknown[]) => {
                if (opt?.debug) {
                    CoreUtil.log('waitFor', ...args);
                }
            };

            const result = (err: string, res?: T) => {
                log('result', err, res);
                window.clearTimeout(timerPoll);
                window.clearTimeout(timerTimeout);
                if (res) {
                    resolve(res);
                } else if (opt?.resolveWithNullOnError) {
                    resolve(null);
                } else {
                    reject(err);
                }
            };

            if (typeof test != 'function') {
                result('test is not a function.');
                return;
            }

            const query = () => {
                try {
                    const res = test();
                    log('query-test-res', res);
                    if (res != undefined) {
                        result(null, res);
                        return true;
                    }
                } catch (e) {
                    log('query-catch', e);
                    result(e);
                    return true;
                }
            };
            if (query()) {
                return;
            }

            const { ngZone, outsideAngular } = opt ?? {};

            const wait = () => {
                let canceled: boolean | string;
                timerPoll = ZoneUtils.zoneTimeout(
                    () => {
                        if (query()) {
                            return;
                        } else if (
                            typeof opt?.cancel == 'function' &&
                            (canceled = opt.cancel())
                        ) {
                            const reason =
                                typeof canceled == 'string'
                                    ? canceled
                                    : 'Canceled.';
                            result(reason);
                        } else if (timedOut) {
                            result('Timeout.');
                        } else {
                            wait();
                        }
                    },
                    opt?.pollDelayMs || 222,
                    ngZone,
                    outsideAngular
                );
            };
            timerTimeout = ZoneUtils.zoneTimeout(
                () => (timedOut = true),
                opt?.timeoutMs || 1000,
                ngZone,
                outsideAngular
            );
            wait();
        });
    }

    static startCancellableTimeout<T>(
        fn?: () => T | Promise<T>,
        delayMs?: number,
        onCancel?: () => void,
        ngZone?: INgZone,
        outsideAngular = false
    ): ICancellableTimeout<T> {
        return ZoneUtils.zoneExecute(
            () => {
                let timer: number;
                let executor: IExecutor<T>;
                const promise = new Promise<T>((resolve, reject) => {
                    executor = { resolve, reject };
                    timer = window.setTimeout(() => {
                        timer = undefined;
                        try {
                            resolve(fn?.());
                        } catch (err) {
                            reject(err);
                        }
                    }, delayMs);
                });
                const cancel = () => {
                    if (!timer) {
                        return;
                    }
                    window.clearTimeout?.(timer);
                    timer = null;
                    if (onCancel) {
                        try {
                            onCancel();
                        } catch (e) {
                            if (!CoreUtil.isProduction) {
                                console.warn(e);
                            }
                        }
                    }
                    executor.resolve();
                };
                return {
                    cancel,
                    promise,
                    executor,
                    get cancelled() {
                        return timer === null;
                    },
                };
            },
            ngZone,
            outsideAngular
        );
    }

    /** Returns a promise that will be resolved with the result of the given function
     * executed with *window.setTimeout*.
     * If *ngZone* is provided, execution is done using *ngZone.run* or,
     * if *outsideAngular* is true, using *ngZone.runOutsideAngular* */
    static startTimeout<T>(
        fn?: () => T | Promise<T>,
        delayMs?: number,
        ngZone?: INgZone,
        outsideAngular?: boolean
    ) {
        return ZoneUtils.zoneExecute(
            () => {
                return new Promise<T>((resolve, reject) => {
                    window.setTimeout(() => {
                        try {
                            resolve(fn?.());
                        } catch (err) {
                            reject(err);
                        }
                    }, delayMs);
                });
            },
            ngZone,
            outsideAngular
        );
    }
    /** Executes *window.setTimeout* passing it the given function and delay.
     * If *ngZone* is provided, execution is done using *ngZone.run* or,
     * if *outsideAngular* is true, using *ngZone.runOutsideAngular* */
    static zoneTimeout(
        fn: () => unknown,
        delayMs?: number,
        ngZone?: INgZone,
        outsideAngular?: boolean
    ): number {
        //console.log('zoneTimeout', delayMs, !!ngZone, outsideAngular, !!fn, NgZone.isInAngularZone())
        return ZoneUtils.zoneExecute(
            () => window.setTimeout(fn, delayMs),
            ngZone,
            outsideAngular
        );
    }

    /** Returns the value from the given function or value.
     *
     * WARNING: There is no type-cheking for *args*
     */
    public static fromFnOrValue<T extends TBaseType>(
        fnOrValue: FnOrValue<T>,
        ...args: unknown[]
    ): T {
        return typeof fnOrValue == 'function' ? fnOrValue(...args) : fnOrValue;
    }

    /** returns the content of the given File as a data URL */
    public static async readAsDataUrl(file: Blob) {
        return new Promise<string>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => resolve(fr.result as string);
            fr.onerror = () => reject(fr.error);
            fr.readAsDataURL(file);
        });
    }

    /** returns the content of the given File as text */
    public static async readAsText(file: Blob, encoding?: string) {
        return new Promise<string>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => resolve(fr.result as string);
            fr.onerror = () => reject(fr.error);
            fr.readAsText(file, encoding);
        });
    }

    //#region for RxJS
    /** Returns a promise from the given Observable.
     * By default, the first emited value is returned.
     * - To be used instead of the deprecated [.toPromise()](https://rxjs.dev/deprecations/to-promise) of an Observable */
    static toPromise<T>(
        observable: Observable<T>,
        defaultValue: T = undefined,
        useLastValue = false
    ) {
        return useLastValue
            ? lastValueFrom(observable, { defaultValue })
            : firstValueFrom(observable, { defaultValue });
    }
    //#endregion

    /** Returns a deep copy of the given object, with every value-type property set to *null* if *isActive* is *falsy*.
     * Usefull, for example, to initialize a configuration object that depends on a debug mode being on or off. */
    public static getNulledIfInactive<T extends TBaseObj>(
        source: T,
        isActive: boolean
    ): T {
        if (Array.isArray(source)) {
            return source.map((v) =>
                CoreUtil.getNulledIfInactive(v, isActive)
            ) as T;
        }
        if (typeof source == 'object') {
            const result = {} as T;
            Object.entries(source).forEach(
                ([k, v]) =>
                    (result[k] = CoreUtil.getNulledIfInactive(v, isActive))
            );
            return result;
        }
        return isActive ? source : null;
    }
}

export interface IOptionsWaitFor {
    /** delay in milliseconds beyond which the operation is failed */
    timeoutMs?: number;
    /** delay in milliseconds between 2 test executions */
    pollDelayMs?: number;
    /** when true, the promise is resolved with null instead of being rejected */
    resolveWithNullOnError?: boolean;
    /** (set by the executer) callback to call to cancel the operation */
    cancel?: () => boolean | string;
    /** true to log execution traces */
    debug?: boolean;
    /** when provided, *setTimeout()* will be executed using this zone's *run()*, or *runOutsideAngular()* */
    ngZone?: INgZone;
    /** when true and *ngZone* is provided, *setTimeout()* will be executed using this zone's *runOutsideAngular()* */
    outsideAngular?: boolean;
}

/** simple replacement for ng.IDeferred:
 * an object containing the *resolve* and *reject* methods used in a promise */
export interface IExecutor<T> {
    resolve(value?: T | PromiseLike<T>): void;
    reject(reason?: unknown): void;
}

export interface ICancellableTimeout<T> {
    cancel: () => void;
    promise: Promise<T>;
    executor: IExecutor<T>;
    readonly cancelled: boolean;
}

type TBaseObj =
    | boolean
    | string
    | number
    | TBaseObj[]
    | { [key: string]: TBaseObj };

type TBaseType = number | string | boolean | object | unknown[];
export type FnOrValue<T extends TBaseType> = T | ((...args: unknown[]) => T);
export type LogFn = (...args: unknown[]) => void;

/** Returns a promise that will be resolved by *setTimeout*, optionally run within or outside the given *ngZone*.
 * Without parameters, this is equivalent to `new Promise<void>(resolve => setTimeout(resolve))` */
export async function wait(
    delayMs?: number,
    ngZone?: INgZone,
    outsideAngular?: boolean
) {
    return CoreUtil.startTimeout<void>(
        undefined,
        delayMs,
        ngZone,
        outsideAngular
    );
}
