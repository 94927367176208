<div *ngIf="data.isCreate" class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.EntityTimeSeriesHistoryModal.addValue
    </h3>
    <button
        dxyIconButton
        color="transparent"
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <form class="form-container" (ngSubmit)="onSubmit()">
        <dxy-field-date
            class="field"
            labelKey="UI.EntityTimeSeriesHistoryModal.edit.dateLabel"
            name="date"
            [ngModel]="timeSeriesEntry.Date"
            (ngModelChange)="onDateChange($event)"
        ></dxy-field-date>
        <dxy-field-number
            class="field"
            labelKey="UI.EntityTimeSeriesHistoryModal.edit.valueLabel"
            name="value"
            [(ngModel)]="timeSeriesEntry.Value"
            (change)="onValueChange()"
        ></dxy-field-number>
        <dxy-spinner
            *ngIf="loading$ | async"
            class="xs no-padding no-margin"
        ></dxy-spinner>
        <div *ngIf="(loading$ | async) === false" class="edit-actions">
            <button
                dxyIconButton
                type="submit"
                [disabled]="isDisabled"
                class="edit-action-save"
                [matTooltip]="
                    'UI.EntityTimeSeriesHistoryModal.saveValueTT' | translate
                "
            >
                <i class="glyph-check"></i>
            </button>
            <button
                dxyIconButton
                color="secondary"
                type="button"
                (click)="onCloseCancel()"
                [matTooltip]="
                    'UI.EntityTimeSeriesHistoryModal.cancelValueTT' | translate
                "
            >
                <i class="glyph-cancelsearch"></i>
            </button>
        </div>
    </form>
    <div *ngIf="errorMessage" class="error-message">
        <i class="glyph-warning"></i>
        <span [translate]="errorMessage"></span>
    </div>
</div>
