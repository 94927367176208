<ng-container *ngIf="panelBodyData">
    <div class="header-wrapper">
        <dxy-entity-panel-header
            [entityData]="entityData"
            [readOnly]="readOnly"
            [noNavLink]="noNavLink"
            (onCloseClick)="onCloseClick()"
            closeDtName="Entity Preview Pane - Toggle Collapse"
        ></dxy-entity-panel-header>
    </div>
    <dxy-entity-panel-body
        [data]="panelBodyData"
        [activeState]="activeState$ | async"
        [dragDropConfig]="dragDropConfig$ | async"
        (toolClick)="onToolClick($event)"
        logId="preview-panel"
    ></dxy-entity-panel-body>
</ng-container>

<app-entity-preview-panel-skeleton
    *ngIf="!panelBodyData"
></app-entity-preview-panel-skeleton>
