<dxy-filter-carousel
    [displayMode]="displayMode"
    (scrollLeftClick)="scrollPrevious.emit()"
    (scrollRightClick)="scrollNext.emit()"
>
    <div
        *ngFor="let afm of filterItems"
        (click)="onFilterItemClick(afm)"
        dxyFilterCarouselItem
    >
        <app-attribute-filter
            [filterItemData]="afm"
            [isReadOnly]="readonly"
            (onAction)="onFilterItemAction(afm, $event)"
        ></app-attribute-filter>
    </div>
</dxy-filter-carousel>
