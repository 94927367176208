<dxy-icon-card
    [size]="size"
    [icon]="icon"
    [iconType]="iconType"
    [iconTooltip]="tooltip | translate"
    [iconTooltipDisabled]="tooltipDisabled"
    [text]="entityTypeNameKey | translate"
></dxy-icon-card>

<div *ngIf="showEntityChip" class="chip-container">
    <dxy-entity-type-icon
        *ngIf="!showEntityGlyph"
        [size]="chipSize"
        [entityType]="entityType"
        [tooltipDisabled]="tooltipDisabled"
    ></dxy-entity-type-icon>
    <dxy-icon
        *ngIf="!showIconUrl"
        [size]="chipSize"
        type="url"
        [icon]="iconUrl"
        [matTooltip]="iconTooltip"
    ></dxy-icon>
</div>
