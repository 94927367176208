export const metaBotUserFullName = 'MetaBot';
export const metaBotUserId = 'e37ab070-0000-0000-0000-000000000000';
export const userSettingValueViewType = 'view-type';
//#region First access user-settings
/**
 * User setting category keys
 * - Mind that some values ("routes") are dynamic
 * - Please do not change string values
 */
export const userSettingsValues = {
    angularSplit: {
        category: 'angular-split',
        routes: { dpMapping: 'dp-mapping', moduleMain: 'module-main' },
    },
    denodo: {
        category: 'denodo',
        routes: { settings: 'settings' },
    },
    display: {
        category: 'display',
        routes: { entityCard: 'entity-card', viewType: 'view-type' },
    },
    dks: {
        category: 'dks',
        routes: { linagev2: 'linagev2' },
    },
    firstAccess: {
        category: 'client-first-access',
        routes: { data: 'data' },
    },
    import: {
        category: 'import',
        routes: { settings: 'settings' },
    },
    navigation: {
        category: 'navigation',
        routes: { landingPage: 'landing-page' },
    },
    omniGrid: {
        category: 'omni-grid',
        routes: {
            mainSearchResults: 'main-search-results',
            diagramList: 'diagrams-list',
            campaignPrefix: 'campaign-details-objects-', // followed with campaign id
        },
    },
    search: {
        category: 'search',
        routes: { settings: 'settings' },
    },
    searchFacetFilter: {
        category: 'search-facet-filter',
        routes: { settings: 'settings' },
    },
    suggestions: {
        category: 'suggestions',
        routes: { settings: 'settings' },
    },
    userSegmentation: {
        category: 'user-segmentation',
        routes: { data: 'user-segmentation-data' },
    },
    widgetConf: {
        category: 'widget-configuration',
        routes: {
            globalActivity: 'users-global-activity-widget',
            adminAnalytics: 'client-admin-global-analytics-widget',
        },
    },
    lineage: {
        category: 'lineage',
        routes: {
            globalState: 'global-state',
        },
    },
};
