import { inject } from '@angular/core';
import { FunnelAction, FunnelStepComponent } from '@datagalaxy/ui/funnel';
import { TranslateService } from '@ngx-translate/core';

export abstract class MultilingualFunnelStepComponent extends FunnelStepComponent {
    protected translate: TranslateService = inject(TranslateService);

    protected previousAction: FunnelAction = {
        actionType: 'previous',
        label: this.translate.instant('UI.Global.btnPrevious'),
        color: 'secondary',
    };
    protected nextAction: FunnelAction = {
        actionType: 'next',
        label: this.translate.instant('UI.Global.btnNext'),
        color: 'primary',
    };
}
