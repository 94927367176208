<div class="solution-details-wrapper">
    <img class="solution-img" [src]="solutionImgUrl" alt="" />
    <div class="right-container">
        <span class="title-text">{{ titleText }}</span>
        <div *ngIf="isWarningVisible" class="description-warning">
            <span class="glyph-warning"></span>
            <span translate
                >Import.GenericImportWizard.SolutionDetails.EmbeddedImport.AccessRightsWarning</span
            >
        </div>
        <span class="description-text" [innerHTML]="descriptionHtml"></span>

        <button mat-button (click)="onClickHelp()">
            <i class="glyph-help"></i
            ><span translate
                >Import.GenericImportWizard.SolutionDetails.OnlineHelpLbl</span
            >
        </button>

        <button *ngIf="isCsvImport" mat-button (click)="onClickSampleFiles()">
            <i class="glyph-help"></i
            ><span translate
                >Import.GenericImportWizard.SolutionDetails.CsvImport.SampleFilesBtn</span
            >
        </button>
        <button *ngIf="isOnlineImport" mat-button (click)="onClickUseTokens()">
            <i class="glyph-help"></i
            ><span translate
                >Import.GenericImportWizard.SolutionDetails.EmbeddedImport.UseTokensBtn</span
            >
        </button>
        <button
            *ngIf="isDesktopImport"
            mat-button
            (click)="onClickOperationMode()"
        >
            <i class="glyph-help"></i
            ><span translate
                >Import.GenericImportWizard.SolutionDetails.DesktopImport.OperationModeBtn</span
            >
        </button>
        <button
            *ngIf="isDesktopImport"
            mat-button
            (click)="onClickConnector64Download()"
            [disabled]="isDownloadingConnector64"
            [matTooltip]="
                'Import.GenericImportWizard.SolutionDetails.DesktopImport.DownloadConnector64Tooltip'
                    | translate
            "
        >
            <i *ngIf="!isDownloadingConnector64" class="glyph-download"></i>
            <i
                *ngIf="isDownloadingConnector64"
                class="dg5-spinner xs-spinner"
            ></i>
            <span translate
                >Import.GenericImportWizard.SolutionDetails.DesktopImport.DownloadConnector64Lbl</span
            >
        </button>
        <button
            *ngIf="isDesktopImport"
            mat-button
            (click)="onClickConnector32Download()"
            [disabled]="isDownloadingConnector32"
            [matTooltip]="
                'Import.GenericImportWizard.SolutionDetails.DesktopImport.DownloadConnector32Tooltip'
                    | translate
            "
        >
            <i *ngIf="!isDownloadingConnector32" class="glyph-download"></i>
            <i
                *ngIf="isDownloadingConnector32"
                class="dg5-spinner xs-spinner"
            ></i>
            <span translate
                >Import.GenericImportWizard.SolutionDetails.DesktopImport.DownloadConnector32Lbl</span
            >
        </button>
    </div>
</div>
