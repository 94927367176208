import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { IShift, IXY, IXYRect, Rect } from '@datagalaxy/core-2d-util';
import { TranslateService } from '@ngx-translate/core';
import {
    DiagramService,
    TDiagramUpdateContentOperations,
} from './diagram.service';
import { DiagramConstants } from './diagram.constants';
import {
    DiagramLinkType,
    ICreatedElements,
    ICreateEdgeInfo,
    ICreateElementsFromIdentifierOptions,
    IDiagramElementIdentifier,
    IDiagramModelData,
    IDiagramNodeSpec,
    IEdgeElementMenuDataItem,
    IEdgeElementMenuDataItems,
    IExistingLinks,
    INodeCreationData,
    TDiagramNodeData,
    TNodeOrIdr,
    TNodeType,
    TNonEntityNodeType,
    TSizeMode,
} from './diagram.types';
import { DiagramData } from './DiagramData';
import { DiagramIdentifier } from '../DiagramIdentifier';
import { DiagramPublishModalComponent } from '../diagram-publish-modal/diagram-publish-modal.component';
import { IDiagramPublishModalInputs } from '../diagram-publish-modal/diagram-publish-modal.types';
import { DiagramSecurityService } from './diagram-security.service';
import {
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    IHierarchicalData,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityService } from '../../shared/entity/services/entity.service';
import { ViewTypeService } from '../../services/viewType.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { EntityLinkService } from '../../entity-links/entity-link.service';
import { ModelerService } from '../../modeler/services/modeler.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import {
    IEntityLinkIdentifier,
    ILinkedEntityInfo,
} from '../../shared/entity/linked-object.types';
import { IOpenCreationModalOptions } from '../../shared/entity/interfaces/entity-create-modal.types';
import { Model, Table } from '@datagalaxy/webclient/modeler/data-access';
import {
    ApiServiceErrorType,
    IHasTechnicalName,
    isApiError,
} from '@datagalaxy/data-access';
import {
    AddLinkAction,
    AttributeValueInfo,
} from '@datagalaxy/webclient/entity/data-access';
import {
    DiagramEdgeDto,
    DiagramEdgeKind,
    DiagramKind,
    DiagramNodeDto,
    DiagramNodeKind,
    PublishingStatus,
} from '@datagalaxy/webclient/diagram/data-access';
import {
    EntityIdentifier,
    EntityLinkUtils,
} from '@datagalaxy/webclient/entity/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import {
    EntityCreationOrigin,
    EntityCreator,
} from '@datagalaxy/webclient/entity/feature';

/**
 * ## Role
 *  Performs high level or service-dependant, or Model-related operations on a given diagram
 * ## Type
 *  Stateless
 */
@Injectable({ providedIn: 'root' })
export class DiagramDataService extends BaseService {
    //#region static helpers

    public static hasSourceId(diagram: EntityItem, modelId: string) {
        return !!(
            diagram &&
            modelId &&
            diagram.ServerType == ServerType.Diagram &&
            diagram
                .getAttributeValue<IHierarchicalData[]>(
                    ServerConstants.Diagram.ObjectLinks_DiagramHasSource_HData
                )
                ?.some((sid) => sid.Data.DataReferenceId == modelId)
        );
    }

    public static hasEntityId(diagram: EntityItem, entityId: string) {
        return !!(
            diagram &&
            entityId &&
            diagram.ServerType == ServerType.Diagram &&
            diagram
                .getAttributeValue<string[]>(
                    ServerConstants.Diagram.DiagramEntityIds
                )
                ?.includes(entityId)
        );
    }

    //#endregion

    public get isTechnicalView() {
        return this.viewTypeService.isTechnicalView;
    }

    constructor(
        private entityService: EntityService,
        private entityCreator: EntityCreator,
        private viewTypeService: ViewTypeService,
        private translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private linkedObjectService: EntityLinkService,
        private entityUiService: EntityUiService,
        private diagramService: DiagramService,
        private modelerService: ModelerService,
        private dxyModalService: DxyModalService,
        private entityEventService: EntityEventService,
        private diagramSecurityService: DiagramSecurityService
    ) {
        super();
    }

    //#region build DiagramData
    public async getDiagramData(diagramId: string, versionId: string) {
        const [diagramEntity, diagramContent] = await Promise.all([
            this.entityService.getEntity(
                new EntityIdentifier(diagramId, versionId, EntityType.All),
                { includeHddSecurityAndSocialData: true }
            ),
            this.diagramService.getDiagramContent(diagramId, versionId),
        ]);
        const sourceInfos = diagramEntity.getAttributeValue<
            IHierarchicalData[]
        >(ServerConstants.Diagram.ObjectLinks_DiagramHasSource_HData);
        const modelsData = await Promise.all(
            sourceInfos?.map((dsi) =>
                this.getModelData(dsi.Data.DataReferenceId, versionId)
            ) ?? []
        );
        if (modelsData.length) {
            const tableNodes = diagramContent.Nodes.filter((ne) =>
                this.isTableNode(ne)
            );
            await this.loadModelsWithTableColumns(
                this.getTableIdsByModelIdFromNodes(tableNodes)
            );
        }
        const res = new DiagramData(diagramEntity, diagramContent, modelsData);
        this.log('getDiagramData-result', res);
        return res;
    }
    private async getModelData(
        sourceId: string,
        versionId: string
    ): Promise<IDiagramModelData> {
        const model = await this.modelerService.getModelWithoutTables(sourceId);
        const entity = await this.entityService.getEntityForDetails(
            new EntityIdentifier(
                sourceId,
                versionId,
                EntityType.RelationalModel
            )
        );
        return { model, entity };
    }
    private async loadModelsWithTableColumns(
        tableIdsByModelId: Map<string, string[]>
    ) {
        this.log('loadModelsWithTableColumns', tableIdsByModelId);
        await Promise.all(
            Array.from(tableIdsByModelId.entries()).map((entry) =>
                this.modelerService.loadModelDataWithTableColumns(
                    entry[0],
                    ...entry[1]
                )
            )
        );
    }
    private getTableIdsByModelIdFromNodes(tableNodes: DiagramNodeDto[]) {
        return CollectionsHelper.objArrayToMapOfArrays(
            tableNodes.map((ne) => ({
                tableId: ne.EntityReferenceId,
                modelId: ModelerService.getModelId(ne),
            })),
            (o) => o.modelId,
            (o) => o.tableId,
            true,
            true
        );
    }
    //#endregion - build DiagramData

    //#region - for diagram-ui.service

    public shouldDisplayPrimaryKeys(diagramData: DiagramData) {
        return this.isTechnicalView && this.isRelationalMonoSource(diagramData);
    }

    //#region - extract info from item

    public getEdgeMenuDataItems(
        diagramData: DiagramData,
        ee: DiagramEdgeDto
    ): IEdgeElementMenuDataItems {
        const fk = this.getForeignKeyFromEdge(diagramData, ee);
        if (!fk) {
            return;
        }

        const entityNodes = diagramData.getNodes();
        const getItems = (
            tableId: string,
            nodeRefId: string
        ): IEdgeElementMenuDataItem[] =>
            entityNodes
                .filter(
                    (ne) =>
                        ne.EntityReferenceId == tableId &&
                        ne.ReferenceId != nodeRefId
                )
                .map((ne) => ({
                    name:
                        ne.DisplayName ||
                        `${this.getNodeEntityName(
                            ne
                        )}${diagramData.getAliasNameSuffix(ne)}`,
                    id: ne.ReferenceId,
                }));

        const childTable = this.modelerService.getFkChildTable(fk);
        const newSourceName: string = this.translate.instant(
            'UI.ForeignKeyElement.miINew',
            { tableName: childTable.DisplayName }
        );

        const pkTable = this.modelerService.getFkParentTable(fk);
        const newTargetName: string = this.translate.instant(
            'UI.ForeignKeyElement.miINew',
            { tableName: pkTable.DisplayName }
        );

        return {
            sourceItems: [
                { name: newSourceName, id: '-1' },
                ...getItems(childTable.ReferenceId, ee.SourceNodeId),
            ],
            targetItems: [
                { name: newTargetName, id: '-1' },
                ...getItems(pkTable.ReferenceId, ee.TargetNodeId),
            ],
        };
    }

    public isFfkEdge(diagramData: DiagramData, ee: DiagramEdgeDto) {
        return !!this.getForeignKeyFromEdge(diagramData, ee)?.IsFunctionalOnly;
    }
    public getFkInfos(diagramData: DiagramData, ee: DiagramEdgeDto) {
        const fk = this.getForeignKeyFromEdge(diagramData, ee);
        return fk
            ? { isFFK: fk.IsFunctionalOnly, isMandatory: fk.IsMandatory }
            : undefined;
    }

    private getForeignKeyFromEdge(
        diagramData: DiagramData,
        ee: DiagramEdgeDto
    ) {
        if (!diagramData.hasModel) {
            return;
        }
        if (!ee) {
            CoreUtil.warn('no item');
            return;
        }
        if (ee.EdgeKind != DiagramEdgeKind.ForeignKey) {
            CoreUtil.warn('invalid edgeType', DiagramEdgeKind[ee.EdgeKind], ee);
            return;
        }
        if (!ee.ForeignKeyId) {
            CoreUtil.warn('no ForeignKeyId', ee);
            return;
        }
        const fk = this.getForeignKeyByReferenceId(
            diagramData,
            ee.ForeignKeyId
        );
        if (!fk) {
            CoreUtil.warn('no fk', ee);
        }
        return fk;
    }
    private getForeignKeyByReferenceId(
        diagramData: DiagramData,
        foreignKeyId: string
    ) {
        return (
            foreignKeyId &&
            diagramData.getFirstFromModel((m) =>
                this.modelerService.getForeignKey(m, foreignKeyId)
            )
        );
    }

    /** Retrieves links (type, id, source, target) from the business data model where the given entity is a source or a target */
    public async getExistingLinkIdentifiers(
        diagramData: DiagramData,
        entityIdr: IEntityIdentifier
    ) {
        const existingLinks = diagramData.isMonoSource
            ? await this.getFkLinkIdentifiers(diagramData, entityIdr)
            : await this.linkedObjectService.getLinkIdentifiers(entityIdr);
        return {
            ...existingLinks,
            count:
                existingLinks.asSource.length + existingLinks.asTarget.length,
        } as IExistingLinks;
    }
    private async getFkLinkIdentifiers(
        diagramData: DiagramData,
        entityIdr: IEntityIdentifier
    ): Promise<ILinkedEntityInfo> {
        // #archi-diagram-model https://datagalaxy.atlassian.net/browse/DG-4576
        // For now, all info is in the model, because loaded for the assets panel.
        // So we don't need to call the backend. This will have to be changed when the assets panel is changed to load-on-scroll mode or whatever
        const { asParent, asChild } = this.getForeignKeysForTableId(
            diagramData,
            entityIdr.ReferenceId
        );
        function tableAsEntityIdr(table: Table) {
            return new EntityIdentifier(
                table.ReferenceId,
                diagramData.versionId,
                table.EntityType
            );
        }
        return {
            entity: entityIdr,
            asSource: asParent.map(
                (fk) =>
                    ({
                        source: entityIdr,
                        target: tableAsEntityIdr(
                            this.modelerService.getFkChildTable(fk)
                        ),
                        linkType: undefined,
                        linkReferenceId: fk.ReferenceId,
                    } as IEntityLinkIdentifier)
            ),
            asTarget: asChild.map(
                (fk) =>
                    ({
                        source: tableAsEntityIdr(
                            this.modelerService.getFkParentTable(fk)
                        ),
                        target: entityIdr,
                        linkType: undefined,
                        linkReferenceId: fk.ReferenceId,
                    } as IEntityLinkIdentifier)
            ),
        };
    }

    private getForeignKeysForTableId(
        diagramData: DiagramData,
        tableId: string
    ) {
        return (
            diagramData.getFirstFromModel((m) => {
                const fks = this.modelerService.getForeignKeys(m);
                const asChild = fks.filter(
                    (fk) => this.modelerService.getFkChildTableId(fk) == tableId
                );
                const asParent = fks.filter(
                    (fk) =>
                        this.modelerService.getFkParentTableId(fk) == tableId
                );
                if (asChild.length || asParent.length) {
                    return { asChild, asParent };
                }
            }) ?? { asChild: [], asParent: [] }
        );
    }

    public getEntityIdrFromElementIdr(
        diagramData: DiagramData,
        elemIdr: IDiagramElementIdentifier
    ): IEntityIdentifier {
        return new EntityIdentifier(
            elemIdr.referenceId,
            diagramData.versionId,
            elemIdr.entityType
        );
    }

    public getNodeEntityName(ne: DiagramNodeDto) {
        return this.viewTypeService.getTechnicalOrDisplayName(ne.HddData);
    }

    public getObjectName(object: IHasTechnicalName) {
        return this.viewTypeService.getTechnicalOrDisplayName(object);
    }

    public isFKTechnicalView(diagramData: DiagramData) {
        return this.isTechnicalView && this.isRelationalMonoSource(diagramData);
    }
    public isRelationalMonoSource(diagramData: DiagramData) {
        return this.modelerService.isRelationalModel(
            diagramData?.monoSourceModel
        );
    }

    public getNodeElementDisplayName(o: DiagramNodeDto) {
        return o instanceof DiagramNodeDto
            ? o.DisplayName ?? this.getObjectName(o.HddData)
            : '';
    }

    public getElementDbg(o: DiagramNodeDto | DiagramEdgeDto) {
        return o instanceof DiagramNodeDto
            ? `${DiagramNodeKind[o.NodeKind]}:${
                  o.NodeKind == DiagramNodeKind.Entity
                      ? o.DisplayName ?? this.getObjectName(o.HddData)
                      : o.Text?.substring(0, 20)?.trim()
              }`
            : o instanceof DiagramEdgeDto
            ? `edge:${DiagramLinkType[this.getEdgeLinkType(o)]}/${
                  o.DisplayName
              }`
            : undefined;
    }

    //#endregion - extract info from item

    //#region link type
    public isFkLinkType(linkType: DiagramLinkType) {
        return (
            linkType == DiagramLinkType.FK ||
            linkType == DiagramLinkType.FKInversed
        );
    }
    public getEdgeLinkType(o: DiagramEdgeDto): DiagramLinkType {
        return !o
            ? undefined
            : o.EdgeKind == DiagramEdgeKind.ForeignKey
            ? DiagramLinkType.FK
            : this.getDiagramLinkTypeNoReverse(o.ObjectLinkType);
    }
    public getDiagramLinkTypeNoReverse(linkTypeNoFK: ObjectLinkType) {
        return this.getDiagramLinkType(
            linkTypeNoFK,
            this.isReverseObjectLinkType(linkTypeNoFK)
        );
    }
    public getDiagramLinkType(linkTypeNoFK: ObjectLinkType, reversed = false) {
        const olt = reversed
            ? this.reverseObjectLinkType(linkTypeNoFK)
            : linkTypeNoFK;
        return olt as number as DiagramLinkType;
    }
    public getObjectLinkType(linkType: DiagramLinkType): ObjectLinkType {
        return this.isFkLinkType(linkType)
            ? undefined
            : (linkType as number as ObjectLinkType);
    }
    public reverseObjectLinkType(olt: ObjectLinkType) {
        return EntityLinkUtils.reverseObjectLinkType(olt);
    }
    public isReverseObjectLinkType(olt: ObjectLinkType) {
        return EntityLinkService.isReverseObjectLinkType(olt);
    }
    public getNotReverseObjectLinkType(olt: ObjectLinkType) {
        return this.isReverseObjectLinkType(olt)
            ? this.reverseObjectLinkType(olt)
            : olt;
    }

    public canChangeLinkType(ee: DiagramEdgeDto) {
        return (
            ee?.EdgeKind == DiagramEdgeKind.EntityLink &&
            ee.ObjectLinkType == ObjectLinkType.IsLinkedTo
        );
    }
    //#endregion - link type

    //#region create,update,delete

    //#region diagram elements

    public async createElementsFromIdentifier(
        diagramData: DiagramData,
        opt: ICreateElementsFromIdentifierOptions,
        ...idrs: IDiagramElementIdentifier[]
    ): Promise<ICreatedElements> {
        if (!idrs?.length) {
            return;
        }
        const newIdrs = idrs.filter((idr) => !idr.referenceId);
        const existingIdrs = idrs.filter((idr) => idr.referenceId);
        const existingLinkedIdrs = existingIdrs.filter((idr) => idr.linkInfo);
        this.log('createElementsFromIdentifier', {
            newIdrs,
            existingIdrs,
            existingLinkedIdrs,
        });

        const shift = opt?.shift ?? DiagramConstants.multiDropShift;
        const newNodeItems: DiagramNodeDto[] = [];
        const createdEntities: EntityItem[] = [];
        const loadedEntities: EntityItem[] = [];

        if (newIdrs.length == 1) {
            const idr = newIdrs[0],
                type = idr?.type;
            if (!type || type == 'link') {
                CoreUtil.warn('type not implemented', type);
            } else if (type == 'entity') {
                let opt2: IOpenCreationModalOptions;
                const isModelTable =
                    diagramData.hasModel &&
                    ModelerService.isTableEntityType(idr.entityType);
                if (isModelTable) {
                    if (diagramData.isMultiSource) {
                        CoreUtil.warn('not implemented');
                        return;
                    }
                    opt2 = {
                        serverType: ServerType.Table,
                        filterEntityTypes: [idr.entityType],
                        modelHdd: diagramData.monoSourceHdd,
                        parentData: diagramData.monoSourceEntity,
                    };
                }
                const result = await this.entityUiService.openCreationModal(
                    EntityCreationOrigin.diagramAddEntityButton,
                    opt2
                );
                const entity = result?.createdEntity;
                if (entity) {
                    if (isModelTable) {
                        this.modelerService.newTable(
                            diagramData.monoSourceModel,
                            entity
                        );
                    }
                    createdEntities.push(entity);
                }
            } else {
                const ncd: INodeCreationData = { type, ...(opt as IXYRect) };
                const node = this.createNodeDto(diagramData, ncd);
                if (node) {
                    newNodeItems.push(node);
                }
            }
        } else if (newIdrs.length) {
            CoreUtil.warn('todo: use create multi');
        }

        if (existingIdrs.length == 1) {
            const addLoadedEntity = async (entityIdr: IEntityIdentifier) => {
                const entity = await this.getOrLoadEntityFromIdr(
                    diagramData,
                    entityIdr
                );
                if (entity) {
                    loadedEntities.push(entity);
                }
            };

            const idr = existingIdrs[0];
            if (idr.entityType == EntityType.ForeignKey) {
                if (!diagramData.isMonoSource) {
                    CoreUtil.warn('not implemented');
                    return;
                }
                const foreignKeyId = idr.referenceId;
                const fk = this.getForeignKeyByReferenceId(
                    diagramData,
                    foreignKeyId
                );
                if (!fk) {
                    CoreUtil.warn(`no fk for id ${foreignKeyId}`);
                    return;
                }
                const parentTableIdr = this.entityIdrFromTableId(
                    diagramData,
                    this.modelerService.getFkParentTableId(fk)
                );
                const childTableIdr = this.entityIdrFromTableId(
                    diagramData,
                    this.modelerService.getFkChildTableId(fk)
                );
                await Promise.all([
                    addLoadedEntity(parentTableIdr),
                    addLoadedEntity(childTableIdr),
                ]);
                existingLinkedIdrs.push({
                    type: 'entity',
                    entityType: childTableIdr.entityType,
                    referenceId: childTableIdr.referenceId,
                    linkInfo: {
                        linkReferenceId: foreignKeyId,
                        linkType: DiagramLinkType.FK,
                        sourceIdr: parentTableIdr,
                    },
                });
            } else {
                await addLoadedEntity(
                    this.getEntityIdrFromElementIdr(diagramData, idr)
                );
            }
        } else if (existingIdrs.length) {
            const entityIds = CollectionsHelper.distinct([
                ...existingIdrs.map((idr) => idr.referenceId),
                ...existingLinkedIdrs.map(
                    (idr) => idr.linkInfo.sourceIdr.ReferenceId
                ),
            ]);
            const serverTypes = EntityTypeUtil.getDistinctServerTypes([
                ...existingIdrs.map((idr) => idr.entityType),
                ...existingLinkedIdrs.map(
                    (idr) => idr.linkInfo.sourceIdr.entityType
                ),
            ]);
            await this.loadMoreEntities(diagramData, entityIds, serverTypes);
            loadedEntities.push(
                ...entityIds
                    .filter((id) => id && !diagramData.hasNodeForEntityId(id))
                    .map((id) => diagramData.getLoadedEntity(id))
            );
        }

        const entities = [...createdEntities, ...loadedEntities];
        const size = DiagramConstants.getDefaultSize('entity', opt.sizeMode);
        const rect = new Rect(opt.x, opt.y, size.width, size.height);
        entities.forEach((entity) => {
            newNodeItems.push(
                this.nodeDtoFromEntity(
                    diagramData,
                    entity,
                    rect.toXYWidthHeight()
                )
            );
            rect.shift(shift);
        });

        if (diagramData.hasModel) {
            await this.loadModelsWithTableColumns(
                this.getTableIdsByModelIdFromEntities(entities)
            );
        }

        const getNewOrExistingNode = (
            entityReferenceId: string,
            isFK: boolean,
            nodeReferenceId?: string
        ) => {
            return (
                newNodeItems.find(
                    (ne) => ne.EntityReferenceId == entityReferenceId
                ) ??
                (isFK
                    ? null
                    : (nodeReferenceId &&
                          diagramData.getNodeByRefId(nodeReferenceId)) ??
                      diagramData.getNode(
                          (ne) => ne.EntityReferenceId == entityReferenceId
                      ))
            );
        };
        const newEdgeItems: DiagramEdgeDto[] = [];
        existingLinkedIdrs.forEach((idr) => {
            const isFK = this.isFkLinkType(idr.linkInfo?.linkType);
            const source = getNewOrExistingNode(
                idr.linkInfo.sourceIdr.ReferenceId,
                isFK,
                idr.linkInfo.sourceNodeReferenceId
            );
            if (!source) {
                CoreUtil.warn('no source node', idr);
                return;
            }
            const target = getNewOrExistingNode(idr.referenceId, isFK);
            if (!target) {
                CoreUtil.warn('no target node', idr);
                return;
            }
            if (isFK) {
                target.Top = source.Top;
                target.Left = source.Left + DiagramConstants.offsetNewFkTarget;
            }
            const res = this.makeEdgeDto(
                diagramData,
                source,
                target,
                idr.linkInfo
            );
            if (res) {
                newEdgeItems.push(res.dto);
            }
        });

        if (opt?.moreEdges?.length) {
            const getDto = (dtoOrIdr: TNodeOrIdr) =>
                dtoOrIdr instanceof DiagramNodeDto
                    ? dtoOrIdr
                    : newNodeItems.find(
                          (n) => n.EntityReferenceId == dtoOrIdr.ReferenceId
                      );

            opt.moreEdges.forEach((efl) => {
                const source = getDto(efl.source);
                const target = getDto(efl.target);
                const olt = this.getObjectLinkType(efl.linkType);
                const oltRev = this.reverseObjectLinkType(olt);
                if (
                    !newEdgeItems.some(
                        (ee) =>
                            ee.SourceNodeId == source.ReferenceId &&
                            ee.TargetNodeId == target.ReferenceId &&
                            ee.ObjectLinkType ==
                                (this.isReverseObjectLinkType(ee.ObjectLinkType)
                                    ? oltRev
                                    : olt)
                    )
                ) {
                    const res = this.makeEdgeDto(
                        diagramData,
                        source,
                        target,
                        efl
                    );
                    if (res) {
                        newEdgeItems.push(res.dto);
                    }
                }
            });
        }

        this.log('createElementsFromIdentifier-addItems', {
            newNodeItems,
            newEdgeItems,
        });
        await this.diagramService.addItems(
            diagramData,
            ...newNodeItems,
            ...newEdgeItems
        );
        return { newNodeItems, newEdgeItems, entities };
    }
    public async loadMoreEntities(
        diagramData: DiagramData,
        entityIds: string[],
        serverTypes: ServerType[]
    ) {
        const entityIdsToLoad = entityIds?.filter(
            (ei) => !diagramData.hasLoadedEntity(ei)
        );
        this.log(
            'loadMoreEntities',
            entityIds?.length,
            entityIdsToLoad?.length
        );
        if (!entityIdsToLoad?.length) {
            return;
        }
        const res = await this.entityService.loadMultiEntity({
            parentReferenceId: diagramData.spaceId,
            versionId: diagramData.versionId,
            dataReferenceIdList: entityIdsToLoad,
            dataTypes: serverTypes,
            includeHdd: true,
            includedAttributesFilter: diagramData.extraDataAttributeKeys,
        });
        diagramData.addLoadedEntities(res.Entities);
    }
    private async getOrLoadEntityFromIdr(
        diagramData: DiagramData,
        entityIdr: IEntityIdentifier
    ) {
        if (!entityIdr?.ReferenceId) {
            return;
        }
        let entity = diagramData.getLoadedEntity(entityIdr.ReferenceId);
        if (!entity) {
            entity = await this.loadEntityFromIdr(
                entityIdr,
                diagramData.extraDataAttributeKeys
            );
            if (entity) {
                diagramData.setLoadedEntity(entity);
            }
        }
        return entity;
    }
    private async loadEntityFromIdr(
        entityIdr: IEntityIdentifier,
        includedAttributesFilter?: string[]
    ) {
        const opt = includedAttributesFilter
            ? { includeHdd: true, includedAttributesFilter }
            : undefined;
        const entity = await this.entityService.getEntity(entityIdr, opt);
        this.log(
            'loadEntityFromIdr-result',
            entityIdr,
            includedAttributesFilter,
            entity
        );
        return entity;
    }
    private getTableIdsByModelIdFromEntities(entities: EntityItem[]) {
        return CollectionsHelper.objArrayToMapOfArrays(
            entities
                .filter((e) => ModelerService.isTableEntityType(e.EntityType))
                .map((e) => ({
                    tableId: e.ReferenceId,
                    modelId: ModelerService.getModelId(e),
                })),
            (o) => o.modelId,
            (o) => o.tableId,
            true,
            true
        );
    }

    public isTableIdr(idr: IDiagramElementIdentifier) {
        return ModelerService.isTableEntityType(idr?.entityType);
    }

    public async cloneEntityElement(
        diagramData: DiagramData,
        data: TDiagramNodeData,
        cloneEntity: boolean
    ): Promise<ICreatedElements> {
        let entity: EntityItem;
        const ne = data.data.item;
        const entityIdr = EntityIdentifier.fromIHasHddData(ne);
        if (cloneEntity) {
            entity = await this.entityCreator.cloneEntity(entityIdr, {
                actionOrigin: EntityCreationOrigin.diagramElementClone,
            });
            if (diagramData.hasModel && this.isTableNode(ne)) {
                this.modelerService.newTable(
                    this.getNodeModel(diagramData, ne),
                    entity
                );
            }
        } else {
            entity = await this.loadEntityFromIdr(
                entityIdr,
                diagramData.extraDataAttributeKeys
            );
        }
        const elements: ICreatedElements = {
            newNodeItems: [],
            newEdgeItems: [],
            entities: [],
        };
        if (entity) {
            const rect = Rect.from(data.rect).shift(
                DiagramConstants.cloneShift('entity')
            );
            const dto = this.nodeDtoFromEntity(diagramData, entity, {
                ...rect.toXYWidthHeight(),
                visualData: ne.VisualData,
            });
            await this.diagramService.addItems(diagramData, dto);
            elements.newNodeItems.push(dto);
            elements.entities.push(entity);
        }
        return elements;
    }

    public async addNonEntityNode(
        diagramData: DiagramData,
        ncd: INodeCreationData
    ): Promise<ICreatedElements> {
        const elements: ICreatedElements = {
            newNodeItems: [],
            newEdgeItems: [],
        };
        const dto = this.createNodeDto(diagramData, ncd);
        await this.diagramService.addItems(diagramData, dto);
        elements.newNodeItems.push(dto);
        return elements;
    }

    /** may open a modal dialog */
    public async createEdgeDto(
        diagramData: DiagramData,
        sourceLocalId: string,
        targetLocalId: string
    ) {
        this.log('createEdgeDto', sourceLocalId, targetLocalId);
        const sourceElement =
            sourceLocalId && diagramData.getNodeByLocalId(sourceLocalId);
        const targetElement =
            targetLocalId && diagramData.getNodeByLocalId(targetLocalId);
        const sourceEntity = diagramData.getNodeEntity(sourceElement);
        const targetEntity = diagramData.getNodeEntity(targetElement);
        const hasFkSettingsModalWriteAccess =
            this.diagramSecurityService.canManageFkSettings(diagramData);
        const hasWriteAccess =
            !this.diagramSecurityService.isReader &&
            targetEntity.SecurityData.HasWriteAccess;
        let linkInfo: ICreateEdgeInfo;
        if (diagramData.hasModel && hasFkSettingsModalWriteAccess) {
            if (!diagramData.isMonoSource) {
                CoreUtil.warn('not implemented');
                return;
            }
            linkInfo = await this.createFkViaModal(
                sourceEntity,
                targetEntity,
                diagramData.monoSourceModel
            );
        } else if (
            await this.linkedObjectService.isAnyExistingEntityLink(
                sourceEntity,
                targetEntity
            )
        ) {
            linkInfo = await this.createLinkViaModal(
                sourceEntity,
                targetEntity
            );
        } else if (hasWriteAccess) {
            linkInfo = await this.createEntityLink(
                sourceEntity,
                targetEntity,
                DiagramLinkType.IsLinkedTo
            );
        }
        if (!linkInfo) {
            return;
        }
        const res = this.makeEdgeDto(
            diagramData,
            sourceElement,
            targetElement,
            linkInfo
        );
        if (res) {
            await this.diagramService.addItems(diagramData, res.dto);
        }
        return res;
    }
    private async createFkViaModal(
        sourceIdr: IEntityIdentifier,
        targetIdr: IEntityIdentifier,
        model: Model
    ): Promise<ICreateEdgeInfo> {
        if (!model) {
            CoreUtil.warn('no model');
            return;
        }
        const fk = await this.modelerService.createForeignKey(
            model,
            sourceIdr.ReferenceId,
            targetIdr.ReferenceId,
            this.isTechnicalView
        );
        return (
            fk && {
                linkType: DiagramLinkType.FK,
                linkReferenceId: fk.ReferenceId,
            }
        );
    }
    private async createLinkViaModal(
        sourceEntity: EntityItem,
        targetEntity: EntityItem
    ): Promise<ICreateEdgeInfo> {
        const isUserReader = this.diagramSecurityService.isReader;
        const res = await this.linkedObjectService.openLinkCreationModal(
            sourceEntity,
            undefined,
            {
                allowExistingLinks: true,
                includeEntityLinks: true,
                targetEntityDataList: [targetEntity],
                modalTitleTranslateKey: 'UI.Diagrams.createLinkModal.title',
                noCheckAccessRights: isUserReader,
                onlyExistingLinks: isUserReader,
            }
        );
        const linkIdr = res?.linkIdrs?.[0];
        if (!linkIdr) {
            return;
        }
        return {
            linkReferenceId: linkIdr.linkReferenceId,
            linkType: this.getDiagramLinkType(linkIdr.linkType),
        };
    }
    private async createEntityLink(
        sourceIdr: IEntityIdentifier,
        targetIdr: IEntityIdentifier,
        linkType: DiagramLinkType
    ): Promise<ICreateEdgeInfo> {
        const objectLinkType = this.getObjectLinkType(linkType);

        try {
            const res = await this.linkedObjectService.linkData(
                objectLinkType,
                [sourceIdr.ReferenceId],
                [targetIdr.ReferenceId],
                sourceIdr.VersionId,
                AddLinkAction.Append
            );
            const led = res?.Links?.[0];
            return (
                led && { linkType, linkReferenceId: led.LinkEntity.ReferenceId }
            );
        } catch (e) {
            if (isApiError(e)) {
                switch (e.type) {
                    case ApiServiceErrorType.UnmodifiedContent: {
                        this.log('createEntityLink', 'link already exists');
                        break;
                    }
                    case ApiServiceErrorType.Unauthorized: {
                        this.log('createEntityLink', 'operation denied');
                        break;
                    }
                    default: {
                        CoreUtil.warn(e);
                        break;
                    }
                }
            }
            throw e;
        }
    }

    private makeEdgeDto(
        diagramData: DiagramData,
        source: DiagramNodeDto,
        target: DiagramNodeDto,
        linkInfo: ICreateEdgeInfo
    ) {
        if (!linkInfo?.linkType) {
            this.warn('incomplete linkInfo', linkInfo);
            return;
        }
        const dto = new DiagramEdgeDto();
        dto.ReferenceId = diagramData.generateReferenceId();
        let reversed: boolean;
        if (this.isFkLinkType(linkInfo.linkType)) {
            dto.EdgeKind = DiagramEdgeKind.ForeignKey;
            dto.ForeignKeyId = linkInfo.linkReferenceId;
            // fk source/target re-inversion
            dto.SourceNodeId = target.ReferenceId;
            dto.TargetNodeId = source.ReferenceId;
        } else {
            dto.EdgeKind = DiagramEdgeKind.EntityLink;
            dto.EntityLinkId = linkInfo.linkReferenceId;
            const olt = this.getObjectLinkType(linkInfo.linkType);
            reversed = this.isReverseObjectLinkType(olt);
            if (reversed) {
                dto.ObjectLinkType = this.reverseObjectLinkType(olt);
                dto.SourceNodeId = target.ReferenceId;
                dto.TargetNodeId = source.ReferenceId;
            } else {
                dto.ObjectLinkType = olt;
                dto.SourceNodeId = source.ReferenceId;
                dto.TargetNodeId = target.ReferenceId;
            }
        }
        return { dto, reversed };
    }

    /** (for model diagram only) Change the source or target alias (existing or new, for the same entity) of the given edge element */
    public async changeLinkEnd(
        diagramData: DiagramData,
        ee: DiagramEdgeDto,
        nodeElementId: string,
        isTarget: boolean,
        topLeft: IXY,
        sizeMode?: TSizeMode
    ): Promise<DiagramNodeDto> {
        const isNewNode = !nodeElementId || nodeElementId == '-1';
        this.log(
            'changeLinkEnd',
            isTarget,
            nodeElementId,
            isNewNode,
            sizeMode,
            ee
        );
        if (!diagramData.hasModel) {
            this.warn('not implemented');
            return;
        }

        const fk = this.getForeignKeyFromEdge(diagramData, ee);
        if (!fk) {
            return;
        }

        let newAlias: DiagramNodeDto;
        if (isNewNode) {
            const table = isTarget
                ? this.modelerService.getFkParentTable(fk)
                : this.modelerService.getFkChildTable(fk);
            const entityIdr = this.entityIdrFromTableId(
                diagramData,
                table.ReferenceId,
                table.EntityType
            );
            const entity = await this.getOrLoadEntityFromIdr(
                diagramData,
                entityIdr
            );
            if (!entity) {
                this.warn('no entity');
                return;
            }
            newAlias = this.nodeDtoFromEntity(diagramData, entity, {
                ...topLeft,
                ...DiagramConstants.getDefaultSize(
                    'entity',
                    sizeMode,
                    diagramData.hasExtraData
                ),
            });
        } else {
            newAlias = diagramData.getNodeByRefId(nodeElementId);
        }

        if (isTarget) {
            ee.TargetNodeId = newAlias.ReferenceId;
        } else {
            ee.SourceNodeId = newAlias.ReferenceId;
        }

        const ops: TDiagramUpdateContentOperations = [];
        if (isNewNode) {
            ops.push({ type: 'A', dto: newAlias });
        }
        ops.push({
            type: 'U',
            dto: ee,
            keys: [isTarget ? 'TargetNodeId' : 'SourceNodeId'],
        });
        await this.diagramService.update(diagramData, ...ops);

        return newAlias;
    }
    private entityIdrFromTableId(
        diagramData: DiagramData,
        tableId: string,
        tableEntityType = EntityType.Table
    ) {
        return new EntityIdentifier(
            tableId,
            diagramData.versionId,
            tableEntityType
        );
    }

    public async removeNodes(
        diagramData: DiagramData,
        ...nodes: DiagramNodeDto[]
    ) {
        await this.diagramService.removeItems(diagramData, ...nodes);
    }

    public async removeEdges(
        diagramData: DiagramData,
        edges: DiagramEdgeDto[]
    ) {
        await this.diagramService.removeItems(diagramData, ...edges);
    }

    /** pops up a confirmation modal if needed */
    public async removeEdge(
        diagramData: DiagramData,
        ee: DiagramEdgeDto,
        withLinkEntity: boolean
    ): Promise<boolean> {
        this.log('removeEdge', ee, withLinkEntity);
        if (!ee) {
            return;
        }
        if (withLinkEntity) {
            if (diagramData.hasModel) {
                const fk = this.getForeignKeyFromEdge(diagramData, ee);
                if (!fk || !(await this.modelerService.deleteForeignKey(fk))) {
                    return;
                }
            } else {
                if (
                    !(await this.dxyModalService.confirmDeleteObject(
                        ServerType[ServerType.EntityLink]
                    ))
                ) {
                    return;
                }
                if (ee.EdgeKind != DiagramEdgeKind.EntityLink) {
                    throw new Error('not implemented');
                }
                const { sourceIdr, targetIdr } =
                    diagramData.getEntityIdentifiersFromEdge(ee);
                await this.linkedObjectService.deleteLink(
                    sourceIdr,
                    targetIdr,
                    ee.ObjectLinkType
                );
            }
            // Note: At this stage, diagramData is not modified
            return true;
        } else {
            await this.diagramService.removeItems(diagramData, ee);
            return true;
        }
    }

    /** returns true if the given edge is an *IsLinkedTo* *Entitylink* */
    public canInverseLink(ee: DiagramEdgeDto) {
        return (
            ee &&
            ee.EdgeKind == DiagramEdgeKind.EntityLink &&
            ee.ObjectLinkType == ObjectLinkType.IsLinkedTo
        );
    }
    /** deletes the given dto and its entitylink,
     * creates new dto and entitylink with inversed source, target, type; other properties are copied. */
    public async inverseEdge(
        diagramData: DiagramData,
        ee: DiagramEdgeDto,
        force = false
    ) {
        if (!force && !this.canInverseLink(ee)) {
            return;
        }
        const { sourceIdr, targetIdr } =
            diagramData.getEntityIdentifiersFromEdge(ee);
        const newSourceIdr = targetIdr;
        const newTargetIdr = sourceIdr;
        const newSourceDto = diagramData.getNodeByRefId(ee.TargetNodeId);
        const newTargetDto = diagramData.getNodeByRefId(ee.SourceNodeId);
        const newLinkType = this.getDiagramLinkType(
            this.reverseObjectLinkType(ee.ObjectLinkType)
        );
        if (!newSourceDto || !newTargetDto || newLinkType == null) {
            return;
        }
        await this.linkedObjectService.deleteLink(
            sourceIdr,
            targetIdr,
            ee.ObjectLinkType
        );
        // note: the back-end has removed the DiagramEdgeDto from the diagram content
        diagramData.removeEdge(ee); // but we have to do it on our local instance
        const linkInfo = await this.createEntityLink(
            newSourceIdr,
            newTargetIdr,
            newLinkType
        );
        if (!linkInfo) {
            return;
        }
        const res = this.makeEdgeDto(
            diagramData,
            newSourceDto,
            newTargetDto,
            linkInfo
        );
        const dto = res?.dto;
        if (!dto) {
            return;
        }
        const keys: (keyof DiagramEdgeDto)[] = [
            'DisplayName',
            'Description',
            'VisualData',
            'LinkGeometryData',
        ];
        keys.forEach((k) => (dto[k as string] = ee[k]));
        await this.diagramService.addItems(diagramData, dto);
        return res;
    }

    public async removeElement(
        diagramData: DiagramData,
        ne: DiagramNodeDto,
        withEntity: boolean
    ): Promise<boolean> {
        const entityId = ne.EntityReferenceId;
        if (withEntity) {
            if (entityId == diagramData.diagramId) {
                return;
            }
            const res = await this.entityUiService.onDeleteEntity(
                EntityIdentifier.fromIHasHddData(ne)
            );
            if (res) {
                this.onEntityDeleted(diagramData, entityId);
            }
            return res;
        }
        await this.diagramService.removeItems(diagramData, ne);
        return true;
    }
    private onEntityDeleted(diagramData: DiagramData, entityId: string) {
        if (!diagramData || !entityId) {
            return;
        }
        if (diagramData.hasModel) {
            diagramData
                .getModels()
                .forEach((m) => this.modelerService.removeTable(m, entityId));
        }
    }

    public async persistItemPosition(
        diagramData: DiagramData,
        ...specs: IDiagramNodeSpec[]
    ) {
        specs.forEach((spec) => Rect.from(spec.data.item));
        const items = CollectionsHelper.distinct(
            specs.map((spec) => spec.data.item)
        );
        this.log('persistItemPosition-items', items?.length);
        await this.diagramService.updateNodesPosition(diagramData, ...items);
    }

    public async updateNodeText(
        diagramData: DiagramData,
        ...items: DiagramNodeDto[]
    ) {
        await this.diagramService.updateNodes(diagramData, 'Text', ...items);
    }

    public async updateItemVisualData(
        diagramData: DiagramData,
        ...items: DiagramNodeDto[]
    ) {
        await this.diagramService.updateNodes(
            diagramData,
            'VisualData',
            ...items
        );
    }

    public async updateEdgeAliasName(
        diagramData: DiagramData,
        e: DiagramEdgeDto,
        aliasName: string
    ) {
        aliasName = aliasName?.trim() ?? '';
        if (aliasName == (e.DisplayName?.trim() ?? '')) {
            return;
        }

        e.DisplayName = aliasName;
        await this.diagramService.updateEdge(diagramData, e, 'DisplayName');
        return true;
    }
    public async updateEdgeGeometryData(
        diagramData: DiagramData,
        ...edges: DiagramEdgeDto[]
    ) {
        await this.diagramService.updateEdges(
            diagramData,
            'LinkGeometryData',
            ...edges
        );
    }
    public async updateEdgeVisualData(
        diagramData: DiagramData,
        ...edges: DiagramEdgeDto[]
    ) {
        await this.diagramService.updateEdges(
            diagramData,
            'VisualData',
            ...edges
        );
    }

    private createNodeDto(diagramData: DiagramData, opt: INodeCreationData) {
        const type = opt?.type;
        if (!type) {
            this.warn('no type');
            return;
        }
        const size = DiagramConstants.getDefaultSize(type);
        const rect: IXYRect = {
            x: opt.x,
            y: opt.y,
            width: opt.width ?? size.width,
            height: opt.height ?? size.height,
        };
        const node = new DiagramNodeDto(
            this.getNodeKind(type),
            diagramData.generateReferenceId(),
            undefined,
            rect,
            opt.text ?? '',
            undefined,
            opt.visualData
        );
        this.log('createNodeDto', type, opt, node);
        return node;
    }

    public getNodeType(kind: DiagramNodeKind): TNodeType {
        return kind == DiagramNodeKind.Entity
            ? 'entity'
            : this.getNonEntityNodeType(kind);
    }
    public getNonEntityNodeType(kind: DiagramNodeKind): TNonEntityNodeType {
        switch (kind) {
            case DiagramNodeKind.Note:
                return 'note';
            case DiagramNodeKind.Frame:
                return 'frame';
            default:
                return;
        }
    }
    public getNodeKind(type: TNodeType): DiagramNodeKind {
        switch (type) {
            case 'entity':
                return DiagramNodeKind.Entity;
            case 'note':
                return DiagramNodeKind.Note;
            case 'frame':
                return DiagramNodeKind.Frame;
            default:
                return;
        }
    }

    public getNodeCreationData(
        node: TDiagramNodeData,
        shift?: IShift
    ): INodeCreationData {
        const dto = node.data.item;
        return {
            type: this.getNonEntityNodeType(dto.NodeKind),
            ...Rect.from(node.rect).shift(shift).toXYWidthHeight(),
            text: dto.Text,
            visualData: dto.VisualData,
        };
    }

    private nodeDtoFromEntity(
        diagramData: DiagramData,
        entity: EntityItem,
        opt: IXYRect & {
            aliasName?: string;
            description?: string;
            visualData?: string;
        }
    ) {
        return (
            entity &&
            new DiagramNodeDto(
                DiagramNodeKind.Entity,
                diagramData.generateReferenceId(),
                entity.HddData,
                opt,
                opt?.aliasName,
                opt?.description,
                opt?.visualData
            )
        );
    }

    public canGenerateDdlForElement(
        diagramData: DiagramData,
        ne: DiagramNodeDto
    ) {
        return this.isRelationalTableNode(diagramData, ne);
    }
    public async generateDdlForElement(
        diagramData: DiagramData,
        ne: DiagramNodeDto
    ) {
        if (!this.canGenerateDdlForElement(diagramData, ne)) {
            return;
        }
        const table = this.getTableFromNode(diagramData, ne);
        if (!table) {
            return;
        }
        const mappingId = this.modelerService.getCurrentMappingId(table);
        await this.modelerService.openDdlScriptGenerationSettings(
            table.ReferenceId,
            ServerType.Table,
            mappingId
        );
    }
    private getTableFromNode(diagramData: DiagramData, ne: DiagramNodeDto) {
        const model = this.getNodeModel(diagramData, ne);
        return this.modelerService.getTable(model, ne.EntityReferenceId);
    }

    public isRelationalTableNode(diagramData: DiagramData, ne: DiagramNodeDto) {
        return (
            this.isTableNode(ne) &&
            this.modelerService.isRelationalModel(
                this.getNodeModel(diagramData, ne)
            )
        );
    }
    public isTableNode(ne: DiagramNodeDto) {
        return ModelerService.isTableEntityType(ne?.EntityType);
    }
    private getNodeModel(diagramData: DiagramData, ne: DiagramNodeDto) {
        const modelId = ModelerService.getModelId(ne);
        return diagramData.getModelById(modelId);
    }

    //#endregion diagram elements

    //#region diagram entity

    public async publishDiagram(diagramEntity: EntityItem) {
        await this.updateDiagramEntity(
            diagramEntity,
            ServerConstants.Diagram.PublishingStatus,
            PublishingStatus[PublishingStatus.Public]
        );
    }

    public async persistDiagramVisualData(diagramData: DiagramData) {
        try {
            const visualDataString = diagramData.preparePersistVisualData();
            this.log('persistDiagramVisualData-generic', diagramData);
            await this.updateDiagramEntity(
                diagramData.diagramEntity,
                'VisualData',
                visualDataString
            );
        } catch (e) {
            this.warn(e);
        }
    }
    private async updateDiagramEntity(
        diagramEntity: EntityItem,
        attributeKey: string,
        value: unknown
    ) {
        // this is to avoid the "attributeDataService.loadAttributes must be called before use" error,
        // at least when entity is a Diagram
        await this.attributeDataService.loadAttributes([
            ServerType[diagramEntity.ServerType],
        ]);

        return this.entityService.updateEntity(
            diagramEntity,
            attributeKey,
            value
        );
    }

    //#endregion - diagramEntity

    //#region links
    /** pops up a modal dialog */
    public async changeLinkType(diagramData: DiagramData, ee: DiagramEdgeDto) {
        if (!this.canChangeLinkType(ee)) {
            return;
        }

        const { source, target } = diagramData.getEdgeEntities(ee);
        const res = await this.linkedObjectService.openChangeLinkTypeModal(
            source,
            target,
            {
                linkReferenceId: ee.EntityLinkId,
                linkType: ee.ObjectLinkType,
            }
        );
        if (res?.linkType != undefined) {
            ee.ObjectLinkType = res.linkType;
            await this.diagramService.updateEdge(
                diagramData,
                ee,
                'ObjectLinkType'
            );
            return true;
        }
    }
    //#endregion

    //#endregion - create,update,delete

    public async resetDiagramSources(
        diagramData: DiagramData,
        sourceIds: string[]
    ) {
        this.log('resetDiagramSources', sourceIds);
        const modelsData = await Promise.all(
            sourceIds?.map((sourceId) =>
                this.getModelData(sourceId, diagramData.versionId)
            ) ?? []
        );
        if (!modelsData?.length) {
            this.warn('no modelsData');
            return;
        }
        const res = await this.diagramService.addDiagramSources(
            diagramData.diagramId,
            ...sourceIds
        );
        const sourcesHData = res.DiagramEntity.getAttributeValue<
            IHierarchicalData[]
        >(ServerConstants.Diagram.ObjectLinks_DiagramHasSource_HData);
        if (
            !CollectionsHelper.contentEquals(
                sourcesHData?.map((hd) => hd.Data.DataReferenceId),
                sourceIds,
                false,
                true
            )
        ) {
            this.warn('sources mismatch');
            return;
        }
        diagramData.initModels(modelsData, sourcesHData);
        return true;
    }

    public async loadModelWithTableAndFkHDatas(sourceId: string) {
        this.log('loadModelWithTableAndFkHDatas', sourceId);
        if (!sourceId) {
            return;
        }
        await this.modelerService.getModelWithTableAndFkHDatas(sourceId);
    }

    /** returns true if the given entity is linked to any other entity */
    public async isEntityLinked(entityIdr: IEntityIdentifier) {
        if (!entityIdr) {
            return;
        }
        return this.entityService.isEntityLinked(entityIdr);
    }

    //#endregion - for diagram-ui.service

    //#region diagram management

    public async openPublishModal(diagram: EntityItem) {
        if (!this.diagramSecurityService.canPublishDiagram(diagram)) {
            return;
        }
        return await this.dxyModalService.open<
            DiagramPublishModalComponent,
            IDiagramPublishModalInputs,
            boolean
        >({
            componentType: DiagramPublishModalComponent,
            data: { entity: diagram },
        });
    }

    public async cloneDiagram(
        inputDiagram: EntityItem,
        diagramName?: string
    ): Promise<EntityItem> {
        this.log('cloneDiagram', inputDiagram, diagramName);
        if (!this.diagramSecurityService.canCloneDiagram(inputDiagram)) {
            return;
        }
        const outputDiagram = await this.entityCreator.cloneEntity(
            inputDiagram,
            {
                actionOrigin: EntityCreationOrigin.diagramClone,
                displayName: diagramName,
            }
        );
        //note: the following could be removed if the back end would clone with all attributes
        [
            ServerConstants.Diagram.ObjectLinks_DiagramHasSource_HData,
            ServerConstants.Diagram.DiagramEntityIds,
        ].forEach((ak) =>
            outputDiagram.setAttributeValue(
                ak,
                inputDiagram.getAttributeValue(ak)
            )
        );
        this.entityEventService.notifyEntityUpdate(outputDiagram, false);
        return outputDiagram;
    }
    public async renameDiagram(
        inputDiagram: EntityItem,
        diagramName: string
    ): Promise<EntityItem> {
        this.log('renameDiagram', inputDiagram, diagramName);
        if (!this.diagramSecurityService.canRenameDiagram(inputDiagram)) {
            return;
        }
        const attributeKey =
            this.viewTypeService.isTechnicalView &&
            inputDiagram.EntityType !== EntityType.PhysicalDiagram
                ? 'TechnicalName'
                : 'DisplayName';
        const res = await this.updateDiagramEntity(
            inputDiagram,
            attributeKey,
            diagramName
        );
        let entity = res.UpdatedEntities?.[0];
        // note: the following is only not to lose attributes that are needed bu diagrams-list. Maybe we could merge the entities ?
        // (the back end may have some logic in regards to technical & display names)
        entity = await this.loadEntityFromIdr(entity);
        this.entityEventService.notifyEntityUpdate(entity, false);
        return entity;
    }
    public async preCreateDiagram(
        diagramName: string,
        spaceIdr: ISpaceIdentifier,
        diagramKind: DiagramKind
    ) {
        const entityType =
            DiagramIdentifier.getEntityTypeFromDiagramKind(diagramKind);
        return await this.entityService.preCreateEntity(
            spaceIdr.spaceId,
            entityType,
            diagramName,
            false,
            spaceIdr.versionId
        );
    }
    public async createDiagram(
        diagramName: string,
        spaceIdr: ISpaceIdentifier,
        diagramKind: DiagramKind,
        sourceIds?: string[]
    ) {
        this.log('createDiagram', {
            diagramName,
            diagramKind,
            spaceIdr,
            sourceIds,
        });
        const entityType =
            DiagramIdentifier.getEntityTypeFromDiagramKind(diagramKind);
        const attributeValues = [
            new AttributeValueInfo(
                ServerConstants.Diagram.DiagramKind,
                diagramKind
            ),
        ];
        if (sourceIds?.length) {
            attributeValues.push(
                new AttributeValueInfo(
                    ServerConstants.Diagram.SourceIds,
                    sourceIds
                )
            );
        }
        return this.entityCreator.createEntity(
            spaceIdr.spaceId,
            spaceIdr.versionId,
            entityType,
            diagramName,
            { attributeValues }
        );
    }
    public async deleteDiagram(diagram: EntityItem) {
        this.log('deleteDiagram', { diagram });
        if (!this.diagramSecurityService.canDeleteDiagram(diagram)) {
            return;
        }
        const deletedId = await this.entityService.deleteEntity(diagram);
        return deletedId == diagram.ReferenceId;
    }

    //#endregion
}
