<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.CommentaryContainer.lblAdd
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content [class.modal-disable]="creationLoading">
        <dxy-rich-text-field
            name="commentText"
            #richTextFieldComment
            labelKey="UI.CommentaryContainer.lblCommentText"
            [(ngModel)]="newCommentaryContent"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
            [required]="true"
            [noTabCapture]="true"
            [takeFocus]="true"
            toolbarDisplayMode="focus"
            [mentionResolvers]="mentionResolvers"
            (onAcquireMentionData)="onPanelOpenClose($event)"
        ></dxy-rich-text-field>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblCreate' | translate"
        [actionLoading]="creationLoading"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
        [showCreateAnother]="true"
        [isCheckedCreateAnother]="isCheckedCreateAnother"
        (onChangeCreateAnother)="onChangeCreateAnother()"
        (onActionPointerDown)="onCloseSubmit()"
    ></dxy-modal-footer>
</form>
