import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgFor, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * Skeleton loader displayed before list is fully loaded
 */
@Component({
    selector: 'dxy-skeleton-list',
    templateUrl: './skeleton-list.component.html',
    styleUrls: ['./skeleton-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, AsyncPipe],
})
export class DxySkeletonListComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    @Input() height?: number;

    @ViewChild('list') public list: ElementRef<HTMLUListElement>;

    protected lines$ = new Subject<number[]>();

    private get realHeight() {
        return this.height ?? this.list?.nativeElement.offsetHeight;
    }

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit() {
        const numberOfLines = Math.floor(this.realHeight / 60);
        this.lines$.next(Array.from(Array(numberOfLines).keys()));
        this.cd.detectChanges();
    }
}
