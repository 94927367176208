import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { IGridCellComponent } from '../grid-cell.types';
import {
    ICustomColDef,
    ICustomGridCellComponent,
    ILayoutRefreshableGridCellComponent,
} from '../../grid-column/grid-column.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    standalone: true,
    selector: 'dxy-custom-grid-cell',
    template: `<ng-container #container></ng-container>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomGridCellComponent<TRow>
    extends DxyBaseComponent
    implements IGridCellComponent<TRow>, OnChanges, AfterViewInit
{
    @Input() col?: ICustomColDef<TRow>;
    @Input() item?: TRow;

    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef?: ViewContainerRef;

    private componentRef?: ComponentRef<ICustomGridCellComponent>;

    constructor() {
        super();
    }

    protected get renderer() {
        return this.col?.customCellComponent;
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['col', 'item'], () =>
            this.setComponentProperties(this.componentRef)
        );
    }

    public refreshLayout() {
        if (!this.componentRef) {
            return;
        }
        const instance = this.componentRef
            .instance as ILayoutRefreshableGridCellComponent;
        instance.refreshLayout?.();
    }

    protected initInnerComponent() {
        if (!this.col || !this.item) {
            return;
        }
        const componentRef = this.viewContainerRef?.createComponent(
            this.col?.customCellComponent
        );

        this.componentRef = componentRef;

        this.setComponentProperties(componentRef);
    }

    private setComponentProperties(
        componentRef?: ComponentRef<ICustomGridCellComponent>
    ) {
        if (!componentRef || !this.item) {
            return;
        }
        const inputs = this.col?.getInputs?.(this.item);

        if (inputs) {
            Object.entries(inputs).forEach(([key, value]) => {
                componentRef.setInput(key, value);
            });
        }

        const outputs = this.col?.getOutputs?.(this.item);

        if (outputs) {
            Object.entries(outputs).forEach(([key, value]) => {
                const property: any =
                    componentRef.instance[
                        key as keyof ICustomGridCellComponent
                    ];

                if (property instanceof EventEmitter) {
                    super.subscribe(property, (...args: any[]) => value(args));
                }
            });
        }

        componentRef.changeDetectorRef.detectChanges();
    }
}
