<button
    mat-flat-button
    [color]="color"
    [class.split-button]="showSplitButton"
    [disabled]="disabled"
>
    {{ btnText }}
    <i *ngIf="isLoading" class="dg5-spinner xs-spinner"></i>
    <span
        *ngIf="showSplitButton"
        (click)="$event.stopImmediatePropagation()"
        (menuOpened)="openClose.emit(true)"
        (menuClosed)="openClose.emit(false)"
        [matMenuTriggerFor]="menu"
        class="menu-trigger"
    >
        <span class="glyph-arrow-drop-down"></span>
    </span>
</button>

<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu no-max-width split-button--mat-menu"
    yPosition="above"
    xPosition="before"
>
    <dxy-option-list [options]="options"></dxy-option-list>
</mat-menu>
