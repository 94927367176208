import {
    BaseService,
    DocumentReference,
    IListOptionItem,
} from '@datagalaxy/core-ui';
import {
    IMentionResolver,
    RichTextContent,
} from '@datagalaxy/core-ui/rich-text';
import {
    RichTextMentionData,
    RichTextMentionType,
    RichTextUserMentionResultItem,
    RichTextTeamMention,
    RichTextUserMention,
    RichTextGroupMention,
} from '../rich-text-mention.types';
import { DxyMentionComponent } from './dxy-mention/dxy-mention.component';
import { Injectable } from '@angular/core';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { MultiSelectAdapter } from '../../shared-ui/UiMultiSelect.util';
import { UserService } from '../../../services/user.service';
import { AppDataService } from '../../../services/app-data.service';
import { TeamService } from '../../../team/team.service';
import { TeamDto } from '@datagalaxy/webclient/team/data-access';
import { UserGroup } from '@datagalaxy/webclient/system/data-access';
import { ObjectSecurityService } from '../../../services/object-security.service';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { generateGuid } from '@datagalaxy/utils';

const maxEntriesInMentionDropdown = 50;

@Injectable({ providedIn: 'root' })
export class RichTextMentionService extends BaseService {
    //#region static

    //#region IRichTextMentionData

    public static mentionFromIdAndType(
        mentionId: string,
        type: RichTextMentionType,
        objectId: string
    ) {
        const typeName = RichTextMentionType[type];
        return new RichTextMentionData(mentionId, typeName, objectId, typeName);
    }

    public static mentionFromUserMentionResultItem(
        userMention: RichTextUserMentionResultItem
    ) {
        return new RichTextMentionData(
            generateGuid(),
            RichTextMentionType[RichTextMentionType.User],
            userMention.objectId,
            userMention.objectTypeName
        );
    }

    public static mentionFromUserId(userId: string) {
        return new RichTextMentionData(
            generateGuid(),
            RichTextMentionType[RichTextMentionType.User],
            userId,
            'User'
        );
    }

    public static mentionFromTeamId(teamId: string) {
        return new RichTextMentionData(
            generateGuid(),
            RichTextMentionType[RichTextMentionType.User],
            teamId,
            'Team'
        );
    }

    //#endregion

    //#region RichTextUserMentionResultItem

    public static userMentionResultItemFromUserGroup(userGroup: UserGroup) {
        return new RichTextUserMentionResultItem(
            userGroup.DisplayName,
            'glyph-team-share',
            userGroup.ReferenceId,
            ServerType[ServerType.UserGroup],
            true
        );
    }

    public static userMentionResultItemFromUserPublicData(
        userPublicData: UserPublicData
    ) {
        return new RichTextUserMentionResultItem(
            userPublicData.FullNameAndEmail,
            'glyph-profile',
            userPublicData.ReferenceId,
            'User',
            false
        );
    }

    //#endregion

    //#endregion - static

    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private appDataService: AppDataService,
        private teamService: TeamService,
        private objectSecurityService: ObjectSecurityService
    ) {
        super();
    }

    public makeUserMention(userLocalId: string) {
        const userId = `${this.appDataService.clientId}:${userLocalId}`;
        return RichTextMentionService.mentionFromIdAndType(
            generateGuid(),
            RichTextMentionType.User,
            userId
        );
    }

    public async getUsersWithAccessToObject(referenceId: string) {
        return referenceId
            ? await this.objectSecurityService.getObjectUsersAccess(referenceId)
            : this.userService.getUserList();
    }

    public getDefaultMentionResolvers(referenceId?: string) {
        return [this.getUserMentionResolver(referenceId)];
    }

    public async getMention(doc: RichTextContent, mentionId: string) {
        const docRef = doc.getReference(mentionId);
        const { mentionType, serverType } = this.getTypesFromDocRef(docRef);
        switch (mentionType) {
            case RichTextMentionType.User: {
                switch (serverType) {
                    case ServerType.User:
                        return this.getUserMention(docRef);
                    case ServerType.Team:
                        return await this.getTeamMention(docRef);
                    case ServerType.UserGroup:
                        return this.getUserGroupMention(docRef);
                }
            }
        }
    }

    private getUserMention(docRef: DocumentReference) {
        const users = this.userService.getUserList();
        const user = users?.find(
            (data) => data.ReferenceId === docRef.TargetId
        );

        return new RichTextUserMention(user, docRef.TargetId);
    }

    private async getTeamMention(docRef: DocumentReference) {
        const teams = await this.teamService.getTeamsPublicData();
        const team = teams?.find(
            (data) => data.ReferenceId === docRef.TargetId
        );
        if (!team) {
            return;
        }
        return new RichTextTeamMention(
            team,
            docRef.TargetId,
            this.teamService.getTeamGlyphClass(team),
            this.teamService.getTeamImageUrl(team)
        );
    }

    private getUserGroupMention(docRef: DocumentReference) {
        const userGroups = this.userService.getUserGroupList();
        const userGroup = userGroups?.find(
            (data) => data.ReferenceId === docRef.TargetId
        );
        return new RichTextGroupMention(userGroup, docRef.TargetId);
    }

    private async getMentionOptions(
        referenceId: string,
        filterString
    ): Promise<IListOptionItem[]> {
        const users: IListOptionItem<UserPublicData>[] = (
            await this.getUsersWithAccessToObject(referenceId)
        ).map((user) => ({
            valueId: user.ReferenceId,
            labelText: user.FullName,
            renderData: MultiSelectAdapter.getUserOrPersonRenderData(
                user.UserId
            ),
            data: user,
        }));

        const teams: IListOptionItem<TeamDto>[] = (
            await this.teamService.getTeamsPublicData()
        )
            .filter((team) => team.HasTeamReadAccess)
            .map((team) => ({
                valueId: team.ReferenceId,
                labelText: team.TeamName,
                glyphClass: this.teamService.getTeamGlyphClass(team),
                iconUrl: this.teamService.getTeamImageUrl(team),
                iconClass: 'rounded',
                hintGlyphClass: 'glyph-team',
                hintGlyphTooltip: this.translate.instant(
                    'UI.RichText.Mention.Types.Team.tooltip'
                ),
                data: team,
            }));

        const options: IListOptionItem<UserPublicData | TeamDto>[] = [
            ...teams,
            ...users,
        ];

        const filteredOptions = StringUtil.filterSearched(
            filterString,
            options,
            (o) => o.labelText as string,
            true
        ).slice(0, maxEntriesInMentionDropdown);
        return CollectionsHelper.orderByText(
            filteredOptions,
            (o) => o.labelText as string
        );
    }

    private getUserMentionResolver(referenceId: string): IMentionResolver {
        return {
            matchChar: '@',
            getAvailableOptions: (filterString) =>
                this.getMentionOptions(referenceId, filterString),
            getMentionFromData: (data: UserPublicData | TeamDto) => {
                if (data instanceof UserPublicData) {
                    return this.makeUserMention(data.UserId);
                }
                return RichTextMentionService.mentionFromTeamId(
                    data.ReferenceId
                );
            },
            componentType: DxyMentionComponent,
        };
    }

    private getTypesFromDocRef(docRef: DocumentReference) {
        return {
            mentionType: RichTextMentionType[docRef?.Kind],
            serverType: ServerType[docRef?.TargetType],
        };
    }
}

export type TMentionResolverType = 'user'; //|unknown
