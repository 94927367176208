import { AppDataService } from '../../services/app-data.service';
import { ServerTimeService } from '../../services/serverTime.service';
import { IApiConfig } from '@datagalaxy/data-access';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { AppConfigService } from '@datagalaxy/webclient/config';

export function backendApiFactory(
    appConfigService: AppConfigService,
    appDataService: AppDataService,
    serverTimeService: ServerTimeService,
    featureFlagService: FeatureFlagService,
    authenticationService: AuthenticationService,
    apiBaseUrl: string
): IApiConfig {
    return {
        baseUrl: apiBaseUrl,
        getUserSessionId: () => appDataService.userSessionId,
        getJWT: async () =>
            featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')
                ? await authenticationService.getAccessTokenFromStorage()
                : appDataService.accessToken,
        getServerTimeString: () => serverTimeService.getServerNowTimeString(),
        clientVersion: appConfigService.clientAppHttpHeaderValue,
    };
}
