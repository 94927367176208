import { deserialize, SerializableEnumeration } from 'cerialize';
import { getLocalId } from '@datagalaxy/webclient/utils';

export enum SecurityRoleConstant {
    None = 0,
    DataReader = 0x30,
    SpaceAccess = 0x50,
    DataExplorer = 0x100,
    DataSteward = 0x300,
    ObjectAdministrator = 0x500,
    UserDataReader = 0x800,
    UserDataExplorer = 0x900,
    TechnicalAdministrator = 0x1000,
    AdministrativeAdministrator = 0x5000,
    SystemAdministrator = 0xffff,
}
SerializableEnumeration(SecurityRoleConstant);

export class SecurityRole {
    @deserialize public $id!: string;
    @deserialize public IsUserLevel!: boolean;
    @deserialize public IsObjectLevel!: boolean;
    @deserialize public IsSpaceObjectLevel!: boolean;
    @deserialize public IsDataObjectLevel!: boolean;
    @deserialize public Value!: SecurityRoleConstant;
    @deserialize public DisplayName!: string;

    public static sort(a: SecurityRole, b: SecurityRole) {
        return b.Value - a.Value;
    }

    public get LocalRoleId() {
        return getLocalId(this.$id);
    }
}
