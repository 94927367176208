import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { Subject } from 'rxjs';

/**
    #Archi-service-leaf (Does not reference any other app service)
*/
@Injectable({ providedIn: 'root' })
export class ClientErrorService extends BaseService {
    public get onClientError$() {
        return this.notifyClientError.asObservable();
    }
    private readonly notifyClientError = new Subject<{
        error: Error;
        cause: string;
        type: string;
    }>();

    public notify(error: Error, cause: string, type: string) {
        this.notifyClientError.next({ error, cause, type });
    }

    constructor() {
        super();
    }
}
