<textarea
    matInput
    #textarea
    #textareaAutosize="cdkTextareaAutosize"
    [cdkTextareaAutosize]="autoSize"
    [cdkAutosizeMinRows]="1"
    [ngClass]="captionClasses"
    [class.full-height]="fullHeight"
    [style.font-size.px]="fontSizePx"
    [readonly]="readonly"
    [(ngModel)]="text"
    [ngModelOptions]="{ updateOn }"
    (focus)="attach()"
    (blur)="detach()"
    [placeholder]="placeholder"
></textarea>
