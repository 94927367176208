import { SerializableEnumeration } from 'cerialize';

export enum DgZone {
    none = 0,
    /** Glossary, Catalog, Processing or Usage module */
    Module,
    /** Client Search */
    Search,
}
SerializableEnumeration(DgZone);
