import { Component, Input } from '@angular/core';
import { CheckMessageData } from '../../../shared/wizard-context';
import { ImportWizardService } from '../../../services/import-wizard.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-csv-check-message',
    templateUrl: './dxy-csv-check-message.component.html',
    styleUrls: ['dxy-csv-check-message.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class DxyCsvCheckMessageComponent extends DxyBaseComponent {
    @Input() data: CheckMessageData;

    public get isExpanded() {
        return this.data.isExpanded;
    }
    public get firstLineLbl() {
        return this.data.firstLineText;
    }
    public get secondLineLbl() {
        return this.data.secondLineText;
    }

    public get hasError() {
        return !!this.data.errorLinkData;
    }
    public get hasErrorLink() {
        return (
            this.hasError &&
            !!this.data.errorLinkData?.redirectionViewIdentifier
        );
    }

    public get errorLbl() {
        return (
            this.data.errorLinkData &&
            (this.data.errorLinkData.errorMessage || 'error')
        );
    }

    constructor(private importWizardService: ImportWizardService) {
        super();
    }

    public onClickErrorLink() {
        this.importWizardService.emitRedirectToPreImportTab(
            this.data.errorLinkData.redirectionViewIdentifier
        );
    }
}
