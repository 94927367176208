export const lookerFieldInfo = {
    apiUrl: {
        hint: {
            fr: "URL de l'API configurée dans l'outil Looker, exemple : https://<instance_name>.api.looker.com",
            en: 'API URL as configured in Looker, example: : https://<instance_name>.api.looker.com',
        },
    },
    clientId: {
        hint: {
            fr: "Clé API3 de l'utilisateur",
            en: 'User API3 key',
        },
    },
    password: {
        hint: {
            fr: "Secret associé à la clé API3 de l'utilisateur",
            en: 'API3 key secret',
        },
    },
};
