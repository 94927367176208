import {
    IResizeHandlesOptions,
    Rect,
    ResizeHandle,
} from '@datagalaxy/core-2d-util';
import { DomUtil } from '@datagalaxy/core-util';
import { ManagedItem } from '../../node/managed-item';

export class ResizeFrame<NodeData> {
    public rect = new Rect();
    public handles: ResizeHandle[];

    public readonly el: HTMLDivElement;

    constructor(
        public target: ManagedItem<NodeData>,
        options: IResizeHandlesOptions
    ) {
        this.el = DomUtil.createElement('div', 'rm-handles');
        this.rect.copy(this.target.rect).augment(14);
        this.handles = ResizeHandle.make(
            this.target.rect,
            this.rect,
            false,
            options
        );
        this.draw();
    }

    public update() {
        this.rect.copy(this.target.rect).augment(14);
        this.handles.forEach((h) => h.update());
    }

    public draw() {
        this.handles.forEach((h) => h.draw());
    }
}
