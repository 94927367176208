import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { VersioningEventService } from './versioning-event.service';
import {
    VersioningDashboardData,
    VersioningNavigationData,
} from '../versioning.types';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { NavigationApiService } from '../../navigation/services/navigation-api.service';
import {
    ChangeVersionStatusParameter,
    CompareVersionsParameter,
    CreateVersionParameter,
    EnableVersioningParameter,
    GetProjectVersionsParameter,
    ProjectVersion,
    ProjectVersionStatus,
    UpdateDefaultVersionParameter,
    UpdateVersionParameter,
    VersioningApiService,
} from '@datagalaxy/webclient/versioning/data-access';

/**
 * IMPORTANT: This service must not be called by any component,
 *
 * Use VersioningUiService instead.
 *
 * Also, don't add any reference to VersioningUiService from this service.
 */
@Injectable({ providedIn: 'root' })
export class VersioningService extends BaseService {
    private readonly _versioningNavigationData = new VersioningNavigationData();

    constructor(
        private versioningApiService: VersioningApiService,
        private navigationApiService: NavigationApiService,
        private versioningEventService: VersioningEventService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    //#region Logic Methods

    public async createProjectVersion(
        projectId: string,
        versionName: string,
        versionDescription: string,
        sourceProjectVersionId: string
    ) {
        const result = await this.callCreateVersionApi(
            projectId,
            versionName,
            versionDescription,
            sourceProjectVersionId
        );
        this.versioningEventService.notifyCreateVersionEvent(
            result.CreatedVersion
        );
        this.navigationApiService.init();
        return result;
    }

    public async updateProjectVersion(
        projectVersion: ProjectVersion,
        versionName: string,
        versionDescription: string
    ) {
        const param = new UpdateVersionParameter(
            projectVersion.ProjectReferenceId,
            projectVersion.ProjectVersionId,
            versionName,
            versionDescription
        );
        const apiResult = await this.versioningApiService.updateProjectVersion(
            param
        );
        this.versioningEventService.notifyUpdateVersionEvent(
            apiResult.UpdatedVersion
        );
        return apiResult;
    }

    public async activateVersioning(
        projectId: string,
        versionName: string,
        versionDescription: string
    ) {
        const result = await this.callActivateVersioningApi(
            projectId,
            versionName,
            versionDescription
        );
        this.handleEnableVersioningResult(result.UpdatedVersion);
        this.versioningEventService.notifyEnableVersioningEvent(
            result.UpdatedVersion
        );
        return result;
    }

    public async changeProjectVersionStatus(
        projectId: string,
        versionId: string,
        newStatus: ProjectVersionStatus
    ) {
        const param = new ChangeVersionStatusParameter(
            projectId,
            versionId,
            newStatus
        );
        const result = await this.versioningApiService.changeVersionStatus(
            param
        );
        this.navigationApiService.init();
        this.versioningEventService.notifyUpdateVersionStatusEvent(
            result.ProjectVersions
        );
    }

    public async updateDefaultVersion(
        projectVersion: ProjectVersion,
        isDefault: boolean
    ) {
        const param = new UpdateDefaultVersionParameter(
            projectVersion.ProjectReferenceId,
            projectVersion.ProjectVersionId,
            isDefault
        );
        const apiResult = await this.versioningApiService.UpdateDefaultVersion(
            param
        );
        projectVersion.IsUserDefaultVersion = isDefault;
        this.versioningEventService.notifyUpdateDefaultVersionEvent(
            projectVersion
        );
        return apiResult;
    }

    public async getProjectVersions(projectId: string) {
        if (!projectId) {
            return [];
        }
        const projectVersionsResult = await this.callGetVersionDataApi(
            projectId
        );
        return projectVersionsResult.ProjectVersions;
    }

    /** warning: returns undefined if the matching project is not versionned */
    public async getProjectVersion(projectId: string, versionId: string) {
        if (!projectId || !versionId) {
            if (projectId && !versionId) {
                this.warn('missing versionId');
            }
            return null;
        }
        const result = await this.callGetVersionDataApi(projectId, versionId);
        this.log('getProjectVersion-result', projectId, versionId, result);
        return result.ProjectVersions[0];
    }

    public async compareVersions(
        srcVersionId: string,
        dstVersionId: string,
        projectId?: string,
        entityId?: string
    ) {
        if (projectId && entityId && projectId == entityId) {
            entityId = null;
        }
        const param = new CompareVersionsParameter(
            srcVersionId,
            dstVersionId,
            projectId,
            entityId
        );
        return await this.versioningApiService.compareVersions(param);
    }

    public async entityCompareVersions(
        srcVersionId: string,
        dstVersionId: string,
        projectId?: string,
        entityId?: string
    ) {
        if (projectId && entityId && projectId == entityId) {
            entityId = null;
        }
        const param = new CompareVersionsParameter(
            srcVersionId,
            dstVersionId,
            projectId,
            entityId
        );
        return await this.versioningApiService.entityCompareVersions(param);
    }

    public async entityCompareVersionsDifference(
        srcVersionId: string,
        projectId?: string,
        entityId?: string
    ) {
        if (projectId && entityId && projectId == entityId) {
            entityId = null;
        }
        if (entityId == null) {
            return null;
        }
        const param = new CompareVersionsParameter(
            srcVersionId,
            null,
            projectId,
            entityId
        );
        return await this.versioningApiService.entityCompareVersions(param);
    }

    //#endregion

    //#region Data Handle Methods
    public handleEnableVersioningResult(projectVersion: ProjectVersion) {
        this.currentSpaceService.enableCurrentSpaceVersioning(projectVersion);
        this.navigationApiService.init();
        this._versioningNavigationData.updateVersion(projectVersion);
    }

    public getVersioningNavigationData() {
        return this._versioningNavigationData;
    }

    public async getVersioningDashboardData(
        projectId: string,
        versionId: string,
        isVersioningEnabled: boolean
    ): Promise<VersioningDashboardData> {
        const newVersioningDashboardData = new VersioningDashboardData(
            projectId,
            versionId,
            isVersioningEnabled
        );
        if (isVersioningEnabled) {
            const projectVersion = await this.getProjectVersion(
                projectId,
                versionId
            );
            newVersioningDashboardData.setProjectVersion(projectVersion);
        }
        return newVersioningDashboardData;
    }

    //#endregion

    //#region API Call Methods
    private callGetVersionDataApi(projectId: string, versionId: string = null) {
        const param = new GetProjectVersionsParameter(projectId, versionId);
        return this.versioningApiService.getProjectVersions(param);
    }

    private callActivateVersioningApi(
        projectId: string,
        versionName: string,
        versionDescription: string
    ) {
        const param = new EnableVersioningParameter(
            projectId,
            versionName,
            versionDescription
        );
        return this.versioningApiService.activateVersioning(param);
    }

    private callCreateVersionApi(
        projectId: string,
        versionName: string,
        versionDescription: string,
        sourceVersionId: string
    ) {
        const param = new CreateVersionParameter(
            projectId,
            versionName,
            versionDescription,
            sourceVersionId
        );
        return this.versioningApiService.createVersion(param);
    }

    //#endregion
}
