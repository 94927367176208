import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    CompareEntity,
    ComparisonItem,
    EntityComparisonItem,
} from './compare-entity';

@inheritSerialization(BaseServiceResult)
export class EntityCompareVersionsItem extends BaseServiceResult {
    @deserializeAs(EntityComparisonItem)
    public VersionItems!: EntityComparisonItem[];
}

@inheritSerialization(BaseServiceParameter)
export class CompareVersionsParameter extends BaseServiceParameter {
    @serialize public SourceVersionId: string;
    @serialize public TargetVersionId: string;
    @serialize public ProjectId: string;
    @serialize public DataReferenceId: string;

    constructor(
        srcVersionId: string,
        dstVersionId: string,
        projectId: string,
        entityId: string
    ) {
        super();
        this.ProjectId = projectId;
        this.DataReferenceId = entityId;
        this.SourceVersionId = srcVersionId;
        this.TargetVersionId = dstVersionId;
    }
}

@inheritSerialization(BaseServiceResult)
export class CompareVersionsResult extends BaseServiceResult {
    @deserialize public IsErrorServer!: boolean;
    @deserializeAs(ComparisonItem) public Items!: ComparisonItem[];
    @deserializeAs(CompareEntity) public singleItem!: CompareEntity;
}
