import { Pipe, PipeTransform } from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';

/**
 * Example: `*ngFor="let product of products | orderBy: 'price': 'desc'"`
 */
@Pipe({
    name: 'orderBy',
    pure: false,
    standalone: true,
})
export class OrderByPipe implements PipeTransform {
    transform(values: any[], ...args: any): any[] {
        return CollectionsHelper.orderBy(values, args[0], args[1]);
    }
}
