import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { SlackApiService } from '../../../notification-channels/api/slack-api.service';
import { UserNotificationChannelsStateService } from '../../../notification-channels/state/user-notification-channels-state.service';
import { slackNotificationChannel } from '../../../notification-channels/slack-notification-channel';
import { ToasterService } from '../../../services/toaster.service';
import { SlackAuthenticationService } from '../../../notification-channels/slack-authentication.service';
import { SlackRedirectionIdentifier } from '../../../notification-channels/slack-redirection.types';
import { CoreUtil } from '@datagalaxy/core-util';

@Injectable({ providedIn: 'root' })
export class SlackIntegrationService extends BaseService {
    constructor(
        private slackApiService: SlackApiService,
        private slackAuthenticationService: SlackAuthenticationService,
        private toasterService: ToasterService,
        private userNotificationChannelsStateService: UserNotificationChannelsStateService
    ) {
        super();
    }

    public async deactivate() {
        try {
            await this.slackApiService.deactivateIntegration();
            this.userNotificationChannelsStateService.setClientIntegration(
                slackNotificationChannel.name,
                false
            );
        } catch (error) {
            CoreUtil.warn(error);
            this.toasterService.errorToast({
                messageKey: 'UI.NotificationChannels.Slack.deactivationError',
            });
        }
    }

    public async activate() {
        await this.userNotificationChannelsStateService.initClientIntegration(
            true
        );
        const activated =
            this.userNotificationChannelsStateService.getClientChannelActivated(
                slackNotificationChannel.name
            );
        if (activated) {
            this.toasterService.warningToast({
                messageKey:
                    'UI.NotificationChannels.Slack.adminHasAlreadyActivated',
            });
            this.userNotificationChannelsStateService.setClientIntegration(
                slackNotificationChannel.name,
                true
            );
            return;
        }
        await this.slackAuthenticationService.loginSlack(
            SlackRedirectionIdentifier.integrationActivation
        );
    }
}
