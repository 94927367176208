import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import {
    MatLegacyMenuModule,
    MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { BaseOptionListComponent } from '../../base';
import {
    IListOption,
    IOptionCategory,
    IOptionWithCategory,
} from '../../IListOption';
import { UiOptionSelectDataType } from '../../UiOptionSelect.types';
import { DxyOptionItemComponent } from '../option-item/option-item.component';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * ## Role
 *  List of clickable options
 *
 * 2 possible usages:
 *  - List: Displays a list of clickable options (icon and/or text).
 *    The list is an html ul, which can be styled to be displayed either vertically or horizontally.
 *  - Drop-down list: Displays a button (or the selected element) that when clicked opens a list of selectable options
 *
 * Options can be grouped in categories.
 *
 * Each option can have:
 * - a glyph
 * - a text
 * - a callback
 * - a css class
 * - a function returning the html content to be displayed
 * - a tooltip
 * - a description text
 * - a hover glyph
 *
 * Each option can be:
 * - an action
 * - a selectable option
 * - a 2-state toggle
 * - a dropdown option-list
 * - a separator
 */
@Component({
    selector: 'dxy-option-list',
    templateUrl: './option-list.component.html',
    styleUrls: ['./option-list.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        MatLegacyMenuModule,
        MatLegacyButtonModule,
        MatLegacyTooltipModule,
        EllipsisTooltipDirective,
        NgTemplateOutlet,
        SearchInputComponent,
        TranslateModule,
        NgFor,
        DxyDataTestIdDirective,
        DxyOptionItemComponent,
        forwardRef(() => DxyOptionListComponent),
    ],
})
export class DxyOptionListComponent
    extends BaseOptionListComponent
    implements OnChanges, OnInit
{
    @Input() options: IListOption[];
    @Input() dataType: UiOptionSelectDataType;
    @Input() btnTooltipKey: string;
    @Input() categories?: IOptionCategory[];
    @Input() isDropdownMenu: boolean;
    @Input() dropdownMenuPanelClass: string;
    @Input() dropdownMenuCustomClass: string;
    @Input() dropdownMenuSelected: IListOption;
    @Input() dropdownMenuSelectedGlyphOnly: boolean;
    @Input() dropdownMenuSelectedTextOnly: boolean;
    @Input() hasSearch: boolean;
    @Input() selectDefaultValue = true;
    @Input() dropdownToggleOnGlyphOnly: boolean;
    @Input() dropdownButton?: IListOption;
    @Input() isDropdownMenuRight: boolean;
    @Input() selectedTextTrackerId: string;
    @Input() selectedDropdownTrackerId: string;
    /** true to prevent the menuTrigger to take focus after the menu being closed */
    @Input() noRestoreFocus: boolean;

    @Output() readonly onDropdownMenuSelected = new EventEmitter<IListOption>();
    @Output() readonly onSelectedTextClick = new EventEmitter<void>();

    public searchString: string;
    public searchHasNoResult = false;
    public categoryGroups: IListOption[][];
    public selectedTextTooltip: string;
    public get matMenuClass() {
        return `dxy-option-list--mat-menu ${this.dropdownMenuPanelClass ?? ''}`;
    }
    /** true while pointerdown in the menu until it is closed */
    public get isMenuTouched() {
        return this._isMenuTouched;
    }
    public get showSelectedText() {
        return (
            !this.dropdownButton &&
            this.dropdownMenuSelected &&
            this.hasText(this.dropdownMenuSelected) &&
            !this.dropdownMenuSelectedGlyphOnly
        );
    }
    public get menuXPosition() {
        return this.isDropdownMenuRight ? 'after' : 'before';
    }

    @ViewChild('menuTrigger') protected menuTrigger: MatMenuTrigger;
    @ViewChild('selectedText') private selectedTextRef: ElementRef<HTMLElement>;
    private filteredOptions: IListOption[];
    private _isMenuTouched = false;

    constructor(translate: TranslateService) {
        super(translate);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.log('$onChanges', changes);
        this.filteredOptions = this.options;
        this.categoryGroups = CollectionsHelper.groupBy(
            this.options || [],
            (o: IListOption) => o.categoryKey,
            (k, items) => items
        );
    }
    ngOnInit() {
        this.log('ngOnInit', this);
        if (this.dropdownButton) {
            this.isDropdownMenu = true;
            this.dropdownMenuSelectedGlyphOnly = false;
        } else if (!this.dropdownMenuSelected && this.selectDefaultValue) {
            this.dropdownMenuSelected = this.options?.[0];
        }
        setTimeout(() => this.setupSelectedTextTooltip());
    }

    //#region API
    public toggleMenu() {
        this.menuTrigger.toggleMenu();
    }
    //#endregion API

    public onMenuOpenedClosed(isOpen: boolean) {
        if (!isOpen) {
            this._isMenuTouched = false;
        }
        super.onMenuOpenedClosed(isOpen);
        this.openClose.emit(isOpen);
    }

    public onListContentPointerDown(_event: PointerEvent) {
        this._isMenuTouched = true;
    }

    public onSearched(e: { searchString: string }) {
        this.searchString = e.searchString;
        this.log('onSearch', this.searchString);
        this.filteredOptions = StringUtil.filterSearched(
            this.searchString,
            this.options,
            (o) => this.getText(o)
        );
        this.categoryGroups = CollectionsHelper.groupBy(
            this.filteredOptions || [],
            (o: IListOption) => o.categoryKey,
            (k, items) => items
        );
        this.searchHasNoResult = !this.filteredOptions?.length;
    }

    public onSelectedTextClicked(event: Event) {
        if (this.dropdownToggleOnGlyphOnly) {
            event.stopPropagation();
            this.onSelectedTextClick.next();
        }
    }

    public getBtnTooltip() {
        const text = this.btnTooltipKey
            ? this.translate.instant(this.btnTooltipKey)
            : this.dropdownButton
            ? this.getTooltipText(this.dropdownButton)
            : this.dropdownMenuSelected
            ? this.getTooltipText(this.dropdownMenuSelected)
            : '';
        return StringUtil.replaceHtmlBR(text);
    }

    public categoryGroupHasText(categoryGroup: IOptionWithCategory[]): boolean {
        const categoryKey = categoryGroup?.[0].categoryKey;
        return this.categories?.some(
            (category) => category.key === categoryKey
        );
    }
    public getCategoryGroupText(categoryGroup: IOptionWithCategory[]) {
        const categoryKey = categoryGroup?.[0].categoryKey;
        const category = this.categories?.find(
            (category) => category.key === categoryKey
        );
        return (
            category?.displayName ||
            (category.translateKey &&
                this.translate.instant(category.translateKey))
        );
    }

    protected onBeforeOptionCallback(option: IListOption, _event: MouseEvent) {
        if (this.isDropdownMenu) {
            if (!this.dropdownButton) {
                this.dropdownMenuSelected = option;
                this.onDropdownMenuSelected.next(option);
                setTimeout(() => this.setupSelectedTextTooltip());
            }
        }
    }

    private setupSelectedTextTooltip() {
        this.selectedTextTooltip = '';
        const selectedTextElement = this.selectedTextRef?.nativeElement;
        if (!selectedTextElement) {
            return;
        }
        this.selectedTextTooltip = this.getText(this.dropdownMenuSelected);
    }
}
