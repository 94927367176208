<ng-container *ngIf="settings$ | async as settings">
    <button
        *ngIf="!hasWriteAccess"
        mat-icon-button
        (click)="toggleSuggestions()"
        [matTooltip]="
            'UI.Suggestions.Settings.' +
                (settings.disabled ? 'enableTT' : 'disableTT') | translate
        "
    >
        <i
            class="glyph-suggestion"
            [class.glyph-robot2]="!settings.disabled"
            [class.glyph-robot2-off]="settings.disabled"
        ></i>
    </button>

    <ng-container *ngIf="hasWriteAccess">
        <button
            #panelToggleButton
            mat-icon-button
            [class.pulse]="notify$ | async"
            (click)="toggleSuggestionPanel()"
            [matTooltip]="'UI.Suggestions.Panel.triggerTT' | translate"
        >
            <i
                class="glyph-suggestion"
                [class.glyph-robot2]="!settings.disabled"
                [class.glyph-robot2-off]="settings.disabled"
            ></i>
        </button>
        <dxy-panel-host
            [panelOpened]="opened$ | async"
            (panelOpenedChange)="toggleSuggestionPanel()"
            [toggleButton]="panelToggleButton._elementRef.nativeElement"
        >
            <app-suggestion-panel
                *ngIf="opened$ | async"
            ></app-suggestion-panel>
        </dxy-panel-host>
    </ng-container>
</ng-container>
