import {
    EntityAttributeUpdatedEvent,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IEventTranslationInputs,
    TEventTranslateParameter,
} from '../../../interfaces/IEventTranslationInputs';
import { getEntityActivityEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

/**
 * ## Role
 * Adapter to translate entity attribute update events
 */
@registerTypedEventTranslationAdapter([EntityAttributeUpdatedEvent])
export class EntityAttributeUpdatedEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: EntityAttributeUpdatedEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const serverType = this.event.EntityHdd?.ServerType;
        const serverTypeName = serverType ? ServerType[serverType] : undefined;
        if (!serverTypeName) {
            return;
        }

        const inputs = getEntityActivityEventTranslationInputs(this.event);
        inputs.translateParameters.push(this.getPropertyNameTranslation());
        return inputs;
    }

    private getPropertyNameTranslation() {
        return {
            key: 'propertyName',
            value: this.event.IsCdp
                ? this.event.AttributeDisplayName
                : undefined,
            valueTranslationKey: this.event.IsCdp
                ? undefined
                : `DgServerTypes.BaseData.fields.${this.event?.attributeKey}`,
        } as TEventTranslateParameter;
    }
}
