import { Injectable } from '@angular/core';
import {
    ILinkSuggestionWithHDD,
    ISuggestion,
    ISuggestionParameters,
} from './suggestion.types';
import {
    GetUserSuggestionsParameter,
    GetUserSuggestionsResult,
    LogViewedSuggestionsParameter,
    SuggestionApiService,
    SuggestionAttributeFilter,
    UpdateEntityAttributeFromSuggestionParameter,
    ViewedSuggestion,
} from '@datagalaxy/webclient/suggestion/data-access';
import {
    SuggestionElement,
    SuggestionType,
} from '@datagalaxy/webclient/suggestion/types';

/**
 * ## Role
 * Performs api calls concerning suggestions
 *
 * ## Type
 * Stateless
 */
@Injectable({ providedIn: 'root' })
export class SuggestionLegacyApiService {
    constructor(private suggestionApiService: SuggestionApiService) {}

    public async setSuggestionAttributeUserChoice(
        accepted: boolean,
        suggestions: SuggestionElement[]
    ) {
        const s = suggestions[0];

        if (!s) {
            return;
        }
        const p = new UpdateEntityAttributeFromSuggestionParameter();
        p.DataReferenceId = s.EntityReferenceId;
        p.IsSuggestionAccepted = accepted;
        p.AttributeKey = s.AttributeKey;
        p.SuggestionElements = suggestions;
        p.VersionId = s.VersionId;
        const result =
            await this.suggestionApiService.setSuggestionAttributeUserChoice(p);
        // Prevent suggestions from disappearing; Api returns only suggestions related to p.AttributeKey
        result.UpdatedEntity.EntitySuggestions = undefined;
        return result.UpdatedEntity;
    }

    public async saveLinkUserChoice(
        accepted: boolean,
        suggestions: ILinkSuggestionWithHDD[]
    ) {
        const param = {
            Guids: suggestions.map((s) => s.linkSuggestion.Guid),
            IsAccepted: accepted,
        };

        return await this.suggestionApiService.applySuggestionUserChoice(param);
    }

    /**
     * ## Note
     * Link suggestions log viewed is not supported
     */
    public async logViewedSuggestions(suggestions: ISuggestion[]) {
        const p = new LogViewedSuggestionsParameter();
        p.ViewedSuggestions = suggestions
            .filter((suggestion) => suggestion.type !== SuggestionType.Link)
            .map(
                (suggestion) =>
                    new ViewedSuggestion(suggestion.type, suggestion.id)
            );
        await this.suggestionApiService.logViewedSuggestions(p);
    }

    public async getUserSuggestions(
        p: ISuggestionParameters
    ): Promise<GetUserSuggestionsResult> {
        const queryParameter = new GetUserSuggestionsParameter();
        queryParameter.SuggestionType = p.suggestionType;
        queryParameter.SpaceId = p.spaceId;
        queryParameter.VersionId = p.versionId ?? '';
        queryParameter.SuggestionAttributeFilter = this.getAttributeFilter(p);
        queryParameter.Size = p.pageSize;
        queryParameter.SuggestionHashCodes = p.excludedHashcodes;
        return this.suggestionApiService.getUserSuggestions(queryParameter);
    }

    public async hasSuggestions(): Promise<boolean> {
        const result = await this.suggestionApiService.hasSuggestions();
        return result.HasSuggestions;
    }

    private getAttributeFilter(
        p: ISuggestionParameters
    ): SuggestionAttributeFilter {
        if (!p.attributePath || !p.attributeValues) {
            return;
        }
        const filter = new SuggestionAttributeFilter();
        filter.AttributePath = p.attributePath;
        filter.Values = p.attributeValues;
        return filter;
    }
}
