import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoreUtil } from '@datagalaxy/core-util';
import {
    CoreDialogUtil,
    DialogResult,
    DialogType,
    DxyBaseModalComponent,
    IConfirmOptions,
    IDialogService,
    IInformOptions,
    IModalDialog,
    IModalDialogData,
    IModalDialogEvent,
    IModalDialogResult,
    IModalInstanceOptions,
    IModalSettings,
    IPromptOptions,
    IShowDialogOptions,
    ModalSize,
} from '@datagalaxy/ui/dialog';
import { Subject } from 'rxjs';
import { BaseService } from '../base';
import { DxyModalDialogComponent } from './modal-dialog/modal-dialog.component';

/** Keeps track of the currently displayed modal dialog.
 *
 * #archi-services-leaf
 * */
@Injectable({ providedIn: 'root' })
export class DxyDialogService extends BaseService implements IDialogService {
    //#region events

    public get onDialogOpened$() {
        return this.onDialogOpened.asObservable();
    }
    private readonly onDialogOpened = new Subject<IModalDialogEvent>();

    public get onDialogClosed$() {
        return this._onDialogClosed.asObservable();
    }
    private readonly _onDialogClosed = new Subject<IModalDialog>();

    public get onDialogResult$() {
        return this.onDialogResult.asObservable();
    }
    private readonly onDialogResult = new Subject<IModalDialogResult>();

    public get currentDialogClosed$() {
        return this.currentDialogClosed.asObservable();
    }
    private readonly currentDialogClosed = new Subject<void>();

    //#endregion

    /** returns true when a modal dialog is currently displayed */
    public get isActiveModal() {
        return !!this.current;
    }
    /** returns true if the currently displayed modal dialog is the root modal dialog  */
    public get isActiveRootModal() {
        return !this.current?.parent;
    }

    private current: IModalDialog;

    constructor(private dialog: MatDialog, private ngZone: NgZone) {
        super();
    }

    /** display a modal dialog with title, message,
     * and a single button to close the dialog */
    public async inform(opt: IInformOptions) {
        this.log('inform', opt);
        const dialogOpt: IShowDialogOptions = {
            type: opt.type ?? DialogType.Ok,
            ...(opt ?? {}),
        };
        try {
            await this.showDialog(dialogOpt);
        } catch (e) {
            this.warn(e);
        }
    }

    /** display a modal dialog with title, message,
     * a text input field,
     * and 2 buttons for to confirm or cancel */
    public async prompt(opt: IPromptOptions) {
        this.log('prompt', opt);
        let result: string;
        const dialogOpt: IShowDialogOptions = {
            ...(opt ?? {}),
            type: DialogType.OkCancel,
            hasUserInput: true,
            userInputValidationMethod: (value) => {
                if (
                    opt?.userInputValidationMethod &&
                    !opt.userInputValidationMethod(value)
                ) {
                    return false;
                }
                result = value;
                return true;
            },
        };
        try {
            const dialogresult = await this.showDialog(dialogOpt);
            if (dialogresult == DialogResult.Ok) {
                return result;
            }
        } catch (e) {
            this.warn(e);
        }
    }

    /** display a modal dialog with title, message,
     * and 2 buttons for to confirm or cancel an action */
    public async confirm(opt: IConfirmOptions) {
        this.log('confirm', opt);
        const dialogOpt: IShowDialogOptions = {
            ...(opt ?? {}),
            type: opt.type ?? DialogType.OkCancel,
        };
        try {
            const result = await this.showDialog(dialogOpt);
            this.log(
                'confirm',
                DialogType[dialogOpt.type],
                DialogResult[result],
                dialogOpt,
                opt
            );
            return CoreDialogUtil.isConfirmed(result, dialogOpt.type);
        } catch (e) {
            this.warn(e);
        }
    }

    /** displays a confirm modal dialog with a single message, displayed as the modal title, or not */
    public async confirmMessage(message: string, noTitle?: boolean) {
        const options = this.getSingleMessageDialogOptions(message, !noTitle);
        return await this.confirm(options);
    }
    /** displays a prompt modal dialog with a default value, and a single message displayed as the modal title, or not */
    public async promptOrDefault(
        message: string,
        defaultValue: string,
        noTitle?: boolean
    ) {
        const options = this.getSingleMessageDialogOptions(message, !noTitle, {
            userInputValue: defaultValue,
        });
        const result = await this.prompt(options);
        return result?.trim();
    }
    private getSingleMessageDialogOptions(
        message: string,
        isMessageTitle?: boolean,
        moreOptions: IPromptOptions = {}
    ) {
        return {
            title: isMessageTitle ? message : undefined,
            message: isMessageTitle ? undefined : message,
            width: 'auto',
            ...moreOptions,
        };
    }

    /** Display a custom dialog using *DxyModalDialogComponent*.
     * Note: Use only if *inform()*, *prompt()* and *confirm()* do not fit your needs,
     * and use *open()* to display another component as a modal. */
    public async showDialog(options: IShowDialogOptions) {
        this.log('showDialog', DialogType[options?.type], options);
        const data: IModalDialogData = {};
        const opt: IModalSettings<
            DxyModalDialogComponent,
            IModalDialogData,
            IModalDialogResult
        > = {
            ...options,
            componentType: DxyModalDialogComponent,
        };
        CoreDialogUtil.resolveFields.forEach(
            (k) => ((data as unknown)[k] = options[k])
        );
        const result = await this.open<
            DxyModalDialogComponent,
            IModalDialogData,
            IModalDialogResult
        >({ data, ...opt });
        if (!result) {
            return;
        }
        this.onDialogResult.next(result);
        return result.dialogResult;
    }

    /** close(cancel) any open modal dialog */
    public dismissAll() {
        this.log('dismissAll');
        const all: IModalDialog[] = [];
        let c = this.current;
        while (c) {
            all.push(c);
            c = c.parent;
        }
        this.log('dismissAll', !!this.current, all);
        all.forEach((m) => {
            this.current = m;
            this.dismissCurrent();
        });
    }

    /** display the given component as a modal dialog */
    public open<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData,
        TResult
    >(options: IModalSettings<TComponent, TData, TResult>) {
        this.log('open-start', options);
        this.adaptOptions(options);

        return this.ngZone.run(() => {
            this.log('open-dialog.open)', options);
            const dialogRef = this.dialog.open<TComponent, TData, TResult>(
                options.componentType,
                options
            );

            this.log('open-setModalInstanceOptions', dialogRef);
            dialogRef.componentInstance.setModalInstanceOptions(
                this.makeInstanceOptions(options)
            );

            const parent = options.isFromActiveModal ? this.current : undefined;
            const modal = (this.current = { parent, instance: dialogRef });

            this.log('open-subscribe');

            const obsClosed = dialogRef.afterClosed();
            const closedSubscription = obsClosed.subscribe((result) => {
                this.log('open-afterClosed');
                closedSubscription?.unsubscribe();
                this.onDialogClosed(modal, result);
            });

            this.log('open-onDialogOpened');
            this.onDialogOpened.next({ options, modal } as IModalDialogEvent);

            this.log('open-end', obsClosed);
            return CoreUtil.toPromise(obsClosed);
        });
    }

    public adaptOptions<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData = unknown,
        TResult = unknown
    >(options: IModalSettings<TComponent, TData, TResult>) {
        if (!options.width) {
            if (options.size == ModalSize.Large) {
                options.width = '1000px';
            } else {
                options.width = '600px';
            }
        }

        // panelClass may be a string or an array. We make it an array.
        let pc = options.panelClass;
        if (!Array.isArray(pc)) {
            pc = pc ? [pc] : [];
        }
        pc.push('dg5-base-modal');
        // windowClass is a (legacy) DG modal option. We add it to panelClass
        const wc = options.windowClass;
        if (wc && !pc.includes(wc)) {
            pc.push(wc);
        }
        options.panelClass = pc;

        if (options.disableCloseOnBackdropClick == undefined) {
            // default behaviour
            options.disableCloseOnBackdropClick = true;
        }

        if (
            options.disableCloseOnEscapeKey ||
            options.disableCloseOnBackdropClick
        ) {
            options.disableClose = true;
        }
    }

    public makeInstanceOptions<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData = unknown,
        TResult = unknown
    >(
        options: IModalSettings<TComponent, TData, TResult>
    ): IModalInstanceOptions {
        return {
            disableCloseOnEscapeKey: options.disableCloseOnEscapeKey,
            disableCloseOnBackdropClick: options.disableCloseOnBackdropClick,
        };
    }

    private dismissCurrent() {
        if (!this.current) {
            return;
        }
        this.log('dismissCurrent', this.current);
        try {
            this.current.instance?.close();
        } finally {
            this.current = null;
        }
    }

    private onDialogClosed(modal: IModalDialog, result: unknown) {
        this.log('onDialogClosed', modal, result);
        this._onDialogClosed.next(modal);
        this.onInstanceClosed(modal);
    }

    private onInstanceClosed(modal: IModalDialog) {
        if (!modal) {
            return;
        }
        this.log('onInstanceClosed', modal);
        this.current = modal.parent;
        this.currentDialogClosed.next();
    }
}
