import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

export type LogoColor = 'primary' | 'white' | 'black';

@Component({
    selector: 'dxy-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NgClass],
})
export class LogoComponent {
    @Input() mode: 'horizontal' | 'vertical' = 'vertical';
    @Input() color: LogoColor = 'primary';
}
