import { deserializeAs, inheritSerialization, serializeAs } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { CommunicationSetting } from './user-communication-setting';

@inheritSerialization(BaseServiceParameter)
export class SetCommunicationSettingParameter extends BaseServiceParameter {
    @serializeAs(CommunicationSetting)
    CommunicationSetting?: CommunicationSetting;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetCommunicationSettingsResult extends BaseServiceResult {
    @deserializeAs(CommunicationSetting)
    CommunicationSettings?: CommunicationSetting[];

    constructor() {
        super();
    }
}
