import * as moment from 'moment';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export class SpaceCache<T extends ISpaceIdentifier> {
    private data = new Map<string, Map<string, T>>();
    private dates = new Map<string, moment.Moment>();

    constructor(
        private load: (idr: ISpaceIdentifier) => Promise<T>,
        private expirationDelaySeconds: number,
        public debug = false
    ) {}

    public async get(idr: ISpaceIdentifier): Promise<T> {
        if (!idr?.spaceId) {
            return null as T;
        }

        const localSpaceId = idr.spaceId;
        const localVersionId = this.getLocalVersionId(idr);

        const m = this.dates.get(this.getDateKey(idr));
        if (m && moment().diff(m, 'second') > this.expirationDelaySeconds) {
            this.log('expired', idr);
            this.deleteOne(idr);
        }

        if (this.data.has(localSpaceId)) {
            const spaces = this.data.get(localSpaceId);
            if (spaces.has(localVersionId)) {
                const result = spaces.get(localVersionId);
                this.log('hit', idr);
                return result;
            } else {
                this.log('miss-versionId', idr);
                const result = await this.doLoad(idr);
                if (result) {
                    spaces.set(localVersionId, result);
                }
                return result;
            }
        } else {
            this.log('miss-spaceId', idr);
            const result = await this.doLoad(idr);
            if (result) {
                const map = new Map<string, T>();
                map.set(localVersionId, result);
                this.data.set(localSpaceId, map);
            }
            return result;
        }
    }

    public deleteOne(idr: ISpaceIdentifier) {
        if (!idr?.spaceId) {
            return;
        }

        const localSpaceId = idr.spaceId;
        const localVersionId = this.getLocalVersionId(idr);

        this.data.get(localSpaceId)?.delete(localVersionId);
    }
    public deleteGroup(spaceId: string) {
        if (!spaceId) {
            return;
        }
        this.data.delete(spaceId);
    }
    public clear() {
        this.data.clear();
    }

    private getLocalVersionId(idr: ISpaceIdentifier) {
        return idr.versionId || '';
    }
    private getDateKey(idr: ISpaceIdentifier) {
        return `${idr.spaceId ?? ''}_${this.getLocalVersionId(idr)}`;
    }

    private async doLoad(idr: ISpaceIdentifier) {
        const result = await this.load(idr);
        this.dates.set(this.getDateKey(idr), moment());
        return result;
    }

    private log(...args: any[]) {
        if (this.debug) {
            console.log('SpaceCache', ...args);
        }
    }
}
