<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.TableColumns
    </div>
    <div class="actions-container">
        <dxy-dropdown-button
            *ngIf="hasWriteAccess && !readOnly"
            type="add"
            tooltipTranslateKey="UI.TableColumns.addColumnTooltip"
            [actions]="addButtonActions"
        ></dxy-dropdown-button>
    </div>
</div>

<div class="panel-content-body">
    <dxy-edit-grid
        *ngIf="gridBridge"
        [data]="gridBridge.gridData"
        (onValueChanged)="gridBridge.onGridValueChanged($event)"
        logId="entity-table-columns"
        class="no-global-left-padding"
    ></dxy-edit-grid>
</div>
