import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { EntityType } from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@inheritSerialization(EntityItem)
export class FilteredEntityItem extends EntityItem {
    //@deserializeAs(EntityType) public EntityType: EntityType;
    @deserializeAs('EntityType') public entityTypeString!: string;
    @deserialize ExactMatchAttributes!: Map<string, string>;

    public override get EntityType() {
        return EntityType[this.entityTypeString as keyof typeof EntityType];
    }

    public static OnDeserialized(instance: FilteredEntityItem) {
        instance.ExactMatchAttributes = new Map(
            Object.entries(instance.ExactMatchAttributes || {}).filter(
                ([k]) => k != '$id' && k != '$type'
            )
        );
    }
}
