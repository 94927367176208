<div class="header">
    <div *ngIf="title" class="title">{{ title }}</div>
    <ng-container *ngIf="showSearchInput">
        <dxy-search-input
            class="no-border-top"
            [showCancelSearch]="true"
            (searchStringChange)="onSearchTerm($event)"
        ></dxy-search-input>
    </ng-container>
</div>
<div class="body" [style.height.px]="bodyHeight">
    <div
        *ngIf="showSearchInput"
        class="user-count"
        translate
        [translateParams]="{ count: userCount }"
    >
        UI.Global.userCount
    </div>
    <dxy-user-cell
        *ngFor="let user of users"
        [userId]="user.UserId"
        [actions]="actions"
        [actionData]="user"
        [disableTooltip]="!hasSingleUser"
    ></dxy-user-cell>
</div>
