import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IHasAttributeFilterModels } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { NavigationService } from '../../services/navigation.service';
import { ISpotlightSection } from './spotlight-result-section.types';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { DxyShowMoreButtonComponent } from '@datagalaxy/core-ui';
import { DxySpotlightResultItemComponent } from '../dxy-spotlight-result-item/dxy-spotlight-result-item.component';
import { NgIf, NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-spotlight-result-section',
    templateUrl: './dxy-spotlight-result-section.component.html',
    styleUrls: ['./dxy-spotlight-result-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        NgIf,
        NgFor,
        DxySpotlightResultItemComponent,
        DxyShowMoreButtonComponent,
    ],
})
export class DxySpotlightResultSectionComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() section: ISpotlightSection;
    @Input() initialRowsCount = 5;
    @Input() spaceIdr: ISpaceIdentifier;
    @Output() onSelectFilterItem = new EventEmitter<AttributeMetaValue>();
    @Output() onShowAll = new EventEmitter();
    @Output() onClickFilteredSearch =
        new EventEmitter<IHasAttributeFilterModels>();
    @Output() onClickResultItem = new EventEmitter();
    @Output() onClearLastSearches = new EventEmitter();

    public currentMaxCount: number;
    public sectionItems: (
        | AttributeMetaValue
        | IHasHddData
        | IHasAttributeFilterModels
    )[];
    public get showBadge() {
        return !this.section.displayAllResults;
    }
    public get isShowAllEnable() {
        return !this.section.displayAllResults && !this.section.isFilter;
    }
    public get isClearSectionEnabled() {
        return this.section.isClearSectionEnabled;
    }
    public get totalItemCount() {
        return this.section?.items?.length;
    }
    public get totalItemCountText() {
        return this.section.totalText ?? this.section?.items?.length;
    }
    /**
     * Defines the number of items we display before showing the 'show more result' button
     * If section is a filter, we never show this button and keep displaying 'show more' button
     */
    public get maxCount() {
        return this.section.isFilter ? Infinity : this.initialRowsCount * 3;
    }

    constructor(
        private functionalLogService: FunctionalLogService,
        private navigationService: NavigationService
    ) {
        super();
    }

    ngOnInit() {
        this.currentMaxCount = this.initialRowsCount;
        if (this.section.displayAllResults) {
            this.currentMaxCount = Infinity;
        }
        this.setSectionItems();
    }

    public onShowMore() {
        if (this.currentMaxCount < this.maxCount) {
            this.currentMaxCount += this.initialRowsCount;
            this.setSectionItems();
            const featureCode = this.navigationService.isInSpaceHomeView
                ? 'MAIN_SEARCH_VIEW_MORE'
                : `SPOTLIGHT_VIEW_MORE_${this.navigationService.getCurrentZoneFeatureCode()}`;
            this.functionalLogService.logFunctionalAction(
                featureCode,
                CrudOperation.R
            );
        } else {
            this.onShowAll.emit();
        }
    }

    public isShowMoreAvailable() {
        return this.totalItemCount > this.currentMaxCount;
    }

    public onClearResults() {
        this.onClearLastSearches.emit();
    }

    private setSectionItems() {
        this.sectionItems = this.section.items.slice(0, this.currentMaxCount);
    }
}
