import {
    EntityType,
    IDataIdentifier,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import {
    ISpaceIdentifier,
    SpaceGovernanceUserDto,
} from '@datagalaxy/webclient/workspace/domain';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';
import {
    ABaseSecurityData,
    EntitySecurityData,
} from '@datagalaxy/webclient/security/domain';
import { EntitySocialData } from '@datagalaxy/webclient/social/domain';

export type NavSpace = NavProject | NavOrganization;

export enum SpaceSecurityProfileType {
    Private = 0,
    Limited = 1,
    Open = 2,
}
SerializableEnumeration(SpaceSecurityProfileType);

abstract class ABaseNavSpace implements IDataIdentifier {
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public ReferenceId!: string;
    @deserialize public ParentReferenceId!: string;
    @deserialize public CanCreateEntities!: boolean;
    // Will be true if user has import rights on ANY version on ANY module in the NavSpace
    @deserialize public CanImportEntities!: boolean;
    @deserializeAs(EntitySecurityData) public SecurityData!: EntitySecurityData;
    @deserializeAs(SpaceGovernanceUserDto)
    public DefaultOwnerUser!: SpaceGovernanceUserDto;
    @deserializeAs(SpaceGovernanceUserDto)
    public DefaultStewardUser!: SpaceGovernanceUserDto;
    @deserializeAs(SpaceSecurityProfileType)
    public SecurityProfileType!: SpaceSecurityProfileType;
    @deserialize public Trigram!: string;
    @deserialize public ImageHash!: string;
    @deserialize public IconHash!: string;

    public IsDefaultSpace = false;

    abstract get Type(): ServerType;
    abstract get TypeName(): string;

    //#region IDataIdentifier
    public get DataReferenceId() {
        return this.ReferenceId;
    }

    public get DataTypeName() {
        return this.TypeName;
    }

    //#endregion
}

@inheritSerialization(ABaseNavSpace)
export class NavOrganization extends ABaseNavSpace implements ISpaceIdentifier {
    public get Type() {
        return ServerType.Organization;
    }

    public get TypeName() {
        return ServerType[ServerType.Organization];
    }

    //#region ISpaceIdentifier
    public get spaceId() {
        return this.ReferenceId;
    }

    //#endregion
}

@inheritSerialization(ABaseNavSpace)
export class NavProject extends ABaseNavSpace implements ISpaceIdentifier {
    @deserialize public DefaultVersionName!: string;

    /**
     * NOTE: Default Version Is:
     * When Versioning Disabled,
     *      the "technical" version Id for the only version in the project
     * Otherwise,
     *      First Official Version,
     *      Or, First Work Version,
     *      Or, First Archived Version
     *      Or, First Release Candidate Version
     */
    @deserialize public DefaultVersionId!: string;
    @deserialize public AnyWorkVersionId!: string;

    /**
     * NOTE: UserDefaultVersionId is the User Favorite version for the Project, if set. May
     * be null
     */
    @deserialize public UserDefaultVersionId?: string;
    @deserialize public UserDefaultVersionName?: string;

    @deserialize public IsVersioningEnabled!: boolean;
    @deserialize public CanImportEntitiesOnCatalog!: boolean;

    public get Type() {
        return ServerType.Project;
    }

    public get TypeName() {
        return ServerType[ServerType.Project];
    }

    //#region ISpaceIdentifier
    public get versionId() {
        return this.UserDefaultVersionId || this.DefaultVersionId;
    }

    public get spaceId() {
        return this.ReferenceId;
    }

    //#endregion
}

export interface IHasSpaceIconData {
    Trigram: string;
    IconHash: string;
}

@inheritSerialization(ABaseSecurityData)
export class ModuleSecurityData extends ABaseSecurityData {
    @deserialize public Module!: DataGalaxyModule;
}

export abstract class Space
    implements
        ISpaceIdentifier,
        IDataIdentifier,
        IEntityIdentifier,
        IHasSpaceIconData
{
    @deserialize public ReferenceId!: string;
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserializeAs(EntitySocialData) public SocialData!: EntitySocialData;
    @deserialize public ParentOrganizationReferenceId!: string;
    @deserializeAs(ModuleSecurityData)
    public GlossarySecurityData!: ModuleSecurityData;
    @deserialize public CanImportEntities!: boolean;
    @deserialize public VersionId!: string;
    @deserialize public DefaultOwnerUserId!: string;
    @deserialize public DefaultStewardUserId!: string;
    @deserialize public HasMultipleVersions!: boolean;
    @deserializeAs(SpaceSecurityProfileType)
    public SecurityProfileType!: SpaceSecurityProfileType;
    @deserialize public Trigram!: string;
    @deserialize public ImageHash!: string;
    @deserialize public IconHash!: string;

    public abstract ServerType: ServerType;

    public abstract get SecurityData(): EntitySecurityData;

    //#region ISpaceIdentifier
    public get versionId() {
        return this.VersionId;
    }

    public get spaceId() {
        return this.ReferenceId;
    }

    //#endregion
    public get isProject() {
        return this.ServerType == ServerType.Project;
    }

    public get isOrga() {
        return this.ServerType == ServerType.Organization;
    }

    //#region IDataIdentifier
    public get DataReferenceId() {
        return this.ReferenceId;
    }

    public get DataTypeName() {
        return ServerType[this.ServerType];
    }

    //#endregion
    public get entityType() {
        return this.isOrga ? EntityType.Organization : EntityType.Project;
    }
}

@inheritSerialization(Space)
export class Project extends Space {
    @deserialize public VersionName!: string;
    @deserialize public VersionStatus!: string;
    @deserialize public VersionDescription!: string;
    @deserialize public IsVersioningEnabled!: boolean;
    @deserialize public CanImportEntitiesOnCatalog!: boolean;
    @deserializeAs(EntitySecurityData)
    public ProjectSecurityData!: EntitySecurityData;
    @deserializeAs(ModuleSecurityData)
    public DataProcessingContainerSecurityData!: ModuleSecurityData;
    @deserializeAs(ModuleSecurityData)
    public SoftwareContainerSecurityData!: ModuleSecurityData;
    @deserializeAs(EntitySecurityData)
    public ModelsSecurityData!: EntitySecurityData[];
    @deserializeAs(ModuleSecurityData)
    public CatalogSecurityData!: ModuleSecurityData;
    @deserialize public OrganizationReferenceId!: string;
    @deserialize public ModelIds!: string[];

    public readonly ServerType = ServerType.Project;

    public get SecurityData() {
        return this.ProjectSecurityData;
    }

    constructor() {
        super();
    }

    public getModelSecurityData(modelId: string) {
        return this.ModelsSecurityData?.find(
            (m) => m.DataReferenceId == modelId
        );
    }

    public getNavProject() {
        const navProject = new NavProject();
        navProject.ReferenceId = this.ReferenceId;
        navProject.DisplayName = this.DisplayName;
        navProject.ParentReferenceId = this.OrganizationReferenceId;
        navProject.DefaultVersionId = this.VersionId;
        navProject.DefaultVersionName = this.VersionName;
        navProject.IsVersioningEnabled = this.IsVersioningEnabled;
        return navProject;
    }
}

@inheritSerialization(Space)
export class Organization extends Space {
    @deserializeAs(EntitySecurityData)
    public OrganizationSecurityData!: EntitySecurityData;
    @deserializeAs(NavProject) public Projects!: NavProject[];
    @deserializeAs(NavOrganization)
    public ChildOrganizations!: NavOrganization[];
    @deserialize public ChildOrganizationReferenceIds!: string[];
    @deserialize public override VersionId!: string;

    public readonly ServerType = ServerType.Organization;

    public override get DataReferenceId() {
        return this.ReferenceId;
    }

    public override get DataTypeName() {
        return ServerType[this.ServerType];
    }

    public get SecurityData() {
        return this.OrganizationSecurityData;
    }

    constructor() {
        super();
    }

    public getNavOrganization() {
        const navOrganization = new NavOrganization();
        navOrganization.ReferenceId = this.ReferenceId;
        navOrganization.DisplayName = this.DisplayName;
        navOrganization.ParentReferenceId = this.ParentOrganizationReferenceId;
        return navOrganization;
    }
}
