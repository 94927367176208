import Screenfull from 'screenfull';
import { Subject } from 'rxjs';

/** fullscreen toggling utility */
export class FullScreenUtil {
    public static get onChange$() {
        FullScreenUtil.attach();
        return FullScreenUtil.onChanged.asObservable();
    }
    private static onChanged = new Subject<boolean>();

    private static isAttached: boolean;
    private static listeners = new Array<EventListener>();

    public static get isEnabled() {
        return Screenfull.isEnabled as boolean;
    }
    public static get isFullScreen() {
        return Screenfull.isFullscreen;
    }

    /** returns the element currently displayed in full screen mode, if any */
    public static get fullScreenElement() {
        return FullScreenUtil.isFullScreen
            ? FullScreenUtil._currentElement
            : undefined;
    }
    private static _currentElement?: Element;

    public static enterFullScreen(element?: Element) {
        return FullScreenUtil.toggle(element, true);
    }
    public static exitFullSCreen() {
        return FullScreenUtil.toggle(undefined, false);
    }

    public static toggle(element?: Element, force?: boolean) {
        if (!element) {
            element = undefined;
        }
        const sf = Screenfull;
        const toFullScreen = force != undefined ? force : !sf.isFullscreen;

        return new Promise<boolean | null>((resolve) => {
            if (sf?.isEnabled) {
                (toFullScreen ? sf.request(element) : sf.exit()).then(() => {
                    FullScreenUtil._currentElement = toFullScreen
                        ? element
                        : undefined;
                    setTimeout(() => resolve(sf.isFullscreen));
                });
            } else {
                FullScreenUtil._currentElement = undefined;
                resolve(null);
            }
        });
    }

    public static addEventListener(listener: EventListener) {
        FullScreenUtil.attach();
        const listeners = FullScreenUtil.listeners;
        if (typeof listener != 'function' || listeners.includes(listener)) {
            return;
        }
        listeners.push(listener);
    }
    public static removeEventListener(listener: EventListener) {
        if (typeof listener != 'function') {
            return;
        }
        const listeners = FullScreenUtil.listeners;
        const i = listeners.indexOf(listener);
        if (i != -1) {
            listeners.splice(i, 1);
        }
    }

    private static attach() {
        if (FullScreenUtil.isAttached) {
            return;
        }
        FullScreenUtil.isAttached = true;
        Screenfull.on('change', FullScreenUtil.onchange);
    }
    private static detach() {
        if (!FullScreenUtil.isAttached) {
            return;
        }
        FullScreenUtil.isAttached = false;
        Screenfull.off('change', FullScreenUtil.onchange);
    }

    private static onchange = (e: Event) => {
        FullScreenUtil.listeners.forEach((listener) => {
            try {
                listener(e);
            } catch (e) {}
        });
        /**
         * screenfull 'change' event is emitted when the browser is in fullscreen mode, but a bit
         * before the target element is fully opened in fullscreen. Delaying the emission of the event
         * solve this issue
         */
        setTimeout(() =>
            FullScreenUtil.onChanged.next(FullScreenUtil.isFullScreen)
        );
    };
}
