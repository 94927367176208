import {
    autoserializeAs,
    deserializeAs,
    inheritSerialization,
} from 'cerialize';
import { BaseLinkDataInfo, ServerType } from '@datagalaxy/dg-object-model';
import {
    EntityItem,
    EntityTypeMeta,
} from '@datagalaxy/webclient/entity/domain';

export class VersionedDataIdentifier {
    @autoserializeAs(String) public DataReferenceId: string;
    @autoserializeAs(String) public DataTypeName: string;
    @autoserializeAs(String) public VersionId: string;

    public constructor(
        dataReferenceId: string,
        dataTypeName: string,
        versionId: string
    ) {
        this.DataReferenceId = dataReferenceId;
        this.DataTypeName = dataTypeName;
        this.VersionId = versionId;
    }

    public get ServerType() {
        return ServerType[this.DataTypeName as keyof typeof ServerType];
    }
}

@inheritSerialization(BaseLinkDataInfo)
export class LinkEntityData extends BaseLinkDataInfo {
    /** target entities ? */
    @deserializeAs(EntityItem) public LinkedEntities!: EntityItem[];
    /** the link object */
    @deserializeAs(EntityItem) public LinkEntity!: EntityItem;
    @deserializeAs(EntityTypeMeta) public LinkEntityMeta!: EntityTypeMeta;
    @deserializeAs(EntityTypeMeta) public LinkedEntitiesMeta!: EntityTypeMeta;
}
