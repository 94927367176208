import { IMarkerSpec } from '@datagalaxy/core-2d-util';
import { IDiagramShapeSpec } from './shape.types';

/** Definition of a marker for usage in a diagram */
export interface IDiagramMarkerSpec extends IMarkerSpec, IDiagramShapeSpec {}

//#region arrows

export enum ArrowKind {
    /** arrow is filled and its contour is materialized by:
     * - a triangle when no foldback
     * - 4 segments when foldback is positive
     */
    plain,
    /** arrow is materialized by 2 segments joined at the tip */
    line,
}

export interface IArrowPointsSpec {
    /** width of the base (height when the arrow is oriented left to right) */
    breadth: number;
    /** distance from the base to the tip */
    length: number;
    /** on interval [0, 1] */
    foldback?: number;
}

export interface IArrowParams extends IArrowPointsSpec {
    /** defaults to *ArrowKind.plain* */
    kind?: ArrowKind;
    class?: string;
    pathClass?: string;
    roundDecimals?: boolean;
    /** thickness of the stroke, used to compute the tip position */
    thickness?: number;
}

//#endregion arrows
