<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.EntityExportModal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form #form="ngForm">
    <div mat-dialog-content>
        <div class="file-name-section">
            <dxy-field-text
                labelKey="UI.EntityExportModal.filename"
                [(ngModel)]="filename"
                (blur)="onFilenameBlur()"
                name="filename"
                required
            ></dxy-field-text>
            <button mat-icon-button (click)="toggleFileOptions()">
                <i class="glyph-settings"></i>
            </button>
        </div>
        <dxy-file-options-form
            *ngIf="showLineOptions"
            [allowedEncodings]="allowedEncodings"
            [delimiter]="defaultDelimiter"
            (onConfigChange)="onFileConfigChange($event)"
        ></dxy-file-options-form>
        <div class="options-section">
            <dxy-collapsible
                titleKey="UI.EntityExportModal.options.hierarchy.title"
                class="body-padding"
            >
                <div class="inline-radio">
                    <mat-radio-group
                        [(ngModel)]="parentOptionSelected"
                        (change)="onUpdateOptions()"
                        name="parentOption"
                    >
                        <mat-radio-button
                            *ngFor="let parentOption of parentOptions"
                            [matTooltip]="getParentOptionTooltip(parentOption)"
                            [value]="parentOption"
                            [disabled]="isParentOptionDisabled(parentOption)"
                        >
                            <span translate>{{
                                getParentOptionText(parentOption)
                            }}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="inline-radio">
                    <mat-radio-group
                        [(ngModel)]="childOptionSelected"
                        (change)="onUpdateOptions()"
                        name="childOption"
                    >
                        <mat-radio-button
                            *ngFor="let childOption of childOptions"
                            [matTooltip]="getChildrenOptionTooltip(childOption)"
                            [value]="childOption"
                            [disabled]="isChildOptionDisabled(childOption)"
                        >
                            <span translate>{{
                                getChildrenOptionText(childOption)
                            }}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </dxy-collapsible>
            <dxy-collapsible
                *ngIf="showLinkedObjectOption"
                titleKey="UI.EntityExportModal.options.linkedObjects.title"
                class="body-padding"
            >
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        [matTooltip]="
                            'UI.EntityExportModal.options.linkedObjects.tooltip'
                                | translate
                        "
                        [(ngModel)]="importAllLinks"
                        (ngModelChange)="onUpdateOptions()"
                        name="importAllLinks"
                    >
                        <span translate
                            >UI.EntityExportModal.options.linkedObjects.options.all</span
                        >
                    </mat-checkbox>
                </div>
            </dxy-collapsible>
            <dxy-collapsible
                *ngIf="showReferencesOptions"
                titleKey="UI.EntityExportModal.options.references.title"
                class="body-padding"
            >
                <div
                    class="checkbox-wrapper"
                    *ngFor="let referenceOption of referenceOptions"
                >
                    <mat-checkbox
                        [matTooltip]="
                            getReferenceOptionTooltip(referenceOption.id)
                        "
                        [(ngModel)]="referenceOption.value"
                        (ngModelChange)="onUpdateOptions()"
                        name="referenceOption"
                    >
                        {{
                            getReferenceOptionText(referenceOption.id)
                                | translate
                        }}
                    </mat-checkbox>
                </div>
            </dxy-collapsible>
        </div>
    </div>
    <div class="modal-footer">
        <div *ngIf="!isPreExportDisabled" class="modal-footer-stats">
            <span *ngIf="!isLoadingPreExport" class="dg5-spinner"></span>
            {{ getRecapText() }}
        </div>
        <div class="modal-footer-actions">
            <button mat-flat-button type="button" (click)="onCloseCancel()">
                <span translate>UI.EntityExportModal.cancel</span>
            </button>
            <button
                mat-flat-button
                color="primary"
                type="button"
                [disabled]="isLoadingPreExport || form.invalid"
                (click)="onExport()"
            >
                <span translate>UI.EntityExportModal.export</span>
            </button>
        </div>
    </div>
</form>
