import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { BaseService } from '@datagalaxy/core-ui';
import { DialogType, ModalSize } from '@datagalaxy/ui/dialog';
import { ForeignKeyColumnMapping } from '../pkfk/ForeignKeyColumnMapping';
import { PrimaryKeyGridData } from '../pkfk/PrimaryKeyGridData';
import { ForeignKeyGridData } from '../pkfk/ForeignKeyGridData';
import { DataTypeMappingGridItem } from '../grid-items/DataTypeMappingGridItem';
import { DataTypeGridItem } from '../grid-items/DataTypeGridItem';
import { DataTypeMappingAdminGridItem } from '../grid-items/DataTypeMappingAdminGridItem';
import {
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    IHasHddData,
    IIdr,
    ModelType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HddUtil } from '../../shared/util/HddUtil';
import { Subject, Subscription } from 'rxjs';
import { RealTimeCommService } from '../../services/realTimeComm.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { DxyPrimaryKeySettingsModalComponent } from '../pkfk/dxy-primary-key-settings-modal/dxy-primary-key-settings-modal.component';
import {
    IForeignKeyDeleteModalResult,
    IForeignKeySettingModalInput,
    IForeignKeySettingsModalOutput,
    IPrimaryKeySettingsInput,
    IPrimaryKeySettingsOutput,
} from '../pkfk/pkfk.types';
import { DxyForeignKeySettingsModalComponent } from '../pkfk/dxy-foreign-key-settings-modal/dxy-foreign-key-settings-modal.component';
import { DxyForeignKeyDeleteModalComponent } from '../pkfk/dxy-foreign-key-delete-modal/dxy-foreign-key-delete-modal.component';
import { IGenerateScriptOptions } from '../../shared/util/app-types/modeler-ddl.types';
import { IGenerationSettingsModalInput } from '../ddl/dxy-script-generation-settings-modal/script-generation-settings.types';
import { DxyScriptGenerationSettingsModalComponent } from '../ddl/dxy-script-generation-settings-modal/dxy-script-generation-settings-modal.component';
import { DxyScriptGenerationResultModalComponent } from '../ddl/dxy-script-generation-result-modal/dxy-script-generation-result-modal.component';
import {
    IGenerationResultModalInput,
    IGroupsByType,
    IScriptMessage,
    IScriptResult,
    IScriptResultItem,
} from '../ddl/dxy-script-generation-result-modal/script-generation-result.types';
import {
    IDataTypeEventArg,
    IDataTypeMappingEventArg,
    IFKChangeEvent,
    ITableColumnsEvent,
} from '../modeler.types';
import {
    AddColumnToPrimaryKeyParameter,
    BaseMasterData,
    Column,
    ConvertForeignKeyParameter,
    CreateFunctionalForeignKeyParameter,
    CreateTechnicalForeignKeyParameter,
    DataType,
    DataTypeMapping,
    DataTypeMappingItem,
    DataTypeSettings,
    DeleteForeignKeyParameter,
    ForeignKey,
    GenerateScriptItem,
    GenerateScriptParameter,
    GenerateScriptResult,
    GetModelDataTypesParameter,
    IHasReferenceId,
    LoadDataParameter,
    MasterDataService,
    Model,
    ModelerApiParameter,
    ModelerApiResult,
    ModelerApiService,
    ModelerData,
    ModelerUpdatePKApiResult,
    ModelSettings,
    PreDeleteDataTypeMappingParameter,
    PrimaryKey,
    Ref,
    RemoveColumnFromPrimaryKeyParameter,
    SaveDataParamItem,
    Table,
    TableType,
    UpdateFunctionalForeignKeyParameter,
    UpdatePrimaryKeyParameter,
    UpdateTechnicalForeignKeyParameter,
} from '@datagalaxy/webclient/modeler/data-access';
import { generateReferenceId, getContextId } from '@datagalaxy/webclient/utils';
import { isUnsuccessfulApiError } from '@datagalaxy/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Injectable({ providedIn: 'root' })
export class ModelerService extends BaseService {
    //#region static

    public static getModelId(o: IHasHddData) {
        return (
            o &&
            HddUtil.getFirstHddByType(o.HddData, ServerType.Model)?.ReferenceId
        );
    }

    public static isTableEntityType(entityType: EntityType) {
        return EntityTypeUtil.getServerType(entityType) == ServerType.Table;
    }

    //#endregion

    private readonly onReorderColumns = new Subject<ITableColumnsEvent>();
    private readonly onDeleteColumn = new Subject<ITableColumnsEvent>();
    private readonly onAddColumn = new Subject<ITableColumnsEvent>();
    private readonly onUpdateColumn = new Subject<ITableColumnsEvent>();
    private readonly fkEvent = new Subject<IFKChangeEvent>();
    private readonly dataTypeEvent = new Subject<IDataTypeEventArg>();
    private readonly dataTypeMappingEvent =
        new Subject<IDataTypeMappingEventArg>();
    private systemTypeSettings: DataTypeSettings;
    private clientTypeSettings: DataTypeSettings;

    constructor(
        private masterDataService: MasterDataService,
        private modelerApiService: ModelerApiService,
        private realTimeCommService: RealTimeCommService,
        private dxyModalService: DxyModalService,
        private translate: TranslateService
    ) {
        super();
    }

    //#region event subscription

    public subscribeTableColumnEvents(
        handler: (event: ITableColumnsEvent) => void
    ) {
        if (!handler) {
            return;
        }
        const subscription = new Subscription();
        subscription.add(this.onAddColumn.subscribe(handler));
        subscription.add(this.onDeleteColumn.subscribe(handler));
        subscription.add(this.onReorderColumns.subscribe(handler));
        subscription.add(this.onUpdateColumn.subscribe(handler));
        return subscription;
    }

    // unused?
    public subscribeFkEvents(handler: (event: IFKChangeEvent) => void) {
        if (!handler) {
            return;
        }
        const subscription = new Subscription();
        subscription.add(this.fkEvent.subscribe(handler));
        return subscription;
    }

    public subscribeDataTypeEvents(opt: {
        onAdd?: (data: DataType) => void;
        onDelete?: (data: DataType) => void;
    }) {
        if (!opt) {
            return;
        }
        return this.dataTypeEvent.subscribe((e) => {
            switch (e.event) {
                case 'add':
                    opt.onAdd?.(e.data);
                    break;
                case 'delete':
                    opt.onDelete?.(e.data);
                    break;
            }
        });
    }
    public subscribeDataTypeMappingEvents(opt: {
        onChange?: (data: DataTypeMapping) => void;
    }) {
        if (!opt) {
            return;
        }
        return this.dataTypeMappingEvent.subscribe((e) => {
            switch (e.event) {
                case 'change':
                    opt.onChange?.(e.data);
                    break;
            }
        });
    }

    public subscribeRealTimeModelerUpdate(
        handler: (
            userData: any,
            modelerUpdateData: ModelerData,
            sourceId: string
        ) => void
    ) {
        return this.realTimeCommService.subscribeModelerUpdate(
            (userData, modelerUpdateData, sourceId) => {
                this.updateMasterDataFromApiForUpdate(modelerUpdateData);
                handler(userData, modelerUpdateData, sourceId);
            }
        );
    }

    //#endregion

    //#region Model

    private getModelById(modelId: string) {
        return this.fromRef<Model>(new Ref(ServerType.Model, modelId));
    }

    public isRelationalModel(model: Model) {
        return model?.Type === ModelType.Relational;
    }
    public getDataTypes(model: Model): DataType[] {
        const modelSettings = this.getSettings(model);
        return modelSettings
            ? this.getAvailableDataTypesForModel(modelSettings)
            : [];
    }
    public getSettings(model: Model, ensureCurrentMappingIsSet = false) {
        const modelSettings = this.fromRef(model?.SettingsRef);
        if (ensureCurrentMappingIsSet) {
            this.ensureCurrentMappingIsSet(modelSettings);
        }
        return modelSettings;
    }
    public getTables(model: Model, filter?: (t: Table) => boolean) {
        return CollectionsHelper.filter(
            model?.Tables.map((tr) => this.fromRef(tr)),
            filter
        );
    }
    public getTable(model: Model, tableId: string) {
        const r = model?.Tables.find((r) => r.$id === tableId);
        return this.fromRef(r);
    }

    public removeTable(model: Model, tableId: string) {
        CollectionsHelper.remove(model?.Tables, (r) => r.$id == tableId);
    }

    public async getModelWithoutTables(modelId: string) {
        this.log('getModelWithoutTables', modelId);
        await this.modelerApiService.loadData(
            LoadDataParameter.forModelWithoutTables(modelId)
        );
        const model = this.masterDataService.getMasterObject(
            new Ref<Model>(ServerType.Model, modelId)
        );
        this.log('getModelWithoutTables-out', model);
        return model;
    }

    public async getModelForDataTypeModelSettings(entityData: EntityItem) {
        const modelId = HddUtil.getModelId(entityData?.HddData);
        if (!modelId) {
            return;
        }
        return this.getModelWithoutTables(modelId);
    }
    public async getModelWithTableColumns(
        modelId: string,
        ...tableIds: string[]
    ) {
        await this.getModelWithoutTables(modelId);
        return this.loadModelDataWithTableColumns(modelId, ...tableIds);
    }

    /** Load (and returns) the Model matching the given modelId data in the MasterDataContainer.
        - Table items are loaded:
          - without their Columns
          - with their HddData
        - ForeignKeys are loaded with their HddData
    */
    public async getModelWithTableAndFkHDatas(modelId: string) {
        this.log('getModelWithTableAndFkHDatas', modelId);
        const param = new ModelerApiParameter(modelId);
        const apiResult = await this.modelerApiService.getModelerData(param);
        return this.loadDataFromDtoToMasterData(apiResult, modelId);
    }

    public async loadModelDataWithTableColumns(
        modelId: string,
        ...tableIds: string[]
    ) {
        this.log('loadModelDataWithTableColumns', modelId, tableIds);
        const param = new ModelerApiParameter(modelId, tableIds);
        const apiResult = await this.modelerApiService.getModelerTableColumns(
            param
        );
        return this.loadDataFromDtoToMasterData(apiResult, modelId);
    }

    //#endregion - Model

    //#region Table & Column

    public getTableById(tableId: string) {
        return this.fromRef(new Ref<Table>(ServerType.Table, tableId));
    }

    public getTableColumnsFromTableId(tableId: string) {
        const table = this.getTableById(tableId);
        const columns = CollectionsHelper.orderBy(
            this.getTableColumns(table),
            (c) => c.DisplayOrder
        );
        this.log('getTableColumnsFromTableId', tableId, table, columns);
        return columns;
    }

    private getTableColumns(table: Table) {
        return table?.Columns?.map((r) => this.fromRef(r)) ?? [];
    }
    public getTablePk(table: Table) {
        return this.fromRef(table.PrimaryKeyRef);
    }
    private getTableFks(table: Table) {
        const pk = this.getTablePk(table);
        return this.getPkFks(pk);
    }
    public hasTechnicalPk(table: Table) {
        const pk = this.getTablePk(table);
        return !!pk && !pk.IsFunctionalOnly;
    }
    private getTableModel(table: Table) {
        return this.fromRef(table?.ModelRef);
    }
    private getTableModelId(table: Table) {
        return table?.ModelRef.$id;
    }
    private getModelIdFromTableId(tableId: string) {
        return this.getTableModelId(this.getTableById(tableId));
    }

    public getColumnTable(column: Column) {
        return this.fromRef(column?.TableRef);
    }
    public getColumnDataType(column: Column) {
        return this.fromRef(column?.DataTypeRef);
    }
    public setColumnDataType(column: Column, value: DataType) {
        if (!column) {
            return;
        }
        column.DataTypeRef = Ref.from(value);
    }
    public getColumnsForFkColumnMapping(
        table: Table,
        mapping: ForeignKeyColumnMapping
    ) {
        return this.getTableColumns(table).filter((col) =>
            mapping.isPrimaryKeyColumnTypeMatch(col.DataTypeRef.$id)
        );
    }
    public getColumnSizeAndPrecision(col: Column): string {
        if (!this.isColumnSizeRequired(col)) {
            return '';
        }
        return this.isColumnPrecisionRequired(col)
            ? `${col.Size},${col.Precision}`
            : `${col.Size}`;
    }
    public setColumnSizeAndPrecision(col: Column, value: string) {
        if (!this.isColumnSizeRequired(col)) {
            return;
        }
        if (this.isColumnPrecisionRequired(col)) {
            const result = value.match(/^(\d+|),(\d+|)?$/);
            if (result.length > 0) {
                col.Size = result[1] ? +result[1] : 0;
                col.Precision = result[2] ? +result[2] : 0;
            }
        } else {
            const result = value.match(/^\d+|$/);
            if (result.length > 0) {
                col.Size = result[0] ? +result[0] : 0;
            }
        }
    }
    public isColumnSizeEditable(col: Column) {
        return (
            !!col &&
            !col.IsPrimaryKey &&
            !col.IsForeignKey &&
            this.isColumnSizeRequired(col)
        );
    }
    public isColumnDeleteEnabled(col: Column) {
        return !!col && !col.IsForeignKey && !col.IsPrimaryKey;
    }
    public async createColumnNoReload(column: Column) {
        const sdi = this.masterDataService.forAddNewData(
            column.TableRef,
            column
        );
        const { success } = await this.masterDataService.saveData(
            'createColumnNoReload',
            sdi
        );
        if (!success) {
            return;
        }
        this.onAddColumn.next({
            table: this.getColumnTable(column),
            column,
            action: 'add',
        });
    }

    public async deleteColumn(column: Column) {
        const sdi = this.masterDataService.forDeleteDataFromPrimaryOwner(
            column,
            column.TableRef
        );
        const { success } = await this.masterDataService.saveData(
            'deleteColumn',
            sdi
        );
        if (!success) {
            return;
        }
        const table = this.getColumnTable(column);
        this.recomputeColumnsOrder(table);
        this.onDeleteColumn.next({ table, column, action: 'delete' });
    }
    private recomputeColumnsOrder(table: Table) {
        table?.Columns?.forEach((r, i) => {
            const c = this.fromRef(r);
            if (c) {
                c.DisplayOrder = i + 1;
            }
        });
    }

    public newColumn(table: Table): Column {
        const model = this.getTableModel(table);
        const modelSettings = this.getSettings(model);
        const defaultDataType =
            this.getAvailableDataTypesForModel(modelSettings)?.[0] ?? null;

        const columnId = this.newReferenceIdFromContext(table);
        const c = new Column(columnId);
        c.DisplayName = this.enforceColumnUniqueness(
            false,
            ServerConstants.TypeName.Column,
            table,
            c
        );
        c.TechnicalName = this.enforceColumnUniqueness(
            true,
            ServerConstants.TypeName.Column,
            table,
            c
        );
        c.TableRef = Ref.from(table);
        c.DataTypeRef = Ref.from(defaultDataType);
        c.DisplayOrder = table.Columns.length + 1;

        // Add to model/masterdata
        c.TableRef = Ref.from(table);
        table.Columns.push(Ref.from(c));
        this.masterDataService.addMasterObject(c);

        return c;
    }

    private newReferenceIdFromContext(idr: IHasReferenceId) {
        const contextId = getContextId(idr.ReferenceId);
        return generateReferenceId(contextId);
    }

    public newTable(model: Model, tableEntity: EntityItem): Table {
        const t = new Table(tableEntity.ReferenceId);

        t.DisplayName = tableEntity.DisplayName;
        t.TechnicalName = tableEntity.TechnicalName ?? tableEntity.DisplayName;

        const etm = EntityTypeUtil.getMapping(tableEntity.entityType);
        t.Type = TableType[etm.SubTypeName];
        t.EntityType = tableEntity.entityType;
        t.ModelRef = Ref.from(model);

        // Add Table to Model/MasterData
        model.Tables.push(Ref.from(t));
        this.masterDataService.addMasterObject(t);

        return t;
    }

    public async updateMandatory(column: Column, newValue: any, oldValue: any) {
        await this.updateGenericEntityProperty(
            column,
            'IsMandatory',
            newValue,
            oldValue
        );
        const table = this.getColumnTable(column);
        this.onUpdateColumn.next({ table, column, action: 'update' });
    }

    public async updateGenericEntityProperty(
        entity: BaseMasterData,
        propertyName: string,
        newPropertyValue: string,
        oldPropertyValue: string
    ) {
        const sdis = this.getCreateUpdateEntityPropertySaveItems(
            entity,
            propertyName,
            newPropertyValue,
            oldPropertyValue
        );
        return (
            await this.masterDataService.saveData(
                'updateGenericEntityProperty',
                ...sdis
            )
        )?.success;
    }
    public getCreateUpdateEntityPropertySaveItems(
        entity: BaseMasterData,
        propertyName: string,
        newPropertyValue: string,
        oldPropertyValue: string
    ): SaveDataParamItem[] {
        // Avoid saving empty Technical/DisplayName
        if (
            (propertyName === 'TechnicalName' ||
                propertyName === 'DisplayName') &&
            (newPropertyValue || '').trim() === ''
        ) {
            entity[propertyName] = oldPropertyValue;
            return [];
        }

        if (newPropertyValue === oldPropertyValue) {
            entity[propertyName] = oldPropertyValue;
            return [];
        }
        const sdi = SaveDataParamItem.forUpdateProperty(
            entity,
            propertyName,
            newPropertyValue
        );
        return [sdi];
    }

    public getCurrentMappingId(table: Table) {
        const model = this.getTableModel(table);
        const settings = this.getSettings(model);
        return Ref.idOrNull(settings?.CurrentMappingRef);
    }

    private getAvailableDataTypesForModel(modelSettings: ModelSettings) {
        return this.getAllDataTypesForModel(modelSettings).filter(
            (dataType) => !this.isExcludedDataType(modelSettings, dataType)
        );
    }
    private isExcludedDataType(
        modelSettings: ModelSettings,
        dataTypeIdr: IIdr
    ) {
        return Ref.includes(modelSettings.ExcludedSystemTypes, dataTypeIdr);
    }

    public async changeColumnOrder(
        column: Column,
        previousIndex: number,
        currentIndex: number
    ) {
        const { sdi, table } =
            this.getSaveItemForChangeColumnOrder(
                column,
                previousIndex,
                currentIndex
            ) ?? {};
        if (!sdi || !table) {
            return;
        }
        const { success } = await this.masterDataService.saveData(
            'changeColumnOrder',
            sdi
        );
        this.onReorderColumns.next({
            table,
            column,
            previousIndex,
            currentIndex,
            action: 'reorder',
        });
        return success;
    }
    private getSaveItemForChangeColumnOrder(
        column: Column,
        previousIndex: number,
        currentIndex: number,
        noRecomputeTableColumnsOrder = false
    ) {
        const table = this.getColumnTable(column);
        if (!table) {
            return;
        }
        if (column.ReferenceId != table.Columns[previousIndex]?.$id) {
            return;
        }
        if (currentIndex >= table.Columns.length) {
            return;
        }
        CollectionsHelper.moveItemInArray(
            table.Columns,
            previousIndex,
            currentIndex
        );
        if (!noRecomputeTableColumnsOrder) {
            this.recomputeColumnsOrder(table);
        }
        const move = currentIndex - previousIndex;
        const sdi = SaveDataParamItem.forMoveDataReference(
            column,
            table,
            ServerConstants.PropertyName.Columns,
            move
        );
        return { sdi, table, move };
    }

    public async updateColumnType(col: Column) {
        if (col.IsPrimaryKey) {
            return await this.onUpdatePKType(col);
        } else {
            return await this.saveColumnType(col);
        }
    }
    private async onUpdatePKType(column: Column) {
        const sdis = this.getSaveColumnTypeItems(column);
        return await this.saveDataModelWithPkFkCols(column, sdis, (fkCol) => {
            fkCol.DataTypeRef = column.DataTypeRef;
            return this.getSaveColumnTypeItems(fkCol);
        });
    }
    private async saveColumnType(col: Column) {
        const sdis = this.getSaveColumnTypeItems(col);
        return (
            await this.masterDataService.saveData('saveColumnType', ...sdis)
        )?.success;
    }
    private getSaveColumnTypeItems(col: Column): SaveDataParamItem[] {
        const items: SaveDataParamItem[] = [];
        const dt = this.fromRef(col.DataTypeRef);
        const sdi = SaveDataParamItem.forAddDataReference(
            dt,
            col,
            ServerConstants.PropertyName.DataTypeRef
        );
        items.push(sdi);

        const oldSize = col.Size;
        const oldPrecision = col.Precision;
        this.resetSizeAndPrecision(col);

        const addItemPrecision = (precision: number) => {
            const sdi = SaveDataParamItem.forUpdateProperty(
                col,
                ServerConstants.PropertyName.Precision,
                precision
            );
            items.push(sdi);
        };

        if (!this.isColumnSizeRequired(col) && oldSize !== 0) {
            if (oldPrecision !== 0) {
                addItemPrecision(0);
            }
            const sdi = SaveDataParamItem.forUpdateProperty(
                col,
                ServerConstants.PropertyName.Size,
                0
            );
            items.push(sdi);
        } else if (oldPrecision !== 0) {
            if (this.isColumnPrecisionRequired(col)) {
                addItemPrecision(col.Precision);
            } else {
                addItemPrecision(0);
            }
        }

        return items;
    }
    private resetSizeAndPrecision(col: Column) {
        if (!this.isColumnSizeRequired(col)) {
            this.resetToNoSize(col);
        } else if (!this.isColumnPrecisionRequired(col)) {
            this.resetToNoPrecision(col);
        }
    }
    private resetToNoSize(col: Column) {
        col.Size = 0;
        col.Precision = 0;
    }
    private resetToNoPrecision(col: Column) {
        col.Precision = 0;
    }

    public getColumnDataTypeDisplayName(col: Column) {
        return this.getColumnDataType(col)?.DisplayName;
    }
    public async updateColumnDataSize(col: Column) {
        return col.IsPrimaryKey
            ? await this.onUpdatePKSize(col)
            : await this.saveColumnDataSize(col);
    }
    private async onUpdatePKSize(column: Column) {
        const sdis = this.getSaveColumnDataSizeItems(column);
        return await this.saveDataModelWithPkFkCols(column, sdis, (fkCol) =>
            this.onUpdateFkSize(fkCol, column)
        );
    }
    private onUpdateFkSize(fkCol: Column, column: Column) {
        fkCol.Size = column.Size;
        if (this.isColumnPrecisionRequired(fkCol)) {
            fkCol.Precision = column.Precision;
        }
        return this.getSaveColumnDataSizeItems(fkCol);
    }
    private async saveColumnDataSize(col: Column) {
        const sdis = this.getSaveColumnDataSizeItems(col);
        return (
            await this.masterDataService.saveData('saveColumnDataSize', ...sdis)
        )?.success;
    }
    private getSaveColumnDataSizeItems(col: Column) {
        const items: SaveDataParamItem[] = [];

        const sdi = SaveDataParamItem.forUpdateProperty(
            col,
            ServerConstants.PropertyName.Size,
            col.Size
        );
        items.push(sdi);

        if (this.isColumnPrecisionRequired(col)) {
            const sdi = SaveDataParamItem.forUpdateProperty(
                col,
                ServerConstants.PropertyName.Precision,
                col.Precision
            );
            items.push(sdi);
        }

        return items;
    }
    private isColumnPrecisionRequired(col: Column) {
        return this.getColumnDataType(col)?.IsPrecisionRequired;
    }
    private isColumnSizeRequired(col: Column) {
        return this.getColumnDataType(col).IsSizeRequired;
    }

    private async saveDataModelWithPkFkCols(
        column: Column,
        sdis: SaveDataParamItem[],
        getFkColItems: (fkCol: Column) => SaveDataParamItem[]
    ) {
        const pk = this.getTablePk(this.getColumnTable(column));
        const columnId = column.ReferenceId;
        const pkColIdx = pk.OrderedColumns.findIndex(
            (oc) => oc.$id == columnId
        );
        if (pkColIdx == -1) {
            throw 'undefined pkColIdx';
        }

        sdis = sdis?.slice();
        this.getPkFks(pk).forEach((fk) => {
            const fkCol = this.fromRef(fk.OrderedChildColumns[pkColIdx]);
            sdis.push(...getFkColItems(fkCol));
        });

        return (
            await this.masterDataService.saveData(
                'saveDataModelWithPkFkCols',
                ...sdis
            )
        )?.success;
    }

    //#endregion - Table & Column

    //#region PrimaryKey

    public getPkTable(pk: PrimaryKey) {
        return this.fromRef(pk?.TableRef);
    }
    public getPkColumns(pk: PrimaryKey) {
        return pk?.OrderedColumns?.map((r) => this.fromRef(r)) ?? [];
    }
    private getPkFks(pk: PrimaryKey) {
        return pk?.ForeignKeys?.map((r) => this.fromRef(r)) ?? [];
    }
    public getPkLinkedColumnsCount(pk: PrimaryKey) {
        return pk?.ForeignKeys.length;
    }
    public hasLinkedForeignKeys(pk: PrimaryKey) {
        return this.getPkLinkedColumnsCount(pk) > 0;
    }

    public async loadModelForPrimaryKeyGrid(modelId: string) {
        this.log('loadModelForPrimaryKeyGrid', modelId);
        if (!modelId) {
            return;
        }
        await this.getModelWithoutTables(modelId);
        const param = new ModelerApiParameter(modelId);
        const apiResult = await this.modelerApiService.getModelPkList(param);
        this.loadDataFromDtoToMasterData(apiResult, modelId);
    }

    /** returns true if the primary key has been updated */
    public async openPrimaryKeySettingsModal(tableId: string) {
        this.log('openPrimaryKeySettingsModal', tableId);
        await this.loadDataForPkModal(tableId);
        const data = this.getPrimaryKeySettingsInput(tableId);
        const result = await this.dxyModalService.open<
            DxyPrimaryKeySettingsModalComponent,
            IPrimaryKeySettingsInput,
            IPrimaryKeySettingsOutput
        >({
            componentType: DxyPrimaryKeySettingsModalComponent,
            size: ModalSize.Large,
            data,
        });
        if (result) {
            await this.updatePrimaryKey(result);
        }
    }

    public async getPrimaryKeySettingsInputForTableEntity(
        tableEntity: IEntityIdentifier & IHasHddData
    ) {
        await this.loadModelForTableEntity(tableEntity);
        return this.getPrimaryKeySettingsInput(tableEntity.ReferenceId);
    }

    public async loadModelForTableEntity(
        tableEntity: IEntityIdentifier & IHasHddData
    ) {
        const modelId = HddUtil.getModelId(tableEntity?.HddData);
        const tableId = tableEntity?.ReferenceId;
        this.log('loadModelForTableEntity', modelId, tableId);
        if (!modelId || !tableId) {
            return;
        }

        return await this.getModelWithTableColumns(modelId, tableId);
    }

    private getPrimaryKeySettingsInput(tableId: string) {
        const table = this.getTableById(tableId);
        const settings: IPrimaryKeySettingsInput = {
            currentPrimaryKey: this.getTablePk(table),
            currentTable: table,
            availableColumns: this.getTableColumns(table),
        };
        this.log('getPrimaryKeySettingsInput', settings);
        return settings;
    }

    /** may display an info modal (in case of error) */
    public async updatePrimaryKey(pkSettings: IPrimaryKeySettingsOutput) {
        this.log('updatePrimaryKey', pkSettings);
        const { tableId, primaryKeyName, columnIds } = pkSettings;
        const param = new UpdatePrimaryKeyParameter(
            tableId,
            primaryKeyName,
            columnIds
        );

        try {
            const result = await this.modelerApiService.updatePrimaryKey(param);
            this.updateMasterDataFromApiForUpdate(result.ModelData);
            return true;
        } catch (e) {
            if (
                isUnsuccessfulApiError<ModelerUpdatePKApiResult>(e) &&
                e.error.IsModelError
            ) {
                await this.dxyModalService.inform({
                    titleKey:
                        'UI.Modeler.PrimaryKeySettingsModal.titleErrorModel',
                    messageKey:
                        'UI.Modeler.PrimaryKeySettingsModal.msgErrorModel',
                });
                return false;
            }
            throw e;
        }
    }

    /** May display an info modal dialog, and return false */
    public async addColumnToPrimaryKey(table: Table, column: Column) {
        const param = new AddColumnToPrimaryKeyParameter(
            table.ReferenceId,
            column.ReferenceId
        );
        const result = await this.modelerApiService.addColumnToPrimaryKey(
            param
        );
        this.updateMasterDataFromApiForUpdate(result.ModelData);
        return true;
    }
    public async removeColumnFromPrimaryKey(table: Table, column: Column) {
        const pk = this.getTablePk(table);
        if (this.hasLinkedForeignKeys(pk)) {
            let titleKey: string;
            let messageKey: string;
            // Display warning popup
            if (this.getPkLinkedColumnsCount(pk) > 1) {
                // Removing last column of PK
                // Display popup : Removing PK
                titleKey = 'UI.PrimaryKey.Dialog.ttlRemoveColumnFromPrimaryKey';
                messageKey =
                    'UI.PrimaryKey.Dialog.msgRemoveColumnFromPrimaryKey';
            } else {
                // Removing a column of PK
                // Display popup : Modification of PK
                titleKey = 'UI.PrimaryKey.Dialog.ttlPrimaryKeyDelete';
                messageKey = 'UI.PrimaryKey.Dialog.msgPrimaryKeyDelete';
            }
            const confirmed = await this.dxyModalService.confirm({
                titleKey,
                messageKey,
                type: DialogType.YesNo,
            });
            if (!confirmed) {
                return false;
            }
        }

        const param = new RemoveColumnFromPrimaryKeyParameter(
            table.ReferenceId,
            column.ReferenceId
        );
        const result = await this.modelerApiService.removeColumnFromPrimaryKey(
            param
        );
        this.updateMasterDataFromApiForUpdate(result.ModelData);
        return true;
    }

    public getPrimaryKeyGridData(modelId: string) {
        const pks = this.getPrimaryKeys(this.getModelById(modelId));
        return CollectionsHelper.flattenGroups(pks, (pk) =>
            this.createPrimaryKeyGridData(pk)
        );
    }
    private getPrimaryKeys(model: Model) {
        const tables = this.getTables(model, (t) => this.hasTechnicalPk(t));
        return tables.map((t) => this.getTablePk(t));
    }
    private createPrimaryKeyGridData(primaryKey: PrimaryKey) {
        const pkTable = this.getPkTable(primaryKey);
        return this.getPkColumns(primaryKey).map(
            (pkCol) => new PrimaryKeyGridData(primaryKey, pkTable, pkCol)
        );
    }

    private async ensurePkIsLoaded(table: Table) {
        const isPkLoaded = (orInvalid?: boolean) =>
            (orInvalid && Ref.isVoid(table?.PrimaryKeyRef)) ||
            this.isLoaded(table.PrimaryKeyRef);
        if (!isPkLoaded(true)) {
            this.log('ensurePkIsLoaded-loadModelDataWithTableColumns', table);
            await this.loadModelDataWithTableColumns(
                this.getTableModelId(table),
                table.ReferenceId
            );
            if (!isPkLoaded()) {
                this.warn('load failed', table?.PrimaryKeyRef);
                return false;
            }
        }
        return true;
    }
    private async ensurePkColumnsAreLoaded(table: Table) {
        const arePkColumnsLoaded = () =>
            this.isLoaded(this.getTablePk(table)?.OrderedColumns?.[0]);
        if (!arePkColumnsLoaded()) {
            this.log(
                'ensurePkColumnsAreLoaded-loadModelDataWithTableColumns',
                table
            );
            await this.loadModelDataWithTableColumns(
                this.getTableModelId(table),
                table.ReferenceId
            );
            if (!arePkColumnsLoaded()) {
                this.warn('table.PrimaryKey.OrderedColumns not loaded');
                return false;
            }
        }
        return true;
    }

    private async loadDataForPkModal(tableId: string) {
        this.log('loadDataForPkModal', tableId);
        const modelId = this.getModelIdFromTableId(tableId);
        const param = new ModelerApiParameter(modelId, [tableId]);
        const apiResult = await this.modelerApiService.getDataForPkModal(param);
        this.loadDataFromDtoToMasterData(apiResult, modelId);
    }

    //#endregion - PrimaryKey

    //#region ForeignKey

    public getForeignKeys(model: Model) {
        return CollectionsHelper.flattenGroups(this.getTables(model), (table) =>
            this.getTableFks(table)
        );
    }
    public getForeignKey(model: Model, fkId: string) {
        return (
            fkId &&
            this.getForeignKeys(model).find((fk) => fk.ReferenceId == fkId)
        );
    }
    public getFkChildTableId(fk: ForeignKey) {
        return fk?.ChildTableRef.$id;
    }
    public getFkChildTable(fk: ForeignKey) {
        return this.fromRef(fk?.ChildTableRef);
    }
    public getFkPk(fk: ForeignKey) {
        return this.fromRef(fk?.PrimaryKeyRef);
    }
    public getFkParentTableId(fk: ForeignKey) {
        const pk = this.getFkPk(fk);
        return pk?.TableRef.$id;
    }
    public getFkParentTable(fk: ForeignKey) {
        const pk = this.getFkPk(fk);
        return this.getPkTable(pk);
    }
    public getFkColumns(fk: ForeignKey) {
        return (
            fk?.OrderedChildColumns.map((colRef) => this.fromRef(colRef)) ?? []
        );
    }
    public getFkColumnByIndex(fk: ForeignKey, columnIndex: number) {
        if (!fk) {
            return;
        }
        if (columnIndex > fk.OrderedChildColumns.length) {
            return null;
        }
        return this.getFkColumns(fk)[columnIndex];
    }

    public async getModelForForeignKeyGrid(entityData: EntityItem) {
        this.log('getModelForForeignKeyGrid', entityData);
        const modelId = HddUtil.getModelId(entityData?.HddData);
        if (!modelId) {
            return;
        }
        await this.getModelWithoutTables(modelId);
        return this.loadModelForeignKeys(modelId);
    }
    private async loadModelForeignKeys(modelId: string) {
        const param = new ModelerApiParameter(modelId);
        const modelResult = await this.modelerApiService.getModelFkList(param);
        return this.loadDataFromDtoToMasterData(modelResult, modelId);
    }

    /** opens the *update foreign key* modal */
    public async updateForeignKey(
        model: Model,
        foreignKeyId: string,
        hasFkSettingsModalWriteAccess: boolean,
        forTechnicalView?: boolean
    ) {
        if (!model) {
            this.warn('no model');
            return;
        }
        if (!foreignKeyId) {
            this.warn('no fkId');
            return;
        }
        const foreignKey = this.getForeignKey(model, foreignKeyId);
        if (!foreignKey) {
            this.warn(`fk not found for ${foreignKeyId}`);
            return;
        }
        const parentTableId = this.getFkParentTableId(foreignKey);
        if (foreignKey && !parentTableId) {
            this.warn('parent table not loaded');
        }
        const result = await this.openForeignKeySettingsModal(
            model,
            parentTableId,
            this.getFkChildTableId(foreignKey),
            hasFkSettingsModalWriteAccess,
            foreignKeyId,
            { forTechnicalView }
        );
        if (!result) {
            return;
        }

        this.log('updateForeignKey-modal-result', result);

        if (result.convertForeignKey) {
            const {
                isForeignKeyMandatory,
                foreignKeyTechnicalName,
                foreignKeyDisplayName,
                foreignKeyDescription,
            } = result;
            const foreignKeyMappings =
                result.foreignKeyColumnMappingContainer.getLinks();
            const param = new ConvertForeignKeyParameter(
                parentTableId,
                foreignKeyId,
                isForeignKeyMandatory,
                foreignKeyTechnicalName,
                foreignKeyDisplayName,
                foreignKeyDescription,
                foreignKeyMappings
            );
            const result2 = await this.modelerApiService.convertForeignKey(
                param
            );
            this.updateMasterDataFromApiForUpdate(result2.ModelData);
        } else if (foreignKey.IsFunctionalOnly) {
            const { foreignKeyDisplayName, foreignKeyDescription } = result;
            const param = new UpdateFunctionalForeignKeyParameter(
                foreignKeyId,
                foreignKeyDisplayName,
                foreignKeyDescription
            );
            const result2 =
                await this.modelerApiService.updateFunctionalForeignKey(param);
            this.updateMasterDataFromApiForUpdate(result2.ModelData);
        } else {
            const {
                isForeignKeyMandatory,
                foreignKeyTechnicalName,
                foreignKeyDisplayName,
                foreignKeyDescription,
            } = result;
            const foreignKeyMappings =
                result.foreignKeyColumnMappingContainer.getLinks();
            const param = new UpdateTechnicalForeignKeyParameter(
                parentTableId,
                foreignKeyId,
                isForeignKeyMandatory,
                foreignKeyTechnicalName,
                foreignKeyDisplayName,
                foreignKeyDescription,
                foreignKeyMappings
            );
            const result2 =
                await this.modelerApiService.updateTechnicalForeignKey(param);
            this.updateMasterDataFromApiForUpdate(result2.ModelData);
        }
        return true;
    }

    private async openForeignKeySettingsModal(
        model: Model,
        parentTableId: string,
        childTableId: string,
        hasWriteAccess: boolean,
        foreignKeyId?: string,
        opt?: { forTechnicalView?: boolean }
    ) {
        const parentTable = this.getTable(model, parentTableId);
        let isFunctionalOnly = false;
        if (opt.forTechnicalView != undefined) {
            if (opt.forTechnicalView) {
                await this.ensurePkIsLoaded(parentTable); // needed for parentTable.hasTechnicalPK()
                isFunctionalOnly = !this.hasTechnicalPk(parentTable);
            } else {
                isFunctionalOnly = true;
            }
        }
        if (foreignKeyId) {
            const fk = this.getForeignKey(model, foreignKeyId);
            if (!fk) {
                this.warn(`no fk for id ${foreignKeyId}`);
                return;
            }
            await this.loadDataForFkModal(
                model.ReferenceId,
                foreignKeyId,
                this.getFkChildTableId(fk)
            );
        } else {
            await this.ensurePkColumnsAreLoaded(parentTable);
            await this.loadModelDataWithTableColumns(
                model.ReferenceId,
                childTableId
            );
        }

        const fk = foreignKeyId && this.getForeignKey(model, foreignKeyId);

        return await this.dxyModalService.open<
            DxyForeignKeySettingsModalComponent,
            IForeignKeySettingModalInput,
            IForeignKeySettingsModalOutput
        >({
            componentType: DxyForeignKeySettingsModalComponent,
            size: ModalSize.Large,
            data: {
                parentTable: this.getTable(model, parentTableId),
                childTable: this.getTable(model, childTableId),
                hasWriteAccess,
                isFunctionalOnly:
                    (!fk && isFunctionalOnly) || fk?.IsFunctionalOnly,
                currentFk: fk,
            },
        });
    }
    private async loadDataForFkModal(
        modelId: string,
        fkId: string,
        fkTableId: string
    ) {
        this.log('loadDataForFkModal', modelId, fkId, fkTableId);
        const param = new ModelerApiParameter(modelId, [fkTableId], [fkId]);
        const modelResult = await this.modelerApiService.getDataForFkModal(
            param
        );
        return this.loadDataFromDtoToMasterData(modelResult, modelId);
    }

    /** pops up a confirmation modal if fk is technical */
    public async deleteForeignKey(foreignKey: ForeignKey) {
        const done = foreignKey.IsFunctionalOnly
            ? await this.deleteFunctionalForeignKey(foreignKey)
            : await this.deleteTechnicalForeignKey(foreignKey);
        if (done) {
            this.fkEvent.next({ action: 'delete', fk: foreignKey });
        }
        return done;
    }
    private async deleteFunctionalForeignKey(foreignKey: ForeignKey) {
        const parentTableId = this.getFkParentTableId(foreignKey);
        await this.callDeleteForeignKeyApi(
            foreignKey.ReferenceId,
            parentTableId,
            false
        );
        return true;
    }
    private async deleteTechnicalForeignKey(foreignKey: ForeignKey) {
        const result = await this.dxyModalService.open<
            DxyForeignKeyDeleteModalComponent,
            void,
            IForeignKeyDeleteModalResult
        >({
            componentType: DxyForeignKeyDeleteModalComponent,
        });
        if (!result) {
            return false;
        }
        const parentTableId = this.getFkParentTableId(foreignKey);
        await this.callDeleteForeignKeyApi(
            foreignKey.ReferenceId,
            parentTableId,
            result.isKeepFunctionalForeignKey
        );
        return true;
    }
    private async callDeleteForeignKeyApi(
        foreignKeyId: string,
        parentTableId: string,
        isKeepFunctionalForeignKey: boolean
    ) {
        const param = new DeleteForeignKeyParameter(
            foreignKeyId,
            parentTableId,
            isKeepFunctionalForeignKey
        );
        const result = await this.modelerApiService.deleteForeignKey(param);
        this.updateMasterDataFromApiForUpdate(result.ModelData);
    }

    public getDefaultFunctionalForeignKeyName(
        parentTable: Table,
        childTable: Table
    ) {
        return `Ref_${parentTable.DisplayName}_${childTable.DisplayName}`;
    }
    public getDefaultTechnicalFkName(parentTable: Table, childTable: Table) {
        return `FK_${this.getTablePk(parentTable).TechnicalName}_${
            childTable.TechnicalName
        }`;
    }

    public getFkColumnMapping(
        primaryKey: PrimaryKey,
        foreignKeyTable: Table,
        foreignKey?: ForeignKey
    ) {
        return (
            this.getPkColumns(primaryKey).map((primaryKeyColumn, index) =>
                this.createForeignKeyColumnMapping(
                    primaryKeyColumn,
                    foreignKeyTable,
                    foreignKey?.ReferenceId ?? null,
                    this.getFkColumnByIndex(foreignKey, index) ?? null
                )
            ) ?? []
        );
    }
    private createForeignKeyColumnMapping(
        primaryKeyColumn: Column,
        foreignKeyTable: Table,
        foreignKeyId: string,
        foreignKeyColumn: Column = null
    ) {
        this.debug &&
            this.log('createForeignKeyColumnMapping', {
                primaryKeyColumn,
                foreignKeyTable,
                foreignKeyId,
                foreignKeyColumn,
            });
        const primaryKeyColumnType = this.getColumnDataType(primaryKeyColumn);
        if (primaryKeyColumnType == undefined) {
            this.warn('primaryKeyColumn has no type', primaryKeyColumn);
        }
        return new ForeignKeyColumnMapping(
            primaryKeyColumn,
            primaryKeyColumnType,
            foreignKeyTable,
            foreignKeyId,
            foreignKeyColumn,
            (newName, table, currentColumn) =>
                this.enforceColumnUniqueness(
                    true,
                    newName,
                    table,
                    currentColumn
                ).replace(/\s/g, '_')
        );
    }
    private enforceColumnUniqueness(
        isTechnical: boolean,
        newName: string,
        table: Table,
        column: Column
    ) {
        const propertyName = isTechnical
            ? ServerConstants.PropertyName.TechnicalName
            : ServerConstants.PropertyName.DisplayName;
        const separator = isTechnical ? '_' : ' ';
        const startName = newName,
            columns = this.getTableColumns(table);
        let unique = false,
            index = 1;
        while (!unique) {
            unique = true;
            columns.forEach((col) => {
                if (column !== col && col[propertyName] === newName) {
                    newName = `${startName}${separator}${index.toString()}`;
                    index++;
                    unique = false;
                }
            });
        }
        return newName;
    }

    public getForeignKeyGridData(model: Model) {
        const fks = this.getForeignKeys(model);
        const result = CollectionsHelper.flattenGroups(fks, (fk) =>
            this.createForeignKeyGridData(fk)
        );
        this.log('getForeignKeyGridData', fks, result);
        return result;
    }
    private createForeignKeyGridData(foreignKey: ForeignKey) {
        const result = foreignKey.IsFunctionalOnly
            ? this.createFunctionalForeignKeyGridData(foreignKey)
            : this.createTechnicalForeignKeyGridData(foreignKey);
        this.log('createForeignKeyGridData', foreignKey, result);
        return result;
    }
    private createFunctionalForeignKeyGridData(foreignKey: ForeignKey) {
        return [
            new ForeignKeyGridData(
                foreignKey,
                null,
                this.getFkParentTable(foreignKey),
                null,
                this.getFkChildTable(foreignKey),
                null
            ),
        ];
    }
    private createTechnicalForeignKeyGridData(foreignKey: ForeignKey) {
        const fkColumns = this.getFkColumns(foreignKey);
        if (!fkColumns?.length) {
            return [];
        }

        const primaryKey = this.getFkPk(foreignKey);
        const primaryKeyTable = this.getPkTable(primaryKey);
        const primaryKeyColumns = this.getPkColumns(primaryKey);

        return fkColumns.map((foreignKeyColumn, index) => {
            const foreignKeyTable = this.getColumnTable(foreignKeyColumn);
            return new ForeignKeyGridData(
                foreignKey,
                primaryKey,
                primaryKeyTable,
                primaryKeyColumns[index],
                foreignKeyTable,
                foreignKeyColumn
            );
        });
    }
    public async createForeignKey(
        model: Model,
        parentTableId: string,
        childTableId: string,
        isTechnicalView: boolean
    ) {
        const modalResult = await this.openForeignKeySettingsModal(
            model,
            parentTableId,
            childTableId,
            true,
            null,
            { forTechnicalView: isTechnicalView }
        );
        if (!modalResult) {
            return;
        }

        this.log('createForeignKey-modal-result', modalResult);

        let res: ModelerApiResult;
        if (
            (isTechnicalView || modalResult.convertForeignKey) &&
            this.hasTechnicalPk(this.getTable(model, parentTableId))
        ) {
            res = await this.modelerApiService.createTechnicalForeignKey(
                new CreateTechnicalForeignKeyParameter(
                    parentTableId,
                    childTableId,
                    modalResult.isForeignKeyMandatory,
                    modalResult.foreignKeyTechnicalName,
                    modalResult.foreignKeyDisplayName,
                    modalResult.foreignKeyDescription,
                    modalResult.foreignKeyColumnMappingContainer.getLinks()
                )
            );
        } else {
            res = await this.modelerApiService.createFunctionalForeignKey(
                new CreateFunctionalForeignKeyParameter(
                    parentTableId,
                    childTableId,
                    modalResult.foreignKeyDisplayName,
                    modalResult.foreignKeyDescription
                )
            );
        }
        this.updateMasterDataFromApiForUpdate(res.ModelData);
        return res.ModelData?.ForeignKeys[0];
    }

    //#endregion - ForeignKey

    //#region data-types

    public setSystemDataTypeSettings(systemDataTypeSettings: DataTypeSettings) {
        this.systemTypeSettings = systemDataTypeSettings;
    }
    public setClientTypeSettings(clientTypeSettings: DataTypeSettings) {
        this.clientTypeSettings = clientTypeSettings;
    }

    public getAllDataTypeMappings() {
        return CollectionsHelper.orderBy(
            this.getSystemAndClientDataTypeMappings(),
            (o) => o.DisplayName
        );
    }
    private getSystemAndClientDataTypeMappings() {
        return this.fromRefs([
            ...this.systemTypeSettings.Mappings,
            ...this.clientTypeSettings.Mappings,
        ]);
    }

    // from modeler.routes#StateName.ClientAdminDataDefinitionLanguage
    public getDataTypeMappingById(
        dataTypeMappingId: string,
        useFirstAsDefault = false
    ) {
        const dtms = this.getSystemAndClientDataTypeMappings();
        const found = dtms.find((dtm) => dtm.ReferenceId === dataTypeMappingId);
        const res = found ?? (useFirstAsDefault ? dtms[0] : undefined);
        this.log('getDataTypeMappingById', dataTypeMappingId, res, dtms);
        return res;
    }

    public getDataTypeGridData(modelSettings: ModelSettings) {
        return this.getAllDataTypesForModel(modelSettings).map(
            (dt) =>
                new DataTypeGridItem(dt, () =>
                    this.isExcludedDataType(modelSettings, dt)
                )
        );
    }
    private getAllDataTypesForModel(modelSettings: ModelSettings) {
        return CollectionsHelper.orderBy(
            this.fromRefs([
                ...this.systemTypeSettings.Types,
                ...modelSettings.ModelTypes,
            ]),
            (o) => o.DisplayName
        );
    }

    public getDataTypeMappingGridData(modelSettings: ModelSettings) {
        if (!modelSettings) {
            return [];
        }
        const refs = [
            ...this.fromRef(modelSettings.CurrentMappingRef)?.Items,
            ...modelSettings.ModelMappingItems,
        ];
        return this.getValidMappingItems(refs).map(
            (dtmi) =>
                new DataTypeMappingGridItem(
                    dtmi,
                    this.fromRef(dtmi.DataTypeRef)
                )
        );
    }
    private getValidMappingItems(dtmiRefs: Ref<DataTypeMappingItem>[]) {
        const dtmis = this.fromRefs(dtmiRefs);
        const validDtmis = dtmis.filter((dtmi) =>
            this.isLoaded(dtmi.DataTypeRef, true)
        );
        if (dtmis.length > validDtmis.length) {
            const emptyRefDtmis = dtmis.filter((dtmi) =>
                Ref.isVoid(dtmi.DataTypeRef)
            );
            const notLoadedRefDtmis = dtmis.filter((dtmi) =>
                this.masterDataService.isNotLoaded(dtmi.DataTypeRef)
            );
            this.warn('invalid dtmis', { emptyRefDtmis, notLoadedRefDtmis });
        }
        return validDtmis;
    }

    public hasCurrentMapping(modelSettings: ModelSettings) {
        return !Ref.isVoid(modelSettings?.CurrentMappingRef);
    }
    public getCurrentMapping(modelSettings: ModelSettings) {
        return this.fromRef(modelSettings?.CurrentMappingRef);
    }

    public getDataTypeMappingAdminGridData(dtm: DataTypeMapping) {
        return this.getValidMappingItems(dtm?.Items).map(
            (dtmi) =>
                new DataTypeMappingAdminGridItem(
                    dtm,
                    dtmi,
                    this.fromRef(dtmi.DataTypeRef)
                )
        );
    }

    public async changeDataTypeMappingForModel(
        modelSettings: ModelSettings,
        selectedMapping: DataTypeMapping
    ) {
        const parentPropName: keyof ModelSettings = 'CurrentMappingRef';
        const sdi = SaveDataParamItem.forAddDataReference(
            selectedMapping,
            modelSettings,
            parentPropName
        );
        const sdis = modelSettings.ModelTypes.filter(
            (dtRef) =>
                !this.getModelSettingsMappingItemRefByDataType(
                    modelSettings,
                    dtRef
                )
        ).map((dtRef) => {
            const dtmiId = this.newReferenceIdFromContext(modelSettings);
            const dtmi = this.newDataTypeMappingItem(
                dtmiId,
                this.fromRef(dtRef)
            );
            const parentPropName: keyof ModelSettings = 'ModelMappingItems';
            return this.masterDataService.forAddNewDataManualParent(
                modelSettings,
                dtmi,
                parentPropName
            );
        });
        const { success } = await this.masterDataService.saveData(
            'changeDataTypeMappingForModel',
            sdi,
            ...sdis
        );
        if (!success) {
            return false;
        }
        modelSettings.CurrentMappingRef = Ref.from(selectedMapping);
        this.dataTypeMappingEvent.next({
            event: 'change',
            data: selectedMapping,
        });
    }
    public getModelSettingsMappingItemRefByDataType(
        modelSettings: ModelSettings,
        dataTypeIdr: IIdr
    ) {
        if (!dataTypeIdr || !modelSettings) {
            return;
        }
        return modelSettings.ModelMappingItems?.find((r) =>
            Ref.areSame(this.fromRef(r)?.DataTypeRef, dataTypeIdr)
        );
    }
    private newDataTypeMappingItem(id: string, dataType: DataType) {
        const res = new DataTypeMappingItem(id);
        res.DisplayName = dataType.DisplayName;
        res.DataTypeRef = Ref.from(dataType);
        return res;
    }

    public async deleteDataTypeMappingForModel(
        modelSettings: ModelSettings,
        dataTypeMapping: DataTypeMapping
    ) {
        const parentPropName: keyof ModelSettings = 'CurrentMappingRef';
        const sdi = SaveDataParamItem.forDeleteDataReference(
            dataTypeMapping,
            modelSettings,
            parentPropName
        );
        return (
            await this.masterDataService.saveData(
                'deleteDataTypeMappingForModel',
                sdi
            )
        )?.success;
    }

    public async addDataTypeForModel(modelSettings: ModelSettings) {
        const dtId = this.newReferenceIdFromContext(modelSettings);
        const dt = DataType.forModelSettings(dtId);
        const dtmiId = this.newReferenceIdFromContext(modelSettings);
        const dtmi = this.newDataTypeMappingItem(dtmiId, dt);
        const parentPropName1: keyof ModelSettings = 'ModelTypes';

        const sdi1 = this.masterDataService.forAddNewDataManualParent(
            modelSettings,
            dt,
            parentPropName1
        );
        const parentPropName2: keyof ModelSettings = 'ModelMappingItems';
        const sdi2 = this.masterDataService.forAddNewDataManualParent(
            modelSettings,
            dtmi,
            parentPropName2
        );
        const { success } = await this.masterDataService.saveData(
            'addDataTypeForModel',
            sdi1,
            sdi2
        );
        if (!success) {
            return false;
        }
        const { ModelTypes, ModelMappingItems } = modelSettings;
        Ref.addIfNotExists(ModelTypes, dt);
        Ref.addIfNotExists(ModelMappingItems, dtmi);
        this.dataTypeEvent.next({ event: 'add', data: dt });
        return true;
    }

    public async deleteDataTypeForModel(
        modelSettings: ModelSettings,
        dataType: DataType
    ) {
        const dtmiRef = this.getModelSettingsMappingItemRefByDataType(
            modelSettings,
            dataType
        );
        if (!dtmiRef) {
            return;
        }
        const parentPropName: keyof ModelSettings = 'ModelMappingItems';
        const sdi1 = SaveDataParamItem.forDeleteData(
            dtmiRef,
            modelSettings,
            parentPropName,
            false
        );
        const sdi2 = SaveDataParamItem.forDeleteData(
            dataType,
            modelSettings,
            'ModelTypes',
            false
        );
        const { success } = await this.masterDataService.saveData(
            'deleteDataTypeForModel',
            sdi1,
            sdi2
        );
        if (!success) {
            return false;
        }
        const { ModelTypes, ModelMappingItems } = modelSettings;
        Ref.remove(ModelTypes, dataType);
        Ref.remove(ModelMappingItems, dtmiRef);
        this.dataTypeEvent.next({ event: 'delete', data: dataType });
        return true;
    }

    public async updateDataTypeAvailability(
        dataType: DataType,
        modelSettings: ModelSettings,
        isAvailable: boolean
    ) {
        this.log(
            'updateDataTypeAvailability',
            dataType,
            isAvailable,
            modelSettings
        );
        const parentPropName: keyof ModelSettings = 'ExcludedSystemTypes';
        const sdi = isAvailable // Back-end logic is add/remove from ExcludedSystemTypes, so available => delete, not available => add
            ? SaveDataParamItem.forAddDataReference(
                  dataType,
                  modelSettings,
                  parentPropName
              )
            : SaveDataParamItem.forDeleteData(
                  dataType,
                  modelSettings,
                  parentPropName,
                  true
              );
        const { success } = await this.masterDataService.saveData(
            'updateDataTypeAvailability',
            sdi
        );
        if (!success) {
            return false;
        }
        const { ExcludedSystemTypes } = modelSettings;
        if (isAvailable) {
            Ref.remove(ExcludedSystemTypes, dataType);
        } else {
            Ref.addIfNotExists(ExcludedSystemTypes, dataType);
        }
        return true;
    }

    public async updateDataType(
        dataType: DataType,
        propertyName: string,
        newPropertyValue: any
    ) {
        const sdi = SaveDataParamItem.forUpdateProperty(
            dataType,
            propertyName,
            newPropertyValue
        );
        return (await this.masterDataService.saveData('updateDataType', sdi))
            ?.success;
    }

    public async updateDataTypeMappingItem(
        dtmi: DataTypeMappingItem,
        propertyName: string,
        newPropertyValue: any
    ) {
        this.log('updateDataTypeMappingItem', dtmi);
        const sdi = SaveDataParamItem.forUpdateProperty(
            dtmi,
            propertyName,
            newPropertyValue
        );
        return (
            await this.masterDataService.saveData(
                'updateDataTypeMappingItem',
                sdi
            )
        )?.success;
    }

    public async copyDataTypeMapping(dtm: DataTypeMapping) {
        if (!dtm) {
            return;
        }
        const parent = this.clientTypeSettings;
        const contextId = getContextId(parent.ReferenceId);
        const parentRef = Ref.from<DataTypeSettings>(parent);
        this.log('copyDataTypeMapping', { parent, contextId, parentRef });
        const newDtmis = this.fromRefs(dtm?.Items).map((it) =>
            DataTypeMappingItem.fromOther(it, contextId)
        );
        const newDtm = DataTypeMapping.fromItems(
            newDtmis,
            contextId,
            parentRef
        );
        const sdi = this.masterDataService.forAddNewData(parent, newDtm);
        const sdis = newDtmis.map((dtmi) =>
            this.masterDataService.forAddNewDataManualParent(
                newDtm,
                dtmi,
                'Items'
            )
        );
        const { success } = await this.masterDataService.saveData(
            'copyDataTypeMapping',
            sdi,
            ...sdis
        );
        if (success) {
            Ref.addIfNotExists(parent.Mappings, newDtm);
        }
        this.log('copyDataTypeMapping-result', success, newDtm, newDtmis);
        return newDtm;
    }

    public async deleteDataTypeMapping(dtm: DataTypeMapping) {
        const sdi = SaveDataParamItem.forDeleteData(
            dtm,
            this.clientTypeSettings,
            'Mappings',
            false
        );
        return (
            await this.masterDataService.saveData('deleteDataTypeMapping', sdi)
        )?.success;
    }

    public async updateDataTypeMapping(
        dtm: DataTypeMapping,
        propertyName: string,
        newPropertyValue: any
    ) {
        const sdi = SaveDataParamItem.forUpdateProperty(
            dtm,
            propertyName,
            newPropertyValue
        );
        return (
            await this.masterDataService.saveData('updateDataTypeMapping', sdi)
        )?.success;
    }

    private ensureCurrentMappingIsSet(modelsettings: ModelSettings) {
        if (!modelsettings) {
            return;
        }
        const mappingRef = modelsettings.CurrentMappingRef;
        if (this.masterDataService.isNotLoaded(mappingRef)) {
            const dtms = this.getSystemAndClientDataTypeMappings();
            const dtm = dtms.find((dtm) => dtm.ReferenceId === mappingRef.$id);
            modelsettings.CurrentMappingRef = Ref.from(dtm);
        }
    }

    public async getModelDataTypesFromEntity(entity: IHasHddData) {
        const entityHd = entity?.HddData;
        if (!entityHd) {
            this.warn('no hdd', entity);
            return [];
        }
        const modelIdr =
            entityHd.ServerType == ServerType.Model
                ? entityHd
                : HddUtil.getModelHdd(entityHd);
        return this.getModelDataTypes(modelIdr);
    }
    public async getModelDataTypes(modelIdr: IEntityIdentifier) {
        if (
            modelIdr?.ServerType != ServerType.Model &&
            modelIdr?.ServerType != ServerType.Container
        ) {
            this.warn('not a model identifier', modelIdr);
            return [];
        }
        const parameter = GetModelDataTypesParameter.fromModelIdr(modelIdr);
        const result = await this.modelerApiService.getModelDataTypes(
            parameter
        );
        return result.DataTypes ?? [];
    }
    public async preDeleteDataTypeMapping(technologyId: string) {
        const parameter = new PreDeleteDataTypeMappingParameter(technologyId);
        return this.modelerApiService.preDeleteDataTypeMapping(parameter);
    }

    //#endregion

    //#region DDL

    public async generateDdlScript(
        mappingId: string,
        serverType: ServerType,
        itemId: string,
        params: IGenerateScriptOptions
    ): Promise<GenerateScriptResult> {
        const gsp = new GenerateScriptParameter();
        gsp.DataTypeMappingReferenceId = mappingId;
        gsp.Items = [new GenerateScriptItem(itemId, ServerType[serverType])];
        gsp.QuoteOption = params.quote;
        gsp.CaseOption = params.case;
        gsp.LineEndingOption = params.lineEnding;
        gsp.DropBeforeCreate = params.dropBeforeCreate;
        gsp.PrefixWithSchemaName = params.prefixWithSchemaName;
        return await this.modelerApiService.generateScript(gsp);
    }

    public async generateDdlScriptForModel(modelId: string) {
        const model = await this.getModelWithoutTables(modelId);
        const modelSettings = this.getSettings(model);
        this.ensureCurrentMappingIsSet(modelSettings);
        await this.openDdlScriptGenerationSettings(
            model.ReferenceId,
            ServerType.Model,
            modelSettings.CurrentMappingRef.$id
        );
    }

    public async generateDdlScriptForTable(tableId: string, modelId: string) {
        const model = await this.getModelWithoutTables(modelId);
        const modelSettings = this.getSettings(model);
        this.ensureCurrentMappingIsSet(modelSettings);
        await this.openDdlScriptGenerationSettings(
            tableId,
            ServerType.Table,
            modelSettings.CurrentMappingRef.$id
        );
    }

    public async openDdlScriptGenerationSettings(
        itemId: string,
        serverType: ServerType,
        mappingId: string
    ) {
        if (!mappingId) {
            await this.dxyModalService.inform({
                titleKey: 'UI.Ddl.Service.titleMissingMapping',
                messageKey: 'UI.Ddl.Service.msgMissingMapping',
            });
            return;
        }

        const result = await this.dxyModalService.open<
            DxyScriptGenerationSettingsModalComponent,
            IGenerationSettingsModalInput,
            GenerateScriptResult
        >({
            componentType: DxyScriptGenerationSettingsModalComponent,
            data: { itemId, serverType, mappingId },
        });
        if (!result) {
            return;
        }

        await this.dxyModalService.open<
            DxyScriptGenerationResultModalComponent,
            IGenerationResultModalInput,
            void
        >({
            componentType: DxyScriptGenerationResultModalComponent,
            data: { result },
        });
    }

    public translateDdlScriptResultItems(items: IScriptResultItem[]) {
        const results: IScriptResult[] = [];
        let hasMessages = false;
        items.forEach((item) => {
            const messageGroups: IGroupsByType = {};
            results.push({ script: item.Script, messageGroups });
            item.Messages.forEach((msg) => {
                hasMessages = true;
                const type = msg.IsError ? 'danger' : 'warning';
                const group = (messageGroups[type] ??= {});
                (group[msg.MessageContext] ??= []).push(msg);
                this.translateDdlScriptMessage(msg);
            });
        });
        return { results, hasMessages };
    }
    private translateDdlScriptMessage(msg: IScriptMessage) {
        const params: { [key: string]: string } = {};
        msg.Parameters?.filter((p) => p).forEach(
            (p, i) => (params[`p${i}`] = p)
        );
        msg.translatedMessage = this.translate.instant(
            `UI.Ddl.scriptGenerationResultModal.messages.${msg.MessageCode}`,
            params
        );
    }

    //#endregion

    //#region Master Data management

    private isLoaded(ref: Ref, andValid = false) {
        return andValid
            ? this.masterDataService.isLoadedAndValid(ref)
            : this.masterDataService.isMasterObjectLoaded(ref);
    }
    private fromRef<T extends BaseMasterData>(r: Ref<T>) {
        return this.masterDataService.getMasterObject(r);
    }
    private fromRefs<T extends BaseMasterData>(
        refs: Ref<T>[],
        dontFilterNulls = false
    ) {
        return this.masterDataService.getMasterObjects(refs, dontFilterNulls);
    }

    private loadDataFromDtoToMasterData(
        apiResult: ModelerApiResult,
        modelId: string
    ) {
        this.updateMasterDataFromApi(apiResult.ModelData, false);
        return this.fromRef(new Ref<Model>(ServerType.Model, modelId));
    }
    private updateMasterDataFromApiForUpdate(modelerData: ModelerData) {
        this.updateMasterDataFromApi(modelerData, true);
    }
    private updateMasterDataFromApi(md: ModelerData, isUpdate: boolean) {
        this.log('updateMasterDataFromApi', md, isUpdate);
        const svc = this.masterDataService;
        const debug = false; //this.debug
        [md.Tables, md.Columns, md.PrimaryKeys, md.ForeignKeys].forEach(
            (list) =>
                list.forEach((o: BaseMasterData) =>
                    svc.updateMasterDataWithObject(o, isUpdate, debug)
                )
        );
    }

    //#endregion
}
