<img src="/images/multilingual-source-language.svg" alt="Source language" />

<p>
    <strong translate>
        Multilingual.Administration.SourceLanguageStep.question
    </strong>
    <br />
    <span
        [innerHTML]="
            'Multilingual.Administration.SourceLanguageStep.hint' | translate
        "
    >
    </span>
</p>
<form [formGroup]="form">
    <dxy-field-select
        formControlName="language"
        labelKey="Multilingual.Administration.sourceLanguage"
        [options]="languages"
        [adapter]="languageAdapter"
        [mandatory]="true"
        [hintBeforeControl]="true"
    ></dxy-field-select>
</form>
