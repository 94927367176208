/**
 * Populate wizard-layout's context with properties & methods
 * to define the wizard behavior and content.
 */
export interface WizardContext {
    title: () => string;

    getCheckTooltip?: () => string;
    getLeftFooterCustomText?: () => string;

    checkMessageData?: () => CheckMessageData;
    getCheckIcon?: () => string;
    getTestButtonIcon?: () => string;
    getTestBtnTooltip?: () => string;
    getTestErrorMessage?: () => string;

    isNextVisible: () => boolean;
    isNextDisabled: () => boolean;
    getNextButtonText: () => string;
    getNextButtonDataTrackerId?: () => string;
    getNextButtonTooltip?: () => string;
    getFeatureCodeNext?: () => string;

    checkboxMessage?: () => string;
    checkboxValue?: () => boolean;

    isCheckBtnDisabled?: () => boolean;
    isCheckBtnVisible: () => boolean;
    isTestDisabled?: () => boolean;
    isTestVisible: () => boolean;
    isSaveDisabled: () => boolean;
    isSaveVisible: () => boolean;
    isRestartVisible: () => boolean;
    isRestartDisabled?: () => boolean;
    isFooterVisible: () => boolean;
    isPreviousVisible: () => boolean;
    isLoadingVisible: () => boolean;
    isCheckboxVisible: () => boolean;
    isLeftFooterCustomTextVisible: () => boolean;

    next: () => void;
    test?: () => void;
    save: () => void;
    previous: () => void;
    close: () => void;
    restart?: () => void;
    toggleCheckbox?: (value: boolean) => void;
    checkBtnClick?: () => void;
}

export class CheckMessageData {
    public static equals(a: CheckMessageData, b: CheckMessageData) {
        return (
            a === b ||
            (!!(a && b) &&
                a.isExpanded == b.isExpanded &&
                a.firstLineText == b.firstLineText &&
                a.secondLineText == b.secondLineText &&
                a.errorLinkData?.errorMessage ==
                    b.errorLinkData?.errorMessage &&
                a.errorLinkData?.redirectionViewIdentifier ==
                    b.errorLinkData?.redirectionViewIdentifier)
        );
    }

    isExpanded: boolean;
    firstLineText: string;
    secondLineText: string;
    errorLinkData?: ICheckMessageErrorLink;

    constructor(
        isExpanded?: boolean,
        firstLineText?: string,
        secondLineText?: string,
        errorLinkData?: ICheckMessageErrorLink
    ) {
        this.isExpanded = isExpanded;
        this.firstLineText = firstLineText;
        this.secondLineText = secondLineText;
        this.errorLinkData = errorLinkData;
    }
}

export interface ICheckMessageErrorLink {
    /** When not empty, append the message on the second line after the secondLineText */
    errorMessage?: string;
    /** When not empty, calls emitRedirectToPreImportTab() method from import-wizard.service on click on errorMessage */
    redirectionViewIdentifier?: string;
}
