<div
    class="logElement-item dg_animate animation-fade"
    [innerHTML]="innerHTML"
></div>
<div class="data-links">
    <ng-container #dataLinks></ng-container>
</div>

<ng-template #dataLinksTemplate>
    <app-entity-card-cell
        *ngIf="linkData?.childHData"
        (click)="onClickEntityLink(linkData.childHData)"
        class="nav-link-child"
        [hierarchicalData]="linkData.childHData"
        [isMiniCard]="true"
        [hideBreadcrumb]="true"
        [noLabelNavLink]="true"
    ></app-entity-card-cell>
    <app-entity-card-cell
        *ngIf="linkData?.hdata"
        (click)="onClickEntityLink(linkData.hdata)"
        class="nav-link-parent"
        [hierarchicalData]="linkData.hdata"
        [isMiniCard]="true"
        [hideBreadcrumb]="true"
        [noLabelNavLink]="true"
    ></app-entity-card-cell>
</ng-template>
