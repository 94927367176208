import {
    EntityCreatedEvent,
    EntityDeletedEvent,
    EntityMovedEvent,
} from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { IEventTranslationInputs } from '../../../interfaces/IEventTranslationInputs';
import { getEntityActivityEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [EntityCreatedEvent, EntityDeletedEvent, EntityMovedEvent];
type TEntityActivityEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to get entity activity events translation inputs
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class EntityActivityEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TEntityActivityEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        return getEntityActivityEventTranslationInputs(this.event);
    }
}
