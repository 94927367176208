import { DgModuleDefinition } from '../dg-module-definition';
import { DgModuleName } from '../dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export class CatalogDgModule extends DgModuleDefinition {
    public name: DgModuleName = 'Catalog';
    public translateKey = 'DgServerTypes.DataGalaxyModule.Catalog';
    public glyphClass = 'glyph-catalog';
    public colorGlyphClass = 'technical-static-color';
    public graphicalColor = GraphicalColor.Blue;
    public position = 2;
    public categoryKey = 'dataKnowledgeCatalog';
}

export default new CatalogDgModule();
