import { roundToMultiple } from '@datagalaxy/core-util';
import { IShift, ISizeParams, IXY, IXYRO } from './2d.types';

type TVect2 = Vect2;

/** 2-dimensions vector object and utility.
 * For screen usage (y < 0 is considered Up for angles computation) */
export class Vect2 {
    //#region static

    //#archi-jest-DomPoint
    //private static readonly _domPoint = new DOMPoint()
    private static readonly _u = new Vect2();
    private static readonly _v = new Vect2();
    private static readonly _w = new Vect2();

    public static readonly O: Readonly<Vect2> = new Vect2(0, 0);
    public static readonly OX: Readonly<Vect2> = new Vect2(1, 0);
    public static readonly OY: Readonly<Vect2> = new Vect2(0, 1);

    public static toString(v: IXYRO, noDecimals?: boolean) {
        return v
            ? noDecimals
                ? `{ x: ${v.x | 0}, y: ${v.y | 0} }`
                : `{ x: ${v.x}, y: ${v.y} }`
            : 'null';
    }
    public static toJSON(v: IXYRO) {
        return v ? { x: v.x, y: v.y } : null;
    }

    public static from(v: IXYRO) {
        return new Vect2(v.x, v.y);
    }

    public static fromPoints(p1: IXYRO, p2: IXYRO) {
        return new Vect2(p2.x - p1.x, p2.y - p1.y);
    }

    public static angle(u: TVect2, v: TVect2, signed?: boolean) {
        let s: number;
        if (signed) {
            s = u.x * -v.y - -u.y * v.x;
        }
        const sign = s < 0 ? -1 : 1;
        return sign * Math.acos(u.dot(v) / (u.length() * v.length()));
    }

    /** Given the 2 points of a segment, returns the rotation angle of the segment, from the OX axis, in radians, on [0,2PI[ */
    public static segmentAngle(a: IXYRO, b: IXYRO): number {
        const angleRadians = Math.atan2(b.y - a.y, b.x - a.x);
        return (angleRadians * 180) / Math.PI;
    }

    public static same(a: Partial<IXYRO>, b: Partial<IXYRO>) {
        return a === b || (a && b && a.x == b.x && a.y == b.y);
    }

    public static add<T extends Partial<IXY> = Vect2>(
        a: Partial<IXYRO>,
        b?: Partial<IXYRO>,
        result = new Vect2() as IXY as T
    ) {
        result.x = (a?.x || 0) + (b?.x || 0);
        result.y = (a?.y || 0) + (b?.y || 0);
        return result;
    }

    public static sub<T extends Partial<IXY> = Vect2>(
        a: Partial<IXYRO>,
        b: Partial<IXYRO>,
        result = new Vect2() as IXY as T
    ) {
        result.x = (a?.x || 0) - (b?.x || 0);
        result.y = (a?.y || 0) - (b?.y || 0);
        return result;
    }

    public static midPoint<T extends Partial<IXY> = Vect2>(
        a: IXYRO,
        b: IXYRO,
        dx = 0,
        dy = 0,
        result: T = new Vect2() as IXY as T
    ) {
        (result.x = (a.x + b.x) / 2 + dx), (result.y = (a.y + b.y) / 2 + dy);
        return result;
    }

    /** For screen (y < 0 is Up) */
    public static angleFromHorizontal(src: IXYRO, tgt: IXYRO) {
        return Math.atan2(tgt.y - src.y, tgt.x - src.x);
    }

    /** Given 2 points, returns the angle (in radians) and the center of the rotation to apply
     * for a text displayed at mid distance of those points to be readable from left to right */
    public static getRotationLTR(p: IXYRO, q: IXYRO) {
        const a = Vect2.angleFromHorizontal(p, q);
        return {
            /** angle (in radians) */
            a: a + (Math.abs(a) >= Math.PI / 2 ? Math.sign(a) * Math.PI : 0),
            /** center */
            c: Vect2.midPoint(p, q),
        };
    }

    /** triplet orientation: 0 colinear; For screen: < 0 clockwise, > 0 counterclockwise */
    public static tripletOrientation(a: IXYRO, b: IXYRO, c: IXYRO) {
        return (b.y - a.y) * (c.x - b.x) - (b.x - a.x) * (c.y - b.y);
    }

    public static distance(a: IXYRO, b: IXYRO) {
        const dx = b.x - a.x;
        const dy = b.y - a.y;
        return Math.sqrt(dx * dx + dy * dy);
    }
    public static distanceSquared(a: IXYRO, b: IXYRO) {
        const dx = b.x - a.x;
        const dy = b.y - a.y;
        return dx * dx + dy * dy;
    }

    public static copy<T extends Partial<IXY> = Vect2>(
        r: IXYRO,
        res = new Vect2() as IXY as T
    ) {
        res.x = r.x;
        res.y = r.y;
        return res;
    }

    public static applyShift<T extends IXY>(v: T, shift: Partial<IShift>) {
        if (!v || !shift) {
            return v;
        }
        v.x += shift.dx || 0;
        v.y += shift.dy || 0;
        return v;
    }

    public static truncateDecimals<T extends Partial<IXY> = Vect2>(
        v: T,
        res = new Vect2() as IXY as T
    ) {
        res.x = v.x | 0;
        res.y = v.y | 0;
        return res;
    }

    /** rounds the given point's coordinates */
    public static round<T extends Partial<IXY> = Vect2>(
        v: IXYRO,
        res = new Vect2() as IXY as T
    ) {
        res.x = Math.round(v.x || 0);
        res.y = Math.round(v.y || 0);
        return res;
    }

    /** rounds the given point's coordinates to a multiple of *cellSize*, which defaults to 1x1) */
    public static roundToMultiple<T extends Partial<IXY> = Vect2>(
        v: IXYRO,
        cellSize?: ISizeParams,
        res = new Vect2() as IXY as T
    ) {
        if (cellSize) {
            res.x = roundToMultiple(v.x, cellSize.width);
            res.y = roundToMultiple(v.y, cellSize.height);
            return res;
        }
        return Vect2.round(v, res);
    }

    //#region segments

    /** Returns the type and coordinates (if any) of the intersection between the given 2 line segments */
    public static intersect<T extends IIntersect2d = IIntersect2d>(
        a0: IXYRO,
        a1: IXYRO,
        b0: IXYRO,
        b1: IXYRO,
        result = {} as IIntersect2d as T
    ) {
        // adapted from: http://www.sunshine2k.de/coding_typescript.html
        const a = Vect2._u.copy(a1).sub(a0);
        const b = Vect2._v.copy(b1).sub(b0);
        if (a.cross(b) == 0) {
            // parallel
            const w = Vect2._w.copy(b0).sub(a0);
            if (a.cross(w) == 0) {
                // overlap
                const sB0 = a.x ? w.x / a.x : w.y / a.y;
                const w2 = Vect2._w.copy(b1).sub(a0);
                const sB1 = a.x ? w2.x / a.x : w2.y / a.y;
                const b0In01 = sB0 >= 0 && sB0 <= 1;
                const b1In01 = sB1 >= 0 && sB1 <= 1;
                result.type =
                    b0In01 && b1In01
                        ? EIntersect2d.coincident_total_overlap
                        : b0In01 || b1In01
                        ? EIntersect2d.coincident_partly_overlap
                        : EIntersect2d.coincident_no_overlap;
            } else {
                result.type = EIntersect2d.true_parallel;
            }
        } else {
            // intersection
            const w = Vect2._w.copy(b0).sub(a0);
            const s = b.cross(w) / b.cross(a);
            Vect2._w.copy(a).multiplyScalar(s).add(a0).copyTo(result);
            const w2 = Vect2._w.copy(a0).sub(b0);
            const t = a.cross(w2) / a.cross(b);
            const sIn01 = s >= 0 && s <= 1;
            const tIn01 = t >= 0 && t <= 1;
            result.type =
                sIn01 && tIn01
                    ? EIntersect2d.intersection_inside_segment
                    : sIn01 || tIn01
                    ? EIntersect2d.intersection_in_one_segment
                    : EIntersect2d.intersection_outside_segment;
        }
        return result;
    }

    //#region orthogonal segment
    public static isH(a: IXYRO, b: IXYRO) {
        return a.x != b.x && a.y == b.y;
    }
    public static isV(a: IXYRO, b: IXYRO) {
        return a.x == b.x && a.y != b.y;
    }
    public static orthogonalSegmentLength(a: IXYRO, b: IXYRO) {
        return b.x == a.x
            ? Math.abs(b.y - a.y)
            : b.y == a.y
            ? Math.abs(b.x - a.x)
            : NaN;
    }
    public static isOrthogonalSegment(a: IXYRO, b: IXYRO) {
        return (
            a && b && ((a.x != b.x && a.y == b.y) || (a.x == b.x && a.y != b.y))
        );
    }
    //#endregion

    //#endregion - segments

    //#region circles

    /** sets the given items coordinates
     * so they are evenly spaced on a circle centered on the given origin */
    public static positionItemsOnCircle<T extends Partial<IXY>>(
        items: T[],
        origin: IXYRO,
        radius: number,
        startAngle = 0
    ) {
        if (!items?.length || !origin) {
            return;
        }
        const separation = (2 * Math.PI) / items.length;
        items.forEach(
            (item, i) =>
                item &&
                Vect2.setOnCircle(
                    item,
                    origin,
                    radius,
                    startAngle + i * separation
                )
        );
    }

    /** sets the given items coordinates
     * in a sunflower-like arrangement
     * around the given origin */
    public static positionItemsAsPhyllotaxis<T extends Partial<IXY>>(
        items: T[],
        origin: IXYRO,
        radius0 = 10
    ) {
        if (!items?.length || !origin) {
            return;
        }
        const initialAngle = Math.PI * (3 - Math.sqrt(5));
        items.forEach(
            (item, i) =>
                item &&
                Vect2.setOnCircle(
                    item,
                    origin,
                    radius0 * Math.sqrt(i),
                    i * initialAngle
                )
        );
    }

    /** sets the given siblings coordinates
     * so they are evenly spaced, alternatively, on both sides of the given item,
     * on a circle centered on the given parent */
    public static positionSiblingsOnArc<T extends IXY>(
        item: T,
        siblings: T[],
        parent: T,
        maxArc: number
    ) {
        if (!item || !parent || !siblings?.length) {
            return;
        }
        const itemAngle = Vect2.angleFromHorizontal(parent, item),
            radius = Vect2.distance(parent, item),
            spacing = maxArc / siblings.length;
        siblings.forEach((sibling, i) => {
            if (!sibling) {
                return;
            }
            const angle = Vect2.alternateOnSidesOfPointOnArc(
                itemAngle,
                spacing,
                i
            );
            Vect2.setOnCircle(sibling, parent, radius, angle);
        });
    }

    /** sets the given children coordinates
     * so they are evenly spaced, alternatively,
     * on a circle centered on the given parent,
     * on the opposite side of the given great-parent */
    public static positionChildrenOnCircle<T extends IXY>(
        parent: T,
        children: T[],
        greatParent: T
    ) {
        if (!parent || !children?.length || !greatParent) {
            return;
        }
        const oppAngle = Vect2.angleFromHorizontal(parent, greatParent),
            radius = Vect2.distance(parent, greatParent),
            spacing = (2 * Math.PI) / (children.length + 1);
        children.forEach((child, i) => {
            if (!child) {
                return;
            }
            const angle = Vect2.alternateOnSidesOfPointOnArc(
                oppAngle,
                spacing,
                i
            );
            Vect2.setOnCircle(child, parent, radius, angle);
        });
    }

    public static alternateOnSidesOfPointOnArc(
        startAngle: number,
        spacingAngle: number,
        index: number
    ) {
        return (
            startAngle +
            (index % 2 ? spacingAngle : -spacingAngle) *
                (1 + Math.floor(index / 2))
        );
    }

    public static setOnCircle<T extends Partial<IXY>>(
        item: T,
        center: IXYRO,
        radius: number,
        angle: number
    ) {
        item.x = center.x + radius * Math.cos(angle);
        item.y = center.y + radius * Math.sin(angle);
    }

    //#endregion - circles

    //#endregion

    //#region instance

    // please keep this compatible with https://github.com/mrdoob/three.js/blob/master/src/math/Vector2.js

    constructor(public x = 0, public y = 0) {}
    //constructor( x = 0, y = 0 ) { super(x, y) }

    clone() {
        return new Vect2(this.x, this.y) as this;
    }
    copy(o: IXYRO) {
        this.x = o.x;
        this.y = o.y;
        return this;
    }
    copyTo<T extends Partial<IXY> = IXY>(res?: T) {
        return Vect2.copy(this, res);
    }
    set(x: number, y: number) {
        (this.x = x || 0), (this.y = y || 0);
        return this;
    }

    add(v: Partial<IXYRO>) {
        return Vect2.add(this, v, this);
    }
    sub(v: Partial<IXYRO>) {
        return Vect2.sub(this, v, this);
    }
    dot(v: IXYRO) {
        return this.x * v.x + this.y * v.y;
    }
    /** also called "perp dot product" */
    cross(v: IXYRO) {
        return this.x * v.y - this.y * v.x;
    }
    multiply(v: IXYRO) {
        this.x *= v.x;
        this.y *= v.y;
        return this;
    }

    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    multiplyScalar(n: number) {
        this.x *= n;
        this.y *= n;
        return this;
    }

    divideScalar(n: number) {
        return this.multiplyScalar(1 / n);
    }

    normalize() {
        return this.divideScalar(this.length() || 1);
    }

    negate() {
        this.x = -this.x;
        this.y = -this.y;
        return this;
    }

    truncateDecimals(truncate?: boolean) {
        if (truncate === undefined || truncate) {
            this.x |= 0;
            this.y |= 0;
        }
        return this;
    }

    same(o: Partial<IXYRO>) {
        return Vect2.same(this, o);
    }

    shift(dx: number, dy: number) {
        this.x += dx || 0;
        this.y += dy || 0;
        return this;
    }

    //#endregion
}

export enum EIntersect2d {
    unknown = 0,
    true_parallel,
    coincident_partly_overlap,
    coincident_total_overlap,
    coincident_no_overlap,
    intersection_outside_segment,
    intersection_in_one_segment,
    intersection_inside_segment,
}
export interface IIntersect2d extends IXY {
    type: EIntersect2d;
}
