import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { DataUtil } from '../../util/DataUtil';
import { SecurityService } from '../../../services/security.service';
import {
    Organization,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';

@Injectable({ providedIn: 'root' })
export class EntitySecurityService extends BaseService {
    constructor(private securityService: SecurityService) {
        super();
    }

    public getSecurityData(projectData: Project, serverType: ServerType) {
        switch (serverType) {
            case ServerType.Property:
                return projectData.GlossarySecurityData;
            case ServerType.DataProcessing:
                return projectData.DataProcessingContainerSecurityData;
            case ServerType.SoftwareElement:
                return projectData.SoftwareContainerSecurityData;
            case ServerType.Model:
                return projectData.CatalogSecurityData;
            default:
                return null;
        }
    }

    public hasReadAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasReadAccess;
    }

    public hasWriteAccessToAnyModule(space: Space) {
        if (space instanceof Project) {
            return (
                space.ProjectSecurityData.HasWriteAccess ||
                space.GlossarySecurityData.HasCreateAccess ||
                space.DataProcessingContainerSecurityData.HasCreateAccess ||
                space.SoftwareContainerSecurityData.HasCreateAccess ||
                space.CatalogSecurityData.HasCreateAccess
            );
        }

        if (space instanceof Organization) {
            // Write Access on the organization means nothing. we remove it
            //return space.OrganizationSecurityData.HasWriteAccess || org.GlossarySecurityData.HasWriteAccess;
            return space.GlossarySecurityData.HasCreateAccess;
        }
    }

    /**
     * @returns true when user have at least HasImportAccess right on a module
     * or on a catalog source
     */
    public hasImportAccessToAnyModule(space: Space) {
        if (space instanceof Project) {
            return space.CanImportEntities || space.CanImportEntitiesOnCatalog;
        }

        if (space instanceof Organization) {
            return space.GlossarySecurityData.HasImportAccess;
        }
    }

    /**
     * @returns true when user have HasImportAccess right on module
     */
    public hasImportAccessToModule(space: Space, module: DgModule) {
        const securityData = this.getModuleRootSecurityData(module, space);
        return (
            securityData?.HasImportAccess || securityData?.hasModuleImportAccess
        );
    }

    public hasWriteAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasWriteAccess;
    }

    public hasWriteAccessEntitySecurity(
        securityData: EntitySecurityData,
        entityData?: EntityItem
    ) {
        return !!(
            securityData?.HasWriteAccess &&
            (this.securityService.isSteward() ||
                (entityData &&
                    this.securityService.isEntityCreatedByCurrentUser(
                        entityData
                    )))
        );
    }

    public hasAdminAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasAdministratorAccess;
    }

    public hasManagementAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasManagementAccess;
    }

    public hasImportAccess(securityData: EntitySecurityData) {
        return (
            !!securityData?.HasImportAccess ||
            securityData?.hasModuleImportAccess
        );
    }

    public hasEntityCreateAccess(securityData: EntitySecurityData) {
        return !!securityData?.HasCreateAccess;
    }

    // Exception: For Model, we must be Entity Admin to be able to delete the entity
    public hasEntityDeleteAccess(
        serverType: ServerType,
        securityData: EntitySecurityData
    ) {
        return !!securityData?.HasDeleteAccess;
    }

    public getModuleImportSecurityData(
        serverType: ServerType,
        spaceData: Space
    ) {
        return this.getModuleRootSecurityDataFromServerType(
            serverType,
            spaceData
        );
    }

    public getModuleRootSecurityDataFromServerType(
        serverType: ServerType,
        spaceData: Space
    ) {
        const dgModule = DataUtil.getModuleFromServerType(serverType);
        return this.getModuleRootSecurityData(dgModule, spaceData);
    }
    public getModuleRootSecurityData(dgModule: DgModule, spaceData: Space) {
        switch (dgModule) {
            case DgModule.Glossary:
                return spaceData.GlossarySecurityData;
            case DgModule.Processing:
                return (spaceData as Project)
                    .DataProcessingContainerSecurityData;
            case DgModule.Usage:
                return (spaceData as Project).SoftwareContainerSecurityData;
            case DgModule.Catalog:
                return (spaceData as Project).CatalogSecurityData;
        }
    }
}
