import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { INavigationSpaceListInputs } from '../INavigationSpaceListInputs';
import { NavigationSpaceListCore } from './navigationSpaceListCore';
import { NavigationService } from '../../services/navigation.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { ToasterService } from '../../services/toaster.service';
import { SpaceApiService } from '../../space/services/space-api.service';
import { NavSpace } from '@datagalaxy/webclient/workspace/data-access';
import { TooltipPosition } from '@angular/material/tooltip';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxySpaceIconComponent } from '../../shared/shared-ui/dxy-space-icon/dxy-space-icon.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';

@Component({
    selector: 'dxy-navigation-space-list',
    templateUrl: './dxy-navigation-space-list.component.html',
    styleUrls: ['./dxy-navigation-space-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLegacyMenuModule,
        MatLegacyTooltipModule,
        NgIf,
        DxySpaceIconComponent,
        NgClass,
        MatLegacyButtonModule,
        DxyDataTestIdDirective,
        DxyLogFunctionalDirective,
        TranslateModule,
        NgFor,
    ],
})
export class DxyNavigationSpaceListComponent
    extends DxyBaseComponent
    implements INavigationSpaceListInputs, OnInit, OnChanges
{
    @Input() allSpaces: boolean;
    @Input() projectsOnly: boolean;
    @Input() defaultAll: boolean;
    @Input() spaceId: string;
    @Input() openOnDisplayNameClick = true;
    @Input() onlyImportable?: boolean;
    @Input() onlyImportableCatalog?: boolean;
    @Input() isSpaceSelectable: boolean;
    @Input() enableFavorite: boolean;
    @Input() isSmallCaretBtn: boolean;
    @Input() allowAll: boolean;
    @Input() showAllSpaceRedirection: boolean;
    @Input() showSelectedSpaceIcon: boolean;
    @Input() hideSelectedSpaceLabel: boolean;
    @Input() selectedTextTrackerId: string;
    @Input() selectedCaretTrackerId: string;
    @Input() enableMenuTriggerTooltip: boolean;
    @Input() menuTriggerTooltipPosition: TooltipPosition = 'right';
    @Input() menuClass: string;
    @Input() @HostBinding('class.sidebar-layout') sidebarLayout = false;

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly openClose = new EventEmitter<boolean>();
    @Output() get onNavSpaceSelected() {
        return this.core.onNavSpaceSelected;
    }
    @Output() get onDisplayNameClick() {
        return this.core.onDisplayNameClick;
    }

    public get orderedNavSpaces() {
        return this.core.orderedNavSpaces;
    }
    public get selectedDisplayName() {
        return this.core.selectedDisplayName;
    }
    public get currentSelected() {
        return this.core.currentSelected;
    }
    public get isMenuVisible() {
        return this.core.isMenuVisible;
    }
    public get isSpaceCaretDisplayed() {
        return this.core.isSpaceSelectable;
    }
    public get showSpaceIcon() {
        return this.currentSelected && this.showSelectedSpaceIcon;
    }
    public get showSpaceLabel() {
        return (
            this.selectedDisplayName &&
            !(this.hideSelectedSpaceLabel && this.core.currentSelected)
        );
    }
    public get iconLayoutClass() {
        return this.sidebarLayout ? 'sidebar-layout' : 'small';
    }
    public get menuTriggerTooltipContent() {
        return this.enableMenuTriggerTooltip ? this.selectedDisplayName : null;
    }

    private core: NavigationSpaceListCore;

    constructor(
        translateService: TranslateService,
        navigationService: NavigationService,
        toasterService: ToasterService,
        appSpaceService: AppSpaceService,
        spaceApiService: SpaceApiService,
        currentSpaceService: CurrentSpaceService
    ) {
        super();
        this.core = new NavigationSpaceListCore(
            navigationService,
            toasterService,
            appSpaceService,
            spaceApiService,
            currentSpaceService,
            translateService,
            (subscription) => super.registerSubscription(subscription),
            (...args) => this.log('(core)', ...args)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onAnyChanges(changes, this, () => this.core.onInputChange(this));
        super.onChange(changes, 'spaceId', (spaceId: string) =>
            this.core.onSpaceIdChange(spaceId)
        );
    }

    ngOnInit() {
        this.core.init(this).then();
    }

    //#region getters
    public isOrga(navSpace: NavSpace) {
        return this.core.isOrga(navSpace);
    }
    public isSelected(navSpace: NavSpace) {
        return this.core.isSelected(navSpace);
    }
    public isFavoriteButtonVisible(navSpace: NavSpace) {
        return this.core.isFavoriteButtonVisible(navSpace);
    }
    public hasWorkspaceIcon(navSpace: NavSpace) {
        return this.core.hasWorkspaceIcon(navSpace);
    }
    public getWorkspaceIconUrl(navSpace: NavSpace) {
        return this.core.getWorkspaceIconUrl(navSpace);
    }
    public getWorkspaceTrigramBg(navSpace: NavSpace) {
        return this.core.getWorkspaceTrigramBg(navSpace);
    }
    public getNavSpaceId(index: number, navSpace: NavSpace) {
        return navSpace?.ReferenceId;
    }
    //#endregion

    //#region event handlers
    public onDisplayNameClickInternal(event: Event) {
        this.core.onDisplayNameClickInternal(event);
    }
    public onSpaceClick(navSpace: NavSpace) {
        this.core.onSpaceClick(navSpace);
    }
    public async onStarClick(event: Event, navSpace: NavSpace) {
        await this.core.onStarClick(event, navSpace);
    }
    public goToClientSpacesList() {
        this.core.goToClientSpacesList();
    }
    //#endregion

    public onOpenClose(_isOpen: boolean) {}
}
