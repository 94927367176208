import { NavSpace } from '@datagalaxy/webclient/workspace/data-access';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export interface INavSpaceSelectedEvent {
    navSpace: NavSpace;
    isUserAction: boolean;
}
export interface IProjectVersionSelectedEvent {
    projectVersion: ProjectVersion;
    isUserAction: boolean;
}

/** display name of a space (orga, project),
 *  with its version name if it is versionned */
export class UiSpaceNameInfo {
    public readonly spaceIdr: SpaceIdentifier;

    constructor(
        spaceIdr: ISpaceIdentifier,
        public readonly spaceDisplayName: string,
        public readonly versionDisplayName?: string
    ) {
        this.spaceIdr = SpaceIdentifier.from(spaceIdr);
    }

    public toString(separator = ' / ') {
        if (!this.spaceIdr) {
            return '';
        }
        if (!this.versionDisplayName) {
            return this.spaceDisplayName ?? '';
        }
        return `${this.spaceDisplayName ?? ''}${separator ?? ''}${
            this.versionDisplayName ?? ''
        }`;
    }
}
