import { IColDef, IValueColDef } from './grid-column.types';
import { MathUtils, StringUtils } from '@datagalaxy/utils';

export class GridColumnUtils {
    public static getColumnSorting<TRow, TValue>(
        col?: IValueColDef<TRow, TValue>,
        itemA?: TRow,
        itemB?: TRow
    ): 1 | -1 | 0 {
        const aValue = GridColumnUtils.getColumnSortableValue(col, itemA);
        const bValue = GridColumnUtils.getColumnSortableValue(col, itemB);

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        }

        const diff = String(aValue).localeCompare(String(bValue));

        return diff > 0 ? 1 : diff < 0 ? -1 : 0;
    }

    public static getColumnValue<TRow, TValue>(
        col?: IValueColDef<TRow, TValue>,
        item?: TRow
    ): TRow | TValue | null {
        if (!col || !item) {
            return null;
        }

        if (col.getValue) {
            return col.getValue(item);
        }

        if (
            typeof item === 'object' &&
            Object.prototype.hasOwnProperty.call(item, col.id)
        ) {
            return item[col.id as keyof TRow] as TValue;
        }

        if (typeof item !== 'object') {
            return item;
        }

        return null;
    }

    public static getColumnSortableValue<TRow, TCell>(
        col?: IValueColDef<TRow, TCell>,
        item?: TRow
    ): string | number {
        if (!col || !item) {
            return '';
        }

        const value = col.getSortableValue
            ? col.getSortableValue(item)
            : GridColumnUtils.getColumnValue(col, item);

        if (typeof value === 'string') {
            return StringUtils.normalize(value);
        } else if (typeof value === 'number') {
            return value;
        } else if (typeof value === 'boolean') {
            return value ? 1 : 0;
        }

        return '';
    }

    public static getColumnSize<TRow>(col?: IColDef<TRow>): number | null {
        if (!col) {
            return null;
        }

        if (!col.width) {
            return col.minWidth || null;
        }

        return MathUtils.clamp(col.width, col.minWidth || 0, col.maxWidth);
    }

    public static getAverageColumnSize<TRow>(columns: IColDef<TRow>[]): number {
        const sizes = columns
            .map((col) => this.getColumnSize(col))
            .filter((size) => !!size) as number[];

        return MathUtils.avg(sizes);
    }
}
