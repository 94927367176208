import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from '@datagalaxy/ui/illustration';

@Component({
    selector: 'dxy-see-you-soon',
    templateUrl: 'see-you-soon.component.html',
    styleUrls: ['see-you-soon.component.scss'],
    standalone: true,
    imports: [LogoComponent, TranslateModule],
})
export class SeeYouSoonComponent {}
