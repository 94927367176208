import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { HierarchyDataDescriptor } from '../core/HierarchyDataDescriptor';

export type TEventTypeName = string;
export interface IDgEvent {
    ClientGuid: string;
    CreationTime: string;
}

export interface IJsonEvent<T> extends ITypedEvent {
    Content: T;
}

export interface ITypedEvent extends IDgEvent {
    EventTypeName: TEventTypeName;
}

export interface IUserEvent extends ITypedEvent {
    UserGuid?: string;
    UserFullName?: string;
}

export abstract class UserEventBase implements IUserEvent {
    @deserialize EventTypeName: string;
    @deserialize ClientGuid: string;
    @deserialize UserGuid?: string;
    @deserialize UserFullName?: string;
    @deserialize CreationTime: string;
}

@inheritSerialization(UserEventBase)
export abstract class EntityActivityEventBase extends UserEventBase {
    @deserialize EntityReferenceId: string;
    @deserializeAs(HierarchyDataDescriptor) EntityHdd: HierarchyDataDescriptor;
    @deserializeAs(HierarchyDataDescriptor)
    ParentEntityHdd?: HierarchyDataDescriptor;
    @deserialize VersionId: string;

    public static OnDeserialized(instance: EntityActivityEventBase) {
        EntityActivityEventBase.EnsureVersionIdsAreSet(instance);
    }

    private static EnsureVersionIdsAreSet(instance: EntityActivityEventBase) {
        if (instance.ParentEntityHdd && !instance.ParentEntityHdd.VersionId) {
            instance.ParentEntityHdd.VersionId = instance.VersionId;
        }
        if (instance.EntityHdd && !instance.EntityHdd.VersionId) {
            instance.EntityHdd.VersionId = instance.VersionId;
        }
    }
}
