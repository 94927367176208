import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import {
    EntityActivityEventBase,
    UserEventBase,
    registerTypedEvent,
} from '../events';
import {
    EntityLinkTypeKind,
    HierarchyDataDescriptor,
    ObjectLinkType,
} from '../core';

@inheritSerialization(EntityActivityEventBase)
export abstract class EntityLinkActivityEventBase extends UserEventBase {
    @deserializeAs(HierarchyDataDescriptor) SourceHdd?: HierarchyDataDescriptor;
    @deserializeAs(HierarchyDataDescriptor) TargetHdd?: HierarchyDataDescriptor;
    @deserialize EntityLinkTypeKind: EntityLinkTypeKind;
    @deserialize LinkType: ObjectLinkType;
    @deserialize VersionId: string;

    public static OnDeserialized(instance: EntityLinkActivityEventBase) {
        EntityLinkActivityEventBase.EnsureVersionIdsAreSet(instance);
    }

    private static EnsureVersionIdsAreSet(
        instance: EntityLinkActivityEventBase
    ) {
        if (instance.SourceHdd && !instance.SourceHdd.VersionId) {
            instance.SourceHdd.VersionId = instance.VersionId;
        }
        if (instance.TargetHdd && !instance.TargetHdd.VersionId) {
            instance.TargetHdd.VersionId = instance.VersionId;
        }
    }
}

@inheritSerialization(EntityLinkActivityEventBase)
@registerTypedEvent('EntityLinkCreatedEvent')
export class EntityLinkCreatedEvent extends EntityLinkActivityEventBase {}
@inheritSerialization(EntityLinkActivityEventBase)
@registerTypedEvent('EntityLinkDeletedEvent')
export class EntityLinkDeletedEvent extends EntityLinkActivityEventBase {}
@inheritSerialization(EntityLinkActivityEventBase)
@registerTypedEvent('EntityLinkUpdatedEvent')
export class EntityLinkUpdatedEvent extends EntityLinkActivityEventBase {}
