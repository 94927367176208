<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Versioning.Navigator.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content class="public-api-container">
    <div class="vernav-row">
        <div class="building-column">
            <h3 class="column-title" translate>
                UI.Versioning.Navigator.active
            </h3>
            <p class="column-subtitle" translate>
                UI.Versioning.Navigator.activeSubtitle
            </p>
            <div class="column-separator"></div>
            <div class="column-container">
                <ng-container *ngIf="hasAdminAccess">
                    <div
                        class="vernav-tag vernav-action-tag"
                        (click)="onCreateProjectVersion()"
                        translate
                    >
                        UI.Versioning.Navigator.btnNewVersion
                    </div>
                </ng-container>
                <ng-container *ngIf="hasActiveVersions">
                    <div
                        *ngFor="let version of activeVersions"
                        class="vernav-tag vernav-active-tag"
                        (click)="goToVersion(version)"
                        [matTooltip]="getVersionName(version)"
                        matTooltipPosition="left"
                        autoPlacement="true"
                    >
                        {{ getVersionName(version) }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="candidate-column">
            <h3 class="column-title" translate>
                UI.Versioning.Navigator.candidate
            </h3>
            <p class="column-subtitle" translate>
                UI.Versioning.Navigator.candidateSubtitle
            </p>
            <div class="column-separator"></div>
            <div class="column-container">
                <ng-container *ngIf="hasCandidateVersions">
                    <div
                        *ngFor="let version of candidateVersions"
                        class="vernav-tag vernav-active-tag"
                        (click)="goToVersion(version)"
                        [matTooltip]="getVersionName(version)"
                        matTooltipPosition="left"
                    >
                        {{ getVersionName(version) }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="official-column">
            <h3 class="column-title" translate>
                UI.Versioning.Navigator.official
            </h3>
            <p class="column-subtitle" translate>
                UI.Versioning.Navigator.officialSubtitle
            </p>
            <div class="column-separator"></div>
            <div class="column-container">
                <ng-container *ngIf="hasOfficialVersion">
                    <div
                        (click)="goToOfficialVersion()"
                        [matTooltip]="officialVersionName"
                        matTooltipPosition="left"
                        autoPlacement="true"
                        class="vernav-tag vernav-active-tag"
                    >
                        {{ officialVersionName }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="archived-column">
        <h3 class="column-title" translate>UI.Versioning.Navigator.archived</h3>
        <p class="column-subtitle" translate>
            UI.Versioning.Navigator.archivedSubtitle
        </p>
        <hr class="column-separator" />
        <div class="column-container">
            <ng-container *ngIf="hasArchivedVersions">
                <div
                    *ngFor="let version of archivedVersions"
                    class="vernav-tag vernav-inactive-tag"
                    (click)="goToVersion(version)"
                    [matTooltip]="getVersionName(version)"
                    matTooltipPosition="left"
                >
                    {{ getVersionName(version) }}
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mat-flat-button type="button" (click)="onCloseCancel()">
        <span translate>UI.Dialog.btnClose</span>
    </button>
</div>
