import { ObservableInput, Subject } from 'rxjs';
import {
    IWidgetConfig,
    IWidgetConfigDb,
    IWidgetConfigParam,
    IWidgetContentConfig,
    IWidgetContentWorkingConfig,
    IWidgetTypeDefinition,
    WidgetCategory,
    WidgetConfigValueType,
    WidgetFormFieldType,
} from './WidgetUtil';
import {
    CollectionsHelper,
    CoreUtil,
    DateTimeUtil,
    StringUtil,
} from '@datagalaxy/core-util';
import {
    BaseService,
    CoreEventsService,
    IListOption,
    IListOptionItem,
    UiOptionSelectDataType,
} from '@datagalaxy/core-ui';
import { DataUtil } from '../shared/util/DataUtil';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import { MultiSelectAdapter } from '../shared/shared-ui/UiMultiSelect.util';
import { ClientService } from '../client/client.service';
import { GlyphService } from '../services/glyph.service';
import { AppDataService } from '../services/app-data.service';
import { SecurityService } from '../services/security.service';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { ModuleView, NavigationService } from '../services/navigation.service';
import { AppSpaceService } from '../services/AppSpace.service';
import { UserService } from '../services/user.service';
import { FilteredViewService } from '../shared/filter/services/filteredView.service';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { DxyPublicEditModalComponent } from '../shared/public-edit-modal/dxy-public-edit-modal/dxy-public-edit-modal.component';
import { TeamService } from '../team/team.service';
import { IPublicEditModalResolve } from '../shared/public-edit-modal/public-edit-modal.types';
import { Constants } from '../shared/util/Constants';
import {
    TeamAccessType,
    TeamDto,
} from '@datagalaxy/webclient/team/data-access';
import {
    CreateDashboardParameter,
    DashboardApiService,
    DashboardDto,
    DeleteDashboardParameter,
    GetAggregationStatisticsDataResult,
    GetDashboardParameter,
    GetDashboardsParameter,
    GetDataQualityStatisticsDataResult,
    GetStatisticsDataParameter,
    GetWidgetDatasetParameter,
    GetWidgetDatasetResult,
    IDashboardGridStoredConfig,
    IWidgetConfigValue,
    IWidgetIngridConfig,
    IWidgetStoredConfig,
    IWidgetStoredContentConfig,
    StatisticsDataQueryType,
    StatisticsDataTargetType,
    UpdateDashboardAction,
    UpdateDashboardParameter,
    WidgetType,
} from '@datagalaxy/webclient/dashboard/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    UserPublicData,
    userSettingsValues,
} from '@datagalaxy/webclient/user/domain';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import {
    ISpaceIdentifier,
    SpaceGovernanceUserDto,
} from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import {
    AttributeMetaInfo,
    AttributeTagDTO,
    EntityLifecycleStatus,
} from '@datagalaxy/webclient/attribute/domain';
import {
    allModules,
    DgModule,
    DgModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { IWidget } from './grid/widget.types';
import { ObjectAccessType } from '@datagalaxy/webclient/security/data-access';
import { ModuleService } from '../module/module.service';

/**
 * ## Role
 * Common logic for dashboard widgets.
 */
@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseService {
    //#region static

    private static readonly debug = false;

    private static readonly commonFields = ['titleText', 'description'];

    private static readonly availableWidgetTypes = [
        WidgetType.spaceModule,
        WidgetType.projectModuleItemsList,
        WidgetType.projectModuleQualityChart,

        //#region Widgets Fondamentaux
        WidgetType.entityCountByEntityType,
        WidgetType.evolutionOfEntityCountByEntityType,
        WidgetType.entityCountByAttributeValues,
        WidgetType.textAttributesFillPercentage,
        WidgetType.textAttributesFillPercentageByEntityType,
        WidgetType.cumulativeTextAttributesFillPercentage,
        //#endregion

        //#region Widgets Activité
        WidgetType.nbUniqueConnectionByLicenseType,
        WidgetType.evolutionOfNbUniqueConnectionByLicenseType,
        WidgetType.nbSessionByLicenseType,
        WidgetType.evolutionOfNbSessionByLicenseType,
        //#endregion
    ];

    //#region Dashboard default configs

    public static getEmptyConfig(): IDashboardGridConfig {
        return {
            availableTypes: DashboardService.availableWidgetTypes.slice(),
            widgets: [],
        };
    }

    public static getDefaultConfig(): IDashboardGridConfig {
        const makeContentConfig = (
            widgetType: WidgetType,
            dgModule: DgModule
        ) => {
            const config =
                DashboardService.getNewWidgetContentConfig(widgetType);
            const dgModuleParam = config.params.find(
                DashboardService.isParamDgModule
            );
            if (dgModuleParam) {
                dgModuleParam.value = dgModule;
            }
            return config;
        };
        return {
            availableTypes: DashboardService.availableWidgetTypes.slice(),
            widgets: DashboardService.getGridWidgetConfigsFromWidgetContentRows(
                [
                    {
                        height: 5,
                        contents: [
                            makeContentConfig(
                                WidgetType.spaceModule,
                                DgModule.Glossary
                            ),
                            makeContentConfig(
                                WidgetType.spaceModule,
                                DgModule.Catalog
                            ),
                            makeContentConfig(
                                WidgetType.spaceModule,
                                DgModule.Processing
                            ),
                            makeContentConfig(
                                WidgetType.spaceModule,
                                DgModule.Usage
                            ),
                        ],
                    },
                    {
                        height: 5,
                        contents: [
                            makeContentConfig(
                                WidgetType.projectModuleQualityChart,
                                DgModule.Glossary
                            ),
                            makeContentConfig(
                                WidgetType.projectModuleItemsList,
                                DgModule.Catalog
                            ),
                            makeContentConfig(
                                WidgetType.projectModuleQualityChart,
                                DgModule.Processing
                            ),
                            makeContentConfig(
                                WidgetType.projectModuleQualityChart,
                                DgModule.Usage
                            ),
                        ],
                    },
                ]
            ),
        };
    }

    //#endregion Default Configs

    //#region widgets

    //#region widget types

    private static typeDefs = new Map<WidgetType, IWidgetTypeDefinition>();

    /** registers a widget type to be used in a dashboard-grid */
    public static registerWidgetType(def: IWidgetTypeDefinition) {
        DashboardService.log(
            'registering type definition for',
            WidgetType[def.widgetType]
        );
        DashboardService.typeDefs.set(def.widgetType, def);
    }

    public static getWidgetTypeInfo(wt: WidgetType) {
        const typeDef = DashboardService.typeDefs.get(wt);
        return {
            name: WidgetType[wt],
            category: typeDef?.category,
            imgSrc: DashboardService.getWidgetImgSrc(wt),
            nameTranslateKey: DashboardService.getWidgetTypeTranslateKey(
                wt,
                'name'
            ),
            spaceVersionSelectorProjectsOnly:
                typeDef?.spaceVersionSelectorProjectsOnly,
        } as IWidgetTypeInfo;
    }

    public static getWidgetImgSrc(wt: WidgetType) {
        return `/images/widgets/widget-${WidgetType[wt]}.png`;
    }

    public static getWidgetCategoryTranslateKey(wc: WidgetCategory) {
        return `UI.DashboardGrid.WidgetsList.Categories.${WidgetCategory[wc]}`;
    }

    /** returns the translation key for the given widget type */
    public static getWidgetTypeTranslateKey(
        widgetType: WidgetType,
        suffix: string
    ) {
        const infix = WidgetType[widgetType];
        return infix
            ? `UI.DashboardGrid.WidgetType.${infix}.${suffix}`
            : `(${WidgetType[widgetType]} ${suffix})`;
    }

    public static debugData(w: any) {
        const replacer = (k: string, v: any) =>
            v instanceof HTMLElement
                ? `(HTMLElement ${v.tagName} ${v.className})`
                : v?.el instanceof HTMLElement
                ? `(GridStack el:${replacer('el', v.el)})`
                : (v as { ServerType: ServerType })?.ServerType
                ? `(ServerType: ${ServerType[v.ServerType]})`
                : v;
        return JSON.stringify(w, replacer, 2);
    }

    //#endregion  widget types

    //#region widget settings

    private static getParamTranslateKey(param: IWidgetConfigParam) {
        switch (param.formFieldType) {
            case WidgetFormFieldType.dgModuleLegacy:
            case WidgetFormFieldType.dgModule:
            case WidgetFormFieldType.dgModuleAll:
            case WidgetFormFieldType.dgModuleButCatalogAndDiagram:
            case WidgetFormFieldType.dgModuleOnlyCatalog:
                return 'UI.DashboardGrid.WidgetSettings.Fields.moduleName';
            case WidgetFormFieldType.spaceVersion:
            case WidgetFormFieldType.moduleEntityTypes:
            case WidgetFormFieldType.moduleEntityTypesAll:
                return `UI.DashboardGrid.WidgetSettings.Fields.${
                    WidgetFormFieldType[param.formFieldType]
                }`;
            case WidgetFormFieldType.owners:
                return 'DgServerTypes.BaseData.fields.DataOwners';
            case WidgetFormFieldType.stewards:
                return 'DgServerTypes.BaseData.fields.DataStewards';
            case WidgetFormFieldType.teams:
                return 'UI.DashboardGrid.WidgetSettings.Fields.teams';
            case WidgetFormFieldType.licenseUsers:
            case WidgetFormFieldType.users:
                return 'DgServerTypes.ServerTypeNamePlural.User';
            case WidgetFormFieldType.tags:
                return 'DgServerTypes.BaseData.fields.Domains';
            case WidgetFormFieldType.days_1_7_30:
            case WidgetFormFieldType.days_30_60_90:
            case WidgetFormFieldType.period:
                return `UI.DashboardGrid.WidgetSettings.Fields.nbDays`;
            case WidgetFormFieldType.entityStatuses:
                return 'DgServerTypes.BaseData.fields.EntityStatus';
            case WidgetFormFieldType.licenseTypes:
            case WidgetFormFieldType.licenseTypesAll:
                return 'UI.DashboardGrid.WidgetSettings.Fields.licenseTypes';
            case WidgetFormFieldType.userServicesAll:
                return 'UI.DashboardGrid.WidgetSettings.Fields.userServices';
            case WidgetFormFieldType.userRolesAll:
                return 'UI.DashboardGrid.WidgetSettings.Fields.userRoles';
        }
    }

    /** returns, from the given config, the first param matching the given type */
    public static findParam(
        wcc: IWidgetContentConfig,
        type: WidgetFormFieldType
    ) {
        return wcc?.params?.find((p) => p.formFieldType == type);
    }

    /** returns, from the given config, the first param of type spaceVersion */
    public static getSpaceVersionParam(wcc: IWidgetContentConfig) {
        return DashboardService.findParam(
            wcc,
            WidgetFormFieldType.spaceVersion
        );
    }

    public static isSpaceVersionParam(param: IWidgetConfigParam) {
        return param && param.formFieldType == WidgetFormFieldType.spaceVersion;
    }

    public static isParamDgModule(param: IWidgetConfigParam) {
        const fieldType = param?.formFieldType;
        return (
            fieldType === WidgetFormFieldType.dgModule ||
            fieldType === WidgetFormFieldType.dgModuleLegacy ||
            fieldType === WidgetFormFieldType.dgModuleAll ||
            fieldType === WidgetFormFieldType.dgModuleButCatalogAndDiagram ||
            fieldType === WidgetFormFieldType.dgModuleOnlyCatalog
        );
    }

    public static isValidParam(param: IWidgetConfigParam) {
        return (
            !param.formFieldType ||
            param.isOptional ||
            (param.value != undefined &&
                (!Array.isArray(param.value) || param.value.length > 0)) ||
            DashboardService.isParamCommonField(param)
        );
    }

    public static isParamCommonField(param: IWidgetConfigParam) {
        return DashboardService.commonFields.indexOf(param.name) != -1;
    }

    public static isParamEnumValues(param: IWidgetConfigParam) {
        switch (param.formFieldType) {
            case WidgetFormFieldType.moduleEntityTypes:
            case WidgetFormFieldType.moduleEntityTypesAll:
            case WidgetFormFieldType.entityStatuses:
                return true;
            default:
                return false;
        }
    }

    /** retrieve the DgModule value from the given parameter set */
    public static getDgModuleFromParams(wcc: IWidgetContentConfig) {
        return wcc.params.find(DashboardService.isParamDgModule)
            ?.value as DgModule;
    }

    /** retrieve the spaceIdentifier param's value from the given parameter set */
    public static getSpaceIdentifierFromParams(wcc: IWidgetContentConfig) {
        return DashboardService.findParam(wcc, WidgetFormFieldType.spaceVersion)
            ?.value as ISpaceIdentifier;
    }

    /** returns, from the given config, the parameters that depends on the given parameter's name */
    public static getDependentParams(
        param: IWidgetConfigParam,
        config: IWidgetContentConfig
    ) {
        return (
            param &&
            config.params.filter(
                (p) =>
                    p.dependsOn &&
                    (Array.isArray(p.dependsOn)
                        ? p.dependsOn.indexOf(param.name) != -1
                        : p.dependsOn == param.name)
            )
        );
    }

    /** returns, from the given config, the parameters the given parameter depends on */
    public static getMasterParams(
        param: IWidgetConfigParam,
        config: IWidgetContentConfig
    ) {
        const dp = param && config.params.find((p) => p.name == param.name);
        return (
            dp?.dependsOn &&
            CollectionsHelper.asArray(dp.dependsOn).map((pn) =>
                config.params.find((p) => p.name == pn)
            )
        );
    }

    /** returns, from the given config, the first parameter that matches the given type and on which depends the given parameter */
    public static getMasterParam(
        param: IWidgetConfigParam,
        config: IWidgetContentConfig,
        type: WidgetFormFieldType
    ) {
        return DashboardService.getMasterParams(param, config).find(
            (mp) => mp.formFieldType == type
        );
    }

    /** returns, from the given config, the value of the first parameter that matches the given type and on which depends the given parameter */
    public static getMasterParamValue<TValue>(
        param: IWidgetConfigParam,
        config: IWidgetContentConfig,
        type: WidgetFormFieldType
    ) {
        const mp = DashboardService.getMasterParam(param, config, type);
        const result =
            mp?.value && DashboardService.isParamEnumValues(mp)
                ? (mp.value as Array<string | number>)?.map((v) => +v)
                : mp?.value;
        return result as TValue;
    }

    /** add the common field parameters if they are not present,
     * sets their valueType and formFieldType if they are,
     * and sets translateKey of system parameters that has not one */
    private static setCommonFieldParams(wcc: IWidgetContentConfig) {
        const params = wcc.params;

        DashboardService.commonFields.forEach((name) => {
            const valueType = () => {
                switch (name) {
                    case 'titleText':
                        return WidgetConfigValueType.string;
                    case 'description':
                        return WidgetConfigValueType.stringLong;
                }
            };

            let param = params.find((p) => p.name == name);
            if (!param) {
                params.push((param = { name, valueType: undefined }));
            }

            param.valueType = valueType();
            param.formFieldType = WidgetFormFieldType.default;
            param.translateKey =
                'UI.DashboardGrid.WidgetSettings.Fields.' + name;
        });

        params
            .filter((p) => p.formFieldType && !p.translateKey)
            .forEach(
                (p) =>
                    (p.translateKey = DashboardService.getParamTranslateKey(p))
            );

        return wcc;
    }

    public static getSortedParams(wcc: IWidgetContentConfig) {
        const params = wcc.params,
            cfs = DashboardService.commonFields;
        const rank = (p: IWidgetConfigParam) => {
            const cfi = cfs.indexOf(p.name);
            return cfi != -1 ? cfi : cfs.length + params.indexOf(p);
        };
        return params.sort((a, b) => rank(a) - rank(b));
    }

    public static isSingleOptionParam(param: IWidgetConfigParam) {
        switch (param.formFieldType) {
            case WidgetFormFieldType.dgModuleLegacy:
            case WidgetFormFieldType.dgModule:
            case WidgetFormFieldType.dgModuleAll:
            case WidgetFormFieldType.dgModuleButCatalogAndDiagram:
            case WidgetFormFieldType.dgModuleOnlyCatalog:
            case WidgetFormFieldType.days_30_60_90:
            case WidgetFormFieldType.days_1_7_30:
            case WidgetFormFieldType.period:
                return true;
            case WidgetFormFieldType.moduleEntityTypes:
            case WidgetFormFieldType.moduleEntityTypesAll:
            case WidgetFormFieldType.commonAttributes:
            case WidgetFormFieldType.textAttributes:
            case WidgetFormFieldType.licenseTypes:
            case WidgetFormFieldType.licenseTypesAll:
            case WidgetFormFieldType.userServicesAll:
            case WidgetFormFieldType.userRolesAll:
                return !!param.isSingleValue;
            default:
                return false;
        }
    }

    public static isMultiOptionParam(param: IWidgetConfigParam) {
        switch (param.formFieldType) {
            case WidgetFormFieldType.owners:
            case WidgetFormFieldType.licenseUsers:
            case WidgetFormFieldType.teams:
            case WidgetFormFieldType.users:
            case WidgetFormFieldType.stewards:
            case WidgetFormFieldType.tags:
            case WidgetFormFieldType.entityStatuses:
                return true;
            case WidgetFormFieldType.moduleEntityTypes:
            case WidgetFormFieldType.moduleEntityTypesAll:
            case WidgetFormFieldType.commonAttributes:
            case WidgetFormFieldType.textAttributes:
            case WidgetFormFieldType.licenseTypes:
            case WidgetFormFieldType.licenseTypesAll:
            case WidgetFormFieldType.userRolesAll:
            case WidgetFormFieldType.userServicesAll:
                return !param.isSingleValue;
            default:
                return false;
        }
    }

    public static isArrayValueParam(param: IWidgetConfigParam) {
        switch (param.formFieldType) {
            case WidgetFormFieldType.moduleEntityTypes:
            case WidgetFormFieldType.moduleEntityTypesAll:
            case WidgetFormFieldType.textAttributes:
            case WidgetFormFieldType.commonAttributes:
                return true;
            default:
                return DashboardService.isMultiOptionParam(param);
        }
    }

    public static getUiMultiselectDataTypeFromFieldType(
        widgetType: WidgetFormFieldType
    ) {
        switch (widgetType) {
            case WidgetFormFieldType.tags:
                return UiOptionSelectDataType.tag;
            case WidgetFormFieldType.owners:
            case WidgetFormFieldType.licenseUsers:
            case WidgetFormFieldType.users:
            case WidgetFormFieldType.stewards:
                return UiOptionSelectDataType.user;
            default:
                return UiOptionSelectDataType.other;
        }
    }

    private static getOptionsFromEnumNumbers<TEnum extends string | number>(
        enumObj: any,
        getDisplayName: (v: TEnum) => string,
        ...excludedValues: TEnum[]
    ) {
        const values = CollectionsHelper.getEnumValues(
            enumObj,
            ...excludedValues
        );
        return DashboardService.getOptionsFromEnumValues(
            values,
            getDisplayName
        );
    }

    private static getOptionsFromEnumValues<TEnum extends string | number>(
        values: TEnum[],
        getDisplayName: (v: TEnum) => string,
        getGlyphClassName?: (v: TEnum) => string
    ) {
        const options = values.map((v) =>
            DashboardService.asOption(
                v,
                getDisplayName(v),
                getGlyphClassName?.(v)
            )
        );
        return CollectionsHelper.orderBy(options, (o) => o.label);
    }

    private static asOption(
        value: number | string,
        label: string,
        glyphClass?: string,
        data?: any
    ): IOption & IListOptionItem & IListOption {
        return {
            value,
            label,
            glyphClass,
            data,
            get valueId() {
                return this.value?.toString();
            },
            get labelText() {
                return this.label as string;
            },
        };
    }

    private static getModuleEntityTypesAsOptions(
        dgModule: DgModule,
        getDisplayName: (et: EntityType) => string,
        getGlyphClassName: (et: EntityType) => string
    ) {
        const moduleEntityTypes = DataUtil.getEntityTypesFromModule(dgModule);
        return DashboardService.getOptionsFromEnumValues(
            moduleEntityTypes,
            getDisplayName,
            getGlyphClassName
        );
    }

    private async getEntityTypesAsOptions(
        getDisplayName: (et: EntityType) => string,
        getGlyphClassName: (et: EntityType) => string,
        config: IWidgetContentConfig
    ) {
        const spaceId = DashboardService.getSpaceVersionParam(config)
            ?.value as ISpaceIdentifier;

        let dgModules: DgModuleDefinition[] = allModules;
        if (spaceId?.spaceId && spaceId?.versionId) {
            const accessibleModuleInfos =
                await this.moduleService.loadCurrentUserAccessibleModules(
                    spaceId.spaceId,
                    spaceId.versionId
                );

            dgModules = accessibleModuleInfos
                .filter(
                    (mi) =>
                        mi.access.ObjectAccessType !== ObjectAccessType.NoAccess
                )
                .map((m) => m.definition);
        }
        const entityTypes: EntityType[] = [];
        dgModules.forEach((module) => {
            const types = DataUtil.getEntityTypesFromModule(
                DgModule[module.name]
            );
            types.forEach((type) => entityTypes.push(type));
        });

        return DashboardService.getOptionsFromEnumValues(
            entityTypes,
            getDisplayName,
            getGlyphClassName
        );
    }

    private static getTagsAsOptions(tags: AttributeTagDTO[]) {
        const options = tags.map((tag) => {
            const opt = DashboardService.asOption(tag.TagId, tag.DisplayName);
            opt.tagColor = tag.Color;
            return opt;
        });
        return CollectionsHelper.orderBy(options, (o) => o.label);
    }

    /** the returned option's value will be:
     * - for a non cdp: the attributeKey
     * - for a cdp : the attributePath (ie: 'DataTypeName.AttributeKey') */
    private static getAttributesAsOptions(
        amis: AttributeMetaInfo[],
        getDisplayName: (ami: AttributeMetaInfo) => string,
        onlyKeys?: string[],
        getSortIndex: (o: IOption) => number | string = (o) => o.label
    ) {
        if (onlyKeys?.length) {
            amis = amis.filter(
                (ami) => onlyKeys.indexOf(ami.AttributeKey) != -1
            );
        }
        const options = amis.map((ami) =>
            DashboardService.asOption(
                ami.IsCdp ? ami.AttributePath : ami.AttributeKey,
                getDisplayName(ami),
                undefined,
                ami
            )
        );
        return CollectionsHelper.orderBy(options, getSortIndex);
    }

    private static getDaysValues(type: WidgetFormFieldType) {
        switch (type) {
            case WidgetFormFieldType.days_1_7_30:
                return [1, 7, 30];
            case WidgetFormFieldType.days_30_60_90:
                return [30, 60, 90];
            case WidgetFormFieldType.period:
                return [1, 7, 30, 60, 90, 180];
            default:
                return [];
        }
    }

    //#endregion  widget settings

    //#region dashboard & widget configs

    /** Returns in-grid configuration for each of the content configurations given by rows.
     * By default a row is 70px high (including 2 * 5px margins), and a column is a 12th of the grid's width */
    public static getGridWidgetConfigsFromWidgetContentRows(
        rows: IDashboardGridRow[]
    ) {
        const gridColumns = 12;
        const result = new Array<IWidgetConfig>();
        const defaultHeight = Math.floor(gridColumns / rows.length);
        let y = 0;
        rows.forEach((row) => {
            const height = Math.max(1, row.height || defaultHeight);
            const width = Math.max(
                1,
                Math.floor(gridColumns / row.contents.length)
            );
            let x = 0;
            row.contents.forEach((wcc) => {
                result.push({
                    content: wcc,
                    inGrid: { x, y, width, height },
                });
                x += width;
            });
            y += height;
        });
        return result;
    }

    public static isWidgetConfigValid(wcc: IWidgetContentWorkingConfig) {
        return wcc?.params?.every(DashboardService.isValidParam);
    }

    /** returns a new grid config containing only the values to be stored */
    private static getStorableGridConfig(gc: IDashboardGridConfig) {
        return {
            widgets: gc.widgets.map(DashboardService.getStorableWidgetConfig),
        } as IDashboardGridStoredConfig;
    }

    private static getStorableWidgetConfig(wc: IWidgetConfig) {
        const content: IWidgetStoredContentConfig = {
            widgetType: wc.content.widgetType,
        };
        const params = wc.content.params
            ?.filter((p) => p.formFieldType)
            .map(DashboardService.getStorableWidgetParam);
        if (params) {
            content.params = params;
        }

        const ingrid: IWidgetIngridConfig = wc.inGrid && {
            x: wc.inGrid.x,
            y: wc.inGrid.y,
            width: wc.inGrid.width,
            height: wc.inGrid.height,
        };

        const result: IWidgetStoredConfig = { content };
        if (ingrid) {
            result.inGrid = ingrid;
        }
        return result;
    }

    private static getStorableWidgetParam(cv: IWidgetConfigParam) {
        const result: IWidgetConfigValue = { name: cv.name };
        if (cv.value != undefined) {
            result.value = DashboardService.getStorableWidgetParamValue(cv);
        }
        return result;
    }

    private static getStorableWidgetParamValue(cv: IWidgetConfigParam) {
        if (cv.formFieldType == WidgetFormFieldType.spaceVersion) {
            // serialize object parameter value
            return SpaceIdentifier.toString(cv.value as ISpaceIdentifier);
        } else if (
            Array.isArray(cv.value) &&
            DashboardService.isParamEnumValues(cv)
        ) {
            // convert enum values to numbers
            return cv.value.map((v) => +v);
        } else {
            return cv.value;
        }
    }

    public static areSameConfigs(
        ca: IDashboardGridStoredConfig,
        cb: IDashboardGridStoredConfig
    ) {
        return (
            ca === cb ||
            CollectionsHelper.contentEquals(
                ca.widgets,
                cb.widgets,
                false,
                true,
                (wa, wb, iwa, iwb) =>
                    wa.content.widgetType == wb.content.widgetType &&
                    wa.content.params.length == wb.content.params.length &&
                    CoreUtil.areEqual(wa.inGrid, wb.inGrid) &&
                    CollectionsHelper.contentEquals(
                        wa.content.params,
                        wb.content.params,
                        false,
                        true,
                        (pa, pb, ipa, ipb) => {
                            if (pa.name != pb.name) {
                                return false;
                            }
                            const res = CoreUtil.areEqual(pa, pb);
                            !res &&
                                DashboardService.log(
                                    '!areSameConfigs',
                                    pa,
                                    pb,
                                    iwa,
                                    iwb,
                                    ipa,
                                    ipb,
                                    ca,
                                    cb
                                );
                            return res;
                        }
                    )
            )
        );
    }

    /** returns a blank widget content configuration for the given widget type */
    public static getNewWidgetContentConfig(widgetType: WidgetType) {
        if (!widgetType) {
            return;
        }

        const typeDef = DashboardService.typeDefs.get(widgetType);
        if (!typeDef) {
            CoreUtil.warn(
                'unregistered type definition for',
                WidgetType[widgetType]
            );
        }

        return CoreUtil.cloneDeep(typeDef) as IWidgetContentWorkingConfig;
    }

    //#endregion  dashboard & widget configs

    //#endregion  widgets

    //#region data

    /** returns the StatisticsDataTargetType corresponding to the given DgModule */
    public static getProjectDashBoardTargetType(dgModule: DgModule) {
        const SDTT = StatisticsDataTargetType;
        switch (dgModule) {
            case DgModule.Glossary:
                return SDTT.ProjectDashboardProperties;
            case DgModule.Catalog:
                return SDTT.ProjectDashboardModels;
            case DgModule.Processing:
                return SDTT.ProjectDashboardDataProcessing;
            case DgModule.Usage:
                return SDTT.ProjectDashboardSoftwareElements;
        }
    }

    //#endregion  data

    private static log(...args: any[]) {
        DashboardService.debug && CoreUtil.log(DashboardService.name, ...args);
    }

    //#endregion  static

    public get isEditingChange$() {
        return this.isEditingChange;
    }

    private isEditingChange = new Subject<boolean>();
    private readonly defaultOrderedFilterTypes: WidgetFilterType[];
    private allowedSpaceIdrs: ISpaceIdentifier[] = [];

    constructor(
        private translate: TranslateService,
        private dashboardApiService: DashboardApiService,
        private appSpaceService: AppSpaceService,
        private attributeDataService: AttributeDataService,
        private coreEventsService: CoreEventsService,
        private glyphService: GlyphService,
        private dxyModalService: DxyModalService,
        private navigationService: NavigationService,
        private filteredViewService: FilteredViewService,
        private userService: UserService,
        private securityService: SecurityService,
        private teamService: TeamService,
        private appDataService: AppDataService,
        private clientApiService: ClientService,
        private moduleService: ModuleService
    ) {
        super();
        this.defaultOrderedFilterTypes =
            CollectionsHelper.getEnumValues<WidgetFilterType>(WidgetFilterType);
    }

    public notifyEditingChange(isEditing: boolean) {
        this.isEditingChange.next(isEditing);
    }

    public subscribeToOnResize(onResize: () => void) {
        return this.coreEventsService.mainViewResize$.subscribe(() =>
            onResize()
        );
    }

    public isSpaceVersioningEnabled() {
        return this.appSpaceService.isSpaceVersioningEnabled();
    }

    public isSingleWorkspace() {
        return this.appSpaceService.isSingleWorkspace();
    }

    public getSingleWorkspaceSpaceIdr() {
        return this.appSpaceService.getSingleWorkSpaceIdentifier();
    }

    //#region widget-settings option loading

    public async getSettingsParamOptions(
        p: IWidgetConfigParam,
        config: IWidgetContentConfig
    ): Promise<IListOptionItem[]> {
        switch (p.formFieldType) {
            case WidgetFormFieldType.dgModuleLegacy:
            case WidgetFormFieldType.dgModule:
            case WidgetFormFieldType.dgModuleAll:
            case WidgetFormFieldType.dgModuleButCatalogAndDiagram:
            case WidgetFormFieldType.dgModuleOnlyCatalog:
                return await this.getFilteredModuleSettingsParamOptions(
                    p,
                    config
                );
            case WidgetFormFieldType.moduleEntityTypes:
                return await this.getModuleEntityTypeSettingsParamOption(
                    config
                );
            case WidgetFormFieldType.moduleEntityTypesAll:
                return await this.getModuleEntityTypeSettingsParamOption(
                    config,
                    true
                );
            case WidgetFormFieldType.tags: {
                const tags = await this.attributeDataService.getClientTags();
                return DashboardService.getTagsAsOptions(tags);
            }
            case WidgetFormFieldType.entityStatuses: {
                const entityStatuses =
                    CollectionsHelper.getEnumValues<EntityLifecycleStatus>(
                        EntityLifecycleStatus
                    );
                return DashboardService.getOptionsFromEnumValues(
                    entityStatuses,
                    (es) => this.getEntityStatusDisplayName(es)
                );
            }
            case WidgetFormFieldType.owners: {
                const res =
                    await this.attributeDataService.getSpaceGovernanceUsers(
                        DashboardService.getSpaceIdentifierFromParams(config)
                    );
                return this.getOptionsFromSpaceGovernanceUserList(
                    res.SpaceGovernanceUserList.get('DataOwners')
                );
            }
            case WidgetFormFieldType.teams: {
                const teams = await this.teamService.getTeams();
                const publicTeams = teams?.filter(
                    (team) => team.AccessType !== TeamAccessType.Private
                );
                return this.getOptionsFromTeams(publicTeams);
            }
            case WidgetFormFieldType.licenseUsers:
            case WidgetFormFieldType.users:
                return this.getUserSettingsParamOptions(p, config);
            case WidgetFormFieldType.userServicesAll:
                return this.getUserServiceSettingsParamOptions(p, config);
            case WidgetFormFieldType.userRolesAll:
                return this.getUserRoleSettingsParamOptions(p, config);
            case WidgetFormFieldType.stewards: {
                const res_1 =
                    await this.attributeDataService.getSpaceGovernanceUsers(
                        DashboardService.getSpaceIdentifierFromParams(config)
                    );
                return this.getOptionsFromSpaceGovernanceUserList(
                    res_1.SpaceGovernanceUserList.get('DataStewards')
                );
            }
            case WidgetFormFieldType.days_1_7_30:
            case WidgetFormFieldType.days_30_60_90:
            case WidgetFormFieldType.period:
                return DashboardService.getDaysValues(p.formFieldType).map(
                    (n) =>
                        DashboardService.asOption(
                            n,
                            this.getNbDaysDisplayName(n),
                            null,
                            n
                        )
                );
            case WidgetFormFieldType.licenseTypes:
            case WidgetFormFieldType.licenseTypesAll:
                return this.getLicenseTypesSettingsParamOptions(p);
            case WidgetFormFieldType.commonAttributes: {
                const amis =
                    await this.attributeDataService.getCommonAttributesForInsightWidgets();
                return DashboardService.getAttributesAsOptions(
                    amis,
                    (ami) =>
                        this.attributeDataService.getAttributeDisplayName(ami),
                    p.availableValues
                );
            }
            case WidgetFormFieldType.textAttributes: {
                const dgModuleOrNull =
                    DashboardService.getDgModuleFromParams(config);
                const entityTypes = DashboardService.getMasterParamValue<
                    EntityType[]
                >(p, config, WidgetFormFieldType.moduleEntityTypes);
                const amis_2 =
                    await this.attributeDataService.getFreeTextAttributesForInsightWidgets(
                        dgModuleOrNull,
                        entityTypes
                    );
                return DashboardService.getAttributesAsOptions(amis_2, (ami) =>
                    this.attributeDataService.getTextAttributeDisplayNameForInsightWidgets(
                        ami
                    )
                );
            }
            default:
                return [];
        }
    }

    private async getFilteredModuleSettingsParamOptions(
        p: IWidgetConfigParam,
        config: IWidgetContentConfig
    ) {
        const spaceId = DashboardService.getSpaceVersionParam(config)
            ?.value as ISpaceIdentifier;

        const dgmOptions = this.getModuleSettingsParamOptions(p);

        if (!spaceId || !spaceId.spaceId || !spaceId.versionId) {
            return dgmOptions;
        }

        const moduleInfos =
            await this.moduleService.loadCurrentUserAccessibleModules(
                spaceId.spaceId,
                spaceId.versionId
            );

        return dgmOptions.filter(
            (option) =>
                !option.value ||
                option.value === DgModule.Diagram ||
                moduleInfos.some(
                    (m) =>
                        DgModule[m.definition.name] ===
                        (option.value as DgModule)
                )
        );
    }

    private getOptionsFromSpaceGovernanceUserList(
        users: SpaceGovernanceUserDto[]
    ) {
        const options = users.map((user) => {
            const opt = DashboardService.asOption(
                user.ReferenceId,
                user.FullName
            );
            opt.renderData = MultiSelectAdapter.getUserOrPersonRenderData(
                user.UserId
            );
            return opt;
        });
        return CollectionsHelper.orderBy(options, (o) => o.label);
    }

    private getOptionsFromPersonPublicData(users: UserPublicData[]) {
        const options = users.map((user) => {
            const opt = DashboardService.asOption(user.UserId, user.FullName);
            opt.renderData = MultiSelectAdapter.getUserOrPersonRenderData(
                user.UserId
            );
            return opt;
        });
        return CollectionsHelper.orderBy(options, (o) => o.label);
    }

    private getOptionsFromTeams(teams: TeamDto[]) {
        const options = teams.map((team) => {
            const opt = DashboardService.asOption(team.TeamUid, team.TeamName);
            opt.iconUrl = this.teamService.getTeamImageUrl(team);
            opt.glyphClass = this.teamService.getTeamGlyphClass(team);
            return opt;
        });

        return CollectionsHelper.orderBy(options, (o) => o.label);
    }

    public hasAccessToWidget(widget: IWidget) {
        return !widget.config.noAccess;
    }

    public async getSettingsSelectOptions(
        config: IWidgetContentConfig
    ): Promise<Map<IWidgetConfigParam, IListOptionItem[]>> {
        const result = new Map<IWidgetConfigParam, IListOptionItem[]>();
        const params = config.params.filter(
            (p) =>
                DashboardService.isMultiOptionParam(p) ||
                DashboardService.isSingleOptionParam(p)
        );
        await Promise.all(
            params.map(async (p) => {
                const options = await this.getSettingsParamOptions(p, config);
                result.set(p, options);
            })
        );
        return result;
    }

    private async getModuleEntityTypeSettingsParamOption(
        config: IWidgetContentConfig,
        allowAllAsEmpty?: boolean
    ) {
        const dgModule = DashboardService.getDgModuleFromParams(config);

        if (allowAllAsEmpty && !dgModule) {
            return await this.getEntityTypesAsOptions(
                (et) => this.getEntityTypeDisplayName(et),
                (et) => this.getEntityTypeGlyphClassName(et),
                config
            );
        }

        return DashboardService.getModuleEntityTypesAsOptions(
            dgModule,
            (et) => this.getEntityTypeDisplayName(et),
            (et) => this.getEntityTypeGlyphClassName(et)
        );
    }

    private getLicenseTypesSettingsParamOptions(p: IWidgetConfigParam) {
        const users = this.userService.getUserList();
        const ltValues = CollectionsHelper.getEnumValues<LicenseLevel>(
            LicenseLevel
        ).filter((lv) => users.some((user) => user.LicenseLevel === lv));
        let ltOptions = DashboardService.getOptionsFromEnumValues(
            ltValues,
            (lt) => this.getLicenseLevelDisplayName(lt)
        );
        if (p.availableValues?.length) {
            ltOptions = ltOptions.filter(
                (o) => p.availableValues.indexOf(o.valueId) != -1
            );
        }
        if (p.formFieldType === WidgetFormFieldType.licenseTypesAll) {
            ltOptions.forEach((option) => (option.data = [option.value]));
            ltOptions.unshift({
                valueId: null,
                value: null,
                label: this.translate.instant(
                    'UI.DashboardGrid.WidgetSettings.all'
                ),
            });
        }
        return ltOptions;
    }

    private getUserRoleSettingsParamOptions(
        p: IWidgetConfigParam,
        config: IWidgetContentConfig
    ) {
        let users = this.userService.getUserList();
        const deps = DashboardService.getMasterParams(p, config);

        deps?.forEach((dep) => {
            switch (dep.formFieldType) {
                case WidgetFormFieldType.licenseTypesAll: {
                    const licenseLevel = (dep.value as LicenseLevel[])?.[0];
                    users = users.filter(
                        (user) =>
                            !licenseLevel || user.LicenseLevel === licenseLevel
                    );
                    break;
                }
                default:
                    break;
            }
        });
        let roles = CollectionsHelper.distinct(
            users.map((user) => user.Role ?? '')
        );
        roles = CollectionsHelper.orderByText(roles, (role) => role);
        return Promise.resolve(
            roles.map((service) => DashboardService.asOption(service, service))
        );
    }

    private getUserServiceSettingsParamOptions(
        p: IWidgetConfigParam,
        config: IWidgetContentConfig
    ) {
        let users = this.userService.getUserList();
        const deps = DashboardService.getMasterParams(p, config);

        deps?.forEach((dep) => {
            switch (dep.formFieldType) {
                case WidgetFormFieldType.licenseTypesAll: {
                    const licenseLevel = (dep.value as LicenseLevel[])?.[0];
                    users = users.filter(
                        (user) =>
                            !licenseLevel || user.LicenseLevel === licenseLevel
                    );
                    break;
                }
                default:
                    break;
            }
        });
        let services = CollectionsHelper.distinct(
            users.map((user) => user.Service ?? '')
        );
        services = CollectionsHelper.orderByText(
            services,
            (service) => service
        );
        return Promise.resolve(
            services.map((service) =>
                DashboardService.asOption(service, service)
            )
        );
    }

    private getModuleSettingsParamOptions(
        p: IWidgetConfigParam
    ): (IListOptionItem & IOption)[] {
        const excluded = [DgModule.unknown];
        if (
            p.formFieldType == WidgetFormFieldType.dgModuleButCatalogAndDiagram
        ) {
            excluded.push(DgModule.Catalog, DgModule.Diagram);
        } else if (p.formFieldType == WidgetFormFieldType.dgModuleOnlyCatalog) {
            excluded.push(
                DgModule.Glossary,
                DgModule.Processing,
                DgModule.Usage,
                DgModule.Diagram
            );
        } else if (p.formFieldType == WidgetFormFieldType.dgModuleLegacy) {
            excluded.push(DgModule.Diagram);
        }

        const dgmOptions = CollectionsHelper.orderBy(
            DashboardService.getOptionsFromEnumNumbers(
                DgModule,
                (m) => this.getModuleDisplayName(m),
                ...excluded
            ),
            (o) => o.valueId
        );
        dgmOptions.forEach((option) => {
            option.glyphClass = this.glyphService.getModuleColoredGlyphClass(
                option.value as DgModule
            );
            option.data = option.data || option.value;
            option.optionClass = 'menu-item';
        });
        if (p.formFieldType === WidgetFormFieldType.dgModuleAll) {
            dgmOptions.unshift({
                valueId: null,
                value: null,
                label: this.translate.instant(
                    'UI.DashboardGrid.WidgetSettings.all'
                ),
                labelKey: this.translate.instant(
                    'UI.DashboardGrid.WidgetSettings.all'
                ),
                optionClass: 'menu-item',
            });
        }
        return dgmOptions;
    }

    private async getUserSettingsParamOptions(
        p: IWidgetConfigParam,
        config: IWidgetContentConfig
    ) {
        const users = this.userService.getUserList();
        const deps = DashboardService.getMasterParams(p, config);

        if (!deps) {
            return this.getOptionsFromPersonPublicData(users);
        }

        const result = await Promise.all(
            deps.map((dep) => this.filterUserByDepFieldType(users, config, dep))
        );
        return this.getOptionsFromPersonPublicData(
            CollectionsHelper.getArraysCommonValues(...result)
        );
    }

    private async filterUserByDepFieldType(
        users: UserPublicData[],
        config: IWidgetContentConfig,
        dep: IWidgetConfigParam
    ) {
        switch (dep.formFieldType) {
            case WidgetFormFieldType.licenseTypesAll: {
                const licenseLevel = DashboardService.findParam(
                    config,
                    WidgetFormFieldType.licenseTypesAll
                )?.value as LicenseLevel[];
                return users.filter(
                    (user) =>
                        !licenseLevel || user.LicenseLevel === licenseLevel?.[0]
                );
            }
            case WidgetFormFieldType.userServicesAll: {
                const services = dep.value as string[];
                return CollectionsHelper.filterByIncludedValue(
                    users,
                    (user) => user.Service || '',
                    services,
                    true
                );
            }
            case WidgetFormFieldType.userRolesAll: {
                const roles = dep.value as string[];
                return CollectionsHelper.filterByIncludedValue(
                    users,
                    (user) => user.Role || '',
                    roles,
                    true
                );
            }
            case WidgetFormFieldType.teams: {
                const teamIds = dep.value as string[];
                if (!teamIds?.length) {
                    return users;
                }

                const getTeamUserIds = async (teamId: string) => {
                    const res = await this.teamService.getTeamMembers(teamId);
                    return res?.TeamMembers?.map((tm) => tm.UserId) ?? [];
                };
                const userIdGroups = await Promise.all(
                    teamIds.map(getTeamUserIds)
                );
                const userIds = CollectionsHelper.flatten(userIdGroups, true);

                return users.filter((user) => userIds.includes(user.UserId));
            }
            default:
                break;
        }
    }

    //#endregion  widget-settings option loading

    //#region widget ui filters

    public async getWidgetFilters(
        options: IOptionsGetWidgetFilters,
        orderedFilterTypes?: WidgetFilterType[]
    ): Promise<IWidgetFilter[]> {
        this.log('getWidgetFilters', options, orderedFilterTypes);

        const filters: IWidgetFilter[] = [],
            promises: Promise<IWidgetFilter>[] = [];
        const isSpaceAllowed =
            options?.spaceIdr && this.isAllowedSpace(options.spaceIdr);

        options?.spaceIdr &&
            isSpaceAllowed &&
            promises.push(this.getSpaceVersionFilter(options.spaceIdr));
        options?.dgModule &&
            filters.push(this.getDgModuleFilter(options.dgModule));
        options?.entityTypes?.length &&
            filters.push(this.getEntityTypesFilter(options.entityTypes));
        options?.tagIds?.length &&
            promises.push(this.getTagsFilter(options.tagIds));
        options?.nbDays != undefined &&
            filters.push(this.getNbDaysFilter(options.nbDays));
        options?.entityStatuses?.length &&
            filters.push(this.getEntityStatusFilter(options?.entityStatuses));
        options?.licenseTypes?.length &&
            filters.push(this.getLicenseLevelFilter(options?.licenseTypes));
        options?.ownerIds?.length &&
            isSpaceAllowed &&
            promises.push(
                this.getUsersFilter(
                    options?.spaceIdr,
                    options?.ownerIds,
                    WidgetFilterType.owners
                )
            );
        options?.stewardIds?.length &&
            isSpaceAllowed &&
            promises.push(
                this.getUsersFilter(
                    options?.spaceIdr,
                    options?.stewardIds,
                    WidgetFilterType.stewards
                )
            );
        options?.commonAttributes &&
            filters.push(
                this.getCommonAttributesFilter(options.commonAttributes)
            );
        options?.textAttributes &&
            promises.push(this.getTextAttributesFilter(options.textAttributes));

        const promiseResults = await Promise.all(promises);
        filters.push(...promiseResults.filter((o) => o));
        orderedFilterTypes = orderedFilterTypes?.length
            ? orderedFilterTypes
            : this.defaultOrderedFilterTypes;
        const result = CollectionsHelper.orderBy(filters, (f) =>
            orderedFilterTypes.indexOf(f.filterType)
        );
        this.log('getWidgetFilters-result', options, result);
        return result;
    }

    private async getSpaceVersionFilter(spaceIdr: ISpaceIdentifier) {
        const usv = await this.appSpaceService.getSpaceVersionDisplayName(
            spaceIdr
        );
        return this.makeUiFilter(
            WidgetFilterType.spaceVersion,
            usv?.toString(' | ')
        );
    }

    private getDgModuleFilter(dgModule: DgModule) {
        const uiValue = this.getModuleDisplayName(dgModule);
        return this.makeUiFilter(WidgetFilterType.dgModule, uiValue);
    }

    private getEntityTypesFilter(ets: EntityType[]) {
        const uiValue = ets
            .map((et) => this.getEntityTypeDisplayName(et))
            .join(', ');
        return this.makeUiFilter(WidgetFilterType.entityTypes, uiValue);
    }

    private getEntityStatusFilter(entityStatuses: EntityLifecycleStatus[]) {
        const uiValue = entityStatuses
            .map((es) => this.getEntityStatusDisplayName(es))
            .join(', ');
        return this.makeUiFilter(WidgetFilterType.entityStatuses, uiValue);
    }

    private getLicenseLevelFilter(licenseLevel: LicenseLevel[]) {
        const uiValue = licenseLevel
            .map((lv) => this.getLicenseLevelDisplayName(lv))
            .join(', ');
        return this.makeUiFilter(WidgetFilterType.licenseTypes, uiValue);
    }

    private async getUsersFilter(
        spaceIdr: ISpaceIdentifier,
        userIds: string[],
        filterType: WidgetFilterType
    ) {
        const res = await this.attributeDataService.getSpaceGovernanceUsers(
            spaceIdr
        );
        let type = '';
        switch (filterType) {
            case WidgetFilterType.stewards:
                type = 'DataStewards';
                break;
            case WidgetFilterType.owners:
                type = 'DataOwners';
                break;
        }
        const uiValue = res.SpaceGovernanceUserList.get(type)
            .filter((user) => userIds.includes(user.ReferenceId))
            .map((user) => user.FullName)
            .join(', ');
        return this.makeUiFilter(filterType, uiValue);
    }

    private async getTagsFilter(tagIds: string[]) {
        const tags = await this.attributeDataService.getClientTags();
        const selectedTags = tagIds.map((tagId) =>
            tags.find((tag) => tag.TagId == tagId)
        );
        const uiValue = selectedTags
            .map((tag_1) => tag_1.DisplayName)
            .join(', ');
        return this.makeUiFilter(WidgetFilterType.tags, uiValue);
    }

    private getNbDaysFilter(nbDays: number) {
        const uiValue = this.getNbDaysDisplayName(nbDays);
        return this.makeUiFilter(WidgetFilterType.nbDays, uiValue, 'label');
    }

    private getCommonAttributesFilter(attributeKeys: string[]) {
        const uiValue = attributeKeys
            .map((ak) => this.getCommonAttributeDisplayName(ak))
            .join(', ');
        return this.makeUiFilter(WidgetFilterType.commonAttributes, uiValue);
    }

    private async getTextAttributesFilter(attributePaths: string[]) {
        await this.attributeDataService.loadAttributesForInsightWidgetFilters(
            attributePaths
        );
        const uiValue = attributePaths
            .map((ap) =>
                this.attributeDataService.getTextAttributeDisplayNameForInsightWidgetFilter(
                    ap,
                    true
                )
            )
            .join(', ');
        return this.makeUiFilter(WidgetFilterType.commonAttributes, uiValue);
    }

    private makeUiFilter(
        filterType: WidgetFilterType,
        uiValue: string,
        labelSubKey?: string
    ): IWidgetFilter {
        const labelSuffix =
            labelSubKey && !labelSubKey.startsWith('.')
                ? `.${labelSubKey}`
                : labelSubKey ?? '';
        const labelKey = `UI.DashboardGrid.WidgetFilters.${WidgetFilterType[filterType]}${labelSuffix}`;
        const uiLabel = this.translate.instant(labelKey);
        return { filterType, uiLabel, uiValue };
    }

    //#endregion

    //#region translate

    public getWidgetTypeTranslatedInfo(wt: WidgetType) {
        const wti = DashboardService.getWidgetTypeInfo(wt);
        const result = wti as IWidgetTypeInfoTranslated;
        result.categoryDisplayName = this.translate.instant(
            DashboardService.getWidgetCategoryTranslateKey(wti.category)
        );
        result.typeDisplayName = this.translate.instant(wti.nameTranslateKey);
        return result;
    }

    public getParamDisplayName(
        param: IWidgetConfigParam,
        options?: IListOptionItem[]
    ) {
        switch (param.formFieldType) {
            case WidgetFormFieldType.dgModuleAll: {
                if (!param.value) {
                    return this.translate.instant(
                        'UI.DashboardGrid.WidgetSettings.all'
                    );
                }
                return this.getModuleDisplayName(param.value as DgModule);
            }
            case WidgetFormFieldType.moduleEntityTypesAll:
                if (!param.value) {
                    return this.translate.instant(
                        'UI.DashboardGrid.WidgetSettings.all'
                    );
                }
                return (param.value as EntityType[])
                    ?.map((et) => this.getEntityTypeDisplayName(et))
                    .join(', ');
            case WidgetFormFieldType.licenseTypesAll:
                if (!param.value) {
                    return this.translate.instant(
                        'UI.DashboardGrid.WidgetSettings.all'
                    );
                }
                return (param.value as LicenseLevel[])
                    ?.map((lv) => this.getLicenseLevelDisplayName(lv))
                    .join(', ');
            case WidgetFormFieldType.days_30_60_90:
            case WidgetFormFieldType.period:
                return this.getNbDaysDisplayName(param.value as number);
            case WidgetFormFieldType.teams: {
                if (!param.value) {
                    return this.translate.instant(
                        'UI.DashboardGrid.WidgetSettings.all'
                    );
                }
                const teamIds = param?.value as string[];
                return teamIds
                    .map(
                        (teamId) =>
                            options.find((opt) => opt.valueId === teamId)
                                ?.labelText
                    )
                    .filter((label) => label)
                    .join(', ');
            }
            case WidgetFormFieldType.licenseUsers:
            case WidgetFormFieldType.users: {
                const nb = (param.value as string[])?.length;
                return nb
                    ? this.translate.instant(`UI.Filter.userTag.count`, { nb })
                    : this.translate.instant(
                          'UI.DashboardGrid.WidgetSettings.all'
                      );
            }
            case WidgetFormFieldType.userRolesAll:
            case WidgetFormFieldType.userServicesAll: {
                if (StringUtil.isNullOrEmpty(param.value as string)) {
                    return this.translate.instant(
                        'UI.DashboardGrid.WidgetSettings.all'
                    );
                }
                const emptyText = this.translate.instant(
                    'UI.DashboardGrid.WidgetSettings.empty'
                );
                return (param.value as string[])
                    .map((value) =>
                        StringUtil.isNullOrEmpty(value) ? emptyText : value
                    )
                    .join(', ');
            }
            default:
                this.warn(
                    `getParamDisplayName not implemented for ${
                        WidgetFormFieldType[param.formFieldType]
                    }`
                );
        }
    }

    public getEntityTypeDisplayName(et: EntityType) {
        return this.attributeDataService.getEntityTypeTranslation(et);
    }

    public getEntityTypePluralDisplayName(et: EntityType) {
        return this.attributeDataService.getEntityTypePluralTranslation(et);
    }

    public getTextAttributeDisplayName(textAttributePath: string) {
        return this.attributeDataService.getTextAttributeDisplayNameForInsightWidgetFilter(
            textAttributePath
        );
    }

    private getModuleDisplayName(m: DgModule) {
        return this.translate.instant(DataUtil.getModuleTranslateKey(m));
    }

    private getEntityTypeGlyphClassName(et: EntityType) {
        return EntityTypeUtils.getColoredGlyphClass(et);
    }

    private getEntityStatusDisplayName(es: string | number) {
        return this.translate.instant(
            `DgServerTypes.EntityStatus.${EntityLifecycleStatus[es]}`
        );
    }

    private getLicenseLevelDisplayName(ll: string | number) {
        return LicenseLevel[ll];
    }

    private getNbDaysDisplayName(nbDays: number) {
        return this.translate.instant(
            'UI.DashboardGrid.WidgetFilters.nbDays.value',
            { count: nbDays }
        );
    }

    private getCommonAttributeDisplayName(attributeKey: string) {
        return this.translate.instant(
            `DgServerTypes.BaseData.fields.${attributeKey}`
        );
    }

    //#endregion  translate

    //#region grid management

    public async getWorkingConfig(
        dgsc: IDashboardGridStoredConfig,
        defaultSpaceIdr: ISpaceIdentifier
    ) {
        const result = CoreUtil.cloneDeep(dgsc) as IDashboardGridConfig;

        result.widgets.forEach((wc) => {
            const widgetContentConfig = wc.content;
            const widgetDefaultConfig =
                DashboardService.getNewWidgetContentConfig(
                    widgetContentConfig.widgetType
                );
            if (!widgetDefaultConfig) {
                return;
            }

            widgetContentConfig.component = widgetDefaultConfig.component;
            widgetContentConfig.params = widgetContentConfig.params || [];
            if (!widgetDefaultConfig.params?.length) {
                return;
            }

            // append not stored parameters
            widgetDefaultConfig.params.forEach(
                (dp) =>
                    !widgetContentConfig.params.some(
                        (wp) => wp.name == dp.name
                    ) &&
                    widgetContentConfig.params.push(<IWidgetConfigParam>{
                        name: dp.name,
                    })
            );

            // set parameter's not stored properties
            widgetContentConfig.params.forEach((wp) => {
                const dp = widgetDefaultConfig.params.find(
                    (p) => p.name == wp.name
                );
                if (!dp) {
                    return;
                }
                wp.valueType = dp.valueType;
                wp.translateKey = dp.translateKey;
                wp.formFieldType = dp.formFieldType;
                wp.dependsOn = dp.dependsOn;
                wp.isOptional = dp.isOptional;
                wp.availableValues = dp.availableValues;
                wp.isSingleValue = dp.isSingleValue;
            });

            // deserialize object parameter values
            const svp =
                DashboardService.getSpaceVersionParam(widgetContentConfig);
            if (svp) {
                svp.value = svp.value
                    ? SpaceIdentifier.fromString(svp.value as string)
                    : defaultSpaceIdr;
            }
        });

        await this.setConfigNoAccessFlag(result);

        // set common field defaults
        return this.setAllCommonFieldDefaults(result);
    }

    private async setConfigNoAccessFlag(config: IDashboardGridConfig) {
        const widgets = config.widgets;

        const widgetSpaces = CollectionsHelper.groupBy(
            widgets,
            (widget) => {
                const spaceIdr = DashboardService.getSpaceIdentifierFromParams(
                    widget.content
                );

                return SpaceIdentifier.toString(spaceIdr);
            },
            (key: string, items: IWidgetConfig[]) => ({ key, items })
        );

        await Promise.all(
            widgetSpaces.map(async (map) => {
                const spaceIdr = SpaceIdentifier.fromString(map.key);

                if (!spaceIdr) {
                    return;
                }

                const modules =
                    await this.moduleService.loadCurrentUserAccessibleModules(
                        spaceIdr.spaceId,
                        spaceIdr.versionId
                    );

                map.items.forEach((widget) => {
                    const moduleParam = widget.content.params.find(
                        DashboardService.isParamDgModule
                    );
                    const module = moduleParam.value as DgModule;

                    if (
                        !modules.some(
                            (m) => DgModule[m.definition.name] === module
                        )
                    ) {
                        widget.noAccess = true;
                    }
                });
            })
        );
    }

    private setAllCommonFieldDefaults(dgc: IDashboardGridConfig) {
        dgc.widgets.forEach((wc) => this.setCommonFieldDefaults(wc.content));
        return dgc;
    }

    /** returns a new config with common field parameters set up
     * and only the parameters to display in the settings form */
    public getSettingsConfig(wcc: IWidgetContentConfig) {
        //console.log('getSettingsConfig', DashboardService.debugData(wcc))
        const config = this.setCommonFieldDefaults(CoreUtil.cloneDeep(wcc));
        CollectionsHelper.remove(config.params, (p) => !p.formFieldType);
        return config;
    }

    //#region widget common fields and their default values

    /** set their default value to the common fields, according to the current dgModule, if:
     * - they are not present,
     * - they are empty,
     * - their value is the default according to the given previous dgModule */
    public setCommonFieldDefaults(
        wcc: IWidgetContentConfig,
        prevDgModule?: DgModule,
        emptyStringToDefault = false
    ) {
        DashboardService.setCommonFieldParams(wcc);
        const dgModule = DashboardService.getDgModuleFromParams(wcc);
        this.updateCommonField(
            wcc,
            'titleText',
            dgModule,
            prevDgModule,
            emptyStringToDefault
        );
        this.updateCommonField(
            wcc,
            'description',
            dgModule,
            prevDgModule,
            emptyStringToDefault
        );
        return wcc;
    }

    private updateCommonField(
        wc: IWidgetContentConfig,
        commonFieldName: CommonFieldName,
        dgModule: DgModule,
        prevDgModule?: DgModule,
        emptyStringToDefault = false
    ) {
        const param = wc.params.find((p) => p.name == commonFieldName);
        //this.log('updateCommonField', wc, commonFieldName, DgModule[dgModule], DgModule[prevDgModule], emptyStringToDefault, param)
        if (!param) {
            return;
        }
        const paramValue = param.value as string;
        const prevDefault = this.getCommonFieldDefaultValue(
            commonFieldName,
            wc.widgetType,
            prevDgModule
        );
        if (
            paramValue == prevDefault ||
            paramValue == undefined ||
            (paramValue == '' && emptyStringToDefault)
        ) {
            param.value = this.getCommonFieldDefaultValue(
                commonFieldName,
                wc.widgetType,
                dgModule
            );
            //this.log('updateCommonField update', paramValue, param.value, prevDefault)
        } //else { this.log('updateCommonField noupdate', paramValue, prevDefault, emptyStringToDefault, param.value) }
    }

    private removeAllCommonFieldDefaults(dgsc: IDashboardGridConfig) {
        dgsc.widgets.forEach((wc) => {
            const wcc = wc.content,
                dgModule = DashboardService.getDgModuleFromParams(wcc);
            //if(!dgModule) { this.log('removeAllCommonFieldDefaults nomodule', wcc, dgsc)}
            this.removeCommonFieldParamIfDefault(wcc, 'titleText', dgModule);
            this.removeCommonFieldParamIfDefault(wcc, 'description', dgModule);
        });
        return dgsc;
    }

    private removeCommonFieldParamIfDefault(
        wc: IWidgetStoredContentConfig,
        paramName: CommonFieldName,
        dgModule: DgModule
    ) {
        const params = wc.params,
            param = params.find((p) => p.name == paramName);
        if (!param) {
            return;
        }
        const defaultValue = this.getCommonFieldDefaultValue(
            paramName,
            wc.widgetType,
            dgModule
        );
        if (param.value == defaultValue) {
            params.splice(params.indexOf(param), 1);
        }
    }

    private getCommonFieldDefaultValue(
        commonFieldName: CommonFieldName,
        widgetType: WidgetType,
        dgModule: DgModule
    ) {
        const wtd = DashboardService.typeDefs.get(widgetType);
        const cftks = wtd?.commonFieldTranslateKeys;
        if (!cftks || (cftks.needsDgModuleSuffix && !dgModule)) {
            return;
        }
        const prefix: string = cftks[commonFieldName];
        const translateKey =
            prefix &&
            prefix +
                (cftks.needsDgModuleSuffix ? '.' + DgModule[dgModule] : '');
        //this.log('getCommonFieldDefaultValue', WidgetType[widgetType], DgModule[dgModule], cftks, prefix, translateKey)
        return translateKey ? this.translate.instant(translateKey) : undefined;
    }

    public async collectAllowedSpaces(gridConfig: IDashboardGridConfig) {
        this.log('collectAllowedSpaces', gridConfig);
        this.allowedSpaceIdrs = [];
        const spaceIdrs =
            gridConfig?.widgets.map((w) =>
                DashboardService.getSpaceIdentifierFromParams(w.content)
            ) ?? [];
        const distinctSpaceIdrs = CollectionsHelper.distinctByProperty(
            spaceIdrs,
            SpaceIdentifier.toString
        );
        const allowedSpaceIdrs = await Promise.all(
            distinctSpaceIdrs.map(async (spaceIdr) => {
                const hasAccess = await this.appSpaceService.hasReadAccess(
                    spaceIdr
                );
                return hasAccess && spaceIdr;
            })
        );
        this.allowedSpaceIdrs = allowedSpaceIdrs.filter((o) => o);
        this.log('collectAllowedSpaces-result', this.allowedSpaceIdrs);
    }

    public collectSpaceAsAllowed(wcc: IWidgetContentConfig) {
        const spaceIdr = DashboardService.getSpaceIdentifierFromParams(wcc);
        if (
            spaceIdr &&
            !this.allowedSpaceIdrs.some((idr) =>
                SpaceIdentifier.areSame(idr, spaceIdr)
            )
        ) {
            this.allowedSpaceIdrs.push(spaceIdr);
        }
    }

    //#endregion    common fields and their default values

    //#region load/save grid

    public getStorableGridConfig(
        dgc: IDashboardGridConfig,
        isCurrent?: boolean
    ) {
        const storableConfig = DashboardService.getStorableGridConfig(
            this.removeAllCommonFieldDefaults(CoreUtil.cloneDeep(dgc))
        );
        if (isCurrent) {
            storableConfig.isCurrent = true;
        } else {
            delete storableConfig.isCurrent;
        }
        return storableConfig;
    }

    /** returns the Dashboard for the given dashboardId*/
    public async loadGridConfig(
        dashboard: DashboardDto
    ): Promise<DashboardDto> {
        this.log('loadGridConfig', dashboard?.DashboardId);
        if (!dashboard?.DashboardId) {
            return Promise.resolve<DashboardDto>(null);
        }

        try {
            const result = await this.getDashboard(
                dashboard?.DashboardId,
                true
            );

            result.Dashboard.dashboardConfig.widgets.sort((a, b) => {
                if (a.inGrid.y == b.inGrid.y) {
                    return a.inGrid.x - b.inGrid.x;
                }
                return a.inGrid.y - b.inGrid.y;
            });

            this.log('loadGridConfig-result', result);
            return result.Dashboard;
        } catch (error) {
            if (error.status === 404) {
                this.notifyDashboardDeleted(dashboard);
            } else {
                console.error(error);
            }
            return null;
        }
    }

    /** Stores the given Dashboard configuration in database.*/
    public async persistGridConfig(
        dashboard: DashboardDto,
        gridConfig: IDashboardGridStoredConfig
    ) {
        this.log('persistGridConfig', dashboard?.DashboardId, gridConfig);
        if (!dashboard?.DashboardId) {
            dashboard.Config = JSON.stringify(gridConfig);
            await this.openCreateDashboardModal(dashboard);
            const result = await this.createDashboard(
                dashboard.DisplayName,
                dashboard.Description,
                dashboard.Config
            );
            this.notifyDashboardCreated(result.Dashboard);
            return result;
        }

        return this.updateDashboardConfig(
            dashboard.DashboardId,
            JSON.stringify(gridConfig)
        );
    }

    //#endregion

    //#endregion    grid management

    //#region get widgets data

    public isSpaceForbiddenWidget(widgetConfig: IWidgetContentConfig) {
        const widgetSpaceIdr =
            DashboardService.getSpaceIdentifierFromParams(widgetConfig);
        return !!widgetSpaceIdr && !this.isAllowedSpace(widgetSpaceIdr);
    }

    public async getWidgetDataset(
        param: GetWidgetDatasetParameter,
        isStandalone?: boolean
    ): Promise<GetWidgetDatasetResult> {
        const spaceIdr =
            param.SpaceId &&
            new SpaceIdentifier(param.SpaceId, param.VersionId);
        if (spaceIdr && !isStandalone && !this.isAllowedSpace(spaceIdr)) {
            return null;
        }

        return await this.dashboardApiService.getWidgetDataset(param);
    }

    public async loadAggregationData(
        spaceIdr: ISpaceIdentifier,
        targetType: number,
        cancelNotifier?: ObservableInput<any>
    ): Promise<GetAggregationStatisticsDataResult> {
        if (!this.isAllowedSpace(spaceIdr)) {
            return Promise.resolve<GetAggregationStatisticsDataResult>(null);
        }

        const parameter = this.createGetStatisticsDataParameter(
            spaceIdr,
            targetType,
            StatisticsDataQueryType.Aggregation
        );
        return await this.dashboardApiService.getStatisticsData(
            parameter,
            GetAggregationStatisticsDataResult,
            cancelNotifier
        );
    }

    public async getStatisticsData(
        spaceIdr: ISpaceIdentifier,
        targetType: StatisticsDataTargetType,
        queryType: StatisticsDataQueryType,
        cancelNotifier?: ObservableInput<any>
    ): Promise<GetDataQualityStatisticsDataResult> {
        if (!this.isAllowedSpace(spaceIdr)) {
            return Promise.resolve<GetDataQualityStatisticsDataResult>(null);
        }
        const gsdp = this.createGetStatisticsDataParameter(
            spaceIdr,
            targetType,
            queryType
        );
        return await this.dashboardApiService.getStatisticsData(
            gsdp,
            GetDataQualityStatisticsDataResult,
            cancelNotifier
        );
    }

    private createGetStatisticsDataParameter(
        spaceIdr: ISpaceIdentifier,
        targetType: StatisticsDataTargetType,
        queryType: StatisticsDataQueryType
    ) {
        const parameter = new GetStatisticsDataParameter();
        parameter.RootDataReferenceId = spaceIdr.spaceId;
        parameter.TargetType = targetType;
        parameter.QueryType = queryType;
        parameter.setVersionId(spaceIdr?.versionId);
        return parameter;
    }

    public isAllowedSpace(spaceIdr: ISpaceIdentifier) {
        return (
            spaceIdr &&
            this.allowedSpaceIdrs.some((idr) =>
                SpaceIdentifier.areSame(spaceIdr, idr)
            )
        );
    }

    //#endregion

    //#endregion

    //#region Dashboard

    public getUserDashboards() {
        const parameter = new GetDashboardsParameter();
        return this.dashboardApiService.getUserDashboards(parameter);
    }

    public getDashboard(dashboardId: number, includeConfig: boolean) {
        const parameter = new GetDashboardParameter();
        parameter.DashboardId = dashboardId;
        parameter.IncludeConfig = includeConfig;
        return this.dashboardApiService.getDashboard(parameter);
    }

    public createDashboard(
        displayName: string,
        description: string,
        config: string
    ) {
        const parameter = new CreateDashboardParameter();
        parameter.DisplayName = displayName;
        parameter.Description = description;
        parameter.Config = config;
        return this.dashboardApiService.createDashboard(parameter);
    }

    public updateDashboardConfig(dashboardId: number, config: string) {
        const parameter = new UpdateDashboardParameter();
        parameter.Action = UpdateDashboardAction.SetConfig;
        parameter.DashboardId = dashboardId;
        parameter.Config = config;
        return this.dashboardApiService.updateDashboard(parameter);
    }

    public updateDashboard(
        action: UpdateDashboardAction,
        dashboardId: number,
        isDefaultValue: boolean,
        displayName?: string,
        description?: string,
        isPrivate?: boolean,
        config?: string
    ) {
        const parameter = new UpdateDashboardParameter();
        parameter.Action = action;
        parameter.IsDefaultValue = isDefaultValue;
        parameter.DashboardId = dashboardId;
        parameter.DisplayName = displayName;
        parameter.Description = description;
        parameter.IsPrivate = isPrivate;
        parameter.Config = config;
        return this.dashboardApiService.updateDashboard(parameter);
    }

    public async deleteDashboard(dashboard: DashboardDto) {
        const parameter = new DeleteDashboardParameter();
        parameter.DashboardId = dashboard.DashboardId;
        await this.dashboardApiService.deleteDashboard(parameter);
        this.notifyDashboardDeleted(dashboard);
    }

    public getInitialDashboard(
        dashboardList: DashboardDto[],
        currentDashboardId: string
    ) {
        /** Return null If no dashboards */
        if (!dashboardList?.length) {
            return null;
        }

        /** Check If DashboardId Exist */
        const id = +currentDashboardId;
        const dashboard = dashboardList.find((a) => a?.DashboardId == id);
        if (dashboard) {
            return dashboard;
        }

        /** return the first user's favorite */
        const favoriteDashboard = dashboardList.find(
            (d) => d.IsDefaultDashboard
        );
        if (favoriteDashboard) {
            return favoriteDashboard;
        }

        /** Return Oldest Private Dashboard in Case That No Chosen Default Dashboard */
        if (dashboardList.some((d) => d.IsPrivate)) {
            const privateDashboards = dashboardList
                .filter((d) => d.IsPrivate)
                .sort((d1, d2) =>
                    DateTimeUtil.compareIsoStrings(
                        d1.CreationTime,
                        d2.CreationTime
                    )
                );
            return privateDashboards[0];
        } else {
            /** Return Oldest Public Dashboard in Case That No Chosen Default Dashboard And Private Dashboard List Is Empty */
            const privateDashboards = dashboardList.sort((d1, d2) =>
                DateTimeUtil.compareIsoStrings(d1.CreationTime, d2.CreationTime)
            );
            return privateDashboards[0];
        }
    }

    public async openCreateDashboardModal(dashboard: DashboardDto) {
        return this.openDashboardModal(dashboard, false);
    }

    public async openEditDashboardModal(dashboard: DashboardDto) {
        return this.openDashboardModal(dashboard, true);
    }

    private async openDashboardModal(dashboard: DashboardDto, edit: boolean) {
        await this.dxyModalService.open<
            DxyPublicEditModalComponent,
            IPublicEditModalResolve,
            void
        >({
            componentType: DxyPublicEditModalComponent,
            data: {
                dto: dashboard,
                edit,
                titleKey: edit
                    ? 'UI.DashboardGrid.UpdateDashboardModal.titleUpdate'
                    : 'UI.DashboardGrid.UpdateDashboardModal.titleCreate',
                featureCode: edit ? 'SAVED_DASHBOARD,U' : 'SAVED_DASHBOARD,C',
                featureCodePublish: 'PUBLISH_SAVED_DASHBOARD,U',
                canChangePrivate:
                    dashboard.IsUserDashboardCreator &&
                    this.securityService.isCurrentUserClientAdmin(),
            },
        });
    }

    //#endregion

    //#region Dashboard Events

    public get dashboardChange$() {
        return this.dashboardChange.asObservable();
    }

    private dashboardChange = new Subject<DashboardDto>();

    public get dashboardDeleted$() {
        return this.dashboardDeleted.asObservable();
    }

    private dashboardDeleted = new Subject<DashboardDto>();

    public get dashboardCreated$() {
        return this.dashboardCreated.asObservable();
    }

    private dashboardCreated = new Subject<DashboardDto>();

    public get dashboardCreate$() {
        return this.dashboardCreate.asObservable();
    }

    private dashboardCreate = new Subject<DashboardDto>();

    public notifyDashboardChange(currentDashboard?: DashboardDto) {
        this.log('notifyDashboardChange', currentDashboard?.DisplayName);
        this.goToDashboard(currentDashboard?.DashboardId);
        this.dashboardChange.next(currentDashboard);
    }

    public notifyDashboardDeleted(deletedDashboard: DashboardDto) {
        this.log('notifyDashboardDeleted', deletedDashboard?.DisplayName);
        this.dashboardDeleted.next(deletedDashboard);
    }

    public notifyDashboardCreated(createdDashboard: DashboardDto) {
        this.log('notifyDashboardCreated', createdDashboard?.DisplayName);
        this.goToDashboard(createdDashboard?.DashboardId);
        this.dashboardCreated.next(createdDashboard);
    }

    public notifyDashboardCreate(createDashboard: DashboardDto) {
        this.log('notifyDashboardCreate', createDashboard?.DisplayName);
        this.dashboardCreate.next(createDashboard);
    }

    public goToDashboard(dashboardId: number) {
        this.navigationService.goToClientDashboard(false, dashboardId);
    }

    public updateDashboardUrl(dashboardId: number) {
        this.navigationService.updateUrlWithoutChangingState({
            [Constants.Nav.ParamKey.DashboardId]: dashboardId?.toString(),
        });
    }

    //#endregion

    //#region filtered view
    public async goToFilteredView(
        filters: Filter[],
        spaceIdr: SpaceIdentifier,
        dgModule: DgModule,
        moduleView?: ModuleView
    ) {
        const fv = this.filteredViewService.getNewFilteredView(DgZone.Module, {
            dgModule,
        });
        fv.DisplayName = this.translate.instant(
            'UI.Global.filter.untitledFilterName'
        );
        filters.forEach((filter) => {
            if (filter.AttributePath === 'LongDescription') {
                filter.AttributePath =
                    ServerConstants.PropertyName.LongDescriptionRaw;
            }
        });
        fv.ListFilter = fv.savedListFilter = filters;
        await this.navigationService.goToFilteredView(fv, spaceIdr, moduleView);
    }

    public goToEntityTypeFilteredGridView(
        entityType: EntityType,
        spaceIdr: SpaceIdentifier
    ) {
        this.navigationService.goToEntityTypeFilteredGridView(
            entityType,
            spaceIdr
        );
    }

    //#endregion

    //#region for StandaloneWidgetConfigManager
    public async getWidgetConfigDb(configId: string, isUserConfig: boolean) {
        if (isUserConfig) {
            const userSetting = await this.userService.getUserSettingValue(
                userSettingsValues.widgetConf.category,
                configId
            );
            return userSetting?.Value
                ? (JSON.parse(userSetting?.Value) as IWidgetConfigDb)
                : undefined;
        } else {
            return this.getClientUISettingsWidgets()?.find(
                (p) => p.id === configId
            );
        }
    }

    public async saveWidgetConfigDb(
        config: IWidgetConfigDb,
        isUserConfig: boolean
    ) {
        if (isUserConfig) {
            await this.userService.setUserSettingValue(
                userSettingsValues.widgetConf.category,
                config.id,
                JSON.stringify(config)
            );
        } else {
            const widgets = this.getClientUISettingsWidgets() ?? [];
            CollectionsHelper.replaceOrAppend(
                widgets,
                (wc) => wc.id === config.id,
                config
            );
            await this.clientApiService.updateClientSpace({ widgets });
        }
    }

    private getClientUISettingsWidgets() {
        return this.appDataService.clientWidgetConfigs;
    }

    //#endregion
}

//#region  exported types

/** a dashboard-grid configuration */
export interface IDashboardGridConfig {
    /** configuration of widgets in the grid */
    widgets: IWidgetConfig[];

    /** types of widgets available to the user for adding to the grid */
    availableTypes?: WidgetType[];

    /** for to check if a reader of the dashboard can access a widget's space */
    allowedSpaces?: ISpaceIdentifier[];
}

/** layout information for a row of widgets in the grid  */
export interface IDashboardGridRow {
    /** height in number of grid cells. A grid cell in 70px high by default, including 2 * 5px margins */
    height: number;

    /** widget content configurations in the row.
     * They will be stretched out to fill the grid's width */
    contents: IWidgetContentConfig[];
}

export interface IWidgetTypeInfo {
    name: string;
    nameTranslateKey: string;
    imgSrc: string;
    category: WidgetCategory;
    spaceVersionSelectorProjectsOnly?: boolean;
}

export interface IWidgetTypeInfoTranslated extends IWidgetTypeInfo {
    categoryDisplayName: string;
    typeDisplayName: string;
}

export interface IOption {
    value: string | number;
    label: string;
    data?: any;
    glyphClass?: string;
}

export type CommonFieldName = 'titleText' | 'description';

/** library category data  */
export interface ILibraryCategory {
    titleText: string;
    widgetTypeInfos: IWidgetTypeInfo[];
    isCollapsed?: boolean;
}

/** data for the widget settings form */
export interface IWidgetSettingsData {
    config: IWidgetContentConfig;
    isCreation: boolean;
    hideFilters?: boolean;
    save: () => void;
    cancel: () => void;
    remove: () => void;
    reset?: () => void;
    commonFieldEmptyToDefault: boolean;

    getOptions(param: IWidgetConfigParam): Promise<IListOptionItem[]>;
}

export enum WidgetFilterType {
    unknown = 0,
    spaceVersion,
    dgModule,
    entityTypes,
    owners,
    stewards,
    tags,
    entityStatuses,
    textAttributes,
    licenseTypes,
    nbDays,
    commonAttributes,
}

/** displayed filter item */
export interface IWidgetFilter {
    filterType: WidgetFilterType;
    uiLabel: string;
    uiValue: string;
}

export interface IOptionsGetWidgetFilters {
    /** for all-spaces, pass a SpaceIdentifier with null spaceId */
    spaceIdr?: ISpaceIdentifier;
    dgModule?: DgModule;
    entityTypes?: EntityType[];
    tagIds?: string[];
    ownerIds?: string[];
    stewardIds?: string[];
    userIds?: string[];
    licenseTypes?: LicenseLevel[];
    nbDays?: number;
    entityStatuses?: EntityLifecycleStatus[];
    commonAttributes?: string[];
    textAttributes?: string[];
    userServices?: string[];
    userRoles?: string[];
    teams?: string[];
}

//#endregion
