<div class="actions-row">
    <dxy-search-input
        *ngIf="hasTeamMembers"
        class="no-border-top"
        [(searchString)]="searchString"
        (onSearchString)="onSearchTermChange()"
        dxyLogFunctional="TEAM_MEMBER_SEARCH,R"
    >
    </dxy-search-input>
    <button
        *ngIf="hasEditRights"
        mat-mini-fab
        (click)="onClickCreateBtn()"
        [matTooltip]="'UI.Dialog.btnAdd' | translate"
        dxyLogFunctional="START_ADD_TEAM_MEMBER,R"
    >
        <span class="glyph-add"></span>
    </button>
    <dxy-grid-column-selector
        *ngIf="hasTeamMembers"
        [forGrid]="grid"
        dxyLogFunctional="MANAGE_COLUMNS_TEAM_MEMBERS,R"
    ></dxy-grid-column-selector>
    <button
        *ngIf="hasEditRights"
        mat-icon-button
        [matMenuTriggerFor]="optionsMenu"
    >
        <span class="glyph-splitter"></span>
    </button>
    <mat-menu
        #optionsMenu="matMenu"
        class="dg5-mat-menu team-members-actions-dropdown"
    >
        <button
            mat-menu-item
            [disabled]="!hasSelectedRows"
            (click)="onClickSetAdminBtn($event)"
            dxyLogFunctional="DEFINE_TEAM_ADMIN,R"
        >
            <span class="glyph-team-owner"></span>
            <span translate>UI.Teams.Members.setAdminBtn</span>
        </button>
        <button mat-menu-item (click)="onClickImportBtn()">
            <span class="glyph-upload"></span>
            <span translate>UI.Global.btnImport</span>
        </button>
        <button
            mat-menu-item
            [disabled]="!hasSelectedRows"
            (click)="onClickDeleteBtn($event)"
        >
            <span class="glyph-delete"></span>
            <span translate>UI.Global.btnDelete</span>
        </button>
    </mat-menu>
</div>

<dxy-grid
    [columns]="columns"
    [class.hidden]="!hasTeamMembers"
    [items]="filteredTeamMembers"
    [config]="gridConfig"
    appGridUserSettings="client-admin-team-members"
    #grid
></dxy-grid>

<div *ngIf="!hasTeamMembers" class="empty-search-result">
    <img src="/images/placeholders/team-members.svg" alt="no members" />
    <span class="empty-search-result-text" translate>
        UI.Teams.Members.emptyLbl
    </span>
</div>
