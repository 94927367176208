import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import {
    IShift,
    MovePhase,
    Rect,
    TDomElement,
    Vect2,
} from '@datagalaxy/core-2d-util';
import { drag as d3drag } from 'd3-drag';
import { Subject } from 'rxjs';
import { ManagedItem } from '../../node/managed-item';
import { GraphManager } from '../../graph-manager';
import { selectAll } from 'd3-selection';
import { SelectionTool } from '../selection';
import {
    INodeDragEvent,
    INodeDragToolOptions,
    NodeDragEvent,
} from './node-drag.types';
import { GraphNodeEvent } from '../../graph-manager.types';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { GridManager } from '../../grid';

/**
 * ## Role
 * Handle dragging of nodes when they are draggable
 *
 * ## Features
 * - Move a standalone node
 * - Move a container, containing several nodes
 * - Move a selection of nodes
 */
export class NodeDragTool<
    NodeData = unknown,
    EdgeData = unknown
> extends BaseGraphicalManager {
    public static isTextSelectable(el: Element) {
        return (
            el &&
            // unfortunatelty we can not easily check if a text node inside the element is hovered
            el.classList.contains('text-select')
            //|| ['P', 'SPAN'].includes(el.tagName)
        );
    }

    private static readonly dragClass = 'gs-dragging';

    private readonly dragBehavior = d3drag<
        TDomElement,
        ManagedItem<NodeData>
    >();
    private readonly dragged = new Subject<INodeDragEvent<NodeData>>();
    /**
     * Represents nodes that are not directly manipulated by the user.
     * These nodes may either be part of the current selection or contained within
     * the node being dragged, when the dragged node is a container.
     */
    private associatedNodes: ManagedItem<NodeData>[] = [];
    private phase: MovePhase;

    public get dragged$() {
        return this.dragged.asObservable();
    }

    constructor(
        private graph: GraphManager<NodeData>,
        private gridManager: GridManager,
        private selectionManager: SelectionTool<NodeData, EdgeData>,
        private zoomAdapter: ZoomedViewAdapter,
        private options?: INodeDragToolOptions<TDomElement, NodeData, any>
    ) {
        super();

        const filter = (event: any, node: ManagedItem<NodeData>) =>
            !event.button &&
            !NodeDragTool.isTextSelectable(event.target as Element) &&
            !node.unDraggable;

        this.dragBehavior
            .filter(filter)
            .subject((_, d) =>
                d.rect
                    ? this.zoomAdapter.fixedToZoomed.xy(d.rect.x, d.rect.y)
                    : d
            )
            .on('start', (event: NodeDragEvent<NodeData>, d) =>
                this.onDragStart(d, event)
            )
            .on('drag', (event: NodeDragEvent<NodeData>, d) =>
                this.onDrag(d, event)
            )
            .on('end', (event: NodeDragEvent<NodeData>, d) =>
                this.onDragEnd(d, event)
            );

        super.subscribe(graph.nodesEvents$, (event) =>
            this.onGraphChange(event)
        );
    }

    public dispose() {
        this.dragBehavior.on('.drag', null);
        this.dragged.complete();
        super.dispose();
    }

    private onGraphChange(event: GraphNodeEvent) {
        if (this.options?.disabled) {
            return;
        }
        if (event.removed?.length) {
            const nodeElements = event.removed.map((it) => it.el);
            selectAll(nodeElements).on('.drag', null);
        } else if (event.added?.length) {
            const nodes = event.added;

            selectAll(nodes.map((it) => it.el))
                .data(nodes)
                .call(this.dragBehavior);
        }
    }

    private onDragStart(
        node: ManagedItem<NodeData>,
        event: NodeDragEvent<NodeData>
    ) {
        const onDragStart = this.options?.callbacks?.onDragStart;

        /**
         * Temporary code to support lineage + force graph
         */
        if (onDragStart) {
            onDragStart(node?.data || (node as any), event as any, node.el);
            return;
        }

        this.associatedNodes = this.getAssociatedNodes(node);
        const nodes = this.associatedNodes.concat([node]);

        this.dragged.next({ phase: (this.phase = MovePhase.start), nodes });
    }

    private onDrag(
        node: ManagedItem<NodeData>,
        event: NodeDragEvent<NodeData>
    ) {
        const onDragMove = this.options?.callbacks?.onDragMove;

        /**
         * Temporary code to support lineage + force graph
         */
        if (onDragMove) {
            onDragMove(node?.data || (node as any), event as any, node.el);
            return;
        }

        const shift = this.getShiftFromDragEvent(node, event);
        const nodes = this.associatedNodes.concat([node]);
        nodes.forEach((n) => this.shiftNodePosition(n, shift));

        this.dragged.next({ phase: (this.phase = MovePhase.move), nodes });
    }

    private onDragEnd(
        node: ManagedItem<NodeData>,
        event: NodeDragEvent<NodeData>
    ) {
        const onDragEnd = this.options?.callbacks?.onDragEnd;

        /**
         * Temporary code to support lineage + force graph
         */
        if (onDragEnd) {
            onDragEnd(node?.data || (node as any), event as any, node.el);
            return;
        }

        const nodes = this.associatedNodes.concat([node]);
        this.associatedNodes = [];

        const phase =
            this.phase === MovePhase.move ? MovePhase.end : MovePhase.cancel;
        this.dragged.next({ phase, nodes });
    }

    private getShiftFromDragEvent(
        node: ManagedItem<NodeData>,
        event: NodeDragEvent<NodeData>
    ): IShift {
        const adapter = this.zoomAdapter.zoomedToFixed;
        const point = adapter.xy(event.x, event.y);
        return { dx: point.x - node.rect.x, dy: point.y - node.rect.y };
    }

    private shiftNodePosition(node: ManagedItem<NodeData>, shift: IShift) {
        if (this.gridManager.isSnapToGridActive) {
            const nodeShiftPosition = Vect2.from(node.rect).shift(
                shift.dx,
                shift.dy
            );
            const roundedPosition = Vect2.roundToMultiple(
                nodeShiftPosition,
                this.gridManager.cellSize
            );
            node.setPosition(roundedPosition);
        } else {
            node.shiftPosition(shift);
        }
    }

    /**
     * Get nodes that are associated with the dragged node.
     * These nodes may either be part of the current selection or contained within
     * the node being dragged, when the dragged node is a container.
     * - If there is a selection, return all selected nodes except the dragged node
     * - If the dragged node is a container, return all nodes contained within it
     * - Otherwise, return an empty array
     */
    private getAssociatedNodes(
        node: ManagedItem<NodeData>
    ): ManagedItem<NodeData>[] {
        const nodeSelection = this.selectionManager.nodeSelection;
        if (nodeSelection?.length > 1) {
            return nodeSelection.filter(
                (it) => !it.isContainer && !it.unDraggable && it != node
            );
        } else if (node.isContainer && !nodeSelection.includes(node)) {
            const isContained = Rect.makeIsContained(node.rect);
            return this.graph.nodes.filter(
                (it) =>
                    !it.uncontainable &&
                    !it.unDraggable &&
                    it != node &&
                    isContained(it.rect)
            );
        }
        return [];
    }
}
