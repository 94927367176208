import { DgModuleDefinition } from '../dg-module-definition';
import { DgModuleName } from '../dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

class MainSearchDgModule extends DgModuleDefinition {
    public name: DgModuleName = 'MainSearch';
    public translateKey = 'DgServerTypes.DataGalaxyModule.MainSearch';
    public colorGlyphClass = '';
    public glyphClass = 'glyph-filter-empty';
    public graphicalColor = GraphicalColor._none;
    public position = 0;
    public categoryKey = '';
}

export default new MainSearchDgModule();
