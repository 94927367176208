import { FieldType } from '../../types/enums/field-type.enum';

export const qlikcloudConfig = [
    {
        payloadField: 'server',
        formField: 'tenantHostname',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'apiKey',
        formMandatory: true,
        isPassword: true,
        passwordMaxLength: 700,
    },
    {
        payloadField: 'managed-space',
        formField: 'managedSpace',
        type: FieldType.boolean,
        formMandatory: false,
    },
    {
        payloadField: 'managed-space-regex',
        formField: 'managedSpaceRegex',
        formMandatory: false,
        type: FieldType.text,
    },
    {
        payloadField: 'shared-space',
        formField: 'sharedSpace',
        type: FieldType.boolean,
        formMandatory: false,
    },
    {
        payloadField: 'shared-space-regex',
        formField: 'sharedSpaceRegex',
        formMandatory: false,
        type: FieldType.text,
    },
    {
        payloadField: 'ignore-tables-and-master-items',
        formField: 'ignoreTableAndMasterItems',
        type: FieldType.boolean,
        formMandatory: false,
    },
];
