<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.apiDocumentation.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <div class="public-api-container">
        <div class="left-section">
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.description.title
                </div>
                <div class="description-content content">
                    <span translate
                        >UI.apiDocumentation.description.content.part1</span
                    >
                    <span translate
                        >UI.apiDocumentation.description.content.part2</span
                    >
                    <span translate
                        >UI.apiDocumentation.description.content.part3</span
                    >
                </div>
                <pre class="copy-container">
                    <span>{{v2ApiUrl}}</span>
                    <button mat-icon-button (click)="copyUrl()"><i class="glyph-file-copy"></i></button>
                </pre>
            </div>
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.needHelp.title
                </div>
                <div class="content">
                    <a (click)="onClickQuickStart()" translate
                        >UI.apiDocumentation.needHelp.link
                    </a>
                    <span translate>UI.apiDocumentation.needHelp.content</span>
                </div>
            </div>
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.documentation.title
                </div>
                <div class="content">
                    <a (click)="onClickPublicApi()" translate
                        >UI.apiDocumentation.documentation.link</a
                    >
                    <span translate
                        >UI.apiDocumentation.documentation.content</span
                    >
                </div>
            </div>
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.changelog.title
                </div>
                <div class="content">
                    <span class="description-content content" translate
                        >UI.apiDocumentation.changelog.details
                    </span>
                    <a (click)="onClickChangelog()" translate
                        >UI.apiDocumentation.changelog.link</a
                    >
                    <span translate>UI.apiDocumentation.changelog.content</span>
                </div>
            </div>
        </div>
        <div class="right-section">
            <img class="api-icon" src="/images/connectors.svg" />
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.links.title
                </div>
                <div class="links">
                    <button mat-button (click)="onDownloadDocumentationClick()">
                        <span class="glyph-upload"></span>
                        <span translate
                            >UI.apiDocumentation.links.openApiLink</span
                        >
                    </button>
                    <button mat-button (click)="onClickPublicApi()">
                        <span class="glyph-help"></span>
                        <span translate
                            >UI.apiDocumentation.links.documentationLink</span
                        >
                    </button>
                    <button mat-button (click)="onClickChangelog()">
                        <span class="glyph-help"></span>
                        <span translate
                            >UI.apiDocumentation.links.changelogLink</span
                        >
                    </button>
                    <button mat-button (click)="onClickQuickStart()">
                        <span class="glyph-help"></span>
                        <span translate
                            >UI.apiDocumentation.links.quickStartLink</span
                        >
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
