import { StringUtil } from '@datagalaxy/core-util';
import { AttributeValueTranslation } from '@datagalaxy/webclient/multilingual/domain';

export interface AttributeValueTranslationResult {
    value: string;
    translation?: AttributeValueTranslation;
    aiOrigin?: boolean;
}

export function mapAttributeValueTranslationToResult(
    translation: AttributeValueTranslation,
    defaultValue: string
): AttributeValueTranslationResult {
    const emptyTranslation = StringUtil.isNullOrEmpty(
        translation.value as string
    );
    const value = emptyTranslation ? defaultValue : translation?.value ?? '';
    return {
        value,
        translation,
        aiOrigin: translation?.origin === 'ai',
    };
}
