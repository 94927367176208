<ng-container *ngIf="visible">
    <div *ngIf="opened" class="updates">
        <ng-container *ngFor="let updatedProperty of translatedUpdates">
            <div class="name" dxyEllipsisTooltip>
                {{ updatedProperty.attributeName }}
            </div>
            <div class="value-update">
                <ng-container *ngIf="updatedProperty.previousValue">
                    <span class="previousValue" dxyEllipsisTooltip>
                        {{ updatedProperty.previousValue }}
                    </span>
                    <i class="glyph-arrow-right"></i>
                </ng-container>

                <span class="value" dxyEllipsisTooltip>
                    {{ updatedProperty.value }}
                </span>
            </div>
        </ng-container>
    </div>
    <dxy-show-more-button
        (click)="toggleList($event)"
        [isLess]="opened"
    ></dxy-show-more-button>
</ng-container>
