import { deserialize } from 'cerialize';

export class SpaceArticleDto {
    @deserialize ArticleUid!: string;
    @deserialize SpaceUid!: string;
    @deserialize Title!: string;
    @deserialize Description!: string;
    @deserialize ImageHash!: string;
    @deserialize CreationTime!: string;
    @deserialize CreationUserId!: string;
    @deserialize LastModificationTime!: string;
}
