import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { SpaceArticleDto } from './article';

@inheritSerialization(BaseServiceParameter)
export class GetSpaceArticlesParameter extends BaseServiceParameter {
    @serialize SpaceGuid?: string;
    @serialize Size?: number;
}

@inheritSerialization(BaseServiceResult)
export class GetSpaceArticlesResult extends BaseServiceResult {
    @deserializeAs(SpaceArticleDto) Articles!: SpaceArticleDto[];
}

@inheritSerialization(BaseServiceResult)
export class GetSpaceArticleResult extends BaseServiceResult {
    @deserializeAs(SpaceArticleDto) Article!: SpaceArticleDto;
}

@inheritSerialization(BaseServiceParameter)
export class CreateSpaceArticleParameter extends BaseServiceParameter {
    @serialize SpaceGuid?: string;
    @serialize Title?: string;
    @serialize Description?: string;

    //#region image content
    @serialize FileContent?: string;
    @serialize FileName?: string;
    @serialize FileType?: string;
    //#endregion image content
}

@inheritSerialization(CreateSpaceArticleParameter)
export class UpdateSpaceArticleParameter extends CreateSpaceArticleParameter {
    @serialize ArticleUid?: string;
    /**
     * If true, it will delete the space article image
     * This will be renamed with the back-end to avoid confusion
     */
    @serialize public IsDelete?: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteSpaceArticleParameter extends BaseServiceParameter {
    @serialize ArticleUid!: string;
}
