import { Component, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { DomUtil } from '@datagalaxy/core-util';
import { ToasterService } from '../../../../services/toaster.service';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-test-error-modal',
    templateUrl: './dxy-test-error-modal.component.html',
    styleUrls: ['dxy-test-error-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, TranslateModule, MatLegacyButtonModule],
})
export class DxyTestErrorModalComponent extends DxyBaseModalComponent<
    string,
    void
> {
    public get message() {
        return this.data;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: string,
        public dialogRef: MatDialogRef<DxyTestErrorModalComponent>,
        private toasterService: ToasterService
    ) {
        super(dialogRef, data);
    }

    public close() {
        this.dialogRef.close();
    }

    public copy() {
        DomUtil.copyToClipboard(this.message);
        this.toasterService.successToast({
            messageKey: 'UI.Connector.Wizard.Step3.Test.errorCopied',
        });
    }
}
