import { IFilterItemModelSourceData } from './IFilterItemModelSourceData';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class FilterItemModelSourceData implements IFilterItemModelSourceData {
    public constructor(
        public attributeMeta: AttributeMetaInfo,
        public spaceIdr: ISpaceIdentifier,
        public currentModelId?: string
    ) {}
}
