<dxy-grid
    *ngIf="gridState$ | async as gridState"
    [items]="entities"
    [columns]="gridState.columns"
    [config]="gridState.config"
    [gridState]="gridState.persistedState"
    (gridStateChange)="onGridStateChange($event)"
    (rowClick)="onRowClick($event)"
    (selectionChange)="selectionChange.emit($event)"
    (serverSideSort)="onServerSideSortChange($event)"
></dxy-grid>
