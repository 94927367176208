<dxy-field-text
    labelKey="UI.Modeler.PrimaryKeySettingsModal.lblDisplayName"
    [placeholder]="
        'UI.Modeler.ForeignKeyGrid.lblPrimaryKeyTechnicalName' | translate
    "
    [(ngModel)]="currentPkName"
    name="primaryKeyName"
    required
    [readonly]="readOnly"
    (ngModelChange)="updateOutput()"
></dxy-field-text>

<div *ngIf="!noTableName" class="structure">
    <label translate>UI.Modeler.PrimaryKeySettingsModal.lblParentTable</label>
    <p class="structure-info">
        <i [ngClass]="tableGlyphClass"></i>
        <span>{{ currentTableTechnicalName }}</span>
    </p>
</div>

<div class="separator"></div>

<dxy-field-text
    *ngIf="!readOnly"
    labelKey="UI.Modeler.PrimaryKeySettingsModal.lblAddColumn"
    [placeholder]="'UI.Modeler.ForeignKeyGrid.lblSelectAColumn' | translate"
    [(ngModel)]="currentSearch"
    [matAutocomplete]="auto"
    name="currentSearch"
></dxy-field-text>
<mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelectColumn($event)"
>
    <mat-option
        *ngFor="let value of getAvailableColumns(currentSearch)"
        [value]="value"
    >
        {{ value.TechnicalName }}
    </mat-option>
</mat-autocomplete>

<dxy-edit-grid
    [data]="gridData"
    (onValueChanged)="onColumnPropertyChanged($event)"
    logId="primary-key-settings-content"
></dxy-edit-grid>
