import { BehaviorSubject, of, ReplaySubject, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseUiService } from '@datagalaxy/core-ui';
import { ISuggestionParameters, ISuggestionRow } from './suggestion.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { SuggestionService } from './suggestion.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { AttributeSuggestionAdapterFactory } from './suggestion-adapter/attribute-suggestion-adapter.factory';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';

/**
 * ## Role
 * Manages suggestion panel components shared state
 *
 * ## Type
 * Statefull
 */
@Injectable({ providedIn: 'root' })
export class SuggestionPanelUiService extends BaseUiService {
    private static readonly defaultParameters: ISuggestionParameters = {
        suggestionType: SuggestionType.Tag,
        pageSize: 7,
    };

    public get parameters$() {
        return this.parameters.asObservable();
    }

    private readonly parameters = new BehaviorSubject<ISuggestionParameters>(
        SuggestionPanelUiService.defaultParameters
    );

    public get settings$() {
        return this.suggestionService.settings$;
    }

    public get userChoice$() {
        return this.suggestionService.userChoice$;
    }

    public get notifyNewSuggestions$() {
        return this.settings$.pipe(
            switchMap((settings) =>
                !settings || settings.disabled
                    ? of(false)
                    : this.notifyNewSuggestions.asObservable()
            )
        );
    }

    private readonly notifyNewSuggestions = new ReplaySubject<boolean>();

    public get allSuggestionsLoaded$() {
        return this.allSuggestionsLoaded.asObservable();
    }

    private readonly allSuggestionsLoaded = new ReplaySubject<boolean>();

    public get loading$() {
        return this.loading.asObservable();
    }

    private readonly loading = new BehaviorSubject<boolean>(false);

    public get panelOpened$() {
        return this.panelOpened.asObservable();
    }

    public get applyAllLoading$() {
        return this.suggestionService.applyAllLoading$;
    }

    private get panelOpenedValue() {
        return this.panelOpened.value;
    }

    private readonly panelOpened = new BehaviorSubject<boolean>(false);

    constructor(
        private attributeDataService: AttributeDataService,
        private attributeSuggestionAdapter: AttributeSuggestionAdapterFactory,
        private suggestionService: SuggestionService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    public initParameters(suggestionType: SuggestionType) {
        this.parameters.next({
            ...SuggestionPanelUiService.defaultParameters,
            suggestionType,
        });
    }

    public async toggleSuggestions() {
        const disabled = this.suggestionService.settingsValue.disabled;
        this.functionalLogService.logFunctionalAction(
            'ALL_SUGGESTIONS_PREF',
            CrudOperation.A,
            disabled ? CrudActionType.Unhide : CrudActionType.Hide
        );
        this.suggestionService.toggleSuggestions();
    }

    public toggleSuggestionPanel() {
        this.panelOpened.next(!this.panelOpenedValue);
        if (!this.panelOpenedValue) {
            return;
        }
        this.functionalLogService.logFunctionalAction(
            'SUGGESTIONS_CENTER',
            CrudOperation.R
        );
    }

    public openSuggestionPanel() {
        if (this.panelOpenedValue) {
            return;
        }
        this.toggleSuggestionPanel();
    }

    public async initNotifyNewSuggestions() {
        const hasSuggestions = await this.suggestionService.hasSuggestions();
        this.notifyNewSuggestions.next(hasSuggestions);
    }

    public filterBySuggestionType(suggestionType: SuggestionType) {
        this.updateParameters({
            suggestionType,
            attributePath: null,
            attributeValues: null,
        });
    }

    public filterBySpaceIdr(spaceIdr: SpaceIdentifier) {
        this.updateParameters({
            spaceId: spaceIdr.spaceId ?? '',
            versionId: spaceIdr.versionId ?? '',
        });
    }

    public filterByAttributeType(
        attributePath: string,
        attributeValues: string[]
    ) {
        this.updateParameters({
            attributePath,
            attributeValues,
        });
    }

    public resetAttributeFilter() {
        this.updateParameters({
            attributeValues: null,
            attributePath: null,
        });
    }

    public updateParameters(parameters: Partial<ISuggestionParameters>) {
        this.parameters.next({
            ...this.parameters.value,
            ...parameters,
        });
    }

    public async getFilteredSuggestions(): Promise<ISuggestionRow[]> {
        return await this.loadSuggestions(this.parameters.value);
    }

    public async getMoreFilteredSuggestions(
        excludedHashcodes: string[]
    ): Promise<ISuggestionRow[]> {
        this.functionalLogService.logFunctionalAction(
            'SUGGESTIONS_CENTER_SHOW_MORE',
            CrudOperation.R
        );
        return await this.loadSuggestions({
            ...this.parameters.value,
            excludedHashcodes,
        });
    }

    private async loadSuggestions(p: ISuggestionParameters) {
        this.loading.next(true);
        try {
            const res = await this.suggestionService.getUserSuggestions(p);

            if (res.SuggestionElements.length === 0) {
                this.allSuggestionsLoaded.next(true);
                return [];
            }

            const serverTypeNames = CollectionsHelper.distinct(
                res.DataList.map((data) => ServerType[data.ServerType])
            );

            await this.attributeDataService.loadAttributes(serverTypeNames);

            const suggestions = res.SuggestionElements.map((se) => {
                const hdd = res.DataList.find(
                    (data) => data.ReferenceId === se.EntityReferenceId
                );
                return this.attributeSuggestionAdapter.getAttributeSuggestion(
                    hdd.ServerType,
                    se,
                    hdd
                );
            });
            const suggestionRows = suggestions
                .map((suggestion) => ({
                    HddData: suggestion.entity,
                    suggestion,
                }))
                .filter((r) => r.HddData);
            this.allSuggestionsLoaded.next(suggestionRows.length < p.pageSize);
            const orderedRows = CollectionsHelper.orderBy(
                suggestionRows,
                (r) => r.suggestion.score,
                'desc'
            );
            this.notifyNewSuggestions.next(false);
            return orderedRows;
        } finally {
            this.loading.next(false);
        }
    }
}
