import { deserialize, inheritSerialization } from 'cerialize';
import { CampaignEventBase } from './campaign-event-base';
import { registerTypedEvent } from '../events';

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignCreatedEvent')
export class CampaignCreatedEvent extends CampaignEventBase {}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignEntitiesAddedEvent')
export class CampaignEntitiesAddedEvent extends CampaignEventBase {
    @deserialize AddedEntitiesReferenceIds: string[];
}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignEntitiesRemovedEvent')
export class CampaignEntitiesRemovedEvent extends CampaignEventBase {
    @deserialize RemovedEntitiesReferenceIds: string[];
}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignPhaseChangedEvent')
export class CampaignPhaseChangedEvent extends CampaignEventBase {
    @deserialize PhaseGuid: string;
    @deserialize PhaseName: string;
}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignUpdatedEvent')
export class CampaignUpdatedEvent extends CampaignEventBase {}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('CampaignEndedEvent')
export class CampaignEndedEvent extends CampaignEventBase {}

@inheritSerialization(CampaignEventBase)
@registerTypedEvent('UserAssigneePhaseChangedEvent')
export class UserAssigneePhaseChangedEvent extends CampaignEventBase {
    @deserialize ReferenceIds: string[];
    @deserialize PhaseName: string;
}
