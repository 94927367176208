export const googleBigQueryFieldInfo = {
    projectId: {
        hint: {
            fr: 'Identifiant du projet Google Cloud Platform, exemple : titanium-atlas-349714',
            en: 'Google Cloud Platform project identifier, example : titanium-atlas-349714',
        },
    },
    projectImportType: {
        description: {
            fr: `Attention au mode "Import multi-projet"! Ce nouveau mode crée un niveau supplémentaire dans la hiérarchie des objets par rapport au mode mono-projet historique :
                - Mono-projet : BigQuery/Dataset/Table/…
                - Multi-projet: BigQuery/Project/Dataset/Table/…

                Si vous avez précédemment importé vos objets BigQuery dans la plateforme avec le mode mono-projet, il est préférable d'ajouter auparavant ce nouveau niveau intermédiaire. Veuillez suivre la documentation en ligne pour migrer vers la nouvelle structure avant de lancer un import multi-projet.`,
            en: `Be careful with the "Multi-project import" mode! This new mode creates an additional level in the objects hierarchy, comparing to the mono-project historical mode:
                - Mono-project: BigQuery/Dataset/Table/…
                - Multi-project: BigQuery/Project/Dataset/Table/…

                If you have previously imported your BigQuery objects in the platform using the mono-project mode, it should be better to add this new intermediate level. Please follow the online BigQuery documentation to migrate to the new structure before launching a multi-project import.`,
        },
    },
    tableLocation: {
        hint: {
            fr: 'Localisation du serveur où se situent les tables (sert pour le linéage uniquement)',
            en: 'Location of the server where the tables are located (used for lineage only)',
        },
    },
    dataset: {
        hint: {
            fr: 'Limite le périmètre à un ensemble de données contenu dans un projet',
            en: "Limits the scope to the project's dataset",
        },
    },
    getAutomaticLineage: {
        hint: {
            fr: 'Nécessite l’activation de l’API lineage, voir documentation',
            en: 'Requires lineage API activation, see documentation',
        },
    },
    password: {
        description: {
            fr: "L'authentification s'effectue à l'aide d'une clé privée (fichier .json) à générer et télécharger depuis le portail d'administration Google Cloud ",
            en: 'Authentication uses a private key (.json file) which must be generated and downloaded from the Google Cloud administration portal ',
        },
    },
};
