import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { deserialize, inheritSerialization, serialize } from 'cerialize';

@inheritSerialization(BaseServiceParameter)
export class GenerateGlossaryCommand extends BaseServiceParameter {
    @serialize DataReferenceIdList?: string[];
    @serialize TargetUniverseReferenceId?: string;
    @serialize TargetUniverseName?: string;
}

@inheritSerialization(BaseServiceParameter)
export class PreGenerateGlossaryQuery extends BaseServiceParameter {
    @serialize DataReferenceIdList: string[];

    constructor(sources: string[]) {
        super();
        this.DataReferenceIdList = sources;
    }
}

@inheritSerialization(BaseServiceResult)
export class PreGenerateGlossaryQueryResult extends BaseServiceResult {
    @deserialize TotalObjectCount!: number;
    @deserialize DataScienceMaxSupportedEntriesSize!: number;
}
