import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';

@inheritSerialization(BaseServiceParameter)
export class GetActivityLogParameter extends BaseServiceParameter {
    @serialize public DataReferenceId?: string;
    @serialize public DataTypeName?: string;
    @serialize public FromTime?: string;
    @serialize public ToTime?: string;
    @serialize public EntryTypes?: Array<string> = [];
    @serialize public UserId?: string;
    @serialize public CommitId?: string;
    @serialize public SearchXid?: string;
    @serialize public DisplayNamePartial?: string;
    @serialize public TechnicalNamePartial?: string;
    @serialize public MaxResults?: number;
    @serialize public IncludeChildren?: boolean;
    @serialize public IncludeTechnicalEntries?: boolean;
}

@inheritSerialization(BaseServiceResult)
export class GetActivityLogResult extends BaseServiceResult {
    @deserializeAs(ActivityLogEntry) public Entries: ActivityLogEntry[] = [];
}
