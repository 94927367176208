<svg
    *ngIf="mode === 'vertical'"
    [ngClass]="color"
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1658.1 864.79"
>
    <g>
        <g>
            <path
                class="text-shadow"
                d="M-1.36,621.95H84.25c20.46,0,38.54,3.9,54.25,11.71,15.7,7.81,27.91,18.8,36.61,32.98,8.7,14.18,13.06,30.69,13.06,49.54s-4.35,35.36-13.06,49.54c-8.71,14.18-20.91,25.17-36.61,32.98-15.71,7.81-33.79,11.71-54.25,11.71H-1.36v-188.45Zm83.46,152.65c18.85,0,33.88-5.25,45.09-15.75,11.22-10.5,16.83-24.72,16.83-42.67s-5.61-32.17-16.83-42.67c-11.22-10.5-26.25-15.75-45.09-15.75H42.26v116.84h39.84Z"
            />
            <path
                class="text-shadow"
                d="M365.05,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text-shadow"
                d="M497.23,803.41c-4.13,3.05-9.2,5.34-15.21,6.87-6.01,1.52-12.34,2.29-18.98,2.29-17.23,0-30.56-4.4-39.98-13.19-9.42-8.79-14.13-21.71-14.13-38.77v-59.5h-22.35v-32.31h22.35v-35.27h42v35.27h36.07v32.31h-36.07v58.96c0,6.1,1.57,10.81,4.71,14.13,3.14,3.32,7.58,4.98,13.33,4.98,6.64,0,12.29-1.79,16.96-5.38l11.31,29.61Z"
            />
            <path
                class="text-shadow"
                d="M663.34,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text-shadow"
                d="M832.41,713.22h39.84v76.46c-10.23,7.72-22.08,13.64-35.54,17.77-13.46,4.13-27.01,6.19-40.65,6.19-19.56,0-37.15-4.17-52.77-12.52-15.62-8.35-27.86-19.92-36.75-34.73-8.88-14.81-13.33-31.54-13.33-50.21s4.44-35.4,13.33-50.21c8.88-14.81,21.22-26.38,37.02-34.73,15.79-8.35,33.56-12.52,53.31-12.52,16.51,0,31.5,2.78,44.96,8.35,13.46,5.57,24.77,13.64,33.92,24.23l-28,25.85c-13.46-14.18-29.71-21.27-48.73-21.27-12.03,0-22.71,2.52-32.04,7.54-9.33,5.03-16.6,12.12-21.81,21.27-5.21,9.15-7.81,19.65-7.81,31.5s2.6,22.08,7.81,31.23c5.2,9.15,12.43,16.29,21.67,21.4,9.24,5.12,19.79,7.67,31.63,7.67,12.56,0,23.87-2.69,33.92-8.08v-55.19Z"
            />
            <path
                class="text-shadow"
                d="M1056.67,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text-shadow"
                d="M1095.43,610.64h42v199.76h-42v-199.76Z"
            />
            <path
                class="text-shadow"
                d="M1323.46,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text-shadow"
                d="M1454.3,810.41l-31.77-44.42-32.85,44.42h-45.77l55.73-72.96-53.84-71.88h47.11l30.69,42.27,31.5-42.27h44.69l-53.85,70.81,56,74.04h-47.65Z"
            />
            <path
                class="text-shadow"
                d="M1658.1,665.57l-65.42,153.73c-6.64,16.69-14.85,28.44-24.64,35.27-9.78,6.82-21.58,10.23-35.4,10.23-7.54,0-14.99-1.17-22.34-3.5-7.36-2.33-13.37-5.57-18.04-9.69l15.35-29.88c3.23,2.87,6.95,5.12,11.17,6.73,4.22,1.62,8.39,2.42,12.52,2.42,5.74,0,10.41-1.39,14-4.17,3.59-2.78,6.82-7.4,9.69-13.86l.54-1.35-62.73-145.92h43.34l40.65,98.27,40.92-98.27h40.38Z"
            />
        </g>
        <g>
            <path
                class="text"
                d="M-1.36,621.95H84.25c20.46,0,38.54,3.9,54.25,11.71,15.7,7.81,27.91,18.8,36.61,32.98,8.7,14.18,13.06,30.69,13.06,49.54s-4.35,35.36-13.06,49.54c-8.71,14.18-20.91,25.17-36.61,32.98-15.71,7.81-33.79,11.71-54.25,11.71H-1.36v-188.45Zm83.46,152.65c18.85,0,33.88-5.25,45.09-15.75,11.22-10.5,16.83-24.72,16.83-42.67s-5.61-32.17-16.83-42.67c-11.22-10.5-26.25-15.75-45.09-15.75H42.26v116.84h39.84Z"
            />
            <path
                class="text"
                d="M365.05,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text"
                d="M497.23,803.41c-4.13,3.05-9.2,5.34-15.21,6.87-6.01,1.52-12.34,2.29-18.98,2.29-17.23,0-30.56-4.4-39.98-13.19-9.42-8.79-14.13-21.71-14.13-38.77v-59.5h-22.35v-32.31h22.35v-35.27h42v35.27h36.07v32.31h-36.07v58.96c0,6.1,1.57,10.81,4.71,14.13,3.14,3.32,7.58,4.98,13.33,4.98,6.64,0,12.29-1.79,16.96-5.38l11.31,29.61Z"
            />
            <path
                class="text"
                d="M663.34,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text"
                d="M832.41,713.22h39.84v76.46c-10.23,7.72-22.08,13.64-35.54,17.77-13.46,4.13-27.01,6.19-40.65,6.19-19.56,0-37.15-4.17-52.77-12.52-15.62-8.35-27.86-19.92-36.75-34.73-8.88-14.81-13.33-31.54-13.33-50.21s4.44-35.4,13.33-50.21c8.88-14.81,21.22-26.38,37.02-34.73,15.79-8.35,33.56-12.52,53.31-12.52,16.51,0,31.5,2.78,44.96,8.35,13.46,5.57,24.77,13.64,33.92,24.23l-28,25.85c-13.46-14.18-29.71-21.27-48.73-21.27-12.03,0-22.71,2.52-32.04,7.54-9.33,5.03-16.6,12.12-21.81,21.27-5.21,9.15-7.81,19.65-7.81,31.5s2.6,22.08,7.81,31.23c5.2,9.15,12.43,16.29,21.67,21.4,9.24,5.12,19.79,7.67,31.63,7.67,12.56,0,23.87-2.69,33.92-8.08v-55.19Z"
            />
            <path
                class="text"
                d="M1056.67,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path class="text" d="M1095.43,610.64h42v199.76h-42v-199.76Z" />
            <path
                class="text"
                d="M1323.46,665.57v144.84h-40.11v-16.69c-10.41,12.57-25.49,18.85-45.23,18.85-13.64,0-25.98-3.05-37.02-9.15-11.04-6.1-19.7-14.81-25.98-26.11-6.29-11.31-9.42-24.41-9.42-39.31s3.14-28,9.42-39.31c6.28-11.31,14.94-20.01,25.98-26.12,11.04-6.1,23.38-9.15,37.02-9.15,18.48,0,32.93,5.83,43.34,17.5v-15.35h42Zm-51.69,101.63c7-7.27,10.5-17,10.5-29.21s-3.5-21.94-10.5-29.21c-7-7.27-15.8-10.9-26.38-10.9s-19.65,3.63-26.65,10.9c-7,7.27-10.5,17.01-10.5,29.21s3.5,21.94,10.5,29.21c7,7.27,15.88,10.9,26.65,10.9s19.38-3.63,26.38-10.9Z"
            />
            <path
                class="text"
                d="M1454.3,810.41l-31.77-44.42-32.85,44.42h-45.77l55.73-72.96-53.84-71.88h47.11l30.69,42.27,31.5-42.27h44.69l-53.85,70.81,56,74.04h-47.65Z"
            />
            <path
                class="text"
                d="M1658.1,665.57l-65.42,153.73c-6.64,16.69-14.85,28.44-24.64,35.27-9.78,6.82-21.58,10.23-35.4,10.23-7.54,0-14.99-1.17-22.34-3.5-7.36-2.33-13.37-5.57-18.04-9.69l15.35-29.88c3.23,2.87,6.95,5.12,11.17,6.73,4.22,1.62,8.39,2.42,12.52,2.42,5.74,0,10.41-1.39,14-4.17,3.59-2.78,6.82-7.4,9.69-13.86l.54-1.35-62.73-145.92h43.34l40.65,98.27,40.92-98.27h40.38Z"
            />
        </g>
    </g>
    <path
        class="logo"
        d="M1074.84,152.94C1047.63,64.48,965.16,0,867.89,0c-105.36,0-193.33,75.67-212.54,175.51-26.87-16.17-58.3-25.49-91.87-25.49-98.54,0-178.7,80.17-178.7,178.71s80.17,178.71,178.7,178.71h479.39c98.54,0,178.71-80.17,178.71-178.71,0-87.62-63.41-160.67-146.74-175.79ZM458.13,328.72c0-58.09,47.26-105.36,105.35-105.36s105.36,47.26,105.36,105.36-47.26,105.36-105.36,105.36-105.35-47.26-105.35-105.36Zm584.74,105.36c-27.4,0-52.38-10.52-71.13-27.72-18.16-16.66-30.47-39.59-33.48-65.32-20.8,11.79-44.8,18.57-70.37,18.57-.34,0-.67-.02-1.01-.03-78.46-.55-142.12-64.52-142.12-143.1s64.21-143.13,143.13-143.13c57.26,0,106.75,33.82,129.62,82.52,8.65,18.42,13.51,38.95,13.51,60.61,0,4.05-.21,8.04-.54,12.01,10.21-3.31,21.09-5.12,32.39-5.12,14.5,0,28.33,2.95,40.91,8.27,37.83,16,64.45,53.49,64.45,97.09,0,58.09-47.26,105.36-105.36,105.36Z"
    />
</svg>
<svg
    *ngIf="mode === 'horizontal'"
    [ngClass]="color"
    viewBox="0 0 439 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_4582_44442)">
        <path
            d="M121.11 13.7392H137.462C141.371 13.7392 144.824 14.4835 147.823 15.9701C150.823 17.4566 153.154 19.5527 154.816 22.2523C156.478 24.9539 157.311 28.1 157.311 31.6887C157.311 35.2774 156.48 38.4256 154.816 41.1251C153.152 43.8267 150.823 45.9208 147.823 47.4073C144.824 48.8939 141.369 49.6382 137.462 49.6382H121.11V13.7392ZM137.05 42.8189C140.651 42.8189 143.521 41.8191 145.663 39.8176C147.805 37.8181 148.876 35.1084 148.876 31.6887C148.876 28.269 147.805 25.5594 145.663 23.5598C143.521 21.5603 140.649 20.5585 137.05 20.5585H129.44V42.8169H137.05V42.8189Z"
            class="text"
        />
        <path
            d="M191.096 22.0471V49.6402H183.434V46.4599C181.445 48.8537 178.565 50.0506 174.795 50.0506C172.189 50.0506 169.833 49.4692 167.723 48.3065C165.615 47.1438 163.959 45.4863 162.761 43.3318C161.561 41.1774 160.962 38.683 160.962 35.8447C160.962 33.0063 161.561 30.5119 162.761 28.3575C163.961 26.2031 165.615 24.5455 167.723 23.3828C169.831 22.2201 172.189 21.6387 174.795 21.6387C178.325 21.6387 181.086 22.7511 183.075 24.972V22.0491H191.096V22.0471ZM181.223 41.4067C182.56 40.0227 183.228 38.1681 183.228 35.8426C183.228 33.5172 182.56 31.6625 181.223 30.2786C179.886 28.8946 178.206 28.2026 176.185 28.2026C174.164 28.2026 172.431 28.8946 171.094 30.2786C169.756 31.6625 169.089 33.5172 169.089 35.8426C169.089 38.1681 169.756 40.0227 171.094 41.4067C172.431 42.7907 174.127 43.4827 176.185 43.4827C178.242 43.4827 179.888 42.7907 181.223 41.4067Z"
            class="text"
        />
        <path
            d="M216.345 48.3065C215.557 48.8879 214.589 49.3244 213.441 49.6141C212.291 49.9038 211.083 50.0506 209.816 50.0506C206.525 50.0506 203.979 49.2138 202.18 47.5381C200.381 45.8624 199.481 43.4023 199.481 40.1535V28.8202H195.213V22.6667H199.481V15.9479H207.503V22.6667H214.393V28.8202H207.503V40.0509C207.503 41.2136 207.803 42.1108 208.402 42.7425C209.001 43.3761 209.851 43.6919 210.948 43.6919C212.217 43.6919 213.296 43.35 214.187 42.666L216.347 48.3065H216.345Z"
            class="text"
        />
        <path
            d="M248.073 22.0471V49.6402H240.41V46.4599C238.422 48.8537 235.541 50.0506 231.772 50.0506C229.166 50.0506 226.81 49.4692 224.7 48.3065C222.592 47.1438 220.936 45.4863 219.738 43.3318C218.538 41.1774 217.939 38.683 217.939 35.8447C217.939 33.0063 218.538 30.5119 219.738 28.3575C220.938 26.2031 222.592 24.5455 224.7 23.3828C226.808 22.2201 229.166 21.6387 231.772 21.6387C235.301 21.6387 238.063 22.7511 240.051 24.972V22.0491H248.073V22.0471ZM238.2 41.4067C239.537 40.0227 240.205 38.1681 240.205 35.8426C240.205 33.5172 239.537 31.6625 238.2 30.2786C236.862 28.8946 235.182 28.2026 233.161 28.2026C231.14 28.2026 229.408 28.8946 228.07 30.2786C226.733 31.6625 226.065 33.5172 226.065 35.8426C226.065 38.1681 226.733 40.0227 228.07 41.4067C229.408 42.7907 231.104 43.4827 233.161 43.4827C235.219 43.4827 236.865 42.7907 238.2 41.4067Z"
            class="text"
        />
        <path
            d="M280.368 31.1255H287.978V45.6915C286.023 47.1619 283.76 48.2905 281.191 49.077C278.619 49.8635 276.031 50.2558 273.425 50.2558C269.687 50.2558 266.329 49.4612 263.346 47.872C260.363 46.2829 258.023 44.0762 256.327 41.2559C254.63 38.4356 253.781 35.2472 253.781 31.6907C253.781 28.1342 254.63 24.9458 256.327 22.1256C258.023 19.3053 260.381 17.0986 263.398 15.5094C266.414 13.9203 269.808 13.1257 273.58 13.1257C276.735 13.1257 279.597 13.6567 282.169 14.7148C284.74 15.7749 286.899 17.3138 288.647 19.3294L283.298 24.2538C280.727 21.5543 277.624 20.2025 273.99 20.2025C271.692 20.2025 269.653 20.6812 267.87 21.6388C266.087 22.5963 264.699 23.9461 263.705 25.6901C262.711 27.4342 262.214 29.4337 262.214 31.6907C262.214 33.9477 262.711 35.897 263.705 37.639C264.699 39.3831 266.079 40.7409 267.844 41.7165C269.609 42.6902 271.624 43.179 273.887 43.179C276.287 43.179 278.445 42.666 280.366 41.6401V31.1255H280.368Z"
            class="text"
        />
        <path
            d="M323.203 22.0471V49.6402H315.54V46.4599C313.552 48.8537 310.671 50.0506 306.901 50.0506C304.295 50.0506 301.94 49.4692 299.83 48.3065C297.722 47.1438 296.066 45.4863 294.868 43.3318C293.668 41.1774 293.069 38.683 293.069 35.8447C293.069 33.0063 293.668 30.5119 294.868 28.3575C296.068 26.2031 297.722 24.5455 299.83 23.3828C301.938 22.2201 304.295 21.6387 306.901 21.6387C310.431 21.6387 313.193 22.7511 315.181 24.972V22.0491H323.203V22.0471ZM313.33 41.4067C314.667 40.0227 315.335 38.1681 315.335 35.8426C315.335 33.5172 314.667 31.6625 313.33 30.2786C311.992 28.8946 310.312 28.2026 308.291 28.2026C306.27 28.2026 304.537 28.8946 303.2 30.2786C301.863 31.6625 301.195 33.5172 301.195 35.8426C301.195 38.1681 301.863 40.0227 303.2 41.4067C304.537 42.7907 306.234 43.4827 308.291 43.4827C310.349 43.4827 311.994 42.7907 313.33 41.4067Z"
            class="text"
        />
        <path
            d="M330.607 11.5848H338.629V49.6402H330.607V11.5848Z"
            class="text"
        />
        <path
            d="M374.163 22.0471V49.6402H366.5V46.4599C364.512 48.8537 361.631 50.0506 357.861 50.0506C355.255 50.0506 352.9 49.4692 350.79 48.3065C348.682 47.1438 347.026 45.4863 345.828 43.3318C344.628 41.1774 344.029 38.683 344.029 35.8447C344.029 33.0063 344.628 30.5119 345.828 28.3575C347.028 26.2031 348.682 24.5455 350.79 23.3828C352.898 22.2201 355.255 21.6387 357.861 21.6387C361.391 21.6387 364.152 22.7511 366.141 24.972V22.0491H374.163V22.0471ZM364.29 41.4067C365.627 40.0227 366.295 38.1681 366.295 35.8426C366.295 33.5172 365.627 31.6625 364.29 30.2786C362.952 28.8946 361.272 28.2026 359.251 28.2026C357.23 28.2026 355.497 28.8946 354.16 30.2786C352.823 31.6625 352.155 33.5172 352.155 35.8426C352.155 38.1681 352.823 40.0227 354.16 41.4067C355.497 42.7907 357.194 43.4827 359.251 43.4827C361.308 43.4827 362.954 42.7907 364.29 41.4067Z"
            class="text"
        />
        <path
            d="M399.154 49.6402L393.087 41.1774L386.814 49.6402H378.072L388.716 35.7421L378.431 22.0491H387.431L393.294 30.1016L399.311 22.0491H407.847L397.562 35.5369L408.259 49.6402H399.156H399.154Z"
            class="text"
        />
        <path
            d="M438.082 22.0471L425.587 51.332C424.318 54.5123 422.749 56.7512 420.881 58.0507C419.011 59.3502 416.758 60 414.118 60C412.678 60 411.256 59.7767 409.85 59.3341C408.444 58.8896 407.297 58.274 406.405 57.4875L409.336 51.7947C409.953 52.3418 410.663 52.7683 411.47 53.0761C412.275 53.3838 413.071 53.5367 413.86 53.5367C414.957 53.5367 415.849 53.2712 416.535 52.7421C417.22 52.2111 417.837 51.332 418.386 50.1009L418.489 49.8454L406.508 22.0491H414.788L422.553 40.7691L430.369 22.0491H438.082V22.0471Z"
            class="text"
        />
        <path
            d="M121.11 13.7392H137.462C141.371 13.7392 144.824 14.4835 147.823 15.9701C150.823 17.4566 153.154 19.5527 154.816 22.2523C156.478 24.9539 157.311 28.1 157.311 31.6887C157.311 35.2774 156.48 38.4256 154.816 41.1251C153.152 43.8267 150.823 45.9208 147.823 47.4073C144.824 48.8939 141.369 49.6382 137.462 49.6382H121.11V13.7392ZM137.05 42.8189C140.651 42.8189 143.521 41.8191 145.663 39.8176C147.805 37.8181 148.876 35.1084 148.876 31.6887C148.876 28.269 147.805 25.5594 145.663 23.5598C143.521 21.5603 140.649 20.5585 137.05 20.5585H129.44V42.8169H137.05V42.8189Z"
            class="text"
        />
        <path
            d="M191.096 22.0471V49.6402H183.434V46.4599C181.445 48.8537 178.565 50.0506 174.795 50.0506C172.189 50.0506 169.833 49.4692 167.723 48.3065C165.615 47.1438 163.959 45.4863 162.761 43.3318C161.561 41.1774 160.962 38.683 160.962 35.8447C160.962 33.0063 161.561 30.5119 162.761 28.3575C163.961 26.2031 165.615 24.5455 167.723 23.3828C169.831 22.2201 172.189 21.6387 174.795 21.6387C178.325 21.6387 181.086 22.7511 183.075 24.972V22.0491H191.096V22.0471ZM181.223 41.4067C182.56 40.0227 183.228 38.1681 183.228 35.8426C183.228 33.5172 182.56 31.6625 181.223 30.2786C179.886 28.8946 178.206 28.2026 176.185 28.2026C174.164 28.2026 172.431 28.8946 171.094 30.2786C169.756 31.6625 169.089 33.5172 169.089 35.8426C169.089 38.1681 169.756 40.0227 171.094 41.4067C172.431 42.7907 174.127 43.4827 176.185 43.4827C178.242 43.4827 179.888 42.7907 181.223 41.4067Z"
            class="text"
        />
        <path
            d="M216.345 48.3065C215.557 48.8879 214.589 49.3244 213.441 49.6141C212.291 49.9038 211.083 50.0506 209.816 50.0506C206.525 50.0506 203.979 49.2138 202.18 47.5381C200.381 45.8624 199.481 43.4023 199.481 40.1535V28.8202H195.213V22.6667H199.481V15.9479H207.503V22.6667H214.393V28.8202H207.503V40.0509C207.503 41.2136 207.803 42.1108 208.402 42.7425C209.001 43.3761 209.851 43.6919 210.948 43.6919C212.217 43.6919 213.296 43.35 214.187 42.666L216.347 48.3065H216.345Z"
            class="text"
        />
        <path
            d="M248.073 22.0471V49.6402H240.41V46.4599C238.422 48.8537 235.541 50.0506 231.772 50.0506C229.166 50.0506 226.81 49.4692 224.7 48.3065C222.592 47.1438 220.936 45.4863 219.738 43.3318C218.538 41.1774 217.939 38.683 217.939 35.8447C217.939 33.0063 218.538 30.5119 219.738 28.3575C220.938 26.2031 222.592 24.5455 224.7 23.3828C226.808 22.2201 229.166 21.6387 231.772 21.6387C235.301 21.6387 238.063 22.7511 240.051 24.972V22.0491H248.073V22.0471ZM238.2 41.4067C239.537 40.0227 240.205 38.1681 240.205 35.8426C240.205 33.5172 239.537 31.6625 238.2 30.2786C236.862 28.8946 235.182 28.2026 233.161 28.2026C231.14 28.2026 229.408 28.8946 228.07 30.2786C226.733 31.6625 226.065 33.5172 226.065 35.8426C226.065 38.1681 226.733 40.0227 228.07 41.4067C229.408 42.7907 231.104 43.4827 233.161 43.4827C235.219 43.4827 236.865 42.7907 238.2 41.4067Z"
            class="text"
        />
        <path
            d="M280.368 31.1255H287.978V45.6915C286.023 47.1619 283.76 48.2905 281.191 49.077C278.619 49.8635 276.031 50.2558 273.425 50.2558C269.687 50.2558 266.329 49.4612 263.346 47.872C260.363 46.2829 258.023 44.0762 256.327 41.2559C254.63 38.4356 253.781 35.2472 253.781 31.6907C253.781 28.1342 254.63 24.9458 256.327 22.1256C258.023 19.3053 260.381 17.0986 263.398 15.5094C266.414 13.9203 269.808 13.1257 273.58 13.1257C276.735 13.1257 279.597 13.6567 282.169 14.7148C284.74 15.7749 286.899 17.3138 288.647 19.3294L283.298 24.2538C280.727 21.5543 277.624 20.2025 273.99 20.2025C271.692 20.2025 269.653 20.6812 267.87 21.6388C266.087 22.5963 264.699 23.9461 263.705 25.6901C262.711 27.4342 262.214 29.4337 262.214 31.6907C262.214 33.9477 262.711 35.897 263.705 37.639C264.699 39.3831 266.079 40.7409 267.844 41.7165C269.609 42.6902 271.624 43.179 273.887 43.179C276.287 43.179 278.445 42.666 280.366 41.6401V31.1255H280.368Z"
            class="text"
        />
        <path
            d="M323.203 22.0471V49.6402H315.54V46.4599C313.552 48.8537 310.671 50.0506 306.901 50.0506C304.295 50.0506 301.94 49.4692 299.83 48.3065C297.722 47.1438 296.066 45.4863 294.868 43.3318C293.668 41.1774 293.069 38.683 293.069 35.8447C293.069 33.0063 293.668 30.5119 294.868 28.3575C296.068 26.2031 297.722 24.5455 299.83 23.3828C301.938 22.2201 304.295 21.6387 306.901 21.6387C310.431 21.6387 313.193 22.7511 315.181 24.972V22.0491H323.203V22.0471ZM313.33 41.4067C314.667 40.0227 315.335 38.1681 315.335 35.8426C315.335 33.5172 314.667 31.6625 313.33 30.2786C311.992 28.8946 310.312 28.2026 308.291 28.2026C306.27 28.2026 304.537 28.8946 303.2 30.2786C301.863 31.6625 301.195 33.5172 301.195 35.8426C301.195 38.1681 301.863 40.0227 303.2 41.4067C304.537 42.7907 306.234 43.4827 308.291 43.4827C310.349 43.4827 311.994 42.7907 313.33 41.4067Z"
            class="text"
        />
        <path
            d="M330.607 11.5848H338.629V49.6402H330.607V11.5848Z"
            class="text"
        />
        <path
            d="M374.163 22.0471V49.6402H366.5V46.4599C364.512 48.8537 361.631 50.0506 357.861 50.0506C355.255 50.0506 352.9 49.4692 350.79 48.3065C348.682 47.1438 347.026 45.4863 345.828 43.3318C344.628 41.1774 344.029 38.683 344.029 35.8447C344.029 33.0063 344.628 30.5119 345.828 28.3575C347.028 26.2031 348.682 24.5455 350.79 23.3828C352.898 22.2201 355.255 21.6387 357.861 21.6387C361.391 21.6387 364.152 22.7511 366.141 24.972V22.0491H374.163V22.0471ZM364.29 41.4067C365.627 40.0227 366.295 38.1681 366.295 35.8426C366.295 33.5172 365.627 31.6625 364.29 30.2786C362.952 28.8946 361.272 28.2026 359.251 28.2026C357.23 28.2026 355.497 28.8946 354.16 30.2786C352.823 31.6625 352.155 33.5172 352.155 35.8426C352.155 38.1681 352.823 40.0227 354.16 41.4067C355.497 42.7907 357.194 43.4827 359.251 43.4827C361.308 43.4827 362.954 42.7907 364.29 41.4067Z"
            class="text"
        />
        <path
            d="M399.154 49.6402L393.087 41.1774L386.814 49.6402H378.072L388.716 35.7421L378.431 22.0491H387.431L393.294 30.1016L399.311 22.0491H407.847L397.562 35.5369L408.259 49.6402H399.156H399.154Z"
            class="text"
        />
        <path
            d="M438.082 22.0471L425.587 51.332C424.318 54.5123 422.749 56.7512 420.881 58.0507C419.011 59.3502 416.758 60 414.118 60C412.678 60 411.256 59.7767 409.85 59.3341C408.444 58.8896 407.297 58.274 406.405 57.4875L409.336 51.7947C409.953 52.3418 410.663 52.7683 411.47 53.0761C412.275 53.3838 413.071 53.5367 413.86 53.5367C414.957 53.5367 415.849 53.2712 416.535 52.7421C417.22 52.2111 417.837 51.332 418.386 50.1009L418.489 49.8454L406.508 22.0491H414.788L422.553 40.7691L430.369 22.0491H438.082V22.0471Z"
            class="text"
        />
        <path
            d="M81.5521 18.0259C78.337 7.59982 68.5907 0 57.0957 0C44.6447 0 34.2469 8.91943 31.9778 20.6873C28.803 18.7823 25.0877 17.682 21.1202 17.682C9.47393 17.682 0 27.1305 0 38.7454C0 50.3604 9.47393 59.8089 21.1202 59.8089H77.7742C89.4205 59.8089 98.8944 50.3604 98.8944 38.7454C98.8944 28.4179 91.4012 19.8082 81.5521 18.0259ZM8.66914 38.7454C8.66914 31.8979 14.2543 26.3278 21.1202 26.3278C27.9861 26.3278 33.5712 31.8979 33.5712 38.7454C33.5712 45.5929 27.9861 51.163 21.1202 51.163C14.2543 51.163 8.66914 45.5929 8.66914 38.7454ZM77.7742 51.163C74.5369 51.163 71.584 49.9239 69.3673 47.8962C67.2212 45.9329 65.7669 43.2293 65.4099 40.1978C62.9511 41.5878 60.1152 42.3864 57.0937 42.3864C57.0534 42.3864 57.015 42.3844 56.9747 42.3844C47.7025 42.32 40.179 34.7806 40.179 25.5171C40.179 16.2537 47.767 8.64787 57.0937 8.64787C63.8608 8.64787 69.7102 12.6328 72.413 18.374C73.4356 20.5445 74.0084 22.9644 74.0084 25.5171C74.0084 25.9939 73.9842 26.4646 73.9439 26.9333C75.1501 26.5431 76.4369 26.3298 77.7722 26.3298C79.4866 26.3298 81.1204 26.6778 82.607 27.3034C87.0787 29.1903 90.2232 33.6078 90.2232 38.7474C90.2232 45.5949 84.6381 51.1651 77.7722 51.1651L77.7742 51.163Z"
            class="logo"
        />
    </g>
    <defs>
        <clipPath id="clip0_4582_44442">
            <rect width="438.082" height="60" fill="white" />
        </clipPath>
    </defs>
</svg>
