import { BaseService } from '@datagalaxy/core-ui';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { Injectable } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import {
    IClientIntegrationEditModalResolve,
    IClientIntegrationEditModalResult,
} from '../client-admin/integration/client-admin-integration-edit-modal/client-admin-integration-edit-modal.types';
import { ClientAdminIntegrationEditModalComponent } from '../client-admin/integration/client-admin-integration-edit-modal/client-admin-integration-edit-modal.component';
import { ViewTypeUtil } from '../shared/util/ViewTypeUtil';
import {
    ClientIntegrationDTO,
    ClientIntegrationParameter,
    IntegrationApiService,
} from '@datagalaxy/webclient/integration/data-access';

@Injectable({ providedIn: 'root' })
export class IntegrationService extends BaseService {
    constructor(
        private integrationApiService: IntegrationApiService,
        private dxyModalService: DxyModalService
    ) {
        super();
    }

    public getTranslateKey(name: string): string {
        return ViewTypeUtil.getTranslateKey(
            'UI.Workspace.tabView.integration',
            name
        );
    }

    public async getClientIntegrationStatus(): Promise<ClientIntegrationDTO> {
        const p = new ClientIntegrationParameter();
        const res = await this.integrationApiService.getClientIntegrations(p);
        return res.Integrations?.length
            ? res.Integrations[0]
            : new ClientIntegrationDTO();
    }

    public async enableClientIntegration(): Promise<ClientIntegrationDTO> {
        const p = new ClientIntegrationParameter('Confluence');
        const res = await this.integrationApiService.createClientIntegration(p);
        return res.Integration;
    }

    public async getClientIntegrations(): Promise<ClientIntegrationDTO[]> {
        const p = new ClientIntegrationParameter();
        const res = await this.integrationApiService.getClientIntegrations(p);
        return res.Integrations;
    }

    public async createClientIntegration(
        displayName: string,
        isClientAdmin: boolean
    ): Promise<ClientIntegrationDTO> {
        const p = new ClientIntegrationParameter(displayName, isClientAdmin);
        const res = await this.integrationApiService.createClientIntegration(p);
        return res.Integration;
    }

    public async updateClientIntegration(
        displayName: string,
        clientIntegrationId: number,
        isClientAdmin: boolean
    ): Promise<ClientIntegrationDTO> {
        const p = new ClientIntegrationParameter(
            displayName,
            isClientAdmin,
            clientIntegrationId
        );
        const res = await this.integrationApiService.editClientIntegration(p);
        return res.Integration;
    }

    public async deleteClientIntegration(
        clientIntegrationId: number
    ): Promise<ClientIntegrationDTO> {
        const p = new ClientIntegrationParameter();
        p.ClientIntegrationId = clientIntegrationId;
        const res = await this.integrationApiService.deleteClientIntegration(p);
        return res.Integration;
    }

    public async regenerateClientIntegration(
        clientIntegrationId: number
    ): Promise<ClientIntegrationDTO> {
        const p = new ClientIntegrationParameter();
        p.ClientIntegrationId = clientIntegrationId;
        const res =
            await this.integrationApiService.regenerateClientIntegration(p);
        return res.Integration;
    }
    public async openClientIntegrationEditModal(
        isCreation: boolean,
        clientIntegration?: ClientIntegrationDTO
    ) {
        return await this.dxyModalService.open<
            ClientAdminIntegrationEditModalComponent,
            IClientIntegrationEditModalResolve,
            IClientIntegrationEditModalResult
        >({
            componentType: ClientAdminIntegrationEditModalComponent,
            data: { isCreation, clientIntegration },
        });
    }
    public async confirmDeleteToken() {
        return this.dxyModalService.confirmDeleteObject(
            ServerType[ServerType.ClientIntegration],
            { featureCode: 'INTEGRATION_TOKEN,D' }
        );
    }
}
