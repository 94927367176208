import { Component, Input } from '@angular/core';
import { BaseCellComponent, ICellParams } from '..';
import { IUserBadgeCellData } from './user-badge-cell.types';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'dxy-user-badge-cell',
    templateUrl: './user-badge-cell.component.html',
    styleUrls: ['./user-badge-cell.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass],
})
export class DxyUserBadgeCellComponent extends BaseCellComponent<
    unknown,
    IUserBadgeCellData,
    ICellParams<unknown, IUserBadgeCellData>
> {
    @Input() public userData: IUserBadgeCellData;
    @Input() public mini: boolean;

    public get initials() {
        return this.userData?.initials;
    }
    public get initialsColorClass() {
        return `${
            this.mini ? 'dg_mini-user-initials' : 'dg_user-initials'
        } dg5-palette bg ${this.userData?.colorClass}`;
    }
    public get hasImage() {
        return this.userData?.hasImage;
    }
    public get imgClassName() {
        return `dg_user-avatar ${
            this.mini ? 'dg_mini-avatar' : 'dg_medium-avatar'
        }`;
    }
    public get imageUrl() {
        return this.userData?.imageUrl;
    }

    constructor() {
        super();
    }

    protected override updateBindings() {
        this.userData = this.value;
        this.mini = this.isMini;
    }
}
