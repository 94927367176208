<div *ngIf="!noHeader" class="title-header">
    <span class="title" [translate]="buttonTextKey"></span>
    <button
        mat-icon-button
        class="glyph-cancelsearch"
        (click)="close.emit()"
    ></button>
</div>
<div *ngIf="noHeader" class="side-close-button">
    <button
        mat-icon-button
        class="glyph-collapse"
        (click)="close.emit()"
    ></button>
</div>
<ng-content select="[side-panel-content]"></ng-content>
