import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { UserNotificationChannelsStateService } from '../../../notification-channels/state/user-notification-channels-state.service';
import { Observable } from 'rxjs';
import { msTeamsNotificationChannel } from '../../../notification-channels/ms-teams-notification-channel';
import { UserNotificationChannelDto } from '../../../notification-channels/api/dtos/UserNotificationChannelDto';
import { MsTeamsChannelUiService } from '../../../notification-channels/ms-teams-channel-ui.service';
import { withLoading } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * Button inside a notification to connect user to Ms Teams
 */
@Component({
    selector: 'app-ms-teams-connect-me-button',
    templateUrl: './ms-teams-connect-me-button.component.html',
    styleUrls: [
        './ms-teams-connect-me-button.component.scss',
        '../nav-link.scss',
    ],
    standalone: true,
    imports: [NgIf, MatLegacyButtonModule, TranslateModule, AsyncPipe],
})
export class MsTeamsConnectMeButtonComponent extends BaseCellComponent<
    unknown,
    unknown,
    unknown
> {
    protected userChannel$: Observable<UserNotificationChannelDto>;

    constructor(
        userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private msTeamsChannelUiService: MsTeamsChannelUiService
    ) {
        super();
        userNotificationChannelsStateService.initUserNotificationChannels();
        this.userChannel$ =
            userNotificationChannelsStateService.selectUserChannel(
                msTeamsNotificationChannel.name
            );
    }

    @withLoading()
    protected async activate() {
        await this.msTeamsChannelUiService.openActivateTeamsNotificationsModal();
    }
}
