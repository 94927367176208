import * as uuid from 'uuid';
import { CoreUtil } from '@datagalaxy/core-util';
import { IFilterItemModelSourceData } from '../models/IFilterItemModelSourceData';
import { AttributeDataService } from '../../attribute-data.service';
import { IComparableSearchFilterItem } from '../../../../search/search.service';
import { FilterUtil } from '../../../util/FilterUtil';
import { Subject } from 'rxjs';
import {
    IFilterFormModel,
    IHasFilterItemChangedEvent,
} from '../attribute-filter-form/IFilterFormModel';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';

export abstract class AttributeFilterModel
    implements
        IFilterItemModelSourceData,
        IComparableSearchFilterItem,
        IHasFilterItemChangedEvent
{
    public readonly id: string;
    public readonly attributeMeta: AttributeMetaInfo;

    public isFormOpen: boolean;
    public isQuickFilter: boolean;
    /** should be true if the filter was generated/added from a quick filter */
    public isFromQuickFilter: boolean;

    public isReadOnly: boolean;
    public isDeletableItem = true;

    public get dgZone() {
        return this.filterFormData.dgZone;
    }
    public get dgModule() {
        return this.filterFormData.dgModule;
    }
    public get spaceIdr() {
        return this.filterFormData.spaceIdr;
    }
    public get sourceAttributes() {
        return this.filterFormData.sourceAttributes;
    }

    public get attributeKey() {
        return this.attributeMeta.AttributeKey;
    }
    public get attributePath() {
        return this.attributeMeta.AttributePath;
    }
    public get attributeType() {
        return this.attributeMeta.AttributeType;
    }
    public get isCdp() {
        return this.attributeMeta.IsCdp;
    }
    public get isSystemDataTypeRefAttribute() {
        return AttributeDataService.isSystemDataTypeRefAttribute(
            this.attributeMeta
        );
    }

    public get operator() {
        return this._operator;
    }
    public set operator(fo: FilterOperator) {
        const change = fo != this._operator;
        this._operator = fo;
        if (change) {
            this.onOperatorChangedInternal();
        }
    }
    private _operator: FilterOperator;
    public get defaultOperator() {
        return this.operators?.[0];
    }
    public get hasOneOperator() {
        return this.operators?.length == 1;
    }
    public get isCurrentOperatorValueLess() {
        return FilterUtil.isValuelessOperator(this.operator);
    }
    public get hasOperatorsListContainsAndMatchAll() {
        return (
            this.operators.includes(FilterOperator.ListContains) &&
            this.operators.includes(FilterOperator.ListMatchAll)
        );
    }
    public get ListValues() {
        return this.attributeMeta.ListValues;
    }
    public set ListValues(amvs: AttributeMetaValue[]) {
        this.attributeMeta.ListValues = amvs;
    }

    public get itemChanged$() {
        return this.filterChanged.asObservable();
    }
    public notifyItemChanged() {
        this.filterChanged.next();
    }
    private filterChanged = new Subject<void>();

    constructor(
        attributeMeta: AttributeMetaInfo,
        public readonly operators: FilterOperator[],
        private filterFormData: IFilterFormModel
    ) {
        this._operator = this.defaultOperator;
        this.id = uuid.v4();

        /** #Archi-ListValues (fbo) this is overkill, since it also clones objects in ListValues, HierarchyValues, etc.
         * Unless we are sure they are empty at this time ?
         * A solution may probably be to shallow-clone the attributes in attributeDataService.getAttributesForFiltering.
         * A good thing also would be to separate AMIs created with attributeDataService.createDynamicAttribute by a specific LocalAttributeMetaInfo type,
         * and making the AttributeMetaInfo ListValues property readonly
         */
        // we use a copied Version of the Meta because of the Special List Values (All/Tous/etc.) for the ValueListItemModels
        this.attributeMeta = CoreUtil.cloneDeep(attributeMeta);

        this.isFormOpen = true;
    }

    public abstract getValuesAsArray(): string[];
    public abstract isValid(): boolean;
    public abstract copy(other: AttributeFilterModel): void;
    public abstract setValuesFromDb(
        values: string[],
        hddValues?: HierarchicalData[]
    ): void;

    protected abstract sameTypeAndValue(other: AttributeFilterModel): boolean;
    protected onOperatorChangedInternal() {}
    public hasMultipleValues() {
        return false;
    }

    /** may be overridden */
    public isNotEmpty() {
        if (!this.isValid()) {
            return false;
        }
        return this.hasValue();
    }

    public isValidOrEmpty() {
        return (
            (this.operator != undefined && this.isValid()) || !this.hasValue()
        );
    }

    protected hasValue() {
        const stringValues = this.getValuesAsArray();
        return (
            stringValues?.length > 0 &&
            stringValues?.every(
                (value) =>
                    value != undefined &&
                    value !== 'undefined' &&
                    value !== '' &&
                    value !== 'NaN' &&
                    value !== 'all'
            )
        );
    }

    public containsValue(value: string, compareAsLocalId: boolean) {
        return compareAsLocalId
            ? this.getValuesAsArray().map(getLocalId).includes(value)
            : this.getValuesAsArray().includes(value);
    }

    public equals(other: IComparableSearchFilterItem) {
        return (
            other instanceof AttributeFilterModel &&
            other.attributeKey == this.attributeKey &&
            other.operator == this.operator &&
            this.sameTypeAndValue(other)
        );
    }
}
