import { SerializableEnumeration } from 'cerialize';

/** values are stored in DB by name (cf gridStatePersistenceId ) */
export enum ServerType {
    Unknown,
    ABaseData /* unused on client side since v3.6 */,
    DomainData /* unused */,
    AUserOwnedData /* unused */,
    Ref /* unused on client side since v3.6 */,
    Organization,
    Project,
    Client,
    Column,
    Table,
    /** @deprecated unused since v3.5 */
    TableElement,
    PrimaryKey,
    ForeignKey,
    /** @deprecated unused since v3.5 */
    ForeignKeyElement,
    /** @deprecated unused since v3.5 */
    AnnotationElement,
    Model,
    /** @deprecated unused since v3.5 - replaced by Diagram */
    ModelDiagram,
    /** @deprecated unused since v3.5 */
    Position,
    Property,
    BaseServiceParameter,
    BaseServiceResult,
    LoginResult /* unused on client side since v3.6 */,
    LoadSystemDataResult /* unused on client side since v3.6 */,
    JsonTypeData /* unused on client side since v3.6 */,
    LoadDataParameter,
    LoadDataResult,
    SaveDataParameter,
    SaveDataItem,
    SaveDataResult,
    SaveDataResultItem,
    CheckUniquenessParameter /* unused on client side since v3.6 (at least) */,
    CheckUniquenessResult /* unused on client side since v3.6 (at least) */,

    LoadDataItem /* unused on client side since v3.6 */,
    LoadDataResultItem /* unused on client side since v3.6 */,

    ClientPublicData /* unused on client side since v3.6 */,
    UserPublicData /* unused on client side since v3.6 */,

    DataIdentifier /* unused on client side */,

    // DataProcessing
    DataProcessing,
    DataProcessingItem,
    DataProcessingLink,

    // SystemSettings
    DataType,
    DataTypeMapping,
    DataTypeMappingItem,
    DataTypeSettings,

    SystemSecurityData /* unused on client side since v3.6 */,
    LoginSecurityData /* unused on client side since v3.6 */,
    SecurityRole /* unused on client side since v3.6 */,

    // users
    ClientPersonReference,
    User,
    Person,

    ModelSettings,

    FieldMetadata,
    TypeMetadata,
    /** unused on client side since v3.6 */
    TypeLinkMetadata,
    SystemTypeMetadata,
    NotificationMessage,

    EntityLink,
    EntityLinkType,
    ActivityLogEntry,
    HierarchyDataDescriptor,
    HierarchicalData,

    FilteredView,

    // Analytics
    ExploratoryDataObject,
    ExploratoryDataLink,
    GetStatisticsDataParameter,
    GetStatisticsDataResult,

    // Social
    ObjectSocialContainer,
    ObjectCommentary,
    ObjectUserOpinion,
    ObjectTask,
    UserNotification,
    UserGroup,

    ClientTagReference,
    Screen,

    // Software
    SoftwareElement,

    SpaceSettings,
    Modeler,

    // Value used for Client-wide/AllMOdules attribute definitions and also for a mix of entity types
    AllTypes,

    //Client Integration (Token)
    ClientIntegration,

    Container,

    //Dashboard
    Dashboard,

    LocalSynonym,

    Team,

    Diagram,
    DiagramNode,
}

/** matches server enum DataGalaxy.Core.Data.Base.FirstClassType */
export enum FirstClassType {
    unknown = 0,

    Property,

    Source,
    Container,
    Structure,
    Field,

    Usage,

    Processing,
}
SerializableEnumeration(FirstClassType);

export class FirstClassTypeUtil {
    public static firstClassTypeFromServerType(
        serverType: ServerType | undefined
    ) {
        switch (serverType) {
            case ServerType.Property:
                return FirstClassType.Property;
            case ServerType.Model:
                return FirstClassType.Source;
            case ServerType.Container:
                return FirstClassType.Container;
            case ServerType.Table:
                return FirstClassType.Structure;
            case ServerType.Column:
                return FirstClassType.Field;
            case ServerType.SoftwareElement:
                return FirstClassType.Usage;
            case ServerType.DataProcessing:
                return FirstClassType.Processing;
            default:
                return FirstClassType.unknown;
        }
    }

    public static serverTypeFromFirstClassType(firstClassType: FirstClassType) {
        switch (firstClassType) {
            case FirstClassType.Property:
                return ServerType.Property;
            case FirstClassType.Source:
                return ServerType.Model;
            case FirstClassType.Container:
                return ServerType.Container;
            case FirstClassType.Structure:
                return ServerType.Table;
            case FirstClassType.Field:
                return ServerType.Column;
            case FirstClassType.Usage:
                return ServerType.SoftwareElement;
            case FirstClassType.Processing:
                return ServerType.DataProcessing;
            default:
                return ServerType.Unknown;
        }
    }
}
