<div *ngIf="hasImage">
    <img
        [ngClass]="imgClassName"
        class="img-responsive"
        [src]="imageUrl"
        alt=""
    />
</div>
<div *ngIf="!hasImage" [ngClass]="initialsColorClass">
    {{ initials }}
</div>
