/** import operation type identifiers
 * - those names are also used as translation sub keys of Import.Wizard.<ModuleType>.
 */
export enum EOperationType {
    undefined = 0,

    //for module: Glossary
    Properties,
    GlossaryRelations,

    //for module: Catalog
    Sources,
    Containers,
    Structures,
    Fields,
    PKs,
    FKs,
    FFKs,
    CatalogRelations,

    //for module: DataProcessings
    DataProcessings,
    DataProcessingInput,
    DataProcessingOutput,
    DataProcessingItems,
    DataProcessingRelations,

    //for module: SoftwareElements
    SoftwareElements,
    SoftwareRelations,

    //for attributes
    AttributeTags,

    //for Users
    Users,

    //for TimeSeries
    TimeSeries,

    // for Teams
    Teams,
}
