import { IPathBuilder } from './path-builder.types';
import { OrthogonalPathBuilder } from './orthogonal/orthogonal-path-builder';
import { TEdgeConnectorType } from '../../connectors.types';
import { StraightPathBuilder } from './straight/straigth-path-builder';

export function getPathBuilder(strategy: TEdgeConnectorType): IPathBuilder {
    switch (strategy) {
        case 'straight':
            return new StraightPathBuilder();
        case 'orthogonal':
        default:
            return new OrthogonalPathBuilder();
    }
}
