import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input,
} from '@angular/core';
import {
    DxyBurgerMenuButtonComponent,
    executeOnce,
    IListOption,
    RichTextContent,
    SpinnerComponent,
    withLoading,
} from '@datagalaxy/core-ui';
import { DomUtil } from '@datagalaxy/core-util';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import {
    AttributeValueTranslation,
    TranslationStatus,
} from '@datagalaxy/webclient/multilingual/domain';
import { SignalAttributeTranslationService } from './signal-attribute-translation.service';
import { MultilingualStateService } from '../../multilingual-state.service';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
    selector: 'dxy-attribute-translation-button',
    standalone: true,
    imports: [
        DxyBurgerMenuButtonComponent,
        MatLegacyTooltipModule,
        TranslateModule,
        SpinnerComponent,
        NgIf,
        AsyncPipe,
    ],
    providers: [SignalAttributeTranslationService],
    templateUrl: './attribute-translation-button.component.html',
    styleUrls: ['./attribute-translation-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeTranslationButtonComponent extends DxyBaseComponent {
    @Input() entityIdentifier!: IEntityIdentifier;
    @Input() translation!: AttributeValueTranslation;
    @Input() attributeName = '';
    @Input() metalModelValue?: string | null;

    protected translationSource = '';
    protected sourceLanguageCode = '';
    protected get translationKo() {
        return this.translation?.status === TranslationStatus.Ko;
    }

    constructor(
        private translationApiService: TranslationApiService,
        private signalAttributeTranslationService: SignalAttributeTranslationService,
        private stateService: MultilingualStateService
    ) {
        super();
    }

    public menuOptions: IListOption[] = [
        {
            glyphClass: 'glyph-file-copy',
            labelKey: 'Multilingual.AttributeTranslationButton.copy',
            callback: () => {
                DomUtil.copyToClipboard(this.translationSource);
            },
        },
        {
            glyphClass: 'glyph-warning',
            labelKey: 'Multilingual.AttributeTranslationButton.report',
            callback: () => {
                this.signalAttributeTranslationService.signal(
                    this.entityIdentifier,
                    this.attributeName
                );
            },
        },
    ];

    @executeOnce()
    @withLoading()
    @HostListener('mouseenter')
    private async _loadTranslationSource() {
        this.sourceLanguageCode =
            this.translation.sourceLanguageCode ??
            this.stateService.state.settings.sourceLanguageCode ??
            '';
        if (!this.entityIdentifier || this.translationSource) {
            return;
        }

        const text =
            this.stateService.isSourceLanguage(this.sourceLanguageCode) &&
            this.metalModelValue
                ? this.metalModelValue
                : await this.getSourceTranslationValue();

        this.translationSource = RichTextContent.fromJsonOrRawText(
            text ?? ''
        ).RawText;
    }

    private async getSourceTranslationValue() {
        const translation =
            await this.translationApiService.getAttributeTranslation({
                entityIdentifier: this.entityIdentifier,
                attributePath: this.translation?.attributePath,
                languageCode: this.sourceLanguageCode,
            });
        return translation?.value ?? this.metalModelValue ?? '';
    }
}
