import { TDatasourceConfig } from '../grid-config';
import { BehaviorSubject } from 'rxjs';
import { GridScrollBehavior } from './grid-scroll.types';
import { ServerFlatScroll } from './server-flat-scroll';

export class GridScroll<TRow> {
    private infiniteLoadingEnded = false;
    private loading = new BehaviorSubject(false);
    private scroll?: GridScrollBehavior<TRow>;

    private get hasInfiniteLoading() {
        return !!this.scroll;
    }

    public get loading$() {
        return this.loading.asObservable();
    }

    public setDataSource(dataSource?: TDatasourceConfig<TRow>) {
        switch (dataSource?.type) {
            case 'serverFlat': {
                this.scroll = new ServerFlatScroll(dataSource);
                break;
            }
            default: {
                this.scroll = undefined;
                break;
            }
        }
    }

    public async onScrollIndexChange(
        index: number,
        rowCount: number
    ): Promise<TRow[]> {
        const scroll = this.scroll;

        if (
            !scroll ||
            !this.hasInfiniteLoading ||
            this.loading.value ||
            this.infiniteLoadingEnded ||
            !scroll.hasReachedInfiniteLoadingThreshold(index, rowCount) ||
            (scroll.disableAutoLoad && rowCount === 0)
        ) {
            return [];
        }

        this.loading.next(true);
        const rows = await scroll.loadNextChunk(rowCount);
        this.loading.next(false);

        if (!rows.length) {
            this.infiniteLoadingEnded = true;
        }

        return rows;
    }
}
