/**
 * ATTENTION: avoid imports here
 */

export const spotlightTokens = {
    user: '@',
    tag: '#',
    domain: '{',
    _all: ['@', '#', '{'],
};

export function isEmptySearchedTerm(value: string) {
    return value == undefined || !value.length || /^\s+$/.test(value);
}

export const searchResultMaxCountOptions = [50, 100, 200, 500];
