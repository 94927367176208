import { deserialize, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseDTO)
export class UserInsightItem extends BaseDTO {
    @deserialize public $id?: string;
    @deserialize public UserGuid?: string;
    @deserialize public InsightType?: string;
    @deserialize public UserObject?: any;
    @deserialize public Count?: number;
}
