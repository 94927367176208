import { Injectable } from '@angular/core';
import { ImportEntityTarget } from '../dxy-target-selection/target-entity-selector.types';
import { ConnectorModule } from '@datagalaxy/webclient/connectivity/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    ImportDataStructureConfig,
    ImportDataTreeConfig,
    ImportFormConfig,
} from './connection-form.types';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    EntityType,
    EntityTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { EntityService } from '../../shared/entity/services/entity.service';
import { ConnectorUtil } from '../ConnectorUtil';

@Injectable({ providedIn: 'root' })
export class ConnectionTargetSelectionService {
    constructor(private entityService: EntityService) {}

    public async getImportEntityTargets(
        importFormConfig: ImportFormConfig & {
            pluginTitle: string;
        },
        spaceIdr: SpaceIdentifier
    ): Promise<ImportEntityTarget[]> {
        const dataStructure = importFormConfig.dataStructure;
        const sourceTypeName = importFormConfig.plugin.sourceType;
        return await Promise.all(
            importFormConfig.plugin.modules.map(
                async (module: ConnectorModule) => {
                    const importEntityTarget = new ImportEntityTarget(
                        module,
                        sourceTypeName
                    );
                    await this.initTargetAvailableEntities(
                        spaceIdr,
                        importEntityTarget
                    );
                    importEntityTarget.newEntityName =
                        this.getDefaultTargetEntityName(
                            importEntityTarget,
                            importFormConfig.pluginTitle
                        );

                    this.initTargetDefaultEntity(
                        importEntityTarget,
                        dataStructure
                    );
                    return importEntityTarget;
                }
            )
        );
    }

    private async initTargetAvailableEntities(
        spaceIdr: ISpaceIdentifier,
        entityTarget: ImportEntityTarget
    ) {
        const searchValue = EntityTypeUtil.getEntityType(
            ServerType[entityTarget.serverType],
            entityTarget.subTypeName
        );
        const typeFilter = new Filter(
            '_EntityType',
            FilterOperator.ListContains,
            [EntityType[searchValue]]
        );
        const { Entities } =
            await this.entityService.getTargetEntitiesFromServerType(
                null,
                spaceIdr,
                entityTarget.serverType,
                1000,
                [typeFilter]
            );
        entityTarget.availableEntities = Entities.filter(
            (entity) =>
                (entityTarget.subTypeName
                    ? entity.SubTypeName === entityTarget.subTypeName
                    : true) && !entity.HddData.Parents.length
        );
    }

    private initTargetDefaultEntity(
        target: ImportEntityTarget,
        dataStructure: ImportDataStructureConfig
    ) {
        if (dataStructure instanceof ImportDataTreeConfig) {
            const importEntityTargets = dataStructure.importEntityTargets;
            if (target.isCatalogModule) {
                if (importEntityTargets.rootSourceId) {
                    const entityExists = target.availableEntities.find(
                        (e) =>
                            e.DataReferenceId ===
                            importEntityTargets.rootSourceId
                    );
                    if (entityExists) {
                        target.selectedEntityId =
                            importEntityTargets.rootSourceId;
                    } else {
                        importEntityTargets.rootSourceId = undefined;
                    }
                } else {
                    target.selectedEntityId = undefined;
                }
                if (importEntityTargets.rootSourceName) {
                    target.newEntityName = importEntityTargets.rootSourceName;
                }

                target.isUpdate = !!target.selectedEntityId;
                return;
            }
            const entityName = target.isUsageModule
                ? dataStructure instanceof ImportDataTreeConfig &&
                  importEntityTargets.rootApplicationName
                : dataStructure instanceof ImportDataTreeConfig &&
                  importEntityTargets.rootDataflowName;

            target.selectedEntityId = target.availableEntities.find(
                (entity) =>
                    entity.SubTypeName == target.subTypeName &&
                    entity.DisplayName == entityName
            )?.DataReferenceId;
            if (entityName) {
                target.newEntityName = entityName;
            }
            target.isUpdate = !!target.selectedEntityId;
        }
    }

    public getDefaultTargetEntityName(
        entityTargetSelectorParam: ImportEntityTarget,
        pluginDisplayName: string
    ): string {
        return ConnectorUtil.buildDefaultName(
            entityTargetSelectorParam.availableEntities,
            pluginDisplayName,
            (entity) => entity.DisplayName
        );
    }
}
