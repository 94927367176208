import { FocusMonitor } from '@angular/cdk/a11y';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import {
    FormGroupDirective,
    NgControl,
    NgForm,
    FormsModule,
} from '@angular/forms';
import {
    MatLegacyCheckbox as MatCheckbox,
    MatLegacyCheckboxModule,
} from '@angular/material/legacy-checkbox';
import { ErrorStateMatcher } from '@angular/material/core';
import {
    MatLegacyFormFieldControl as MatFormFieldControl,
    MatLegacyFormFieldModule,
} from '@angular/material/legacy-form-field';
import { DxyBaseMatFormFieldControl } from '../../../base/DxyBaseMatFormFieldControl';

@Component({
    selector: 'dxy-checkbox-field-control',
    templateUrl: './checkbox-field-control.component.html',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyCheckboxFieldControlComponent,
        },
    ],
    standalone: true,
    imports: [MatLegacyCheckboxModule, FormsModule, MatLegacyFormFieldModule],
})
export class DxyCheckboxFieldControlComponent extends DxyBaseMatFormFieldControl<boolean> {
    @Input() set value(value: boolean) {
        if (value == this.value) {
            return;
        }
        super.setValue(value);
        this.valueChange.emit(value);
    }
    get value(): boolean {
        return this._value;
    }

    @Input() textValueTrue = 'true';
    @Input() textValueFalse = 'false';
    @Input() allowIndeterminate: boolean;
    @Input() textValueIndeterminate = 'indeterminate';

    @Output() valueChange = new EventEmitter<boolean>();

    @ViewChild('matCheckbox') matCheckbox: MatCheckbox;

    public get valueText() {
        return this.value
            ? this.textValueTrue
            : this.indeterminate
            ? this.textValueIndeterminate
            : this.textValueFalse;
    }
    public get indeterminate() {
        return this.allowIndeterminate && this.value == undefined;
    }

    constructor(
        @Optional() @Self() ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef,
        ngZone: NgZone
    ) {
        super(
            'dxy-checkbox-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone
        );
    }

    public focus() {
        this.matCheckbox.focus();
    }

    public get empty() {
        return this.indeterminate;
    }
}
