import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IDropdownSection } from '@datagalaxy/core-ui';
import { StringUtil } from '@datagalaxy/core-util';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { AttributeFilterSectionService } from './attribute-filter-section.service';
import { TranslateModule } from '@ngx-translate/core';
import { DxyDropdownSectionComponent } from '@datagalaxy/core-ui';

/**
 * ## Role
 * Dropdown to select an attribute filter with a search input
 */
@Component({
    selector: 'app-attribute-filter-add-dropdown',
    templateUrl: './attribute-filter-add-dropdown.component.html',
    standalone: true,
    imports: [DxyDropdownSectionComponent, TranslateModule],
})
export class AttributeFilterAddDropdownComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() attributes: AttributeMetaInfo[];
    @Input() btnClass = 'mat-button-base mat-button';
    @Input() btnIconClass = 'glyph-add';
    @Input() addBtnText = 'UI.Filter.addFilterBtnGlobal';
    @Input() noSections = false;
    @Output() onAddAttribute = new EventEmitter<AttributeMetaInfo>();

    public attributeSections: IDropdownSection[] = [];

    constructor(
        private attributeFilterSectionService: AttributeFilterSectionService
    ) {
        super();
    }

    ngOnInit() {
        this.initSections();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'attributes', () => this.initSections());
    }

    private onAddAttributeInternal(attribute: AttributeMetaInfo) {
        this.log('onAddAttributeInternal', attribute);
        this.onAddAttribute.emit(attribute);
    }

    private onFilterOptions(searchTerm: string, section: IDropdownSection) {
        section.filteredOptions = StringUtil.filterSearched(
            searchTerm,
            section.options,
            (option) =>
                (option.data as AttributeMetaInfo)?.translatedDisplayName
        );

        /** Auto expansion logic on text search */
        section.isExpanded = section.filteredOptions.length > 0;

        if (!section.isExpanded) {
            return;
        }
        let parent = section.parentSection;
        while (parent) {
            parent.isExpanded = true;
            parent = parent.parentSection;
        }
    }

    private initSections() {
        this.attributeSections =
            this.attributeFilterSectionService.getUiAttributesSections(
                this.attributes,
                {
                    includeLinks: true,
                    onAddAttribute: (attr) => this.onAddAttributeInternal(attr),
                    onSearchString: (
                        searchTerm: string,
                        section: IDropdownSection
                    ) => this.onFilterOptions(searchTerm, section),
                    getLogFunctional: () => 'FILTER,R',
                    flattenSections: this.noSections,
                }
            );
    }
}
