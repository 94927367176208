import { deserialize } from 'cerialize';

/** Note: Is System (indenpendant from the client company) */
export class UserGroup {
    @deserialize public $id!: string;
    @deserialize public DisplayName!: string;
    @deserialize public Code!: string;
    @deserialize public IsSystem!: boolean;

    public get ReferenceId() {
        return this.$id;
    }
}
