import { Injectable } from '@angular/core';
import {
    Connection,
    Connector,
    DataStructure,
    IConnectorPlugin,
} from '@datagalaxy/webclient/connectivity/data-access';
import {
    ImportDataTreeConfig,
    ImportDataUrnConfig,
    ImportFormConfig,
    JsonObject,
} from './connection-form.types';
import {
    DEFAULT_ORPHANED_OBJECTS_HANDLING,
    IConnectionToken,
    OrphanedObjectsHandling,
} from './dxy-connection-form-target/dxy-connection-form-target.types';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ConnectionTargetSelectionService } from './connection-target-selection.service';
import { ISavedConnectionRow } from '../saved-connections.types';
import { ConnectivityService } from '../connectivity.service';
import { ConnectorUtil } from '../ConnectorUtil';
import { ImportEntityTarget } from '../dxy-target-selection/target-entity-selector.types';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { ConnectorStateService } from '../connector-state.service';
import { IConnectionFields } from './form-configs/types/interfaces/connection-fields.interface';

export const IS_OTHER_USER_PAT = 'otherUserPat';
export const OUTPUT_ORPHANED_OBJECTS_HANDLING = 'orphaned-objects-handling';

@Injectable({ providedIn: 'root' })
export class ConnectionFormService {
    private importFormConfig: ImportFormConfig;
    private savedConnection: JsonObject;
    private savedTransform: JsonObject;
    private importEntityTargets: ImportEntityTarget[];
    private fields: IConnectionFields;
    private currentConnection: any;

    constructor(
        private connectionTargetSelectionService: ConnectionTargetSelectionService,
        private connectorStateService: ConnectorStateService,
        private connectivityService: ConnectivityService,
        private currentUserService: CurrentUserService
    ) {}

    /**
     * Load an existing connection
     */
    public async load(
        metadata: ISavedConnectionRow,
        spaceIdr: SpaceIdentifier
    ) {
        this.clear();
        const { credentials, name, pluginName } = metadata;
        const { entityId, data, tokenUid, connectionId } = credentials ?? {};
        const { connection, transform, output } = data ?? {};
        this.savedConnection = connection as JsonObject;
        this.savedTransform = transform as JsonObject;
        this.importFormConfig.connectionName = name;
        this.importFormConfig.connectionId = connectionId;
        this.importFormConfig.token = {
            id: tokenUid,
            displayName: '',
            value: '',
        };
        const dataStructure =
            connection['data-structure'] || DataStructure.Tree;
        this.importFormConfig.dataStructure =
            dataStructure === DataStructure.Tree
                ? new ImportDataTreeConfig({
                      rootSourceId: entityId,
                      rootApplicationName: connection['root-application-name'],
                      rootDataflowName: connection['root-dataflow-name'],
                  })
                : new ImportDataUrnConfig();

        let orphanedObjectsHandling;
        if (output) {
            orphanedObjectsHandling =
                OrphanedObjectsHandling[
                    output[OUTPUT_ORPHANED_OBJECTS_HANDLING]
                ];
        }
        this.importFormConfig.orphanedObjectsHandling =
            orphanedObjectsHandling ?? DEFAULT_ORPHANED_OBJECTS_HANDLING;
        const connector = await this.getConnector(pluginName);
        this.importFormConfig.plugin = {
            ...connector,
            iconUrl: this.getOnlineConnectorImageUrl(pluginName),
        };
        this.importEntityTargets =
            await this.connectionTargetSelectionService.getImportEntityTargets(
                {
                    ...this.importFormConfig,
                    pluginTitle: this.getPluginTitle(),
                },
                spaceIdr
            );
    }

    /**
     * Create a new connection
     */
    public async create(plugin: IConnectorPlugin, spaceIdr: SpaceIdentifier) {
        this.clear();
        await this.init(plugin, spaceIdr);
    }

    /**
     * Initialize the connection
     */
    public async init(plugin: IConnectorPlugin, spaceIdr: SpaceIdentifier) {
        this.importFormConfig.plugin = plugin;
        this.importEntityTargets =
            await this.connectionTargetSelectionService.getImportEntityTargets(
                {
                    ...this.importFormConfig,
                    pluginTitle: this.getPluginTitle(),
                },
                spaceIdr
            );
    }

    public getPluginTitle(): string {
        return this.importFormConfig.plugin.title[
            this.currentUserService.userInfo.LanguageCode
        ];
    }

    public getImportEntityTargets(): ImportEntityTarget[] {
        return this.importEntityTargets;
    }

    public getCurrentConnection() {
        return this.currentConnection;
    }

    public setCurrentConnection(value) {
        this.currentConnection = value;
    }

    public getFields() {
        return this.fields;
    }

    public get connectionId(): string | undefined {
        return this.importFormConfig.connectionId;
    }

    public updateConnectionName(connectionName: string) {
        this.importFormConfig.connectionName = connectionName;
    }

    public updateConnectionId(connectionId: string) {
        this.importFormConfig.connectionId = connectionId;
    }

    public getSavedConnection(): JsonObject {
        return this.savedConnection;
    }

    public getSavedTransform() {
        return this.savedTransform;
    }

    public async initConnectionName(hasInitialWorkspace: boolean) {
        if (this.importFormConfig.connectionName !== undefined) {
            // already defined
            return this.importFormConfig.connectionName;
        }
        const connectionName = await this.buildDefaultConnectionName(
            hasInitialWorkspace,
            this.importFormConfig.plugin.name
        );
        this.importFormConfig.connectionName = connectionName;
        return connectionName;
    }

    public getDataStructure(): DataStructure {
        return this.importFormConfig.dataStructure instanceof
            ImportDataTreeConfig
            ? DataStructure.Tree
            : DataStructure.Urn;
    }

    public getOrphanedObjectsHandling(): OrphanedObjectsHandling {
        return this.importFormConfig.orphanedObjectsHandling;
    }

    public updateOrphanedObjectsHandling(value: OrphanedObjectsHandling) {
        this.importFormConfig.orphanedObjectsHandling = value;
    }

    public getTokenValue(): string {
        return this.importFormConfig.token.value;
    }

    public updateToken(value: IConnectionToken): void {
        this.importFormConfig.token = value;
    }

    public getTokenUid(): string {
        return this.importFormConfig.token.id;
    }

    public getConnectionName(): string {
        return this.importFormConfig.connectionName;
    }

    public updateDataStructure(value: boolean) {
        this.importFormConfig.dataStructure = value
            ? new ImportDataUrnConfig()
            : new ImportDataTreeConfig({});
    }

    public updateTarget(value: ImportEntityTarget) {
        if (
            this.importFormConfig.dataStructure instanceof ImportDataTreeConfig
        ) {
            if (value.isCatalogModule) {
                if (value.isUpdate) {
                    this.importFormConfig.dataStructure.importEntityTargets.rootSourceId =
                        value.selectedEntityId;
                } else {
                    this.importFormConfig.dataStructure.importEntityTargets.rootSourceId =
                        undefined;
                    this.importFormConfig.dataStructure.importEntityTargets.rootSourceName =
                        value.newEntityName;
                }
            } else if (value.isProcessingModule) {
                this.importFormConfig.dataStructure.importEntityTargets.rootDataflowName =
                    value.newEntityName;
            } else if (value.isUsageModule) {
                this.importFormConfig.dataStructure.importEntityTargets.rootApplicationName =
                    value.newEntityName;
            }
        }
    }

    public getPlugin(): IConnectorPlugin {
        return this.importFormConfig.plugin;
    }

    public isValid(): boolean {
        return (
            this.importFormConfig.connectionName !== undefined &&
            this.importFormConfig.token.value !== undefined &&
            !this.importEntityTargets.some(
                (targetSelector) => !targetSelector.isValid
            )
        );
    }

    public listFieldUpdated() {
        this.connectorStateService.listFieldUpdated();
    }

    private clear() {
        this.savedConnection = undefined;
        this.savedTransform = undefined;
        this.currentConnection = {};
        this.fields = {} as IConnectionFields;
        this.importFormConfig = {
            connectionName: undefined,
            dataStructure: new ImportDataTreeConfig({
                rootApplicationName: undefined,
                rootDataflowName: undefined,
                rootSourceId: undefined,
            }),
            orphanedObjectsHandling: OrphanedObjectsHandling.DO_NOTHING,
            output: undefined,
            token: {
                id: undefined,
                value: undefined,
                displayName: undefined,
            },
            plugin: undefined,
        };
    }

    private async buildDefaultConnectionName(
        hasInitialWorkspace: boolean,
        pluginName: string
    ) {
        if (!hasInitialWorkspace) {
            return pluginName;
        }

        const savedConnections = await this.getConnections();
        if (!savedConnections) {
            return;
        }

        return ConnectorUtil.buildDefaultName(
            savedConnections,
            pluginName,
            (connection) => connection.name
        );
    }

    // API

    private async getConnections(): Promise<Connection[]> {
        const result = await this.connectivityService.getConnections();
        return result.connections;
    }

    private async getConnector(pluginName: string): Promise<Connector> {
        return (await this.connectivityService.getConnector(pluginName))
            .connector;
    }

    private getOnlineConnectorImageUrl(connectorName: string) {
        return this.connectivityService.getConnectorImageUrl(connectorName);
    }
}
