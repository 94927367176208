import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ToasterService } from '../../../../services/toaster.service';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { NgIf } from '@angular/common';
import { DxyInputFileChangeDirective } from '@datagalaxy/core-ui';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { FileDropDirective } from '@datagalaxy/ui/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-csv-file-deposit-card',
    templateUrl: 'dxy-csv-file-deposit-card.component.html',
    styleUrls: ['dxy-csv-file-deposit-card.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        FileDropDirective,
        MatLegacyButtonModule,
        DxyInputFileChangeDirective,
        NgIf,
    ],
})
export class DxyCsvFileDepositCardComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() titleKey?: string;
    @Input() showInfoBtn?: boolean;
    @Output() onUploadFile = new EventEmitter<{
        files: File[];
        forceDetails?: boolean;
    }>();

    public readonly allowedEncodings = ['windows-1252', 'UTF-8'];
    /** accepted file extension and mime types */
    // "text/csv" detail is necessary for e2e tests
    public readonly mimeAccept = [
        'text/plain',
        'text/csv',
        '.csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
    ];
    public readonly mimeAcceptString = this.mimeAccept.join(', ');

    /** list of files for the input file tag */
    public files: File[];

    @ViewChild('fileInputRef')
    private fileInputRef: ElementRef<HTMLInputElement>;

    constructor(
        private functionalLogService: FunctionalLogService,
        private toasterService: ToasterService
    ) {
        super();
    }

    ngOnInit() {
        this.titleKey ??=
            'Import.GenericImportWizard.SolutionSelection.CsvCardTitle';
    }

    public onFilesSelected(files?: File[]) {
        this.files = files;
        this.handleFiles();
    }

    public handleFiles() {
        const file = this.files?.[0];

        this.log('handleFiles', file);

        if (file?.type && !this.mimeAccept.includes(file.type.toLowerCase())) {
            this.toasterService.warningToast({
                titleKey:
                    'Import.GenericImportWizard.SolutionSelection.CsvFileToasterTitle',
                messageKey:
                    'Import.GenericImportWizard.SolutionSelection.CsvFileToasterText',
            });
            return CoreUtil.warn(`file type not allowed: ${file.type}`);
        }

        this.onUploadFile.emit({ files: this.files });
    }

    public onClickInfoBtn() {
        this.functionalLogService.logFunctionalAction(
            'IMPORT_CSV_OPEN_HELP',
            CrudOperation.R
        );
        this.onUploadFile.emit({ files: this.files, forceDetails: true });
    }

    public onClickFlatBtn() {
        this.fileInputRef.nativeElement.click();
    }
}
