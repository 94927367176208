<div
    *ngIf="showActionButton"
    [matTooltip]="tooltipText"
    [matTooltipClass]="tooltipClass"
>
    <button
        class="mat-button-base"
        [class.mat-button]="!!text"
        [class.mat-icon-button]="!text"
        [class.mat-button-disabled]="isDisabled"
        [class.hover-disabled]="isHoverDisabled"
        [disabled]="isDisabled"
        [ngClass]="glyphClass"
        (click)="onOptionClicked($event)"
        (mousedown)="onOptionMouseDown($event)"
        [dxyDataTestId]="dataTestId"
    >
        {{ text }}
    </button>
</div>
<dxy-burger-menu-button
    *ngIf="showBurgerMenuButton"
    class="burger-menu-button"
    [glyphClass]="action.burgerMenuGlyphClass"
    [tooltipKey]="tooltipText"
    [options]="burgerMenuOptions"
    [data]="data"
    [captureMouseDown]="actionsCaptureMouseDown"
    [dataTestId]="dataTestId"
    (openClose)="openClose.emit($event)"
    (onLogFunctional)="onLogFunctional.emit($event)"
></dxy-burger-menu-button>
