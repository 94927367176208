<dxy-field-select
    *ngIf="languages?.length; else noLanguage"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    labelKey="Multilingual.Administration.targetLanguage"
    [options]="languages"
    [adapter]="languageAdapter"
    [mandatory]="true"
    [hintBeforeControl]="true"
    [errorMessageKey]="errorMessageKey"
    (openClose)="onOpenClose($event)"
>
    <footer footer>
        <mat-hint>
            <span translate>
                Multilingual.Administration.TargetLanguageSelector.footerText
            </span>
        </mat-hint>
        <ng-container [ngTemplateOutlet]="askForNewLanguageLink"></ng-container>
    </footer>
</dxy-field-select>

<ng-template #noLanguage>
    <dxy-alert-message
        alertType="warning"
        [message]="
            'Multilingual.Administration.TargetLanguageSelector.noMoreLanguage'
                | translate
        "
    ></dxy-alert-message>
    <ng-container [ngTemplateOutlet]="askForNewLanguageLink"></ng-container>
</ng-template>

<ng-template #askForNewLanguageLink>
    <a [href]="languageRequestLink" class="dxy-link" target="_blank">
        <span translate>
            Multilingual.Administration.TargetLanguageSelector.askNewLanguageSupport
        </span>
    </a>
</ng-template>
