export enum SoftwareElementType {
    Application = 0,
    Screen = 1,
    Dashboard = 2,
    Usage = 3,
    DataSet = 4,
    OpenDataSet = 5,
    Process = 6,
    Report = 7,
    Algorithm = 8,
    Feature = 9,
    UsageField = 10,
    UsageComponent = 11,
}
