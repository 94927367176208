import { deserialize, inheritSerialization } from 'cerialize';
import { UserDto } from './user-dto';

@inheritSerialization(UserDto)
export class UserPublicData extends UserDto {
    //#region Important: When adding properties, update PersonPublicData.copy
    @deserialize public ExternalId?: string;
    @deserialize public HasValidLicense?: boolean;
    @deserialize public Title?: string;
    @deserialize public Service?: string;
    @deserialize public Role?: string;
    @deserialize public ClientId?: string;
    @deserialize public IsUserDeleted?: boolean;
    @deserialize public IsToken?: boolean;
    @deserialize public IsSupport?: boolean;
    //#endregion

    //#region static
    public static copy(source: UserPublicData, target = new UserPublicData()) {
        if (!source) {
            throw 'no source';
        }
        target.FirstName = source.FirstName;
        target.LastName = source.LastName;
        target.Title = source.Title;
        target.Service = source.Service;
        target.Role = source.Role;
        target.Email = source.Email;
        target.ClientId = source.ClientId;
        target.UserId = source.UserId;
        target.IsUserDeleted = source.IsUserDeleted;
        target.ProfileImageHash = source.ProfileImageHash;
        target.ProfileThumbnailHash = source.ProfileThumbnailHash;
        target.LicenseLevel = source.LicenseLevel;
        target.HasValidLicense = source.HasValidLicense;
        target.IsToken = source.IsToken;
        target.IsSupport = source.IsSupport;

        return target;
    }

    //#endregion

    public get FullNameAndEmail(): string {
        return `${this.FullName} (${this.Email})`;
    }

    public get EmailPrefix(): string {
        return this.Email?.split('@')[0] || '';
    }

    public get isDisabled() {
        return !this.HasValidLicense || this.IsUserDeleted;
    }

    public get isReadOnly(): boolean {
        return !!(this.IsSupport || this.IsToken);
    }
}
