<dxy-filter-button
    [xMenuPosition]="xMenuPosition"
    [labelKey]="labelKey"
    [labelText]="labelText"
    [isResolved]="isResolved"
    [text]="valueDisplay"
    [hasRemove]="hasRemove"
    [hasSmallPanel]="true"
    [readonly]="readonly"
    [noOperator]="noOperator"
    [removeDisabled]="removeDisabled"
    [isMini]="isMini"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    (onOpenClose)="onOpenClose.emit($event)"
    [iconClass]="iconClass"
    (removeClick)="onRemove($event)"
>
    <mat-radio-group [ngModel]="value">
        <div mat-menu-item (click)="setBooleanValue(true)">
            <mat-radio-button [value]="true">
                <span translate>CoreUI.Global.True</span>
            </mat-radio-button>
        </div>
        <div mat-menu-item (click)="setBooleanValue(false)">
            <mat-radio-button [value]="false">
                <span translate>CoreUI.Global.False</span>
            </mat-radio-button>
        </div>
    </mat-radio-group>
</dxy-filter-button>
