<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.EntityMoveModal.modalTitle
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content [class.modal-disable]="isSaving">
        <dxy-field-text
            name="name"
            [readonly]="true"
            labelKey="DgServerTypes.BaseData.fields.DisplayName"
            [ngModel]="entityDisplayName"
        ></dxy-field-text>

        <dxy-field-text
            name="type"
            [readonly]="true"
            labelKey="DgServerTypes.BaseData.fields.Type"
            [ngModel]="entityTypeString"
        ></dxy-field-text>

        <dxy-entity-selector-field
            name="parent"
            labelKey="DgServerTypes.BaseData.fields.LogicalParentData"
            [options]="entitySelectorData.instance"
            [ngModel]="parentItem"
            [takeFocus]="true"
            (ngModelChange)="setParent($event)"
            [errorMessageText]="parentErrorMessage"
            [openMenuOnFocus]="true"
            [noRemove]="!canDeleteParent"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-entity-selector-field>
    </div>
    <dxy-modal-footer
        [noMandatory]="true"
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Global.btnUpdate' | translate"
        [actionLoading]="isSaving"
        (onCloseCancel)="onCloseCancel()"
        featureCode="MOVE_ENTITY,U"
    ></dxy-modal-footer>
</form>
