import { inheritSerialization } from 'cerialize';
import { UserEventBase, registerTypedEvent } from '../events';

@inheritSerialization(UserEventBase)
@registerTypedEvent('AskSlackIntegrationActivationEvent')
export class AskSlackIntegrationActivationEvent extends UserEventBase {}

@inheritSerialization(UserEventBase)
@registerTypedEvent('SlackIntegrationActivatedEvent')
export class SlackIntegrationActivatedEvent extends UserEventBase {}

@inheritSerialization(UserEventBase)
@registerTypedEvent('SlackIntegrationDeactivatedEvent')
export class SlackIntegrationDeactivatedEvent extends UserEventBase {}
