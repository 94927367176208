/**
 * returns *min* if *n* &lt; *min*, *max* if n &gt; *max*, else *n*.
 * If *null* or *undefined*, *min* and *max* are respectively considered as -Infinity and Infinity
 * @deprecated use `MathUtils.clamp()` from `@datagalaxy/utils` instead
 */
export function clamp(n: number, min: number, max?: number) {
    return Math.min(Math.max(min ?? -Infinity, n ?? 0), max ?? Infinity);
}

/** returns *n* rounded to a multiple of *m*, or simply rounded if *m* is not a number */
export function roundToMultiple(target: number, base?: number) {
    if (isNaN(base ?? NaN)) {
        return Math.round(target);
    }
    if (base === 0) {
        return target;
    }

    const res = Math.round(target / base) * base;
    return Object.is(res, -0) ? 0 : res;
}

//#endregion angles
export function rad2deg(rad: number) {
    return (rad * 180) / Math.PI;
}
//#endregion - angles
