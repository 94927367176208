import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { AttributeMetaType } from './attribute-meta-type';
import {
    EntityType,
    FirstClassType,
    FirstClassTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { AttributeCommonDto } from './attribute-common';
import { AttributeObjectValue } from './attribute-object-value';

export interface IAttributeMetaInfoParams {
    attributePath?: string;
    attributeKey?: string;
    attributeType?: AttributeMetaType;
}

/**
 * API definition: DataGalaxy.Core.Data.Service.Entity.AttributeMetaInfo
 */
@inheritSerialization(AttributeCommonDto)
export class AttributeMetaInfo extends AttributeCommonDto {
    //#region properties
    @deserialize public IsReference!: boolean;
    @deserialize public IsReadOnly!: boolean;
    /* This marks a Non-User Visible field such as: Screen.IsSystemLayoutOverridden */
    @deserialize public IsScreenDisplayable!: boolean;
    @deserialize public IsComputed!: boolean;
    @deserialize public ComputedAttributeDescription!: string;
    @deserialize public ReferenceDataTypeName!: string;

    @deserialize public ListTypeName!: string;
    @deserialize public DataTypeName!: string;

    /**
     * Specifies if an attribute is bulk editable
     * Note: use this property only if we want to bulk edit attributes with the same ServerType
     * See *IsMultiTypeBulkEditable* for bulk edit with different ServerType
     */
    @deserialize public IsBulkEditable!: boolean;
    /**
     * Specifies if an attribute is bulk editable with different server types
     */
    @deserialize public IsMultiTypeBulkEditable!: boolean;

    // For ObjectValues/StringValues, the source AttributeType and Key
    @deserializeAs(AttributeMetaType)
    public SourceAttributeType?: AttributeMetaType;
    @deserialize public SourceAttributeKey!: string;
    @deserializeAs(EntityType)
    public ObjectLinksIncludedEntityTypes!: EntityType[];
    @deserialize public IsReversedEntityLink!: boolean;
    @deserialize public IsAllTypes!: boolean;
    /**  Used for displaying the raw text version of the associated Source Attribute */
    @deserialize public IsRawTextComputedAttribute!: boolean;
    @deserialize public AreValuesHierarchical!: boolean;
    @deserializeAs(AttributeObjectValue)
    public HierarchyValues!: AttributeObjectValue[];

    public serverType?: ServerType;
    public firstClassType?: FirstClassType;

    public loadingValuesPromise?: Promise<any>;
    public isLoadingValues?: boolean;
    public hasLoadedValues?: boolean;
    public excludedEntitiesIds: string[] = [];
    // when true : another user updated this attribute a few seconds ago -> display a visual notification
    public hasBeenModifiedExternallyRecently = false;

    // Ajouter les informations propres a la comparaison de version
    public versionContextValue?: string;
    public roleValue?: string;

    public get isText() {
        return (
            this.AttributeType == AttributeMetaType.Text ||
            this.AttributeType == AttributeMetaType.FormattedText ||
            this.AttributeType == AttributeMetaType.MultiLineText
        );
    }

    public get ReferenceServerType() {
        return ServerType[
            this.ReferenceDataTypeName as keyof typeof ServerType
        ];
    }

    //#endregion

    //#region static

    public static override OnDeserialized(
        instance: AttributeMetaInfo,
        json: any
    ) {
        AttributeMetaInfo.init(instance, json);
    }

    // This method will map the string values of the Enums from server to the integer values
    protected static override init(instance: AttributeMetaInfo, json: any) {
        super.init(instance, json);

        instance.firstClassType =
            FirstClassTypeUtil.firstClassTypeFromServerType(
                instance.serverType
            );
        instance.serverType =
            ServerType[instance.DataTypeName as keyof typeof ServerType];
    }

    //#endregion

    public constructor(params?: IAttributeMetaInfoParams) {
        super();
        if (params) {
            this.AttributeType = params.attributeType;
            this.AttributeKey = params.attributeKey || '';
            this.AttributePath = params.attributePath;
        }
    }

    /** sets ServerType, firstClassType and attributePath, only if DataTypeName is defined */
    public init() {
        AttributeMetaInfo.init(this, JSON.stringify(this));
    }
}
