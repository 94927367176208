export class AnimationFrameBuffer {
    private callbacks: (() => void)[] = [];
    private isRunning: boolean;
    private static instance: AnimationFrameBuffer;

    public static getInstance() {
        if (AnimationFrameBuffer.instance) {
            return AnimationFrameBuffer.instance;
        }
        return (AnimationFrameBuffer.instance = new AnimationFrameBuffer());
    }

    private constructor() {}

    addCallback(callback: () => void): void {
        if (this.callbacks.includes(callback)) {
            return;
        }
        // If the animation is not running, start it
        if (!this.isRunning) {
            this.isRunning = true;
            requestAnimationFrame(() => this.animate());
        }

        // Add the callback to the array
        this.callbacks.push(callback);
    }

    private animate(): void {
        // Execute all registered callbacks
        this.callbacks.forEach((callback) => callback());

        // Clear the callbacks array after executing them
        this.callbacks = [];

        // Stop the animation if there are no more callbacks
        this.isRunning = false;
    }
}
