import { getTeamNavLink } from './adapter-registry/adapters/common/dg-event-translation-nav-links.helpers';
import { DgEventTranslationFactory } from './dg-event-translation-factory';
import { TEventTranslateParameter } from './interfaces/IEventTranslationInputs';
import { IEventTranslator } from './interfaces/IEventTranslator';
import {
    ITranslateEventOptions,
    TTranslateMethod,
} from './interfaces/ITranslateEventOptions';
import { ITranslatedHtmlEvent } from './interfaces/ITranslatedHtmlEvent';

export class DgEventTranslator implements IEventTranslator {
    private factory: DgEventTranslationFactory;

    constructor() {
        this.factory = new DgEventTranslationFactory();
    }

    translate(options: ITranslateEventOptions): ITranslatedHtmlEvent {
        const translateInputs = this.factory.buildTranslationInputs(
            options.event
        );
        let { translateKey, translateParameters, navLinks } = translateInputs;
        let { event, isUserMention, mentionedTeamGuid, mentionedTeamName } =
            options;
        const translateMethod = options.translateMethod;
        if (isUserMention) {
            translateKey += 'UserMention';
        }
        if (mentionedTeamGuid) {
            translateKey += 'TeamMention';
            translateParameters.push({
                key: 'teamName',
                value: mentionedTeamName,
            });
            navLinks.push(
                getTeamNavLink(event, mentionedTeamGuid, mentionedTeamName)
            );
        }

        const objParams = {};
        translateParameters.forEach((p) => {
            objParams[p.key] =
                p.value ?? this.translateParameterValue(p, translateMethod);
        });

        const html = translateMethod(translateKey, objParams);

        return {
            html,
            sourceEvent: event,
            summary: this.getSummary(html),
            navLinks,
        };
    }

    private translateParameterValue(
        p: TEventTranslateParameter,
        translatMethod: TTranslateMethod
    ): any {
        if (p.valueTranslationKey) {
            return translatMethod(p.valueTranslationKey, {});
        } else if (p.valuesTranslationKeys) {
            return p.valuesTranslationKeys
                .map((k) => translatMethod(k, {}))
                .join(', ');
        }
        return '';
    }

    private getSummary(html: string): string {
        try {
            if (!DOMParser) {
                return undefined;
            }
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            return doc.documentElement.textContent;
        } catch {
            return undefined;
        }
    }
}
