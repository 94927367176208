<dxy-field-multi-select
    [labelKey]="labelKey"
    [data]="multiSelectData"
    [mandatory]="mandatory"
    [disabled]="disabled"
    [readonly]="readonly"
    [hint]="hint ?? ''"
    [hintBeforeControl]="true"
    [errorMessageKey]="errorMessageKey"
    (openClose)="onOpenClose($event)"
></dxy-field-multi-select>
