import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import {
    IDgEventNavLinkCell,
    IEventTranslationResult,
} from '../dg-event-translation.type';
import * as moment from 'moment';
import { DomUtil } from '@datagalaxy/core-util';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyRendererComponent } from '@datagalaxy/core-ui';
import { DxyProfileAvatarComponent } from '../../shared/shared-ui/dxy-profile-avatar/dxy-profile-avatar.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-dg-event-log-item',
    templateUrl: './dg-event-log-item.component.html',
    styleUrls: ['./dg-event-log-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        DxyProfileAvatarComponent,
        NgFor,
        DxyRendererComponent,
        NgClass,
    ],
})
export class DgEventLogItemComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() translationResult: IEventTranslationResult;

    protected get userGuid(): string {
        if (this.event && 'UserGuid' in this.event) {
            return this.event.UserGuid as string;
        }
        return undefined;
    }
    protected get profileAvatarVisible() {
        return this.userGuid && !this.customAvatarUrl;
    }
    protected get customAvatarUrl() {
        return this.translationResult.customAvatarUrl;
    }
    protected get customAvatarVisible() {
        return this.customAvatarUrl;
    }
    protected get dgLogoVisible() {
        return !this.customAvatarVisible && !this.profileAvatarVisible;
    }
    protected get innerHtml() {
        return this.translationResult.innerHTML;
    }
    protected get navLinksData() {
        return this.translationResult.navLinkCells;
    }
    protected get creationTimeFromNow() {
        return moment(this.event.CreationTime).fromNow();
    }

    private get event() {
        return this.translationResult.sourceEvent;
    }

    constructor(private elementRef: ElementRef<HTMLElement>) {
        super();
    }

    ngOnInit(): void {
        this.init();
    }
    ngOnChanges(changes: SimpleChanges): void {
        super.onAnyChanges(changes, this, () => this.init());
    }

    private init() {
        setTimeout(() => this.replaceNavLinks(), 10);
    }

    private replaceNavLinks() {
        if (!this.navLinksData) {
            return;
        }
        for (const navLinkData of this.navLinksData) {
            this.replaceLink(navLinkData);
        }
    }

    private replaceLink(navLinkData: IDgEventNavLinkCell) {
        const navLinkCssClass = navLinkData.linkCssClass;
        const selector = `.${navLinkCssClass}`;
        const displayedLink = this.getLink('.message', selector);
        const dataLink = this.getLink('.data-links', selector);
        if (displayedLink && dataLink) {
            displayedLink.parentElement.replaceChild(dataLink, displayedLink);
        }
    }

    private getLink(containerSelector: string, linkSelector: string) {
        return DomUtil.getElement<HTMLLinkElement>(
            this.elementRef,
            `${containerSelector} ${linkSelector}`
        );
    }
}
