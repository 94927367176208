import { IWidthHeight } from '@datagalaxy/core-2d-util';
import { GridMode } from './grid.types';

export function gridCellSize(
    cellSize: number | IWidthHeight = 10
): IWidthHeight {
    return typeof cellSize == 'number'
        ? { width: cellSize, height: cellSize }
        : cellSize;
}

export interface ISvgGridElements {
    pattern: SVGPatternElement;
    rect: SVGRectElement;
}

export function gridModeToVisibleAndMagnetic(mode: GridMode) {
    return {
        visible: mode === GridMode.on,
        magnetic: mode === GridMode.on || mode === GridMode.hidden,
    };
}

export function visibleAndMagneticToGridMode(
    visible: boolean,
    magnetic: boolean
): GridMode {
    if (visible && magnetic) {
        return GridMode.on;
    } else if (!visible && magnetic) {
        return GridMode.hidden;
    } else if (!visible && !magnetic) {
        return GridMode.off;
    } else {
        return null;
    }
}
