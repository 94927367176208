import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    ExternalSystemLoginParameter,
    GetExternalAuthenticationMethodParameter,
    GetExternalAuthenticationMethodResult,
    SystemLoginParameter,
    SystemLoginResult,
} from './authentication';
import {
    CheckSecurityTokenParameter,
    CheckSecurityTokenResult,
} from './security-token';
import {
    InitPasswordParameter,
    InitPasswordResult,
    RequestPasswordResetParameter,
    RequestPasswordResetResult,
    ResetPasswordParameter,
    ResetPasswordResult,
} from './password';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async initPassword(param: InitPasswordParameter) {
        return await this.backendApiService.postPromise(
            'Account/SetInitialPassword',
            param,
            InitPasswordResult
        );
    }

    public async requestPasswordReset(param: RequestPasswordResetParameter) {
        return await this.backendApiService.postPromise(
            'Account/RequestPasswordReset',
            param,
            RequestPasswordResetResult
        );
    }

    public async resetPassword(param: ResetPasswordParameter) {
        return await this.backendApiService.postPromise(
            'Account/ResetPassword',
            param,
            ResetPasswordResult
        );
    }

    public async checkSecurityToken(param: CheckSecurityTokenParameter) {
        return await this.backendApiService.postPromise(
            'Account/CheckSecurityToken',
            param,
            CheckSecurityTokenResult
        );
    }

    public async getExternalAuthenticationMethod(
        param: GetExternalAuthenticationMethodParameter
    ) {
        return await this.backendApiService.postPromise(
            'Authentication/GetExternalAuthenticationMethod',
            param,
            GetExternalAuthenticationMethodResult
        );
    }

    public async systemLogin(
        param: SystemLoginParameter
    ): Promise<SystemLoginResult> {
        return await this.backendApiService.postPromise(
            'Login/SystemLogin',
            param,
            SystemLoginResult
        );
    }

    public async externalSystemLogin(param: ExternalSystemLoginParameter) {
        return await this.backendApiService.postPromise(
            'Login/ExternalSystemLogin',
            param,
            SystemLoginResult
        );
    }
}
