import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { UiSpinnerService } from '@datagalaxy/core-ui';
import { DxyVersioningComparatorComponent } from '../dxy-version-comparator/dxy-version-comparator.component';
import { VersioningUiService } from '../services/versioning-ui.service';
import { IVersioningSelectorMoreText } from '../versioning-selector.types';
import {
    ComparatorItemClient,
    IEntityDifferenceCount,
} from '../../shared/entity/compare-entity.types';
import { ServerType } from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { FreshDeskService } from '../../shared/support/freshdesk/fresh-desk.service';
import { SpaceApiService } from '../../space/services/space-api.service';
import {
    CompareOperation,
    CompareVersionsResult,
    EntityCompareVersionsItem,
    EntityComparisonItem,
    ProjectVersion,
    ProjectVersionStatus,
} from '@datagalaxy/webclient/versioning/data-access';
import { FunctionalLogService } from '@datagalaxy/shared/monitoring/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxyScreenLayoutComponent } from '../../shared/screens-layout/dxy-screen-layout/dxy-screen-layout.component';
import { DxyVersioningComparatorSectionComponent } from '../dxy-versioning-comparator-section/dxy-versioning-comparator-section.component';
import { DxyVersioningSelectorComponent } from '../dxy-versioning-selector/dxy-versioning-selector.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'dxy-versioning-comparator-single-object',
    templateUrl: 'dxy-versioning-comparator-single-object.component.html',
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        MatLegacyFormFieldModule,
        DxyVersioningSelectorComponent,
        NgFor,
        DxyVersioningComparatorSectionComponent,
        DxyScreenLayoutComponent,
        MatLegacyButtonModule,
    ],
})
export class DxyVersioningComparatorSingleObjectComponent extends DxyVersioningComparatorComponent {
    public readonly moreText: IVersioningSelectorMoreText = {
        getMoreText: (version) => this.getDiffText(version),
        getMoreTextClass: (version) => this.getDiffClass(version),
    };
    public selectorSpaceIdr: ISpaceIdentifier;
    public isProjectResultCompareVersion: boolean;
    public comparatorItems = new Map<string, ComparatorItemClient>();
    public countCompareDifference: string;
    public showCountCompareDifference: boolean;
    public get hasResults() {
        return this.hasBeenExecuted && this.comparatorItems?.size > 0;
    }
    public get hasNoResult() {
        return this.hasBeenExecuted && !this.comparatorItems?.size;
    }
    public get noResultAvailable() {
        return (this.hasNoResult || !this.hasOtherVersion) && !this.isLoading;
    }
    public get hasOtherVersion() {
        return this.versionSelector?.hasAny(
            [
                ProjectVersionStatus.Official,
                ProjectVersionStatus.ReleaseCandidate,
                ProjectVersionStatus.Active,
                ProjectVersionStatus.Archived,
            ],
            (version) =>
                !this.selectedVersion ||
                version.versionId != this.selectedVersion.versionId
        );
    }

    protected get featureCode() {
        return this.isProjectResultCompareVersion
            ? 'PROJECT_VERSION_COMPARAISON'
            : super.featureCode;
    }

    private readonly entityDifferenceCountByVersionId = new Map<
        string,
        IEntityDifferenceCount
    >();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private attributeDataService: AttributeDataService,
        private freshDeskService: FreshDeskService,
        translate: TranslateService,
        versioningUiService: VersioningUiService,
        spaceApiService: SpaceApiService,
        uiSpinnerService: UiSpinnerService,
        functionalLogService: FunctionalLogService
    ) {
        super(
            translate,
            versioningUiService,
            spaceApiService,
            uiSpinnerService,
            functionalLogService
        );
    }

    public onClickDocButton() {
        this.freshDeskService.openVersionComparatorArticle();
    }

    protected async generateComparison() {
        this.comparatorItems = new Map<string, ComparatorItemClient>();
        const {
            currentEntity,
            currentSpaceId,
            currentVersionId,
            selectedVersionId,
        } = this;
        this.log(
            'generateComparison',
            currentEntity,
            currentVersionId,
            selectedVersionId
        );
        if (!currentEntity || !currentVersionId || !selectedVersionId) {
            return;
        }
        if (currentVersionId == selectedVersionId) {
            this.clearResults();
            this.hasBeenExecuted = true;
            return;
        }

        const result = await this.uiSpinnerService.executeWithSpinner<
            CompareVersionsResult | EntityCompareVersionsItem
        >(() =>
            currentEntity.DataReferenceId
                ? this.versioningUiService.entityCompareVersions(
                      currentVersionId,
                      selectedVersionId,
                      currentVersionId,
                      currentSpaceId,
                      currentEntity.ReferenceId
                  )
                : this.versioningUiService.compareVersions(
                      currentVersionId,
                      selectedVersionId,
                      currentVersionId,
                      currentSpaceId
                  )
        );

        this.log('generateComparison-result', result);

        this.hasBeenExecuted = true;
        if (!currentEntity.DataReferenceId) {
            const versionItems = (result as CompareVersionsResult).Items;
            this.versionCompareModuleItems =
                this.computeComparisonData(versionItems);
            this.filterModulesDataByStatus();
        } else {
            const versionItems = (result as EntityCompareVersionsItem)
                .VersionItems;
            await this.generateComparisonSingleItem(
                currentEntity,
                versionItems
            );
        }
    }

    protected async refreshComparisonOnEntityChange() {
        this.clearResults();
        const isEntityComparison = !this.currentSpace;
        this.isProjectResultCompareVersion = !isEntityComparison;
        if (isEntityComparison) {
            await Promise.all([
                this.generateComparison(),
                this.getEntityCompareVersionDifferenceList(),
            ]);
        }
    }

    private clearResults() {
        this.comparationSingleItem = null;
        this.selectedVersion = null;
        this.hasBeenExecuted = false;
        this.comparatorItems = new Map<string, ComparatorItemClient>();
        this.showCountCompareDifference = false;
    }

    private async getEntityCompareVersionDifferenceList() {
        this.entityDifferenceCountByVersionId.clear();
        if (!this.currentEntity) {
            return;
        }
        const result =
            await this.versioningUiService.entityCompareVersionsDifference(
                this.currentVersionId,
                this.selectedVersionId,
                this.currentSpaceId,
                this.currentEntity.ReferenceId
            );
        result?.VersionItems.forEach((entityCompIem) => {
            this.entityDifferenceCountByVersionId.set(entityCompIem.VersionId, {
                versionId: entityCompIem.VersionId,
                differenceCount: entityCompIem.DifferenceCount,
                status: entityCompIem.Operation,
            });
        });
    }

    private getDiffText(version: ProjectVersion) {
        const info = this.entityDifferenceCountByVersionId.get(
            version.ProjectVersionId
        );
        if (!info) {
            return;
        }
        if (info.status == CompareOperation.Deleted) {
            return this.translate.instant(
                'UI.Versioning.Comparator.Inexistant'
            );
        }
        if (info.differenceCount == 0) {
            return this.translate.instant('UI.Versioning.Comparator.identical');
        }
        if (info.differenceCount > 0) {
            return this.translate.instant(
                'UI.Versioning.Comparator.differenceCount',
                { differenceCount: info.differenceCount }
            );
        }
    }
    private getDiffClass(version: ProjectVersion) {
        const info = this.entityDifferenceCountByVersionId.get(
            version.ProjectVersionId
        );
        if (!info) {
            return;
        }
        if (info.status == CompareOperation.Deleted) {
            return 'inexistant-version';
        }
        if (info.differenceCount == 0) {
            return 'identical-version';
        }
        if (info.differenceCount > 0) {
            return 'difference-version';
        }
    }

    private async generateComparisonSingleItem(
        currentEntity: EntityItem,
        versionItems: EntityComparisonItem[]
    ) {
        this.log('generateComparisonSingleItem', versionItems);
        const attributes = await this.attributeDataService.loadAttributes([
            ServerType[currentEntity.HddData.Data.DataServerType],
        ]);

        // comparaison de version
        const diffCount = versionItems[1]?.DifferenceCount ?? 0;
        this.comparationSingleItem = {
            ModifiedAttributeKeys: versionItems[0].ModifiedAttributeKeys,
            sourceAttributes: versionItems[0].Attributes,
            targetAttributes: versionItems[1]?.Attributes,
        };

        const sourceValues = Array.from(attributes.values());
        this.entityAttributes = sourceValues.filter(
            (c) =>
                c.AttributeType !== AttributeMetaType.ObjectValueList &&
                c.AttributeType !== AttributeMetaType.ObjectNameList &&
                c.AttributeType !== AttributeMetaType.Reference &&
                c.AttributeType !== AttributeMetaType.ReferenceList
        );

        // When in readOnly mode,
        // we make a copy of the meta attributes before making them readonly,
        // not to mess the cached data
        if (this.readOnly) {
            this.entityAttributes = this.entityAttributes.map((e) => {
                const clone = CoreUtil.clone(e);
                clone.IsReadOnly = true;
                return clone;
            });
        }

        const objectValueAttributes = [];

        this.comparationSingleItem.ModifiedAttributeKeys.forEach(
            (singleItem) => {
                const foundAttributeDataItemInfos = attributes.get(singleItem);
                if (foundAttributeDataItemInfos) {
                    if (
                        foundAttributeDataItemInfos.AttributeType ==
                        AttributeMetaType.ObjectValueList
                    ) {
                        objectValueAttributes.push(singleItem);
                    } else {
                        this.comparatorItems.set(singleItem, {
                            attributeMeta: foundAttributeDataItemInfos,
                            sourceValue:
                                this.comparationSingleItem.sourceAttributes[
                                    singleItem
                                ],
                            targetValue:
                                this.comparationSingleItem.targetAttributes[
                                    singleItem
                                ],
                            newValue:
                                this.comparationSingleItem.sourceAttributes[
                                    singleItem
                                ],
                        });
                    }
                }
            }
        );

        objectValueAttributes.forEach((objectValueItem) => {
            const foundAttributeDataItemInfos = attributes.get(objectValueItem);
            if (foundAttributeDataItemInfos) {
                const sourceAttributeData = this.comparatorItems.get(
                    foundAttributeDataItemInfos.SourceAttributeKey
                );
                sourceAttributeData.sourceObjectValues =
                    this.comparationSingleItem.sourceAttributes[
                        objectValueItem
                    ];
                sourceAttributeData.undoObjectValues =
                    this.comparationSingleItem.sourceAttributes[
                        objectValueItem
                    ];
                sourceAttributeData.targetObjectValues =
                    this.comparationSingleItem.targetAttributes[
                        objectValueItem
                    ];
            }
        });

        if (diffCount != 0) {
            this.countCompareDifference = this.translate.instant(
                'UI.Versioning.Comparator.differenceCount',
                { differenceCount: diffCount }
            );
            this.showCountCompareDifference = true;
        } else {
            this.showCountCompareDifference = false;
        }
        super.preventNg0100Error(this.changeDetector);
    }
}
