import * as moment from 'moment';
import { Component, Input } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DxyMiniAvatarsComponent } from '../../shared/shared-ui/dxy-mini-avatars/dxy-mini-avatars.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-insight-activity',
    templateUrl: 'dxy-insight-activity.component.html',
    styleUrls: ['dxy-insight-activity.component.scss'],
    standalone: true,
    imports: [
        DxyCollapsibleComponent,
        NgIf,
        TranslateModule,
        DxyMiniAvatarsComponent,
    ],
})
export class DxyInsightActivityComponent extends DxyBaseComponent {
    @Input() currentEntity: EntityItem;

    public get isCreationUserIdAvailable() {
        return !!this.currentEntity?.CreationUserId;
    }
    public get creationUserId() {
        return this.currentEntity?.CreationUserId;
    }
    public get isCreationTimeAvailable() {
        return !!this.currentEntity?.CreationTime;
    }
    public get creationTime() {
        return (
            this.currentEntity &&
            moment(this.currentEntity.CreationTime).format('D MMM YYYY')
        );
    }

    public get isLastModificationUserAvailable() {
        return !!this.currentEntity?.LastModificationUserId;
    }
    public get lastModificationUserId() {
        return this.currentEntity?.LastModificationUserId;
    }
    public get isLastModificationTimeAvailable() {
        return !!this.currentEntity?.LastModificationTime;
    }
    public get lastModificationTime() {
        return (
            this.currentEntity &&
            moment(this.currentEntity.LastModificationTime).format('D MMM YYYY')
        );
    }

    constructor() {
        super();
    }
}
