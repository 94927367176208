import { OpenFeatureService } from '@datagalaxy/open-feature';
import { BACKEND_API_CONFIG, v3ApiUrl } from '@datagalaxy/data-access';
import { OFREPWebProvider } from '@openfeature/ofrep-web-provider';
import { inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OpenFeatureInitializer {
    private openFeatureService = inject(OpenFeatureService);
    private apiConfig = inject(BACKEND_API_CONFIG);

    constructor() {}

    public async init(clientId: string) {
        const jwt = (await this.apiConfig.getJWT?.()) ?? '';

        await this.openFeatureService.init({
            targetingKey: clientId,
            provider: new OFREPWebProvider({
                baseUrl: v3ApiUrl.endsWith('/')
                    ? v3ApiUrl.slice(0, -1)
                    : v3ApiUrl,
                pollInterval: -1,
                headers: [['Authorization', `Bearer ${jwt}`]],
            }),
        });
    }
}
