import { LineageItem } from './LineageItem';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import {
    GetDataLineageResult,
    LineageOrientation,
} from '@datagalaxy/webclient/explorer/data-access';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';

export class LineageGraphParams {
    public static isOrientationChange(
        a: LineageGraphParams,
        b: LineageGraphParams
    ) {
        return !!(
            a &&
            b &&
            EntityIdentifier.areSame(a.originIdr, b.originIdr) &&
            a.orientation != b.orientation
        );
    }

    constructor(
        /** entity identifier for which the lineage graph has been asked */
        public originIdr: IEntityIdentifier,
        public orientation: LineageOrientation,
        public getData: (
            forceReload: boolean,
            forceLoadAllItems?: boolean,
            directLinksOnly?: boolean
        ) => Promise<GetDataLineageResult>,
        public canUnlink = false,
        public loadChildrenAndLinked?: (
            item: LineageItem
        ) => Promise<GetDataLineageResult>
    ) {}
}
