import { FieldType } from '../../types/enums/field-type.enum';

export const tableauConfig = [
    { payloadField: 'usage-technology', payloadValue: 'tableau' },
    {
        payloadField: 'protocol',
        formField: 'protocol',
        formMandatory: true,
        type: FieldType.select,
        selectValues: ['http', 'https'],
    },
    {
        payloadField: 'server',
        formField: 'server',
        formMandatory: true,
        type: FieldType.text,
    },
    { payloadField: 'port', formField: 'port', type: FieldType.text },
    {
        payloadField: 'site-content-url',
        formField: 'siteContentUrl',
        type: FieldType.text,
    },
    {
        payloadField: 'api-version',
        formField: 'apiVersion',
        payloadValue: '3.6',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'user',
        formField: 'user',
        tradKey: 'personalAccessTokenName',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        tradKey: 'personalAccessTokenSecret',
        isPassword: true,
        formMandatory: true,
    },
];
