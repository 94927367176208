import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DomainName } from './domain-name';

export function createDomainNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            new DomainName(control.value);
        } catch {
            return {
                invalidDomainName: true,
            };
        }
        return null;
    };
}
