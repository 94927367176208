import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
    DxyFieldMultiSelectComponent,
    DxyFieldSelectComponent,
    IFieldSelectSelectedItemClickEvent,
    IMultiSelectFieldSelectedItemClickEvent,
} from '@datagalaxy/core-ui/fields';
import { IDxyEntitySelectorInputs } from '../entity-selector.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { coerceArray } from '@angular/cdk/coercion';
import { AttributeFilterAction } from '../../attribute/attribute-filter/attribute-filter.types';
import { EntityType, IHasHddData } from '@datagalaxy/dg-object-model';
import {
    IActionOption,
    IFieldSelectAdapter,
    IMultiSelectData,
    ITranslate,
    UiOptionSelectDataType,
} from '@datagalaxy/core-ui';
import { MultiSelectAdapter } from '../../shared-ui/UiMultiSelect.util';
import { SearchUsage } from '../../util/DgServerTypesApi';
import { AttributeQuickFiltersComponent } from '../../attribute/attribute-filter/attribute-quick-filters/attribute-quick-filters.component';
import { EntityPreviewPanelService } from '../../entity/services/entity-preview-panel.service';
import { SearchService } from '../../../search/search.service';
import { CurrentSearch } from '../../../search/models/CurrentSearch';
import { AttributeFilterModel } from '../../attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { multiSelectEntityCardParams } from '../../entityCard/entity-card/entity-card-cell.types';
import {
    EntityIdentifier,
    EntityTypeUtils,
} from '@datagalaxy/webclient/entity/utils';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { AttributeFilterComponent } from '../../attribute/attribute-filter/attribute-filter/attribute-filter.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf, NgClass, NgTemplateOutlet, NgFor } from '@angular/common';

/**
 * ## Role
 * Dropdown with filters to select one or many entities
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-selector-field',
    templateUrl: 'dxy-entity-selector-field.component.html',
    standalone: true,
    imports: [
        NgIf,
        DxyFieldMultiSelectComponent,
        FormsModule,
        NgClass,
        MatLegacyTooltipModule,
        NgTemplateOutlet,
        DxyFieldSelectComponent,
        NgFor,
        forwardRef(() => AttributeFilterComponent),
        AttributeQuickFiltersComponent,
        TranslateModule,
    ],
})
export class DxyEntitySelectorFieldComponent<T extends IHasHddData>
    extends DxyBaseFocusableFieldComponent<T | T[]>
    implements OnInit, OnChanges
{
    @Input() options: IDxyEntitySelectorInputs;
    @Input() noRemove: boolean;
    @Input() isMultiValue: boolean;
    @Input() maxDisplayedOptions?: number;
    @Input() openMenuOnFocus: boolean;
    /**
     * If true, the mat-menu will take the same width as the field control
     * Only needed with @Input() isMultiValue set to true
     */
    @Input() menuTakeFullWidth: boolean;
    @Input() mini = false;
    /** If true, open entity preview pane on selected items click */
    @Input() openPreviewOnSelectedItemClick: boolean;

    /** Classes applied to multiselect/select fields */
    @Input() fieldClass: string;

    @Output() readonly onRemove = new EventEmitter<T>();
    @Output() readonly onSelected = new EventEmitter<EntityItem>();
    /** Emits when the menu panel is opened or closed, a value of *true* meaning opened */
    @Output() readonly openClose = new EventEmitter<boolean>();

    @ViewChild(DxyFieldMultiSelectComponent)
    multiselectField: DxyFieldMultiSelectComponent<T>;
    @ViewChild(DxyFieldSelectComponent) selectField: DxyFieldSelectComponent<T>;

    @ViewChild(AttributeQuickFiltersComponent)
    quickFiltersComponent: AttributeQuickFiltersComponent;

    public multiSelectData: IMultiSelectData<IHasHddData>;
    public currentSearch: CurrentSearch;
    public searchFilters: AttributeFilterModel[] = [];
    public quickFilters: Filter[] = [];

    public get field() {
        return this.multiselectField || this.selectField;
    }

    public get fieldControl() {
        return (
            this.multiselectField?.fieldControl ||
            this.selectField?.fieldControl
        );
    }

    public get label() {
        return this.getLabel(this.translate);
    }

    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }

    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    public get hasSearchFilters() {
        return this.searchFilters?.length;
    }

    public get hasQuickFilters() {
        return this.quickFilters?.length;
    }

    public get propertyTypeNameKey() {
        const types = this.options?.includedEntityTypes;
        if (!(types?.length == 1)) {
            return;
        }
        const entityTypeName = EntityType[types[0]];
        return `DgServerTypes.PropertyTypePlural.${entityTypeName}`;
    }

    public get miniValueGlyphClass() {
        const items = this.multiSelectData?.selectedItems;
        if (!items?.length) {
            return;
        }
        const firstValue = items[0];
        return EntityTypeUtils.getColoredGlyphClass(
            firstValue.HddData.Data.EntityType
        );
    }

    public get hasIncludedIds() {
        return !!this.options?.includedIds?.length;
    }

    public get values() {
        return this.value as T[];
    }

    public get miniValueTooltipText() {
        return (this.value as T[])
            ?.map((v) => v.HddData.Data.DisplayName)
            .join(', ');
    }

    //#region API
    public get hasOpenPanel() {
        return this.field.hasOpenPanel;
    }

    //#endregion

    public selectAdapter: IFieldSelectAdapter<IHasHddData>;
    public selectOptions: IHasHddData[];

    private searchTerm: string;

    constructor(
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
        protected elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private searchService: SearchService,
        private attributeDataService: AttributeDataService
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngOnInit() {
        super.ngOnInit();
        this.logId ??= this.label;
        this.initAsync();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        super.onChange(changes, 'options', async () => {
            await this.initAsync();
            await this.buildOptions();
        });
    }

    async writeValue(value: T[] | T) {
        super.writeValue(value);
        await this.buildOptions();
    }

    //#region API
    public doFocus() {
        this.field.doFocus();
    }

    //#endregion

    public async onSelectSearch(searchTerm: string) {
        const res = await this.onSearch(searchTerm);

        this.adaptSelectOptions(res);
        this.selectOptions = res;
    }

    public onSelectedItemClick(
        event:
            | IMultiSelectFieldSelectedItemClickEvent<IHasHddData>
            | IFieldSelectSelectedItemClickEvent<IHasHddData>
    ) {
        if (
            this.openPreviewOnSelectedItemClick &&
            (event.data.HddData.Data.HasReadAccess ?? true)
        ) {
            event.event.stopPropagation();
            this.entityPreviewPanelService.setupPanel({
                entityIdr: EntityIdentifier.fromIHasHddData(event.data),
            });
        }
    }

    public onMultiSelectOpenClose(open: boolean) {
        this.openClose.emit(open);

        setTimeout(() => {
            this.quickFiltersComponent?.refresh();
        }, 250);
    }

    public async onFilterItemAction(
        afm: AttributeFilterModel,
        action: AttributeFilterAction
    ) {
        switch (action) {
            case AttributeFilterAction.itemRemoved:
                await this.onFilterRemove(afm);
                break;
            case AttributeFilterAction.itemChanged:
                await this.multiselectField?.triggerSearch();
                this.refreshMultiSelectField();
                break;
        }
    }

    public async selectQuickFilterEvent(afm: AttributeFilterModel) {
        this.currentSearch.addFilterItem(afm);
        this.setSearchFilters();
        this.setQuickFilters();
        await this.multiselectField?.triggerSearch();
        this.refreshMultiSelectField();
    }

    /** Avoid error message when field is untouched. */
    protected override getErrorMessage(translate: TranslateService) {
        if (!this.touched) {
            return null;
        }
        return super.getErrorMessage(translate);
    }

    /** avoid double ** when field is required */
    protected override getLabel(translate: ITranslate) {
        const label =
            this.labelText ||
            (this.labelKey && translate.instant(this.labelKey)) ||
            '';
        return label;
    }

    private async buildOptions() {
        let items: IHasHddData[];
        const selectedItems =
            this.value && (coerceArray(this.value) as IHasHddData[]);
        await this.setCurrentSearch();

        if (this.options?.initialSearch || this.hasIncludedIds) {
            items = await this.onSearch(this.currentSearch?.searchTerm);
        }

        if (this.isMultiValue) {
            this.buildMultiSelectData(items, selectedItems);
        } else {
            this.buildSelectAdapter(items, selectedItems);
        }
    }

    private buildMultiSelectData(
        items: IHasHddData[],
        selectedItems: IHasHddData[]
    ) {
        this.multiSelectData = {
            items,
            selectedItems,
            searchParams: {
                enabled: true,
                threshold: false,
            },
            dataType: UiOptionSelectDataType.entityReference,
            isRestrictedItem: (data) => {
                return !(data.HddData?.Data.HasReadAccess ?? true);
            },
            adapter: {
                getRenderData: (hddData) =>
                    MultiSelectAdapter.getEntityRenderData(hddData, (data) =>
                        this.getEntityCardParams(data)
                    ),
            },
            onSearch: (searchTerm) => this.onSearch(searchTerm),
        };
    }

    private getEntityCardParams(data: IHasHddData) {
        const actions: IActionOption<IHasHddData>[] = [];

        if (this.openPreviewOnSelectedItemClick) {
            actions.push({
                glyphClass: 'glyph-object-preview',
                tooltipTranslateKey: 'UI.Global.openPreview',
                alwaysVisible: true,
                callback: () =>
                    this.entityPreviewPanelService.setupPanel({
                        entityIdr: EntityIdentifier.fromIHasHddData(data),
                    }),
            });
        }
        const params = multiSelectEntityCardParams;
        params.inputs.isRestrictedItem = (data) => {
            return !(data.data?.Data?.HasReadAccess ?? true);
        };

        return {
            ...params,
            actions,
            data,
        };
    }

    private buildSelectAdapter(
        items: IHasHddData[],
        selectedItems: IHasHddData[]
    ) {
        this.selectAdapter = {
            getRenderData: (hddData) =>
                MultiSelectAdapter.getEntityRenderData(hddData, (data) => {
                    const params = multiSelectEntityCardParams;
                    params.inputs.isRestrictedItem = (data) =>
                        !(data?.data?.Data?.HasReadAccess ?? true);
                    return {
                        ...params,
                        data,
                    };
                }),
        };

        this.adaptSelectOptions(items);
        this.selectField.canRemove = (data: IHasHddData) =>
            data?.HddData?.Data.HasReadAccess;
        this.selectOptions = [...(selectedItems ?? []), ...(items ?? [])];
    }

    private adaptSelectOptions(items: IHasHddData[]) {
        if (this.value) {
            const selectedValue = this.value as T;
            const selectedItem = items?.find(
                (item) =>
                    item.HddData.DataReferenceId ===
                    selectedValue?.HddData?.DataReferenceId
            );
            if (!selectedItem) {
                items?.unshift(selectedValue);
            } else {
                this.value = selectedItem as T;
            }
        }
    }

    private async onFilterRemove(afm: AttributeFilterModel) {
        this.currentSearch.removeFilterItem(afm);
        this.setSearchFilters();
        this.setQuickFilters();
        this.refreshMultiSelectField();
        await this.multiselectField?.triggerSearch();
    }

    private async onSearch(searchTerm: string): Promise<IHasHddData[]> {
        const minChars = this.options.minChars;
        const isSearchTermTooShort =
            minChars && (!searchTerm || searchTerm?.length < minChars);
        const hasFilters = this.searchFilters?.length;

        if (isSearchTermTooShort && !hasFilters) {
            return [];
        }

        this.searchTerm = this.currentSearch.searchTerm = searchTerm;

        const excludedIds =
            this.options.includedIds?.length > 0
                ? []
                : [
                      ...(this.options.excludedIds || []),
                      ...coerceArray(this.value || [])?.map(
                          (o) => o.HddData.DataReferenceId
                      ),
                  ];

        const res = await this.searchService.searchPreview(this.currentSearch, {
            searchUsage: SearchUsage.EntitySelector,
            excludedIds,
            includedIds: this.options.includedIds,
            includedAttributesFilter: this.options.includedAttributesFilter,
            includeSearchPreferences: this.options.includeSearchPreferences,
        });

        const selectedItems =
            this.value && (coerceArray(this.value) as IHasHddData[]);
        const items = res?.allItems as IHasHddData[];
        CollectionsHelper.replace(selectedItems, (selectedItem) =>
            items?.find(
                (item) =>
                    item.HddData.DataReferenceId ===
                    selectedItem?.HddData.DataReferenceId
            )
        );
        return items;
    }

    private async initAsync() {
        if (this.readonly) {
            return;
        }
        await this.setCurrentSearch();
        this.setQuickFilters();
        this.refreshMultiSelectField();
    }

    private async setCurrentSearch() {
        const filters = new Array<Filter>();
        this.searchFilters = [];
        if (this.options?.includedEntityTypes?.length) {
            filters.push(
                new Filter(
                    ServerConstants.Search.EntityTypeFilterKey,
                    FilterOperator.ListContains,
                    this.options.includedEntityTypes.map((e) => EntityType[e])
                )
            );
        }

        if (this.options?.excludedEntityTypes?.length) {
            filters.push(
                new Filter(
                    ServerConstants.Search.EntityTypeFilterKey,
                    FilterOperator.ListExcludes,
                    this.options.excludedEntityTypes.map((e) => EntityType[e])
                )
            );
        }
        this.currentSearch =
            await this.searchService.getCurrentSearchFromFilters(
                filters,
                this.options?.spaceIdr?.spaceId,
                this.options?.spaceIdr?.versionId
            );
        this.currentSearch.searchTerm = this.searchTerm;
    }

    private setQuickFilters() {
        const includedEntityTypes = this.options?.includedEntityTypes ?? [];
        const showTypeFilter = includedEntityTypes?.length > 1;
        const typeFilters = showTypeFilter
            ? CollectionsHelper.orderBy(includedEntityTypes, (et) =>
                  this.attributeDataService.getEntityTypeTranslation(et)
              ).map(
                  (type) =>
                      new Filter(
                          ServerConstants.Search.EntityTypeFilterKey,
                          FilterOperator.ListContains,
                          [EntityType[type]]
                      )
              )
            : [];
        const parentsFilter = this.options?.useParentsFilter
            ? new Filter(
                  ServerConstants.PropertyName.Parents,
                  FilterOperator.ListContains,
                  [],
                  true
              )
            : null;

        this.quickFilters = [parentsFilter, ...typeFilters].filter((filter) => {
            const filterAlreadyUsed = this.searchFilters.some(
                (f) => f.attributeKey === filter?.AttributeKey
            );
            return filter && !filterAlreadyUsed;
        });
    }

    private setSearchFilters() {
        this.searchFilters = this.currentSearch.filterItems.filter((afm) => {
            const isSearchFilter =
                afm.attributeKey === ServerConstants.Search.SearchTermFilterKey;
            return !isSearchFilter && afm.isFromQuickFilter;
        });
    }

    private refreshMultiSelectField() {
        setTimeout(() => this.multiselectField?.refreshUi());
    }
}
