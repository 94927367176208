import {
    ChangeDetectionStrategy,
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeNumberFilterModel } from './AttributeNumberFilterModel';
import { AttributeFilterService } from '../attribute-filter.service';
import {
    INumberFilterData,
    NumberFilterOperator,
    DxyNumberFilterComponent,
} from '@datagalaxy/core-ui/filters';
import { AttributeFilterAction } from '../attribute-filter.types';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';

/**
 * ## Role
 * Display a number attribute filter
 */
@Component({
    selector: 'app-attribute-number-filter',
    templateUrl: './attribute-number-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DxyNumberFilterComponent],
})
export class AttributeNumberFilterComponent
    extends BaseAttributeFilter<AttributeNumberFilterModel>
    implements OnInit, OnChanges
{
    @ViewChild(DxyNumberFilterComponent)
    filterComponent: DxyNumberFilterComponent;

    filter: INumberFilterData;

    public get operators() {
        return this.filterItemData.operators.map((op) =>
            this.getTypedOperator(op)
        );
    }

    private get value() {
        return this.filterItemData.value;
    }
    private get value2() {
        return this.filterItemData.value2;
    }

    constructor(attributeFilterService: AttributeFilterService) {
        super(attributeFilterService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItemData', () => this.init());
    }

    public onFilterChange() {
        this.filterItemData.operator =
            FilterOperator[NumberFilterOperator[this.filter.operator]];
        this.filterItemData.value = this.filter.min?.toString();
        this.filterItemData.value2 = this.filter.max?.toString();

        this.onAction.emit(AttributeFilterAction.itemChanged);
    }

    private init() {
        const value = this.value,
            value2 = this.value2;
        this.filter = {
            operator: this.getTypedOperator(this.filterItemData.operator),
            min: value && parseInt(value, 10),
            max: value2 && parseInt(value2, 10),
        };
    }

    private getTypedOperator(filterOperator: FilterOperator) {
        const operatorText = FilterOperator[filterOperator];
        return NumberFilterOperator[operatorText];
    }
}
