<dxy-value-list-filter
    [operators]="operators"
    [(filter)]="filter"
    [isMini]="isMini"
    [noOperator]="isQuickFilter"
    [forceSingleSelection]="isQuickFilter"
    [readonly]="readOnly"
    [items]="filterOptions"
    [adapter]="filterAdapter"
    [labelText]="label"
    [config]="filterConfig"
    [defaultIconClass]="iconClass"
    [hasRemove]="showRemoveButton"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    [menuHasDynamicHeight]="true"
    (filterChange)="onFilterChange()"
    (remove)="onRemove()"
    (onOpenClose)="onOpenCloseMenu($event)"
>
    <div *ngIf="hasSearchFilters" headerBeforeSearch class="filters-wrappers">
        <app-attribute-filter
            *ngFor="let afm of searchFilters"
            [filterItemData]="afm"
            [isMini]="true"
            [isReadOnly]="true"
            [hasRemove]="true"
            (onAction)="onFilterItemAction(afm, $event)"
            class="filter"
        ></app-attribute-filter>
    </div>
    <app-attribute-quick-filters
        *ngIf="hasQuickFilters"
        headerAfterSearch
        class="quick-filters"
        [currentSearch]="currentSearch"
        [quickFilters]="quickFilters"
        [hideLookingFor]="true"
        (onSelectFilter)="selectQuickFilterEvent($event)"
    ></app-attribute-quick-filters>
</dxy-value-list-filter>
