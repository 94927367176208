import {
    ISpaceVersionSelectedEvent,
    ISpaceVersionSelector,
    ISpaceVersionSelectorInputs,
} from './space-version-selector.types';
import { AppSpaceService } from '../services/AppSpace.service';
import {
    INavSpaceSelectedEvent,
    IProjectVersionSelectedEvent,
} from './space.types';
import { SecurityService } from '../services/security.service';
import {
    NavOrganization,
    NavProject,
    NavSpace,
} from '@datagalaxy/webclient/workspace/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export class SpaceVersionSelectorCore implements ISpaceVersionSelectorInputs {
    private debugDetailed = true;
    private debugWarnOnly = false;

    //#region ISpaceVersionSelectorInputs
    public projectsOnly: boolean;
    public allowAll: boolean;
    public defaultAll: boolean;
    public versionLabelAll: string;
    public spaceIdr: ISpaceIdentifier;
    public hasLabel: boolean;
    public allowLastModuleSpace: boolean;
    public isSmallCaretBtn: boolean;
    public enableFavorite: boolean;
    public showSpaceDropdown: boolean;
    public showVersionDropdown: boolean;
    public useCurrentSpace: boolean;
    public isSpaceSelectable: boolean;
    public isVersionSelectable: boolean;
    public showAllSpaceRedirection: boolean;
    public showSelectedSpaceIcon: boolean;
    public onlyImportable: boolean;
    public hideOfficialVersion: boolean;
    public onlyImportableCatalog: boolean;
    public openOnSpaceOrVersionClick: boolean;
    //#endregion

    public projectIdr: SpaceIdentifier;
    public isProjectVersioned: boolean;
    public get spaceId() {
        return this._spaceId;
    }
    public get isSpaceVisible() {
        return this.showSpaceDropdown;
    }
    public get isAll() {
        return this.allowAll && !this._spaceId && !this.isSingleWorkspace;
    }
    public get showSeparator() {
        return this.showVersion || this.showOfficialVersion;
    }
    public get showVersion() {
        return this.showVersionDropdown && this.isProjectVersioned;
    }
    public get showVersionLabel() {
        return this.hasLabel && !this.isAll && this.showVersion;
    }
    public get showOfficialVersion() {
        return this.isAll && !this.hideOfficialVersion;
    }
    public get showOfficialVersionLabel() {
        return this.showOfficialVersion && this.hasLabel;
    }

    private initDone = false;
    /** IMPORTANT: write only using setIds() */
    private _spaceId: string;
    /** IMPORTANT: write only using setIds() */
    private _versionId: string;
    private lastSpace: NavSpace;
    private lastVersion: ProjectVersion;
    private get isSingleWorkspace() {
        return this.securityService.isSingleWorkspaceClient();
    }

    constructor(
        private appSpaceService: AppSpaceService,
        private securityService: SecurityService,
        private onSpaceVersionSelected: (
            event: ISpaceVersionSelectedEvent
        ) => void,
        private onSpaceNameClick: () => void,
        private onVersionNameClick: () => void,
        private refreshUI: () => void,
        private debug: boolean,
        private logFn: (...args: any[]) => void,
        private warn: (...args: any[]) => void
    ) {}

    public async init(host: ISpaceVersionSelector) {
        this.getInputs(host, true);
        await this.init2(host);
    }

    public onInputChange(host: ISpaceVersionSelector) {
        this.getInputs(host);
    }

    public async onChangeSpaceIdr(
        spaceIdr: ISpaceIdentifier,
        host: ISpaceVersionSelector
    ) {
        if (SpaceIdentifier.areSame(spaceIdr, this.spaceIdr)) {
            return;
        }
        this.spaceIdr = spaceIdr;
        await this.init2(host);
    }

    public onSpaceSelected(event: INavSpaceSelectedEvent) {
        this.debugDetailed && this.log('onSpaceSelected', event);
        const { navSpace, isUserAction } = event;
        this.lastSpace = navSpace;
        this.setSelected(navSpace, false, isUserAction);
        this.isProjectVersioned =
            navSpace instanceof NavProject && navSpace.IsVersioningEnabled;
        this.refreshUI();
    }

    public onVersionSelected(event: IProjectVersionSelectedEvent) {
        this.debugDetailed && this.log('onVersionSelected:', event);
        const { projectVersion, isUserAction } = event;
        this.lastVersion = projectVersion || null;
        this.setSelected(projectVersion, true, isUserAction);
    }

    public onSpaceNameClickInternal() {
        if (!this.openOnSpaceOrVersionClick) {
            this.onSpaceNameClick();
        }
    }

    public onVersionNameClickInternal() {
        if (!this.openOnSpaceOrVersionClick) {
            this.onVersionNameClick();
        }
    }

    private getInputs(host?: ISpaceVersionSelector, isInit = false) {
        this.log('getInputs', isInit);
        const isSingleWorkspace = this.isSingleWorkspace;
        host.showSpaceDropdown ??= true;
        host.showVersionDropdown ??= true;
        host.isSpaceSelectable ??= true;
        host.isVersionSelectable ??= true;
        host.openOnSpaceOrVersionClick ??= true;

        if (isInit) {
            this.spaceIdr = host.spaceIdr;
        }
        this.projectsOnly = host.projectsOnly;
        this.allowAll = host.allowAll;
        this.defaultAll = host.defaultAll;
        this.versionLabelAll = host.versionLabelAll;
        this.hasLabel = host.hasLabel;
        this.allowLastModuleSpace = host.allowLastModuleSpace;
        this.isSmallCaretBtn = host.isSmallCaretBtn;
        this.enableFavorite = host.enableFavorite;
        this.showSpaceDropdown = host.showSpaceDropdown;
        this.showVersionDropdown = host.showVersionDropdown;
        this.useCurrentSpace = host.useCurrentSpace;
        this.isSpaceSelectable = host.isSpaceSelectable && !isSingleWorkspace;
        this.isVersionSelectable = host.isVersionSelectable;
        this.showAllSpaceRedirection =
            host.showAllSpaceRedirection && !isSingleWorkspace;
        this.showSelectedSpaceIcon = host.showSelectedSpaceIcon;

        this.onlyImportable = host.onlyImportable;
        this.hideOfficialVersion = host.hideOfficialVersion;
        this.onlyImportableCatalog = host.onlyImportableCatalog;

        this.openOnSpaceOrVersionClick = host.openOnSpaceOrVersionClick;
    }

    private async init2(host: ISpaceVersionSelector) {
        this.log('init2', this.spaceIdr, this.allowAll, this.defaultAll);
        this.initDone = this.lastSpace = this.lastVersion = undefined;

        if (this.allowAll) {
            this.defaultAll = this.spaceIdr && !this.spaceIdr.spaceId;
        } else {
            this.defaultAll = false;
        }

        let spaceIdr: ISpaceIdentifier;
        if (this.defaultAll || this.spaceIdr) {
            spaceIdr = this.spaceIdr;
        } else {
            spaceIdr = await this.appSpaceService.getASpaceIdentifier({
                includeCurrent: true,
                includeLast: this.allowLastModuleSpace,
                includeNonUserDefault: true,
            });
        }
        this.setIds(spaceIdr);

        host.defaultAll = this.defaultAll;
        host.spaceIdr = this.spaceIdr;

        this.debugDetailed &&
            this.log('init2-end', this.allowAll, this.defaultAll, spaceIdr);
    }

    private setIds(spaceIdr: ISpaceIdentifier, incomplete = false) {
        this.log('setIds', spaceIdr, incomplete);
        this._spaceId = spaceIdr?.spaceId;
        this._versionId = spaceIdr?.versionId;

        if (incomplete) {
            return;
        }

        if (!SpaceIdentifier.areSame(spaceIdr, this.spaceIdr)) {
            this.debugDetailed && this.log('setIds-spaceIdr', spaceIdr);
            this.spaceIdr = SpaceIdentifier.from(spaceIdr);
        }
        if (!SpaceIdentifier.areSame(spaceIdr, this.projectIdr)) {
            this.log('setIds-projectIdr', spaceIdr);
            this.projectIdr = SpaceIdentifier.from(spaceIdr);
        }
    }

    // todo (fbo) since we now have isUserAction, all this could be simplified
    private setSelected(
        spaceIdr: ISpaceIdentifier,
        isFromVersion: boolean,
        isUserAction: boolean
    ) {
        // if from project selected by the user, reset the previous selected version)
        if (isUserAction && !isFromVersion) {
            this.lastVersion = undefined;
        }

        const prevSpaceId = this._spaceId,
            prevVersionId = this._versionId,
            spaceInitDone = this.lastSpace !== undefined,
            versionInitDone = this.lastVersion !== undefined,
            isProject = this.lastSpace instanceof NavProject,
            isOrga = this.lastSpace instanceof NavOrganization,
            isVersioned =
                this.lastSpace instanceof NavProject &&
                this.lastSpace.IsVersioningEnabled,
            spaceId = spaceIdr?.spaceId || null;

        // if from project :
        // - if selected by the user, we take its default version (it will be displayed in the version selector)
        // - if non-versioned, we take its version, because even a non-versioned project has a version
        let versionId =
            ((isFromVersion || isUserAction || !isVersioned) &&
                spaceIdr?.versionId) ||
            null;

        // If we only want importable versions and space is versioned -- init it with any work version
        if (
            (this.onlyImportable || this.onlyImportableCatalog) &&
            isVersioned &&
            !isFromVersion &&
            !isUserAction
        ) {
            versionId = this.isVersionSelectable
                ? this.spaceIdr.versionId
                : (this.lastSpace as NavProject).AnyWorkVersionId;
        }

        this.debugDetailed &&
            this.debug &&
            this.log('setSelected-pre', {
                isUserAction,
                spaceId: getLocalId(spaceId),
                versionId,
                prevSpaceId: getLocalId(prevSpaceId),
                prevVersionId,
                isFromVersion,
                spaceInitDone,
                versionInitDone,
            });

        let noEvent = false;
        let versionNeeded = false;
        if (!this.initDone) {
            if (!spaceId && !this.allowAll) {
                // not expected
                this.warn(
                    'setSelected-init',
                    'no space received',
                    this.lastSpace,
                    this.lastVersion
                );
                return;
            }
            if (!spaceInitDone || !versionInitDone) {
                if (isFromVersion) {
                    if (isUserAction && versionId && spaceId) {
                        this.log(
                            'setSelected-init-isUserAction',
                            'projectVersion selected'
                        );
                        this.initDone = true;
                    } else {
                        this.log(
                            'setSelected-init',
                            'version received, waiting for space'
                        );
                        noEvent = true;
                    }
                } else if (isProject) {
                    if (isVersioned) {
                        if (isUserAction && versionId) {
                            this.log(
                                'setSelected-init',
                                'space(versioned project) received with versionId, no version awaited'
                            );
                            this.lastVersion = null;
                        } else {
                            this.log(
                                'setSelected-init',
                                'space(versioned project) received, waiting for version'
                            );
                            noEvent = true;
                            versionNeeded = true;
                        }
                    } else {
                        this.log(
                            'setSelected-init',
                            'non-versioned project received, no version awaited'
                        );
                        this.lastVersion = null;
                    }
                } else {
                    this.log(
                        'setSelected-init',
                        'orga received, no version awaited'
                    );
                    this.lastVersion = null;
                }
            } else {
                let initDone = true;
                if (isFromVersion) {
                    this.log(
                        'setSelected-init',
                        'version received, after space'
                    );
                    if (spaceId != this.lastSpace.spaceId) {
                        // not expected, but since we have spaceId and versionId, let it go
                        this.warn(
                            'version received after space, with project mismatch',
                            this.lastSpace,
                            this.lastVersion
                        );
                    } else if (versionId == prevVersionId) {
                        this.log('setSelected-init', 'version unchanged');
                        noEvent = true;
                    }
                } else if (isProject && isVersioned) {
                    this.log(
                        'setSelected-init',
                        'space(project) received, after version'
                    );
                    if (spaceId != this.lastVersion?.spaceId) {
                        this.log(
                            'setSelected-init',
                            'space/version project mismatch => wait for version to be updated'
                        );
                        this.lastVersion = undefined;
                        noEvent = true;
                        versionNeeded = true;
                        initDone = false;
                    } else {
                        // project matches. keep selected version
                        versionId = this.lastVersion?.versionId;
                    }
                }
                this.initDone = initDone;
            }
        } else if (spaceId === prevSpaceId && versionId === prevVersionId) {
            this.log('setSelected', 'no change');
            noEvent = true;
        } else if (!this._spaceId && !this.allowAll) {
            this.log('setSelected', 'no space not allowed');
            noEvent = true;
        } else if (!versionId && !isFromVersion && isProject && isVersioned) {
            this.log('setSelected', 'project received, waiting for version');
            noEvent = true;
            versionNeeded = true;
        } else if (!spaceId && isFromVersion && (isOrga || !isVersioned)) {
            this.log(
                'setSelected',
                'null space received from version after orga or non versioned project'
            );
            noEvent = true;
            this.lastVersion = null;
        }
        if (!versionId) {
            versionId = isOrga ? '' : null;
        }

        this.debugDetailed &&
            this.debug &&
            this.log('setSelected-post', {
                isFromVersion,
                prevSpaceId: getLocalId(prevSpaceId),
                prevVersionId,
                spaceId: getLocalId(spaceId),
                versionId,
            });

        const newSpaceIdr = new SpaceIdentifier(spaceId, versionId);
        this.setIds(newSpaceIdr, versionNeeded);

        if (noEvent) {
            this.log('setSelected no event');
            return;
        }

        this.log('setSelected *event*', isUserAction, newSpaceIdr);
        this.onSpaceVersionSelected({ spaceIdr: newSpaceIdr, isUserAction });
    }

    private log(...args: any[]) {
        if (!this.debug || this.debugWarnOnly) {
            return;
        }
        this.logFn(...args);
    }
}
