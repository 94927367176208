<header>
    <div class="entity-type"></div>
    <div class="title"></div>
    <div class="title"></div>
    <div class="attributes"></div>
</header>
<div class="separator"></div>
<main>
    <nav>
        <div class="icon-button"></div>
        <div class="icon-button"></div>
        <div class="icon-button"></div>
        <div class="icon-button"></div>
        <div class="icon-button"></div>
        <div class="icon-button"></div>
    </nav>
    <article>
        <div class="text"></div>
        <div class="text"></div>
    </article>
</main>
