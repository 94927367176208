<div class="file-report">
    <div class="left-align">
        <span class="title" translate
            >Import.GenericImportWizard.CsvPreImport.FileDataTxt</span
        >
        <div class="file-data">
            <span class="glyph-csv-file"></span>
            <span>{{ importContext.csvFileName }}</span>
        </div>
    </div>
</div>

<mat-tab-group
    class="content-fill-available no-padding"
    animationDuration="0ms"
    #matTabGroup
>
    <mat-tab [label]="mappingTabId">
        <ng-template mat-tab-label>
            <span translate
                >Import.GenericImportWizard.CsvPreImport.Mapping.TabTitle</span
            >
            <span
                *ngIf="!importContext.areAllMandatoryFieldsMatched"
                class="glyph-mandatory"
            ></span>
        </ng-template>
        <dxy-csv-pre-import-mappings
            [importContext]="importContext"
            (onInitMappingDone)="onInitMappingDone()"
            [isImportLoading]="isImportLoading"
        ></dxy-csv-pre-import-mappings>
    </mat-tab>
    <mat-tab [label]="previewTabId">
        <ng-template mat-tab-label>
            <span translate
                >Import.GenericImportWizard.CsvPreImport.Preview.TabTitle</span
            >
        </ng-template>
        <ng-template matTabContent>
            <dxy-csv-preimport-preview
                *ngIf="importContext?.attributeMatches"
                [importContext]="importContext"
            ></dxy-csv-preimport-preview>
        </ng-template>
    </mat-tab>
    <mat-tab [label]="checkTabId">
        <ng-template mat-tab-label>
            <span translate
                >Import.GenericImportWizard.CsvPreImport.Check.TabTitle</span
            >
            <span
                *ngIf="importContext.hasImportErrors"
                class="glyph-mandatory"
            ></span>
            <span
                *ngIf="
                    importContext.hasImportWarnings &&
                    !importContext.hasImportErrors
                "
                class="glyph-warning"
            ></span>
        </ng-template>
        <ng-template matTabContent>
            <dxy-csv-preimport-check
                *ngIf="importContext?.attributeMatches"
                [importContext]="importContext"
                [showActionsColumn]="true"
            ></dxy-csv-preimport-check>
        </ng-template>
    </mat-tab>
</mat-tab-group>
