import { inheritSerialization } from 'cerialize';
import { UserEventBase, registerTypedEvent } from '../events';

@inheritSerialization(UserEventBase)
@registerTypedEvent('AskMsTeamsIntegrationActivationEvent')
export class AskMsTeamsIntegrationActivationEvent extends UserEventBase {}

@inheritSerialization(UserEventBase)
@registerTypedEvent('MsTeamsIntegrationActivatedEvent')
export class MsTeamsIntegrationActivatedEvent extends UserEventBase {}

@inheritSerialization(UserEventBase)
@registerTypedEvent('MsTeamsIntegrationDeactivatedEvent')
export class MsTeamsIntegrationDeactivatedEvent extends UserEventBase {}
