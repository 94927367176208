import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { BaseMasterData, ITypeMetadata } from '../master-data';
import { ColumnRef } from '../column';
import { ForeignKeyRef } from '../foreign-key';
import { TableRef } from '../table';
import { ServerType } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseMasterData)
export class PrimaryKey extends BaseMasterData {
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'PrimaryKeyRef',
        childPropertyName: 'TableRef',
    };

    @deserialize public IsActive!: boolean;
    @deserialize public TechnicalName!: string;
    @deserialize public IsFunctionalOnly!: boolean;
    @deserializeAs(TableRef.fromId, 'TableId') public TableRef = new TableRef();
    @deserializeAs(ColumnRef.fromIds, 'OrderedColumnIds')
    public OrderedColumns: ColumnRef[] = []; //#abasedata-metadata
    @deserializeAs(ForeignKeyRef.fromIds, 'ForeignKeyIds')
    public ForeignKeys: ForeignKeyRef[] = []; //#abasedata-metadata

    public readonly ServerType = ServerType.PrimaryKey;

    constructor() {
        super();
    }
}
