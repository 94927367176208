export const sqlServerFieldInfo = {
    server: {
        hint: {
            fr: 'Nom ou adresse su serveur SQL Server',
            en: 'SQL Server name or address',
        },
    },
    serverInstance: {
        hint: {
            fr: "Nom de l'instance SQL Server ",
            en: 'SQL Server instance name ',
        },
    },
    port: {
        hint: {
            fr: "Port d'écoute de l'instance SQL Server",
            en: 'SQL Server instance port',
        },
    },
    database: {
        hint: {
            fr: 'Limite le périmètre à la base de données spécifiée',
            en: 'Limits the scope to the specified database',
        },
    },
    schema: {
        hint: {
            fr: 'Limite le périmètre au schéma spécifié',
            en: 'Limits the scope to the specified schema',
        },
    },
    user: {
        hint: {
            fr: 'Compte utilisateur SQL Server',
            en: 'SQL Server user account',
        },
    },
};
