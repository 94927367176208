import { Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyErrorPageComponent } from '../dxy-error-page/dxy-error-page.component';

@Component({
    selector: 'dxy-client-error',
    templateUrl: 'dxy-client-error.component.html',
    styleUrls: ['dxy-client-error.component.scss'],
    standalone: true,
    imports: [DxyErrorPageComponent],
})
export class DxyClientErrorComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}
