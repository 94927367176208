import { deserialize, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    TextQualityUserVoteDto,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';

@inheritSerialization(BaseServiceParameter)
export class SetTextQualityVoteParameter extends BaseServiceParameter {
    @serialize public ReferenceId?: string;
    @serialize public AttributePath?: string;
    @serialize public TextQualityVoteStatus?: TextQualityVoteStatus;
}

@inheritSerialization(BaseServiceResult)
export class SetTextQualityVoteResult extends BaseServiceResult {
    @deserialize public TextQualityUserVoteDto!: TextQualityUserVoteDto;
}
