import {
    IRectNode,
    Rect,
    RectSide,
    rectSides,
    TDomElement,
} from '@datagalaxy/core-2d-util';
import { IRectPortSpec, PortKind, PortUsage } from './index';
import { ManagedItem } from '../node/managed-item';
import { CoreUtil } from '@datagalaxy/core-util';

export class NodePort<N = unknown> implements IRectNode<N> {
    private readonly _id: string;

    public get el() {
        return this.getEl();
    }
    public get id() {
        return this._id;
    }
    public get localId() {
        return this.spec.id;
    }
    public get rect() {
        return this.getRect();
    }
    public get isSource() {
        return [PortUsage.source, PortUsage.both].includes(this.spec.usage);
    }
    public get isTarget() {
        return [PortUsage.target, PortUsage.both].includes(this.spec.usage);
    }
    public get kind() {
        return this.spec.kind;
    }

    public get excludedSides(): RectSide[] {
        if (!this.spec?.sides?.length) {
            return [];
        }
        return rectSides.filter((rs) => !this.spec.sides?.includes(rs));
    }

    constructor(public node: ManagedItem<N>, private spec?: IRectPortSpec) {
        this._id = spec?.id ? `${node.id}:${spec.id}` : node.id;
    }

    private getEl() {
        const node = this.node;
        const spec = this.spec;

        switch (spec?.kind) {
            case PortKind.custom: {
                const el: TDomElement = node.el.querySelector(
                    `[gs-port-id="${spec.id}"]`
                );

                if (!el) {
                    CoreUtil.warn(
                        `DOM Element with gs-port-id="${spec.id}" not found`
                    );
                    return;
                }
                return el;
            }
            case PortKind.node:
            default: {
                return node.el;
            }
        }
    }

    /**
     * Compute the node rect dynamically
     * Note: for a custom node, it is important to have it computed each time we
     * access it since it won't necessarily be drawn when initialized in the constructor
     */
    private getRect() {
        const nodeRect = this.getNodeRect(this.node);
        const margin = this.spec?.margin;

        if (!margin) {
            return nodeRect;
        }

        return new Rect(
            nodeRect.x - (margin.left || 0),
            nodeRect.y - (margin.top || 0),
            nodeRect.width + (margin.left || 0) + (margin.right || 0),
            nodeRect.height + (margin.top || 0) + (margin.bottom || 0)
        );
    }

    private getNodeRect(node: ManagedItem<N>): Rect {
        switch (this.spec?.kind) {
            case PortKind.custom: {
                const el = this.el;
                if (!el) {
                    return;
                }
                if (this.spec.linkFromNode) {
                    return node.rect;
                }
                return this.calculateRelativeRectangle(el, node);
            }
            case PortKind.node:
            default: {
                return node.rect;
            }
        }
    }

    private calculateRelativeRectangle(portEl: TDomElement, node: ManagedItem) {
        const portElRect = portEl.getBoundingClientRect();
        const nodeElRect = node.el.getBoundingClientRect();
        const nodeRect = node.rect;

        const widthRatio = nodeElRect.width / nodeRect.width;
        const heightRatio = nodeElRect.height / nodeRect.height;

        const rect = Rect.from({
            x: nodeRect.x + (portElRect.x - nodeElRect.x) / widthRatio,
            y: nodeRect.y + (portElRect.y - nodeElRect.y) / heightRatio,
            width: portElRect.width / widthRatio,
            height: portElRect.height / heightRatio,
        });

        return rect;
    }
}
