import { ServerType } from '@datagalaxy/dg-object-model';

export class EntityServerTypeUtils {
    public static readonly firstClassEntityServerTypes = [
        ServerType.Model,
        ServerType.Container,
        ServerType.Table,
        ServerType.Column,
        ServerType.Property,
        ServerType.DataProcessing,
        ServerType.SoftwareElement,
    ];
    public static readonly secondClassEntityServerTypes = [
        ServerType.DataProcessingItem,
        ServerType.Diagram,
        ServerType.PrimaryKey,
        ServerType.ForeignKey,
        ServerType.FilteredView,
    ];
}
