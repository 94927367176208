import { ViewType } from './app-types/ViewType';

export class ViewTypeUtil {
    static getTranslateKey(
        path: string,
        name: string,
        viewType: ViewType = ViewType.Other
    ) {
        const vt =
            viewType == ViewType.Functional
                ? 'F'
                : viewType == ViewType.Technical
                ? 'T'
                : '';
        return `${path}.${vt}${name}`;
    }
}
