<app-account-logo></app-account-logo>
<form
    *ngIf="!isInvalidToken"
    class="account-form"
    [formGroup]="accountResetPasswordForm"
    (ngSubmit)="sendRequest()"
    autocomplete="off"
>
    <!-- input-email -->
    <dxy-field-email
        *ngIf="!hasToken"
        fieldAppearance="outline"
        formControlName="mail"
        dxyDataTestId="email-field"
        [errorMessageText]="mailErrorMessage"
        [placeholder]="'UI.Reset.lblLogin' | translate"
        [hideMaxLengthHint]="true"
        (ngModelChange)="onChangeEmail()"
    >
    </dxy-field-email>

    <div *ngIf="hasToken" class="form-group">
        <label for="mail" class="control-label" translate>
            UI.Reset.lblLogin
        </label>
        <p class="form-control-static">{{ accountSetPasswordData.mail }}</p>
    </div>

    <dxy-field-password
        *ngIf="hasToken"
        fieldAppearance="outline"
        formControlName="newPassword"
        [errorMessageText]="newPasswordErrorMessage"
        [placeholder]="'UI.Reset.lblNewPassword' | translate"
        dxyDataTestId="new-password-field"
    >
    </dxy-field-password>

    <dxy-field-password
        *ngIf="hasToken"
        fieldAppearance="outline"
        formControlName="newPasswordConfirm"
        [errorMessageText]="newPasswordConfirmErrorMessage"
        [placeholder]="'UI.Reset.lblNewPasswordConfirm' | translate"
        dxyDataTestId="new-password-confirm-field"
    >
    </dxy-field-password>

    <button
        mat-flat-button
        color="primary"
        class="account-button"
        type="submit"
        [disabled]="accountResetPasswordForm.invalid"
        dxyDataTestId="submit-button"
    >
        <span *ngIf="!hasToken" translate> UI.Reset.btnSendMail </span>
        <span *ngIf="hasToken" translate> UI.Reset.btnChangePassword </span>
    </button>
    <div
        *ngIf="isMailSent || hasError"
        class="account-success"
        role="alert"
        translate
        dxyDataTestId="email-sent-message"
    >
        UI.Reset.msgMailSent
    </div>
    <a
        *ngIf="isResetPasswordMode"
        class="account-link connect-link"
        (click)="goToLoginPage()"
        translate
        dxyDataTestId="login-page-link"
    >
        UI.Reset.lblConnect
    </a>
</form>
<div
    *ngIf="isInvalidToken"
    class="invalid-token"
    dxyDataTestId="invalid-token-message"
>
    <div class="account-warning" role="alert">
        <span class="glyph-warning"></span>
        <span> {{ getInvalidTokenMsg() }} </span>
    </div>
    <a class="account-link connect-link" (click)="goToLoginPage()" translate>
        UI.Reset.lblConnect
    </a>
</div>
