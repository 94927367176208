import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export type AlertMessageType = 'info' | 'success' | 'warning' | 'error';

@Component({
    selector: 'dxy-alert-message',
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './alert-message.component.html',
    styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent {
    @Input() message!: string;
    @Input() alertType: AlertMessageType = 'info';
}
