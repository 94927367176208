<!--Header-->
<div class="modal-header">
    <h3 mat-dialog-title class="modal-title">{{ ctx.title() }}</h3>
    <button
        mat-icon-button
        (click)="ctx.close()"
        class="modal-close-btn"
        dxyDataTestId="modal-close-button"
    ></button>
</div>

<!--Body-->
<div mat-dialog-content>
    <ng-content class="transclude-wrapper"></ng-content>
</div>

<!--Footer-->
<div *ngIf="ctx.isFooterVisible()" class="modal-footer">
    <mat-checkbox
        *ngIf="ctx.isCheckboxVisible()"
        [(ngModel)]="checkboxValueLocal"
        class="align-left-footer"
    >
        <span class="checkbox-text" [innerHTML]="getCheckboxMessage()"></span>
    </mat-checkbox>

    <span *ngIf="ctx.isLeftFooterCustomTextVisible()" class="align-left-footer">
        {{ ctx.getLeftFooterCustomText() }}
    </span>

    <!-- PREVIOUS -->
    <button
        *ngIf="ctx.isPreviousVisible()"
        mat-flat-button
        (click)="ctx.previous()"
        dxyDataTestId="previous-wizard-button"
    >
        <span translate>Import.GenericImportWizard.Global.BtnPrevious</span>
    </button>

    <!-- RESTART -->
    <button
        *ngIf="ctx.isRestartVisible()"
        mat-flat-button
        [disabled]="ctx.isRestartDisabled()"
        (click)="onRestartClick()"
    >
        <span translate>Import.GenericImportWizard.Global.BtnNewImport</span>
    </button>

    <!-- SAVE -->
    <button
        *ngIf="ctx.isSaveVisible()"
        mat-flat-button
        [disabled]="ctx.isSaveDisabled()"
        (click)="onSaveClick()"
    >
        <span translate>UI.Global.btnSave</span>
    </button>

    <!-- TEST -->
    <ng-container *ngIf="ctx.isTestVisible()">
        <div [matTooltip]="getTestBtnTooltip()">
            <button
                mat-flat-button
                [disabled]="getIsTestDisabled()"
                (click)="onTestClick()"
                dxyDataTestId="test-connection-button"
            >
                <span translate>Import.GenericImportWizard.Global.BtnTest</span>
                <span
                    *ngIf="hasCheckIcon"
                    [ngClass]="getTestIconClass()"
                    dxyDataTestId="test-check-success"
                ></span>
            </button>
        </div>
        <dxy-test-error-message
            *ngIf="getTestErrorMessage()"
            [message]="getTestErrorMessage()"
        ></dxy-test-error-message>
    </ng-container>

    <!-- CHECK -->
    <ng-container *ngIf="ctx.isCheckBtnVisible()">
        <div [matTooltip]="getCheckTooltip()">
            <button
                mat-flat-button
                [disabled]="getIsCheckBtnDisabled()"
                (click)="onCheckBtnClick()"
                dxyDataTestId="check-connection-button"
            >
                <span translate
                    >Import.GenericImportWizard.CsvPreImport.CheckBtnLbl</span
                >
                <span
                    *ngIf="hasCheckIcon"
                    [ngClass]="getCheckIconClass()"
                ></span>
            </button>
        </div>
        <dxy-csv-check-message
            [data]="localCheckMessageData"
        ></dxy-csv-check-message>
    </ng-container>

    <!-- NEXT -->
    <div *ngIf="ctx.isNextVisible()" [matTooltip]="getNextButtonTooltip()">
        <button
            mat-flat-button
            color="primary"
            [disabled]="ctx.isNextDisabled()"
            (click)="onNextClick($event)"
            [attr.data-tracker-id]="ctx?.getNextButtonDataTrackerId()"
            dxyDataTestId="next-wizard-button"
        >
            {{ ctx.getNextButtonText() }}
        </button>
    </div>
    <button *ngIf="ctx.isLoadingVisible()" mat-flat-button color="primary">
        <span translate
            >Import.GenericImportWizard.SolutionDetails.NextBtnDesktopLoading</span
        >
        <span class="dg5-spinner"></span>
    </button>
</div>
