import { Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'app-account-logo',
    templateUrl: 'account-logo.component.html',
    styleUrls: ['account-logo.component.scss'],
    standalone: true,
})
export class AccountLogoComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}
