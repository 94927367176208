<dxy-space-icon
    *ngIf="spaceInfo"
    [workspace]="spaceInfo"
    class="xs"
    [matTooltip]="spaceInfo.DisplayName"
    matTooltipPosition="below"
    dxyNavDataLink
    [hierarchicalData]="firstItemHierarchicalData"
    [isFromBreadcrumb]="true"
></dxy-space-icon>

<div
    #breadcrumbContainer
    class="breadcrumb-container"
    [class.is-displayed]="isReadyToDisplay"
    [matTooltip]="breadcrumbTooltip"
>
    <div
        *ngFor="let it of renderedItems"
        class="ancestor-container"
        [class.dot-ancestor]="!it.isDisplayed"
        [style.max-width.px]="it.maxWidth"
    >
        <a
            *ngIf="it.isDisplayed && !readOnly"
            dxyNavDataLink
            [hierarchicalData]="it.itemData.ancestorData.hierarchicalData"
            [navToTab]="it.itemData.ancestorData.navToTab"
            [isFromBreadcrumb]="true"
            [attr.data-dtname]="it.itemData.dtName"
            [openPreview]="openPreview"
        >
            {{ it.itemData.ancestorData.displayName }}</a
        >
        <span *ngIf="it.isDisplayed && readOnly" class="read-only-item">{{
            it.itemData.ancestorData.displayName
        }}</span>
        <span *ngIf="!it.isDisplayed" class="dot-item"></span>
    </div>
</div>
