import { DgModuleName } from './dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

/**
 * Defines a DgModule with all its UI attributes
 */
export abstract class DgModuleDefinition {
    public abstract name: DgModuleName;
    public abstract translateKey: string;
    public abstract glyphClass: string;
    public abstract colorGlyphClass: string;
    public abstract graphicalColor: GraphicalColor;
    public abstract position: number;
    public abstract categoryKey?: string;
    public get coloredGlyphClass(): string {
        return `${this.glyphClass} ${this.colorGlyphClass}`;
    }
}
