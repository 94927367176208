import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { GraphManager } from '../../graph-manager';
import { select, selectAll } from 'd3-selection';
import { SelectionTool } from './selection.tool';
import { IGraphEvent, TGraphItem } from '../../graph-manager.types';
import { Subject } from 'rxjs';
import { ManagedItem } from '../../node/managed-item';
import { IDiagNode } from '../../../diagramming';
import { TDiagEdge } from '../../edge';
import { KeyboardUtil } from '@datagalaxy/utils';

/**
 * ## Role
 * Handle graph items (nodes & edges) click selection
 *
 * ## Features
 * - toggle selection on item click
 * - add to selection on shift + click
 * - unselect all on surface click (click outside)
 */
export class ClickSelectionTool extends BaseGraphicalManager {
    private readonly surfaceClicked = new Subject<void>();
    private readonly nodeClicked = new Subject<{
        node: IDiagNode;
        e: MouseEvent;
    }>();
    private readonly edgeClicked = new Subject<{
        edge: TDiagEdge<unknown>;
        e: MouseEvent;
    }>();

    public get surfaceClicked$() {
        return this.surfaceClicked.asObservable();
    }
    public get nodeClicked$() {
        return this.nodeClicked.asObservable();
    }
    public get edgeClicked$() {
        return this.edgeClicked.asObservable();
    }

    constructor(
        container: HTMLDivElement,
        private selectionManager: SelectionTool,
        private graph: GraphManager
    ) {
        super();

        this.subscribe(graph.items$, (event) => this.onGraphChange(event));
        select(container).on('click', (event) => this.onClickOutside(event));
    }

    private onGraphChange(event: IGraphEvent) {
        const items = event.added;
        const elements = items?.map((it) => it.el);

        selectAll(elements)
            .data(items)
            .on('click', (event, d) => this.onItemClick(d, event));
    }

    private onItemClick(item: TGraphItem, event: MouseEvent) {
        const selection = this.selectionManager.selection;
        const wasSelected = selection?.includes(item);
        const keys = KeyboardUtil.getAltShiftCtrl(event);

        if ((!keys || keys?.shift) && wasSelected) {
            return;
        }

        if ((keys?.ctrl && wasSelected) || wasSelected) {
            this.selectionManager.remove(item);
        } else if (keys?.shift || keys?.ctrl) {
            this.selectionManager.add(item);
        } else {
            this.selectionManager.select(item);
        }

        this.notifyItemClick(item, event);
    }

    private notifyItemClick(item: TGraphItem, event: MouseEvent) {
        if (item instanceof ManagedItem) {
            this.nodeClicked.next({
                node: this.graph.getNodeById(item.id),
                e: event,
            });
        } else {
            this.edgeClicked.next({
                edge: this.graph.getEdgeFromConnector(item),
                e: event,
            });
        }
    }

    private onClickOutside(event: MouseEvent) {
        const elements = this.selectionManager.selection.map((item) => item.el);
        if (elements.some((el) => el.contains(event.target as Node))) {
            return;
        }
        this.selectionManager.unselectAll();
        this.surfaceClicked.next();
    }
}
