import { DomUtil } from '@datagalaxy/core-util';
import { IXY, MovePhase, Rect } from '@datagalaxy/core-2d-util';
import { D3Helper } from '../../../D3Helper';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { D3RectOrDiv, IAreaSelectorParams } from './area-selector.types';

/** ## Role
 * Sub component managing a draggable selection rectangle with mouse */
export class AreaSelector extends BaseGraphicalManager {
    private static readonly verbose = false;

    private readonly handleRect: Rect;
    private readonly areaClass: string;
    private readonly handleClass: string;
    private start: IXY;
    private selecting: Rect;
    private selection: Rect;
    private d3area: D3RectOrDiv;
    private d3handle: D3RectOrDiv;

    constructor(private params: IAreaSelectorParams) {
        super();
        super.initInternal(params, AreaSelector.verbose);
        const { areaClass, handleClass, handleSize } = params;
        this.areaClass = DomUtil.addClass('as-area', areaClass);
        if (handleClass || handleSize) {
            this.handleClass = DomUtil.addClass('as-handle', handleClass);
            this.handleRect = Rect.fromSizeCentered(handleSize ?? 10);
        }
    }

    public update(phase: MovePhase, corner: IXY, e: MouseEvent) {
        this.verbose && this.log('update', MovePhase[phase], corner, e);

        if (this.selecting) {
            this.selecting.setFromStartAndCorner(this.start, corner);
            if (phase == MovePhase.end) {
                this.selection = this.selecting;
                this.selecting = null;
            }
        } else if (phase == MovePhase.start) {
            const { x, y } = corner;
            this.start = { x, y };
            this.selection = null;
            this.selecting = new Rect(x, y, 0, 0);
        }

        const areaRect = this.selecting ?? this.selection;
        const rightToLeft = corner.x < this.start.x,
            bottomToTop = corner.y < this.start.y;
        const areaElement = this.getOrCreateAreaElement(
            areaRect,
            rightToLeft,
            bottomToTop
        );
        if (this.params?.onAreaChange) {
            this.params.onAreaChange({
                areaRect,
                areaElement,
                e,
                phase,
                rightToLeft,
                bottomToTop,
            });
        }
    }

    private getOrCreateAreaElement(
        rect: Rect,
        rightToLeft?: boolean,
        bottomToTop?: boolean
    ) {
        if (!rect) {
            return;
        }
        const hr = this.handleRect;
        hr &&
            rect
                .corner(!rightToLeft, !bottomToTop, hr)
                .shiftXY(-hr.width / 2, -hr.height / 2);
        const useStyle = !this.params?.useSvg;
        if (this.d3area) {
            D3Helper.setBounds(this.d3area, rect, useStyle);
        } else {
            this.d3area = this.createRectEl(rect, useStyle, this.areaClass);
            if (hr) {
                this.d3handle = this.createRectEl(
                    hr,
                    useStyle,
                    this.handleClass
                );
                //this.params.initDrag?.(this.d3handle)
            }
        }
        hr && D3Helper.setXY(this.d3handle, hr.x, hr.y, useStyle);
        return this.d3area.node();
    }
    private createRectEl(r: Rect, useStyle: boolean, className = '') {
        const create = useStyle
            ? D3Helper.createDivOrUpdate
            : D3Helper.createRectOrUpdate;
        const d3Sel = create(
            this.params.areaContainer,
            className,
            r
        ) as D3RectOrDiv;
        D3Helper.setBounds(d3Sel, r, useStyle);
        return d3Sel.style('pointer-events', 'none');
    }

    public clear() {
        this.params.areaContainer?.select('.' + this.areaClass).remove();
        this.d3area?.remove();
        this.d3handle?.remove();
        this.d3area = this.start = this.selecting = this.selection = undefined;
    }
}
