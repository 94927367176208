<div class="group-container group-container-wrap">
    <div class="group group-10">
        <dxy-screen-layout-category
            *ngFor="let category of displayedCategories"
            [screenData]="screenData"
            [screenCategory]="category"
            [reloadOnSourceChange]="reloadOnSourceChange"
            [comparatorItems]="comparatorItems"
            [versionCompareContext]="versionCompareContext"
            [isVersioningCompare]="isVersioningCompare"
        ></dxy-screen-layout-category>
    </div>
</div>
