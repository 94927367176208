import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { CoreUtil } from '@datagalaxy/core-util';
import { SecurityService } from './security.service';
import { AppDataService } from './app-data.service';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { AnalyticLoginBuilder } from './analytic.login.builder';
import { AppConfigService } from '@datagalaxy/webclient/config';

/**
 * ## Role
 * Wrapper service for segment-io js library
 *
 * ## Note
 * Since the DataGalaxy's segment account is configured to dispatch segment's events to
 * its dataHub + pendo, it will load and initialise pendo-js under the hood.
 */
@Injectable({
    providedIn: 'root',
})
export class AnalyticService extends BaseService {
    private analytics: AnalyticsBrowser;
    private userId: string;

    private get isExternalSegmentTrackingEnabled() {
        return (
            !this.appConfigService.DISABLE_TRACKING &&
            this.appConfigService.ANALYTICS_SEGMENT_ENABLED
        );
    }

    constructor(
        private appConfigService: AppConfigService,
        private appDataService: AppDataService,
        private securityService: SecurityService,
        private featureFlagService: FeatureFlagService,
        private analyticLoginBuilder: AnalyticLoginBuilder
    ) {
        super();
    }

    public async initAsync() {
        if (!this.isExternalSegmentTrackingEnabled || this.analytics) {
            return;
        }

        const analytics = (this.analytics = AnalyticsBrowser.load(
            {
                writeKey: this.appConfigService.ANALYTICS_SEGMENT_KEY,
            },
            {
                cookie: {
                    secure: true,
                },
            }
        ));
        const injectSegmentGroupId = async ({ payload, next }) => {
            payload.obj.groupId = (await analytics.group())?.id();
            next(payload);
        };
        await analytics.addSourceMiddleware(injectSegmentGroupId);
    }

    public async login() {
        await this.reset();
        let privateData, publicData;

        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            const l = this.analyticLoginBuilder.build();
            privateData = l.privateData;
            publicData = l.publicData;
        } else {
            const l = this.appDataService.getLoginDataForAnalytics();
            privateData = l.privateData;
            if (!privateData?.isAuthenticated) {
                return;
            }
            publicData = l.publicData;
        }

        this.userId = privateData.userId;

        await this.identify(privateData.userId, publicData);
        await this.group(privateData.currentClientId, {
            name: privateData.currentClientName,
            id: privateData.currentClientId,
        });
    }

    public async addUserMetaData(properties: TMetaData) {
        if (!this.userId) {
            return;
        }
        await this.identify(this.userId, properties);
    }

    public async identify(userId: string, properties: TMetaData) {
        if (!userId || !this.analytics) {
            return;
        }
        await this.analytics.identify(
            userId,
            this.extendProperties(properties)
        );
    }

    public async track(
        name: string,
        properties: TMetaData,
        additionalProperties?: TMetaData
    ) {
        if (!this.userId || !this.analytics) {
            return;
        }
        await this.analytics.track(
            name,
            this.extendProperties(properties, additionalProperties)
        );
    }

    public async page(
        category?: string,
        name?: string,
        properties?: TMetaData
    ) {
        if (!this.userId || !this.analytics) {
            return;
        }
        await this.analytics.page(
            category,
            name,
            this.extendProperties(properties)
        );
    }

    public async group(groupId: string, properties: TMetaData) {
        if (!groupId || !this.analytics) {
            return;
        }
        await this.analytics.group(groupId, this.extendProperties(properties));
    }

    public async reset() {
        if (!this.analytics) {
            return;
        }
        await this.analytics.reset();
    }

    private extendProperties(
        properties: object,
        additionalProperties?: object
    ) {
        const svc = this.appConfigService;
        const defaultProperties = {
            env: svc.appVersionName,
            platform_version: svc.PLATFORM_VERSION_NUMBER,
            version: svc.APP_VERSION_NUMBER,
            build_time: svc.APP_VERSION_BUILD_TIME,
            version_tech: svc.APP_VERSION_TECHNICAL_INFO,
            access_url: window.location.origin,
            feedback_enabled:
                !svc.DISABLE_FEEDBACK &&
                this.securityService.isClientFeedbackEnabled(),
            ...this.appDataService.getLoginDataForAnalyticsAccount(),
        };
        properties = properties
            ? CoreUtil.merge(properties, defaultProperties)
            : defaultProperties;
        if (additionalProperties) {
            CoreUtil.merge(properties, additionalProperties);
        }
        return properties;
    }
}

type TMetaData = { [key: string]: any };
