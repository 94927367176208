import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    DxyTrendPillComponent,
    NumberFormatPipe,
    TrendPillColorRule,
} from '@datagalaxy/core-ui';
import {
    TimeSeriesColorRule,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Display last value of a time series and series trend in an omnigrid
 */
@Component({
    standalone: true,
    selector: 'app-dg-time-series-cell',
    templateUrl: 'dg-time-series-cell.component.html',
    styleUrls: ['dg-time-series-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatLegacyTooltipModule,
        DxyTrendPillComponent,
        TranslateModule,
        NumberFormatPipe,
        NgIf,
    ],
})
export class DgTimeSeriesCellComponent {
    @Input() colorRule?: TimeSeriesColorRule;
    @Input() timeSeriesObject?: TimeSeriesObject;

    public get trend() {
        return this.timeSeriesObject?.Trend;
    }

    public get trendColorRule() {
        const timeSeriesColorRule: TimeSeriesColorRule = this.colorRule;

        switch (timeSeriesColorRule) {
            case TimeSeriesColorRule.None:
                return TrendPillColorRule.none;
            case TimeSeriesColorRule.ShouldIncrease:
                return TrendPillColorRule.shouldIncrease;
            case TimeSeriesColorRule.ShouldDecrease:
                return TrendPillColorRule.shouldDecrease;
        }
    }
}
