import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
    IListOption,
    IOptionWithCategory,
    ListOptionUtil,
} from '../../IListOption';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { EventName } from '../../Constants';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyOptionItemComponent } from '../option-item/option-item.component';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { NgFor, NgIf, NgClass } from '@angular/common';

/**
 * ## Role
 * Display a mat menu content with its items
 *
 * It can be rendered standalone without the need of a mat-menu-trigger
 */

@Component({
    selector: 'dxy-burger-menu',
    templateUrl: './burger-menu.component.html',
    styleUrls: ['./burger-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        MatLegacyMenuModule,
        NgClass,
        DxyDataTestIdDirective,
        DxyOptionItemComponent,
        TranslateModule,
    ],
})
export class DxyBurgerMenuComponent<T>
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() options: IListOption[];
    @Input() data?: T;
    /** If set to true, the menu will be driven by BurgerManager */
    @Input() isStandalone: boolean;
    @Output() readonly onLogFunctional = new EventEmitter<IFunctionalEvent>();

    public get optionGroups(): IListOption[][] {
        return this._optionGroups.filter((group) =>
            this.showCategoryGroup(group)
        );
    }

    private _optionGroups: IListOption[][];

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private detectorRef: ChangeDetectorRef,
        private translate: TranslateService,
        private ngZone: NgZone
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'options',
            () => this.buildGroups(this.options),
            true
        );
    }

    ngAfterViewInit() {
        this.log('ngAfterViewInit');
        if (this.isStandalone) {
            const eMenu = DomUtil.getElement(this.elementRef, '.burger-menu');
            this.registerForDestroy(
                DomUtil.addCustomListener(
                    eMenu,
                    EventName.BurgerMenuShowing,
                    () => this.onBurgerMenuShowing(),
                    this.ngZone,
                    true
                )
            );
        }
    }

    public isHidden(option: IListOption) {
        return ListOptionUtil.isHidden(option, this.data);
    }

    public getLabel(option: IListOption) {
        return ListOptionUtil.getText(option, this.translate, this.data);
    }
    public getOptionClass(option: IListOption) {
        return ListOptionUtil.getOptionClass(option, this.data);
    }
    public getOptionDisabled(option: IListOption) {
        return ListOptionUtil.isDisabled(option, this.data);
    }
    public getDataTestId(option: IListOption) {
        return ListOptionUtil.getDataTestId(option, this.data);
    }

    public onClick(option: IListOption, event: Event) {
        if (this.getOptionDisabled(option)) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        ListOptionUtil.onClick(option, this.onLogFunctional, event, this.data);
    }

    public categoryGroupKey(categoryGroup: IOptionWithCategory[]): string {
        return categoryGroup?.[0].categoryKey;
    }

    public categoryGroupHasText(categoryGroup: IOptionWithCategory[]): boolean {
        return (
            !!this.categoryGroupKey(categoryGroup) &&
            this.optionGroups?.length > 1
        );
    }

    private showCategoryGroup(categoryGroup: IOptionWithCategory[]) {
        return categoryGroup?.some((opt) => !this.isHidden(opt));
    }

    private onBurgerMenuShowing() {
        this.log('event BurgerMenuShowing', this.options);
        this.detectorRef.detectChanges();
    }

    private buildGroups(options: IListOption[]) {
        this._optionGroups = CollectionsHelper.groupBy(
            options || [],
            (o) => o.categoryKey,
            (key, items) => items
        );
    }
}
