import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';

export enum PreCreateEntityStatus {
    Ok,
    Exists,
    ExistsInParentSpace,
    ExistsInChildSpace,
    ParentIsRequired,
}
SerializableEnumeration(PreCreateEntityStatus);

@inheritSerialization(AttributeQuickFilterUsage)
export class AttributeQuickFilterUsage {
    @deserializeAs(LicenseLevel) public ProfileName!: LicenseLevel;
    @deserialize public IsUsedOnLastQuickFilter!: boolean;
}
