import { LayoutManagerOptions, LayoutMode } from './layout.types';
import { ManagedItem } from '../node/managed-item';
import { Connector } from '../connector';
import { DirectedLayout } from './directed';
import { GraphManager } from '../graph-manager';
import { AnimationFrameBuffer } from '../utils/animation-frame-buffer';
import { CollectionsHelper } from '@datagalaxy/core-util';

export class LayoutManager<NodeData> {
    private afb = AnimationFrameBuffer.getInstance();

    constructor(
        private graph: GraphManager,
        private options: LayoutManagerOptions<NodeData>
    ) {}

    public updateLayout(
        nodes: ManagedItem<NodeData>[],
        connectors: Connector[]
    ): boolean {
        if (!this.options) {
            return;
        }
        const layout = this.getLayout(this.options?.mode);

        if (!layout) {
            return;
        }
        const orderedNodes = this.options?.nodeSortingValue
            ? CollectionsHelper.orderBy(nodes, (node) =>
                  this.options.nodeSortingValue(node.data)
              )
            : nodes;

        layout.updateNodesLayout(orderedNodes, connectors);
        return true;
    }

    public refresh() {
        const nodes = this.graph.nodes as ManagedItem<NodeData>[];
        const connectors = this.graph.edges;

        const layoutUpdated = this.updateLayout(nodes, connectors);

        if (!layoutUpdated) {
            return;
        }

        this.afb.addCallback(() => {
            nodes?.forEach((node) => node.draw());
            connectors?.forEach((connector) => {
                connector.computeEndpoints();
                connector.computePoints();
                connector.draw();
            });
        });
    }

    private getLayout(mode: LayoutMode) {
        switch (mode) {
            case LayoutMode.Directed:
                return new DirectedLayout();
        }
    }
}
