<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Teams.CreateModal.nameLbl
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            name="teamName"
            labelKey="UI.Teams.CreateModal.nameLbl"
            [takeFocus]="true"
            [(ngModel)]="teamName"
            (debouncedModelChange)="onNameUpdate()"
            [required]="true"
            [debounceTimeMs]="222"
            [errorMessageText]="teamNameErrorMessage"
        ></dxy-field-text>
        <dxy-field-select
            labelKey="UI.Teams.CreateModal.accessLbl"
            [adapter]="teamAccessAdapter"
            [mandatory]="true"
            [useRichLayout]="true"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>
        <dxy-rich-text-field
            name="teamDescription"
            labelKey="UI.Teams.CreateModal.descriptionLbl"
            [(ngModel)]="teamDescription"
            [noTabCapture]="true"
            toolbarDisplayMode="focus"
        ></dxy-rich-text-field>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Global.btnCreate' | translate"
        [actionLoading]="isLoading"
        (onCloseCancel)="onCloseCancel()"
        featureCode="TEAM,C"
    ></dxy-modal-footer>
</form>
