<div class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.Campaign
    </div>
    <div class="actions-container">
        <div *ngIf="canAddToCampaign" class="tool-add-entity">
            <button
                dxyIconButton
                [matTooltip]="'UI.Global.btnAddToCampaign' | translate"
                matTooltipPosition="left"
                (click)="addToExistingCampaign()"
            >
                <span class="glyph glyph-add"></span>
            </button>
        </div>
    </div>
</div>
<div class="mainContainer panel-content-body">
    <div *ngIf="isEmptyGrid" class="dxy-placeholder">
        <img
            src="/images/placeholders/campaign.svg"
            class="dxy-placeholder-img"
            alt="campaign"
        />
        <div class="dxy-placeholder-text" translate>UI.Campaign.NoCampaign</div>
    </div>

    <dxy-grid
        *ngIf="!isEmptyGrid"
        [columns]="cols"
        [config]="gridConfig"
        appGridUserSettings="campaign-list-key"
        [items]="campaigns"
        (rowClick)="onRowClick($event)"
    >
    </dxy-grid>

    <dxy-spinner
        *ngIf="loading$ | async"
        class="xl absolute-centered"
    ></dxy-spinner>
</div>
