import { TranslationBatchCompletedActivityEvent } from '@datagalaxy/dg-object-model';
import {
    IDgEventTranslationAdapter,
    IEventTranslationInputs,
} from '../../../interfaces';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';

const eventTypes = [TranslationBatchCompletedActivityEvent];
export type TEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to translate TranslationBatchCompletedActivityEvent
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class TranslationBatchCompletedActivityEventAdapter
    implements IDgEventTranslationAdapter
{
    constructor(private event: TEvent) {}

    getTranslationInputs(): IEventTranslationInputs {
        return {
            sourceEvent: this.event,
            translateKey: 'DgEvent.TranslationBatchCompletedActivityEvent',
            translateParameters: [
                {
                    key: 'targetLanguage',
                    value: this.event.Content.Language.toUpperCase(),
                },
            ],
            navLinks: [
                {
                    linkCssClass: 'nav-link-language',
                    relativeUrl: '#/user/user-settings/Language',
                    parameters: {
                        languageCode: this.event.Content.Language,
                    },
                },
            ],
        };
    }
}
