import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { UserNotificationChannelsStateService } from '../../../notification-channels/state/user-notification-channels-state.service';
import { Observable } from 'rxjs';
import { UserNotificationChannelDto } from '../../../notification-channels/api/dtos/UserNotificationChannelDto';
import { slackNotificationChannel } from '../../../notification-channels/slack-notification-channel';
import { SlackAuthenticationService } from '../../../notification-channels/slack-authentication.service';
import { SlackRedirectionIdentifier } from '../../../notification-channels/slack-redirection.types';
import { ToasterService } from '../../../services/toaster.service';
import { withLoading } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * Button inside a notification to connect user to Slack
 */
@Component({
    selector: 'app-slack-connect-me-button',
    templateUrl: './slack-connect-me-button.component.html',
    styleUrls: ['./slack-connect-me-button.component.scss', '../nav-link.scss'],
    standalone: true,
    imports: [NgIf, MatLegacyButtonModule, TranslateModule, AsyncPipe],
})
export class SlackConnectMeButtonComponent extends BaseCellComponent<
    unknown,
    unknown,
    unknown
> {
    protected userChannel$: Observable<UserNotificationChannelDto>;

    constructor(
        private userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private slackAuthenticationService: SlackAuthenticationService,
        private toasterService: ToasterService
    ) {
        super();
        userNotificationChannelsStateService.initUserNotificationChannels();
        this.userChannel$ =
            userNotificationChannelsStateService.selectUserChannel(
                slackNotificationChannel.name
            );
    }

    @withLoading()
    protected async activate() {
        await this.userNotificationChannelsStateService.initUserNotificationChannels(
            true
        );
        const activable =
            this.userNotificationChannelsStateService.getUserChannelActivable(
                slackNotificationChannel.name
            );
        if (!activable) {
            this.toasterService.errorToast({
                messageKey: 'UI.NotificationChannels.Slack.adminHasDeactivated',
            });
            return;
        }
        this.slackAuthenticationService.loginSlack(
            SlackRedirectionIdentifier.notficationsActivation
        );
    }
}
