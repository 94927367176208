import { inheritSerialization, deserialize } from 'cerialize';
import { EntityActivityEventBase, registerTypedEvent } from '../events';

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('EntityCreatedEvent')
export class EntityCreatedEvent extends EntityActivityEventBase {}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('EntityDeletedEvent')
export class EntityDeletedEvent extends EntityActivityEventBase {}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('EntityMovedEvent')
export class EntityMovedEvent extends EntityActivityEventBase {}

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('EntitiesImportedEvent')
export class EntitiesImportedEvent extends EntityActivityEventBase {
    @deserialize EntitiesCount: number;
}
