import { UiUtil } from '@datagalaxy/core-ui';
import { Subscription } from 'rxjs';
import { INavigationSpaceListInputs } from '../INavigationSpaceListInputs';
import { EventEmitter } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { NavigationService } from '../../services/navigation.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { ToasterService } from '../../services/toaster.service';
import { INavSpaceSelectedEvent } from '../../space/space.types';
import { SpaceApiService } from '../../space/services/space-api.service';
import { NavSpace, Space } from '@datagalaxy/webclient/workspace/data-access';

/** Core code for dxy-navigation-space-list components */
export class NavigationSpaceListCore implements INavigationSpaceListInputs {
    //#region INavigationSpaceListInputs
    public enableFavorite: boolean;
    public allSpaces: boolean;
    public projectsOnly: boolean;
    public isSmallCaretBtn: boolean;
    public isSpaceSelectable: boolean;
    public allowAll: boolean;
    public defaultAll: boolean;
    public spaceId: string;
    public showAllSpaceRedirection: boolean;
    public showSelectedSpaceIcon: boolean;
    public openOnDisplayNameClick: boolean;
    public onlyImportable: boolean;
    public onlyImportableCatalog: boolean;
    public readonly onNavSpaceSelected =
        new EventEmitter<INavSpaceSelectedEvent>();
    public readonly onDisplayNameClick = new EventEmitter<void>();
    //#endregion

    public orderedNavSpaces: NavSpace[];
    public get currentSelected() {
        return this.selected;
    }
    public get isMenuVisible() {
        return this.isSpaceSelectable || this.showAllSpaceRedirection;
    }
    public get selectedDisplayName() {
        return (
            this.selected?.DisplayName ||
            (this.allowAll && !this.spaceId && this.allSpacesText) ||
            this.currentSpaceService.getCurrentSpace()?.DisplayName
        );
    }

    private readonly navSpaces = new Map<string, NavSpace>();
    private readonly allSpacesText: string;
    private selected: NavSpace;

    constructor(
        private navigationService: NavigationService,
        private toasterService: ToasterService,
        private appSpaceService: AppSpaceService,
        private spaceApiService: SpaceApiService,
        private currentSpaceService: CurrentSpaceService,
        private translate: { instant(key: string): string },
        private registerSubscription: (
            subscription: Subscription
        ) => Subscription,
        private log: (...args: any[]) => void
    ) {
        this.allSpacesText = this.translate.instant(
            'UI.SpaceVersionSelector.allSpaces'
        );
    }

    public async init(host: INavigationSpaceListInputs) {
        this.getInputs(host, true);
        this.log('init');
        if (!this.allowAll) {
            this.defaultAll = false;
        }
        await this.loadNavSpaces();
        this.subscribeEvents();
    }

    public isOrga(navSpace: NavSpace) {
        return navSpace.Type == ServerType.Organization;
    }

    public isSelected(navSpace: NavSpace) {
        return this.selected?.ReferenceId == navSpace?.ReferenceId;
    }

    public isFavoriteButtonVisible(navSpace: NavSpace) {
        return navSpace.IsDefaultSpace || this.enableFavorite;
    }

    public hasWorkspaceIcon(navSpace: NavSpace) {
        return !!navSpace?.IconHash;
    }

    public getWorkspaceIconUrl(navSpace: NavSpace) {
        return this.spaceApiService.getSpaceIconUrl(navSpace);
    }

    public getWorkspaceTrigramBg(navSpace: NavSpace): string {
        return UiUtil.getColorClassFromString(navSpace?.Trigram);
    }

    //#region event handlers

    public onInputChange(host: INavigationSpaceListInputs) {
        this.log('onInputChange', host);
        this.getInputs(host);
    }

    public onSpaceIdChange(spaceId: string) {
        this.log('onSpaceIdChange', spaceId);
        this.spaceId = spaceId;
        this.setSelected(this.navSpaces.get(spaceId));
    }

    public onDisplayNameClickInternal(event: Event) {
        if (this.openOnDisplayNameClick) {
            return;
        }
        event.stopPropagation();
        this.onDisplayNameClick.emit();
    }

    public onSpaceClick(navSpace: NavSpace) {
        this.log('onSpaceClick', navSpace);
        this.setSelected(navSpace, true);
    }

    public async onStarClick(event: Event, navSpace: NavSpace) {
        if (!this.enableFavorite) {
            return;
        }
        this.log('onStarClick', navSpace);
        event.stopPropagation();
        await this.appSpaceService.updateDefaultSpace(
            navSpace,
            !navSpace.IsDefaultSpace,
            Array.from(this.navSpaces.values())
        );
    }

    public goToClientSpacesList() {
        this.navigationService.goToClientSpacesList();
    }

    private onChangeCurrentSpace(space: Space) {
        this.setSelected(this.navSpaces.get(space.ReferenceId));
    }

    private onCreateSpaceEvent(navSpace: NavSpace) {
        this.navSpaces.set(navSpace.ReferenceId, navSpace);
    }

    private onUpdateSpaceEvent(navSpace: NavSpace) {
        const existingNavSpace = this.navSpaces.get(navSpace.ReferenceId);
        if (existingNavSpace) {
            existingNavSpace.DisplayName = navSpace.DisplayName;
            existingNavSpace.Description = navSpace.Description;
        } else {
            this.onCreateSpaceEvent(navSpace);
        }
    }

    private onDeleteSpaceEvent() {
        this.loadNavSpaces();
    }

    private onSecurityRightsChangeEvent() {
        this.loadNavSpaces();
    }

    private onUserFavoriteChanged(navSpace: NavSpace) {
        this.navSpaces.forEach((nav) => {
            nav.IsDefaultSpace =
                nav.ReferenceId == navSpace.ReferenceId
                    ? navSpace.IsDefaultSpace
                    : false;
        });
    }

    //#endregion

    private getInputs(host: INavigationSpaceListInputs, isInit = false) {
        this.log('getInputs', isInit);
        const isSingleWorkspace = this.appSpaceService.isSingleWorkspace();

        host.openOnDisplayNameClick ??= true;

        if (isInit) {
            this.spaceId = host.spaceId;
        }
        this.enableFavorite = host.enableFavorite;
        this.allSpaces = host.allSpaces;
        this.projectsOnly = host.projectsOnly;
        this.defaultAll = host.defaultAll;
        this.openOnDisplayNameClick = host.openOnDisplayNameClick;
        this.onlyImportable = host.onlyImportable;
        this.onlyImportableCatalog = host.onlyImportableCatalog;
        this.isSmallCaretBtn = host.isSmallCaretBtn;
        this.isSpaceSelectable = host.isSpaceSelectable && !isSingleWorkspace;
        this.allowAll = host.allowAll;
        this.showAllSpaceRedirection =
            host.showAllSpaceRedirection && !isSingleWorkspace;
        this.showSelectedSpaceIcon = host.showSelectedSpaceIcon;
    }

    private async loadNavSpaces() {
        const orderedNavSpaces = await this.appSpaceService.getNavSpacesSorted({
            projectsOnly: this.projectsOnly,
            onlyImportable: this.onlyImportable,
            onlyImportableCatalog: this.onlyImportableCatalog,
        });

        this.orderedNavSpaces = orderedNavSpaces;
        this.navSpaces.clear();
        orderedNavSpaces.forEach((ns) =>
            this.navSpaces.set(ns.ReferenceId, ns)
        );

        let selected = this.selected;
        if (selected && !this.navSpaces.has(selected.ReferenceId)) {
            // Lost access to current space
            this.toasterService.warningToast({
                titleKey: 'UI.Notification.CurrentSpaceAccessLost.title',
                messageKey: 'UI.Notification.CurrentSpaceAccessLost.msg',
            });
            selected = null;
        }

        if (!selected && this.spaceId) {
            selected = this.navSpaces.get(this.spaceId);
        }

        if (!selected && (!this.defaultAll || this.navSpaces.size === 1)) {
            const space = this.currentSpaceService.getCurrentOrLastSpace();
            const navSpace = space && this.navSpaces.get(space.ReferenceId);
            // current, default, of first in list
            selected =
                navSpace ??
                orderedNavSpaces.find((ns) => ns.IsDefaultSpace) ??
                this.navSpaces.values().next().value;
        }

        if (this.navSpaces.size === 1) {
            this.isSpaceSelectable = false;
        }

        this.setSelected(selected);
    }

    private subscribeEvents() {
        this.registerSubscription(
            this.appSpaceService.subscribe({
                onCreate: (navSpace) => this.onCreateSpaceEvent(navSpace),
                onUpdate: (navSpace) => this.onUpdateSpaceEvent(navSpace),
                onDelete: () => this.onDeleteSpaceEvent(),
                onSecurityRightsChange: () =>
                    this.onSecurityRightsChangeEvent(),
                onUserFavoriteChanged: (navSpace: NavSpace) =>
                    this.onUserFavoriteChanged(navSpace),
                onChangeCurrent: this.allSpaces
                    ? undefined
                    : async (space) => {
                          // Navigation to client-space induce the emission of null workspace..
                          // ..  which has to be ignored for persitent navigationList instances
                          if (!space) return;
                          // After space creation, internal map is ahead of public list
                          if (
                              this.navSpaces.size > this.orderedNavSpaces.length
                          )
                              await this.loadNavSpaces();
                          this.onChangeCurrentSpace(space);
                      },
            })
        );
    }

    private setSelected(navSpace: NavSpace, isUserAction = false) {
        const prevSelected = this.selected;
        const newSelected = (this.selected = navSpace);
        this.log(
            'loadNavSpaces selectedNavSpace',
            prevSelected,
            '->',
            newSelected
        );
        if (prevSelected?.ReferenceId != newSelected?.ReferenceId) {
            this.onNavSpaceSelected.emit({ navSpace, isUserAction });
        }
    }
}
