<dxy-profile-avatar
    *ngIf="profileAvatarVisible"
    [userId]="userGuid"
></dxy-profile-avatar>
<div *ngIf="customAvatarVisible || dgLogoVisible" class="custom-avatar">
    <span class="dg-logo">
        <i class="glyph-logo-dg"></i>
    </span>
    <img *ngIf="customAvatarVisible" [src]="customAvatarUrl" />
</div>
<div class="event-infos">
    <div class="message" [innerHTML]="innerHtml"></div>

    <span class="event-date">
        {{ creationTimeFromNow }}
    </span>
</div>
<div class="data-links">
    <dxy-renderer
        *ngFor="let navData of navLinksData"
        [ngClass]="navData.linkCssClass"
        [class.full-width]="navData.fullWidth"
        [renderer]="navData.renderer"
        [param]="navData.param"
    ></dxy-renderer>
</div>
