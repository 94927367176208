import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { IconComponent } from '@datagalaxy/ui/icon';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf } from '@angular/common';

@Component({
    standalone: true,
    selector: 'dxy-grid-expand-button',
    templateUrl: './grid-expand-button.component.html',
    styleUrls: ['./grid-expand-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent, SpinnerComponent, NgIf, DxyIconButtonDirective],
})
export class GridExpandButtonComponent {
    @Input() expanded = false;
    @Input() loading = false;

    @Output() toggle = new EventEmitter<void>();

    protected onToggle(event: MouseEvent) {
        event.stopPropagation();
        this.toggle.emit();
    }
}
