import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import {
    SlackRedirectionIdentifier,
    SlackRedirectionQueryParams,
} from './slack-redirection.types';
import { SlackApiService } from './api/slack-api.service';

@Injectable({ providedIn: 'root' })
export class SlackAuthenticationService extends BaseService {
    constructor(private slackApiService: SlackApiService) {
        super();
    }

    public async loginSlack(callbackIdentifier: SlackRedirectionIdentifier) {
        const redirectUrl = `${this.getCurrentUrl()}?${
            SlackRedirectionQueryParams.callbackIdentifier
        }=${callbackIdentifier}`;
        const result = await this.slackApiService.getSlackRedirectionUrl(
            redirectUrl
        );
        window.open(result.SlackRedirectUrl, '_self', 'popup=yes');
        return new Promise(() => {});
    }

    private getCurrentUrl() {
        return window.location.href.split('?')[0];
    }
}
