import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import {
    AcknowledgeNotificationsCommand,
    GetUserNotificationsQueryResult,
    UserNotification,
} from './notification';
import { lastValueFrom, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
    public constructor(private restApiService: RestApiService) {}
    public async getUserNotifications() {
        return await lastValueFrom(
            this.restApiService
                .get<GetUserNotificationsQueryResult>(
                    'notifications',
                    undefined
                )
                .pipe(
                    map((r) =>
                        r.Notifications.map((n) => new UserNotification(n))
                    )
                )
        );
    }

    public async acknowledgeNotifications(
        param: AcknowledgeNotificationsCommand
    ) {
        await this.restApiService.postPromise(
            'notifications/acknowledge',
            param
        );
    }
}
