import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    GetObjectSecurityParameter,
    GetObjectSecurityResult,
    SetUserSecurityParameter,
    SetUserSecurityResult,
} from './security';

@Injectable({ providedIn: 'root' })
export class SecurityApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getObjectSecurity(param: GetObjectSecurityParameter) {
        return await this.backendApiService.postPromise(
            'Security/GetObjectSecurity',
            param,
            GetObjectSecurityResult
        );
    }

    public async setUserSecurity(param: SetUserSecurityParameter) {
        return await this.backendApiService.postPromise(
            'Account/SetUserSecurity',
            param,
            SetUserSecurityResult
        );
    }
}
