import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    MatLegacyTab as MatTab,
    MatLegacyTabGroup as MatTabGroup,
    MatLegacyTabsModule,
} from '@angular/material/legacy-tabs';
import { ImportContext } from '../../../shared/ImportContext';
import { ViewIdentifier } from '../../../../shared/util/ViewIdentifier';
import { ImportWizardService } from '../../../services/import-wizard.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyCsvPreImportCheckComponent } from '../dxy-csv-preimport-check/dxy-csv-preimport-check.component';
import { DxyCsvPreImportPreviewComponent } from '../dxy-csv-preimport-preview/dxy-csv-preimport-preview.component';
import { DxyCsvPreImportMappingsComponent } from '../dxy-csv-preimport-mappings/dxy-csv-pre-import-mappings.component';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-csv-preimport-wrapper',
    templateUrl: './dxy-csv-preimport-wrapper.component.html',
    styleUrls: ['dxy-csv-preimport-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        MatLegacyTabsModule,
        NgIf,
        DxyCsvPreImportMappingsComponent,
        DxyCsvPreImportPreviewComponent,
        DxyCsvPreImportCheckComponent,
    ],
})
export class DxyCsvPreImportWrapperComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() importContext: ImportContext;
    @Input() isImportLoading: boolean;
    @Output() onMappingInitDone = new EventEmitter<void>();

    @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
    @ViewChildren(MatTab) matTabItems: QueryList<MatTab>;

    // #archi-constants (revi) don't use viewIdentifier for non routed tabs (use custom enum)
    public mappingTabId = ViewIdentifier.CsvImportMapping;
    public previewTabId = ViewIdentifier.CsvImportPreview;
    public checkTabId = ViewIdentifier.CsvImportCheck;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private importWizardService: ImportWizardService
    ) {
        super();
    }

    ngOnInit() {
        super.subscribe(
            this.importWizardService.redirectToPreImportTab$,
            (tabKey: string) => this.onRedirectToTab(tabKey)
        );
        this.importContext.isMappingInitDone = false;
    }

    public onInitMappingDone() {
        this.importContext.isMappingInitDone = true;
        if (this.importContext.areAllMandatoryFieldsMatched) {
            this.onMappingInitDone.emit();
        }
    }

    private onRedirectToTab(tabKey: string) {
        const tabIndex = this.matTabItems
            .toArray()
            .findIndex((tabItem: MatTab) => tabItem.textLabel == tabKey);
        if (tabIndex == undefined) {
            return;
        }
        this.matTabGroup.selectedIndex = tabIndex;
        this.changeDetector.detectChanges();
    }
}
