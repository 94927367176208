import { deserialize, SerializableEnumeration } from 'cerialize';

export class ClientPublicData {
    @deserialize public $id?: string; //#archi-abasedata-$id: may be not used
    @deserialize public ClientId?: string;
    @deserialize public DisplayName?: string;

    constructor() {}
}

export enum ClientAccessFlags {
    None = 0,

    // <summary>Sub Organization</summary>
    EnableSubOrganizations = 1 << 0, // bit 0 set to 1

    /// <summary>
    /// Clients with this flag has a single Project, and no ability to create more workspaces
    /// </summary>
    IsSingleWorkspace = 1 << 1,

    /// <summary>
    /// Clients with this flag do not have versioning functionality
    /// </summary>
    DisableVersioning = 1 << 2,

    /// <summary>
    /// Client with this flag has access to the Online Connector (tab and import plugins) if enabled on instance
    /// </summary>
    EnableEmbeddedConnector = 1 << 4,

    /// <summary>
    /// Client with this flag does not have access to send feedback
    /// </summary>
    DisableFeedback = 1 << 5,

    /// <summary>
    /// Client with this flag DOES NOT have access to the Desktop Connector (page and import plugins) if enabled on instance
    /// </summary>
    DisableDesktopConnector = 1 << 7,

    /// <summary>
    /// Clients with this flag have access to advanced mode in Online Connector (with raw text input)
    /// </summary>
    EnableOnlineConnectionTextEditor = 1 << 8,

    /// <summary>
    /// Enable Campaigns Feature
    /// </summary>s
    EnableCampaigns = 1 << 9,

    /// <summary>
    /// Clients with this flag have access to data quality screens
    /// </summary>
    EnableDataQuality = 1 << 10,

    /// <summary>
    /// Enable Auto glossary generation Feature
    /// </summary>s
    EnableGlossaryAutoGen = 1 << 11,
}
SerializableEnumeration(ClientAccessFlags);
