import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { deserialize, inheritSerialization, serialize } from 'cerialize';

@inheritSerialization(BaseServiceParameter)
export class CheckSecurityTokenParameter extends BaseServiceParameter {
    @serialize public SecurityToken: string;
    @serialize public Email: string;

    constructor(securityToken: string, email: string) {
        super();
        this.SecurityToken = securityToken;
        this.Email = email;
    }
}

export class CheckSecurityTokenResult extends BaseServiceResult {
    @deserialize public IsValidToken!: boolean;
    @deserialize public IsExpired!: boolean;
}
