import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

@inheritSerialization(BaseServiceParameter)
export class SetPersonProfileDataParameter extends BaseServiceParameter {
    @serialize public PersonId: string;
    @serialize public FirstName?: string;
    @serialize public LastName?: string;
    @serialize public Title?: string;
    @serialize public Service?: string;
    @serialize public Role?: string;
    @serialize public Email?: string;

    constructor(personId: string) {
        super();
        this.PersonId = personId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class SetUserProfileImageParameter extends BaseServiceParameter {
    @serialize public PersonId: string;
    @serialize public FileName?: string;
    @serialize public FileContent?: string;
    @serialize public ThumbnailContent?: string;
    @serialize public IsDelete?: boolean;

    constructor(personId: string) {
        super();
        this.PersonId = personId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GenericPersonProfileDataResult extends BaseServiceResult {
    @deserializeAs(UserPublicData) public Person?: UserPublicData;
    @deserialize public IsErrorEmailAlreadyExists?: boolean;

    constructor() {
        super();
    }
}
