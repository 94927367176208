import { BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { AppEventsService } from './AppEvents.service';
import { Injectable } from '@angular/core';
import { NavigationEventService } from '../navigation/services/navigation-event.service';
import { RealTimeCommService } from './realTimeComm.service';
import {
    NavSpace,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { ImageCategory } from '@datagalaxy/webclient/client/data-access';
import { getContextId } from '@datagalaxy/webclient/utils';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class CurrentSpaceService extends BaseService {
    public get currentSpace$() {
        return this.currentSpaceSubject;
    }
    public get currentSpace() {
        return this.currentSpace$.value;
    }
    private set currentSpace(space: Space) {
        this.currentSpaceSubject.next(space);
    }
    private currentSpaceSubject = new BehaviorSubject<Space>(null);

    /** Previous space (defined only if currentSpace is not) */
    private _lastSpace: Space;

    public get mainSearchSpaceChange$() {
        return this.mainSearchSpaceChange.asObservable();
    }
    private mainSearchSpaceChange = new Subject<ISpaceIdentifier>();

    constructor(
        private navigationEventService: NavigationEventService,
        private realTimeCommService: RealTimeCommService,
        private appEventsService: AppEventsService
    ) {
        super();
        this.navigationEventService._updateSpace$.subscribe((navSpace) =>
            this.onUpdateSpace(navSpace)
        );
    }

    public hasCurrentSpace() {
        return !!this.currentSpace;
    }

    public getFuncLogPrefix() {
        return this.currentSpace?.isProject
            ? 'WORKSPACE_'
            : this.currentSpace?.isOrga
            ? 'ORGA_'
            : '';
    }

    public isCurrentSpaceVersioningEnabled() {
        return !!(this.currentSpace as Project)?.IsVersioningEnabled;
    }

    public getCurrentSpaceVersionName() {
        return (this.currentSpace as Project)?.VersionName;
    }

    public getCurrentSpace() {
        return this.currentSpace;
    }

    public setCurrentSpaceImageHash(
        hash: string,
        imageCategory: ImageCategory
    ) {
        const currentSpace = this.currentSpace;
        if (!currentSpace) {
            return;
        }

        if (imageCategory == ImageCategory.SpaceIcon) {
            currentSpace.IconHash = hash;
        }
    }

    public getCurrentOrLastSpace() {
        return this.currentSpace ?? this._lastSpace;
    }

    public notifyMainSearchSpaceChange(spaceIdr: ISpaceIdentifier) {
        this.mainSearchSpaceChange.next(spaceIdr);
    }

    public isCurrentSpace(spaceReferenceId: string) {
        return this.currentSpace?.ReferenceId === spaceReferenceId;
    }

    public isCurrentSpaceAndVersion(spaceIdr: ISpaceIdentifier) {
        return (
            this.isCurrentSpace(spaceIdr?.spaceId) &&
            ((!this.currentSpace.versionId && !spaceIdr?.versionId) ||
                this.currentSpace.versionId === spaceIdr?.versionId)
        );
    }

    public enableCurrentSpaceVersioning(projectVersion: ProjectVersion) {
        const project = this.currentSpace as Project;
        if (!project.isProject || projectVersion.spaceId != project.spaceId) {
            return;
        }

        project.IsVersioningEnabled = true;
        project.VersionId = projectVersion.ProjectVersionId;
        project.VersionName = projectVersion.VersionName;
        project.VersionDescription = projectVersion.VersionDescription;
    }

    /** Reserved for NavigationService. Use AppSpaceService.clearCurrentSpace instead. */
    public _clearCurrentAndLastSpace() {
        this.log('_clearCurrentAndLastSpace');
        this.setCurrentSpaceInternal(null);
        this._lastSpace = null;
    }

    /** Reserved for AppSpaceService. Use AppSpaceService.clearCurrentSpace instead */
    public _clearCurrentSpace(noLastSpace = false) {
        if (noLastSpace) {
            this._lastSpace = null;
        } else {
            const currentSpace = this.getCurrentSpace();
            if (currentSpace) {
                this._lastSpace = currentSpace;
            }
        }
        this.setCurrentSpaceInternal(null);
    }

    /** Reserved for AppSpaceService. Use AppSpaceService.setCurrentSpace instead */
    public _setCurrentSpace(currentSpace: Space) {
        if (!currentSpace) {
            this.logTrace('setCurrentSpace', currentSpace);
        }
        this.setCurrentSpaceInternal(currentSpace);
        this._lastSpace = null;
    }

    /** Also calls ImpactAnalysisService clearLineageCache, clearExploratoryCache */
    private setCurrentSpaceInternal(currentSpace: Space = null) {
        this.log('setCurrentSpaceInternal', currentSpace);

        this.currentSpace = currentSpace;

        if (currentSpace) {
            this.realTimeCommService.rtUpdateTradInterpolation(
                currentSpace.ReferenceId,
                'UI.IRealTime.WorkingOn',
                {
                    typeName: currentSpace.DataTypeName,
                    entityName: currentSpace.DisplayName,
                }
            );
        }

        const contextId = getContextId(currentSpace?.ReferenceId);
        const currentVersionId =
            currentSpace instanceof Project ? currentSpace.VersionId : null;
        this.realTimeCommService.setCurrentSpaceAndVersion(
            contextId,
            currentVersionId
        );
        this.appEventsService.notifyImpactAnalysisClearCacheNeeded();
    }

    private onUpdateSpace(navSpace: NavSpace) {
        if (this.currentSpace?.ReferenceId === navSpace.ReferenceId) {
            this.log('updateSpace$ is current space. updating');

            const currentSpace = this.currentSpace;
            if (!navSpace || !currentSpace) {
                return;
            }
            currentSpace.DisplayName = navSpace.DisplayName;
            currentSpace.Trigram = navSpace.Trigram;
            currentSpace.Description = navSpace.Description;
        }
    }
}
