import { deserialize, inheritSerialization } from 'cerialize';
import { SoftwareElementType } from './SoftwareElementType.enum';
import { DataProcessingType } from './DataProcessingType.enum';
import { PropertyType } from './PropertyType.enum';
import { IEntityIdentifier } from './IEntityIdentifier';
import { ServerType } from './ServerType.enum';
import { ModelType } from './ModelType.enum';
import { EntityTypeUtil } from './EntityTypeUtil';
import { DataIdentifier } from './DataIdentifier';

@inheritSerialization(DataIdentifier)
export class HierarchyDataDescriptor
    extends DataIdentifier
    implements IEntityIdentifier
{
    @deserialize public DisplayName: string;
    @deserialize public TechnicalName: string;
    @deserialize public SubTypeName: string;

    /** unordered list of item's direct parents local ids */
    @deserialize public ParentList: string[];

    /** true when the item is not to be displayed */
    @deserialize public IsTechnical: boolean;
    @deserialize public HasReadAccess: boolean;

    // CLIENT_SIDE ONLY
    public VersionId: string;
    public get ServerType() {
        return this.DataServerType;
    }
    public override get DataServerType() {
        return ServerType[this.DataTypeName] as ServerType;
    }
    public get DataContextId() {
        return this.DataReferenceId.split(':')[0];
    }
    public get DataLocalId() {
        return this.DataReferenceId.split(':')[1];
    }
    public get EntityType() {
        return EntityTypeUtil.getEntityType(
            this.DataTypeName,
            this.SubTypeName || null
        );
    }
    public get entityType() {
        return this.EntityType;
    }
    public get ReferenceId() {
        return this.DataReferenceId;
    }

    public get SubType(): PropertyType | SoftwareElementType | ModelType {
        switch (this.DataServerType) {
            case ServerType.Property:
                return PropertyType[this.SubTypeName];
            case ServerType.DataProcessing:
                return DataProcessingType[this.SubTypeName];
            case ServerType.SoftwareElement:
                return SoftwareElementType[this.SubTypeName];
            case ServerType.Model:
                return ModelType[this.SubTypeName];
        }
    }

    public constructor(id?: string, serverType?: ServerType) {
        super(id, serverType ? ServerType[serverType] : null);
    }

    public setVersionId(versionId: string) {
        this.VersionId = versionId;
    }

    public isSpace() {
        return (
            this.DataServerType == ServerType.Project ||
            this.DataServerType == ServerType.Organization
        );
    }
}
