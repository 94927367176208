import { IRichTextMentionData } from '@datagalaxy/core-ui/rich-text';
import { TeamPublicData } from '@datagalaxy/webclient/team/data-access';
import { UserGroup } from '@datagalaxy/webclient/system/data-access';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

export enum RichTextMentionType {
    User,
    Object,
}

/** Data used to render a mention in a rich-text content */
export class RichTextMentionData implements IRichTextMentionData {
    constructor(
        public mentionId: string,
        public mentionTypeName?: string,

        public objectId?: string,
        public objectTypeName?: string,

        public mentionContent?: any
    ) {}
}

/** Item for selecting a user, for a user mention in a rich-text content */
export class RichTextUserMentionResultItem {
    constructor(
        public displayName: string,
        public iconClass: string,

        public objectId: string,
        public objectTypeName: string,

        public isGroup?: boolean
    ) {}
}

abstract class RichTextMention {
    public abstract label: string;
    public abstract iconClass: string;
    public abstract iconUrl: string;
    public abstract tooltipKey: string;
    public abstract targetId: string;
}

export class RichTextUserMention extends RichTextMention {
    public get label() {
        return this.user?.FullName;
    }
    public get iconClass() {
        return 'glyph-profile';
    }
    public get iconUrl() {
        return '';
    }
    public get tooltipKey() {
        return '';
    }

    constructor(private user: UserPublicData, public targetId: string) {
        super();
    }
}

export class RichTextTeamMention extends RichTextMention {
    public get label() {
        return this.team?.TeamName;
    }
    public get tooltipKey() {
        return 'UI.RichText.Mention.Types.Team.tooltip';
    }

    constructor(
        private team: TeamPublicData,
        public targetId: string,
        public iconClass: string,
        public iconUrl: string
    ) {
        super();
    }
}

export class RichTextGroupMention extends RichTextMention {
    public get label() {
        return this.userGroup?.DisplayName;
    }
    public get iconClass() {
        return 'glyph-team-share';
    }
    public get iconUrl() {
        return '';
    }
    public get tooltipKey() {
        return '';
    }

    constructor(private userGroup: UserGroup, public targetId: string) {
        super();
    }
}
