import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    LegacyMenuPositionY as MenuPositionY,
    MatLegacyMenuModule,
} from '@angular/material/legacy-menu';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { DomUtil } from '@datagalaxy/core-util';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { IMultiSelectData } from '../../../components/multiselect-list/multiselect-list.types';
import { IListOptionItem } from '../../../components/option-item/option-item.types';
import { DxyMultiselectListComponent } from '../../../components/multiselect-list/multiselect-list.component';
import { getOptionItemFromAdapter } from '../../../field-select.types';
import { DxyBaseMatFormFieldControl } from '../../../base';
import { IMultiSelectFieldSelectedItemClickEvent } from './multi-select-field-control.types';
import { ListOptionUtil } from '../../../IListOption';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyOptionItemComponent } from '../../../components/option-item/option-item.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'dxy-multi-select-field-control',
    templateUrl: 'multi-select-field-control.component.html',
    styleUrls: ['multi-select-field-control.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyMultiSelectFieldControlComponent,
        },
    ],
    standalone: true,
    imports: [
        MatLegacyMenuModule,
        NgIf,
        NgFor,
        DxyOptionItemComponent,
        DxyDataTestIdDirective,
        TranslateModule,
        MatLegacyButtonModule,
        DxyMultiselectListComponent,
    ],
})
export class DxyMultiSelectFieldControlComponent<T>
    extends DxyBaseMatFormFieldControl<T[]>
    implements OnChanges, OnInit
{
    @Input() data: IMultiSelectData<T>;
    @Input() readonly: boolean | string;
    @Input() openMenuOnFocus: boolean;
    /** If true, the mat-menu will take the same width as the field control */
    @Input() menuTakeFullWidth: boolean;
    @Input() maxDisplayedOptions?: number;
    @Input() menuHasDynamicHeight?: boolean;
    @Output() onMenuToggle = new EventEmitter<boolean>();
    @Output() selectedItemClick = new EventEmitter<
        IMultiSelectFieldSelectedItemClickEvent<T>
    >();

    //#region html bindings
    public orderedSelectedItems: IListOptionItem<T>[];
    protected yMenuPosition: MenuPositionY = 'below';
    public get moreTagCount() {
        return this.data?.selectedItems?.length - this.maxDisplayedTags;
    }
    public get isReadonly() {
        return this.disabled || coerceBooleanProperty(this.readonly);
    }
    public get dataType() {
        return this.data.dataType;
    }
    //#endregion

    //#region DxyBaseMatFormFieldControl
    public get value() {
        return this.data.selectedItems;
    }
    public get empty() {
        return !this.data?.selectedItems?.length;
    }
    //#endregion

    @ViewChild(MatMenuTrigger)
    public trigger: MatMenuTrigger;
    @ViewChild('matMenuTrigger', { read: ElementRef })
    triggerRef: ElementRef<HTMLElement>;

    @ViewChild(DxyMultiselectListComponent)
    private multiSelectList: DxyMultiselectListComponent;

    public get isMenuOpen() {
        return this.trigger?.menuOpen;
    }
    private get isActive() {
        return this.focused;
    }
    private get adapter() {
        return this.data?.adapter;
    }
    private get maxDisplayedTags() {
        return Math.max(10, this.maxDisplayedOptions ?? 0);
    }

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        private translate: TranslateService
    ) {
        super(
            'dxy-multi-select-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'data', () =>
            this.updateOrderedSelectedItems()
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.log('ngOnInit', this.data);
        this.updateOrderedSelectedItems();
    }

    //#region API
    public focus() {
        DomUtil.focusElement(this.elementRef, '.field-value');
        if (this.openMenuOnFocus) {
            setTimeout(() => this.openMenu(), 200);
        }
    }
    public blur() {
        DomUtil.blurElement(this.elementRef, '.field-value');
    }
    public focusSearch() {
        this.multiSelectList?.focusSearch();
    }
    public refreshUi() {
        this.multiSelectList?.refreshUi();
    }
    public triggerSearch() {
        return this.multiSelectList?.triggerSearch();
    }
    //#endregion

    public onListSelectionChange(selectedItems: T[]) {
        super.onValueChanged(selectedItems);
        this.updateOrderedSelectedItems();
    }

    public onMenuTriggerMouseDown() {
        if (this.readonly || this.trigger.menuOpen) {
            return;
        }
        this.adjustYMenuPositionIfNeeded();
    }

    public onToggle(open: boolean) {
        this.log('onToggle', open);
        if (!this.data || (open && this.readonly)) {
            this.trigger.closeMenu();
            return;
        }
        const action = () => this.onMenuToggle.next(open);
        if (open) {
            action();
            if (this.menuTakeFullWidth) {
                this.adaptMenuWidthToFieldControlWidth();
            }
            // Refresh multiselectUi after mat-menu animation
            setTimeout(() => this.multiSelectList?.refreshUi(), 250);
        } else {
            setTimeout(action, 333);
        } // onclose, prevent any modal to close
    }

    public onSelectedItemClick(event: MouseEvent, option: IListOptionItem<T>) {
        this.selectedItemClick.emit({ event, data: option.data });
    }

    public async onRemoveOption(option: IListOptionItem<T>, event: Event) {
        this.log('onRemoveOption', option);
        event.stopPropagation();
        const canBeToggled = this.data.canBeToggled;
        if (
            canBeToggled &&
            !(await this.data.canBeToggled(option.data, true))
        ) {
            return;
        }
        const selectedItems = this.data.selectedItems.filter(
            (item) => item !== option.data
        );
        this.data = {
            ...this.data,
            selectedItems,
        };
        this.onValueChanged(selectedItems);
        this.data?.onSelectionChange?.(selectedItems);
        this.updateOrderedSelectedItems();
    }

    public showRemoveOption(option: IListOptionItem<T>) {
        const isRestrictedItemValue = CoreUtil.fromFnOrValue(
            this.data.isRestrictedItem,
            option.data
        );

        return this.isActive && !this.readonly && !isRestrictedItemValue;
    }

    protected preventBlurOnFocusOut(event: FocusEvent) {
        return (
            super.preventBlurOnFocusOut(event) ||
            super.relatedTargetHasClassName(event, 'menu-item', 'search-input')
        );
    }

    private updateOrderedSelectedItems() {
        this.log(
            'updateOrderedSelectedItems',
            this.data?.selectedItems?.length
        );
        const items = this.data?.sortOptions
            ? this.data
                  .sortOptions(this.data?.selectedItems)
                  ?.map((item) => this.makeOption(item))
            : CollectionsHelper.orderBy(
                  this.data?.selectedItems?.map((item) =>
                      this.makeOption(item)
                  ),
                  (it) => ListOptionUtil.getText(it, this.translate)
              );

        this.orderedSelectedItems = items.slice(0, this.maxDisplayedTags);
    }

    private makeOption(item: T): IListOptionItem<T> {
        return getOptionItemFromAdapter(item, this.adapter, this.translate);
    }

    private openMenu() {
        if (this.readonly || this.trigger.menuOpen) {
            return;
        }
        this.adjustYMenuPositionIfNeeded();
        this.trigger.openMenu();
        if (this.menuTakeFullWidth) {
            this.adaptMenuWidthToFieldControlWidth();
        }
    }

    private adaptMenuWidthToFieldControlWidth() {
        const matMenuId = this.trigger.menu.panelId;
        const matMenuEl = DomUtil.getElement(document, `#${matMenuId}`);
        const width =
            this.elementRef.nativeElement.getBoundingClientRect().width;
        matMenuEl?.setAttribute(
            'style',
            `max-width: ${width}px;width: ${width}px;`
        );
    }

    private adjustYMenuPositionIfNeeded() {
        if (!this.menuHasDynamicHeight) {
            return;
        }
        const menuTriggerEl = this.triggerRef.nativeElement;
        const menuTriggerMiddleOffsetY =
            menuTriggerEl.getBoundingClientRect().top +
            menuTriggerEl.offsetHeight / 2;

        this.yMenuPosition =
            menuTriggerMiddleOffsetY > window.innerHeight / 2
                ? 'above'
                : 'below';
    }
}
