import { ForeignKeyColumnMapping } from './ForeignKeyColumnMapping';
import { PrimaryKeyForeignKeyLink } from '@datagalaxy/webclient/modeler/data-access';

export class ForeignKeyColumnMappingContainer {
    public get mappings() {
        return this._mappings;
    }

    private readonly _mappings: ForeignKeyColumnMapping[] = [];

    constructor() {}

    public addMapping(...mappings: ForeignKeyColumnMapping[]) {
        this._mappings.push(...mappings);
    }

    public getLinks() {
        return this._mappings.map(
            (m) =>
                new PrimaryKeyForeignKeyLink(
                    m.primaryKeyColumnId,
                    m.isNewColumn,
                    m.childColumnId,
                    m.childColumnName
                )
        );
    }

    public isAllMappingsValid() {
        return this._mappings.every((m) => m.isMappingSuccess);
    }

    public updateMappingsValidity() {
        this._mappings.forEach((m) => m.updateMappingValidity());
    }

    public updateMappingUnicity() {
        this._mappings.forEach((m) => m.updateMappingUnicity(this._mappings));
    }
}
