import { MultilingualState } from '../../multilingual-state';
import { LanguageSelectorButtonState } from './language-selector-button-state';

export const selectPendingBatchesState = (
    state: MultilingualState
): LanguageSelectorButtonState => {
    const languages = [
        state.settings.sourceLanguageCode ?? '',
        ...(state.settings.targetLanguages?.map((l) => l.languageCode) ?? []),
    ];
    return {
        languageCodes: languages,
        sourceLanguageCode: state.settings.sourceLanguageCode ?? '',
        hasPendingBatch:
            !!state.pendingBatches?.length || !!state.liveImportIds.length,
        enabled: state.settings.enabled ?? false,
        hasPendingRegeneration: !!state.runningRegenerations,
    };
};
