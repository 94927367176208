import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    NavigateTo,
    NavigationService,
} from '../../services/navigation.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { NavigationApiService } from './navigation-api.service';
import { ISpaceBreadcrumbInfo } from '../../shared/util/BreadcrumbUtil';
import { IHierarchicalData, ServerType } from '@datagalaxy/dg-object-model';
import { HddUtil } from '../../shared/util/HddUtil';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { DisplayedHData } from '../../shared/util/app-types/DisplayedHData';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService extends BaseService {
    constructor(
        private navigationService: NavigationService,
        private navigationApiService: NavigationApiService,
        private entityUiService: EntityUiService,
        private currentSpaceService: CurrentSpaceService,
        private appSpaceService: AppSpaceService
    ) {
        super();
    }

    /** note: This assumes that first item's HddData is referencing a space */
    public async getSpaceBreadcrumbInfo(
        breadcrumbData: BreadcrumbData
    ): Promise<ISpaceBreadcrumbInfo> {
        const firstItem = breadcrumbData.items[0];
        if (!firstItem?.HddData?.Data?.isSpace?.()) {
            return;
        }

        const spaceId = firstItem.HddData.DataReferenceId;
        const navSpace = await this.navigationApiService.getNavSpaceById(
            spaceId
        );
        return {
            IconHash: navSpace?.IconHash,
            DisplayName: navSpace?.DisplayName,
            Trigram: navSpace?.Trigram,
        };
    }

    public getBreadcrumbForSpace(space: Space, readOnly = false) {
        const hd = HddUtil.createHDataForSpace(space);
        return this.getBreadcrumb(hd, { readOnly, includeSelf: true });
    }
    public getBreadcrumbFromLogicalHdds(
        data?: HierarchyDataDescriptor,
        logicalParents?: HierarchyDataDescriptor[],
        options: IBreadcrumbOptions = {}
    ) {
        const {
            includeSelf,
            readOnly,
            forceIncludeSpace,
            forceExcludeSpace,
            getTabNameToNavTo,
        } = options;
        const excludeSpace =
            forceExcludeSpace ||
            (!forceIncludeSpace && this.currentSpaceService.hasCurrentSpace());
        const orderedParents = logicalParents?.slice().reverse() ?? [];
        const items =
            orderedParents
                .filter((hdd) => !excludeSpace || !HddUtil.isSpace(hdd))
                .map((p, i) => {
                    const hData = new HierarchicalData(
                        p,
                        orderedParents.slice(i + 1)
                    );
                    const navToTabName = getTabNameToNavTo?.(hData);
                    return this.buildItem(hData, readOnly, navToTabName);
                }) || [];

        if (data && includeSelf) {
            const hData = new HierarchicalData(data, orderedParents);
            items.push(this.buildItem(hData, readOnly));
        }

        return new BreadcrumbData(items);
    }
    public getBreadcrumb(
        hdata: IHierarchicalData,
        options: IBreadcrumbOptions = {}
    ) {
        const {
            readOnly,
            includeSelf,
            forceIncludeSpace,
            forceExcludeSpace,
            getTabNameToNavTo,
        } = options;
        const spaceIdr = SpaceIdentifier.fromHdd(hdata.Data);
        const spaceIsSameAsContext =
            this.appSpaceService.isSingleWorkspace() ||
            (this.currentSpaceService.hasCurrentSpace() &&
                SpaceIdentifier.areSame(
                    spaceIdr,
                    this.currentSpaceService.currentSpace
                ));
        const excludeSpace =
            forceExcludeSpace || (!forceIncludeSpace && spaceIsSameAsContext);
        const items: DisplayedHData[] = [];

        if (hdata) {
            const currentHdd = hdata.Data;
            const hdds = this.getHierarchyDataDescriptorsForBreadcrumb(hdata);
            hdds.forEach((hdd) => {
                if (excludeSpace && HddUtil.isSpace(hdd)) {
                    return;
                }

                const isCurrent =
                    currentHdd && hdd.DataLocalId == currentHdd.DataLocalId;
                const hData = isCurrent
                    ? hdata
                    : HddUtil.extractParentHData(hdata, hdd.DataReferenceId);

                if (includeSelf || !isCurrent) {
                    const navToTabName = getTabNameToNavTo?.(hData);
                    items.push(this.buildItem(hData, readOnly, navToTabName));
                }
            });
        }
        return new BreadcrumbData(items);
    }
    private buildItem(
        hData: IHierarchicalData,
        isReadOnly: boolean,
        navToTab?: string
    ) {
        return this.entityUiService.buildDisplayedHData(
            hData,
            isReadOnly,
            navToTab
        );
    }

    public getNavToItem(item: DisplayedHData) {
        return this.getNavTo(false, item.navToTab);
    }
    public getNavTo(navToLineage = false, navToTabName?: string) {
        return this.navigationService.getNavTo(navToLineage, navToTabName);
    }

    public async navigateByHierarchicalData(
        hdata: IHierarchicalData,
        destination: NavigateTo,
        isFromBreadcrumb: boolean,
        isFromHierarchicalView = false,
        withEntityFullPage?: boolean
    ) {
        await this.navigationService.goToWithHierarchicalData(hdata, {
            destination,
            isFromHierarchicalView,
            withEntityFullPage,
            isFromBreadcrumb,
            broadcastStateChangeSuccess: true,
        });
    }

    private getHierarchyDataDescriptorsForBreadcrumb(hdata: IHierarchicalData) {
        const dataDescriptors = HddUtil.getHierarchyDataDescriptorList(hdata)
            .filter((hdd) => this.isAvailableDataDescriptorForBreadcrumb(hdd))
            .reverse();

        dataDescriptors.push(hdata.Data);
        return dataDescriptors;
    }

    private isAvailableDataDescriptorForBreadcrumb(
        parentDataDescriptor: HierarchyDataDescriptor
    ) {
        if (parentDataDescriptor.IsTechnical) return false;

        switch (ServerType[parentDataDescriptor.DataTypeName]) {
            case ServerType.Organization:
            case ServerType.Project:
            case ServerType.Property:
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
            case ServerType.Diagram:
                return true;
            default:
                return false;
        }
    }
}

/** holds an array of DisplayedHData for to display a breadcrumb */
export class BreadcrumbData {
    public items: DisplayedHData[];

    constructor(items: DisplayedHData | DisplayedHData[]) {
        this.items = Array.isArray(items) ? items : items ? [items] : [];
    }
}

export interface IBreadcrumbOptions {
    readOnly?: boolean;
    includeSelf?: boolean;
    /* if false, space is included only if no current space is selected */
    forceIncludeSpace?: boolean;
    /* if false, space is included only if no current space is selected */
    forceExcludeSpace?: boolean;
    getTabNameToNavTo?: (hd: IHierarchicalData) => string | undefined;
}
