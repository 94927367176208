import { ClientVersionInterceptor } from '../http-interceptors/client-version.interceptor';
import { JwtInterceptor } from '../http-interceptors/jwt.interceptor';
import { XidInterceptor } from '../http-interceptors/xid.interceptor';
import { ErrorInterceptor } from '../http-interceptors/error.interceptor';
import { REST_HTTP_INTERCEPTORS } from './rest-http-client.service';
import { ProblemDetailsInterceptor } from '../http-interceptors/problem-details.interceptor';

export const restHttpInterceptors = [
    {
        provide: REST_HTTP_INTERCEPTORS,
        useClass: ClientVersionInterceptor,
        multi: true,
    },
    { provide: REST_HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: REST_HTTP_INTERCEPTORS, useClass: XidInterceptor, multi: true },
    {
        provide: REST_HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true,
    },
    {
        provide: REST_HTTP_INTERCEPTORS,
        useClass: ProblemDetailsInterceptor,
        multi: true,
    },
];
