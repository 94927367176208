<ng-container *ngIf="hdd">
    <span class="glyph" [ngClass]="glyphClass"></span>
    <a
        class="navto-href"
        [href]="href"
        (click)="onClick($event)"
        dxyEllipsisTooltip
        >{{ hdd.Data?.DisplayName }}</a
    >
</ng-container>
