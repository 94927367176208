import { Injectable } from '@angular/core';
import {
    SlackIntegrationActivatedEvent,
    SlackIntegrationDeactivatedEvent,
} from '@datagalaxy/dg-object-model';
import { IDgEventInterpolationProvider } from './dg-event-interpolation-provider';
import { slackNotificationChannel } from '../../notification-channels/slack-notification-channel';

@Injectable({ providedIn: 'root' })
export class SlackInterpolationProvider
    implements IDgEventInterpolationProvider
{
    public static eventTypes = [
        SlackIntegrationActivatedEvent,
        SlackIntegrationDeactivatedEvent,
    ];

    constructor() {}

    public resolveParameters() {
        return {
            customAvatarUrl: slackNotificationChannel.imageUrl,
        };
    }
}
