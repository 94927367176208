<ng-template #mentionWrapper>
    <div class="mention-dropdown dg5-mat-menu mat-menu-panel">
        <ng-container #mentionDropdown></ng-container>
    </div>
</ng-template>
<div class="editor-container" tabindex="0" [class.disabled]="disabled">
    <div class="editor-pane" [class.has-focus]="focused" #paneElement></div>
    <div
        class="toolbar-overlay"
        [class.hidden]="!showToolbarOverlay"
        (click)="onOverlayClick()"
    ></div>
    <div class="editor-toolbar" [class.hidden]="!showToolbar" #toolbarElement>
        <!-- Add font size dropdown -->
        <span class="ql-formats">
            <select class="ql-header toolbar-button dg5-small-icon-button">
                <option value="1"></option>
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option value="2"></option>
                <option value="3"></option>
                <option selected></option>
            </select>
        </span>
        <span class="ql-formats">
            <button
                mat-icon-button
                class="ql-bold toolbar-button dg5-small-icon-button"
            ></button>
            <button
                mat-icon-button
                class="ql-italic toolbar-button dg5-small-icon-button"
            ></button>
            <button
                mat-icon-button
                class="ql-underline toolbar-button dg5-small-icon-button"
            ></button>
            <button
                mat-icon-button
                class="ql-strike toolbar-button dg5-small-icon-button"
            ></button>
        </span>
        <span class="ql-formats">
            <button
                mat-icon-button
                class="ql-script toolbar-button dg5-small-icon-button"
                value="super"
            ></button>
            <button
                mat-icon-button
                class="ql-script toolbar-button dg5-small-icon-button"
                value="sub"
            ></button>
        </span>
        <span class="ql-formats">
            <select
                class="ql-color toolbar-button mat-button-base mat-icon-button"
            >
                <option selected></option>
                <option value="#d4d4d4"></option>
                <option value="#ffffff"></option>
                <option value="#00a99d"></option>
                <option value="#8cc63f"></option>
                <option value="#f7931e"></option>
                <option value="#d4145a"></option>
                <option value="#29abe2"></option>
                <option value="#0971b7"></option>
                <option value="#1e2437"></option>
                <option value="#808595"></option>
                <option value="#b7bdcb"></option>
                <option value="#3E2723"></option>
                <option value="#753627"></option>
                <option value="#D38C6C"></option>
                <option value="#CB4800"></option>
                <option value="#E57400"></option>
                <option value="#F1A600"></option>
                <option value="#7D0000"></option>
                <option value="#D30027"></option>
                <option value="#FC6565"></option>
                <option value="#841052"></option>
                <option value="#E01882"></option>
                <option value="#F074C0"></option>
                <option value="#4A148C"></option>
                <option value="#761FDE"></option>
                <option value="#B98BEF"></option>
                <option value="#1A237E"></option>
                <option value="#404ED7"></option>
                <option value="#3FA9F5"></option>
                <option value="#006837"></option>
                <option value="#009245"></option>
                <option value="#8CC63F"></option>
                <option value="#263238"></option>
                <option value="#577F92"></option>
                <option value="#6EB3D4"></option>
                <option value="#212121"></option>
                <option value="#616161"></option>
                <option value="#90A4AE"></option>
            </select>
            <select
                class="ql-background mat-button-base mat-icon-button toolbar-button"
            >
                <option value="#1e2437"></option>
                <option value="#d4d4d4"></option>
                <option selected></option>
                <option value="#00a99d"></option>
                <option value="#8cc63f"></option>
                <option value="#f7931e"></option>
                <option value="#d4145a"></option>
                <option value="#29abe2"></option>
                <option value="#0971b7"></option>
                <option value="#1e2437"></option>
                <option value="#808595"></option>
                <option value="#b7bdcb"></option>
                <option value="#3E2723"></option>
                <option value="#753627"></option>
                <option value="#D38C6C"></option>
                <option value="#CB4800"></option>
                <option value="#E57400"></option>
                <option value="#F1A600"></option>
                <option value="#7D0000"></option>
                <option value="#D30027"></option>
                <option value="#FC6565"></option>
                <option value="#841052"></option>
                <option value="#E01882"></option>
                <option value="#F074C0"></option>
                <option value="#4A148C"></option>
                <option value="#761FDE"></option>
                <option value="#B98BEF"></option>
                <option value="#1A237E"></option>
                <option value="#404ED7"></option>
                <option value="#3FA9F5"></option>
                <option value="#006837"></option>
                <option value="#009245"></option>
                <option value="#8CC63F"></option>
                <option value="#263238"></option>
                <option value="#577F92"></option>
                <option value="#6EB3D4"></option>
                <option value="#212121"></option>
                <option value="#616161"></option>
                <option value="#90A4AE"></option>
            </select>
        </span>
        <span class="ql-formats">
            <button
                mat-icon-button
                class="ql-list toolbar-button dg5-small-icon-button"
                value="ordered"
            ></button>
            <button
                mat-icon-button
                class="ql-list toolbar-button dg5-small-icon-button"
                value="bullet"
            ></button>
            <select
                class="ql-align toolbar-button mat-button-base mat-icon-button"
            >
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
            </select>
        </span>

        <span class="ql-formats">
            <button
                mat-icon-button
                class="ql-blockquote toolbar-button dg5-small-icon-button"
            ></button>
            <button
                mat-icon-button
                class="ql-code-block toolbar-button dg5-small-icon-button"
            ></button>
            <button
                mat-icon-button
                class="ql-link toolbar-button dg5-small-icon-button"
            ></button>
        </span>
    </div>
</div>
