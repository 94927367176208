/**
 * [Crowdin](https://crowdin.com/) need to use a real lang, not used by the application
 * to init in app context translation, for now we use ga (Irish)
 */
export const crowdinInContextLang = 'ga';

export interface ILanguageConfig {
    /** available language codes */
    codes: string[];

    /** startup language code, from browser languages */
    startup: string;
}
