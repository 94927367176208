import { percentage, Rect, Shape } from 'doka';

export interface IDestState {
    dest: File;
}
export interface IDestCropState {
    dest: File;
    imageState: {
        crop: Rect;
    };
}

export enum CropMode {
    default = 0,
    free,
    round,
    square,
}

export interface IShapes {
    decorationShapes: Shape[];
    annotationShapes: Shape[];
    interfaceShapes: Shape[];
}

export interface IShapesState {
    selectionRect: Rect;
    opacity: number | percentage;
}

export type TAllowedMimeType =
    | 'image/png'
    | 'image/jpeg'
    | 'image/gif'
    | 'image/webp';
export const AllowedMimeTypes: Readonly<TAllowedMimeType[]> = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/webp',
];
