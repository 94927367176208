import { SerializableEnumeration } from 'cerialize';

/**
 * Represent all attribute's types
 * Note: The order doesn't matter since it is returned by the server as a string value
 *
 * API Definition: DataGalaxy.Core.Data.Service.Entity.AttributeType.cs
 */
export enum AttributeMetaType { //server type: DataGalaxy.Core.Data.Service.Entity.AttributeType
    Text,
    Number,
    Boolean,
    ValueList,
    Module,
    Technology,
    FormattedText,
    HtmlLink,
    Date,
    MultiLineText,
    Reference,
    ReferenceList,
    ReferenceId,
    /** For a CDP containing tags (ClientAttributeTag)*/
    ManagedTag,
    /** For a CDP containing tags (ClientAttributeTag) that can be added on the fly (ie in non-admin UI) */
    MultiValueList,
    UserGuid,
    UserReference,
    PersonReference,
    /** For a CDP or System-attribute containing hierarchical tags (see also HierarchyKind) */
    Hierarchy,
    DateTime,
    /** Attribute key is *DataTypePrecisionSize* */
    DataTypeAndSize,
    SystemDataType,
    SystemEntityType,
    ObjectValueList,
    ObjectNameList,
    /** Possible attribute keys are: *CdoUsers*, *CisoUsers*, *DataOwners*, *DataStewards*, *DpoUsers*, *ExpertUsers* */
    StewardUserReference,
    /* For Client-level Tags (Table T_ClientTag) */
    ClientTag,
    /** Attribute key is *LogicalPath* */
    EntityLogicalPath,
    EntityLogicalPathString,
    EntityLogicalTypePathString,
    /** Attribute key is *LogicalParentData* */
    EntityLogicalParent,
    /** Possible attribute keys are: *LinkShortcutUniverseId*, ... */
    EntityLinkShortcut,
    EntityReference,
    SpaceDefaultOfficialUser, // For the Space Creation modal, for the available users list as default owner vs steward
    SpaceGovernanceUser,
    ObjectLink,

    /**
     * The full TimeSeriesObject content
     * */
    TimeSeriesObject,

    /**
     * The TimeSeriesObject content, with only the LastEntry and Trend properties. Entries property is null.
     * The AttributeKey is the Base AttributeKey + "_lastEntry"
     * */
    TimeSeriesLastEntry,

    /**
     * The TimeSeriesObject content, limited to the (10 + 1) last entries.
     * The AttributeKey is the base AttributeKey + "_lastEntries"
     * */
    TimeSeriesLastEntries,

    /**
     * NOTE: introduced to avoid having a boost when search in ES.
     * e.g.: For ImportTypedUnicityName, when searching in ES
     */
    ElasticSearchKeyword,

    /**
     * Used to filter on all linked objects
     */
    AllLinkedData,
    /**
     * NOTE: Used to represent internal JSON-based data such as <see cref="DiagramEdge.LinkGeometryData"/>
     */
    JsonText,
}
SerializableEnumeration(AttributeMetaType);
