import { Component, Input } from '@angular/core';
import { IServerHealthCheck } from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'healthcheck',
    templateUrl: 'dxy-is-up.component.html',
    standalone: true,
})
export class DxyIsUpComponent extends DxyBaseComponent {
    @Input() upData: IServerHealthCheck;

    constructor() {
        super();
    }
}
