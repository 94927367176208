<div class="settings-item">
    <dxy-field-text
        [(ngModel)]="delimiter"
        (blur)="onManuallyChangeDelimiter()"
        labelKey="Import.GenericImportWizard.CsvSettings.FieldSeparator"
        [placeholder]="
            'Import.Wizard.FileDeposit.CarriageAutoDetect' | translate
        "
    ></dxy-field-text>
</div>
<div class="settings-item">
    <dxy-field-select
        labelKey="Import.GenericImportWizard.CsvSettings.FieldCarriage"
        [adapter]="carriageAdapter"
        (openClose)="onMenuOpenClose.emit($event)"
    ></dxy-field-select>
</div>
<div class="settings-item">
    <dxy-field-select
        labelKey="Import.GenericImportWizard.CsvSettings.FieldEncoding"
        [adapter]="encodingAdapter"
        (openClose)="onMenuOpenClose.emit($event)"
    ></dxy-field-select>
</div>
