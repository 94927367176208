import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { BaseTeamParameter } from '../team';
import {
    TeamMemberDto,
    TeamMembershipRequest,
    UpdateMembershipRequestType,
} from './team-member';

@inheritSerialization(BaseServiceResult)
export class GetTeamMembersResult extends BaseServiceResult {
    @deserializeAs(TeamMemberDto) TeamMembers!: TeamMemberDto[];
    @deserializeAs(TeamMembershipRequest)
    MembershipRequests!: TeamMembershipRequest[];
    @deserialize public IsCurrentUserMember!: boolean;
    @deserialize public HasCurrentUserMembershipRequest!: boolean;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class AddTeamMembersParameter extends BaseServiceParameter {
    @serialize public TeamUid: string;
    @serialize public TeamMemberUidList: string[];
    @serialize public TeamOwnerGuidList?: string[];
    @serialize public IsMembershipRequest?: boolean;

    constructor(
        teamId: string,
        newMemberIds: string[],
        newOwnerIds?: string[],
        isMembershipRequest?: boolean
    ) {
        super();
        this.TeamUid = teamId;
        this.TeamMemberUidList = newMemberIds;
        this.TeamOwnerGuidList = newOwnerIds;
        this.IsMembershipRequest = isMembershipRequest;
    }
}

@inheritSerialization(BaseServiceResult)
export class AddTeamMembersResult extends BaseServiceResult {
    @deserialize TeamMembers!: TeamMemberDto[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteTeamMembersParameter extends BaseServiceParameter {
    @serialize public TeamUid: string;
    @serialize public TeamMemberUidList: string[];

    constructor(teamId: string, newMemberIds: string[]) {
        super();
        this.TeamUid = teamId;
        this.TeamMemberUidList = newMemberIds;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateTeamMembershipRequestResult extends BaseServiceResult {
    @deserializeAs(TeamMembershipRequest)
    CreatedMembershipRequest!: TeamMemberDto[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseTeamParameter)
export class UpdateMembershipRequestParameter extends BaseTeamParameter {
    @serialize public UpdateMembershipRequestType: UpdateMembershipRequestType;
    /** Nullable Field if UpdateMembershipRequestType is AcceptAll OR RejectAll
     Required Field if UpdateMembershipRequestType is Accept OR Reject (error 500 will be returned if it s not the case) */
    @serialize public PersonUid?: string;

    constructor(
        teamId: string,
        updateType: UpdateMembershipRequestType,
        personId?: string
    ) {
        super(teamId);
        this.UpdateMembershipRequestType = updateType;
        this.PersonUid = personId;
    }
}
