import { gridCellSize } from './grid-util';
import { GridMode, IGridOptions } from './grid.types';
import { AnimationFrameBuffer, IViewPortEvent, Viewport } from '../index';
import { BaseGraphicalManager } from '../BaseGraphicalManager';
import { GridComponent } from './grid.component';

/** ## Role
 * Manage a svg background grid
 *
 *  ## Features
 * - onChange event with grid mode and specs
 * - provides grid-mode button glyph, tooltip, state & callback
 */
export class GridManager extends BaseGraphicalManager {
    private grid?: GridComponent;
    private _mode?: GridMode;
    private afb = AnimationFrameBuffer.getInstance();

    public set mode(value: GridMode) {
        this.setGridMode(value);
    }
    public get mode() {
        return this._mode;
    }
    public get isSnapToGridActive() {
        return this.options?.magnetic;
    }
    public get cellSize() {
        return gridCellSize(this.options?.cellSize);
    }

    constructor(
        private container: HTMLDivElement,
        private viewport: Viewport,
        private options?: IGridOptions
    ) {
        super();
        this.setupGrid();

        super.subscribe(viewport.change$, (event) =>
            this.onViewPortChange(event)
        );
    }

    private onViewPortChange(event: IViewPortEvent) {
        if (!this.grid) {
            return;
        }
        const viewportProps = this.getViewportProps(event);
        this.grid.updateProps(viewportProps);
        this.afb.addCallback(() => this.grid.draw());
    }

    private setGridMode(mode: GridMode) {
        if (this._mode === mode) {
            return;
        }
        this._mode = mode;
        Object.assign(this.options, {
            visible: mode === GridMode.on,
            magnetic: mode === GridMode.on || mode === GridMode.hidden,
        });
        this.setupGrid();
    }

    private setupGrid() {
        this._mode = this.getGridMode(this.options);
        if (!this.options?.visible) {
            this.grid?.dispose();
            return;
        }

        const viewportProps = this.getViewportProps(this.viewport.viewport);
        this.grid = new GridComponent({
            ...this.options,
            ...viewportProps,
        });

        this.afb.addCallback(() => this.grid.draw());
        this.container.prepend(this.grid.el);
    }

    private getViewportProps(viewport: IViewPortEvent) {
        return {
            width: viewport.bounds.width / viewport.scale,
            height: viewport.bounds.height / viewport.scale,
            position: {
                x: -viewport.position.x / viewport.scale,
                y: -viewport.position.y / viewport.scale,
            },
        };
    }

    private getGridMode(options: IGridOptions) {
        if (!options) {
            return GridMode.off;
        }
        const { visible, magnetic } = options;
        if (visible && magnetic) {
            return GridMode.on;
        } else if (!visible && magnetic) {
            return GridMode.hidden;
        } else if (!visible && !magnetic) {
            return GridMode.off;
        } else {
            return null;
        }
    }
}
