import { DeltaOperation } from 'quill';
import {
    IQuillContent,
    IRichTextContentAdapterOf,
    IRichTextMentionData,
    RichTextContent,
} from '@datagalaxy/core-ui/rich-text';
import { RichTextMentionData } from './rich-text-mention.types';

/** Adapter for RichTextEditor and DxyRichTextFieldControl (which use Quill editor)
 * to use RichTextContent, with mention management */
export class QuillContentAdapter
    implements IRichTextContentAdapterOf<DeltaOperation[]>
{
    deserialize(rawTextOrJsonString: string): IQuillContent {
        return RichTextContent.fromJsonOrRawText(rawTextOrJsonString);
    }
    serialize(doc: IQuillContent): string {
        return doc instanceof RichTextContent ? doc.toJsonString() : undefined;
    }

    isEmpty(doc: IQuillContent): boolean {
        return doc instanceof RichTextContent
            ? doc.isEmpty()
            : doc == null
            ? true
            : undefined;
    }

    getMentionIds(doc: IQuillContent): string[] {
        return doc instanceof RichTextContent
            ? doc.getReferences().map((r) => r.Id)
            : [];
    }
    deleteMention(doc: IQuillContent, mentionId: string): void {
        if (doc instanceof RichTextContent) {
            doc.deleteReference(mentionId);
        }
    }
    addMention(doc: IQuillContent, mention: IRichTextMentionData): void {
        if (doc instanceof RichTextContent) {
            const m = mention as RichTextMentionData;
            doc.addReference(
                m.mentionId,
                m.mentionTypeName,
                m.objectId,
                m.objectTypeName
            );
        }
    }
}
