import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { IRouteNavLinkCellParams } from '../nav-link.types';
import { StateName } from '@datagalaxy/webclient/routing';
import { ICampaignIdentifier } from '@datagalaxy/dg-object-model';
import { DxyShowMoreButtonComponent } from '@datagalaxy/core-ui';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Link to navigate to a campaign
 */
@Component({
    selector: 'app-campaign-nav-link',
    templateUrl: './campaign-nav-link.component.html',
    styleUrls: ['../nav-link.scss', './campaign-nav-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        UIRouterModule,
        EllipsisTooltipDirective,
        DxyShowMoreButtonComponent,
    ],
})
export class CampaignNavLinkComponent extends BaseCellComponent<
    unknown,
    ICampaignIdentifier,
    IRouteNavLinkCellParams<ICampaignIdentifier>
> {
    constructor() {
        super();
    }

    @Input() campaignIdentifier: ICampaignIdentifier;
    @Input() routeStateName = StateName.CampaignDetails;
    @Input() disableNavigation = false;

    protected get displayName() {
        return this.campaignIdentifier.Name;
    }
    protected get navParams() {
        return {
            campaignId: this.campaignIdentifier.Guid,
            navid: this.campaignIdentifier.SpaceGuid,
            versionId: this.campaignIdentifier.VersionId,
        };
    }
    protected get iconButtonVisible() {
        return !this.buttonHidden && !this.showMoreStyle;
    }
    protected get showMoreButtonVisible() {
        return !this.buttonHidden && this.showMoreStyle;
    }
    protected get labelKey() {
        return this.params.labelKey;
    }

    private get showMoreStyle() {
        return this.params.showMoreStyle;
    }
    private get hideOnNavigationDisabled() {
        return this.params.hideOnNavigationDisabled;
    }
    private get buttonHidden() {
        return this.hideOnNavigationDisabled && this.disableNavigation;
    }

    protected override updateBindings() {
        this.campaignIdentifier = this.value;
        const stateName = this.params.routeStateName;
        if (stateName) {
            this.routeStateName = stateName;
        }
        this.disableNavigation = this.params.disableNavigation ?? false;
    }
}
