import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityDisplayNameService } from '../services/entity-display-name.service';
import { firstValueFrom } from 'rxjs';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';
import { EntityEventService } from '../services/entity-event.service';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { EntityDisplayNameComponent } from '../entity-display-name/entity-display-name.component';
import { NgIf } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-header-title',
    templateUrl: 'dxy-entity-header-title.component.html',
    styleUrls: ['dxy-entity-header-title.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        EntityDisplayNameComponent,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        FormsModule,
        TranslateModule,
    ],
})
export class DxyEntityHeaderTitleComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entity: EntityItem;
    @Input() readOnly?: boolean;

    protected readonly textMaxLength =
        ServerConstants.AttributeConstants.AttributeTextLimit;

    protected isEditEnabled = true;
    protected updatedEntityPrimaryName: string;
    protected get hasWriteAccess() {
        return this.entity?.SecurityData?.HasWriteAccess;
    }
    protected get remainingTitleDigits() {
        return `${
            this.textMaxLength - this.updatedEntityPrimaryName?.length || 0
        }/${this.textMaxLength}`;
    }
    protected get isEditingPrimaryName() {
        return this.isEditingTitle;
    }

    protected get isObsolete() {
        return this.entity.Status === EntityLifecycleStatus.Obsolete;
    }
    protected get obsoleteTooltipKey() {
        return this.isObsolete ? 'DgServerTypes.EntityStatus.Obsolete' : '';
    }

    protected get editButtonVisible() {
        return this.hasWriteAccess && !this.readOnly;
    }

    private isEditingTitle: boolean;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private entityDisplayNameService: EntityDisplayNameService,
        private entityEventService: EntityEventService
    ) {
        super();
    }

    ngOnInit() {
        this.isEditingTitle = false;
        super.registerSubscription(
            this.entityEventService.subscribeEntityUpdate(
                this.entity.ServerType,
                (entityItem) => this.onEntityUpdate(entityItem)
            )
        );
    }

    protected async onPrimaryNameEdit() {
        if (this.readOnly || !this.hasWriteAccess) {
            return;
        }

        this.isEditingTitle = !this.isEditingTitle;
        this.updatedEntityPrimaryName = await firstValueFrom(
            this.entityDisplayNameService.getDisplayName$(this.entity.HddData)
        );
        if (this.isEditingTitle) {
            setTimeout(() =>
                DomUtil.focusElement(this.elementRef, '[name="propertyTitle"]')
            );
        }
    }
    protected onClearPrimaryName() {
        this.isEditingTitle = false;
    }
    protected async onSubmitNewPrimaryName() {
        const previousNameValue = await firstValueFrom(
            this.entityDisplayNameService.getDisplayName$(this.entity.HddData)
        );
        // Never authorize empty name
        if (!this.updatedEntityPrimaryName) {
            this.updatedEntityPrimaryName = previousNameValue;
        } else if (this.updatedEntityPrimaryName != previousNameValue) {
            await this.entityDisplayNameService.updateDisplayName(
                this.entity,
                this.updatedEntityPrimaryName
            );
        }
        this.onClearPrimaryName();
    }

    private onEntityUpdate(entityItem: EntityItem) {
        if (EntityIdentifier.areSame(entityItem, this.entity)) {
            this.entity = entityItem;
        }
    }
}
