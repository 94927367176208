//#region usersettings
import { deserialize } from 'cerialize';

export class UserSettingCategoryValueDto {
    /** identifier of the value, unique in its category */
    @deserialize public Id?: string;
    @deserialize public Value?: string;
}

export class UserSettingCategoryRouteDto {
    /** identifier of the value, unique in its category */
    @deserialize public Id?: string;
    /** meta info (title, ...) of the value. May be null. Max-length: 512 */
    @deserialize public Meta?: string;
}

export class UserSettingsDto {
    /** in seconds */
    @deserialize public NotificationCleanupPeriod: number;
    @deserialize public UserLanguageCode: string;

    constructor(notificationCleanupPeriod: number, userLanguageCode: string) {
        this.NotificationCleanupPeriod = notificationCleanupPeriod;
        this.UserLanguageCode = userLanguageCode;
    }
}
