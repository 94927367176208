import { TSearchSettingsType } from './search-settings.service';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';

export class SearchSettingsNode {
    id: TSearchSettingsType;
    parentId?: TSearchSettingsType;
    level?: number;
    name?: string;
    isServerType?: boolean;
    selected?: boolean;
    collapsed?: boolean;
    /**
     * Specify if the node is here just to organize the tree
     * His value will not be taken in count
     */
    isPlaceholder?: boolean;
    nodes?: SearchSettingsNode[];
    path?: string;
}

export class SearchSettings {
    version: string;
    nodes: SearchSettingsNode[];
}

/**
 * To apply a modification on search settings
 * just update the version number, it will reset
 * the configuration.
 *
 * However, for version 2.31, we implement a small upgrade logic
 * just to include the new SubStructure subtype if we have a version 1.4 tree
 * See method SearchSettingsService.upgradeFromVersion14
 *
 * Since version 2.33, we implement an upgrade logic to keep the previously
 * selected/unselected items, the new items are selected if their parents are
 * selected
 *
 * Version History:
 * https://dev.azure.com/datagalaxy/_git/DataGalaxy?path=%2FDataGalaxyWebClient%2Fsrc%2Fapp%2Fsearch%2Fsearch-settings%2Fsearch-settings-tree.ts
 * 1.11: Rename DiagramNote EntityType to DiagramNode
 * 1.10.1: Migration script patch for 1.10
 * 1.10: EntityType.Diagram replaced by PhysicalDiagram
 * 1.9: Set Diagram root branch to serverType
 * 1.6: Added Usage component
 * 1.5: Added SubStructure under Table node
 * 1.4: Genericization of EntityTypes for the non FirstClassTypes (FilteredView, Pk, Fk, Diagram, etc.)
 * 1.3: Added FilteredView node (but with legacy name)
 * 1.2  Added missing nodes (Diagrams, PK, FK)
 */
export const searchSettingsTree: SearchSettings = {
    version: '1.11',
    nodes: [
        {
            id: EntityType.FilteredView,
        },
        {
            id: ServerType.Property,
            isServerType: true,
            nodes: [
                {
                    id: ServerType.Property,
                    isPlaceholder: true,
                    isServerType: true,
                    nodes: [
                        {
                            id: EntityType.Universe,
                        },
                        {
                            id: EntityType.Concept,
                        },
                        {
                            id: EntityType.BusinessTerm,
                        },
                        {
                            id: EntityType.IndicatorGroup,
                        },
                        {
                            id: EntityType.Indicator,
                        },
                        {
                            id: EntityType.Dimension,
                        },
                        {
                            id: EntityType.DimensionGroup,
                        },
                        {
                            id: EntityType.ReferenceData,
                        },
                        {
                            id: EntityType.ReferenceDataValue,
                        },
                    ],
                },
                {
                    id: EntityType.BusinessDomain,
                    isPlaceholder: true,
                    nodes: [
                        {
                            id: EntityType.BusinessDomain,
                        },
                        {
                            id: EntityType.BusinessDomainGroup,
                        },
                    ],
                },
            ],
        },
        {
            id: ServerType.DataProcessing,
            isServerType: true,
            nodes: [
                {
                    id: EntityType.DataFlow,
                },
                {
                    id: EntityType.DataProcessing,
                },
                {
                    id: EntityType.DataProcessingItem,
                },
            ],
        },
        {
            id: ServerType.SoftwareElement,
            isServerType: true,
            nodes: [
                {
                    id: ServerType.SoftwareElement,
                    isPlaceholder: true,
                    isServerType: true,
                    nodes: [
                        {
                            id: EntityType.Application,
                        },
                        {
                            id: EntityType.Screen,
                        },
                        {
                            id: EntityType.Feature,
                        },
                        {
                            id: EntityType.Process,
                        },
                        {
                            id: EntityType.Use,
                        },
                        {
                            id: EntityType.UsageField,
                        },
                        {
                            id: EntityType.UsageComponent,
                        },
                    ],
                },
                {
                    id: 'Analytics',
                    isPlaceholder: true,
                    isServerType: true,
                    nodes: [
                        {
                            id: EntityType.Dashboard,
                        },
                        {
                            id: EntityType.Report,
                        },
                        {
                            id: EntityType.Algorithm,
                        },
                        {
                            id: EntityType.UsageField,
                        },
                        {
                            id: EntityType.UsageComponent,
                        },
                    ],
                },
                {
                    id: EntityType.DataSet,
                    isPlaceholder: true,
                    nodes: [
                        {
                            id: EntityType.DataSet,
                        },
                        {
                            id: EntityType.OpenDataSet,
                        },
                        {
                            id: EntityType.UsageField,
                        },
                    ],
                },
            ],
        },
        {
            id: 'Dictionary',
            isPlaceholder: true,
            isServerType: true,
            nodes: [
                {
                    id: ServerType.Model,
                    isServerType: true,
                    nodes: [
                        {
                            id: EntityType.RelationalModel,
                        },
                        {
                            id: EntityType.NonRelationalModel,
                        },
                        {
                            id: EntityType.NoSqlModel,
                        },
                        {
                            id: EntityType.TagBase,
                        },
                    ],
                },
                {
                    id: ServerType.Container,
                    isServerType: true,
                    nodes: [
                        {
                            id: EntityType.Model,
                        },
                        {
                            id: EntityType.Directory,
                        },
                        {
                            id: EntityType.Equipment,
                        },
                    ],
                },
                {
                    id: ServerType.Table,
                    isServerType: true,
                    nodes: [
                        {
                            id: EntityType.Table,
                        },
                        {
                            id: EntityType.View,
                        },
                        {
                            id: EntityType.File,
                        },
                        {
                            id: EntityType.Document,
                        },
                        {
                            id: EntityType.Tag,
                        },
                        {
                            id: EntityType.SubStructure,
                        },
                    ],
                },
                {
                    id: EntityType.Field,
                    isPlaceholder: true,
                    nodes: [
                        {
                            id: EntityType.Column,
                        },
                        {
                            id: EntityType.Field,
                        },
                    ],
                },
                {
                    id: EntityType.PrimaryKey,
                    selected: false,
                },
                {
                    id: EntityType.ForeignKey,
                    selected: false,
                },
            ],
        },
        {
            id: ServerType.Diagram,
            isServerType: true,
            selected: false,
            nodes: [
                {
                    id: EntityType.PhysicalDiagram,
                    selected: false,
                },
                {
                    id: EntityType.FreeDiagram,
                    selected: false,
                },
                {
                    id: EntityType.DiagramNode,
                    selected: false,
                },
            ],
        },
    ],
};
