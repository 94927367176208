<dxy-spinner
    *ngIf="_loading$ | async"
    class="absolute-centered xl"
></dxy-spinner>

<div class="header" *ngIf="!config?.header?.hide">
    <div class="content">
        <div class="title">
            <dxy-search-input
                *ngIf="config?.header?.showSearch"
                class="no-border no-padding"
                placeholderKey="UI.Global.filter.filterByName"
                (onSearchString)="onSearchTermChange($event.searchString)"
            ></dxy-search-input>
            <ng-container *ngIf="totalCount$ | async as count">
                <ng-container *ngIf="!config?.header?.hideCounter">
                    <div
                        *ngIf="config?.multiSelect; else entitiesCount"
                        class="counter"
                    >
                        <span translate>UI.OmniGrid.lblSelectionCount</span> :
                        {{ selectedEntities?.length }} / {{ count }}
                    </div>
                    <ng-template #entitiesCount>
                        <span
                            translate
                            [translateParams]="{ count }"
                            class="counter"
                        >
                            UI.OmniGrid.lblTotalCount
                        </span>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
        <div class="sub-title">
            <div
                *ngIf="(totalCount$ | async) === 0"
                class="filters-too-many-results"
                translate
            >
                UI.Filter.noResultWithFilters
            </div>
            <div
                *ngIf="hasReachLimit$ | async"
                class="filters-too-many-results"
                translate
            >
                UI.Filter.tooManyResults
            </div>
        </div>
    </div>
    <div class="actions">
        <ng-content select="[actions]"></ng-content>
        <dxy-grid-column-selector
            *ngIf="config?.showColumnSelector"
            [enableSearch]="true"
            [forGrid]="entityGrid?.grid"
        ></dxy-grid-column-selector>
        <button
            *ngIf="!config?.header?.hideViewToggle"
            dxyIconButton
            color="secondary"
            class="hierachical-view-button"
            (click)="onToggleView()"
            [matTooltip]="viewTranslateKey | translate"
            matTooltipPosition="above"
            [dxyLogFunctional]="viewFeatureCode"
        >
            <span
                class="glyph"
                [class.glyph-view-tree]="view === 'flat'"
                [class.glyph-view-flat]="view === 'tree'"
            ></span>
        </button>
    </div>
</div>

<dxy-entity-grid
    [entities]="entities$ | async"
    [config]="gridConfig$ | async"
    (rowClick)="entityClick.emit($event)"
    (selectionChange)="onSelectionChange($event)"
    (attributeVisibilityChange)="onAttributeVisibilityChange($event)"
    (serverSideSort)="onServerSideSortChange($event)"
></dxy-entity-grid>
