import { deserialize, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@inheritSerialization(BaseDTO)
export class TechnologyDto {
    @deserialize DisplayName!: string;
    @deserialize TechnologyCode!: string;
    @deserialize Modules: Array<string> = [];
    @deserialize DescriptionFr?: string;
    @deserialize DescriptionEn?: string;
    @deserialize ImageHash?: string;
    @deserialize SvgImageHash?: string;
    @deserialize CreationTime?: string;
    @deserialize CreationUserId?: string;
    @deserialize LastModificationTime?: string;
    @deserialize LastModificationUserId?: string;
    @deserialize LastUsageTime?: string;
    @deserialize LastUsageUserId?: string;

    public dgModules!: DgModule[];
}
