<div class="section-box">
    <dxy-collapsible [titleKey]="getFormName()">
        <div class="comparison-item">
            <dxy-attribute-compare-item
                *ngFor="let it of itemInfos"
                [comparatorItemClient]="it.itemClient"
                [fieldInfo]="it.fieldInfo"
            ></dxy-attribute-compare-item>
        </div>
    </dxy-collapsible>
</div>
