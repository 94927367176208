import { BaseService } from '@datagalaxy/core-ui';
import { DialogType } from '@datagalaxy/ui/dialog';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { UserService } from '../services/user.service';
import { DxyUserProfileEditModalComponent } from './dxy-user-profile-edit-modal/dxy-user-profile-edit-modal.component';
import { DxyUserProfileChangePasswordModalComponent } from './dxy-user-profile-change-password-modal/dxy-user-profile-change-password-modal.component';
import { IUserProfileModalResult } from './models/IUserProfileModalResult';
import { IUserProfileModalResolve } from './models/IUserProfileModalResolve';
import { ViewTypeUtil } from '../shared/util/ViewTypeUtil';
import {
    CreateUserResult,
    PreDeleteUserResult,
    UserAdminData,
} from '@datagalaxy/webclient/user/data-access';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class UserUiService extends BaseService {
    public get userChange$() {
        return this.userChange.asObservable();
    }
    private userChange = new Subject<IChangedUser>();

    constructor(
        private translate: TranslateService,
        private dxyModalService: DxyModalService,
        private userService: UserService
    ) {
        super();
    }

    public async openUserProfileEditModal(
        adminUserData?: UserAdminData,
        isTrialInvite?: boolean
    ) {
        return await this.dxyModalService.open<
            DxyUserProfileEditModalComponent,
            IUserProfileModalResolve,
            IUserProfileModalResult
        >({
            componentType: DxyUserProfileEditModalComponent,
            data: {
                adminUserData,
                isUserCreation: !adminUserData,
                isTrialInvite,
            },
        });
    }

    public async openCreatedUserInformModal(createdUser: CreateUserResult) {
        return this.dxyModalService.inform({
            modalClass: 'dg_modalServerUrl',
            titleKey: 'UI.Users.createdUserUrlModal.title',
            message: this.translate.instant(
                'UI.Users.createdUserUrlModal.message',
                { url: createdUser.CreatedUserLoginUrl }
            ),
        });
    }

    public async openChangePasswordModal(adminUserData: UserAdminData) {
        await this.dxyModalService.open<
            DxyUserProfileChangePasswordModalComponent,
            IUserProfileModalResolve,
            void
        >({
            componentType: DxyUserProfileChangePasswordModalComponent,
            data: { adminUserData, isUserCreation: false },
            restoreFocus: false,
        });
    }

    public async openDeleteUserPrompt(currentUser: UserPublicData) {
        return this.dxyModalService.prompt({
            titleKey: 'UI.Users.Profile.delete.modal.title',
            message: this.translate.instant(
                'UI.Users.Profile.delete.modal.message',
                { email: currentUser.Email }
            ),
            userInputLabelKey: 'UI.Users.Profile.delete.modal.inputLabel',
            confirmButtonKey: 'UI.Users.Profile.delete.modal.btnConfirm',
            userInputValidationMethod: (value: string) =>
                value === currentUser?.Email,
        });
    }

    public async openSendPasswordResetMailModal(noMailing: boolean) {
        return this.dxyModalService.confirm({
            titleKey: this.getProfileTranslateKey(
                noMailing
                    ? 'dialogMailPasswordTitleNoMailing'
                    : 'dialogMailPasswordTitle'
            ),
            messageKey: this.getProfileTranslateKey(
                noMailing
                    ? 'dialogMailPasswordMessageNoMailing'
                    : 'dialogMailPasswordMessage'
            ),
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnSend',
        });
    }

    public async openPasswordResetInformModal(url: string) {
        return this.dxyModalService.inform({
            modalClass: 'dg_modalServerUrl',
            titleKey: this.getProfileTranslateKey(
                'resetPasswordUrlModal.title'
            ),
            message: this.translate.instant(
                this.getProfileTranslateKey('resetPasswordUrlModal.message'),
                { url }
            ),
        });
    }

    public openCantDeleteUserModal(result: PreDeleteUserResult) {
        const msg = this.getCantDeleteUserMessage(result);

        return this.dxyModalService.inform({
            type: DialogType.Close,
            message: msg,
            titleKey: 'UI.Users.Profile.preDeleteModal.title',
        });
    }

    public emitUserChange(userId: string, deleted = false) {
        this.userChange.next({
            userId,
            deleted,
        });
    }

    public getPublicUserData(userId: string): UserPublicData {
        return this.userService.getPublicUserData(userId);
    }

    public loadClientUserData() {
        return this.userService.loadClientUserData();
    }

    public getUserInsights(userId: string) {
        return this.userService.getUserInsights(userId);
    }

    public createUserFromModal(result: IUserProfileModalResult) {
        return this.userService.createUser(
            result.email,
            result.firstName,
            result.lastName,
            false,
            result.isClientAdministrator,
            result.licenseId,
            result.title,
            result.service,
            result.role
        );
    }

    public exportUsers(userIds?: string[]) {
        this.userService.exportUsers(userIds);
    }

    public async deleteUserProfileImage(userId: string): Promise<boolean> {
        const success = await this.userService.deleteUserProfileImage(userId);
        this.emitUserChange(userId);
        return success;
    }
    public async setUserProfileImage(
        userId: string,
        main: File,
        mini: File
    ): Promise<boolean> {
        const success = await this.userService.setUserProfileImage(
            userId,
            main,
            mini
        );
        this.emitUserChange(userId);
        return success;
    }

    private getProfileTranslateKey(name: string): string {
        return ViewTypeUtil.getTranslateKey(`UI.Users.Profile`, name);
    }

    private getCantDeleteUserMessage(result: PreDeleteUserResult) {
        let msg = '';

        if (result.AuthorizedClients?.length > 1) {
            msg += this.translate.instant(
                'UI.Users.Profile.preDeleteModal.usedInOtherClients'
            );
            msg += '<ul>';
            CollectionsHelper.orderBy(
                result.AuthorizedClients,
                (d) => d.DisplayName
            ).forEach((client) => {
                msg += `<li><b>${client.DisplayName}</b></li>`;
            });
            msg += '</ul>';
        }

        if (result.SpaceGovernanceDefaultUsages?.length) {
            msg += this.translate.instant(
                'UI.Users.Profile.preDeleteModal.defaultRole'
            );
            msg += '<ul>';
            const uniqueSpaces = CollectionsHelper.distinctByProperty(
                result.SpaceGovernanceDefaultUsages,
                (d) => d.SpaceDescriptor.DataReferenceId
            );
            CollectionsHelper.orderBy(
                uniqueSpaces,
                (d) => d.SpaceDescriptor.DisplayName
            ).forEach((space) => {
                msg += `<li><b>${space.SpaceDescriptor.DisplayName}</b></li>`;
            });
            msg += '</ul>';
        }

        if (result.AttributeUsages?.length) {
            msg += this.translate.instant(
                'UI.Users.Profile.preDeleteModal.usedInAttributes'
            );
            msg += '<ul>';
            const uniqueUsages = CollectionsHelper.distinctByProperty(
                result.AttributeUsages,
                (d) => d.AttributeKey
            );
            CollectionsHelper.orderBy(
                uniqueUsages,
                (d) => d.DisplayName
            ).forEach((attr) => {
                msg += `<li><b>${attr.DisplayName}</b></li>`;
            });
            msg += '</ul>';
        }

        return msg;
    }
}

export interface IChangedUser {
    userId: string;
    deleted?: boolean;
}
