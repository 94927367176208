import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BaseService } from '../base';
import { from, fromEvent, take, takeUntil } from 'rxjs';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategyOrigin,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay';
import { CoreUtil, ICancellableTimeout } from '@datagalaxy/core-util';
import { DxyBasePopoverComponent } from './DxyBasePopoverComponent';

export const DXY_POPOVER_DATA = new InjectionToken('DXY_POPOVER_DATA');

@Injectable({ providedIn: 'root' })
export class DxyPopoverService extends BaseService {
    public static readonly behaviour = {
        openingDelay: 1000,
        closingDelay: 1000,
        disableWhenPreviewPanelVisible: false,
    };

    /**
     * This is a temporary solution, we need to implement
     * a strategy position with preferred position like matTooltip
     * For now, popovers will be placed on top and fallback on bottom
     * if there is no places.
     * For x axe, we try to put it on right, then center, then left
     */
    private static readonly positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
        },
        {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
        },
        {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
        },
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        },
        {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
        },
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        },
    ];

    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay, private injector: Injector) {
        super();
    }

    public open<TComponent extends DxyBasePopoverComponent<TData>, TData>(
        settings: IPopoverSettings<TComponent, TData>
    ) {
        const target = settings.target;
        const delay =
            settings.openingDelay || DxyPopoverService.behaviour.openingDelay;

        const popupHostTimeout = CoreUtil.startCancellableTimeout(() => {
            popupHostTimeout?.cancel();
            this.openPopover(settings);
        }, delay);

        fromEvent(target, 'mouseleave')
            .pipe(take(1), takeUntil(from(popupHostTimeout.promise)))
            .subscribe(() => popupHostTimeout?.cancel());
    }

    public close() {
        this.overlayRef?.detach();
    }

    private openPopover<
        TComponent extends DxyBasePopoverComponent<TData>,
        TData
    >(settings: IPopoverSettings<TComponent, TData>) {
        const target = settings.target;

        if (!target?.parentElement) {
            return;
        }
        this.overlayRef?.detach();

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(target)
            .withPositions(DxyPopoverService.positions);
        const overlayRef = (this.overlayRef = this.overlay.create({
            positionStrategy: positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
            panelClass: 'dg5-popover',
        }));
        const popoverPortal = new ComponentPortal(
            settings.componentType,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    { provide: DXY_POPOVER_DATA, useValue: settings?.data },
                    { provide: OverlayRef, useValue: overlayRef },
                ],
            })
        );
        const componentRef = overlayRef.attach(popoverPortal);
        const delay =
            settings.closingDelay || DxyPopoverService.behaviour.closingDelay;
        const closeOverlay = () => {
            overlayRef.removePanelClass('open');
            // Wait for the animation duration
            setTimeout(() => overlayRef.detach(), 300);
        };
        let timeout: ICancellableTimeout<void>;

        setTimeout(() => overlayRef.addPanelClass('open'));

        fromEvent(target, 'mouseleave')
            .pipe(takeUntil(overlayRef.detachments()))
            .subscribe(() => {
                timeout?.cancel();
                timeout = CoreUtil.startCancellableTimeout(closeOverlay, delay);
            });

        fromEvent(componentRef.location.nativeElement, 'mouseenter')
            .pipe(takeUntil(overlayRef.detachments()))
            .subscribe(() => {
                timeout?.cancel();
            });

        fromEvent(componentRef.location.nativeElement, 'mouseleave')
            .pipe(takeUntil(overlayRef.detachments()))
            .subscribe(() => {
                timeout?.cancel();
                timeout = CoreUtil.startCancellableTimeout(closeOverlay, delay);
            });
    }

    public openGeneric<TComponent>(
        settings: IPopoverSettingsGeneric<TComponent>
    ) {
        this.close();
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(settings.origin)
            .withPositions(DxyPopoverService.positions);
        const overlayRef = (this.overlayRef = this.overlay.create({
            ...settings.config,
            positionStrategy,
        }));
        const componentRef = overlayRef.attach(
            new ComponentPortal(settings.componentType)
        );
        settings.withInstance?.(componentRef.instance);
    }
}

export interface IPopoverSettings<
    TComponent extends DxyBasePopoverComponent<TData>,
    TData
> {
    target: HTMLElement;
    componentType: ComponentType<TComponent>;
    openingDelay?: number;
    closingDelay?: number;
    data?: any;
}

export interface IPopoverSettingsGeneric<TComponent> {
    componentType: ComponentType<TComponent>;
    origin: FlexibleConnectedPositionStrategyOrigin;
    config?: OverlayConfig;
    withInstance?: (instance: TComponent) => void;
}
