import { Injectable } from '@angular/core';
import { BackendApiService, BaseServiceResult } from '@datagalaxy/data-access';
import {
    CreateEntityTaskParameter,
    CreateEntityTaskResult,
    DeleteEntityTasksParameter,
    GetEntityTasksParameter,
    GetEntityTasksResult,
    GetUserTasksParameter,
    UpdateEntityTaskParameter,
    UpdateEntityTaskResult,
} from './task';

@Injectable({ providedIn: 'root' })
export class TaskApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async createEntityTask(param: CreateEntityTaskParameter) {
        return await this.backendApiService.postPromise(
            'Social/CreateEntityTask',
            param,
            CreateEntityTaskResult
        );
    }

    public async getUserTasks(param: GetUserTasksParameter) {
        return await this.backendApiService.postPromise(
            'Social/GetUserTasks',
            param,
            GetEntityTasksResult
        );
    }

    public async getEntityTasks(param: GetEntityTasksParameter) {
        return await this.backendApiService.postPromise(
            'Social/GetEntityTasks',
            param,
            GetEntityTasksResult
        );
    }

    public async updateEntityTask(param: UpdateEntityTaskParameter) {
        return await this.backendApiService.postPromise(
            'Social/UpdateEntityTask',
            param,
            UpdateEntityTaskResult
        );
    }

    public async deleteEntityTasks(param: DeleteEntityTasksParameter) {
        return await this.backendApiService.postPromise(
            'Social/DeleteEntityTasks',
            param,
            BaseServiceResult
        );
    }
}
