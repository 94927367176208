import { IFilterFormAttributes } from './IFilterFormModel';
import { AttributeFilterService } from '../attribute-filter.service';
import { LogFn } from '@datagalaxy/core-util';
import { BaseAttributeFilterFormModel } from './BaseAttributeFilterFormModel';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export class ModuleAttributeFilterFormModel extends BaseAttributeFilterFormModel {
    constructor(
        public readonly dgZone: DgZone,
        public readonly dgModule: DgModule,
        public readonly spaceIdr: ISpaceIdentifier,
        public log: LogFn,
        formAttributes: IFilterFormAttributes
    ) {
        super(dgZone, spaceIdr, formAttributes, log);
    }

    protected setupAdditionalFilters() {
        if (!this?.entityTypeAttribute) {
            return;
        }

        let afm = AttributeFilterService.getFilterItem(
            this.filterItems,
            this.entityTypeAttribute.AttributeKey
        );

        if (!afm) {
            afm = this.addFilterFromAttribute(this.entityTypeAttribute);
            afm.operator = FilterOperator.ListContains;
            afm.isFormOpen = false;
        }
        afm.isDeletableItem = false;
    }
}
