import {
    Column,
    DataType,
    Table,
} from '@datagalaxy/webclient/modeler/data-access';

export class ForeignKeyColumnMapping {
    public get primaryKeyColumnId() {
        return this._primaryKeyColumn.ReferenceId;
    }

    public get primaryKeyColumnName() {
        return this._primaryKeyColumn.TechnicalName;
    }

    public get primaryKeyColumnTypeName() {
        return this._primaryKeyColumnType.DisplayName;
    }

    public get childColumnId() {
        return this.isNewColumn ? null : this._childColumn?.ReferenceId ?? null;
    }

    public get childColumnName() {
        return this.isNewColumn ? this._newColumnName : null;
    }

    public get isMappingSuccess() {
        return this._isMappingSuccess && !this._isUsedDuplicatedChildColumn;
    }

    public get isNewColumn() {
        return this._isNewColumn;
    }

    /** warning: setting this sets childColumn to null, even if value is false */
    public set isNewColumn(value: boolean) {
        //console.log('isNewColumn', value)
        this.initNewColumnName(value);
        this._childColumn = null;
        this._isNewColumn = value;
    }

    public get childColumn() {
        return this._childColumn;
    }

    public set childColumn(col: Column) {
        this._childColumn = col;
    }

    public get newColumnName() {
        return this._newColumnName;
    }

    public set newColumnName(value: string) {
        this._newColumnName = value;
    }

    private _isNewColumn: boolean;
    private _newColumnName: string;
    private _isMappingSuccess: boolean;
    private _isUsedDuplicatedChildColumn: boolean;

    private get hasChildColumn() {
        return !!this._childColumn;
    }

    private get primaryKeyColumnTypeId() {
        return this._primaryKeyColumnType.ReferenceId;
    }

    constructor(
        private _primaryKeyColumn: Column,
        private _primaryKeyColumnType: DataType,
        private _foreignKeyTable: Table,
        private _foreignKeyId: string,
        private _childColumn: Column,
        private enforceUniquenessForColumnTechnicalName: (
            newName: string,
            table: Table,
            currentColumn: Column
        ) => string
    ) {
        this._isNewColumn = !this.hasChildColumn;
        this.initNewColumnName(this._isNewColumn);
        this.initMappingState();
    }

    public isPrimaryKeyColumnTypeMatch(columnTypeId: string) {
        return this.primaryKeyColumnTypeId === columnTypeId;
    }

    public updateMappingValidity() {
        if (this.isNewColumn || this.hasChildColumn) {
            this.setMappingSuccess();
        } else {
            this.initMappingState();
        }
    }

    public updateMappingUnicity(columnMappings: ForeignKeyColumnMapping[]) {
        if (this.isNewColumn) {
            this._isUsedDuplicatedChildColumn = false;
        } else {
            const childColumnId = this.childColumnId;
            const primaryKeyColumnId = this.primaryKeyColumnId;
            const isUniqueMapping = columnMappings
                .filter(
                    (mapping) =>
                        mapping.primaryKeyColumnId != primaryKeyColumnId &&
                        !mapping.isNewColumn &&
                        mapping.hasChildColumn
                )
                .reduce(
                    (previousValue, mapping) =>
                        previousValue ||
                        mapping.childColumnId === childColumnId,
                    false
                );

            this._isUsedDuplicatedChildColumn = isUniqueMapping;
        }
    }

    public initMappingState() {
        this._isMappingSuccess = false;
    }

    private initNewColumnName(isNewColumn: boolean) {
        if (isNewColumn) {
            const defaultName = `${this._primaryKeyColumn.TechnicalName}_FK`;
            const newColumnName = this.enforceUniquenessForColumnTechnicalName(
                defaultName,
                this._foreignKeyTable,
                null
            );
            this._newColumnName = newColumnName;
        } else {
            this._newColumnName = null;
        }
    }

    private setMappingSuccess() {
        this._isMappingSuccess = true;
    }
}
