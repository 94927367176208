export interface IAttributeUpdateTranslationInputs {
    attributeNameKey: string;
    previousValue?: IAttributeTranslationValue;
    value?: IAttributeTranslationValue;
}

export class AttributeUpdatesTranslation {
    public updates: IAttributeUpdateTranslationInputs[] = [];
    constructor() {}
}

export interface IAttributeTranslationValue {
    text?: string;
    translateKey?: string;
    userGuid?: string;
    richText?: string;
}
