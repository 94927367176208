import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ImportHistory } from '@datagalaxy/webclient/connectivity/data-access';

@Injectable({ providedIn: 'root' })
export class ConnectorStateService {
    private readonly savedConnectionsRefreshCallSource = new Subject<void>();
    private readonly listFieldUpdateCallSource = new Subject<void>();
    private readonly importUpdateCallSource = new Subject<ImportHistory>();

    public get savedConnectionsRefreshCalled$() {
        return this.savedConnectionsRefreshCallSource.asObservable();
    }

    public refreshSavedConnections() {
        this.savedConnectionsRefreshCallSource.next();
    }

    public get listFieldUpdateCalled$() {
        return this.listFieldUpdateCallSource.asObservable();
    }

    public listFieldUpdated() {
        this.listFieldUpdateCallSource.next();
    }

    public get importUpdateCalled$() {
        return this.importUpdateCallSource.asObservable();
    }

    public notifyImportUpdate(importUpdate: ImportHistory) {
        this.importUpdateCallSource.next(importUpdate);
    }
}
