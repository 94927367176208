import { CollectionsHelper } from '@datagalaxy/core-util';
import { ActivityLogEntryDataTypeName } from '../activityLog.constants';
import { IActivityLogEntryTranslationData } from './activity-log-entry-translation.type';
import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import { ServerType } from '@datagalaxy/dg-object-model';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    IDiagramEdgeVisualData,
    IDiagramNodeVisualData,
    IDiagramVisualData,
} from '../../diagrams/diagram/diagram.types';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

/**
 * ## Role
 * Activity log adapter for logs concerning diagrams
 */
export class DiagramLogEntryAdapter extends DefaultLogEntryAdapter {
    protected baseDiagramTranslateKey = 'UI.ActivityLogData.DiagramEntries.';

    public override getEntryTranslationData(
        entry: ActivityLogEntry
    ): IActivityLogEntryTranslationData {
        const data = super.getEntryTranslationData(entry);
        switch (entry.EntryType) {
            case ActivityLogEntryType.Update:
                this.setUpdateData(entry, data);
                break;
            case ActivityLogEntryType.AddObject:
                this.setAddObjectData(entry, data);
                break;
            case ActivityLogEntryType.DeleteObject:
                this.setDeleteObjectData(entry, data);
                break;
        }

        return data;
    }

    protected override getChildHData(entry: ActivityLogEntry) {
        const child = this.findFirstNonDiagramParent(entry);
        const hdd = this.getUsedDataDescriptor(entry, child);
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }
    protected override setUpdateData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        if (
            entry.Data.DataTypeName == ActivityLogEntryDataTypeName.diagramNote
        ) {
            data.translateKey = `${this.baseDiagramTranslateKey}updateNote`;
            return;
        }
        if (
            entry.Data.DataTypeName ==
                ActivityLogEntryDataTypeName.diagramNode &&
            !data.navLinkData.childHData
        ) {
            data.translateKey = `${this.baseDiagramTranslateKey}updateNode`;
            return;
        }
        if (entry.PropertyName == 'VisualData') {
            switch (entry.Data.DataTypeName) {
                case ActivityLogEntryDataTypeName.diagramNode:
                    return this.setDiagramNodeVisualData(entry, data);
                case ActivityLogEntryDataTypeName.diagramEdge:
                    return this.setDiagramEdgeVisualData(entry, data);
                case ServerType[ServerType.Diagram]:
                    return this.setDiagramVisualData(entry, data);
            }
        }
        super.setUpdateData(entry, data);
        if (
            entry.Data.DataTypeName == ActivityLogEntryDataTypeName.diagramEdge
        ) {
            const key =
                entry.PropertyName == ServerConstants.PropertyName.DisplayName
                    ? 'renameEdge'
                    : 'editEdge';
            data.translateKey = `${this.baseDiagramTranslateKey}${key}`;
        }
    }

    protected override getHData(entry: ActivityLogEntry) {
        const parent =
            entry.Data?.ServerType == ServerType.Diagram
                ? entry.Data
                : entry.Parents[0];
        const hdd = this.getUsedDataDescriptor(entry, parent);
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }

    private findFirstNonDiagramParent(entry: ActivityLogEntry) {
        const excludedServerTypes = [
            ServerType[ServerType.Diagram],
            ServerType[ServerType.Project],
        ];
        return entry.Parents.find(
            (hdd) => !excludedServerTypes.includes(hdd.DataTypeName)
        );
    }

    private setAddObjectData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        switch (entry.ChildData?.DataTypeName) {
            case ActivityLogEntryDataTypeName.diagramNode:
                data.translateKey = data.navLinkData.childHData
                    ? `${this.baseDiagramTranslateKey}addReference`
                    : `${this.baseDiagramTranslateKey}addNode`;
                break;
            case ActivityLogEntryDataTypeName.diagramEdge:
                this.setAddEdgeData(entry, data);
                break;
            case ActivityLogEntryDataTypeName.diagramNote:
                data.translateKey = `${this.baseDiagramTranslateKey}addNote`;
                break;
        }
    }

    private setDeleteObjectData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        switch (entry.ChildData?.DataTypeName) {
            case ActivityLogEntryDataTypeName.diagramNode:
                data.translateKey = data.navLinkData.childHData
                    ? `${this.baseDiagramTranslateKey}deleteReference`
                    : `${this.baseDiagramTranslateKey}deleteNode`;
                break;
            case ActivityLogEntryDataTypeName.diagramEdge:
                data.translateKey = `${this.baseDiagramTranslateKey}removeLink`;
                break;
            case ActivityLogEntryDataTypeName.diagramNote:
                data.translateKey = `${this.baseDiagramTranslateKey}deleteNote`;
                break;
        }
    }

    private setDiagramNodeVisualData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        const oldValue: IDiagramNodeVisualData = JSON.parse(
            entry.OldValueString
        );
        const newValue: IDiagramNodeVisualData = JSON.parse(
            entry.NewValueString
        );
        const isColor = newValue.color != oldValue?.color;
        const isLock = newValue.locked != oldValue?.locked;
        const isFontSize = newValue.fontSize != oldValue?.fontSize;
        if (isColor) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeNodeColor`;
            this.setColorUpdateData(data, oldValue?.color, newValue?.color);
        } else if (isLock) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeNodeLock`;
            data.translateParams.newValue = newValue.locked ? 'true' : 'false';
        } else if (isFontSize) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeNodeSize`;
        }
    }

    private setDiagramEdgeVisualData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        const oldValue: IDiagramEdgeVisualData = JSON.parse(
            entry.OldValueString
        );
        const newValue: IDiagramEdgeVisualData = JSON.parse(
            entry.NewValueString
        );

        if (!newValue) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeVisualData`;
            return;
        }

        const isColor = newValue.color != oldValue?.color;
        const isThickness = newValue.thickness != oldValue?.thickness;
        const isLineStyle = newValue.lineStyle != oldValue?.lineStyle;
        const isArrowType = newValue.arrowType != oldValue?.arrowType;

        if (isColor) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeEdgeColor`;
            this.setColorUpdateData(data, oldValue?.color, newValue?.color);
        } else if (isThickness) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeEdgeThickness`;
        } else if (isLineStyle) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeEdgeLineStyle`;
        } else if (isArrowType) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeEdgeArrowType`;
        }
    }

    private setDiagramVisualData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        const oldValue: IDiagramVisualData = JSON.parse(entry.OldValueString);
        const newValue: IDiagramVisualData = JSON.parse(entry.NewValueString);

        const isExtraData = CollectionsHelper.anyDifference(
            newValue.extraData,
            oldValue?.extraData
        );
        const isGrid = newValue.grid != oldValue?.grid;
        if (isExtraData) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeExtraData`;
        } else if (isGrid) {
            data.translateKey = `${this.baseDiagramTranslateKey}changeGrid`;
        } else {
            data.translateKey = `${this.baseDiagramTranslateKey}changeVisualData`;
        }
    }

    private setAddEdgeData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData
    ) {
        data.translateKey = `${this.baseDiagramTranslateKey}addLink`;
        data.translateParams.entityLinkName = this.getEntityLinkName(entry);

        const source = this.getHDataById(entry, entry.EntityLinkSourceId);
        const target = this.getHDataById(entry, entry.EntityLinkTargetId);
        data.translateParams.parentDataName = source?.DisplayName;
        data.translateParams.childDataName = target?.DisplayName;

        data.navLinkData.hdata = source;
        data.navLinkData.childHData = target;
    }

    private setColorUpdateData(
        data: IActivityLogEntryTranslationData,
        oldColor?: GraphicalColor,
        newColor?: GraphicalColor
    ) {
        data.translateParams.oldValue = oldColor
            ? GraphicalColor[oldColor.toString()]
            : GraphicalColor._none;
        data.translateParams.newValue = newColor
            ? GraphicalColor[newColor.toString()]
            : GraphicalColor._none;
    }
}
