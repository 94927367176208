import { IConnectorRouter } from '../routing.types';
import { ConnectorEndpoint } from '../../endpoint';
import { Point } from '@datagalaxy/core-2d-util';

export class StraightRouter implements IConnectorRouter {
    computePoints(src: ConnectorEndpoint, tgt: ConnectorEndpoint): Point[] {
        const start = src.updatePosition();
        const end = tgt.updatePosition();
        const distance = Math.abs(start.x - end.x);
        const stubMargin = Math.max(distance / 2.5, 20);

        return [
            start,
            src.getStubPoint(stubMargin),
            tgt.getStubPoint(stubMargin),
            end,
        ];
    }
}
