import { IXYRO, Point } from '@datagalaxy/core-2d-util';
import { IOrthogonalPathOptions } from '../../routing';
import { TEdgeConnectorType } from '../../connectors.types';

export interface IPathBuilder {
    computePathD(route: IXYRO[], opt?: TPathBuilderOptions): string;
}

export enum PathCurve {
    None = 0,
    Bezier,
}

type DistributiveOmit<T, K extends keyof any> = T extends any
    ? Omit<T, K>
    : never;

export type ConnectorPathOptions = DistributiveOmit<
    TPathBuilderOptions,
    'arrowPosition' | 'inverted'
>;

export interface PathBuilderOptions {
    routing: TEdgeConnectorType;
    /**
     * Defines the starting point of the arrow. This is utilized to halt the path's
     * rendering before reaching the arrow, thus not extending to the target's end.
     * To be used with inverted property if the arrow should be drawn at the start
     */
    arrowPosition?: Point;
    /**
     * Specifies if the path should be inverted. This is used in conjunction with
     * the arrow to control the path's drawing. If inverted, the path starts after
     * the arrow. Otherwise, it stops before the arrow.
     */
    inverted?: boolean;
}

export type TPathBuilderOptions =
    | StraightPathBuilderOptions
    | IOrthogonalPathOptions;

export interface StraightPathBuilderOptions extends PathBuilderOptions {
    routing: 'straight';
    curve?: PathCurve;
}
