import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { UserDto } from '@datagalaxy/webclient/user/domain';

export enum UpdateMembershipRequestType {
    Accept,
    Reject,
    AcceptAll,
    RejectAll,
}
SerializableEnumeration(UpdateMembershipRequestType);

@inheritSerialization(UserDto)
export class TeamMembershipRequest extends UserDto {
    @deserialize TeamUid!: string;
}

@inheritSerialization(UserDto)
export class TeamMemberDto extends UserDto {
    @deserialize IsTeamOwner!: boolean;
    @deserialize CreationTime!: string;
    @deserialize Role!: string;
    @deserialize Service!: string;
    @deserialize Title!: string;
}
