import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { SpaceCache } from './SpaceCache';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { NavigationEventService } from '../../navigation/services/navigation-event.service';
import { DataIdentifier } from '@datagalaxy/dg-object-model';
import {
    CheckSpaceTrigramValidityParameter,
    CreateSpaceArticleParameter,
    CreateSpaceParameter,
    DeleteOrganizationParameter,
    DeleteProjectParameter,
    DeleteSpaceArticleParameter,
    GetSpaceAdministrationUsersParameter,
    GetSpaceArticlesParameter,
    GetSpaceUsersParameter,
    IHasSpaceIconData,
    LoadOrganizationParameter,
    LoadProjectParameter,
    NavOrganization,
    NavProject,
    Organization,
    Project,
    Space,
    SpaceArticleDto,
    SpaceSecurityProfileType,
    UpdateSpaceArticleParameter,
    UpdateSpaceGovernanceUserParameter,
    UpdateSpaceParameter,
    WorkSpaceApiService,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    ImageCategory,
    SetImageParameter,
} from '@datagalaxy/webclient/client/data-access';
import { ClientService } from '../../client/client.service';
import { getContextId } from '@datagalaxy/webclient/utils';
import { UserAdminData } from '@datagalaxy/webclient/user/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    ISpaceIdentifier,
    SpaceGovernanceUserDto,
} from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class SpaceApiService extends BaseService {
    public get debug() {
        return super.debug;
    }
    public set debug(value: boolean) {
        super.debug = value;
        if (this.spaceCache) {
            this.spaceCache.debug = value;
        }
    }

    private spaceCache: SpaceCache<Space>;

    constructor(
        private clientService: ClientService,
        private workspaceApiService: WorkSpaceApiService,
        private currentSpaceService: CurrentSpaceService,
        private navigationEventService: NavigationEventService
    ) {
        super();
        this.spaceCache = new SpaceCache<Space>(
            (spaceIdr) => this._getSpace(spaceIdr),
            60,
            this.debug
        );
        this.navigationEventService._deleteSpace$.subscribe((dataIdr) =>
            this.spaceCache.deleteGroup(dataIdr.DataReferenceId)
        );

        this.navigationEventService._updateSpace$.subscribe((navSpace) =>
            this.spaceCache.deleteGroup(navSpace.spaceId)
        );

        this.navigationEventService._securityRightsChange$.subscribe(() =>
            this.spaceCache.clear()
        );
    }

    public clearSpaceCache(spaceIdr: SpaceIdentifier) {
        this.spaceCache.deleteGroup(spaceIdr.spaceId);
    }

    public getSpace(spaceIdr: ISpaceIdentifier) {
        return this.spaceCache.get(spaceIdr);
    }

    private async _getSpace(spaceIdr: ISpaceIdentifier): Promise<Space> {
        return spaceIdr
            ? spaceIdr.versionId
                ? await this._getProject(spaceIdr)
                : await this._getOrganization(spaceIdr)
            : (null as Space);
    }

    private async _getProject(spaceIdr: ISpaceIdentifier) {
        const param = new LoadProjectParameter(
            spaceIdr.spaceId,
            spaceIdr.versionId
        );
        try {
            const result = await this.workspaceApiService.loadProject(param);
            return result?.Project;
        } catch (err) {
            this.log(
                '_getProject-err',
                spaceIdr,
                err.status,
                err.xhrStatus,
                err
            );
            if (err.status != 404) {
                console.error(err);
            }
            return null as Project;
        }
    }

    private async _getOrganization(spaceIdr: ISpaceIdentifier) {
        const parameter = new LoadOrganizationParameter(spaceIdr.spaceId);
        const result = await this.workspaceApiService.loadOrganization(
            parameter
        );
        return result.Organization;
    }

    //#region Project management API

    public async createProject(
        organizationId: string,
        projectName: string,
        projectTrigram: string,
        projectDescription: string,
        defaultDataOwnerUserId: string,
        defaultDataStewardUserId: string,
        securityProfileType: SpaceSecurityProfileType
    ) {
        const param = new CreateSpaceParameter(
            organizationId,
            projectName,
            projectTrigram,
            projectDescription,
            defaultDataOwnerUserId,
            defaultDataStewardUserId,
            securityProfileType
        );
        const result = await this.workspaceApiService.createProject(param);
        this.navigationEventService.notifyCreateSpaceEvent(
            result.Project.getNavProject()
        );
        return result.Project;
    }

    // TODO : Refactor legacy code
    public async updateProject(
        projectReferenceId: string,
        newDisplayName: string,
        newTrigram: string,
        newDescription: string,
        isDefaultSpace = false,
        spaceSecurityProfileType: SpaceSecurityProfileType = null
    ): Promise<NavProject> {
        const parameter = new UpdateSpaceParameter(
            projectReferenceId,
            newDisplayName,
            newTrigram,
            newDescription,
            isDefaultSpace,
            spaceSecurityProfileType
        );
        const result = this.workspaceApiService.updateSpace(parameter);
        const navProject = new NavProject();
        navProject.ReferenceId = projectReferenceId;
        navProject.Trigram = newTrigram;
        navProject.DisplayName = newDisplayName;
        navProject.Description = newDescription;
        navProject.SecurityProfileType = spaceSecurityProfileType;
        this.navigationEventService.notifyUpdateSpaceEvent(navProject);
        this.log('updateProject-result', result);
        return navProject;
    }

    public async deleteProject(parentId: string, projectId: string) {
        const param = new DeleteProjectParameter(parentId, projectId);
        await this.workspaceApiService.deleteProject(param);
        this.navigationEventService.notifyDeleteSpaceEvent(
            new DataIdentifier(projectId, ServerType[ServerType.Project])
        );
    }

    public async checkTrigramValidity(spaceId: string, trigram: string) {
        return await this.workspaceApiService.CheckSpaceTrigramValidity(
            new CheckSpaceTrigramValidityParameter(spaceId || null, trigram)
        );
    }

    public async preCreateSpace(
        parentId: string,
        name: string,
        trigram: string,
        descriptionId: string,
        defaultOwnerId: string,
        defaultStewardId: string
    ) {
        const createSpaceParameter = new CreateSpaceParameter(
            parentId,
            name,
            trigram,
            descriptionId,
            defaultOwnerId,
            defaultStewardId
        );
        return await this.workspaceApiService.preCreateSpace(
            createSpaceParameter
        );
    }

    public async deleteSpaceImage(
        spaceId: string,
        imageCategory: ImageCategory
    ) {
        return this.setSpaceImageInternal(spaceId, imageCategory, true);
    }
    public async setSpaceImage(
        file: File,
        spaceId: string,
        imageCategory: ImageCategory
    ) {
        return this.setSpaceImageInternal(spaceId, imageCategory, false, file);
    }
    private async setSpaceImageInternal(
        spaceId: string,
        imageCategory: ImageCategory,
        isDelete: boolean,
        file?: File
    ) {
        let param: SetImageParameter;
        if (isDelete) {
            param = SetImageParameter.forDelete(imageCategory, spaceId);
        } else {
            const fileContent = await CoreUtil.readAsDataUrl(file);
            param = new SetImageParameter(
                fileContent,
                file.name,
                file.type,
                imageCategory,
                spaceId
            );
        }
        const result = await this.clientService.setImage(param);
        this.currentSpaceService.setCurrentSpaceImageHash(
            result.ImageHash,
            imageCategory
        );
        return true;
    }

    public getSpaceIconUrl(space: IHasSpaceIconData) {
        const hash = space?.IconHash;
        if (!hash) {
            return;
        }
        return this.clientService.getImageUrl(hash);
    }
    //#endregion

    //#region Organization management API

    // TODO : Refactor legacy code (for loading)
    public async createOrganization(
        parentId: string,
        organizationName: string,
        organizationTrigram: string,
        organizationDescription: string,
        defaultDataOwnerUserId: string,
        defaultDataStewardUserId: string,
        securityProfileType: SpaceSecurityProfileType
    ): Promise<Organization> {
        const param = new CreateSpaceParameter(
            parentId,
            organizationName,
            organizationTrigram,
            organizationDescription,
            defaultDataOwnerUserId,
            defaultDataStewardUserId,
            securityProfileType
        );
        const result = await this.workspaceApiService.createOrganization(param);
        this.navigationEventService.notifyCreateSpaceEvent(
            result.Organization.getNavOrganization()
        );
        return result.Organization;
    }

    public async updateOrganization(
        organizationReferenceId: string,
        newDisplayName: string,
        newTrigram: string,
        newDescription: string,
        isDefaultSpace = false,
        spaceSecurityProfileType: SpaceSecurityProfileType = null
    ) {
        const parameter = new UpdateSpaceParameter(
            organizationReferenceId,
            newDisplayName,
            newTrigram,
            newDescription,
            isDefaultSpace,
            spaceSecurityProfileType
        );
        await this.workspaceApiService.updateSpace(parameter);
        let navOrg = new NavOrganization();
        navOrg.ReferenceId = organizationReferenceId;
        navOrg.DisplayName = newDisplayName;
        navOrg.Trigram = newTrigram;
        navOrg.Description = newDescription;
        navOrg.SecurityProfileType = spaceSecurityProfileType;
        this.navigationEventService.notifyUpdateSpaceEvent(navOrg);
        return navOrg;
    }

    public async deleteOrganization(parentId: string, organizationId: string) {
        const param = new DeleteOrganizationParameter(parentId, organizationId);
        const result = await this.workspaceApiService.deleteOrganization(param);
        result.DeletedSpaces.forEach((deletedSpace) =>
            this.navigationEventService.notifyDeleteSpaceEvent(
                deletedSpace.Data
            )
        );
    }
    //#endregion

    public async createNavSpace(
        serverType: ServerType,
        parentSpaceId: string,
        displayName: string,
        trigram: string,
        description: string,
        defaultOwnerId: string,
        defaultStewardId: string,
        securityProfile
    ) {
        switch (serverType) {
            case ServerType.Organization: {
                const orga = await this.createOrganization(
                    parentSpaceId,
                    displayName,
                    trigram,
                    description,
                    defaultOwnerId,
                    defaultStewardId,
                    securityProfile
                );
                return orga.getNavOrganization();
            }
            case ServerType.Project: {
                const proj = await this.createProject(
                    parentSpaceId,
                    displayName,
                    trigram,
                    description,
                    defaultOwnerId,
                    defaultStewardId,
                    securityProfile
                );
                return proj.getNavProject();
            }
            default:
                // spaces have two types..
                throw new Error(
                    `Type must be either: ${
                        ServerType[ServerType.Project]
                    } or ${ServerType[ServerType.Organization]}`
                );
        }
    }

    public async updateNavSpace(
        serverType: ServerType,
        spaceId: string,
        isDefaultSpace: boolean,
        displayName: string,
        trigram: string,
        description: string,
        securityProfile
    ) {
        switch (serverType) {
            case ServerType.Organization:
                return await this.updateOrganization(
                    spaceId,
                    displayName,
                    trigram,
                    description,
                    isDefaultSpace,
                    securityProfile
                );
            case ServerType.Project:
                return await this.updateProject(
                    spaceId,
                    displayName,
                    trigram,
                    description,
                    isDefaultSpace,
                    securityProfile
                );
            default:
                // spaces have two types..
                throw new Error(
                    `Type must be either: ${
                        ServerType[ServerType.Project]
                    } or ${ServerType[ServerType.Organization]}`
                );
        }
    }

    public async updateDefaultSpace(
        spaceIdr: ISpaceIdentifier,
        isDefault: boolean
    ) {
        const param = new UpdateSpaceParameter(
            spaceIdr.spaceId,
            null,
            null,
            null,
            isDefault
        );
        return this.workspaceApiService.updateDefaultSpace(param);
    }

    public async updateSpaceGovernanceUser(
        spaceIdr: ISpaceIdentifier,
        addUserList: string[],
        deleteUserList: string[],
        defaultUserId: string,
        attributeKey: string
    ) {
        const param = new UpdateSpaceGovernanceUserParameter(
            addUserList,
            deleteUserList,
            defaultUserId,
            attributeKey,
            spaceIdr.spaceId
        );
        param.setVersionId(spaceIdr?.spaceId);
        return this.workspaceApiService.updateSpaceGovernanceUser(param);
    }

    public async getSpaceUsers(
        spaceIdr: ISpaceIdentifier,
        includeRoleData = false
    ) {
        const param = new GetSpaceUsersParameter();
        param.SpaceGuid = getContextId(spaceIdr?.spaceId);
        param.IncludeRoleData = includeRoleData;
        return await this.workspaceApiService.getSpaceUsers(param);
    }

    public async getSpaceAdministrationUsers(
        spaceIdr: ISpaceIdentifier
    ): Promise<UserAdminData[]> {
        const param = new GetSpaceAdministrationUsersParameter();
        param.SpaceGuid = getContextId(spaceIdr?.spaceId);
        const res = await this.workspaceApiService.getSpaceAdministrationUsers(
            param
        );
        return res?.SpaceUsers;
    }

    /** This is the source list for the Space Governance Users management : All (non-deleted) users are valid candidates */
    public async getUsersDataAsSpaceGovDtos(spaceIdr: ISpaceIdentifier) {
        const res = await this.getSpaceAdministrationUsers(spaceIdr);
        return res
            .filter((user) => !user.IsSupport && !user.IsToken)
            ?.map(
                (user) =>
                    new SpaceGovernanceUserDto(
                        user.ReferenceId,
                        user.FirstName,
                        user.LastName,
                        user.Email,
                        user.UserId,
                        false,
                        '',
                        user.ProfileImageHash,
                        user.ProfileThumbnailHash
                    )
            );
    }

    public forceReloadSpace(currentRoot: Space) {
        this.spaceCache.deleteGroup(currentRoot.spaceId);
        return this.getSpace(currentRoot);
    }

    public getArticleImage(article: SpaceArticleDto) {
        return this.clientService.getImageUrl(article?.ImageHash);
    }

    public async getArticles(
        spaceIdr: ISpaceIdentifier,
        limitTo = 10
    ): Promise<SpaceArticleDto[]> {
        const param = new GetSpaceArticlesParameter();
        param.SpaceGuid = getContextId(spaceIdr.spaceId);
        param.VersionId = spaceIdr.versionId;
        param.Size = limitTo;

        const res = await this.workspaceApiService.getSpaceArticles(param);

        return res?.Articles;
    }

    public async createArticle(
        spaceIdr: ISpaceIdentifier,
        title?: string,
        description?: string,
        file?: File
    ): Promise<SpaceArticleDto> {
        const param = new CreateSpaceArticleParameter();
        const fileContent = file ? await CoreUtil.readAsDataUrl(file) : null;
        param.SpaceGuid = getContextId(spaceIdr.spaceId);
        param.VersionId = spaceIdr.versionId;
        param.Title = title;
        param.Description = description;
        param.FileName = file?.name;
        param.FileType = file?.type;
        param.FileContent = fileContent;
        const res = await this.workspaceApiService.createSpaceArticle(param);

        return res?.Article;
    }

    public async updateArticle(
        articleId: string,
        spaceIdr: ISpaceIdentifier,
        title?: string,
        description?: string,
        file?: File,
        deleteImage?: boolean
    ): Promise<SpaceArticleDto> {
        const param = new UpdateSpaceArticleParameter();
        const fileContent = file ? await CoreUtil.readAsDataUrl(file) : null;
        param.ArticleUid = articleId;
        param.SpaceGuid = getContextId(spaceIdr.spaceId);
        param.VersionId = spaceIdr.versionId;
        param.Title = title;
        param.Description = description;
        param.FileName = file?.name;
        param.FileType = file?.type;
        param.FileContent = fileContent;
        param.IsDelete = deleteImage;
        const res = await this.workspaceApiService.updateSpaceArticle(param);

        return res?.Article;
    }

    public async deleteArticle(articleId: string): Promise<void> {
        const param = new DeleteSpaceArticleParameter();
        param.ArticleUid = articleId;
        await this.workspaceApiService.deleteSpaceArticle(param);
    }
}
