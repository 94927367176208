import { ITranslationResource } from 'ngx-translate-multi-http-loader';

export interface ITranslationConfig {
    locales: string[];
    defaultLang: string;
    resources: ITranslationResource[];
    buildRandomKey: string;
    preferedLang?: string;
    messageFormatPatch?: IMessageFormatPatchConfig;
    doLog?: boolean;
    onError?: (error: Error, cause: string, type: string) => void;
}

export interface IMessageFormatPatchConfig {
    doReplaceShortcutAll?: boolean;
    doLog?: boolean;
    doCheckAllOnError?: boolean;
}

/** Translation configuration, with initial default values.
 * Overwritten by DXY_TRANSLATE_CONFIG provider */
export const defaultTranslationConfig: ITranslationConfig = {
    locales: ['en', 'fr'],
    defaultLang: 'en',
    resources: [
        {
            prefix: '/i18n/',
            suffix: '.json',
        },
    ],
    buildRandomKey: '',
    messageFormatPatch: {
        doReplaceShortcutAll: true,
        doLog: false,
        doCheckAllOnError: false,
    },
};
