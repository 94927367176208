import { CoreUtil, ICancellableTimeout } from '@datagalaxy/core-util';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '../base';

/**
    #Archi-service-leaf (Does not reference any other app service)
*/
@Injectable({ providedIn: 'root' })
export class UiSpinnerService extends BaseService {
    public isSpinnerActive$ = new BehaviorSubject<boolean>(false);
    public translatedMessage$ = new BehaviorSubject<string>('');

    private readonly activateTimeoutDelay = 1500;
    private timer: ICancellableTimeout<void>;

    constructor(private translate: TranslateService) {
        super();
    }

    public async executeWithSpinner<T>(
        fn: () => Promise<T>,
        tradKey?: string,
        throwOnError = false,
        timeoutDelay = this.activateTimeoutDelay
    ) {
        this.internalStartSpinner(tradKey, timeoutDelay);
        try {
            return await fn();
        } catch (e) {
            if (throwOnError) {
                throw e;
            }
            this.warn(e);
        } finally {
            this.stopSpinner();
        }
    }

    public setActiveSpinner<T>(
        tradKey?: string,
        promise?: Promise<T>,
        timeoutDelay = this.activateTimeoutDelay
    ) {
        this.internalStartSpinner(tradKey, timeoutDelay);
        promise?.finally(() => this.stopSpinner());
    }

    public stopSpinner() {
        this.log('Stopping spinner');
        this.isSpinnerActive$.next(false);
        this.timer?.cancel();
        this.timer = null;
    }

    private internalStartSpinner(
        tradKey?: string,
        timeoutDelay: number = this.activateTimeoutDelay
    ) {
        if (this.isSpinnerActive$.value || this.timer) {
            this.log('Start Spinner Aborted: Already Active!');
            return;
        }

        this.log('Starting Spinner');
        this.timer = CoreUtil.startCancellableTimeout(() => {
            this.isSpinnerActive$.next(true);
        }, timeoutDelay);
        const message = tradKey ? this.translate.instant(tradKey) : '';
        this.translatedMessage$.next(message);
    }
}
