import { inheritSerialization, serialize } from 'cerialize';
import { BaseServiceParameter } from '@datagalaxy/data-access';

@inheritSerialization(BaseServiceParameter)
export class GetEntityInsightsParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;

    constructor(entityId: string) {
        super();
        this.DataReferenceId = entityId;
    }
}
