import { DomUtil, THTMLElement } from '@datagalaxy/core-util';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { IGraphicalToolbarOption } from '../graphical.types';
import {
    IGraphicalControlEvents,
    IGraphicalSidePanelSpec,
} from './graphical-controls.types';
import { CoreEventsService } from '../../services';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { FullScreenUtil } from '@datagalaxy/shared/fullscreen';
import { DxyGraphicalToolbarComponent } from '../graphical-toolbar/graphical-toolbar.component';
import { DxyGraphicalSidePanelComponent } from '../graphical-side-panel/graphical-side-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf, NgClass } from '@angular/common';

/** ## Role
 * Toolbars management for graphical views
 *
 * ## Features
 * - left toolbar for action buttons
 * - bottom-right toolbar for zoom, full-screen, screen-shot, mini-map toggle
 * - top-right assets icon and sliding panel management
 *
 * ## Note:
 * action callbacks, mini-map and assets content must be provided by the client component
 * */
@Component({
    selector: 'dxy-graphical-controls',
    templateUrl: './graphical-controls.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatLegacyButtonModule,
        NgClass,
        TranslateModule,
        DxyGraphicalSidePanelComponent,
        DxyGraphicalToolbarComponent,
    ],
})
export class DxyGraphicalControlsComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
    @Input() actionButtons: IGraphicalToolbarOption[];
    @Input() noZoom: boolean;
    @Input() enableLegend: boolean;
    @Input() events: IGraphicalControlEvents;
    /** element or css selector for the element to display in full screen. Defaults to this component (dxy-graphical-controls)  */
    @Input() fullScreenElement: string | THTMLElement;
    @Input() zoom: number;
    @Input() showMiniMap: boolean;
    /** Allows for css scope limitation when a toolbar is displayed in a global overlay */
    @Input() toolbarsClass: string;
    @Input() sidePanelSpec: IGraphicalSidePanelSpec;
    @Input() sidePanelExpanded: boolean;
    @Output() readonly sidePanelExpandedChange = new EventEmitter<boolean>();
    @Output() readonly functional = new EventEmitter<IFunctionalEvent>();

    //#region API
    public get isFullScreen() {
        return this.isFullScreenGlobal;
    }
    //#endregion

    protected viewButtons: IGraphicalToolbarOption[];

    protected get isFullScreenLocal() {
        return !!this.fullScreenElement && FullScreenUtil.isFullScreen;
    }
    protected get sidePanelSide() {
        return this.sidePanelSpec?.side ?? 'right';
    }

    private legendOpen = false;
    private miniMapElement: Element;
    private isFullScreenGlobal: boolean;
    /** true when mini-map content has been trascluded */
    private get hasMiniMapContent() {
        return !!this.miniMapElement?.children.length;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private coreEventsService: CoreEventsService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'noZoom', () => this.updateViewButtons());
        super.onChange(changes, 'sidePanelSpec', () =>
            this.updateSidePanelParams()
        );
        super.onChange(changes, 'zoom', () => this.updateViewButtons());
    }

    ngOnInit() {
        this.log('ngOnInit');
        this.updateViewButtons();
        this.updateSidePanelParams();
        this.subscribeEvents();
    }

    ngAfterViewInit(): void {
        this.miniMapElement = DomUtil.getElement(this.elementRef, '.mini-map');
        this.log('ngAfterViewInit', this.miniMapElement);
        setTimeout(() => this.updateViewButtons());
    }
    ngOnDestroy() {
        this.log('ngOnDestroy');
        super.ngOnDestroy();
    }

    protected onFunctional(event: IFunctionalEvent) {
        this.functional.emit(event);
    }

    protected onSidePanelButtonClick() {
        this.sidePanelExpandedChange.emit((this.sidePanelExpanded = true));
    }
    protected onSidePanelCloseClick() {
        this.sidePanelExpandedChange.emit((this.sidePanelExpanded = false));
    }

    private async toggleFullScreen() {
        const el =
            typeof this.fullScreenElement == 'string'
                ? document.querySelector(this.fullScreenElement)
                : DomUtil.getElement(this.fullScreenElement ?? this.elementRef);
        const toFullSCreen = !FullScreenUtil.isFullScreen;
        this.log(
            'toggleFullScreen',
            this.fullScreenElement,
            !!el,
            toFullSCreen
        );
        await FullScreenUtil.toggle(el, toFullSCreen);
    }

    private toggleMinimap() {
        this.showMiniMap = !this.showMiniMap;
        this.events?.onMinimapVisibleChange?.(this.showMiniMap);
    }

    private updateViewButtons() {
        this.log('updateViewButtons', this.noZoom, this.hasMiniMapContent);
        if (this.noZoom) {
            this.viewButtons = undefined;
            return;
        }

        this.viewButtons = [
            {
                glyphClass: 'glyph-zoom-reset',
                callback: () => this.events?.onZoomReset?.(),
                tooltipTranslateKey:
                    'UI.ImpactAnalysis.lineage.ttToolResetDefaultZoom',
            },

            !this.fullScreenElement
                ? undefined
                : {
                      glyphClass: () =>
                          this.isFullScreenLocal
                              ? 'glyph-fullscreen-contract'
                              : 'glyph-fullscreen-expand',
                      callback: () => this.toggleFullScreen(),
                      tooltipTranslateKey: () =>
                          'UI.ImpactAnalysis.lineage.' +
                          (this.isFullScreenLocal
                              ? 'ttToolExitFullScreen'
                              : 'ttToolEnterFullScreen'),
                  },

            !this.hasMiniMapContent
                ? undefined
                : {
                      glyphClass: 'glyph-location-map',
                      callback: () => this.toggleMinimap(),
                      tooltipTranslateKey: () =>
                          this.showMiniMap
                              ? 'Graphical.Plumb.hideMiniView'
                              : 'Graphical.Plumb.showMiniView',
                  },

            !this.events?.onScreenshot
                ? undefined
                : {
                      glyphClass: 'glyph-photo',
                      callback: () => this.events?.onScreenshot?.(),
                      tooltipTranslateKey:
                          'UI.ImpactAnalysis.lineage.ttScreenshot',
                  },

            {
                glyphClass: 'glyph-help',
                callback: () => {
                    this.legendOpen = !this.legendOpen;
                    this.events?.onToggleLegend?.(this.legendOpen);
                },
                tooltipTranslateKey: 'UI.ImpactAnalysis.lineage.ttToolLegend',
                hidden: () => !this.enableLegend,
            },

            { isSeparator: true },

            {
                glyphClass: 'glyph-minus',
                callback: () => this.events?.onZoomOut?.(),
                tooltipTranslateKey: 'UI.ImpactAnalysis.lineage.ttToolZoomOut',
            },

            {
                glyphClass: 'glyph-add',
                callback: () => this.events?.onZoomIn?.(),
                tooltipTranslateKey: 'UI.ImpactAnalysis.lineage.ttToolZoomIn',
            },

            {
                optionClass: this.zoom ? 'zoom-percentage' : 'hidden',
                labelText: isNaN(this.zoom ?? NaN)
                    ? ''
                    : `${Math.round(this.zoom * 100)}%`,
            },
        ].filter((o) => o);
        this.cd.markForCheck();
    }

    private subscribeEvents() {
        super.subscribe(FullScreenUtil.onChange$, (isFullScreen) => {
            this.log('onFullScreenChanged', isFullScreen);
            this.isFullScreenGlobal = isFullScreen;
            this.updateViewButtons();
            this.events?.onFullScreenChanged?.(isFullScreen);
        });
        super.subscribe(this.coreEventsService.windowKeyDownEscape$, () => {
            this.onSidePanelCloseClick();
        });
    }

    private updateSidePanelParams() {
        const spec = this.sidePanelSpec;
        DomUtil.setStyleProperty(
            this.elementRef,
            '--side-panel-width',
            spec?.width ?? 300,
            'px'
        );
        DomUtil.setStyleProperty(
            this.elementRef,
            '--side-panel-slide-delay',
            spec?.slideDelayMs ?? 200,
            'ms'
        );
    }
}
