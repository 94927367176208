import { Injectable } from '@angular/core';
import { ModuleSecurityService } from './module-security.service';
import { allModules } from '@datagalaxy/shared/dg-module/domain';
import { ModuleInfo } from './module.store';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { ArrayUtils } from '@datagalaxy/utils';

@Injectable({ providedIn: 'root' })
export class ModuleService {
    constructor(
        private moduleSecurityService: ModuleSecurityService,
        private currentUser: CurrentUserService
    ) {}

    public async loadCurrentUserAccessibleModules(
        spaceId: string,
        versionId: string
    ): Promise<ModuleInfo[]> {
        const currentUser = this.currentUser.userInfo;

        const res = await this.moduleSecurityService.getUserAccessibleModules(
            spaceId,
            versionId,
            currentUser.UserId
        );

        return res
            .map((moduleAccessInfo) => ({
                definition: allModules.find(
                    (m) => m.name === moduleAccessInfo.Module
                ),
                access: moduleAccessInfo,
            }))
            .sort(ArrayUtils.sortByIndexOf(allModules, (m) => m.definition));
    }
}
