import { FieldType } from '../../types/enums/field-type.enum';
import { schemasTradKeys } from '../../types/constants/schemas-trad-keys.constant';

export const oracleConfig = [
    { payloadField: 'type', payloadValue: 'MODEL' },
    { payloadField: 'database-technology', payloadValue: 'oracle' },
    {
        payloadField: 'server',
        formField: 'server',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'port',
        formField: 'port',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'use-sid',
        formField: 'useSid',
        tradKey: 'connectionMode',
        type: FieldType.select,
        payloadValue: false,
        selectValues: [true, false],
        translate: true,
        getDisplayValueTradKey: (value: boolean) =>
            value ? 'sid' : 'instance',
    },
    {
        payloadField: 'instance',
        formField: 'instance',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        formField: 'schemas',
        payloadField: 'schemas',
        payloadValue: [],
        type: FieldType.list,
        listFieldTradKeys: schemasTradKeys,
        isFilter: true,
    },
    {
        payloadField: 'user',
        formField: 'user',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        isPassword: true,
        formMandatory: true,
    },
];
