import { select } from 'd3-selection';
import { Subject } from 'rxjs';
import { DomUtil } from '@datagalaxy/core-util';
import {
    MovePhase,
    Point,
    RectSidePoint,
    simplifyPath,
    Vect2,
} from '@datagalaxy/core-2d-util';
import { SD3ED, TD3Subject } from '../../../D3Helper';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { IConnectorEditor, IConnectorEditorEvent } from '../../edge';
import { D3DragEvent, drag as d3drag } from 'd3-drag';
import { Connector } from '../../connector';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { IOrthoEditorOptions } from './editor.types';
import { OrthoHandle } from './orthogonal/OrthoHandle';
import { OrthoSegment } from './orthogonal/OrthoSegment';

/** ## Role
 * Provides wysiwyg modification of a graphical orthogonal connector */
export class OrthoEditor<N = unknown, D = unknown>
    extends BaseGraphicalManager
    implements IConnectorEditor<N, D>
{
    private static readonly verbose = true;

    public get editing() {
        return this.connector;
    }
    public get editingChanged$() {
        return this.editingChange.asObservable();
    }
    public get connectorChanged$() {
        return this.connectorChanged.asObservable();
    }

    private readonly editingChange = new Subject<IConnectorEditorEvent<N, D>>();
    private readonly connectorChanged = new Subject<void>();
    private readonly handleClass = 'oe-handle';
    private handles: OrthoHandle[] = [];
    private d3endpoints: SD3ED<HTMLDivElement, RectSidePoint<N>>;
    private container: HTMLElement;
    private connector: Connector<N, D>;
    private segments: OrthoSegment[];
    private options: IOrthoEditorOptions;
    /** margin around a node's rectangle */
    private shapeMargin: number;
    private dragBehaviour = d3drag<HTMLElement, OrthoHandle, TD3Subject>();

    constructor(private zoomAdapter: ZoomedViewAdapter) {
        super();

        const self = this;
        this.dragBehaviour
            .subject((_, handle) =>
                this.zoomAdapter.fixedToZoomed.xy(handle.x, handle.y)
            )
            .on(
                'drag',
                function (
                    event: D3DragEvent<HTMLElement, OrthoHandle, OrthoSegment>,
                    handle: OrthoHandle
                ) {
                    self.onHandleMove(event, handle, MovePhase.move);
                }
            );
    }

    public init(options: IOrthoEditorOptions) {
        super.initInternal(options, OrthoEditor.verbose);
        const container = (this.container = DomUtil.createElement('div'));
        container.style.position = 'relative';
        this.options = options;
        this.shapeMargin = options?.shapeMargin ?? 20;
        return container;
    }

    /** starts or ends editing of the provided connector */
    public toggleEdit(c: Connector<N, D>) {
        if (!(c instanceof Connector)) {
            return;
        }
        this.log('toggleEdit', c);
        if (this.connector == c) {
            this.endEdit();
        } else {
            this.startEdit(c);
        }
    }

    /** starts editing of the provided connector */
    public startEdit(c: Connector<N, D>) {
        this.log('startEdit', c);
        if (!(c instanceof Connector)) {
            return;
        }
        if (this.connector == c) {
            return;
        }
        this.connector = c;
        this.container
            .querySelector(`div.oe-container-${this.connector.id}`)
            ?.remove();
        const div = DomUtil.createElement(
            'div',
            `oe-container-${this.connector.id}`
        );
        this.container.append(div);
        this.rebuildConnectorAndHandles();
        this.editingChange.next({ connector: c, editing: true });
    }

    /** cancels editing of the currently edited connector */
    public endEdit(noEmit?: boolean) {
        if (!this.connector) {
            return;
        }
        this.log('endEdit', noEmit);
        const connector = this.connector;
        this.container
            .querySelector(`div.oe-container-${this.connector.id}`)
            .remove();
        this.connector = null;
        this.clearHandles();
        if (this.debug) {
            this.removeDummies(connector);
        }
        if (!noEmit) {
            this.editingChange.next({ connector, editing: false });
        }
    }

    public onConnectorUpdated() {
        if (!this.connector) {
            return;
        }
        this.verbose && this.log('onConnectorUpdated');
        this.buildHandles();
    }

    private clearHandles() {
        this.handles?.forEach((h) => h.remove());
        this.d3endpoints?.remove();
    }

    private onHandleMove(
        event: D3DragEvent<HTMLElement, OrthoHandle, TD3Subject>,
        handle: OrthoHandle,
        phase: MovePhase
    ) {
        const adapter = this.zoomAdapter.zoomedToFixed;
        const point = adapter.xy(event.x, event.y);
        this.moveSegment(handle.segment, point, phase, this.shapeMargin);
        this.rebuildConnectorAndHandles();
        return true;
    }

    private buildHandles() {
        this.verbose && this.log('buildHandles');
        const connector = this.connector;
        const path = simplifyPath(
            connector.isFixed ? connector.getFixedPoints() : connector.points
        );
        const segments = (this.segments = OrthoSegment.fromPoints(path));
        if (this.debug) {
            this.drawDummies(connector);
        }
        const handleSegments = segments as OrthoSegment<OrthoHandle>[];
        const opt = this.options?.handles ?? {},
            handleClass = this.handleClass;
        this.handles = handleSegments
            .filter((s) => s.length)
            .map((s, i) => {
                const handle = s.data ?? new OrthoHandle(s, opt);
                handle.dbg = i;
                return handle;
            });
        const common = function (this: HTMLElement, d: OrthoHandle) {
            d.init(this, true);
            this.className = DomUtil.addClass(
                `${handleClass} ${d.vh}`,
                opt.className
            );
            this.style.cursor =
                opt.cursor == 0
                    ? null
                    : opt.cursor == 2
                    ? d.cursorRowCol
                    : d.cursorNsew;
        };
        const d3handles = select(this.container)
            .selectAll(`div.oe-container-${this.connector.id}`)
            .selectAll(`div.oe-handle`)
            .data(this.handles)
            .join(
                (enter) =>
                    enter
                        .append('div')
                        .each(common)
                        .style('position', (d) =>
                            d.useStyle ? 'absolute' : null
                        )
                        .on('dblclick', (event, d) =>
                            this.handleDblClick(event, d)
                        )
                        .on('click', (event) => event.stopPropagation())
                        .call(this.dragBehaviour),
                (update) => update.each(common),
                (exit) => exit.remove()
            );
        if (this._debug) {
            d3handles.text((d) => d.dbg);
        }
    }

    private handleDblClick(event: MouseEvent, handle: OrthoHandle) {
        event.stopPropagation();
        this.log('handleDblClick', handle);
        this.alignSegment(handle.segment);
        this.rebuildConnectorAndHandles();
    }

    /** note: a call to draw/build is needed after this */
    private alignSegment(s: OrthoSegment) {
        this.log('alignSegment', s?.isFirstOrLast);
        if (!s || s.isFirstOrLast) {
            return;
        }

        s.alignOnNearest();

        const segments = OrthoSegment.toArray(s.getFirst());

        if (segments.length <= 3) {
            this.connector.updateFixedPoints(null);
            this.connector.computePoints();
        } else {
            this.connector.updateFixedPoints(s.getPoints());
        }
    }

    /** note: a call to draw/build is needed after this */
    private moveSegment(
        s: OrthoSegment,
        moveTo: Point,
        phase: MovePhase,
        margin: number
    ) {
        const isFixed = this.connector.isFixed;
        this.log(
            'moveSegment',
            MovePhase[phase],
            margin,
            isFixed,
            s.isFirst,
            s.isLast,
            s
        );

        if (s.isFirst || !isFixed) {
            const firstSegment = s.getFirst();
            if (!isFixed) {
                firstSegment.shorten(margin);
            }
            this.segments.unshift(
                (firstSegment.prev = new OrthoSegment(
                    Vect2.from(firstSegment.startPoint),
                    firstSegment.startPoint
                ))
            );
        }

        if (s.isLast || !isFixed) {
            const lastSegment = s.getLast();
            if (!isFixed) {
                lastSegment.shorten(margin, false);
            }
            this.segments.push(
                (lastSegment.next = new OrthoSegment(
                    lastSegment.endPoint,
                    Vect2.from(lastSegment.endPoint)
                ))
            );
        }

        s.translate(moveTo, true);

        const points = s.getPoints();
        this.connector.updateFixedPoints(
            phase === MovePhase.end ? simplifyPath(points) : points
        );

        this.rebuildConnectorAndHandles();

        this.debug &&
            this.log(
                'moveSegment-out',
                this.segments.slice(),
                points?.slice(),
                s
            );
    }

    private rebuildConnectorAndHandles() {
        if (!this.connector) {
            return;
        }
        this.verbose && this.log('rebuildConnectorAndHandles');
        requestAnimationFrame(() => {
            this.buildHandles();
            this.connector.computePoints();
            this.connector.draw();
        });

        this.connectorChanged.next();
    }

    private drawDummies(c: Connector) {
        const dbg = select(c.el)
            .selectAll('g.dbg-ortho-editor')
            .data([0])
            .join('g')
            .attr('class', 'dbg-ortho-editor')
            .style('pointer-events', 'none');
        const segments = this.segments;
        if (!segments?.length) {
            return;
        }
        // segments lines
        dbg.selectAll('path.s')
            .data(segments)
            .join('path')
            .attr('class', 's')
            .attr(
                'd',
                (s) =>
                    `M${s.startPoint.x} ${s.startPoint.y},L${s.endPoint.x} ${s.endPoint.y}`
            )
            .style('stroke', 'blue')
            .style('fill', 'none')
            .style('stroke-dasharray', (s) => (s.isFirstOrLast ? 4 : 2));
        // segments middle text with index
        dbg.selectAll('text.s')
            .data(segments)
            .join('text')
            .attr('class', 's')
            .attr('x', (s) => s.getMidPoint().x + (s.isVertical ? 10 : 0))
            .attr('y', (s) => s.getMidPoint().y + (s.isHorizontal ? -10 : 0))
            .text((_, i) => i)
            .style('fill', 'blue');
    }

    private removeDummies(c: Connector) {
        select(c.el).selectAll('g.dbg-ortho-editor').remove();
    }
}
