import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { AppDataService } from '../services/app-data.service';
import { IWidgetConfigDb } from '../widgets-dashboard/WidgetUtil';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import {
    ClientApiService,
    ClientLoginParameter,
    SetImageParameter,
    UpdateClientSpaceParameter,
} from '@datagalaxy/webclient/client/data-access';
import { ClientStorageService } from '../services/clientStorage.service';
import { MasterDataService } from '@datagalaxy/webclient/modeler/data-access';
import { StateService } from '@uirouter/core';
import { StateName } from '@datagalaxy/webclient/routing';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { CoreUtil } from '@datagalaxy/core-util';

@Injectable({ providedIn: 'root' })
export class ClientService extends BaseService {
    constructor(
        private clientApiService: ClientApiService,
        private appDataService: AppDataService,
        private clientStorageService: ClientStorageService,
        private masterDataService: MasterDataService,
        private stateService: StateService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
    }

    public async updateClientSpace(opt: {
        clientName?: string;
        clientDescription?: string;
        userSessionTimeoutMinutes?: number;
        widgets?: IWidgetConfigDb[];
    }) {
        if (opt.widgets) {
            this.appDataService.setClientWidgets(opt.widgets);
        }
        const uiSettingsJSON = this.appDataService.getClientUISettingsJSON();
        const updateClientSpaceParameter = new UpdateClientSpaceParameter(
            opt.clientName,
            opt.clientDescription,
            opt.userSessionTimeoutMinutes,
            uiSettingsJSON
        );
        const result = await this.clientApiService.updateClientSpace(
            updateClientSpaceParameter
        );
        this.appDataService.updateClientData(
            result.ClientFriendlyName,
            result.ClientDescription,
            result.ClientUISettingsJSON
        );
        return result;
    }

    public async clientLogin(clientId: string, from?: string) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('clientLogin', clientId, from);

        try {
            if (!this.appDataService.hasAuthorizedClientId(clientId)) {
                this.clientStorageService.clearCookie();
                clientId = this.appDataService.getFirstAuthorizedClientId();
            }

            this.appDataService.clearLogginFailed();
            this.appDataService.setLoginInProgress(true);

            // Init User session Ids
            this.appDataService.clearUserSessionId();

            // Process client login
            this.masterDataService.clear(false); // masterDataService listens to client login deserialization event
            const loginToken = this.appDataService.getLoginToken();
            const clp = new ClientLoginParameter();
            clp.LoginToken = loginToken;
            clp.ClientId = clientId;

            try {
                const clr = await this.clientApiService.clientLogin(clp);
                this.appDataService.setIsClientAuthenticated(true);
                // await this.clientServiceManager.initializeServices(clr, clp.ClientId)
                return clr;
            } catch (e) {
                this.appDataService.setIsClientAuthenticated(false);
                this.appDataService.setLoginFailed(true);
                throw e;
            } finally {
                this.appDataService.setLoginInProgress(false);
            }
        } catch (err) {
            CoreUtil.warn('err', err);
        }
    }

    public async logout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('logout');
        await this.clientApiService.clientLogout();
    }

    public async getClientListRoute() {
        return this.stateService.target(StateName.MainError, {
            accessLevel: 'private',
        });
    }

    public updateClientSpaceSecurity(userSessionTimeoutMinutes: number) {
        return this.updateClientSpace({ userSessionTimeoutMinutes });
    }

    public async getClientStatistics() {
        const serviceParameters = new BaseServiceParameter();
        return this.clientApiService.getClientStatistics(serviceParameters);
    }

    public getImageUrl(hash: string) {
        return this.clientApiService.getImageUrl(hash);
    }

    public async setImage(setImageParameter: SetImageParameter) {
        return this.clientApiService.setImage(setImageParameter);
    }
}
