<header>
    <dxy-icon
        [size]="iconSize"
        [icon]="icon"
        [type]="iconType"
        [matTooltip]="iconTooltip"
        [matTooltipDisabled]="iconTooltipDisabled"
    ></dxy-icon>
</header>

<div *ngIf="text" class="icon-card-text">
    <span dxyEllipsisTooltip>
        {{ text }}
    </span>
</div>
