<dxy-action-list [actions]="currentItemActions" [data]="currentItem">
    <span #panelToggleButton class="navigation-panel-buttons">
        <button
            mat-icon-button
            (click)="togglePanel()"
            [matTooltip]="titleButtonTooltipText"
        >
            <i class="glyph-sort"></i>
        </button>
        <button class="text-button" (click)="togglePanel()">
            <span class="text-button-content" dxyEllipsisTooltip>
                {{ titleButtonText }}
            </span>
        </button>
    </span>
</dxy-action-list>

<dxy-panel-host
    [(panelOpened)]="panelOpened"
    [toggleButton]="panelToggleButton"
    class="left"
>
    <div *ngIf="panelOpened" class="navigation-panel-container">
        <header>
            <dxy-tabs-header
                [tabs]="tabsHeaderData"
                (tabChange)="setCurrentTab($event)"
            ></dxy-tabs-header>
            <dxy-mat-burger-menu></dxy-mat-burger-menu>
            <button
                *ngIf="addButtonVisible"
                mat-mini-fab
                (click)="onAddClick()"
                [matTooltip]="addButtonTooltip"
            >
                <span class="glyph-add"></span>
            </button>
        </header>
        <div *ngIf="searchVisible" class="search-bar">
            <dxy-search-input
                class="no-border-top"
                placeholderKey="UI.Global.search"
                [(searchString)]="searchTerm"
                (onSearchString)="createCurrentTabItems()"
                [showCancelSearch]="true"
            ></dxy-search-input>
        </div>
        <div class="item-list">
            <cdk-virtual-scroll-viewport
                [itemSize]="itemSize"
                [minBufferPx]="itemSize * 5"
                [maxBufferPx]="itemSize * 10"
                class="item-list-viewport"
            >
                <div
                    *cdkVirtualFor="let item of items; trackBy: trackByItemId"
                    class="item"
                    [style.height.px]="itemSize"
                    [class.active]="item.id == currentItemId"
                    (click)="onItemClick(item)"
                >
                    <dxy-action-list
                        [actions]="itemActions"
                        [data]="item.data"
                        [fixButtonPositions]="true"
                    >
                        <span
                            *ngIf="item.glyphClass"
                            class="item-glyph"
                            [ngClass]="item.glyphClass"
                        ></span>
                        <div
                            class="name"
                            dxyEllipsisTooltip
                            [dxyEllipsisTooltipDisabled]="item.tooltipText"
                            [matTooltip]="item.tooltipText"
                        >
                            {{ item.name }}
                        </div>
                        <div *ngIf="userRenderer" class="user">
                            <dxy-renderer
                                *ngIf="item.userRendererParams"
                                [renderer]="userRenderer"
                                [param]="item.userRendererParams"
                            ></dxy-renderer>
                        </div>
                        <div
                            *ngIf="params?.getLastModificationDate"
                            class="last-modification"
                        >
                            <dxy-dgdatetime-cell
                                [dgDate]="item.lastModificationDate"
                                [useDgFormat]="true"
                                [noTime]="true"
                            ></dxy-dgdatetime-cell>
                        </div>
                    </dxy-action-list>
                    <div *ngIf="extraInfos" class="extra-infos">
                        <ng-container
                            [ngTemplateOutlet]="extraInfos"
                            [ngTemplateOutletContext]="{ data: item.data }"
                        ></ng-container>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</dxy-panel-host>
