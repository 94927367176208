import { ILoggerService, LogLevel } from './logger.types';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggerService implements ILoggerService {
    private currentLogLevel: LogLevel = LogLevel.TRACE;

    print(level: LogLevel, message: string, ...additional: any[]): void {
        if (level >= this.currentLogLevel) {
            console.log(this.getLogLevelString(level), message, ...additional);
        }
    }

    trace(message: string, ...additional: any[]): void {
        this.print(LogLevel.TRACE, message, ...additional);
    }

    debug(message: string, ...additional: any[]): void {
        this.print(LogLevel.DEBUG, message, ...additional);
    }

    info(message: string, ...additional: any[]): void {
        this.print(LogLevel.INFO, message, ...additional);
    }

    log(message: string, ...additional: any[]): void {
        this.print(LogLevel.LOG, message, ...additional);
    }

    warn(message: string, ...additional: any[]): void {
        this.print(LogLevel.WARN, message, ...additional);
    }

    error(message: string, ...additional: any[]): void {
        this.print(LogLevel.ERROR, message, ...additional);
    }

    fatal(message: string, ...additional: any[]): void {
        this.print(LogLevel.FATAL, message, ...additional);
    }

    private getLogLevelString(level: LogLevel): string {
        return LogLevel[level];
    }
}
