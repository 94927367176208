import { Inject, Injectable } from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import {
    COMMENTARY_MODAL_SERVICE_TOKEN,
    CommentaryModalService,
} from '@datagalaxy/webclient/comment/feature';
import { EntityLoader } from '@datagalaxy/webclient/entity/feature';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SignalAttributeTranslationService {
    constructor(
        @Inject(COMMENTARY_MODAL_SERVICE_TOKEN)
        private commentaryModalService: CommentaryModalService,
        private entityLoader: EntityLoader,
        private translate: TranslateService,
        private currentUserService: CurrentUserService
    ) {}

    public async signal(entityIdr: IEntityIdentifier, attributeName: string) {
        const message = this.translate.instant(
            'Multilingual.AttributeTranslationButton.reportComment',
            {
                attributeName,
                languageCode:
                    this.currentUserService.userInfo?.LanguageCode?.toUpperCase(),
            }
        );
        const entityData = await this.entityLoader.loadEntity(entityIdr);
        const owners = entityData.getDataOwners().map((id) => id.split(':')[1]);
        this.commentaryModalService.openCommentaryNewModal({
            entityData,
            message,
            targetUserIds: owners,
        });
    }
}
