export const amazonAthenaFieldInfo = {
    region: {
        hint: {
            fr: 'Code de la région AWS, exemple : eu-west-3',
            en: 'AWS region code, example: eu-west-3',
        },
    },
    accessKey: {
        hint: {
            fr: "Identifiant de la clé d'accès de votre compte AWS",
            en: 'AWS account access key identifier',
        },
    },
    password: {
        hint: {
            fr: "Clé d'accès secrète de votre compte AWS ",
            en: 'AWS account access key secret',
        },
    },
};
