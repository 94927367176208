import { TDomElement } from '@datagalaxy/core-2d-util';
import { SD3ED } from './D3Helper';
import { ID3Single, ID3SvgSingle } from './graph/graph-surface.types';

/** ## Role
 * Expose a DOM element both as-such and as a d3 selection */
export class D3Single<T extends TDomElement> implements ID3Single<T> {
    public get el() {
        return this.d3.node();
    }
    constructor(public readonly d3: SD3ED<T, unknown>) {}
    public remove() {
        this.d3.remove();
    }
}

/** ## Role
 * Expose a G element and the *defs* element of a svg document, each as such and as a d3 selection */
export class D3SvgSingle extends D3Single<SVGGElement> implements ID3SvgSingle {
    public readonly defs: ID3Single<SVGDefsElement>;

    constructor(
        d3g: SD3ED<SVGGElement, unknown>,
        d3defs: SD3ED<SVGDefsElement, unknown>
    ) {
        super(d3g);
        this.defs = new D3Single(d3defs);
    }
}
