export interface IAttributeFilterSubmitEvent {
    closeForm?: boolean;
}

export enum AttributeFilterAction {
    none = 0,
    itemRemoved,
    itemChanged,
    formClosed,
}
