import { UIRouter } from '@uirouter/core';
import { Injector } from '@angular/core';
import { StateName } from '@datagalaxy/webclient/routing';
import { NavigationService } from '../services/navigation.service';
import { FirstAccessService } from './first-access/first-access.service';
import { clientFirstAccessRouteAuthV2, clientRoute } from './client.routes';

export function clientRouterConfig(uiRouter: UIRouter, injector: Injector) {
    const { transitionService, stateService } = uiRouter;

    uiRouter.stateRegistry.register(clientRoute);
    uiRouter.stateRegistry.register(clientFirstAccessRouteAuthV2);

    // User first-access middleware for Auth V2
    transitionService.onBefore(
        { entering: StateName.Client },
        async (transition) => {
            const firstAccessService =
                injector.get<FirstAccessService>(FirstAccessService);
            const isFirstAccessCompleted =
                await firstAccessService.isCompleted();
            const navigationService =
                injector.get<NavigationService>(NavigationService);

            if (
                transition.targetState().name() ===
                StateName.ClientFirstAccessAuthV2
            ) {
                return true;
            }

            if (!isFirstAccessCompleted) {
                navigationService.setMemoState(transition, true);
                return stateService.target(
                    StateName.ClientFirstAccessAuthV2,
                    undefined,
                    {
                        reload: false,
                    }
                );
            }
            firstAccessService.notifyFirstAccessDone();
        }
    );
}
