import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { IS_BACKEND_API } from '../backend';
import { generateGuid, ObjectUtils } from '@datagalaxy/utils';

/**
 * ## Role
 * Add a unique request identifier for each requests
 */
@Injectable()
export class XidInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const xid = generateGuid();
        const reqClone: HttpRequest<any> = req.clone({
            headers: req.headers.set('Xid', xid),
            body: req.context.get(IS_BACKEND_API)
                ? ObjectUtils.assign(req.body, { Xid: xid })
                : req.body,
        });

        return next.handle(reqClone);
    }
}
