<div
    [matMenuTriggerFor]="menu"
    (menuOpened)="onMenuOpened()"
    (menuClosed)="onMenuClosed()"
>
    <button
        mat-icon-button
        class="suggestions-btn pulse"
        [matTooltip]="'UI.Suggestions.MultiValue.btnTT' | translate"
    >
        <i class="glyph-robot2"></i>
    </button>
</div>

<mat-menu #menu="matMenu" class="dg5-mat-menu no-max-width">
    <div class="content-wrapper not-an-item">
        <div class="suggestion-section">
            <div class="suggestion-title">
                <i class="glyph glyph-robot2"></i>
                <span class="text" translate>{{ title }}</span>
            </div>
            <div class="suggestion-title-description" translate>
                {{ description }}
            </div>
        </div>
        <div class="separator-line"></div>
        <ul class="suggestion-items">
            <li
                *ngFor="let item of filteredSuggestions"
                class="suggestion-item menu-item"
            >
                <app-suggestion-cell
                    [suggestion]="item"
                    featureCodePrefix="ROBOT_SUGGESTION_"
                ></app-suggestion-cell>
            </li>
        </ul>
        <div class="separator-line"></div>
        <ul class="suggestion-items suggestion-items-all">
            <li
                *ngIf="isMultiValue"
                (click)="onUserChoiceForAll(true)"
                class="suggestion-item menu-item suggestion-item-all"
            >
                <span class="glyph glyph-check"></span>
                <span translate>UI.Suggestions.MultiValue.AcceptAll</span>
            </li>
            <li
                (click)="onUserChoiceForAll(false)"
                class="suggestion-item menu-item suggestion-item-all"
            >
                <span class="glyph glyph-cancelsearch"></span>
                <span translate>UI.Suggestions.MultiValue.DeclineAll</span>
            </li>
        </ul>
    </div>
</mat-menu>
