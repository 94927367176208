import { VersioningUiService } from '../services/versioning-ui.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { UiSpinnerService } from '@datagalaxy/core-ui';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DxyVersioningSelectorComponent } from '../dxy-versioning-selector/dxy-versioning-selector.component';
import {
    ITabViewData,
    ITabViewItem,
} from '../../shared/shared-ui/dxy-tab-view/dxy-tab-view.types';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import { VersionCompareModuleData } from '../versioning.types';
import { VersionCompareContext } from '../../shared/entity/compare-entity.types';
import { SpaceApiService } from '../../space/services/space-api.service';
import { IProjectVersionSelectedEvent } from '../../space/space.types';
import {
    Organization,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    CompareEntity,
    CompareOperation,
    ComparisonItem,
    ProjectVersion,
} from '@datagalaxy/webclient/versioning/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { DxyVersioningComparatorSectionComponent } from '../dxy-versioning-comparator-section/dxy-versioning-comparator-section.component';
import { DxyTabViewComponent } from '../../shared/shared-ui/dxy-tab-view/dxy-tab-view.component';
import { NgIf, NgFor } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
    selector: 'dxy-versioning-comparator',
    templateUrl: 'dxy-versioning-comparator.component.html',
    standalone: true,
    imports: [
        MatLegacyFormFieldModule,
        TranslateModule,
        DxyVersioningSelectorComponent,
        NgIf,
        DxyTabViewComponent,
        NgFor,
        DxyVersioningComparatorSectionComponent,
    ],
})
export class DxyVersioningComparatorComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() currentSpace: Space;
    @Input() currentEntity: EntityItem;
    @Input() toolParam: ProjectVersion;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;

    public readonly tabsData: ITabViewData = {
        tabItems: [
            ViewIdentifier.VersioningComparatorCreated,
            ViewIdentifier.VersioningComparatorModified,
            ViewIdentifier.VersioningComparatorDeleted,
        ].map((tabId) => ({ tabId })),
        getTabItemTranslateKey: (tabItem) =>
            `UI.Versioning.Comparator.${tabItem.tabId}`,
        noUiView: true,
        isTabItemActive: (tabItem) => tabItem.tabId == this.currentActiveTabId,
        onActiveTabItem: (tabItem) => this.onActiveTabItem(tabItem),
    };
    /** Equals to currentSpace (if project-level comparison) or the space related to the current Entity */
    public versionCompareSpace: Space;
    public versionCompareModuleItems: VersionCompareModuleData[] = [];
    public displayedModuleData: VersionCompareModuleData[];
    public selectedVersion: ProjectVersion;

    public get isVersionSelected() {
        return (
            this.selectedVersionId &&
            this.selectedVersionId != this.currentVersionId
        );
    }
    public get currentActiveOperation() {
        return this.currentActiveTabId ==
            ViewIdentifier.VersioningComparatorCreated
            ? CompareOperation.Added
            : this.currentActiveTabId ==
              ViewIdentifier.VersioningComparatorModified
            ? CompareOperation.Updated
            : CompareOperation.Deleted;
    }
    public get hasResultsModule() {
        return this.hasBeenExecuted && !!this.versionCompareModuleItems?.length;
    }
    public get hasNoResultModule() {
        return this.hasBeenExecuted && !this.versionCompareModuleItems?.length;
    }

    //#region for screen-layout
    public entityAttributes: AttributeMetaInfo[] = [];
    public reloadOnSourceChange = true;
    public isVersioningCompare = true;
    public versionCompareContext: VersionCompareContext;
    public isLoading = true;
    public get currentSpaceId() {
        return this.versionCompareSpace?.spaceId;
    }
    public get currentVersionName() {
        return (this.versionCompareSpace as Project)?.VersionName ?? '';
    }
    //#endregion

    @ViewChild(DxyVersioningSelectorComponent)
    protected versionSelector: DxyVersioningSelectorComponent;

    protected comparationSingleItem: CompareEntity;
    protected hasBeenExecuted: boolean;
    protected get featureCode() {
        return 'ENTITY_VERSION_COMPARISON';
    }

    private currentActiveTabId: string;
    private mappedModuleData: Map<string, VersionCompareModuleData[]>;

    protected get currentVersionId() {
        return this.versionCompareSpace?.versionId;
    }
    protected get selectedVersionId() {
        return this.selectedVersion?.versionId;
    }

    constructor(
        protected translate: TranslateService,
        protected versioningUiService: VersioningUiService,
        private spaceApiService: SpaceApiService,
        protected uiSpinnerService: UiSpinnerService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'currentEntity', async () => {
            if (this.currentVersionId != this.currentEntity.VersionId) {
                this.versionCompareSpace = await this.spaceApiService.getSpace(
                    SpaceIdentifier.fromEntity(this.currentEntity)
                );
            }
            await this.refreshComparisonOnEntityChange();
        });
    }

    ngOnInit() {
        this.asyncInit().then();
    }

    public getModuleDataId(index: number, data: VersionCompareModuleData) {
        return data.clientUid;
    }

    public onProjectVersionsLoading(isStart: boolean) {
        this.log('onProjectVersionsLoading', isStart);
        this.isLoading = isStart;
    }
    public async onProjectVersionSelected(event: IProjectVersionSelectedEvent) {
        this.log('onProjectVersionSelected', event);
        if (event.isUserAction) {
            this.functionalLogService.logFunctionalAction(
                this.featureCode,
                CrudOperation.R
            );
        }
        const version = event.projectVersion;
        this.selectedVersion = version;
        this.versionCompareContext = {
            sourceVersion: this.currentVersionName,
            targetVersion: version.VersionName,
        };
        this.generateComparison();
    }

    protected async generateComparison() {
        const currentVersionId = this.currentVersionId;
        const selectedVersionId = this.selectedVersionId;
        if (!currentVersionId || !selectedVersionId) {
            return;
        }

        if (currentVersionId == selectedVersionId) {
            this.versionCompareModuleItems = [];
            this.comparationSingleItem = null;
            this.hasBeenExecuted = true;
            return;
        }

        const result = await this.uiSpinnerService.executeWithSpinner(() =>
            this.versioningUiService.compareVersions(
                currentVersionId,
                selectedVersionId,
                currentVersionId,
                this.currentSpaceId
            )
        );
        this.hasBeenExecuted = true;

        this.versionCompareModuleItems = this.computeComparisonData(
            result.Items
        );
        this.filterModulesDataByStatus();
        this.comparationSingleItem = result.singleItem;
    }

    protected async refreshComparisonOnEntityChange() {}

    protected filterModulesDataByStatus() {
        this.mappedModuleData = new Map<string, VersionCompareModuleData[]>();
        const createdModulesData = (comparisonOperation: CompareOperation) => {
            return this.versionCompareModuleItems.map(
                (moduleData: VersionCompareModuleData) => {
                    const moduleItems = moduleData.Items.filter(
                        (item) => item.Operation == comparisonOperation
                    );
                    return new VersionCompareModuleData(
                        moduleData.ModuleName,
                        moduleItems
                    );
                }
            );
        };
        this.mappedModuleData.set(
            ViewIdentifier.VersioningComparatorCreated,
            createdModulesData(CompareOperation.Added)
        );
        this.mappedModuleData.set(
            ViewIdentifier.VersioningComparatorModified,
            createdModulesData(CompareOperation.Updated)
        );
        this.mappedModuleData.set(
            ViewIdentifier.VersioningComparatorDeleted,
            createdModulesData(CompareOperation.Deleted)
        );

        this.onActiveTabItem(this.tabsData.tabItems[0]);
        this.tabsData.tabItems.forEach((tabItem) => {
            const modulesData = this.mappedModuleData.get(tabItem.tabId);
            tabItem.contentDataCount = CollectionsHelper.sum(
                modulesData,
                (value) => value.Items?.length ?? 0
            );
        });
    }

    protected computeComparisonData(
        items: ComparisonItem[]
    ): VersionCompareModuleData[] {
        return ['Property', 'Model', 'DataProcessing', 'SoftwareElement'].map(
            (name) =>
                new VersionCompareModuleData(
                    name,
                    items?.filter((i) => i.Module === name) ?? []
                )
        );
    }

    private async asyncInit() {
        if (this.currentEntity instanceof Organization) {
            return;
        }
        this.log('asyncInit');

        this.versionCompareSpace =
            this.currentSpace ??
            (await this.spaceApiService.getSpace(
                SpaceIdentifier.fromEntity(this.currentEntity)
            ));
        await this.refreshComparisonOnEntityChange();

        if (this.toolParam && !(this.currentEntity instanceof Project)) {
            this.selectedVersion = this.toolParam;
            this.versionCompareContext = {
                sourceVersion: this.currentVersionName,
                targetVersion: this.toolParam.VersionName,
            };
        }
    }

    private onActiveTabItem(tabItem: ITabViewItem) {
        this.currentActiveTabId = tabItem.tabId;
        this.displayedModuleData = this.mappedModuleData.get(
            this.currentActiveTabId
        );
    }
}
