import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

@Directive({
    selector: 'input[type="file"][dxyInputFileChange]',
    standalone: true,
})
export class DxyInputFileChangeDirective extends DxyBaseDirective {
    @Output() filesChange = new EventEmitter<File[]>();

    constructor(private elementRef: ElementRef<HTMLInputElement>) {
        super();
    }

    @HostListener('change', ['$event'])
    private onInputFileChange(e: Event) {
        const fileList = this.elementRef.nativeElement.files;
        this.log('onInputFileChange', e, fileList);
        const files = fileList && Array.from(fileList);
        this.filesChange.emit(files);
    }
}
