import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { withLoading } from '@datagalaxy/core-ui';
import { MsTeamsApiService } from '../api/ms-teams-api.service';
import { msTeamsNotificationChannel } from '../ms-teams-notification-channel';
import { UserNotificationChannelsStateService } from '../state/user-notification-channels-state.service';
import { ToasterService } from '../../services/toaster.service';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

/**
 * ## Role
 * MsTeams Notifications deactivation modal
 */
@Component({
    selector: 'app-ms-teams-notifications-deactivation-modal',
    templateUrl: './ms-teams-notifications-deactivation-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class MsTeamsNotificationsDeactivationModalComponent extends DxyBaseModalComponent<
    void,
    boolean
> {
    private channelName = msTeamsNotificationChannel.name;

    constructor(
        dialogRef: MatDialogRef<
            MsTeamsNotificationsDeactivationModalComponent,
            boolean
        >,
        private userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private msTeamsApiService: MsTeamsApiService,
        private toasterService: ToasterService
    ) {
        super(dialogRef, undefined);
    }

    @withLoading()
    public async deactivateNotifications() {
        try {
            await this.msTeamsApiService.deactivateNotifications();
            this.userNotificationChannelsStateService.setUserChannelActivation(
                this.channelName,
                false
            );
        } catch (error) {
            this.toasterService.errorToast({
                messageKey: 'UI.NotificationChannels.MsTeams.deactivationError',
            });
        } finally {
            this.result = true;
            this.onCloseSubmit();
        }
    }
}
