<!--Import-Entity-Selection-->
<div
    [matMenuTriggerFor]="menu"
    class="current-entity-wrapper"
    #menuTrigger="matMenuTrigger"
    (menuOpened)="openChange.emit(true)"
    (menuClosed)="openChange.emit(false)"
    [dxyDataTestId]="dataTypeName + '-select'"
>
    <span
        *ngIf="!hasCurrentEntity"
        [translate]="getTranslationKey('fieldPlaceholderLbl')"
    ></span>
    <span
        *ngIf="hasCurrentEntity"
        class="entity-icon"
        [ngClass]="getEntityGlyphClass(subTypeName)"
    ></span>
    <span *ngIf="hasCurrentEntity">{{ currentEntityName }}</span>
    <button mat-icon-button><i class="glyph-arrow-drop-down"></i></button>
</div>

<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu"
    yPosition="below"
    xPosition="before"
>
    <div class="header not-an-item" (click)="$event.stopPropagation()">
        <div class="entity-operation-line">
            <mat-radio-group
                [(ngModel)]="isUpdate"
                (ngModelChange)="onChangeMode($event)"
            >
                <mat-radio-button
                    [value]="false"
                    [class.disabled-option]="!hasEntityCreationAccess"
                    [disabled]="!hasEntityCreationAccess"
                    [dxyDataTestId]="'create-new-' + dataTypeName + '-button'"
                >
                    <span
                        [translate]="getTranslationKey('creationBtnLbl')"
                    ></span>
                </mat-radio-button>
                <mat-radio-button
                    [value]="true"
                    [matTooltip]="getUpdateRadioButtonTooltip()"
                    [disabled]="!isUpdateAllowed"
                    [dxyDataTestId]="'use-existing-' + dataTypeName + '-button'"
                >
                    <span
                        [translate]="getTranslationKey('updateBtnLbl')"
                    ></span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <!--Sub type -->
        <div
            *ngIf="!isUpdate"
            class="source-type-line menu-item"
            (click)="$event.stopPropagation()"
            [ngClass]="sourceTypeDisabledClass"
        >
            <div
                class="selected-source"
                [matMenuTriggerFor]="sourceMenu"
                #sourceMenuTrigger="matMenuTrigger"
            >
                <span
                    class="entity-icon"
                    [ngClass]="getEntityGlyphClass(subTypeName)"
                ></span>
                <span
                    *ngIf="!subTypeName"
                    class="selected-source"
                    [translate]="
                        getTranslationKey('newTargetTypePlaceholderLbl')
                    "
                >
                </span>
                <span
                    *ngIf="subTypeName"
                    class="selected-source"
                    [translate]="getSubTypeTranslationKey(subTypeName)"
                >
                </span>
                <button
                    *ngIf="!disableSourceType"
                    mat-icon-button
                    class="dg5-small-icon-button"
                >
                    <i class="glyph-arrow-drop-down"></i>
                </button>
            </div>
            <mat-menu
                #sourceMenu="matMenu"
                class="dg5-mat-menu import-source-type--mat-menu"
                xPosition="after"
                yPosition="below"
            >
                <ul class="source-type-menu">
                    <li
                        *ngFor="let typeName of sourceTypeNames"
                        (click)="onClickSourceType($event, typeName)"
                    >
                        <span
                            class="entity-icon"
                            [ngClass]="getEntityGlyphClass(typeName)"
                        ></span>
                        <span translate>{{
                            getSubTypeTranslationKey(typeName)
                        }}</span>
                    </li>
                </ul>
            </mat-menu>
        </div>
        <!--Name of the entity to create -->
        <div
            *ngIf="showEntityCreationField"
            class="import-entity-field-line"
            (click)="$event.stopPropagation()"
        >
            <dxy-field-text
                [name]="'temp-new-' + dataTypeName + '-name'"
                [(ngModel)]="temporaryNewEntityName"
                [labelKey]="getTranslationKey('newTargetNameTitle')"
                [placeholder]="
                    getTranslationKey('newTargetNamePlaceholderLbl') | translate
                "
                [mandatory]="true"
                [dxyDataTestId]="'new-' + dataTypeName + '-name-field'"
            ></dxy-field-text>
            <div
                *ngIf="isValidateAvailable()"
                (click)="onValidateNewEntityName()"
                class="validation-btn glyph-check"
                [dxyDataTestId]="
                    'validate-new-' + dataTypeName + '-name-button'
                "
            ></div>
        </div>

        <!-- Name of the entity to update -->
        <div *ngIf="isUpdate" class="search-bar">
            <dxy-search-input
                [searchString]="searchTerm"
                [showCancelSearch]="true"
                (onSearchString)="onSearch($event.searchString)"
            ></dxy-search-input>
        </div>
    </div>
    <ul *ngIf="isUpdate" class="body" (click)="$event.stopPropagation()">
        <li
            *ngFor="let entity of filteredEntities"
            (click)="onSelectedExistingEntity(entity)"
        >
            {{ getEntityName(entity) }}
        </li>
    </ul>
</mat-menu>
