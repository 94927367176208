import { StateParams } from '@uirouter/core';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable, Injector } from '@angular/core';
import { AppDataService } from './app-data.service';
import { ClientStorageService } from './clientStorage.service';
import {
    ErrorLogin,
    ErrorLoginType,
} from '../shared/util/app-types/errors.types';
import { RealTimeCommService } from './realTimeComm.service';
import { SecurityService } from './security.service';
import { UserService } from './user.service';
import { BrowserInfoUtil } from '../shared/util/BrowserInfoUtil';
import { BrowserInformation } from '../shared/util/app-types/BrowserInformation';
import { NavigationService } from './navigation.service';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { ModelerService } from '../modeler/services/modeler.service';
import {
    BaseServiceParameter,
    isUnsuccessfulApiError,
} from '@datagalaxy/data-access';
import { MasterDataService } from '@datagalaxy/webclient/modeler/data-access';
import {
    AuthApiService,
    ExternalSystemLoginParameter,
    SystemLoginParameter,
    SystemLoginResult,
} from '@datagalaxy/webclient/auth/data-access';
import { ClientApiService } from '@datagalaxy/webclient/client/data-access';
import {
    LoadSystemDataResult,
    SystemApiService,
} from '@datagalaxy/webclient/system/data-access';
import { LoggingHttpService } from '../core/backend-http-interceptors/logging-http.service';
import { ClientService } from '../client/client.service';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import {
    AppConfig,
    AppConfigService,
    IDgAppVersionInfo,
} from '@datagalaxy/webclient/config';

/**
 * @deprecated replaced by auth library authentication service
 */
@Injectable({ providedIn: 'root' })
export class LegacyLoginService extends BaseService {
    private static createSystemLoginParameter(
        email: string,
        password: string,
        browserInfo: BrowserInformation,
        loginToken: string,
        appInfo: IDgAppVersionInfo
    ): SystemLoginParameter {
        const lp = new SystemLoginParameter();
        lp.Login = email;
        lp.Password = password;
        lp.BrowserInfo = browserInfo;
        lp.BrowserInfo.dgAppName = appInfo.versionName;
        lp.BrowserInfo.dgAppVersion = appInfo.versionNumber;
        lp.BrowserInfo.dgAppBuildString = appInfo.buildString;
        lp.LoginToken = loginToken;
        return lp;
    }

    public isExternalLogin = false;

    public get canAutoLogin() {
        return (
            AppConfig.isLocalDev || !this.appConfigService.DISABLE_AUTO_LOGIN
        );
    }

    private get navigationService() {
        return (this._navigationService ??=
            this.injector.get(NavigationService));
    }
    private _navigationService: NavigationService;

    constructor(
        private injector: Injector,
        private appConfigService: AppConfigService,
        private realTimeCommService: RealTimeCommService,
        private clientStorageService: ClientStorageService,
        private systemApiService: SystemApiService,
        private clientApiService: ClientApiService,
        private appDataService: AppDataService,
        private masterDataService: MasterDataService,
        private modelerService: ModelerService,
        private userService: UserService,
        private securityService: SecurityService,
        private authApiService: AuthApiService,
        private loggingHttpService: LoggingHttpService,
        private clientService: ClientService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.setLoginInProgress(false);
        this.loggingHttpService.sessionRecovering$.subscribe(() =>
            this.doTryAutoReloginOnSessionLoss()
        );
    }

    private setLoginInProgress(value: boolean) {
        this.appDataService.setLoginInProgress(value);
    }

    public init() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('init');
        this.appDataService.initIfNotLoaded();
        this.appDataService.setFromCookieLogin(
            this.clientStorageService.readCookieLoginData()
        );
    }

    public setUseAutoLogin(value: boolean) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.appDataService.setUseAutoLogin(value);
    }
    public setCurrentUserLogin(value: string) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.appDataService.setCurrentUserLogin(value);
    }
    public setLoginFailed(value: boolean, clearIsLoginInProgress = false) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.appDataService.setLoginFailed(value);
        if (clearIsLoginInProgress) {
            this.clearIsLoginInProgress();
        }
    }

    public async externalLogin(loginToken: string) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('externalLogin', loginToken);
        const externalLoginParameter = new ExternalSystemLoginParameter(
            loginToken
        );
        try {
            const systemLoginResult =
                await this.authApiService.externalSystemLogin(
                    externalLoginParameter
                );
            this.isExternalLogin = systemLoginResult.IsExternalLogin;
            await this.handleSystemLoginSuccess(systemLoginResult);
        } catch (error) {
            if (isUnsuccessfulApiError<SystemLoginResult>(error)) {
                const errorLogin = this.createErrorLogin(error.error);

                if (errorLogin.type == ErrorLoginType.trialLicenseExpired) {
                    await this.navigationService.goToExpiredTrialError();
                } else {
                    await this.navigationService.goToMainError(
                        errorLogin.loginErrorMessage,
                        false
                    );
                }
            }

            throw error;
        }
    }

    public async systemLogin(
        useToken: boolean,
        password: string,
        from?: string
    ) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('systemLogin', from, useToken);

        // Gather browser info
        const browserInfo = BrowserInfoUtil.getBrowserInfo();
        const appInfo = this.appConfigService.getAppVersionInfo();

        //Init login data
        this.appDataService.clearLogginFailed();
        this.setLoginInProgress(true);
        this.clearUserSessionIds();

        // Create system login parameter
        const { login, loginToken } = this.appDataService.getLoginAndToken();
        const slp = useToken
            ? LegacyLoginService.createSystemLoginParameter(
                  '',
                  '',
                  browserInfo,
                  loginToken,
                  appInfo
              )
            : LegacyLoginService.createSystemLoginParameter(
                  login,
                  password,
                  browserInfo,
                  '',
                  appInfo
              );

        // Process system login
        try {
            this.masterDataService.clear(true); // masterDataService listens to system login deserialization event
            const systemLoginResult = await this.authApiService.systemLogin(
                slp
            );
            await this.handleSystemLoginSuccess(systemLoginResult);
            return systemLoginResult;
        } catch (error) {
            if (useToken) {
                this.clientStorageService.clearCookie();
            }

            if (isUnsuccessfulApiError<SystemLoginResult>(error)) {
                throw this.createErrorLogin(error.error);
            }

            this.setLoginInProgress(false);
            this.appDataService.setSystemAuthenticated();

            throw error;
        }
    }

    private clearUserSessionIds() {
        this.appDataService.clearUserSessionId();
    }

    public async login(useToken: boolean, password: string) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.setLoginInProgress(true);
        this.appDataService.clearLogginFailed();

        this.clearUserSessionIds();

        await this.systemLogin(useToken, password, 'login-authent');
    }

    public async logout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        if (this.isExternalLogin) {
            await this.navigationService.goExternalLogout();
        } else {
            await this.navigationService.goLogout();
        }
    }

    public async logoutOnSessionLoss() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('logoutOnSessionLoss');
        await this.doLogout();
        return this.navigationService.goLogout();
    }
    private async doLogout() {
        this.appDataService.setLoggedout();
        this.clearUserSessionIds();
        try {
            await this.realTimeCommService.logout();
        } catch (err) {
            this.warn(err);
        }
    }

    private clearCookie() {
        return this.clientStorageService.clearCookie();
    }

    public clearIsLoginInProgress() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.setLoginInProgress(false);
    }

    public handleSystemStartupStateParameters(stateParams: StateParams) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        if (stateParams['al'] === '1') {
            this.appDataService.setUseAutoLogin(true);
        }
        if (stateParams['cc'] === '1') {
            this.clearCookie();
        }
    }

    public getLoginError(type: ErrorLoginType) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        return new ErrorLogin(undefined, type);
    }

    /**
     *
     * @deprecated use client-service-manager instead
     */
    private async handleSystemLoginSuccess(
        systemLoginResult: SystemLoginResult
    ) {
        this.clearIsLoginInProgress();
        this.appDataService.setForSystemLoginSuccess(systemLoginResult);
        const lsdr: LoadSystemDataResult =
            await this.systemApiService.loadSystemData(
                new BaseServiceParameter()
            );
        this.masterDataService.setJsonTypes(lsdr.JsonTypes);
        this.modelerService.setSystemDataTypeSettings(
            lsdr.SystemDataTypeSettings
        );
        EntityTypeUtil.initMappings(lsdr.EntityTypeMappings);
        this.userService.setSystemUsersGroups(lsdr.SystemUserGroups);
        this.securityService.setSystemRoles(lsdr.SystemRoles);
    }

    public async sessionKeepAlive() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        // no need to send keep alive if no session
        if (!this.appDataService?.userSessionId) {
            return;
        }

        try {
            await this.clientApiService.keepAlive();
        } catch (err) {
            this.warn('sessionKeepAlive', err);
            // if ( err instanceof ErrorApi && err.type == ErrorApiType.SessionNotFound ) {
            //     this.clearUserSessionIds()
            // }
        }
    }

    public async logoutSessionTimeout() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        await this.doLogout();
        try {
            await this.clientApiService.clientLogout();
        } catch (err) {
            this.warn('logoutSessionTimeout', err);
        }
    }

    private createErrorLogin(apiResult: SystemLoginResult) {
        let loginErrorMessageReason = 'lblServerError';
        let type = ErrorLoginType.loginFailed;

        if (apiResult.IsErrorAccessDenied) {
            type = ErrorLoginType.notAuthenticated;
            loginErrorMessageReason = 'lblAuthenticationFailed';
        } else if (apiResult.IsErrorUserLicenseExpired) {
            if (apiResult.IsTrial) {
                type = ErrorLoginType.trialLicenseExpired;
            }
            loginErrorMessageReason = 'lblLicenseExpired';
        } else if (apiResult.IsErrorUserLicenseNotStarted) {
            loginErrorMessageReason = 'lblLicenseNotStarted';
        } else if (apiResult.IsErrorUserNotLicensed) {
            loginErrorMessageReason = 'lblNoLicense';
        }

        return new ErrorLogin(
            apiResult,
            type,
            `UI.Login.${loginErrorMessageReason}`
        );
    }

    private async doTryAutoReloginOnSessionLoss() {
        if (!this.appDataService.isAuthenticatedAndHasToken()) {
            return false;
        }

        try {
            const result = await this.systemLogin(true, null);
            await this.clientService.clientLogin(result.CurrentClientId);
            this.loggingHttpService.notifySessionRecovered(true);

            // not awaited
            this.realTimeCommService.login(
                this.appDataService.getRealTimeLoginData('api')
            );

            return true;
        } catch (e) {
            this.log('tryAutoReloginOnSessionLoss-catch', e);
            this.loggingHttpService.notifySessionRecovered(false);
            return false;
        }
    }
}
