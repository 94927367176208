import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    Input,
    OnChanges,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { TextGridCellComponent } from './text-grid-cell/text-grid-cell.component';
import { GridCellType, IGridCellComponent } from './grid-cell.types';
import { CustomGridCellComponent } from './custom-grid-cell/custom-grid-cell.component';
import { TColDef } from '../grid-column/grid-column.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    standalone: true,
    selector: 'dxy-grid-cell',
    template: `<ng-container #container></ng-container>`,
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellComponent<TRow>
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() col?: TColDef<TRow>;
    @Input() item?: TRow;

    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef?: ViewContainerRef;

    private componentRef?: ComponentRef<IGridCellComponent<TRow>>;

    private static getCellComponentType<TRow>(
        col?: TColDef<TRow>
    ): Type<IGridCellComponent<TRow>> | null {
        switch (col?.type) {
            case GridCellType.text:
                return TextGridCellComponent<TRow>;
            case GridCellType.custom:
                return CustomGridCellComponent<TRow>;
            default:
                return CustomGridCellComponent<TRow>;
        }
    }

    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['col', 'item'], () => {
            this.setComponentInputs(this.componentRef);
        });
    }

    public refreshInputs() {
        this.setComponentInputs(this.componentRef);
    }

    public refreshLayout(): void {
        this.refreshComponentLayout(this.componentRef);
    }

    private initInnerComponent() {
        const componentType = GridCellComponent.getCellComponentType(this.col);
        if (!componentType) {
            return;
        }
        const componentRef =
            this.viewContainerRef?.createComponent(componentType);
        this.componentRef = componentRef;

        this.setComponentInputs(componentRef);
    }

    private setComponentInputs(
        componentRef?: ComponentRef<IGridCellComponent<TRow>>
    ) {
        if (!componentRef) {
            return;
        }
        componentRef.setInput('col', this.col);
        componentRef.setInput('item', this.item);

        componentRef.changeDetectorRef.detectChanges();
    }

    private refreshComponentLayout(
        componentRef?: ComponentRef<IGridCellComponent<TRow>>
    ) {
        if (!componentRef) {
            return;
        }
        componentRef.instance?.refreshLayout?.();
        componentRef.changeDetectorRef.detectChanges();
    }
}
