import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyNavbarProfileDropdownComponent } from '../dxy-navbar-profile-dropdown/dxy-navbar-profile-dropdown.component';
import { UIRouterModule } from '@uirouter/angular';

@Component({
    selector: 'dxy-navbar',
    templateUrl: './dxy-navbar.component.html',
    styleUrls: [
        './dxy-navbar.component.scss',
        './dxy-navbar.component.responsive.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [UIRouterModule, DxyNavbarProfileDropdownComponent],
})
export class DxyNavbarComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}
