/**
 * This function removes all aria-owns attributes from the DOM.
 * This is necessary because the aria-owns attribute is not well supported by chromium in a mat-select context.
 * See https://github.com/angular/components/issues/28905 for more information.
 * To be removed when we upgrade to Angular 17.
 */
export const removeAllIncomingAriaOwnsAttributes = () => {
    document.addEventListener('DOMNodeInserted', function () {
        const elements = document.querySelectorAll('[aria-owns]');

        elements.forEach((element) => {
            element.removeAttribute('aria-owns');
        });
    });
};
