import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    AuthApiService,
    CheckSecurityTokenParameter,
    InitPasswordParameter,
    RequestPasswordResetParameter,
    ResetPasswordParameter,
} from '@datagalaxy/webclient/auth/data-access';

@Injectable({ providedIn: 'root' })
export class AccountApiService extends BaseService {
    constructor(private authApiService: AuthApiService) {
        super();
    }

    public initPassword(securityToken: string, newPassword: string) {
        const parameter = new InitPasswordParameter(securityToken, newPassword);
        return this.authApiService.initPassword(parameter);
    }

    public requestPasswordReset(email: string) {
        const rpr = new RequestPasswordResetParameter(email);
        return this.authApiService.requestPasswordReset(rpr);
    }

    public resetPassword(securityToken: string, newPassword: string) {
        const rp = new ResetPasswordParameter(securityToken, newPassword);
        return this.authApiService.resetPassword(rp);
    }

    public checkSecurityToken(securityToken: string, email: string) {
        const ctp = new CheckSecurityTokenParameter(securityToken, email);
        return this.authApiService.checkSecurityToken(ctp);
    }
}
