import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectivityService } from '../connectivity.service';

@Injectable({ providedIn: 'root' })
export class ConnectionEntitySelectionService {
    private _isLoading = false;
    private _isAllObjectsSelected = false;
    private _selectedObjectNames: string[] = [];
    private selectedObjectsTotal = 0;

    constructor(
        private connectivityService: ConnectivityService,
        private translate: TranslateService
    ) {}

    public getObjectNames() {
        return this.selectedObjectNames.length === this.selectedObjectsTotal ||
            this.isAllObjectsSelected
            ? []
            : this.selectedObjectNames;
    }

    get selectedObjectNames(): string[] {
        return this._selectedObjectNames;
    }

    set selectedObjectNames(value: string[]) {
        this._selectedObjectNames = value;
        this.selectedObjectsTotal = value.length;
    }

    get isAllObjectsSelected(): boolean {
        return this._isAllObjectsSelected;
    }

    set isAllObjectsSelected(value: boolean) {
        this._isAllObjectsSelected = value;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    public clear() {
        this._isLoading = false;
        this._isAllObjectsSelected = false;
        this._selectedObjectNames = [];
        this.selectedObjectsTotal = 0;
    }

    public async getCurrentObjects(connectionId: string, spaceId: string) {
        const result = await this.connectivityService.getConnectionObjects(
            spaceId,
            connectionId
        );
        // QUESTION: should we update this.selectedObjectNames?
        this.selectedObjectsTotal = result.objects.length;
        return result.objects;
    }

    public get selectedObjectsText() {
        const selectedCount = this._isAllObjectsSelected
            ? this.selectedObjectsTotal
            : this._selectedObjectNames.length;
        return this.selectedObjectsTotal >= selectedCount
            ? this.translate.instant(
                  'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.selection',
                  {
                      selection: selectedCount,
                      total: this.selectedObjectsTotal,
                  }
              )
            : '';
    }
}
