import { deserialize } from 'cerialize';
import { IJsonEvent, registerTypedEvent } from '../events';
import { TranslationBatchCompletedActivityEventContent } from './translation-batch-completed-activity-event-content';

@registerTypedEvent('TranslationBatchCompletedActivityEvent')
export class TranslationBatchCompletedActivityEvent
    implements IJsonEvent<TranslationBatchCompletedActivityEventContent>
{
    @deserialize EventTypeName: string;
    @deserialize CreationTime: string;
    @deserialize ClientGuid: string;
    @deserialize Content: TranslationBatchCompletedActivityEventContent;
}
