import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BaseServiceParameter, BaseServiceResult } from '../backend';
import {
    catchError,
    lastValueFrom,
    map,
    Observable,
    of,
    takeUntil,
    throwError,
} from 'rxjs';
import { GenericDeserialize, INewable, Serialize } from 'cerialize';
import { BACKEND_API_CONFIG, IApiCallOptions, IApiConfig } from '../types';
import { BackendHttpClient } from './backend-http-client.service';
import { HttpContext, HttpContextToken } from '@angular/common/http';
import { isUnsuccessfulApiError } from '../api-error.helper';

/**
 * Specify if the request is coming from legacy backend service which uses
 * BaseService params and results and which is not using REST
 */
export const IS_BACKEND_API = new HttpContextToken<boolean>(() => false);

/**
 * ## Role
 * Allows to make API calls to DataGalaxy's backend
 */
@Injectable()
export class BackendApiService {
    private get baseUrl() {
        return this.config?.baseUrl;
    }

    constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
        private backendHttpClient: BackendHttpClient
    ) {}

    public post<
        TResult extends BaseServiceResult,
        TParam extends BaseServiceParameter = BaseServiceParameter
    >(
        apiString: string,
        apiParameter: TParam,
        resultType?: INewable<TResult>,
        options?: IApiCallOptions
    ): Observable<TResult> {
        const callUrl = Location.joinWithSlash(
            this.baseUrl,
            `api/${apiString}`
        );

        const param = options?.skipSerialization
            ? apiParameter
            : Serialize(apiParameter);
        return this.backendHttpClient
            .post<TResult>(callUrl, param, {
                ...options,
                context: new HttpContext().set(IS_BACKEND_API, true),
            })
            .pipe(
                takeUntil(options?.cancelNotifier || of()),
                map((data) => this.deserializeResult(data, resultType)),
                catchError((error) =>
                    this.deserializeUnsuccessfulError(error, resultType)
                )
            );
    }

    public async postPromise<
        TResult extends BaseServiceResult = BaseServiceResult,
        TParam extends BaseServiceParameter = BaseServiceParameter
    >(
        apiString: string,
        apiParameter: TParam,
        type?: INewable<TResult>,
        options?: IApiCallOptions
    ): Promise<TResult> {
        return await lastValueFrom(
            this.post(apiString, apiParameter, type, options)
        );
    }

    private deserializeUnsuccessfulError<T extends BaseServiceResult>(
        error: any,
        resultType?: INewable<T>
    ) {
        if (isUnsuccessfulApiError<T>(error)) {
            error.error = this.deserializeResult(error.error, resultType);
        }
        return throwError(() => error);
    }

    private deserializeResult<TParam, TResult extends BaseServiceResult>(
        data: TParam,
        resultType?: INewable<TResult>
    ) {
        return resultType ? GenericDeserialize(data, resultType) : data;
    }
}
