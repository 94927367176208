import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import {
    Column,
    ForeignKey,
    PrimaryKey,
    Table,
} from '@datagalaxy/webclient/modeler/data-access';

export class ForeignKeyGridData {
    public get foreignKey() {
        return this._foreignKey;
    }
    public get foreignKeyId() {
        return this._foreignKey.ReferenceId;
    }

    public get isForeignKeyMandatory() {
        return this._foreignKey.IsMandatory;
    }
    public get isTechnicalForeignKey() {
        return !this._foreignKey.IsFunctionalOnly;
    }
    public get foreignKeyDisplayName() {
        return this._foreignKey.DisplayName;
    }
    public get foreignKeyTechnicalName() {
        return this._foreignKey.TechnicalName;
    }
    public get primaryKeyTechnicalName() {
        return this.primaryKey?.TechnicalName ?? null;
    }
    public get parentTableTechnicalName() {
        return this.primaryKeyTable?.TechnicalName;
    }
    public get parentColumnTechnicalName() {
        return this.primaryKeyColumn?.TechnicalName ?? null;
    }
    public get childTableTechnicalName() {
        return this.childTable?.TechnicalName;
    }
    public get childColumnTechnicalName() {
        return this.childColumn?.TechnicalName ?? null;
    }
    public get parentTableType() {
        return EntityTypeUtil.getMapping(
            this.primaryKeyTable?.EntityType
        )?.getTranslateKey();
    }
    public get childTableType() {
        return EntityTypeUtil.getMapping(
            this.childTable?.EntityType
        )?.getTranslateKey();
    }

    constructor(
        private _foreignKey: ForeignKey,
        private primaryKey?: PrimaryKey,
        private primaryKeyTable?: Table,
        private primaryKeyColumn?: Column,
        private childTable?: Table,
        private childColumn?: Column
    ) {}
}
