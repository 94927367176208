<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Versioning.VersioningModal.updateTitle
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            name="versionName"
            labelKey="UI.Versioning.VersioningModal.versionName"
            [(ngModel)]="result.versionName"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
        ></dxy-field-text>

        <dxy-field-text
            name="versionDescription"
            labelKey="UI.Versioning.VersioningModal.versionDescription"
            [(ngModel)]="result.versionDescription"
            (ngModelChange)="updateCanCreate()"
        ></dxy-field-text>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblSave' | translate"
        (onCloseCancel)="onCloseCancel()"
        featureCode="VERSION,U"
    ></dxy-modal-footer>
</form>
