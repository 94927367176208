import { Injectable } from '@angular/core';
import { AppConfig, AppConfigService } from '@datagalaxy/webclient/config';
import { Subject, Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const defaultDelayMinutes = 5;

@Injectable({ providedIn: 'root' })
export class AppVersionUpdateService {
    private readonly appVersionChange = new Subject<{
        latest: string;
        current: string;
    }>();

    private delayMinutes: number;

    public get appVersionChange$() {
        return this.appVersionChange.asObservable();
    }

    constructor(private appConfigService: AppConfigService) {
        this.delayMinutes =
            this.appConfigService.versionCheckMinutes || defaultDelayMinutes;
    }
    private intervalSubscription?: Subscription;

    public startAppVersionChangeDetection() {
        this.intervalSubscription = interval(1000 * 60 * this.delayMinutes)
            .pipe(switchMap(() => this.check()))
            .subscribe();
    }

    private async check() {
        const current = this.appConfigService.APP_VERSION_NUMBER;
        const latest = await AppConfig.getLatestVersionNumber();

        const versionHasChanged =
            this.normalizeVersionNumber(latest) !=
            this.normalizeVersionNumber(current);
        if (versionHasChanged) {
            const data = { latest, current };
            this.appVersionChange.next(data);
            this.appVersionChange.complete();
            this.intervalSubscription?.unsubscribe();
        }
    }

    private normalizeVersionNumber(s: string | number) {
        return s?.toString().trim().toLowerCase() ?? '';
    }
}
