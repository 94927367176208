import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IHasAttributeFilterModels } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { AttributeFilterModel } from '../../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { SearchResult, SearchService } from '../../search/search.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { ISpotlightSection } from '../dxy-spotlight-result-section/spotlight-result-section.types';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import { CurrentSearch } from '../../search/models/CurrentSearch';
import { NavigationService } from '../../services/navigation.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { TranslateModule } from '@ngx-translate/core';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { AttributeQuickFiltersComponent } from '../../shared/attribute/attribute-filter/attribute-quick-filters/attribute-quick-filters.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DxySpaceVersionSelectorComponent } from '../../space-selector/dxy-space-version-selector/dxy-space-version-selector.component';
import { DxySpotlightResultSectionComponent } from '../dxy-spotlight-result-section/dxy-spotlight-result-section.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'dxy-spotlight-result-container',
    templateUrl: './dxy-spotlight-result-container.component.html',
    styleUrls: ['./dxy-spotlight-result-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        DxySpotlightResultSectionComponent,
        DxySpaceVersionSelectorComponent,
        MatLegacyButtonModule,
        MatLegacyTooltipModule,
        AttributeQuickFiltersComponent,
        SpinnerComponent,
        CdkDropList,
        TranslateModule,
    ],
})
export class DxySpotlightResultContainerComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() previewResult: SearchResult;
    @Input() lastViewedEntities: IHasHddData[];
    @Input() lastSearchViews: CurrentSearch[];
    @Input() loading: boolean;
    @Input() currentSearch: CurrentSearch;
    @Input() quickFilters: Filter[];
    @Input() filteredUsers: AttributeMetaValue[];
    @Input() filteredTags: AttributeMetaValue[];
    @Input() filteredDomains: AttributeMetaValue[];
    @Input() spaceIdr: ISpaceIdentifier;

    @Output() onSearch = new EventEmitter();
    @Output() onClickFilteredSearch =
        new EventEmitter<IHasAttributeFilterModels>();
    @Output() onClickResultItem = new EventEmitter();
    @Output() onClearLastSearches = new EventEmitter();
    @Output() onClosePane = new EventEmitter();
    @Output() onSubmit = new EventEmitter();
    @Output() onSelectQuickFilter = new EventEmitter<AttributeFilterModel>();
    @Output() onModuleShowMore = new EventEmitter<ISpotlightSection>();
    @Output() onSpaceVersionMenuOpenClose = new EventEmitter<boolean>();
    @Output() onSelectFilterItem = new EventEmitter<AttributeMetaValue>();
    @Output() spaceVersionSelected = new EventEmitter<ISpaceIdentifier>();

    //#region html bindings
    public resultCategories: ISpotlightSection[];
    public filterCategories: ISpotlightSection[];
    public isFilterDropdownVisible = false;
    public isEmptyLastViewedEntities: boolean;
    public get isSingleWorkspace() {
        return this.appSpaceService.isSingleWorkspace();
    }
    public get hasQuickFilters() {
        return !!this.quickFilters?.length;
    }
    public get showSpaceVersionSelector() {
        return !this.isSingleWorkspace;
    }
    public get hasResults(): boolean {
        return !!(
            this.previewResult?.count ||
            this.filteredDomains?.length ||
            this.filteredTags?.length ||
            this.filteredUsers?.length ||
            (!this.previewResult &&
                (this.lastViewedEntities || this.lastSearchViews))
        );
    }
    public get showEmptyLastViewedEntities() {
        return this.isEmptyLastViewedEntities && !this.loading;
    }
    public get showEmptySearchResult() {
        return !this.hasResults && !this.loading;
    }
    public get showSearchResult() {
        return this.hasResults && !this.loading;
    }
    public get spotlightDragDrop() {
        return this.searchService.spotlightDragDrop;
    }
    //#endregion

    private readonly lastSearchesSectionName = 'last-searches';
    private isSpaceVersionMenuOpen: boolean;

    constructor(
        private appSpaceService: AppSpaceService,
        private navigationService: NavigationService,
        private searchService: SearchService,
        private ngZone: NgZone
    ) {
        super();
    }

    ngOnInit() {
        this.log('$onInit', this.spaceIdr);
        this.makeResultCategories();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            [
                'previewResult',
                'filteredUsers',
                'filteredTags',
                'filteredDomains',
                'spaceIdr',
                'lastViewedEntities',
                'lastSearchViews',
            ],
            () => this.makeResultCategories()
        );
    }

    public onSectionShowMore(section: ISpotlightSection) {
        if (section.name != this.lastSearchesSectionName) {
            this.onModuleShowMore.emit(section);
        }
    }

    public goToSearchSettings() {
        return this.navigationService.goToUserSettingsSearch();
    }

    public onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        this.log('onSpaceVersionSelected', spaceIdr);
        this.spaceVersionSelected.emit(spaceIdr);
    }
    public onSpaceVersionMenuOpenCloseInternal(isOpen: boolean) {
        this.onSpaceVersionMenuOpenClose.emit(isOpen);
        if (isOpen) {
            this.log('onSpaceVersionMenuOpenCloseInternal-open');
            this.isSpaceVersionMenuOpen = true;
        } else {
            this.ngZone.runOutsideAngular(() => {
                // let time for outside-click to be prevented
                this.log(
                    'onSpaceVersionMenuOpenCloseInternal-closed-setTimeout'
                );
                this.isSpaceVersionMenuOpen = false;
            });
        }
    }

    public selectQuickFilterEvent(filter: AttributeFilterModel) {
        this.onSelectQuickFilter.emit(filter);
    }

    private makeResultCategories() {
        this.isEmptyLastViewedEntities = false;
        this.resultCategories = [];
        if (this.previewResult) {
            const exactMatchesItems = this.previewResult.allItems.filter(
                (item) => !!item.ExactMatchAttributes?.size
            );
            const exactMatchesSection = {
                translateKey: 'UI.Spotlight.section.exactMatches',
                name: 'exact-matches',
                isFilter: false,
                initialRowsCount: 10,
                items: exactMatchesItems,
                totalText: exactMatchesItems.length.toString(),
                noNavLink: true,
            };
            const items = this.previewResult.allItems.filter(
                (item) => !item.ExactMatchAttributes?.size
            );
            const totalItems =
                this.previewResult.moreResultsTotalCount -
                (exactMatchesSection.items?.length || 0);

            if (!items.length) {
                exactMatchesSection.totalText += '+';
            }
            this.resultCategories.push({
                translateKey: exactMatchesSection.items.length
                    ? 'UI.Spotlight.section.moreResults'
                    : 'UI.Spotlight.section.allResults',
                name: 'more-result',
                isFilter: false,
                initialRowsCount: exactMatchesSection.items.length ? 10 : 20,
                items,
                totalText:
                    totalItems > items.length
                        ? `${items.length}+`
                        : items.length.toString(),
            });

            if (exactMatchesSection?.items?.length) {
                this.resultCategories.unshift(exactMatchesSection);
            }
        } else {
            if (this.lastSearchViews?.length) {
                this.resultCategories.push({
                    translateKey: 'UI.Spotlight.section.lastSearches',
                    name: this.lastSearchesSectionName,
                    displayAllResults: true,
                    isFilter: false,
                    items: this.lastSearchViews,
                    isClearSectionEnabled: true,
                });
            }
            if (this.lastViewedEntities?.length) {
                this.resultCategories.push({
                    translateKey: 'UI.Spotlight.section.lastViewed',
                    name: 'last-viewed',
                    displayAllResults: true,
                    isFilter: false,
                    items: this.lastViewedEntities,
                });
            } else {
                this.isEmptyLastViewedEntities = true;
            }
        }

        if (this.filteredUsers?.length) {
            this.filterCategories = CollectionsHelper.groupBy(
                this.filteredUsers,
                (amv) => amv.spaceGovernanceUserDto.AttributeKey,
                (groupKey, groupUsers) =>
                    ({
                        name: groupKey,
                        translateKey: `DgServerTypes.BaseData.fields.${groupKey}`,
                        isFilter: true,
                        items: groupUsers,
                    } as ISpotlightSection)
            );
            this.isFilterDropdownVisible = true;
        } else if (this.filteredTags?.length) {
            this.filterCategories = [
                {
                    name: 'tags',
                    translateKey: 'DgServerTypes.BaseData.fields.Tags',
                    items: this.filteredTags,
                    isFilter: true,
                    displayAllResults: true,
                },
            ];
            this.isFilterDropdownVisible = true;
        } else if (this.filteredDomains?.length) {
            this.log(
                'makeResultCategories-filteredDomains',
                this.filteredDomains
            );
            this.filterCategories = [
                {
                    name: 'domains',
                    translateKey: 'DgServerTypes.PropertyType.BusinessDomain',
                    items: this.filteredDomains,
                    isFilter: true,
                    displayAllResults: true,
                },
            ];
            this.isFilterDropdownVisible = true;
        } else {
            this.isFilterDropdownVisible = false;
        }

        this.log('makeResultCategories-out', this.resultCategories);
    }
}
