import { ICellParams } from '../../cell-components';

export interface IGraphicalTextInputParams
    extends ICellParams<unknown, string> {
    readOnly?: boolean;
    value?: string;
    updateOn?: UpdateOn | UpdateOn[];
    onValueChange?: (newValue: string) => void;
    takeFocus?: boolean;
    inputClass?: string;
    placeholderText?: string;
    placeholderKey?: string;
    tooltipText?: string;
    tooltipKey?: string;
}

export enum UpdateOn {
    change = 0,
    blur,
    destroy,
    enter,
}
