import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import {
    DataLineageDataLink,
    DataLineageGenerationData,
    DataLineageItem,
} from './lineage';

export enum LineageOrientation {
    Both,
    Left,
    Right,
}
SerializableEnumeration(LineageOrientation);

@inheritSerialization(BaseServiceParameter)
export class GetDataLineageParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;
    @serialize public DataTypeName: string;
    @serialize public ExcludeSecondaryLinks: boolean;
    @serialize public IncludeRootChildren: boolean;
    @serializeAs(LineageOrientation) public Orientation: LineageOrientation;
    @serialize public ComputeLinkOrientationAndSwap: boolean;
    @serialize public MaxItemsCount: number;
    @serialize public IsLazyLoad?: boolean;
    @serialize public IsDirectLinksMode: boolean;

    constructor(
        entity: IEntityIdentifier,
        orientation = LineageOrientation.Both,
        directLinksOnly = false
    ) {
        super();
        this.DataReferenceId = entity.ReferenceId;
        this.DataTypeName = ServerType[entity?.ServerType];
        this.VersionId = entity.VersionId;
        this.ExcludeSecondaryLinks = true;
        this.IncludeRootChildren = true;
        this.Orientation = orientation;
        this.ComputeLinkOrientationAndSwap = true;
        this.MaxItemsCount = 0;
        this.IsDirectLinksMode = directLinksOnly;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetDataLineageResult extends BaseServiceResult {
    @deserialize public SourceDataLocalId!: string;
    @deserialize public SourceDataTypeName!: string;
    @deserializeAs(DataLineageItem) public Items!: DataLineageItem[];
    @deserializeAs(DataLineageDataLink)
    public DataLinks!: DataLineageDataLink[];
    @deserialize public LazyItemsCount!: number;

    /** Forced columns' layout.
     *  Note: When set, roots splitting (splitRoots) will not be enabled at start */
    public generations?: DataLineageGenerationData[];

    constructor() {
        super();
    }
}
