import { CollectionsHelper } from '@datagalaxy/core-util';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { GlyphService } from '../../../../services/glyph.service';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';

export abstract class BaseFilterListItemModel extends AttributeFilterModel {
    public values: AttributeMetaValue[];
    public get hasValues() {
        return this.values?.length > 0;
    }
    public get isListContainsAndEmpty() {
        return !this.hasValues && this.operator == FilterOperator.ListContains;
    }

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[]
    ) {
        super(attributeMeta, operators, filterFormData);
        this.values = [];
    }

    public getHddValues(): HierarchicalData[] {
        return this.values?.map((value) => value.HddData).filter((hdd) => hdd);
    }

    hasMultipleValues(): boolean {
        return this.values?.length > 1;
    }

    getFirstValue(): AttributeMetaValue {
        return this.values?.[0];
    }

    getValuesAsArray() {
        return this.values?.map((amv) => amv.Key) ?? [];
    }

    isValid() {
        return true;
    }

    setValuesFromDb(values: string[], hddValues?: HierarchicalData[]) {
        if (hddValues?.length) {
            this.values = hddValues?.map((hd) => {
                return AttributeMetaValue.fromHierarchicalData(
                    hd,
                    this.attributeMeta,
                    GlyphService.getColoredHdDataGlyph(hd)
                );
            });
        } else {
            this.values =
                (Array.isArray(values) &&
                    this.ListValues?.filter((lv) => values.includes(lv.Key))) ||
                [];
        }
    }

    hasValueKey(attributeMetaValueKey: string, compareAsLocalId = false) {
        if (!this.values?.length) {
            return false;
        }
        if (compareAsLocalId) {
            attributeMetaValueKey = getLocalId(attributeMetaValueKey);
            return this.values.some(
                (v) => getLocalId(v.Key) == attributeMetaValueKey
            );
        }
        return this.values.some((v) => v.Key == attributeMetaValueKey);
    }

    addValueIfKeyNotFound(amv: AttributeMetaValue, compareAsLocalId: boolean) {
        if (amv && !this.hasValueKey(amv.Key, compareAsLocalId)) {
            this.values.push(amv);
            return true;
        }
        return false;
    }

    protected sameValuesByKey(other: BaseFilterListItemModel) {
        return CollectionsHelper.contentEquals(
            this.values,
            other.values,
            true,
            true,
            (a, b) => (!a && !b) || a.Key == b.Key
        );
    }
}
