<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>UI.AboutInfo.title</h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <div class="info-container">
        <img
            class="dg_logo"
            src="/images/logo/logo-cloud.svg"
            alt="datagalaxy-logo"
        />
        <h2
            class="version"
            translate
            [translateParams]="{ version: platformVersion }"
        >
            UI.AboutInfo.versionNote
        </h2>
        <a
            *ngIf="showReleaseNote"
            mat-flat-button
            [href]="currentReleaseNoteHref"
            target="_blank"
            rel="noopener"
            dxyLogFunctional="RELEASE_NOTE,R"
        >
            <span translate>UI.AboutInfo.releaseNote</span>
        </a>
    </div>
    <span class="technical-info">webclient:{{ technicalInfo }}</span>
    <span class="copyright"
        >Copyright &copy; {{ currentYear }} - Data Galaxy SAS</span
    >
</div>
