<h2 translate>UI.Suggestions.Panel.title</h2>
<dxy-suggestion-link-filter
    (parameterChange)="onParameterChange($event)"
></dxy-suggestion-link-filter>
<div class="suggestions-grid-container" [class.hidden]="gridHidden$ | async">
    <dxy-grid
        [columns]="columns"
        [config]="gridConfig"
        [items]="rows"
    ></dxy-grid>
</div>
<div *ngIf="placeholderVisible$ | async" class="placeholder-container">
    <img
        src="/images/glossary-links-generation/links-generation-placeholder.svg"
        alt="UI.Suggestions.Panel.gridPlaceholder | translate"
    />
    <div translate>UI.Suggestions.Panel.gridPlaceholder</div>
</div>

<dxy-spinner *ngIf="isMainSpinnerVisible$ | async"></dxy-spinner>
<div class="action-container" *ngIf="hasSuggestions">
    <dxy-show-more-button
        *ngIf="showMoreButton"
        [loading]="loading$ | async"
        [disabled]="allSuggestionsBeingApplied$ | async"
        (click)="loadMore()"
    ></dxy-show-more-button>
    <app-suggestion-apply-all-button
        [disabled]="loading$ | async"
        [suggestions]="rows"
        [suggestionType]="SuggestionType.Link"
    ></app-suggestion-apply-all-button>
</div>
