import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UserNotificationChannelsStateService } from '../../notification-channels/state/user-notification-channels-state.service';
import { msTeamsNotificationChannel } from '../../notification-channels/ms-teams-notification-channel';
import { ToasterService } from '../../services/toaster.service';
import { withLoading } from '@datagalaxy/core-ui';
import { MsTeamsApiService } from '../../notification-channels/api/ms-teams-api.service';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

/**
 * ## Role
 * Display a user specific version of notification ms teams card
 */
@Component({
    selector: 'app-ms-teams-notifications-activation-modal',
    templateUrl: './ms-teams-notifications-activation-modal.component.html',
    styleUrls: ['./ms-teams-notifications-activation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class MsTeamsNotificationsActivationModalComponent extends DxyBaseModalComponent<
    void,
    boolean
> {
    private channelName = msTeamsNotificationChannel.name;

    constructor(
        private userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private toasterService: ToasterService,
        private msTeamsApiService: MsTeamsApiService,
        dialogRef: MatDialogRef<MsTeamsNotificationsActivationModalComponent>
    ) {
        super(dialogRef, undefined);
    }

    @withLoading()
    public async activateNotifications() {
        try {
            await this.userNotificationChannelsStateService.initUserNotificationChannels(
                true
            );
            const activable =
                this.userNotificationChannelsStateService.getUserChannelActivable(
                    this.channelName
                );
            if (!activable) {
                this.toasterService.errorToast({
                    messageKey:
                        'UI.NotificationChannels.MsTeams.adminHasDeactivated',
                });
                return;
            }
            await this.msTeamsApiService.activateNotifications();
            this.userNotificationChannelsStateService.setUserChannelActivation(
                this.channelName,
                true
            );
            this.toasterService.successToast({
                messageKey:
                    'UI.NotificationChannels.MsTeams.notificationsActivationSuccess',
            });
            this.onCloseSubmit();
        } catch {
            this.toasterService.errorToast({
                messageKey:
                    'UI.NotificationChannels.MsTeams.notificationsActivationError',
            });
        }
    }
}
