import { BaseService } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { SearchFacetFilterSettings } from './models/SearchFacetFilterSettings';
import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class SearchFacetSettingsService extends BaseService {
    public get settings() {
        return this._settings;
    }
    private _settings: SearchFacetFilterSettings;

    constructor(private userService: UserService) {
        super();
    }

    public async updateSettings() {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.searchFacetFilter.category,
            userSettingsValues.searchFacetFilter.routes.settings
        );
        let settings: SearchFacetFilterSettings;
        if (res?.Value) {
            settings = JSON.parse(res.Value);
        }
        if (
            !settings ||
            settings.version !=
                SearchFacetFilterSettings.defaultSettings.version
        ) {
            settings = SearchFacetFilterSettings.defaultSettings;
        }
        this._settings = settings;
    }

    public saveSettings(settings: SearchFacetFilterSettings) {
        const replacer = (k: string, v: any) =>
            k == '$$haskey' || k == 'displayName' || k == 'highlightText'
                ? undefined
                : v;
        this.userService.setUserSettingValue(
            userSettingsValues.searchFacetFilter.category,
            userSettingsValues.searchFacetFilter.routes.settings,
            JSON.stringify(settings, replacer)
        );
    }

    public getAttributeKeys() {
        return CollectionsHelper.flattenGroups(
            this._settings.groups,
            (g) =>
                g.filters
                    .filter((f) => f.selected)
                    .map((f) => f.type as string),
            true
        );
    }
}
