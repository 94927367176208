import { Component } from '@angular/core';
import { MultilingualFunnelService } from '../funnel/multilingual-funnel.service';
import { MultilingualReadMoreButtonDirective } from '../multilingual-read-more-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-multilingual-activator',
    templateUrl: './multilingual-activator.component.html',
    standalone: true,
    imports: [
        DxyButtonDirective,
        TranslateModule,
        MultilingualReadMoreButtonDirective,
    ],
})
export class MultilingualActivatorComponent {
    constructor(private funnelService: MultilingualFunnelService) {}
    protected activate() {
        this.funnelService.startFunnel();
    }
}
