import { deserialize, deserializeAs } from 'cerialize';
import { AttributeMetaType } from './attribute-meta-type';

export class AttributeObjectValue {
    @deserialize public ObjectId!: string;
    @deserializeAs(AttributeMetaType) public AttributeType!: AttributeMetaType;
    @deserialize public DisplayName!: string;
    @deserialize public TagColor!: string;
    @deserialize public TagDescription!: string;

    @deserialize public PersonUid!: string;
    @deserialize public PersonEmail!: string;
    @deserialize public PersonFullName!: string;
    @deserialize public PersonFirstName!: string;
    @deserialize public PersonLastName!: string;
    // This flag is used only with tags
    @deserialize public IsActive!: boolean;
}
