<div class="navbar-title-container">
    <ui-view name="navbarTitle"></ui-view>
    <ui-view name="navbarPanel"></ui-view>
</div>
<div class="navbar-content">
    <ng-content></ng-content>
</div>
<div class="dg_navItem--account">
    <dxy-navbar-profile-menu></dxy-navbar-profile-menu>
</div>
