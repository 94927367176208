import { FieldType } from '../../types/enums/field-type.enum';

export const powerBiConfig = [
    { payloadField: 'usage-technology', payloadValue: 'powerbi' },
    {
        payloadField: 'tenant-id',
        formField: 'tenantId',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'client-id',
        formField: 'clientId',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'workspace-names',
        formField: 'workspaceNames',
        payloadValue: [],
        type: FieldType.list,
        isFilter: true,
        listFieldTradKeys: {
            gridTitle: 'UI.Connector.Wizard.Step3.WorkspaceNames.Grid.title',
            gridAddBtn: 'UI.Connector.Wizard.Step3.WorkspaceNames.Grid.addBtn',
            modalTitle: 'UI.Connector.Wizard.Step3.WorkspaceNames.Modal.title',
            modalLabel: 'UI.Connector.Wizard.Step3.WorkspaceNames.Modal.value',
        },
    },
    {
        payloadField: 'client-secret',
        formField: 'clientSecret',
        isPassword: true,
        formMandatory: true,
    },
    {
        payloadField: 'ignore-hidden-fields',
        formField: 'ignoreHiddenFields',
        type: FieldType.boolean,
        formMandatory: false,
    },
];
