import { EntityCardDisplayMode } from '@datagalaxy/webclient/entity/ui';

export class EntityCardSettings {
    entityCardDisplay: EntityCardDisplayMode;
    technoImgUrl?: string;
    technoTooltip?: string;

    public get isGlyphDisplay() {
        return (
            !this.technoImgUrl ||
            this.entityCardDisplay == EntityCardDisplayMode.GlyphWithChip ||
            this.entityCardDisplay == EntityCardDisplayMode.GlyphOnly
        );
    }

    public get showEntityChip() {
        return (
            this.technoImgUrl &&
            (this.entityCardDisplay == EntityCardDisplayMode.GlyphWithChip ||
                this.entityCardDisplay == EntityCardDisplayMode.TechnoWithChip)
        );
    }

    constructor(
        entityCardDisplay: EntityCardDisplayMode,
        technoImgUrl?: string,
        technoTooltip?: string
    ) {
        this.entityCardDisplay = entityCardDisplay;
        this.technoImgUrl = technoImgUrl;
        this.technoTooltip = technoTooltip;
    }
}
