import { deserialize, inheritSerialization } from 'cerialize';
import { EntityActivityEventBase, registerTypedEvent } from '../events';

@inheritSerialization(EntityActivityEventBase)
@registerTypedEvent('EntityAttributeUpdatedEvent')
export class EntityAttributeUpdatedEvent extends EntityActivityEventBase {
    @deserialize AttributePath: string;
    @deserialize AttributeDisplayName: string;
    @deserialize IsCdp: boolean;
    @deserialize PreviousValue: string;
    @deserialize Value: string;

    public get attributeKey(): string {
        return this.AttributePath?.split('.').pop();
    }
}
