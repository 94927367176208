import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { Dom2dUtil, IXY, Point, Rect } from '@datagalaxy/core-2d-util';
import { ConnectorLabelProps } from './connector-label.types';
import { select } from 'd3-selection';
import { OrthoRouteUtil } from '../routing/orthogonal/OrthoRouteUtil';

export class ConnectorLabel {
    public static readonly class = 'connector-label';

    public readonly el: SVGGElement;

    private readonly textEl: SVGGElement;
    private readonly rectEl: SVGGElement;

    constructor(private props: ConnectorLabelProps) {
        const g = (this.el = DomUtil.createSvgElement(
            'g',
            ConnectorLabel.class
        ));
        this.textEl = DomUtil.createSvgElement('text', 'text-select');
        this.textEl.setAttribute('text-anchor', 'middle');
        this.textEl.setAttribute('dominant-baseline', 'middle');

        this.rectEl = DomUtil.createSvgElement('rect', 'background');
        this.rectEl.setAttribute('rx', '10');
        this.rectEl.setAttribute('ry', '10');

        g.append(this.rectEl);
        g.append(this.textEl);
    }

    public dispose() {
        this.textEl?.remove();
        this.rectEl?.remove();
        this.el?.remove();
    }

    public updateProps(newProps: Partial<ConnectorLabelProps>) {
        Object.assign(this.props, newProps);
    }

    public draw() {
        if (!this.textEl) {
            return;
        }
        const { text: getText, location, points, debug } = this.props;
        const text = CoreUtil.fromFnOrValue(getText, this);
        const p = this.getPosition(
            points,
            CoreUtil.fromFnOrValue(location, this)
        );

        if (p.x == undefined) {
            return;
        }

        this.el.setAttribute('transform', `translate(${p.x},${p.y})`);
        DomUtil.replaceAllClasses(this.el, [
            ConnectorLabel.class,
            this.props.class,
        ]);
        this.textEl.textContent = text;

        const fontSize = 11;
        const horizontalPadding = 20;
        const lineHeight = 20;
        const averageCharWidth = 0.55 * fontSize;
        const estimatedTextWidth =
            text.length * averageCharWidth + horizontalPadding;

        const rect = new Rect(
            -estimatedTextWidth / 2,
            -lineHeight / 2,
            estimatedTextWidth,
            lineHeight
        );
        Dom2dUtil.setBounds(this.rectEl, rect);

        if (debug) {
            this.drawDebugPoint(p);
        }
    }

    /**
     * Returns the coordinates of a point along the route, located at a
     * normalized distance (.5 is the middle) from the start
     */
    private getPosition(points: Point[], location = 0.5, result = {} as IXY) {
        const res = OrthoRouteUtil.getPosition(
            points,
            location,
            undefined,
            true
        );
        result.x = res.p.x;
        result.y = res.p.y;
        return result;
    }

    private drawDebugPoint(position: Point) {
        const dbg = select(this.el)
            .selectAll('g.dbg')
            .data([0])
            .join('g')
            .attr('class', 'dbg')
            .style('pointer-events', 'none');

        dbg.selectAll('circle.m')
            .data([0])
            .join('circle')
            .attr('class', 'm')
            .attr('cx', position.x)
            .attr('cy', position.y)
            .attr('r', 5)
            .style('fill', 'none')
            .style('stroke', 'blue')
            .style('opacity', 0.5);
    }
}
