import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimatedLogoComponent } from '../animated-logo/animated-logo.component';

@Component({
    selector: 'dxy-splash-screen',
    template: '<dxy-animated-logo></dxy-animated-logo>',
    styleUrls: ['splash-screen.component.scss'],
    standalone: true,
    imports: [AnimatedLogoComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent {}
