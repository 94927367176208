export const tableauFieldInfo = {
    protocol: {
        hint: {
            fr: "Protocole à utiliser lors des appels à l'API Tableau",
            en: 'Protocol to use when calling the Tableau API',
        },
    },
    server: {
        hint: {
            fr: 'Adresse du serveur Tableau',
            en: 'Tableau server address',
        },
    },
    port: {
        hint: {
            fr: 'Port du serveur Tableau (facultatif)',
            en: 'Tableau server port (optional)',
        },
    },
    siteContentUrl: {
        hint: {
            fr: 'Identifiant du site',
            en: 'Tableau site identifier',
        },
    },
    apiVersion: {
        hint: {
            fr: "Version de l'API Tableau (dépendante de la version du serveur Tableau)",
            en: 'Tableau API version (depends on Tableau server version)',
        },
    },
    user: {
        hint: {
            fr: "Nom du jeton individuel d'accès Tableau",
            en: 'Tableau Personal Access Token name',
        },
    },
    password: {
        hint: {
            fr: "Secret du jeton individuel d'accès Tableau",
            en: 'Tableau Personal Access Token secret',
        },
    },
};
