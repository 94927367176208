import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import {
    IWidthHeight,
    IXY,
    IXYRO,
    MovePhase,
    Point,
    Rect,
} from '@datagalaxy/core-2d-util';
import { BehaviorSubject } from 'rxjs';
import { IPanZoomEvent, PanZoomTool } from '../pan-zoom';
import { IUpdateViewportOptions } from './viewport.types';
import { ZoneUtils } from '@datagalaxy/utils';
import { CoreUtil } from '@datagalaxy/core-util';

export interface IViewPortEvent {
    position: Point;
    bounds: IWidthHeight;
    scale?: number;
    phase: MovePhase;
}

export class Viewport extends BaseGraphicalManager {
    public width: number;
    public height: number;

    private change = new BehaviorSubject<IViewPortEvent>(null);
    private timerUpdateViewport: number;

    public get size(): Readonly<IWidthHeight> {
        return { width: this.width, height: this.height };
    }
    public get center(): IXYRO {
        return { x: this.width / 2, y: this.height / 2 };
    }
    public get documentCenter() {
        return this.panZoom.adapter.unzoom.xy(this.center.x, this.center.y);
    }
    public get change$() {
        return this.change.asObservable();
    }
    public get viewport() {
        return this.change.value;
    }

    /**
     * Viewport offset and size, in view coordinates
     * Note: origin point from the viewport point of view is always 0,0
     */
    public readonly vp = new Rect();
    /** view box: the viewport seen from the zoomed world, in document coordinates */
    public readonly vb = new Rect();

    constructor(private container: HTMLElement, private panZoom: PanZoomTool) {
        super();
        this.updateWidthHeightInternal();
        super.subscribe(panZoom.zoomed$, (event) => this.onPanZoomEvent(event));
    }

    /**
     * Given the coordinate in client browser system, return the coordinate
     * inside the viewport in document system (fixed position)
     */
    public fromPageLocation(x: number, y: number): IXY {
        const pos = this.container.getBoundingClientRect();
        const point = { x: x - pos.x, y: y - pos.y };
        return this.panZoom.adapter.unzoom.xy(point.x, point.y);
    }

    /** To call when a parent element or the container size has changed */
    public updateViewportAsync(recenter?: boolean) {
        this.log('updateViewportAsync');
        return new Promise<void>((resolve) => {
            //this.internalContainer.classed('hidden', true)
            ZoneUtils.zoneTimeout(
                () => {
                    this.updateViewport({ recenter });
                    //this.internalContainer.classed('hidden', false)
                    ZoneUtils.zoneTimeout(resolve, 0, null, true);
                },
                0,
                null,
                true
            );
        });
    }

    public updateViewport(opt?: IUpdateViewportOptions) {
        window.clearTimeout(this.timerUpdateViewport);

        const action = () => {
            const info = this.updateWidthHeightInternal();
            this.log('updateViewport', opt, info);
            if (info && opt?.recenter) {
                // adapt the zoomed view so its content stays in place
                const scale = this.panZoom.scale;
                this.panZoom.translateBy(info.tx / scale, info.ty / scale);
            }
            opt?.onEnd?.();
        };

        if (opt?.debounce || opt?.delayMs) {
            this.timerUpdateViewport = ZoneUtils.zoneTimeout(
                action,
                opt?.delayMs || 333,
                null,
                true
            );
        } else {
            action();
        }
    }

    private updateWidthHeightInternal() {
        if (!this.container) {
            return;
        }

        // old dimensionsc
        let w0 = this.width,
            h0 = this.height;

        // new dimensions
        const bcr = this.container.getBoundingClientRect();
        const offset = { x: bcr.x, y: bcr.y };
        const { width: w = 0, height: h = 0 } = bcr;
        if (!w) {
            CoreUtil.warn('container has no width', this.container);
        }
        if (!h) {
            CoreUtil.warn('container has no height', this.container);
        }

        w0 ??= w;
        h0 ??= h;

        this.width = w;
        this.height = h;

        // return applied translation and scaling
        const tx = (w - w0) / 2,
            ty = (h - h0) / 2,
            k = Math.min(w / w0, h / h0);

        this.debug &&
            this.log('updateWidthHeightInternal', { w, h, w0, h0, tx, ty, k });

        this.vp.setPosition(0, 0).copySize(this.size);
        this.panZoom.extent({ x: 0, y: 0 }, { x: w, y: h });
        this.panZoom.updateOffset({ x: offset.x, y: offset.y });

        const position = this.panZoom.position;
        this.change.next({
            position: { x: position.x, y: position.y },
            bounds: { width: w, height: h },
            scale: this.panZoom.scale,
            phase: MovePhase.end,
        });
        return { tx, ty, k, w, h };
    }

    private onPanZoomEvent(event: IPanZoomEvent) {
        this.change.next({
            position: { x: event.x, y: event.y },
            bounds: this.viewport.bounds,
            scale: event.k,
            phase: event.phase,
        });
        this.panZoom.adapter.unzoom.rectSelf(this.vp.copyTo(this.vb));
    }
}
