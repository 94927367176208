import { DgModuleDefinition } from '../dg-module-definition';
import { DgModuleName } from '../dg-module-name';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export class ProcessingDgModule extends DgModuleDefinition {
    public name: DgModuleName = 'Processing';
    public translateKey = 'DgServerTypes.DataGalaxyModule.Processing';
    public glyphClass = 'glyph-dataprocessing';
    public colorGlyphClass = 'technical-dynamic-color';
    public graphicalColor = GraphicalColor.Reflex2;
    public position = 3;
    public categoryKey = 'dataKnowledgeCatalog';
}

export default new ProcessingDgModule();
