import { Injectable } from '@angular/core';
import { TransitionOptions, UIRouter, UIRouterGlobals } from '@uirouter/core';

/**
 * ## Role
 * Remove any query param from url
 */
@Injectable({
    providedIn: 'root',
})
export class QueryParamRemover {
    constructor(
        private uiRouter: UIRouter,
        private uIRouterGlobals: UIRouterGlobals
    ) {}

    async remove(queryParams: string[]) {
        const globalParams = this.uiRouter.globals.params;
        const currentState = this.uIRouterGlobals.current;
        const newParams = {};
        for (let qp of queryParams) {
            delete globalParams[qp];
            newParams[qp] = undefined;
        }
        const options = {
            location: 'replace',
            reload: false,
            custom: {
                queryParamRemoval: true,
            },
        } as TransitionOptions;

        this.uiRouter.stateService.go(currentState, newParams, options);
    }
}
