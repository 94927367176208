import { IWidthHeight, RectSide, Side } from '@datagalaxy/core-2d-util';

/** Connection port usage (source, target, both) */
export enum PortUsage {
    /** The port can neither create nor receive new connections */
    none = -1,
    /** The port can create and receive new connections */
    both = 0,
    /** The port can only create new connections */
    source,
    /** The port can only receive new connections */
    target,
}

/** Connection port kind (node, element, custom) */
export enum PortKind {
    unknown = 0,
    /** The port is materialized by the whole node element */
    node,
    /** The port is materialized by a distinct element,
     * positionned along one or several sides of the node's rectangle,
     * with an optional margin */
    element,
    /** The port is materialized by an element defined by the client,
     * its properties are defined by attributes on this element.
     * Those attributes are:
     * - data-graph-port: with value being 'source' or 'target'
     * - data-graph-port-type: any value, passed along at connection time
     */
    custom,
}

/** Connection port definition for a rectangular connectible node */
export interface IRectPortSpec {
    id?: string;
    /** Usage type of the port: source only, target only, both. Defaults to *both*. */
    usage?: PortUsage;
    /** Kind of port. Defaults to *PortKind.node* */
    kind?: PortKind;
    /** Sides of the rectangle that can hold a connector end or port element. Defaults to *all sides*. */
    sides?: (RectSide | Side)[];
    /** size of the port element, when kind is *element*. Defaults to 10 */
    size?: number | IWidthHeight;
    /** Distance to the side for positionning a port element of type *PortKind.element*.
     * Defaults to 0.
     * Can be positive (outside the node rectangle) or negative (inside the node rectangle). */
    margin?: { top?: number; right?: number; bottom?: number; left?: number };
    /** client port type, passed along at connection time to allow/disallow a connection */
    type?: string;
    /** Enable the connecting to start from the node itself instead of the port */
    linkFromNode?: boolean;
}
