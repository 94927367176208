export type TDomElement = SVGElement | HTMLElement;

//#region points
export interface ITopLeft {
    top: number;
    left: number;
}
export interface IXY {
    x: number;
    y: number;
}
export type Point = IXY;
/** readonly x & y */
export type IXYRO = Readonly<IXY>;
export type IXYParams = Partial<IXYRO>;
//#endregion

//#region other 2-coordinates objects
export interface IShift {
    dx: number;
    dy: number;
}
//#endregion

export enum MovePhase {
    unknown = 0,
    start,
    move,
    end,
    cancel,
}

export interface IWidthHeight {
    width: number;
    height: number;
}
export type ISizeRO = Readonly<IWidthHeight>;
export type ISizeParams = Partial<ISizeRO>;

//#region rectangles
/** DOMRect-like rectangle (with properties: x & left, y & top, width, height) */
export type TRect = IRect & IXYRect;
/** readonly x, y, width, height */
export interface IXYRectRO extends IXYRO {
    readonly width: number;
    readonly height: number;
}
/** x, y, width, height */
export interface IXYRect extends IWidthHeight {
    x: number;
    y: number;
}
export type IXYRectParams = Partial<IXYRect>;
/** top, left, width, height */
export interface IRect extends ITopLeft, IWidthHeight {}
export type TInputRect = Partial<IRect & IXYRect> & { w?: number; h?: number };
export interface IRoundRect extends Partial<IXYRect> {
    rx?: number;
    ry?: number;
}
//#endregion
