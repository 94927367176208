import { deserialize, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';
import { IWidgetStoredConfig } from '../widget';

/** a dashboard-grid configuration */
export interface IDashboardGridStoredConfig {
    /** configuration of widgets in the grid */
    widgets: IWidgetStoredConfig[];

    /** in case of several configurations available, true means this is the one to be displayed */
    isCurrent?: boolean;
}

@inheritSerialization(BaseDTO)
export class DashboardDto extends BaseDTO {
    @deserialize public DashboardId?: number;
    @deserialize public UserId?: string;
    @deserialize public DisplayName?: string;
    @deserialize public Description?: string;
    @deserialize public Config?: string;
    @deserialize public IsPrivate?: boolean;
    @deserialize public IsDefaultDashboard?: boolean;
    @deserialize public IsUserDashboardCreator?: boolean;

    public dashboardConfig?: IDashboardGridStoredConfig;

    public static OnDeserialized(instance: DashboardDto) {
        if (instance.Config) {
            instance.dashboardConfig = JSON.parse(
                instance.Config
            ) as IDashboardGridStoredConfig;
        }
    }

    /** returns a new DashboardDto, with IsUserDashboardCreator set to true */
    public static create(
        displayName: string,
        config?: IDashboardGridStoredConfig
    ) {
        const dashboard = new DashboardDto();
        dashboard.DisplayName = displayName;
        dashboard.IsUserDashboardCreator = true;
        dashboard.dashboardConfig = config;
        return dashboard;
    }

    constructor() {
        super();
    }
}
