<ng-container *ngFor="let module of accessibleModules$ | async">
    <dxy-nav-sidebar-item
        [glyphClass]="module.glyphClass"
        [showTooltip]="showItemsTooltip"
        [dtName]="module.name"
        [itemIdentifier]="getViewIdentifier(module)"
        [stateId]="getStateName(module.name)"
        (onNavigation)="navigateTo($event)"
    >
    </dxy-nav-sidebar-item>
</ng-container>
