import { Injectable } from '@angular/core';
import {
    MultilingualAttributeSetting,
    MultilingualLanguageSetting,
    MultilingualSettings,
} from '@datagalaxy/webclient/multilingual/data-access';
import { MultilingualState } from './multilingual-state';
import { BaseStateService } from '@datagalaxy/utils';
import { TranslationBatch } from '@datagalaxy/webclient/multilingual/domain';

@Injectable({
    providedIn: 'root',
})
export class MultilingualStateService extends BaseStateService<MultilingualState> {
    constructor() {
        super({
            featureEnabled: false,
            settings: {
                sourceLanguageCode: '',
                targetLanguages: [],
                attributes: [],
            },
            loading: false,
            pendingBatches: [],
            liveImportIds: [],
        });
    }

    public getEnabled() {
        return this.state.settings.enabled && this.state.featureEnabled;
    }

    public selectPendingBatches() {
        return this.select((s) => s.pendingBatches);
    }

    public selectLoading() {
        return this.select((s) => s.loading);
    }

    public selectEnabled() {
        return this.select((s) => s.settings.enabled && s.featureEnabled);
    }
    public selectSourceLanguageCode() {
        return this.select((s) => s.settings.sourceLanguageCode);
    }
    public selectAllLanguageCodes() {
        return this.select((s) => {
            const languages =
                s.settings.targetLanguages?.map((ls) => ls.languageCode) ?? [];
            const sourceLang = s.settings.sourceLanguageCode;
            if (sourceLang) {
                languages.unshift(sourceLang);
            }
            return languages;
        });
    }
    public selectAttributeSettings() {
        return this.select((s) => s.settings.attributes ?? []);
    }

    public startLoading() {
        this.setState({
            loading: true,
        });
    }

    public stopLoading() {
        this.setState({
            loading: false,
        });
    }

    public init(state: Partial<MultilingualState>) {
        this.setState(state);
    }

    public isSourceLanguage(sourceLanguageCode: string) {
        return this.state.settings.sourceLanguageCode === sourceLanguageCode;
    }

    public updateSettings(settings: Partial<MultilingualSettings>) {
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                ...settings,
            },
        });
    }
    public updateLanguageSettings(
        languageSettings: MultilingualLanguageSetting[]
    ) {
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                targetLanguages: languageSettings,
            },
        });
    }

    public updateAttributeSettings(
        attributeSettings: MultilingualAttributeSetting[]
    ) {
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                attributes: attributeSettings,
            },
        });
    }

    public updateAttributeSetting(
        updatedSetting: MultilingualAttributeSetting
    ) {
        const attributes = this.state.settings.attributes ?? [];
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                attributes: attributes.map((a) =>
                    a.attributePath === updatedSetting.attributePath
                        ? updatedSetting
                        : a
                ),
            },
        });
    }

    public removeAttributeSetting(
        attributeSetting: MultilingualAttributeSetting
    ) {
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                attributes: this.state.settings.attributes?.filter(
                    (a) => a.id !== attributeSetting.id
                ),
            },
        });
    }

    public updatePendingBatches(pendingBatches: TranslationBatch[]) {
        this.setState({
            ...this.state,
            pendingBatches,
        });
    }

    public addLanguage(languageCode: string) {
        const targetLanguages = this.state.settings.targetLanguages ?? [];
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                targetLanguages: [...targetLanguages, { languageCode }],
            },
        });
    }

    public addAttributeSettings(settings: MultilingualAttributeSetting[]) {
        const previousSettings = this.state.settings.attributes ?? [];
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                attributes: [...previousSettings, ...settings],
            },
        });
    }

    public enable(settings: MultilingualSettings) {
        this.setState({
            settings,
            loading: true,
        });
    }
    public enableEnded() {
        this.setState({
            settings: {
                ...this.state.settings,
                enabled: true,
            },
            loading: false,
        });
    }

    public startRegneration() {
        this.setState({
            runningRegenerations: (this.state.runningRegenerations ?? 0) + 1,
        });
    }

    public regenerationEnded() {
        this.setState({
            runningRegenerations: this.state.runningRegenerations
                ? this.state.runningRegenerations - 1
                : 0,
        });
    }

    public addLiveImportTranslation(importId: string) {
        this.setState({
            liveImportIds: [...this.state.liveImportIds, importId],
        });
    }

    public removeLiveImportTranslation(importId: string) {
        this.setState({
            liveImportIds: this.state.liveImportIds.filter(
                (id) => id !== importId
            ),
        });
    }
}
