<dxy-value-list-filter
    [(filter)]="filter"
    [isMini]="isMini"
    [operators]="operators"
    [items]="filterOptions"
    [adapter]="filterAdapter"
    [config]="filterConfig"
    [forceSingleSelection]="isQuickFilter"
    [labelText]="label"
    [readonly]="readOnly"
    [noOperator]="isQuickFilter"
    [hasRemove]="showRemoveButton"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    [hideIcon]="hideIcon"
    (filterChange)="onFilterChange()"
    (remove)="onRemove()"
    (onOpenClose)="onOpenCloseMenu($event)"
>
    <div
        *ngIf="showTagCollection"
        customTextTemplate
        class="filter-value-wrapper"
    >
        <div class="text-value-wrapper">
            <app-attribute-collection-cell
                *ngIf="filter?.values?.length"
                [containerWidth]="80"
                [lineHeight]="12"
                [hideEllipsedLabel]="false"
                [attributeMeta]="filterItemData.attributeMeta"
                [values]="filter?.values"
                [mini]="true"
                [fitContentWidth]="true"
            ></app-attribute-collection-cell>
        </div>
    </div>

    <div *ngIf="showUserCollection" customIconTemplate class="user-collection">
        <app-attribute-collection-cell
            *ngIf="filter?.values?.length"
            [containerWidth]="70"
            [fitContentWidth]="true"
            [attributeMeta]="filterItemData.attributeMeta"
            [values]="filter?.values"
            [hideLabel]="true"
            [mini]="true"
            [moreCountAsOption]="true"
        ></app-attribute-collection-cell>
    </div>
</dxy-value-list-filter>
