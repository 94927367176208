import * as Papa from 'papaparse';
export class ParseErrorInfo {
    /** groups parse error row numbers  */
    public static fromParseErrors(
        parseErrors: Papa.ParseError[]
    ): ParseErrorInfo[] {
        const map = new Map<string, ParseErrorInfo>();

        parseErrors.forEach((pe) => {
            const key = pe.type + '|' + pe.code + '|' + pe.message;
            const m = map.get(key);
            if (m) {
                if (m.rows.indexOf(pe.row) == -1) m.rows.push(pe.row);
            } else {
                map.set(
                    key,
                    new ParseErrorInfo(pe.type, pe.code, pe.message, [pe.row])
                );
            }
        });

        const result = new Array<ParseErrorInfo>();
        map.forEach((m) => result.push(m));
        return result;
    }

    constructor(
        public type: string,
        public code: string,
        public message: string,
        public rows: number[]
    ) {}

    public get textInfo() {
        return `[${this.type}, ${this.code}] ${this.message}`;
    }
    public get rowsInfo() {
        return this.rows.join(', ');
    }
}
